import React from "react";
import ReactDOM from 'react-dom'
import SendRequest from "../SendRequest";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import PubSub from 'pubsub-js'
import jstz from 'jstz';
import ViewReportInfo from './ViewReportInfo.jsx';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as ChartUtil from "../js/helpers/utils/chartutil";
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import AuditMetaDataView from './auditmetadataview'
import ViewScheduleReportModal from './viewschedulereport';
import SaveStoryboardHtml from './SaveStoryboardModal.jsx';
import UserCustomscriptModal from './UserCustomscriptModal.jsx';
import StoryboardTheme from './StoryboardColorThemeModal.jsx'
import EmbedReportView from './embedreportview'
import ShareReportModal from './sharereportmodal'
import ViewReportHeaderTemplate from './ViewReportHeader.jsx'
import { lang } from "../js/helpers/utils/languages/language";
import LiveReportsView from './livereportsview'
import _ from 'lodash';
const $ = window.$;


export default class ViewReportHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        PubSub.unsubscribe('updatembbedreport', this.updatembbedreport.bind(this));
        this.state.reportId = this.props.reportId;
        this.state.datamodelId = this.props.datamodelId;
        this.state.features = this.props.features;
        this.state.name = this.props.name;
        this.state.timeZoneType = this.props.timeZoneType;
        this.state.folder = this.props.folder;
        this.state.syncConfigData = this.props.syncConfigData;
        this.state.desc = this.props.desc;
        this.state.schemaType = undefined;
        this.state.isStoredProcedure = undefined;
        var that = this;
        this.state.facttables = this.props.facttables;
        this.state.multifact = (this.props.multifact == 'true');
        this.state.multifactentities = this.props.multifact == 'true' ? JSON.parse(this.props.multifactentities) : this.props.multifactentities;
        this.updatembbedreportEvee = PubSub.subscribe('updatembbedreport', this.updatembbedreport.bind(this));
        this.liveReportOpenEve= PubSub.subscribe('liveReportOpen',this.liveReportOpenEve.bind(this));
        this.state.isImportedReport = this.props.isImportedReport;
        this.state.isFromSmartInsigt = this.props.isFromSmartInsigt;
        this.state.isVersioned=this.props.isVersioned;
        this.state.userRole=this.props.userRole
    }
    componentDidMount() {
        if (this.state.isImportedReport) {
            var folder = this.props.folder
            if (folder === "") { folder = "Default" }
            var path = '<li><a class="navDashboard" href="/'+window.localStorage.getItem("appName")+'/welcome/visualization"><i class="fa fa-bar-chart fa-solid"></i> '+lang["storyboard.visualization"]+'</a></li><li><a href="javascript:void(0)"><i class="fa fa-bar-chart"></i> '+lang["storyboard.newboard"]+'</a></li>'
        } else {
            var folder = this.props.folder
            if (folder === undefined || folder === "") { folder = "Default" }
            var path = '<li><a class="navDashboard" href="/'+window.localStorage.getItem("appName")+'/welcome/visualization"><i class="fa fa-bar-chart fa-solid"></i> '+lang["storyboard.visualization"]+'</a></li><li><a href="javascript:void(0)"><i class="fa fa-bar-chart"></i> '+lang["storyboard.newboard"]+'</a></li>'
            if (this.state.reportId != undefined && this.state.reportId != "") {
                var path = '<li><a class="navDashboard" href="/'+window.localStorage.getItem("appName")+'/welcome/visualization"><i class="fa fa-bar-chart fa-solid"></i>'+lang["storyboard.visualization"]+'</a></li><li><a href="javascript:void(0)"><i class="fa fa-folder"></i> ' + folder + '</a></li><li><a><i class="fa fa-bar-chart"></i> ' + this.props.name + '</a></li>'
            }
        }
        $(".navbar.navbar-bird ul.nav-path").html(path);
    }
    render() {
        if (sessionStorage.getItem("isImportedReport") == "true" || window.location.pathname.indexOf("importreport") >= 0) {
            this.state.isImportedReport = true;
        }
        var tempColumns = [];
        var title = this.props.title;
        var schemaType;
        if (this.state.schemaType != undefined && this.state.schemaType != '' && this.state.schemaType != null)
            schemaType = (this.state.schemaType).toLowerCase();
        var schemaTypeFlag;
        var schemaTypeFlag1;
        var schemaTypeFlag2;
        var purgeHidden;
        if (title === undefined) {
            title = lang["storyboard.board"];
        }
        var isSharedReport = false;
        if (window.location.pathname.indexOf("sharereport") >= 0) {
            isSharedReport = true;
        }
        var isViewModel = false;
        if (window.location.pathname.indexOf("viewmodel") >= 0) {
            isViewModel = true;
            title = lang["storyboard.smartinsights"];
        }
        var isEmbeddedReport = false;
        if (window.location.pathname.indexOf("embeddedreport") >= 0 || window.location.pathname.indexOf("alertreport") >= 0) {
            isEmbeddedReport = true;
        }
        var isScheduleReport = false;
        if (window.location.pathname.indexOf("schedulereport") >= 0) {
            isScheduleReport = true;
        }
        var isPurgedReport = false;
        if (window.location.pathname.indexOf("viewpurgereport") >= 0) {
            isPurgedReport = true;
        }


        var isDirect = ChartInfo.functions.getIsDirect();
        if (this.state.isStoredProcedure != undefined) {
            ChartInfo.functions.setIsStoredProcedure(this.state.isStoredProcedure);
        }
        if (this.props.viewreportCollection != undefined && this.props.viewreportCollection.getSharedViewOnlyFlag != undefined &&
            this.props.viewreportCollection.getSharedViewOnlyFlag() == 1) {
            isEmbeddedReport = true;
            isPurgedReport = true;
        }
        var isMicrosoftEdge = false;
        if (/Edge\/\d./i.test(navigator.userAgent)) {
            isMicrosoftEdge = true
        }
        return (<div>
            <ViewReportHeaderTemplate
                {...this.props}
                isfavorite={this.props.viewreportCollection.model.isfavorite}
                isPublished={this.props.viewreportCollection.model.isPublished}
                reportRequestFrom={this.props.reportRequestFrom}
                isMicrosoftEdge={isMicrosoftEdge}
                title={this.props.title}
                userRole={this.state.userRole}
                isScheduleReport={isScheduleReport}
                isSharedReport={isSharedReport}
                isEmbeddedReport={isEmbeddedReport}
                allfeatures={this.state.features}
                reportInfo={this.props.reportInfo}
                Report_Info={this.props.Report_Info}
                datamodelId={this.state.datamodelId}
                sharedInfo={this.props.sharedInfo}
                scheduleInfo={this.props.scheduleInfo}
                alertsInfo={this.props.alertsInfo}
                filtersArray={this.props.filtersArray}
                reportId={this.state.reportId}
                reportcolumns={this.props.reportcolumns}
                reportName={this.state.name}
                reportDesc={this.state.desc}
                isImportedReport={this.state.isImportedReport}
                multifact={this.state.multifact}
                scheduleReportModel={this.props.scheduleReportModel}
                storyboardcolortheme={this.storyboardcolortheme}
                setAsFavourite={this.setAsFavourite}
                publishStoryboard={this.publishStoryboard}
                viewEmbedreport={this.viewEmbedreport}
                showschedulemodal={this.showschedulemodal}
                showsharestroyboardmodal={this.showsharestroyboardmodal}
                liveReportOpen={this.liveReportOpen}
                isFromSmartInsigt={this.state.isFromSmartInsigt}
                exportstoryboardreport={this.exportstoryboardreport}
                auditmetadata={this.auditmetadata}
                isVersioned={this.state.isVersioned}
                versionname={this.props.versionname}
            />
        </div>);
    }
    viewEmbedreport = (e) => {
        if (this.state.reportId == null || this.state.reportId == undefined || this.state.reportId == "" || window.location.pathname.indexOf("importreport") >= 0 || window.location.pathname.indexOf("sharereport") >= 0) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage("BirdInformation01"));
            return;
        }
        var isPublished=ChartInfo.functions.getisPublishedOfDb();
        var isWorkspaceLiveEnabled = ChartInfo.functions.getIsWorkspaceLiveEnabled();
        if(isWorkspaceLiveEnabled){
            isPublished = 'true'
        }
        if(isPublished=='false'){
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation61'));
        }else {
            e.preventDefault();
            var name = e.currentTarget;
            ModalProperties.resize();
            var that = this;
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "getServerTime",
                queryString: "",
                sucessFunction: response => {
                    var embedReportView = new EmbedReportView({
                        reportId: that.state.reportId,
                        reportName: that.state.name,
                        servertimezone: response.serverTimeZone,
                        features: that.state.features
                    });
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });
        }
    }

    storyboardcolortheme = (e) => {
        var $this = e.currentTarget;
        var reporttheme1 = new StoryboardTheme(
            {
                chartnumber: $($this).closest('.maxanim1').attr("data-num")
            });
        ModalProperties.resize();
    }
    publishStoryboard = () => {
        if (this.state.reportId === undefined || this.state.reportId === "" || window.location.pathname.indexOf("sharereport") >= 0 || window.location.pathname.indexOf("importreport") >= 0) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
        }
        else {
            var publishsb = new FormData();
            publishsb.set("reportId", this.state.reportId);
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "publishstoryboard",
                body: publishsb,
                sucessFunction: response => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    } else
                        $('#publishstoryboard i').addClass("fa-yellow");
                        $('#publishstoryboard i').addClass("published");
                        ChartInfo.functions.setisPublished("true");
                        ChartInfo.functions.setisPublishedOfDb("true");
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
    }
    setAsFavourite = () => {
        if (this.state.reportId === undefined || this.state.reportId === "" || window.location.pathname.indexOf("sharereport") >= 0 || window.location.pathname.indexOf("importreport") >= 0) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
        }
        else {
            var favmodel = new FormData();
            favmodel.set("reportId", this.state.reportId);
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "setasfavourite",
                body: favmodel,
                sucessFunction: response => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    } else
                        $('#setstoryboardasfavourite i').addClass("fa-yellow");
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
    }
    updatembbedreport() {
        var dateSelection;
        var timeSelection;
        var dateSelected = $('#embedreportenddate').val();
        var timeSelected = $('#embedreportendTime').val();
        if (dateSelected !== "" && timeSelected === "") {
            $('.spinner').hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning75'));
            $("#embedurl").prop("disabled", true);
            $("#emailembedurlbutton").prop("disabled", true);
            return false;

        } else if (timeSelected !== "" && dateSelected === "") {
            $('.spinner').hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning74'));
            $("#embedurl").prop("disabled", true);
            $("#emailembedurlbutton").prop("disabled", true);
            return false;

        } else if (timeSelected !== "" && dateSelected !== "") {
            dateSelection = dateSelected;
            timeSelection = timeSelected;
            $("#embedurl").prop("disabled", false);
            $("#emailembedurlbutton").prop("disabled", false);
        } else if (timeSelected == "" && dateSelected == "") {
            $('#embedreportendTime').val("");
            dateSelection = "00/00/0000";
            timeSelection = "00:00";
            $("#embedurl").prop("disabled", false);
            $("#emailembedurlbutton").prop("disabled", false);
        }

        var loginTypeReport = $(".reportLoginType input[type='radio']:checked").attr("id");
        var urlAccessingTypeReport = $(".reportUrlAccessing input[type='radio']:checked").attr("id");

        var that = this;
        var report;
        // if (that.state.reportId == undefined) {
        //     if (loginTypeReport != undefined && loginTypeReport == "1") {
        //         report = $('#embedurl').val().split('=')[1].split('&')[0];
        //     } else if (loginTypeReport != undefined && loginTypeReport == "2") {
        //         report = $('#embedurl').val().split('/').pop();
        //     }
        // } else {
        //     if (that.state.reportId != $('#embedurl').val().split('=').pop()) {
        //         report = $('#embedurl').val().split('=')[1].split('&')[0];
        //     }
        //     if (that.state.reportId != $('#embedurl').val().split('/').pop()) {
        //         if (loginTypeReport != undefined && loginTypeReport == "1") {
        //             report = $('#embedurl').val().split('=')[1].split('&')[0];
        //         }
        //         else if (loginTypeReport != undefined && loginTypeReport == "2") {
        //             report = $('#embedurl').val().split('/').pop();
        //         }
        //     } else {
        //         report = that.state.reportId;
        //     }
        // }

        report = that.state.reportId;
        var embedtimezone = $('.embedtimezone:checked').attr("id")
        if (embedtimezone == "localtimezone") {
            var localTimezone = jstz.determine().name();
        }
        var accessPermissionId = $('.publishurl:checked').attr("id");
        var formData = new FormData();
        var expDatetime =   new Date(new Date(dateSelection+" "+timeSelection).toLocaleString("en-US", {timeZone: window.localStorage.getItem("serverTimezone")})).getTime() +"@@@"+window.localStorage.getItem("serverTimezone");
        formData.set("dateSelectionn", ChartUtil.birdbtoa("embed",expDatetime));
        formData.set("timeSelectionn", timeSelection);
        formData.set("reportId", btoa(report));
        formData.set("embeddedLoginId", accessPermissionId);
        formData.set("localTimezone", localTimezone);
        formData.set("pathName", window.location.origin + "/"+window.localStorage.getItem("appName")+"/welcome")

        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "publishreport",
            body: formData,
            sucessFunction: (response) => {
                if (accessPermissionId == 1) {
                    $("#embedurl").html(response.private);
                    if ($('.publishurl:checked').attr("id") == "1") {
                        $('.publishurl:checked').attr("purl", response.private)
                    }
                } else if (accessPermissionId == 2) {
                    $("#embedurl").html(response.public);
                    if ($('.publishurl:checked').attr("id") == "2") {
                        $('.publishurl:checked').attr("purl", response.public)
                    }
                }
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders
        });
    }
    
    showschedulemodal = (e) => {
        if (this.state.reportId == null || this.state.reportId == undefined || this.state.reportId == "" || window.location.pathname.indexOf("importreport") >= 0 || window.location.pathname.indexOf("sharereport") >= 0) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage("BirdInformation01"));
        } 
        var isPublished=ChartInfo.functions.getisPublishedOfDb();
        var isWorkspaceLiveEnabled = ChartInfo.functions.getIsWorkspaceLiveEnabled();
        if(isWorkspaceLiveEnabled){
            isPublished = 'true'
        }
        if(isPublished=='false'){
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation61'));
        }else {
            e.preventDefault();
            var name = e.currentTarget;
            var that = this;
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "getServerTime",
                queryString: "",
                sucessFunction: response => {
                    var viewScheduleReportModal = new ViewScheduleReportModal({
                        reportId: that.state.reportId,
                        reportName: that.state.name,
                        servertimezone: response.serverTimeZone
                    });
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });
            ModalProperties.resize();
        }
    }
  
    showsharestroyboardmodal = (e) => {
        if (this.state.reportId === undefined || this.state.reportId =="" || window.location.pathname.indexOf("sharereport") >= 0 || window.location.pathname.indexOf("importreport") >= 0) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
            return;
        }
        var isPublished=ChartInfo.functions.getisPublishedOfDb();
        var isWorkspaceLiveEnabled = ChartInfo.functions.getIsWorkspaceLiveEnabled();
        if(isWorkspaceLiveEnabled){
            isPublished = 'true'
        }
        if(isPublished=='false'){
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation61'));
        }
        else {
            var shareReportModel = new ShareReportModal({
                reportId: this.state.reportId,
                singlereport: false,
                // model:new ShareReportValidation(),
                dimensions: this.props.viewreportCollection.getDimensions(),
                sharedFilters: this.props.viewreportCollection.getSharedFilters(),
                datamodelId: this.props.datamodelId
            });
        }
    }
    liveReportOpenEve(msg,data){
       this.liveReportOpen(data);
    }
    liveReportOpen = (e) => {
        var chartnumber = $(e.currentTarget).attr('data-num') != undefined ? $(e.currentTarget).attr('data-num') : -1;
        var that = this;
        var storyboard=$(e.currentTarget).attr('title');
        var isStoryboard=false;
        if(storyboard.toLowerCase().indexOf('storyboard')!=-1){
           isStoryboard=true;
        }
        var reportId = this.state.reportId;
        if (this.state.isImportedReport || this.state.reportId == undefined || this.state.reportId == "") {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
            return;
        } else {
            var livereport = new LiveReportsView({
                reportId: that.props.reportId,
                datamodelId: that.props.datamodelId,
                chartnumber: chartnumber,
                worker: that.props.worker,
                features: that.state.features,
                facttables: that.state.facttables,
                multifact: that.state.multifact,
                multifactentities: that.state.multifactentities,
                display: "block",
                isStoryboard:isStoryboard
            });
            $(".livereportmodaldisplay #livereportsmodal").modal("show");
            ModalProperties.resize();

            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };

            SendRequest({
                url: "checkforlive",
                queryString: "?reportId=" + this.props.reportId,
                sucessFunction: response => {
                    if (response.hasOwnProperty('error')) {
                        $("label.realtimedatalable.nomargin.streaming").addClass("hide");
                    } else {
                        $("label.realtimedatalable.nomargin.streaming").addClass("show");
                    }
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });
        }
    }
    exportstoryboardreport = (e) => {
        e.preventDefault();
        var that = this;
        if (window.location.pathname.indexOf("importreport") >= 0) {
            // this.reportId=undefined;
        }
        if (this.state.reportId == undefined || this.state.reportId == null || this.state.reportId == "" || window.location.pathname.indexOf("importreport") >= 0) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage("BirdInformation01"));
        } else {
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "exportreport",
                queryString: "?reportId=" + that.state.reportId,
                sucessFunction: response => {
                    if (response != null) {
                        var text = JSON.stringify(response);
                        var filename = response.FILE_PATH;
                        var element = document.createElement('a');
                        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
                        element.setAttribute('download', filename);
                        element.style.display = 'none';
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                        let msg = lang["storyboard.export_audit_message"]
                        ChartUtil.auditMessage(that.state.reportId, msg);
                    }
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });
        }
    }
    auditmetadata=(e)=>{
        var that = this;
        SendRequest({
            url: "getAuditconfig",
            sucessFunction: (response) => {
                if (response[0].audit_enabled === "1") {
                    that.state.auditEnabled = 1;
                } else if (response[0].audit_enabled === "0") {
                    $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Information');
                    $('.birdmessage-info').empty().text(getMessage('BirdInformation40'));
                }
                if (that.state.auditEnabled == 1) {
                     var auditMetaDataView=   new AuditMetaDataView({
                            reportId : that.props.reportId,
                            schemaType:that.state.schemaType,
                        });
                }
            },
            rejectFunction: () => { },
            headers: {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
}