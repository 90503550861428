import React from 'react';
import * as viewreportCollection from "../js/helpers/utils/SavedReportCollection";
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import SendRequest from '../SendRequest';
import { getMessage } from '../js/helpers/utils/BirdMessage';


export default class SaveStoryboardModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			features: props.features,
			datamodelId: props.datamodelId,
			issharedreport: false
		};
		this.applystoryboardchanges = this.applystoryboardchanges.bind(this);
	}
	componentDidMount(){
		$(".form-control").keyup(function(){
			var valThis = $(this).val().toLowerCase();
			  var valLength = valThis.length;
				var maxCount = 45;
				var errormsg="#errmsg"
				if(valLength>=maxCount){
					$(errormsg).html("");
					$(errormsg).html(lang["storyboard.namexceeds"]+maxCount+"" ).show();
					$(this).val($(this).val().substring(0, $(this).val().length - 1));
				}else{
					$(errormsg).html("");
				}
		});
	}
	render() {
		$("#storyboardname-help-block").html('')
		this.state.features = this.props.features;
		this.state.datamodelId = this.props.datamodelId;
		if (viewreportCollection != undefined && viewreportCollection.model != undefined && viewreportCollection.model != null) {
			if (viewreportCollection.model.reportfolder != undefined) {
				viewreportCollection.model.reportfolder = viewreportCollection.model.reportfolder == 'Default' ? "" : viewreportCollection.model.reportfolder
			}
			this.state.reportname = viewreportCollection.model.reportname != undefined ? viewreportCollection.model.reportname : "";
			this.state.reportdesc = viewreportCollection.model.reportdesc != undefined ? viewreportCollection.model.reportdesc : "";
			this.state.folder = viewreportCollection.model.reportfolder != undefined ? viewreportCollection.model.reportfolder : "";
			this.state.title = viewreportCollection.model.title != undefined ? viewreportCollection.model.title : "";
			this.state.reportId = viewreportCollection.model.id != undefined ? viewreportCollection.model.id : 0;
		}
		return (
			// <!-- savestoryboardmodal.js -->

			<div className={`modal fade ${this.props.isDisplay ? "in" : ""}`} id="savestoryboard" tabIndex="-1" role="dialog" aria-labelledby="savestoryboard" aria-hidden={this.props.isDisplay ? "false" : "true"} style={{ display: this.props.isDisplay ? "block" : "none" }}>
				<div className="modal-backdrop fade in" onClick={this.props.close}></div>
				<div className="modal-dialog modal-lg modal-save-report modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
					<div className="modal-content">
						<div className="modal-header boc-modal-hf-padding">
							<button type="button" className="close white" id="storyboardclose" aria-hidden="true" data-dismiss="modal" onClick={this.props.close}>&times;</button>
							<h4 className="modal-title"><i className="fa fa-floppy-o"></i>{lang["storyboard.saveboard"]}</h4>
						</div>
						<div className="modal-body scrollset">
							<div className="container-fluid">
								<div className="row filtercontent">
									<fieldset className="savereport-border">
										<legend className="savereport-border">{lang["storyboard.reportdetails"]}</legend>
										<div className="control-group">
											<div className="validationgroup form-group" style={{ marginTop: "-2px" }}>
												<span className="required right legend"></span>
												<label className="subhead" for="storyboardname">{lang["storyboard.name"]}</label>
												<input type="name" id="storyboardname" name="storyboardname" defaultValue={this.state.reportname} className="form-control input-sm" placeholder={lang["storyboard.placeholdname"]} />
												<span className="help-block  marg-left-10" id="storyboardname-help-block"></span>
											</div>
											<div className="validationgroup form-group">
												<label className="subhead" for="storyboardfolder">{lang["storyboard.folder"]}</label>
												<input type="folder" className="form-control input-sm" defaultValue={this.state.folder} id="storyboardfolder" name="storyboardfolder" list="folders" placeholder={lang["storyboard.slctfolder"]} />
												<span style={{color:"#ff0000"}} id="errmsg"></span>
												<span className="help-block hidden marg-left-10"></span>
												<datalist id="folders">
												</datalist>
											</div>
											<div className="validationgroup form-group hidden-lg hidden-md hidden-sm hidden-xs">
												<label className="subhead">{lang["storyboard.description"]} </label>
												<textarea id="storyboarddesc" placeholder={lang["storyboard.placeholdescription"]} value={this.props.reportDesc} name="storyboarddesc" className="form-control input-sm resize-vertical">{this.props.reportDesc}</textarea>
												<span className="help-block hidden"></span>
											</div>
										</div>
									</fieldset>
								</div>
							</div>
						</div>
						<div className="modal-footer boc-modal-hf-padding">
							<button type="button" className="btn btn-bird" id="btn-birdsave" data-dismiss="modal" onClick={this.props.close}><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
							<button type="button" className="btn btn-bird applystoryboard" id="applystoryboards" onClick={this.applystoryboardchanges}><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
						</div>
					</div>
					{/* <!-- /.modal-content --> */}
				</div>
			</div>
		);
	}
	applystoryboardchanges() {
		var storyboardname = $("#storyboardname").val().trim();
		var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
		if (storyboardname == undefined || storyboardname == "" || storyboardname.length == 0 || /[~`!@#$%^*+\=\[\]{};':\\|†.<>\/?"""`]/.test(storyboardname)) {
			$("#storyboardname-help-block").html(lang["storyboard.validname"])
			return;
		} else if (storyboardname.length > 70) {
			$("#storyboardname-help-block").html(lang["storyboard.reportnameexceeds"])
			return;
		} else if (reservedwordslist != null){ 
		if (reservedwordslist.indexOf(storyboardname.toLowerCase()) != -1) {
			$("#storyboardname-help-block").html(lang["storyboard.noreservewords"])
			return;
			}
		} else {
			$("#storyboardname-help-block").html('')
		}
		var customFieldsScript = sessionStorage.customFields;
		var customFiltersScript = sessionStorage.newreportCustomFilters;
		var drillThroughReports = ChartInfo.functions.getDrillThroughReports();
		var storyboardfolder = $("#storyboardfolder").val() != undefined ? $("#storyboardfolder").val() : "";
		var storyboarddesc = $("#storyboarddesc").val();
		var isSyncValid = true;
		var smartInsightsCols = {};
		smartInsightsCols["targetColumns"] = ChartInfo.functions.getSmartInsightsTargetVal();
		smartInsightsCols["ExcludeColumns"] = ChartInfo.functions.getSmartInsightsExcludeValues();
		var smartInsightsObj = {};
		smartInsightsObj["smartInsightsObj"] = smartInsightsCols;
		console.log(smartInsightsObj);
		var that = this;
		// this.options.model.set('storyboardname', storyboardname);
		// this.options.model.set('storyboardfolder', storyboardfolder);
		// this.options.model.set('storyboarddesc', storyboarddesc);
		if (storyboardfolder === "") {
			storyboardfolder = "Default"
		}
		if (isSyncValid) {
			var reporttitle = $(".boc-viewreport-title:visible").text();
			$(".spinner").show();
			var reportDataArray = [];
			var reportdetails = { "name": storyboardname, "folder": storyboardfolder, "desc": storyboarddesc, "title": reporttitle, "iscloned": false, "issharedreportsaved": that.state.issharedreport };
			var axisDataArray = [];
			var chartInfoDetails = ChartInfo.functions.getAllChartDetails();
			_.each(chartInfoDetails, function (i, j) {
				var storedObject = i;
				var storedParsedObject = JSON.parse(storedObject);
				var tabId = storedParsedObject["tabId"];
				var tabName = storedParsedObject["tabName"];
				var xAxisData = storedParsedObject["xAxis"];
				var legend = storedParsedObject["legend"];
				var yAxisData = storedParsedObject["yAxis"];
				var zAxisData = storedParsedObject["zAxis"];
				var tooltipData = storedParsedObject["tooltip"];
				var chartType = storedParsedObject["chartType"];
				var chartTitle = storedParsedObject["reporttitle"];
				var colLimit = storedParsedObject["colLimit"];
				var orderType = storedParsedObject["orderType"];
				var sortType = storedParsedObject["sortType"];
				var sortingColumn = storedParsedObject["sortingColumn"];
				var xdatesortingEnabled = storedParsedObject['xdatesortingEnabled'];
				var sortingColumnAggregation = storedParsedObject["sortingColumnAggregation"];
				var rankingColumn = storedParsedObject["rankingColumn"];
				var rankingColumnAggregation = storedParsedObject["rankingColumnAggregation"];
				var dobyselectedcolumns = storedParsedObject["dobyselectedcolumns"]
				var dobyrowlimit = storedParsedObject["dobyrowlimit"];
				var metricdatetype = storedParsedObject["metricdatetype"];
				var metricdatefield = storedParsedObject["metricdatefield"];
				var metricdatefielddisplay = storedParsedObject["metricdatefielddisplay"];
				var metricprefix = storedParsedObject["metricprefix"];
				var metricsuffix = storedParsedObject["metricsuffix"];
				var metricfont = storedParsedObject["metricfont"];
				var solidgaugemin = storedParsedObject["solidgaugemin"];
				var solidgaugemax = storedParsedObject["solidgaugemax"];
				var pivotcolumnvalues = storedParsedObject["columns"];
				var pivotrowvalues = storedParsedObject["rows"];
				var pivotvalues = storedParsedObject["values"];
				var richtextcontent = storedParsedObject["richtextcontent"];
				var colorThemeIndex = storedParsedObject["colorThemeIndex"];
				var reportcolortheme = storedParsedObject["reportcolortheme"];
				var customSolidGauge = storedParsedObject["customSolidGauge"];
				var chartSettings = storedParsedObject["chartSettings"];
				var percentileValue = storedParsedObject["percentileValue"];
				var predictionChartDetails = storedParsedObject["predictionArray"];
				var summarytablecolumns = storedParsedObject['summarytablecolumns'];
				var summarytablegroupby = storedParsedObject['summarytablegroupbys'];
				var summarytablevalues = storedParsedObject['summarytablevalues'];
				/*	if(chartType=='summarytable' && yAxisData.length==0 && summarytablecolumns!=undefined){					
						xAxisData=storedParsedObject['summarytablecolumns'];
						yAxisData=storedParsedObject['summarytablecolumns'];
					}*/
				var combinationchartslist = storedParsedObject["combinationchartslist"];
				var compareDateRangeObj = storedParsedObject["compareDateRangeObj"];
				var reference = storedParsedObject["reference"]
				var trendLine = storedParsedObject["trendLine"];
				var periodsvalue = storedParsedObject["periodsvalue"];
				var savedDrillDown = storedParsedObject['drillDownFilter'];
				var drillDown = [];
				drillDown = JSON.parse(sessionStorage.getItem("drilldown" + j));
				var drillDownFilter = [];
				if (drillDown != undefined && drillDown != null) {
					_.each(drillDown, function (value) {
						if (value['isDrillDownAll'] == false) {
							drillDownFilter.push(value);
						}
					});
				}
				if (savedDrillDown != undefined && drillDownFilter != undefined) {
					drillDownFilter = savedDrillDown.concat(drillDownFilter);
				}
				else if (savedDrillDown != undefined) {
					drillDownFilter = savedDrillDown;
				}

				var drillDownAllFilter = [];
				if (storedParsedObject['drillDownAllFilter'] != null && storedParsedObject['drillDownAllFilter'] != undefined && storedParsedObject['drillDownAllFilter'].length != 0) {
					drillDownAllFilter = storedParsedObject['drillDownAllFilter'];
				}
				_.each(ChartInfo.functions.getdrillfitlerArray(), function (filterdata, index) {
					if (filterdata != undefined && filterdata["isDrillDownAll"] == true) {
						drillDownAllFilter.push(filterdata);
					}
				});
				/*	    var summarytabledrilldownfilters =  sessionStorage.getItem('customhierarchy'+j);
						if($("#drilldownallchartsinstoryboard").is(":checked")){
							 var summaryTableFilter = ChartInfo.functions.getAllTempSummaryTableFilterValues();
							if(summaryTableFilter!=undefined && summaryTableFilter.length!=0){
								_.each(summaryTableFilter,function(filterdata,index){
									if(filterdata["drilldownAll"]==true){
										filterdata["breadcrump"] = undefined
										filterdata["saved"] = undefined
										drillDownAllFilter.push(filterdata);
									}
									else if(filterdata["isDrillDownAll"]==true){
										filterdata["breadcrump"] = undefined
										filterdata["saved"] = undefined
										drillDownAllFilter.push(filterdata);
									}
									else{
										filterdata["saved"] = undefined;
										drillDownFilter.push(filterdata);
									}
								});
							}
						}
						*/
				var xaxisDrilldownState = ChartInfo.functions.getXaxisDetails(j);

				if (xaxisDrilldownState != undefined && ((drillDownFilter != undefined && drillDownFilter.length != 0) || (drillDownAllFilter != undefined && drillDownAllFilter.length != 0))) {
					if ((chartType == "countrymap" || chartType == "indiamap") && (xaxisDrilldownState['columnName'] != xAxisData[0].columnName || xAxisData.length > 1)) {
						chartType = "pie";
					} else if ((chartType == "world" || chartType == "srilankamap" || chartType == "leaflet") &&
						(xaxisDrilldownState != xAxisData[0].columnName || xAxisData.length > 1)) {
						chartType = "bar";
					}
				}

				if (xaxisDrilldownState != undefined && xAxisData != undefined && xAxisData.length > 0 && ((drillDownFilter != undefined && drillDownFilter.length != 0) ||
					(drillDownAllFilter != undefined && drillDownAllFilter.length != 0))) {
					xAxisData[0].columnName = xaxisDrilldownState['columnName'];
					xAxisData[0].dataType = xaxisDrilldownState['dataType'];
					xAxisData[0].displayName = xaxisDrilldownState['displayname'];
				}

				var xaxisDrilldownState = ChartInfo.functions.getXaxisDetails(j);

				var getSummarytableAxisFieldsArray = ChartInfo.functions.getSummarytableAxisFieldsArray(j);
				if (chartType == "summarytable") {
					if (getSummarytableAxisFieldsArray != undefined && getSummarytableAxisFieldsArray.length > 0) {
						summarytablecolumns = getSummarytableAxisFieldsArray[getSummarytableAxisFieldsArray.length - 1].textcolumns;
						summarytablegroupby = getSummarytableAxisFieldsArray[getSummarytableAxisFieldsArray.length - 1].group;
					}
				}
				var datehistogramstate = ChartInfo.functions.getDateHistogramState(j);
				if (datehistogramstate == undefined) {
					datehistogramstate = storedParsedObject['datehistogramstate']
				}
				var summaryTableDateHistogramState = ChartInfo.functions.getSummaryTableDateHistogramState(j)
				var xaxisDrilldownState = ChartInfo.functions.getXaxisDetails(j);
				if (xaxisDrilldownState != undefined && xAxisData != undefined && xAxisData.length > 0) {
					if ((drillDownFilter != undefined && drillDownFilter.length != 0) || (drillDownAllFilter != undefined && drillDownAllFilter.length != 0)) {
						xAxisData[0].columnName = xaxisDrilldownState['columnName'];
						xAxisData[0].dataType = xaxisDrilldownState['dataType'];
						xAxisData[0].displayName = xaxisDrilldownState['displayname'];
						if (xAxisData[1] != undefined && xAxisData[1].xaxisidentity != undefined) {
							xAxisData = xAxisData[0];
						}
					}
				}
				var tempSummaryTableFilterValues = ChartInfo.functions.getTempSummaryTableFilterValues(j);
				var legendfilter = ChartInfo.functions.getLegendFilterobj(j);
				var legendfilterAll = ChartInfo.functions.getLegendFilterobjForAllCharts();
				var xaxisDataArray = [];
				var jsonObject = {};
				if (!Array.isArray(xAxisData)) {
					xaxisDataArray.push(xAxisData);
					jsonObject["xaxis"] = xaxisDataArray;
				} else {
					jsonObject["xaxis"] = xAxisData;
				}
				jsonObject["tabId"] = tabId;
				jsonObject["tabName"] = tabName;
				jsonObject["yaxis"] = yAxisData;
				jsonObject["zaxis"] = zAxisData;
				jsonObject["tooltip"] = tooltipData;
				jsonObject["legend"] = legend;
				jsonObject["chartType"] = chartType;
				jsonObject["chartnumber"] = j;
				jsonObject["reporttitle"] = chartTitle;
				jsonObject["colLimit"] = colLimit;
				jsonObject["orderType"] = orderType;
				jsonObject["sortType"] = sortType;
				jsonObject["sortingColumn"] = sortingColumn;
				jsonObject["sortingColumnAggregation"] = sortingColumnAggregation;
				jsonObject["rankingColumn"] = rankingColumn;
				jsonObject["rankingColumnAggregation"] = rankingColumnAggregation;
				jsonObject["xdatesortingEnabled"] = xdatesortingEnabled;
				jsonObject["dobyselectedcolumns"] = dobyselectedcolumns;
				jsonObject["dobyrowlimit"] = dobyrowlimit;
				jsonObject["metricdatetype"] = metricdatetype;
				jsonObject["metricdatefield"] = metricdatefield;
				jsonObject["metricdatefielddisplay"] = metricdatefielddisplay;
				jsonObject["metricprefix"] = metricprefix;
				jsonObject["metricsuffix"] = metricsuffix;
				jsonObject["metricfont"] = metricfont;
				jsonObject["solidgaugemin"] = solidgaugemin;
				jsonObject["solidgaugemax"] = solidgaugemax;
				jsonObject["pivotrowvalues"] = pivotrowvalues;
				jsonObject["pivotcolumnvalues"] = pivotcolumnvalues;
				jsonObject["pivotvalues"] = pivotvalues;
				jsonObject["richtextcontent"] = richtextcontent;
				jsonObject["colorThemeIndex"] = colorThemeIndex;
				jsonObject["reportcolortheme"] = reportcolortheme;
				jsonObject["chartSettings"] = chartSettings;
				jsonObject["percentileValue"] = percentileValue;
				jsonObject["customSolidGauge"] = customSolidGauge;
				jsonObject["predictionArray"] = predictionChartDetails;
				jsonObject['summarytablecolumns'] = summarytablecolumns;
				jsonObject['summarytablegroupbys'] = summarytablegroupby;
				jsonObject['summarytablevalues'] = summarytablevalues;
				jsonObject["combinationchartslist"] = combinationchartslist;
				jsonObject["compareDateRangeObj"] = compareDateRangeObj;
				jsonObject["reference"] = ChartInfo.functions.getreferenceLineValues(j);
				jsonObject["trendLine"] = trendLine;
				jsonObject["periodsvalue"] = periodsvalue;
				jsonObject['drillDownAllFilter'] = drillDownAllFilter;
				if (chartType != "summarytable") {
					jsonObject["drillDownFilter"] = drillDownFilter;
				}

				jsonObject['datehistogramstate'] = datehistogramstate;
				jsonObject['summaryTableDateHistogram'] = summaryTableDateHistogramState;
				jsonObject["legendfilter"] = legendfilter;
				jsonObject["summarytablehierarchyArray"] = sessionStorage.getItem('customhierarchy' + j) || [];
				jsonObject["legendfilterallchart"] = legendfilterAll;
				var summarytabledrilldownfilters = sessionStorage.getItem('customhierarchy' + j);
				if (summarytabledrilldownfilters != undefined && summarytabledrilldownfilters != null && summarytabledrilldownfilters != "undefined" && summarytabledrilldownfilters.length > 0) {
					if ($("#drilldownallchartsinstoryboard").is(":checked")) {
						var summaryTableFilter = ChartInfo.functions.getAllTempSummaryTableFilterValues();
						if (summaryTableFilter != undefined && summaryTableFilter.length != 0) {
							_.each(summaryTableFilter, function (filterdata, index) {
								if (filterdata["drilldownAll"] == true) {
									filterdata["breadcrump"] = undefined
									filterdata["saved"] = undefined
									drillDownAllFilter.push(filterdata);
								}
								else if (filterdata["isDrillDownAll"] == true) {
									filterdata["breadcrump"] = undefined
									filterdata["saved"] = undefined
									drillDownAllFilter.push(filterdata);
								}
								else {
									filterdata["saved"] = undefined;
									drillDownFilter.push(filterdata);
								}
							});
						}
					}
					jsonObject["summarytabledrilldownstates"] = summarytabledrilldownfilters;
					jsonObject["summarytabledrilldownname"] = ChartInfo.functions.getSummarytabledrilldownname();
					jsonObject["summarytabledrilldowndisplayname"] = ChartInfo.functions.getSummarytabledrilldownname();
					jsonObject["summarytabledrilldowndatatype"] = ChartInfo.functions.getSummarytabledrilldowndatatype();
					jsonObject['drillDownAllFilter'] = drillDownAllFilter;
				}
				if (getSummarytableAxisFieldsArray != undefined && getSummarytableAxisFieldsArray != null && getSummarytableAxisFieldsArray != "undefined" && getSummarytableAxisFieldsArray.length > 0) {
					jsonObject["getsummarytableaxisfieldsarray"] = ChartInfo.functions.getSummarytableAxisFieldsArray(j);
				}
				jsonObject["customseriescolors"] = ChartInfo.functions.getCustomSeriesColorsDetails(j);
				jsonObject['customfieldFilterObject'] = ChartInfo.functions.getCustomFieldsFilterObject(j);
				jsonObject["conditionalFormateData"] = ChartInfo.functions.getConditionalFormatData(j);
				if (!$("#drilldownallchartsinstoryboard").is(":checked")) {
					jsonObject['tempSummaryTableFilterValues'] = tempSummaryTableFilterValues;
				}

				axisDataArray.push(jsonObject);
			});
			var axisDataObject = {};
			axisDataObject["axis"] = axisDataArray;
			reportDataArray.push(axisDataObject);
			var detailsObject = {};
			detailsObject["details"] = reportdetails;
			reportDataArray.push(detailsObject);
			var filterArray = ChartInfo.functions.getFilters(false,"save");
			var filterObject;
			var that = this;
			$.each(this.state.features, function (index, featuresModule) {
				if (featuresModule.moduleName == 'story_board') {
					$.each(featuresModule.features, function (index, feature) {
						if (feature.featureName == 'viewreport_filters' && feature.allow == false && filterArray.length == 0) {
							if (that.viewreportCollection != undefined && that.viewreportCollection.getFilterObject != undefined) {
								filterArray = that.viewreportCollection.getFilterObject();
							}
						}
					});
				}
			});
			var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
			var parsedData = JSON.parse(renameMeasuresorDimensions);
			if (filterArray != undefined && parsedData != null && parsedData != undefined) {
				_.each(filterArray, function (obj) {
					if (parsedData[obj.columnname] != undefined) {
						obj['columndisplayname'] = parsedData[obj.columnname];
					}
				});
			}
			filterObject = { "filter": filterArray };
			reportDataArray.push(filterObject);

			var gridStackData = _.map($('.grid-stack .grid-stack-item.tileview'), function (el) {
				try {
					el = $(el);
					var node = el.data('_gridstack_node');
					return {
						id: el.attr('data-custom-id'),
						num: el.attr('data-num'),
						x: node.x,
						y: node.y,
						width: el.attr('data-gs-width'),
						height: node.height
					};
				} catch (e) {

				}
			});

			var gridStackObject = { "grid": gridStackData };
			reportDataArray.push(gridStackObject);

			var convertedValues = sessionStorage.getItem("convertedvalues");
			var convertedObject = { "convertedvalues": convertedValues };
			reportDataArray.push(convertedObject);

			var customFields = sessionStorage.getItem("customFields");
			var customFieldsObject = { "customFields": customFields };
			reportDataArray.push(customFieldsObject);

			var storyboardcolortheme = {};
			storyboardcolortheme['stroyboardcolortheme'] = sessionStorage.getItem("stroyboardcolortheme");
			reportDataArray.push(storyboardcolortheme);

			var customHierarchy = JSON.stringify(ChartInfo.functions.getcustomhierarchy());
			var customHierarchyObject = { "customHierarchy": customHierarchy }
			reportDataArray.push(customHierarchyObject);

			var customRange = JSON.stringify(ChartInfo.functions.getRange());
			var customRangeObject = { "customRange": customRange };
			reportDataArray.push(customRangeObject);

			var customMeasure = JSON.stringify(ChartInfo.functions.getCustomMeasureField());
			var customMeasureObject = { "customMeasure": customMeasure };
			reportDataArray.push(customMeasureObject);

			var customParameter = JSON.stringify(ChartInfo.functions.getCustomParameter());
			var customParameter = { "customParameter": customParameter };
			reportDataArray.push(customParameter);

			var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters();
			var customParameterfilterObject = { "customParameterFilters": customParameterFilterArray };
			reportDataArray.push(customParameterfilterObject);

			reportDataArray.push({ "customMeasureHierarchy": JSON.stringify(ChartInfo.functions.getMeasureHierarchy()) });

			var isExcludeNulls = ChartInfo.functions.getIsExcludeNulls();
			reportDataArray.push({ "isExcludeNulls": isExcludeNulls });

			var livedatasyncconfig = JSON.stringify(ChartInfo.functions.getliveDataconfig());
			reportDataArray.push({ "livedatasyncconfig": livedatasyncconfig });

			var timeZoneSettings = JSON.stringify(ChartInfo.functions.getListofTimeZoneSettings());
			reportDataArray.push({ "timeZoneSettings": timeZoneSettings });

			var auditMetaDataOptions = JSON.stringify(ChartInfo.functions.getAuditMetaDataOptions());
			reportDataArray.push({ "auditMetaDataOptions": auditMetaDataOptions });


			var isDrillDownAllenable = $("#drilldownallchartsinstoryboard").length>0?($("#drilldownallchartsinstoryboard").prop("checked")):false;
			reportDataArray.push({ "isDrillDownAllenable": isDrillDownAllenable });



			if (this.state.reportId == undefined || this.state.reportId === 0) {
				_.each($(".attributesmeasures"), function (measure, i) {
					$.each(reportDataArray, function (index, column) {
						if ($(measure).attr("data-name") == column['columnName']) {
							column['aggregation'] = $(measure).find('#dropdownaggregation').text().replace(/[^A-Z0-9]/ig, "");
							reportDataArray.splice(index, 1, column);
						}
					});
				});
				var finalColumns = viewreportCollection.getColumns();
				if (window.location.pathname.indexOf("importreport") > 0) {
					finalColumns = JSON.parse(finalColumns);
				}
				$.each(viewreportCollection.getMeasures(), function (i, measure) {
					$.each(finalColumns, function (index, column) {
						if (measure.columnName == column.columnName) {
							column.aggregation = measure.aggregation;
							//finalColumns.splice(index,1,column);
						}
					});
				});
				/*$.each(ChartInfo.functions.getPriorityColumns(), function(i,priority){
					$.each(finalColumns,function(index,column){
						if(priority.columnName==column['columnName']){
							column['priorityColumn']=priority.priorityColumn;
							//finalColumns.splice(index,1,column);
						}
					});
				});*/
				_.each(finalColumns, function (column, i) {
					var isPriorityColumn = false;
					_.each(ChartInfo.functions.getPriorityColumns(), function (priorityObj, j) {
						if (priorityObj.columnName == column.columnName) {
							isPriorityColumn = priorityObj.priorityColumn;
						}
					});
					column["priorityColumn"] = isPriorityColumn;
				});
				var usercustomScript = ChartInfo.functions.getUserCustomScript(this.state.reportId);
				reportDataArray.push({ "usercustomScript": usercustomScript });
				var custom_fields_script = { "custom_fields_script": JSON.stringify(customFields) };
				reportDataArray.push(custom_fields_script);
				var isFilterCollapsed = ($("body").hasClass("filters-hidden"));
				reportDataArray.push({ "isFilterCollapsed": isFilterCollapsed });
				var tileFilters = ChartInfo.functions.getAllTileLevelFilters();
				reportDataArray.push({ "tile_filters": tileFilters });
				var linkreportId = $(".navigatereporturl option:selected").val();
				this.saveReport(this.state.reportId, JSON.stringify(reportDataArray), JSON.stringify(finalColumns), linkreportId, this.exportSchemaId, customFieldsScript, customFiltersScript, drillThroughReports, smartInsightsObj);
			} else {
				var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
				if (viewreportCollection.getColumns() != undefined) {
					var reportnames = $.parseJSON(viewreportCollection.getColumns());
					if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
						var parsedData = JSON.parse(renameMeasuresorDimensions);
						_.each(reportnames, function (obj) {
							if (parsedData[obj.columnName] != undefined) {
								obj['columnDisplayName'] = parsedData[obj.columnName];
								obj['columnssrename'] = "yes";
							}
						});
					}
				} else {
					var reportnames = viewreportCollection.getColumns();
					if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
						var parsedData = JSON.parse(renameMeasuresorDimensions);
						_.each(reportnames, function (obj) {
							if (parsedData[obj.columnName] != undefined) {
								obj['columnDisplayName'] = parsedData[obj.columnName];
								obj['columnssrename'] = "yes";
							}
						});
					}
				}
				$.each(viewreportCollection.getMeasures(), function (i, measure) {
					$.each(reportnames, function (index, column) {
						if (measure.columnName == column['columnName']) {
							column['aggregation'] = measure.aggregation;
							//reportnames.splice(index,1,column);
						}
					});
				});
				_.each(reportnames, function (column, i) {
					var isPriorityColumn = (column.priorityColumn != undefined) ? column.priorityColumn : false;
					_.each(ChartInfo.functions.getPriorityColumns(), function (priorityObj, j) {
						if (priorityObj.columnName == column.columnName) {
							isPriorityColumn = priorityObj.priorityColumn;
						}
					});
					column["priorityColumn"] = isPriorityColumn;
				});
				var usercustomScript = ChartInfo.functions.getUserCustomScript(this.state.reportId);
				reportDataArray.push({ "usercustomScript": usercustomScript });
				var custom_fields_script = { "custom_fields_script": JSON.stringify(customFields) };
				reportDataArray.push(custom_fields_script);
				var linkreportId = $(".navigatereporturl option:selected").val();
				var isFilterCollapsed = ($("body").hasClass("filters-hidden"));
				reportDataArray.push({ "isFilterCollapsed": isFilterCollapsed });
				var tileFilters = ChartInfo.functions.getAllTileLevelFilters();
				reportDataArray.push({ "tile_filters": tileFilters });
				this.saveReport(this.state.reportId, JSON.stringify(reportDataArray), JSON.stringify(reportnames), linkreportId, this.exportSchemaId, customFieldsScript, customFiltersScript, drillThroughReports, smartInsightsObj);
			}
		}

		/*Navigate Update*/
		var getnavurlid = $('.navigatereporturl').val();
		if (getnavurlid != "") {
			$('.nextpage').removeClass('disabled');
			$('.nextpage').attr("href", "#viewreport/" + btoa(getnavurlid));
		} else {
			$('.nextpage').addClass('disabled');
		}
	}
	saveReport(reportId, reportdata, columnsData, linkreportId, exportSchemaId, customFieldsScript, customFiltersScript, drillThroughReports, smartInsightsObj) {
		var that = this;
		var entities = "";
		// var fragmentArray = Backbone.history.fragment.split("/");
		// if(fragmentArray[2] != undefined && fragmentArray[2] != ""){
		//    entities = atob(fragmentArray[2]);
		// }else{
		//    entities = fragmentArray[2];
		// }
		var datamodelId = this.state.datamodelId;
		var isSharedReport = false;
		if (window.location.pathname.indexOf("sharereport") > 0) {
			isSharedReport = true;
		}
		var isViewModel = false;
		// if(fragmentArray.length>2 && reportId != undefined){
		// 	datamodelId  = 0;
		// }if(fragmentArray.length==2 && reportId != undefined){
		// 	reportId  = reportId;
		// 	datamodelId  = 0;
		// }else{
		// 	datamodelId  = atob(fragmentArray[1]);
		// }
		// if(fragmentArray.length==2 && fragmentArray[0]=== "customviewreport"){
		// 	entities=sessionStorage.getItem("entities");
		// }
		// if(fragmentArray.length==2 && fragmentArray[0]=== "sharereport"){
		// 	isSharedReport = true;
		// }
		// if(fragmentArray.length==2 && fragmentArray[0]=== "viewmodel"){
		// 	isViewModel = true;
		// }
		//	var tableName = fragmentArray[3];
		var tableName = "";
		var modeldata = {};
		var delimiter = sessionStorage.getItem("delimiter");
		if (reportId != undefined) {
			modeldata["reporId"] = reportId;
		}
		modeldata["reportdata"] = JSON.stringify(reportdata);
		var convertedTypes = sessionStorage.getItem("convertedtypes");
		modeldata["columns"] = columnsData;
		modeldata["entities"] = entities;
		modeldata["tables"] = tableName;
		modeldata["datamodelId"] = datamodelId;
		modeldata["delimiter"] = delimiter;
		modeldata["linkreportId"] = linkreportId;
		modeldata["customFieldsScript"] = customFieldsScript;
		modeldata["customFiltersScript"] = customFiltersScript;
		modeldata["drillThroughReports"] = drillThroughReports;

		//	viewreportsavestoryboardmodel.set(modeldata);
		//	this.reset();
		//	this.add(viewreportsavestoryboardmodel);
		var isImportedReport = false;
		if (window.location.pathname.indexOf("importreport") > 0) {
			isImportedReport = true;
		}
		var storyboardMetaData={};
		storyboardMetaData["sharemanage"] ="false";
		var url = window.location.search;
		if (url.length > 0) {
			url = url.replace('?', "").split("&");
			for (var i = 0; i < url.length; i++) {
				var key = url[i].split("=")[0];
				if (key.indexOf("sharemanage") != -1 && url[i].split("=")[1]!=undefined && url[i].split("=")[1] == "true") {
					storyboardMetaData["sharemanage"] ="true";
				}
			}
		}
		var requestbody = new FormData();
		requestbody.set("reportId", reportId);
		requestbody.set("reportdata", encodeURIComponent(reportdata));
		requestbody.set("columns", columnsData);
		requestbody.set("entities", entities);
		requestbody.set("datamodelId", (exportSchemaId != undefined) ? exportSchemaId : datamodelId);
		requestbody.set("tables", tableName);
		requestbody.set("convertedTypes", convertedTypes);
		requestbody.set("delimiter", "");
		requestbody.set("linkreportId", linkreportId != undefined && linkreportId != null ? linkreportId : "");
		requestbody.set("isImportedReport", isImportedReport);
		requestbody.set("customFieldsScript", "");
		requestbody.set("customFiltersScript", "");
		requestbody.set("drillThroughReports ", drillThroughReports);
		requestbody.set("smartInsightsData ", JSON.stringify(smartInsightsObj));
		requestbody.set("isSharedReport ", isSharedReport);
		requestbody.set("storyboardMetaData", JSON.stringify(storyboardMetaData));

		let requestHeaders = {
			method: "post",
			headers: new Headers({
				"Content-Type": "application/json"
			})
		};
		SendRequest({
			url: "savestoryboard",
			body: requestbody,
			sucessFunction: (props) => {
				$(".spinner").hide();
				console.log(props);
				if (props.reportId != 0) {
					if (props.success == true) {
						$('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
						$('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
						$('.birdmessagebuttons').removeClass('show');
						$('.birdmessagebuttons').addClass('hide');
						$('.birdmessage-center').addClass("message-top-in");
						$('.details').removeClass('show');
						$('.details').addClass('hide');
						$('.birdmessage h2').empty().append('Success');
						$('.birdmessage-info').empty().text(getMessage('BirdSuccess01'));
						sessionStorage.setItem("changesdone", 0);
					}
					// var reportdata = JSON.parse(model.get("reportdata"));
					// var details = reportdata[1]["details"];
					// this.broker.trigger("savestoryboardchanges", model.get("reportId"),details["name"], details["folder"], details["desc"],details["maxage"],details["maxageunits"],details["maxpurgeindex"]);
					if (reportId == undefined || reportId == "") {
						setTimeout(function () {
							var href = "/viewreport";
							if (props != undefined && props.reportId != undefined) {
								$('.birdmessage-container .close').click();
								href = "/" + window.localStorage.getItem("appName") + "/welcome/viewreport/" + btoa(props.reportId);
								//window.open(href, '_self')
								that.props.history.push(href);
								window.location.reload();
							}
						}, 2000);
					}
					// $("#savestoryboard").removeClass("in");
					this.props.close();
					var that = this;
					//	setTimeout(function(){that.destroyview();}, 1500);
				} else {
					$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
					$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
					$('.birdmessagebuttons').removeClass('show');
					$('.birdmessagebuttons').addClass('hide');
					$('.birdmessage-center').addClass("message-top-in");
					$('.details').removeClass('hide');
					$('.birdmessage h2').empty().append('Error');
					$('.birdmessage-info').empty().text(props.error);
					$('.details').addClass('show');
				}
				$(".spinner").hide();
			},
			rejectFunction: (props) => {
				var response = props;
				$(".spinner").hide();
				$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
				$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('hide');
				$('.birdmessage h2').empty().append('Error : ');
				$('.birdmessage h2').append('<span class="errorcode">');
				$('.birdmessage .errorcode').empty().append(response.errorCode);
				$('.birdmessage-info').empty().text(response.error);
				$('.details').addClass('show').text("Details");
				$('.messagedetails xmp').empty().append(response.errorTrace)
			},
			headers: requestHeaders
		});
	}
}