import React, { useState, useEffect } from "react";
import SendRequest from "../SendRequest";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from '../js/helpers/utils/chartinfo';
const $ = window.$;
const closepopup = () => {
    $('.pivotdataviewwrap').remove();
    if (document.getElementById("datatablepopupmodal") != null) {
        $('#datatablepopupmodal').remove();
    }
}
const updateData = (e, props) => {
    const aliasesMap = {};
    const { indexName, entityId, formVisibleColumns, religionCastData } = props.editData;
    const otherOptionsCols = religionCastData ? religionCastData.columns : [];
    formVisibleColumns.map((val, index) => {
        Object.keys(val).map((key) => {
            let obj = val[key];
            let dataval = obj["data"];
            dataval.map(val2 => {
                let { column, displayName } = val2;
                aliasesMap[displayName] = column
            })
        })
    })
    const { fetchData } = props
    let columnTypes = {};
    let { data, row, columns, uniqueColumn, allColumns } = props;
    allColumns.map(val => columnTypes[val.name] = val.type)
    Object.keys(data).map(key => {
        if (key == uniqueColumn) {
            return
        }
        let val;
        if(otherOptionsCols.indexOf(key)>=0){
            val= $('.edit-data-' + key).val();
        }else if ($('form').find('input[name="' + data[key] + '"][data-key="' + key + '"][is-customfield="false"]').length > 0) {
            val = $('form').find('input[name="' + data[key] + '"][data-key="' + key + '"][is-customfield="false"]').val()
        } else if ($('form').find('textarea[name="' + data[key] + '"][data-key="' + key + '"][is-customfield="false"]').length > 0) {
            val = $('form').find('textarea[name="' + data[key] + '"][data-key="' + key + '"][is-customfield="false"]').val()
        }
        if ($('form').find('input[data-key="' + key + '"][is-customfield="true"]').length > 0) {
            delete data[key];
        } else if ($('form').find('textarea[data-key="' + key + '"][is-customfield="true"]').length > 0) {
            delete data[key];
        }

        if (aliasesMap[key]) {
            delete data[key];
            key = aliasesMap[key]
        }

        if (val != undefined && columnTypes[key] == "number") {
            data[key] = parseFloat(val);
        } else if (val != undefined && columnTypes[key] == "date") {
            data[key] = new Date(val).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }) + " " + new Date(val).toLocaleTimeString('en-CA', { hour: '2-digit', hour12: false, minute: '2-digit', second: '2-digit' });
        } else if (val == undefined && data[key] != undefined && columnTypes[key] == "date") {
            data[key] = new Date(data[key]).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }) + " " + new Date(data[key]).toLocaleTimeString('en-CA', { hour: '2-digit', hour12: false, minute: '2-digit', second: '2-digit' });
        } else if (val != undefined) {
            data[key] = val;
        }
    })
    var form_data = new FormData();
    form_data.append("index_name", indexName);
    form_data.append("data", JSON.stringify(data));
    SendRequest({
        url: "insertToDatabase",
        queryString: "",
        body: form_data,
        sucessFunction: (response) => {
            if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('hide');
                $('.birdmessage h2').empty().append('Error : ');
                $('.birdmessage h2').append('<span class="errorcode">');
                $('.birdmessage-info').empty().text(response.error);
                $('.details').addClass('show');
                $('.messagedetails xmp').empty().append(response.error)
                setTimeout(function () {
                    $('.birderror').remove();
                }, 3000);
            } else {
                $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Success');
                $('.birdmessage-info').empty().text(getMessage('BirdSuccess70'));
                setTimeout(function () {
                    $('.birdmessage-container .close').click();
                }, 3000);
                closepopup();
                var filterDataObj = ChartInfo.functions.getEditFilterArray();
                fetchData(filterDataObj);
                props.history.push(`/${window.localStorage.getItem("appName")}/welcome/edit_data/` + btoa(entityId))
                $(".spinner").hide();
            }
        },
        rejectFunction: (error) => { $(".spinner").hide(); },
        headers: {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        }
    });
}
const EditFormData = (props) => {
    const { data, columns, allColumns } = props;
    const row_data = data
    const { formDisableColumns, formVisibleColumns, religionCastData } = props.editData;
    const otherOptionsCols = religionCastData ? religionCastData.columns : [];
    useEffect(() => {
        otherOptionsCols.map((col) => {
            $('.edit-data-' + col).append('<option value="">Select</option>');
            let data = religionCastData[col];
            data.map((i) => {
                $('.edit-data-' + col).append('<option value=' + i + '>' + i + '</option>');
            })
            if(row_data[col]!=null){
                $(".edit-data-"+ col+" option[value='"+row_data[col]+"']").attr("selected","selected")
            }
        });

    }, []);
    let columnTypes = {};
    let column_names = [];
    allColumns.map(val => columnTypes[val.name] = val.type)
    allColumns.map(val => { if (val.name && column_names.indexOf(val.name) == -1) column_names.push(val.name) })
    return (
        <div className='pivotdataviewwrap custombrandform'>
            <div className="pivotdataview">
                {/* <div className="ball-pulse"><div></div><div></div><div></div></div> */}
                <div className="pivotdataviewclose" onClick={closepopup} id="pivotdataviewdatapop">
                    <i className='fa fa-times'></i>
                </div>
                <div className="boc-carousel-area-header-title pivottile" style={{ color: '#2737a3' }}>{`Update Data`}</div>
                <div className="container-fluid marg-bot-10 nopadding">
                    <div className="row">
                        <div id="pivotdataview">
                            <div className="col-xs-12" id="PEformGroup">
                                {formVisibleColumns.map((val, index) => {
                                    return (Object.keys(val).map((key) => {
                                        const obj = val[key];
                                        const { displaycolumn, sequence, icon, isEditable, data } = obj;
                                        return (index + 1 == sequence ?
                                            <div className="panel panel-transparent" id={`panel${key}`} key={index}>
                                                <div className="panel-heading" data-parent="#PEformGroup" data-toggle="collapse" href={`#${key.replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "")}Collapse`} aria-expanded="false">
                                                    <h4 className="panel-title">
                                                        <i className={icon} aria-hidden="false"></i>
                                                        {displaycolumn && column_names.indexOf(displaycolumn) != -1 ? <React.Fragment><span className="titletag">{key}</span><span className="votername">{row_data[displaycolumn]}</span></React.Fragment> : key}
                                                        {isEditable ? <i className="fa fa-edit" aria-hidden="false"></i> : <React.Fragment />}
                                                        <i className="fa fa-angle-down pull-right" aria-hidden="false"></i>
                                                    </h4>
                                                </div>
                                                <div id={`${key.replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "")}Collapse`} className="panel-collapse collapse" aria-expanded="false">
                                                    <div className="panel-body">
                                                        <div className="col-xs-12 nopadding">
                                                            <form className="form-horizontal col-xs-12 submitform">
                                                                {data.map((val2, index2) => {
                                                                    const { column, displayName, formDisplayName, editable, datatype, isCustomField } = val2;
                                                                    return (column_names.indexOf(displayName) != -1 ? <div key={index}>
                                                                        <div className="form-group form-group-sm form-group">
                                                                            <label className="col-sm-3 col-xs-12 control-label">{formDisplayName ? formDisplayName : displayName}</label>
                                                                            <div className="col-sm-6 col-xs-12 ">
                                                                                {datatype == 'select' ? <select className={`sort-attribute-name nomargin edit-data-${displayName}`}></select> : datatype == 'textarea' ? <textarea type={"text"} name={"" + (row_data[displayName])} data-key={displayName} defaultValue={row_data[displayName]} disabled={editable == 'enable' ? false : true} is-customfield={isCustomField ? "true" : "false"}></textarea> :
                                                                                    <input type={datatype} name={"" + (row_data[displayName]) + ""} data-key={displayName} defaultValue={row_data[displayName]} disabled={editable == 'enable' ? false : true} is-customfield={isCustomField ? "true" : "false"} />}
                                                                            </div>
                                                                        </div>
                                                                    </div> : <React.Fragment />)
                                                                })}
                                                            </form>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : <React.Fragment />)
                                    })
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col-xs-12 bottomOptions">
                            <button type="button" className="btn btn-bird cancelform" onClick={closepopup} ><i className="fa fa-times marg-righ-5"></i>Cancel</button>
                            <button type="button" className="btn btn-bird updateuser" onClick={e => updateData(e, props)} ><i className="fa fa-check marg-righ-5"></i>Submit</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default EditFormData;