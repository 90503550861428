import React from "react";
import ReactDOM from "react-dom";
import { lang } from "../js/helpers/utils/languages/language";
import * as ChartInfo from '../js/helpers/utils/chartinfo';
const $=window.$
export class SearchData extends React.Component {
    // It's necessary to implement getValue
    getValue() {
      return ReactDOM.findDOMNode(this).value;
    }
  
    // It's necessary to implement setValue
    setValue(value) {
      ReactDOM.findDOMNode(this).value = value;
    }
    searchkeyup=(e)=>{
      if (e.which == 13) {
        //for enter key
        this.handleSearch(e)
      }
    }
    handleSearch=(e)=>{
      $(".spinner").show();
        let filter_data = ChartInfo.functions.getEditFilterArray();
        this.props.fetchData(filter_data,this.getValue())
    }
    render() {
      return (
        <React.Fragment>
			<input className="form-control searchedittable" type="text" onKeyUp={this.searchkeyup} defaultValue={this.props.searchprops.defaultValue} placeholder={ lang["admin.audit.search"] }  />
      <button className="btn btn-warning" onClick={this.handleSearch}><i className="fa fa-search fa-flip-horizontal marg-righ-5"></i>{lang["workspace.search"]}</button>
      </React.Fragment>
      );
    }
  }