import React from "react";
import PubSub from 'pubsub-js';
import * as htmlToImage from 'html-to-image';
import * as html2canvas from 'html2canvas';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import SendRequest from '../SendRequest';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
import * as ChartUtil from "../js/helpers/utils/chartutil";
import jsPDF from 'jspdf';
const $ = window.$;

export default class SingleSavedReportTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayFooter: this.props.displayFooter,
            title: this.props.title,
            chartid: this.props.chartid,
            current_user_role: this.props.current_user_role,
            isfavorite: this.props.isfavorite,
            isPublished: this.props.isPublished,
            userRole: this.props.userRole,
            isSharedReport: this.props.isSharedReport,
            isEmbeddedReport: this.props.isEmbeddedReport,
            allfeatures: this.props.allfeatures,
            isScheduleReport: this.props.isScheduleReport,
            isViewOnlyStatus: this.props.isViewOnlyStatus,
            schemaType: this.props.schemaType,
            isPurgedReport: this.props.isPurgedReport,
            reportId: this.props.reportId,
            schemaTypeFlag: this.props.schemaTypeFlag,
            isDirect: this.props.isDirect,
            isStoredProcedure: this.props.isStoredProcedure,
            isMicrosoftEdge: this.props.isMicrosoftEdge,
            isMultifact: this.props.isMultifact,
            tabid: this.props.tabid,
            isNLPReport:this.props.isNLPReport,
            isVersioned:this.props.isVersioned,
            versionname:this.props.versionname,
            version:"",
            currentUserRoleName:this.props.currentUserRoleName?this.props.currentUserRoleName:this.props.userRole
        };
        if(this.state.isVersioned){
            this.state.version="hide";
        }
        this.filterMenuToggle = this.filterMenuToggle.bind(this);
        this.isPrivate = window.location.pathname.split("/")[3]; // Backbone.history.location.pathname.split("/")[2];
    }
    downloadFunction =(e)=> {
        //var node = document.getElementById('chart0');
        //var node = document.getElementsByTagName("body")[0];
        var cTarget = e.currentTarget;
        var node = document.getElementsByClassName("fullscreensample")[0];
        var that = this;
        $(".spinner").show();
        var options = {
            height:node.offsetHeight,
            canvasHeight: node.offsetHeight,
            backgroundColor:"#ffffff"
        };
      htmlToImage.toPng(node,options)
        .then(function (dataUrl) {
          // var img = new Image();
          // img.src = dataUrl;
          // document.body.appendChild(img);
          if (cTarget != undefined && cTarget.id != undefined && cTarget.id != 'singledownload_myreportpdf') {
          var a = document.createElement("a");
          a.id = "downloadimage";
          a.href = dataUrl;
          a.target = '_blank';
          a.download = "MyReport.png";
          a.click();
          let msg = lang["reports.downlod_PNG_audit_message"];
          ChartUtil.auditMessage(that.props.reportId, msg);
        }else{
        //     var doc = new jsPDF();
        //   doc.addImage(dataUrl, 'PNG', 15, 40, 180, 160);
        //   doc.save("MyReport.pdf");
          html2canvas(node)
          .then((canvas) => {
            const pdf = new jsPDF({
              orientation: 'portrait',
            });
            const imgProps= pdf.getImageProperties(dataUrl);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('MyReport.pdf');
          });
          let msg = lang["reports.download_PDF_audit_message"];
          ChartUtil.auditMessage(that.props.reportId, msg);
        }
        $(".spinner").hide();
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
          $(".spinner").hide();
        });
      }
      SingleEmbedLogout(){
        let requestHeaders1 = {
            method: "get",
            headers: new Headers({
              "Content-Type": "application/json"
            })
          };
          SendRequest({
            url: "logoutbird",
            queryString: "",
            sucessFunction: (props) => {
              sessionStorage.removeItem("accessToken");
              sessionStorage.removeItem("reservedwordslist");
              window.localStorage.clear();
              window.location.replace("/");
            },
            rejectFunction: () => { },
            headers: requestHeaders1,
          })
      }
    cancelselectedcolumns() {
        $('.doby-offcanvas-columnlist').toggleClass("show");
    }
    componentDidMount() {
        var that = this;
        $('.singlereportpivotcolumnmodal').click(() => { PubSub.publish("showPivotModal", this.state.chartid) });
        $('.maximizesetting').click((e) => {
            that.props.editTitle(e);
            setTimeout($('.modal-backdrop').remove(), 100);
        });
        $('.quickinfotoggle').click((e) => { that.props.quickinfotoggle(e); });
        $('.summarymodal').click((e) => { PubSub.publish("showSummaryModal", e) });
        $('.singlereport_summarymodal').click((e) => { PubSub.publish("showSummaryModal", e) });
        $('.refreshsinglecharts').click((e) => { that.props.redrawchart(e) });
        if(window.self !== window.top){
            $("#singlesaveddropdownview").addClass("hide");
            $(".quickinfotoggle").addClass("hide");
            $(".tab-filters-container").hide();
            $(".single-filter-toggle").hide();
        }
    }
    loadPivotRows(e) {
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		PubSub.publish("loadPivotRows", currentChartNumber);
	}
	loadPrevious(e) {
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		PubSub.publish("showprevious", currentChartNumber);
	}
	loadNext(e) {
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		PubSub.publish("shownext", currentChartNumber);
	}
    filterMenuToggle() {
        $("body").toggleClass("filters-hidden");
    }
    render() {
        var singlesavedreporttitlemode = "";
        var isWorkspaceLiveEnabled = ChartInfo.functions.getIsWorkspaceLiveEnabled();
      var loggedInUserRole = ChartInfo.functions.getUserRole()!=null && ChartInfo.functions.getUserRole()!=undefined && ChartInfo.functions.getUserRole()[0]!=undefined ? ChartInfo.functions.getUserRole()[0]:"";
        this.state.allfeatures.map((featuresModule) => {
            if (featuresModule.moduleName == 'my_report') {
                if (this.state.isSharedReport == false) {
                    if (this.state.isScheduleReport === true) {

                    } else {
                        featuresModule.features.map((feature) => {
                            if (feature.featureName == 'viewreport_maximize_title_edit' && feature.allow == true) {
                                singlesavedreporttitlemode = "maximizesetting titleeditmode";
                            }
                        })
                    }
                } else {
                    featuresModule.features.map((feature) => {
                        if (feature.featureName == 'viewreport_maximize_save_shared_report' && feature.allow == true) {
                            featuresModule.features.map((feature) => {
                                if (feature.featureName == 'viewreport_maximize_title_edit' && feature.allow == true) {
                                    singlesavedreporttitlemode = "maximizesetting titleeditmode";
                                }
                            })
                        }
                    })
                }
            }
        })
        return (
            <>
                <section className="boc-viewreport-section newsinglereport">
                    <div className="boc-home boc-dark-background">
                        {/* <!-- viewsinglesavedreportview.js --> */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12 leftpane pull-left">
                                    {/* <!-- Gridster Start --> */}
                                    <section className="boc-snapshot-container">
                                        <div className="container-fluid">
                                            <div className="row grid-stack" id="charts" style={{}}>
                                                <div className="singlereportview fullscreenmain nopadding maximized maxanim1" data-num={this.state.chartid} tab-id={this.state.tabid} tab-name="">
                                                    <div className="container-fluid nopadding maxanim2">
                                                        <div className="maxleftpanehidden maxanim3">
                                                            <div aria-label="..." role="group" className="btn-group pull-right dropdown">
                                                                <button className="btn btn-transparent maxanim" type="button"><i className="fa fa-expand"></i></button>
                                                                <button className="btn btn-transparent" type="button"><i className="fa fa-trash-o"></i></button>
                                                            </div>
                                                        </div>
                                                        <div className="row-same-height maxanim5">
                                                            <div className="col-xs-1 col-xs-height nopadding maxleftpane maxanim6">
                                                                <div id="custommeasurediv" className="custommeasuredivall"></div>
                                                            </div>
                                                            {/* <!-- /.Left inner items End --> */}
                                                            <div className="col-xs-12 col-xs-height nopadding maxanim7" data-num={this.state.chartid} tab-id={this.state.tabid} tab-name="">
                                                                <div className="tab-filters-container">
                                                                    <div className="gridload"><div></div><div></div><div></div><div></div></div>
                                                                    <div className={`${'filteritem dropdown-toggle '}`} data-direction="left" data-toggle="dropdown" aria-expanded="true" title={lang["reports.filters"]}><i className="fa fa-filter"></i> {lang["reports.filters"]}</div>
                                                                    {
                                                                        (this.state.isEmbeddedReport != true) ? (
                                                                            <>
                                                                                {this.state.allfeatures.map((featuresModule) => {
                                                                                    if (featuresModule.moduleName == 'story_board') {
                                                                                        if (this.state.isSharedReport == false) {
                                                                                            if (this.state.isScheduleReport === true) {
                                                                                                return featuresModule.features.map((feature) => {
                                                                                                    if ((this.state.isDirect == 0 || this.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_embed_report_and_email' && feature.allow == true) {
                                                                                                        return (<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
                                                                                                            <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlefilterdropdown" id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addfilter"]} onClick={this.props.addfilter}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addfilter"]}</a></li>
                                                                                                            <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlecustomparameterfilterdropdown" id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" onClick={this.props.addCustomParamerFilter} title={lang["reports.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addparameter"]}</a></li>
                                                                                                        </ul>);
                                                                                                    }
                                                                                                })
                                                                                            } else {
                                                                                                return featuresModule.features.map((feature) => {
                                                                                                    if ((this.state.isDirect == 0 || this.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_filters' && feature.allow == true) {
                                                                                                        return (<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
                                                                                                            <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlefilterdropdown" id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addfilter"]} onClick={this.props.addfilter}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addfilter"]}</a></li>
                                                                                                            <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlecustomparameterfilterdropdown" id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" onClick={this.props.addCustomParamerFilter} title={lang["reports.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addparameter"]}</a></li>
                                                                                                        </ul>);
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        } else if (this.state.isSharedReport === true) {
                                                                                            if(loggedInUserRole == "ROLE_Viewer"){
                                                                                            return (<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
                                                                                            <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlefilterdropdown" id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addfilter"]} onClick={this.props.addfilter}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addfilter"]}</a></li>
                                                                                            <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlecustomparameterfilterdropdown" id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" onClick={this.props.addCustomParamerFilter} title={lang["reports.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addparameter"]}</a></li>
                                                                                        </ul>)
                                                                                           } else{
                                                                                            return featuresModule.features.map((feature) => {
                                                                                                if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
                                                                                                    return featuresModule.features.map((feature) => {
                                                                                                        if ((this.state.isDirect == 0 || this.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_filters' && feature.allow == true && this.state.isViewOnlyStatus == false) {
                                                                                                            return (<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
                                                                                                                <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlefilterdropdown" id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addfilter"]} onClick={this.props.addfilter}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addfilter"]}</a></li>
                                                                                                                <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlecustomparameterfilterdropdown" id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" onClick={this.props.addCustomParamerFilter} title={lang["reports.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addparameter"]}</a></li>
                                                                                                            </ul>);
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            })}
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            </>
                                                                        ) : (this.state.isEmbeddedReport == true) ? (
                                                                            <>
                                                                                {
                                                                                    this.state.allfeatures.map((featuresModule) => {
                                                                                        if (featuresModule.moduleName == 'my_report') {
                                                                                            featuresModule.features.map((feature) => {
                                                                                                if ((this.state.isDirect == 0 || this.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_embeded_filters' && feature.allow == true && this.state.isPurgedReport == false) {
                                                                                                    return (<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
                                                                                                        <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlefilterdropdown" id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addfilter"]} onClick={this.props.addfilter}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addfilter"]}</a></li>
                                                                                                        <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlecustomparameterfilterdropdown" id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" onClick={this.props.addCustomParamerFilter} title={lang["reports.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addparameter"]}</a></li>
                                                                                                    </ul>);
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                }
                                                                                {
                                                                                    (this.isPrivate != "private") ? (
                                                                                        <ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
                                                                                            <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlefilterdropdown" id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addfilter"]} onClick={this.props.addfilter}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addfilter"]}</a></li>
                                                                                            <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlecustomparameterfilterdropdown" id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" onClick={this.props.addCustomParamerFilter} title={lang["reports.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addparameter"]}</a></li>
                                                                                        </ul>
                                                                                    ) : null
                                                                                }
                                                                            </>
                                                                        ) : null
                                                                    }
                                                                    <div className="panel-group rightfilter filteritem-container" id="filteraccordion"><div className="instant-filter-tags addInstanceFilterData"></div><div className="instant-filter-tags addURLInstanceFilterData"></div></div>
                                                                    <div className="filter-menu-toggle"><span onClick={this.filterMenuToggle} title={lang["reports.showhidefilters"]}><i className="fa fa-angle-up"></i></span></div>
                                                                </div>
                                                                <div className="container-fluid scrollset maxanim8">
                                                                    {/* <!-- Quick Info Container --> */}
                                                                    <div id="viewreportinfoview"></div>
                                                                    {/* <!-- /Quick Info Container --> */}
                                                                    <div className="row maxanim9" data-num={this.state.chartid} tab-id="0" tab-name="">
                                                                        <div className="alert alert-success clonesinglereportsuccess" style={{ display: "none" }} role="alert">{lang["reports.clonemsg"]}</div>
                                                                        <div className="alert alert-danger clonesinglereporterror" style={{ display: "none" }} role="alert">{lang["reports.errormsg"]}</div>
                                                                        <div className="alert alert-success saredsinglereportsavedsuccess" style={{ display: "none" }} role="alert">{lang["reports.savemsg"]}</div>
                                                                        <div className="alert alert-danger saredsinglereportsavederror" style={{ display: "none" }} role="alert">{lang["reports.saverror"]}</div>
                                                                        <div className="alert alert-danger singlereportalreadyexisterror" style={{ display: "none" }} role="alert">{lang["reports.namexists"]}</div>
                                                                        <div className="col-lg-9 col-md-9 col-sm-8 col-xs-8 flex-baseline-div marg-top-5">
                                                                            {/* <!-- Active Visualization --> */}
                                                                            <div className="btn-group bgcommon">
                                                                                {
                                                                                    (this.state.isEmbeddedReport != true && this.state.isScheduleReport != true) ? (
                                                                                        <>
                                                                                            {
                                                                                                (this.state.isViewOnlyStatus == false) ? (
                                                                                                    <>
                                                                                                        <button type="button" className="btn btn-bird-group btn-sm" id="sharedcharts" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-bar-chart"></i></button>
                                                                                                        <button type="button" className={`btn btn-bird-group tooltip-bottom dropdown-toggle dropdown`} id="sharedchartmore" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title={lang["reports.more"]}>
                                                                                                            <span className="caret"></span>
                                                                                                            <span className="sr-only">{lang["reports.toggle"]}</span>
                                                                                                        </button>
                                                                                                    </>
                                                                                                ) : ""
                                                                                            }
                                                                                        </>
                                                                                    ) : null
                                                                                }
                                                                                <ul className="dropdown-menu">
                                                                                    {/* <!-- Active Visualization -->	 */}
                                                                                    {
                                                                                        this.state.allfeatures.map((featuresModule) => {
                                                                                            if (featuresModule.moduleName == 'my_report') {
                                                                                                if (this.state.isSharedReport == false) {
                                                                                                    if (this.state.isScheduleReport === true) {

                                                                                                    } else {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <li><a href="javascript:void(0)" className="boc-maxscreen-left-item chart1" onClick={this.props.viewChart} id="showchart" data-num={this.state.chartid} tab-id="0" tab-name=""><i className="fa fa-bar-chart marg-righ-10"></i>{lang["reports.chart"]}</a></li>
                                                                                                                {
                                                                                                                    featuresModule.features.map((feature, index) => {
                                                                                                                        if (feature.featureName == 'viewreport_maximize_data_table' && feature.allow == true) {
                                                                                                                            return <li key={index}><a href="javascript:void(0)" className="boc-maxscreen-left-item dobygrid1" id="dobygrid1datatable" onClick={this.props.viewdatatable} data-num={this.state.chartid} tab-id="0" tab-name="" start="0" end="500"><i className="fa fa-table marg-righ-10"></i>{lang["reports.datatable"]}<span className="maxscreen-lft-filtertitle hide">{lang["reports.data"]}</span></a></li>
                                                                                                                        }
                                                                                                                    })
                                                                                                                }
                                                                                                                {
                                                                                                                    featuresModule.features.map((feature, index) => {
                                                                                                                        if (feature.featureName == 'viewreport_maximize_summary_table' && feature.allow == true) {
                                                                                                                            return <li key={index}><a href="javascript:void(0)" className="boc-maxscreen-left-item singlereport_summary_table" id="singlereportsummarytablesview" data-num={this.state.chartid} tab-id="0" tab-name="" start="0" end="500" onClick={this.props.viewsummary}><i className="fa fa-th-large marg-righ-10"></i>{lang["reports.summarytable"]}<span className="maxscreen-lft-filtertitle hide">{lang["reports.summarytable"]}</span></a></li>
                                                                                                                        }
                                                                                                                    })
                                                                                                                }
                                                                                                                {
                                                                                                                    featuresModule.features.map((feature, index) => {
                                                                                                                        if (feature.featureName == 'viewreport_maximize_pivot' && feature.allow == true) {
                                                                                                                            return <li key={index}><a href="javascript:void(0)" className="boc-maxscreen-left-item pivotgrid1" id="pivotgrid1" data-num={this.state.chartid} tab-id="0" tab-name="" start="0" end="500" onClick={this.props.viewpivottable}><i className="fa fa-pivot-table-16 marg-righ-10"></i>{lang["reports.pivotable"]}<span className="maxscreen-lft-filtertitle hide">{lang["reports.pivot"]}</span></a></li>
                                                                                                                        }
                                                                                                                    })
                                                                                                                }
                                                                                                                {
                                                                                                                    featuresModule.features.map((feature) => {
                                                                                                                        if (feature.featureName == 'viewreport_maximize_prediction' && feature.allow == true) {
                                                                                                                            // <!-- <li><a href="javascript:void(0)" class="boc-maxscreen-left-item singlereport_prediction modalleft" id="singlereportpredictionsview" data-num= "<%=chartid%>" data-direction="left" data-toggle="modal" data-target="#predictmodal"><i class="fa fa-predict-icon-16 marg-righ-10"></i>Prediction<span class="maxscreen-lft-filtertitle hide">Prediction</span></a></li> -->
                                                                                                                        }
                                                                                                                    })
                                                                                                                }
                                                                                                            </>
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                  //  featuresModule.features.map((feature) => {
                                                                                                    //    if (feature.featureName == 'viewreport_maximize_save_shared_report' && feature.allow == true) {
                                                                                                            if (this.state.isScheduleReport === true) {

                                                                                                            } else {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <li><a href="javascript:void(0)" className="boc-maxscreen-left-item chart1" onClick={this.props.viewChart} id="showchart" data-num={this.state.chartid} tab-id="0" tab-name=""><i className="fa fa-bar-chart marg-righ-10"></i>{lang["reports.chart"]}</a></li>
                                                                                                                        {featuresModule.features.map((feature,index) => {
                                                                                                                            if (feature.featureName == 'viewreport_maximize_data_table' && feature.allow == true) {
                                                                                                                                return <li key={index}><a href="javascript:void(0)" className="boc-maxscreen-left-item dobygrid1" onClick={this.props.viewdatatable} id="datapivottable" data-num={this.state.chartid} tab-id="0" tab-name="" start="0" end="500"><i className="fa fa-table marg-righ-10"></i>{lang["reports.datatable"]}<span className="maxscreen-lft-filtertitle hide">{lang["reports.data"]}</span></a></li>
                                                                                                                            }
                                                                                                                        })}
                                                                                                                        {featuresModule.features.map((feature) => {
                                                                                                                            if (feature.featureName == 'viewreport_maximize_summary_table' && feature.allow == true) {
                                                                                                                                return <li><a href="javascript:void(0)" className="boc-maxscreen-left-item singlereport_summary_table" onClick={this.props.viewsummary} id="summarypivottable" data-num={this.state.chartid} tab-id="0" tab-name="" start="0" end="500"><i className="fa fa-th-large marg-righ-10"></i>{lang["reports.summarytable"]}<span className="maxscreen-lft-filtertitle hide">{lang["reports.summarytable"]}</span></a></li>
                                                                                                                            }
                                                                                                                        })}
                                                                                                                        {featuresModule.features.map((feature) => {
                                                                                                                            if (feature.featureName == 'viewreport_maximize_pivot' && feature.allow == true) {
                                                                                                                                return <li><a href="javascript:void(0)" className="boc-maxscreen-left-item pivotgrid1" id="pivotgrid1table" onClick={this.props.viewpivottable} data-num={this.state.chartid} tab-id="0" tab-name="" start="0" end="500"><i className="fa fa-pivot-table-16 marg-righ-10"></i>{lang["reports.pivotable"]}<span className="maxscreen-lft-filtertitle hide">{lang["reports.pivot"]}</span></a></li>
                                                                                                                            }
                                                                                                                        })}
                                                                                                                        {featuresModule.features.map((feature) => {
                                                                                                                            if (feature.featureName == 'viewreport_maximize_prediction' && feature.allow == true) {
                                                                                                                                // <!-- <li><a href="javascript:void(0)" class="boc-maxscreen-left-item singlereport_prediction modalleft" id="predictionpivot" data-num={this.state.chartid} data-direction="left" data-toggle="modal" data-target="#predictmodal"><i class="fa fa-predict-icon-16 marg-righ-10"></i>Prediction<span class="maxscreen-lft-filtertitle hide">Prediction</span></a></li> -->
                                                                                                                            }
                                                                                                                        })}
                                                                                                                    </>
                                                                                                                )
                                                                                                            }
                                                                                                        //}
                                                                                                 //   })
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    {/* <!--\ Active Visualization -->	 */}
                                                                                </ul>
                                                                            </div>
                                                                            {/* <!-- \Active Visualization --> */}
                                                                            <div className="marg-left-5 hidden-xs"></div>
                                                                        </div>
                                                                        {
                                                                            (this.state.isEmbeddedReport != true) ? (
                                                                                <>
                                                                                    {
                                                                                        (this.state.isScheduleReport === true) ? (
                                                                                            <>
                                                                                                <div className="col-lg-3 col-md-3 col-sm-4 col-xs-4 width-54-mob pull-right">
                                                                                                    <div className="col-xs-12 boc-padding-11 reporttoolbar" data-num={this.state.chartid}>
                                                                                                        <div className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 reporttitletoolbar${this.state.chartid}`}>
                                                                                                            <div className="row controlbar-main">
                                                                                                                <div className="col-lg-7 col-xs-7 flex-baseline-div">
                                                                                                                    <h2 className="boc-viewreport-title" data-num={this.state.chartid} tab-id="0" tab-name="">{this.state.title}</h2>
                                                                                                                    <div className="datacount maxscreendatacount hide" data-num={this.state.chartid} tab-id="0" tab-name="">
                                                                                                                        <span className="publishinfo"></span><span className="filterdatacount"></span>
                                                                                                                        <span className="lastexecutedtimestamp"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="pull-right marg-righ-10">
                                                                                                    <div aria-label="..." role="group" className="btn-group pull-right dropdown bgcommon">
                                                                                                        <button className="btn btn-bird-group refreshsinglecharts hide" title={lang["reports.refresh"]} type="button"><i className="fa fa-refresh"></i></button>
                                                                                                        {(this.state.isDirect == 0 || this.state.isStoredProcedure == 0) ? (
                                                                                                            // <!-- <button type="button" class="btn btn-bird-group boc-maxscreen-left-item singlerportfilters modalleft" data-direction="left" data-toggle="modal" data-target="#innerfiltermodal" title="Filters"><i class="fa fa-filter"></i><span class="maxscreen-lft-filtertitle hide">Filters</span></button> -->
                                                                                                            <></>
                                                                                                        ) : null}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (
                                                                                                <div className="col-lg-3 col-md-3 col-sm-4 col-xs-4 width-54-mob pull-right">
                                                                                                    <div className="col-xs-12 boc-padding-11 reporttoolbar" data-num={this.state.chartid} tab-id="0" tab-name="">
                                                                                                        <div className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 reporttitletoolbar${this.state.chartid}`}>
                                                                                                            <div className="row controlbar-main">
                                                                                                                <div className="col-lg-7 col-xs-7 flex-baseline-div">
                                                                                                                    {/* <!-- Saved Single Report Title Edit --> */}

                                                                                                                    {/* <!--\Saved Single Report Title Edit --> */}
                                                                                                                    <h2 className={`boc-viewreport-title ${singlesavedreporttitlemode}`} data-num={this.state.chartid} tab-id="0" tab-name="">{this.state.title}</h2>
                                                                                                                    <div className="datacount maxscreendatacount hide" data-num={this.state.chartid} tab-id="0" tab-name="">
                                                                                                                        <span className="publishinfo"></span><span className="filterdatacount"></span>
                                                                                                                        <span className="lastexecutedtimestamp"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="pull-right marg-left-auto">
                                                                                                                    <div aria-label="..." role="group" className="btn-group pull-right dropdown bgcommon">
                                                                                                                        {
                                                                                                                            this.state.allfeatures.map((featuresModule) => {
                                                                                                                                return featuresModule.features.map((feature) => {
                                                                                                                                    if ((feature.featureName == 'viewreport_maximize_quick_information' && feature.allow == true) && this.state.isPurgedReport == false) {
                                                                                                                                        return <button type="button" className="btn btn-bird-group quickinfotoggle" id="" title={lang["reports.quickinfo"]}><i className="fa fa-info-circle"></i></button>
                                                                                                                                        // <!-- <span class="quickinfotoggle" title="Quick Information"><i class="fa fa-chevron-down"></i></span> -->
                                                                                                                                    }
                                                                                                                                })
                                                                                                                            })
                                                                                                                        }
                                                                                                                        {
                                                                                                                            (this.state.isViewOnlyStatus == false) ? (
                                                                                                                                <>
                                                                                                                                    {/* <!-- Attributes, Filters & Refresh Button --> */}
                                                                                                                                    {
                                                                                                                                        this.state.allfeatures.map((featuresModule) => {
                                                                                                                                            if (featuresModule.moduleName == 'my_report') {
                                                                                                                                                if (this.state.isSharedReport == false) {
                                                                                                                                                    if (this.state.isScheduleReport === true) {
                                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                                            if (feature.featureName = "viewreport_maximize_filters" && feature.allow == true) {
                                                                                                                                                                return (
                                                                                                                                                                    // <!-- <button type="button" class="btn btn-bird-group boc-maxscreen-left-item singlerportfilters modalleft" data-direction="left" data-toggle="modal" data-target="#innerfiltermodal" title="Filters"><i class="fa fa-filter"></i><span class="maxscreen-lft-filtertitle">Filters</span></button> -->
                                                                                                                                                                    <span style={{ float: "left", position: "relative" }}>
                                                                                                                                                                        <button type="button" className="dropdown-toggle btn btn-bird-group" data-direction="right" data-toggle="dropdown" aria-expanded="true"><i className="fa fa-filter"></i></button>
                                                                                                                                                                        <ul className="dropdown-menu dropdown-menu-right">
                                                                                                                                                                            <li><a href="javascript:void(0)" className="modalfilter singlefilterdropdown" id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addfilter"]} onClick={this.props.addfilter}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addfilter"]}</a></li>
                                                                                                                                                                            <li><a href="javascript:void(0)" className="modalfilter singlecustomparameterfilterdropdown" id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" onClick={this.props.addCustomParamerFilter} title={lang["reports.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addparameter"]}</a></li>
                                                                                                                                                                        </ul>
                                                                                                                                                                    </span>
                                                                                                                                                                );
                                                                                                                                                            }
                                                                                                                                                        });
                                                                                                                                                    } else {
                                                                                                                                                        return (
                                                                                                                                                            <>
                                                                                                                                                                {
                                                                                                                                                                    featuresModule.features.map((feature) => {
                                                                                                                                                                        if ((this.state.isDirect == 0 || this.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_nlp_search' && feature.allow == true && (this.state.isMultifact == 'false' || this.state.isMultifact == false)&&this.state.isNLPReport) {
                                                                                                                                                                            return <button type="button" className="btn btn-bird-group tooltip-bottom intelligentsearch hide" id="intelligentsearchview" title={lang["reports.intelligentsearch"]} onClick={this.props.intelligentSearch}><i className="fa fa-search"></i></button>
                                                                                                                                                                        }
                                                                                                                                                                        if (feature.featureName == 'viewreport_maximize_attributes_settings' && feature.allow == true) {
                                                                                                                                                                            return <button type="button" className="btn btn-bird-group boc-maxscreen-left-item singlereportattributes modalleft" id="singlereportattributesview" data-direction="left" data-toggle="modal" data-target="#attributesmodal" title={lang["reports.attributes"]} onClick={this.props.togglevisualizationsettings}><i className="fa fa-cogs"></i><span className="maxscreen-lft-filtertitle hide">{lang["reports.attributes"]}</span></button>
                                                                                                                                                                        }
                                                                                                                                                                    })
                                                                                                                                                                }
                                                                                                                                                                {
                                                                                                                                                                    featuresModule.features.map((feature) => {
                                                                                                                                                                        return (
                                                                                                                                                                            <>
                                                                                                                                                                                {
                                                                                                                                                                                    ((this.state.isDirect == 0 || this.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_filters' && feature.allow == true) ? (
                                                                                                                                                                                        <span style={{ float: "left", position: "relative" }}>
                                                                                                                                                                                            <button type="button" className="dropdown-toggle btn btn-bird-group single-filter-toggle" data-direction="right" data-toggle="dropdown" aria-expanded="true"><i className="fa fa-filter"></i></button>
                                                                                                                                                                                            <ul className="dropdown-menu dropdown-menu-right">
                                                                                                                                                                                                <li><a href="javascript:void(0)" className="modalfilter singlefilterdropdown" id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addfilter"]} onClick={this.props.addfilter}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addfilter"]}</a></li>
                                                                                                                                                                                                <li><a href="javascript:void(0)" className="modalfilter singlecustomparameterfilterdropdown" id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" onClick={this.props.addCustomParamerFilter} title={lang["reports.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addparameter"]}</a></li>
                                                                                                                                                                                            </ul>
                                                                                                                                                                                        </span>) : null
                                                                                                                                                                                }
                                                                                                                                                                                {/* <!-- This button should be displayed only in saved storyboard --> */}
                                                                                                                                                                                {((this.state.isDirect == 0) && feature.featureName == 'viewreport_maximize_updatedata' && feature.allow == true) ? (
                                                                                                                                                                                    <></>
                                                                                                                                                                                    // <!-- button type="button" class="btn btn-bird-group tooltip-bottom updateesdata" id="updatedatasavedsinglereport" title="Update Data"><i class="fa fa-pencil"></i></button> -->
                                                                                                                                                                                ) : null}
                                                                                                                                                                            </>
                                                                                                                                                                        );
                                                                                                                                                                    })
                                                                                                                                                                }
                                                                                                                                                            </>
                                                                                                                                                        );
                                                                                                                                                    }
                                                                                                                                                } else {
                                                                                                                                                   return featuresModule.features.map((feature) => {
                                                                                                                                                        if (feature.featureName == 'viewreport_maximize_save_shared_report' && feature.allow == true) {
                                                                                                                                                            if (this.state.isScheduleReport === true) {
                                                                                                                                                               return featuresModule.features.map((feature) => {
                                                                                                                                                                    if (feature.featureName == 'viewreport_embed_report_and_email' && feature.allow == true) {
                                                                                                                                                                        return (
                                                                                                                                                                            <span style={{ float: "left", position: "relative" }}>
                                                                                                                                                                                <button type="button" className="dropdown-toggle btn btn-bird-group" data-direction="right" data-toggle="dropdown" aria-expanded="true"><i className="fa fa-filter"></i></button>
                                                                                                                                                                                <ul className="dropdown-menu dropdown-menu-right">
                                                                                                                                                                                    <li><a href="javascript:void(0)" className="modalfilter singlefilterdropdown" id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addfilter"]}><i className="fa fa-plus marg-righ-10" onClick={this.props.addfilter}></i>{lang["reports.addfilter"]}</a></li>
                                                                                                                                                                                    <li><a href="javascript:void(0)" className="modalfilter singlecustomparameterfilterdropdown" id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" onClick={this.props.addCustomParamerFilter} title={lang["reports.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addparameter"]}</a></li>
                                                                                                                                                                                </ul>
                                                                                                                                                                            </span>
                                                                                                                                                                        );
                                                                                                                                                                    }
                                                                                                                                                                })
                                                                                                                                                            } else {
                                                                                                                                                                return (
                                                                                                                                                                    <>
                                                                                                                                                                        {
                                                                                                                                                                            featuresModule.features.map((feature) => {
                                                                                                                                                                                if ((this.state.isDirect == 0 || this.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_nlp_search' && feature.allow == true && (this.state.isMultifact == 'false' || this.state.isMultifact == false)&&this.state.isNLPReport) {
                                                                                                                                                                                    return <button type="button" className="btn btn-bird-group tooltip-bottom intelligentsearch hide" id="intelligentsearchview" title={lang["reports.intelligentsearch"]} onClick={this.props.intelligentSearch}><i className="fa fa-search"></i></button>
                                                                                                                                                                                }
                                                                                                                                                                                if (feature.featureName == 'viewreport_maximize_attributes_settings' && feature.allow == true) {
                                                                                                                                                                                    return <button type="button" className="btn btn-bird-group boc-maxscreen-left-item singlereportattributes modalleft" onClick={this.props.togglevisualizationsettings} id="sharedsinglereportattributes" data-direction="left" data-toggle="modal" data-target="#attributesmodal" title={lang["reports.attributes"]}><i className="fa fa-cogs"></i><span className="maxscreen-lft-filtertitle hide">{lang["reports.attributes"]}</span></button>
                                                                                                                                                                                }
                                                                                                                                                                            })
                                                                                                                                                                        }
                                                                                                                                                                        {
                                                                                                                                                                            featuresModule.features.map((feature) => {
                                                                                                                                                                                if ((this.state.isDirect == 0 || this.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_filters' && feature.allow == true && this.state.isViewOnlyStatus == false) {
                                                                                                                                                                                    // <!-- <button type="button" className="btn btn-bird-group boc-maxscreen-left-item singlerportfilters modalleft" id="sharedsinglerportfilters" data-direction="left" data-toggle="modal" data-target="#innerfiltermodal" title="Filters"><i className="fa fa-filter"></i><span className="maxscreen-lft-filtertitle hide">Filters</span></button> -->
                                                                                                                                                                                    return <span style={{ float: "left", position: "relative" }}>
                                                                                                                                                                                        <button type="button" className="dropdown-toggle btn btn-bird-group single-filter-toggle" data-direction="right" data-toggle="dropdown" aria-expanded="true"><i className="fa fa-filter"></i></button>
                                                                                                                                                                                        <ul className="dropdown-menu dropdown-menu-right">
                                                                                                                                                                                            <li><a href="javascript:void(0)" className="modalfilter singlefilterdropdown" id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addfilter"]} onClick={this.props.addfilter}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addfilter"]}</a></li>
                                                                                                                                                                                            <li><a href="javascript:void(0)" className="modalfilter singlecustomparameterfilterdropdown" id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" onClick={this.props.addCustomParamerFilter} title={lang["reports.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addparameter"]}</a></li>
                                                                                                                                                                                        </ul>
                                                                                                                                                                                    </span>
                                                                                                                                                                                }
                                                                                                                                                                            })
                                                                                                                                                                        }
                                                                                                                                                                    </>
                                                                                                                                                                );
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    })
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                            ) : null
                                                                                                                        }
                                                                                                                        <button className="btn btn-bird-group refreshsinglecharts" id="refreshsinglechartsview" title={lang["reports.refresh"]} type="button"><i className="fa fa-refresh"></i></button>
                                                                                                                        {/* <!-- /Attributes, Filters & Refresh Button --> */}
                                                                                                                        {
                                                                                                                            this.state.allfeatures.map((featuresModule) => {
                                                                                                                                if (featuresModule.moduleName == 'my_report') {
                                                                                                                                    if (this.state.isSharedReport == false) {
                                                                                                                                        var bool = false;
                                                                                                                                        var general = false;
                                                                                                                                        var customize = false;
                                                                                                                                        var collaborate = false;
                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                            if ((feature.featureName == 'viewreport_maximize_query_info' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_save_report' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_clone_report' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_colortheme' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_title_edit' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_favourites' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_share_report' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_embed_report_email' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_schedule_reports' && feature.allow == true)) {
                                                                                                                                                bool = true
                                                                                                                                            }
                                                                                                                                        });
                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                            if ((feature.featureName == 'viewreport_maximize_query_info' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_save_report' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_clone_report' && feature.allow == true)) {
                                                                                                                                                general = true
                                                                                                                                            }
                                                                                                                                        })
                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                            if ((feature.featureName == 'viewreport_maximize_colortheme' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_title_edit' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_favourites' && feature.allow == true)) {
                                                                                                                                                customize = true
                                                                                                                                            }
                                                                                                                                        })
                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                            if ((feature.featureName == 'viewreport_maximize_share_report' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_embed_report_email' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_schedule_reports' && feature.allow == true)) {
                                                                                                                                                collaborate = true
                                                                                                                                            }
                                                                                                                                        })
                                                                                                                                        if (bool == true) {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    {(this.state.isPurgedReport == false) ? (
                                                                                                                                                        <button type="button" className={`btn btn-bird-group tooltip-bottom dropdown-toggle dropdown ${this.state.version}`} id="singlesaveddropdownview" title={lang["reports.more"]} data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></button>
                                                                                                                                                    ) : null}
                                                                                                                                                    <ul class="dropdown-menu dropdown-menu-right megadropdown">
                                                                                                                                                        {
                                                                                                                                                            (this.state.isScheduleReport != true) ? (
                                                                                                                                                                <>
                                                                                                                                                                    {(general == true) ? (<li className="dropdown-header">{lang["reports.general"]}</li>) : null}
                                                                                                                                                                    {featuresModule.features.map((feature) => {
                                                                                                                                                                        if (feature.featureName == 'viewreport_maximize_save_report' && feature.allow == true  && this.state.isVersioned==false) {
                                                                                                                                                                            return <li><a href="javascript:void(0)" className="updatesinglereport" id="singleupdatesinglereport" title={lang["reports.savereport"]} type="button" data-direction="top" data-toggle="modal" data-target="#savereportgraph" onClick={this.props.savereport}><i className="fa fa-floppy-o marg-righ-10"></i>{lang["reports.savereport"]}</a></li>
                                                                                                                                                                        }
                                                                                                                                                                    })}
                                                                                                                                                                    {/* {featuresModule.features.map((feature) => {
                                                                                                                                                                        if (feature.featureName == 'viewreport_maximize_clone_report' && feature.allow == true) {
                                                                                                                                                                            return <li><a href="javascript:void(0)" className="clonesinglereport" id="singleclonesinglereport" title="Clone MyReport" aria-expanded="false" onClick={this.props.clone}><i className="fa fa-files-o marg-righ-10"></i>Clone MyReport</a></li>
                                                                                                                                                                        }
                                                                                                                                                                    })} */}
                                                                                                                                                                      {featuresModule.features.map((feature) => {
                                                                                                                                                                        if (feature.featureName == 'viewreport_maximize_clone_report' && feature.allow == true && this.state.isVersioned==false) {
                                                                                                                                                                            return <li><a href="javascript:void(0)" className="versionsinglereport" id="singleversionsinglereport" title={lang["reports.versionmyreport"]} aria-expanded="false" onClick={this.props.saveversionreport}><i className="fa fa-files-o marg-righ-10"></i>{lang["reports.saveversion"]}</a></li>
                                                                                                                                                                        }
                                                                                                                                                                    })}
                                                                                                                                                                    {featuresModule.features.map((feature) => {
                                                                                                                                                                        if (this.state.schemaType != "pdf" && this.state.schemaType != "csv" && this.state.schemaType != "xls" && this.state.schemaType != "xlsx" && this.state.schemaType != "json" && this.state.schemaType != "log" && this.state.schemaType != "fuse" && this.state.schemaType != "customstoryboard") {
                                                                                                                                                                            if (feature.featureName == 'live_report' && feature.allow == true) {
                                                                                                                                                                                return <li><a href="javascript:void(0)" className="livestoryboard" id="singlelivestoryboard" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.livereport"]} onClick={(e) => { this.props.livereport(e); $('body>.modal-backdrop').remove(); }}><i className="fa fa-clock-o fa-flip-horizontal marg-righ-10"></i>{lang["reports.livereport"]}</a></li>
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    })}
                                                                                                                                                                    {featuresModule.features.map((feature) => {
                                                                                                                                                                        if (feature.featureName == 'viewreport_maximize_drilldown_settings' && feature.allow == true) {
                                                                                                                                                                            return <li><a href="javascript:void(0)" className="drilldownsettings" title={lang["reports.drilldownsettings"]} onClick={this.props.drilldownsetting}><i className="fa fa-cog marg-righ-10"></i>{lang["reports.drilldownsettings"]}</a></li>
                                                                                                                                                                        }
                                                                                                                                                                    })}
                                                                                                                                                                    {featuresModule.features.map((feature) => {
                                                                                                                                                                        if (feature.featureName == 'audit_meta_data_maximize' && feature.allow == true) {
                                                                                                                                                                            return <li><a href="javascript:void(0)" className="auditmetadata_singlereport" onClick={this.props.auditmetadata} id="singlechangemetadata" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.audit"]}><span className="fa-stack marg-righ-10" aria-hidden="true"><i className="fa fa-desktop fa-stack-2x"></i><i className="fa fa-search fa-stack-1x"></i></span>{lang["reports.audit"]}</a></li>
                                                                                                                                                                        }
                                                                                                                                                                    })}
                                                                                                                                                                    
                                                                                                                                                                    {
                                                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                                                            if (feature.featureName == 'viewreport_maximize_publish' && this.state.currentUserRoleName=="ROLE_Power_User" && feature.allow == true && !isWorkspaceLiveEnabled) {
                                                                                                                                                                                if ( ChartInfo.functions.getisPublishedOfDb() == "true") {
                                                                                                                                                                                  return   <li><a href="javascript:void(0)" className="setsinglereportpublish" onClick={this.props.publishStoryboard} id="publishstoryboard" title={lang["reports.publish"]} type="button"><i className="fa fa-send fa-publish marg-righ-10 publishsb published"></i>{lang["reports.publish"]}</a></li>
                                                                                                                                                                                } else {
                                                                                                                                                                                    return    <li><a href="javascript:void(0)" className="setsinglereportpublish" onClick={this.props.publishStoryboard} id="publishstoryboard" title={lang["reports.publish"]}><i className="fa fa-send fa-publish marg-righ-10 publishsb"></i>{lang["reports.publish"]}</a></li>
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                            })
                                                                                                                                                                        }
                                                                                                                                                            
                                                                                                                                                                    {(customize == true) ? (<><li className="divider"></li><li className="dropdown-header">{lang["reports.customize"]}</li></>) : null}
                                                                                                                                                                    {
                                                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                                                            if (feature.featureName == 'viewreport_maximize_colortheme' && feature.allow == true) {
                                                                                                                                                                                return <li><a href="javascript:void(0)" className="reporttheme" id="singlereporttheme" title={lang["reports.reporttheme"]} onClick={this.props.reportcolortheme} data-num={this.state.chartid} tab-id="0" tab-name="" data-toggle="modal" data-target="#tiletheme"><i className="fa fa-paint-brush marg-righ-10 fa-violet"></i>{lang["reports.reporttheme"]}</a></li>
                                                                                                                                                                            }
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                                                            if (feature.featureName == 'viewreport_maximize_title_edit' && feature.allow == true) {
                                                                                                                                                                                return <li><a href="javascript:void(0)" className="maximizesetting" id="singlemaximizesetting" title={lang["reports.edittitle"]} type="button" data-direction="top" data-toggle="modal" data-target="#infomodal"><i className="fa fa-pencil marg-righ-10"></i>{lang["reports.edittitle"]}</a></li>
                                                                                                                                                                            }
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                                                            if (feature.featureName == 'viewreport_maximize_favourites' && feature.allow == true) {
                                                                                                                                                                                if (this.state.isfavorite === "Y") {
                                                                                                                                                                                    return <li><a href="javascript:void(0)" className="setsinglereportfavourite" onClick={this.props.setAsFavourite} id="updateasfavourite" title={lang["reports.favorite"]} type="button"><i className="fa fa-star fa-yellow marg-righ-10"></i>{lang["reports.favorite"]}</a></li>
                                                                                                                                                                                } else {
                                                                                                                                                                                    return <li><a href="javascript:void(0)" className="setsinglereportfavourite" onClick={this.props.setAsFavourite} id="setstoryboardasfavourite" title={lang["reports.favorite"]}><i className="fa fa-star marg-righ-10"></i>{lang["reports.favorite"]}</a></li>
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        (collaborate == true) ? (<><li class="divider"></li><li class="dropdown-header">{lang["reports.collaborate"]}</li></>) : null
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                                                            if (feature.featureName == 'viewreport_maximize_share_report' && feature.allow == true) {
                                                                                                                                                                                return <li><a href="javascript:void(0)" className="sharesinglereport1" onClick={this.props.sharesinglereport} id="singlesharesinglereport1" title={lang["reports.sharereport"]} type="button" data-direction="top" data-toggle="modal" data-target="#sharereportgraph"><i className="fa fa-users marg-righ-10"></i>{lang["reports.sharereport"]}</a></li>
                                                                                                                                                                            }
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                                                            if (feature.featureName == 'viewreport_maximize_embed_report_email' && feature.allow == true) {
                                                                                                                                                                                return <li><a href="javascript:void(0)" className="" id="embedsinglereportmodal" onClick={this.props.viewEmbedreport} title={lang["reports.embedreport"]}><i className="fa fa-code marg-righ-10"></i>{lang["reports.embedreport"]}</a></li>
                                                                                                                                                                            }
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                                                            return (
                                                                                                                                                                                <>
                                                                                                                                                                                    {
                                                                                                                                                                                        (feature.featureName == 'viewreport_maximize_schedule_reports' && feature.allow == true) ? (
                                                                                                                                                                                            <li><a href="javascript:void(0)" className="" id="schedulesinglereport" onClick={this.props.scheduleReport} title={lang["reports.schedulereport"]} type="button"><i className="fa fa-clock-o marg-righ-10"></i>{lang["reports.schedulereport"]}</a></li>
                                                                                                                                                                                        ) : null
                                                                                                                                                                                    }
                                                                                                                                                                                    {
                                                                                                                                                                                        (this.state.isDirect == 0 && feature.featureName == 'viewreport_maximize_alerts' && feature.allow == true) ? (
                                                                                                                                                                                            <li><a href="javascript:void(0)" className="singlereport_alerts" onClick={this.props.singlereportAlerts} id="single_singlereportalerts" title={lang["reports.alerts"]} data-num={this.state.chartid} tab-id="0" tab-name=""><i className="fa fa-exclamation-triangle marg-righ-10"></i>{lang["reports.alerts"]}</a></li>
                                                                                                                                                                                        ) : null
                                                                                                                                                                                    }
                                                                                                                                                                                </>
                                                                                                                                                                            );
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                                                            if (feature.featureName == 'download_myreport' && feature.allow == true && !this.state.isMicrosoftEdge) {
                                                                                                                                                                                return <li><a href="javascript:void(0)" className="download_myreport" id="singledownload_myreport" title={lang["reports.downloadpng"]} data-num={this.state.chartid} tab-id="0" tab-name="" onClick={this.downloadFunction}><i className="fa fa-download fa-flip-horizontal marg-righ-10"></i>{lang["reports.downloadpng"]}</a></li>
                                                                                                                                                                            }
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                     {
                                                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                                                            if (feature.featureName == 'download_myreport' && feature.allow == true && !this.state.isMicrosoftEdge) {
                                                                                                                                                                                return <li><a href="javascript:void(0)" className="download_myreport" id="singledownload_myreportpdf" title={lang["reports.downloadpdf"]} data-num={this.state.chartid} tab-id="0" tab-name="" onClick={this.downloadFunction}><i className="fa fa-download fa-flip-horizontal marg-righ-10"></i>{lang["reports.downloadpdf"]}</a></li>
                                                                                                                                                                            }
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        featuresModule.features.map((feature) => {
                                                                                                                                                                                if (feature.featureName == 'viewreport_maximize_export_report' && feature.allow == true) {
                                                                                                                                                                                        return <li><a href="javascript:void(0)" onClick={this.props.exportmyreport} className="export_myreport" id="exportsavedsinglereport" title={lang["reports.export"]} data-num={this.state.chartid} tab-id="0" tab-name=""><i className="fa fa-external-link marg-righ-10" aria-hidden="true"></i>{lang["reports.export"]}</a></li>
                                                                                                                                                                                }
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                </>
                                                                                                                                                            ) : null
                                                                                                                                                        }
                                                                                                                                                    </ul>
                                                                                                                                                </>
                                                                                                                                            );
                                                                                                                                        }
                                                                                                                                    } else {
                                                                                                                                       return featuresModule.features.map((feature) => {
                                                                                                                                            if (feature.featureName == 'viewreport_maximize_save_shared_report' && feature.allow == true) {
                                                                                                                                                var bool1 = false;
                                                                                                                                                var general1 = false;
                                                                                                                                                var customize1 = false;
                                                                                                                                                featuresModule.features.map((feature) => {
                                                                                                                                                    if ((feature.featureName == 'viewreport_maximize_save_report' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_title_edit' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_query_info' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_colortheme' && feature.allow == true)) {
                                                                                                                                                       if(!this.state.isViewOnlyStatus){
                                                                                                                                                           bool1 = true
                                                                                                                                                       }
                                                                                                                                                    }
                                                                                                                                                })
                                                                                                                                                featuresModule.features.map((feature) => {
                                                                                                                                                    if ((feature.featureName == 'viewreport_maximize_save_report' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_query_info' && feature.allow == true)) {
                                                                                                                                                        general1 = true
                                                                                                                                                    }
                                                                                                                                                })
                                                                                                                                                featuresModule.features.map((feature) => {
                                                                                                                                                    if ((feature.featureName == 'viewreport_maximize_title_edit' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_colortheme' && feature.allow == true)) {
                                                                                                                                                        customize1 = true
                                                                                                                                                    }
                                                                                                                                                })
                                                                                                                                                if (bool1 == true) {
                                                                                                                                                    return (
                                                                                                                                                        <>
                                                                                                                                                            <button type="button" className={`btn btn-bird-group tooltip-bottom dropdown-toggle dropdown ${this.state.version}`} title={lang["reports.more"]} data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></button>
                                                                                                                                                            <ul class="dropdown-menu dropdown-menu-right megadropdown">
                                                                                                                                                                {(general1 == true) ? (<li class="dropdown-header">{lang["reports.general"]}</li>) : null}
                                                                                                                                                                {featuresModule.features.map((feature) => {
                                                                                                                                                                    if (feature.featureName == 'viewreport_maximize_save_report' && feature.allow == true) {
                                                                                                                                                                        return <li><a href="javascript:void(0)" className="updatesinglereport" id="updatesinglereportsin" title={lang["reports.save"]} type="button" data-direction="top" data-toggle="modal" data-target="#savereportgraph" onClick={this.props.savereport}><i className="fa fa-floppy-o marg-righ-10"></i>{lang["reports.savereport"]}</a></li>
                                                                                                                                                                    }
                                                                                                                                                                })}
                                                                                                                                                                {(customize1 == true) ? (<li class="dropdown-header">Customize</li>) : null}
                                                                                                                                                                {
                                                                                                                                                                    featuresModule.features.map((feature) => {
                                                                                                                                                                        if (feature.featureName == 'viewreport_maximize_colortheme' && feature.allow == true) {
                                                                                                                                                                            return <li><a href="javascript:void(0)" type="button" className="reporttheme" id="reportthemesin" title={lang["reports.reporttheme"]} onClick={this.props.reportcolortheme} data-num={this.state.chartid} tab-id="0" tab-name="" data-toggle="modal" data-target="#tiletheme"><i className="fa fa-paint-brush marg-righ-10 fa-violet"></i>{lang["reports.reporttheme"]}</a></li>
                                                                                                                                                                        }
                                                                                                                                                                    })
                                                                                                                                                                }
                                                                                                                                                                {
                                                                                                                                                                    featuresModule.features.map((feature) => {
                                                                                                                                                                        if (feature.featureName == 'viewreport_maximize_title_edit' && feature.allow == true) {
                                                                                                                                                                            return <li><a href="javascript:void(0)" className="maximizesetting" id="maximizesettingsin" title={lang["reports.edittitle"]} type="button" data-direction="top" data-toggle="modal" data-target="#infomodal"><i className="fa fa-cog marg-righ-10"></i>{lang["reports.edittitle"]}</a></li>
                                                                                                                                                                        }
                                                                                                                                                                    })
                                                                                                                                                                }
                                                                                                                                                            </ul>
                                                                                                                                                        </>
                                                                                                                                                    );
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="quickinfotogglecontainer hide">
                                                                                                                    {
                                                                                                                        this.state.allfeatures.map((featuresModule) => {
                                                                                                                            featuresModule.features.map((feature) => {
                                                                                                                                if ((feature.featureName == 'viewreport_maximize_quick_information' && feature.allow == true) && this.state.isPurgedReport == false) {
                                                                                                                                    return <span className="quickinfotoggle" title={lang["reports.quickinfo"]}><i className="fa fa-chevron-down"></i></span>
                                                                                                                                }
                                                                                                                            })
                                                                                                                        })
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="text-right marg-top-5">
                                                                                                        <div className="opacitydiv">
                                                                                                            <div id="dobypagination" className={`paginationdiv${this.state.chartid}`} data-num={this.state.chartid} tab-id="0" tab-name="" style={{ display: "block" }}>
                                                                                                                <div className="btn-group pull-right marg-left-10">
                                                                                                                    {
                                                                                                                        this.state.allfeatures.map((featuresModule) => {
                                                                                                                            if (featuresModule.moduleName == 'my_report') {
                                                                                                                                if (this.state.isScheduleReport !== true) {
                                                                                                                                    return featuresModule.features.map((feature) => {
                                                                                                                                        return (
                                                                                                                                            <>
                                                                                                                                                {
                                                                                                                                                    (feature.featureName == 'viewreport_maximize_data_table_export' && feature.allow == true) ? (
                                                                                                                                                        <>
                                                                                                                                                            {(this.state.isViewOnlyStatus == false) ? (
                                                                                                                                                                <><button className="btn btn-bird btn-sm dobyexport dropdown-toggle" id="dobyexportdownload" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.download"]} data-toggle="dropdown" aria-expanded="true">
                                                                                                                                                                    <i className="fa fa-download"></i>
                                                                                                                                                                </button>
                                                                                                                                                                    <ul className="dropdown-menu dropdown-menu-right megadropdown downloadreports">
                                                                                                                                                                        <li className="dropdown-header">{lang["reports.selectrows"]}</li>
                                                                                                                                                                        <li>
                                                                                                                                                                            <div className="container-fluid">
                                                                                                                                                                                <div className="col-xs-12 nopadding">
                                                                                                                                                                                    <div className="col-xs-6 nopadding">{lang["reports.from"]}<input type="number" data-num={this.state.chartid} tab-id="0" tab-name="" defaultValue="1" className="rowsfrom marg-righ-5" /></div>
                                                                                                                                                                                    <div className="col-xs-6 nopadding">{lang["reports.to"]}<input type="number" data-num={this.state.chartid} tab-id="0" tab-name="" defaultValue="100" className="rowsto" /></div>
                                                                                                                                                                                </div>
                                                                                                                                                                            </div>
                                                                                                                                                                        </li>
                                                                                                                                                                        <li>&nbsp;</li>
                                                                                                                                                                        <li><a href="javascript:void(0)" className="csv" id="" title={lang["reports.csv"]}><i className="fa fa-file-text-o"></i>{lang["reports.csv"]}</a></li>
                                                                                                                                                                        <li><a href="javascript:void(0)" className="xls" id="" title={lang["reports.xls"]}><i className="fa fa-file-excel-o"></i>{lang["reports.xls"]}</a></li>
                                                                                                                                                                        <li><a href="javascript:void(0)" className="pdf" id="" title={lang["reports.pdf"]}><i className="fa fa-file-pdf-o"></i>{lang["reports.pdf"]}</a></li>
                                                                                                                                                                    </ul></>
                                                                                                                                                            ) : null}
                                                                                                                                                        </>
                                                                                                                                                    ) : null
                                                                                                                                                }
                                                                                                                                                {
                                                                                                                                                    (feature.featureName == 'viewreport_maximize_data_table_column_selection' && feature.allow == true) ? (
                                                                                                                                                        <>{(this.state.isViewOnlyStatus == false) ? (
                                                                                                                                                            <button className="btn btn-bird-group  marg-righ-10 btn-sm singlereportselectedtablecolumns" data-num={this.state.chartid} tab-id="0" tab-name=""><i className="fa fa-columns" onClick={this.props.showdobyselectedcolumns}></i></button>
                                                                                                                                                        ) : null}</>
                                                                                                                                                    ) : null
                                                                                                                                                }
                                                                                                                                            </>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            }
                                                                                                                        })
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="opacitydiv">
                                                                                                            {
                                                                                                                (this.state.isViewOnlyStatus == false) ? (
                                                                                                                    <button className="btn btn-bird btn-sm pull-right singlereport_summarymodal  marg-left-5 hide" id="singlereportsummarymodalview" data-num={this.state.chartid} tab-id="0" tab-name="" data-toggle="modal" title={lang["reports.sumarysettings"]}><i className="fa fa-wrench fa-flip-horizontal"></i></button>
                                                                                                                ) : null
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className="opacitydiv">
                                                                                                            <button className={`btn btn-bird btn-sm predictmodelsummary predictmodelsummary${this.state.chartid} marg-left-5 hide`} data-num={this.state.chartid} tab-id="0" tab-name="" data-toggle="modal" title={lang["reports.summary"]}><i className="fa fa-info"></i></button>
                                                                                                        </div>
                                                                                                        <div className="opacitydiv">
                                                                                                            <div className="btn-group marg-righ-5" role="group">
                                                                                                                <button className="btn btn-bird-group btn-sm viewdatafromsinglechart active hide" id="viewdatafromsinglecharts" type="button" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.dataview"]} onClick={this.props.viewchartdata}><i className="fa fa-table"></i> {lang["reports.dataview"]}</button>
                                                                                                                <button className="btn btn-bird-group btn-sm viewsinglechartsummary hide" id="viewsinglechartsummarys" type="button" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.summaryview"]} onClick={this.props.viewchartsummary}><i className="fa fa-th-large"></i> {lang["reports.summaryview"]}</button>
                                                                                                            </div>
                                                                                                            {
                                                                                                                this.state.allfeatures.map((featuresModule) => {
                                                                                                                    if (featuresModule.moduleName == 'my_report') {
                                                                                                                        return featuresModule.features.map((feature) => {
                                                                                                                            if (feature.featureName == 'viewreport_maximize_data_behind_chart' && feature.allow == true) {
                                                                                                                                if (this.state.isViewOnlyStatus == false) {
                                                                                                                                    return (<div className="btn-group nomargin h2 viewchartfromchartdata" role="group">
                                                                                                                                        <button className="btn btn-bird-group btn-sm active sinbleviewchartfromchartdata saved-data-buttons" id="sinbleviewchartfromchartdataview" type="button" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.chartview"]} onClick={this.props.viewChartFromChartData}><i className="fa fa-bar-chart"></i></button>
                                                                                                                                        {(this.state.isMultifact == true || this.state.isMultifact == "true") ? (
                                                                                                                                            <button className="btn btn-bird-group btn-sm sinbleviewchartdata saved-data-buttons" id="sinbleviewchartdataview" type="button" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.summaryview"]} onClick={this.props.viewchartdata}><i className="fa fa-table"></i></button>
                                                                                                                                        ) : (
                                                                                                                                                <button className="btn btn-bird-group btn-sm sinbleviewchartdata saved-data-buttons" id="sinbleviewchartdataview" type="button" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.dataview"]} onClick={this.props.viewchartdata}><i className="fa fa-table"></i></button>
                                                                                                                                            )}
                                                                                                                                    </div>);
                                                                                                                                }
                                                                                                                            }
                                                                                                                        })
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                            <div className="btn-group bgcommon predictionChart hide" >
                                                                                                                <button type="button" className={`btn btn-bird-group tooltip-bottom dropdown-toggle dropdown ${this.state.version}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title={lang["reports.more"]}>
                                                                                                                    <span className="caret"></span>&nbsp; <span className="Predictionmore">More Charts</span></button>
                                                                                                                <ul className="dropdown-menu">
                                                                                                                    <li><a href="javascript:void(0)" data-num={this.state.chartid} tab-id="0" tab-name="" className="boc-maxscreen-left-item h2o_charts  h2ochartssinglereport scorehistory"><i className="fa fa-bar-chart marg-righ-10"></i>Score History</a></li>
                                                                                                                    <li><a href="javascript:void(0)" data-num={this.state.chartid} tab-id="0" tab-name="" className="boc-maxscreen-left-item h2o_charts h2ochartssinglereport variableimp"><i className="fa fa-bar-chart marg-righ-10"></i>Variable Importance</a></li>
                                                                                                                    <li><a href="javascript:void(0)" data-num={this.state.chartid} tab-id="0" tab-name="" className="boc-maxscreen-left-item h2o_charts h2ochartssinglereport h2odatatable"><i className="fa fa-summary-table-16 marg-righ-10"></i>H2oData Table</a></li>
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                            {
                                                                                                                this.state.allfeatures.map((featuresModule) => {
                                                                                                                    if (featuresModule.moduleName == 'my_report') {
                                                                                                                        return featuresModule.features.map((feature) => {
                                                                                                                            if (feature.featureName == 'viewreport_maximize_trade_lines' && feature.allow == true) {
                                                                                                                                if (this.state.isViewOnlyStatus == false) {
                                                                                                                                    return <><button className="btn btn-bird btn-sm marg-left-5 dropdown-toggle dropdown showtrending" id="showtrendingsingle" data-num={this.state.chartid} tab-id="0" tab-name="" data-toggle="dropdown" title={lang["reports.trend"]}><i className="fa fa-line-chart "></i></button>
                                                                                                                                        <ul className="dropdown-menu dropdown-menu-right">
                                                                                                                                            <li className="singlereport_trendtype" id="linear" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.lineartool"]} onClick={this.props.trendtype}><a className="trendtypes linear" data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)">{lang["reports.linear"]}</a></li>
                                                                                                                                            <li className="singlereport_trendtype" id="SMA" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.smatool"]} onClick={this.props.trendtype}><a className="trendtypes sma" data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)">{lang["reports.sma"]}<span className="opacity-effect"><input type="number" className="trendperiodsbox smaperiods" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.SMAP"]} min="2" max="" defaultValue="2" /></span></a></li>
                                                                                                                                            <li className="singlereport_trendtype" id="EMA" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.ematool"]} onClick={this.props.trendtype}><a className="trendtypes ema" data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)">{lang["reports.ema"]}<span className="opacity-effect"><input type="number" className="trendperiodsbox emaperiods" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.EMAP"]} min="2" max="" defaultValue="2" /></span></a></li>
                                                                                                                                            <li className="singlereport_trendtype" id="rsum" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.runsum"]} onClick={this.props.trendtype}><a className="trendtypes rsum" data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)">{lang["reports.runsum"]}<span className="opacity-effect"></span></a></li>
                                                                                                                                            <li className="singlereport_trendtype" id="rmin" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.runmin"]} onClick={this.props.trendtype}><a className={`trendtypes rmin${this.state.chartid}`} data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)">{lang["reports.runmin"]}<span className="opacity-effect"></span></a></li>
                                                                                                                                            <li className="singlereport_trendtype" id="rmax" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.runmax"]} onClick={this.props.trendtype}><a className={`trendtypes rmax${this.state.chartid}`} data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)">{lang["reports.runmax"]}<span className="opacity-effect"></span></a></li>
                                                                                                                                            <li className="singlereport_trendtype" id="ravg" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.runavg"]} onClick={this.props.trendtype}><a className={`trendtypes ravg${this.state.chartid}`} data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)">{lang["reports.runavg"]}<span className="opacity-effect"></span></a></li>
                                                                                                                                            <li className="singlereport_trendtype" id="rdiff" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.rundiff"]} onClick={this.props.trendtype}><a className={`trendtypes rdiff${this.state.chartid}`} data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)">{lang["reports.rundiff"]}<span className="opacity-effect"></span></a></li>
                                                                                                                                            <li className="singlereport_trendtype" id="rpercentage" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.percent"]} onClick={this.props.trendtype}><a className={`trendtypes rdiff${this.state.chartid}`} data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)">{lang["reports.percent"]}<span className="opacity-effect"></span></a></li>
                                                                                                                                            <li className="singlereport_trendtype" id="trendstats" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.stats"]} onClick={this.props.trendtype}><a className={`trendtypes trendstats${this.state.chartid}`} data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)">{lang["reports.stats"]}</a></li>
                                                                                                                                        </ul></>
                                                                                                                                }
                                                                                                                            }
                                                                                                                        })
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                </>
                                                                            ) : (
                                                                                    <>
                                                                                        {
                                                                                            (this.state.isEmbeddedReport == true) ? (
                                                                                                <div className="pull-right marg-righ-10">
                                                                                                    <button className="btn btn-bird-group refreshsinglecharts hide" title={lang["reports.refresh"]} type="button"><i className="fa fa-refresh"></i></button>
                                                                                                </div>
                                                                                            ) : null
                                                                                        }
                                                                                        <div className="col-lg-3 col-md-3 col-sm-4 col-xs-4 width-54-mob pull-right">
                                                                                            <div className="col-xs-12 boc-padding-11 reporttoolbar" data-num={this.state.chartid} tab-id="0" tab-name="">
                                                                                                <div className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 reporttitletoolbar${this.state.chartid}`}>
                                                                                                    <div className="row controlbar-main">
                                                                                                        <div className="col-lg-7 col-xs-7 flex-baseline-div">
                                                                                                            <h2 className="boc-viewreport-title" data-num={this.state.chartid} tab-id="0" tab-name="">{this.state.title}</h2>
                                                                                                            <div className="datacount maxscreendatacount hide" data-num={this.state.chartid} tab-id="0" tab-name="">
                                                                                                                <span className="publishinfo"></span><span className="filterdatacount"></span>
                                                                                                                <span className="lastexecutedtimestamp"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {(this.isPrivate != "private") ? (
                                                                                                            <div className="pull-right marg-left-auto">
                                                                                                                <div aria-label="..." role="group" className="btn-group pull-right dropdown bgcommon">
                                                                                                                    <button type="button" className="btn btn-bird-group boc-maxscreen-left-item singlereportattributes modalleft" id="singlereportattributesview" data-direction="left" data-toggle="modal" data-target="#attributesmodal" title={lang["reports.attributes"]} onClick={this.props.togglevisualizationsettings}><i className="fa fa-cogs"></i><span className="maxscreen-lft-filtertitle hide">{lang["reports.attributes"]}</span></button>
                                                                                                                    <button type="button" className="btn btn-bird-group boc-maxscreen-left-item pull-right download_myreport" id="singledownload_myreport" title={lang["reports.download"]} data-num={this.state.chartid} tab-id="0" onClick={this.downloadFunction}><i className="fa fa-download"></i><span className="maxscreen-lft-filtertitle hide">{lang["reports.download"]}</span></button>
                                                                                                                    {this.state.isNLPReport? 
                                                                                                                    <button type="button" className="btn btn-bird-group tooltip-bottom intelligentsearch hide" id="intelligentsearchview" title={lang["reports.intelligentsearch"]} onClick={this.props.intelligentSearch}><i className="fa fa-search"></i></button>
                                                                                                                    :<React.Fragment/>}
                                                                                                                    <span style={{ float: "right", position: "relative" }}>
                                                                                                                        <button type="button" className="dropdown-toggle btn btn-bird-group single-filter-toggle" data-direction="right" data-toggle="dropdown" aria-expanded="true"><i className="fa fa-filter"></i></button>
                                                                                                                        <ul className="dropdown-menu dropdown-menu-right">
                                                                                                                            <li><a href="javascript:void(0)" className="modalfilter singlefilterdropdown" id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addfilter"]} onClick={this.props.addfilter}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addfilter"]}</a></li>
                                                                                                                            <li><a href="javascript:void(0)" className="modalfilter singlecustomparameterfilterdropdown" id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" onClick={this.props.addCustomParamerFilter} title={lang["reports.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addparameter"]}</a></li>
                                                                                                                        </ul>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                        {
                                                                                                            this.state.allfeatures.map((featuresModule) => {
                                                                                                                if (featuresModule.moduleName == 'my_report') {
                                                                                                                  return  featuresModule.features.map((feature) => {
                                                                                                                        if ((this.state.isDirect == 0 || this.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_embeded_filters' && feature.allow == true) {
                                                                                                                            return (
                                                                                                                                <div className="pull-right marg-left-auto">
                                                                                                                                    <div aria-label="..." role="group" className="btn-group pull-right dropdown bgcommon">
                                                                                                                                        <button className="btn btn-bird-group refreshsinglecharts hide" title={lang["reports.refresh"]} type="button"><i className="fa fa-refresh"></i></button>
                                                                                                                                        {/* <!-- 	<button type="button" className="btn btn-bird-group boc-maxscreen-left-item singlerportfilters modalleft" data-direction="left" data-toggle="modal" data-target="#innerfiltermodal" title="Filters"><i className="fa fa-filter"></i><span className="maxscreen-lft-filtertitle hide">Filters</span></button> --> */}
                                                                                                                                        <button type="button" className="btn btn-bird-group boc-maxscreen-left-item singlereportattributes modalleft" id="singlereportattributesview" data-direction="left" data-toggle="modal" data-target="#attributesmodal" title={lang["reports.attributes"]} onClick={this.props.togglevisualizationsettings}><i className="fa fa-cogs"></i><span className="maxscreen-lft-filtertitle hide">{lang["reports.attributes"]}</span></button>
                                                                                                                                        <button type="button" className="btn btn-bird-group boc-maxscreen-left-item pull-right download_myreport" id="singledownload_myreport" title={lang["reports.download"]} data-num={this.state.chartid} tab-id="0" onClick={this.downloadFunction}><i className="fa fa-download"></i><span className="maxscreen-lft-filtertitle hide">{lang["reports.download"]}</span></button>
                                                                                                                                        {this.state.isNLPReport?
                                                                                                                                        <button type="button" className="btn btn-bird-group tooltip-bottom intelligentsearch hide" id="intelligentsearchview" title={lang["reports.intelligentsearch"]} onClick={this.props.intelligentSearch}><i className="fa fa-search"></i></button>
                                                                                                                                        :<React.Fragment/>}
                                                                                                                                        <span style={{ float: "right", position: "relative" }}>
                                                                                                                                            <button type="button" className="dropdown-toggle btn btn-bird-group single-filter-toggle" data-direction="right" data-toggle="dropdown" aria-expanded="true"><i className="fa fa-filter"></i></button>
                                                                                                                                            <ul className="dropdown-menu dropdown-menu-right">
                                                                                                                                                <li><a href="javascript:void(0)" className="modalfilter singlefilterdropdown" id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addfilter"]} onClick={this.props.addfilter}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addfilter"]}</a></li>
                                                                                                                                                <li><a href="javascript:void(0)" className="modalfilter singlecustomparameterfilterdropdown" id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" onClick={this.props.addCustomParamerFilter} title={lang["reports.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addparameter"]}</a></li>
                                                                                                                                            </ul>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            );
                                                                                                                        }
                                                                                                                    })
                                                                                                                }
                                                                                                            })
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.allfeatures.map((featuresModule) => {
                                                                                                              return  featuresModule.moduleName == 'my_report'?
                                                                                                                   this.isPrivate == "private"?
                                                                                                                         <button type="button" className="btn btn-bird-group" onClick={this.SingleEmbedLogout} id="singleembedlogout" title={lang["reports.logout"]}><i className="fa fa-power-off"></i></button>
                                                                                                                    :null
                                                                                                                :null
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </>

                                                                                )}
                                                                    </div>
                                                                    <div id={`attributesmodaldiv${this.state.chartid}`} className={`attributesmodaldiv${this.state.chartid}`}></div>
                                                                    <div className={`pivotcolumnmodal${this.state.chartid}`}></div>
                                                                    {/* <div id={`datatablepopupmodal${this.state.chartid}`}></div> */}
                                                                    <div id="predictmodaldiv" className={`singlereport_predictmodaldiv${this.state.chartid}`}></div>
                                                                    <div className={`append_summarytablemodal append_summarytablemodal${this.state.chartid}`}></div>
                                                                    <div className={`append_customrange${this.state.chartid}`}></div>
                                                                    <div className={`customaggregatedfield${this.state.chartid}`}></div>
                                                                    <div id="advancedchartsetingsmodaldiv" className={`advancedchartsetingsmodaldiv${this.state.chartid}`}></div>
                                                                    <div className={`pivotchartsettingsicons${this.state.chartid}`}></div>
                                                                    <div id="savequeryinfo"></div>
                                                                    <div id="singlereportsetting"></div>
                                                                    <div id="updatesinglereportdiv"></div>
                                                                    <div id="nlpseacrhdiv"></div>
                                                                    <div className="savehashcriteriamodaldisplay"></div>
                                                                    <div className="sharediv"></div>
                                                                    <div id="advancedaccesspermissonscontainer"></div>
                                                                    <div className="embedreportdiv"></div>
                                                                    <div className={`append_alertmodal append_alertmodal${this.state.chartid}`} id={`append_alertmodal${this.state.chartid}`}></div>
                                                                    <div id="chartdrilldowndiv" className={`chartdrilldowndiv${this.state.chartid}`} data-num={this.state.chartid} tab-id={this.state.tabid} tab-name=""></div>
                                                                    <div id="smartinsightchoosefielddiv" className={`smartinsightchoosefielddiv${this.props.chartid}`} data-num={this.props.chartid} tab-id="0" tab-name="">
								                                            <div className="append_drillthroughoptions"></div>
							                                        </div>
                                                                    <div id="custommeasurediv" className="custommeasurediv0 custommeasuredivall"></div>
                                                                    <div id="schedulereportdiv" className="schedulereportdiv"></div>
                                                                    <div className="usercustomscriptmodaldisplay"></div>
                                                                    <div className="opacitydiv">
                                                                        <div className={`pivotsettingsmain${this.state.chartid}`} id="pivotcolumns" style={{ display: "none" }}>
                                                                            <div className="btn-group pull-left marg-top-5">
                                                                                <button className="btn btn-bird btn-sm pull-left prevsingle prev marg-righ-5" id="prevpivot" onClick={this.loadPrevious} data-num={this.state.chartid} tab-id="0" tab-name="" data-toggle="modal" title={lang["reports.previous"]}>{lang["reports.prev"]}</button>
                                                                                {/* <!-- Chart Type Button for Pivot View --> */}
                                                                                <button type="button" className="btn btn-bird btn-sm dropdown-toggle dropdown pull-left marg-righ-5 nextsingle next" id="nextsinglepivot" onClick={this.loadNext} data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.next"]} data-toggle="dropdown" aria-expanded="false">{lang["reports.next"]}</button>
                                                                                <select className="numberofpivotrows numberofpivotrowssingle" id="numberofpivotrowssinglepivot" onChange={this.loadPivotRows} data-num={this.state.chartid} tab-id="0" tab-name="">
                                                                                    <option selected="selected" value="10">10</option>
                                                                                    <option value="25">25</option>
                                                                                    <option value="50">50</option>
                                                                                    <option value="100">100</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="opacitydiv">
                                                                        <div className={`btn-group pull-right pivotnavigationbuttons${this.state.chartid} marg-top-5`} style={{ display: "none" }}>
                                                                            {
                                                                                (this.state.isViewOnlyStatus == false) ? (
                                                                                    <button className="btn btn-bird btn-sm pull-right pivotcolumnmodal singlereportpivotcolumnmodal" id="singlereportpivotcolumnmodalpivot" data-num={this.state.chartid} tab-id="0" tab-name="" data-toggle="modal" title={lang["reports.pivotsettings"]} ><i className="fa fa-wrench fa-flip-horizontal"></i></button>
                                                                                ) : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="opacitydiv">
                                                                        <div className={`btn-group pull-right pivotcharticon${this.state.chartid} marg-top-5`} style={{ display: "none" }}>
                                                                            {
                                                                                this.state.allfeatures.map((featuresModule) => {
                                                                                    return featuresModule.features.map((feature) => {
                                                                                        if (feature.featureName == 'viewreport_maximize_pivot_chart' && feature.allow == true) {
                                                                                            if (this.state.isViewOnlyStatus == false) {
                                                                                                return (
                                                                                                    <>
                                                                                                        <button type="button" className="btn btn-bird btn-sm dropdown-toggle dropdown pull-right marg-righ-5 pivotcharttypes" id="pivotcharttypespivot" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.chart"]} data-toggle="dropdown" aria-expanded="false"><i className="fa fa-bar-chart"></i></button>
                                                                                                        <ul className="dropdown-menu dropdown-menu-right">
                                                                                                            <li className="charttype " id="bar" data-num={this.state.chartid} tab-id="0" tab-name=""><a className="singlepivotchartmodal" onClick={this.props.showpivotchart} data-type="pivotbar" data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)"><img className="chart-icons-18" src={require('../images/birdimages/charts/basicbar-20.png')} />{lang["reports.defaultbar"]}</a></li>
                                                                                                            <li className="charttype " id="stackedbar" data-num={this.state.chartid} tab-id="0" tab-name=""><a className="singlepivotchartmodal" onClick={this.props.showpivotchart} data-type="pivotstackedbar" data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)"><img className="chart-icons-18" src={require('../images/birdimages/charts/stackedbar-20.png')} />{lang["reports.stackedbar"]}</a></li>
                                                                                                            <li className="charttype" id="line" data-num={this.state.chartid} tab-id="0" tab-name=""><a className="singlepivotchartmodal" onClick={this.props.showpivotchart} data-type="pivotline" data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)"><img className="chart-icons-18" src={require('../images/birdimages/charts/linechart-sm-20.png')} />{lang["reports.linechart"]}</a></li>
                                                                                                            <li className="charttype " id="area" data-num={this.state.chartid} tab-id="0" tab-name=""><a className="singlepivotchartmodal" onClick={this.props.showpivotchart} data-type="pivotarea" data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)"><img className="chart-icons-18" src={require('../images/birdimages/charts/areachartbasic-20.png')} />{lang["reports.areachart"]}</a></li>
                                                                                                            <li className="charttype " id="stackedarea" data-num={this.state.chartid} tab-id="0" tab-name=""><a className="singlepivotchartmodal" onClick={this.props.showpivotchart} data-type="pivotstackedarea" data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)"><img className="chart-icons-18" src={require('../images/birdimages/charts/stackedarea-20.png')} />{lang["reports.stackarea"]}</a></li>
                                                                                                            <li className="charttype " id="pivot" data-num={this.state.chartid} tab-id="0" tab-name=""><a className="singlepivotchartmodal" onClick={this.props.showpivotchart} data-type="pivot" data-num={this.state.chartid} tab-id="0" tab-name="" href="javascript:void(0)"><img className="chart-icons-18" src={require('../images/birdimages/charts/pivottable-20.png')} />{lang["reports.pivot"]}</a></li>
                                                                                                        </ul>
                                                                                                    </>
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                })
                                                                            }
                                                                            {(this.state.isViewOnlyStatus == false) ? (
                                                                                <button type="button" className="btn btn-bird btn-sm pull-right marg-left-5 marg-righ-5 sin singlereportpivotchartsettingsicons" onClick={this.props.pivotchartsettingsicon} id="singlereportpivotchartsettingsiconspivot" data-num={this.state.chartid} tab-id="0" tab-name="" title={lang["reports.pivotchartsettings"]}><i className="fa fa-cogs"></i></button>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                    <div className="append_drilldownallsettingspop"></div>
                                                                    <div id="singlereportchartsereiscolors"></div>
                                                                    <div id="purgedindicesmodelview"></div>
                                                                    <div id="metadataauditview"></div>
                                                                    <div className="livereportmodaldisplay"></div>
                                                                    <div id="changetimezonemodelview"></div>

                                                                    <div className={`summarytablemodalcontainer${this.state.chartid}`}></div>
                                                                    <div className={`customfieldhierarchymodalcontainer${this.state.chartid}`}></div>
                                                                    <div className={`customparametermodalcontainer${this.state.chartid}`}></div>
                                                                    <div className={`customrange${this.state.chartid}`}></div>
                                                                    <div className={`addreportthememodal${this.state.chartid} addreportthememodal`}></div>
                                                                    <div className={`richtextcontrolschart${this.state.chartid} pull-left`} style={{ display: "none" }}><span className="countcharacters" data-num={this.state.chartid} tab-id="0" tab-name=""></span></div>
                                                                    <div className="opacitydiv">
                                                                        <div className={`btn-group pull-right richtextcontrolschart${this.state.chartid}`} style={{ display: "none" }}>
                                                                            <button type="button" className="btn btn-bird btn-sm editrichtext1 marg-righ-5" data-num={this.state.chartid} tab-id="0" tab-name="" onClick={(e) => { this.editrichtext(e); }}>{lang["reports.edit"]}</button>
                                                                            <button type="button" className="btn btn-bird btn-sm cancelrichtext1 marg-righ-5" data-num={this.state.chartid} tab-id="0" tab-name="" onClick={(e) => { this.cancelrichtext(e); }}>{lang["reports.cancelbtn"]}</button>
                                                                            <button type="button" className="btn btn-bird btn-sm saverichtext1" data-num={this.state.chartid} tab-id="0" tab-name="" onClick={(e) => { this.applyTextChart(e); }}>{lang["reports.applybtn"]}</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 fullscreensample">
                                                                        {/* <!-- Chart Series Colors  --> */}
                                                                        <div className="chartadvancedoptions" data-num={`chart${this.state.chartid}`} tab-id="0" tab-name="">
                                                                            <div className="chartadvancedoptions-container pull-right">
                                                                                <button className="btn btn-transparent seriescolors tooltip-right" onClick={this.props.seriescolors} data-tooltip="Series Colors" data-num={this.state.chartid} tab-id="0" tab-name=""><i className="fa fa-th-list"></i></button>
                                                                            </div>
                                                                            <div className="showchartoptions" title={lang["reports.chartcustom"]}><i className="fa fa-angle-double-down"></i></div>
                                                                        </div>
                                                                        <div className="doby-offcanvas-columnlist backdrop"></div>
                                                                        <div className="doby-offcanvas-columnlist">
                                                                            <span className={`dobyselectcolumns${this.state.chartid} closedobycolumns singlereportselectedtablecolumns closeselectedcolumns`} data-num={this.state.chartid} tab-id="0" tab-name="" onClick={this.cancelselectedcolumns}><i className="fa fa-times-circle"></i></span>
                                                                            <div className="container-fluid">
                                                                                <div className="row dobycolumnscontent">
                                                                                    {
                                                                                        (this.state.isViewOnlyStatus == false) ? (
                                                                                            <>
                                                                                                <legend className="subhead">{lang["reports.choosecolumns"]}</legend>
                                                                                                <div className={`innerdobyselectedcolumns${this.state.chartid} scrollsetdobycolumns`}>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : null
                                                                                    }
                                                                                </div>
                                                                                <div className="dobycolumnslimitbuttons">
                                                                                    <button type="button" className="btn btn-bird btn-sm singlereportselectedtablecolumns closeselectedcolumns" onClick={this.cancelselectedcolumns}>{lang["reports.cancelbtn"]}</button>
                                                                                    <button type="button" className="btn btn-bird btn-sm applydobysettings" data-num={this.state.chartid} tab-id="0" tab-name="" onClick={this.props.applydobysettings}>{lang["reports.applybtn"]}</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div id={`chart${this.state.chartid}`} className="width-100 maxanim10" style={{ height: "calc(100vh - 25vh)" }}></div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='gridspinner' id={`spinnerchart${this.state.chartid}`} data-num={this.state.chartid} tab-id="0" tab-name="">
                                                        <div className="gridspinnercontent gridspinnermessage"></div>
                                                        <div className='gridspinnercontent'>
                                                            {/* <!-- <img alt="Loading..." src="img/loading9.gif"> -->
			<!-- <div className="semi-circle-spin"><div></div></div> --> */}
                                                            <div className="gridload"><div></div><div></div><div></div><div></div></div>
                                                        </div>
                                                    </div>
                                                    <canvas id="singlesavedcanvas" width="1920" height="1080" style={{ border: "1px solid #000", display: "none" }}></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="rightattributes"></div>
                            </div>
                        </div>
                        {
                            (this.state.displayFooter != false) ? (
                                <footer className="footer">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-xs-7">
                                                {/* <!-- <p className="text-muted"><%=whitelableMap["footertext"]%></p> --> */}
                                                {/* <!-- <div className="timezone hide pull-right">
								<span className="servertime"><b>Server Time:</b></span> <span className="servertimezone" style="margin-right:20px">Fri Sep 27 2019 10:37:00 GMT+0530 </span>
								<span className="localtime"><b>Client Time:</b></span> <span className="localtimezone"> Fri Sep 27 2019 10:37:00 GMT+0530 </span>
				</div> --> */}
                                            </div>

                                            <div className="col-xs-5">
                                                {/* <!-- <div className="page-nav-container text-right">
					<a className="previouspage tooltip-top linkpreviousreport" data-tooltip="Previous Page"><i className="fa fa-arrow-circle-left" aria-hidden="true"></i></a>
					<a className="nextpage tooltip-top linknextreport" data-tooltip="Next Report"><i className="fa fa-arrow-circle-right" aria-hidden="true"></i></a>
				</div> --> */}
                                                <div className="itsaudit hide">
                                                    <span className="livecirclecontainer dataauditlivecontainer hide">
                                                        <span className="livecircle"></span> {lang["reports.dataudit"]}
					</span>
                                                    <span className="livecirclecontainer metadataauditlivecontainer hide">
                                                        <span className="livecircle"></span>  {lang["reports.metadataaudit"]}
					</span>
                                                </div>
                                                <div className="itslive hide"><span className="liveupdatetime"></span><span className="livecirclecontainer"><span className="livecircle"></span>   {lang["reports.live"]}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </footer>
                            ) : null
                        }
                        <div className="screenshotmessage  hide">
                            <div className="screenshot-message">{lang["reports.reportstext"]}</div>
                        </div>
                    </div>
                </section>
                <div className="filterdata" id="filtersmodaldiv0"></div>
                <div id="singlefiltercontent"></div>
            </>)
    }
    applyTextChart(e) {
		var $this = e.currentTarget;
		var currentchartnumber = $($this).attr('data-num');
		$('.editrichtext[data-num=' + currentchartnumber + ']').removeClass('active');
		$('.saverichtext[data-num=' + currentchartnumber + ']').addClass('active');
		var chartData = ChartInfo.functions.getChartDetails(currentchartnumber);
		var data = JSON.parse(chartData);
		data['richtextcontent'] = encodeURIComponent($('#chart' + currentchartnumber).summernote('code'));
		ChartInfo.functions.updateChartDetails(currentchartnumber, JSON.stringify(data));
		$('#chart' + currentchartnumber).summernote('destroy');
        $('.maxanim9 .btn-group button').removeClass('disabled');
        $('#chart' + currentchartnumber).show();
	}
	editrichtext(e) {
		var $this = e.currentTarget;
		var currentchartnumber = $($this).attr('data-num');
		$('.maxanim9 .btn-group button').addClass('disabled');
		$('.editrichtext[data-num=' + currentchartnumber + ']').addClass('active');
		$('.saverichtext[data-num=' + currentchartnumber + ']').removeClass('active');
		$('#chart' + currentchartnumber).summernote({
			height: 300,
			focus: false,
			disableDragAndDrop: true,
			toolbar: [
				['style', ['style']],
				['font', ['bold', 'italic', 'underline', 'clear']],
				['fontname', ['fontname']],
				['color', ['color']],
				['para', ['ul', 'ol', 'paragraph']],
				['height', ['height']],
				['table', ['table']],
				['insert', ['link', 'picture', 'hr']],
				['view', ['fullscreen', 'codeview']],
			],
			onKeyup: function (e) {
				setTimeout(function () {
					var num = $('#chart' + currentchartnumber).summernote('code').replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length;
					var key = e.keyCode;
					var allowed_keys = [37, 38, 39, 40]
					if ($.inArray(key, allowed_keys) != -1)
						return true
					else if (num > 14000) {
						e.preventDefault();
						e.stopPropagation();
						$('.countcharacters[data-num=' + currentchartnumber + ']').text(lang["reports.characterscount"] + (14000 - num));
						$('.saverichtext[data-num=' + currentchartnumber + ']').addClass('disabled');
					} else {
						$('.countcharacters[data-num=' + currentchartnumber + ']').text(lang["reports.characterscount"] + (14000 - num));
						$('.saverichtext[data-num=' + currentchartnumber + ']').removeClass('disabled');
					}
				}, 150);
			},
			onPaste: function (e) {
				setTimeout(function () {
					var num = $('#chart' + currentchartnumber).summernote('code').replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length;
					var key = e.keyCode;
					var allowed_keys = [8, 37, 38, 39, 40, 46]
					if ($.inArray(key, allowed_keys) != -1)
						return true
					else if (num > 14000) {
						e.preventDefault();
						e.stopPropagation();
						$('.countcharacters[data-num=' + currentchartnumber + ']').text(lang["reports.characterscount"] + (14000 - num));
						$('.saverichtext[data-num=' + currentchartnumber + ']').addClass('disabled');
					} else {
						$('.countcharacters[data-num=' + currentchartnumber + ']').text(lang["reports.characterscount"] + (14000 - num));
						$('.saverichtext[data-num=' + currentchartnumber + ']').removeClass('disabled');
					}
				}, 150);
			}
		});
		var chartData = ChartInfo.functions.getChartDetails(currentchartnumber);
		var data = JSON.parse(chartData);
		if (data['richtextcontent'] != undefined){
            $('#chart' + currentchartnumber).summernote('code', decodeURIComponent(data['richtextcontent']));
        }
        $('#chart' + currentchartnumber).hide(); 
	}
	cancelrichtext(e) {
		var $this = e.currentTarget;
		var currentchartnumber = $($this).attr('data-num');
		var chartData = ChartInfo.functions.getChartDetails(currentchartnumber);
		var data = JSON.parse(chartData);
		if (data['richtextcontent'] != undefined) {
			$('#chart' + currentchartnumber).summernote('code', decodeURIComponent(data['richtextcontent']));
		} else if (data['richtextcontent'] === undefined) {
			$('#chart' + currentchartnumber).summernote('destroy').empty();
		}
		$('#chart' + currentchartnumber).summernote('destroy');
		$('.editrichtext[data-num=' + currentchartnumber + ']').removeClass('active');
		$('.saverichtext[data-num=' + currentchartnumber + ']').removeClass('active');
        $('.maxanim9 .btn-group button').removeClass('disabled');
        $('#chart' + currentchartnumber).show();
	}
}
