import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class SavedatamodelHtml extends React.Component {
    render() {
        return (
            <div className="modal fade in" id="savedatamodel" tabIndex="-1" role="dialog" aria-labelledby="savesdatamodel" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-lg modal-save-report modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="storyboardclose" onClick={this.props.canceldSaveModel} aria-hidden="true">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-save"></i> {lang["models.savemodel"]}</h4>
                        </div>
                        <div className="modal-body scrollset marg-top-20">
                            <div className="container-fluid">
                                <div className="row">
                                    <fieldset className="savereport-border">
                                        <legend className="savereport-border">{lang["models.details"]}</legend>
                                        <div className="control-group">
                                            <div className="validationgroup form-group" style={{ marginTop: "-2px" }}>
                                                <span className="required right legend"></span>
                                                <label className="subhead" htmlFor="datamodelname">{lang["models.name"]} </label>
                                                <input type="name" id="datamodelname" name="datamodelname" defaultValue={this.props.dataModelName} className="form-control" placeholder={lang["models.placeholdname"]} />
                                                <span className="help-block dmname-error  marg-left-10"></span>
                                            </div>
                                            <div className="validationgroup form-group marg-top-10">
                                                <label className="subhead nopadding">{lang["models.describe"]} </label>
                                                <textarea id="datamodeldesc" placeholder={lang["models.placeholddescribe"]} defaultValue={this.props.dataModelDesc} name="datamodeldesc" className="form-control resize-vertical"></textarea>
                                                <span className="help-block hidden"></span>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset className="savereport-border">
                                        <legend className="savereport-border">{lang["models.prefetchdatafornlp"]}</legend>
                                        <div className="row datasyncschedulecontrollers">
                                            <div className="col-xs-12">
                                                <div class="col-xs-12 marg-top-10 streamingsetting" id="wsstreamingsetting">
                                                    <div class="checkbox pull-left folderview">
                                                        <span><i class=""></i> {lang["models.prefetchnlpdataoff"]} </span>
                                                        <label class="checkbox-slider--c">
                                                            <input type="checkbox" id="streamingstatus"  class="dashboard-view-style dashboardview prefetchnlp" defaultChecked={this.props.prefetchNLP?true:false}/>
                                                            <span></span>
                                                        </label>
                                                        <div><i class=""></i> {lang["models.prefetchnlpdataon"]}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" onClick={this.props.canceldSaveModel}><i className="fa fa-times"></i> {lang["models.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird savedatamodelmodal" id="savedatamodelmodal" onClick={this.props.savedatamodel}><i className="fa fa-check"></i> {lang["models.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}