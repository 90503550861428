import React from 'react'
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from 'react-dom';
import download from "downloadjs"
import SendRequest from "../SendRequest";
import { getMessage } from '../js/helpers/utils/BirdMessage';
const $ = window.$;

const CampaignDownloadModel = (props) => {
    const { id, row,isSample,PopupTitle } = props;
    const toTextTitileMap = {"sms":"Your Mobile Number","email":"Your Email","whatsapp":"Your Mobile Number"};
    const  toPlaceHolderMap = {"sms":"Mobile Number","email":"Email","whatsapp":"Mobile Number"};
    const {category} = row;
    const closeModal = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("campdownloadletter"));
    }
    const exportLetters = (e) => {
        let textMessage = "";
        switch (category.toLowerCase()) {
            case "sms":
            case "whatsapp":
                textMessage = getMessage('BirdSuccess73')
                break;
            case "email":
                textMessage = getMessage('BirdSuccess74')    
                break;
            default:
                textMessage = getMessage('BirdSuccess71');
                break;
        }
        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Success');
        $('.birdmessage-info').empty().text(textMessage);
        setTimeout(function() {
            closeModal();
            $('.birdmessage-container .close').click();	
            }, 3000);
        let id = e.currentTarget.getAttribute("data-id");
        var data = new FormData();
        data.append("id", id);
        data.append("candidatename", $("#candidatename").val());
        data.append("isSample",isSample?isSample:false);
        if(isSample &&  category && category.toLowerCase()!='letter'){
            let campaignMetaData = {};
            let toVal = $("#to"+category.toLowerCase()).val();
            if(!toVal){
                $(".queryinfomodal.modalfilter.singlefilterdropdown").addClass('hide');
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage("ValidationMessage2").replace('@replace',toPlaceHolderMap[category.toLowerCase()]));
                return;
            }
            campaignMetaData["to"] = toVal;
            data.append("campaignMetaData",JSON.stringify(campaignMetaData));
        }
        SendRequest({
            url: "exportLetters",
            queryString: "",
            body: data,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.error)
                    setTimeout(function () {
                        $('.birderror').remove();
                    }, 3000);
                } else {
                    // that.plotDataTable(response);
                    if(category && category.toLowerCase()==="letter"){
                        download(response, "letters.pdf", "application/pdf");
                    }    
                }
            },
            rejectFunction: (error) => { $(".spinner").hide(); },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });

    }
    return (
        //    <!-- CampaignDownloadModel.js -->
        <div class="modal fade in" id="chartseriescolorsmodal" tabIndex="-1" role="dialog" aria-labelledby="chartseriescolors" aria-hidden="true" tab-name="" style={{ display: "block" }}>
            <div className="modal-backdrop fade in"></div>
            <div class="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center" style={{ zIndex: "9999" }}>
                <div class="modal-content">
                    <div class="modal-header boc-modal-hf-padding">
                        <button type="button" class="close white" onClick={closeModal} id="viewreportsettingclose" aria-hidden="true" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title seriescolors"><i class="fa fa-file"></i>{PopupTitle}</h4>
                    </div>
                    <div class="modal-body scrollset">
                        <div class="container-fluid">
                            <div class="row ">
                            {isSample && category && category.toLowerCase() != "letter"?
                            <div class="col-xs-12 marg-top-10 marg-bot-20">
                                    <label>{toTextTitileMap[category.toLowerCase()]}</label>
                                    <div class="validationgroup">
                                        <input type="text" class="form-control" id={`to${category.toLowerCase()}`} placeholder={`${toPlaceHolderMap[category.toLowerCase()]}`} defaultValue=""></input>
                                    </div>
                                </div>
                                :<React.Fragment/>
                                }
                                <div class="col-xs-12 marg-top-10 marg-bot-20 candidatename">
                                    <label>Candidate Name</label>
                                    <div class="validationgroup">
                                        <input type="text" class="form-control" id="candidatename" placeholder="Candidate Name" defaultValue=""></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer boc-modal-hf-padding">
                        <button type="button" class="btn btn-bird" onClick={closeModal} data-dismiss="modal"><i class="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                        <button type="button" class="btn btn-bird-group active applychartseriescolors" data-id={id} onClick={exportLetters} tab-name="">{ category && category.toLowerCase()==="letter"?<React.Fragment><i className="fa fa-download"></i>Download</React.Fragment>:<React.Fragment><i className="fa fa-arrow-right marg-righ-5"></i>Send</React.Fragment>}</button>
                    </div>
                </div>
                {/* <!-- /.modal-content --> */}
            </div>
            {/* <!-- /.modal-dialog --> */}
            {/* <!-- /.Chart Series Colors Modal -->); */}
        </div>);

}

export default CampaignDownloadModel