import React from 'react'
import ReactDOM from 'react-dom'
import { getMessage } from '../js/helpers/utils/BirdMessage';
import SendRequest from '../SendRequest';
import MetricChart from '../js/charts/metric'
import BarChart from "../js/charts/bar";
import TimeseriesChart from "../js/charts/timeseries";
import BasicPieChart from '../js/charts/basicPie';
import * as ChartUtils from "../js/helpers/utils/chartutil";
import BootStrapTable from "../js/charts/bootstraptable";
import SummaryView from "../js/charts/summaryview";
import * as collection from "../js/helpers/utils/SavedReportCollection";
import ReportBody from '../../src/ViewStoryboard/singlereportbody';
import SearchTemplate from './search.jsx'
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import _, { isArray } from 'underscore';
import NLPSinglereportSavemodal from "./nlpsinglereportsavemodal.jsx";
import { lang } from "../js/helpers/utils/languages/language";
import twbsPagination from 'twbs-pagination';
import InputValidate from '../js/helpers/InputValidate';

const $ = window.$;
export default class SearchView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {nlp_default_doby_columns:""};
        this.state.currentData = [];
        this.state.currentNLPData = [];
        this.state.selectedValue = "";
        this.state.nlpdata = "";
        this.state.searchText = "";
        $("body").addClass("searchpage").removeClass("datahubpage dataworkspacepage datamodelspage datagovernancepage datamainpage");
        $("body").removeClass("rightpanelopen");
        $('#ui-datepicker-div').hide();
        $('.clockpicker-popover').remove();
        sessionStorage.setItem("changesdone", 0);
        sessionStorage.removeItem("convertedtypes");
        sessionStorage.removeItem("isconvettedtypesapplied");
        sessionStorage.removeItem("pos");
        sessionStorage.removeItem("renameMeasuresorDimensions");
        sessionStorage.removeItem("linkedreports");
        $(".pivotdataview").remove();
        var that = this;
        this.state.features = ChartInfo.functions.getAllFeatures();
        this.state.rowLimit = 25;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getavailabledatamodels",
            queryString: "",
            sucessFunction: (response) => {
                that.state.collection = response;
                that.render1();
            },
            rejectFunction: () => { },
            headers: requestHeaders,
        })
    }
    render() {
        return (<div></div>)
    }
    render1() {
        //Control Footer UI Based on Parameters in URL
        var that = this
        that.state.displayFooter = true;
        var urlparameters = window.location.search.replace("?", "").split("&");
        $.each(urlparameters, function (i, u) {
            var parameter = u.split("=");
            if (parameter[0].toLowerCase() == "bottomfooter" && parameter[1].toLowerCase() == "false") {
                that.state.displayFooter = false;
            }
        });
        ReactDOM.render(<SearchTemplate {...this.props} clearQuery={this.clearQuery} queryTyping={this.queryTyping} getnlpqueryresult={this.getnlpqueryresult} formatquery={this.formatquery} displayFooter={that.state.displayFooter} datamodelslist={this.state.collection} />, document.getElementById("main"));
        var that = this;
        $("#selectdatamodel").chosen().change(function () {
            $(".notifications-table-container").addClass("hidden")
            $("#nlpchart").empty();
            that.getColumns();
        });

        $(".spinner").hide();
        this.getColumns();
        //Re-size charts
        /*var doit;
        $(window).on("resize",function(){
            clearTimeout(doit);
              doit = setTimeout(that.resizeChart(), 500);
        });*/

        var resizeTimer;
        $(window).on('resize', function (e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
                that.resizeChart()
            }, 500);
        });
        $("#birdintelligentsearch").keypress(function (e) {
            that.validateInput(e);
            if (e.which == 13) {
                console.log('You pressed enter!');
                $("#clicksearchbutton").click();
                that.getnlpqueryresult(e);
            }
        });
        $("#addintelligentchart").click(function (e) {
            that.saveAsMyeport(e);
        });
        $("body").addClass("searchpage").removeClass("datahubpage dataworkspacepage datamodelspage datagovernancepage datamainpage");
    }
    getColumns() {
        var that = this;
        var selected = $("#selectdatamodel option:selected").text();
        var selectedId = $("#selectdatamodel option:selected").val();
        var isMultiFact = $("#selectdatamodel option:selected").attr("multifact") != undefined ? $("#selectdatamodel option:selected").attr("multifact") : false;
        var isShared = $("#selectdatamodel option:selected").attr("is_shared");
        var isfrom = "governance";
        if (isShared == "true") {
            isfrom = "shredmodel";
        } else {
            isfrom = "governance"
        }

        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        $(".spinner").show();
        SendRequest({
            url: "getNLPMetaData",
            queryString: "?datamodelId=" + selectedId + "&multifact=" + isMultiFact + "&isfrom=" + isfrom,
            sucessFunction: (res) => {
                try {
                    let { columns_info, index_name, nlp_default_doby_columns,meta_data } = res;
                    that.state.nlp_default_doby_columns = nlp_default_doby_columns;
                    let parsedmetadata = JSON.parse(meta_data);
                    let response = JSON.parse(columns_info);
                    //Dynamically update suggestions
                    that.textAreaAutoComplete(response, index_name, parsedmetadata);
                    response.sort(function (a, b) {
                        var x = a.columnDisplayName.toLowerCase();
                        var y = b.columnDisplayName.toLowerCase();
                        return x < y ? -1 : x > y ? 1 : 0;
                    });
                    that.state.columns = response;
                    $('ul.nlp-available-columns').empty();
                    _.each(response, function (mitem) {
                        var dataType = mitem.tableDisplayType,
                            dataIcon = lang["search.abc"];
                        if (dataType == "number") {
                            dataIcon = "123"
                        } else if (dataType == "date" || dataType == "datetime" || dataType === 'month' || dataType === 'day') {
                            dataIcon = "<i class='fa fa-calendar'></i>"
                        }
                        $('ul.nlp-available-columns').append('<li class="boc-list-item singleliitems" title="' + mitem.columnDisplayName + '" data-type="' + dataType + '">'
                            + '<span class="itemtype">' + dataIcon + '</span>'
                            + '<span class="itemname">' + mitem.columnDisplayName + '</span>'
                            + '</li>');
                    });
                    $(".spinner").hide();
                } catch (e) {
                    $(".spinner").hide();
                }
            },
            rejectFunction: () => { $(".spinner").hide(); },
            headers: requestHeaders
        });
    }
    textAreaAutoComplete = (response, index_name, meta_data) => {
        var that = this;
        var matchedAttributeList = [];
        var currentSearch = $("#birdintelligentsearch").text();
        that.state.currentData = response;
        var columnDisplayNameMap = {};
        var columnDisplayTypeMap = {};
        var columnDisplaynameArray = [];
        var multifactentities = [];
        var multifact = false;

        _.each(response, function (mitem) {
            columnDisplayNameMap[mitem["columnDisplayName"]] = mitem["columnName"];
            columnDisplayTypeMap[mitem["columnDisplayName"]] = mitem["tableDisplayType"];
            columnDisplaynameArray.push(mitem["columnDisplayName"]);
        });
        columnDisplaynameArray.sort();
        let delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();

        /*var	words = ['concat()', 'lowercase()', 'uppercase()', 'length()',"indexOf(,)", "substring(,,)", 'contains(,)', 'endswith(,)', 'startswith(,)', 'trim()',
                     'replace(,,)','ltrim()', 'find(,)', 'round()', 'ceil()','floor()',
                     'absolute()', 'max(,)', 'min(,)','sin()','cos()','tan()','exp()','log()','log10()','sqrt()','atan()', 'pow(,)',
                     'year()', 'month()','day()',"format(,)","IIf(,,)","toDay()","now()","convertToDate(,)","dateDiff(,,)","converttoTime(,)","datediffAsString(,)","case()"];*/
        /*var	words = ['empty()','notEmpty()','length()','reverse()','concat()','substring(,,)','appendTrailingCharIfAbsent(,)','position(,)','match(,)','lower()','upper()','like(,)','notLike(,)','replaceAll(,,)','toString()'
                           ,'e()','pi()','exp()','log()','exp2()','log2()','exp10()','log10()','sqrt()','cbrt()','erf()','erfc()','sin()','cos()','tan()','atan()','acos()','asin()',
                           'pow(,)','abs()','round()','ceil()','floor()'
                            ,'toDateTime()','toYear()','toMonth()','toDayOfMonth()','toDayOfWeek()','toHour()','toMinute()','toSecond()','toMonday()','toStartOfMonth()','toStartOfQuarter()','toStartOfYear()','toStartOfMinute()',
                           'toStartOfFiveMinute()','toStartOfHour()','toStartOfDay()','toTime()','toRelativeYearNum()','toRelativeMonthNum()','toRelativeWeekNum()','toRelativeDayNum()',
                           'toRelativeHourNum()','toRelativeMinuteNum()','toRelativeSecondNum()','today()','now()','yesterday()','if(,,)','case()',
                           'toFloat64OrZero()','toFloat32OrZero()','toUInt64OrZero()','toUInt32OrZero()','toInt64OrZero()','toInt32OrZero()','toTypeName()','replaceOne(,,)'];*/
        var words = [];

        var multifact = (that.props.multifact == 'true');
        var multifactentities = (that.props.multifact == 'true') ? JSON.parse(that.props.multifactentities) : that.props.multifactentities;
        var facttables = that.props.facttables;
        var displayNameMap = columnDisplayNameMap;
        $('#birdintelligentsearch').on("mousedown change", function () {
            $('.customfieldlist:empty').remove();
        });
        $('#birdintelligentsearch').empty();
        $('#birdintelligentsearch').textcomplete('destroy');
        /*$('#birdintelligentsearch').focus(function(){
            var textComplete = $(this).data('textComplete');
         // Cursor has not set yet. And wait 100ms to skip global click event.
        delay(()=>{
            // Cursor is ready.
            textComplete.trigger();
        }, 5000);
         })*/
         var searchterm = "";
        $('#birdintelligentsearch').textcomplete([
            {
                words: words,
                match: /\b(\w{1,}\/\w{1,}\/\w{1,}|\w{1,}\/\w{1,}|\w{1,}-\w{1,}-\w{1,}|\w{1,}-\w{1,}|\w{1,}|\w{1,} \w{1,}\.{0,1}\w{0,})$/g,
                index: 0,
                search: async (term, callback) => {
                    searchterm= term;
                    // var newterm = term;
                    // var totalsentence = $('#birdintelligentsearch').html().replace(/&nbsp;/g, ' ').split(" ").splice(-2);
                    // var ishtml = false
                    // _.each(totalsentence,function(a){
                    //     var result = /<\/?[a-z][\s\S]*>/i.test(a);
                    //     if(result){
                    //         ishtml=true;
                    //     }
                    // })
                    
                    //var termcount = 
                    this.words = words;
                    if (multifact) {
                        var allowedTableNames = [];
                        var previoustablename;
                        _.each($('.customfieldlist'), function (elemtnt) {
                            if ($(elemtnt).not(':empty').length > 0) {
                                var columnName = $(elemtnt).attr('columnname');
                                var tableName = columnName.split(".")[0];
                                previoustablename = tableName;
                                allowedTableNames = multifactentities[tableName];
                                allowedTableNames.push(tableName);
                            }
                        });

                        if (previoustablename != undefined && facttables.indexOf(previoustablename) != -1) {
                            _.each(facttables, function (ft) {
                                if (ft != previoustablename) {
                                    _.each(allowedTableNames, function (allowed) {
                                        var index = allowedTableNames.indexOf(ft);
                                        if (index > -1) {
                                            allowedTableNames.splice(index, 1);
                                        }
                                    });
                                }
                            });
                        }

                        if (allowedTableNames.length > 0) {
                            var allowedColumnNames = [];
                            _.each(columnDisplaynameArray, function (columnDisplayName) {
                                var actualName = displayNameMap[columnDisplayName];
                                var actualTableName = actualName.split(".")[0];

                                if (allowedTableNames.indexOf(actualTableName) >= 0) {
                                    allowedColumnNames.push(columnDisplayName);
                                }
                            });
                            var customparameters = ChartInfo.functions.getCustomParameter();
                            _.each(customparameters, function (cp, i) {
                                var cpname = customparameters[i].parametername;
                                allowedColumnNames.push(cpname)
                            })
                            this.words = words;
                            this.words = this.words.concat(allowedColumnNames.sort());
                        }
                        else {
                            this.words = this.words.concat(columnDisplaynameArray.sort());
                        }

                    }
                    else {
                        this.words = this.words.concat(columnDisplaynameArray);
                    }

                    // if(!ishtml && totalsentence.length>1){//forjoining words
                    //     var newarray =[];
                    //     newarray.push(totalsentence[0]);
                    //     newarray.push(term);
                    //     newterm = newarray.join(' ');
                    // }

                    let { prefetchNLP } = meta_data
                    if (prefetchNLP) {
                            let requestHeaders = {
                                method: "get",
                                headers: new Headers({
                                    "Content-Type": "application/json"
                                })
                            };
                            try{
                                if(term){
                                    SendRequest({
                                    url: "getSearchItems",
                                    queryString: "?searchTerm="+term+"&indexName="+index_name,
                                    sucessFunction: async (response) => {
                                        that.words = [];
                                        that.words = that.words.concat(columnDisplaynameArray);
                                        response.forEach(val=>{
                                            if(columnDisplayNameMap[val.value]){
                                                let {columnName,value} = val;
                                                let finalword = value+"@@"+columnName;
                                                if(that.words.indexOf(finalword)==-1)
                                                    that.words.push(finalword)
                                                if(that.state.currentNLPData.indexOf(val.value)==-1)
                                                    that.state.currentNLPData.push(val.value);
                                            }else{
                                                columnDisplayTypeMap[val.value] = "string";
                                                columnDisplayNameMap[val.value]=val.columnName
                                                if(that.words.indexOf(val.value)==-1)
                                                    that.words.push(val.value)
                                            }
                                                if(that.state.currentNLPData.indexOf(val.value)==-1)
                                                    that.state.currentNLPData.push(val.value);
                                            
                                            
                                        });
                                        
                                        callback(await $.map(that.words, function (word) {
                                            let secondTerm = term.split(" ").length>1? term.split(" ")[1].toLowerCase():"";
                                            return word ? word.toLowerCase().indexOf(term.toLowerCase()) >= 0 ? word : secondTerm? word.toLowerCase().indexOf(secondTerm) >= 0 ? word:null:null : null;
                                        }));
                                    },
                                    rejectFunction: () => { },
                                    headers: requestHeaders,
                                    })
                                }
                        }catch(err){
                            console.log("Error for nlp search items "+err)
                            callback(await $.map(that.words, function (word) {
                                let secondTerm = term.split(" ").length>1? term.split(" ")[1].toLowerCase():"";
                                return word ? word.toLowerCase().indexOf(term.toLowerCase()) >= 0 ? word : secondTerm? word.toLowerCase().indexOf(secondTerm) >= 0 ? word:null:null : null;
                            }));
                        }
                    } else {
                        callback(await $.map(this.words, function (word) {
                            let secondTerm = term.split(" ").length>1? term.split(" ")[1].toLowerCase():"";
                            return word ? word.toLowerCase().indexOf(term.toLowerCase()) >= 0 ? word : secondTerm? word.toLowerCase().indexOf(secondTerm) >= 0 ? word:null:null : null;
                        }));
                    }

                },
                template: function (word) {
                    const el = $(".dropdown-menu.textcomplete-dropdown")
                    if (word.indexOf('()') < 0 && word.indexOf('(,)') < 0 && word.indexOf('(,,)') < 0 && word.indexOf('(){}') < 0 && word.indexOf('{}') < 0 && word.indexOf('(,"")') < 0 && word.indexOf('(,,"")') < 0 && word.indexOf('( in )') < 0) {
                        if(word.indexOf('@@')!=-1){
                            let split_word = word.split("@@");
                            let wordvalue = split_word[0];
                            let columnValue = split_word[1];
                            if (that.state.currentNLPData.indexOf(wordvalue) != -1) {
                                result = `<a><span class="actualname parentcolumn" title="${columnValue}"><span class="itemtype string">${lang["storyboard.abc"]}</span>${columnValue}</span><span class="itemname" title="${wordvalue}">${wordvalue}</span></a>`;
                                return result;
                            }
                        }
                        var type = columnDisplayTypeMap[word];
                        var result = '';
                        if (that.state.currentNLPData.indexOf(word) != -1) {
                            result = `<a><span class="actualname parentcolumn" title="${columnDisplayNameMap[word]}"><span class="itemtype string">${lang["storyboard.abc"]}</span>${columnDisplayNameMap[word]}</span><span class="itemname" title="${word}">${word}</span></a>`;
                        } else {
                            if (type == 'number' || type == 'parameter') {
                                result = "<span class='itemtype number'>123</span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                            }
                            else if (type == 'date') {
                                result = "<span class='itemtype date'><i class='fa fa-calendar'></i></span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                            }
                            else if (type == 'day' || type == 'month') {
                                result = "<span class='itemtype date'><i class='fa fa-calendar'></i></span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                            }
                            else {
                                result = "<span class='itemtype string'>" + lang["storyboard.abc"] + "</span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                            }
                        }

                        return result;
                    } else {
                        return "<span class='fa-orange marg-righ-5'><i>f</i> <small>(x)</small></span> " + word;
                    }
                },
                replace: function (word, e) {
                    let temp_word = word;
                    if(word.indexOf('@@')!=-1){
                        word = word.split("@@")[0];
                    }
                    let data_column = $(e.currentTarget).find('a>span.actualname.parentcolumn').attr('title');
                    let proceed = that.validateInput();
                    if (!proceed) return
                    var tempWord = word;
                    var type = columnDisplayTypeMap[word];
                    if (word.indexOf('()') < 0 && word.indexOf('(,)') < 0 && word.indexOf('(,,)') < 0 && word.indexOf('(){}') < 0 && word.indexOf('{}') < 0 && word.indexOf("(,'')") < 0 && word.indexOf("(,,'')") < 0 && word.indexOf('( in )') < 0) {
                        var columnName = columnDisplayNameMap[word];
                        var color = "#000"
                        if (type == "number") { color = "rgba(0, 90, 255,0.1)" } else if (type == "string") { color = "rgba(182, 40, 203, 0.1)" } else if (type == "date" || type == "datetime") { color = "rgba(2, 179, 40,0.1)" }
                        if (that.state.currentNLPData.indexOf(word) != -1) {
                            color = "rgba(255, 218, 8, 0.4);"
                            if (isArray(columnName) && columnName.indexOf(data_column) != -1) {
                                columnName = data_column;
                            }
                            if(temp_word.indexOf("@@")!=-1){
                                let tempArr = temp_word.split("@@");
                                if(tempArr && tempArr.length==2){
                                    columnName = tempArr[1];
                                }
                            }
                        }
                        tempWord = "<span contenteditable='false' class='customfieldlist' displayName = '" + word + "' columnName='" + columnName + "' style='color:#333;background-color:" + color + ";padding:0 0 0 2px;margin-right:1px;'>" + word + " </span>"
                        if (type == 'parameter') {
                            that.parametrFieldsArray.push(word);
                            tempWord = "[<span class='fa-orange customfieldparameterlist' data-word ='" + word + "'>" + word + "</span>]";
                        }
                    }
                    else {
                        if (word == "case()") {
                            var dataword = "CASE WHEN ";
                            //var dataWithInbrackets = "[Condition] THEN  [Statement] ELSE [Statement] END";
                            var dataWithInbrackets = "[<span class='fa-blue'>Condition</span>] THEN  [<span class='fa-blue'>Statement</span>] ELSE [<span class='fa-blue'>Statement</span>] END";
                            tempWord = "<span class='customfieldaggrlist' data-word ='" + word + "' contenteditable=false>" + dataword + "</span>" + dataWithInbrackets + "<span></span>";
                            that.showhelp(this, word);
                        } else {
                            var dataword = word.substring(0, word.indexOf('('));
                            var dataWithInbrackets = word.substring(word.indexOf('(') + 1, word.indexOf(')'));
                            tempWord = "<span class='customfieldaggrlist' data-word ='" + word + "' contenteditable=false>" + dataword + "(</span>" + dataWithInbrackets + "<span>)</span>";
                            that.showhelp(this, word);
                        }
                    }
                    let tempStr  = $('#birdintelligentsearch').text().match(/\b(\w{1,}\/\w{1,}\/\w{1,}|\w{1,}\/\w{1,}|\w{1,}-\w{1,}-\w{1,}|\w{1,}-\w{1,}|\w{1,}|\w{1,} \w{1,}\.{0,1}\w{0,})$/g);
                   tempStr=searchterm
                    if(tempStr!=null){
                       // var actualsearch = tempStr[0];
                       var actualsearch=searchterm;
                        actualsearch=actualsearch.toLowerCase();
                        word = word.toLowerCase();
                       
                        var searchregex = new RegExp(actualsearch,"g");
                        var result = word.match(searchregex);

                        if(actualsearch!=word && result==null && actualsearch.match(/\b(\w{1,}\/\w{1,}\/\w{1,}|\w{1,}\/\w{1,}|\w{1,}-\w{1,}-\w{1,}|\w{1,}-\w{1,})$/g)==null){
                            tempWord=actualsearch.match(/^([\w\-]+)/)[0]+" "+tempWord;
                        }
                    }
                    tempWord=tempWord+" ";
                    return tempWord;
                }
            }
        ], { maxCount: 100 }).on({
            'textComplete:select': function (e, value) {
                that.validateInput(e)
            }
        })
    }
    queryTyping = (e) => {
        if ($("#birdintelligentsearch").text().length > 0) {
            $('#clickclearbutton').removeClass('hide');
        } else {
            $('#clickclearbutton').addClass('hide');
        }
    }
    formatquery = (e) => {
        var that = this;
        if (e == "fromtyping") {
            return;
            var pastedText = $('#birdintelligentsearch').text().trim();
        } else {
            var pastedText = (e.originalEvent || e).clipboardData.getData('text/plain');
        }
        if (pastedText && InputValidate({ "text": pastedText, "isFrom": "nlp" })) {
            $('#birdintelligentsearch').text(pastedText);
            $('#Intelligencyerror').empty().text(getMessage("ValidationMessage7"));
            $('#Intelligencyerror').show();
            $("#addintelligentchart").addClass("hide");
            if (e != "fromtyping") e.preventDefault();
            return;
        }
        var finalText = pastedText;
        that.state.currentData.forEach(item => {
            var word = item.columnDisplayName;
            var type = item.type;
            if (type == undefined) { type = item.tableDisplayType }
            var color = "#2737a3";
            if (type == "number") { color = "rgba(0, 90, 255,0.1)" } else if (type == "string") { color = "rgba(182, 40, 203, 0.1)" } else if (type == "date" || type == "datetime") { color = "rgba(2, 179, 40,0.1)" }
            var rgxp = new RegExp('(?<![\w\-\\d])' + word + '(?![\w\d]|[^<]*>*<\/)', 'gmi');

            var repl = '<span contenteditable="false" class="" displayname="' + word + '" columnname="' + item.columnName + '" style="color:#333;background-color:' + color + ';padding:0 0 0 2px;margin-right:1px;">' + word + '</span>';
            finalText = finalText.replace(rgxp, repl);
        });
        var spacer = new RegExp("</span> ", 'g');
        finalText = finalText.replace(spacer, " </span>");
        //finalText=finalText+'&nbsp;';
        pastedText = pastedText.replace(/  +/g, ' ');
        setTimeout(function () {
            $('#birdintelligentsearch').html(finalText);
            //$('#birdintelligentsearch').html( $('#birdintelligentsearch').html().split('&nbsp;').join(' ') );
            try {
                setTimeout(function () {
                    //$('#birdintelligentsearch').focus();
                    $.fn.focusEnd = function () {
                        $(this).focus();
                        var tmp = $('<span />').appendTo($(this)),
                            node = tmp.get(0),
                            range = null,
                            sel = null;

                        if (document.selection) {
                            range = document.body.createTextRange();
                            range.moveToElementText(node);
                            range.select();
                        } else if (window.getSelection) {
                            range = document.createRange();
                            range.selectNode(node);
                            sel = window.getSelection();
                            sel.removeAllRanges();
                            sel.addRange(range);
                        }
                        tmp.remove();
                        return this;
                    }
                    $('#birdintelligentsearch').focusEnd();
                }, 0);
            } catch (e) { }
            $('#clickclearbutton').removeClass('hide');
        }, 0);
    }
    clearQuery = () => {
        $('#clickclearbutton').addClass('hide');
        $("#birdintelligentsearch").empty();
    }
    getnlpqueryresult = (e) => {
        e.preventDefault();
        var that = this;
        var $this = e.currentTarget;
        var searchText = $("#birdintelligentsearch").text().trim();
        if (searchText && InputValidate({ "text": searchText, "isFrom": "nlp" })) {
            $('#birdintelligentsearch').text(searchText);
            $('#Intelligencyerror').empty().text(getMessage("ValidationMessage7"));
            $('#Intelligencyerror').show();
            $("#addintelligentchart").addClass("hide");
            return;
        }
        $("#birdintelligentsearch span.customfieldlist>br").remove();
        if ($($this).hasClass("searchbutton") || e.keyCode === 13) {
            that.intelligencySearchString(searchText);
        }
        if (($($this).hasClass("searchbutton") || e.keyCode === 13) && $("#birdintelligentsearch").text().length > 0) {
            $(".spinner").show();
            $("#Intelligencyerror").hide();
            searchText = $("#birdintelligentsearch").text().trim();
            var x = escape(searchText);
            searchText = x.replace(/%A0/g, "%20");
            searchText = unescape(searchText);
            var columnNames = this.state.dimensions;
            var schemaId = that.props.schemaId;
            var reportId = that.props.reportId;
            var offset = 0;
            var rowLimit = 25;
            that.state.rowLimit = 25;
            var tableInfo;
            $(".page-size").html(rowLimit);
            var datamodelId = $("#selectdatamodel option:selected").val();
            let columnsArray = [];
            let columnValues = {};
            that.state.columns.map(val => columnsArray.push(val.columnDisplayName));
            $(".customfieldlist").each(function () {
                let displayName = $(this).attr('displayname');
                let columnName = $(this).attr('columnname');
                if (columnsArray.indexOf(displayName) == -1 && that.state.currentNLPData.indexOf(displayName) != -1)
                    if (Object.keys(columnValues).indexOf(columnName) == -1) {
                        columnValues[columnName] = [];
                        columnValues[columnName].push(displayName);
                    } else {
                        columnValues[columnName].push(displayName)
                    }
            })
            var formdata = new FormData();
            formdata.set("columnNames", JSON.stringify(that.state.columns));
            formdata.set("schemaId", schemaId != undefined ? schemaId : "");
            formdata.set("reportId", reportId != undefined ? reportId : "");
            formdata.set("datamodelId", datamodelId);
            formdata.set("offset", offset);
            formdata.set("rowLimit", rowLimit);
            formdata.set("searchText", searchText);
            formdata.set("columnValues", JSON.stringify(columnValues));

            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };

            SendRequest({
                url: "getnlpreportdata",
                body: formdata,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty("error")) {
                        if (response.errorCode == "CI-DI-012") {
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(lang["search.serverconnect"]);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append("java.net.ConnectException:Not able to connect to Server, please check the connection details")
                            return;
                        }
                        else if (response.errorCode == "CI-DI-006" || response.errorCode == "ERR_9031") {
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.message ? response.message : response.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.message ? response.message : response.error)
                            return;
                        }
                        else if (response.errorCode == "CI-DI-013" || response.errorCode == "ERR_9019") {
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(lang["search.datahandlerconctn"])
                            return;
                        }
                        else {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Warning');
                            $('.birdmessage-info').empty().text(lang["search.criterianotfound"]);
                            return false;
                        }
                    } else {
                        that.chartnumber = 0;
                        $("#nlpchart").empty();
                        $("#addintelligentchart").removeClass('hide');
                        that.state.nlpdata = response;
                        that.state.searchText = searchText;
                        var data = JSON.parse(response['chartdata']);
                        var chartType = response['chartType'];
                        var xaxisName = response['xaxisName'];
                        var xaxisColumnName = response['xaxisColumnName'];
                        var xaxisdataType = response['xaxisdataType'];
                        var yAxisNameArray = response['yAxisNameArray'];
                        var legend = response['legend'];
                        var legendObject = {};
                        legendObject['columnName'] = "";
                        if (legend != undefined) {
                            legendObject['columnName'] = legend;
                            legendObject['displayname'] = legend;
                        }
                        var chartSettings = { 'chartTitle': true, 'legend': true, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true };

                        var tempChartDetails = {};
                        tempChartDetails['chartSettings'] = chartSettings;
                        ChartInfo.functions.updateChartDetails(that.chartnumber, JSON.stringify(tempChartDetails));
                        $(".notifications-table-container").addClass("hidden");
                        that.addChart();
                        return;
                        $(".intelligentsearchchart").removeClass("hidden")
                        if (chartType == "datatable") {
                            $("#nlpchart").empty();
                            var headers = [];
                            $(".notifications-table-container").removeClass("hidden")
                            $(".intelligentsearchchart").addClass("hidden")
                            for (var i = 0; i < data[data.length - 1]["tablecolumns"].length; i++) {
                                var columnName = data[data.length - 1]["tablecolumns"][i];
                                var columnDataTypes = data[data.length - 1]["tableColumnDataTypes"][i];
                                headers.push({
                                    id: i + "",
                                    name: columnName,
                                    field: columnName,
                                    sortable: true,
                                    removable: true,
                                    tooltip: columnName,
                                    type: columnDataTypes
                                });
                            }
                            var opts = {};
                            opts["columns"] = headers;
                            if (data[data.length - 1].hasOwnProperty('data')) {
                                opts["data"] = data;
                            }
                            opts['exportFileName'] = "Doby";
                            opts['autoColumnWidth'] = true;
                            opts['quickFilter'] = true;
                            opts['contextMenu'] = 'header';
                            opts['canvasFocus'] = false;
                            opts['rowHeight'] = 25;
                            var totalPages = 1;
                            var page;
                            var pageDisplay;
                            var len = data.length - 1;
                            var limit = response['limitvalue'];
                            var totalRecords = 0;
                            if (limit != "" && limit != undefined) {
                                totalRecords = parseInt(limit);
                            } else {
                                totalRecords = data[len].datacount;
                            }
                            if (pageDisplay == undefined) {
                                pageDisplay = 25;
                            }
                            var showPageList;
                            if (totalRecords > 25) {
                                showPageList = true;
                                totalPages = Math.ceil(totalRecords / pageDisplay);
                            } else {
                                totalPages = 1;
                                showPageList = false;
                            }
                            var currentpage = page;
                            if (currentpage == undefined) {
                                currentpage = 1;
                            }
                            if ($('#datatable-pagination-view').data("twbs-pagination")) {
                                $('#datatable-pagination-view').twbsPagination('destroy');
                            }
                            ChartUtils.drawBootstrapTableForNLP(headers, data);
                            $('#datatable-pagination-view').twbsPagination({
                                totalPages: totalPages,
                                visiblePages: 5,
                                startPage: currentpage,
                                first: "«",
                                prev: "‹",
                                next: "›",
                                last: "»"
                            }).on('page', function (evt, pageSelected) {

                                var tableInfo = {
                                    page: pageSelected,
                                    pageLimit: that.state.rowLimit,
                                    paginationdiv: tableInfo != undefined && tableInfo.paginationdiv != undefined ? tableInfo.paginationdiv : undefined
                                };
                                that.dataTableServerRequest(tableInfo)
                            });
                            $(".btablerowlimit1").unbind().click(function (e) {
                                var $this = e.currentTarget;
                                var pageLimit = $($this).find('a').text();
                                $(".btablerowlimit li").removeClass("active");
                                var tableInfo = {
                                    page: 1,
                                    pageLimit: pageLimit,
                                    // paginationdiv:tableInfo!= undefined &&  tableInfo.paginationdiv != undefined ? tableInfo.paginationdiv:undefined
                                };
                                that.dataTableServerRequest(tableInfo)
                            });

                        }
                        if (chartType == "bar") {
                            $(".notifications-table-container").addClass("hidden")
                            $(".intelligentsearchchart").removeClass("hidden")
                            var today = new Date();
                            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                            $("#nlpchart").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                            ReactDOM.render(<BarChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisName} xaxisDataType={xaxisdataType}
                                yaxisnameArray={yAxisNameArray} chartid={"nlpchart"} chartType={chartType} legend={legendObject} position={"min"} features={that.state.features} isSharedReport={false}
                                colorThemeIndex={0} chartSettings={chartSettings} tooltipNameArray={[]} algorithm={undefined} periodsvalue={undefined}
                                trendstats={undefined} yAxisOriginalNameArray={that.yAxisNameArray} referenceLine={undefined} iscustomstoryboard={false}
                                yAxisDataTypeArray={[]} yAxisAggArray={[]} isMLReport={undefined} columnsData={undefined} ismultifact={undefined} multifactentities={undefined} datamodelId={undefined} reportid={undefined} facttables={undefined} ></BarChart>, document.getElementById(dynamicdiv));
                        }
                        else if (chartType == "linetimeseries") {
                            $(".notifications-table-container").addClass("hidden")
                            $(".intelligentsearchchart").removeClass("hidden")
                            var today = new Date();
                            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                            $("#nlpchart").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                            ReactDOM.render(<TimeseriesChart data={data} xaxisName={xaxisName} xaxisColumnName={xaxisName} xaxisDataType={xaxisdataType}
                                yaxisnameArray={yAxisNameArray} chartId={"nlpchart"} chartType={chartType} legend={legendObject} position={"min"} features={that.state.features} isSharedReport={false}
                                colorThemeIndex={0} chartSettings={chartSettings} tooltipNameArray={[]} algorithm={undefined} periodsvalue={undefined}
                                trendstats={undefined} yAxisOriginalNameArray={that.yAxisNameArray} referenceLine={undefined} iscustomstoryboard={false}
                                yAxisDataTypeArray={[]} yAxisAggArray={[]} isMLReport={undefined} columnsData={undefined} ismultifact={undefined} multifactentities={undefined} datamodelId={undefined} reportid={undefined} facttables={undefined} />, document.getElementById(dynamicdiv));
                        }
                        else if (chartType == "pie") {
                            $(".notifications-table-container").addClass("hidden")
                            $(".intelligentsearchchart").removeClass("hidden")
                            var today = new Date();
                            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                            $("#nlpchart").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                            ReactDOM.render(<BasicPieChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisName} xaxisDataType={xaxisdataType}
                                yaxisnameArray={yAxisNameArray} chartid={"nlpchart"} chartType={chartType} legend={legendObject} position={"min"} features={that.state.features} isSharedReport={false}
                                colorThemeIndex={0} chartSettings={chartSettings} tooltipNameArray={undefined} algorithm={undefined} periodsvalue={undefined}
                                trendstats={undefined} yAxisOriginalNameArray={yAxisNameArray} referenceLine={undefined} iscustomstoryboard={false}
                                yAxisDataTypeArray={[]} yAxisAggArray={[]} isMLReport={undefined} columnsData={undefined}
                                ismultifact={undefined} multifactentities={undefined} datamodelId={undefined} reportid={undefined}
                                facttables={undefined} />, document.getElementById(dynamicdiv));
                        }
                        else if (chartType == "metric") {
                            $(".notifications-table-container").addClass("hidden")
                            $(".intelligentsearchchart").removeClass("hidden")
                            var today = new Date();
                            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                            $("#nlpchart").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                            if (data && data.length == 1) {
                                let { aggType } = data[0];
                                if (aggType && (aggType.toLowerCase() == "count" || aggType.toLowerCase() == "countdistinct") && chartSettings && !chartSettings.numberformat) {
                                    let obj = {
                                        "decimals": "0",
                                        "seperator": "thousands1"
                                    }
                                    chartSettings["numberformat"] = obj
                                }
                            }
                            ReactDOM.render(<MetricChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisName} xaxisDataType={xaxisdataType}
                                yaxisnameArray={yAxisNameArray} chartid={"nlpchart"} chartType={chartType} legend={legendObject} position={"max"}
                                metrictype={"All"} metricdatefield={""} tileno={that.chartnumber} colorThemeIndex={0}
                                chartSettings={chartSettings} metricprefix={""} metricsuffix={""} iscustomstoryboard={false} trendstats={undefined} metricfont={undefined}
                            ></MetricChart>, document.getElementById(dynamicdiv));
                        }
                        else if (chartType == "summarytable") {

                            $(".spinner").hide();
                            var dataSet = response['chartdata'];
                            var columns = JSON.parse(dataSet).columns;
                            //var legendLength = 1;
                            this.chartnumber = 0
                            var xAxisLength = response.xcount;
                            var yAxisLength = response.ycount;
                            var legendLength = 1;
                            if (xAxisLength == 0) {
                                $(".singlereport_summarymodal").click();
                                $(".addpivotrows-error help-block marg-left-10").removeClass("hide");
                                $("#spinnerchart" + this.props.chartNumber).hide();
                                return;
                            }
                            var totalStart = xAxisLength + legendLength + 1;
                            var totalEnd = xAxisLength + yAxisLength + 1;

                            var chartNumber = this.chartnumber;
                            $('.summartable' + this.chartnumber + '').remove();
                            //   ChartUtils.unbindEvents(this.chartnumber);
                            //			$("#chart"+this.chartnumber).empty();
                            $("#nlpchart").css({ "overflow": '' });
                            $("#nlpchart").append('<div class="summartable' + this.chartnumber + ' summartytablecontainer"></div>');
                            var $tableEle = $('#summartable' + this.chartnumber + '');
                            $(".summartable" + this.chartnumber).append('<table id="summartable' + this.chartnumber + '" data-num=' + this.chartnumber + ' class="table table-striped table-bordered summarytablestyling" cellspacing="0" width="100%">');
                            var groupCol = 1;

                            var $tableEle = $('#summartable' + this.chartnumber + '');
                            var rows_rightalighn = [];
                            var rows_rightalighn_legend = [];
                            var length = yAxisLength + xAxisLength;
                            var lengthwithlegend = yAxisLength + xAxisLength + legendLength;
                            var summarytableViewdatapopup = "summarytable-viewdatapopup";
                            for (var i = 1; i <= yAxisLength; i++) {
                                rows_rightalighn.push(length--);
                                rows_rightalighn_legend.push(lengthwithlegend--);
                            }
                            //Initiate DataTable
                            if (legendLength != 0) {
                                $("#nlpchart .summartytablecontainer").addClass("summaryhasgroupby");
                                var $tableList = $tableEle.DataTable({
                                    data: JSON.parse(dataSet).data,
                                    columns: columns,
                                    "lengthMenu": [[10, 25, 50, 100], [10, 25, 50, 100]],
                                    "autoWidth": true,
                                    "retrieve": true,
                                    "paging": true,
                                    "scrollY": "100%",
                                    "scrollX": true,
                                    "dom": 'lBftrip',
                                    "buttons": [{ extend: 'csv', text: '<i class="fa fa-download"></i>', className: 'btn btn-bird btn-sm summarytableexport', titleAttr: 'Download CSV', title: 'SummaryTable', exportOptions: { columns: ':visible' } }],
                                    "deferRender": true,
                                    "scroller": true,
                                    //"orderFixed": [[groupCol, 'asc']],
                                    "columnDefs": [{
                                        "orderable": false,
                                        "targets": [0]
                                    }, {
                                        "visible": false,
                                        "targets": [0, 1]
                                    }, {
                                        "className": "dt-body-center " + summarytableViewdatapopup + "", "targets": rows_rightalighn_legend
                                    }],
                                    "drawCallback": function (settings) {
                                        var that = this;
                                        if (settings.bSorted || settings.bFiltered) {
                                            this.$('td:first-child', {
                                                "filter": "applied"
                                            }).each(function (i) {
                                                that.fnUpdate(i + 1, this.parentNode, 0, false, false);
                                            });
                                        }

                                        var api = this.api();
                                        var rows = api.rows({
                                            page: 'current'
                                        }).nodes();
                                        var rowsData = api.rows({
                                            page: 'current'
                                        }).data();

                                        var last = null;
                                        var subTotal = new Array();
                                        var grandTotal = new Array();
                                        var groupID = -1;
                                        this.chartnumber = 0
                                        api.column(groupCol, {
                                            page: 'current'
                                        }).data().each(function (group, i) {
                                            if (last !== group) {
                                                groupID++;
                                                $(rows).eq(i).before("<tr class='groupTR' group-no='" + groupID + "' group-name='" + group + "' data-num=" + that.chartnumber + "><td colspan='" + xAxisLength + "' class='groupTitle'>" + group + "</td></tr>");
                                                last = group;
                                            }

                                            //Sub-total of each column within the same grouping
                                            var val = api.row(api.row($(rows).eq(i)).index()).data(); //Current order index
                                            $.each(val, function (colIndex, colValue) {
                                                if (typeof subTotal[groupID] == 'undefined') {
                                                    subTotal[groupID] = new Array();
                                                }
                                                if (typeof subTotal[groupID][colIndex] == 'undefined') {
                                                    subTotal[groupID][colIndex] = 0;
                                                }
                                                if (typeof grandTotal[colIndex] == 'undefined') {
                                                    grandTotal[colIndex] = 0;
                                                } if (colValue != null) {
                                                    colValue = (typeof colValue == "number") ? colValue : colValue.replace(/,/g, '');
                                                }
                                                var value = colValue ? parseFloat(colValue) : 0;
                                                subTotal[groupID][colIndex] += parseFloat(value);
                                                grandTotal[colIndex] += parseFloat(value);
                                            });
                                        });
                                        var sumArray = [];
                                        for (var a1 = totalStart; a1 <= totalEnd; a1++) {
                                            var sum = 0;
                                            api.data().each(function (val) {
                                                sum = parseFloat(sum) + parseFloat(val[a1] + "".replace(/,/g, ''))
                                            });
                                            sumArray[a1] = sum;
                                        }
                                        $('tbody').find('.groupTR[data-num=' + this.chartnumber + ']').each(function (i, v) {
                                            var rowCount = $(this).nextUntil('.groupTR').length;
                                            $(this).nextUntil('.groupTR').attr("group-no", i);//add group number to each row
                                            $(this).nextUntil('.groupTR').attr("data-num", this.chartnumber);//add chartno to each row
                                            var subTotalInfo = "";
                                            for (var a = totalStart; a <= totalEnd; a++) {
                                                if (subTotal[i][a] != undefined)
                                                    subTotalInfo += "<td class='groupTD dt-body-center'>" + subTotal[i][a].toLocaleString() + "/" + sumArray[a].toLocaleString() + "</td>";
                                            }
                                            $(this).append(subTotalInfo);
                                        });
                                    }
                                });
                                // $("#spinnerchart"+this.props.chartNumber).hide();
                            } else {
                                this.chartnumber = 0
                                $("#nlpchart" + this.chartnumber + " .summartytablecontainer").removeClass("summaryhasgroupby");
                                var appendTH = "";
                                for (var i = 0; i < columns.length - 2; i++) {
                                    appendTH += "<th class=text-right></th>"
                                }
                                var summarytableViewdatapopup = "summarytable-viewdatapopup";
                                if (this.isDirect == 1 && ChartInfo.functions.getIsStoredProcedure() == 1) {
                                    summarytableViewdatapopup = "";
                                }
                                if (this.isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true) {
                                    summarytableViewdatapopup = "notpermitted";
                                }
                                $('#summartable' + this.chartnumber + '').append('<tfoot class="tFooterTotal"><tr>'
                                    + '<th></th><th>Grand Total</th>' + appendTH + '</tr></tfoot>');
                                var $tableList = $tableEle.DataTable({
                                    data: JSON.parse(dataSet).data,
                                    columns: columns,
                                    "paging": true,
                                    "lengthMenu": [[10, 25, 50, 100], [10, 25, 50, 100]],
                                    "scrollY": "100%",
                                    "scrollX": true,
                                    "dom": 'lBftrip',
                                    "buttons": [{ extend: 'csv', text: '<i class="fa fa-download"></i>', className: 'btn btn-bird btn-sm summarytableexport', titleAttr: 'Download CSV', title: 'SummaryTable', exportOptions: { columns: ':visible' } }],
                                    "initComplete": function (settings, json) {
                                        for (var i = 1; i <= yAxisLength; i++) {
                                            $('.summarytablestyling thead tr th:nth-last-child(' + i + ')').addClass('sum');
                                        }
                                        this.api().columns('.sum').every(function () {
                                            var column = this;
                                            if (column.data().length > 0) {
                                                var sum = column
                                                    .data()
                                                    .reduce(function (a, b) {
                                                        a = (a == null) ? 0 : (typeof a == "number") ? a : a.replace(/,/g, '');
                                                        b = (b == null) ? 0 : (typeof b == "number") ? b : b.replace(/,/g, '');
                                                        return parseFloat(a, 10) + parseFloat(b, 10);
                                                    });
                                                if (sum != null && sum != undefined) {
                                                    $(column.footer()).html(sum.toLocaleString());
                                                }
                                            }
                                        });
                                    },
                                    "columnDefs": [{
                                        "targets": [0],
                                        "visible": false,
                                        "searchable": false,
                                        "paging": false,
                                    }, {
                                        "className": "dt-body-right " + summarytableViewdatapopup + "", "targets": rows_rightalighn
                                    }],
                                })

                                //$("#spinnerchart"+this.props.chartNumber).hide();
                            }
                        }
                        $(".spinner").hide();
                    }
                },
                rejectFunction: response => {
                    $(".spinner").hide();
                },
                headers: requestHeaders
            });
        }
        if ($("#birdintelligentsearch").text().length > 0) {
            $('#clickclearbutton').removeClass('hide');
        } else {
            $('#clickclearbutton').addClass('hide');
        }
    }
    intelligencySearchString(searchText) {
        if (searchText == "" || searchText == undefined) {
            $('#Intelligencyerror').empty().text(getMessage('ValidationMessage6'));
            $('#Intelligencyerror').show();
            $("#addintelligentchart").addClass("hide");
            return false;
        }
    }
    resizeChart() {
        if ($(window).width() > 1200) {
            if ($("#nlpchart .highcharts-container:not(.metric-container)").length > 0) {
                // var chart = $("#nlpchart").highcharts();
                // var width = $(".row.height-100").width() - ($(".is-search-group-container").width() + 141),
                //     height = $(".row.height-100").height() - 50;
                // chart.setSize(width,height)
            }
        } else {
            if ($("#nlpchart .highcharts-container:not(.metric-container)").length > 0) {
                // var chart = $("#nlpchart").highcharts();
                // var width = $(".row.height-100").width() - 10,
                //     height = $("#nlpchart").height();
                // chart.setSize(width,height)
            }
        }
    }
    dataTableServerRequest(tableInfo) {
        $(".spinner").show();
        $("#Intelligencyerror").hide();
        var that = this
        $(".page-size").html(tableInfo.pageLimit);
        that.state.rowLimit = tableInfo.pageLimit;
        var searchText = $("#birdintelligentsearch").text().trim();
        var x = escape(searchText);
        searchText = x.replace(/%A0/g, "%20");
        searchText = unescape(searchText);
        var columnNames = this.dimensions;
        var schemaId = that.props.schemaId;
        var reportId = that.props.reportId;
        var offset = 0;
        var rowLimit = 10;
        if (tableInfo != undefined && tableInfo.pageLimit != undefined) {
            rowLimit = tableInfo.pageLimit;
        }
        if (tableInfo != undefined && tableInfo.page != undefined && tableInfo.page > 1) {
            offset = (tableInfo.page - 1) * rowLimit;
        }
        var datamodelId = $("#selectdatamodel option:selected").val();

        var formdata = new FormData();
        formdata.set("columnNames", JSON.stringify(that.state.columns));
        formdata.set("schemaId", schemaId != undefined ? schemaId : "");
        formdata.set("reportId", reportId != undefined ? reportId : "");
        formdata.set("datamodelId", datamodelId);
        formdata.set("offset", offset);
        formdata.set("rowLimit", rowLimit);
        formdata.set("searchText", searchText);

        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };

        SendRequest({
            url: "getnlpreportdata",
            body: formdata,
            sucessFunction: (response) => {
                if (response.hasOwnProperty("error")) {
                    $(".spinner").hide();
                } else {
                    var data = JSON.parse(response['chartdata']);
                    that.state.nlpdata = response;
                    var chartType = response['chartType'];
                    var xaxisName = response['xaxisName'];
                    var xaxisColumnName = response['xaxisColumnName'];
                    var xaxisdataType = response['xaxisdataType'];
                    var yAxisNameArray = response['yAxisNameArray'];
                    var legend = response['legend'];
                    var legendObject = {};
                    legendObject['columnName'] = "";
                    if (legend != undefined) {
                        legendObject['columnName'] = legend;
                        legendObject['displayname'] = legend;
                    }
                    var chartSettings = { 'chartTitle': true, 'legend': true, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true };
                    $("#nlpchart").empty();
                    if (chartType == "datatable") {
                        var headers = [];
                        for (var i = 0; i < data[data.length - 1]["tablecolumns"].length; i++) {
                            var columnName = data[data.length - 1]["tablecolumns"][i];
                            var columnDataTypes = data[data.length - 1]["tableColumnDataTypes"][i];
                            headers.push({
                                id: i + "",
                                name: columnName,
                                field: columnName,
                                sortable: true,
                                removable: true,
                                tooltip: columnName,
                                type: columnDataTypes
                            });
                        }
                        var opts = {};
                        opts["columns"] = headers;
                        if (data[data.length - 1].hasOwnProperty('data')) {
                            opts["data"] = data;
                        }
                        opts['exportFileName'] = "Doby";
                        opts['autoColumnWidth'] = true;
                        opts['quickFilter'] = true;
                        opts['contextMenu'] = 'header';
                        opts['canvasFocus'] = false;
                        opts['rowHeight'] = 25;
                        var totalPages = 1;
                        var page;
                        var pageDisplay;
                        var len = data.length - 1;
                        var limit = response['limitvalue'];
                        var totalRecords = 0;
                        if (limit != "" && limit != undefined) {
                            totalRecords = parseInt(limit);
                        } else {
                            totalRecords = data[len].datacount;
                        }
                        if (tableInfo != undefined && tableInfo.pageLimit != undefined) {
                            pageDisplay = parseInt(tableInfo.pageLimit);
                        }
                        if (pageDisplay == undefined) {
                            pageDisplay = 10;
                        }
                        var showPageList;
                        if (totalRecords > 10) {
                            showPageList = true;
                            totalPages = Math.ceil(totalRecords / pageDisplay);
                        } else {
                            totalPages = 1;
                            showPageList = false;
                        }
                        var currentpage = tableInfo.page;
                        if (currentpage == undefined) {
                            currentpage = 1;
                        }
                        if ($('#datatable-pagination-view').data("twbs-pagination")) {
                            $('#datatable-pagination-view').twbsPagination('destroy');
                        }
                        ChartUtils.drawBootstrapTableForNLP(headers, data);
                        $('#datatable-pagination-view').twbsPagination({
                            totalPages: totalPages,
                            visiblePages: 5,
                            startPage: currentpage,
                            first: "«",
                            prev: "‹",
                            next: "›",
                            last: "»"
                        }).on('page', function (evt, pageSelected) {

                            var tableInfo = {
                                page: pageSelected,
                                pageLimit: that.state.rowLimit,
                                paginationdiv: tableInfo != undefined && tableInfo.paginationdiv != undefined ? tableInfo.paginationdiv : undefined
                            };
                            that.dataTableServerRequest(tableInfo)
                        })
                    }


                    $(".spinner").hide();
                }
            },
            rejectFunction: response => {
                $(".spinner").hide();
            },
            headers: requestHeaders
        });
    }
    saveAsMyeport = (e) => {
        this.state.el = "updatesinglereportdiv";
        ReactDOM.render(
            <NLPSinglereportSavemodal
                savereport={this.savereport}
                cancelmodal={this.cancelmodal}
            />,
            document.getElementById(this.state.el)
        );
    }
    cancelmodal = (e) => {
        var that = this;
        if (document.getElementById(that.state.el) != null) {
            ReactDOM.unmountComponentAtNode(document.getElementById(that.state.el));
        }
    }
    savereport = (e) => {
        var that = this;
        var storyboardname = $('#' + this.state.el).find("#reportname").val().trim();
        var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
        if (storyboardname == undefined || storyboardname == "" || storyboardname.length == 0 || /[~`!@#$%^*+\=\[\]{};':\\|†.<>\/?"""`]/.test(storyboardname)) {
            $("#storyboardname-help-block").html(lang["search.validname"])
            return;
        } else if (storyboardname.length > 70) {
            $("#storyboardname-help-block").html(lang["search.reportnameexceeds"])
            return;
        } else if (reservedwordslist != undefined && reservedwordslist.indexOf(storyboardname.toLowerCase()) != -1) {
            $("#storyboardname-help-block").html(lang["search.noreservewords"])
            return;
        } else {
            $("#storyboardname-help-block").html('')
        }
        var storyboardfolder = $('#' + this.state.el).find("#reportFolder").val();
        var storyboarddesc = $('#' + this.state.el).find("#description").val();
        if (storyboardfolder === "") {
            storyboardfolder = "Default"
        }
        var datamodelId = $("#selectdatamodel option:selected").val();
        var chartData = JSON.parse(that.state.nlpdata['chartdata']);
        var chartType = that.state.nlpdata['chartType'];
        var xaxisName = that.state.nlpdata['xaxisName'];
        var xaxisColumnName = that.state.nlpdata['xaxisColumnName'];
        var xaxisdataType = that.state.nlpdata['xaxisdataType'];
        var yAxisNameArray = that.state.nlpdata['yAxisNameArray'];
        var legend = that.state.nlpdata['legend'];
        var sortType = that.state.nlpdata["sortType"];
        var colLimit = that.state.nlpdata["colLimit"];
        var nlpFilters = that.state.nlpdata["nlpfilters"];
        var nlpfilterscols = that.state.nlpdata["nlpfilterscols"];
        var nlprowlimit = that.state.nlpdata["nlprowLimit"];
        var nlptableordertype = that.state.nlpdata["sortType"]
        var searchText = that.state.searchText;
        var dobyselectedcolumns = JSON.stringify([]);
        var formdata = new FormData();
        if (chartType == "datatable") {
            chartType = "doby";
            dobyselectedcolumns = that.state.nlpdata["dobyselectedColumns"];
            if (nlprowlimit != undefined && nlprowlimit != "" && nlprowlimit != null) {
                formdata.set("nlprowlimit", nlprowlimit);
            }
            if (nlptableordertype != undefined && nlptableordertype != "" && nlptableordertype != null) {
                formdata.set("nlptableordertype", nlptableordertype);
            }
        }
        var summarytablecolumns = [], summarytablegroupbys = {}, summarytablevalues = [], getsummarytableaxisfieldsarray = [];
        if (chartType == "summarytable") {
            var aggregationcolumns = JSON.parse(that.state.nlpdata["summaryaggColumns"]);
            var aggregationdisplaynames = JSON.parse(that.state.nlpdata["summaryaggregationColumns"]);
            var groupbycolumns = JSON.parse(that.state.nlpdata["summarygroupByColumns"]);
            var groupbydisplaynames = JSON.parse(that.state.nlpdata["summarygroupsbydisplaynames"]);
            let i = 0;
            _.each(aggregationcolumns, function (val, index) {
                summarytablevalues.push(aggregationcolumns[aggregationdisplaynames[i]])
                i++;
            })
            i = 0;
            _.each(groupbycolumns, function (val, index) {
                if (i != 0) {
                    summarytablecolumns.push(groupbycolumns[groupbydisplaynames[i]])
                }
                if (i == 0) {
                    summarytablegroupbys = groupbycolumns[groupbydisplaynames[i]];
                }
                i++;
            })
            let tempsummaryobj = {}
            tempsummaryobj["textcolumns"] = summarytablecolumns;
            tempsummaryobj["group"] = summarytablegroupbys;
            getsummarytableaxisfieldsarray.push(tempsummaryobj);
            formdata.set("summarytablevalues", JSON.stringify(summarytablevalues));
            formdata.set("summarytablecolumns", JSON.stringify(summarytablecolumns));
            formdata.set("summarytablegroupbys", JSON.stringify(summarytablegroupbys));
            formdata.set("getsummarytableaxisfieldsarray", JSON.stringify(getsummarytableaxisfieldsarray));

            if (nlprowlimit != undefined && nlprowlimit != "" && nlprowlimit != null) {
                formdata.set("nlprowlimit", nlprowlimit);
            }
            if (nlptableordertype != undefined && nlptableordertype != "" && nlptableordertype != null) {
                formdata.set("nlptableordertype", nlptableordertype);
            }
        }
        formdata.set("aggregation", chartData["aggType"] != undefined ? chartData["aggType"] : "count");
        formdata.set("yaxis", yAxisNameArray != undefined ? JSON.stringify(yAxisNameArray) : "");
        formdata.set("yAxisDataType", yAxisNameArray != undefined ? JSON.stringify(that.state.nlpdata.yAxisDataTypeArray) : "");
        formdata.set("chartType", chartType);
        formdata.set("xaxis", xaxisName != undefined ? xaxisName : "");
        formdata.set("xaxisdataType", xaxisdataType != undefined ? xaxisdataType : "");
        formdata.set("legend", legend != undefined ? legend : "");
        formdata.set("legendDataType", legend != undefined ? that.state.nlpdata.legendDataType : "");
        formdata.set("dobyselectedcolumns", dobyselectedcolumns);
        formdata.set("filterObject", JSON.stringify([]));
        formdata.set("columns", JSON.stringify(that.state.columns));
        formdata.set("datamodelId", parseInt(datamodelId));
        formdata.set("sortType", sortType);
        formdata.set("colLimit", colLimit);
        formdata.set("searchText", searchText);
        if (nlpfilterscols != undefined && nlpfilterscols != "" && nlpfilterscols != null) {
            formdata.set("nlpfilterscols", nlpfilterscols);
        }
        if (nlpFilters != undefined && nlpFilters != "" && nlpFilters != null) {
            formdata.set("nlpFilters", nlpFilters);
        }
        formdata.set("storyboardname", storyboardname);
        formdata.set("storyboardfolder", storyboardfolder);
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "createReportFromNLP",
            body: formdata,
            sucessFunction: (response) => {
                if (response.hasOwnProperty("error")) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                } else {
                    that.cancelmodal();
                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Success');
                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess01'));
                }
            },
            rejectFunction: response => {
                $(".spinner").hide();
            },
            headers: requestHeaders
        });
    }
    addChart = (event) => {
        $('body').removeClass('nlp-open');
        sessionStorage.setItem("changesdone", 1);
        var that = this;
        var filtersArray = []
        if (ChartInfo.functions.getFilters(true) != undefined && ChartInfo.functions.getFilters(true).length != 0) {
            filtersArray = JSON.parse(JSON.stringify(filtersArray)).concat(ChartInfo.functions.getFilters(true))
        }
        var details = JSON.parse(ChartInfo.functions.getChartDetails(that.props.chartnumber != undefined ? that.props.chartnumber : that.chartnumber));
        var currentChartDetails = {}
        let searchText = $("#birdintelligentsearch").text();
        currentChartDetails['chartSettings'] = details['chartSettings'];
        var chartData = JSON.parse(that.state.nlpdata['chartdata']);
        var chartType = that.state.nlpdata['chartType'];
        var xaxisName = that.state.nlpdata['xaxisName'] != undefined ? that.state.nlpdata['xaxisName'] : "";
        var xaxisColumnName = that.state.nlpdata['xaxisColumnName'] != undefined ? that.state.nlpdata['xaxisColumnName'] : "";
        var xaxisdataType = that.state.nlpdata['xaxisdataType'] != undefined ? that.state.nlpdata['xaxisdataType'] : "";
        var yAxisNameArray = that.state.nlpdata['yAxisNameArray'] != undefined ? that.state.nlpdata['yAxisNameArray'] : [];
        var yAxisDataTypeArray = that.state.nlpdata["yAxisDataTypeArray"] != undefined ? that.state.nlpdata["yAxisDataTypeArray"] : []
        var legend = that.state.nlpdata['legend'];
        var legendDataType = that.state.nlpdata["legendDataType"];
        var sortType = that.state.nlpdata["sortType"];
        var colLimit = that.state.nlpdata["colLimit"];
        var nlpFilters = that.state.nlpdata["nlpfilters"];
        var nlpfilterscols = that.state.nlpdata["nlpfilterscols"];
        var nlprowlimit = that.state.nlpdata["nlprowLimit"];
        var nlptableordertype = that.state.nlpdata["sortType"];
        let nlprowlimitobj = {}
        var dobyselectedcolumns = that.state.nlp_default_doby_columns?JSON.parse(that.state.nlp_default_doby_columns):[];
        var yaxisFinalArray = [];
        var tempObj = {}
        var newChartnumber = that.props.chartnumber;
        _.each(yAxisDataTypeArray, function (val, index) {
            tempObj = {}
            tempObj["displayName"] = yAxisNameArray[index];
            tempObj["columnName"] = yAxisNameArray[index];
            tempObj["dataType"] = val.ydataType
            tempObj["aggregation"] = val.yaggType;
            tempObj["uniqueid"] = index;
            tempObj["tableName"] = "";
            tempObj["percentileValue"] = "25";
            yaxisFinalArray.push(tempObj);
        })
        var xaxisFinalArray = [];
        if (xaxisColumnName != undefined && xaxisColumnName != '') {
            tempObj = {};
            tempObj["displayName"] = xaxisName;
            tempObj["columnName"] = xaxisColumnName;
            tempObj["dataType"] = xaxisdataType;
            tempObj["xaxisidentity"] = xaxisdataType;
            tempObj["tableName"] = "";
            xaxisFinalArray.push(tempObj);
        }
        var legendObj = {};
        if (legend != undefined && legend != '') {
            tempObj = {};
            tempObj["displayName"] = legend;
            tempObj["columnName"] = legend;
            tempObj["dataType"] = legendDataType;
            tempObj["tableName"] = "";
            legendObj = tempObj;
        }
        if (chartType == "datatable") {
            chartType = "doby";
            dobyselectedcolumns = JSON.parse(that.state.nlpdata["dobyselectedColumns"]);
            if (nlprowlimit != undefined && nlprowlimit != "" && nlprowlimit != null) {
                currentChartDetails["nlprowlimit"] = nlprowlimit;
                nlprowlimitobj["nlprowLimit"] = nlprowlimit;
            }
            if (nlptableordertype != undefined && nlptableordertype != "" && nlptableordertype != null) {
                currentChartDetails["nlptableordertype"] = nlptableordertype;
                nlprowlimitobj["nlptableordertype"] = nlptableordertype;
            }
        }
        var summarytablecolumns = [], summarytablegroupbys = {}, summarytablevalues = [], getsummarytableaxisfieldsarray = [];
        if (chartType == "summarytable") {
            var aggregationcolumns = JSON.parse(that.state.nlpdata["summaryaggColumns"]);
            var aggregationdisplaynames = JSON.parse(that.state.nlpdata["summaryaggregationColumns"]);
            var groupbycolumns = JSON.parse(that.state.nlpdata["summarygroupByColumns"]);
            var groupbydisplaynames = JSON.parse(that.state.nlpdata["summarygroupsbydisplaynames"]);
            let i = 0;
            _.each(aggregationcolumns, function (val, index) {
                summarytablevalues.push(aggregationcolumns[aggregationdisplaynames[i]])
                i++;
            })
            i = 0;
            _.each(groupbycolumns, function (val, index) {
                if (i != 0) {
                    summarytablecolumns.push(groupbycolumns[groupbydisplaynames[i]])
                }
                if (i == 0) {
                    summarytablegroupbys = groupbycolumns[groupbydisplaynames[i]];
                }
                i++;
            })
            let tempsummaryobj = {}
            tempsummaryobj["textcolumns"] = summarytablecolumns;
            tempsummaryobj["group"] = summarytablegroupbys;
            getsummarytableaxisfieldsarray.push(tempsummaryobj);
            currentChartDetails["summarytablevalues"] = summarytablevalues;
            currentChartDetails["summarytablecolumns"] = summarytablecolumns;
            currentChartDetails["summarytablegroupbys"] = summarytablegroupbys;
            currentChartDetails["getsummarytableaxisfieldsarray"] = getsummarytableaxisfieldsarray;
            if (nlprowlimit != undefined && nlprowlimit != "" && nlprowlimit != null) {
                currentChartDetails["nlprowlimit"] = nlprowlimit;
                nlprowlimitobj["nlprowLimit"] = nlprowlimit;
            }
            if (nlptableordertype != undefined && nlptableordertype != "" && nlptableordertype != null) {
                currentChartDetails["nlptableordertype"] = nlptableordertype;
                nlprowlimitobj["nlptableordertype"] = nlptableordertype;
            }
        }
        currentChartDetails["dobyselectedcolumns"] = dobyselectedcolumns;
        currentChartDetails.chartType = chartType;
        currentChartDetails['yAxis'] = yaxisFinalArray;
        currentChartDetails['xAxis'] = xaxisFinalArray;
        if (!_.isEmpty(legendObj)) {
            currentChartDetails['legend'] = legendObj;
        }
        currentChartDetails['reporttitle'] = details['reporttitle'];
        currentChartDetails['metricdatefield'] = "";
        currentChartDetails['metricdatetype'] = details['metricdatetype'];
        //currentChartDetails['metricdatefield'] = details['metricdatefield'];
        currentChartDetails["tooltip"] = [];
        currentChartDetails["combinationchartslist"] = details['combinationchartslist'];
        currentChartDetails["customSolidGauge"] = details['customSolidGauge'];;
        currentChartDetails["customfieldFilterObject"] = details['customfieldFilterObject'];;
        currentChartDetails["drillDownFilter"] = ChartInfo.functions.getSavedDrilldownAll();
        currentChartDetails["zAxis"] = [];
        currentChartDetails["colLimit"] = colLimit;
        currentChartDetails["orderType"] = sortType == 'asc' ? 'bottom' : "top";
        currentChartDetails["rankingColumnAggregation"] = yAxisDataTypeArray.length > 0 ? yAxisDataTypeArray[0].yaggType : "";
        currentChartDetails["rankingColumn"] = yAxisNameArray.length > 0 ? yAxisNameArray[0] : "";
        currentChartDetails["trendLine"] = "";
        currentChartDetails["searchText"] = searchText;
        currentChartDetails["datehistogramstate"] = undefined;
        currentChartDetails.chartnumber = that.props.chartnumber;
        if (nlpfilterscols != undefined && nlpfilterscols != "" && nlpfilterscols != null) {
            currentChartDetails["nlpfiltercols"] = nlpfilterscols;
            nlprowlimitobj["nlpfilterscols"] = nlpfilterscols;
        }
        if (nlpFilters != undefined && nlpFilters != "" && nlpFilters != null) {
            currentChartDetails["nlpFilter"] = nlpFilters;
            nlprowlimitobj["nlpfilters"] = nlpFilters;
        }
        ChartInfo.functions.updateChartDetails(that.chartnumber, JSON.stringify(currentChartDetails));
        window.localStorage.setItem("nlpreportdatatoiframe", JSON.stringify(currentChartDetails));
        window.localStorage.setItem("setNLPTableRowLimitDetails", JSON.stringify(nlprowlimitobj));
        ChartInfo.functions.setNLPTableRowLimitDetails(that.chartnumber, nlprowlimitobj);
        var datamodelId = $("#selectdatamodel option:selected").val();
        var bodyParams = new FormData();
        bodyParams.set("datamodelid", datamodelId);
        bodyParams.set("type", "report");
        SendRequest({
            url: "createreportfrommodel",
            body: bodyParams,
            sucessFunction: (response) => {
                $(".spinner").hide();
                var tempReportId = response['tempreportid'];
                var href = window.location.origin + "/" + window.localStorage.getItem("appName") + "/welcome/viewreport/" + btoa(datamodelId) + "/" + btoa(tempReportId) + "?topheader=false";
                document.getElementById("intelligentsearchchartiframe").src = href;
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    validateInput = (e) => {
        let text = $("#birdintelligentsearch").text().trim();
        if (text && InputValidate({ "text": text, "isFrom": "nlp" })) {
            $('#birdintelligentsearch').text(text);
            $('#Intelligencyerror').empty().text(getMessage("ValidationMessage7"));
            $('#Intelligencyerror').show();
            $("#addintelligentchart").addClass("hide");
            if (e && e.type === "textComplete:select") {
                e.preventDefault();
            }
            return false;
        }
        return true;
    }
}