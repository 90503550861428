import React, { useEffect } from 'react'
import ReactDOM from "react-dom";
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import CampaignDownloadModel from './campaignDownloadModel';
const $ = window.$;

const CampaignPreview = (props) => {

    const { id, row } = props;
    const {category} = row;
    useEffect(() => {
        $("#pivotdataview").html(row.content);
        $(".pivotdataviewclose").click(e => $("#datatablepopupmodal").remove())
        $("#campaignpreviewdownload").click((e) => {
            $("#campdownloadletter").remove();
            $("body").append(`<div id="campdownloadletter"></div>`);
            ReactDOM.render(<CampaignDownloadModel id={id} row={row} isSample={false} PopupTitle={category&& category.toLowerCase()==="letter"?"Document Download":"Send Message"}/>,document.getElementById('campdownloadletter'));
            ModalProperties.resize();
            $(window).on("resize", function () {
                ModalProperties.resize();
            });
           // exportLetters(e);
        })

    }, []);
    const  campaignSampleDownload = ()=>{
        $("#campdownloadletter").remove();
            $("body").append(`<div id="campdownloadletter"></div>`);
            ReactDOM.render(<CampaignDownloadModel id={id} row={row} isSample={true} PopupTitle={category&& category.toLowerCase()==="letter"?"Sample Document":"Sample Message"}/>,document.getElementById('campdownloadletter'));
            ModalProperties.resize();
            $(window).on("resize", function () {
                ModalProperties.resize();
            });
    }
    return (
        // <div id="datatablepopupmodal">
            <div className="pivotdataviewwrap custombrandform campaignpreview" style={{ zIndex: "1050" }}>
                <div className="pivotdataview">
                    <div className="pivotdataviewclose" id="pivotdataviewdatapop"><i className="fa fa-times"></i></div>
                    <div className="boc-carousel-area-header-title pivottile" style={{ color: "rgb(39, 55, 163)" }} >Message Information</div>
                    <div className="container-fluid marg-bot-10 nopadding">
                        <div className='row'>
                            <div className='col-xs-12'>
                            <button class="btn btn-birdfuse marg-left-10 pull-right" data-id={id} onClick={campaignSampleDownload}><i class="fa fa-comment-o" data-id={id}></i> Sample</button>
                                <button className="btn btn-birdfuse campaignpreviewdownload pull-right" id="campaignpreviewdownload" data-id={id}>{category && category.toLowerCase()=="letter"?<React.Fragment><i className="fa fa-download" data-id={id}></i>Download</React.Fragment>:<React.Fragment><i className="fa fa-arrow-right marg-righ-5"></i>Send</React.Fragment>}</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-xs-12'>
                                <h4>Preview</h4>
                                <div id="pivotdataview" className='campaignPreviewContainer'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        // </div>
    )
}

export default CampaignPreview;