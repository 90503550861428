import React from "react";
import _ from 'lodash';
import SendRequest from '../SendRequest';
// import $ from 'jquery/dist/jquery';
import SingleReportSaveModalView from './singlereportsavemodal';
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
import DataTablePopupView from "./DataTablePopupView";
import QuickTemplate from "./drilldownfilters.jsx";
import SeriesColorsModalView from "./chartseriescolorsmodalview"
import ReportThemeModal from './reportthememodal.jsx'
import ShareReportModal from './sharereportmodal'
import AlertsView from "./alerts/createalertview"
import ViewScheduleReportModal from './viewschedulereport';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import BarChart from "../js/charts/bar";
import AreaChart from "../js/charts/area";
import BootStrapTable from "../js/charts/bootstraptable";
import SummaryView from "../js/charts/summaryview";
import jstz from 'jstz';
import EmbedReportView from './embedreportview'
import PubSub from 'pubsub-js'
import SingleReportSettingModalView from './singlereportsettingmodal'
import LineChart from "../js/charts/line";
import TextChart from "../js/charts/textchart";
import SparkLineChart from "../js/charts/sparkline"
import HeatMapChart from '../js/charts/heatmap';
import WordCloudChart from "../js/charts/wordcloud"
import CombinationChart from '../js/charts/combinationchart'
import GuageChart from "../js/charts/guage"
import SankeyChart from '../js/charts/sankey';
import BasicPieChart from '../js/charts/basicPie';
import DonutChart from '../js/charts/donutChart';
import BubbleChart from '../js/charts/bubbleChart';
import Leaflet from '../js/charts/leaflet';
import MetricChart from '../js/charts/metric'
import ScatterChart from '../js/charts/scatterChart';
import FunnelChart from '../js/charts/funnel';
import SummaryTableView from '../ViewStoryboard/summarytable/summarytable';
import WorldMap from '../js/charts/worldMap';
import IndiaMap from '../js/charts/Indiamap';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as TabInfo from "../js/helpers/utils/tabinfo";
import * as ChartUtil from "../js/helpers/utils/chartutil";
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import * as viewcollection from "../js/helpers/utils/SavedReportCollection";
import PivotView from './pivot/pivotview'
import BoxplotChart from "../js/charts/boxplot";
import TimeseriesChart from "../js/charts/timeseries";
import SrilankaChart from '../js/charts/srilankachart';
import TrellisChart from '../js/charts/TrellisChart';
import Textchart from '../js/charts/textchart';
import ViewReportAddFilterView from "./viewreportaddfilterview";

const $ = window.$;

export default class ReportBody extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allfeatures: props.allfeatures,
			scheduleid: props.scheduleid,
			date: props.date,
			features: props.allfeatures,
			reportcolumns: props.reportcolumns,
			tileinfo: props.tileinfo,
			multifactentities: props.multifactentities,
			facttables: props.facttables,
			chartnumber: props.chartnumber,
			reportId: props.reportId,
			datamodelId: props.datamodelId,
			isPublished: props.isPublished,
			multifact: props.multifact != undefined && props.multifact != null ? props.multifact : false,
			isForAddInstantFilters: false,
			grid: props.grid,
			isTempRender: false
		};
		this.state.dimcols=[];
		this.state.isEmbeddedReport = false;
		this.state.sharedYes = false;
		if (window.location.pathname.indexOf("embeddedreport") >= 0 || window.location.pathname.indexOf("alertreport") >= 0) {
			this.state.isEmbeddedReport = true;
			sessionStorage.setItem("isDataTableDataExport", false);
		}
		this.state.isSharedReport = false;
		if (window.location.pathname.indexOf("sharereport") >= 0) {
			this.state.isSharedReport = true;
		}
		this.state.isScheduleReport = false;
		if (window.location.pathname.indexOf("schedulereport") >= 0) {
			this.state.isScheduleReport = true;
			sessionStorage.setItem("isDataTableDataExport", false);
		}
		this.state.isPurgedReport = false;
		if (window.location.pathname.indexOf("viewpurgereport") >= 0) {
			this.state.isPurgedReport = true;
			sessionStorage.setItem("isDataTableDataExport", false);
		}
		this.state.isImportedReport = false;
		if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
			this.state.isImportedReport = true;
		}
		this.state.isViewOnlyFlags = false;
		if (viewcollection.getSharedViewOnlyFlag() != undefined) {
			var parsedViewFlag = JSON.parse(viewcollection.getSharedViewOnlyFlag());
			if (parsedViewFlag == "1") {
				this.state.isViewOnlyFlags = true;
				$('.instant-floating-filters').remove();
				$('#filteraccordion').remove();
				sessionStorage.setItem("isDataTableDataExport", false);
				ChartInfo.functions.setisViewOnlyFlag(this.state.isViewOnlyFlags);
			} else {
				sessionStorage.setItem("isDataTableDataExport", true);
				this.state.isViewOnlyFlags = false;
				ChartInfo.functions.setisViewOnlyFlag(this.state.isViewOnlyFlags);
			}
		}
		this.onCloneTile = this.onCloneTile.bind(this);
		this.onDeleteTile = this.onDeleteTile.bind(this);
		this.onMaximisereport = this.onMaximisereport.bind(this);
		this.adjustChartHeight = this.adjustChartHeight.bind(this);
		this.adjustChartHeightEve = PubSub.subscribe('adjustchartheight', this.adjustChartHeightEveCap.bind(this));
		this.onMinimisereport = this.onMinimisereport.bind(this);
		this.modifyChartHeightOnGridResizeEve = PubSub.subscribe('modifyChartHeightOnGridResize', this.modifyChartHeightOnGridResize.bind(this));
		this.viewDataTableEve = PubSub.subscribe('viewDataTable', this.viewDataTableEve.bind(this));
		this.applyfiltesandredrawchartEve = PubSub.subscribe('applyfiltesandredrawchart', this.applyfiltesandredrawchartEve.bind(this));
		this.viewDataTable = this.viewDataTable.bind(this);
		this.viewchartfromchartdata = this.viewchartfromchartdata.bind(this);
		this.viewchartdataforstoryboard = this.viewchartdataforstoryboard.bind(this);
		this.viewchartdata = this.viewchartdata.bind(this);
		this.viewchartsummaryforstoryboard = this.viewchartsummary.bind(this);
		this.togglevisualizationsettings = this.togglevisualizationsettings.bind(this);
		this.viewsummaryTabeEve = PubSub.subscribe('viewSummaryTabe', this.viewSummaryTabeEve.bind(this));
		this.viewdatatableForSummaryTableEve = PubSub.subscribe("viewDataTableForSummaryTable", this.viewDataTableForSummaryTableEve.bind(this));
		this.summaryTableDrilldown = PubSub.subscribe("summarytabledrilldown", this.symmarytabledrilldownEve.bind(this));
		this.summarytabledrilldownwithbreadcrumbEve = PubSub.subscribe("summarytabledrilldownwithbreadcrumb", this.symmarytabledrilldown_breadcrumbEve.bind(this));
		this.columnPreviewEve = PubSub.subscribe("columnpreview", this.ColumnPreview.bind(this));
		this.tileFilterMenuToggle = this.tileFilterMenuToggle.bind(this);
		this.maxdatatables = this.maxdatatables.bind(this);
		this.showdobyselectedcolumns = this.showdobyselectedcolumns.bind(this);
	}
	componentWillUnmount() {

		PubSub.unsubscribe(this.applyfiltesandredrawchartEve);
		PubSub.unsubscribe(this.summaryTableDrilldown);
		PubSub.unsubscribe(this.summarytabledrilldownwithbreadcrumbEve);
		PubSub.unsubscribe(this.viewdatatableForSummaryTableEve);
		PubSub.unsubscribe(this.columnPreviewEve);
		//	this.modifyChartHeightOnGridResizeEve.unsubscribe();
	}

	componentDidMount() {
		$("#root>.spinner").addClass("white story").show();
		if (this.props.chartnumber != undefined && this.props.chartnumber == 0 && (this.props.reportId == undefined || this.props.reportId == "")) {
			if (window.location.pathname.indexOf("importreport") < 0) {
				$("#chartchangers[data-num='" + this.props.chartnumber + "']").click();
			}
			$('.gridspinner, .chartadvancedoptions[data-num="chart' + this.props.chartnumber + '"]').hide();
			setTimeout(function () { $('.spinner').removeClass('white story'); }, 500);
		}
		if (!this.props.isFromTabClick) {
			this.fetchReportData(this.props.tileinfo, this.props.reportcolumns);
			//$(".spinner").removeClass("white").hide();
		} else {
			$('#' + this.props.storyBookel).remove();
			$(".spinner").removeClass("white story").hide();
		}
		$(".gridspinner").hide();
		$('.summarymodal').unbind().click((e) => { PubSub.publish("showSummaryModal", e) });
		$('.singlereport_summarymodal').unbind().click((e) => { PubSub.publish("showSummaryModal", e) });
		//$('.refreshsinglecharts').click((e) => { this.redrawchart() });
	}
	redrawchart = () => {
		var that = this;
		var isSmartInsightsEnable = $('.storyboardtabs .smartinsights').hasClass('active')
		if (!isSmartInsightsEnable) {
			$('.instant-floating-filters').hide();
			//$('.addInstanceFilterData').empty();
			$('.quickfiltercontainer').empty();
			$(".clonetile").removeClass("disabled");
			if ($('.acc-content').length == 0 && $('.addInstanceFilterData').text().trim().length == 0) {
				$('.instant-floating-filters').hide();
			}
			//sessionStorage.removeItem("instantExcludeFilters");
			//sessionStorage.removeItem("instantIncludeFilters");
			//sessionStorage.removeItem("instantExcludeCustomFilters");
			//ChartInfo.setInstanceIncludeFilterData("");
			//ChartInfo.setInstanceFilterData("");
			//ChartInfo.setIncludeFilterData("");
			//ChartInfo.setInstanceFilterData("");
			var drilldown = [];
			ChartInfo.functions.setdrillfitlerArray(drilldown);
			if (window.location.pathname.indexOf("viewstorybook") < 0) {
				TabInfo.functions.updateDrilldownFilterFromSession(TabInfo.functions.getCurrentTabId(), undefined);
			}
			//ChartInfo.getFilters(false);
			var timeZone;
			var TimeZoneSettingsValueForstoryboard = ChartInfo.functions.getTimeZoneSettings("-1");
			if (TimeZoneSettingsValueForstoryboard == "localtimezone") {
				timeZone = jstz.determine().name();
			}
			var isPurgedReport = false;
			if (this.props.multifact == false) {
				let requestHeaders = {
					method: "get",
					headers: new Headers({
						"Content-Type": "application/json"
					})
				};
				SendRequest({
					url: "gettotaldatacount",
					queryString: "?reportId=" + that.props.reportId + " &datamodelId=" + that.props.datamodelId + " &timeZone=" + timeZone + "&isPurgedReport=" + isPurgedReport + "&isImportedReport=" + that.props.isImportedReport + "&isPublished=" + ChartInfo.functions.getisPublished(),
					sucessFunction: response => {
						that.state.datacount = response;
						$(".publishinfo").text(lang["storyboard.datasetcount"] + response.size);
						if (response.lastexecutedtimestamp != undefined && response.lastexecutedtimestamp.length > 0) {
							$(".lastexecutedtimestamp").text(lang["storyboard.lastdatasync"] + response.lastexecutedtimestamp);
						}
					},
					rejectFunction: () => { },
					headers: requestHeaders
				});
			}
			$('.gridspinner').show();
			$(".insightavgsuggestion1 #spinner9").hide();
			$(".simulationselectioncontainer #spinner10").hide();
			$('.showtrending').removeClass('active');
			$('.trendtype').removeClass('showactive');
			//ChartInfo.getFilters(false);

			var tilecount = $('.tileview').length;
			setTimeout(function () {
				_.each(tilecount, function (tilecount, i) {
					if ($('.fullscreenmain .maxanim8').length == 1) {
					} else {
						if ($('.tileview[data-num=' + i + ']').length == 1) {
							var elem = $('.tileview[data-num=' + i + ']');
							var eastbar = elem.find(".ui-resizable-handle.ui-resizable-se").first();
							var pageX = eastbar.offset().left;
							var pageY = eastbar.offset().top;

							(eastbar.trigger("mouseover")
								.trigger({ type: "mousedown", which: 1, pageX: pageX, pageY: pageY })
								.trigger({ type: "mousemove", which: 1, pageX: pageX - 1, pageY: pageY })
								.trigger({ type: "mousemove", which: 1, pageX: pageX, pageY: pageY })
								.trigger({ type: "mouseup", which: 1, pageX: pageX, pageY: pageY }));
						}
					}
				});
				$('.ui-resizable-handle').css("display", "none");
			}, 2000);
			var charts = ChartInfo.functions.getAllChartDetails();
			var legendfilter = [];
			ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
			_.each(charts, function (chart, j) {
				var chart = JSON.parse(chart);
				ChartInfo.functions.setLegendFilterobj(legendfilter, j);
				sessionStorage.removeItem("drilldown" + j);
				ChartInfo.functions.setTempSummaryTableFilterValues(chart["savedSummaryFilterArray"], j);
			});
			PubSub.publish('applyfiltesandredrawchart');
			sessionStorage.removeItem("smartinsightsfilter");
			$(".pie-menu").remove();
			$(".fullscreensample").removeClass("nopie");
			var firstChartNumber = Object.keys(ChartInfo.functions.getAllChartDetails())[0];
			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(firstChartNumber));
			var drillDownAllFilter = _.uniq(chartDetails["drillDownAllFilter"], 'columnname');
			$(".quickfiltercontainer").empty();
			$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer").attr("drilled-chart", this.props.chartnumber);
			_.each(drillDownAllFilter, function (i, j) {
				var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
				if (name === null) {
					name = decodeURIComponent(drillDownAllFilter[j].name);
				}
				else if (name == "undefined") {
					name = decodeURIComponent(drillDownAllFilter[j].displayName);
					if (drillDownAllFilter[j].customfield != undefined) {
						name = drillDownAllFilter[j].customfield.name;
					}
				}
				if (drillDownAllFilter[j].type == "date") {
					name = $(name).find('li:first').attr('column-display-name');
					if (name === undefined) {
						name = drillDownAllFilter[j].displayName;
					}
				}

				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
				ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
				$("#" + dynamicdiv).remove();
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
				// ReactDOM.render(<QuickTemplate columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />,
				//   document.querySelector('.quickfiltercontainer')
				// );
			});
			if (that.state.isSharedReport != true && ChartInfo.functions.getisViewOnlyFlag() != true) {
				$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>");
				$('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) });
			}
		} else {
			var that = this;
			if (that.props.reportId == null || that.props.reportId == undefined) {
				$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
				$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Information');
				$('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
			} else {
				$(".spinner").show();
				$(".cancelmodal").click();
				setTimeout(function () {
					$(".smartinsightsclick").click();
					$(".storyboardtabs ul.tabcontainer").removeClass("hide");
					$(".smartinsightintro-container").removeClass("hide");
					$(".smartinsightdatacontainer").removeClass("hide");
					var globalfilterArray = ChartInfo.functions.getFilterArray();
					// require(['views/viewreport/smartinsights/smartinsightsview'], function(SmartInsightsView){
					// 	$(".smartinsightintro-container").removeClass("hide");
					// 	var smartInsightsView = new SmartInsightsView({
					// 		dimensions : that.props.viewreportCollection.getDimensions(),
					// 		measures:that.props.viewreportCollection.getMeasures(),
					// 		reportId : that.props.reportId,
					// 		datamodelId: that.props.datamodelId,
					// 		features:that.props.features,
					// 		viewreportCollection:that.props.viewreportCollection,
					// 		isPurgedReport:false,
					// 		schemaType:that.state.schemaType,
					// 		globalfilterArray:JSON.stringify(globalfilterArray)
					// 	});		
					// });
					$(".spinner").hide();
				}, 1500);
			}
		}
		//Execute Custom Script
		var userCustomScript = ChartInfo.functions.getUserCustomScript("all");
		userCustomScript = userCustomScript[Object.keys(userCustomScript)];

		function executeUserCustomCode() {
			var emptycharts = $(".maxanim10:empty").length;
			if (emptycharts == 0) {
				setTimeout(function () {
					eval(decodeURI(userCustomScript));
					// try {
					// 	Function(decodeURI(userCustomScript))();
					// } catch (e) {
					// 	console.log(e.message);
					// }
					$(".doby-grid-viewport").on("scroll", function () {
						eval(decodeURI(userCustomScript));
						// try {
						// 	Function(decodeURI(userCustomScript))();
						// } catch (e) {
						// 	console.log(e.message);
						// }
					});
				}, 1000);
			} else {
				setTimeout(function () {
					executeUserCustomCode();
				}, 2000);
			}
		}
		setTimeout(function () { executeUserCustomCode(); }, 2000);
	}

	fetchReportData = () => {
		this.applyfiltesandredrawchart();
		this.applycolorthemes();
	}
	applyfiltesandredrawchartEve(msg, data) {
		
		if (data != undefined) {
			if(this.state.chartnumber == data.currentchartnum){
				this.applyfiltesandredrawchart(data.pos, data.currentchartnum, data.applyattributes, data.dateHistogramInterval, data.calculation, data.isExcludeorIncludeFilters, data.isChartSummaryData,
				data.selecteddobycolumns, data.filterArray, data.navigatefrom, data.isCustomparameterFilter, data.tableInfo);
			}
		} else {
			this.applyfiltesandredrawchart();
		}
		this.applycolorthemes();
		
	}

	applyfiltesandredrawchart(pos, currentchartnum, applyattributes, dateHistogramInterval, calculation, isExcludeorIncludeFilters, isChartSummaryData,
		selecteddobycolumns, filterArray, navigatefrom, isCustomparameterFilter, tableInfo) {
		var that = this.state;
		if (tableInfo != undefined && currentchartnum != that.chartnumber) {
			return;
		}
		/*if(currentchartnum != undefined && currentchartnum != that.chartnumber){
			that.chartnumber = currentchartnum;
		}*/
		if (dateHistogramInterval != undefined) {
			//added below line for storybook datehistogram
			ChartInfo.functions.setDateHistogramState(dateHistogramInterval, currentchartnum);
		}
		$(".streamingnow").removeClass("streamingnow");//remove class to select 15 mins range for Streaming charts
		// main filter
		var globalfilterArray = [];
		var trendstats;
		var isPublished=this.props.isPublished;
		this.chartnumber = that.chartnumber;
		var fragmentArray = window.location.pathname;
		if (fragmentArray != undefined && fragmentArray.indexOf("viewstorybook") > 0) {
			if (TabInfo.functions.getCurrentTabId() != undefined) {
				var tabid = TabInfo.functions.getCurrentTabId();
				if (this.props.chartnumber != undefined && tabid != undefined && tabid != this.props.chartnumber.split("_")[0]) {
					if (navigatefrom != undefined && navigatefrom == "drilldownview") {
						this.state.chartnumber = currentchartnum;
						var tabData = ChartInfo.functions.getStoryBookTabDetails(tabid);
						if (tabData != undefined) {
							this.state.reportId = tabData.reportId;
							this.state.datamodelId = tabData.dataModelId;
							this.state.datamodelId = tabData.dataModelId;
							this.state.reportId = tabData.reportId;
						}
					} else {
						//TabInfo.updateTabFiltersBeforeRefresh(tabid,ChartInfo.getFilters(false));
						$('.refreshtabs').click();
						$('#refreshstorybooktab').removeClass("refreshtabs");
						setTimeout(function () {
							$('#refreshstorybooktab').addClass("refreshtabs");
						}, 5000);
						return;
					}
				} else if (TabInfo.functions.getDrilldownFilterFromSession(tabid) != undefined && Object.keys(TabInfo.functions.getDrilldownFilterFromSession(tabid)).length != 0) {
					var drilldownChartNo = Object.keys(TabInfo.functions.getDrilldownFilterFromSession(tabid))[0];
					ChartInfo.functions.setDrillDownChartNumber(drilldownChartNo);
					if (filterArray == undefined || filterArray == null || filterArray == "null" || filterArray == "" || filterArray == "[]") {
						if (TabInfo.functions.getDrilldownFilterFromSession(tabid)[drilldownChartNo] != undefined) {
							filterArray = JSON.parse(TabInfo.functions.getDrilldownFilterFromSession(tabid)[drilldownChartNo]);
						}
					}
				}
			}
		}
		if (ChartInfo.functions.getChartDetails(that.chartnumber) != undefined) {
			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(that.chartnumber));
			if (chartDetails != undefined && chartDetails["chartType"] != "textchart" && chartDetails["chartType"] != "summarytable" && chartDetails["chartType"] != "pivot" && chartDetails["chartType"] != "doby" && chartDetails["yAxis"] != undefined && chartDetails["yAxis"] != null && chartDetails["yAxis"].length == 0) {
				$('.spinner').hide();
				//inner elements can not be hided hide with chartId
				// $('.gridload').hide();
				$('.gridspinner').hide();
				return;
			}
			var drilldownChartnumber = ChartInfo.functions.getDrillDownChartNumber();
			var datehistogramstate;
			if (chartDetails["chartType"] != undefined && chartDetails["chartType"] != "pivot") {
				datehistogramstate = chartDetails['datehistogramstate']
			}
			if (dateHistogramInterval == undefined && datehistogramstate != undefined) {
				if (datehistogramstate == "reset" || ChartInfo.functions.getDateHistogramState(that.chartnumber) == "reset") {
					dateHistogramInterval = undefined;
				}
				else {
					ChartInfo.functions.setDateHistogramStatus(that.chartnumber, true);
					ChartInfo.functions.setDateHistogramXaxisData(chartDetails['xAxis']);
					//added below line for storybook datehistogram
					dateHistogramInterval = ChartInfo.functions.getDateHistogramState(that.chartnumber)
					if(dateHistogramInterval==undefined){
					dateHistogramInterval = datehistogramstate;
					}
				}
			}
			// } else if (ChartInfo.functions.getDateHistogramStatus(that.chartnumber)) {
			// 	dateHistogramInterval = ChartInfo.functions.getDateHistogramState(that.chartnumber);
			// }
			var applyFilterAll = [];
			if (globalfilterArray != undefined && globalfilterArray.length != 0) {
				if (chartDetails["drillDownFilter"] != undefined) {
					if (chartDetails["drillDownFilter"].length >= 1) {
						var drillDown = chartDetails["drillDownFilter"];
						_.each(drillDown, function (value, index) {
							globalfilterArray = $.grep(globalfilterArray, function (e) { return e.columnname != value.columnname });
						});
						globalfilterArray = globalfilterArray.concat(drillDown);
					}
				}
				if (chartDetails['drillDownAllFilter'] != undefined) {
					globalfilterArray = globalfilterArray.concat(chartDetails['drillDownAllFilter']);
				}
				globalfilterArray = Array.from(new Set(globalfilterArray.map(JSON.stringify))).map(JSON.parse);
			}
			var drillDownAllFilter = [];
			if (globalfilterArray != undefined && globalfilterArray.length == 0) {
				if (chartDetails["drillDownFilter"] != undefined) {
					if (chartDetails["drillDownFilter"].length >= 1) {
						_.each(chartDetails["drillDownFilter"], function (value, index) {
							globalfilterArray[index] = value;
						});
					}
					if (chartDetails["drillDownAllFilter"] != undefined && chartDetails["drillDownAllFilter"].length > 0) {
						globalfilterArray = globalfilterArray.concat(chartDetails["drillDownAllFilter"]);
					}
				} else {
					drillDownAllFilter = chartDetails['drillDownAllFilter'] || [];
					if (drillDownAllFilter != undefined && filterArray != undefined && drillDownAllFilter.length != 0) {
						globalfilterArray = drillDownAllFilter.concat(filterArray);
					} else {
						globalfilterArray = drillDownAllFilter;
					}
				}
			}
			else if (globalfilterArray.length == 0 && chartDetails["drillDownFilter"] != undefined) {
				//DrillDownAll filter  and saved drilldown of single reoprt 	
				var sharereportFilerObjects = "";
				var drillDownAllFilter = chartDetails['drillDownAllFilter'];
				if (drillDownAllFilter != undefined && drillDownAllFilter.length != 0) {
					var that = this.state;
					if (chartDetails["drillDownFilter"] != undefined) {
						if (chartDetails["drillDownFilter"].length >= 1) {
							_.each(chartDetails["drillDownFilter"], function (value, index) {
								globalfilterArray[index] = value;
							});
						}
					}
					globalfilterArray = globalfilterArray.concat(drillDownAllFilter);
					$(".quickfiltercontainer").empty();
					$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer").attr("drilled-chart", that.chartnumber);
					_.each(drillDownAllFilter, function (i, j) {
						var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
						if (name === null) {
							name = decodeURIComponent(drillDownAllFilter[j].name);
						}
						else if (name == "undefined") {
							name = decodeURIComponent(drillDownAllFilter[j].displayName);
							if (drillDownAllFilter[j].customfield != undefined) {
								name = drillDownAllFilter[j].customfield.name;
							}
						}
						if (drillDownAllFilter[j].type == "date") {
							name = $(name).find('li:first').attr('column-display-name');
							if (name === undefined) {
								name = drillDownAllFilter[j].displayName;
							}
						}
						var today = new Date();
						var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
						dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
						$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
						ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
						$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
						$("#" + dynamicdiv).remove();
						$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
						$('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) });
						//$(".quickfiltercontainer").append(that.quicktemplate({ columnDisplayName: name, filterValues: drillDownAllFilter[j].data }));
					});
					if (that.isSharedReport != true && ChartInfo.functions.getisViewOnlyFlag() != true) {
						$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>")
					}
				}
			}
			if (globalfilterArray != undefined && globalfilterArray.length == 0 && chartDetails['drillDownAllFilter'] != undefined && chartDetails["drillDownFilter"] == undefined) {
				if (chartDetails['drillDownAllFilter'].length != 0) {
					var that = this.state;
					var drillDownAllFilter = chartDetails['drillDownAllFilter'];
					globalfilterArray = drillDownAllFilter;
					$(".quickfiltercontainer").empty();
					$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer").attr("drilled-chart", that.chartnumber);
					_.each(drillDownAllFilter, function (i, j) {
						var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
						if (name === null) {
							name = decodeURIComponent(drillDownAllFilter[j].name);
						}
						else if (name == "undefined") {
							name = decodeURIComponent(drillDownAllFilter[j].displayName);
							if (drillDownAllFilter[j].customfield != undefined) {
								name = drillDownAllFilter[j].customfield.name;
							}
						}

						if (drillDownAllFilter[j].type == "date") {
							name = $(name).find('li:first').attr('column-display-name');
							if (name === undefined) {
								name = drillDownAllFilter[j].displayName;
							}
						}
						var today = new Date();
						var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
						dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
						$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
						ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
						$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
						$("#" + dynamicdiv).remove();
						$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
						$('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) });
					});
					if (that.isSharedReport != true && ChartInfo.functions.getisViewOnlyFlag() != true) {
						$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>")
					}
				}
			}
			//single report drilldowns saved
			if (globalfilterArray != undefined && globalfilterArray.length == 0) {
				if (chartDetails["drillDownFilter"] != undefined) {
					if (chartDetails["drillDownFilter"].length >= 1) {
						_.each(chartDetails["drillDownFilter"], function (value, index) {
							globalfilterArray[index] = value
						});
					}
				}
			}
			var customfieldFilterObject = chartDetails['customfieldFilterObject'];

			if (customfieldFilterObject != undefined && customfieldFilterObject.length != 0) {
				_.each(customfieldFilterObject, function (custfield) {
					customfieldFilterObject = $.grep(globalfilterArray, function (value) {
						if (custfield.customfield != undefined) {
							if (custfield.customfield.parameter == value.data) {
								return true;
							}
							else {
								return false;
							}
						}
					});
				});
			}

			if (globalfilterArray != undefined && filterArray != undefined && filterArray != null && filterArray != "null" && filterArray.lenth != 0) {
				globalfilterArray = globalfilterArray.concat(filterArray);
			}
			if (that.isClone && that.filterArray != undefined) {
				globalfilterArray = globalfilterArray.concat(that.filterArray);
				that.isClone = false;
				that.filterArray = undefined;
			}
			if (that.isNewChart && that.filterArray != undefined) {
				globalfilterArray = globalfilterArray.concat(that.filterArray);
				that.isNewChart = false;
				that.filterArray = undefined;
			}
			//customfield drilldown filter
			if (customfieldFilterObject != undefined) {
				_.each(customfieldFilterObject, function (custfield) {
					globalfilterArray = $.grep(globalfilterArray, function (value) {
						if (custfield.customfield != undefined) {
							if (custfield.customfield.parameter == value.data) { return false; }
							else { return true; }
						}
						else { return true; }
					});
				});
				ChartInfo.functions.setCustomFieldsFilterObject(that.chartnumber, customfieldFilterObject);
				globalfilterArray = globalfilterArray.concat(customfieldFilterObject);
			}


			/*if(ChartInfo.functions.getEmbedFilterArray()!=undefined && ChartInfo.functions.getEmbedFilterArray().length>0){
				globalfilterArray=globalfilterArray.concat(ChartInfo.functions.getEmbedFilterArray());
			} */
			if (applyattributes === "applyattributes" && dateHistogramInterval === undefined) {
				ChartInfo.functions.setDateHistogramState(undefined, currentchartnum);
				ChartInfo.functions.setDateHistogramStatus(currentchartnum, false);
			}
			var mainFilter = ChartInfo.functions.getFilterArray();
			if (globalfilterArray == undefined) {
				globalfilterArray = [];
			}
			globalfilterArray = JSON.parse(JSON.stringify(globalfilterArray)).concat(mainFilter);
			var legendFilter = chartDetails["legendfilter"];
			var legendFilterAll = chartDetails["legendfilterall"];
			if (legendFilter !== undefined && legendFilter.length != 0) {
				globalfilterArray = JSON.parse(JSON.stringify(globalfilterArray)).concat(legendFilter);
			}
			if (legendFilterAll !== undefined && legendFilterAll.length != 0) {
				globalfilterArray = JSON.parse(JSON.stringify(globalfilterArray)).concat(legendFilterAll);
			}
			//Added code to handle tile level filters
			var mainFilterCopy = globalfilterArray;
			if (mainFilterCopy != undefined) {
				globalfilterArray = [];
				_.each(mainFilterCopy, function (mfvalue, index1) {
					var mfvalueC = _.clone(mfvalue);
					globalfilterArray.push(mfvalueC);
				})
			}

			var mainFiltersList = [];
			var tileLevelFilters = ChartInfo.functions.getTileLevelFilters(this.props.chartnumber);
			if (tileLevelFilters != undefined && tileLevelFilters[1].chartid == this.props.chartnumber) {
				var tileFiltersList = tileLevelFilters[1].tileFiltersList;
				var storyboardFilters = tileLevelFilters[0].storyboardFilters;
				var isStoryboardFiltersEnabled = tileLevelFilters[0].isStoryboardFiltersEnabled;
				if (globalfilterArray != undefined && storyboardFilters != undefined && storyboardFilters.length > 0) {
					_.each(storyboardFilters, function (sfvalue, index) {
						_.each(globalfilterArray, function (mfvalue, index1) {
							if (sfvalue != undefined && mfvalue != undefined && mfvalue.filterIgnore != undefined && mfvalue.columnname == sfvalue.columnname) {
								mfvalue.filterIgnore = !sfvalue.checked;
							}
						});
					});
				}

				if (isStoryboardFiltersEnabled != undefined && globalfilterArray != undefined && isStoryboardFiltersEnabled == false) {
					_.each(globalfilterArray, function (mfvalue, index1) {
						if (mfvalue != undefined && mfvalue.filterIgnore != undefined) {
							mfvalue.filterIgnore = true;
						}
					});
				}

				if (tileFiltersList != undefined && globalfilterArray != undefined) {
					_.each(tileFiltersList, function (sfvalue, index) {
						_.each(globalfilterArray, function (mfvalue, index1) {
							if (sfvalue != undefined && mfvalue != undefined && mfvalue.filterIgnore != undefined && mfvalue.columnname == sfvalue.columnname) {
								mfvalue.filterIgnore = true;
							}
						});
					});
				}

				if (globalfilterArray != undefined && tileFiltersList != undefined) {
					var mainFiltersList = [];
					var instanceFilteresList = [];
					_.each(globalfilterArray, function (mfvalue, index1) {
						if (mfvalue != undefined && mfvalue.filterIgnore != undefined && !mfvalue.filterIgnore) {
							mainFiltersList.push(mfvalue.columnname);
						} else if (mfvalue != undefined && mfvalue.filterIgnore == undefined && mfvalue.isdrilldownfilter != undefined && mfvalue.summaryTableFilter == undefined) {
							instanceFilteresList.push(mfvalue.columnname);
						}
					})
					var tileFiltersListClone = [];
					_.each(tileFiltersList, function (tfv, index) {
						var tfvCopy = _.clone(tfv);
						if (instanceFilteresList.indexOf(tfvCopy.columnname) == -1) {
							tileFiltersListClone.push(tfvCopy);
						}
					});
					globalfilterArray = globalfilterArray.concat(tileFiltersListClone);
				}
			}
			//fornlpchart filter
			if (chartDetails['drillDownAllFilter'] != undefined && chartDetails['drillDownAllFilter'].length != 0) {
				ChartInfo.functions.setSavedDrilldownAll(chartDetails['drillDownAllFilter']);
			}
			var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(applyattributes);

			//In Filter Ignore Disabled Parameters
			if (customParameterFilterArray != undefined && customParameterFilterArray != "" && customParameterFilterArray.length > 0) {
				customParameterFilterArray = _.filter(customParameterFilterArray, function (customParameterFilter) {
					return !customParameterFilter['filterIgnore'];
				});
			}

			var isExcludeNulls = ChartInfo.functions.getIsExcludeNulls();
			if (navigatefrom != undefined && navigatefrom != "drilldownview") {
				var legendfilter = [];
				ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
				ChartInfo.functions.setLegendFilterobj(legendfilter, that.chartnumber);
				sessionStorage.removeItem("drilldown" + that.chartnumber);
				sessionStorage.removeItem("drilldowndata" + that.chartnumber);
			}
			var convertedValues = null;
			if (isExcludeorIncludeFilters == "yes") {
				$("#spinner" + that.chartnumber).show();
			}
			if (convertedValues != null) {
				var customhierarchy = ChartInfo.functions.getcustomhierarchy();
				_.each(customhierarchy, function (value, index) {
					_.each(value.hierarchelist, function (value1, index1) {
						if (convertedValues.hasOwnProperty(value1.columnName)) {
							value1["displayName"] = convertedValues[value1.columnName];
						}
					});
				});
				ChartInfo.functions.setcustomhierarchy(customhierarchy);
				var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(that.chartnumber));
				_.each(chartDetails['xAxis'], function (value, index) {
					if (convertedValues.hasOwnProperty(value.columnName)) {
						value["displayName"] = convertedValues[value.columnName];
					}
				});
				_.each(chartDetails['yAxis'], function (value, index) {
					if (convertedValues.hasOwnProperty(value.columnName)) {
						value["displayName"] = convertedValues[value.columnName];
					}
				});
				_.each(chartDetails['tooltip'], function (value, index) {
					if (convertedValues.hasOwnProperty(value.columnName)) {
						value["displayName"] = convertedValues[value.columnName];
					}
				});
				if (chartDetails['legend'] != undefined) {
					var columnName = chartDetails['legend']["columnName"];
					if (convertedValues.hasOwnProperty(chartDetails['legend'].columnName)) {
						chartDetails['legend']["displayName"] = convertedValues[columnName];
					}
				}
				// for updating summary table
				_.each(chartDetails["summarytablecolumns"], function (xAxisObject) {
					if (xAxisObject.columnName != undefined && convertedValues[xAxisObject.columnName] != undefined) {
						xAxisObject.displayName = convertedValues[xAxisObject.columnName];
					}
				});
				if (chartDetails["summarytablegroupbys"] != undefined && convertedValues[chartDetails["summarytablegroupbys"].columnName] != undefined) {
					chartDetails["summarytablegroupbys"].displayName = convertedValues[chartDetails["summarytablegroupbys"].columnName];
				}
				_.each(chartDetails["summarytablevalues"], function (yaxisObject) {
					if (convertedValues[yaxisObject.columnName] != undefined) {
						yaxisObject.displayName = convertedValues[yaxisObject.columnName];
					}
				});
				//for updating pivot table
				_.each(chartDetails["rows"], function (row, i) {
					if (convertedValues[row.columnName] != undefined) {
						row.displayName = convertedValues[row.columnName];
					}
				});
				_.each(chartDetails["columns"], function (xAxisObject, i) {
					if (convertedValues[xAxisObject.columnName] != undefined) {
						xAxisObject.displayName = convertedValues[xAxisObject.columnName];
					}
				});
				_.each(chartDetails["values"], function (yaxisObject) {
					if (convertedValues[yaxisObject.columnName] != undefined) {
						yaxisObject.displayName = convertedValues[yaxisObject.columnName];
					}
				});
				ChartInfo.functions.updateChartDetails(that.chartnumber, JSON.stringify(chartDetails));
			}
			convertedValues = JSON.parse(sessionStorage.getItem("renameMeasuresorDimensions"));
			ChartInfo.functions.setSummaryTableDateHistogramStatus(that.chartnumber, false);
			var that = this.state;
			// if (globalfilterArray != undefined) {
			// 	$.each(that.features, function (index, featuresModule) {
			// 		if (featuresModule.moduleName == 'story_board') {
			// 			$.each(featuresModule.features, function (index, feature) {
			// 				if (feature.featureName == 'viewreport_filters' && feature.allow == false) {
			// 					if (viewcollection.getFilterObject != undefined && viewcollection.getFilterObject(that.chartnumber).length != 0) {
			// 						globalfilterArray = viewcollection.getFilterObject(that.chartnumber);
			// 						customParameterFilterArray = viewcollection.getCustomParameterFilterObject(that.chartnumber);
			// 					}

			// 				}
			// 			});
			// 		}
			// 	});
			// }
			if (that.reportRequestFrom != "viewreport") {
				$.each(that.features, function (index, featuresModule) {
					if (featuresModule.moduleName == 'create_report') {
						if (viewcollection.getCustomParameterFilterObject != undefined) {
							if (customParameterFilterArray != undefined && customParameterFilterArray.length == 0 && isCustomparameterFilter != "yes") {
								customParameterFilterArray = viewcollection.getCustomParameterFilterObject(that.chartnumber);
							}
						}
					}
				})
			}
			if (viewcollection.getSharedFilters() != undefined && viewcollection.getSharedFilters().length > 2) {
				var parsedSharedFilters = JSON.parse(viewcollection.getSharedFilters());
				var tempfilterArray = [];
				if (globalfilterArray.length > 0) {
					_.each(globalfilterArray, function (value, index) {
						if (value != undefined) {
							if (value.excludeFilterData != "excludeFilterData") {
								parsedSharedFilters = $.grep(parsedSharedFilters, function (e) { return e.columnname != value.columnname });
							}
						}
					});
				}
				globalfilterArray = globalfilterArray.concat(parsedSharedFilters);
			}
			globalfilterArray = ChartInfo.functions.generateFilterWithEmbededFilters(globalfilterArray, viewcollection.getSharedFilters());
			var appliedSharedFilters = JSON.parse(viewcollection.getSharedFilters());
			var that = this.state;
			_.each(mainFilter, function (filter1, index) {
				if (filter1 != undefined) {
					_.each(appliedSharedFilters, function (filter, index1) {
						var sharedfiltercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
						if (filter1.columndisplayname == sharedfiltercolumndisplayname) {
							if (filter.dataType != undefined) {
								var filtercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
								if (filter.dataType == "number") {
									var fromRange = filter.fromRange,
										toRange = filter.toRange,
										customRange = filter.customfiltervalue;
									var mainfromRange = filter1.fromRange,
										maintoRange = filter1.toRange,
										maincustomRange = filter1.customfiltervalue;
									if (fromRange == undefined && toRange == undefined) {
										fromRange = filter.filtergreaterthanvalue;
										toRange = filter.filterlessthanvalue;
									}
									if (fromRange > maintoRange || toRange < maintoRange) {
										//appliedSharedFilters.splice(index1,1)
										globalfilterArray = appliedSharedFilters;
										console.log(globalfilterArray);
									}
								}
								if (filter.dataType == "string") {
									var sharedYes = false;
									if (filter.customfield == undefined) {
										var filtereddatatext = filter["data"].replace(/\@@/g, ', ');
										var newfiltereddatatext = filtereddatatext.split(",");
										var filtereddatatext1 = filter1["data"].replace(/\@@/g, ', ');
										var newfiltereddatatext1 = filtereddatatext1.split(",");

										_.each(newfiltereddatatext, function (shared, i) {
											var filtereddatatext1 = filter1["data"].replace(/\@@/g, ', ');
											if (filtereddatatext1.includes(shared)) {
												sharedYes = true
											}
										});
										if (sharedYes == false) {
											globalfilterArray = appliedSharedFilters;
											console.log(globalfilterArray);
										}
									} else {
										var filtereddatatext = filter.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
										var newfiltereddatatext = filtereddatatext.split(",");
										var filtereddatatext1 = filter1.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
										var newfiltereddatatext1 = filtereddatatext1.split(",");
									}
								}
								else if (filter.dataType == "date") {
									var fromDateRange = filter.fromRange,
										toDateRange = filter.toRange,
										dateRange = filter.daterange;
									var mainfromRange = filter1.fromRange,
										maintoRange = filter1.toRange,
										maindateRange = filter1.daterange;
									var dates = $('.daterange-center[displayname="' + filter.columndisplayname + '"] span').text();
									var sharedFrom = Date.parse(fromDateRange);
									var mainTo = Date.parse(maintoRange);
									var sharedTo = Date.parse(toDateRange);
									var mainFrom = Date.parse(mainfromRange);
									if (sharedFrom > mainTo || mainFrom > sharedTo) {
										globalfilterArray = appliedSharedFilters;
									}
									//if(customRange== undefined || customRange===""){
									//$('.filteritem-container .acc-content[data-id="'+filtercolumndisplayname+'"] .filteredvalues').text(dates).attr("title",dates);
									//}
								}

							}
						}
					})
				}
			})
			if (applyattributes == undefined && dateHistogramInterval == undefined) {
				ChartInfo.functions.setDateHistogramStatus(that.chartnumber, false);
				if (tableInfo == undefined) {
					//	$("#chart" + that.chartnumber).empty();
				}
				//$('.spinner').hide();
				var that = this.state;
				// if (globalfilterArray != undefined) {
				// 	$.each(that.features, function (index, featuresModule) {
				// 		if (featuresModule.moduleName == 'story_board') {
				// 			$.each(featuresModule.features, function (index, feature) {
				// 				if (feature.featureName == 'viewreport_filters' && feature.allow == false) {
				// 					if (viewcollection.getFilterObject != undefined && viewcollection.getFilterObject(that.chartnumber).length != 0) {
				// 						globalfilterArray = viewcollection.getFilterObject(that.chartnumber);
				// 						customParameterFilterArray = viewcollection.getCustomParameterFilterObject(that.chartnumber);
				// 					}

				// 				}
				// 			});
				// 		}
				// 	});
				// }
				that.pivotfilterArrays = globalfilterArray;
				try {
					var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(that.chartnumber));
					var referencelineobj;
					if (chartType != "polar" && chartType != "windrose" && chartType != "spiderweb") {
						referencelineobj = ChartInfo.functions.getreferenceLineValues(that.chartnumber);
					}
					referencelineobj = referencelineobj != undefined && referencelineobj != null ? referencelineobj : {};

					calculation = chartDetails['trendLine'];
					var xAxisData = "";
					var yAxisData = "";
					var zAxisData = [];
					var combinationchartsData = [];
					var customSolidGaugeData = [];
					var toolTipData = [];
					var xAxisDataType = "";
					var yAxisDataType = "";
					var rangefieldMap = {};
					var xaxisAggregation = "";
					var sortingColumn = chartDetails["sortingColumn"];
					var sortingColumnAggregation = chartDetails["sortingColumnAggregation"];
					var rankingColumn = encodeURIComponent(chartDetails["rankingColumn"]);
					var orderType = chartDetails["orderType"];
					var colLimit = chartDetails["colLimit"];
					var rankingColumnAggregation = chartDetails["rankingColumnAggregation"];
					var aggregation = "";
					var percentileValue = "";
					that.calculation = calculation;
					that.dobyfilterArray = globalfilterArray;
					var sortType = chartDetails["sortType"];
					var metricdatefield = chartDetails["metricdatefield"];
					that.metricprefix = chartDetails["metricprefix"];
					that.metricsuffix = chartDetails["metricsuffix"];
					that.metricfont = chartDetails["metricfont"];
					that.solidgaugemin = chartDetails["solidgaugemin"];
					that.solidgaugemax = chartDetails["solidgaugemax"];
					var metricdatetype = chartDetails["metricdatetype"];
					that.numberformat = chartDetails["numberformat"];
					var mainFilter = that.filterArray;
					var yAxisDisplayName = "";
					ChartInfo.functions.setTrendType(that.calculation);
					_.each(ChartInfo.functions.getRange(), function (e) {
						if (xAxisData == encodeURIComponent(e.name)) {
							xAxisDataType = "customrange";
						}
					})
					var xAxisIdentity = "";
					var convertedValues = JSON.parse(sessionStorage.getItem("convertedvalues"));
					_.each(chartDetails["yAxis"], function (val) {
						var value = val.columnName;
						yAxisData += encodeURIComponent(val.columnName) + ",";
						yAxisDisplayName += val.displayName + ","
						yAxisDataType += val.dataType + ",";
						if (val.dataType == "customaggregatedfield") {
							aggregation += "customaggregatedfield,";
						} else if (val.dataType == "custommeasurehierarchy") {
							aggregation += "custommeasurehierarchy,";
						} else {
							aggregation += val.aggregation.replace(/[^A-Z0-9]/ig, "") + ",";
						}
						percentileValue += val.percentileValue || "25";
						percentileValue += ",";
					});
					if ($('.doby-offcanvas-columnlist').hasClass("show")) {
						$('.doby-offcanvas-columnlist').toggleClass("show");
					}
					yAxisData = yAxisData.substring(0, yAxisData.length - 1);
					yAxisDataType = yAxisDataType.substring(0, yAxisDataType.length - 1);
					aggregation = aggregation.substring(0, aggregation.length - 1);
					percentileValue = percentileValue.substring(0, percentileValue.length - 1);
					yAxisDisplayName = yAxisDisplayName.substring(0, yAxisDisplayName.length - 1);
					if (percentileValue == undefined) {
						percentileValue = "";
					}
					_.each(chartDetails["xAxis"], function (val1) {
						var temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == val1.columnName });
						var ramgeArray = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == val1.columnName });
						if (temp.length > 0) {
							xAxisData += encodeURIComponent(temp[0]["hierarchelist"][0].columnName) + ",";
							xAxisDataType += temp[0]["hierarchelist"][0].dataType + ",";
						} else if (ramgeArray.length > 0) {
							xAxisData += encodeURIComponent(ramgeArray[0].name) + ",";
							rangefieldMap[ramgeArray[0].name] = ramgeArray[0];
							xaxisAggregation = "range";
						} else {
							xAxisData += encodeURIComponent(val1.columnName) + ",";
							xAxisDataType += val1.dataType + ",";
							xAxisIdentity += val1.xaxisidentity || "";
							xAxisIdentity += ",";
						}
					});
					xAxisData = xAxisData.substring(0, xAxisData.length - 1);
					xAxisDataType = xAxisDataType.substring(0, xAxisDataType.length - 1);
					xAxisIdentity = xAxisIdentity.substring(0, xAxisIdentity.length - 1)
					var legendSeries = chartDetails["legend"];
					var chartType;
					if (chartDetails["oldcharttype"] == "heatmap") {
						chartType = chartDetails["oldcharttype"];
					}
					else {
						chartType = chartDetails["chartType"];
					}
					_.each(chartDetails["tooltip"], function (val) {
						var toolTipObject = {};
						toolTipObject["columnName"] = val.columnName;
						toolTipObject["displayName"] = val.displayName;
						toolTipObject["aggType"] = val.aggregation.replace(/[^A-Z0-9]/ig, "");
						toolTipObject["percentileValue"] = val.percentileValue;
						toolTipData.push(toolTipObject);
					});
					_.each(chartDetails["combinationchartslist"], function (val) {
						var combinationchartslist = {};
						combinationchartslist["columnName"] = val.columnName;
						combinationchartslist["chartType"] = val.selectedChartType;
						combinationchartslist["chartaxisposintion"] = val.chartaxisposintion;
						combinationchartsData.push(combinationchartslist);
					});
					_.each(chartDetails["zAxis"], function (val) {
						var zAxisObject = {};
						zAxisObject["columnName"] = val.columnName;
						zAxisObject["aggType"] = val.aggregation.replace(/[^A-Z0-9]/ig, "");
						zAxisObject["percentileValue"] = val.percentileValue;
						zAxisData.push(zAxisObject);
					});
					_.each(chartDetails["customSolidGauge"], function (val) {
						var customSolidGaugeObject = {};
						customSolidGaugeObject["columnName"] = val.columnName;
						customSolidGaugeObject["aggType"] = val.aggregation.replace(/[^A-Z0-9]/ig, "");
						customSolidGaugeObject["percentileValue"] = val.percentileValue;
						customSolidGaugeData.push(customSolidGaugeObject);
					});
					//			var xaxisname = xaxisData.columnName;
					if (convertedValues != null && convertedValues != undefined) {
						_.each(convertedValues, function (convertValue) {
							if (convertValue.columnName == xAxisData) {
								xAxisData = convertValue.columnName;
							}
						});
					}
					var reportId = "";
					// var fragmentArray = Backbone.history.fragment.split("/");
					if (fragmentArray.length == 2) {
						if (fragmentArray[0] === "importreport" || fragmentArray[0] === "viewpurgereport" || fragmentArray[0] === "viewreport" || fragmentArray[0] === "sharereport") {
							reportId = fragmentArray[1];
						} else if (fragmentArray[0] === "embeddedreport" || fragmentArray[0] === "alertreport") {
							var reg = new RegExp('^[0-9]+$');
							if (!reg.test(fragmentArray[1])) {
								reportId = atob(fragmentArray[1]);
							} else {
								reportId = fragmentArray[1];
							}
						}
					}
					if (fragmentArray.length == 1 && fragmentArray[0] == "viewstorybook") {
						reportId = this.options.reportId;
					}
					if (fragmentArray.length == 4) {
						if (fragmentArray[0] === "schedulereport") {
							reportId = fragmentArray[1];
							var reg = new RegExp('^[0-9]+$');
							if (fragmentArray[1] != "" && !reg.test(fragmentArray[1])) {
								reportId = atob(fragmentArray[1]);
							} else {
								reportId = fragmentArray[1];
							}
						}
					}
					var legendcolumnName = "";
					var legendDataType = "";
					if (legendSeries == undefined || legendSeries == null) {
						legendcolumnName = "";
						legendDataType = "";
					}
					else {
						legendcolumnName = legendSeries.columnName;
						legendDataType = legendSeries.dataType;
						var ramgeArray = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == legendSeries.columnName });
						if (ramgeArray.length > 0) {
							rangefieldMap[ramgeArray[0].name] = ramgeArray[0];
							xaxisAggregation = "range";
						}
					}
					if (chartType == "sankey" && (legendSeries == undefined || legendSeries == null)) {
						legendcolumnName = chartDetails["yAxis"][0].columnName;
						legendDataType = chartDetails["yAxis"][0].dataType;
					}


					if (chartType == 'boxplot') {
						legendDataType = legendSeries.dataType;
						legendcolumnName = legendcolumnName;
						if (chartDetails["xAxis"][0] == undefined) {
							xAxisData = "Measure Names";
							xAxisDisplayName = "Measure Names";
							xAxisDataType = "String";
						}
					}
					var that = this.state;

					if (chartType === "summarytable") {
						this.redrawChart(chartDetails, undefined, undefined, undefined, undefined, applyattributes);
						$('.summarymodal[data-num=' + that.chartnumber + ']').removeClass('hide');
					} else {
						$('.breadcrumb[data-num=' + that.chartnumber + ']').remove();
						$('.summarymodal[data-num=' + that.chartnumber + ']').addClass('hide');
					}
					if (chartType === "doby") {
						this.redrawChart(chartDetails, undefined, undefined, undefined, undefined, applyattributes, tableInfo);
						return;
					}
					if (chartType == 'predictionchart' || chartType == "h2o_variableimportance" || chartType == "h2o_scorehistory" || chartType == "h2o_datatable") {
						this.predictionChart(that.chartnumber);
						return true;
					}
					var aggregatedCustomField = ChartInfo.functions.getCustomMeasureField();
					var customFields = sessionStorage.getItem("customFields");
					var temp = JSON.parse(customFields);
					var res;
					_.each(temp, function (customfields) {
						_.each(customfields.parametrFieldsArray, function (value) {
							var customparameter = ChartInfo.functions.getCustomParameter();
							if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
								customparameter = customParameterFilterArray;
							}
							res = $.grep(customparameter, function (e) {
								return e.parametername == value;
							})
							var formula = customfields.formula;
							var scriptcondition = customfields.scriptcondition;
							var fields = customfields.fields;
							var sqlformula = customfields.sqlformula;
							if (res.length != 0) {
								customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
								customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
								customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
								customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
							} else {
								_.each(ChartInfo.functions.getCustomParameter(), function (values) {
									if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
										customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
										customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
										customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
										customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
									}
								});
							}
						});
					});
					var renameMeasuresorDimensions = JSON.parse(sessionStorage.getItem("renameMeasuresorDimensions"));
					var that = this.state;
					if (temp != "null" && temp != null && temp.length > 0 && renameMeasuresorDimensions != null && rankingColumn != null && rankingColumn != undefined && rankingColumn.length > 0 && renameMeasuresorDimensions.hasOwnProperty(rankingColumn)) {
						_.each(temp, function (customfields) {
							if (customfields.name == rankingColumn) {
								//chartDetails["rankingColumn"]=renameMeasuresorDimensions[rankingColumn];
								//rankingColumn=renameMeasuresorDimensions[rankingColumn];
								ChartInfo.functions.updateChartDetails(that.chartnumber, JSON.stringify(chartDetails));
							}
						});
					}
					customFields = JSON.stringify(temp);
					var custommeasurehierarachyarray = ChartInfo.functions.getMeasureHierarchy();
					var timeZone;
					var TimeZoneSettingsValueForstoryboard = ChartInfo.functions.getTimeZoneSettings("-1");
					var tableNameMartric = chartDetails.yAxis[0] != undefined ? chartDetails.yAxis[0]["tableName"] : undefined;
					var displayNameMartric = chartDetails.yAxis[0] != undefined ? chartDetails.yAxis[0]["displayName"] : undefined;
					var TimeZoneSettingsValueForEachChart = ChartInfo.functions.getTimeZoneSettings(that.chartnumber);
					if (TimeZoneSettingsValueForEachChart == undefined) {
						if (TimeZoneSettingsValueForstoryboard == "localtimezone") {
							timeZone = jstz.determine().name();
							that.timeZone = timeZone;
						} else {
							that.timeZone = undefined;
						}
					} else {
						if (TimeZoneSettingsValueForEachChart == "localtimezone") {
							timeZone = jstz.determine().name();
							that.timeZone = timeZone;
						} else {
							that.timeZone = undefined;
						}
					}

					if (that.chartnumber, JSON.stringify(chartDetails) != undefined) {
						var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(that.chartnumber, JSON.stringify(chartDetails)))
						var trendLine = chartDetails["trendLine"];
						if (trendLine != undefined) {
							if (trendLine != "linear" || trendLine != "SMA" || trendLine != "EMA") {
								calculation = trendLine;
							}
						}
					}
					_.each(ChartInfo.functions.getRange(), function (e) {
						if (xAxisData == encodeURIComponent(e.name)) {
							xAxisDataType = "customrange";
						}
					})
					if (!ChartInfo.functions.isTrendSupport(chartType)) {
						calculation = undefined;
					}
					ChartInfo.functions.setTrendType(calculation);
					var referencelineobj;
					if (chartType != "polar" && chartType != "windrose" && chartType != "spiderweb") {
						referencelineobj = ChartInfo.functions.getreferenceLineValues(that.chartnumber);
					}
					referencelineobj = referencelineobj != undefined && referencelineobj != null ? referencelineobj : {};
					var bodymodel = new FormData();
					var storymetaobj = {};
					storymetaobj["xdatesortingEnabled"] = chartDetails["xdatesortingEnabled"]!=undefined ? chartDetails["xdatesortingEnabled"]: false;
					bodymodel.set("storymetaobj", JSON.stringify(storymetaobj));
					if (xAxisIdentity != undefined) {
						bodymodel.set("xAxisIdentity", xAxisIdentity);
					} else {
						bodymodel.set("xAxisIdentity", "");
					}
					bodymodel.set("aggregation", aggregation);
					if (xAxisData == undefined || xAxisData == "") {
						bodymodel.set("xaxis", "Measure Names");
					} else {
						bodymodel.set("xaxis", xAxisData);
					}
					bodymodel.set("xaxisdataType", xAxisDataType);
					bodymodel.set("yaxis", yAxisData);
					bodymodel.set("yAxisDataType", yAxisDataType);
					bodymodel.set("script", customFields);
					bodymodel.set("legend", legendcolumnName);
					bodymodel.set("legendDataType", legendDataType);
					bodymodel.set("datamodelId", that.datamodelId);
					bodymodel.set("indexName", that.indexName);
					bodymodel.set("filterObject", JSON.stringify(globalfilterArray));
					bodymodel.set("reportId", that.reportId);
					bodymodel.set("sortingColumn", sortingColumn != undefined && sortingColumn != null ? sortingColumn : "");
					bodymodel.set("sortingColumnAggregation", sortingColumnAggregation != undefined && sortingColumnAggregation != null ? sortingColumnAggregation : "");
					bodymodel.set("rankingColumn", rankingColumn);
					bodymodel.set("rankingColumnAggregation", rankingColumnAggregation);
					bodymodel.set("sortType", sortType != undefined && sortType != null ? sortType : "");
					bodymodel.set("orderType", orderType);
					bodymodel.set("colLimit", colLimit);
					bodymodel.set("chartType", chartType);
					bodymodel.set("chartnumber", that.chartnumber);
					bodymodel.set("percentileValue", percentileValue);
					bodymodel.set("toolTips", JSON.stringify(toolTipData));
					bodymodel.set("zaxis", JSON.stringify(zAxisData));
					bodymodel.set("customSolidGaugeField", JSON.stringify(customSolidGaugeData));
					bodymodel.set("calculation", calculation);
					bodymodel.set("entities", that.entities);
					bodymodel.set("rangefieldMap", JSON.stringify(rangefieldMap));
					bodymodel.set("customaggregatedscriptfield", JSON.stringify(aggregatedCustomField));
					bodymodel.set("combinationchartsData", JSON.stringify(combinationchartsData));
					var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
					let tempYaxis = chartDetails['yAxis'];
					let finalCompareDateObj = {};
					let compareDateRangeObjtemp = chartDetails["compareDateRangeObj"];
					if(tempYaxis.length>1&&compareDateRangeObjtemp!=null&&compareDateRangeObjtemp["0"]!=null){
						finalCompareDateObj = this.getMutiyComapreDateObj(finalCompareDateObj,chartDetails["compareDateRangeObj"],chartDetails['yAxis']);
					 }else{
						 finalCompareDateObj =  chartDetails["compareDateRangeObj"];
					 }
					 let tempXAxis = chartDetails['xAxis'];
					 if(compareDateRangeObjtemp!=null&&compareDateRangeObjtemp["0"]!=null){
						let yjsObj = compareDateRangeObjtemp["0"];
						let customhierarchy = ChartInfo.functions.getcustomhierarchy();
						var key = Object.keys(yjsObj);
						let xObj = tempXAxis.length>0?tempXAxis[0]:{};
						if (yjsObj != null&&!_.isEmpty(xObj)){
							var ycompareDetailsObj = yjsObj[key];
							let datehistogramstateval = ycompareDetailsObj["compareDateHistogramInterval"] ;
							if(datehistogramstateval==""&&xObj.dataType.toLowerCase()=="date"){
								finalCompareDateObj = null; 
							} 
							if (datehistogramstateval==""&&xObj.dataType.toLowerCase()=='customhierarchy') {
								let hierarchy = $.grep(customhierarchy, function (e) { return e.name == xObj.displayName }); 
								if(hierarchy[0].hierarchelist[0].dataType =="date"){
									finalCompareDateObj = null; 
								}
							}
						}
					 }
					bodymodel.set("compareDateRangeJson", JSON.stringify(comparedaterangecharttpes.indexOf(chartType)!=-1?finalCompareDateObj:null));
					bodymodel.set("custommeasurehierarchyarray", JSON.stringify(custommeasurehierarachyarray));
					bodymodel.set("isExcludeNulls", isExcludeNulls);
					bodymodel.set("timeZone", timeZone);
					bodymodel.set("yAxisDisplayName", yAxisDisplayName);
					bodymodel.set("referencelineobj", JSON.stringify(referencelineobj));
					bodymodel.set("isPublished", ChartInfo.functions.getisPublished());
					if (dateHistogramInterval != undefined && dateHistogramInterval != null && dateHistogramInterval != 'reset' && xAxisDataType == "date") {
						bodymodel.set("xaxisAggregation", "dateHistogram");
						bodymodel.set("dateHistogramInterval", dateHistogramInterval);
					} else if (xaxisAggregation == "range") {
						bodymodel.set("xaxisAggregation", "range");
					} else {
						bodymodel.set("xaxisAggregation", "group");
					}
					if (isChartSummaryData == "yes") {
						calculation = undefined;
					}
					if ((chartType === "metric" || chartType === "solidgauge" || chartType === "fullcirclegauge") && chartType != "textchart" && chartType != "pivot") {

						var metricbody = new FormData();
						metricbody.set("aggregation", aggregation);
						metricbody.set("yaxis", yAxisData);
						metricbody.set("script", bodymodel.get("script"));
						metricbody.set("datamodelId", bodymodel.get("datamodelId"));
						metricbody.set("reportId", bodymodel.get("reportId"));
						metricbody.set("filterObject", JSON.stringify(globalfilterArray));
						metricbody.set("entities", bodymodel.get("entities"));
						metricbody.set("chartType", chartType);
						metricbody.set("metricdatefield", metricdatefield != undefined ? metricdatefield : "");
						metricbody.set("metrictype", metricdatetype != undefined ? metricdatetype : "");
						metricbody.set("percentileValue", bodymodel.get("percentileValue"));
						metricbody.set("customSolidGaugeField", bodymodel.get("customSolidGaugeField"));
						metricbody.set("calculation", calculation);
						metricbody.set("yAxisDataType", yAxisDataType);
						metricbody.set("isExcludeNulls", bodymodel.get("isExcludeNulls"));
						metricbody.set("isPurgedReport", false);
						metricbody.set("isImportedReport", that.isImportedReport);
						metricbody.set("tableName", tableNameMartric);
						metricbody.set("isPublished", ChartInfo.functions.getisPublished());
						metricbody.set("displayName", displayNameMartric);
						metricbody.set("referencelineobj", bodymodel.get("referencelineobj"));
						metricbody.set("xAxisIdentity", bodymodel.get("xAxisIdentity"));
						metricbody.set("customaggregatedscriptfield", bodymodel.get('customaggregatedscriptfield'));

						let requestHeaders = {
							method: "post",
							headers: new Headers({
								"Content-Type": "application/json"
							})
						};
						SendRequest({
							url: "getMetricData",
							body: metricbody,
							sucessFunction: response => {
								var currentState = sessionStorage.getItem("state") != undefined ? sessionStorage.getItem("state") : "viewreport";
								if (currentState != undefined && (currentState == 'viewreport' || currentState == 'viewstorybook')) {
									if (response.hasOwnProperty('error')) {
										$(".spinner").hide();
										$(".gridspinner").hide();
										$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
										$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
										$('.birdmessagebuttons').removeClass('show');
										$('.birdmessagebuttons').addClass('hide');
										$('.birdmessage-center').addClass("message-top-in");
										$('.details').removeClass('hide');
										$('.birdmessage h2').empty().append('Error : ');
										$('.birdmessage h2').append('<span class="errorcode">');
										$('.birdmessage .errorcode').empty().append(response.errorCode);
										$('.birdmessage-info').empty().text(response.error);
										$('.details').addClass('show');
										$('.messagedetails xmp').empty().append(response.errorTrace)
									}
									else {
										that.chartData = response;
										if (globalfilterArray != undefined && globalfilterArray.length != 0) {
											if (chartDetails.legend != undefined) {
												try {
													$(".filterdatacount").text("Filtered Count: " + that.chartData[1][0].filterCount)
												} catch (e) {
													$(".filterdatacount").text("Filtered Count: " + that.chartData[0].filterCount)
												}
											}
											else {
												if (that.chartData[0] != undefined) {
													$(".filterdatacount").text("Filtered Count: " + that.chartData[0].filterCount)
												}
											}
											ChartInfo.functions.setFilterDataCount(that.chartData[0].filterCount);
											$('.filterdatacountrow').removeClass("hide");

											var uniqueNames = [];
											for (var i = 0; i < globalfilterArray.length; i++) {
												if (globalfilterArray[i]["saved"] != "true") {
													if (uniqueNames.indexOf(globalfilterArray[i].columndisplayname) === -1) {
														uniqueNames.push(globalfilterArray[i].columndisplayname);
													}
												}
											}
											var fcount = 0;
											for (var i = 0; i < uniqueNames.length; i++) {
												fcount++;
											}
											if (fcount > 0) {
												$('.singlebadge').remove();
												$('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
												$('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
											} else {
												$('.singlebadge').remove();
											}
										}
										else {
											$('.filterdatacountrow').addClass("hide");
											$(".filterdatacount").text("");
											var customparameteruniquenames = [];
											var fcount = 0;
											var i;
											for (i = 0; i < customParameterFilterArray.length; i++) {
												if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
													customparameteruniquenames.push(customParameterFilterArray[i].parametername);
												}
											}
											for (i = 0; i < customparameteruniquenames.length; i++) {
												fcount++;
											}
											if (fcount > 0) {
												$('.singlebadge').remove();
												$('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
												$('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
											} else {
												$('.singlebadge').remove();
											}
										}
										ChartInfo.functions.setFilterDataCount(that.chartData[0].filterCount);
										$('.filterDatacount').text(that.chartData[0].filterCount);
										/* if(globalfilterArray!=undefined && globalfilterArray.length!=0){
											 if( chartDetails["chartType"]=="countrymap"){
												 chartDetails["chartType"]="pie";
											 }
										 }*/
										this.redrawChart(chartDetails, pos, metricdatetype, dateHistogramInterval, undefined);

									}
								}
							},
							rejectFunction: this.reportDataError,
							headers: requestHeaders
						});

					} else if (that.isScheduleReport && chartType != "textchart" && chartType != "pivot" && chartType != "summarytable") {
						if (globalfilterArray.length === 0) {
							var formData = new FormData();
							formData.set("aggregation", bodymodel.get("aggregation"));
							formData.set("xaxis", bodymodel.get("xaxis"));
							formData.set("xaxisdataType", bodymodel.get("xaxisdataType"));
							formData.set("yaxis", bodymodel.get("yaxis"));
							formData.set("datamodelId", bodymodel.get("datamodelId"));
							formData.set("reportId", bodymodel.get("reportId"));
							formData.set("filterObject", bodymodel.get("filterObject"));
							formData.set("orderType", bodymodel.get("sortType"));
							formData.set("chartType", bodymodel.get("chartType"));
							formData.set("datefield", metricdatefield);
							formData.set("scheduletype", metricdatetype);
							formData.set("schedule", that.isScheduleReport);
							formData.set("sortingColumn", bodymodel.get("sortingColumn"));
							formData.set("rankingColumn", bodymodel.get("rankingColumn"));
							formData.set("sortType", bodymodel.get("sortType"));
							formData.set("sortingColumnAggregation", bodymodel.get("sortingColumnAggregation"));
							formData.set("legend", bodymodel.get("legend"));
							formData.set("legendDataType", bodymodel.get("legendDataType"));
							formData.set("script", bodymodel.get("script"));
							formData.set("colLimit", bodymodel.get("colLimit"));
							formData.set("scheduleid", that.scheduleid?that.scheduleid:0);
							formData.set("date", that.date?that.date:0);
							formData.set("percentileValue", bodymodel.get("percentileValue"));
							formData.set("toolTips", bodymodel.get("toolTips"));
							formData.set("calculation", bodymodel.get("calculation"));
							formData.set("entities", bodymodel.get("entities"));
							formData.set("customaggregatedscriptfield ", bodymodel.get('customaggregatedscriptfield'));
							formData.set("custommeasurehierarchy", bodymodel.get("custommeasurehierarchyarray"));
							formData.set("isPurgedReport", that.isPurgedReport);
							formData.set("isImportedReport", that.isImportedReport);
							formData.set("yAxisDataType", yAxisDataType);
							formData.set("referencelineobj", bodymodel.get("referencelineobj"));
							formData.set("xAxisIdentity", bodymodel.get("xAxisIdentity"));
							formData.set("combinationchartsData", bodymodel.get("combinationchartsData"));
							formData.set("compareDateRangeJson",  bodymodel.get("compareDateRangeJson"));
							formData.set("yAxisDisplayName", bodymodel.get("yAxisDisplayName"));
							formData.set("rangefieldMap", bodymodel.get("rangefieldMap"));
							formData.set("xaxisAggregation", bodymodel.get("xaxisAggregation"));

							let requestHeaders = {
								method: "post",
								headers: new Headers({
									"Content-Type": "application/json"
								})
							};
							SendRequest({
								url: "getScheduleReportURLData",
								body: formData,
								sucessFunction: (response) => {
									that.chartData = response;
									var currentState = sessionStorage.getItem("state");
									if (currentState != undefined && (currentState == 'viewreport' || currentState == 'viewstorybook')) {
										if (globalfilterArray != undefined && globalfilterArray.length != 0) {
											if (chartDetails.legend != undefined) {
												if (that.chartData.length == 3 && chartDetails["chartType"] == "heatmap") {
													$(".filterdatacount").text("Filtered Count: " + that.chartData[2][0].filterCount)
												}
												else {
													if (that.chartData[1][0] != undefined) {
														$(".filterdatacount").text("Filtered Count: " + that.chartData[1][0].filterCount);
													}
												}
											} else {
												$(".filterdatacount").text("Filtered Count: " + that.chartData[0].filterCount)
											}
											var uniqueNames = [];
											var i;
											for (i = 0; i < globalfilterArray.length; i++) {
												if (uniqueNames.indexOf(globalfilterArray[i].columndisplayname) === -1) {
													uniqueNames.push(globalfilterArray[i].columndisplayname);
												}
											}
											var fcount = 0;
											for (i = 0; i < uniqueNames.length; i++) {
												fcount++;
											}
											if (fcount > 0) {
												$('.singlebadge').remove();
												$('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
												$('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
											} else {
												$('.singlebadge').remove();
											}
											var customparameteruniquenames = [];
											for (i = 0; i < customParameterFilterArray.length; i++) {
												if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
													customparameteruniquenames.push(customParameterFilterArray[i].parametername);
												}
											}
											for (i = 0; i < customparameteruniquenames.length; i++) {
												fcount++;
											}
											if (fcount > 0) {
												$('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
												$('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
											}
										} else {
											$(".filterdatacount").text("");
											var customparameteruniquenames = [];
											var fcount = 0;
											var i;
											for (i = 0; i < customParameterFilterArray.length; i++) {
												if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
													customparameteruniquenames.push(customParameterFilterArray[i].parametername);
												}
											}
											for (i = 0; i < customparameteruniquenames.length; i++) {
												fcount++;
											}
											if (fcount > 0) {
												$('.singlebadge').remove();
												$('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
												$('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
											} else {
												$('.singlebadge').remove();
											}
										}
										this.redrawChart(chartDetails);
									}
								},
								rejectFunction: this.reportDataError,
								headers: requestHeaders
							});
						} else {
							var requestbody = new FormData();

							requestbody.set("aggregation", bodymodel.get("aggregation"));
							requestbody.set("xaxis", bodymodel.get("xaxis"));
							requestbody.set("xaxisdataType", bodymodel.get("xaxisdataType"));
							requestbody.set("yaxis", bodymodel.get("yaxis"));
							requestbody.set("legend", bodymodel.get("legend"));
							requestbody.set("legendDataType", bodymodel.get("legendDataType"));
							requestbody.set("datamodelId", bodymodel.get("datamodelId"));
							requestbody.set("indexName", bodymodel.get("indexName"));
							requestbody.set("reportId", bodymodel.get("reportId"));
							requestbody.set("script", bodymodel.get("script"));
							requestbody.set("filterObject", bodymodel.get("filterObject"));
							requestbody.set("sortingColumn", bodymodel.get("sortingColumn"));
							requestbody.set("sortType", bodymodel.get("sortType"));
							requestbody.set("sortingColumnAggregation", bodymodel.get("sortingColumnAggregation"));
							requestbody.set("rankingColumn", bodymodel.get("rankingColumn"));
							requestbody.set("orderType", bodymodel.get("orderType"));
							requestbody.set("rankingColumnAggregation", bodymodel.get("rankingColumnAggregation"));
							requestbody.set("colLimit", bodymodel.get("colLimit"));
							requestbody.set("chartType", bodymodel.get("chartType"));
							requestbody.set("percentileValue", bodymodel.get("percentileValue"));
							requestbody.set("toolTips", bodymodel.get("toolTips"));
							requestbody.set("zaxis", bodymodel.get("zaxis"));
							requestbody.set("isExcludeorIncludeFilters", isExcludeorIncludeFilters);
							requestbody.set("calculation", bodymodel.get("calculation"));
							requestbody.set("entities", bodymodel.get("entities"));
							requestbody.set("customaggregatedscriptfield ", bodymodel.get('customaggregatedscriptfield'));
							requestbody.set("custommeasurehierarchy", bodymodel.get("custommeasurehierarchyarray"));
							requestbody.set("timeZone", bodymodel.get("timeZone"));
							requestbody.set("isPurgedReport", that.isPurgedReport);
							requestbody.set("isImportedReport", that.isImportedReport);
							requestbody.set("referencelineobj", bodymodel.get("referencelineobj"));
							requestbody.set("xAxisIdentity", bodymodel.get("xAxisIdentity"));
							requestbody.set("yAxisDataType", bodymodel.get("yAxisDataType"));
							requestbody.set("xaxisAggregation", bodymodel.get("xaxisAggregation"));
							requestbody.set("rangefieldMap", bodymodel.get("rangefieldMap"));
							requestbody.set("combinationchartsData", bodymodel.get("combinationchartsData"));
							var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
							requestbody.set("compareDateRangeJson", JSON.stringify(comparedaterangecharttpes.indexOf(chartType)!=-1?chartDetails["compareDateRangeObj"]:null));
							requestbody.set("compareDateRangeJson", bodymodel.get("compareDateRangeJson"));
							requestbody.set("isPublished", ChartInfo.functions.getisPublished());
							requestbody.set("storymetaobj",bodymodel.get("storymetaobj"));
							let requestHeaders = {
								method: "post",
								headers: new Headers({
									"Content-Type": "application/json"
								})
							};
							SendRequest({
								url: "getPreviewData",
								body: requestbody,
								sucessFunction: (response) => {
									var currentState = sessionStorage.getItem("state");
									if (currentState != undefined && (currentState == 'viewreport' || currentState == 'viewstorybook')) {
										try {
											var parsedData = response;
											if (parsedData != undefined) {
												response = JSON.parse(decodeURIComponent(parsedData));
											}
										} catch (e) {
										}
										that.chartData = response;

										if (globalfilterArray != undefined && globalfilterArray.length != 0) {
											if (chartDetails.legend != undefined) {
												if (that.chartData.length == 3 && that.chartData[2] != undefined && that.chartData[2][0] != undefined && chartDetails["chartType"] == "heatmap") {
													$(".filterdatacount").text("Filtered Count: " + that.chartData[2][0].filterCount)
												}
												else {
													if (that.chartData[1] != undefined && that.chartData[1][0] != undefined) {
														$(".filterdatacount").text("Filtered Count: " + that.chartData[1][0].filterCount)
													}
												}
											} else {
												$(".filterdatacount").text("Filtered Count: " + that.chartData[0].filterCount)
											}
											var uniqueNames = [];
											var i;
											for (i = 0; i < globalfilterArray.length; i++) {
												if (uniqueNames.indexOf(globalfilterArray[i].columndisplayname) === -1) {
													uniqueNames.push(globalfilterArray[i].columndisplayname);
												}
											}
											var fcount = 0;
											for (i = 0; i < uniqueNames.length; i++) {
												fcount++;
											}
											if (fcount > 0) {
												$('.singlebadge').remove();
												$('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
												$('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
											} else {
												$('.singlebadge').remove();
											}
											var customparameteruniquenames = [];
											for (i = 0; i < customParameterFilterArray.length; i++) {
												if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
													customparameteruniquenames.push(customParameterFilterArray[i].parametername);
												}
											}
											for (i = 0; i < customparameteruniquenames.length; i++) {
												fcount++;
											}
											if (fcount > 0) {
												$('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
												$('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
											}
										} else {
											$(".filterdatacount").text("");
											var customparameteruniquenames = [];
											var fcount = 0;
											var i;
											for (i = 0; i < customParameterFilterArray.length; i++) {
												if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
													customparameteruniquenames.push(customParameterFilterArray[i].parametername);
												}
											}
											for (i = 0; i < customparameteruniquenames.length; i++) {
												fcount++;
											}
											if (fcount > 0) {
												$('.singlebadge').remove();
												$('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
												$('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
											} else {
												$('.singlebadge').remove();
											}
										}
										this.redrawChart(chartDetails);
									}
								},
								rejectFunction: this.reportDataError,
								headers: requestHeaders

							});
						}
					} else if (chartType != "textchart" && chartType != "pivot" && chartType != "summarytable") {
						var requestbody = new FormData();

						requestbody.set("aggregation", bodymodel.get("aggregation"));
						requestbody.set("xaxis", bodymodel.get("xaxis"));
						requestbody.set("xaxisdataType", bodymodel.get("xaxisdataType"));
						requestbody.set("yaxis", bodymodel.get("yaxis"));
						requestbody.set("script", bodymodel.get("script"));
						requestbody.set("legend", bodymodel.get("legend"));
						requestbody.set("legendDataType", bodymodel.get("legendDataType"));
						requestbody.set("datamodelId", bodymodel.get("datamodelId"));
						requestbody.set("indexName", bodymodel.get("indexName"));
						requestbody.set("reportId", bodymodel.get("reportId"));
						requestbody.set("filterObject", bodymodel.get("filterObject"));
						requestbody.set("sortingColumn", bodymodel.get("sortingColumn") != undefined && bodymodel.get("sortingColumn") != null ? bodymodel.get("sortingColumn") : "");
						requestbody.set("sortType", bodymodel.get("sortType") != undefined && bodymodel.get("sortType") != null ? bodymodel.get("sortType") : "");
						requestbody.set("sortingColumnAggregation", bodymodel.get("sortingColumnAggregation") != undefined && bodymodel.get("sortingColumnAggregation") != null ? bodymodel.get("sortingColumnAggregation") : "");
						requestbody.set("rankingColumn", bodymodel.get("rankingColumn"));
						requestbody.set("orderType", bodymodel.get("orderType"));
						requestbody.set("rankingColumnAggregation", bodymodel.get("rankingColumnAggregation"));
						requestbody.set("colLimit", bodymodel.get("colLimit"));
						requestbody.set("chartType", bodymodel.get("chartType"));
						requestbody.set("percentileValue", bodymodel.get("percentileValue"));
						requestbody.set("toolTips", bodymodel.get("toolTips"));
						requestbody.set("zaxis", bodymodel.get("zaxis"));
						requestbody.set("isExcludeorIncludeFilters", isExcludeorIncludeFilters);
						requestbody.set("calculation", bodymodel.get("calculation"));
						requestbody.set("entities", bodymodel.get("entities"));
						requestbody.set("yAxisDataType", yAxisDataType);
						requestbody.set("rangefieldMap", bodymodel.get("rangefieldMap"));
						requestbody.set("xaxisAggregation", bodymodel.get("xaxisAggregation"));
						requestbody.set("customaggregatedscriptfield ", bodymodel.get('customaggregatedscriptfield'));
						requestbody.set("combinationchartsData", bodymodel.get('combinationchartsData'));
						requestbody.set("compareDateRangeJson", bodymodel.get('compareDateRangeJson'));
						requestbody.set("custommeasurehierarchy", bodymodel.get("custommeasurehierarchyarray"));
						requestbody.set("isExcludeNulls", bodymodel.get("isExcludeNulls"));
						requestbody.set("isChartSummaryData", isChartSummaryData);
						requestbody.set("timeZone", bodymodel.get("timeZone"));
						requestbody.set("isPurgedReport", false);
						requestbody.set("isImportedReport", that.isImportedReport);
						requestbody.set("referencelineobj", bodymodel.get("referencelineobj"));
						requestbody.set("xAxisIdentity", bodymodel.get("xAxisIdentity"));
						requestbody.set("yAxisDisplayName", bodymodel.get("yAxisDisplayName"));
						requestbody.set("isPublished", ChartInfo.functions.getisPublished());
						requestbody.set("storymetaobj",bodymodel.get("storymetaobj"));
						let requestHeaders = {
							method: "post",
							headers: new Headers({
								"Content-Type": "application/json"
							})
						};
						SendRequest({
							url: "getPreviewData",
							body: requestbody,
							sucessFunction: (props) => {
								var currentState = sessionStorage.getItem("state") != null ? sessionStorage.getItem("state") : 'viewreport';
								if (currentState != undefined && (currentState == 'viewreport' || currentState == 'viewstorybook')) {
									var response = props;
									var parsedData = response;
									if (response.hasOwnProperty('error')) {
										$(".spinner").hide();
										$(".gridspinner").hide();
										$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
										$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
										$('.birdmessagebuttons').removeClass('show');
										$('.birdmessagebuttons').addClass('hide');
										$('.birdmessage-center').addClass("message-top-in");
										$('.details').removeClass('hide');
										$('.birdmessage h2').empty().append('Error : ');
										$('.birdmessage h2').append('<span class="errorcode">');
										$('.birdmessage .errorcode').empty().append(response.errorCode);
										$('.birdmessage-info').empty().text(response.error);
										$('.details').addClass('show');
										$('.messagedetails xmp').empty().append(response.errorTrace)
									}
									else {
										that.chartData = response
										var trendstats;
										_.each(response, function (response, i) {
											_.each(chartDetails["yAxis"], function (data, j) {
												if (response[chartDetails["yAxis"][j].columnName] != undefined) {
													if (response[chartDetails["yAxis"][j].columnName][1] == "trendstats") {
														trendstats = "trendstats";
													}
												}
											});
										});
										if (that.chartData.length != undefined) {
											if (chartDetails.xAxis.length == 0) {
												var columnName = "Measure Names";
											} else {
												var tableName = chartDetails.xAxis[0]["tableName"];
												var displayName = chartDetails.xAxis[0]["displayName"];
												var columnName = chartDetails.xAxis[0]["columnName"];
												var dataType = chartDetails.xAxis[0]["dataType"];
												var temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == chartDetails.xAxis[0]["columnName"] });
												var rangeobject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == chartDetails.xAxis[0]["columnName"] });
												if (temp.length > 0) {
													var levelmapping = sessionStorage.getItem("customhierarchyforchartnum" + that.chartnumber);
													var obj = temp[0].hierarchelist[0];
													tableName = obj.tableName;
													displayName = obj.displayName;
													columnName = obj.columnName;
													dataType = obj.dataType;
												}

												if (rangeobject.length > 0) {
													var obj = rangeobject[0]["xAxisName"][0];
													tableName = obj.tableName;
													displayName = obj.columnDisplayName;
													columnName = obj.columnName;
												}
											}
											if (chartDetails.legend == undefined && chartType != "sankey") {
												that.chartData = response;
												var filtersForDataBehindChart = [];
												if (chartType == "boxplot") {
													dataArray = [that.chartData[0]];
													$.each(dataArray[0][0].categories, function (index, data) {
														if (data != null && data != undefined && columnName != "Measure Names") {
															var filterObject = {};
															filterObject["dataType"] = dataType;
															filterObject["tablename"] = tableName; //chartDetails.xAxis[0]["tableName"];
															filterObject["columndisplayname"] = displayName; //chartDetails.xAxis[0]["displayName"];
															filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
															if (dataType == "date") {
																var dateString = data["name"];
																filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
															} else {
																filterObject["data"] = data;
															}
															if (dataType == "customrange") {
																filterObject["dataType"] = dataType;
															}
															filtersForDataBehindChart.push(filterObject);
														}
													});
												} else {
													if (that.chartData[0] != undefined) {
														$.each(that.chartData[0].data, function (index, data) {
															if (data != null && data != undefined && columnName != "Measure Names") {
																var filterObject = {};
																filterObject["dataType"] = dataType;
																filterObject["tablename"] = tableName; //chartDetails.xAxis[0]["tableName"];
																filterObject["columndisplayname"] = displayName; //chartDetails.xAxis[0]["displayName"];
																filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
																if (dataType == "date") {
																	var dateString = data["name"];
																	filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
																} else {
																	filterObject["data"] = data["name"];
																}
																if (dataType == "customrange") {
																	filterObject["dataType"] = dataType;
																}
																filtersForDataBehindChart.push(filterObject);
															}
														});
													}
												}
												if (ChartInfo.functions.getIsDirect() == 1) {
													if (ChartInfo.functions.getIsStoredProcedure() != 1) {
														ChartInfo.functions.setFiltersForDataBehindChart(that.chartnumber, filtersForDataBehindChart);
													} else {
														ChartInfo.functions.setFiltersForDataBehindChart(that.chartnumber, []);
													}
												} else {
													ChartInfo.functions.setFiltersForDataBehindChart(that.chartnumber, filtersForDataBehindChart);
												}

											} else {
												var filtersForDataBehindChart = [];
												var dataArray = [];
												if (that.chartData.length == 3) {
													dataArray = [that.chartData[0]];
												} else {
													if (that.chartData[1] != undefined) {
														$.each(that.chartData[1], function (index, data) {
															if (data["data"] != undefined) {
																$.each(data["data"], function (index, data1) {
																	if (data1["name"] != "") {
																		dataArray.push(data1["name"]);
																	}

																});
															}
														});
													}
												}
												dataArray = $.unique(dataArray);
												if (chartType == "boxplot") {
													dataArray = [that.chartData[0]];
													$.each(dataArray[0][0].categories, function (index, data) {
														if (data != null && data != undefined && columnName != "Measure Names") {
															var filterObject = {};
															filterObject["dataType"] = dataType != "number" ? dataType : "string";
															filterObject["tablename"] = tableName; //chartDetails.xAxis[0]["tableName"];
															filterObject["columndisplayname"] = displayName; //chartDetails.xAxis[0]["displayName"];
															filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
															if (dataType == "date") {
																var dateString = data;
																filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
															} else {
																filterObject["data"] = data;
															}
															if (dataType == "customrange") {
																filterObject["dataType"] = dataType;
															}
															filtersForDataBehindChart.push(filterObject);
														}
													});
												} else if (chartType == "heatmap") {

													dataArray = [that.chartData[0]];
													$.each(dataArray[0], function (index, data) {
														if (data != null & data != undefined && columnName != "Measure Names") {
															var filterObject = {};
															filterObject["dataType"] = dataType;
															filterObject["tablename"] = tableName; //chartDetails.xAxis[0]["tableName"];
															filterObject["columndisplayname"] = displayName; //chartDetails.xAxis[0]["displayName"];
															filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
															if (dataType == "date") {
																var dateString = data;
																filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
															} else {
																filterObject["data"] = data;
															}
															if (dataType == "customrange") {
																filterObject["dataType"] = dataType;
															}
															filtersForDataBehindChart.push(filterObject);
														}
													});
												} else {

													$.each(dataArray, function (index, data) {
														if (data != null && data != undefined && columnName != "Measure Names") {
															if (chartDetails.legend != undefined) {
																var filterObject = {};
																filterObject["dataType"] = dataType != "number" ? dataType : "string";
																filterObject["tablename"] = tableName; //chartDetails.xAxis[0]["tableName"];
																filterObject["columndisplayname"] = displayName; //chartDetails.xAxis[0]["displayName"];
																filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
																if (dataType == "date") {
																	filterObject["data"] = data;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
																} else {
																	filterObject["data"] = data;
																}
																if (dataType == "customrange") {
																	filterObject["dataType"] = dataType;
																}
																filtersForDataBehindChart.push(filterObject);
															} else {
																_.each(data, function (value) {
																	var filterObject = {};
																	filterObject["dataType"] = dataType != "number" ? dataType : "string";
																	filterObject["tablename"] = tableName; //chartDetails.xAxis[0]["tableName"];
																	filterObject["columndisplayname"] = displayName; //chartDetails.xAxis[0]["displayName"];
																	filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
																	if (dataType == "date") {
																		filterObject["data"] = value;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
																	} else {
																		filterObject["data"] = value;
																	}
																	if (dataType == "customrange") {
																		filterObject["dataType"] = dataType;
																	}
																	filtersForDataBehindChart.push(filterObject);
																})
															}
														}
													});
												}
												if (ChartInfo.functions.getIsDirect() == 1) {
													if (ChartInfo.functions.getIsStoredProcedure() != 1) {
														ChartInfo.functions.setFiltersForDataBehindChart(that.chartnumber, filtersForDataBehindChart);
													} else {
														ChartInfo.functions.setFiltersForDataBehindChart(that.chartnumber, []);
													}
												} else {
													ChartInfo.functions.setFiltersForDataBehindChart(that.chartnumber, filtersForDataBehindChart);
												}
											}
										}
										if (globalfilterArray != undefined && globalfilterArray.length != 0 && that.chartData != undefined && that.chartData.length != 0) {
											if (chartDetails.legend != undefined) {
												if (that.chartData.length == 3 && that.chartData[2][0] != undefined && chartDetails["chartType"] == "heatmap") {
													$(".filterdatacount").text("Filtered Count: " + that.chartData[2][0].filterCount)
												}
												else if (chartDetails["chartType"] == "boxplot") {
													$(".filterdatacount").text("Filtered Count: " + that.chartData[0][0].filterCount)
												}
												else if (chartDetails["chartType"] == "wordcloud") {
													$(".filterdatacount").text("Filtered Count: " + that.chartData[0].filterCount)
												}
												else {
													if (that.chartData[1] != undefined && that.chartData[1][0] != undefined) {
														$(".filterdatacount").text("Filtered Count: " + that.chartData[1][0].filterCount);
													}
												}
											}
											else {
												if (chartDetails["chartType"] == "sankey" && that.chartData[1] != undefined && that.chartData[1][0] != undefined) {
													$(".filterdatacount").text("Filtered Count: " + that.chartData[1][0].filterCount);
												}
												else if (chartDetails["chartType"] == "boxplot") {
													$(".filterdatacount").text("Filtered Count: " + that.chartData[0][0].filterCount)
												}
												else if (chartDetails["chartType"] == "wordcloud") {
													$(".filterdatacount").text("Filtered Count: " + that.chartData[0].filterCount)
												}
												else {
													$(".filterdatacount").text("Filtered Count: " + that.chartData[0].filterCount)
												}
											}
											if (that.chartData[0] != undefined) {
												ChartInfo.functions.setFilterDataCount(that.chartData[0].filterCount);
											} else {
												ChartInfo.functions.setFilterDataCount(0);
											}
										}
										else {
											$(".filterdatacount").text("");
										}
										/*if(globalfilterArray!=undefined && globalfilterArray.length!=0){
											if( chartDetails["chartType"]=="countrymap"){
												chartDetails["chartType"]="pie";
											}
										}*/
										that.chartnumber = that.chartnumber;
										this.redrawChart(chartDetails, pos, dateHistogramInterval, undefined, trendstats);
										_.each(chartDetails["yAxis"], function (val) {
											if (val.dataType == "custommeasurehierarchy") {
												$(".showtrending[data-num='" + that.chartnumber + "']").hide();
												return
											}
										});
										if (that.isCallingDataSetCountFromViewReportBodyRequired == true && that.multifact == "false") {
											that.getDataSetCount();
										}
									}
								}
							},
							rejectFunction: this.reportDataError,
							headers: requestHeaders
						});



					}
					if (chartType == "textchart" || chartType == "pivot") {
						this.redrawChart(chartDetails, pos, dateHistogramInterval, undefined, undefined, applyattributes);
					}
				} catch (e) {
					console.log(e);
				}
			} else {
				if (that.chartnumber == currentchartnum || currentchartnum == undefined) {
					var convertedValues = JSON.parse(sessionStorage.getItem("renameMeasuresorDimensions"));
					if (convertedValues != null && isChartSummaryData != "yes" && dateHistogramInterval == undefined && navigatefrom != "drilldownview") {
						$('.refreshsinglecharts1[data-num=0]').eq(0).click();
					}
					$('#spinner' + that.chartnumber).show();
					//$("#chart"+that.chartnumber).empty();
					$('.spinner').hide();
					var that = this.state;
					if (globalfilterArray != undefined) {
						$.each(that.features, function (index, featuresModule) {
							if (featuresModule.moduleName == 'story_board') {
								$.each(featuresModule.features, function (index, feature) {
									if (feature.featureName == 'viewreport_filters' && feature.allow == false && globalfilterArray.length == 0) {
										globalfilterArray = viewcollection.getFilterObject(that.chartnumber);
									}
								});
							}
						});
					}
					that.pivotfilterArrays = globalfilterArray;
					try {
						var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(that.chartnumber));
						var xAxisData = "";
						var xAxisIdentity = "";
						var yAxisData = "";
						var zAxisData = [];
						var combinationchartsData = [];
						var customSolidGaugeData = [];
						var toolTipData = [];
						var xAxisDataType = "";
						var yAxisDataType = "";
						var aggregation = "";
						var percentileValue = "";
						var rangefieldMap = {};
						var xaxisAggregation = "";
						var xAxisDisplayName = "";
						var yAxisDisplayName = "";
						var zAxisDisplayName = "";
						var toolTipDisplayName = "";
						var legendcolumnDisplayName = "";
						that.dobyfilterArray = globalfilterArray;
						var sortingColumn = chartDetails["sortingColumn"];
						var sortType = chartDetails["sortType"];
						var sortingColumnAggregation = chartDetails["sortingColumnAggregation"];
						var rankingColumn = encodeURIComponent(chartDetails["rankingColumn"]);
						var orderType = chartDetails["orderType"];
						var rankingColumnAggregation = chartDetails["rankingColumnAggregation"];
						var colLimit = chartDetails["colLimit"];
						var metricdatefield = chartDetails["metricdatefield"];
						that.metricprefix = chartDetails["metricprefix"];
						that.metricsuffix = chartDetails["metricsuffix"];
						that.metricfont = chartDetails["metricfont"];
						that.solidgaugemin = chartDetails["solidgaugemin"];
						that.solidgaugemax = chartDetails["solidgaugemax"];
						var metricdatetype = chartDetails["metricdatetype"];
						that.numberformat = chartDetails["numberformat"];
						var convertedValues = JSON.parse(sessionStorage.getItem("convertedvalues"));
						_.each(chartDetails["yAxis"], function (val) {
							var value = val.columnName;
							yAxisData += encodeURIComponent(val.columnName) + ",";
							yAxisDataType += val.dataType + ",";
							yAxisDisplayName += val.displayName + ",";
							if (val.percentileValue != undefined) {
								percentileValue += val.percentileValue + ",";
							} else {
								percentileValue += "25" + ",";
							}
							if (val.dataType == "customaggregatedfield") {
								aggregation += "customaggregatedfield,";
							} else if (val.dataType == "custommeasurehierarchy") {
								aggregation += "custommeasurehierarchy,";
							} else {
								aggregation += val.aggregation.replace(/[^A-Z0-9]/ig, "") + ",";
							}
						});
						if ($('.doby-offcanvas-columnlist').hasClass("show")) {
							$('.doby-offcanvas-columnlist').toggleClass("show");
						}
						yAxisData = yAxisData.substring(0, yAxisData.length - 1);
						yAxisDataType = yAxisDataType.substring(0, yAxisDataType.length - 1);
						yAxisDisplayName = yAxisDisplayName.substring(0, yAxisDisplayName.length - 1);
						aggregation = aggregation.substring(0, aggregation.length - 1);
						percentileValue = percentileValue.substring(0, percentileValue.length - 1);
						_.each(chartDetails["tooltip"], function (val) {
							var toolTipObject = {};
							toolTipObject["columnName"] = val.columnName;
							toolTipObject["displayName"] = val.displayName;
							toolTipDisplayName += val.displayName + ",";
							toolTipObject["aggType"] = val.aggregation.replace(/[^A-Z0-9]/ig, "");
							toolTipObject["percentileValue"] = val.percentileValue;
							toolTipData.push(toolTipObject);
						});
						_.each(chartDetails["combinationchartslist"], function (val) {
							var combinationchartslist = {};
							combinationchartslist["columnName"] = val.columnName;
							combinationchartslist["chartType"] = val.selectedChartType;
							combinationchartslist["chartaxisposintion"] = val.chartaxisposintion;
							combinationchartsData.push(combinationchartslist);
						});
						_.each(chartDetails["zAxis"], function (val) {
							var zAxisObject = {};
							zAxisObject["columnName"] = val.columnName;
							zAxisDisplayName = val.displayName;
							zAxisObject["aggType"] = val.aggregation.replace(/[^A-Z0-9]/ig, "");
							zAxisObject["percentileValue"] = val.percentileValue;
							zAxisData.push(zAxisObject);
						});
						_.each(chartDetails["customSolidGauge"], function (val) {
							var customSolidGaugeObject = {};
							customSolidGaugeObject["columnName"] = val.columnName;
							customSolidGaugeObject["aggType"] = val.aggregation.replace(/[^A-Z0-9]/ig, "");
							customSolidGaugeObject["percentileValue"] = val.percentileValue;
							customSolidGaugeData.push(customSolidGaugeObject);
						});
						if (dateHistogramInterval != undefined && dateHistogramInterval != null && dateHistogramInterval != "reset") {
							//	chartDetails["xAxis"]=ChartInfo.functions.getDateHistogramXaxisData();
							$(".maxanim1[data-num='" + that.chartnumber + "']").find('.storyboard-data-buttons').hide();
							$(".maxanim1[data-num='" + that.chartnumber + "']").find('.max-data-buttons').hide();
							$(".showtrending[data-num='" + that.chartnumber + "']").hide();
							//$(".chartchanger[data-num='"+that.chartnumber+"']").hide();
						}
						else {
							$(".maxanim1[data-num='" + that.chartnumber + "']").find('.storyboard-data-buttons').show();
							$(".maxanim1[data-num='" + that.chartnumber + "']").find('.max-data-buttons').show();
							$(".chartchanger[data-num='" + that.chartnumber + "']").show();

						}
						_.each(chartDetails["xAxis"], function (val1) {
							var temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == val1.columnName });
							var ramgeArray = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == val1.columnName });
							if (temp.length > 0) {
								xAxisData += encodeURIComponent(temp[0]["hierarchelist"][0].columnName) + ",";
								xAxisDataType += temp[0]["hierarchelist"][0].dataType + ",";
								xAxisDisplayName += temp[0]["hierarchelist"][0].displayName + ",";
							} else if (ramgeArray.length > 0) {
								xAxisData += encodeURIComponent(ramgeArray[0].name) + ",";
								rangefieldMap[ramgeArray[0].name] = ramgeArray[0];
								xaxisAggregation = "range";
							} else {
								xAxisData += encodeURIComponent(val1.columnName) + ",";
								xAxisDataType += val1.dataType + ",";
								xAxisDisplayName += val1.displayName + ",";
								xAxisIdentity += val1.xaxisidentity + ","
							}
						});
						xAxisData = xAxisData.substring(0, xAxisData.length - 1);
						xAxisDataType = xAxisDataType.substring(0, xAxisDataType.length - 1);
						xAxisDisplayName = xAxisDisplayName.substring(0, xAxisDisplayName.length - 1);
						xAxisIdentity = xAxisIdentity.substring(0, xAxisIdentity.length - 1)
						var legendSeries = chartDetails["legend"];
						var chartType;
						if (chartDetails["oldcharttype"] == "heatmap") {
							chartType = chartDetails["oldcharttype"];
						}
						else {
							chartType = chartDetails["chartType"];
						}
						//					var xaxisname = xaxisData.columnName;
						if (convertedValues != null && convertedValues != undefined && chartType != "metric") {
							_.each(convertedValues, function (convertValue) {
								if (convertValue.columnName == xAxisData) {
									xAxisData = convertValue.columnName;
								}
							});
						}
						var reportId = "";

						if (fragmentArray.length == 2) {
							if (fragmentArray[0] === "importreport" || fragmentArray[0] === "viewpurgereport" || fragmentArray[0] === "viewreport" || fragmentArray[0] === "sharereport") {
								reportId = fragmentArray[1];
							} else if (fragmentArray[0] === "embeddedreport" || fragmentArray[0] === "alertreport") {
								var reg = new RegExp('^[0-9]+$');
								if (!reg.test(fragmentArray[1])) {
									reportId = atob(fragmentArray[1]);
								} else {
									reportId = fragmentArray[1];
								}
							}
							//reportId
						}
						if (fragmentArray.length == 4) {
							if (fragmentArray[0] === "schedulereport") {
								reportId = fragmentArray[1];
							}
							var reg = new RegExp('^[0-9]+$');
							if (!reg.test(fragmentArray[1])) {
								reportId = atob(fragmentArray[1]);
							} else {
								reportId = fragmentArray[1];
							}
						}


						// var fragmentArray = Backbone.history.fragment.split("/");
						// var reportId = "";
						// if(fragmentArray.length == 2){
						// 	if(fragmentArray[0] === "importreport" || fragmentArray[0] === "viewpurgereport" || fragmentArray[0] === "viewreport"||fragmentArray[0] === "sharereport"||fragmentArray[0] === "embeddedreport"){
						// 		reportId = fragmentArray[1];	
						// 	}
						// 	//reportId
						// }
						// if(fragmentArray.length == 4){
						// 	if(fragmentArray[0] === "schedulereport"){
						// 		reportId = fragmentArray[1];	
						// 	}
						// }
						var legendcolumnName = "";
						var legendDataType = "";
						if (legendSeries == undefined || legendSeries == null) {
							legendcolumnName = "";
							legendDataType = "";
							legendcolumnDisplayName = "";
						}
						else {
							legendcolumnName = legendSeries.columnName;
							legendcolumnDisplayName = legendSeries.displayName;
							legendDataType = legendSeries.dataType;
							var ramgeArray = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == legendSeries.columnName });
							if (ramgeArray.length > 0) {
								rangefieldMap[ramgeArray[0].name] = ramgeArray[0];
								xaxisAggregation = "range";
							}
						}
						if (chartType == "sankey" && (legendSeries == undefined || legendSeries == null)) {
							legendcolumnName = chartDetails["yAxis"][0].columnName;
							legendcolumnDisplayName = chartDetails["yAxis"][0].displayName
							legendDataType = chartDetails["yAxis"][0].dataType;
						}

						if (chartType == 'boxplot') {
							legendcolumnName = legendcolumnName;
							legendcolumnDisplayName = legendSeries.displayName;
							legendDataType = legendSeries.dataType;
							if (chartDetails["xAxis"][0] == undefined) {
								xAxisData = "Measure Names";
								xAxisDisplayName = "Measure Names";
								xAxisDataType = "String";
							}
						}

						var that = this.state;

						if (chartType === "summarytable") {
							this.redrawChart(chartDetails);
							$('.summarymodal[data-num=' + that.chartnumber + ']').removeClass('hide');
						} else {
							$('.breadcrumb[data-num=' + that.chartnumber + ']').remove();
							$('.summarymodal[data-num=' + that.chartnumber + ']').addClass('hide');
						}
						if (chartType === "doby") {
							this.redrawChart(chartDetails, undefined, undefined, undefined, undefined, applyattributes, tableInfo);
							return;
						}
						if (chartType == 'predictionchart' || chartType == "h2o_variableimportance" || chartType == "h2o_scorehistory" || chartType == "h2o_datatable") {
							this.predictionChart(that.chartnumber);
							return true;
						}
						var aggregatedCustomField = ChartInfo.functions.getCustomMeasureField();
						var customFields = sessionStorage.getItem("customFields");
						var temp = JSON.parse(customFields);
						var res;
						_.each(temp, function (customfields) {
							_.each(customfields.parametrFieldsArray, function (value) {
								var customparameter = ChartInfo.functions.getCustomParameter();
								if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
									customparameter = customParameterFilterArray;
								}
								res = $.grep(customparameter, function (e) {
									return e.parametername == value;
								})
								var formula = customfields.formula;
								var scriptcondition = customfields.scriptcondition;
								var fields = customfields.fields;
								var sqlformula = customfields.sqlformula;
								if (res.length != 0) {
									customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
									customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
									customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
									customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
								} else {
									_.each(ChartInfo.functions.getCustomParameter(), function (values) {
										if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
											customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
											customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
											customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
											customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
										}
									});
								}
							});
						});
						customFields = JSON.stringify(temp);

						var timeZone;
						var TimeZoneSettingsValueForstoryboard = ChartInfo.functions.getTimeZoneSettings("-1");
						var tableNameMartric;
						var displayNameMartric;
						if (chartDetails.yAxis[0] != undefined) {
							tableNameMartric = chartDetails.yAxis[0]["tableName"];
							displayNameMartric = chartDetails.yAxis[0]["displayName"];
						}
						var TimeZoneSettingsValueForEachChart = ChartInfo.functions.getTimeZoneSettings(that.chartnumber);
						if (TimeZoneSettingsValueForEachChart == undefined) {
							if (TimeZoneSettingsValueForstoryboard == "localtimezone") {
								timeZone = jstz.determine().name();
								that.timeZone = timeZone;
							} else {
								that.timeZone = undefined;
							}
						} else {
							if (TimeZoneSettingsValueForEachChart == "localtimezone") {
								timeZone = jstz.determine().name();
								that.timeZone = timeZone;
							} else {
								that.timeZone = undefined;
							}
						}
						if (that.chartnumber, JSON.stringify(chartDetails) != undefined) {
							var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(that.chartnumber, JSON.stringify(chartDetails)))
							var trendLine = chartDetails["trendLine"];
							if (trendLine != undefined) {
								if (trendLine != "linear" || trendLine != "SMA" || trendLine != "EMA") {
									calculation = trendLine;
								}
							}
						}
						if (isChartSummaryData == "yes") {
							calculation = undefined;
						}
						if (!ChartInfo.functions.isTrendSupport(chartType)) {
							calculation = undefined;
						}
						ChartInfo.functions.setTrendType(calculation);
						_.each(ChartInfo.functions.getRange(), function (e) {
							if (xAxisData == encodeURIComponent(e.name)) {
								xAxisDataType = "customrange";
							}
						})
						var referencelineobj;
						if (chartType != "polar" && chartType != "windrose" && chartType != "spiderweb") {
							referencelineobj = ChartInfo.functions.getreferenceLineValues(that.chartnumber);
						}
						referencelineobj = referencelineobj != undefined && referencelineobj != null ? referencelineobj : {};
						var custommeasurehierarachyarray = ChartInfo.functions.getMeasureHierarchy();
						var bodymodel = new FormData();
						var storymetaobj = {};
						storymetaobj["xdatesortingEnabled"] = chartDetails["xdatesortingEnabled"]!=undefined ? chartDetails["xdatesortingEnabled"]: false;
						bodymodel.set("storymetaobj", JSON.stringify(storymetaobj));
						if (xAxisIdentity != undefined) {
							bodymodel.set("xAxisIdentity", xAxisIdentity);
						} else {
							bodymodel.set("xAxisIdentity", "");
						}
						bodymodel.set("aggregation", aggregation);
						if (xAxisData == undefined || xAxisData == "") {
							bodymodel.set("xaxis", "Measure Names");
						} else {
							bodymodel.set("xaxis", xAxisData);
						}

						bodymodel.set("xaxisdataType", xAxisDataType);
						bodymodel.set("yAxisDataType", yAxisDataType);
						bodymodel.set("yaxis", yAxisData);
						bodymodel.set("script", customFields);
						bodymodel.set("legend", legendcolumnName);
						bodymodel.set("legendDataType", legendDataType);
						bodymodel.set("datamodelId", that.datamodelId);
						bodymodel.set("indexName", that.indexName);
						bodymodel.set("filterObject", JSON.stringify(globalfilterArray));
						bodymodel.set("reportId", that.reportId);
						bodymodel.set("sortingColumn", sortingColumn != undefined && sortingColumn != null ? sortingColumn : "");
						bodymodel.set("sortType", sortType);
						bodymodel.set("sortingColumnAggregation", sortingColumnAggregation != undefined && sortingColumnAggregation != null ? sortingColumnAggregation : "");
						bodymodel.set("rankingColumn", rankingColumn);
						bodymodel.set("orderType", orderType);
						bodymodel.set("rankingColumnAggregation", rankingColumnAggregation);
						bodymodel.set("colLimit", colLimit);
						bodymodel.set("chartType", chartType);
						bodymodel.set("percentileValue", percentileValue);
						bodymodel.set("toolTips", JSON.stringify(toolTipData));
						bodymodel.set("zaxis", JSON.stringify(zAxisData));
						bodymodel.set("customSolidGaugeField", JSON.stringify(customSolidGaugeData));
						bodymodel.set("calculation", calculation);
						bodymodel.set("entities", that.entities);
						bodymodel.set("rangefieldMap", JSON.stringify(rangefieldMap));
						bodymodel.set('customaggregatedscriptfield', JSON.stringify(aggregatedCustomField));
						bodymodel.set("combinationchartsData", JSON.stringify(combinationchartsData));
						var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
						let tempYaxis = chartDetails['yAxis'];
						let finalCompareDateObj = {};
						let compareDateRangeObjtemp = chartDetails["compareDateRangeObj"];
						if(tempYaxis.length>1&&compareDateRangeObjtemp!=null&&compareDateRangeObjtemp["0"]!=null){
							finalCompareDateObj = this.getMutiyComapreDateObj(finalCompareDateObj,chartDetails["compareDateRangeObj"],chartDetails['yAxis']);
						 }else{
							 finalCompareDateObj =  chartDetails["compareDateRangeObj"];
						 }
						 let tempXAxis = chartDetails['xAxis'];
						 if(compareDateRangeObjtemp!=null&&compareDateRangeObjtemp["0"]!=null){
							let yjsObj = compareDateRangeObjtemp["0"];
							let customhierarchy = ChartInfo.functions.getcustomhierarchy();
							var key = Object.keys(yjsObj);
							let xObj = tempXAxis.length>0?tempXAxis[0]:{};
							if (yjsObj != null&&!_.isEmpty(xObj)){
								var ycompareDetailsObj = yjsObj[key];
								let datehistogramstateval = ycompareDetailsObj["compareDateHistogramInterval"] ;
								if(datehistogramstateval==""&&xObj.dataType.toLowerCase()=="date"){
									finalCompareDateObj = null; 
								} 
								if (datehistogramstateval==""&&xObj.dataType.toLowerCase()=='customhierarchy') {
									let hierarchy = $.grep(customhierarchy, function (e) { return e.name == xObj.displayName }); 
									if(hierarchy[0].hierarchelist[0].dataType =="date"){
										finalCompareDateObj = null; 
									}
								}
							}
						 }
						bodymodel.set("compareDateRangeJson", JSON.stringify(comparedaterangecharttpes.indexOf(chartType)!=-1?finalCompareDateObj:null));
						bodymodel.set("custommeasurehierarchyarray", JSON.stringify(custommeasurehierarachyarray));
						bodymodel.set("isExcludeNulls", isExcludeNulls);
						bodymodel.set("timeZone", timeZone);
						bodymodel.set("xAxisDisplayName", xAxisDisplayName);
						bodymodel.set("yAxisDisplayName", yAxisDisplayName);
						bodymodel.set("zAxisDisplayName", zAxisDisplayName);
						bodymodel.set("toolTipDisplayName", toolTipDisplayName);
						bodymodel.set("legendcolumnDisplayName", legendcolumnDisplayName);
						bodymodel.set("referencelineobj", JSON.stringify(referencelineobj));

						if (dateHistogramInterval != undefined && dateHistogramInterval != null && dateHistogramInterval != 'reset' && xAxisDataType == "date") {
							bodymodel.set("xaxisAggregation", "dateHistogram");
							bodymodel.set("dateHistogramInterval", dateHistogramInterval);
						} else if (xaxisAggregation == "range") {
							bodymodel.set("xaxisAggregation", "range");
						} else {
							bodymodel.set("xaxisAggregation", "group");
						}
						if ((chartType === "metric" || chartType === "solidgauge" || chartType === "fullcirclegauge") && chartType != "textchart" && chartType != "pivot") {
							var metricbody = new FormData();
							metricbody.set("aggregation", aggregation);
							metricbody.set("yaxis", yAxisData);
							metricbody.set("script", bodymodel.get("script") != undefined && bodymodel.get("script") != null ? bodymodel.get("script") : "");
							metricbody.set("datamodelId", bodymodel.get("datamodelId"));
							metricbody.set("indexName", bodymodel.get("indexName"));
							metricbody.set("reportId", bodymodel.get("reportId"));
							metricbody.set("filterObject", JSON.stringify(globalfilterArray));
							metricbody.set("chartType", chartType);
							metricbody.set("metricdatefield", metricdatefield != undefined ? metricdatefield : "");
							metricbody.set("metrictype", metricdatetype != undefined ? metricdatetype : "");
							metricbody.set("percentileValue", bodymodel.get("percentileValue"));
							metricbody.set("customSolidGaugeField", bodymodel.get("customSolidGaugeField"));
							metricbody.set("entities", bodymodel.get("entities"));
							metricbody.set("calculation", calculation);
							metricbody.set("yAxisDataType", yAxisDataType);
							metricbody.set("isExcludeNulls", bodymodel.get("isExcludeNulls"));
							metricbody.set("isPurgedReport", false);
							metricbody.set("isImportedReport", that.isImportedReport);
							metricbody.set("tableName", tableNameMartric);
							metricbody.set("displayName", displayNameMartric);
							metricbody.set("isPublished", ChartInfo.functions.getisPublished());
							metricbody.set("referencelineobj", bodymodel.get("referencelineobj"));
							metricbody.set("customaggregatedscriptfield", bodymodel.get('customaggregatedscriptfield'));

							let requestHeaders = {
								method: "post",
								headers: new Headers({
									"Content-Type": "application/json"
								})
							};

							SendRequest({
								url: "getMetricData",
								body: metricbody,
								sucessFunction: response => {
									var currentState = sessionStorage.getItem("state") != undefined ? sessionStorage.getItem("state") : "viewreport";
									if (currentState != undefined && (currentState == 'viewreport' || currentState == 'viewstorybook')) {
										if (response.hasOwnProperty('error')) {
											$(".spinner").hide();
											$(".gridspinner").hide();
											$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
											$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
											$('.birdmessagebuttons').removeClass('show');
											$('.birdmessagebuttons').addClass('hide');
											$('.birdmessage-center').addClass("message-top-in");
											$('.details').removeClass('hide');
											$('.birdmessage h2').empty().append('Error : ');
											$('.birdmessage h2').append('<span class="errorcode">');
											$('.birdmessage .errorcode').empty().append(response.errorCode);
											$('.birdmessage-info').empty().text(response.error);
											$('.details').addClass('show');
											$('.messagedetails xmp').empty().append(response.errorTrace)
										}
										else {
											that.chartData = response;
											if (globalfilterArray != undefined && globalfilterArray.length != 0) {
												if (chartDetails.legend != undefined) {
													$(".filterdatacount").text("Filtered Count: " + that.chartData[1][0].filterCount)
												}
												else {
													if (that.chartData[0] != undefined) {
														$(".filterdatacount").text("Filtered Count: " + that.chartData[0].filterCount)
													}
												}
												ChartInfo.functions.setFilterDataCount(that.chartData[0].filterCount);
											}
											else {
												$(".filterdatacount").text("");
												var customparameteruniquenames = [];
												var fcount = 0;
												var i;
												for (i = 0; i < customParameterFilterArray.length; i++) {
													if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
														customparameteruniquenames.push(customParameterFilterArray[i].parametername);
													}
												}
												for (i = 0; i < customparameteruniquenames.length; i++) {
													fcount++;
												}
												if (fcount > 0) {
													$('.singlebadge').remove();
													$('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
													$('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
												} else {
													$('.singlebadge').remove();
												}
											}
											/*if(globalfilterArray!=undefined && globalfilterArray.length!=0){
												if( chartDetails["chartType"]=="countrymap"){
													chartDetails["chartType"]="pie";
												}
											}*/
											this.redrawChart(chartDetails, pos, metricdatetype, dateHistogramInterval);
										}
									}
								},
								rejectFunction: this.reportDataError,
								headers: requestHeaders
							});

						} else if (chartType != "textchart" && chartType != "pivot" && chartType != "summarytable") {
							var body = new FormData();

							body.set("aggregation", bodymodel.get("aggregation"));
							body.set("xaxis", bodymodel.get("xaxis"));
							body.set("xaxisdataType", bodymodel.get("xaxisdataType"));
							body.set("yaxis", bodymodel.get("yaxis"));
							body.set("script", bodymodel.get("script"));
							body.set("legend", bodymodel.get("legend"));
							body.set("legendDataType", bodymodel.get("legendDataType"));
							body.set("datamodelId", bodymodel.get("datamodelId"));
							body.set("indexName", bodymodel.get("indexName"));
							body.set("reportId", bodymodel.get("reportId"));
							body.set("filterObject", bodymodel.get("filterObject"));
							body.set("sortingColumn", bodymodel.get("sortingColumn"));
							body.set("sortType", bodymodel.get("sortType"));
							body.set("sortingColumnAggregation", bodymodel.get("sortingColumnAggregation"));
							body.set("rankingColumn", bodymodel.get("rankingColumn"));
							body.set("orderType", bodymodel.get("orderType"));
							body.set("rankingColumnAggregation", bodymodel.get("rankingColumnAggregation"));
							body.set("colLimit", bodymodel.get("colLimit"));
							body.set("chartType", bodymodel.get("chartType"));
							body.set("percentileValue", bodymodel.get("percentileValue"));
							body.set("xaxisAggregation", bodymodel.get("xaxisAggregation"));
							body.set("dateHistogramInterval", bodymodel.get("dateHistogramInterval"));
							body.set("toolTips", bodymodel.get("toolTips"));
							body.set("zaxis", bodymodel.get("zaxis"));
							body.set("isExcludeorIncludeFilters", isExcludeorIncludeFilters != undefined && isExcludeorIncludeFilters != null ? isExcludeorIncludeFilters : "");
							body.set("calculation", bodymodel.get("calculation"));
							body.set("entities", bodymodel.get("entities"));
							body.set("yAxisDataType", yAxisDataType);
							body.set("rangefieldMap", bodymodel.get("rangefieldMap"));
							body.set("customaggregatedscriptfield", bodymodel.get('customaggregatedscriptfield'));
							body.set("combinationchartsData", bodymodel.get('combinationchartsData'));
							body.set("compareDateRangeJson", bodymodel.get("compareDateRangeJson"));
							body.set("custommeasurehierarchy", bodymodel.get("custommeasurehierarchyarray"));
							body.set("isExcludeNulls", bodymodel.get("isExcludeNulls"));
							body.set("isChartSummaryData", isChartSummaryData);
							body.set("timeZone", bodymodel.get("timeZone"));
							body.set("isPurgedReport", false);
							body.set("isImportedReport", that.isImportedReport);
							body.set("xAxisDisplayName", bodymodel.get("xAxisDisplayName"));
							body.set("yAxisDisplayName", bodymodel.get("yAxisDisplayName"));
							body.set("zAxisDisplayName", bodymodel.get("zAxisDisplayName"));
							body.set("toolTipDisplayName", bodymodel.get("toolTipDisplayName"));
							body.set("legendcolumnDisplayName", bodymodel.get("legendcolumnDisplayName"));
							body.set("referencelineobj", bodymodel.get("referencelineobj"));
							body.set("xAxisIdentity", bodymodel.get("xAxisIdentity"));
							body.set("isPublished", ChartInfo.functions.getisPublished());
							body.set("storymetaobj",bodymodel.get("storymetaobj"));
							let requestHeaders = {
								method: "post",
								headers: new Headers({
									"Content-Type": "application/json"
								})
							};
							SendRequest({
								url: "getPreviewData",
								body: body,
								sucessFunction: props => {
									var response = props;
									var currentState = sessionStorage.getItem("state") != undefined && sessionStorage.getItem("state") != null ? sessionStorage.getItem("state") : "viewreport";
									if (currentState != undefined && (currentState == 'viewreport' || currentState == 'viewstorybook')) {
										try {
											var parsedData = response;
											if (parsedData != undefined) {
												response = JSON.parse(decodeURIComponent(parsedData));
											}
										} catch (e) {
										}
										if (response.hasOwnProperty('error')) {
											$(".spinner").hide();
											$(".gridspinner").hide();
											$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
											$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
											$('.birdmessagebuttons').removeClass('show');
											$('.birdmessagebuttons').addClass('hide');
											$('.birdmessage-center').addClass("message-top-in");
											$('.details').removeClass('hide');
											$('.birdmessage h2').empty().append('Error : ');
											$('.birdmessage h2').append('<span class="errorcode">');
											$('.birdmessage .errorcode').empty().append(response.errorCode);
											$('.birdmessage-info').empty().text(response.error);
											$('.details').addClass('show');
											$('.messagedetails xmp').empty().append(response.errorTrace);
											//	ChartUtils.unbindEvents(that.chartnumber);
											that.chartData = response;
										}
										else {
											if (isChartSummaryData == "yes") {
												if (that.chartnumber == undefined) {
													that.chartnumber = that.chartnumber;
												}
												var bootObj = {};
												bootObj["currentChartNumber"] = that.chartnumber;
												bootObj["response"] = response;
												var bootStrapTable = new SummaryView(bootObj);
												bootStrapTable.render();
												//	ChartUtils.drawDataTable(response, that.chartnumber,selecteddobycolumns,true,undefined);
												$(".chartchanger[data-num='" + that.chartnumber + "']").hide();
												$("#spinner" + that.chartnumber).hide();
												$("#chart" + that.chartnumber).find(".doby-grid").addClass('databehindchart');
											} else {
												that.chartData = response;
												var trendstats;
												_.each(response, function (response, i) {
													_.each(chartDetails["yAxis"], function (data, j) {
														if (response[chartDetails["yAxis"][j].columnName] != undefined) {
															if (response[chartDetails["yAxis"][j].columnName][1] == "trendstats") {
																trendstats = "trendstats";
															}
														}
													});
												});
												if (that.chartData.length != undefined) {
													if (chartDetails.xAxis != undefined && chartDetails.xAxis.length == 0) {
														var columnName = "Measure Names";
													} else {
														var tableName;
														var dataType;
														var displayName;
														var columnName;
														var temp;
														var rangeobject;
														if (chartDetails.xAxis != undefined && chartDetails.xAxis[0] != undefined) {
															tableName = chartDetails.xAxis[0]["tableName"];
															displayName = chartDetails.xAxis[0]["displayName"];
															columnName = chartDetails.xAxis[0]["columnName"];
															dataType = chartDetails.xAxis[0]["dataType"];
															temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == chartDetails.xAxis[0]["columnName"] });
															rangeobject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == chartDetails.xAxis[0]["columnName"] });
														}
														if (temp != undefined && temp.length > 0) {
															var levelmapping = sessionStorage.getItem("customhierarchyforchartnum" + that.chartnumber);
															var obj = temp[0].hierarchelist[0];
															tableName = obj.tableName;
															displayName = obj.displayName;
															columnName = obj.columnName;
															dataType = obj.dataType;
														}
														if (rangeobject != undefined && rangeobject.length > 0) {
															var obj = rangeobject[0]["xAxisName"][0];
															tableName = obj.tableName;
															displayName = obj.columnDisplayName;
															columnName = obj.columnName;
														}
													}
													if (chartDetails.legend == undefined && chartType != "sankey") {
														var filtersForDataBehindChart = [];
														if (chartType == "boxplot") {
															dataArray = [that.chartData[0]];
															$.each(dataArray[0][0].categories, function (index, data) {
																if (data != null && data != undefined && columnName != "Measure Names") {
																	var filterObject = {};
																	filterObject["dataType"] = dataType;
																	filterObject["tablename"] = tableName; //chartDetails.xAxis[0]["tableName"];
																	filterObject["columndisplayname"] = displayName; //chartDetails.xAxis[0]["displayName"];
																	filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
																	if (dataType == "date") {
																		var dateString = data["name"];
																		filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
																	} else {
																		filterObject["data"] = data;
																	}
																	if (dataType == "customrange") {
																		filterObject["dataType"] = dataType;
																	}
																	filtersForDataBehindChart.push(filterObject);
																}
															});
														} else {
															if (that.chartData.length != 0) {
																$.each(that.chartData[0].data, function (index, data) {
																	if (data != null && data != undefined && columnName != "Measure Names") {
																		var filterObject = {};
																		filterObject["dataType"] = dataType;
																		filterObject["tablename"] = tableName; //chartDetails.xAxis[0]["tableName"];
																		filterObject["columndisplayname"] = displayName; //chartDetails.xAxis[0]["displayName"];
																		filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
																		if (dataType == "date") {
																			var dateString = data["name"];
																			filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
																		} else {
																			filterObject["data"] = data["name"];
																		}
																		if (dataType == "customrange") {
																			filterObject["dataType"] = dataType;
																		}
																		filtersForDataBehindChart.push(filterObject);
																	}
																});
															}
														}
														if (ChartInfo.functions.getIsDirect() == 1) {
															if (ChartInfo.functions.getIsStoredProcedure() != 1) {
																ChartInfo.functions.setFiltersForDataBehindChart(that.chartnumber, filtersForDataBehindChart);
															} else {
																ChartInfo.functions.setFiltersForDataBehindChart(that.chartnumber, []);
															}
														} else {
															ChartInfo.functions.setFiltersForDataBehindChart(that.chartnumber, filtersForDataBehindChart);
														}
													} else {
														var filtersForDataBehindChart = [];
														var dataArray = [];
														if (that.chartData.length == 3) {
															dataArray = [that.chartData[0]];
														} else {
															if (that.chartData[1] != undefined) {
																$.each(that.chartData[1], function (index, data) {
																	if (data["data"] != undefined) {
																		$.each(data["data"], function (index, data1) {
																			if (data1["name"] != "") {
																				dataArray.push(data1["name"]);
																			}
																		});
																	}
																});
															}
														}
														dataArray = $.unique(dataArray);
														if (chartType == "boxplot") {
															dataArray = [that.chartData[0]];
															$.each(dataArray[0][0].categories, function (index, data) {
																if (data != null & data != undefined && columnName != "Measure Names") {
																	var filterObject = {};
																	filterObject["dataType"] = dataType;
																	filterObject["tablename"] = tableName; //chartDetails.xAxis[0]["tableName"];
																	filterObject["columndisplayname"] = displayName; //chartDetails.xAxis[0]["displayName"];
																	filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
																	if (dataType == "date") {
																		var dateString = data;
																		filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
																	} else {
																		filterObject["data"] = data;
																	}
																	if (dataType == "customrange") {
																		filterObject["dataType"] = dataType;
																	}
																	filtersForDataBehindChart.push(filterObject);
																}
															});
														} else if (chartType == "heatmap") {
															dataArray = [that.chartData[0]];
															$.each(dataArray[0], function (index, data) {
																if (data != null & data != undefined && columnName != "Measure Names") {
																	var filterObject = {};
																	filterObject["dataType"] = dataType;
																	filterObject["tablename"] = tableName; //chartDetails.xAxis[0]["tableName"];
																	filterObject["columndisplayname"] = displayName; //chartDetails.xAxis[0]["displayName"];
																	filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
																	if (dataType == "date") {
																		var dateString = data;
																		filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
																	} else {
																		filterObject["data"] = data;
																	}
																	if (dataType == "customrange") {
																		filterObject["dataType"] = dataType;
																	}
																	filtersForDataBehindChart.push(filterObject);
																}
															});
														} else {

															$.each(dataArray, function (index, data, value) {
																if (data != null & data != undefined && columnName != "Measure Names") {
																	if (chartDetails.legend != undefined) {
																		var filterObject = {};
																		filterObject["dataType"] = dataType;
																		filterObject["tablename"] = tableName; //chartDetails.xAxis[0]["tableName"];
																		filterObject["columndisplayname"] = displayName; //chartDetails.xAxis[0]["displayName"];
																		filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
																		if (dataType == "date") {
																			filterObject["data"] = data;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
																		} else {
																			filterObject["data"] = data;
																		}
																		if (dataType == "customrange") {
																			filterObject["dataType"] = dataType;
																		}
																		filtersForDataBehindChart.push(filterObject);
																	} else {
																		_.each(data, function (value) {
																			var filterObject = {};
																			filterObject["dataType"] = dataType;
																			filterObject["tablename"] = tableName; //chartDetails.xAxis[0]["tableName"];
																			filterObject["columndisplayname"] = displayName; //chartDetails.xAxis[0]["displayName"];
																			filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
																			if (dataType == "date") {
																				filterObject["data"] = value;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
																			} else {
																				filterObject["data"] = value;
																			}
																			if (dataType == "customrange") {
																				filterObject["dataType"] = dataType;
																			}
																			filtersForDataBehindChart.push(filterObject);
																		});
																	}

																}
															});
														}

														if (ChartInfo.functions.getIsDirect() == 1) {
															if (ChartInfo.functions.getIsStoredProcedure() != 1) {
																ChartInfo.functions.setFiltersForDataBehindChart(that.chartnumber, filtersForDataBehindChart);
															} else {
																ChartInfo.functions.setFiltersForDataBehindChart(that.chartnumber, []);
															}
														} else {
															ChartInfo.functions.setFiltersForDataBehindChart(that.chartnumber, filtersForDataBehindChart);
														}
													}
												}
												if (globalfilterArray != undefined && globalfilterArray.length != 0 && that.chartData != undefined && that.chartData.length != 0) {
													if (chartDetails.legend != undefined) {
														if (that.chartData.length == 3 && chartDetails["chartType"] == "heatmap") {
															$(".filterdatacount").text("Filtered Count: " + that.chartData[2][0].filterCount)
														}
														else if (chartDetails["chartType"] == "boxplot") {
															$(".filterdatacount").text("Filtered Count: " + that.chartData[0][0].filterCount)
														}
														else if (chartDetails["chartType"] == "wordcloud") {
															$(".filterdatacount").text("Filtered Count: " + that.chartData[0].filterCount)
														}
														else {
															if (that.chartData[1] != undefined && that.chartData[1][0] != undefined) {
																$(".filterdatacount").text("Filtered Count: " + that.chartData[1][0].filterCount)
															}
														}
													}
													else {
														if (chartDetails["chartType"] == "sankey" && that.chartData[1] != undefined && that.chartData[1][0] != undefined) {
															$(".filterdatacount").text("Filtered Count: " + that.chartData[1][0].filterCount)
														}
														else if (chartDetails["chartType"] == "boxplot") {
															$(".filterdatacount").text("Filtered Count: " + that.chartData[0][0].filterCount)
														}
														else if (chartDetails["chartType"] == "wordcloud") {
															$(".filterdatacount").text("Filtered Count: " + that.chartData[0].filterCount)
														}
														else {
															$(".filterdatacount").text("Filtered Count: " + that.chartData[0].filterCount)
														}
													}
													if (that.multifact != 'true') {
														ChartInfo.functions.setFilterDataCount(that.chartData[0].filterCount);
													}
												}
												else {
													$(".filterdatacount").text("");
													var customparameteruniquenames = [];
													var fcount = 0;
													var i;
													for (i = 0; i < customParameterFilterArray.length; i++) {
														if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
															customparameteruniquenames.push(customParameterFilterArray[i].parametername);
														}
													}
													for (i = 0; i < customparameteruniquenames.length; i++) {
														fcount++;
													}
													if (fcount > 0) {
														$('.singlebadge').remove();
														$('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
														$('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
													} else {
														$('.singlebadge').remove();
													}
												}
												/*if(globalfilterArray!=undefined && globalfilterArray.length!=0){
													if( chartDetails["chartType"]=="countrymap"){
														chartDetails["chartType"]="pie";
													}
												}*/
												this.redrawChart(chartDetails, pos, dateHistogramInterval, undefined, trendstats, applyattributes);
												_.each(chartDetails["yAxis"], function (val) {
													if (val.dataType == "custommeasurehierarchy") {
														$(".showtrending[data-num='" + that.chartnumber + "']").hide();
														return
													}
												});
												if (!ChartInfo.functions.isTrendSupport(chartType)) {
													$(".showtrending[data-num='" + that.chartnumber + "']").hide();
												}
												var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
												let compareDateRangeObj = chartDetails!=undefined && chartDetails["compareDateRangeObj"]!=null?chartDetails["compareDateRangeObj"]:null;
												let tempYaxis = chartDetails!=undefined?chartDetails["yAxis"]:[];
												let checkcondition = false;
												if(compareDateRangeObj!=null && compareDateRangeObj.hasOwnProperty("0") && compareDateRangeObj["0"]!=null){
													let yjsObj = compareDateRangeObj["0"]; 
													_.each(tempYaxis,function(val,index){
													if(val.columnName!=undefined&&val.columnName!=null&&yjsObj.hasOwnProperty(val.columnName)){
														checkcondition = true;
													}
												});
												}
												if(checkcondition&&comparedaterangecharttpes.indexOf(chartType)>=0){
													$(".showtrending[data-num='" + that.chartnumber + "']").hide();
													$(".chartfromdatabuttons" + that.chartnumber).hide();
													$(".sinbleviewchartdata[data-num='" + that.chartnumber + "']").hide();
												}
											}
										}
									}
								},
								rejectFunction: () => { },
								headers: requestHeaders
							});

						} else if (chartType == "textchart" || chartType == "pivot") {
							this.redrawChart(chartDetails, pos, dateHistogramInterval, undefined, trendstats, applyattributes, undefined);
						}
					} catch (e) {

					}
				}
				else {
					//$(".gridspinner").hide();
					var that = this.state;
					if ($("#chart" + that.chartnumber).is(':empty') != true) {
						$("#spinner" + that.chartnumber).hide();
					}
				}

			}
		}
	}
	redrawChart(chartDetails, pos, dateHistogramInterval, algorithm, trendstats, applyattributes, tableInfo) {
		var xaxisName = "";
		var xaxisColumnName = "";
		var xaxisdataType = "";
		var title = "";
		var that = this.state;
		if (chartDetails.xAxis != undefined && chartDetails.xAxis.length == 0) {
			xaxisColumnName = "Measure Names";
		} else {
			if (chartDetails["xAxis"][0] != undefined) {
				xaxisColumnName = chartDetails["xAxis"][0].columnName;
				xaxisdataType = chartDetails["xAxis"][0].dataType;
			}
		}

		var metrictype = chartDetails["metricdatetype"];
		var metricdatefield = chartDetails["metricdatefield"];
		var chartType = chartDetails["chartType"];
		that.referenceLine = chartDetails["reference"];
		var trendLine = chartDetails["trendLine"];
		var periodsvalue = chartDetails["periodsvalue"];
		if (trendLine != undefined && trendLine != "") {
			if (trendLine === "linear" || trendLine === "SMA" || trendLine === "EMA") {
				algorithm = trendLine;
			}
			$('.trendtype[id=' + trendLine + '][data-num=' + that.chartnumber + ']').addClass("showactive");
		}
		var xAxisIdentity = "";
		_.each(chartDetails["xAxis"], function (val1) {
			xAxisIdentity += val1.xaxisidentity + ",";
		});
		xAxisIdentity = xAxisIdentity.substring(0, xAxisIdentity.length - 1)
		var colorThemeIndex = chartDetails["colorThemeIndex"];
		if (colorThemeIndex == undefined) {
			colorThemeIndex = 0;
		}
		var chartSettings = chartDetails['chartSettings'];
		if (chartSettings == undefined) {
			if (chartType == "pie" || chartType == "3dpie" || chartType == "semicircle" || chartType == "donut") {
				chartSettings = { 'chartTitle': true, 'legend': false, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true };
			}
			else {
				chartSettings = { 'chartTitle': true, 'legend': true, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true };
			}
		}
		if (chartType === "summarytable") {
			$('.summarymodal[data-num=' + that.chartnumber + ']').removeClass('hide');
			$('.btn-toolbar').show();
		} else {
			$('.summarymodal[data-num=' + that.chartnumber + ']').addClass('hide');
			$('.btn-toolbar').hide();
		}
		var yAxisNameArray = [];
		var yAxisDataTypeArray = [];
		var yAxisAggArray = [];
		_.each(chartDetails["yAxis"], function (val) {
			yAxisNameArray.push(val.displayName);
			yAxisDataTypeArray.push(val.dataType);
			yAxisAggArray.push(val.aggregation.toLowerCase());
		});
		var yAxisOriginalNameArray = [];
		_.each(chartDetails["yAxis"], function (val) {
			yAxisOriginalNameArray.push(val.columnName);
		});
		var tooltipNameArray = [];
		_.each(chartDetails["tooltip"], function (val) {
			tooltipNameArray.push(val.displayName);
		});
		var zAxisNameArray = [];
		var zAxisAggArray = [];
		_.each(chartDetails["zAxis"], function (val) {
			zAxisNameArray.push(val.columnName);
			zAxisAggArray.push(val.aggregation);
		});
		if (chartType === "metric" || chartType === "solidgauge" || chartType === "fullcirclegauge") {
			if (yAxisDataTypeArray[0] != "customaggregatedfield") {
				title = chartDetails['yAxis'][0].aggregation + " of ";
			}
		} else {
			var i = 0;
			if (chartType == "srilankamap" || chartType == "world" || chartType == "leaflet" || chartType == "countrymap" || chartType == "indiamap") {
				xaxisdataType = "";
				xaxisColumnName = "";
				_.each(chartDetails["xAxis"], function (xaxisname) {
					xaxisdataType += xaxisname.dataType + ",";
					xaxisColumnName += xaxisname.columnName + ",";
				});
				xaxisdataType = xaxisdataType.substring(0, xaxisdataType.length - 1);
				xaxisColumnName = xaxisColumnName.substring(0, xaxisColumnName.length - 1);
			}
			var that = this.state;
			_.each(chartDetails["xAxis"], function (xaxisname) {
				var temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == xaxisname.columnName });
				if (temp.length > 0) {
					var levelmapping = 0;
					sessionStorage.setItem("customhierarchyforchartnum" + that.chartnumber, levelmapping);
					//sessionStorage.setItem("customhierarchyforchart",levelmapping);
					if (i == 0) {
						xaxisColumnName = temp[0]["hierarchelist"][0].columnName;
						xaxisdataType = temp[0]["hierarchelist"][0].dataType;
						i++;
					}
					xaxisName += temp[0]["hierarchelist"][0].columnDisplayName + ",";
					title += temp[0]["hierarchelist"][0].columnDisplayName + ",";
				} else {
					title += xaxisname.displayName + ",";
					xaxisName += xaxisname.displayName + ",";
				}
			});
			title = title.substring(0, title.length - 1);
			title = title + " by "
			xaxisName = xaxisName.substring(0, xaxisName.length - 1);
		}
		if (chartType != "textchart") {
			$('.richtextcontrolschart' + that.chartnumber).hide();
			$("#chart" + that.chartnumber).css({
				'display': '',
				'overflow-y': '',
				'overflow-x': '',
				'word-wrap': '',
				'max-width': ''
			});
		}
		$('.maxanim9 .btn-group button').removeClass('disabled');

		_.each(yAxisNameArray, function (yAxisName) {
			title += yAxisName + ",";
		});
		title = title.substring(0, title.length - 1);
		if (chartType == "predictionchart" || chartType == "h2o_variableimportance" || chartType == "h2o_scorehistory" || chartType == "h2o_datatable") {
			title = ""
			var jsonArray = JSON.parse(chartDetails['predictionArray'])[0];
			_.each(jsonArray['xAxisArray'], function (xaxisname) {
				title += xaxisname.displayName;
			});
			title += " by "
			_.each(jsonArray['yAxisArray'], function (yaxisname) {
				title += yaxisname.displayName;
			});
		}
		var localstoragedata = ChartInfo.functions.getChartDetails(that.chartnumber);
		var parsedData = localstoragedata != undefined ? JSON.parse(localstoragedata) : {};
		if (parsedData["reporttitle"] != undefined) {
			title = parsedData["reporttitle"];
		}
		if (parsedData["reporttitle"] != undefined) {
			$(".boc-viewreport-title[data-num=" + that.chartnumber + "]").attr("customtitle", "1");
			$(".boc-viewreport-title[data-num=" + that.chartnumber + "]").text(parsedData["reporttitle"]);
			$(".maxanim4[data-num=" + that.chartnumber + "]").text(parsedData["reporttitle"]);
			$(".boc-snapshot-options.maxanim3[data-num=" + that.chartnumber + "]").attr("title", parsedData["reporttitle"]);
			$("h2.boc-viewreport-title[data-num=" + that.chartnumber + "]").attr("title", parsedData["reporttitle"]);
			$(".boc-snapshot-title[data-num=" + that.chartnumber + "]").attr("title", parsedData["reporttitle"]);
		} else {
			var customtitle = $(".boc-viewreport-title[data-num=" + that.chartnumber + "]").attr("customtitle");
			if (customtitle != 1) {
				$(".boc-viewreport-title[data-num=" + that.chartnumber + "]").text(title);
				$(".maxanim4[data-num=" + that.chartnumber + "]").text(title);
				$(".boc-snapshot-options.maxanim3[data-num=" + that.chartnumber + "]").attr("title", title + " ");
				$("h2.boc-viewreport-title[data-num=" + that.chartnumber + "]").attr("title", title + " ");
				$(".boc-snapshot-title[data-num=" + that.chartnumber + "]").attr("title", title + " ");
			}
		}
		if (chartSettings['chartTitle'] == true) {
			//$(".boc-viewreport-title[data-num="+that.chartnumber+"]").show();
			$(".maxanim9[data-num=" + that.chartnumber + "] .boc-viewreport-title").removeClass('hide');
			$(".boc-snapshot-title[data-num=" + that.chartnumber + "], .maxanim4[data-num=" + that.chartnumber + "]").removeClass('invisible');
		} else {
			//$(".boc-viewreport-title[data-num="+that.chartnumber+"]").hide();
			$(".maxanim9[data-num=" + that.chartnumber + "] .boc-viewreport-title").addClass('hide');
			$(".boc-snapshot-title[data-num=" + that.chartnumber + "], .maxanim4[data-num=" + that.chartnumber + "]").addClass('invisible');
		}
		$(".predictmodelsummary" + that.chartnumber).addClass("hide");
		var data = that.chartData;
		var reportId = that.reportId;
		var legend = chartDetails["legend"];

		if (data != undefined && data.hasOwnProperty('error')) {
			$(".spinner").hide();
			$(".gridspinner").hide();
			$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('hide');
			$('.birdmessage h2').empty().append('Error : ');
			$('.birdmessage h2').append('<span class="errorcode">');
			$('.birdmessage .errorcode').empty().append(data.errorCode);
			$('.birdmessage-info').empty().text(data.error);
			$('.details').addClass('show');
			$('.messagedetails xmp').empty().append(data.errorTrace);
		}
		if (dateHistogramInterval != undefined && dateHistogramInterval != null && dateHistogramInterval != "reset") {
			$(".showtrending[data-num='" + that.chartnumber + "']").hide();
		} else {
			if (ChartInfo.functions.isTrendSupport(chartDetails.chartType) && chartDetails != undefined) {
				$(".showtrending[data-num='" + that.chartnumber + "']").show();

			}
		}
		var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
		let compareDateRangeObj = chartDetails!=undefined && chartDetails["compareDateRangeObj"]!=null?chartDetails["compareDateRangeObj"]:null;
		let tempYaxis = chartDetails!=undefined?chartDetails["yAxis"]:[];
		let checkcondition = false;
		if(compareDateRangeObj!=null && compareDateRangeObj.hasOwnProperty("0") && compareDateRangeObj["0"]!=null){
			let yjsObj = compareDateRangeObj["0"]; 
			_.each(tempYaxis,function(val,index){
			if(val.columnName!=undefined&&val.columnName!=null&&yjsObj.hasOwnProperty(val.columnName)){
				checkcondition = true;
			}
		});
		}
		if(checkcondition&&comparedaterangecharttpes.indexOf(chartType)>=0){
			$(".showtrending[data-num='" + that.chartnumber + "']").hide();
			$(".chartfromdatabuttons" + that.chartnumber).hide();
			$(".viewchartfromchartdata[data-num='" + that.chartnumber + "']").hide();
		}
		if (chartType === "sparkline") {
			$(".showtrending[data-num='" + that.chartnumber + "']").hide();
		}
		if (chartType != 'pivot') {
			$(".pivotsettingsmain" + that.chartnumber).hide();
			$(".pivotcharticon" + that.chartnumber).hide();
			$(".pivotnavigationbuttons" + that.chartnumber).hide();
		}
		if (chartType != 'summarytable' && chartType != 'pivot' && chartType != 'predictionchart' && chartType != "doby" || chartType == "h2o_variableimportance" || chartType == "h2o_scorehistory" || chartType == "h2o_datatable") {
			$('.boc-maxscreen-left-item[data-num=' + that.chartnumber + '].previewChart').addClass("showactive");
			$('.paginationdiv' + that.chartnumber).hide();
		}
		/*Series Colors*/
		var currentColorTheme = ChartInfo.functions.getColorTheme(colorThemeIndex);

		//Show Series Color option to selected charts
		if (chartType === "multicolorbar" || chartType === "pie" || chartType === "3dpie" || chartType === "donut" || chartType === "semicircle" || chartType === "funnel" || chartType === "pyramid" || chartType === "trellis" || chartType === "sparkline" || chartType === "sankey" || chartType === "sunburst" || chartType === "wordcloud" || chartType === "boxplot" || chartType === "heatmap" || chartType === "textchart" || chartType === "doby" || chartType === "pivot" || chartType === "summarytable" || chartType == "predictionchart" || chartType === "leaflet" || chartType == "indiamap" || chartType === "srilankamap" || chartType === "countrymap" || chartType == "world" || chartType == "h2o_variableimportance" || chartType == "h2o_scorehistory" || chartType == "h2o_datatable") {
			$(".chartadvancedoptions[data-num='chart" + that.chartnumber + "']").hide();
		} else {
			$(".chartadvancedoptions[data-num='chart" + that.chartnumber + "']").show();
		}

		//Update default series colors in ChartUtil
		if (data != undefined && data != "") {
			var seriesnames = [];
			if (data[0].name != undefined) {//Without Legend
				_.each(data, function (i, k) {
					if (currentColorTheme[k] === undefined) {
						currentColorTheme = currentColorTheme.concat(currentColorTheme)
					}
					seriesnames[k] = { name: i.name, color: currentColorTheme[k] };
				});
				ChartInfo.functions.updateDefaultSeriesDetails(that.chartnumber, seriesnames);
			} else {//With Legend
				var serieslist = [];
				if (chartType != undefined && chartType != 'heatmap') {

					_.each(data, function (i, k) {
						if (k % 2 != 0) {
							serieslist = serieslist.concat(i);
							_.each(serieslist, function (l, m) {
								if (currentColorTheme[m] === undefined) {
									currentColorTheme = currentColorTheme.concat(currentColorTheme)
								}
								seriesnames[m] = { name: l.name, color: currentColorTheme[m] };
							});
						}
					});
				}
				ChartInfo.functions.updateDefaultSeriesDetails(that.chartnumber, seriesnames);
			}
		}

		//Update Custom series colors in ChartUtil
		var customseirescolors;
		if (chartType != "heatmap") {
			customseirescolors = ChartInfo.functions.getCustomSeriesColorsDetails(that.chartnumber);
		}
		if (data != undefined && data != "") {
			if (customseirescolors != undefined) {
				if (data[0].name != undefined) {//Without Legend
					_.each(customseirescolors, function (i, k) {
						var iname = i.name;
						var icolor = i.color;
						var uniqueid = i.uniqueid;
						_.each(data, function (l, m) {
							if (data[m].name == iname) {
								if (data[m].name + m === iname + uniqueid) {
									data[m].color = icolor;
								}
							}
							/*	else{
									_.each(data,function(h,i){
										if(data[i].name+k===iname+uniqueid){
											data[i].color=icolor;
										}
									});
								}*/
						});
					});
				} else {//With Legend
					var matcharray = [];
					_.each(customseirescolors, function (i, k) {
						var iname = i.name;
						var icolor = i.color;
						_.each(data, function (l, m) {
							if (m % 2 != 0) {
								_.each(data[m], function (n, o) {
									if (n != undefined && n != "") {
										if (n.name === iname) {
											n.color = icolor;
											matcharray.push(n.name)
											return false;
										} else {
											if (matcharray.indexOf(n.name) === -1) {
												if (currentColorTheme[o] === undefined) {
													currentColorTheme = currentColorTheme.concat(currentColorTheme)
												}
												if (n.color != undefined) {
													n.color = currentColorTheme[o];
												}
											}
										}
									}
								})
							}
						});
					});
				}
			}
		}
		/*\Series Colors*/
		//Clear Memory
        var memEle = $('#chart' + that.chartnumber+' [classname]').attr("id");
        if(memEle!=undefined){
            ReactDOM.unmountComponentAtNode(document.getElementById(memEle));
        }
		if (chartType === "piewithlegend") {
			//	ChartUtils.drawPieWithLegend(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+that.chartnumber, chartType, legend, pos,that.features,that.isSharedReport,colorThemeIndex,chartSettings,trendstats,that.broker,tooltipNameArray,false,that.multifact,that.multifactentities,that.datamodelId,reportId,that.facttables);
			$("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "pie" || chartType === "3dpie") {
			//	ChartUtils.drawPieChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+that.chartnumber, chartType, legend, pos,that.features,that.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,that.broker,false,false, yAxisDataTypeArray, yAxisAggArray,that.multifact,that.multifactentities,that.datamodelId,reportId,that.facttables,yAxisOriginalNameArray);
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(
				<BasicPieChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
					yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport}
					colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
					trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
					yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={that.reportcolumns}
					ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId}
					facttables={that.facttables} />, document.getElementById(dynamicdiv)
			);
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "donut" || chartType === "semicircle") {
			//	ChartUtils.drawDonutChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+that.chartnumber,chartType, legend, pos,that.features,that.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,that.broker,trendstats,false, yAxisDataTypeArray, yAxisAggArray,that.multifact,that.multifactentities,that.datamodelId,reportId,that.facttables,yAxisOriginalNameArray);
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(
				<DonutChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
					yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport}
					colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
					trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
					yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={that.reportcolumns}
					ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId}
					facttables={that.facttables} />, document.getElementById(dynamicdiv)
			);
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "line" || chartType === "spline" || chartType === "invertedline" || chartType === "multixline" || chartType === "spiderweb" || chartType === "polar") {
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(<LineChart data={data} xaxisName={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
				yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport}
				colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm}
				periodsvalue={periodsvalue} trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
				yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={that.reportcolumns} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} ></LineChart>, document.getElementById(dynamicdiv));

			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "bubble") {
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(
				<BubbleChart
					data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType} yAxisNameArray={yAxisNameArray}
					chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features}
					isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} chartSettings={chartSettings}
					tooltipNameArray={tooltipNameArray} trendstats={trendstats} zAxisNameArray={zAxisNameArray} zAxisAggArray={zAxisAggArray} iscustomstoryboard={false}
					referenceLine={that.referenceLine} yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray}
					isMLReport={undefined} ismultifact={that.multifact} multifactentities={that.multifactentities} columnsData={that.reportcolumns}
					datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} yAxisOriginalNameArray={yAxisOriginalNameArray}
				/>, document.getElementById(dynamicdiv));
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "stackedarea" || chartType === "area" || chartType === "invertedarea" || chartType === "arearange" || chartType === "multixarea") {
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(<AreaChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
				yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport}
				colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
				trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
				yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={that.reportcolumns} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} ></AreaChart>, document.getElementById(dynamicdiv));
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide()
		}
		else if (chartType === "bar" || chartType === "multicolorbar" || chartType === "invertedbar" || chartType === "stackedbar" || chartType === "invertedstackedbar" || chartType === "multiaxisbar" || chartType === "3dbar" || chartType === "multixbar" || chartType === "windrose" || chartType === "waterfall") {
			$('#chart' + that.chartnumber).empty();
			chartSettings.dThiddenFilters = chartDetails.xAxis;
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(<BarChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
				yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport}
				colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
				trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
				yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={that.reportcolumns} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} ></BarChart>, document.getElementById(dynamicdiv));
			$("#spinner" + that.chartnumber).hide();
		} else if (chartType === "combinationchart") {
			//ChartUtils.drawCombinationChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+that.chartnumber, chartType, legend, pos,that.features,that.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,algorithm,periodsvalue, that.broker,trendstats,yAxisOriginalNameArray,that.referenceLine,false,yAxisDataTypeArray, yAxisAggArray,undefined,that.multifact,that.multifactentities,that.datamodelId,reportId,that.facttables);
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");

			ReactDOM.render(<CombinationChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
				yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport}
				colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
				trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
				yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={that.reportcolumns} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv));
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		} else if (chartType === "trellis") {
			//ChartUtils.drawTrellisChart(that.multifact,that.multifactentities,that.datamodelId);
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "' style='height:0px !important'></div>");

			ReactDOM.render(<TrellisChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
				yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport}
				colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
				trendstats={trendstats} iscustomstoryboard={false} yAxisOriginalNameArray={yAxisOriginalNameArray}
				yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} />, document.getElementById(dynamicdiv));
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		} else if (chartType === "sparkline") {
			$("#spinner" + that.chartnumber).show();
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "' style='height:0px !important'></div>");
			ReactDOM.render(<SparkLineChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
				yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport}
				colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
				trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
				yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={that.reportcolumns} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv));
			$("#spinner" + that.chartnumber).hide();
			this.adjustChartHeight($("#chart" + that.chartnumber));
			$("#spinner" + that.chartnumber).hide();
		} else if (chartType === "heatmap") {
			//ChartUtils.drawHeatMapChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+that.chartnumber, chartType, legend, pos,that.features,that.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,that.broker,trendstats,false,undefined,that.multifact,that.multifactentities,that.datamodelId,reportId,that.facttables);
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(
				<HeatMapChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType} yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber}
					chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray}
					trendstats={trendstats} iscustomstoryboard={false} yAxisOriginalNameArray={yAxisOriginalNameArray} reportid={reportId} facttables={that.facttables} datamodelId={that.datamodelId} isMLReport={undefined} multifactentities={that.multifactentities} ismultifact={that.multifact} columnsData={that.reportcolumns} />, document.getElementById(dynamicdiv)
			);
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "funnel" || chartType === "pyramid") {
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(
				<FunnelChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType} yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} columnsData={that.reportcolumns}
					chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} trendstats={trendstats} iscustomstoryboard={false} isMLReport={undefined} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} yAxisOriginalNameArray={yAxisOriginalNameArray} />, document.getElementById(dynamicdiv)
			);
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "world" || chartType === "countrymap") {
			//$("#spinner" + that.chartnumber).hide();
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(
				<WorldMap dynamicdiv={dynamicdiv} data={data} xaxisName={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType} yaxisnameArray={yAxisNameArray} chartId={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} columnsData={that.reportcolumns} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} trendstats={trendstats} iscustomstoryboard={false} xaxisidentity={xAxisIdentity} yAxisDataTypeArray={yAxisDataTypeArray} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv)
			);
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
			//ChartUtils.drawWorldMap(data,xaxisName,xaxisColumnName, xaxisdataType,yAxisNameArray,"chart"+that.chartnumber, chartType, legend, pos,that.features,that.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,that.broker,trendstats,false,xAxisIdentity,yAxisDataTypeArray,that.multifact,that.multifactentities,that.datamodelId,reportId,that.facttables);

		}
		else if (chartType === "metric") {
			//ChartUtils.drawMetricChart(data, xaxisName, xaxisColumnName,xaxisdataType, yAxisNameArray, "chart"+ that.chartnumber,chartType, 
			//legend, pos,metrictype,metricdatefield,that.chartnumber,colorThemeIndex,chartSettings,that.metricprefix,that.metricsuffix,that.broker,trendstats,that.metricfont);
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(<MetricChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
				yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos}
				metrictype={metrictype} metricdatefield={metricdatefield} tileno={that.chartnumber} colorThemeIndex={colorThemeIndex}
				chartSettings={chartSettings} metricprefix={that.metricprefix} metricsuffix={that.metricsuffix} iscustomstoryboard={false} trendstats={trendstats} metricfont={that.metricfont}
			></MetricChart>, document.getElementById(dynamicdiv));
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "solidgauge" || chartType === "fullcirclegauge") {
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(<GuageChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
				yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos}
				metrictype={metrictype} metricdatefield={metricdatefield} isSharedReport={that.isSharedReport} tileno={that.chartnumber} colorThemeIndex={colorThemeIndex}
				chartSettings={chartSettings} solidgaugemin={that.solidgaugemin} solidgaugemax={that.solidgaugemax} iscustomstoryboard={false}
				features={that.features} isMLReport={undefined} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={that.reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv));
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "scatter") {
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(
				<ScatterChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType} yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} columnsData={that.reportcolumns}
					chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray}
					trendstats={trendstats} iscustomstoryboard={false} referenceLine={that.referenceLine} yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined}
					multifactentities={that.multifactentities} ismultifact={that.multifact} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} yAxisOriginalNameArray={yAxisOriginalNameArray} />, document.getElementById(dynamicdiv)
			);
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "sankey") {
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			//ChartUtils.drawSankeyChart (data, xaxisName, xaxisColumnName,xaxisdataType, yAxisNameArray, "chart"+ that.chartnumber,chartType, legend, pos,that.features,that.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,that.broker);
			ReactDOM.render(
				<SankeyChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType} yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber}
					chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray}
					trendstats={trendstats} iscustomstoryboard={false} yAxisOriginalNameArray={yAxisOriginalNameArray} reportid={reportId} facttables={that.facttables} datamodelId={that.datamodelId} isMLReport={undefined} multifactentities={that.multifactentities} ismultifact={that.multifact} columnsData={that.reportcolumns} />, document.getElementById(dynamicdiv)
			);
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "boxplot") {
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(<BoxplotChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
				yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport}
				colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
				trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
				yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={that.reportcolumns} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv));
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();;
		}
		else if (chartType === "sunburst") {
			//ChartUtils.drawSunburstChart(data,xaxisName,xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+that.chartnumber, chartType, legend, "max",that.features,that.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray, algorithm,periodsvalue,that.broker,trendstats,yAxisOriginalNameArray,that.referenceLine);
			$("#spinner" + that.chartnumber).hide();
			$(".spinner").hide();
		}
		else if (chartType === "wordcloud") {
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(<WordCloudChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
				yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport}
				colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
				chartnumber={that.chartnumber} trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
				yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMlChart={undefined} isMLReport={undefined} columnsData={that.reportcolumns} ismultifact={that.multifact}
				multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv));
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "bartimeseries" || chartType === "linetimeseries" || chartType === "areatimeseries") {
			if ($('.realtimedatatoggle[data-num=' + that.chartnumber + ']').is(':checked') == true) {
				var data = {};
				data["aggregation"] = 'count';
				data["xaxis"] = xaxisColumnName;
				data["xaxisdataType"] = xaxisdataType;
				data["yaxis"] = "rental_rate";
				data["script"] = null;
				data["legend"] = "";
				data["legendDataType"] = "";
				data["datamodelId"] = 82;
				data["indexName"] = "";
				data["reportId"] = "";
				data["filterObject"] = ["[{\"dataType\":\"date\",\"tablename\":\"film\",\"columndisplayname\":\"last_update\",\"columnname\":\"last_update\",\"daterange\":\"last 30 days\",\"fromRange\":10000,\"toRange\":1492626600000}]"];
				data["sortingColumn"] = chartDetails.sortingColumn;
				data["sortType"] = chartDetails.sortType;
				data["sortingColumnAggregation"] = chartDetails.sortingColumnAggregation;
				data["rankingColumn"] = chartDetails.rankingColumn;
				data["orderType"] = chartDetails.orderType;
				data["rankingColumnAggregation"] = chartDetails.rankingColumnAggregation;
				data["colLimit"] = chartDetails.colLimit;
				data["chartType"] = chartDetails.chartType;
				data["percentileValue"] = chartDetails.percentileValue;
				data["toolTips"] = [];
				data["zaxis"] = [];
				data["isExcludeorIncludeFilters"] = "";
				data["calculation"] = "";
				data["entities"] = 4;
				data["yAxisDataType"] = "string";
				data["rangefieldMap"] = "";
				data["xaxisAggregation"] = "group";
				data["customaggregatedscriptfield"] = [];
				data["combinationchartsData"] = [];
				data["custommeasurehierarchy"] = [];
				data["isExcludeNulls"] = "";
				data["isChartSummaryData"] = "";
				that.worker.postMessage(data);
				$("#spinner" + that.chartnumber).hide();
				$("#spinner" + that.chartnumber).hide();
			}
			else {
				//ChartUtils.drawTimeSeries(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+that.chartnumber, chartType, legend, pos,that.features,that.isSharedReport,
				//		colorThemeIndex,chartSettings,tooltipNameArray, algorithm,periodsvalue,that.broker,trendstats,yAxisOriginalNameArray,that.referenceLine,false, yAxisDataTypeArray, yAxisAggArray,undefined,reportId);

				$('#chart' + that.chartnumber).empty();
				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
				ReactDOM.render(<TimeseriesChart data={data} xaxisName={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
					yaxisnameArray={yAxisNameArray} chartId={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport}
					colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
					trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
					yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={that.reportcolumns} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv));
				$("#spinner" + that.chartnumber).hide();
				$("#spinner" + that.chartnumber).hide();
			}
			//this.webworkerdata()
		}
		else if (chartType === "textchart") {
			//ChartUtils.textChart(chartDetails,"chart"+that.chartnumber, chartType, pos,that.features,that.isSharedReport,that.broker,trendstats);
			$('#chart' + that.chartnumber).empty();
			//var today = new Date();
			//var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			//dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			//$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			//ReactDOM.render(<TextChart data={chartDetails} chartId={"chart" + that.chartnumber} chartType={chartType} position={pos} features={that.features} isSharedReport={that.isSharedReport} iscustomstoryboard={false} />, document.getElementById("chart" + that.chartnumber + ""));
			setTimeout(function () {
				if (chartDetails != undefined && chartDetails['richtextcontent'] != undefined) {
					$("#chart" + that.chartnumber).empty();
					$("#chart" + that.chartnumber).html(decodeURIComponent(chartDetails['richtextcontent']));
				}
				$("#spinner" + that.chartnumber).hide();
				$("#spinner" + that.chartnumber).hide();
				//$('#chart' + that.chartnumber).summernote('destroy');
				$('.editrichtext[data-num=' + that.chartnumber + ']').removeClass('active');
				$('.saverichtext[data-num=' + that.chartnumber + ']').removeClass('active');
				$("#chart" + that.chartnumber).css({
					'display': 'block',
					'overflow-y': 'auto',
					'overflow-x': 'auto',
					'word-wrap': 'break-word',
					//'max-width': 'calc(100vw - 150px)'
				});
				$('.countcharacters').text("Characters Count Limit:" + (14000 - ($('#chart' + that.chartnumber).summernote('code') || "").replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length));
				if ($('#chart' + that.chartnumber).is(':empty')) {
					$('#chart' + that.chartnumber).hide();
					$('.editrichtext[data-num=' + that.chartnumber + ']').click();
				} else {
					$('#chart' + that.chartnumber).summernote('destroy');
				}
				if (that.pos == "min" || that.pos == undefined) {
					//$('.richtextcontrolschart' + that.chartnumber).hide();
					var texttileheight = $("#tileview" + that.chartnumber).height() - $(".maxanim3[data-num=" + that.chartnumber + "]").height() - 14;
					$("#chart" + that.chartnumber).css({
						'height': texttileheight
					});
				}
				$("#spinner" + that.chartnumber).hide();
			}, 200);
		}
		else if (chartType === "doby") {
			$('.viewchartfromchartdata[data-num=' + that.chartnumber + ']').hide();
			$('.viewchartdataforstoryboard[data-num=' + that.chartnumber + ']').hide();
			$('.viewchartsummaryforstoryboard[data-num=' + that.chartnumber + ']').hide();
			$('.pivotsettingsmain' + that.chartnumber).hide();
			$(".pivotcharticon" + that.chartnumber).hide();
			$(".pivotnavigationbuttons" + that.chartnumber).hide();
			$('.summarymodal[data-num=' + that.chartnumber + ']').addClass('hide');
			$('.boc-maxscreen-left-item[data-num=' + that.chartnumber + '].dobygrid').addClass("showactive");
			var dobyselectedcolumns = ["dobyselectedcolumns"];
			if (dobyselectedcolumns == undefined) {
				dobyselectedcolumns = xaxisName;
			}
			$("#spinner" + that.chartnumber).show();
			$(".chartchanger[data-num='" + that.chartnumber + "']").hide();
			this.viewDataTable(that.chartnumber, dobyselectedcolumns, "applymethod", that.pos, undefined, undefined, undefined, undefined, tableInfo);
			//$("#spinner"+that.chartnumber).hide();
			return;
		}
		else if (chartType === "pivot") {
			$('.paginationdiv' + that.chartnumber).hide();
			$('.summarymodal[data-num=' + that.chartnumber + ']').addClass('hide');
			if (that.pos == "min" || that.pos == undefined) {
				$(".pivotsettingsmain" + that.chartnumber).hide();
				$(".pivotnavigationbuttons" + that.chartnumber).hide();
				$(".pivotcharticon" + that.chartnumber).show();
			}
			$('.boc-maxscreen-left-item[data-num=' + that.chartnumber + '].pivotgrid').addClass("showactive");
			$('.boc-maxscreen-left-item[data-num=' + that.chartnumber + '].previewChart').removeClass("showactive");
			$('.boc-maxscreen-left-item[data-num=' + that.chartnumber + '].summarytable').removeClass("showactive");
			$('.boc-maxscreen-left-item[data-num=' + that.chartnumber + '].dobygrid').removeClass("showactive");
			$(".showtrending[data-num=" + that.chartnumber + "]").hide();

			this.viewPivotTable(that.chartnumber, viewcollection.getMeasures(), viewcollection.getDimensions(), that.pos, applyattributes);;
			$("#spinner" + that.chartnumber).hide();
			//gridspinner immediately closing added closing after request complete
			// $("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "summarytable") {
			$('.boc-maxscreen-left-item[data-num=' + that.chartnumber + '].dobygrid').removeClass("showactive");
			$('.boc-maxscreen-left-item[data-num=' + that.chartnumber + '].pivotgrid').removeClass("showactive");
			$('.boc-maxscreen-left-item[data-num=' + that.chartnumber + '].previewChart').removeClass("showactive");
			$('.boc-maxscreen-left-item[data-num=' + that.chartnumber + '].summarytable').addClass("showactive");
			$(".showtrending[data-num=" + that.chartnumber + "]").hide();
			$('.boc-maxscreen-left-item.singlereport_summary_table').addClass("showactive");
			this.viewSummaryTabe(that.chartnumber, viewcollection.getMeasures(), viewcollection.getDimensions(), that.pos, chartDetails, applyattributes);
			$("#spinner" + that.chartnumber).hide();
			//gridspinner immediately closing added closing after request complete
			// $("#spinner" + that.chartnumber).hide();
		} else if (chartType == "predictionchart" || chartType == "h2o_variableimportance" || chartType == "h2o_scorehistory" || chartType == "h2o_datatable") {
			this.predictionChart(that.chartnumber);
		}
		else if (chartType === "leaflet") {
			$("#spinner" + that.chartnumber).show();
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(
				<Leaflet data={data} xaxisName={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType} yaxisnameArray={yAxisNameArray} chartId={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} trendstats={trendstats} iscustomstoryboard={false} xaxisidentity={xAxisIdentity} yAxisDataTypeArray={yAxisDataTypeArray} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} facttables={that.facttables} reportId={reportId} columnsData={that.reportcolumns}/>, document.getElementById(dynamicdiv)
			)
			//ChartUtils.drawLeaflet(data,xaxisName,xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+that.chartnumber, chartType, legend, pos,that.features,that.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,that.broker,trendstats,false,xAxisIdentity,yAxisDataTypeArray,that.multifact,that.multifactentities,that.datamodelId,that.facttables,reportId);
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		}
		else if (chartType === "srilankamap") {
			$("#spinner" + that.chartnumber).show();
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(<SrilankaChart data={data} xaxisName={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
				yaxisnameArray={yAxisNameArray} chartId={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport}
				colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} trendstats={trendstats} iscustomstoryboard={false}
				xaxisidentity={xAxisIdentity} yAxisDataTypeArray={yAxisDataTypeArray} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} dynamicdiv={dynamicdiv} columnsData={that.reportcolumns} />, document.getElementById(dynamicdiv));
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();


			//ChartUtils.drawSriLankaMap(,,,that.multifactentities,that.datamodelId,reportId,that.facttables);

		} else if (chartType === "indiamap") {
			$("#spinner" + that.chartnumber).show();
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(<IndiaMap data={data} xaxisName={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
				yaxisnameArray={yAxisNameArray} chartId={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={pos} features={that.features} isSharedReport={that.isSharedReport}
				colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} trendstats={trendstats} iscustomstoryboard={false} columnsData={that.reportcolumns}
				xaxisidentity={xAxisIdentity} yAxisDataTypeArray={yAxisDataTypeArray} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} dynamicdiv={dynamicdiv} />, document.getElementById(dynamicdiv));
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();


			//ChartUtils.drawSriLankaMap(,,,that.multifactentities,that.datamodelId,reportId,that.facttables);

		}
		else {//default
			//ChartUtils.drawBarChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+that.chartnumber, "bar", legend, pos,that.features,that.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,that.broker,trendstats,yAxisOriginalNameArray, false, false, yAxisDataTypeArray,undefined,undefined,that.multifact,that.multifactentities,that.datamodelId,reportId,that.facttables);
			$("#spinner" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
		}
		if($(".chartfromdatabuttons" + that.chartnumber).is(':visible') == false){
			$(".chartfromdatabuttons" + that.chartnumber).show();
		}
		if (pos != "max" && $('.fullscreenmain .maxanim8').length != 1) {
			$('.highcharts-button:not([states])').hide();
		}
		if (chartType === "metric" || chartType === "solidgauge" || chartType === "fullcirclegauge" || chartType == "summarytable" || chartType == "doby" || chartType == "textchart" || chartType == 'pivot' || chartType == 'summarytable' || chartType == 'sparkline' || ChartInfo.functions.getDateHistogramStatus(that.chartnumber) == true) {
			$(".maxanim1[data-num='" + that.chartnumber + "']").find('.storyboard-data-buttons').hide();
			$(".maxanim1[data-num='" + that.chartnumber + "']").find('.max-data-buttons').hide();
		}
		else {
			$(".maxanim1[data-num='" + that.chartnumber + "']").find('.storyboard-data-buttons').show();
			$(".maxanim1[data-num='" + that.chartnumber + "']").find('.max-data-buttons').show();

		}
		$(".maxanim1[data-num=" + that.chartnumber + "] .dropdown").find('#dropdownmorechart').removeClass("disabled")

		if (chartType == "summarytable" || chartType == "doby" || chartType == 'pivot'/* || ChartInfo.getDateHistogramStatus(that.chartnumber)==true*/) {
			$(".chartchanger[data-num='" + that.chartnumber + "']").hide();
		} else {
			$(".chartchanger[data-num='" + that.chartnumber + "']").show();
		}

		if (chartType === "bartimeseries" || chartType === "linetimeseries" || chartType === "areatimeseries") {
			$(".timeseriesrealtimedatabuttons[data-num='" + that.chartnumber + "']").removeClass('hide');
		} else {
			$(".timeseriesrealtimedatabuttons[data-num='" + that.chartnumber + "']").addClass('hide');
		}
		var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
		 compareDateRangeObj = chartDetails!=undefined && chartDetails["compareDateRangeObj"]!=null?chartDetails["compareDateRangeObj"]:null;
		  tempYaxis = chartDetails!=undefined?chartDetails["yAxis"]:[];
		 let checkconditionval = false;
		 if(compareDateRangeObj!=null && compareDateRangeObj.hasOwnProperty("0") && compareDateRangeObj["0"]!=null){
			  let yjsObj = compareDateRangeObj["0"]; 
			 _.each(tempYaxis,function(val,index){
			 if(val.columnName!=undefined&&val.columnName!=null&&yjsObj.hasOwnProperty(val.columnName)){
				 checkconditionval = true;
			 }
		 });
		 }
		 if(checkconditionval&&comparedaterangecharttpes.indexOf(chartType)>=0){
			$(".showtrending[data-num='" + that.chartnumber + "']").hide();
			$(".chartfromdatabuttons" + that.chartnumber).hide();
			$(".viewchartfromchartdata[data-num='" + that.chartnumber + "']").hide();
			$('.viewchartdataforstoryboard[data-num=' + that.chartnumber + ']').hide();
			$('.viewchartsummaryforstoryboard[data-num=' + that.chartnumber + ']').hide();
		}
	}

	render() {
		var that = this;
		_.each(that.props.reportcolumns, function (val) {
			that.state.dimcols.push(val["columnName"]);
		});
		var MoveTile = "hide", chartChanger = "hide", tileFiltersToggle = "hide", maxAnimIcon = "hide", megaDropdown = "hide", chartViewIcon = "hide", dataViewIcon = "hide", summaryViewIcon = "hide", cloneIcon = "hide", alertsIcon = "hide", deleteGridIcon = "hide", maxPreviewChartIcon = "hide", maxDobyGridIcon = "hide", maxPivotIcon = "hide", maxSummaryTableIcon = "hide", megadropdownBool = "hide";
		var maximizeChartChanger = "hide", GeneralBool = "hide", savesinglereportIcon = "hide", clonemaxsinblereport1Icon = "hide", livestoryboardICon = "hide", drilldownsettingsIcon = "hide", CustomizeVBool = "hide", reporttheme1 = "hide", maximizesetting1 = "hide", setsinglereportfavourite1Icon = "hide", CollaborateBool = "hide", sharesinglereport1Icon = "hide", embedreportmodal1Icon = "hide", schedulereport1Icon = "hide", downloadsinglereportsIcon = "hide", singlealertsIcon = "hide";
		var isViewOnlyFlags = this.state.isViewOnlyFlags;
		var isSharedReport = this.state.isSharedReport;
		var allfeatures = this.state.allfeatures;
		if (!isViewOnlyFlags) {
			if (that.state.isEmbeddedReport != true) {
				_.each(that.state.allfeatures, function (featuresModule) {
					if (featuresModule.moduleName == 'story_board') {
						if (isSharedReport == false) {
							_.each(featuresModule.features, function (feature) {
								if (feature.featureName == 'viewreport_adjust_tile' && feature.allow == true) {
									MoveTile = "";
								}
							})
						} else {
							_.each(featuresModule.features, function (feature) {
								if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_adjust_tile' && feature.allow == true) {
											MoveTile = "";
										}
									})
								}
							})
						}
					}
					if (featuresModule.moduleName == 'my_report') {
						if (isSharedReport == false) {
							if (that.state.isScheduleReport === true) {
							} else {
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_maximize_attributes_settings' && feature.allow == true) {
										chartChanger = "";
									}
								})
							}
						} else {
							_.each(featuresModule.features, function (feature) {
								if (feature.featureName == 'viewreport_maximize_save_shared_report' && feature.allow == true) {
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_maximize_attributes_settings' && feature.allow == true) {
											chartChanger = "";
										}
									})
								}
							})
						}
					}
					if (featuresModule.moduleName == 'story_board') {
						_.each(featuresModule.features, function (feature) {
							if (feature.featureName == 'viewreport_tile_filter' && feature.allow == true) {
								tileFiltersToggle = "";
							}
						})
					}
					if (featuresModule.moduleName == 'story_board') {
						if (isSharedReport == false) {
							if (that.state.isScheduleReport === true) { } else {
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_max_tile' && feature.allow == true) {
										maxAnimIcon = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if ((feature.featureName === 'viewreport_data_behind_chart' && feature.allow == true) || (feature.featureName === 'viewreport_delete_new_tile' && feature.allow == true) || (feature.featureName == 'viewreport_add_new_tile' && feature.allow == true)) {
										megaDropdown = "";
									}
								})

								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_data_behind_chart' && feature.allow == true) {
										chartViewIcon = "";
										if (that.state.isMultifact != "true") {
											dataViewIcon = "";
										}
										summaryViewIcon = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_clone_tile' && feature.allow == true) {
										cloneIcon = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_alerts' && feature.allow == true) {
										alertsIcon = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_delete_new_tile' && feature.allow == true) {
										deleteGridIcon = "";
									}
								})
							}
						} else {
							_.each(featuresModule.features, function (feature) {
								if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_max_tile' && feature.allow == true) {
											maxAnimIcon = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if ((feature.featureName === 'viewreport_delete_new_tile' && feature.allow == true)) {
											megaDropdown = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_delete_new_tile' && feature.allow == true) {
											deleteGridIcon = "";
										}
									})

								}
							})
						}
					}
					if (featuresModule.moduleName == 'story_board') {
						if (isSharedReport == false) {
							if (that.state.isScheduleReport === true) { } else {
								maxPreviewChartIcon = "";
								_.each(that.state.allfeatures, function (featuresModule) {
									if (featuresModule.moduleName == 'my_report') {
										_.each(featuresModule.features, function (feature) {
											if (feature.featureName == 'viewreport_maximize_data_table' && feature.allow == true) {
												maxDobyGridIcon = "";
											}
										})
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_summary_table' && feature.allow == true) {
										maxSummaryTableIcon = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_pivot' && feature.allow == true) {
										maxPivotIcon = "";
									}
								})
							}
						} else {
							_.each(featuresModule.features, function (feature) {
								if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
									maxPreviewChartIcon = "";
									_.each(allfeatures, function (featuresModule) {
										if (featuresModule.moduleName == 'my_report') {
											_.each(featuresModule.features, function (feature) {
												if (feature.featureName == 'viewreport_maximize_data_table' && feature.allow == true) {
													maxDobyGridIcon = "";
												}
											})
										}
									})
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_summary_table' && feature.allow == true) {
											maxSummaryTableIcon = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_pivot' && feature.allow == true) {
											maxPivotIcon = "";
										}
									})
								}
							})
						}
					}
					if (featuresModule.moduleName == 'my_report') {
						if (isSharedReport == false) {
							if (that.state.isScheduleReport != true) {
								_.each(featuresModule.features, function (feature) {
									if ((feature.featureName == 'viewreport_maximize_query_info' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_save_report' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_clone_report' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_colortheme' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_title_edit' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_favourites' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_share_report' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_embed_report_email' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_schedule_reports' && feature.allow == true)
										|| (feature.featureName == 'live_report' && feature.allow == true)) {
										megadropdownBool = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if ((feature.featureName === 'viewreport_maximize_query_info' && feature.allow == true) || (feature.featureName === 'viewreport_maximize_save_report' && feature.allow == true) || (feature.featureName === 'viewreport_maximize_clone_report' && feature.allow == true) || (feature.featureName === 'live_report' && feature.allow == true)) {
										GeneralBool = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_maximize_save_report' && feature.allow == true) {
										savesinglereportIcon = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_maximize_clone_report' && feature.allow == true) {
										clonemaxsinblereport1Icon = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'live_report' && feature.allow == true) {
										livestoryboardICon = "";
									}
								})

								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_maximize_drilldown_settings' && feature.allow == true) {
										drilldownsettingsIcon = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if ((feature.featureName === 'viewreport_maximize_colortheme' && feature.allow == true) || (feature.featureName === 'viewreport_maximize_title_edit' && feature.allow == true) || (feature.featureName === 'viewreport_maximize_favourites' && feature.allow == true)) {
										CustomizeVBool = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_maximize_colortheme' && feature.allow == true) {
										reporttheme1 = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_maximize_title_edit' && feature.allow == true) {
										maximizesetting1 = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_maximize_favourites' && feature.allow == true) {
										setsinglereportfavourite1Icon = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if ((feature.featureName === 'viewreport_maximize_share_report' && feature.allow == true) || (feature.featureName === 'viewreport_maximize_embed_report_email' && feature.allow == true) || (feature.featureName === 'viewreport_maximize_schedule_reports' && feature.allow == true)) {
										CollaborateBool = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_maximize_share_report' && feature.allow == true) {
										sharesinglereport1Icon = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_maximize_embed_report_email' && feature.allow == true) {
										embedreportmodal1Icon = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_maximize_schedule_reports' && feature.allow == true) {
										schedulereport1Icon = "";
									}
									if (that.state.isDirect == 0 && feature.featureName == 'viewreport_maximize_alerts' && feature.allow == true) {
										singlealertsIcon = "";
									}
								})
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'download_myreport' && feature.allow == true) {
										downloadsinglereportsIcon = "";
									}
								})
							}
						} else {
							_.each(featuresModule.features, function (feature) {
								if (feature.featureName == 'viewreport_maximize_save_shared_report' && feature.allow == true) {
									_.each(featuresModule.features, function (feature) {
										if ((feature.featureName == 'viewreport_maximize_query_info' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_save_report' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_clone_report' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_colortheme' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_title_edit' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_favourites' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_share_report' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_embed_report_email' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_schedule_reports' && feature.allow == true)) {
											megadropdownBool = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if ((feature.featureName === 'viewreport_maximize_query_info' && feature.allow == true) || (feature.featureName === 'viewreport_maximize_save_report' && feature.allow == true) || (feature.featureName === 'viewreport_maximize_clone_report' && feature.allow == true)) {
											GeneralBool = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_maximize_save_report' && feature.allow == true) {
											savesinglereportIcon = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_maximize_clone_report' && feature.allow == true) {
											clonemaxsinblereport1Icon = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if ((feature.featureName === 'viewreport_maximize_colortheme' && feature.allow == true) || (feature.featureName === 'viewreport_maximize_title_edit' && feature.allow == true) || (feature.featureName === 'viewreport_maximize_favourites' && feature.allow == true)) {
											CustomizeVBool = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_maximize_colortheme' && feature.allow == true) {
											reporttheme1 = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_maximize_title_edit' && feature.allow == true) {
											maximizesetting1 = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_maximize_favourites' && feature.allow == true) {
											setsinglereportfavourite1Icon = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if ((feature.featureName === 'viewreport_maximize_share_report' && feature.allow == true) || (feature.featureName === 'viewreport_maximize_embed_report_email' && feature.allow == true) || (feature.featureName === 'viewreport_maximize_schedule_reports' && feature.allow == true)) {
											CollaborateBool = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_maximize_share_report' && feature.allow == true) {
											sharesinglereport1Icon = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_maximize_embed_report_email' && feature.allow == true) {
											embedreportmodal1Icon = "";
										}
									})
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_maximize_schedule_reports' && feature.allow == true) {
											schedulereport1Icon = "";
										}
									})
								}
							})
						}
					}
					if (featuresModule.moduleName == 'my_report') {
						if (isSharedReport == false) {
							if (that.state.isScheduleReport === true) {
							} else {
								_.each(featuresModule.features, function (feature) {
									if (feature.featureName == 'viewreport_maximize_attributes_settings' && feature.allow == true) {
										maximizeChartChanger = "";
									}
								})
							}
						} else {
							_.each(featuresModule.features, function (feature) {
								if (feature.featureName == 'viewreport_maximize_save_shared_report' && feature.allow == true) {
									_.each(featuresModule.features, function (feature) {
										if (feature.featureName == 'viewreport_maximize_attributes_settings' && feature.allow == true) {
											maximizeChartChanger = "";
										}
									})
								}
							})
						}
					}
				})
			}
		}
		return (
			<React.Fragment>
				<div className="boc-snapshot-options maxanim3" data-num={this.props.chartid} title={this.props.charttitle}>
					<div className={`dragme btn btn-transparent ${MoveTile}`} title={lang["storyboard.movetile"]}>
						<i className="fa fa-arrows"></i>
					</div>
					<div aria-label="..." role="group" className="btn-group pull-right dropdown">
						<button className={`btn btn-transparent chartchanger magictool ${chartChanger}`} onClick={this.togglevisualizationsettings} id="chartchangers" data-num={this.props.chartid} title={lang["storyboard.visualsetting"]}>
							<i className="fa fa-bar-chart"></i>
						</button>
						<button className={`btn btn-transparent toggleTileFilter ${tileFiltersToggle}`} data-num={this.props.chartid} onClick={this.tileFilterMenuToggle} tab-id="0" tab-name="" title={lang["storyboard.tilefilter"]}><i className="fa fa-filter"></i></button>
						<button className={`btn btn-transparent maxanim ${maxAnimIcon}`} id="maxanimicon" onClick={this.onMaximisereport} data-num={this.props.chartid} tab-id="0" tab-name="" data-child={this.props.chartid} title={lang["storyboard.maxmize"]}>
							<i className="fa fa-expand"></i>
						</button>
						<button type="button" className={`btn btn-transparent dropdown-toggle dropdown disabled ${megaDropdown}`} id="dropdownmorechart" title={lang["storyboard.more"]} data-toggle="dropdown" aria-expanded="false" style={{ margin: "0 5px" }}>
							<i className="fa fa-ellipsis-v"></i>
						</button>
						<ul className="dropdown-menu dropdown-menu-right megadropdown">
							<li><button className={`btn viewchartfromchartdata storyboard-data-buttons ${chartViewIcon}`} onClick={this.viewchartfromchartdata} id="chartviews" data-num={this.props.chartid} title={lang["storyboard.chartview"]} role="button" style={{ display: this.props.storyboardbuttonsstate == "show" ? "block" : "none" }}>
								<i className="fa fa-bar-chart marg-righ-10"></i>{lang["storyboard.chartview"]}</button></li>
							{that.state.multifact !== "true" ?
								<li><button className={`btn viewchartdataforstoryboard storyboard-data-buttons ${dataViewIcon}`} onClick={this.viewchartdataforstoryboard} id="dataviews" data-num={this.props.chartid} title={lang["storyboard.dataview"]} role="button" style={{ display: this.props.storyboardbuttonsstate == "show" ? "block" : "none" }}>
									<i className="fa fa-table marg-righ-10"></i>{lang["storyboard.dataview"]}</button></li> : null
							}
							<li><button className={`btn  viewchartsummaryforstoryboard storyboard-data-buttons ${summaryViewIcon}`} onClick={this.viewchartsummaryforstoryboard} id="summaryviews" role="button" data-num={this.props.chartid} title={lang["storyboard.summaryview"]} style={{ display: this.props.storyboardbuttonsstate == "show" ? "block" : "none" }}>
								<i className="fa fa-table marg-righ-10"></i>{lang["storyboard.summaryview"]}</button></li>
							<li><button className={`btn clonetile ${cloneIcon}`} id="cloneviews" data-num={this.props.chartid} onClick={this.onCloneTile} title={lang["storyboard.clonetile"]}><i className="fa fa-files-o marg-righ-10"></i>{lang["storyboard.clonetile"]}</button></li>
							<li><button className={`btn storyboardalerts ${alertsIcon}`} id="storyboardalerts" onClick={this.props.storyboardalerts} data-num="1" tab-id="0" tab-name="" title={lang["storyboard.alerts"]}><i className="fa fa-exclamation-triangle marg-righ-10"></i>{lang["storyboard.alerts"]}</button></li>
							<li><button className={`btn deletegrid ${deleteGridIcon}`} id="deletegrid" data-num={this.props.chartid} onClick={this.onDeleteTile} title={lang["storyboard.deltile"]}>
								<i className="fa fa-trash-o marg-righ-10"></i>{lang["storyboard.deltile"]}</button>
							</li>
						</ul>
						<div className={`btn-group bgcommon predictionChart${this.props.chartid}  hide`}>
							<button type="button" className="btn btn-bird-group btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title={lang["storyboard.more"]}>
								<span className="caret"></span>&nbsp; <span className="Predictionmore"></span>H2o</button>
							<ul className="dropdown-menu">
								<li><a href="javascript:void(0)" data-num={this.props.chartid} className="boc-maxscreen-left-item h2ochartprediction scorehistory">
									<i className="fa fa-bar-chart marg-righ-10"></i>Score History</a></li>
								<li><a href="javascript:void(0)" data-num={this.props.chartid} className="boc-maxscreen-left-item h2ochartprediction variableimportance">
									<i className="fa fa-bar-chart marg-righ-10"></i>Variable Importance</a></li>
								<li><a href="javascript:void(0)" data-num={this.props.chartid} className="boc-maxscreen-left-item h2ochartprediction h2odatatable">
									<i className="fa fa-summary-table-16 marg-righ-10"></i>H2oData Table</a></li>
							</ul>
							<div className="boc-snapshot-title  maxanim4 maximizesetting1 titleeditmode" onClick={this.maximizesettingmodal} data-num={this.props.chartid} title={this.props.charttitle}>{this.props.charttitle}</div>
						</div>
					</div>
					<div className="boc-snapshot-title  maxanim4 maximizesetting1 titleeditmode" onClick={this.maximizesettingmodal} data-num={this.props.chartid} title={this.props.charttitle}>{this.props.charttitle}</div>
				</div>

				<div className="col-xs-12 nopadding maxanim5">
					<div id="attributesmodaldiv" className={`attributesmodaldiv attributesmodaldiv${this.props.chartid}`}></div>
					<div id="custommeasurediv" className={`custommeasurediv${this.props.chartid} custommeasuredivall`}></div>
					<div id="filtersmodaldiv" className={`filtersmodaldiv${this.props.chartid}`}></div>
					<div id="predictmodaldiv" className={`predictmodaldiv${this.props.chartid}`}></div>
					<div className="col-xs-12 nopadding maxanim7" data-num={this.props.chartid} tab-id={0}>
						<div className="container-fluid nopadding maxanim8">
							<div className="row"><div className="col-xs-12 quickfiltercontainer" data-num={this.props.chartid} tab-id={0} tab-name=""></div></div>
							<div className="row maxleftpanehidden maxanim9" data-num={this.props.chartid}>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-8 flex-baseline-div marg-top-5">
									<div className="btn-group bgcommon">
										<button type="button" className="btn btn-bird-group btn-sm" id="bars" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-bar-chart"></i></button>
										<button type="button" className="btn btn-bird-group btn-sm dropdown-toggle" id="morecharts" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title={lang["storyboard.more"]}>
											<span className="caret"></span>
											<span className="sr-only">{lang["storyboard.toggle"]}</span>
										</button>
										<ul className="dropdown-menu">
											<li><a href="javascript:void(0)" className={`boc-maxscreen-left-item previewChart showactive ${maxPreviewChartIcon}`} onClick={this.viewchartfromchartdata} id="maxcharts" data-num={this.props.chartid}><i className="fa fa-bar-chart marg-righ-10"></i>{lang["storyboard.chart"]}</a></li>
											<li><a href="javascript:void(0)" className={`boc-maxscreen-left-item dobygrid ${maxDobyGridIcon}`} id="maxdatatables" onClick={this.maxdatatables} data-num={this.props.chartid} start="0" end="500"><i class="fa fa-table marg-righ-10"></i>{lang["storyboard.datatable"]}<span className="maxscreen-lft-filtertitle hide">{lang["storyboard.data"]}</span></a></li>
											<li><a href="javascript:void(0)" className={`boc-maxscreen-left-item summarytable ${maxSummaryTableIcon}`} id="maxsummarytables" data-num={this.props.chartid} start="0" end="500" onClick={this.summarytableview}><i className="fa fa-th-large marg-righ-10"></i>{lang["storyboard.summarytable"]}<span className="maxscreen-lft-filtertitle hide">{lang["storyboard.summarytable"]}</span></a></li>
											<li><a href="javascript:void(0)" className={`boc-maxscreen-left-item pivotgrid ${maxPivotIcon}`} id="maxpivotable" onClick={this.viewPivotGrid} data-num={this.props.chartid} start="0" end="500"><i className="fa fa-pivot-table-16 marg-righ-10"></i>{lang["storyboard.pivotable"]}<span className="maxscreen-lft-filtertitle hide">{lang["storyboard.pivot"]}</span></a></li>
											{/* <li><a href="javascript:void(0)" className="boc-maxscreen-left-item prediction modalleft" id="maxprediction" data-num={this.props.chartid} data-direction="left" data-toggle="modal" data-target="#predictmodal"><i className="fa fa-predict-icon-16 marg-righ-10"></i>Prediction</a></li> */}
										</ul>
									</div>
									<div className="marg-left-5 hidden-xs"></div>
								</div>
								<div className="col-lg-3 col-md-3 col-sm-4 col-xs-4 width-54-mob pull-right">
									<div className="col-xs-12 boc-padding-11 reporttoolbar" data-num={this.props.chartid}>
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 reporttitletoolbar0">
											<div className="row controlbar-main">
												<div className="col-lg-9 col-xs-8 flex-baseline-div">
													<h2 className="boc-viewreport-title maximizesetting1 titleeditmode" onClick={this.maximizesettingmodal} data-num={this.props.chartid} title={lang["storyboard.countname"]}>{lang["storyboard.countname"]}</h2>
													<div className="datacount maxscreendatacount hide" data-num={this.props.chartid}>
														<span className="publishinfo">{lang["storyboard.dataset"]}</span><span className="filterdatacount"></span>
														<span className="lastexecutedtimestamp"></span>
													</div>
												</div>
												<div className="pull-right marg-left-auto">
													<div className="">
														<div className="col-xs-12 storyboardtoolbarfull">
															<div className="row">
																<div aria-label="..." role="group" className="btn-group pull-right dropdown bgcommon">
																	<button className="btn btn-bird-group minanim" id="minimumicon" onClick={this.onMinimisereport} data-num={this.props.chartid} title={lang["storyboard.minimize"]} type="button"><i className="fa fa-compress"></i></button>
																	<button type="button" className="btn btn-bird-group attributes modalleft" id="modalsleftattributes" onClick={this.togglevisualizationsettings} data-direction="left" data-toggle="modal" data-target="#attributesmodal" title={lang["storyboard.attributes"]} data-num={this.props.chartid}><i className="fa fa-cogs"></i></button>
																	{/* <button type="button" className="btn btn-bird-group tooltip-bottom maximize_intelligentsearch" id="maximizeintelligenticon1" data-num={this.props.chartid} title="Intelligent Search"><i className="fa fa-search"></i></button> */}
																	<button className="btn btn-bird-group refreshsinglecharts1" id="maxirefreshicon" onClick={this.redrawchart} data-num={this.props.chartid} title={lang["storyboard.refresh"]} type="button"><i className="fa fa-refresh"></i></button>

																	<button type="button" className={`btn btn-bird-group tooltip-bottom dropdown-toggle dropdown ${megadropdownBool}`} id="maximizemore" data-num={this.props.chartid} title={lang["storyboard.more"]} data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></button>
																	<ul className="dropdown-menu dropdown-menu-right megadropdown">
																		<li className={`dropdown-header ${GeneralBool}`}>{lang["storyboard.general"]}</li>
																		<li><a href="javascript:void(0)" className={`savesinglereport${savesinglereportIcon}`} id="savesinglereports" title={lang["storyboard.savemyreport"]} data-direction="top" data-toggle="modal" data-target="#savereportgraph" onClick={this.savesinglereport}><i className="fa fa-floppy-o marg-righ-10"></i> {lang["storyboard.savemyreport"]}</a></li>
																		<li><a href="javascript:void(0)" className={`clonemaxsinblereport${clonemaxsinblereport1Icon}`} onClick={this.cloneReport} id="singleclonemaxsinblereports" title={lang["storyboard.clonemyreport"]}><i className="fa fa-files-o marg-righ-10"></i>{lang["storyboard.clonemyreport"]}</a></li>
																		<li><a href="javascript:void(0)" className="livestoryboard" onClick={this.liveReportOpen} id="singlelivestoryboards" data-num={this.props.chartid} title={lang["storyboard.live"]}><i className="fa fa-clock-o fa-flip-horizontal marg-righ-10"></i>{lang["storyboard.livereport"]}</a></li>
																		<li><a href="javascript:void(0)" className={`drilldownsettings${drilldownsettingsIcon}`} onClick={this.drilldownsettings} title={lang["storyboard.drilldownsettings"]}><i className="fa fa-cog marg-righ-10"></i>{lang["storyboard.drilldownsettings"]}</a></li>
																		<li className={`divider ${CustomizeVBool}`}></li>
																		<li className={`dropdown-header ${CustomizeVBool}`}>{lang["storyboard.customize"]}</li>
																		<li><a href="javascript:void(0)" className={`reporttheme${reporttheme1}`} onClick={this.reportTheme} id="singlereporttheme" title={lang["storyboard.reporttheme"]} data-num={this.props.chartid} data-toggle="modal" data-target="#tiletheme"><i className="fa fa-paint-brush marg-righ-10 fa-violet"></i>{lang["storyboard.reporttheme"]}</a>
																		</li><li><a href="javascript:void(0)" className={`maximizesetting1 ${maximizesetting1}`} onClick={this.maximizesettingmodal} id="singlemaximizesetting1" data-num={this.props.chartid} title={lang["storyboard.edittitle"]}><i className="fa fa-pencil marg-righ-10"></i>{lang["storyboard.edittitle"]}</a></li>
																		<li><a href="javascript:void(0)" className={`setsinglereportfavourite1 ${setsinglereportfavourite1Icon}`} onClick={this.setAsFavourite} id="setasfavourite" title={lang["storyboard.favorite"]}><i className="fa fa-star marg-righ-10"></i>{lang["storyboard.favorite"]}</a></li>
																		<li className={`divider ${CollaborateBool}`}></li>
																		<li className={`dropdown-header ${CollaborateBool}`}>{lang["storyboard.collaborate"]}</li>
																		<li><a href="javascript:void(0)" className={`sharesinglereport${sharesinglereport1Icon}`} onClick={this.sharesinglereport} id="sharesinglereports" title={lang["storyboard.share"]} data-direction="top" data-toggle="modal" data-target="#sharereportgraph"><i className="fa fa-users marg-righ-10"></i>{lang["storyboard.sharemyreport"]}</a></li>
																		<li><a href="javascript:void(0)" className={`embedreportmodal1${embedreportmodal1Icon}`} onClick={this.viewEmbedreport} id="embedreportmodal1" title={lang["storyboard.embedmyreport"]}><i className="fa fa-code marg-righ-10"></i>{lang["storyboard.embedmyreport"]}</a></li>
																		<li><a href="javascript:void(0)" className={`schedulereport${schedulereport1Icon}`} onClick={this.schedulereport} id="schedulereports" title={lang["storyboard.schedulemyreport"]}><i className="fa fa-clock-o marg-righ-10"></i>{lang["storyboard.schedulemyreport"]}</a></li>
																		<li><a href="javascript:void(0)" className={`alerts`} id="singlealerts" onClick={this.singlealerts} title={lang["storyboard.alerts"]}><i className="fa fa-exclamation-triangle marg-righ-10"></i>{lang["storyboard.alerts"]}</a></li>
																		<li><a href="javascript:void(0)" className={`download_singlereport ${downloadsinglereportsIcon}`} id="downloadsinglereports" title={lang["storyboard.download"]} data-num="chart0"><i className="fa fa-download fa-flip-horizontal marg-righ-10"></i>{lang["storyboard.download"]}</a></li>
																		<li><a href="javascript:void(0)" className={`export_myreport export_myreport_bool hide`} id="exportsinglereport" title={lang["storyboard.export"]} data-num="chart0"><i className="fa fa-external-link marg-righ-10" aria-hidden="true"></i>{lang["storyboard.export"]}</a></li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="text-right marg-top-5">
										<div className="opacitydiv">
											<div id="dobypagination" className={`paginationdiv${this.props.chartid}`} data-num={this.props.chartid} style={{ display: "block" }}>
												<div className="btn-group pull-right marg-left-10">
													<button className="btn btn-bird btn-sm dobyexport dropdown-toggle" id="dobyexportdownload" data-num={this.props.chartid} title={lang["storyboard.download"]} data-toggle="dropdown" aria-expanded="true"><i className="fa fa-download"></i></button>
													<ul className="dropdown-menu dropdown-menu-right megadropdown downloadreports">
														<li className="dropdown-header">{lang["storyboard.selectrows"]}</li>
														<li>
															<div className="container-fluid">
																<div className="col-xs-12 nopadding">
																	<div className="col-xs-6 nopadding">
																		{lang["storyboard.from"]}<input type="number" defaultValue="1" data-num={this.props.chartid} className="rowsfrom marg-righ-5" /></div>
																	<div className="col-xs-6 nopadding">
																		{lang["storyboard.to"]}<input type="number" data-num={this.props.chartid} defaultValue="100" className="rowsto" /></div>
																</div>
															</div>
														</li>
														<li>&nbsp;</li>
														<li><a href="javascript:void(0)" data-num={this.props.chartid} className="csv" id="" title={lang["storyboard.csv"]}><i className="fa fa-file-text-o"></i>{lang["storyboard.csv"]}</a></li>
														<li><a href="javascript:void(0)" data-num={this.props.chartid} className="xls" id="" title={lang["storyboard.xls"]}><i className="fa fa-file-excel-o"></i>{lang["storyboard.xls"]}</a></li>
														<li><a href="javascript:void(0)" data-num={this.props.chartid} className="pdf" id="" title={lang["storyboard.pdf"]}><i className="fa fa-file-pdf-o"></i>{lang["storyboard.pdf"]}</a></li>
													</ul>
													<button className="btn btn-bird-group btn-sm dobyselectcolumns" onClick={this.showdobyselectedcolumns} id="dobyselectcolumnsview" data-num={this.props.chartid} title={lang["storyboard.choosecolumns"]}><i className="fa fa-columns"></i></button>
												</div>
											</div>
										</div>
										<div className="opacitydiv">
											{!that.state.isViewOnlyFlags ?
												<button className="btn btn-bird btn-sm summarymodal marg-left-5 hide" id="summarymodalpop" data-num={this.props.chartid} data-toggle="modal" title={lang["storyboard.sumarysettings"]}><i className="fa fa-wrench fa-flip-horizontal"></i></button>
												: null}
										</div>
										<div className="opacitydiv">
											<button className="btn btn-bird btn-sm predictmodelsummary predictmodelsummary0 marg-left-5 hide" data-num={this.props.chartid} data-toggle="modal" title={lang["storyboard.summary"]}><i className="fa fa-info"></i></button>
										</div>
										<div className="opacitydiv">
											<div className="btn-group marg-righ-10" role="group">
												<button className="btn btn-bird-group btn-sm viewdatafromchart active hide" onClick={this.viewchartdata} id="viewdatafromchartviews" type="button" data-num={this.props.chartid} title={lang["storyboard.dataview"]}><i className="fa fa-table"></i> {lang["storyboard.dataview"]}</button>
												<button className="btn btn-bird-group btn-sm viewchartsummary hide" id="viewchartsummaryviews" onClick={this.viewchartsummaryforstoryboard} type="button" data-num={this.props.chartid} title={lang["storyboard.summaryview"]}><i className="fa fa-th-large"></i> {lang["storyboard.summaryview"]}</button>
											</div>
											{/* <button className={`btn btn-bird btn-sm chartchanger magictool marg-righ-5 ${maximizeChartChanger}`} id="chartstyleviews" type="button" data-num={this.props.chartid} title={lang["storyboard.chartstyle"]}><i className="fa fa-bar-chart"></i></button> */}

											{(that.state.isEmbeddedReport != true && that.state.isSharedReport == false) ? (
												that.state.allfeatures.map((featuresModule) => {
													if (featuresModule.moduleName == 'my_report') {
														return featuresModule.features.map((feature) => {
															if (feature.featureName == 'viewreport_maximize_data_behind_chart' && feature.allow == true) {
																return <div className={`btn-group chartfromdatabuttons${this.props.chartid}`} role="group">
																	<button className="btn btn-bird-group btn-sm active viewchartfromchartdata max-data-buttons" id="viewchartfromchartdataview" onClick={this.viewchartfromchartdata} type="button" data-num={this.props.chartid} title={lang["storyboard.chartview"]} style={{ display: "none" }}>	<i className="fa fa-bar-chart"></i></button>
																	{that.state.Multifact == "true" ?
																		<button className="btn btn-bird-group btn-sm viewchartdata max-data-buttons" onClick={this.viewchartdata} id="viewchartdataview" type="button" data-num={this.props.chartid} tab-id="0" tab-name="" title={lang["storyboard.summaryview"]}> style={{ display: "none" }}	<i class="fa fa-table"></i></button>
																		:
																		<button className="btn btn-bird-group btn-sm viewchartdata max-data-buttons" onClick={this.viewchartdata} id="viewchartdataview" type="button" data-num={this.props.chartid} tab-id="0" tab-name="" title={lang["storyboard.dataview"]} style={{ display: "none" }}>	<i class="fa fa-table"></i></button>
																	}
																</div>
															}
														})
													}
												})
											) : null}
											{(that.state.isEmbeddedReport != true && that.state.isSharedReport == false) ?
												that.state.allfeatures.map((featuresModule) => {
													if (featuresModule.moduleName == 'my_report') {
														return featuresModule.features.map((feature) => {
															if (feature.featureName == 'viewreport_maximize_trade_lines' && feature.allow == true) {
																return <><button className="btn btn-bird btn-sm marg-left-5 dropdown-toggle dropdown showtrending" id="showtrendinglistview" data-num={this.props.chartid} data-toggle="dropdown" title={lang["storyboard.trend"]}><i className="fa fa-line-chart "></i></button>
																	<ul className="dropdown-menu dropdown-menu-right">
																		<li className="trendtype" onClick={this.trendtypeSet} id="linear" data-num={this.props.chartid} title={lang["storyboard.lineartool"]}><a className="trendtypes linear" data-num={this.props.chartid} href="javascript:void(0)">{lang["storyboard.linear"]}</a></li>
																		<li className="trendtype" onClick={this.trendtypeSet} id="SMA" data-num={this.props.chartid} title={lang["storyboard.smatool"]}><a className="trendtypes sma" data-num={this.props.chartid} href="javascript:void(0)">{lang["storyboard.sma"]}<span className="opacity-effect"><input type="number" className="trendperiodsbox smaperiods" data-num={this.props.chartid} title={lang["storyboard.SMAP"]} min="2" max="" defaultValue="2" /></span></a></li>
																		<li className="trendtype" onClick={this.trendtypeSet} id="EMA" data-num={this.props.chartid} title={lang["storyboard.ematool"]}><a className="trendtypes ema" data-num={this.props.chartid} href="javascript:void(0)">{lang["storyboard.ema"]}<span className="opacity-effect"><input type="number" className="trendperiodsbox emaperiods" data-num={this.props.chartid} title={lang["storyboard.EMAP"]} min="2" max="" defaultValue="2" /></span></a></li>
																		<li className="trendtype" onClick={this.trendtypeSet} id="rsum" data-num={this.props.chartid} title={lang["storyboard.runsum"]}><a className="trendtypes rsum" data-num={this.props.chartid} href="javascript:void(0)">{lang["storyboard.runsum"]}<span className="opacity-effect"></span></a></li>
																		<li className="trendtype" onClick={this.trendtypeSet} id="rmin" data-num={this.props.chartid} title={lang["storyboard.runmin"]}><a className="trendtypes rmin0" data-num={this.props.chartid} href="javascript:void(0)">{lang["storyboard.runmin"]}<span className="opacity-effect"></span></a></li>
																		<li className="trendtype" onClick={this.trendtypeSet} id="rmax" data-num={this.props.chartid} title={lang["storyboard.runmax"]}><a className="trendtypes rmax0" data-num={this.props.chartid} href="javascript:void(0)">{lang["storyboard.runmax"]}<span className="opacity-effect"></span></a></li>
																		<li className="trendtype" onClick={this.trendtypeSet} id="ravg" data-num={this.props.chartid} title={lang["storyboard.runavg"]}><a className="trendtypes ravg0" data-num={this.props.chartid} href="javascript:void(0)">{lang["storyboard.runavg"]}<span className="opacity-effect"></span></a></li>
																		<li className="trendtype" onClick={this.trendtypeSet} id="rdiff" data-num={this.props.chartid} title={lang["storyboard.rundiff"]}><a className="trendtypes rdiff0" data-num={this.props.chartid} href="javascript:void(0)">{lang["storyboard.rundiff"]}<span className="opacity-effect"></span></a></li>
																		<li className="trendtype" onClick={this.trendtypeSet} id="rpercentage" data-num={this.props.chartid} title={lang["storyboard.percent"]}><a className="trendtypes rdiff0" data-num={this.props.chartid} href="javascript:void(0)">{lang["storyboard.percent"]}<span className="opacity-effect"></span></a></li>
																		<li className="trendtype" onClick={this.trendtypeSet} id="trendstats" data-num={this.props.chartid} title={lang["storyboard.stats"]}><a className="trendtypes trendstatschart0" data-num={this.props.chartid} href="javascript:void(0)">{lang["storyboard.stats"]}</a></li>
																	</ul></>
															}
														})
													}
												})
												: null}
										</div>
										<div className="btn-group bgcommon predictionChart0 hide  ">
											<button type="button" className="btn btn-bird-group btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="More">
												<span className="caret"></span>&nbsp; <span className="Predictionmore"></span>H2o Charts</button>
											<ul className="dropdown-menu">
												<li><a href="javascript:void(0)" data-num={this.props.chartid} className="boc-maxscreen-left-item  h2ochartprediction scorehistory"><i className="fa fa-bar-chart marg-righ-10"></i>Score History</a></li>
												<li><a href="javascript:void(0)" data-num={this.props.chartid} className="boc-maxscreen-left-item h2ochartprediction  variableimportance"><i className="fa fa-bar-chart marg-righ-10"></i>Variable Importance</a></li>
												<li><a href="javascript:void(0)" data-num={this.props.chartid} className="boc-maxscreen-left-item h2ochartprediction  h2odatatable"><i className="fa fa-summary-table-16 marg-righ-10"></i>H2oData Table</a></li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div id="singlequeryinfo" className="singlequeryinfodiv0 maximizesinglequeryinfo" data-num={this.props.chartid}></div>
							<div id="singlereportsetting" className={`singlereportsettingdiv${this.props.chartid}`} data-num={this.props.chartid}>
							</div>
							<div id={`savesinglereport${this.props.chartid}`} className={`singlereportdiv${this.props.chartid}`} data-num={this.props.chartid}>
							</div>
							<div className={`append_alertmodal append_alertmodal${this.props.chartid}`} id={`append_alertmodal${this.props.chartid}`}></div>
							<div id="sharesinglereport" className={`sharesinglereportdiv${this.props.chartid}`} data-num={this.props.chartid}>
							</div>
							<div className="embedreportdiv0" data-num={this.props.chartid}></div>
							<div id="maxfiltermodal">
							</div>
							<div id="drilldownfiltersid" className="drilldownfiltersid hide"></div>
							<div id="chartdrilldowndiv" className={`chartdrilldowndiv${this.props.chartid}`} data-num={this.props.chartid} tab-id="0" tab-name="">
								<div className="append_drillthroughoptions"></div>
							</div>
							<div id="smartinsightchoosefielddiv" className={`smartinsightchoosefielddiv${this.props.chartid}`} data-num={this.props.chartid} tab-id="0" tab-name="">
								<div className="append_drillthroughoptions"></div>
							</div>
							<div id="schedulereportdiv" className="schedulereportdiv"></div>
							<div className="opacitydiv">
								<div className={`pivotsettingsmain${this.props.chartid} marg-top-5`} id="pivotcolumns" style={{ display: "none" }}>
									<div className="btn-group pull-left">
										<button className="btn btn-bird btn-sm pull-left prev marg-righ-5" onClick={this.loadPrevious} data-num={this.props.chartid} data-toggle="modal" title={lang["storyboard.previous"]}>{lang["storyboard.prev"]}</button>
										<button type="button" className="btn btn-bird btn-sm dropdown-toggle dropdown pull-left marg-righ-5 next" onClick={this.loadNext} data-num={this.props.chartid} title={lang["storyboard.next"]} data-toggle="dropdown" aria-expanded="false">{lang["storyboard.next"]}</button>
										<select className="numberofpivotrows" onChange={this.loadPivotRows} id="numberofpivotrows1" data-num={this.props.chartid}>
											<option selected="selected" value="10">10</option>
											<option value="25">25</option>
											<option value="50">50</option>
											<option value="100">100</option>
										</select>
									</div>
								</div>
							</div>
							<div className="opacitydiv">
								<div className={`btn-group pull-right pivotnavigationbuttons${this.props.chartid}`} style={{ display: "none" }}>
									<button className="btn btn-bird btn-sm pull-right pivotcolumnmodal" onClick={this.pivotcolumnmodal} data-num={this.props.chartid} data-toggle="modal" title={lang["storyboard.pivotsettings"]}><i className="fa fa-wrench fa-flip-horizontal"></i></button>
								</div>
							</div>
							<div className="opacitydiv">
								<div className={`btn-group pull-right pivotcharticon${this.props.chartid}`} style={{ display: "none" }}>
									{that.state.allfeatures.map((featuresModule) => {
										return featuresModule.features.map((feature) => {
											if (feature.featureName == 'viewreport_pivot_chart' && feature.allow == true) {
												if (that.state.isViewOnlyFlags == false) {
													return <><button type="button" className="btn btn-bird btn-sm dropdown-toggle dropdown pull-right marg-righ-5 pivotcharttypes" data-num={this.props.chartid} title={lang["storyboard.chart"]} data-toggle="dropdown" aria-expanded="false"><i className="fa fa-bar-chart"></i></button>
														<ul className="dropdown-menu dropdown-menu-right">
															<li className="charttype " id="bar" data-num={this.props.chartid}><a className="pivotchartmodal" onClick={this.showpivotchart} data-type="pivotbar" data-num={this.props.chartid} href="javascript:void(0)"><img className="chart-icons-18" src={require('../images/birdimages/charts/basicbar-20.png')} />{lang["storyboard.defaultbar"]}</a></li>
															<li className="charttype " id="stackedbar" data-num={this.props.chartid}><a className="pivotchartmodal" onClick={this.showpivotchart} data-type="pivotstackedbar" data-num={this.props.chartid} href="javascript:void(0)"><img className="chart-icons-18" src={require('../images/birdimages/charts/stackedbar-20.png')} />{lang["storyboard.stackedbar"]}</a></li>
															<li className="charttype" id="line" data-num={this.props.chartid}><a className="pivotchartmodal" onClick={this.showpivotchart} data-type="pivotline" data-num={this.props.chartid} href="javascript:void(0)"><img className="chart-icons-18" src={require('../images/birdimages/charts/linechart-sm-20.png')} />{lang["storyboard.linechart"]}</a></li>
															<li className="charttype " id="area" data-num={this.props.chartid}><a className="pivotchartmodal" onClick={this.showpivotchart} data-type="pivotarea" data-num={this.props.chartid} href="javascript:void(0)"><img className="chart-icons-18" src={require('../images/birdimages/charts/areachartbasic-20.png')} />{lang["storyboard.areachart"]}</a></li>
															<li className="charttype " id="stackedarea" data-num={this.props.chartid}><a className="pivotchartmodal" onClick={this.showpivotchart} data-type="pivotstackedarea" data-num={this.props.chartid} href="javascript:void(0)"><img className="chart-icons-18" src={require('../images/birdimages/charts/stackedarea-20.png')} />{lang["storyboard.stackarea"]}</a></li>
															<li className="charttype " id="pivot" data-num={this.props.chartid}><a className="pivotchartmodal" onClick={this.showpivotchart} data-type="pivot" data-num={this.props.chartid} href="javascript:void(0)"><img className="chart-icons-18" src={require('../images/birdimages/charts/pivottable-20.png')} />{lang["storyboard.pivot"]}</a></li>
														</ul></>
												}
											}
										})
									})}
									<button type="button" className="btn btn-bird btn-sm pull-right marg-left-5 marg-righ-5 pivotchartsettingsicons" onClick={this.pivotchartsettingsicon} data-num={this.props.chartid} title={lang["storyboard.pivotchartsettings"]}><i className="fa fa-cogs"></i></button>
								</div>
							</div>
							<div className={`richtextcontrolschart${this.props.chartid} pull-left"`} style={{ display: "none" }}>
								<span className="countcharacters" data-num={this.props.chartid}></span>
							</div>
							<div className="opacitydiv">
								<div className={`btn-group pull-right richtextcontrolschart${this.props.chartid} marg-top-5 marg-righ-10`} style={{ display: "none" }}>
									<button type="button" className="btn btn-bird btn-sm editrichtext marg-righ-5" id="editopacity" data-num={this.props.chartid} onClick={(e) => { this.editrichtext(e); }}>{lang["storyboard.edit"]}</button>
									<button type="button" className="btn btn-bird btn-sm cancelrichtext marg-righ-5" id="cancelopacity" data-num={this.props.chartid} onClick={(e) => { this.cancelrichtext(e); }}>{lang["storyboard.cancelbtn"]}</button>
									<button type="button" className="btn btn-bird btn-sm saverichtext" id="applyopacity" data-num={this.props.chartid} onClick={(e) => { this.applyTextChart(e); }}>{lang["storyboard.applybtn"]}</button>
								</div>
							</div>
							<div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 fullscreensample nopadding">
								<div className="chartadvancedoptions" data-num={`chart${this.props.chartid}`}>
									<div className="chartadvancedoptions-container pull-right">
										<button className="btn btn-transparent seriescolors tooltip-right" onClick={this.seriescolors} data-tooltip={lang["storyboard.seriescolors"]} data-num={`chart${this.props.chartid}`}><i className="fa fa-th-list"></i></button>
									</div>
									<div className="showchartoptions" title={lang["storyboard.chartcustom"]}><i className="fa fa-angle-double-down"></i></div>
								</div>
								<div className="doby-offcanvas-columnlist backdrop" data-num={this.props.chartid}></div>
								<div className="doby-offcanvas-columnlist" data-num={this.props.chartid}>
									<span className="dobyselectcolumns0 closedobycolumns dobyselectcolumns closeselectedcolumns" onClick={this.cancelselectedcolumns} data-num={this.props.chartid}><i className="fa fa-times-circle"></i></span>
									<div className="container-fluid">
										<div className="row dobycolumnscontent">
											<legend className="subhead">{lang["storyboard.choosecolumns"]}</legend>
											<div className={`innerdobyselectedcolumns${this.props.chartid} scrollsetdobycolumns`}></div>
										</div>
										<div className="dobycolumnslimitbuttons">
											<button type="button" className="btn btn-bird btn-sm dobyselectcolumns closeselectedcolumns" onClick={this.cancelselectedcolumns} id="closeselectedcolumnsof" data-num={this.props.chartid}>{lang["storyboard.cancelbtn"]}</button>
											<button type="button" className="btn btn-bird btn-sm applydobysettings" onClick={this.applydobysettings} id="applydobysettingsof" data-num={this.props.chartid}>{lang["storyboard.applybtn"]}</button>
										</div>
									</div>
								</div>
								{/* <div id={`datatablepopupmodal${this.props.chartid}`}></div> */}
								<div className={`pivotcolumnmodal${this.props.chartid}`}></div>
								<div className={`pivotchartsettingsicons${this.props.chartid}`}></div>
								<div className={`summarytablemodalcontainer${this.props.chartid}`}></div>
								<div className={`customfieldhierarchymodalcontainer${this.props.chartid}`} id="customfieldhierarchymodalcontainer"></div>
								<div className={`customparametermodalcontainer${this.props.chartid}`}></div>
								<div className={`append_customrange${this.props.chartid}`}></div>
								<div className={`customaggregatedfield${this.props.chartid}`}></div>
								<div id="advancedchartsetingsmodaldiv" className={`advancedchartsetingsmodaldiv${this.props.chartid}`}></div>
								<div className={`append_summarytablemodal append_summarytablemodal${this.props.chartid}`}></div>
								<div id={`chart${this.props.chartid}`} className="width-100 maxanim10" data-num={this.props.chartid} ref="chartid">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='gridspinner' id={`spinner${this.props.chartid}`} data-num={this.props.chartid} tab-id="0" tab-name="">
					<div className="gridspinnercontent gridspinnermessage"></div>
					<div className='gridspinnercontent'>
						<div className="gridload"><div></div><div></div><div></div><div></div></div>
					</div>
				</div>
			</React.Fragment>

		);
	}
	applycolorthemes() {
		//individual tile color themes
		var chartDetails = {};
		if (ChartInfo.functions.getChartDetails(this.props.chartnumber) != undefined) {
			chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
		}
		if (chartDetails["reportcolortheme"] != undefined) {
			var reportcolortheme = JSON.parse(chartDetails["reportcolortheme"]);
			if (reportcolortheme.datacount == 'hide') {
				$('.maxscreendatacount[data-num=' + this.props.chartnumber + ']').addClass('hide');
			} else if (reportcolortheme.datacount == 'show') {
				$('.maxscreendatacount[data-num=' + this.props.chartnumber + ']').removeClass('hide');
			}

			if (reportcolortheme.toolbar == 'hamburger') {
				$('.reporttitletoolbar' + this.props.chartnumber).removeClass('col-lg-12 col-md-12');
				$('.reporttitlehamburger' + this.props.chartnumber).removeClass('hidden-lg hidden-md');

				$('.maxleftpanecustomhidden[data-num=' + this.props.chartnumber + ']').addClass('hide');
				$('.reporttitletoolbar' + this.props.chartnumber).addClass('hidden-lg hidden-md');
				$('.reporttitlehamburger' + this.props.chartnumber).addClass('col-lg-12 col-md-12');
			}
			if (reportcolortheme.titlecolor != undefined) {
				$('.maxanim9 h2.boc-viewreport-title[data-num=' + this.props.chartnumber + ']').css('color', reportcolortheme.titlecolor);
				$('.maxscreendatacount[data-num=' + this.props.chartnumber + ']').css('color', reportcolortheme.titlecolor);
				$('.maxanim4[data-num=' + this.props.chartnumber + ']').css('color', reportcolortheme.titlecolor);
			}
			if (reportcolortheme.reporttilebackground != undefined) {
				$('.maxanim7[data-num=' + this.props.chartnumber + ']').css('background-color', reportcolortheme.reporttilebackground);
			}
			if (reportcolortheme.headerbackground != undefined) {
				/*$('.maxanim9[data-num='+this.props.chartnumber+']').css('background-color',reportcolortheme.headerbackground);*/
				$('.reporttoolbar[data-num=' + this.props.chartnumber + '] .controlbar-main').css('background-color', reportcolortheme.headerbackground);
				$('.maxanim4[data-num=' + this.props.chartnumber + ']').parent().css('background-color', reportcolortheme.headerbackground);
			}
		}
		//storyboard color theme
		if (this.state.reportId != undefined) {
			var model = viewcollection.model;
			var reportdetails = JSON.parse(model.reportdata);
			if (reportdetails[6] != undefined && reportdetails[6].stroyboardcolortheme.length > 0) {
				var storyboardcolortheme = JSON.parse(reportdetails[6].stroyboardcolortheme)[0];
				setTimeout(function () {
					if (storyboardcolortheme.storyboarddatacount == 'hide') {
						$('.storyboarddatacount').addClass('hide');
					} else if (storyboardcolortheme.storyboarddatacount == 'show') {
						$('.storyboarddatacount').removeClass('hide');
					}

					if (storyboardcolortheme.storyboardtoolbar == "hamburger") {
						$('.reporttitletoolbarfull').removeClass('col-lg-12 col-md-12');
						$('.storyboardtoolbarhamburger').removeClass('hidden-lg hidden-md');
						/*$('.storyboardtoolbarfull').addClass('hidden-lg hidden-md');
						$('.storyboardtoolbarhamburger').addClass('col-lg-12 col-md-12');*/
					}
					$('.boc-viewreport-title[data-num=storyboard]').css('color', storyboardcolortheme.storyboardtitlecolor);
					$('.storyboarddatacount').css('color', storyboardcolortheme.storyboardtitlecolor);
					$('.leftpane').attr('style',"background-color : "+storyboardcolortheme.storyboardbackground+" !important");
					$('.maxanim1').css('background-color', storyboardcolortheme.storyboardbackground);
					$('.maxanim2').css('background-color', storyboardcolortheme.storyboardbackground);
					//$('.fullscreensample').css('background-color',storyboardcolortheme.storyboardbackground);
					$('#reportheader .controlbar-main').css('background-color', storyboardcolortheme.storyboardheaderbackground);
					$('.footer').css('background-color', storyboardcolortheme.storyboardfooterbackground);
					$('.text-muted').css('color', storyboardcolortheme.storyboardfootertext);
				}, 150);
				sessionStorage.setItem('stroyboardcolortheme', reportdetails[6].stroyboardcolortheme)
			}
		}
	}
	onMaximisereport(e) {
		$("body").addClass("maximized");
		var isExportChartEnable = false;
		$.each(this.state.allfeatures, function (index, featuresModule) {
			if (featuresModule.moduleName == 'my_report') {
				$.each(featuresModule.features, function (index, feature) {
					if (feature.featureName == 'viewreport_maximize_export_option' && feature.allow == true) {
						isExportChartEnable = true;
					}
				})
			}
		});
		if (isExportChartEnable) {
			$('.highcharts-button').show();
		}
		sessionStorage.setItem('pos', "max");
		var $this = e.currentTarget;
		$($this).closest('.maxanim1').find(".databehindchart").remove();
		this.state.chartnumber = $($this).closest('.maxanim1').attr("data-num");
		$('#spinner' + $($this).closest('.maxanim1').attr("data-num")).show();
		this.adjustChartHeight($this, "max")
		//	PubSub.publish('adjustchartsize', this.state.chartnumber);
		if (this.schemaType == "pdf" || this.schemaType == "csv" || this.schemaType == "elasticsearch" || this.schemaType == "mongodb" || this.schemaType == "fuse" || this.schemaType == "customstoryboard" || this.schemaType == "webdataconnector"
			|| this.schemaType == "rabbitmq" || this.schemaType == "json" || this.schemaType == "eventhub" || this.schemaType == "kafka" || this.schemaType == "websocket" || this.schemaType == "log" || this.schemaType == "nlp" || this.schemaType == "xls" || this.schemaType != 'log_sftp' || this.schemaType != 'log_ftp') {
			$(".singlequeryinfomodal").addClass("hide");
		}
		var addclass;
		if ($($this).closest('.maxanim1').hasClass('highlight')) {
			addclass = 'fullscreenmain nopadding maximized maxanim1 highlight'
		} else {
			addclass = 'fullscreenmain nopadding maximized maxanim1'
		}
		$($this).closest('.maxanim1').removeClass().addClass(addclass);
		$($this).closest('.maxanim1').find('.maxanim2').removeClass('boc-snapshot-auto margin-viewreport').addClass('container-fluid nopadding');
		$($this).closest('.maxanim1').find('.maxanim3').removeClass('boc-snapshot-options').addClass('maxleftpanehidden');
		$($this).closest('.maxanim1').find('.maxanim4').removeClass('boc-snapshot-title').addClass('maxleftpanehidden');
		$($this).closest('.maxanim1').find('.maxanim5').removeClass('col-xs-12 nopadding').addClass('row-same-height');
		$($this).closest('.maxanim1').find('.maxanim6').removeClass('row maxleftpanehidden').addClass('col-xs-1 col-xs-height nopadding maxleftpane');
		$($this).closest('.maxanim1').find('.maxanim7').removeClass('col-xs-12 nopadding').addClass('col-xs-12 col-xs-height nopadding');
		$($this).closest('.maxanim1').find('.maxanim8').removeClass('container-fluid nopadding').addClass('container-fluid scrollset');
		$($this).closest('.maxanim1').find('.maxanim9').removeClass('maxleftpanehidden').addClass('');
		$(".publishinfo").text("Dataset Count: " + ChartInfo.functions.getDataSetCount());
		$(window).trigger('resize');
		$($this).closest('.maxanim1').find('.maxanim7').css("max-width", '95vw');
		//  setTimeout(function(){
		//   $('#spinner'+$($this).attr('data-num')).hide();
		//   },550);
		$('.pie-menu').remove();
		$('.mobilemenu-storyboard').removeClass('leftshow').addClass('righthide');
		if (this.schemaType == "pdf" || this.schemaType == "xls" || this.schemaType == "csv" || this.schemaType == "customstoryboard" || this.schemaType == "json" || this.schemaType == "log" || this.schemaType == "xlsx" || this.schemaType == "fuse") {
			$('.livestoryboard').addClass('hide');
		}
		if (this.schemaType == "customstoryboard" || this.schemaType == "fuse" || this.schemaType == "nlp") {
			$('.export_myreport_bool').addClass('hide');
		}
		if (this.schemaType == "fuse" || this.schemaType == "nlp") {
			$('#exportstoryboardreport').addClass('hide');
		}
		if ($(".viewchartdataforstoryboard").hasClass("active") == true ||
			$(".viewchartsummaryforstoryboard").hasClass("active") == true) {
			$(".viewchartfromchartdata[data-num='" + $($this).closest('.maxanim1').attr("data-num") + "']").click();
		}
		if (this.state.multifact == "true") {
			$('.viewchartdata').attr('title','Summary View')
			 }
		var auditMetaDataFeature = false;
		var auditDataFeature = false;
		$.each(this.state.allfeatures, function (index1, feature1) {
			if (feature1.moduleName == 'my_report') {
				$.each(feature1.features, function (index2, feature2) {
					if (feature2.featureName == 'audit_meta_data_maximize' && feature2.allow == true) {
						auditMetaDataFeature = true;
						auditDataFeature = true;
					}
				});
			}

		});
		var jsonObject = ChartInfo.functions.getAuditMetaDataOptions();
		ChartInfo.functions.displaymetadata(jsonObject, auditMetaDataFeature, auditDataFeature);

		$($this).closest('.maxanim1').attr("data-num");
		$('.charttype.active').attr('id');

		var chartNum = $($this).closest('.maxanim1').attr("data-num");
		var chartData = ChartInfo.functions.getChartDetails(chartNum);
		var jsonData = JSON.parse(chartData);
		var chartType = jsonData.chartType;
		var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
		let compareDateRangeObj = jsonData!=undefined && jsonData["compareDateRangeObj"]!=null?jsonData["compareDateRangeObj"]:null;
		let tempYaxis = jsonData!=undefined?jsonData["yAxis"]:[];
		let checkcondition = false;
		if(compareDateRangeObj!=null && compareDateRangeObj.hasOwnProperty("0") && compareDateRangeObj["0"]!=null){
			let yjsObj = compareDateRangeObj["0"]; 
			_.each(tempYaxis,function(val,index){
			if(val.columnName!=undefined&&val.columnName!=null&&yjsObj.hasOwnProperty(val.columnName)){
				checkcondition = true;
			}
		});
		}
		if(checkcondition&&comparedaterangecharttpes.indexOf(chartType)>=0){
			$(".showtrending[data-num='" + chartNum + "']").hide();
			$(".chartfromdatabuttons" + chartNum).hide();
			$(".viewchartfromchartdata[data-num='" + chartNum + "']").hide();
		}
		
		var isversioned=ChartInfo.functions.getisversioned();
		if (!ChartUtil.isTrendSupport(chartType)) {
			$(".showtrending[data-num='" + chartNum + "']").hide();
		}
		if (chartType == "pivot") {
			$(".dobyselectcolumns[data-num='" + chartNum + "']").addClass('hide');
			$('.dobyexport').addClass('hide');
		}
		if ("doby" === chartType) {
			$(".chartfromdatabuttons" + chartNum).hide();
			$(".chartchanger[data-num='" + chartNum + "']").hide();
			$(".viewchartfromchartdata[data-num='" + chartNum + "']").hide();
			$('.boc-maxscreen-left-item[data-num=' + chartNum + '].pivotgrid').removeClass("showactive");
			$('.boc-maxscreen-left-item[data-num=' + chartNum + '].previewChart').removeClass("showactive");
			$('.boc-maxscreen-left-item[data-num=' + chartNum + '].summarytable').removeClass("showactive");
			$('.boc-maxscreen-left-item[data-num=' + chartNum + '].dobygrid').addClass("showactive");
			$(".showtrending[data-num=" + chartNum + "]").hide();
			$(".dobyselectcolumns[data-num='" + chartNum + "']").removeClass('hide');
			$('.dobyexport').removeClass('hide');
		}
		$('.quickfilterremove').unbind().click((e) => {
			e.preventDefault();
			PubSub.publish("quickfilterremove", e);
		});
		if(isversioned=='Y'){
			$("#maximizemore[data-num='" + chartNum + "']").addClass("hide");
		}
		else{
			$("#maximizemore[data-num='" + chartNum + "']").removeClass("hide");
		}
		setTimeout(function () {
			$(window).resize();
			if ($("#storyboardname").val() == "") {
				$("#maximizemore[data-num='" + chartNum + "']").addClass("hide");
				$(".maximize_intelligentsearch").addClass("hide");
				$("#maximizemore").addClass("hide");
				//$(".fa-filter").addClass("hide");		
				$(".refreshsinglecharts1").addClass("hide");
			}
			$('#spinner'+chartNum).hide();
		}, 550);

	}

	onMinimisereport(e) {
		$("body").removeClass("maximized");
		$(".fa-filter").removeClass("hide");
		sessionStorage.setItem('pos', undefined);
		var $this = e.currentTarget;
		var chartNum = $($this).closest('.maxanim1').attr("data-num");
		this.state.chartnumber = chartNum;
		$($this).closest('.maxanim1').addClass("hideoverflow");
		$('#spinner'+chartNum).show();
		if ($(".maxanim1[data-num='" + $($this).closest('.maxanim1').attr("data-num") + "']").find('.viewchartdata').hasClass("active") == true) {
			$(".viewchartfromchartdata[data-num='" + $($this).closest('.maxanim1').attr("data-num") + "']").click();
		}
		$('.highcharts-button:not([states])').hide();
		$('.dobypagination').hide();
		//$('.trend-stats-container').remove();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		var addclass;
		if ($($this).closest('.maxanim1').hasClass('highlight')) {
			addclass = 'tileview col-xs-12 maxanim1 grid-stack-item ui-draggable ui-resizable ui-resizable-autohide highlight'
		} else {
			addclass = 'tileview col-xs-12 maxanim1 grid-stack-item ui-draggable ui-resizable ui-resizable-autohide'
		}
		$($this).closest('.maxanim1').removeClass('fullscreenmain nopadding maximized').addClass(addclass);
		$($this).closest('.maxanim1').find('.maxanim2').removeClass('container-fluid nopadding').addClass('boc-snapshot-auto');
		$($this).closest('.maxanim1').find('.maxanim3').removeClass('maxleftpanehidden').addClass('boc-snapshot-options');
		$($this).closest('.maxanim1').find('.maxanim4').removeClass('maxleftpanehidden').addClass('boc-snapshot-title');
		$($this).closest('.maxanim1').find('.maxanim5').removeClass('row-same-height').addClass('col-xs-12 nopadding');
		$($this).closest('.maxanim1').find('.maxanim6').removeClass('col-xs-1 col-xs-height nopadding maxleftpane').addClass('row maxleftpanehidden');
		$($this).closest('.maxanim1').find('.maxanim7').removeClass('col-xs-12 col-xs-height nopadding').addClass('col-xs-12 nopadding');
		$($this).closest('.maxanim1').find('.maxanim8').removeClass('container-fluid scrollset').addClass('container-fluid nopadding');
		$($this).closest('.maxanim1').find('.maxanim9').removeClass('row').addClass('row maxleftpanehidden');
		$(".filtersmodaldiv" + $($this).closest('.maxanim1').attr("data-num")).empty();
		$(".attributesmodaldiv" + $($this).closest('.maxanim1').attr("data-num")).empty();
		$(window).trigger('resize');
		$('.doby-grid-dropdown-menu').remove();
		//	PubSub.publish('adjustchartsize',this.state.chartnumber);
		this.adjustChartHeight($this, "min");
		if ($('.doby-offcanvas-columnlist').hasClass("show")) {
			$('.doby-offcanvas-columnlist').toggleClass("show");
		}
		$($this).closest('.maxanim1').find('.maxanim7').css("max-width", '100vw');
		$('.pie-menu').remove();
		var auditMetaDataFeature = false;
		var auditDataFeature = false;
		$.each(this.state.allfeatures, function (index1, feature1) {
			if (feature1.moduleName == 'story_board') {
				$.each(feature1.features, function (index2, feature2) {
					if (feature2.featureName == 'audit_meta_data' && feature2.allow == true) {
						auditMetaDataFeature = true;
						auditDataFeature = true;
					}
				});
			}

		});
		var jsonObject = ChartInfo.functions.getAuditMetaDataOptions();
		ChartInfo.functions.displaymetadata(jsonObject, auditMetaDataFeature, auditDataFeature);
		setTimeout(function () {$('#spinner'+chartNum).hide();$('.maxanim1[data-num="'+chartNum+'"]').removeClass("hideoverflow");},550);
	}
	modifyChartHeightOnGridResize(msg, data) {
		this.adjustChartHeight(data);
		//this.state.chartnumber = $(data).attr("data-num");
	}
	adjustChartHeightEveCap(msg, data) {
		this.adjustChartHeight(data.element, data.pos);
	}
	adjustChartHeight(element, pos) {
		var ua = navigator.userAgent;
		if(this.state.chartnumber==undefined && this.chartnumber!=undefined){
			this.state.chartnumber=this.chartnumber;
		}
		var is_mobile_chrome = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua));
		if ($(element).attr("data-num") == this.state.chartnumber) {
			this.pos = pos;
			var that = this;
			var chartType ="";
			setTimeout(function () {
				var parentHeight = /*$(element).closest(".tileview").height()*/ $(".maxanim1[data-num='" + that.state.chartnumber + "']").height() - 42;
				/*var parentWidth = $(element).closest(".tileview").width() - 40;*/
				var parentWidth = /*$(element).closest(".tileview").width()*/  $(".maxanim1[data-num='" + that.state.chartnumber + "']").width() - 2;
				var chartDetails = ChartInfo.functions.getChartDetails(that.state.chartnumber);
				if(chartDetails!=undefined){
				chartDetails=JSON.parse(ChartInfo.functions.getChartDetails(that.state.chartnumber));
				chartType = chartDetails["chartType"];
				}
				if (ChartInfo.functions.getChartTypeForMap(that.state.chartnumber) != undefined) {
					chartType = ChartInfo.functions.getChartTypeForMap(that.state.chartnumber);
				}
				if ($(window).width() < 480) {
					$(window).resize(function () {
						$('.metric-container').css('display', 'inline');
						var height = $("#chart" + that.state.chartnumber).height();
						var width = $("#chart" + that.state.chartnumber).width();
						//  ChartUtils.updateReport("chart"+that.state.chartnumber,null,height,width);
					});
				}
				if (pos != "max") {
					if (chartType === "doby" || chartType === "pivot" || chartType === "textchart" || chartType === "metric" || chartType === "solidgauge" || chartType === "fullcirclegauge" || chartType == "predictionchart" || chartType == "h2o_datatable" || chartType === "summarytable" || ChartInfo.functions.getDateHistogramStatus(that.state.chartnumber) == true || chartType == "h2o_variableimportance" || chartType == "h2o_scorehistory" || chartType == "sparkline") {
						$(".maxanim1[data-num='" + that.state.chartnumber + "']").find('.storyboard-data-buttons').hide();
					} else {
						$(".maxanim1[data-num='" + that.state.chartnumber + "']").find('.storyboard-data-buttons').show();
					}
					if (chartType === "doby" || chartType == "h2o_datatable") {
						$("#chart" + that.state.chartnumber).css("height", parentHeight);

						var baseHeight = $("#chart" + that.state.chartnumber).height(),
							headerHeight = $("#chart" + that.state.chartnumber + " .notifications-table-container:nth(0) table>thead").height(),
							paginationHeight = $("#chart" + that.state.chartnumber + " .notifications-table-container:nth(1)").height();
						var chartHeight = baseHeight - (headerHeight + paginationHeight + 0) + 22;
						if (dataHeight != null && chartHeight >= dataHeight) { chartHeight = chartHeight - (chartHeight - dataHeight) + 33 };
						$(".drawbootstraptable" + that.state.chartnumber + " .table").bootstrapTable('resetView', { height: chartHeight });
					}
					if (chartType === "pivot") {
						$('#chart' + that.state.chartnumber + ' .pivotscroll').css("max-height", $(element).closest(".tileview").height() + -100 + "px");
						//$(".pivotnavigationbuttons"+that.state.chartnumber).show();
						$("#chart" + that.state.chartnumber).css("height", "");
					}
					if (chartType == "textchart") {
						//$('#chart' + that.state.chartnumber).remove();
						//style="display: block;height: 200px;overflow-y: auto;overflow-x: hidden;"
						var textheight = $("#tileview" + that.state.chartnumber).height() - $(".maxanim3[data-num=" + that.state.chartnumber + "]").height() - 14;
						$("#chart" + that.state.chartnumber).css({
							'display': 'block',
							'overflow-y': 'auto',
							'height': textheight,
							'overflow-x': 'auto',
							'word-wrap': 'break-word',
							//'max-width': 'calc(100vw - 150px)'
						});
						if ($("#chart" + that.state.chartnumber).next().hasClass("note-editor")) {
							$("#chart" + that.state.chartnumber).hide();
						}
						$('.richtextcontrolschart' + that.state.chartnumber).hide();
					}
					if (chartType != "doby" && chartType != "pivot" && chartType != "textchart" && chartType !== "trellis" && chartType != "h2o_datatable") {
						//if($(".drawbootstraptable"+that.state.chartnumber).length===0){$("#chart"+that.state.chartnumber).css("height", "");}else {$("#chart"+that.state.chartnumber).css("height", parentHeight);}						
					}
					if (chartType == "solidgauge" || chartType == "fullcirclegauge") {
						// parentHeight = parentHeight - 90;
						parentHeight = parentHeight - 0;
						parentWidth = $("#chart" + that.state.chartnumber).width()
						//$("#chart"+that.state.chartnumber).css("height", parentHeight);						
					}
					$('.paginationdiv' + that.state.chartnumber).hide();
					$(".pivotsettingsmain" + that.state.chartnumber).hide();
					$(".pivotnavigationbuttons" + that.state.chartnumber).hide();
					//					$("#spinner"+that.state.chartnumber).show();
					if (chartType !== "trellis" && chartType !== "sparkline") {
						if (chartType == "metric") {
							$(element).closest(".tileview").find(".highcharts-container").height($(element).closest(".tileview").height() - 2).css("height", $(element).closest(".tileview").height() - 2 + "px");
							//$(element).closest(".tileview").find(".highcharts-container").width(parentWidth);
						} else {
							$(element).closest(".tileview").find(".highcharts-container").height(parentHeight);
							$(element).closest(".tileview").find(".highcharts-container").width(parentWidth);
						}
					} else if (chartType === "sparkline") {
						parentHeight = parentHeight - 10;
						$("#chart" + that.state.chartnumber + " .sparklinecontainer").css("height", parentHeight);
						$("#chart" + that.state.chartnumber + " .sparklinecontainer").parent().css("height", "");
						parentHeight = parentHeight - 112;
						$("#chart" + that.state.chartnumber + " .dataTables_scrollBody").css("height", parentHeight);
						if ($("#chart" + that.state.chartnumber + " .dataTables_scrollBody table").height() < $("#chart" + that.state.chartnumber + " .dataTables_scrollBody").height()) {
							$("#chart" + that.state.chartnumber + " .dataTables_scrollBody").css("height", $("#chart" + that.state.chartnumber + " .dataTables_scrollBody table").height() + 12);
						}
						$("#chart"+that.state.chartnumber+"").height($(".maxanim1[data-num=" + that.state.chartnumber + "]").height() - 42);
                    
					} else {
						var chartscount = $("#chart" + that.state.chartnumber + " #trellis td").length;
						var parentwidth = $('#chart' + that.state.chartnumber).width() - 20;
						parentWidth = parentwidth / chartscount;
						if (parentWidth < 300) { parentWidth = 300; }
						parentHeight = parentHeight - 10;
						$(element).closest(".tileview").find("#chart" + that.state.chartnumber).height("");
						$(element).closest(".tileview").find(".highcharts-container").height(parentHeight);
					}
					if (chartType === "summarytable") {
						/*parentHeight=parentHeight-10;*/
						$("#chart" + that.state.chartnumber + " .summartytablecontainer").css("height", parentHeight);
						$("#chart" + that.state.chartnumber + " .summartytablecontainer").parent().css("height", "");
						var tfootTotal = $("#chart" + that.state.chartnumber + " .summartytablecontainer .dataTables_scrollFoot").height();
						if(tfootTotal!=undefined){
							tfootTotal = tfootTotal + 100
						}else{
							tfootTotal = 100
						}
						var breadcrumbHeight = $("ul.breadcrumb[data-num="+that.state.chartnumber+"]").outerHeight();
						if(breadcrumbHeight==undefined){breadcrumbHeight = 0}
						parentHeight = parentHeight - (tfootTotal + breadcrumbHeight);
						$("#chart" + that.state.chartnumber + " .dataTables_scrollBody").css("height", parentHeight);
						if ($("#chart" + that.state.chartnumber + " .dataTables_scrollBody table").height() < $("#chart" + that.state.chartnumber + " .dataTables_scrollBody").height()) {
							$("#chart" + that.state.chartnumber + " .dataTables_scrollBody").css("height", $("#chart" + that.state.chartnumber + " .dataTables_scrollBody table").height() + 1);
						}
						var chartNumber = that.state.chartnumber;
						setTimeout(function () {
							(function ($) {
								$.fn.hasScrollBar = function () {
									return this.get(0) ? this.get(0).scrollHeight > this.innerHeight() : false;
								}
							})($);

							if ($("#chart" + chartNumber + " .dataTables_scrollBody").hasScrollBar()) {
								$("#chart" + chartNumber + " .summartytablecontainer").addClass("summaryhasscroll");
							} else {
								$("#chart" + chartNumber + " .summartytablecontainer").removeClass("summaryhasscroll");
							}
							window.dispatchEvent(new Event('resize'));
						}, 1000);
						window.dispatchEvent(new Event('resize'));
					}
					if (chartType!="sparkline" && chartType != "summarytable" && chartType != "pivot" && chartType != "textchart" && chartType != "doby" && chartType != "h2o_datatable" && chartType != "metric") {
						$("#chart" + that.state.chartnumber).css("height", parentHeight);
						//if($(".drawbootstraptable"+that.state.chartnumber).length===0){$("#chart"+that.state.chartnumber).css("height", "");}else {$("#chart"+that.state.chartnumber).css("height", parentHeight);}
					}
					if (chartType == "metric") {
						var topmargin = $('.boc-snapshot-options.maxanim3[data-num=' + that.state.chartnumber + ']').outerHeight() + 12;
						$("#chart" + that.state.chartnumber).css({ "height": $(element).closest(".tileview").height() - 2, "margin-top": "-" + topmargin + "px" });
					}
					if (chartType === "leaflet" || chartType === "srilankamap" || chartType === "wordcloud") {
						$("#chart" + that.state.chartnumber).css("height", parentHeight);
					}
					var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
					let compareDateRangeObj = chartDetails!=undefined && chartDetails["compareDateRangeObj"]!=null?chartDetails["compareDateRangeObj"]:null;
					let  tempYaxis = chartDetails!=undefined?chartDetails["yAxis"]:[];
					let checkconditionval = false;
					if(compareDateRangeObj!=null && compareDateRangeObj.hasOwnProperty("0") && compareDateRangeObj["0"]!=null){
						 let yjsObj = compareDateRangeObj["0"]; 
						_.each(tempYaxis,function(val,index){
						if(val.columnName!=undefined&&val.columnName!=null&&yjsObj.hasOwnProperty(val.columnName)){
							checkconditionval = true;
						}
					});
					}
				   if(checkconditionval&&comparedaterangecharttpes.indexOf(chartType)>=0){
					$(".maxanim1[data-num='" + that.state.chartnumber + "']").find('.storyboard-data-buttons').hide();
				   }
				
				}
				else {
					$("#chart" + that.state.chartnumber).css("margin-top", "");
					if (chartType === "doby" || chartType == "h2o_datatable") {
						$('.paginationdiv' + that.state.chartnumber).show();
						//$("#chart"+that.state.chartnumber).css("height", "calc(100vh - 30vh)");
						var navbar = $('.navbar-fixed-top').height();
						var toolbar = $('.reporttoolbar[data-num="' + that.state.chartnumber + '"] .controlbar-main').height();
						var optionh = $('.fullscreenmain[data-num="' + that.state.chartnumber + '"] .maxanim9').height();
						var quickfilterh = $(".quickfiltercontainer[data-num=" + that.state.chartnumber + "]").height();
						var footer = $('.footer').height();
						var finalh = navbar + toolbar + optionh + quickfilterh + footer + 18;
						if (is_mobile_chrome) {
							finalh = finalh + 60
						}
						$("#chart" + that.state.chartnumber).css("height", "calc(100vh - " + finalh + "px)");

						var baseHeight = $("#chart" + that.state.chartnumber).height(),
							headerHeight = $("#chart" + that.state.chartnumber + " .notifications-table-container:nth(0) table>thead").height(),
							dataHeight = $("#chart" + that.state.chartnumber + " .notifications-table-container:nth(0) table>tbody").height(),
							paginationHeight = $("#chart" + that.state.chartnumber + " .notifications-table-container:nth(1)").height();
						var chartHeight = baseHeight - (headerHeight + 10);
						if (dataHeight != null && chartHeight >= dataHeight) { chartHeight = dataHeight + 41 };
						$(".drawbootstraptable" + that.state.chartnumber + " .table").bootstrapTable('resetView', { height: chartHeight });

					} else if (chartType === "pivot") {
						var navbar = $('.navbar-fixed-top').height();
						var toolbar = $('.reporttoolbar[data-num="' + that.state.chartnumber + '"] .controlbar-main').height();
						var optionh = $('.fullscreenmain[data-num="' + that.state.chartnumber + '"] .maxanim9').height();
						var quickfilterh = $(".quickfiltercontainer[data-num=" + that.state.chartnumber + "]").height();
						var footer = $('.footer').height();
						var finalh = navbar + toolbar + optionh + quickfilterh + footer + 50;
						if (is_mobile_chrome) {
							finalh = finalh + 60
						}
						$("#chart" + that.state.chartnumber).css("height", "calc(100vh - " + finalh + "px)");
						$('#chart' + that.state.chartnumber + ' .pivotscroll').removeAttr('style');
						$(".pivotsettingsmain" + that.state.chartnumber).show();
						$(".pivotcharticon" + that.state.chartnumber).show();
						$(".pivotnavigationbuttons" + that.state.chartnumber).show();

						var attropen = $(".rightattributes.open").length;
						var attrwidth = 0;
						if (attropen > 0) { attrwidth = $(".rightattributes.open").width() + 60 }
						$(".fullscreenmain #chart" + that.state.chartnumber + "").css("max-width", "calc(100vw - " + attrwidth + "px)");
					}
					if (chartType == "textchart") {
						//$('#chart' + that.state.chartnumber).empty();
						$('.richtextcontrolschart' + that.state.chartnumber).show();
						$("#chart" + that.state.chartnumber).css({
							'display': 'block',
							'height': 'calc(100vh - 200px)',
							'word-wrap': 'break-word',
							'overflow-y': 'auto',
							'overflow-x': 'auto',
							//'max-width': 'calc(100vw - 150px)'
						});
						if ($('#chart' + that.state.chartnumber).is(':empty')) {
							$('#chart' + that.state.chartnumber).hide();
							if (!$("#chart0").next().hasClass("note-editor")) {
								$('.editrichtext[data-num=' + that.state.chartnumber + ']').click();
							}
						} else {
							$('#chart' + that.state.chartnumber).summernote('destroy');
						}
					} else if (chartType == 'summarytable') {
						$("#chart" + that.state.chartnumber).css("height", "calc(100vh - 35vh)");
						var chartNumber = that.state.chartnumber;
						var navh = $("nav.navbar.navbar-bird.navbar-fixed-top").height();
						var controlh = $(".reporttoolbar[data-num=" + chartNumber + "] .controlbar-main").height();
						var charcontrolh = $(".maxanim9[data-num=" + chartNumber + "]").height();
						var quickfilterh = $(".quickfiltercontainer[data-num=" + chartNumber + "]").height();
						var newFilterh = $('.tab-filters-container').outerHeight();
						var breadcrumbh = $(".breadcrumb[data-num=" + chartNumber + "]").outerHeight() + 3;
						var footerh = $("footer.footer").height();
						var summarcontrolh = $(".summartable" + chartNumber + ".summartytablecontainer .dataTables_length").height();
						var summarheaderh = $(".summartable" + chartNumber + ".summartytablecontainer .dataTables_scrollHeadInner").height();
						var summarfooterh = $(".summartable" + chartNumber + ".summartytablecontainer .dataTables_scrollFoot").height();
						var summarpagingh = $("#summartable" + chartNumber + "_paginate").innerHeight();

						var finalh = navh + controlh + charcontrolh + quickfilterh + breadcrumbh + newFilterh + footerh + summarcontrolh + summarheaderh + summarfooterh + summarpagingh + 15;
						$("#chart" + chartNumber + " .dataTables_scrollBody").css("max-height", "calc(100vh - " + finalh + "px)");

						var attropen = $(".rightattributes.open").length;
						var attrwidth = 0;
						if (attropen > 0) { attrwidth = $(".rightattributes.open").width() + 60 }
						$(".fullscreenmain #chart" + chartNumber + "").css("max-width", "calc(100vw - " + attrwidth + "px)");

						var chartNumber = that.state.chartnumber;
						setTimeout(function () {
							(function ($) {
								$.fn.hasScrollBar = function () {
									return this.get(0) ? this.get(0).scrollHeight > this.innerHeight() : false;
								}
							})($);

							if ($("#chart" + chartNumber + " .dataTables_scrollBody").hasScrollBar()) {
								$("#chart" + chartNumber + " .summartytablecontainer").addClass("summaryhasscroll");
							} else {
								$("#chart" + chartNumber + " .summartytablecontainer").removeClass("summaryhasscroll");
							}
							window.dispatchEvent(new Event('resize'));
						}, 1000);
						window.dispatchEvent(new Event('resize'));
					} else if (chartType != "doby" && chartType != "pivot" && chartType != "textchart" && chartType != "h2o_datatable") {

						/*for Safari Browser Storyboard Issue Fixed*/
						var browser = "";
						var chrome = /chrom(e|ium)/.test(navigator.userAgent.toLowerCase());
						var safari = /safari/.test(navigator.userAgent.toLowerCase());

						if (chrome == false && safari == true) {
							parentHeight = $(element).closest(".fullscreenmain").find(".maxanim2").height() - 160;
							var maxWidth = $(element).closest(".fullscreenmain").find(".maxanim2").width();
							$("#chart" + that.state.chartnumber).css("height", parentHeight);
							$(element).closest(".fullscreenmain").find(".maxanim5").css("max-width", maxWidth);
							$(element).closest(".fullscreenmain").find(".maxanim7").css("max-width", maxWidth);
						} else {
							/*$("#chart"+that.state.chartnumber).css("height", "calc(100vh - 30vh)");*/
							//var totalh = $('.fullscreenmain[data-num="'+that.state.chartnumber+'"] .maxanim5').height();
							var navbar = $('.navbar-fixed-top').height();
							var toolbar = $('.reporttoolbar[data-num="' + that.state.chartnumber + '"] .controlbar-main').height();
							var footer = $('.footer').height();
							var optionh = $('.fullscreenmain[data-num="' + that.state.chartnumber + '"] .maxanim9').height();
							var quickfilterh = $('.quickfiltercontainer[data-num="' + that.state.chartnumber + '"]').height();
							var newFilterh = $('.tab-filters-container').outerHeight();
							var finalh = navbar + toolbar + optionh + quickfilterh + newFilterh + footer;
							if (is_mobile_chrome) {
								finalh = finalh + 60
							}

							$("#chart" + that.state.chartnumber).css("height", "calc(100vh - " + finalh + "px)");
						}
						/*---for Safari Browser Storyboard Issue Fixed*/

						//$("#chart"+that.state.chartnumber).css("height", "calc(100vh - 25vh)"); Previous Code for all Browsers
					}

					//					$("#spinner"+that.state.chartnumber).show();
					//					$("#spinner"+that.state.chartnumber).show();
					// fix for when x-axis labels are too long....
					var chartnum = that.chartnumber;
					if (chartType == "bar" || chartType == "stackedbar" || chartType == "line" || chartType == "spline" || chartType == "area" || chartType == "stackedarea" || chartType == "scatter" || chartType == "combinationchart" || chartType == "multicolorbar" || chartType == "multixbar" || chartType == "multiaxisbar" || chartType == "waterfall" || chartType == "3dbar" || chartType == "multixline" || chartType == "multixarea" || chartType == "heatmap") {
						//   var chart = $("#chart"+chartnum).highcharts();
						var chart = undefined;

						if (chart != null && chart != undefined) {
							chart.update({ xAxis: [{ labels: { rotation: -45 } }] })
						}
					}
					parentHeight = $('.fullscreenmain[data-num=' + that.state.chartnumber + ']').find(".fullscreensample").height() - 10;
					if ($('.breadcrumb[data-num=' + that.state.chartnumber + '] li').length > 0) {
						parentHeight = parentHeight - 83;
					}
					var attrmodalwidth = 0;
					if ($(".rightattributes.open").length > 0) {
						attrmodalwidth = $(".rightattributes.open").width() + 60;
						parentWidth = $(window).width() - attrmodalwidth;
					} else {
						parentWidth = $('.fullscreenmain[data-num=' + that.state.chartnumber + ']').find(".fullscreensample").width();
					}

					if (chartType != "sparkline" && chartType != "doby") {
						$(element).closest(".fullscreenmain").find(".highcharts-container").height(parentHeight);
						$(element).closest(".fullscreenmain").find(".highcharts-container").width(parentWidth);
					}
					if (chartType === "summarytable") {
						$("#chart" + that.state.chartnumber + " .summartytablecontainer").css("height", "");
						$("#chart" + that.state.chartnumber + " .dataTables_scrollBody").css("height", "");
					}
					if (chartType === "sparkline") {
						$("#chart" + that.state.chartnumber + " .dataTables_scrollBody").css("height", "");
					}
				}
				//if (chartType == "sankey" || chartType == "sunburst") {
				if (chartType == "sunburst") {
					this.redrawChart(chartDetails);
				}
				if (chartType == "sankey") {
					// ChartUtil.modifyChartHeightOnResize(that.state.chartnumber, pos);
				}
				if (chartType !== "doby" && chartType !== "metric" && chartType != "textchart" && chartType != "summarytable" && chartType != "sparkline" && chartType != "sankey" && chartType != "sunburst" && chartType != "h2o_datatable") {
					if (chartType === "pivot") {
						parentHeight = parentHeight - 40;
						var curentpivottype = $(".maxanim1[data-num=" + that.state.chartnumber + "] .pivotcharttypes").attr('data-type');
						if (curentpivottype != "pivot" && curentpivottype != undefined) {
							//  ChartUtils.updateReport("chart"+that.state.chartnumber,that.state.chartnumber,parentHeight,parentWidth);

							//Re-set Charts Navigator
							//	var chartinstance = $("#chart" + that.state.chartnumber).highcharts();
							var chartinstance;
							if (chartinstance != undefined && chartinstance.navigator != undefined && chartinstance.navigation != undefined) { chartinstance.navigator.update(chartinstance.navigator.series); chartinstance.navigation.update(); }

						} else {
							$("#chart" + that.state.chartnumber + " .highcharts-container").remove();
						}
					} else if (chartType == "trellis") {
						var attropen = $(".rightattributes.open").length;
						var attrwidth = 0;
						if (attropen > 0) { attrwidth = $(".rightattributes.open").width() + 0 }
						var chartscount = $("#chart" + that.state.chartnumber + " #trellis > tbody > tr > td").length;
						var parentwidth = $('#chart' + that.state.chartnumber).width() - 20;
						var breadcrumb = $("ul.breadcrumb[data-num=" + that.state.chartnumber + "]").outerHeight()
						parentWidth = parentwidth / chartscount;
						if (parentWidth < 400) { parentWidth = 400; }
						parentHeight = parentHeight - 20 + breadcrumb;
						finalh = finalh + 20;
						$(".fullscreenmain #chart" + that.state.chartnumber + "").css("max-width", "calc(100vw - " + attrwidth + "px)");
						$("#chart" + that.state.chartnumber).css("height", "calc(100vh - " + finalh + "px)");
						//  ChartUtils.updateReport("chart"+that.state.chartnumber,that.state.chartnumber,parentHeight,parentWidth);
					} else if (chartType == "srilankamap" || chartType == "leaflet") {
						//  ChartUtils.updateLeaflet("chart"+that.state.chartnumber,that.state.chartnumber,parentHeight,parentWidth);
					}
					else {
						if ($("#chart" + that.state.chartnumber + " .databehindchart").length == 0) {
							//     ChartUtils.updateReport("chart"+that.state.chartnumber,that.state.chartnumber,parentHeight,parentWidth);

							//Re-set Charts Navigator
							//	var chartinstance = $("#chart"+that.state.chartnumber).highcharts();
							var chartinstance = undefined;
							if (chartinstance != undefined && chartinstance.navigator != undefined && chartinstance.navigation != undefined) { chartinstance.navigator.update(chartinstance.navigator.series); chartinstance.navigation.update(); }

						} else {
							$("#chart" + that.state.chartnumber).css("height", parentHeight - 10);
						}
					}
				}
				if (chartType == "predictionchart" || chartType == "h2o_scorehistory" || chartType == "h2o_datatable") {
					$(".predictionChart" + that.state.chartnumber).removeClass("hide");
				}
				//				$("#spinner"+that.state.chartnumber).hide();
				if (chartType !== "textchart") {
					ChartUtil.modifyChartHeightOnResize(that.state.chartnumber, pos);
				}

				$(window).trigger('resize');
			}, 150);
			$(".predictmodelsummary" + that.state.chartnumber).addClass('hide');
			var data = ChartInfo.functions.getChartDetails(that.state.chartnumber);
			$(".quickfiltercontainer[data-num=" + that.state.chartnumber + "]").empty();
			$(".quickfiltercontainer[data-num=" + that.state.chartnumber + "]").append($(".header-quickfiltercontainer").html());
			if(data != undefined){
			data=JSON.parse(ChartInfo.functions.getChartDetails(that.state.chartnumber));
			if (data["drillDownFilter"] != undefined && data["drillDownFilter"].length != 0) {
				$(".breadcrumb[data-num=" + that.state.chartnumber + "]").remove();
				$("#chart" + that.state.chartnumber + "").before("<ul class='breadcrumb' data-num=" + that.state.chartnumber + "></ul>");
				var breadCrumbElement = $('.breadcrumb[data-num=' + that.state.chartnumber + ']')
				_.each(data["drillDownFilter"], function (value, index) {
					if (value.isDrillDownAll != true) {
						var name = "";
						if (value.columndisplayname != undefined) {
							name = value.columndisplayname;
						}
						else {
							name = value.displayName;
						}
						var appendValue = value.data;
						var ulEle = "";
						var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
						if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
							appendValue = breadCrumpElements["span"];
							ulEle = breadCrumpElements["ulelement"];
						}
						breadCrumbElement.append("<li class=drilldown saved>" + name + " [" + appendValue + "] <a  columnname='" + value.columnname + "' name='" + value.data + "' class='close removedrilldown' title='Remove'><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
						$('.removedrilldown').click((e) => { PubSub.publish("removedrilldown", e); });
					}
				});
				if (sessionStorage.getItem("drilldown" + that.state.chartnumber) != undefined) {
					_.each(JSON.parse(sessionStorage.getItem("drilldown" + that.state.chartnumber)), function (value, index) {
						if (value["isDrillDownAll"] != true) {
							var name = "";
							if (value.columndisplayname != undefined) {
								name = value.columndisplayname;
							}
							else {
								name = value.displayName;
							}
							breadCrumbElement.append("<li>" + value.name + " [" + value.data + "]</li>");
						}
					});
				}
			}
		}
		}
		window.dispatchEvent(new Event('resize'));
	}

	onDeleteTile(e) {
		var $this = e.currentTarget;
		var chartnumber = $($this).attr('data-num')
		PubSub.publish('deleteTile', chartnumber);
	}
	onCloneTile(e) {
		var $this = e.currentTarget;
		var chartnumber = $($this).attr('data-num')
		PubSub.publish('cloneTile', chartnumber);
	}
	viewDataTableEve(msg, data) {
		this.viewDataTable(data.currentChartNumber, data.selectedcolumns, data.state, data.pos, data.popupwindow, data.target, data.applyattributes, data.filters, data.tableInfo);
	}

	viewDataTable(currentChartNumber, selectedcolumns, state, pos, popupwindow, $this, applyattributes, filters, tableInfo) {
		var that = this.state;
		var thiscopy = this;
		$.each(that.allfeatures, function (index, module) {
			if (module.moduleName == 'my_report') {
				$.each(module.features, function (index, feature) {
					if (feature.featureName == 'viewreport_maximize_data_table_export' && feature.allow == true) {
						sessionStorage.setItem('isDataTableDataExport', true);
					} else if (feature.featureName == 'viewreport_maximize_data_table_export' && feature.allow == false) {
						sessionStorage.setItem('isDataTableDataExport', false);
					}
				});
			}
		});
		var that = this.state;
		if (currentChartNumber == that.chartnumber) {
			if (popupwindow != "popupview") {
				//$('.instant-floating-filters').toggleClass('minisidefilter');
				sessionStorage.removeItem("datatable_popup_filterdata");
				sessionStorage.removeItem("datatable_popup_yaxis");
				$(".predictmodelsummary" + currentChartNumber).addClass("hide");
				$('.pivotsettingsmain' + currentChartNumber).hide();
				$(".pivotcharticon" + currentChartNumber).hide();
				$(".pivotnavigationbuttons" + currentChartNumber).hide();
				$('.summarymodal[data-num=' + currentChartNumber + ']').addClass('hide');
				$(".maxanim1[data-num='" + currentChartNumber + "'] #dropdownmorechart").removeClass("disabled");
				$('.breadcrumb[data-num=' + currentChartNumber + ']').remove();
				if (pos == "min" || pos == undefined) {
					if($('.paginationdiv' + currentChartNumber).is(':visible') === false){
					pos = 'min';
					var parentHeight = $(".tileview[data-num=" + currentChartNumber + "]").height() - 40;
					$("#spinner" + currentChartNumber).show();
					$("#chart" + currentChartNumber).css("height", parentHeight);
					}
				}
				else {
					$('.paginationdiv' + currentChartNumber).show();
					$("#spinner" + currentChartNumber).show();
					$("#chart" + currentChartNumber).css("height", "calc(100vh - 30vh)");
				}
				$(".summarytablemessage[data-num=" + currentChartNumber + "]").remove();
				$('.summarymodal[data-num=' + currentChartNumber + ']').addClass('hide');
				$('.richtextcontrolschart' + currentChartNumber).hide();
				if (tableInfo == undefined) {
					$("#chart" + currentChartNumber).empty();
				}
			}
			var TimeZoneSettingsValueForstoryboard = ChartInfo.functions.getTimeZoneSettings("-1");
			var TimeZoneSettingsValueForEachChart = ChartInfo.functions.getTimeZoneSettings(currentChartNumber);
			var timeZone;
			if (TimeZoneSettingsValueForEachChart == undefined) {
				if (TimeZoneSettingsValueForstoryboard == "localtimezone") {
					timeZone = jstz.determine().name();
					that.timeZone = timeZone;
				} else {
					that.timeZone = undefined;
				}
			} else {
				if (TimeZoneSettingsValueForEachChart == "localtimezone") {
					timeZone = jstz.determine().name();
					that.timeZone = timeZone;
				} else {
					that.timeZone = undefined;
				}
			}
			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber));
			var rowLimit = chartDetails["dobyrowlimit"];
			if (popupwindow != undefined && popupwindow == "popupview" && rowLimit != undefined && rowLimit != "" && rowLimit.length > 0) {
				var limit = parseInt(rowLimit);
				if (limit > 10000) {
					rowLimit = "10000"
				}
			}
			if (chartDetails["chartType"] != 'summarytable' && chartDetails["chartType"] != 'doby' && popupwindow != "popupview") {
				this.redrawChart(chartDetails, "max");
			} else {
				var xaxisData = chartDetails["xAxis"];
				var yAxisData = "";
				var aggregation = "";
				var drilldownvalue = "";
				var showTitle = true;
				var selectedcolumns = chartDetails["dobyselectedcolumns"];
				_.each(chartDetails["yAxis"], function (val) {
					yAxisData += val.columnName + ",";
					aggregation += val.aggregation + ",";
				});
				yAxisData = yAxisData.substring(0, yAxisData.length - 1);
				aggregation = aggregation.substring(0, aggregation.length - 1);
				var gridmodel = new FormData();
				if (!Array.isArray(selectedcolumns) || selectedcolumns == undefined) {
					var temp = {};
					selectedcolumns = [];
					temp["actualnames"] = xaxisData.columnName;
					temp["displaynames"] = xaxisData.displayName;
					temp["datatype"] = xaxisData.dataType;
				}
				if (that.reportcolumns != undefined) {
					var actualColumns = that.reportcolumns;
					$.each(selectedcolumns, function (index, selectedcolumn) {
						$.each(actualColumns, function (index1, actualColumn) {
							if (actualColumn.columnName == selectedcolumn.actualnames && actualColumn.columnssrename != undefined) {
								selectedcolumn["displaynames"] = actualColumn.columnDisplayName;
								selectedcolumns.splice(index, selectedcolumn);
							}
						});
					});
				}
				var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
				if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
					$.each(chartDetails["dobyselectedcolumns"], function (index, data) {
						var parsedData = JSON.parse(renameMeasuresorDimensions);
						if (parsedData[data.actualnames] != undefined) {
							data['displaynames'] = parsedData[data.actualnames];
						}
					});
				}
				if(selectedcolumns.length != 0){
					chartDetails["dobyselectedcolumns"] = selectedcolumns;
					ChartInfo.functions.updateChartDetails(currentChartNumber, JSON.stringify(chartDetails));
				}
				
				localStorage.setItem(currentChartNumber, JSON.stringify(chartDetails));
				if (that.isSharedReport && applyattributes == "view1" && that.reportcolumns != undefined && selectedcolumns != undefined) {
					//for removing restricted columns in view data of shared report
					if (that.reportcolumns.length != selectedcolumns.length) {
						selectedcolumns = []
						_.each(that.reportcolumns, function (val) {

							var temp = {};
							temp["actualnames"] = val["columnName"];
							temp["displaynames"] = val["columnDisplayName"];
							temp["datatype"] = val["tableDisplayType"];
							selectedcolumns.push(temp);

						});
					}
				}
				var convertedValues = JSON.parse(sessionStorage.getItem("convertedvalues"));
				var found = false;
				if (convertedValues != null) {
					for (var i = 0; i < convertedValues.length; i++) {
						for (var int = 0; int < selectedcolumns.length; int++) {
							if (convertedValues[i].columnDisplayName == selectedcolumns[int].displaynames) {
								found = true;
							}
						}
					}
				}
				if (found == false) {
					_.each(convertedValues, function (val) {
						if (val["type"] === "dimension") {
							var temp = {};
							temp["actualnames"] = val["columnName"];
							temp["displaynames"] = val["columnDisplayName"];
							temp["datatype"] = val["dataType"];
							selectedcolumns.push(temp);
						}
					});
				}

				var yaxisFeilds = sessionStorage.getItem("datatable_popup_yaxis");
				var filtervalue = JSON.parse(sessionStorage.getItem('datatable_popup_filterdata'));
				var customFields = JSON.parse(sessionStorage.getItem('customFields'));
				var cumstoMeasures = ChartInfo.functions.getCustomMeasureField();
				var measureHierarchy = ChartInfo.functions.getMeasureHierarchy();
				if (customFields != undefined && customFields.length > 0) {
					var yaxisCustomFieldCheck;
					_.each(customFields, function (i, j) {
						if (yaxisFeilds == i.name) {
							if (i.usedFields.length > 1) {
								var usedFieldsArray = [];
								for (let k = 0; k < i.usedFields.length; k++) {
									usedFieldsArray.push(i.usedFields[k].split(".").shift());
								}
								yaxisFeilds = _.intersection(that.facttables, usedFieldsArray).length > 0 ? _.intersection(that.facttables, usedFieldsArray)[0] : i.usedFields[0];
							} else {
								yaxisCustomFieldCheck = i.usedFields[0];
								yaxisFeilds = yaxisCustomFieldCheck.split(".").shift();
							}
						}
					});
				}
				if (cumstoMeasures != undefined && cumstoMeasures.length > 0) {
					var yaxisCustomMeasureCheck;
					var usedfield;
					_.each(cumstoMeasures, function (i, j) {
						if (yaxisFeilds == i.name) {
							yaxisCustomMeasureCheck = i.usedFields;
							var customfieldcolumnname;
							if (customFields != undefined && customFields.length > 0) {
								_.each(customFields, function (obj, index) {
									customfieldcolumnname = obj.name;
									if (customfieldcolumnname == yaxisCustomMeasureCheck) {
										if (obj.usedFields.length > 1) {
											var usedFieldsArray = [];
											for (let k = 0; k < obj.usedFields.length; k++) {
												usedFieldsArray.push(obj.usedFields[k].split(".").shift());
											}
											usedfield = _.intersection(that.facttables, usedFieldsArray).length > 0 ? _.intersection(that.facttables, usedFieldsArray)[0] : obj.usedFields[0];
										} else {
											usedfield = obj.usedFields[0];
										}
									}
								});
							}
							if (usedfield != undefined) {
								yaxisFeilds = usedfield;
							} else {
								yaxisFeilds = yaxisCustomMeasureCheck.split(".").shift();
							}
						}
					});
				}
				if (measureHierarchy != undefined && measureHierarchy.length > 0) {
					var yaxisMeasureHierarchyCheck;
					_.each(measureHierarchy, function (i, j) {
						if (yaxisFeilds == i.name) {
							yaxisMeasureHierarchyCheck = i.hierarchelist[0].columnName;
							yaxisFeilds = yaxisMeasureHierarchyCheck.split(".").shift();
						}
					});
				}
				if (that.multifact != undefined && that.multifact == 'true' && yaxisFeilds != undefined) {
					selectedcolumns = [];
					var i = 0;
					_.each(viewcollection.getDimensions(), function (dimension) {
						var tablename = dimension.columnName.split(".")[0];
						var yaxisTableName = yaxisFeilds.split(".")[0];

						if (tablename == yaxisTableName) {
							if (i <= 10) {
								var temp = {};
								temp["actualnames"] = dimension.columnName;
								temp["displaynames"] = dimension.columnDisplayName;
								temp["datatype"] = dimension.tableDisplayType;
								selectedcolumns.push(temp);
								i++;
							}
							else {
								var columnName = dimension.columnName;
								if (yaxisFeilds == columnName) {
									var temp = {};
									temp["actualnames"] = dimension.columnName;
									temp["displaynames"] = dimension.columnDisplayName;
									temp["datatype"] = dimension.tableDisplayType;
									selectedcolumns.push(temp);
									i++;
								}
							}
						}
					});

					_.each(filtervalue, function (filters) {
						var temp = {};
						temp["actualnames"] = filters['columnname'];
						temp["displaynames"] = filters['columndisplayname'];
						temp["datatype"] = filters['dataType'];
						selectedcolumns.push(temp);
					});
					if (selectedcolumns.length == 0) {
						var tempTableName;
						_.each(tableInfo, function (tablInfo) {
							var temp = {};
							temp["actualnames"] = tablInfo['columnname'];
							temp["displaynames"] = tablInfo['columndisplayname'];
							temp["datatype"] = tablInfo['dataType'];
							selectedcolumns.push(temp);
							tempTableName = tablInfo['columnname'].split(".")[0];
						});
						var multifactObject = JSON.parse(that.multifactentities);
						var selectedTableObject = multifactObject[tempTableName];
						var selectedTableFact = selectedTableObject[0];
						var i = 0;
						_.each(viewcollection.getDimensions(), function (dimension) {
							var tablename = dimension.columnName.split(".")[0];

							if (selectedTableFact == tablename) {
								if (i <= 10) {
									var temp = {};
									temp["actualnames"] = dimension.columnName;
									temp["displaynames"] = dimension.columnDisplayName;
									temp["datatype"] = dimension.tableDisplayType;
									selectedcolumns.push(temp);
									i++;
								}
							}
						});
					}
					//Checking for selected columns contains Fact or not
					var selectedColumnsArray = [];
					var usedFieldFactCheck;
					_.each(selectedcolumns, function (i, j) {
						selectedColumnsArray.push(i.actualnames.split(".")[0]);
					});
					if (_.intersection(that.facttables, selectedColumnsArray).length == 0) {
						_.each(chartDetails.yAxis, function (yAttributes, index) {
							if (customFields != undefined && customFields.length > 0) {
								_.each(customFields, function (i, j) {
									if (yAttributes.columnName == i.name) {
										if (i.usedFields.length > 1) {
											var usedFieldsArray = [];
											for (let k = 0; k < i.usedFields.length; k++) {
												usedFieldsArray.push(i.usedFields[k].split(".").shift());
											}
											usedFieldFactCheck = _.intersection(that.facttables, usedFieldsArray).length > 0 ? _.intersection(that.facttables, usedFieldsArray)[0] : i.usedFields[0];
										} else {
											usedFieldFactCheck = i.usedFields[0];
										}
									}
								});
							}
							if (cumstoMeasures != undefined && cumstoMeasures.length > 0) {
								var yaxisCustomMeasureCheck;
								var usedfield;
								_.each(cumstoMeasures, function (i, j) {
									if (yAttributes.columnName == i.name) {
										yaxisCustomMeasureCheck = i.usedFields;
										var customfieldcolumnname;
										if (customFields != undefined && customFields.length > 0) {
											_.each(customFields, function (obj, index) {
												customfieldcolumnname = obj.name;
												if (customfieldcolumnname == yaxisCustomMeasureCheck) {
													if (obj.usedFields.length > 1) {
														var usedFieldsArray = [];
														for (let k = 0; k < obj.usedFields.length; k++) {
															usedFieldsArray.push(obj.usedFields[k].split(".").shift());
														}
														usedfield = _.intersection(that.facttables, usedFieldsArray).length > 0 ? _.intersection(that.facttables, usedFieldsArray)[0] : obj.usedFields[0];
													} else {
														usedfield = obj.usedFields[0];
													}
												}
											});
										}
										if (usedfield != undefined) {
											usedFieldFactCheck = usedfield;
										} else {
											usedFieldFactCheck = yaxisCustomMeasureCheck;
										}
									}
								});
							}
							if (measureHierarchy != undefined && measureHierarchy.length > 0) {
								_.each(measureHierarchy, function (i, j) {
									if (yAttributes.columnName == i.name) {
										_.each(i.hierarchelist, function (k, l) {
											if (that.facttables.indexOf(k.columnName.split(".")[0]) != -1) {
												usedFieldFactCheck = k.columnName;
											}
										});
									}
								});
							}
							if (usedFieldFactCheck == undefined || that.facttables.indexOf(yAttributes.columnName.split(".")[0]) != -1) {
								usedFieldFactCheck = yAttributes.columnName;
							}
							if (that.facttables.indexOf(usedFieldFactCheck.split(".").shift()) != -1) {
								_.each(viewcollection.getDimensions(), function (dimension) {
									var tablename = dimension.columnName;
									var yaxisTableName = usedFieldFactCheck;
									if (tablename == yaxisTableName) {
										var tempFact = {};
										tempFact["actualnames"] = dimension.columnName;
										tempFact["displaynames"] = dimension.columnDisplayName;
										tempFact["datatype"] = dimension.tableDisplayType;
										selectedcolumns.push(tempFact);
									}
								});
							}
						});
					}
					chartDetails["dobyselectedcolumns"] = selectedcolumns;
					ChartInfo.functions.updateChartDetails(currentChartNumber, JSON.stringify(chartDetails));
				}
				var isXaxisFieldValid = true;
				var bool = true;
				var filterArray = [];
				if (that.dobyfilterArray != undefined) {
					filterArray = JSON.parse(JSON.stringify(that.dobyfilterArray))
				}
				if (filtervalue != null && $.grep(filtervalue, function (e) { return e.columnname == "Measure Names" }).length > 0) {
					isXaxisFieldValid = false;
				}
				var summarytablefiltervalues = [];
				var temp1 = [];
				var xaxis_legend_Length = chartDetails["xAxis"].length;
				if (chartDetails["legend"] != undefined) {
					xaxis_legend_Length = xaxis_legend_Length + 1;
				}
				var customFields = sessionStorage.getItem("customFields");
				var temp = JSON.parse(customFields);
				var res;
				var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(applyattributes);
				_.each(temp, function (customfields) {
					_.each(customfields.parametrFieldsArray, function (value) {
						var customparameter = ChartInfo.functions.getCustomParameter();
						if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
							customparameter = customParameterFilterArray;
						}
						res = $.grep(customparameter, function (e) {
							return e.parametername == value;
						})
						var formula = customfields.formula;
						var scriptcondition = customfields.scriptcondition;
						var fields = customfields.fields;
						var sqlformula = customfields.sqlformula;
						if (res.length != 0) {
							customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
							customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
							customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
							customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
						} else {
							_.each(ChartInfo.functions.getCustomParameter(), function (values) {
								if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
									customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
									customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
									customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
									customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
								}
							});
						}
					});
				});
				customFields = JSON.stringify(temp);
				var that = this.state;
				if (chartDetails.chartType != "doby") {
					if (filtervalue != null && popupwindow == "popupview" && chartDetails.chartType != "summarytable" && isXaxisFieldValid) {
						if (customFields != undefined && customFields != null && customFields != "null" && customFields != "[]") {
							var customObject = {};
							var tempcustomObject = JSON.parse(customFields);
							for (var i = 0; i < xaxis_legend_Length; i++) {
								bool = true;
								_.each(tempcustomObject, function (customfield) {
									var filterObject = {};
									var columnName = '';
									var filterData = '';
									var dataType = '';
									var columndisplayname = '';
									if (chartDetails["chartType"] == "multixbar" || chartDetails["chartType"] == "multixline" || chartDetails["chartType"] == "multixarea" ||
										chartDetails["chartType"] == "srilankamap" || chartDetails["chartType"] == "world" || chartDetails["chartType"] == "leaflet" || chartDetails["chartType"] == "countrymap" || chartDetails["chartType"] == "indiamap") {
										columnName = filtervalue[0].columnname;
										filterData = filtervalue[0].data;
										dataType = filtervalue[0].dataType;
										columndisplayname = filtervalue[0].columndisplayname;
									}
									else {
										columnName = filtervalue[i].columnname;
										filterData = filtervalue[i].data;
										dataType = filtervalue[i].dataType;
										columndisplayname = filtervalue[i].columndisplayname;
									}
									if (customfield.name == columnName) {
										var sqlFilterObject = {};
										sqlFilterObject["sqldata"] = filterData;
										customfield["sqlcondition"] = sqlFilterObject;
										//	that.excludingFilterArray(filterArray, customfield.name)
										bool = false;
										customObject = customfield;
										customObject["parameter"] = filterData;
										filterObject["customfield"] = customObject;
										filterObject["dataType"] = dataType;
										filterObject["isdrilldownfilter"] = true;
										filterObject["data"] = filterData;
										filterObject["columnDisplayName"] = columndisplayname;
										filterObject["tablename"] = "";
										filterObject["columndisplayname"] = columndisplayname;
										filterObject["columnname"] = columnName;
										var scriptFilterCondition = customObject['scriptcondition'];

										if (filterObject["dataType"] == "date") {
											customObject['scriptcondition'] = "; if(new Date(" + scriptFilterCondition + ")==new Date(parameter)){ return true } else{ return false}";
											if (customObject["parameter"].length > 0) {
												var newDate = customObject["parameter"];//Date.parse(customObject["parameter"].replace(/-/g,'/'));
												if (isNaN(newDate)) {
													//var newdate1=moment(myDate,dateFormat);
													customObject["parameter"] = customObject["parameter"];
												}
												else {
													customObject["parameter"] = newDate;//new Date(newDate).getTime();
												}
											}
										}
										if (filterObject["dataType"] == "number") {
											customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ")==(parameter)) {return true }else{ return false}";
										} else {
											customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ").equals(parameter)){ return true }else{ return false}";
										}
										//drilldownvalue += filtervalue[i].columnname+": "+filtervalue[i].data+", ";
										if (!thiscopy.findSameFilterisExists(filterArray, filterObject)) {
											filterArray.push(filterObject);
										}
									}
								});
								_.each(filtervalue, function (value, k) {
									if (thiscopy.isCustomFiledExists(tempcustomObject, value.columnname) && !thiscopy.findSameFilterisExists(filterArray, filtervalue[k])) {
										filterArray.push(filtervalue[k]);
									}
								});
								if (bool) {
									_.each(filtervalue, function (value, i) {
										var data = filtervalue[i].data;
										if (filtervalue[i].dataType == "date") {
											if (data.length > 0) {
												var newDate = data;//Date.parse(data.replace(/-/g,'/'));
												if (isNaN(newDate)) {
													//var newdate1=moment(myDate,dateFormat);
													data = data;
												}
												else {
													data = newDate;//new Date(newDate).getTime();
												}

											}
										}
										//	drilldownvalue += filtervalue[i].columnname+": "+filtervalue[i].data+", ";
										filtervalue[i].data = data;
										if (!thiscopy.findSameFilterisExists(filterArray, value)) {
											filterArray.push(value);
										}
									});
								}
							}
						} else if (bool) {
							_.each(filtervalue, function (value, i) {
								var data = filtervalue[i].data;
								if (filtervalue[i].dataType == "date") {
									if (data.length > 0) {
										var newDate = data;//Date.parse(data.replace(/-/g,'/'));
										if (isNaN(newDate)) {
											//var newdate1=moment(myDate,dateFormat);
											data = data;
										}
										else {
											data = newDate;//new Date(newDate).getTime();
										}

									}
								}
								//drilldownvalue += filtervalue[i].columnname+": "+filtervalue[i].data+", ";
								filtervalue[i].data = data;
								filterArray.push(value);
							});
						}
						var drillDown = JSON.parse(sessionStorage.getItem("drilldown" + currentChartNumber));
						_.each(drillDown, function (drillDownData1) {
							if (drillDownData1.name != "Measure Names") {
								var filterObject = {};
								filterObject["dataType"] = "string";
								filterObject["isdrilldownfilter"] = true;
								if (drillDownData1.type != undefined && drillDownData1.type.length > 0 && (drillDownData1.type == "date" || drillDownData1.type == "customrange" || drillDownData1.type == "number")) {
									filterObject["dataType"] = drillDownData1.type;
								}
								if (customFields != undefined && customFields != null && customFields != "null" && customFields != "[]") {
									var customObject = {};
									var tempcustomObject = JSON.parse(customFields);
									var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == drillDownData1.name });
									_.each(tempcustomObject, function (customfield) {
										if (customfield.name == drillDownData1.name) {
											var sqlFilterObject = {};
											sqlFilterObject["sqldata"] = drillDownData1.value;
											customfield["sqlcondition"] = sqlFilterObject;
											customObject = customfield;
											customObject["parameter"] = drillDownData1.value;
											filterObject["customfield"] = customObject;
											filterObject["dataType"] = drillDownData1.type;
											filterObject["isdrilldownfilter"] = true;
											filterObject["columndisplayname"] = drillDownData1.name;
											filterObject["columnname"] = drillDownData1.name;
											var scriptFilterCondition = customObject['scriptcondition'];
											customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ").equals(parameter)) return true else false";
											if (filterObject["dataType"] == "date") {
												customObject['scriptcondition'] = "; if(new Date(" + scriptFilterCondition + ")==new Date(parameter)) return true else false";
												if (customObject["parameter"].length > 0) {
													var newDate = customObject["parameter"];//Date.parse(customObject["parameter"].replace(/-/g,'/'));
													if (isNaN(newDate)) {
														//var newdate1=moment(myDate,dateFormat);
														customObject["parameter"] = customObject["parameter"];
													}
													else {
														customObject["parameter"] = newDate;//new Date(newDate).getTime();
													}
												}
											}
											if (filterObject["dataType"] == "number") {
												customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ")==(parameter)) return true else return false";
											}
											//	drilldownvalue += drillDownData1.name+": "+drillDownData1.value+", ";
										} else if (rangeObject.length > 0) {
											filterObject["tablename"] = "";
											filterObject["columndisplayname"] = rangeObject[0]["xAxisName"][0].columnDisplayName;
											filterObject["columnname"] = rangeObject[0]["xAxisName"][0].columnName;
											var value = drillDownData1.value;
											filterObject["data"] = value;
										} else {
											var Object = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == filtervalue.columnname });
											if (Object.length > 0) {
												filterObject["tablename"] = "";
												filterObject["columndisplayname"] = Object[0].columnDisplayName;
												filterObject["columnname"] = Object[0].columnName;
												filterObject["dataType"] = Object[0].dataType;
												filterObject["isdrilldownfilter"] = true;
												var value = drillDownData1.value;
												if (filterObject["dataType"] == "date") {
													if (value.length > 0) {
														var newDate = value;//Date.parse(value.replace(/-/g,'/'));
														if (isNaN(newDate)) {
															//var newdate1=moment(myDate,dateFormat);
															value = value;
														}
														else {
															value = newDate;//new Date(newDate).getTime();
														}

													}
												}
												filterObject["data"] = value;
												//	drilldownvalue += filterObject["columndisplayname"]+": "+value+", ";

											} else {
												filterObject["tablename"] = "";
												filterObject["columndisplayname"] = drillDownData1.name;
												filterObject["isdrilldownfilter"] = true;
												filterObject["columnname"] = drillDownData1.name;
												var value = drillDownData1.value;
												if (filterObject["dataType"] == "date") {
													if (value.length > 0) {
														var newDate = value;//Date.parse(value.replace(/-/g,'/'));
														if (isNaN(newDate)) {
															//var newdate1=moment(myDate,dateFormat);
															value = value;
														}
														else {
															value = newDate;//new Date(newDate).getTime();
														}
													}
												}
												filterObject["data"] = value;
												//	drilldownvalue += filterObject["columndisplayname"]+": "+value+", ";
											}
										}
									});

								} else {
									var Object = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == filtervalue.columnname });
									var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == drillDownData1.name });
									if (Object.length > 0) {
										filterObject["tablename"] = "";
										filterObject["columndisplayname"] = Object[0].columnDisplayName;
										filterObject["columnname"] = Object[0].columnName;
										filterObject["dataType"] = Object[0].dataType;
										filterObject["isdrilldownfilter"] = true;
										var value = drillDownData1.value;
										if (filterObject["dataType"] == "date") {
											if (value.length > 0) {
												var newDate = value;//Date.parse(value.replace(/-/g,'/'));
												if (isNaN(newDate)) {
													//var newdate1=moment(myDate,dateFormat);
													value = value;
												}
												else {
													value = newDate;//new Date(newDate).getTime();
												}

											}
										}
										filterObject["data"] = value;
										//	drilldownvalue += filterObject["columndisplayname"]+": "+value+", ";

									} else if (rangeObject.length > 0) {
										filterObject["tablename"] = "";
										filterObject["columndisplayname"] = rangeObject[0]["xAxisName"][0].columnDisplayName;
										filterObject["columnname"] = rangeObject[0]["xAxisName"][0].columnName;
										var value = drillDownData1.value;
										filterObject["data"] = value;
									} else {
										filterObject["tablename"] = "";
										filterObject["columndisplayname"] = drillDownData1.name;
										filterObject["columnname"] = drillDownData1.name;
										filterObject["isdrilldownfilter"] = true;
										var value = drillDownData1.value;
										if (filterObject["dataType"] == "date") {
											if (value.length > 0) {
												var newDate = value;//Date.parse(value.replace(/-/g,'/'));
												if (isNaN(newDate)) {
													//var newdate1=moment(myDate,dateFormat);
													value = value;
												}
												else {
													value = newDate;//new Date(newDate).getTime();
												}

											}
										}
										filterObject["data"] = value;
										//	drilldownvalue += filterObject["columndisplayname"]+": "+value+", ";
									}
								}
								filterArray.push(filterObject);
							}

						});
					} else if (isXaxisFieldValid) {
						if (sessionStorage.getItem('customhierarchy' + currentChartNumber) != undefined && sessionStorage.getItem('customhierarchy' + currentChartNumber) != null
							&& sessionStorage.getItem('customhierarchy' + currentChartNumber) != "undefined") {
							var customhierarchy_filterObj = JSON.parse(sessionStorage.getItem('customhierarchy' + currentChartNumber));
							var summarytablefiltervalues = [];
							var temp1 = [];
							var summarytableDrilldownValues = ChartInfo.functions.getTempSummaryTableFilterValues(currentChartNumber) || [];
							filterArray = summarytableDrilldownValues.concat(JSON.parse(JSON.stringify(that.dobyfilterArray || [])));

							if (chartDetails["summarytablegroupbys"] != undefined && chartDetails["summarytablegroupbys"] != null) {
								var groupNo = currentChartNumber; // $($this).parent().attr('group-no');
								if ($($this).parent().attr('group-no') != undefined) {
									groupNo = $($this).parent().attr('group-no');
								}
								var groupName = $('.groupTR[group-no=' + groupNo + '][data-num=' + currentChartNumber + ']').attr('group-name');
								var dimensions = viewcollection.getDimensions();
								var Object = $.grep(viewcollection.getDimensions(), function (e) { return e.columnDisplayName == groupName.split(':')[0] });
								if (Object != undefined && Object.length > 0) {
									Object = Object[0];
									var filterObject = {};
									filterObject["dataType"] = Object.tableDisplayType;
									filterObject["tablename"] = Object.tableName;
									filterObject["columndisplayname"] = Object.columnDisplayName;
									filterObject["columnname"] = Object.columnName;
									filterObject["isdrilldownfilter"] = true;
									if (Object.tableDisplayType == 'date') {
										filterObject["data"] = groupName.substring(groupName.indexOf(":") + 1, groupName.length).trim();
									} else {
										filterObject["data"] = groupName.substring(groupName.indexOf(":") + 1, groupName.length).trim();
									}
									if (filterObject["dataType"] == "date") {
										if (filterObject["data"].length > 0) {
											var newDate = filterObject["data"];//Date.parse(filterObject["data"].replace(/-/g,'/'));
											if (isNaN(newDate)) {
												//var newdate1=moment(myDate,dateFormat);
												filterObject["data"] = filterObject["data"];
											}
											else {
												filterObject["data"] = newDate;//new Date(newDate).getTime();
											}

										}
									}
									//	drilldownvalue +=groupName+", ";
									summarytablefiltervalues.push(filterObject);
								}
								var customFieldsViewData = sessionStorage.getItem("customFields");
								var customFieldViewData = JSON.parse(customFieldsViewData);
								if (customFieldViewData != undefined && customFieldViewData.length > 0) {
									var Object = $.grep(customFieldViewData, function (e) {
										return e.name == groupName.split(':')[0]
									});
									if (Object != undefined && Object.length > 0) {
										var filterObject = {};
										Object = Object[0];
										filterObject["dataType"] = Object.dataType;
										filterObject["tablename"] = Object.tableName;
										filterObject["columndisplayname"] = Object.name;
										filterObject["columnname"] = Object.name;
										filterObject["isdrilldownfilter"] = true;
										if (Object.dataType == 'date') {
											filterObject["data"] = groupName.substring(groupName.indexOf(":") + 1, groupName.length).trim();
										} else {
											filterObject["data"] = groupName.substring(groupName.indexOf(":") + 1, groupName.length).trim();
										}
										if (filterObject["dataType"] == "date") {
											if (filterObject["data"].length > 0) {
												var newDate = filterObject["data"];//Date.parse(filterObject["data"].replace(/-/g,'/'));
												if (isNaN(newDate)) {
													//var newdate1=moment(myDate,dateFormat);
													filterObject["data"] = filterObject["data"];
												}
												else {
													filterObject["data"] = newDate;//new Date(newDate).getTime();
												}

											}
										}
										summarytablefiltervalues.push(filterObject);
									}
								}
							}

							if (!$($this).hasClass('groupTitle')) {
								var drilldownColumnName = $('.summarytablestyling[data-num=' + currentChartNumber + '] thead tr th').eq($($this).index()).html();
								var Object = $.grep(viewcollection.getDimensions(), function (e) {
									return e.columnDisplayName == drilldownColumnName
								});
								if (Object != undefined && Object.length > 0) {
									Object = Object[0];
									var filterObject = {};
									filterObject["dataType"] = Object.tableDisplayType;
									filterObject["tablename"] = Object.tableName;
									filterObject["columndisplayname"] = Object.columnDisplayName;
									filterObject["columnname"] = Object.columnName;
									filterObject["isdrilldownfilter"] = true;
									filterObject["data"] = $($this).text().trim();
									if (filterObject["dataType"] == "date") {
										if (filterObject["data"].length > 0) {
											var newDate = filterObject["data"];//Date.parse(filterObject["data"].replace(/-/g,'/'));
											if (isNaN(newDate)) {
												//var newdate1=moment(myDate,dateFormat);
												filterObject["data"] = filterObject["data"];
											}
											else {
												filterObject["data"] = newDate;//new Date(newDate).getTime();
											}

										}
									}
									//	drilldownvalue += Object.columnDisplayName+": "+$($this).text().trim()+", ";	
									if ($($this).closest("tr.groupTR").length == 0) {
										summarytablefiltervalues.push(filterObject);
									}
								}
								var customFieldsViewData = sessionStorage.getItem("customFields");
								var customFieldViewData = JSON.parse(customFieldsViewData);
								if (customFieldViewData != undefined && customFieldViewData.length > 0) {
									var Object = $.grep(customFieldViewData, function (e) {
										return e.name == drilldownColumnName
									});
									if (Object != undefined && Object.length > 0) {
										var filterObject = {};
										Object = Object[0];
										filterObject["dataType"] = Object.dataType;
										filterObject["tablename"] = Object.tableName;
										filterObject["columndisplayname"] = Object.name;
										filterObject["columnname"] = Object.name;
										filterObject["isdrilldownfilter"] = true;
										filterObject["data"] = $($this).text().trim();
										if (filterObject["dataType"] == "date") {
											if (filterObject["data"].length > 0) {
												var newDate = filterObject["data"];//Date.parse(filterObject["data"].replace(/-/g,'/'));
												if (isNaN(newDate)) {
													filterObject["data"] = filterObject["data"];
												}
												else {
													filterObject["data"] = newDate;//new Date(newDate).getTime();
												}

											}
										}
										if ($($this).closest("tr.groupTR").length == 0) {
											summarytablefiltervalues.push(filterObject);
										}
									}
								}
							}
							if (that.multifact != undefined && that.multifact == 'true') {
								_.each(summarytablefiltervalues, function (summaryTableFilter) {
									var temp = {};
									temp["actualnames"] = summaryTableFilter.columnname;
									temp["displaynames"] = summaryTableFilter.columndisplayname;
									temp["datatype"] = summaryTableFilter.dataType;
									selectedcolumns.push(temp);
								})
							}
							var tempfilters = summarytablefiltervalues;
							if (filterArray != undefined && filterArray != null) {
								var filter1 = $.grep(filterArray, function (e) { return e.isdrilldownfilter == true })
								tempfilters = tempfilters.concat(filter1);
							}
							var temp2 = [];
							_.each(filterArray, function (value, index) {
								var temp = $.grep(tempfilters, function (e) { return e.columnname == value.columnname });
								if (temp.length == 0) {
									temp2.push(value);
								}
							});
							filterArray = tempfilters.concat(temp2);
						}
					}
				}
				var tempfilters = [];
				//					if(filterArray!=undefined && filterArray!=null){
				//						tempfilters = $.grep(filterArray,function(e){return e.isdrilldownfilter==true})
				//					}
				var temp2 = [];
				_.each(filterArray, function (value, index) {
					var temp = $.grep(tempfilters, function (e) { return e.columnname == value.columnname });
					if (temp.length == 0) {
						temp2.push(value);
					}
				});
				filterArray = tempfilters.concat(temp2);
				if (filterArray != undefined && filterArray != null) {
					_.each(filterArray, function (value) {
						if (value.isdrilldownfilter != undefined && value.isdrilldownfilter) {
							if (value.dataType == "date") {
								if (value.customfield != undefined) {
									var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.customfield.parameter);
									var appendValue = value.customfield.parameter;
									var ulEle = "";
									if (breadCrumpElements != undefined && breadCrumpElements.hasOwnProperty("span")) {
										appendValue = breadCrumpElements["span"];
										ulEle = breadCrumpElements["ulelement"];
										drilldownvalue += value.customfield.name + ": [" + appendValue + "]" + ulEle + ", ";
										showTitle = false;
									} else {
										drilldownvalue += value.customfield.name + ": " + value.customfield.parameter + ", ";
									}
								} else {
									var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value["data"]);
									var appendValue = value["data"];
									var ulEle = "";
									if (breadCrumpElements != undefined && breadCrumpElements.hasOwnProperty("span")) {
										appendValue = breadCrumpElements["span"];
										ulEle = breadCrumpElements["ulelement"];
										drilldownvalue += value["columndisplayname"] + ": [" + appendValue + "]" + ulEle + ", ";
										showTitle = false;
									} else {
										drilldownvalue += value["columndisplayname"] + ": " + value["data"] + ", ";
									}
								}

							} else {
								if (value.customfield != undefined) {
									var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.customfield.parameter);
									var appendValue = value.customfield.parameter;
									var ulEle = "";
									if (breadCrumpElements != undefined && breadCrumpElements.hasOwnProperty("span")) {
										appendValue = breadCrumpElements["span"];
										ulEle = breadCrumpElements["ulelement"];
										drilldownvalue += value.customfield.name + ": [" + appendValue + "]" + ulEle + ", ";
										showTitle = false;
									} else {
										if (drilldownvalue == "") {
											drilldownvalue += value.customfield.name + ": " + value.customfield.parameter + ", ";
										} else {
											if (drilldownvalue.split(",").indexOf(" " + value.customfield.name + ": " + value.customfield.parameter) == -1) {
												drilldownvalue += value.customfield.name + ": " + value.customfield.parameter + ", ";
											}
										}
									}
								} else {
									var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value["data"]);
									var appendValue = value["data"];
									var ulEle = "";
									if (breadCrumpElements != undefined && breadCrumpElements.hasOwnProperty("span")) {
										appendValue = breadCrumpElements["span"];
										ulEle = breadCrumpElements["ulelement"];
										drilldownvalue += value["columndisplayname"] + ": [" + appendValue + "]" + ulEle + ", ";
										showTitle = false;
									} else {
										if (drilldownvalue == "") {
											drilldownvalue += value["columndisplayname"] + ": " + value["data"] + ", ";
										} else {
											if (drilldownvalue.split(",").indexOf(" " + value["columndisplayname"] + ": " + value["data"]) == -1) {
												drilldownvalue += value["columndisplayname"] + ": " + value["data"] + ", ";
											}
										}
									}

								}

							}
						}
					});
				}
				drilldownvalue = drilldownvalue.substring(0, drilldownvalue.length - 2);
				var InstanceExcludeFilterData = JSON.parse(sessionStorage.getItem("instantExcludeFilters"));
				if (InstanceExcludeFilterData != null && InstanceExcludeFilterData != "null") {
					_.each(InstanceExcludeFilterData, function (data) {
						filterArray.push(data);
					});
				}
				var InstanceIncludeFilterData = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
				if (InstanceIncludeFilterData != null && InstanceIncludeFilterData != "null") {
					_.each(InstanceIncludeFilterData, function (data) {
						filterArray.push(data);
					});
				}
				var instantExcludeCustomFilters = JSON.parse(sessionStorage.getItem("instantExcludeCustomFilters"));
				if (instantExcludeCustomFilters != null && instantExcludeCustomFilters != "null") {
					_.each(instantExcludeCustomFilters, function (data) {
						filterArray.push(data);
					});
				}
				var responseCount = 0;
				var customFields = sessionStorage.getItem("customFields");
				var temp = JSON.parse(customFields);
				var res;
				_.each(temp, function (customfields) {
					_.each(customfields.parametrFieldsArray, function (value) {
						var customparameter = ChartInfo.functions.getCustomParameter();
						if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
							customparameter = customParameterFilterArray;
						}
						res = $.grep(customparameter, function (e) {
							return e.parametername == value;
						});
						var formula = customfields.formula;
						var scriptcondition = customfields.scriptcondition;
						var fields = customfields.fields;
						var sqlformula = customfields.sqlformula;
						if (res.length != 0) {
							customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
							customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
							customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
							customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
						} else {
							_.each(ChartInfo.functions.getCustomParameter(), function (values) {
								if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
									customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
									customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
									customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
									customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
								}
							});
						}
					});
				});
				if (filters != undefined && filters.length != 0) {
					filterArray = filterArray.concat(filters);
				}
				/*if(selectedcolumns.length>0){
					var selectedColumnsArray=[];
					for(let k=0;k<selectedcolumns.length;k++){
						selectedColumnsArray.push(selectedcolumns[k].actualnames.split(".").shift());
					}
					var isFactExists=_.intersection(that.facttables,selectedColumnsArray)
					if(isFactExists!=undefined && isFactExists.length==0 && that.multifact == 'true'){
						$('#spinner'+currentChartNumber).hide();
						$('.pivotdataviewwrap[data-number='+currentChartNumber+']').remove()
						$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
						$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
						$('.birdmessagebuttons').removeClass('show');
						$('.birdmessagebuttons').addClass('hide');
						$('.birdmessage-center').addClass("message-top-in");
						$('.details').removeClass('show');
						$('.details').addClass('hide');
						$('.birdmessage h2').empty().append('Warning');
						$('.birdmessage-info').empty().text(getMessage('BirdWarning140'));
						return false;
					}
				}*/
				//filterArray = $.grep(filterArray ,function(e){ return e.excludeFilterData == undefined || e.excludeFilterData != "excludeFilterData"});
				var rangefieldMap = {};
				if (chartDetails.xAxis != undefined && chartDetails.xAxis.length > 0) {
					var ramgeArray = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == chartDetails.xAxis[0].columnName });
					if (ramgeArray.length > 0) {
						rangefieldMap[ramgeArray[0].name] = ramgeArray[0];
					}
				}
				customFields = JSON.stringify(temp);

				var numformat = {};
				if (chartDetails.chartSettings != undefined) {
					if (chartDetails.chartSettings.numberformat != undefined) {
						numformat = chartDetails.chartSettings.numberformat
					}
				}
				gridmodel.set("numberformat", JSON.stringify(numformat));
				gridmodel.set("aggregation", aggregation);
				gridmodel.set("xaxis", xaxisData.columnName);
				gridmodel.set("xaxisdataType", xaxisData.dataType);
				gridmodel.set("yaxis", yAxisData);
				gridmodel.set("datamodelId", that.datamodelId);
				gridmodel.set("indexName", that.indexName);
				gridmodel.set("customFields", customFields);
				gridmodel.set("reportId", that.reportId);
				gridmodel.set("filterObject", JSON.stringify(filterArray));
				gridmodel.set("selectedcolumns", JSON.stringify(selectedcolumns));
				gridmodel.set("rangefieldMap", JSON.stringify(rangefieldMap));
				gridmodel.set("entities", that.entities);
				gridmodel.set("rowLimit", rowLimit);
				gridmodel.set("timeZone", this.timeZone);
				gridmodel.set("script", customFields);
				gridmodel.set("isImportedReport", that.isImportedReport != undefined ? that.isImportedReport : false);
				//					gridmodel.set("showMoreOptions",true);
				if (tableInfo == undefined) {
					var tableInfo = {
						showTitle: showTitle,
						drilldownvalue: drilldownvalue
					};
				} else {
					tableInfo.showTitle = showTitle;
					tableInfo.drilldownvalue = drilldownvalue;
				}

				var bootObj = {};
				bootObj["currentChartNumber"] = currentChartNumber;
				bootObj["gridmodel"] = gridmodel;
				bootObj["tableInfo"] = tableInfo;
				bootObj["pos"] = undefined;
				bootObj["popupwindow"] = popupwindow;
				bootObj["reporttype"] = undefined;
				bootObj["target"] = undefined;
				bootObj["isFromPagenation"] = undefined;
				var bootStrapTable = new BootStrapTable(bootObj);
				bootStrapTable.render();
				//		ChartUtils.drawBootstrapTable(currentChartNumber,gridmodel,tableInfo,undefined,popupwindow);
			}
		}
		this.setState({
			isForAddInstantFilters: true
		})
	}
	viewchartfromchartdata(e) {
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		$(".chartadvancedoptions[data-num='chart" + currentChartNumber + "']").show();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		$('.viewchartfromchartdata[data-num=' + currentChartNumber + ']').addClass("active");
		$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + '].previewChart').addClass("showactive");
		$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + '].pivotgrid').removeClass("showactive");
		$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + '].dobygrid').removeClass("showactive");
		$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + '].summarytable').removeClass("showactive");
		$('.viewchartdata[data-num=' + currentChartNumber + ']').removeClass("active");
		$('.storyboard-data-buttons[data-num=' + currentChartNumber + ']').prop('disabled', true);
		$(".chartfromdatabuttons" + currentChartNumber).show();
		setTimeout(function () {
			$('.storyboard-data-buttons[data-num=' + currentChartNumber + ']').prop('disabled', false);
		}, 5000);

		$(".storyboard-data-buttons").removeClass("active");
		$($this).addClass("active");

		var drilldown = [];
		var drilldownchartnumber = ChartInfo.functions.getDrillDownChartNumber()
		if (drilldownchartnumber != undefined && drilldownchartnumber != currentChartNumber) {
			drilldown = JSON.parse(sessionStorage.getItem("drilldown" + drilldownchartnumber));
			if (drilldown != null) {
				drilldown = $.grep(drilldown, function (value) { return value.isDrillDownAll; });
			}
		}
		this.viewChart(currentChartNumber, "min", drilldown);
		$(".showtrending[data-num=" + currentChartNumber + "]").show();
		if (ChartInfo.functions.getDrillDownChartNumber() != undefined && ChartInfo.functions.getDrillDownChartNumber() == currentChartNumber) {
			var legendfilter = [];
			ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
			ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
			$(".pie-menu").remove();
			$(".fullscreensample").removeClass("nopie");
			$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
			$('.breadcrumb[data-num=' + currentChartNumber + ']').remove();
			var drilldown = JSON.parse(sessionStorage.getItem("drilldown" + currentChartNumber));
			if (drilldown != null) {
				drilldown = $.grep(drilldown, function (value) { return value.isDrillDownAll; });
			}
			sessionStorage.removeItem("drilldown" + currentChartNumber);
			$(".clonetile[data-num=" + currentChartNumber + "]").removeClass("disabled");
			ChartInfo.functions.setdrillfitlerArray([]);
			if (drilldown != undefined && drilldown.length != 0) {
				var that = this;
				_.each($(".maxanim1[data-num!=" + currentChartNumber + "]"), function (i) {
					var $this = i
					var chartnumber = $($this).attr("data-num");
					that.applyfiltesandredrawchart(undefined, chartnumber, "applyattributes", undefined, undefined, undefined, undefined, undefined, [], "drilldownview");
				});
			}

			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber));
			var drillDownAllFilter = chartDetails['drillDownAllFilter'];
			if (drillDownAllFilter != undefined && drillDownAllFilter.length != 0) {
				var that = this;
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
				$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").attr("drilled-chart", currentChartNumber);
				_.each(drillDownAllFilter, function (i, j) {
					var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
					if (name === null) {
						name = decodeURIComponent(drillDownAllFilter[j].name);
					}
					else if (name == "undefined") {
						name = decodeURIComponent(drillDownAllFilter[j].displayName);
						if (drillDownAllFilter[j].customfield != undefined) {
							name = drillDownAllFilter[j].customfield.name;
						}
					}
					if (drillDownAllFilter[j].type == "date") {
						name = $(name).find('li:first').attr('column-display-name');
						if (name === undefined) {
							name = drillDownAllFilter[j].displayName;
						}
					}
					var today = new Date();
					var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
					dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
					ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
					$("#" + dynamicdiv).remove();
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
					$('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) });
				});
				if (this.isSharedReport != true && ChartInfo.functions.getisViewOnlyFlag() != true) {
					$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>")
				}
			}
		}

	}

	viewChart(CurrentChartNumber, pos, filter) {
		if (this.state.chartnumber == CurrentChartNumber) {
			var data = JSON.parse(ChartInfo.functions.getChartDetails(CurrentChartNumber));
			$("#chart" + CurrentChartNumber).empty();
			$(".dobyselectcolumns[data-num='" + CurrentChartNumber + "']").addClass('hide');
			$(".predictmodelsummary" + CurrentChartNumber).addClass("hide");
			$(".pivotsettingsmain" + CurrentChartNumber).hide();
			$(".pivotcharticon" + CurrentChartNumber).hide();
			$(".pivotnavigationbuttons" + CurrentChartNumber).hide();
			$('.paginationdiv' + CurrentChartNumber).hide();
			$('.summarymodal[data-num=' + CurrentChartNumber + ']').addClass('hide');
			$('.showtrending[data-num=' + CurrentChartNumber + ']').removeClass('active');
			$('.trendtype[data-num=' + CurrentChartNumber + ']').removeClass('showactive');
			$(".clonetile[data-num=" + CurrentChartNumber + "]").removeClass("disabled");
			if ($('.doby-offcanvas-columnlist').hasClass("show")) {
				$('.doby-offcanvas-columnlist').toggleClass("show");
			}
			if (data["oldcharttype"] != undefined) {
				data["chartType"] = data["oldcharttype"];
			}
			else if (data["chartType"] == "pivot" || data["chartType"] == "summarytable" || data["chartType"] == "predictionchart" || data["chartType"] == "h2o_variableimportance" || data["chartType"] == "h2o_scorehistory" || data["chartType"] == "doby") {
				data["chartType"] = "bar";
			}
			ChartInfo.functions.updateChartDetails(CurrentChartNumber, JSON.stringify(data));
			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(CurrentChartNumber));
			var drillDownAllFilter = chartDetails.drillDownAllFilter;
			//				this.redrawChart(chartDetails, pos);
			ChartInfo.functions.getFilters(true);
			$("#spinner" + CurrentChartNumber).show();
			this.applyfiltesandredrawchart(undefined, CurrentChartNumber, "applyattributes", undefined, undefined, undefined, undefined, undefined, filter, "drilldownview");
			var that = this;
			$(".breadcrumb[data-num=" + CurrentChartNumber + "]").remove();
			var chart = JSON.parse(ChartInfo.functions.getChartDetails(CurrentChartNumber))
			var drillDownFilter = chartDetails["drillDownFilter"]
			$("#chart" + CurrentChartNumber).before("<ul class='breadcrumb' data-num=" + CurrentChartNumber + "></ul>");
			if (drillDownFilter != undefined) {
				var breadCrumbElement = $('.breadcrumb[data-num=' + CurrentChartNumber + ']')
				var that = this;
				_.each(drillDownFilter, function (value, index) {
					if (value["saved"] == "true") {
						var appendValue = value.data;
						var ulEle = "";
						var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
						if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
							appendValue = breadCrumpElements["span"];
							ulEle = breadCrumpElements["ulelement"];
						}
						breadCrumbElement.append("<li class=drilldown saved >" + value.columndisplayname + " [" + appendValue + "] <a columnname=" + value.columnname + "  name='" + value.data + "' class='close removedrilldownsinglereport' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'><i/></a>" + ulEle + "</li>");
						$('.removedrilldownsinglereport').click(function (e) {
							PubSub.publish('removeDrillDown', e);
						})
					}
				});
			}
		}
	}
	viewchartdataforstoryboard(e) {
		var that = this.state;
		sessionStorage.setItem("changesdone", 1);
		var $this = e.currentTarget;
		var currentChartNumber = $($this).closest('.maxanim1').attr("data-num");
		$('.trend-stats-container').remove();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		$('.breadcrumb[data-num=' + currentChartNumber + ']').fadeOut(200, function () { $(this).remove(); });
		$('.storyboard-data-buttons[data-num=' + currentChartNumber + ']').prop('disabled', true);
		setTimeout(function () {
			$('.storyboard-data-buttons[data-num=' + currentChartNumber + ']').prop('disabled', false);
		}, 5000);
		$(".storyboard-data-buttons").removeClass("active");
		$($this).addClass("active");
		$("#spinner" + currentChartNumber).show();
		var chartData = ChartInfo.functions.getChartDetails(currentChartNumber);
		var data = JSON.parse(chartData);
		var chartDobyColumns = [];
		var finalCustomFields = [];
		var customFields = sessionStorage.getItem("customFields");
		var temp = JSON.parse(customFields);
		var isYaxisContainsMeasureHierarchyfields = false;
		var res;
		var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(undefined);
		_.each(temp, function (customfields) {
			_.each(customfields.parametrFieldsArray, function (value) {
				var customparameter = ChartInfo.functions.getCustomParameter();
				if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
					customparameter = customParameterFilterArray;
				}
				res = $.grep(customparameter, function (e) {
					return e.parametername == value;
				})
				var formula = customfields.formula;
				var scriptcondition = customfields.scriptcondition;
				var fields = customfields.fields;
				var sqlformula = customfields.sqlformula;
				if (res.length != 0) {
					customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
				} else {
					_.each(ChartInfo.functions.getCustomParameter(), function (values) {
						if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
							customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
							customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
						}
					});
				}
			});
		});
		customFields = JSON.stringify(temp);
		var parsingCustomFields = []
		if (customFields != "null" && customFields != undefined) {
			parsingCustomFields = JSON.parse(customFields);
		}
		var reportnames = that.reportcolumns;
		var that2 = this;
		$.each(data.yAxis, function (inddex, yObject) {
			var isCustomFiled = false;
			_.each(reportnames, function (names) {
				if (names.columnName == yObject.columnName) {
					yObject["displayName"] = names.columnDisplayName;
				}
			});
			var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
			if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
				var parsedData = JSON.parse(renameMeasuresorDimensions);
				if (parsedData[yObject.columnName] != undefined) {
					yObject['displayName'] = parsedData[yObject.columnName];
				}
			}
			var measureHierarachy = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name == yObject.columnName });
			if (measureHierarachy.length > 0) {
				yObject["displayName"] = measureHierarachy[0]["hierarchelist"][0]["columnName"];
				yObject["columnName"] = measureHierarachy[0]["hierarchelist"][0]["columnName"];
				isYaxisContainsMeasureHierarchyfields = true;
			}
			var customMeasure = $.grep(ChartInfo.functions.getCustomMeasureField(), function (e) { return e.name == yObject.columnName });
			if (customMeasure.length > 0) {
				that2.viewchartsummary(e);
			}
			if (parsingCustomFields != null && parsingCustomFields != undefined) {
				$.each(parsingCustomFields, function (inddex, custom) {
					if (custom["name"] == yObject["columnName"]) {
						isCustomFiled = true;
						if (data.yAxis != undefined && data.yAxis.length > 0) {
							for (let i = 0; i < custom.usedFields.length; i++) {
								var object = {
									"actualnames": custom.usedFields[i],
									"displaynames": yObject["displayName"],
									"datatype": yObject["dataType"]
								};
								chartDobyColumns.push(object);
							}
						}
						finalCustomFields.push(parsingCustomFields[inddex]);
					}
				});
			}
			if (isCustomFiled == false) {
				var object = {
					"actualnames": yObject["columnName"],
					"displaynames": yObject["displayName"],
					"datatype": yObject["dataType"]
				};
				chartDobyColumns.push(object);
			}
		});
		$.each(data.xAxis, function (inddex, xObject) {
			var isCustomFiled = false;
			var temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == xObject.columnName });
			var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == xObject.columnName });
			if (temp.length > 0) {
				xObject = temp[0].hierarchelist[0];
				xObject['displayName'] = xObject.columnDisplayName;
			}
			_.each(reportnames, function (names) {
				if (names.columnName == xObject.columnName) {
					xObject["displayName"] = names.columnDisplayName;
				}
			});
			if (rangeObject.length > 0) {
				var tempxObject = rangeObject[0]["xAxisName"][0];
				xObject['columnName'] = tempxObject.columnName;
			}
			var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
			if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
				var parsedData = JSON.parse(renameMeasuresorDimensions);
				if (parsedData[xObject.columnName] != undefined) {
					if (xObject.dataType != "customrange") {
						xObject['displayName'] = parsedData[xObject.columnName];
					}
				}
			}
			if (parsingCustomFields != null && parsingCustomFields != undefined) {
				$.each(parsingCustomFields, function (inddex, custom) {
					if (custom["name"] == xObject["columnName"]) {
						isCustomFiled = true;
						if (data.xAxis != undefined && data.xAxis.length > 0) {
							for (let i = 0; i < custom.usedFields.length; i++) {
								var object = {
									"actualnames": custom.usedFields[i],
									"displaynames": xObject["displayName"],
									"datatype": xObject["dataType"]
								};
								chartDobyColumns.push(object);
							}
						}
						finalCustomFields.push(parsingCustomFields[inddex]);
					}
				});
			}
			if (isCustomFiled == false) {
				var object = {
					"actualnames": xObject["columnName"],
					"displaynames": xObject["displayName"],
					"datatype": xObject["dataType"]
				};
				chartDobyColumns.push(object);
			}
		});
		if (data.legend != undefined && data.legend != "") {
			var isCustomFiled = false;
			var columnName = data.legend["columnName"];
			var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == data.legend["columnName"] });
			if (parsingCustomFields != null && parsingCustomFields != undefined) {
				$.each(parsingCustomFields, function (inddex, custom) {
					if (custom["name"] == data.legend["columnName"]) {
						isCustomFiled = true;
						if (data.legend != undefined) {
							for (let i = 0; i < custom.usedFields.length; i++) {
								var object = {
									"actualnames": custom.usedFields[i],
									"displaynames": data.legend["displayName"],
									"datatype": data.legend["dataType"]
								};
								chartDobyColumns.push(object);
							}
						}
						finalCustomFields.push(parsingCustomFields[inddex]);
					}
				});
			}
			if (rangeObject.length > 0) {
				var tempxObject = rangeObject[0]["xAxisName"][0];
				columnName = tempxObject.columnName;
			}
			if (isCustomFiled == false) {
				var object = {
					"actualnames": columnName,
					"displaynames": data.legend["displayName"],
					"datatype": data.legend["dataType"]
				};
				chartDobyColumns.push(object);
			}
		}

		if (data.zAxis != undefined && data.zAxis != null && data.zAxis != "null") {
			$.each(data.zAxis, function (inddex, zObject) {
				var isCustomFiled = false;
				_.each(reportnames, function (names) {
					if (names.columnName == zObject.columnName) {
						zObject["displayName"] = names.columnDisplayName;
					}
				});
				var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
				if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
					var parsedData = JSON.parse(renameMeasuresorDimensions);
					if (parsedData[zObject.columnName] != undefined) {
						zObject['displayName'] = parsedData[zObject.columnName];
					}
				}
				if (parsingCustomFields != null && parsingCustomFields != undefined) {
					$.each(parsingCustomFields, function (inddex, custom) {
						if (custom["name"] == zObject["columnName"]) {
							isCustomFiled = true;
							if (data.zAxis != undefined && data.zAxis.length > 0) {
								for (let i = 0; i < custom.usedFields.length; i++) {
									var object = {
										"actualnames": custom.usedFields[i],
										"displaynames": zObject["displayName"],
										"datatype": zObject["displayName"]
									};
									chartDobyColumns.push(object);
								}
							}
							finalCustomFields.push(parsingCustomFields[inddex]);
						}
					});
				}
				if (isCustomFiled == false) {
					var object = {
						"actualnames": zObject["columnName"],
						"displaynames": zObject["displayName"],
						"datatype": zObject["dataType"]
					};
					chartDobyColumns.push(object);
				}
			});
		}

		if (data.tooltip != undefined && data.tooltip != null && data.tooltip != "null") {
			$.each(data.tooltip, function (inddex, tooltip) {
				var isCustomFiled = false;
				_.each(reportnames, function (names) {
					if (names.columnName == tooltip.columnName) {
						tooltip["displayName"] = names.columnDisplayName;
					}
				});
				var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
				if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
					var parsedData = JSON.parse(renameMeasuresorDimensions);
					if (parsedData[tooltip.columnName] != undefined) {
						tooltip['displayName'] = parsedData[tooltip.columnName];
					}
				}
				if (parsingCustomFields != null && parsingCustomFields != undefined) {
					$.each(parsingCustomFields, function (inddex, custom) {
						if (custom["name"] == tooltip["columnName"]) {
							isCustomFiled = true;
							finalCustomFields.push(parsingCustomFields[inddex]);
						}
					});
				}
				if (isCustomFiled == false) {
					var object = {
						"actualnames": tooltip["columnName"],
						"displaynames": tooltip["displayName"],
						"datatype": tooltip["dataType"]
					};
					chartDobyColumns.push(object);
				}
			});
		}
		var finalFilters = JSON.parse(JSON.stringify(ChartInfo.functions.getFilters(true)));
		var drilldown = [];
		var drilldownchartnumber = ChartInfo.functions.getDrillDownChartNumber();
		if (drilldownchartnumber != undefined && drilldownchartnumber != currentChartNumber) {
			drilldown = JSON.parse(sessionStorage.getItem("drilldown" + drilldownchartnumber));
			if (drilldown != null) {
				drilldown = $.grep(drilldown, function (value) { return value.isDrillDownAll; });
				finalFilters = finalFilters.concat(drilldown);
			}
		}
		//							if((finalCustomFields==null || finalCustomFields== undefined||finalCustomFields.length==0) && !isYaxisContainsMeasureHierarchyfields){
		//								$.each(ChartInfo.functions.getFiltersForDataBehindChart(currentChartNumber),function(index,filter){
		//									finalFilters = finalFilters.concat(filter);
		//								});
		//							}
		if (parsingCustomFields != null && parsingCustomFields != undefined) {
			$.each(parsingCustomFields, function (inddex, custom) {
				_.each(finalFilters, function (names) {
					if (custom["name"] == names["columnname"] && names["invalue"] != undefined && names["invalue"] != "") {
						finalCustomFields.push(parsingCustomFields[inddex]);
					}
				});
			});
		}

		var timeZone;
		var TimeZoneSettingsValueForstoryboard = ChartInfo.functions.getTimeZoneSettings("-1");

		var TimeZoneSettingsValueForEachChart = ChartInfo.functions.getTimeZoneSettings($($this).attr("data-num"));
		if (TimeZoneSettingsValueForEachChart == undefined) {
			if (TimeZoneSettingsValueForstoryboard == "localtimezone") {
				timeZone = jstz.determine().name();
				this.timeZone = timeZone;
			} else {
				this.timeZone = undefined;
			}
		} else {
			if (TimeZoneSettingsValueForEachChart == "localtimezone") {
				timeZone = jstz.determine().name();
				this.timeZone = timeZone;
			} else {
				this.timeZone = undefined;
			}
		}
		var rangefieldMap = {};
		if (data.xAxis.length != 0) {
			if (data.xAxis[0].dataType == "customrange") {
				var ramgeArray = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == data.xAxis[0].displayName });
				if (ramgeArray.length > 0) {
					rangefieldMap[ramgeArray[0].name] = ramgeArray[0];
				}
			}
		}
		finalFilters = ChartInfo.functions.generateFilterWithEmbededFilters(finalFilters, viewcollection.getSharedFilters());
		//	if(finalCustomFields==null||finalCustomFields==""){
		if (parsingCustomFields != null && parsingCustomFields != undefined) {
			$.each(parsingCustomFields, function (inddex, custom) {
				_.each(finalFilters, function (names) {
					if (custom["name"] == names["name"]) {
						isCustomFiled = true;
						for (let i = 0; i < custom.usedFields.length; i++) {
							var object = {
								"actualnames": custom.usedFields[i],
								"displaynames": names["displayName"],
								"datatype": names["dataType"]
							};
							chartDobyColumns.push(object);
						}

						finalCustomFields.push(parsingCustomFields[inddex]);
					}
					if (custom["name"] == names["columnname"] && names["invalue"] != undefined && names["invalue"] != "") {
						finalCustomFields.push(parsingCustomFields[inddex]);
					}
				});
			});
		}
		//}

		//Added code to handle tile level filters
		var mainFilterCopy = finalFilters;
		if (mainFilterCopy != undefined) {
			finalFilters = [];
			_.each(mainFilterCopy, function (mfvalue, index1) {
				var mfvalueC = _.clone(mfvalue);
				finalFilters.push(mfvalueC);
			})
		}

		var mainFiltersList = [];
		var tileLevelFilters = ChartInfo.functions.getTileLevelFilters(currentChartNumber);
		if (tileLevelFilters != undefined && tileLevelFilters[1].chartid == currentChartNumber) {
			var tileFiltersList = tileLevelFilters[1].tileFiltersList;
			var storyboardFilters = tileLevelFilters[0].storyboardFilters;
			var isStoryboardFiltersEnabled = tileLevelFilters[0].isStoryboardFiltersEnabled;
			if (finalFilters != undefined && storyboardFilters != undefined && storyboardFilters.length > 0) {
				_.each(storyboardFilters, function (sfvalue, index) {
					_.each(finalFilters, function (mfvalue, index1) {
						if (sfvalue != undefined && mfvalue != undefined && mfvalue.filterIgnore != undefined && mfvalue.columnname == sfvalue.columnname) {
							mfvalue.filterIgnore = !sfvalue.checked;
						}
					});
				});
			}

			if (isStoryboardFiltersEnabled != undefined && finalFilters != undefined && isStoryboardFiltersEnabled == false) {
				_.each(finalFilters, function (mfvalue, index1) {
					if (mfvalue != undefined && mfvalue.filterIgnore != undefined) {
						mfvalue.filterIgnore = true;
					}
				});
			}

			if (tileFiltersList != undefined && finalFilters != undefined) {
				_.each(tileFiltersList, function (sfvalue, index) {
					_.each(finalFilters, function (mfvalue, index1) {
						if (sfvalue != undefined && mfvalue != undefined && mfvalue.filterIgnore != undefined && mfvalue.columnname == sfvalue.columnname) {
							mfvalue.filterIgnore = true;
						}
					});
				});
			}

			if (finalFilters != undefined && tileFiltersList != undefined) {
				var mainFiltersList = [];
				var instanceFilteresList = [];
				_.each(finalFilters, function (mfvalue, index1) {
					if (mfvalue != undefined && mfvalue.filterIgnore != undefined && !mfvalue.filterIgnore) {
						mainFiltersList.push(mfvalue.columnname);
					} else if (mfvalue != undefined && mfvalue.filterIgnore == undefined && mfvalue.isdrilldownfilter != undefined) {
						instanceFilteresList.push(mfvalue.columnname);
					}
				})
				var tileFiltersListClone = [];
				_.each(tileFiltersList, function (tfv, index) {
					var tfvCopy = _.clone(tfv);
					if (instanceFilteresList.indexOf(tfvCopy.columnname) == -1) {
						tileFiltersListClone.push(tfvCopy);
					}
				});
				finalFilters = finalFilters.concat(tileFiltersListClone);
			}
		}
		var numformat = {};
		if (data.chartSettings.numberformat != undefined) {
			numformat = data.chartSettings.numberformat;
		}
		var gridmodel = new FormData();
		gridmodel.set("aggregation", null);
		gridmodel.set("xaxis", null);
		gridmodel.set("xaxisdataType", null);
		gridmodel.set("yaxis", null);
		gridmodel.set("datamodelId", that.datamodelId);
		gridmodel.set("customFields", JSON.stringify(finalCustomFields));
		gridmodel.set("reportId", that.reportId);
		gridmodel.set("script", JSON.stringify(finalCustomFields));
		gridmodel.set("filterObject", JSON.stringify(finalFilters));
		gridmodel.set("selectedcolumns", JSON.stringify(chartDobyColumns));
		gridmodel.set("rangefieldMap", JSON.stringify(rangefieldMap));
		gridmodel.set("multifact", that.multifact);
		gridmodel.set("multifactentities", that.multifactentities);
		gridmodel.set("numberformat", JSON.stringify(numformat));
		var hasCustomAggregatedField = false;
		if (chartDobyColumns != undefined && chartDobyColumns != null && chartDobyColumns.length > 0) {
			_.each(chartDobyColumns, function (names) {
				if (names.datatype == "customaggregatedfield") {
					hasCustomAggregatedField = true;
					return
				}
			});
		}
		var that = this.state;
		if (hasCustomAggregatedField == false) {

			var tableInfor = {
				paginationdiv: false
			};
			gridmodel.set("isImportedReport", that.isImportedReport);
			gridmodel.set("timeZone", timeZone);
			gridmodel.set("isPurgedReport", that.isPurgedReport);
			gridmodel.set("showMoreOptions", true);
			//	$("#chart"+currentChartNumber).empty();

			var bootObj = {};
			bootObj["currentChartNumber"] = currentChartNumber;
			bootObj["gridmodel"] = gridmodel;
			bootObj["tableInfo"] = tableInfor;
			bootObj["pos"] = undefined;
			bootObj["popupwindow"] = undefined;
			bootObj["reporttype"] = undefined;
			bootObj["target"] = undefined;
			bootObj["isFromPagenation"] = undefined;
			var bootStrapTable = new BootStrapTable(bootObj);
			bootStrapTable.render();
			//	ChartUtils.drawBootstrapTable(currentChartNumber, gridmodel,tableInfor,undefined,undefined);
		}
		var drilldown = [];
		var drilldownchartnumber = ChartInfo.functions.getDrillDownChartNumber()
		if (drilldownchartnumber != undefined && drilldownchartnumber != currentChartNumber) {
			drilldown = JSON.parse(sessionStorage.getItem("drilldown" + drilldownchartnumber));
			if (drilldown != null) {
				drilldown = $.grep(drilldown, function (value) { return value.isDrillDownAll; });
			}
		}

		$(".showtrending[data-num=" + currentChartNumber + "]").show();
		if (ChartInfo.functions.getDrillDownChartNumber() != undefined && ChartInfo.functions.getDrillDownChartNumber() == currentChartNumber) {
			var legendfilter = [];
			$(".clonetile[data-num=" + currentChartNumber + "]").removeClass("disabled");
			ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
			ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
			$(".pie-menu").remove();
			$(".fullscreensample").removeClass("nopie");
			$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
			$('.breadcrumb[data-num=' + currentChartNumber + ']').remove();
			var drilldown = JSON.parse(sessionStorage.getItem("drilldown" + currentChartNumber));
			if (drilldown != null) {
				drilldown = $.grep(drilldown, function (value) { return value.isDrillDownAll; });
			}
			sessionStorage.removeItem("drilldown" + currentChartNumber);
			if (drilldown != undefined && drilldown.length != 0) {
				var that = this.state;
				_.each($(".maxanim1[data-num!=" + currentChartNumber + "]"), function (i) {
					var $this = i
					var chartnumber = $($this).attr("data-num");
					that.applyfiltesandredrawchart(undefined, chartnumber, "applyattributes", undefined, undefined, undefined, undefined, undefined, [], "drilldownview");
				});
			}

			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber));
			var drillDownAllFilter = chartDetails['drillDownAllFilter'];
			if (drillDownAllFilter != undefined && drillDownAllFilter.length != 0) {
				var that = this.state;
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
				$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").attr("drilled-chart", currentChartNumber);
				_.each(drillDownAllFilter, function (i, j) {
					var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
					if (name === null) {
						name = decodeURIComponent(drillDownAllFilter[j].name);
					}
					else if (name == "undefined") {
						name = decodeURIComponent(drillDownAllFilter[j].displayName);
						if (drillDownAllFilter[j].customfield != undefined) {
							name = drillDownAllFilter[j].customfield.name;
						}
					}
					if (drillDownAllFilter[j].type == "date") {
						name = $(name).find('li:first').attr('column-display-name');
						if (name === undefined) {
							name = drillDownAllFilter[j].displayName;
						}
					}
					var today = new Date();
					var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
					dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
					ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
					$("#" + dynamicdiv).remove();
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
					$('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) });
				});
				if (that.isSharedReport != true && ChartInfo.functions.getisViewOnlyFlag() != true) {
					$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>")
				}
			}
		}
	}
	viewchartsummary(e) {
		var that = this.state
		var $this = e.currentTarget;
		$('.viewdatafromchart').removeClass('active');
		$('.viewchartsummary').addClass('active');
		var currentChartNumber = $($this).attr("data-num");
		$(".dobyselectcolumns[data-num='" + currentChartNumber + "']").addClass('hide');
		$('.trend-stats-container').remove();
		$('.breadcrumb[data-num=' + currentChartNumber + ']').fadeOut(200, function () { $(this).remove(); });
		$(".summarytablemessage[data-num=" + currentChartNumber + "]").remove();
		$(".chartchanger[data-num='" + currentChartNumber + "']").hide();
		$('.viewchartdata[data-num=' + currentChartNumber + ']').addClass("active");
		$('.viewchartfromchartdata[data-num=' + currentChartNumber + ']').removeClass("active");
		$("#spinner" + currentChartNumber).show();
		sessionStorage.setItem('isDataTableDataExport', false)
		setTimeout(function () {
			$("#chart" + currentChartNumber + " .highcharts-container").remove();
		}, 1000)
		$(".storyboard-data-buttons").removeClass("active");
		$($this).addClass("active");
		if ($(".tileview[data-num=" + currentChartNumber + "]").length > 0) {
			var parentHeight = $($this).closest(".tileview").height() - 50;
			$("#chart" + currentChartNumber).css("height", parentHeight);
		}


		var chartData = ChartInfo.functions.getChartDetails(currentChartNumber);
		var data = JSON.parse(chartData);
		var chartDobyColumns = [];
		var finalCustomFields = [];
		var customFields = sessionStorage.getItem("customFields");
		var temp = JSON.parse(customFields);
		var isYaxisContainsMeasureHierarchyfields = false;
		var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(undefined);
		var res;
		_.each(temp, function (customfields) {
			_.each(customfields.parametrFieldsArray, function (value) {
				var customparameter = ChartInfo.functions.getCustomParameter();
				if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
					customparameter = customParameterFilterArray;
				}
				res = $.grep(customparameter, function (e) {
					return e.parametername == value;
				})
				var formula = customfields.formula;
				var scriptcondition = customfields.scriptcondition;
				var fields = customfields.fields;
				var sqlformula = customfields.sqlformula;
				if (res.length != 0) {
					customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
				} else {
					_.each(ChartInfo.functions.getCustomParameter(), function (values) {
						if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
							customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
							customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
						}
					});
				}
			});
		});
		customFields = JSON.stringify(temp);
		var parsingCustomFields = []
		if (customFields != undefined) {
			parsingCustomFields = JSON.parse(customFields);
		}
		$.each(data.xAxis, function (inddex, xObject) {
			var isCustomFiled = false;
			var temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == xObject.columnName });
			if (temp.length > 0) {
				xObject = temp[0].hierarchelist[0];
				xObject['displayName'] = xObject.columnDisplayName;
			}
			var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
			if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
				var parsedData = JSON.parse(renameMeasuresorDimensions);
				if (parsedData[xObject.columnName] != undefined) {
					xObject['displayName'] = parsedData[xObject.columnName];
				}
			}
			if (parsingCustomFields != null && parsingCustomFields != undefined) {
				$.each(parsingCustomFields, function (inddex, custom) {
					if (custom["name"] == xObject["columnName"]) {
						isCustomFiled = true;
						finalCustomFields.push(parsingCustomFields[inddex]);
					}
				});
			}
			if (isCustomFiled == false && xObject["columnName"] != "Measure Names") {
				var object = {
					"actualnames": xObject["columnName"],
					"displaynames": xObject["displayName"],
					"datatype": xObject["dataType"]
				};
				chartDobyColumns.push(object);
			}
		});
		if (data.legend != undefined && data.legend != "") {
			var isCustomFiled = false;
			if (parsingCustomFields != null && parsingCustomFields != undefined) {
				$.each(parsingCustomFields, function (inddex, custom) {
					if (custom["name"] == data.legend["columnName"]) {
						isCustomFiled = true;
						finalCustomFields.push(parsingCustomFields[inddex]);
					}
				});
			}
			if (isCustomFiled == false) {
				var object = {
					"actualnames": data.legend["columnName"],
					"displaynames": data.legend["displayName"],
					"datatype": data.legend["dataType"]
				};
				chartDobyColumns.push(object);
			}
		}
		chartDobyColumns.reverse();
		$.each(data.yAxis, function (inddex, yObject) {
			var isCustomFiled = false;
			var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
			if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
				var parsedData = JSON.parse(renameMeasuresorDimensions);
				if (parsedData[yObject.columnName] != undefined) {
					yObject['displayName'] = parsedData[yObject.columnName];
				}
			}
			var measureHierarachy = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name == yObject.columnName });
			if (measureHierarachy.length > 0) {
				yObject["displayName"] = measureHierarachy[0]["hierarchelist"][0]["columnName"];
				yObject["columnName"] = measureHierarachy[0]["hierarchelist"][0]["columnName"];
				isYaxisContainsMeasureHierarchyfields = true;
			}
			if (parsingCustomFields != null && parsingCustomFields != undefined) {
				$.each(parsingCustomFields, function (inddex, custom) {
					if (custom["name"] == yObject["columnName"]) {
						isCustomFiled = true;
						finalCustomFields.push(parsingCustomFields[inddex]);
					}
				});
			}
			if (isCustomFiled == false) {
				var object = {
					"actualnames": yObject["columnName"],
					"displaynames": yObject["displayName"],
					"datatype": yObject["dataType"]
				};
				chartDobyColumns.push(object);
			}
		});

		if (data.zAxis != undefined && data.zAxis != null && data.zAxis != "null") {
			$.each(data.zAxis, function (inddex, zObject) {
				var isCustomFiled = false;
				var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
				if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
					var parsedData = JSON.parse(renameMeasuresorDimensions);
					if (parsedData[zObject.columnName] != undefined) {
						zObject['displayName'] = parsedData[zObject.columnName];
					}
				}
				if (parsingCustomFields != null && parsingCustomFields != undefined) {
					$.each(parsingCustomFields, function (inddex, custom) {
						if (custom["name"] == zObject["columnName"]) {
							isCustomFiled = true;
							finalCustomFields.push(parsingCustomFields[inddex]);
						}
					});
				}
				if (isCustomFiled == false) {
					var object = {
						"actualnames": zObject["columnName"],
						"displaynames": zObject["displayName"],
						"datatype": zObject["dataType"]
					};
					chartDobyColumns.push(object);
				}
			});
		}

		if (data.tooltip != undefined && data.tooltip != null && data.tooltip != "null") {
			$.each(data.tooltip, function (inddex, tooltip) {
				var isCustomFiled = false;
				var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
				if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
					var parsedData = JSON.parse(renameMeasuresorDimensions);
					if (parsedData[tooltip.columnName] != undefined) {
						tooltip['displayName'] = parsedData[tooltip.columnName];
					}
				}
				if (parsingCustomFields != null && parsingCustomFields != undefined) {
					$.each(parsingCustomFields, function (inddex, custom) {
						if (custom["name"] == tooltip["columnName"]) {
							isCustomFiled = true;
							finalCustomFields.push(parsingCustomFields[inddex]);
						}
					});
				}
				if (isCustomFiled == false) {
					var object = {
						"actualnames": tooltip["columnName"],
						"displaynames": tooltip["displayName"],
						"datatype": tooltip["dataType"]
					};
					chartDobyColumns.push(object);
				}
			});
		}

		var finalFilters = JSON.parse(JSON.stringify(ChartInfo.functions.getFilters(true)));
		var drilldown = [];
		var drilldownchartnumber = ChartInfo.functions.getDrillDownChartNumber();
		if (drilldownchartnumber != undefined && drilldownchartnumber != currentChartNumber) {
			var drilldown = JSON.parse(sessionStorage.getItem("drilldown" + drilldownchartnumber));
			if (drilldown != null) {
				drilldown = $.grep(drilldown, function (value) { return value.isDrillDownAll; });
				if (finalFilters != undefined && finalFilters.length != 0) {
					finalFilters = finalFilters.concat(drilldown);
				} else {
					finalFilters = drilldown;
				}
			}
		}
		/*if((finalCustomFields==null || finalCustomFields== undefined||finalCustomFields.length==0) && !isYaxisContainsMeasureHierarchyfields){
			$.each(ChartInfo.functions.getFiltersForDataBehindChart(currentChartNumber),function(index,filter){
				finalFilters = finalFilters.concat(filter);
			});
		}*/
		this.applyfiltesandredrawchart(undefined, currentChartNumber, "applyattributes", undefined, "yes", "yes", "yes", chartDobyColumns, finalFilters); if (ChartInfo.functions.getDrillDownChartNumber() != undefined && ChartInfo.functions.getDrillDownChartNumber() == currentChartNumber) {
			if (ChartInfo.functions.getDrillDownChartNumber() != undefined && ChartInfo.functions.getDrillDownChartNumber() == currentChartNumber) {
				var legendfilter = [];
				ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
				ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
				$(".pie-menu").remove();
				$(".fullscreensample").removeClass("nopie");
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
				$('.breadcrumb[data-num=' + currentChartNumber + ']').remove();
				var drilldown = JSON.parse(sessionStorage.getItem("drilldown" + currentChartNumber));
				if (drilldown != null) {
					drilldown = $.grep(drilldown, function (value) { return value.isDrillDownAll; });
				}
				sessionStorage.removeItem("drilldown" + currentChartNumber);
				$(".clonetile[data-num=" + currentChartNumber + "]").removeClass("disabled");
				if (drilldown != undefined && drilldown.length != 0) {
					var that = this;
					_.each($(".maxanim1[data-num!=" + currentChartNumber + "]"), function (i) {
						var $this = i
						var chartnumber = $($this).attr("data-num");
						that.applyfiltesandredrawchart(undefined, chartnumber, "applyattributes", undefined, undefined, undefined, undefined, undefined, [], "drilldownview");
					});
				}
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
				$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").attr("drilled-chart", currentChartNumber);
				var that = this;
				var drillDownAllFilter = data['drillDownAllFilter'];
				_.each(drillDownAllFilter, function (i, j) {
					// if(drillDownAllFilter[j]['save'])
					var name = decodeURIComponent(drillDownAllFilter[j].columnname);
					if (name === null) {
						var name = decodeURIComponent(drillDownAllFilter[j].name);
					}
					if (drillDownAllFilter[j].type == "date") {
						name = $(name).find('li.drilldown:first').attr('column-display-name');
						if (name === undefined) {
							name = drillDownAllFilter[j].columnname;
						}
					}
					var today = new Date();
					var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
					dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
					ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
					$("#" + dynamicdiv).remove();
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
					$('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) });
				});
				$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>")

				$('.breadcrumb[data-num=' + currentChartNumber + ']').remove();
				$("#chart" + currentChartNumber + "").before("<ul class='breadcrumb' data-num=" + currentChartNumber + "></ul>");
				if (currentChartNumber != undefined) {
					if (data["drillDownFilter"] != undefined) {
						var breadCrumbElement = $('.breadcrumb[data-num=' + currentChartNumber + ']')
						_.each(data["drillDownFilter"], function (value, index) {
							var appendValue = value.data;
							var ulEle = "";
							var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
							if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
								appendValue = breadCrumpElements["span"];
								ulEle = breadCrumpElements["ulelement"];
							}
							if (value["saved"] == "true") {
								breadCrumbElement.append("<li class= drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name=" + value.data + " class='close removedrilldown' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
							}
						});
					}
				}
			}
		}
	}
	viewchartdata(e) {
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		var that = this;
		var thisstate = this.state;
		if (thisstate.multifact == "true") {
			this.viewchartsummary(e);
			return true;
		}
		$(".chartadvancedoptions[data-num='chart" + currentChartNumber + "']").hide();
		$('.trend-stats-container').remove();
		$('.viewdatafromchart').removeClass('hide');
		$('.viewchartsummary').removeClass('hide');
		$('.viewchartsummary').removeClass('active');
		$('.viewdatafromchart').addClass('active');
		$('.breadcrumb[data-num=' + currentChartNumber + ']').fadeOut(200, function () { $(this).remove(); });
		var flag = true;
		if (sessionStorage.getItem("drilldown" + currentChartNumber) != undefined) {
			var fiterdata = JSON.parse(sessionStorage.getItem("drilldown" + currentChartNumber));
			_.each(fiterdata, function (data) {
				if (data["isDrillDownAll"] == true) {
					flag = false;
					return;
				}
			});
		}
		var that = this;
		_.each($(".tileview[data-num!=" + that.chartnumber + "]"), function (i) {
			var $this = i
			var chartnumber = $($this).attr("data-num");
			if (currentChartNumber != chartnumber) {
				if (!flag) {
					$(".pie-menu").remove();
					$(".fullscreensample").removeClass("nopie");
					that.applyfiltesandredrawchart(undefined, chartnumber, "applyattributes", undefined, undefined, undefined, undefined, undefined, [], "drilldownview");
				}
			}
		});
		var legendfilter = [];
		ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
		ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
		sessionStorage.removeItem("drilldown" + currentChartNumber);
		$(".clonetile[data-num=" + currentChartNumber + "]").removeClass("disabled");
		$('.viewchartdata[data-num=' + currentChartNumber + ']').addClass("active");
		$(".chartchanger[data-num='" + currentChartNumber + "']").hide();
		$('.viewchartfromchartdata[data-num=' + currentChartNumber + ']').removeClass("active");
		$("#spinner" + currentChartNumber).show();
		var chartData = ChartInfo.functions.getChartDetails(currentChartNumber);
		var data = JSON.parse(chartData);
		var chartDobyColumns = [];
		var finalCustomFields = [];
		var customFields = sessionStorage.getItem("customFields");
		var temp = JSON.parse(customFields);
		var isYaxisContainsMeasureHierarchyfields = false;
		var res;
		var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(undefined);
		_.each(temp, function (customfields) {
			_.each(customfields.parametrFieldsArray, function (value) {
				var customparameter = ChartInfo.functions.getCustomParameter();
				if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
					customparameter = customParameterFilterArray;
				}
				res = $.grep(customparameter, function (e) {
					return e.parametername == value;
				})
				var formula = customfields.formula;
				var scriptcondition = customfields.scriptcondition;
				var fields = customfields.fields;
				var sqlformula = customfields.sqlformula;
				if (res.length != 0) {
					customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
				} else {
					_.each(ChartInfo.functions.getCustomParameter(), function (values) {
						if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
							customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
							customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
						}
					});
				}
			});
		});
		customFields = JSON.stringify(temp);
		var parsingCustomFields = []
		if (customFields != undefined) {
			parsingCustomFields = JSON.parse(customFields);
		}
		var reportnames = that.reportcolumns;
		$.each(data.xAxis, function (inddex, xObject) {
			var isCustomFiled = false;
			var temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == xObject.columnName });
			var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == xObject.columnName });
			if (temp.length > 0) {
				xObject = temp[0].hierarchelist[0];
				xObject['displayName'] = xObject.columnDisplayName;
			}
			_.each(reportnames, function (names) {
				if (names.columnName == xObject.columnName) {
					xObject["displayName"] = xObject.displayName;
				}
			});
			if (rangeObject.length > 0) {
				var tempxObject = rangeObject[0]["xAxisName"][0];
				xObject['columnName'] = tempxObject.columnName;
				$('.viewchartsummary[data-num=' + currentChartNumber + ']').addClass('hide');
			}
			var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
			if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
				var parsedData = JSON.parse(renameMeasuresorDimensions);
				if (parsedData[xObject.columnName] != undefined) {
					if (xObject.dataType != "customrange") {
						xObject['displayName'] = parsedData[xObject.columnName];
					}
				}
			}
			if (parsingCustomFields != null && parsingCustomFields != undefined) {
				$.each(parsingCustomFields, function (inddex, custom) {
					if (custom["name"] == xObject["columnName"]) {
						isCustomFiled = true;
						if (data.xAxis != undefined && data.xAxis.length > 0) {
							for (let i = 0; i < custom.usedFields.length; i++) {
								var object = {
									"actualnames": custom.usedFields[i],
									"displaynames": xObject["displayName"],
									"datatype": xObject["dataType"]
								};
								chartDobyColumns.push(object);
							}
						}
						finalCustomFields.push(parsingCustomFields[inddex]);
					}
				});
			}
			if (isCustomFiled == false && xObject["columnName"] != "Measure Names") {
				var object = {
					"actualnames": xObject["columnName"],
					"displaynames": xObject["displayName"],
					"datatype": xObject["dataType"]
				};
				chartDobyColumns.push(object);
			}
		});
		$.each(data.yAxis, function (inddex, yObject) {
			var isCustomFiled = false;
			_.each(reportnames, function (names) {
				if (names.columnName == yObject.columnName) {
					yObject["displayName"] = yObject.displayName;
				}
			});
			var measureHierarachy = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name == yObject.columnName });
			if (measureHierarachy.length > 0) {
				yObject["displayName"] = measureHierarachy[0]["hierarchelist"][0]["columnName"];
				yObject["columnName"] = measureHierarachy[0]["hierarchelist"][0]["columnName"];
				isYaxisContainsMeasureHierarchyfields = true;
			}
			var customMeasure = $.grep(ChartInfo.functions.getCustomMeasureField(), function (e) { return e.name == yObject.columnName });
			if (customMeasure.length > 0) {
				that.viewchartsummary(e);
				$('.viewdatafromchart').addClass('active');
				$('.viewchartsummary').removeClass('active');
			}
			var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
			if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
				var parsedData = JSON.parse(renameMeasuresorDimensions);
				if (parsedData[yObject.columnName] != undefined) {
					yObject['displayName'] = parsedData[yObject.columnName];
				}
			}
			if (parsingCustomFields != null && parsingCustomFields != undefined) {
				$.each(parsingCustomFields, function (inddex, custom) {
					if (custom["name"] == yObject["columnName"]) {
						isCustomFiled = true;
						if (data.yAxis != undefined && data.yAxis.length > 0) {
							for (let i = 0; i < custom.usedFields.length; i++) {
								var object = {
									"actualnames": custom.usedFields[i],
									"displaynames": yObject["displayName"],
									"datatype": yObject["dataType"]
								};
								chartDobyColumns.push(object);
							}
						}
						finalCustomFields.push(parsingCustomFields[inddex]);
					}
				});
			}
			if (isCustomFiled == false) {
				var object = {
					"actualnames": yObject["columnName"],
					"displaynames": yObject["displayName"],
					"datatype": yObject["dataType"]
				};
				chartDobyColumns.push(object);
			}
		});
		if (data.legend != undefined && data.legend != "") {
			var isCustomFiled = false;
			var columnName = data.legend["columnName"];
			var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == data.legend["columnName"] });
			if (parsingCustomFields != null && parsingCustomFields != undefined) {
				$.each(parsingCustomFields, function (inddex, custom) {
					if (custom["name"] == data.legend["columnName"]) {
						isCustomFiled = true;
						if (data.legend != undefined) {
							for (let i = 0; i < custom.usedFields.length; i++) {
								var object = {
									"actualnames": custom.usedFields[i],
									"displaynames": data.legend["displayName"],
									"datatype": data.legend["dataType"]
								};
								chartDobyColumns.push(object);
							}
						}
						finalCustomFields.push(parsingCustomFields[inddex]);
					}
				});
			}
			if (rangeObject.length > 0) {
				var tempxObject = rangeObject[0]["xAxisName"][0];
				columnName = tempxObject.columnName;
				$('.viewchartsummary[data-num=' + currentChartNumber + ']').addClass('hide');
			}
			if (isCustomFiled == false) {
				var object = {
					"actualnames": columnName,
					"displaynames": data.legend["displayName"],
					"datatype": data.legend["dataType"]
				};
				chartDobyColumns.push(object);
			}
		}

		if (data.zAxis != undefined && data.zAxis != null && data.zAxis != "null") {
			$.each(data.zAxis, function (inddex, zObject) {
				var isCustomFiled = false;
				_.each(reportnames, function (names) {
					if (names.columnName == zObject.columnName) {
						zObject["displayName"] = zObject.displayName;
					}
				});
				var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
				if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
					var parsedData = JSON.parse(renameMeasuresorDimensions);
					if (parsedData[zObject.columnName] != undefined) {
						zObject['displayName'] = parsedData[zObject.columnName];
					}
				}
				if (parsingCustomFields != null && parsingCustomFields != undefined) {
					$.each(parsingCustomFields, function (inddex, custom) {
						if (custom["name"] == zObject["columnName"]) {
							isCustomFiled = true;
							if (data.zAxis != undefined && data.zAxis.length > 0) {
								for (let i = 0; i < custom.usedFields.length; i++) {
									var object = {
										"actualnames": custom.usedFields[i],
										"displaynames": zObject["displayName"],
										"datatype": zObject["dataType"]
									};
									chartDobyColumns.push(object);
								}
							}
							finalCustomFields.push(parsingCustomFields[inddex]);
						}
					});
				}
				if (isCustomFiled == false) {
					var object = {
						"actualnames": zObject["columnName"],
						"displaynames": zObject["displayName"],
						"datatype": zObject["dataType"]
					};
					chartDobyColumns.push(object);
				}
			});
		}

		if (data.tooltip != undefined && data.tooltip != null && data.tooltip != "null") {
			$.each(data.tooltip, function (inddex, tooltip) {
				var isCustomFiled = false;
				_.each(reportnames, function (names) {
					if (names.columnName == tooltip.columnName) {
						tooltip["displayName"] = tooltip.displayName;
					}
				});
				var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
				if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
					var parsedData = JSON.parse(renameMeasuresorDimensions);
					if (parsedData[tooltip.columnName] != undefined) {
						tooltip['displayName'] = parsedData[tooltip.columnName];
					}
				}
				if (parsingCustomFields != null && parsingCustomFields != undefined) {
					$.each(parsingCustomFields, function (inddex, custom) {
						if (custom["name"] == tooltip["columnName"]) {
							isCustomFiled = true;
							finalCustomFields.push(parsingCustomFields[inddex]);
						}
					});
				}
				if (isCustomFiled == false) {
					var object = {
						"actualnames": tooltip["columnName"],
						"displaynames": tooltip["displayName"],
						"datatype": tooltip["dataType"]
					};
					chartDobyColumns.push(object);
				}
			});
		}

		var finalFilters = ChartInfo.functions.getFilters(true);
		//							if((finalCustomFields==null || finalCustomFields== undefined||finalCustomFields.length==0) && !isYaxisContainsMeasureHierarchyfields){
		//								$.each(ChartInfo.functions.getFiltersForDataBehindChart(currentChartNumber),function(index,filter){
		//									finalFilters = finalFilters.concat(filter);
		//								});
		//							}

		if (parsingCustomFields != null && parsingCustomFields != undefined) {
			$.each(parsingCustomFields, function (inddex, custom) {
				_.each(finalFilters, function (names) {
					if (custom["name"] == names["columnname"] && names["invalue"] != undefined && names["invalue"] != "") {
						finalCustomFields.push(parsingCustomFields[inddex]);
					}
				});
			});
		}

		var rangefieldMap = {};
		if (data.xAxis.length != 0) {
			if (data.xAxis[0].dataType == "customrange") {
				var ramgeArray = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == data.xAxis[0].displayName });
				if (ramgeArray.length > 0) {
					rangefieldMap[ramgeArray[0].name] = ramgeArray[0];
				}
			}
		}
		finalFilters = ChartInfo.functions.generateFilterWithEmbededFilters(finalFilters, viewcollection.getSharedFilters());
		var mainFilterCopy = finalFilters;
		if (mainFilterCopy != undefined) {
			finalFilters = [];
			_.each(mainFilterCopy, function (mfvalue, index1) {
				var mfvalueC = _.clone(mfvalue);
				finalFilters.push(mfvalueC);
			})
		}
		//Added code to handle tile level filters
		var mainFiltersList = [];
		var tileLevelFilters = ChartInfo.functions.getTileLevelFilters(currentChartNumber);
		if (tileLevelFilters != undefined && tileLevelFilters[1].chartid == currentChartNumber) {
			var tileFiltersList = tileLevelFilters[1].tileFiltersList;
			var storyboardFilters = tileLevelFilters[0].storyboardFilters;
			var isStoryboardFiltersEnabled = tileLevelFilters[0].isStoryboardFiltersEnabled;
			if (finalFilters != undefined && storyboardFilters != undefined && storyboardFilters.length > 0) {
				_.each(storyboardFilters, function (sfvalue, index) {
					_.each(finalFilters, function (mfvalue, index1) {
						if (sfvalue != undefined && mfvalue != undefined && mfvalue.filterIgnore != undefined && mfvalue.columnname == sfvalue.columnname) {
							mfvalue.filterIgnore = !sfvalue.checked;
						}
					});
				});
			}

			if (isStoryboardFiltersEnabled != undefined && finalFilters != undefined && isStoryboardFiltersEnabled == false) {
				_.each(finalFilters, function (mfvalue, index1) {
					if (mfvalue != undefined && mfvalue.filterIgnore != undefined) {
						mfvalue.filterIgnore = true;
					}
				});
			}

			if (tileFiltersList != undefined && finalFilters != undefined) {
				_.each(tileFiltersList, function (sfvalue, index) {
					_.each(finalFilters, function (mfvalue, index1) {
						if (sfvalue != undefined && mfvalue != undefined && mfvalue.filterIgnore != undefined && mfvalue.columnname == sfvalue.columnname) {
							mfvalue.filterIgnore = true;
						}
					});
				});
			}

			if (finalFilters != undefined && tileFiltersList != undefined) {
				var mainFiltersList = [];
				var instanceFilteresList = [];
				_.each(finalFilters, function (mfvalue, index1) {
					if (mfvalue != undefined && mfvalue.filterIgnore != undefined && !mfvalue.filterIgnore) {
						mainFiltersList.push(mfvalue.columnname);
					} else if (mfvalue != undefined && mfvalue.filterIgnore == undefined && mfvalue.isdrilldownfilter != undefined) {
						instanceFilteresList.push(mfvalue.columnname);
					}
				})
				var tileFiltersListClone = [];
				_.each(tileFiltersList, function (tfv, index) {
					var tfvCopy = _.clone(tfv);
					if (instanceFilteresList.indexOf(tfvCopy.columnname) == -1) {
						tileFiltersListClone.push(tfvCopy);
					}
				});
				finalFilters = finalFilters.concat(tileFiltersListClone);
			}
		}
		var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber));
		var gridmodel = new FormData();
		var numformat = {};
		if (chartDetails.chartSettings != undefined && chartDetails.chartSettings.numberformat != undefined) {
			numformat = chartDetails.chartSettings.numberformat;
		}
		gridmodel.set("numberformat", JSON.stringify(numformat));
		gridmodel.set("aggregation", null);
		gridmodel.set("xaxis", null);
		gridmodel.set("xaxisdataType", null);
		gridmodel.set("yaxis", null);
		gridmodel.set("datamodelId", thisstate.datamodelId);
		gridmodel.set("customFields", JSON.stringify(finalCustomFields));
		gridmodel.set("reportId", thisstate.reportId);
		gridmodel.set("script", JSON.stringify(finalCustomFields));
		gridmodel.set("filterObject", JSON.stringify(finalFilters));
		gridmodel.set("selectedcolumns", JSON.stringify(chartDobyColumns));
		gridmodel.set("rangefieldMap", JSON.stringify(rangefieldMap));
		gridmodel.set("isImportedReport", thisstate.isImportedReport);
		gridmodel.set("showMoreOptions", true);
		var timeZone;
		var TimeZoneSettingsValueForstoryboard = ChartInfo.functions.getTimeZoneSettings("-1");

		var TimeZoneSettingsValueForEachChart = ChartInfo.functions.getTimeZoneSettings($($this).attr("data-num"));
		if (TimeZoneSettingsValueForEachChart == undefined) {
			if (TimeZoneSettingsValueForstoryboard == "localtimezone") {
				timeZone = jstz.determine().name();
				thisstate.timeZone = timeZone;
				gridmodel.set("timeZone", jstz.determine().name());
			} else {
				thisstate.timeZone = undefined;
			}
		} else {
			if (TimeZoneSettingsValueForEachChart == "localtimezone") {
				timeZone = jstz.determine().name();
				thisstate.timeZone = timeZone;
				gridmodel.set("timeZone", jstz.determine().name());
			} else {
				thisstate.timeZone = undefined;
			}
		}
		var hasCustomAggregatedField = false;
		if (chartDobyColumns != undefined && chartDobyColumns != null && chartDobyColumns.length > 0) {
			_.each(chartDobyColumns, function (names) {
				if (names.datatype == "customaggregatedfield") {
					hasCustomAggregatedField = true;
					return
				}
			});
		}
		var that = this;
		if (hasCustomAggregatedField == false) {
			gridmodel.set("timeZone", timeZone);
			gridmodel.set("isImportedReport", thisstate.isImportedReport);
			var tableInfor = {
				paginationdiv: false
			};
			var bootObj = {};
			bootObj["currentChartNumber"] = currentChartNumber;
			bootObj["gridmodel"] = gridmodel;
			bootObj["tableInfo"] = tableInfor;
			bootObj["pos"] = undefined;
			bootObj["popupwindow"] = undefined;
			bootObj["reporttype"] = undefined;
			bootObj["target"] = undefined;
			bootObj["isFromPagenation"] = undefined;
			var bootStrapTable = new BootStrapTable(bootObj);
			bootStrapTable.render();
			//	ChartUtils.drawBootstrapTable(currentChartNumber, gridmodel,tableInfor,undefined,undefined);
		}
		$(".showtrending[data-num=" + currentChartNumber + "]").hide()
		var drillDownFilter = data["drillDownFilter"];
		$(".breadcrumb[data-num=" + currentChartNumber + "]").remove();
		$("#chart" + currentChartNumber).before("<ul class='breadcrumb' data-num=" + currentChartNumber + "></ul>");
		if (drillDownFilter != undefined) {
			var breadCrumbElement = $('.breadcrumb[data-num=' + currentChartNumber + ']')
			var that = this;
			_.each(drillDownFilter, function (value, index) {
				if (value["saved"] == "true") {
					var appendValue = value.data;
					var ulEle = "";
					var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
					if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
						appendValue = breadCrumpElements["span"];
						ulEle = breadCrumpElements["ulelement"];
					}
					try {
						value.columnname = decodeURI(value.columnname)
					} catch (e) {
					}
					breadCrumbElement.append("<li class=drilldown saved >" + value.columnname + " [" + appendValue + "] <a columnname=" + value.columnname + "  name='" + value.data + "' class='close removedrilldownsinglereport' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'><i/></a>" + ulEle + "</li>");
					$('.removedrilldownsinglereport').click(function (e) {
						PubSub.publish('removeDrillDown', e);
					})
				}
			});
		}
	}
	togglevisualizationsettings(e) {
		var currentChartNumber = $(e.currentTarget).attr("data-num");
		this.state.chartnumber = currentChartNumber;
		$('#spinner'+currentChartNumber).show();
		$('.tileview').removeClass('.highlight');
		$("#tileview"+currentChartNumber).addClass("highlight");
		PubSub.publish('togglevisualizationsettings', e.currentTarget);
		// document.getElementById(`tileview${this.state.chartnumber}`).classList.add("highlight");
	}
	viewPivotGrid = (e) => {
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		$(".chartadvancedoptions[data-num='chart" + currentChartNumber + "']").hide();
		ChartInfo.functions.setSummaryTableDateHistogramStatus(currentChartNumber, false);
		$('.trend-stats-container').remove();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		$(".summarytablemessage[data-num=" + currentChartNumber + "]").remove();
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.max-data-buttons').hide();
		$(".chartchanger[data-num='" + currentChartNumber + "']").hide();
		$(".maxanim1[data-num=" + currentChartNumber + "] #dropdownmorechart").removeClass("disabled");
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.showtrending').hide()
		$(".summarymodal[data-num='" + currentChartNumber + "']").addClass('hide');
		$(".clonetile[data-num=" + currentChartNumber + "]").removeClass("disabled");
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.viewchartdata').removeClass("active");
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.viewchartfromchartdata').addClass("active");
		$('#spinner' + currentChartNumber).show();
		var currentSelection = $($this).find('.maxscreen-lft-filtertitle').text();
		if (currentSelection == 'Pivot') {
			$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + ']').removeClass("showactive");
			$('.boc-maxscreen-hamburger[data-num=' + currentChartNumber + ']').removeClass("showactive");
			$($this).addClass("showactive");
			$(".pivotsettingsmain" + currentChartNumber).show();
			var chartData = ChartInfo.functions.getChartDetails(currentChartNumber);
			var data = JSON.parse(chartData);
			if (data["chartType"] != "pivot" && data["chartType"] != "summarytable" && data["chartType"] == "predictionchart") {
				if (data["chartType"] != "pivot" && data["chartType"] != "summarytable" && data["chartType"] != "doby")
					data["oldcharttype"] = data["chartType"];
			}
			data["chartType"] = "pivot";
			ChartInfo.functions.updateChartDetails(currentChartNumber, JSON.stringify(data));
			$(".dobypagination").hide();
		}
		this.viewPivotTable(currentChartNumber, viewcollection.getMeasures(), viewcollection.getDimensions(), "max");
	}
	viewPivotTable(currentChartNumber, measures, dimensions, pos, applyattributes) {
		if (currentChartNumber == this.props.chartnumber) {
			$('.paginationdiv' + currentChartNumber).hide();
			$('.richtextcontrolschart' + this.props.chartnumber).hide();
			$('.summarymodal[data-num=' + currentChartNumber + ']').addClass('hide');
			$('.breadcrumb[data-num=' + currentChartNumber + ']').remove();
			$('.btn-toolbar').hide();
			$(".predictmodelsummary" + currentChartNumber).addClass("hide");
			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
			var that = this;
			if (chartDetails["chartType"] != 'pivot') {
				this.redrawChart(chartDetails, that.pos, undefined, undefined, undefined, applyattributes);
			} else {
				var pivotview = new PivotView({
					chartNumber: that.props.chartnumber,
					measures: measures,
					dimensions: dimensions,
					reportId: that.props.reportId,
					datamodelId: that.props.datamodelId,
					filterArray: JSON.stringify(that.state.pivotfilterArrays || []),
					pos: that.state.pos != undefined ? that.state.pos : "max",
					features: that.state.features,
					entities: that.state.entities,
					timeZone: that.state.timeZone,
					isPurgedReport: that.state.isPurgedReport,
					isPublished: that.state.isPublished,
					isImportedReport: that.state.isImportedReport,
					applyattributes: applyattributes,
					multifact: that.state.multifact,
					facttables: that.props.facttables,
					multifactentities: that.state.multifactentities
				});
			}
		}
	}
	pivotcolumnmodal = (e) => {
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		PubSub.publish("showPivotModal", currentChartNumber);
		$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + '].previewChart').removeClass("showactive");
		$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + '].pivotgrid').addClass("showactive");
		$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + '].summarytable').removeClass("showactive");
		$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + '].dobygrid').removeClass("showactive");
	}
	showpivotchart(e) {
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		var legendfilter = [];
		if ($('.charttype').hasClass('active')) {
			$('.charttype').removeClass('active');
		}
		$($this).parent().addClass('active');
		ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
		ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
		sessionStorage.removeItem("drilldown" + currentChartNumber);
		var charType = $($this).attr("data-type");
		$('.pivotcharttypes').attr('data-type', charType);
		if (charType === 'pivot') { $("#chart" + currentChartNumber).css("height", "100%") }
		var obj = {};
		obj["chartNumber"] = currentChartNumber;
		obj["chartType"] = charType;
		PubSub.publish("showPivotchart", obj);
	}
	loadPivotRows(e) {
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		PubSub.publish("loadPivotRows", currentChartNumber);
	}
	loadPrevious(e) {
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		PubSub.publish("showprevious", currentChartNumber);
	}
	loadNext(e) {
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		PubSub.publish("shownext", currentChartNumber);
	}
	pivotchartsettingsicon(e) {
		e.stopPropagation();
		e.preventDefault();
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		PubSub.publish("showPivotChartSettingsModal", currentChartNumber);
	}
	trendtypeSet = (e) => {
		$(".spinner").show();
		if ($(e.target).is("input")) {
			$(".spinner").hide();
			return false;
		}
		var that = this;
		var $this = e.currentTarget;
		var currentCnartNum = $($this).attr("data-num");
		$(".maxanim1[data-num=" + currentCnartNum + "] .trend-stats-container").remove();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		var currentChartNumber = $($this).attr("data-num");
		$('#spinner' + currentChartNumber).show();
		var calculationName;
		var trendtype = $($this).attr("id");
		var activetrend = $('.trendtype[data-num=' + currentChartNumber + '].showactive').attr("id");
		if (trendtype != activetrend) {
			calculationName = $($this).attr('id');
			$('.trendtype[data-num=' + currentChartNumber + ']').removeClass("showactive");
			$($this).addClass('showactive');
			$('.showtrending[data-num=' + currentChartNumber + ']').addClass('active');
		} else {
			calculationName = null;
			$('.trendtype[data-num=' + currentChartNumber + ']').removeClass("showactive");
			$('.showtrending[data-num=' + currentChartNumber + ']').removeClass('active');
		}
		var data = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber));
		if (trendtype == 'SMA') {
			var periodsvalue = parseInt($('.smaperiods[data-num=' + currentChartNumber + ']').val());
		}
		else if (trendtype == 'EMA') {
			var periodsvalue = parseInt($('.emaperiods[data-num=' + currentChartNumber + ']').val());
		}
		data["trendLine"] = trendtype;
		data["periodsvalue"] = periodsvalue;
		ChartInfo.functions.setTrendType(calculationName);
		ChartInfo.functions.setdrillfitlerArray([]);
		ChartInfo.functions.updateChartDetails(currentChartNumber, JSON.stringify(data));
		if ($($this).attr('id') == "rsum" || $($this).attr('id') == "rmax" || $($this).attr('id') == "rmin" || $($this).attr('id') == "ravg" || $($this).attr('id') == "rdiff" || $($this).attr('id') == "rpercentage" || $($this).attr('id') == "trendstats") {
			ChartInfo.functions.getFilters(true);
			that.applyfiltesandredrawchart(undefined, currentChartNumber, "", undefined, calculationName, "yes");
		}
		else {
			that.trendline($this);
		}
		sessionStorage.setItem("changesdone", 1);
		if (ChartInfo.functions.getDrillDownChartNumber() != undefined && ChartInfo.functions.getDrillDownChartNumber() == currentChartNumber) {
			var legendfilter = [];
			ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
			ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
			$(".pie-menu").remove();
			$(".fullscreensample").removeClass("nopie");
			$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
			$('.breadcrumb[data-num=' + currentChartNumber + ']').remove();
			var drilldown = JSON.parse(sessionStorage.getItem("drilldown" + currentChartNumber));
			if (drilldown != null) {
				drilldown = $.grep(drilldown, function (value) { return value.isDrillDownAll; });
			}
			sessionStorage.removeItem("drilldown" + currentChartNumber);
			if (drilldown != undefined && drilldown.length != 0) {
				var that = this;
				_.each($(".maxanim1[data-num!=" + currentChartNumber + "]"), function (i) {
					var $this = i
					var chartnumber = $($this).attr("data-num");
					that.applyfiltesandredrawchart(undefined, chartnumber, "applyattributes", undefined, undefined, undefined, undefined, undefined, [], "drilldownview");
				});
			}
			$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
			var drillDownAllFilter = data['drillDownAllFilter'];
			_.each(drillDownAllFilter, function (i, j) {
				// if(drillDownAllFilter[j]['save'])
				var name = decodeURIComponent(drillDownAllFilter[j].columnname);
				if (name === null) {
					var name = decodeURIComponent(drillDownAllFilter[j].name);
				}
				if (drillDownAllFilter[j].type == "date") {
					name = $(name).find('li.drilldown:first').attr('column-display-name');
					if (name === undefined) {
						name = drillDownAllFilter[j].columnname;
					}
				}
				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
				ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
				$("#" + dynamicdiv).remove();
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
			});
			$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>")
			$('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) });
			$('.breadcrumb[data-num=' + currentChartNumber + ']').remove();
			$("#chart" + currentChartNumber + "").before("<ul class='breadcrumb' data-num=" + currentChartNumber + "></ul>");
			if (currentChartNumber != undefined) {
				if (data["drillDownFilter"] != undefined) {
					var breadCrumbElement = $('.breadcrumb[data-num=' + currentChartNumber + ']')
					_.each(data["drillDownFilter"], function (value, index) {
						var appendValue = value.data;
						var ulEle = "";
						var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
						if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
							appendValue = breadCrumpElements["span"];
							ulEle = breadCrumpElements["ulelement"];
						}
						if (value["saved"] == "true") {
							breadCrumbElement.append("<li class= drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name=" + value.data + " class='close removedrilldown' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
						}
					});
				}
			}
		} else {
			var drillDownFilter = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber)).drillDownFilter;
			var that = this;
			if (drillDownFilter != undefined && drillDownFilter != null) {
				$(".quickfiltercontainer[data-num=" + currentChartNumber + "][tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
				$(".quickfiltercontainer[data-num=" + currentChartNumber + "][tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append($(".header-quickfiltercontainer").html());
				$(".breadcrumb[data-num=" + currentChartNumber + "]").remove();
				$("#chart" + currentChartNumber + "").before("<ul class='breadcrumb' data-num=" + currentChartNumber + "></ul>");
				var breadCrumbElement = $('.breadcrumb[data-num=' + currentChartNumber + ']')
				_.each(drillDownFilter, function (value, index) {
					if (value.isDrillDownAll != true) {
						var name = "";
						if (value.columndisplayname != undefined) {
							name = value.columndisplayname;
						}
						else {
							name = value.displayName;
						}
						var appendValue = value.data;
						var ulEle = "";
						var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
						if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
							appendValue = breadCrumpElements["span"];
							ulEle = breadCrumpElements["ulelement"];
						}
						breadCrumbElement.append("<li class=drilldown saved>" + name + " [" + appendValue + "] <a  columnname='" + value.columnname + "' name='" + value.data + "' class='close removedrilldown' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
					}
				});
			}
		}
		$(".spinner").hide();
	}
	trendline(eventTarget, filter) {
		var $this = eventTarget;
		var currentChartNumber = $($this).attr("data-num");
		var trendtype = $($this).attr("id");
		if (currentChartNumber == this.props.chartnumber) {
			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber));
			this.applyfiltesandredrawchart(undefined, currentChartNumber, undefined, null, trendtype, undefined, "no", undefined, undefined, filter)
			$(".spinner").show();
		}

	}
	isCustomFiledExists(customFields, currentColumnName) {
		for (var i = 0; i < customFields.length; i++) {
			if (customFields[i].name == currentColumnName) {
				return false;
			}
		};
		return true;
	}
	findSameFilterisExists(filterArray, filtervalue) {
		for (var i = 0; i < filterArray.length; i++) {
			if (JSON.stringify(filterArray[i]) == JSON.stringify(filtervalue)) {
				return true;
			}
		}
		return false;
	}
	seriescolors = (e) => {
		e.preventDefault();
		var $this = e.currentTarget;
		var chartnum = $($this).attr("data-num").replace("chart", "");
		//$($this).parent().parent().toggleClass("show");
		var that = this;
		var defaultseriesnames = ChartInfo.functions.getDefaultSeriesDetails(chartnum);
		var seriesColorsModalView = new SeriesColorsModalView({
			chartnumber: chartnum,
			defaultserieslist: defaultseriesnames
		});
	}
	viewSummaryTabeEve(msg, data) {
		if (data != undefined) {
			if(this.state.chartnumber === data.currentChartNumber){
			this.viewSummaryTabe(data.currentChartNumber, data.measures, data.dimensions, data.pos, data.chartDetails, data.isExcludeorInclude, data.isDateHistogram, data.dateHistomgramColName, data.applyattributes);
			}
		}else{
			this.viewSummaryTabe();
		}
	}
	viewSummaryTabe = (currentChartNumber, measures, dimensions, pos, chartDetails, isExcludeorInclude, isDateHistogram, dateHistomgramColName, applyattributes) => {
		var that = this;
		if (chartDetails != undefined && chartDetails['summaryTableDateHistogram'] != undefined && dateHistomgramColName == undefined) {
			dateHistomgramColName = chartDetails['summaryTableDateHistogram'];
			var columnsInfo = chartDetails['getsummarytableaxisfieldsarray'][0]["textcolumns"];
			_.each(columnsInfo, function (val, index) {
				var column = val["columnName"];
				if (column == dateHistomgramColName["dateHistogramColName"] && dateHistomgramColName['histogramInterval'] != "default") {
					isDateHistogram = "true";
				}
			})
		}
		if (currentChartNumber == this.props.chartnumber) {
			var mainFilters = JSON.parse(JSON.stringify(this.state.pivotfilterArrays || []));
			var filterArray = JSON.parse(JSON.stringify(this.state.pivotfilterArrays || []));
			$(".predictmodelsummary" + this.props.chartnumber).addClass("hide");
			$('.breadcrumb[data-num=' + currentChartNumber + ']').remove();
			$(".pivotsettingsmain" + this.props.chartnumber).hide();
			$(".pivotcharticon" + this.props.chartnumber).hide();
			$(".pivotnavigationbuttons" + this.props.chartnumber).hide();
			$('.paginationdiv' + this.props.chartnumber).hide();
			$("#chart" + that.props.chartnumber).before('<ul class="breadcrumb" data-num=' + currentChartNumber + '></ul>');
			var drilldownon = "";
			var appendbreadcrumb = false;
			var drilldownname = "Home";
			var drilldowndisplayname = "";
			var drilldownDataType = "";
			this.levelmapping = {};
			var dateHistogramFound = false;
			_.each(chartDetails["summarytablecolumns"], function (xAxisObject, i) {
				that.levelmapping[xAxisObject.columnName] = 0;
				if (dateHistomgramColName != undefined && dateHistomgramColName["dateHistogramColName"] != undefined && dateHistomgramColName["histogramInterval"] != undefined &&
					dateHistomgramColName["histogramInterval"] != "default" && dateHistomgramColName["dateHistogramColName"] == xAxisObject.columnName) {
					dateHistogramFound = true;
				}
			});
			if (chartDetails["summarytablegroupbys"] != undefined) {
				that.levelmapping[chartDetails["summarytablegroupbys"].columnName] = 0;
				if (dateHistomgramColName != undefined && dateHistomgramColName["dateHistogramColName"] != undefined && dateHistomgramColName["histogramInterval"] != undefined &&
					dateHistomgramColName["histogramInterval"] != "default" && dateHistomgramColName["dateHistogramColName"] == chartDetails["summarytablegroupbys"].columnName) {
					dateHistogramFound = true;
				}
			}
			if (dateHistogramFound) {
				isDateHistogram = "true";
			} else {
				isDateHistogram = "false";
			}
			if (chartDetails["getsummarytableaxisfieldsarray"] == undefined || sessionStorage.getItem("customhierarchy" + currentChartNumber) == undefined) {

				var summarytablefiltervalues = ChartInfo.functions.setTempSummaryTableFilterValues([], currentChartNumber);
				var filter = this.pivotfilterArrays != undefined ? that.pivotfilterArrays : [];
				var jsonArr = [];
				var jsonarray = [];
				jsonArr.push(that.levelmapping);
				jsonArr.push([]);
				jsonarray.push(jsonArr);
				if (chartDetails["summarytablecolumns"] != undefined) {
					sessionStorage.removeItem("customhierarchy" + currentChartNumber);
					sessionStorage.setItem("customhierarchy" + currentChartNumber, JSON.stringify(jsonarray));
				}
			} else {

				var string = sessionStorage.getItem("customhierarchy" + currentChartNumber);
				if (string != undefined && string != "undefined" && string != null && string != "null"
					&& string.length > 0) {
					appendbreadcrumb = true;
					drilldownname = chartDetails["summarytabledrilldownname"];
					drilldowndisplayname = chartDetails["summarytabledrilldowndisplayname"];
					drilldownDataType = chartDetails["summarytabledrilldowndatatype"];
					if (JSON.parse(string).length > 0) {
						if (that.levelmapping != undefined && JSON.stringify(that.levelmapping) != JSON.stringify(JSON.parse(string)[0][0])) {
							var jsonArr = [];
							var jsonarray = [];
							jsonArr.push(that.levelmapping);
							jsonArr.push([]);
							jsonarray.push(jsonArr);
							if (chartDetails["summarytablecolumns"] != undefined) {
								sessionStorage.removeItem("customhierarchy" + currentChartNumber);
								sessionStorage.setItem("customhierarchy" + currentChartNumber, JSON.stringify(jsonarray));
							}
						}
					}

				}
			}
			var tempsummarytabledetails = [];
			var temparray = {};
			temparray["group"] = chartDetails["summarytablegroupbys"];
			temparray["textcolumns"] = chartDetails["summarytablecolumns"];
			tempsummarytabledetails.push(temparray);
			ChartInfo.functions.setSummarytableAxisFieldsArray(tempsummarytabledetails, currentChartNumber);
			filterArray = mainFilters.concat(ChartInfo.functions.getTempSummaryTableFilterValues(currentChartNumber) || []);

			var summarytableDrilldownValues = ChartInfo.functions.getTempSummaryTableFilterValues(currentChartNumber) || [];
			summarytableDrilldownValues = summarytableDrilldownValues.concat(JSON.parse(JSON.stringify(this.state.dobyfilterArray || [])));
			$('.summarymodal[data-num=' + currentChartNumber + ']').removeClass('hide');
			//$("#chart" + currentChartNumber).empty();
			ReactDOM.unmountComponentAtNode(document.getElementById(`chart${currentChartNumber}`))
			ReactDOM.render(
				<SummaryTableView
					chartNumber={currentChartNumber}
					measures={measures}
					dimensions={dimensions}
					reportId={this.props.reportId}
					datamodelId={this.props.datamodelId}
					filterArray={JSON.stringify(summarytableDrilldownValues)}
					viewsummarytabe={this.viewSummaryTabe}
					pos={pos}
					features={that.state.features}
					chartDetails={chartDetails}
					indexName={that.props.indexname}
					drilldownlevel={0}
					drilldownName={drilldownname}
					levelMapping={that.levelmapping}
					appendbreadcrumb={appendbreadcrumb}
					isExcludeorInclude={isExcludeorInclude}
					entities={that.entities}
					isDateHistogram={isDateHistogram}
					dateHistomgramColName={dateHistomgramColName}
					timeZone={that.state.timeZone}
					isPurgedReport={that.state.isPurgedReport}
					isPublished={that.state.isPublished}
					isImportedReport={that.state.isImportedReport}
					drilldowndisplayName={drilldowndisplayname}
					applyattributes={applyattributes}
					drilldownDataType={drilldownDataType}
					multifact={that.state.multifact}
					multifactentities={that.state.multifactentities}
					facttables={that.state.facttables}
				/>,
				document.getElementById(`chart${currentChartNumber}`)
			)
		}
	}
	viewSummaryTable = (e) => {
		$("#pivotcolumns").hide();
		var $this = e.currentTarget;
		$('.trend-stats-container').remove();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		$(".append_summarytablemodal").empty();
		var currentChartNumber = $($this).attr("data-num");
		$(".chartadvancedoptions[data-num='chart" + currentChartNumber + "']").hide();
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.max-data-buttons').hide();
		$(".chartchanger[data-num='" + currentChartNumber + "']").hide();
		$(".maxanim1[data-num=" + currentChartNumber + "] #dropdownmorechart").removeClass("disabled");
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.showtrending').hide()
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.viewchartdata').removeClass("active");
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.viewchartfromchartdata').addClass("active");
		if ($('.doby-offcanvas-columnlist').hasClass("show")) {
			$('.doby-offcanvas-columnlist').toggleClass("show");
		}
		var legendfilter = [];
		ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
		ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
		sessionStorage.removeItem("drilldown" + currentChartNumber);
		var currentSelection = $($this).find('.maxscreen-lft-filtertitle').text();

		if (currentSelection == 'Summary Table') {
			$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + ']').removeClass("showactive");
			$('.boc-maxscreen-hamburger[data-num=' + currentChartNumber + ']').removeClass("showactive");
			$($this).addClass("showactive");
			var chartData = ChartInfo.functions.getChartDetails(currentChartNumber);
			var data = JSON.parse(chartData);
			if (data["chartType"] != "pivot" && data["chartType"] != "summarytable" && data["chartType"] != "predictionchart")
				if (data["chartType"] != "pivot" && data["chartType"] != "summarytable" && data["chartType"] != "doby")
					data["oldcharttype"] = data["chartType"];
			data["chartType"] = "summarytable";
			ChartInfo.functions.updateChartDetails(currentChartNumber, JSON.stringify(data));
		}
		var currentChartNumber = $($this).attr("data-num");
		$('#spinner' + currentChartNumber).show();
		if ($($this).find(".fa").hasClass("fa-bar-chart")) {
			var storedValue = ChartInfo.functions.getChartDetails(currentChartNumber);
			var storedObect = JSON.parse(storedValue);
			var chartType = storedObect["chartType"];
		}
		this.viewSummaryTabe(currentChartNumber, viewcollection.getMeasures(), viewcollection.getDimensions(), "", data);
	}
	viewDataTableForSummaryTableEve(msg, data) {
		if (data != undefined) {
			this.viewDataTableForSummaryTable(data.chartnumber, data.selectedcolumn, data.popupwindow, data.$this, data.selectedname);
		} else {
			this.viewDataTableForSummaryTable();
		}
	}
	viewDataTableForSummaryTable(chartnumber, selectedcolumn, popupwindow, $this, selectedname) {
		var drilldownvalue = "";
		var selectedcolumns = [];
		if (selectedcolumn != undefined) {
			selectedcolumns = selectedcolumn;
		}
		if (chartnumber == this.state.chartnumber) {
			var responseCount = 0;
			var gridmodel = new FormData();
			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.state.chartnumber));
			var rowLimit = chartDetails["dobyrowlimit"];
			//var viewReportDataTableCollection=new ViewReportDataTableCollection(gridmodel); getDataTable
			if (sessionStorage.getItem('customhierarchy' + this.state.chartnumber) != undefined && sessionStorage.getItem('customhierarchy' + this.state.chartnumber).length != 0
				&& sessionStorage.getItem('customhierarchy' + this.state.chartnumber) !== "[]") {
				var sessionValue = JSON.parse(sessionStorage.getItem('customhierarchy' + this.state.chartnumber));

				this.levelmapping = JSON.parse(JSON.stringify(sessionValue[sessionValue.length - 1][0]));
			}
			var customFields = sessionStorage.getItem("customFields");
			var tempCF = JSON.parse(customFields);
			var res;
			var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(this.props.applyattributes);
			_.each(tempCF, function (customfields) {
				_.each(customfields.parametrFieldsArray, function (value) {
					var customparameter = ChartInfo.functions.getCustomParameter();
					if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
						customparameter = customParameterFilterArray;
					}
					res = $.grep(customparameter, function (e) {
						return e.parametername == value;
					})
					var formula = customfields.formula;
					var scriptcondition = customfields.scriptcondition;
					var fields = customfields.fields;
					var sqlformula = customfields.sqlformula;
					if (res.length != 0) {
						customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
						customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
						customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
						customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
					} else {
						_.each(ChartInfo.functions.getCustomParameter(), function (values) {
							if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
								customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
								customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
								customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
								customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							}
						});
					}
				});
			});
			customFields = JSON.stringify(tempCF);

			var selectedcustomfields = [];
			if (customFields != undefined && customFields != null && customFields != "null") {
				var temp = JSON.parse(customFields);
				_.each(selectedcolumns, function (value) {
					var getcustomfield = $.grep(temp, function (e) { return e.name == value.actualnames });
					if (getcustomfield.length > 0) {
						selectedcustomfields.push(getcustomfield[0]);
					}
				});
				_.each(this.state.dobyfilterArray, function (filtercolumn) {
					var getcustomfield = $.grep(temp, function (e) { return e.name == filtercolumn.columnname });
					if (getcustomfield.length > 0) {
						selectedcustomfields.push(getcustomfield[0]);
					}
				});
			}
			var summarytabledetails = ChartInfo.functions.getSummarytableAxisFieldsArray(chartnumber);
			var that = this;
			if (summarytabledetails != undefined && summarytabledetails.length > 0) {
				var details = summarytabledetails[summarytabledetails.length - 1];
				if (details["group"] != undefined) {
					if (temp != undefined) {
						var getcustomfield = $.grep(temp, function (e) {
							return e.name == details["group"].columnName;
						});
					}
					if (getcustomfield != undefined && getcustomfield.length > 0) {
						selectedcustomfields.push(getcustomfield[0]);
					} else {
						var tempVal = {};
						if (details["group"].dataType != undefined && details["group"].dataType == "customhierarchy") {
							var customhierarchy = ChartInfo.functions.getcustomhierarchy();
							var summaryTableCustomHierarchyDrilldownName = ChartInfo.functions.getSummaryTableCustomHierarchyDrilldownName();
							if (summaryTableCustomHierarchyDrilldownName != undefined) {
								_.each(customhierarchy, function (val1) {
									if (val1.name == details["group"].columnName) {
										if (temp != undefined) {
											var getcustomfield = $.grep(temp, function (e) {
												return e.name == val1.hierarchelist[that.levelmapping[details["group"].columnName]].columnName;
											});
										}
										if (getcustomfield != undefined && getcustomfield.length > 0) {
											selectedcustomfields.push(getcustomfield[0]);
										} else {
											tempVal["actualnames"] = val1.hierarchelist[that.levelmapping[details["group"].columnName]].columnName;
											tempVal["displaynames"] = val1.hierarchelist[that.levelmapping[details["group"].columnName]].columnDisplayName;
											tempVal["datatype"] = val1.hierarchelist[that.levelmapping[details["group"].columnName]].dataType;
											selectedcolumns.push(tempVal);
										}
									}
								});
							}
						}
						else {
							tempVal["actualnames"] = details["group"].columnName;
							tempVal["displaynames"] = details["group"].displayName;
							tempVal["datatype"] = details["group"].dataType;
							selectedcolumns.push(tempVal);
						}

					}
				}

				if (that.state.multifact == "true" && selectedname != undefined) {
					/*_.each(selectedcustomfields,function(value){
						if(value.name==selectedname){
							selectedname=value.usedFields[0]
						}
					});*/

					if (tempCF != undefined && tempCF.length > 0) {
						var yaxisCustomFieldCheck;
						_.each(tempCF, function (i, j) {
							console.log(selectedname + "" + i.name);
							if (selectedname == i.name) {
								if (i.usedFields.length > 1) {
									for (let k = 0; k < i.usedFields.length; k++) {
										for (let l = 0; l < that.state.facttables.length; l++) {
											if (i.usedFields[k].split(".")[0] == that.state.facttables[l]) {
												yaxisCustomFieldCheck = i.usedFields[k];
												selectedname = yaxisCustomFieldCheck;
											}
										}
									}
								} else {
									yaxisCustomFieldCheck = i.usedFields[0];
									selectedname = yaxisCustomFieldCheck;
								}
							}
						});
					}
					var selectedFeildName;
					_.each(viewcollection.getDimensions(), function (dimension) {
						if (selectedname == dimension.columnDisplayName) {
							selectedFeildName = dimension.columnName;
						}
					});
					if (selectedFeildName != undefined) {
						selectedcolumns = [];
						var i = 0;
						var columnFound = false;
						_.each(viewcollection.getDimensions(), function (dimension) {
							var dimensionTableName = dimension.columnName.split(".")[0];
							var selectedTablename = selectedFeildName.split(".")[0];
							var displayName = dimension.columnDisplayName;
							if (dimensionTableName == selectedTablename) {
								i++;
								if (i <= 10) {
									var tempVal = {};
									tempVal["actualnames"] = dimension.columnName;
									tempVal["displaynames"] = dimension.columnDisplayName;
									tempVal["datatype"] = dimension.tableDisplayType;
									selectedcolumns.push(tempVal);
									if (selectedname == displayName) {
										columnFound = true;
									}
								}
								else if (!columnFound && selectedname == displayName) {
									var tempVal = {};
									tempVal["actualnames"] = dimension.columnName;
									tempVal["displaynames"] = dimension.columnDisplayName;
									tempVal["datatype"] = dimension.tableDisplayType;
									selectedcolumns.push(tempVal);
									if (selectedname == displayName) {
										columnFound = true;
									}
								}
							}

						});
					}
				}

				if (details["textcolumns"].length > 0) {
					_.each(details["textcolumns"], function (value, i) {
						if (temp != undefined) {
							var getcustomfield = $.grep(temp, function (e) {
								return e.name == details["textcolumns"][i].columnName;
							});
						}
						if (getcustomfield != undefined && getcustomfield.length > 0) {
							$.each(getcustomfield, function (index, custom) {
								for (let i = 0; i < custom.usedFields.length; i++) {
									var object = {
										"actualnames": custom.usedFields[i],
										"displaynames": getcustomfield[index].name,
										"datatype": getcustomfield[index].dataType
									};
									selectedcolumns.push(object);
								}
							});
							selectedcustomfields.push(getcustomfield[0]);
						} else {
							var tempVal = {};
							if (details["textcolumns"][i].dataType != undefined && details["textcolumns"][i].dataType == "customhierarchy") {
								var customhierarchy = ChartInfo.functions.getcustomhierarchy();
								var summaryTableCustomHierarchyDrilldownName = ChartInfo.functions.getSummaryTableCustomHierarchyDrilldownName();
								if (summaryTableCustomHierarchyDrilldownName != undefined) {
									_.each(customhierarchy, function (val1) {
										if (val1.name == details["textcolumns"][i].columnName) {
											if (temp != undefined) {
												var getcustomfield = $.grep(temp, function (e) {
													return e.name == val1.hierarchelist[that.levelmapping[details["textcolumns"][i].columnName]].columnName;
												});
											}
											if (getcustomfield != undefined && getcustomfield.length > 0) {
												selectedcustomfields.push(getcustomfield[0]);
											} else {
												tempVal["actualnames"] = val1.hierarchelist[that.levelmapping[details["textcolumns"][i].columnName]].columnName;
												tempVal["displaynames"] = val1.hierarchelist[that.levelmapping[details["textcolumns"][i].columnName]].columnDisplayName;
												tempVal["datatype"] = val1.hierarchelist[that.levelmapping[details["textcolumns"][i].columnName]].dataType;
												selectedcolumns.push(tempVal);
											}
										}
									});
								}
							} else {
								tempVal["actualnames"] = details["textcolumns"][i].columnName;
								tempVal["displaynames"] = details["textcolumns"][i].displayName;
								tempVal["datatype"] = details["textcolumns"][i].dataType;
								selectedcolumns.push(tempVal);
							}
						}
					});
				}
				if (chartDetails["summarytablevalues"].length > 0) {
					if (selectedFeildName != undefined) {
						var selectedtblname = selectedFeildName.split(".")[0];
					} else if (selectedname != undefined) {
						var selectedtblname = selectedname.split(".")[0];
					}
					_.each(chartDetails["summarytablevalues"], function (value, i) {
						if (temp != undefined) {
							var getcustomfield = $.grep(temp, function (e) {
								return e.name == chartDetails["summarytablevalues"][i].columnName;
							});
						}
						if (getcustomfield != undefined && getcustomfield.length > 0) {
							//var selectedtblname=selectedname.split(".")[0];

							var isCustomMeasureField = false;
							for (let c = 0; c < chartDetails["summarytablevalues"].length; c++) {
								if (selectedtblname == chartDetails["summarytablevalues"][c].columnName) {
									if (chartDetails["summarytablevalues"][c].aggregation == "customaggregatedfield") {
										isCustomMeasureField = true;
									}
								}
							}
							$.each(getcustomfield, function (index, custom) {
								for (let i = 0; i < custom.usedFields.length; i++) {
									var object = {
										"actualnames": custom.usedFields[i],
										"displaynames": getcustomfield[index].name,
										"datatype": getcustomfield[index].dataType
									};
									if (that.state.multifact == "true") {
										if (that.state.facttables.indexOf(custom.usedFields[i].split(".")[0]) != -1 && that.state.facttables.indexOf(selectedtblname) != -1 && selectedtblname == custom.usedFields[i].split(".")[0]) {
											selectedcolumns.push(object);
										} else if (that.state.facttables.indexOf(custom.usedFields[i].split(".")[0]) != -1 && that.state.facttables.indexOf(selectedtblname) == -1 && isCustomMeasureField != true) {
											selectedcolumns.push(object);
										}
									}
								}
							});
							selectedcustomfields.push(getcustomfield[0]);
						} else {
							var tempVal = {};
							if (chartDetails["summarytablevalues"][i].dataType != undefined && chartDetails["summarytablevalues"][i].dataType == "customhierarchy") {
								var customhierarchy = ChartInfo.functions.getcustomhierarchy();
								var summaryTableCustomHierarchyDrilldownName = ChartInfo.functions.getSummaryTableCustomHierarchyDrilldownName();
								if (summaryTableCustomHierarchyDrilldownName != undefined) {
									_.each(customhierarchy, function (val1) {
										if (val1.name == chartDetails["summarytablevalues"][i].columnName) {
											if (temp != undefined) {
												var getcustomfield = $.grep(temp, function (e) {
													return e.name == val1.hierarchelist[that.levelmapping[value.columnName]].columnName;
												});
											}
											if (getcustomfield != undefined && getcustomfield.length > 0) {
												selectedcustomfields.push(getcustomfield[0]);
											} else {
												tempVal["actualnames"] = val1.hierarchelist[that.levelmapping[value.columnName]].columnName;
												tempVal["displaynames"] = val1.hierarchelist[that.levelmapping[value.columnName]].columnDisplayName;
												tempVal["datatype"] = val1.hierarchelist[that.levelmapping[value.columnName]].dataType;
												selectedcolumns.push(tempVal);
											}
										}
									});
								}
							} else {
								if (that.state.multifact != "true") {
									tempVal["actualnames"] = chartDetails["summarytablevalues"][i].columnName;
									tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
									tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
									if (chartDetails["summarytablevalues"][i].aggregation != "customaggregatedfield") {
										selectedcolumns.push(tempVal);
									}
								} else if (that.state.multifact == "true" && chartDetails["summarytablevalues"][i].columnName.indexOf(".") != -1) {
									var tblname = chartDetails["summarytablevalues"][i].columnName.split(".")[0]
									//var selectedtblname = selectedname.split(".")[0]
									var isCustomMeasureField = false;
									for (let c = 0; c < chartDetails["summarytablevalues"].length; c++) {
										if (selectedtblname == chartDetails["summarytablevalues"][c].columnName) {
											if (chartDetails["summarytablevalues"][c].aggregation == "customaggregatedfield") {
												isCustomMeasureField = true;
											}
										}
									}
									if (that.state.facttables.indexOf(tblname) != -1 && that.state.facttables.indexOf(selectedtblname) != -1 && selectedtblname == tblname) {
										tempVal["actualnames"] = chartDetails["summarytablevalues"][i].columnName;
										tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
										tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
										if (chartDetails["summarytablevalues"][i].aggregation != "customaggregatedfield") {
											selectedcolumns.push(tempVal);
										}
									} else if (that.state.facttables.indexOf(tblname) != -1 && that.state.facttables.indexOf(selectedtblname) == -1 && isCustomMeasureField != true) {
										tempVal["actualnames"] = chartDetails["summarytablevalues"][i].columnName;
										tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
										tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
										if (chartDetails["summarytablevalues"][i].aggregation != "customaggregatedfield") {
											selectedcolumns.push(tempVal);
										}
									}
								} else if (that.state.multifact == "true" && chartDetails["summarytablevalues"][i].aggregation == "customaggregatedfield") {
									var tempCMeasures = ChartInfo.functions.getCustomMeasureField();
									var selectednameIsFact = false;
									for (let k = 0; k < tempCMeasures.length; k++) {
										if (tempCMeasures[k].name == selectedname) {
											var CMUsedFieldsArray = tempCMeasures[k].usedFields.split(",");
											for (var uf = 0; uf < CMUsedFieldsArray.length; uf++) {
												//var isCustomField=false;
												let CMeasureUserField = CMUsedFieldsArray[uf];
												if (that.state.facttables.indexOf(CMeasureUserField.split(".")[0]) != -1) {
													selectednameIsFact = true;
												}
												if (tempCF != undefined && tempCF.length > 0) {
													for (let l = 0; l < tempCF.length; l++) {
														if (tempCF[l].name == CMeasureUserField) {
															//isCustomField=true;
															for (let m = 0; m < tempCF[l].usedFields.length; m++) {
																if (that.state.facttables.indexOf(tempCF[l].usedFields[m].split(".")[0]) != -1) {
																	selectednameIsFact = true;
																}

															}
														}
													}
												}
											}
										}
									}

									for (var j = 0; j < tempCMeasures.length; j++) {
										if (chartDetails["summarytablevalues"][i].columnName == tempCMeasures[j].name && chartDetails["summarytablevalues"][i].columnName == selectedname) {
											var CMeasureUserField = tempCMeasures[j].usedFields;
											var CMeasureUserFieldsArray = CMeasureUserField.split(",");
											for (let n = 0; n < CMeasureUserFieldsArray.length; n++) {
												var cf = false;
												if (tempCF != undefined && tempCF.length > 0) {
													for (let l = 0; l < tempCF.length; l++) {
														if (tempCF[l].name == CMeasureUserFieldsArray[n]) {
															cf = true;
															for (let m = 0; m < tempCF[l].usedFields.length; m++) {
																tempVal["actualnames"] = tempCF[l].usedFields[m];
																tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
																tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
																selectedcolumns.push(tempVal);
															}
														}
													}
												}
												if (cf == false) {
													tempVal["actualnames"] = CMeasureUserFieldsArray[n];
													tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
													tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
													selectedcolumns.push(tempVal);
												}
											}
										}
										else if (chartDetails["summarytablevalues"][i].columnName == tempCMeasures[j].name) {
											var CMeasureUserField = tempCMeasures[j].usedFields;
											var CMeasureUserFieldsArray = CMeasureUserField.split(",");
											for (let n = 0; n < CMeasureUserFieldsArray.length; n++) {
												var cf = false;
												if (tempCF != undefined && tempCF.length > 0) {
													for (let l = 0; l < tempCF.length; l++) {
														if (tempCF[l].name == CMeasureUserFieldsArray[n]) {
															cf = true;
															for (let m = 0; m < tempCF[l].usedFields.length; m++) {
																if (that.state.facttables.indexOf(tempCF[l].usedFields[m].split(".")[0]) != -1 && that.state.facttables.indexOf(selectedtblname) == -1
																	&& selectednameIsFact != true) {
																	tempVal["actualnames"] = tempCF[l].usedFields[m];
																	tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
																	tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
																	selectedcolumns.push(tempVal);
																}
															}
														}
													}
												}
												if (cf = false) {
													if (that.state.facttables.indexOf(CMeasureUserFieldsArray[n].split(".")[0]) != -1 && that.state.facttables.indexOf(selectedtblname) == -1 && selectednameIsFact != true) {
														let tempVal = {};
														tempVal["actualnames"] = CMeasureUserFieldsArray[n];
														tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
														tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
														selectedcolumns.push(tempVal);
													}
												}
											}
										}
									}
								}
							}
						}
					});
				}
				if (chartDetails["summarytablegroupbys"] != undefined && chartDetails["summarytablegroupbys"] != null) {
					if (temp != undefined) {
						var getcustomfield = $.grep(temp, function (e) {
							return e.name == chartDetails["summarytablegroupbys"].columnName;
						});
					}
					if (getcustomfield != undefined && getcustomfield.length > 0) {
						$.each(getcustomfield, function (index, custom) {
							for (let i = 0; i < custom.usedFields.length; i++) {
								var object = {
									"actualnames": custom.usedFields[i],
									"displaynames": getcustomfield[index].name,
									"datatype": getcustomfield[index].dataType
								};
								selectedcolumns.push(object);
							}
						});
						selectedcustomfields.push(getcustomfield[0]);
					} else {
						var tempVal = {};
						if (chartDetails["summarytablegroupbys"].dataType != undefined && chartDetails["summarytablegroupbys"].dataType != "customhierarchy") {
							if (that.state.multifact == "true" && chartDetails["summarytablegroupbys"].columnName.indexOf(".") != -1) {
								var tblname = chartDetails["summarytablegroupbys"].columnName.split(".")[0]
								var selectedtblname = selectedname.split(".")[0]
								if (that.state.facttables.indexOf(tblname) != -1 && that.state.facttables.indexOf(selectedtblname) != -1 && selectedtblname == tblname) {
									tempVal["actualnames"] = chartDetails["summarytablegroupbys"].columnName;
									tempVal["displaynames"] = chartDetails["summarytablegroupbys"].displayName;
									tempVal["datatype"] = chartDetails["summarytablegroupbys"].dataType;
									selectedcolumns.push(tempVal);
								} else if (that.state.facttables.indexOf(tblname) != -1 && that.state.facttables.indexOf(selectedtblname) == -1) {
									tempVal["actualnames"] = chartDetails["summarytablegroupbys"].columnName;
									tempVal["displaynames"] = chartDetails["summarytablegroupbys"].displayName;
									tempVal["datatype"] = chartDetails["summarytablegroupbys"].dataType;
									selectedcolumns.push(tempVal);
								}
							}
						}
					}
				}
			}
			var summarytableDrilldownValues = ChartInfo.functions.getTempSummaryTableFilterValues(chartnumber) || [];
			var filterArray = summarytableDrilldownValues.concat(JSON.parse(JSON.stringify(this.state.dobyfilterArray || [])));
			if (details["textcolumns"].length > 0) {
				_.each(details["textcolumns"], function (value, j) {
					var filtervalues = {};
					if (details["textcolumns"][j].dataType != undefined && details["textcolumns"][j].dataType == "customhierarchy") {
						var customhierarchy = ChartInfo.functions.getcustomhierarchy();
						var summaryTableCustomHierarchyDrilldownName = ChartInfo.functions.getSummaryTableCustomHierarchyDrilldownName();
						if (summaryTableCustomHierarchyDrilldownName != undefined) {
							_.each(customhierarchy, function (val1, i) {
								if (val1.name == details["textcolumns"][j].columnName) {
									filtervalues["columnname"] = val1.hierarchelist[that.levelmapping[value.columnName]].columnName;
									filtervalues["columndisplayname"] = val1.hierarchelist[that.levelmapping[value.columnName]].columnDisplayName;
									filtervalues["dataType"] = val1.hierarchelist[that.levelmapping[value.columnName]].dataType;
									filtervalues["tablename"] = "";
									if ($($this).closest("tr.groupTR").length == 0) {
										filtervalues["data"] = $($this).closest("tr").find("td")[j].textContent.trim();
										filtervalues["datevalue"] = $($this).closest("tr").find("td")[j].textContent.trim();
										filterArray.push(filtervalues);
									}
								}
							});
						}
					}
					else {
						filtervalues["dataType"] = details["textcolumns"][j].dataType;
						filtervalues["tablename"] = "";
						filtervalues["columndisplayname"] = details["textcolumns"][j].displayName;
						filtervalues["columnname"] = details["textcolumns"][j].columnName;
						if ($($this).closest("tr.groupTR").length == 0) {
							filtervalues["data"] = $($this).closest("tr").find("td")[j].textContent.trim();
							filtervalues["datevalue"] = $($this).closest("tr").find("td")[j].textContent.trim();
							filterArray.push(filtervalues);
						}
					}

				});
			}
			if (chartDetails["summarytablegroupbys"] != undefined && chartDetails["summarytablegroupbys"] != null) {
				var groupNo = $($this).parent().attr('group-no');
				var groupName = $('.groupTR[group-no=' + groupNo + '][data-num=' + chartnumber + ']').attr('group-name');
				var Object = $.grep(viewcollection.getDimensions(), function (e) { return e.columnDisplayName == groupName.split(':')[0] });
				var customFieldObj = $.grep(temp || [], function (e) { return e.name == groupName.split(':')[0] }) || [];
				var filterObject = {};
				if (Object != undefined && Object.length > 0 && Object[0] != undefined) {
					filterObject["dataType"] = Object[0].tableDisplayType;
					filterObject["columndisplayname"] = Object[0].columnDisplayName;
					filterObject["columnname"] = Object[0].columnName;
					filterObject["data"] = groupName.split(": ")[1].trim();
					filterObject["datevalue"] = groupName.split(": ")[1].trim();
					filterArray.push(filterObject);
				} else if (customFieldObj.length > 0) {
					filterObject["dataType"] = customFieldObj[0].dataType;
					filterObject["columndisplayname"] = customFieldObj[0].name;
					filterObject["columnname"] = customFieldObj[0].name;
					filterObject["data"] = groupName.split(": ")[1].trim();
					filterObject["datevalue"] = groupName.split(": ")[1].trim();
					filterArray.push(filterObject);
				}
			}
			if (that.state.multifact == "true") {
				var selectedColumnsTblNameArray = [];
				_.each(selectedcolumns, function (i, j) {
					selectedColumnsTblNameArray.push(i.actualnames.split(".")[0]);
				});
				if (_.intersection(that.state.facttables, selectedColumnsTblNameArray).length == 0) {
					_.each(chartDetails["summarytablevalues"], function (val, i) {
						var tempCMeasures = ChartInfo.functions.getCustomMeasureField();
						for (var j = 0; j < tempCMeasures.length; j++) {
							var tempVal = {};
							if (val.columnName == tempCMeasures[j].name) {
								var CMeasureUserField = tempCMeasures[j].usedFields;
								var CMeasureUserFieldsArray = CMeasureUserField.split(",");
								for (let n = 0; n < CMeasureUserFieldsArray.length; n++) {
									var cf = false;
									if (tempCF != undefined && tempCF.length > 0) {
										for (let l = 0; l < tempCF.length; l++) {
											if (tempCF[l].name == CMeasureUserFieldsArray[n]) {
												cf = true;
												for (let m = 0; m < tempCF[l].usedFields.length; m++) {
													tempVal = {};
													if (that.facttables.indexOf(tempCF[l].usedFields[m].split(".")[0]) != -1) {
														tempVal["actualnames"] = tempCF[l].usedFields[m];
														tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
														tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
														selectedcolumns.push(tempVal);
													}
												}
											}
										}
									}
									if (cf == false) {
										if (that.state.facttables.indexOf(CMeasureUserFieldsArray[n].split(".")[0]) != -1) {
											tempVal["actualnames"] = CMeasureUserFieldsArray[n];
											tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
											tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
											selectedcolumns.push(tempVal);
										}
									}
								}
							}
						}

					});
				}
			}
			if (this.state.dobyfilterArray == undefined || this.state.dobyfilterArray.length == 0) {
				gridmodel.set("filterObject", JSON.stringify(filterArray));
			}
			else {
				gridmodel.set("filterObject", JSON.stringify(this.state.dobyfilterArray.concat(filterArray)));
			}

			gridmodel.set("datamodelId", this.state.datamodelId);
			gridmodel.set("indexName", this.indexName);
			gridmodel.set("customFields", JSON.stringify(selectedcustomfields));
			gridmodel.set("script", JSON.stringify(selectedcustomfields));
			gridmodel.set("reportId", this.state.reportId);
			//gridmodel.set("filterObject",JSON.stringify(this.dobyfilterArray));
			gridmodel.set("selectedcolumns", JSON.stringify(selectedcolumns));
			gridmodel.set("entities", this.entities);
			gridmodel.set("rowLimit", rowLimit);
			gridmodel.set("timeZone", this.timeZone);
			gridmodel.set("isImportedReport", this.isImportedReport)
			if (chartDetails.chartSettings != undefined && chartDetails.chartSettings.numberformat != undefined) {
				gridmodel.set("numberformat", JSON.stringify(chartDetails.chartSettings.numberformat));
			}
			var that = this;
			try {
				var tableInfo = {
					chartType: chartDetails["chartType"]
				};
				var bootObj = {};
				bootObj["currentChartNumber"] = chartnumber;
				bootObj["gridmodel"] = gridmodel;
				bootObj["tableInfo"] = tableInfo;
				bootObj["pos"] = null;
				bootObj["popupwindow"] = popupwindow;
				bootObj["reporttype"] = undefined;
				bootObj["target"] = undefined;
				bootObj["isFromPagenation"] = undefined;
				var bootStrapTable = new BootStrapTable(bootObj);
				bootStrapTable.render();

				//ChartUtils.drawBootstrapTable(chartnumber, gridmodel,tableInfo,null,popupwindow);
				$('.summarytable_datasetcount').text($('.datacount[data-num=' + chartnumber + ']  .publishinfo').text());
				$('.summarytable_filtervalues').text(drilldownvalue).attr("title", drilldownvalue);;
				$('.spinner').hide();
				$('#spinner' + chartnumber).hide();
			} catch (e) {
				$(".gridspinner").hide();
			}

		}
	}
	symmarytabledrilldownEve(msg, data) {
		if (data != undefined) {
			this.symmarytabledrilldown(data.currentTarget, data.filtervalues, data.drilldownon, data.currentchartno, data.index, data.isDateHistogram, data.dateHistomgramColName);
		} else {
			this.symmarytabledrilldown();
		}
	}
	symmarytabledrilldown(currentTarget, filtervalues, drilldownon, currentchartno, index, isDateHistogram, dateHistomgramColName) {
		var $this = currentTarget;
		var isDateHistogram = isDateHistogram || false;
		var that = this;
		var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails($($this).attr('data-num')));
		if (chartDetails['summarytablegroupbys'] == undefined && currentchartno == undefined) {
			currentchartno = $($this).attr('data-num');
		} else if (currentchartno == undefined) {
			currentchartno = $($this).attr('data-num');
		}
		if (currentchartno == this.state.chartnumber) {
			var jsonarray = [];
			var temp;
			var drilldownName, drilldowndisplayName;
			var drilldownDataType;
			var sessionValue = [];
			if (sessionStorage.getItem('customhierarchy' + this.state.chartnumber) != undefined)
				sessionValue = JSON.parse(sessionStorage.getItem('customhierarchy' + this.state.chartnumber));
			if (sessionValue.length != 0) {
				this.levelmapping = JSON.parse(JSON.stringify(sessionValue[sessionValue.length - 1][0]));
			}
			var filterArray = [];
			var groupvalue = "";
			var textcolumnvalue = "";
			var summarytablefiltervalues = [];
			var temp1 = [];
			var drilldownongroup = false;
			drilldowndisplayName = ChartInfo.functions.getSummarytabledrilldownname();
			if (drilldownon == undefined && isDateHistogram != undefined && isDateHistogram != "true" && dateHistomgramColName == undefined) {
				var index = $($this).index();
				var xAxisColumnName = chartDetails['summarytablecolumns'][index].columnName;
				temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == xAxisColumnName });
				if (temp != undefined && temp.length > 0 && !$($this).hasClass("groupTitle")) {
					drilldownName = temp[0].hierarchelist[that.levelmapping[xAxisColumnName] + 1].columnName;
					drilldownDataType = temp[0].hierarchelist[that.levelmapping[xAxisColumnName] + 1].dataType
					drilldowndisplayName = temp[0].hierarchelist[that.levelmapping[xAxisColumnName] + 1].columnName;
					that.levelmapping[xAxisColumnName] = that.levelmapping[xAxisColumnName] + 1;
				} else if (chartDetails["summarytablegroupbys"] != undefined) {
					var legendColumnName = chartDetails["summarytablegroupbys"].columnName;
					temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == legendColumnName });
					if (temp != undefined && temp.length > 0) {
						drilldownName = temp[0].hierarchelist[that.levelmapping[legendColumnName] + 1].columnName;
						drilldownDataType = temp[0].hierarchelist[that.levelmapping[legendColumnName] + 1].dataType
						drilldowndisplayName = temp[0].hierarchelist[that.levelmapping[legendColumnName] + 1].columnName;
						that.levelmapping[legendColumnName] = that.levelmapping[legendColumnName] + 1;
					}
				}
			}
			var tempfilters = filtervalues || [];
			if (drilldownon != undefined && drilldownon != 123 && isDateHistogram != undefined && isDateHistogram != "true") {
				var selectedhtmlcolumn = $('.summarytable-filteritems .drilldowncheckbox:checked');
				var columnName = $(selectedhtmlcolumn).attr("data-child");
				var dataType = $(selectedhtmlcolumn).attr("data-type");
				var displayName = $(selectedhtmlcolumn).attr("data-name");
				var tableName = $(selectedhtmlcolumn).attr("data-parent");
				var summarytabledetails = ChartInfo.functions.getSummarytableAxisFieldsArray(currentchartno);
				var details = summarytabledetails[summarytabledetails.length - 1];
				var temparray = {};
				if (details["group"] != undefined) {
					var groupobj = JSON.parse(JSON.stringify(details["group"]));
				}
				var textcolumnobj = JSON.parse(JSON.stringify(details["textcolumns"]));
				if (drilldownon == "group") {
					groupobj["columnName"] = columnName;
					groupobj["displayName"] = displayName;
					groupobj["dataType"] = dataType;
					groupobj["tableName"] = tableName;
					drilldownDataType = dataType;
					drilldownName = columnName;
					drilldowndisplayName = displayName;
				} else {
					textcolumnobj[index]["columnName"] = columnName;
					textcolumnobj[index]["displayName"] = displayName;
					textcolumnobj[index]["dataType"] = dataType;
					textcolumnobj[index]["tableName"] = tableName;
					drilldownDataType = dataType;
					drilldownName = columnName;
					drilldowndisplayName = displayName;
				}

				temparray["group"] = groupobj;
				temparray["textcolumns"] = textcolumnobj;
				temparray["filterid"] = filtervalues[filtervalues.length - 1]["filterid"];
				summarytabledetails.push(temparray);
				ChartInfo.functions.setSummarytableAxisFieldsArray(summarytabledetails, currentchartno);
			}
			var temp2 = [];
			_.each(filterArray, function (value, index) {
				var temp = $.grep(tempfilters, function (e) { return e.columnname == value.columnname });
				if (temp.length == 0) {
					temp2.push(value);
				}
			});
			filterArray = tempfilters.concat(temp2);
			var jsonArr = [];
			jsonArr.push(that.levelmapping);
			jsonArr.push(filterArray);
			sessionValue.push(jsonArr);
			sessionStorage.setItem("customhierarchy" + this.state.chartnumber, JSON.stringify(sessionValue));
			$("#chart" + this.state.chartnumber).empty();
			var summarytableDrilldownValues = ChartInfo.functions.getTempSummaryTableFilterValues(this.state.chartnumber) || [];
			summarytableDrilldownValues = summarytableDrilldownValues.concat(JSON.parse(JSON.stringify(this.state.dobyfilterArray || [])));
			ReactDOM.render(
				<SummaryTableView
					chartNumber={this.state.chartnumber}
					measures={viewcollection.getMeasures()}
					dimensions={viewcollection.getDimensions()}
					reportId={this.state.reportId}
					datamodelId={this.state.datamodelId}
					filterArray={JSON.stringify(summarytableDrilldownValues)}
					viewsummarytabe={this.viewSummaryTabe}
					features={this.state.features}
					chartDetails={chartDetails}
					indexName={that.props.indexname}
					drilldownName={drilldownName}
					levelMapping={that.levelmapping}
					appendbreadcrumb={true}
					entities={that.entities}
					isDateHistogram={isDateHistogram}
					dateHistomgramColName={dateHistomgramColName}
					timeZone={that.state.timeZone}
					isPurgedReport={that.state.isPurgedReport}
					isPublished={that.state.isPublished}
					isImportedReport={that.state.isImportedReport}
					drilldowndisplayName={drilldowndisplayName}
					drilldownDataType={drilldownDataType}
					multifact={that.state.multifact}
					multifactentities={that.state.multifactentities}
					facttables={that.state.facttables}
					drilldownongroup={drilldownongroup}
					groupvalue={groupvalue}
					textcolumnvalue={textcolumnvalue}
				/>,
				document.getElementById(`chart${this.state.chartnumber}`)
			);
		}
	}
	applyTextChart(e) {
		var $this = e.currentTarget;
		var currentchartnumber = $($this).attr('data-num');
		$('.editrichtext[data-num=' + currentchartnumber + ']').removeClass('active');
		$('.saverichtext[data-num=' + currentchartnumber + ']').addClass('active');
		var chartData = ChartInfo.functions.getChartDetails(currentchartnumber);
		var data = JSON.parse(chartData);
		data['richtextcontent'] = encodeURIComponent($('#chart' + currentchartnumber).summernote('code'));
		ChartInfo.functions.updateChartDetails(currentchartnumber, JSON.stringify(data));
		$('#chart' + currentchartnumber).summernote('destroy');
		$('.maxanim9 .btn-group button').removeClass('disabled');
	}
	editrichtext(e) {
		var $this = e.currentTarget;
		var currentchartnumber = $($this).attr('data-num');
		$('.maxanim9 .btn-group button').addClass('disabled');
		$('.editrichtext[data-num=' + currentchartnumber + ']').addClass('active');
		$('.saverichtext[data-num=' + currentchartnumber + ']').removeClass('active');
		var chartnumber = currentchartnumber;
		var textChartHeight = $("#tileview" + chartnumber).height() - ($("#tileview" + chartnumber + " .note-toolbar.panel-heading").outerHeight() + $(".maxanim3[data-num=" + chartnumber + "]").outerHeight() + 44)

		if ($('.fullscreenmain[data-num=' + chartnumber + ']').length != null && $('.fullscreenmain[data-num=' + chartnumber + ']').length > 0) {
			textChartHeight = $("#chart" + chartnumber).height() - 200;
		}
		if ($('.maxanim1[data-num=' + chartnumber + ']').hasClass('singlereportview')) {
			textChartHeight = $("#chart" + chartnumber).height() - 100;
		}
		if (textChartHeight < 100) {
			textChartHeight = 100;
		}
		$('#chart' + currentchartnumber).summernote('destroy');
		$('#chart' + currentchartnumber).summernote({
			height: textChartHeight,
			focus: false,
			disableDragAndDrop: true,
			toolbar: [
				['style', ['style']],
				['font', ['bold', 'italic', 'underline', 'clear']],
				['fontname', ['fontname']],
				['color', ['color']],
				['para', ['ul', 'ol', 'paragraph']],
				['height', ['height']],
				['table', ['table']],
				['insert', ['link', 'picture', 'hr']],
				['view', ['fullscreen', 'codeview']],
			],
			onKeyup: function (e) {
				setTimeout(function () {
					var num = $('#chart' + currentchartnumber).summernote('code').replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length;
					var key = e.keyCode;
					var allowed_keys = [37, 38, 39, 40]
					if ($.inArray(key, allowed_keys) != -1)
						return true
					else if (num > 14000) {
						e.preventDefault();
						e.stopPropagation();
						$('.countcharacters[data-num=' + currentchartnumber + ']').text("Characters Count Limit:" + (14000 - num));
						$('.saverichtext[data-num=' + currentchartnumber + ']').addClass('disabled');
					} else {
						$('.countcharacters[data-num=' + currentchartnumber + ']').text("Characters Count Limit:" + (14000 - num));
						$('.saverichtext[data-num=' + currentchartnumber + ']').removeClass('disabled');
					}
				}, 150);
			},
			onPaste: function (e) {
				setTimeout(function () {
					var num = $('#chart' + currentchartnumber).summernote('code').replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length;
					var key = e.keyCode;
					var allowed_keys = [8, 37, 38, 39, 40, 46]
					if ($.inArray(key, allowed_keys) != -1)
						return true
					else if (num > 14000) {
						e.preventDefault();
						e.stopPropagation();
						$('.countcharacters[data-num=' + currentchartnumber + ']').text("Characters Count Limit:" + (14000 - num));
						$('.saverichtext[data-num=' + currentchartnumber + ']').addClass('disabled');
					} else {
						$('.countcharacters[data-num=' + currentchartnumber + ']').text("Characters Count Limit:" + (14000 - num));
						$('.saverichtext[data-num=' + currentchartnumber + ']').removeClass('disabled');
					}
				}, 150);
			}
		});
		var chartData = ChartInfo.functions.getChartDetails(currentchartnumber);
		var data = JSON.parse(chartData);
		if (data['richtextcontent'] != undefined)
			$('#chart' + currentchartnumber).summernote('code', decodeURIComponent(data['richtextcontent']));
	}
	cancelrichtext(e) {
		var $this = e.currentTarget;
		var currentchartnumber = $($this).attr('data-num');
		var chartData = ChartInfo.functions.getChartDetails(currentchartnumber);
		var data = JSON.parse(chartData);
		if (data['richtextcontent'] != undefined) {
			$('#chart' + currentchartnumber).summernote('code', decodeURIComponent(data['richtextcontent']));
		} else if (data['richtextcontent'] === undefined) {
			$('#chart' + currentchartnumber).summernote('destroy');
			$('#chart' + currentchartnumber).empty();
		}
		$('#chart' + currentchartnumber).summernote('destroy');
		$('.editrichtext[data-num=' + currentchartnumber + ']').removeClass('active');
		$('.saverichtext[data-num=' + currentchartnumber + ']').removeClass('active');
		$('.maxanim9 .btn-group button').removeClass('disabled');
	}
	maximizesettingmodal = (e) => {
		var $this = e.currentTarget;
		var today = new Date();
		var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
		dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
		$(".singlereportsettingdiv" + $($this).closest('.maxanim1').attr("data-num")).empty();
		$(".singlereportsettingdiv" + $($this).closest('.maxanim1').attr("data-num")).append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
		var changeSingleReportSettingModal = new SingleReportSettingModalView({
			dynamicdiv: dynamicdiv,
			reportname: viewcollection.getreportname($($this).closest('.maxanim1').attr("data-num")),
			reportdesc: viewcollection.getreportdesc($($this).closest('.maxanim1').attr("data-num")),
			chartnumber: $($this).closest('.maxanim1').attr("data-num")
		});
		try {
			$('.modal-anim-left').prop('class', 'modal fade').addClass("left");
			$(".singlereportsettingdiv" + $($this).closest('.maxanim1').attr("data-num") + " #pagesettingsmodal").show();
			ModalProperties.resize();
		} catch (e) {
		}
	}
	drilldownsettings = () => {
		PubSub.publish('drilldownsettings');
	}
	summarytableview = (e) => {
		var $this = e.currentTarget;
		$('.trend-stats-container').remove();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		$(".append_summarytablemodal").empty();
		var currentChartNumber = $($this).attr("data-num");
		$(".chartadvancedoptions[data-num='chart" + currentChartNumber + "']").hide();
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.max-data-buttons').hide();
		$(".chartchanger[data-num='" + currentChartNumber + "']").hide();
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.showtrending').hide()
		$(".summarymodal[data-num='" + currentChartNumber + "']").addClass('hide');
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.viewchartdata').removeClass("active");
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.viewchartfromchartdata').addClass("active");
		if ($('.doby-offcanvas-columnlist').hasClass("show")) {
			$('.doby-offcanvas-columnlist').toggleClass("show");
		}
		var legendfilter = [];
		ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
		ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
		sessionStorage.removeItem("drilldown" + currentChartNumber);
		$(".clonetile[data-num=" + currentChartNumber + "]").removeClass("disabled");
		$("#spinner" + currentChartNumber).show();
		var currentSelection = 'Summary Table';
		var chartData = ChartInfo.functions.getChartDetails(currentChartNumber);
		var data = JSON.parse(chartData);
		if (currentSelection == 'Summary Table') {
			$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + ']').removeClass("showactive");
			$('.boc-maxscreen-hamburger[data-num=' + currentChartNumber + ']').removeClass("showactive");
			$($this).addClass("showactive");
			if (data["chartType"] != "pivot" && data["chartType"] != "summarytable" && data["chartType"] != "predictionchart" && data["chartType"] != "doby") {
				data["oldcharttype"] = data["chartType"];
			}
			data["chartType"] = "summarytable";
			ChartInfo.functions.updateChartDetails(currentChartNumber, JSON.stringify(data));
		}
		this.viewSummaryTabe(currentChartNumber, viewcollection.getMeasures(), viewcollection.getDimensions(), "", data);
	}
	symmarytabledrilldown_breadcrumbEve(msg, data) {
		this.symmarytabledrilldown_breadcrumb(data);
	}
	symmarytabledrilldown_breadcrumb = (currentTarget) => {
		var $this = currentTarget;
		var that = this;
		var chartno = $($this).parent().attr("data-num");
		$("#spinner"+chartno).show();
		var appendbreadcrumb = true;
		if (chartno == this.state.chartnumber) {
			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(chartno));
			var drilldownlevel = $($this).parent().index();
			var sessionValue = JSON.parse(sessionStorage.getItem('customhierarchy' + chartno));
			var customhierarchy_level_data = sessionValue[drilldownlevel];
			var drilldownName = $($this).parent().attr("level-columnname");
			var drilldowndisplayName = $($this).parent().attr("level-displayName");
			if (customhierarchy_level_data != undefined) {
				this.levelmapping = customhierarchy_level_data[0];
				var level_filterobject = customhierarchy_level_data[1];
			}
			$("#chart" + chartno).empty();
			_.each(sessionValue, function (list, i) {
				if (drilldownlevel >= i) {
					$($this).parent().next().remove();
				} else {
					sessionValue.pop(i);
					$($this).parent().next().remove();
				}
			});
			if (drilldownlevel == 0) {
				$($this).parent().remove();
				appendbreadcrumb = false;
				$(".clonetile[data-num=" + chartno + "]").removeClass("disabled");
			}
			var summarytablefiltervalues = ChartInfo.functions.getTempSummaryTableFilterValues(chartno);
			var levelid = $($this).parent().attr("level");
			var filterArray = [];
			if(summarytablefiltervalues!=undefined){
				filterArray = $.grep(summarytablefiltervalues, function (e) { return e.filterid < levelid });
			}
			ChartInfo.functions.setTempSummaryTableFilterValues(filterArray, chartno);
			var summarytabledetails = ChartInfo.functions.getSummarytableAxisFieldsArray(chartno);
			ChartInfo.functions.setSummarytableAxisFieldsArray($.grep(summarytabledetails, function (e) { return e.filterid < levelid || e.filterid == undefined }), chartno);
			ChartInfo.functions.setSummaryTableDateHistogramStatus(chartno, "false");
			sessionStorage.setItem('customhierarchy' + chartno, JSON.stringify(sessionValue));
			var summarytableDrilldownValues = ChartInfo.functions.getTempSummaryTableFilterValues(chartno) || [];
			summarytableDrilldownValues = summarytableDrilldownValues.concat(JSON.parse(JSON.stringify(this.state.dobyfilterArray || [])));
			if (summarytableDrilldownValues.length == 0) {
				ChartInfo.functions.setdrillfitlerArray([]);
			}
			ReactDOM.render(
				<SummaryTableView
					chartNumber={this.state.chartnumber}
					measures={viewcollection.getMeasures()}
					dimensions={viewcollection.getDimensions()}
					reportId={this.state.reportId}
					datamodelId={this.state.datamodelId}
					filterArray={JSON.stringify(summarytableDrilldownValues)}
					viewsummarytabe={this.viewSummaryTabe}
					pos={this.pos}
					features={that.state.features}
					chartDetails={chartDetails}
					indexName={that.props.indexname}
					drilldownName={drilldownName}
					levelMapping={that.levelmapping}
					appendbreadcrumb={appendbreadcrumb}
					drilldownongroup={true}
					entities={that.entities}
					timeZone={that.state.timeZone}
					isPurgedReport={that.state.isPurgedReport}
					isPublished={that.state.isPublished}
					isImportedReport={that.state.isImportedReport}
					drilldowndisplayName={drilldowndisplayName}
					multifact={that.state.multifact}
					multifactentities={that.state.multifactentities}
					facttables={that.state.facttables}
				/>,
				document.getElementById(`chart${this.state.chartnumber}`)
			)
		}
		setTimeout(function(){$("#spinner"+chartno).hide();},1000)
	}
	viewEmbedreport = (e) => {
		var $this = e.currentTarget;
		var that = this;
		if (viewcollection.getreportId($($this).closest('.maxanim1').attr("data-num")) === undefined) {
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
		} else {
			e.preventDefault();
			ModalProperties.resize();
			var embedReportView = new EmbedReportView({
				reportId: viewcollection.getreportId($($this).closest('.maxanim1').attr("data-num")),
				reportName: viewcollection.getreportname($($this).closest('.maxanim1').attr("data-num")),
				multifact: that.state.multifact,
				facttables: that.state.facttables,
				multifactentities: that.state.multifactentities,
			});
		}
	}
	ColumnPreview=(msg, data) =>{
		var that=this;
		if (data != undefined) {
			if(that.state.dimcols!=undefined){
				if(that.state.dimcols.includes(data.name)){
					this.columnpreview(data.name, data.displayname, data.dataType);
				}
		}
		} else {
			this.columnpreview();
		}
	}
	columnpreview = (name, displayname, dataType) => {
		var that = this;
		$('body #datatablepopupview0').remove();
		$('body').append('<div class=' + "datatablepopupview" + 0 + ' id=' + "datatablepopupview" + 0 + '></div>');
		ReactDOM.render(
			<DataTablePopupView
				dimensions={viewcollection}
				currentChartNumber={0}
				target={undefined}
				multifactentities={this.state.multifactentities}
				multifact={this.state.multifact}
				facttables={this.state.facttables}
				isFromColumnPreview={true}
				appendheaders={false}
			>
			</DataTablePopupView>,
			document.querySelector(".datatablepopupview0"));
		// new DataTablePopupView({
		// 	currentChartNumber:0,
		// 	//broker:that.broker,
		// 	target:undefined,
		// 	facttables:that.facttables,
		// 	multifact:that.multifact,
		// 	multifactentities:that.multifactentities,
		// 	dimensions:viewcollection,
		// 	isFromColumnPreview:true,
		// 	appendheaders:false
		// });
		// var gridmodel=new DataTablemodel();
		// var viewReportDataTableCollection=new ViewReportDataTableCollection(gridmodel);
		var gridmodel = new FormData();
		var temp = {};
		var selectedcolumns = [];
		temp["actualnames"] = name;
		temp["displaynames"] = displayname;
		temp["datatype"] = dataType;
		selectedcolumns.push(temp);

		var customFields = sessionStorage.getItem("customFields");
		var tempCF = JSON.parse(customFields);
		var res;
		var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(this.props.applyattributes);
		_.each(tempCF, function (customfields) {
			_.each(customfields.parametrFieldsArray, function (value) {
				var customparameter = ChartInfo.functions.getCustomParameter();
				if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
					customparameter = customParameterFilterArray;
				}
				res = $.grep(customparameter, function (e) {
					return e.parametername == value;
				})
				var formula = customfields.formula;
				var scriptcondition = customfields.scriptcondition;
				var fields = customfields.fields;
				var sqlformula = customfields.sqlformula;
				if (res.length != 0) {
					customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
				} else {
					_.each(ChartInfo.functions.getCustomParameter(), function (values) {
						if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
							customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
							customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
						}
					});
				}
			});
		});
		customFields = JSON.stringify(tempCF);

		var selectedcustomfields = [];
		if (customFields != undefined && customFields != null && customFields != "null") {
			var temp = JSON.parse(customFields);
			var getcustomfield = $.grep(temp, function (e) { return e.name == name });
			if (getcustomfield.length > 0) {
				selectedcustomfields.push(getcustomfield[0]);
			}
		}
		var parsedSharedFilters = [];
		if (viewcollection.getSharedFilters() != undefined && viewcollection.getSharedFilters() != null && viewcollection.getSharedFilters() != "null" && viewcollection.getSharedFilters().length > 2) {
			parsedSharedFilters = viewcollection.getSharedFilters();
		}

		gridmodel.set("aggregation", "");
		gridmodel.set("xaxis", "");
		gridmodel.set("xaxisdataType", "");
		gridmodel.set("yAxisDataType", "");
		gridmodel.set("yaxis", "");
		gridmodel.set("datamodelId", this.state.datamodelId);
		gridmodel.set("indexName", (this.props.indexname != undefined) ? this.props.indexname : "");
		gridmodel.set("customFields", JSON.stringify(selectedcustomfields));
		gridmodel.set("reportId", this.props.reportId);
		gridmodel.set("filterObject", parsedSharedFilters);
		gridmodel.set("selectedcolumns", JSON.stringify(selectedcolumns));
		gridmodel.set("rangefieldMap", "");
		gridmodel.set("entities", (this.props.entities != undefined) ? this.props.entities : "");
		gridmodel.set("rowLimit", 100);
		gridmodel.set("timeZone", (this.props.timeZone != undefined) ? this.props.timeZone : "");
		gridmodel.set("script", JSON.stringify(selectedcustomfields));
		gridmodel.set("isImportedReport", this.isImportedReport != undefined ? this.isImportedReport : false);
		//			gridmodel.set("showMoreOptions",true);
		var tableInfo = {
			showTitle: false,
			drilldownvalue: undefined
		};

		var bootObj = {};
		bootObj["currentChartNumber"] = 0;
		bootObj["gridmodel"] = gridmodel;
		bootObj["tableInfo"] = tableInfo;
		bootObj["pos"] = undefined;
		bootObj["popupwindow"] = "popupview";
		bootObj["reporttype"] = undefined;
		bootObj["target"] = undefined;
		bootObj["isFromPagenation"] = undefined;
		bootObj["ForPreviewData"] = true;
		var bootStrapTable = new BootStrapTable(bootObj);
		bootStrapTable.render();

		//ChartUtils.drawBootstrapTable(0,gridmodel,tableInfo,undefined,"popupview");
	}
	tileFilterMenuToggle(e) {
		e.preventDefault();
		PubSub.publish('tileFilterMenuToggle', e.currentTarget);
	}
	maxdatatables(e) {
		e.stopPropagation();
		PubSub.publish('maxdatatables', e.currentTarget);
	}
	showdobyselectedcolumns(e) {
		e.stopPropagation();
		PubSub.publish('showdobyselectedcolumns', e.currentTarget);
	}
	cancelselectedcolumns = (event) => {
		var that = this;
		var currenttarget = event.currentTarget;
		var chartnumber = $(currenttarget).attr("data-num");
		var dobycolumnnames = viewcollection.getColumns();
		$('.innerdobyselectedcolumns' + chartnumber).empty();
		var isColumnSelectionEnable = false;
		$.each(that.state.features, function (index, featuresModule) {
			if (featuresModule.moduleName == 'my_report') {
				$.each(featuresModule.features, function (index, feature) {
					if (feature.featureName == 'viewreport_maximize_data_table_column_selection' && feature.allow == true) {
						isColumnSelectionEnable = true;
					}
				})
			}
		});
		var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
		var parsedData;
		if ($(currenttarget).hasClass("closeselectedcolumns")) {
			$('.doby-offcanvas-columnlist[data-num="' + chartnumber + '"]').toggleClass("show");
		} else {
			$('.innerdobyselectedcolumns' + chartnumber).append("<li class='doby-list-item boc-list-item' ><input class='form-control form-control-dobycolumns search-image' placeholder=" + lang['storyboard.search'] + " id='searchinput' type='text'></li>");
			var reportnames = that.state.reportcolumns;

			_.each(dobycolumnnames, function (i, j) {
				var columnDisplayName;
				_.each(reportnames, function (names) {
					if (dobycolumnnames[j].columnName == names.columnName) {
						columnDisplayName = names.columnDisplayName;
					}
					else {
						columnDisplayName = dobycolumnnames[j].columnDisplayName;
					}
				});
				if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
					$.each(dobycolumnnames, function (index, data) {
						parsedData = JSON.parse(renameMeasuresorDimensions);
						if (parsedData[data.columnName] != undefined) {
							data['columnDisplayName'] = parsedData[data.columnName];
						}
					});
				}
				var columnName = dobycolumnnames[j].columnName;
				var datatype = dobycolumnnames[j].tableDisplayType;
				columnDisplayName = dobycolumnnames[j].columnDisplayName;
				if (isColumnSelectionEnable) {
					$('.innerdobyselectedcolumns' + chartnumber).append("<li class='doby-list-item boc-list-item' tite= '" + columnDisplayName + "' ><label><input type='checkbox' data-num=" + chartnumber + " class = 'filtercheckbox1' " + " name= '" + columnName + "' displayname='" + columnDisplayName + "'  datatype= '" + datatype + "' > </i>&nbsp;" + columnDisplayName + "</label></li>");
				} else {
					$('.innerdobyselectedcolumns' + chartnumber).append("<li class='doby-list-item boc-list-item' tite= '" + columnDisplayName + "' ><label><input type='checkbox' data-num=" + chartnumber + " class = 'filtercheckbox1' " + " name= '" + columnName + "' displayname='" + columnDisplayName + "'  datatype= '" + datatype + "' disabled='true'> </i>&nbsp;" + columnDisplayName + "</label></li>");
				}
			})
			$('.doby-offcanvas-columnlist[data-num="' + chartnumber + '"]').toggleClass("show");
			var getdobyselecteddata = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
			$('.datatablerowlimit' + chartnumber + ' option[value=' + getdobyselecteddata["dobyrowlimit"] + ']').prop('selected', true);
			var dobyselecteddata = getdobyselecteddata["dobyselectedcolumns"];
			var xaxisname = getdobyselecteddata["xAxis"];
			if (dobyselecteddata === undefined) {
				dobyselecteddata = this.dobycolumns
				if (dobyselecteddata == undefined) {
					dobyselecteddata = xaxisname.displayName
				}
			}
			$(dobyselecteddata).each(function (i, j) {

				var displayname;
				if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null" && parsedData[dobyselecteddata[i].actualnames] != undefined) {
					displayname = parsedData[dobyselecteddata[i].actualnames].replace(/([#;?&,. +*~\':"!^$[\]()<=>|\/@])/g, "\\$1");
				} else {
					displayname = dobyselecteddata[i].displaynames.replace(/([#;?&,. +*~\':"!^$[\]()<=>|\/@])/g, "\\$1");
				}
				$('.innerdobyselectedcolumns' + chartnumber + ' .boc-list-item label input[displayname=' + displayname + ']').prop("checked", true)
			})
		}
		if (this.state.isDirect == 1 && this.state.isStoredProcedure == 1) {
			$(".innerdobyselectedcolumns" + chartnumber).parent().find('#datatablerowid').addClass("hide");
			$('.datatablerowlimit' + chartnumber).addClass('hide');
		} else {
			$(".innerdobyselectedcolumns" + chartnumber).parent().find('#datatablerowid').removeClass("hide");
			$('.datatablerowlimit' + chartnumber).removeClass('hide');
		}
	}
	applydobysettings(e) {
		e.stopPropagation();
		PubSub.publish('applydobysettings', e.currentTarget);
	}
	savesinglereport = (e) => {
		e.stopPropagation();
		var $this = e.currentTarget;
		$(".singlereportdiv" + $($this).closest('.maxanim1').attr("data-num")).empty();
		var that = this;
		var isDirect = 0;
		if (viewcollection.getIsDirect() != undefined) {
			isDirect = viewcollection.getIsDirect();
		} else if (this.state.isDirect != undefined) {
			isDirect = this.state.isDirect;
		}
		var model = viewcollection.model;
		var reportdetails = JSON.parse(model.reportdata);
		var detailsObj = reportdetails[1]["details"];
		detailsObj["isclonedsyncconfig"] = true;
		var saveSingleReportModal = new SingleReportSaveModalView(
			{
				viewreportcollection: viewcollection,
				columns: viewcollection.getColumns(),
				reportcolumns: JSON.parse(viewcollection.getColumns()),
				reportId: viewcollection.getreportId($($this).closest('.maxanim1').attr("data-num")),
				reportname: viewcollection.getreportname($($this).closest('.maxanim1').attr("data-num")),
				reportfolder: viewcollection.getreportfolder($($this).closest('.maxanim1').attr("data-num")),
				reportdesc: viewcollection.getreportdesc($($this).closest('.maxanim1').attr("data-num")),
				chartnumber: $($this).closest('.maxanim1').attr("data-num"),
				//model : new SaveReportValidation(),
				schemaType: that.state.schemaType,
				isDirect: isDirect,
				//syncConfigData:that.collection.getsyncconfigdata($($this).closest('.maxanim1').attr("data-num")),
				isPurgedReport: that.state.isPurgedReport,
				//servertimezone:response.serverTimeZone,
				details: detailsObj,
				syncConfigData: detailsObj.syncConfigData,
				multifact: that.state.multifact,
				multifactentities: that.state.multifactentities,
				datamodelId: that.state.datamodelId,
				isFromStoryboard: true
			});
		//that.$(".singlereportdiv"+ $($this).closest('.maxanim1').attr("data-num")).append(that.saveSingleReportModal.$el);
		try {
			$('.modal-anim-left').prop('class', 'modal fade').addClass("left");
			$(".singlereportdiv" + $($this).closest('.maxanim1').attr("data-num") + " #savereportgraph").show();
			//$(".singlereportdiv" + $($this).closest('.maxanim1').attr("data-num") + " #savereportgraph").modal("show");
			//	$("#savestoryboard").remove()
			//$('#incrementalrefreshfield').chosen({ 'width': '100%', search_contains: true });
			ModalProperties.resize();
		} catch (e) {
			console.log(e);
		}
	}
	cloneReport(e) {
		var $this = e.currentTarget;
		var that = this;
		var reportId = viewcollection.getreportId($($this).closest('.maxanim1').attr("data-num"));
		if (reportId === undefined) {
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text("Please save the report before clone");
		} else {
			let requestHeaders = {
				method: "post",
				headers: new Headers({
					"Content-Type": "application/json"
				})
			};
			var requestbody = new FormData();
			requestbody.set("reportId", reportId);
			SendRequest({
				url: "clonereport",
				body: requestbody,
				sucessFunction: (response) => {
					if (response.status == 'success') {
						$('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
						$('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
						$('.birdmessagebuttons').removeClass('show');
						$('.birdmessagebuttons').addClass('hide');
						$('.birdmessage-center').addClass("message-top-in");
						$('.details').removeClass('show');
						$('.details').addClass('hide');
						$('.birdmessage h2').empty().append('Success');
						$('.birdmessage-info').empty().text(getMessage('BirdSuccess02'));
					} else {
						$(".spinner").hide();
						$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
						$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
						$('.birdmessagebuttons').removeClass('show');
						$('.birdmessagebuttons').addClass('hide');
						$('.birdmessage-center').addClass("message-top-in");
						$('.details').removeClass('hide');
						$('.birdmessage h2').empty().append('Error : ');
						$('.birdmessage h2').append('<span class="errorcode">');
						$('.birdmessage .errorcode').empty().append(response.errorCode);
						$('.birdmessage-info').empty().text(response.error);
						$('.details').addClass('show');
						$('.messagedetails xmp').empty().append(response.errorTrace);
					}
				},
				rejectFunction: reportDataError => {
					console.log(reportDataError);
				},
				headers: requestHeaders
			});
		}
	}
	reportTheme(e) {
		var $this = e.currentTarget;
		var reportThemeModal = new ReportThemeModal({
			chartnumber: $($this).closest('.maxanim1').attr("data-num")
		});
		ModalProperties.resize();
	}
	setAsFavourite(e) {
		var $this = e.currentTarget;
		var reportId1 = viewcollection.getreportId($($this).closest('.maxanim1').attr("data-num"));
		if (reportId1 === undefined) {
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation02'));
		} else {
			var favmodel = new FormData();
			favmodel.set("reportId", reportId1);
			let requestHeaders = {
				method: "post",
				headers: new Headers({
					"Content-Type": "application/json"
				})
			};
			SendRequest({
				url: "setasfavourite",
				body: favmodel,
				sucessFunction: response => {
					if (response.hasOwnProperty('error')) {
						$(".spinner").hide();
						$(".gridspinner").hide();
						$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
						$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
						$('.birdmessagebuttons').removeClass('show');
						$('.birdmessagebuttons').addClass('hide');
						$('.birdmessage-center').addClass("message-top-in");
						$('.details').removeClass('hide');
						$('.birdmessage h2').empty().append('Error : ');
						$('.birdmessage h2').append('<span class="errorcode">');
						$('.birdmessage .errorcode').empty().append(response.errorCode);
						$('.birdmessage-info').empty().text(response.error);
						$('.details').addClass('show');
						$('.messagedetails xmp').empty().append(response.errorTrace)
					} else
						$('.setsinglereportfavourite1 i').addClass('fa-yellow');
				},
				rejectFunction: reportDataError => {
					console.log(reportDataError);
				},
				headers: requestHeaders
			});
		}
	}
	sharesinglereport = (e) => {
		var $this = e.currentTarget;
		var that = this;
		if (viewcollection.getreportId($($this).closest('.maxanim1').attr("data-num")) === undefined) {
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
		} else {
			var shareReportModal = new ShareReportModal({
				reportId: viewcollection.getreportId($($this).closest('.maxanim1').attr("data-num")),
				singlereport: true,
				dimensions: viewcollection.getDimensions(),
				sharedFilters: viewcollection.getSharedFilters(),
				datamodelId: that.state.datamodelId
			});
		}
	}
	viewEmbedreport = (e) => {
		var $this = e.currentTarget;
		var that = this;
		if (viewcollection.getreportId($($this).closest('.maxanim1').attr("data-num")) === undefined) {
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
		} else {
			e.preventDefault();
			ModalProperties.resize();
			var embedReportView = new EmbedReportView({
				reportId: viewcollection.getreportId($($this).closest('.maxanim1').attr("data-num")),
				reportName: viewcollection.getreportname($($this).closest('.maxanim1').attr("data-num")),
				multifact: that.state.multifact,
				facttables: that.state.facttables,
				multifactentities: that.state.multifactentities,
				features: that.state.features
			});
		}
	}
	schedulereport = (e) => {
		var $this = e.currentTarget;
		var that = this;
		if (viewcollection.getreportId($($this).closest('.maxanim1').attr("data-num")) === undefined) {
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
		} else {
			e.preventDefault();
			ModalProperties.resize();
			var viewScheduleReportModal = new ViewScheduleReportModal({
				reportId: viewcollection.getreportId($($this).closest('.maxanim1').attr("data-num")),
				reportName: viewcollection.getreportname($($this).closest('.maxanim1').attr("data-num")),
			});
		}
	}
	singlealerts = (e) => {
		e.preventDefault();
		var $this = e.currentTarget;
		var chartnumber = $($this).closest('.maxanim1').attr("data-num");
		var reportId = viewcollection.getreportId(chartnumber);
		if (reportId === undefined) {
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
		}
		else {
			var that = this;
			let requestHeaders = {
				method: "get",
				headers: new Headers({
					"Content-Type": "application/json"
				})
			};
			SendRequest({
				url: "getalertsinfo",
				queryString: "?alertId=&reportId=" + reportId,
				sucessFunction: response => {
				var filterid = window.localStorage.getItem("current")!=undefined ? atob(window.localStorage.getItem("current")):"";
					if(filterid!=undefined && filterid!=""){
						response = $.grep(response, function (e) { return e.filterid == filterid });
					}
					ReactDOM.render(<AlertsView
						reportId={reportId}
						chartnumber={chartnumber}
						jsonArray={response}
						features={that.state.features}
						facttables={that.state.facttables}
						multifact={that.state.multifact}
						multifactentities={that.state.multifactentities}
					></AlertsView>, document.getElementById("append_alertmodal" + chartnumber));
					ChartInfo.functions.setAlerts(response);
				},
				rejectFunction: () => { },
				headers: requestHeaders
			});
		}
	}
	liveReportOpen(e) {
		PubSub.publish('liveReportOpen', e);
	}
	getMutiyComapreDateObj(finalCompareDateObj,tempcompareDateObj,tempYaxis){
        let yjsObj = tempcompareDateObj["0"]; 
        let matchedYDisplay = "",matchedIndex=0;
        _.each(tempYaxis,function(val,index){
            if(val.columnName!=undefined&&val.columnName!=null&&yjsObj.hasOwnProperty(val.columnName)){
                finalCompareDateObj[index+""]=yjsObj;
                matchedYDisplay = val.columnName;
                matchedIndex = index;
            }
        });
        let clonedObj = yjsObj[matchedYDisplay]
         if (yjsObj != null && yjsObj.hasOwnProperty(matchedYDisplay)&&tempYaxis.length>1) {
             _.each(tempYaxis,function(val,index){
                 if(index!=matchedIndex){
                     let tempObj = {}
                     tempObj[val.columnName] = _.clone(clonedObj)
                    finalCompareDateObj[index+""] = tempObj;
                 }
                })
            }
            return finalCompareDateObj;
    }
}