import React from "react";
import SendRequest from '../SendRequest';
import Storyboardview from './Storyboard.jsx'
import CustomStoryboards from '../CustomStoryboard/customstoryboards.jsx';
import * as ChartUtil from '../js/helpers/utils/chartutil';
import { lang } from "../js/helpers/utils/languages/language";

import $ from "jquery/dist/jquery";

export default class PublicStoryboardview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderReq: false
        }

        if(window.location.href.indexOf("embeddedreport")>=0){
            var validURL = false;
            var that = this;
            var urlparameters = window.location.search.replaceAll("?", "").split("&");
            $.each(urlparameters, function (i, u) {
              var parameter = u.split("=");
              if (parameter[0] == "ReportEndDate") {
                validURL = true;
               var expiryTime = parameter[1]!=null && parameter[1]!=undefined? ChartUtil.birdatob("embed",parameter[1]):"00/00/0000 00:00@@@Etc/UTC";
               var expiryTimeArr = expiryTime.split("@@@");
               var serverTimeZone = expiryTimeArr[1]!=undefined && expiryTimeArr[1]!=null ? expiryTimeArr[1]:"Etc/UTC";
               var time = expiryTimeArr[0];
             if(time != "00/00/0000 00:00"){
              var current_time = new Date(new Date().toLocaleString("en-US", {timeZone: serverTimeZone}));
              var server = new Date(+time);
              if (current_time > server) {
                that.props.history.push({
                    pathname: "/" + window.localStorage.getItem("appName") + "/welcome/errorpage",
                    state: lang["error.urlexpired"]
                    });
                  return;
              }
             }
              }
            });
            if(!validURL){
                this.props.history.push({
                    pathname: "/" + window.localStorage.getItem("appName") + "/welcome/errorpage",
                    state: lang["error.pagenotfound"]
                    });
                  return;
            }
          }
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        let queryString =window.location.search;

        SendRequest({
            url: "authenticate/public",
            queryString: queryString,
            sucessFunction: result => {
                //const history = useHistory();
                window.localStorage.clear();
                window.localStorage.setItem("refreshToken", result.refreshToken);
                window.localStorage.setItem("accessToken", result.accessToken);
                window.localStorage.setItem("accessTokenExpiration", result.accessTokenExpiration);
                window.localStorage.setItem("serverTimezone", result.timezoneId);
                sessionStorage.setItem("accessToken", result.accessToken);

                var reg = new RegExp('^[0-9]+$');
                // if(reportId != "" && reportId != undefined && !reg.test(reportId)){
                //     reportId = atob(reportId)
                // }
                $("body").removeClass("datamainpage datahubpage dataworkspacepage datamodelspage datagovernancepage searchpage");
                $("body").removeClass("rightpanelopen");
                $("#main").removeClass("newreportpagescrolling");
                sessionStorage.removeItem("convertedtypes");
                sessionStorage.removeItem("isconvettedtypesapplied");
                sessionStorage.removeItem("pos");
                sessionStorage.removeItem("renameMeasuresorDimensions");
                sessionStorage.setItem("changesdone", 0);
                $('body').addClass('embedreportbdoy day');
               this.setState({ renderReq: true })
            },
            rejectFunction: this.loginRejectFunction,
            headers: requestHeaders,
        });
    }
    render() {
        if(this.props.isFromCustomStoryboard){
            return (<div>{(this.state.renderReq) ? (
                <CustomStoryboards {...this.props} />
            ) : null}
            </div>);
        }
        else{
        return (<div>{(this.state.renderReq) ? (
            <Storyboardview {...this.props} />
        ) : null}
        </div>);
        }
    }
}
