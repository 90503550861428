import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import SendRequest from '../SendRequest';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import WorkspaceItemSettings from './WorkspaceItemSettings';
import WorkspaceHtml from './WorkspaceHtml.jsx';
import WorkspaceDatahubTables from './WorkspaceDatahubTables';
import WorkspaceExistingEntitiesHtml from './WorkspaceExistingEntitiesHtml.jsx';
import { lang } from "../js/helpers/utils/languages/language";
import WorkspaceCustomfields from './WorkspaceCustomfields';
import SaveWorkSpaceModal from './SaveWorkspaceModal';
import * as dataHubEntitiesCollection from '../Hub/DatahubEntitiesCollection'
import { format } from 'sql-formatter';
const ace = require('brace');
const $ = window.$;

export default class Workspace extends React.Component {
    constructor(props) {
        $(".spinner").show();
        super(props);
        this.state = {
            wsmetadata: {},
            wsEntityTypes: []
        }
        dataHubEntitiesCollection.resetCollection();
        this.workspaceId = this.props.match.params.WSID;
        if (this.workspaceId === undefined) {
            this.workspaceId = 0;
        }
        this.displayFooter = true;
        this.workspaceName = undefined;
        this.workspaceDesc = undefined;
        this.dataSyncDetails = undefined;
        this.metadataObj = {};
        this.entities = {};
        this.custqueentities = {};
        this.wscqnames = [];
        this.wscsqQueries = {};
        this.allentities = [];
        this.allentitynames = [];
    }
    UNSAFE_componentWillMount() {
        document.body.className = '';
        document.body.classList.add('day', 'dataworkspacepage');
    }
    componentDidMount() {
        $(".spinner").show();
        var width;
        if ($(window).width() < 800) {
            width = '150';
        } else {
            width = '300';
        }
        $("#workspacesplitcontainer").w2destroy('splitter');
        $("#workspacesplitcontainer").w2layout({
            name: 'splitter',
            padding: 5,
            panels: [
                { type: 'left', size: width, resizable: true, style: this.pstyle, content: `<div id="workspaceselectentitycontainer"></div>` },
                { type: 'main', style: this.pstyle, content: "<div id='workspaceentitycontainer'></div>" },
            ]
        });
        ReactDOM.render(
            <WorkspaceDatahubTables
                factcheck={this.factcheck}
                joinremove={this.joinremove}
                editworkspaceitem={this.editworkspaceitem}
                search={this.search}
                workspaceaddcustomquery={this.workspaceaddcustomquery}
                onWorkSpaceEntitiesLoad={this.onWorkSpaceEntitiesLoad}
            />, document.getElementById('workspaceselectentitycontainer'));
        // setTimeout(function(){$(".spinner").hide();},1500);
    }
    WorkSpaceEntityMetaDataCollection = {
        resetCollection: () => {
            this.entities = {};
        },
        updateEntityColumns: (columns, entityName, connectionId) => {
            this.entities[entityName + "&&&" + connectionId]["columns"] = columns;
        },
        updateErrorInfo: (errorinfo, workspaceentityid) => {
            if (this.entities[workspaceentityid] == undefined) {
                this.entities[workspaceentityid] = {};
            }
            this.entities[workspaceentityid]["errorinfo"] = errorinfo;
        },
        updateEntityFilters: (filters, entityName, connectionId) => {
            this.entities[entityName + "&&&" + connectionId]["filters"] = filters;
        },
        updateEntityCustomFeilds: (customfeilds, entityName, connectionId) => {
            this.entities[entityName + "&&&" + connectionId]["customfeilds"] = customfeilds;
        },
        updateEntityCurrency: (currency, entityName, connectionId) => {
            this.entities[entityName + "&&&" + connectionId]["currency"] = currency;
        },
        getErrorInfo: (workspaceentityid) => {
            if (this.entities[workspaceentityid] == undefined) {
                this.entities[workspaceentityid] = {};
                return undefined;
            }
            else {
                return this.entities[workspaceentityid]["errorinfo"];
            }
        },
        getEntityColumns: (entityName, connectionId) => {
            if (this.entities[entityName + "&&&" + connectionId] == undefined) {
                this.entities[entityName + "&&&" + connectionId] = {};
                return undefined;
            }
            else {
                return this.entities[entityName + "&&&" + connectionId]["columns"];
            }
        },
        getEntityFilters: (entityName, connectionId) => {
            if (this.entities[entityName + "&&&" + connectionId] == undefined) {
                this.entities[entityName + "&&&" + connectionId] = {};
                return undefined;
            }
            else {
                return this.entities[entityName + "&&&" + connectionId]["filters"];
            }
        },
        getEntityCustomFeilds: (entityName, connectionId) => {
            if (this.entities[entityName + "&&&" + connectionId] == undefined) {
                this.entities[entityName + "&&&" + connectionId] = {};
                return undefined;
            }
            else {
                return this.entities[entityName + "&&&" + connectionId]["customfeilds"];
            }
        },
        getEntityCurrency: (entityName, connectionId) => {
            if (this.entities[entityName + "&&&" + connectionId] == undefined) {
                this.entities[entityName + "&&&" + connectionId] = {};
                return undefined;
            }
            else {
                return this.entities[entityName + "&&&" + connectionId]["currency"];
            }
        },
        addFilters: (connId, entityName, stringClassName, numberClassName, dateClassName) => {
            var filterArray = [];
            var filtersKeyValueMap = {};
            var filtersCount = 0;
            var that = this;
            var isValid = true;
            $(stringClassName).each(function () {
                var $this = this;
                var filterObject = {};

                if ($($this).is(":checked")) {
                    var columnName = $($this).attr("data-child");
                    var myObjects = Object.keys(filtersKeyValueMap).map(function (itm) { return filtersKeyValueMap[itm]; });
                    if (filtersKeyValueMap != undefined && myObjects != undefined
                        && filtersKeyValueMap[$($this).attr("data-child")] != undefined && Object.keys(filtersKeyValueMap[$($this).attr("data-child")]).length > 0) {
                        filtersKeyValueMap[$($this).attr("data-child")]["data"] += "@@" + $($this).parent().text();
                    } else {
                        filterObject["dataType"] = $($this).attr("data-type");
                        filterObject["tablename"] = $($this).attr("data-parent");
                        filterObject["columndisplayname"] = $($this).attr("displayname");
                        filterObject["columnname"] = $($this).attr("data-child");
                        filterObject["data"] = $($this).parent().text();
                        filterObject["reportfiltersid"] = filtersCount + 1;
                        filtersKeyValueMap[$($this).attr("data-child")] = filterObject;
                        filterArray.push(filterObject);
                    }
                }
            });
            $(numberClassName).each(function () {
                var filterObject = {};
                var $this = this;
                var th = $($this);
                var displayname = $($($this).find("input")).attr("displayname").replace(/([#;?&,%.+*~\':"!^$%[\]()<=>|\/@\s])/g, "");
                var filterlessthanvalue = $(numberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filterlessthanvalue").val();
                var filtergreaterthanvalue = $(numberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filtergreaterthanvalue").val();
                var columnName = $($($this).find("input")).attr("data-child");
                var isCustomField = false;

                if ($($($this).find("input")).attr("data-type") === "number") {
                    filterObject["dataType"] = $($($this).find("input")).attr("data-type");
                    filterObject["tablename"] = $($($this).find("input")).attr("data-parent");
                    filterObject["columndisplayname"] = $($($this).find("input")).attr("displayname");
                    filterObject["columnname"] = $($($this).find("input")).attr("data-child");
                    if ((filterlessthanvalue == "" && filtergreaterthanvalue == "")) {
                        filterObject["fromRange"] = $(numberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').old_from;
                        filterObject["toRange"] = $(numberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').old_to;
                    }
                    else {
                        filterObject["filtergreaterthanarequalvalue"] = filtergreaterthanvalue;
                        filterObject["filterlessthanarequalvalue"] = filterlessthanvalue;
                    }
                    filterObject["customfiltervalue"] = $($($this).find("input[type='text']")).val();
                    filtersKeyValueMap[$($this).attr("data-child")] = filterObject;
                    filterArray.push(filterObject);
                }
            });
            $(dateClassName + "[data-type = 'date']").each(function () {
                var $this = this;
                var filterObject = {};
                filterObject["dataType"] = $($this).attr("data-type");
                filterObject["tablename"] = $($this).attr("data-parent");
                filterObject["columndisplayname"] = $($this).attr("displayname");
                filterObject["columnname"] = $($this).attr("data-child");
                filterObject["daterange"] = $($this).attr("date-range");
                var text = $($this).text();
                var dateArray = text.split("-");
                var fromdate = dateArray[0].trim();//Date.parse(dateArray[0].trim());
                var todate = dateArray[1].trim() //Date.parse(dateArray[1].trim());
                if (filterObject["daterange"] == "custom") {
                    var fromdate = dateArray[0].trim() + " 00:00:00";
                    var todate = dateArray[1].trim() + " 23:59:59";
                }
                filterObject["fromRange"] = fromdate;
                filterObject["toRange"] = todate;
                filtersKeyValueMap[$($this).attr("data-child")] = filterObject;
                filterArray.push(filterObject);
            });
            var filterOrder = [];
            $("#workspaceitemsettingsfiltercontainer #filteraccordion [data-accordion-id]").each(function(index,a){
                var name = $(a).find('.acc-content').attr("data-id");
                filterOrder.push(name);
            });
            if(filterOrder!=undefined && filterOrder.length!=0){
                filterArray = _.sortBy(filterArray, function(obj){ 
                                    return _.indexOf(filterOrder, obj.columnname);
                                   });
            }
            this.WorkSpaceEntityMetaDataCollection.updateEntityFilters(filterArray, entityName, connId);
            if (this.WorkSpaceEntityMetaDataCollection.getValues(filtersKeyValueMap) === undefined || this.WorkSpaceEntityMetaDataCollection.getValues(filtersKeyValueMap).length === 0) {
                isValid = false;
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning128'));
                return
            }
            if (isValid) {
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Success');
                $('.birdmessage-info').empty().text(getMessage('BirdSuccess56'));
            }
        },
        getValues: function (obj) {
            var values = Object.keys(obj).map(function (e) {
                return obj[e]
            });
            return values;
        }
    }
    workspaceCustomQueryCollection = {
        resetCollection: () => {
            this.custqueentities = {};
            this.wscqnames = [];
            this.wscsqQueries = {};
        },
        updatewsCustomQueryEntityColumns: (columns, entityName, connectionId) => {
            this.custqueentities[entityName + "&&&" + connectionId] = columns;
        },
        getwsCustomQueryEntityColumns: (entityName, connectionId) => {
            if (this.custqueentities[entityName + "&&&" + connectionId] === undefined) {
                this.custqueentities[entityName + "&&&" + connectionId] = {};
                return undefined;
            }
            else {
                return this.custqueentities[entityName + "&&&" + connectionId];
            }
        },
        updatewsCustomQueryQueries: (query, entityName, connectionId) => {
            this.wscsqQueries[entityName + "&&&" + connectionId] = query;
        },
        getwsCustomQueryQueries: (entityName, connectionId) => {
            if (this.wscsqQueries[entityName + "&&&" + connectionId] === undefined) {
                this.wscsqQueries[entityName + "&&&" + connectionId] = {};
                return undefined;
            }
            else {
                return this.wscsqQueries[entityName + "&&&" + connectionId];
            }
        },
        addWsCustomQueryName: (name) => {
            this.wscqnames.push(name);
        },
        getWsCustomQueryNames: () => {
            return this.wscqnames;
        }
    }

    applyworkspacefilters = (connId, entityName) => {
        var that = this;
        var stringClassName = ".filtervalue";
        var numberClassName = ".filterrangevalues";
        var dateClassName = ".daterange-center";

        this.WorkSpaceEntityMetaDataCollection.addFilters(connId, entityName, stringClassName, numberClassName, dateClassName);
    }
    applyworkspacecurrency = (connId, entityName) => {
        var that = this;
        let currency = {}
        currency["entity_name"] = entityName;
        currency["date_columns"] = $(".datecolumns .singleliitems").attr('data-name');
        currency["base_currency"] = $(".basecurrencycolumns .singleliitems").attr('data-name');
        let currency_columns = "";
        $.each($(".currencycolumns .singleliitems"), function () {
            currency_columns += $(this).attr('data-name') + ",";
        })
        if (currency_columns.length > 0) {
            currency_columns = currency_columns.substring(0, currency_columns.length - 1);
        }
        currency["currency_columns"] = currency_columns;
        let output_currency = "";

        $.each($(".outputcurrencycolumns .singleliitems"), function () {
            output_currency += $(this).attr('data-name') + ",";
        })
        if (output_currency.length > 0) {
            output_currency = output_currency.substring(0, output_currency.length - 1);
        }
        currency["output_currency"] = output_currency;
        this.WorkSpaceEntityMetaDataCollection.updateEntityCurrency(currency, entityName, connId)
    }
    onWorkSpaceEntitiesLoad = (addExisitngEntities) => {
        $("#workspaceentitycontainer").sortable({
            revert: "100",
        });
        $("#scopeItem ul.list-group>li").draggable({
            connectToSortable: "#workspaceentitycontainer",
            revert: "100",
            scroll: false,
            helper: 'clone',
            addClasses: true,
            drag: (event, ui) => {
                $(ui.helper.context).draggable({ disabled: true }).addClass('disabled')
            },
            stop: (event, ui) => {
                var dragText = $(ui.helper.context).find("input").attr("data-value");
                var connectionId = $(ui.helper.context).find("input").attr("data_parent");
                var entityId = $(ui.helper.context).find("input").attr("entity_id");
                setTimeout(function () {
                    if ($("#workspaceentitycontainer li:not('.ui-draggable-dragging')").find("input[data-value='" + dragText + "'][data_parent='" + connectionId + "'][entity_id='" + entityId + "']").length != 1) {
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "'][entity_id='" + entityId + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "'][entity_id='" + entityId + "']").prop('checked', false);
                    }
                }, 500);
            }
        });

        $("ul, li").disableSelection();
        $("#workspaceentitycontainer").droppable({
            hoverClass: "highlight",
            activeClass: "highlight",
            out: (event, ui) => {
                var self = ui;
                var dragText = ui.draggable.find("input").attr("data-value");

            },
            drop: (event, ui) => {
                $('#settingscontainer').removeClass("showsettings");
                $("#workspaceentitycontainer>li").removeClass('active');
                $("#workspaceentitycontainer").removeClass('settingsopened');
                var dragText = ui.draggable.find("input").attr("data-value");
                var connectionId = ui.draggable.find("input").attr("data_parent");
                var entityId = ui.draggable.find("input").attr("entity_id");
                setTimeout(function () {
                    if ($("#workspaceentitycontainer").find("input[data-value='" + dragText + "'][data_parent='" + connectionId + "'][entity_id='" + entityId + "']").length === 1) {
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "'][entity_id='" + entityId + "']").parent().parent().draggable({ disabled: true }).addClass("disabled");
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "'][entity_id='" + entityId + "']").prop('checked', true);
                    } else {
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "'][entity_id='" + entityId + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "'][entity_id='" + entityId + "']").prop('checked', false);
                    }
                }, 500);
                $('.editworkspaceitem').click((e) => { this.editworkspaceitem(e); });
                $(".joinremove").click((e) => { this.joinremove(e); });
                $(".viewreport-list-item .form-check input").click((e) => { this.factcheck(e); });
            },
            over: (event, ui) => {
            }
        });
        if (addExisitngEntities) {
            var that = this;
            var reg = new RegExp('^[0-9]+$');
            if (this.workspaceId != "" && this.workspaceId != undefined && !reg.test(this.workspaceId)) {
                this.workspaceId = atob(this.workspaceId);
            } else {
                this.workspaceId = this.workspaceId;
            }
            SendRequest({
                url: "getworkspaceentities",
                queryString: "?workspaceId=" + this.workspaceId + "&&isFromBmodel=" + false,
                sucessFunction: (response) => {
                    if (!response.hasOwnProperty('error')) {
                        this.oldEntityArray = [];
                        ReactDOM.render(
                            <WorkspaceExistingEntitiesHtml
                                entities={response}
                            />,
                            document.querySelector('#workspaceentitycontainer')
                        );
                        _.each(response, (i, j) => {
                            var entityObject = {};
                            if (i.fact == 1) {
                                $(".fact[data_parent='" + i.connection_access_id + "'][name='" + i.entityname + "']").prop("checked", true)
                            }
                            $(".filtercontent input[data-value='" + i.entityname + "'][data_parent='" + i.connection_access_id + "']").parent().parent().draggable({ disabled: true }).addClass("disabled");
                            $(".filtercontent input[data-value='" + i.entityname + "'][data_parent='" + i.connection_access_id + "']").prop('checked', true);
                            that.workspaceName = i.workspacename;
                            that.workspaceDesc = i.workspacedesc;
                            that.metadataObj = i.wsmetadata != undefined && i.wsmetadata != null ? JSON.parse(i.wsmetadata) : {};
                            that.setState({ wsmetadata: this.metadataObj })
                            that.dataSyncDetails = i.dataSyncDetails;
                            if (i.entitytype != undefined && i.entitytype == "customquery") {
                                i.connection_access_id = "wscsq";
                                var hub_id = i.datahubid;
                                that.workspaceCustomQueryCollection.addWsCustomQueryName(i.entityname);
                                that.workspaceCustomQueryCollection.updatewsCustomQueryEntityColumns(i, i.entityname, "wscsq");
                                that.workspaceCustomQueryCollection.updatewsCustomQueryQueries(i.query, i.entityname, "wscsq");
                                var width = $("#workspacedatahubitemscontainer").width() - 43;
                                if (i.fact == 1) {
                                    $("#workspaceentitycontainer").append('<li class="viewreport-list-item workspace-customquery-item activefact" style="width:' + width + 'px"><label class="scopeitem" title=' + i.entityname + '><i class="fa fa-code"><input type="checkbox" class="scopeitem wscustomqueryscope" data_type="existing" name=' + i.entityname + ' entity_id=' + i.entityId + ' hub_id=' + hub_id + ' data-value=' + i.entityname + '></i>' + i.entityname + '</label><button class="btn btn-transparent btn-sm editworkspacecustomquery" type="button" data_type="existing" entity_id=' + i.entityId + ' wscqname="' + i.entityname + '"  workspace_entity_id=' + i.workspaceentityid + ' title=' + lang["workspace.editcustomquery"] + '><i class="fa fa-cog"></i></button><button class="btn btn-transparent btn-sm joinremove" workspace_entity_id=' + i.workspaceentityid + ' type="button" ><i class="fa fa-times"></i></button><div class="form-check checkbox-slider--b-flat checkbox-slider-sm"><label><input class="fact" type="checkbox" data_parent="wscsq" data_type="existing" hub_id=' + hub_id + ' name=' + i.entityname + '><span></span></label></div></li>');
                                    $(".fact[data_parent='" + i.connection_access_id + "'][name='" + i.entityname + "']").prop("checked", true)
                                } else {
                                    $("#workspaceentitycontainer").append('<li class="viewreport-list-item workspace-customquery-item" style="width:' + width + 'px"><label class="scopeitem" title=' + i.entityname + '><i class="fa fa-code"><input type="checkbox" class="scopeitem wscustomqueryscope" data_type="existing" name=' + i.entityname + ' entity_id=' + i.entityId + ' hub_id=' + hub_id + ' data-value=' + i.entityname + '></i>' + i.entityname + '</label><button class="btn btn-transparent btn-sm editworkspacecustomquery" type="button" data_type="existing" entity_id=' + i.entityId + ' wscqname="' + i.entityname + '" workspace_entity_id=' + i.workspaceentityid + ' title=' + lang["workspace.editcustomquery"] + '><i class="fa fa-cog"></i></button><button class="btn btn-transparent btn-sm joinremove" workspace_entity_id=' + i.workspaceentityid + ' type="button" ><i class="fa fa-times"></i></button><div class="form-check checkbox-slider--b-flat checkbox-slider-sm"><label><input class="fact" type="checkbox" data_parent="wscsq" data_type="existing" hub_id=' + hub_id + ' name=' + i.entityname + '><span></span></label></div></li>');
                                }
                            }
                            var existingColumns = that.WorkSpaceEntityMetaDataCollection.getEntityColumns(i.entityname, i.connection_access_id);
                            if (existingColumns == undefined) {
                                that.WorkSpaceEntityMetaDataCollection.updateEntityColumns(i.columns, i.entityname, i.connection_access_id);
                            }
                            var existingfilters = that.WorkSpaceEntityMetaDataCollection.getEntityFilters(i.entityname, i.connection_access_id);
                            if (existingfilters == undefined && i.filters != undefined && i.filters != "") {
                                that.WorkSpaceEntityMetaDataCollection.updateEntityFilters(JSON.parse(i.filters), i.entityname, i.connection_access_id);
                            }
                            var existingcustomFields = that.WorkSpaceEntityMetaDataCollection.getEntityCustomFeilds(i.entityname, i.connection_access_id);
                            if (existingcustomFields == undefined && i.customfields != undefined && i.customfields != "") {
                                that.WorkSpaceEntityMetaDataCollection.updateEntityCustomFeilds(JSON.parse(i.customfields), i.entityname, i.connection_access_id);
                            }
                            var existingCurrency = that.WorkSpaceEntityMetaDataCollection.getEntityCurrency(i.entityname, i.connection_access_id);
                            if (existingCurrency == undefined) {
                                that.WorkSpaceEntityMetaDataCollection.updateEntityCurrency(i.currency, i.entityname, i.connection_access_id);
                            }
                            entityObject['name'] = i.entityname;
                            if (i.columns != undefined && i.columns != "") {
                                entityObject['entitycolumns'] = JSON.parse(i.columns);
                            }
                            entityObject['entitytype'] = i.entitytype;
                            if (i.filters != undefined && i.filters != "") {
                                entityObject['entityfilters'] = JSON.parse(i.filters);
                            }
                            if (i.customfields != undefined && i.customfields != "") {
                                entityObject['entityCustomfeilds'] = JSON.parse(i.customfields);
                            }
                            if (i.entitytype != undefined && i.entitytype == "customquery") {
                                entityObject['customquery'] = i.query;
                            }
                            entityObject['connId'] = (i.connection_access_id != undefined && i.connection_access_id == "wscsq") ? 0 : i.connection_access_id;
                            entityObject['entityname'] = i.entityname;
                            entityObject['fact'] = i.fact;
                            entityObject['entityId'] = i.entityId
                            entityObject['source'] = i.connName;
                            this.oldEntityArray.push(entityObject);

                            if (i.entitySaveSatus != undefined && i.entitySaveSatus == "failed") {
                                var ent = $("#workspaceentitycontainer>li [name='" + i.entityname + "']").closest('li');
                                ent.removeClass("active");
                                ent.removeClass("warning");
                                ent.addClass("error");
                                if (i.error_info != undefined && i.error_info != "") {
                                    var errorTrace = i.error_info;
                                    that.WorkSpaceEntityMetaDataCollection.updateErrorInfo(errorTrace, i.workspaceentityid);
                                    ent.prepend('<span class="errorinfo"  workspace_entity_id=' + i.workspaceentityid + '  title=' + lang["workspace.errorinfo"] + '><i class="fa fa-info"></i></span>');
                                }
                                $('.errorinfo').click((e) => { this.showerrorinfo(e); })
                            }
                        });
                        if (that.workspaceId != undefined && that.workspaceId != '') {
                            setTimeout(function () {
                                var path = '<li><a class="navDashboard" href="#data"><i class="fa fa-database fa-solid"></i> ' + lang["workspace.data"] + '</a></li><li><a class="navDashboard" href="#workspace"><i class="fa fa-cubes fa-solid"></i>' + lang["workspace.title"] + '</a></li><li><a href="#workspace/' + btoa(that.workspaceId) + '"><i class="fa fa-cubes fa-solid"></i> ' + that.workspaceName + '</a></li>'
                                $(".navbar.navbar-bird ul.nav-path").html(path)
                            }, 200);
                            $('.datamain-fixed-head-container .subhead').find('span').text(that.workspaceName)
                        }
                        $("ul, li").disableSelection();
                        $('.joinremove').click((e) => { this.joinremove(e); });
                        $('.viewreport-list-item .form-check input').click((e) => { this.factcheck(e); });
                        $('.editworkspaceitem').click((e) => { this.editworkspaceitem(e); });
                        $('.editworkspacecustomquery').click((e) => { this.editworkspacecustomquery(e); });
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "get",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            })
        }
    }
    showerrorinfo = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var that = this;
        var workspaceentityid = $($this).attr("workspace_entity_id");
        if (that.WorkSpaceEntityMetaDataCollection.getErrorInfo(workspaceentityid) != undefined && that.WorkSpaceEntityMetaDataCollection.getErrorInfo(workspaceentityid) != "") {
            var errorInfo = JSON.parse(that.WorkSpaceEntityMetaDataCollection.getErrorInfo(workspaceentityid));
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('hide');
            $('.birdmessage h2').empty().append('Error : ');
            $('.birdmessage h2').append('<span class="errorcode">');
            $('.birdmessage .errorcode').empty().append(errorInfo.errorCode);
            $('.birdmessage-info').empty().text(errorInfo.error);
            $('.details').addClass('show').text("Details");
            $('.messagedetails xmp').empty().append(errorInfo.errorTrace);
        }
    }
    editworkspaceitem = (e) => {
        e.preventDefault();
        var that = this;
        this.isCustomQuery = false;
        var $this = e.currentTarget;
        $("#workspaceentitycontainer>li").removeClass('active');
        $("#settingscontainer").remove();
        $("#workspaceentitycontainer").after('<div id="settingscontainer"></div>');
        var entityId = $($this).attr("entity_id");
        var data_type = $($this).attr("data_type");
        var data_parent = $($this).attr("data_parent");
        var entityName = $($this).attr("name");
        var hubId = $($this).attr("hub_id");
        var entitySourceName = $($this).parent().find('.source').text();
        var workspace_entity_id = $($this).attr('workspace_entity_id')?$($this).attr('workspace_entity_id'):0;
        var existingColumns = this.WorkSpaceEntityMetaDataCollection.getEntityColumns(entityName, data_parent);
        if (existingColumns == undefined) {
            SendRequest({
                url: "getentitymetadata",
                queryString: "?entityId=" + entityId + "&&type=" + data_type,
                sucessFunction: (response) => {
                    that.WorkSpaceEntityMetaDataCollection.updateEntityColumns(response, entityName, data_parent);
                    if (!response.hasOwnProperty('error')) {
                        ReactDOM.render(
                            <WorkspaceItemSettings
                                columns={that.WorkSpaceEntityMetaDataCollection.getEntityColumns(entityName, data_parent)}
                                connId={data_parent}
                                entityName={entityName}
                                entityId={entityId}
                                filters={that.WorkSpaceEntityMetaDataCollection.getEntityFilters(entityName, data_parent)}
                                customfields={that.WorkSpaceEntityMetaDataCollection.getEntityCustomFeilds(entityName, data_parent)}
                                workspaceId={that.workspaceId}
                                collection={that.WorkSpaceEntityMetaDataCollection}
                                hubId={hubId}
                                entitySourceName={entitySourceName}
                                workspaceCustomQueryCollection={this.workspaceCustomQueryCollection}
                                createworkspacecustomfield={this.createworkspacecustomfield}
                                editworkspaceitem={true}
                                data_type={data_type}
                                applyworkspacefilters={this.applyworkspacefilters}
                                applyworkspacecurrency={this.applyworkspacecurrency}
                                workspace_entity_id={workspace_entity_id}
                            />,
                            document.querySelector('#settingscontainer')
                        );
                        $($this).closest("li").addClass('active');
                        $("#settingscontainer").addClass("showsettings");
                        $("#workspaceentitycontainer").addClass('settingsopened');
                        setTimeout(function () {
                            var container = $("#workspaceentitycontainer"),
                                scrollTo = $($this).closest("li");
                            container.animate({
                                scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - container.height() / 2
                            });
                        }, 1000);
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "get",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            })
        }
        else {
            ReactDOM.render(
                <WorkspaceItemSettings
                    columns={that.WorkSpaceEntityMetaDataCollection.getEntityColumns(entityName, data_parent)}
                    connId={data_parent}
                    entityName={entityName}
                    entityId={entityId}
                    filters={that.WorkSpaceEntityMetaDataCollection.getEntityFilters(entityName, data_parent)}
                    customfields={that.WorkSpaceEntityMetaDataCollection.getEntityCustomFeilds(entityName, data_parent)}
                    workspaceId={that.workspaceId}
                    collection={that.WorkSpaceEntityMetaDataCollection}
                    hubId={hubId}
                    entitySourceName={entitySourceName}
                    workspaceCustomQueryCollection={this.workspaceCustomQueryCollection}
                    createworkspacecustomfield={this.createworkspacecustomfield}
                    editworkspaceitem={true}
                    data_type={data_type}
                    applyworkspacefilters={this.applyworkspacefilters}
                    applyworkspacecurrency={this.applyworkspacecurrency}
                    workspace_entity_id={workspace_entity_id}
                />,
                document.getElementById('settingscontainer')
            );
            $($this).closest("li").addClass('active');
            $("#settingscontainer").addClass("showsettings");
            $("#workspaceentitycontainer").addClass('settingsopened');
            setTimeout(function () {
                var container = $("#workspaceentitycontainer"),
                    scrollTo = $($this).closest("li");
                container.animate({
                    scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - container.height() / 2
                });
            }, 1000);
        }

    }
    editworkspacecustomquery = (e) => {
        e.preventDefault();
        var that = this;
        var $this = e.currentTarget;
        this.wscustomquelemet = e.currentTarget;
        this.isCustomQuery = true;
        var entitiname = $($this).attr('wscqname');
        var data_type = $($this).attr("data_type");
        var hubId = $($this).attr("hub_id");
        var workspace_entity_id = $($this).attr('workspace_entity_id')?$($this).attr('workspace_entity_id'):0;
        $("#workspaceentitycontainer>li").removeClass('active');
        $($this).closest("li").removeClass('warning');
        $("#settingscontainer").remove();
        $("#workspaceentitycontainer").after('<div id="settingscontainer"></div>');
        ReactDOM.render(
            <WorkspaceItemSettings
                type={data_type}
                isCustomQuery={true}
                columns={this.WorkSpaceEntityMetaDataCollection.getEntityColumns(entitiname, "wscsq")}
                connId={undefined}
                entityName={entitiname}
                entityId={undefined}
                filters={this.WorkSpaceEntityMetaDataCollection.getEntityFilters(entitiname, "wscsq")}
                customfields={this.WorkSpaceEntityMetaDataCollection.getEntityCustomFeilds(entitiname, "wscsq")}
                workspaceId={this.workspaceId}
                workspaceCustomQueryCollection={this.workspaceCustomQueryCollection}
                collection={this.WorkSpaceEntityMetaDataCollection}
                hubId={hubId}
                data_type={data_type}
                applyworkspacefilters={this.applyworkspacefilters}
                validateworkspacecustomquery={this.validateworkspacecustomquery}
                saveworkspacecustomquery={this.saveworkspacecustomquery}
                createworkspacecustomfield={this.createworkspacecustomfield}
                editworkspaceitem={false}
                applyworkspacecurrency={this.applyworkspacecurrency}
                workspace_entity_id={workspace_entity_id}
            />,
            document.getElementById('settingscontainer')
        );
        $($this).closest("li").addClass('active');
        //$("#settingscontainer").addClass("showsettings");
        $("#workspaceentitycontainer").addClass('settingsopened');
        setTimeout(function () {
            var container = $("#workspaceentitycontainer"),
                scrollTo = $($this).closest("li");
            container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - container.height() / 2
            });
        }, 1000);
        $("#workspacecustomquerytab").click();
        if (this.workspaceId != undefined && this.workspaceId != null && this.workspaceId != "" && this.workspaceId != 0 && data_type != "new") {
            this.vaidatequery("edit");
        }
    }
    vaidatequery = (isSave) => {
        $(".spinner").show();
        var that = this;
        var connectionJsObj = {};
        let aceeditval = ace.edit("advancedcustomquery");
        aceeditval.getSession().setValue(format(aceeditval.getValue()));
        var customQueryVal = ace.edit("advancedcustomquery").getValue().trim().replace(/\s+/g, ' ');
        customQueryVal = customQueryVal.replace("! ", "!")
        if (customQueryVal == "" || customQueryVal == undefined) {
            $(".spinner").hide();
            $(".wsadvancedcustomquery").addClass("error");
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning135'));
            return
        } else {
            $(".wsadvancedcustomquery").removeClass("error");
        }
        connectionJsObj["command"] = "validateQuery";
        connectionJsObj["query"] = customQueryVal;
        var data = new FormData();
        data.append("connectionObject", JSON.stringify(connectionJsObj));
        SendRequest({
            url: "validatecustomquery",
            queryString: "",
            body: data,
            sucessFunction: (response) => {
                if (response.success === true) {
                    if (isSave || (isSave == "edit")) {
                        var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
                        var status = false;
                        var oldname = $(that.wscustomquelemet).attr('wscqname');
                        var wscustomquname = $('.wscustomqueryname').val();
                        var wscqnames = that.workspaceCustomQueryCollection.getWsCustomQueryNames();
                        var allentitiesNames = dataHubEntitiesCollection.getAllEntityNames();
                        if (wscustomquname != undefined && wscustomquname != "") {
                            var reservedwordCheck = wscustomquname.toLowerCase();
                            _.each(reservedwordslist, function (reservedword, j) {
                                if (reservedwordCheck == reservedword) {
                                    status = true
                                }
                            });
                        }
                        if (wscustomquname == undefined || wscustomquname == "") {
                            $(".spinner").hide();
                            $(".cscqnamespecailcharacter").hide();
                            $(".cscqnamevalidate").show();
                        } else if (status) {
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Warning');
                            $('.birdmessage-info').empty().text("Custom Query" + "" + getMessage('BirdWarning147'));
                            $(".spinner").hide();
                            return;
                        }
                        else if (wscustomquname != undefined && wscustomquname != "" && /[~`!@#$%^&*()+\=\[\]{};':\\|†,.<>\/?" ""`]/.test(wscustomquname)) {
                            $(".spinner").hide();
                            $(".cscqnamevalidate").hide();
                            $(".cscqnameexists").hide();
                            $(".cscqnamespecailcharacter").show();
                        } else if (oldname == "" && (wscqnames.indexOf(wscustomquname) != -1 || allentitiesNames.indexOf(wscustomquname) != -1)) {
                            $(".spinner").hide();
                            $(".cscqnamevalidate").hide();
                            $(".cscqnamespecailcharacter").hide();
                            $(".cscqnameexists").show();
                        } else if (oldname != "" && oldname != wscustomquname && (wscqnames.indexOf(wscustomquname) != -1 || allentitiesNames.indexOf(wscustomquname) != -1)) {
                            $(".spinner").hide();
                            $(".cscqnamevalidate").hide();
                            $(".cscqnamespecailcharacter").hide();
                            $(".cscqnameexists").show();
                        } else {
                            $(".cscqnamespecailcharacter").hide();
                            $(".cscqnamevalidate").hide();
                            $(".cscqnameexists").hide();
                            connectionJsObj["command"] = "getEntities";
                            connectionJsObj["isCustomQuery"] = true;
                            var wscustomquname = $('.wscustomqueryname').val().trim();
                            $(that.wscustomquelemet).parent().find('label[class=scopeitem]').contents().last()[0].textContent = wscustomquname;
                            $(that.wscustomquelemet).parent().find('input[class="scopeitem wscustomqueryscope"]').attr('id', wscustomquname);
                            $(that.wscustomquelemet).parent().find('input[class="scopeitem wscustomqueryscope"]').attr('wscqname', wscustomquname);
                            $(that.wscustomquelemet).parent().find('input[class="scopeitem wscustomqueryscope"]').attr('name', wscustomquname);
                            $(that.wscustomquelemet).parent().find('input[class="fact"]').attr('name', wscustomquname);
                            $(that.wscustomquelemet).attr('wscqname', wscustomquname);
                            connectionJsObj["entityname"] = wscustomquname;
                            connectionJsObj["indexName"] = "ws_" + wscustomquname;
                            var info = new FormData();
                            info.append("connectionObject", JSON.stringify(connectionJsObj));
                            SendRequest({
                                url: "validatecustomquery",
                                queryString: "",
                                body: info,
                                sucessFunction: (response) => {
                                    var colsArray = [];
                                    if (!response.hasOwnProperty('error')) {
                                        that.workspaceCustomQueryCollection.addWsCustomQueryName(wscustomquname);
                                        that.WorkSpaceEntityMetaDataCollection.getEntityColumns(wscustomquname, "wscsq");
                                        that.workspaceCustomQueryCollection.updatewsCustomQueryQueries(customQueryVal, wscustomquname, "wscsq");
                                        that.workspaceCustomQueryCollection.updatewsCustomQueryEntityColumns(response, wscustomquname, "wscsq");
                                        that.WorkSpaceEntityMetaDataCollection.updateEntityColumns(response.entities, wscustomquname, "wscsq");
                                        if (response.entities != undefined && response.entities != null) {
                                            var cols = response.entities;
                                            cols.map((colObj, index) => {
                                                colsArray.push(colObj.columnName);
                                            })
                                        }
                                        // To Update filters on customquery update
                                        if (that.WorkSpaceEntityMetaDataCollection.getEntityFilters(wscustomquname, "wscsq") != undefined &&
                                            that.WorkSpaceEntityMetaDataCollection.getEntityFilters(wscustomquname, "wscsq") != null) {
                                            var filters = that.WorkSpaceEntityMetaDataCollection.getEntityFilters(wscustomquname, "wscsq");
                                            var finalFilters = [];
                                            filters.map((filter, index) => {
                                                if (colsArray.indexOf(filter.columnname) != -1) {
                                                    finalFilters.push(filter);
                                                }
                                            })
                                            that.WorkSpaceEntityMetaDataCollection.updateEntityFilters(finalFilters, wscustomquname, "wscsq");
                                        }

                                        if (that.WorkSpaceEntityMetaDataCollection.getEntityCustomFeilds(wscustomquname, "wscsq") != undefined
                                            && that.WorkSpaceEntityMetaDataCollection.getEntityCustomFeilds(wscustomquname, "wscsq") != null) {
                                            var finalCustomFields = [];
                                            var customfields = that.WorkSpaceEntityMetaDataCollection.getEntityCustomFeilds(wscustomquname, "wscsq");
                                            customfields.map((custObj, index) => {
                                                var addCustomField = true;
                                                var usedFileds = custObj.usedFields;
                                                usedFileds.map((used, index) => {
                                                    if (colsArray.indexOf(used) == -1) {
                                                        addCustomField = false;
                                                    }
                                                })
                                                if (addCustomField) {
                                                    finalCustomFields.push(custObj);
                                                }
                                            })
                                            that.WorkSpaceEntityMetaDataCollection.updateEntityCustomFeilds(finalCustomFields, wscustomquname, "wscsq");
                                        }

                                        // require(['views/workspace/workspaceitemsettingsview'],function(WorkspaceItemSettingsView){
                                        //     var WorkspaceItemSettingsView = new WorkspaceItemSettingsView({
                                        //         isCustomQuery:true,
                                        //         columns :that.workSpaceEntityMetaDataCollection.getEntityColumns(wscustomquname,"wscsq"),
                                        //         connId :undefined,
                                        //         broker:that.broker,
                                        //         entityName:wscustomquname,
                                        //         entityId:undefined,
                                        //         filters:that.workSpaceEntityMetaDataCollection.getEntityFilters(wscustomquname,"wscsq"),
                                        //         customfields:that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(wscustomquname,"wscsq"),
                                        //         workspaceId :that.workspaceId,
                                        //         workspaceCustomQueryCollection:that.workspaceCustomQueryCollection,
                                        //         collection:that.workSpaceEntityMetaDataCollection,
                                        //         hubId:undefined
                                        //     });
                                        //     $("#settingscontainer").addClass("showsettings");
                                        //     $("#workspacecustomquerytab").click();
                                        //     $("#settingscontainer").removeClass("showsettings");
                                        //     setTimeout(function(){$("#settingscontainer").remove();},1000);
                                        // });
                                        $("#settingscontainer").addClass("showsettings");
                                        $("#workspacecustomquerytab").click();
                                        if (isSave != "edit") {
                                            $("#settingscontainer").removeClass("showsettings");
                                            setTimeout(function () { $("#settingscontainer").remove(); }, 1000);
                                        }
                                        $(".spinner").hide();
                                    } else {
                                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                        $('.birdmessagebuttons').removeClass('show');
                                        $('.birdmessagebuttons').addClass('hide');
                                        $('.birdmessage-center').addClass("message-top-in");
                                        $('.details').addClass('hide');
                                        $('.birdmessage h2').empty().append('Error');
                                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                                        $('.birdmessage-info').empty().text(response.error);
                                        $('.details').addClass('show').text("Details");
                                        $('.messagedetails xmp').empty().append(response.errorTrace);
                                        $(".spinner").hide();
                                    }
                                },
                                rejectFunction: (error) => { },
                                headers: {
                                    method: "post",
                                    headers: new Headers({
                                        "Content-Type": "application/json"
                                    })
                                }
                            });
                        }
                    } else {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess49'));
                        $('.spinner').hide();
                    }
                } else {
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Error');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(response.errorTrace);
                    $(".spinner").hide();
                    $("#settingscontainer").addClass("showsettings");
                    setTimeout(function () { $("#settingscontainer").show(); }, 1000);
                }
            },
            rejectFunction: (error) => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    validateworkspacecustomquery = (e) => {
        this.vaidatequery(false);
    }
    saveworkspacecustomquery = (e) => {
        e.stopPropagation();
        this.vaidatequery(true);
    }
    wsforcesync = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var that = this;
        var workspaceId = $($this).attr('workspaceId');
        if (workspaceId != undefined) {
            $(".deleteyes").unbind("click");
            $(".deleteno").unbind("click");
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').addClass('show');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning136'));
            $(".deleteyes").click(function () {
                $(".spinner").show();
                that.saveworkspace(that.workspaceName, that.workspaceDesc, that.dataSyncDetails, true)
                $('.birdmessage-center').removeClass("message-top-in");
            });
            $(".deleteno").click(function () {
                $('.birdmessage-center').removeClass("message-top-in");
            });
        }

    }
    joinremove = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var tablevalue = $($this).parent().find("input").attr("data-value");
        var connectionId = $($this).parent().find("input").attr("data_parent");
        var type = $($this).parent().find("input").attr("data_type");
        var entityId = $($this).attr("workspace_entity_id");
        var isDelete = true
        if (tablevalue != undefined) {
            _.each($("#workspaceentitycontainer .scopeitem"), function (i, j) {
                var $this1 = $(i);
                if (connectionId == $($this1).attr('data_parent') && tablevalue == $($this1).attr('data-value')) {
                    if (type === 'existing') {
                        isDelete = false;

                        $(".deleteyes").unbind("click");
                        $(".deleteno").unbind("click");
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').addClass('show');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning125'));
                        $(".deleteyes").click(function () {
                            $(".spinner").show();
                            isDelete = true;
                            SendRequest({
                                url: "deleteentityforworkspace",
                                queryString: "?entityId=" + entityId + "&&entityName=" + tablevalue,
                                sucessFunction: (response) => {
                                    if (isDelete) {
                                        $(".filtercontent input[data-value='" + tablevalue + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                                        $(".filtercontent input[data-value='" + tablevalue + "']").prop('checked', false);
                                        $("input.fact[name='" + tablevalue + "']").prop('checked', false);
                                        $("#settingscontainer").remove();
                                        $(".spinner").hide();
                                        if ($($this).closest("li").hasClass("workspace-customquery-item")) {
                                            $($this).closest("li").remove();
                                            $("#settingscontainer").remove();
                                        } else {
                                            $($this1).parent().parent().remove()
                                        }
                                        $("#workspaceentitycontainer").removeClass('settingsopened');
                                    }
                                },
                                rejectFunction: (error) => { },
                                headers: {
                                    method: "get",
                                    headers: new Headers({
                                        "Content-Type": "application/json"
                                    })
                                }
                            });
                            $('.birdmessage-center').removeClass("message-top-in");
                        });
                        $(".deleteno").click(function () {
                            $('.birdmessage-center').removeClass("message-top-in");
                        });

                    }
                    if (isDelete) {
                        $(".filtercontent input[data-value='" + tablevalue + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                        $(".filtercontent input[data-value='" + tablevalue + "']").prop('checked', false);
                        $("input.fact[name='" + tablevalue + "']").prop('checked', false);
                        $($this1).parent().parent().remove();
                        $("#workspaceentitycontainer").removeClass('settingsopened');
                        $("#settingscontainer").remove();
                    }
                }
            });
        } else if ($($this).closest("li").hasClass("workspace-customquery-item")) {
            if (isDelete) {
                $(".deleteyes").unbind("click");
                $(".deleteno").unbind("click");
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').addClass('show');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning139'));
                $(".deleteno").click(function () {
                    $('.birdmessage-center').removeClass("message-top-in");
                });
                $(".deleteyes").click(function () {
                    $($this).closest("li").remove();
                    $("#settingscontainer").remove();
                    $("#workspaceentitycontainer").removeClass('settingsopened');
                    $('.birdmessage-center').removeClass("message-top-in");

                });
            }
        }

    }
    workspaceaddcustomquery = (e) => {
        e.stopPropagation();
        e.preventDefault();
        var $this = e.currentTarget;
        var hub_id = $($this).attr("hub_id");
        var width = $("#workspacedatahubitemscontainer").width() - 43;
        $("#workspaceentitycontainer").append('<li class="viewreport-list-item workspace-customquery-item" style="width:' + width + 'px"><label class="scopeitem" title=' + lang["workspace.customquery"] + '><i class="fa fa-code"><input type="checkbox" class="scopeitem wscustomqueryscope" data_type="new" hub_id=' + hub_id + '></i> ' + lang["workspace.customquery"] + '</label><button class="btn btn-transparent btn-sm editworkspacecustomquery" data_type="new" type="button" wscqname="" title=' + lang["workspace.editcustomquery"] + '><i class="fa fa-cog"></i></button><button class="btn btn-transparent btn-sm joinremove" type="button"><i class="fa fa-times"></i></button><div class="form-check checkbox-slider--b-flat checkbox-slider-sm"><label><input class="fact" type="checkbox" data_parent="wscsq" data_type="new" hub_id=' + hub_id + '><span></span></label></div></li>');
        $('.joinremove').click((e) => {
            this.joinremove(e);
        });
        $('.editworkspacecustomquery').click((e) => {
            this.editworkspacecustomquery(e);
        });
        $(".viewreport-list-item .form-check input").click((e) => {
            this.factcheck(e);
        });
    }
    closeconnectionpop = (e) => {
        $(".connection-center").removeClass("connection-in");
    }
    createworkspacecustomfield = (e, data_type) => {
        e.preventDefault();
        e.stopPropagation();
        var $this = e.currentTarget;
        var that = this;

        var entityId = $($this).attr("entity_id");
        var data_parent = $($this).attr("data_parent");
        var entityName = $($this).attr("name");
        var existingColumns = undefined
        var collection;
        if (this.isCustomQuery) {
            data_parent = "wscsq";
        }
        existingColumns = this.WorkSpaceEntityMetaDataCollection.getEntityColumns(entityName, data_parent);
        collection = this.WorkSpaceEntityMetaDataCollection;
        var isEdit = $($this).attr("data-edit");
        var isDelete = $($this).attr("data-delete");
        $(".append_filtercustomfield").remove();
        $("body").append('<div class="append_filtercustomfield"></div>');
        ReactDOM.render(
            <WorkspaceCustomfields
                data_type={data_type}
                customparameters={""}
                isCustomQuery={that.isCustomQuery}
                connectionObject={undefined}
                schemaId={undefined}
                schemaType={undefined}
                isStoredProcedure={undefined}
                isEditCustomFiled={isEdit}
                isDeleteCustomFiled={isDelete}
                editField={$($this).attr("id")}
                deleteField={$($this).attr("id")}
                reportColumns={existingColumns}
                //importId={that.importId}
                entityId={entityId}
                entityName={entityName}
                data_parent={data_parent}
                workSpaceEntityMetaDataCollection={collection}
                tableName={"ws_" + entityName}
            />,
            document.querySelector('.append_filtercustomfield')
        );
    }
    openSaveWorkspaceModal = (e) => {
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getSyncConfigDetails",
            queryString: "?category=Workspace",
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                } else {
                    if (document.getElementById('saveworkspacemodal') != null) {
                        ReactDOM.unmountComponentAtNode(document.getElementById('saveworkspacemodal'));
                    }
                    ReactDOM.render(
                        <SaveWorkSpaceModal
                            syncConfigs={response}
                            workspaceName={that.workspaceName}
                            workspaceDesc={that.workspaceDesc}
                            dataSyncDetails={that.dataSyncDetails}
                            onsaveworkspace={that.saveworkspace}
                            metadataObj={that.metadataObj}
                            checkForCustomQueryOrStreamingEntiyPresent={that.checkForCustomQueryOrStreamingEntiyPresent}
                        />,
                        document.getElementById('saveworkspacemodal')
                    );
                }
            },
            rejectFunction: () => { },
            headers: requestHeaders

        });
    }

    saveworkspace = (workspacename, workspacedescr, syncScheduleData, isForcesync) => {
        $(".spinner").show();
        var that = this;
        var saveObject = {};
        if ($('.syncdomelement').val() != undefined) {
            syncScheduleData = $('.syncdomelement').val();
        }
        if($('.syncdomelement').val() == undefined){
            syncScheduleData = 0;
        }
        saveObject['workspacename'] = workspacename;
        saveObject['workspacedescr'] = workspacedescr;
        var hubId = 0;
        var isValid = true;
        var entityArray = [];
        if (workspacename == undefined || workspacename == "") {
            isValid = false;
        }
        var reservedwordCheck = workspacename.toLowerCase();
        var status = false;
        var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
        _.each(reservedwordslist, function (reservedword, j) {
            if (reservedwordCheck == reservedword) {
                status = true
            }
        });
        if (status) {
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text("WorkSpace " + "" + getMessage('BirdWarning147'));
            $("#savesworkspace").modal("show");
            $(".spinner").hide();
            return;
        }
        if (isForcesync) {
            entityArray = that.oldEntityArray;
        } else {
            _.each($("#workspaceentitycontainer input[type='checkbox'].scopeitem"), function (element) {
                var $this = element;
                hubId = $($this).attr("hub_id");
                saveObject['hubId'] = hubId;
                var entityObject = {};
                var isCustomQuery = false;
                var connId = $($this).attr("data_parent");
                var entityname = $($this).attr("name");
                // var classname = $($this).context.className;
                var classname = $($this).attr('class');
                if (entityname == undefined || entityname == "") {
                    $($this).closest("li").addClass('warning');
                    isValid = false;
                    if (classname.includes("wscustomqueryscope")) {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning143'));
                        $('.spinner').hide();
                    }
                    return
                }
                var entityId = $($this).attr("entity_id");
                var data_parent = $($this).attr("data_parent");
                var data_type = $($this).attr('data_type');
                var entitytype = "dbentity";
                var source = $($this).parent().find("span.source").text();
                var wscqnames = that.workspaceCustomQueryCollection.getWsCustomQueryNames();
                if (wscqnames.indexOf(entityname) != -1) {
                    var csqobj = that.workspaceCustomQueryCollection.getwsCustomQueryEntityColumns(entityname, "wscsq");
                    entitytype = "customquery";
                    entityObject['customquery'] = that.workspaceCustomQueryCollection.getwsCustomQueryQueries(entityname, "wscsq");
                    connId = "wscsq";
                    isCustomQuery = true;
                }
                var $factcheckbox = $(".checkbox-slider--b-flat input:checked[data_parent='" + connId + "'][name='" + entityname + "'][data_type='" + data_type + "']");
                var fact = 0;
                if ($factcheckbox != undefined && $($factcheckbox).attr("data_parent") != undefined) {
                    fact = 1;
                }
                if (that.WorkSpaceEntityMetaDataCollection.getEntityColumns(entityname, connId) == undefined) {
                    _.each(dataHubEntitiesCollection.getAllEntityDetails(), function (conData, i) {
                        if (conData != undefined && conData != null && conData.connectionsData != undefined && conData.connectionsData != null) {
                            var connectionsData = conData.connectionsData;
                            _.each(connectionsData, function (entData, j) {
                                if (entData != undefined && entData.entitiesData != undefined) {
                                    var entitiesData = entData.entitiesData;
                                    _.each(entitiesData, function (entity, k) {
                                        if (entity.entityId == entityId) {
                                            that.WorkSpaceEntityMetaDataCollection.updateEntityColumns(JSON.parse(entity.entityColumns), entityname, data_parent);
                                        }
                                    })
                                }
                            })
                        }
                    });
                }

                var entitycolumns = that.WorkSpaceEntityMetaDataCollection.getEntityColumns(entityname, connId);
                var entityfilters = that.WorkSpaceEntityMetaDataCollection.getEntityFilters(entityname, connId);
                var entityCustomfeilds = that.WorkSpaceEntityMetaDataCollection.getEntityCustomFeilds(entityname, connId);
                var entityCurrency = that.WorkSpaceEntityMetaDataCollection.getEntityCurrency(entityname, connId);
                if (isCustomQuery) {
                    connId = 0;
                }
                entityObject['name'] = entityname;
                entityObject['entitycolumns'] = entitycolumns;
                entityObject['entitytype'] = entitytype;
                entityObject['entityfilters'] = entityfilters;
                entityObject['entityCustomfeilds'] = entityCustomfeilds;
                entityObject['entityCurrency'] = entityCurrency;
                entityObject['connId'] = connId;
                entityObject['entityname'] = entityname;
                entityObject['fact'] = fact;
                entityObject['entityId'] = entityId
                entityObject['source'] = source;
                entityArray.push(entityObject);
            });
        }

        if (isValid) {
            $(".wsname-error").addClass("hidden");
            $(".wsname-error").html("");
            if ($('#workspaceentitycontainer').find("li").length <= 0) {
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning141'));
                $('.spinner').hide();
                return;
            }
            if (!isForcesync) {
                if($("#streamingstatus").prop('checked')){
                    let id = $(".realtimedataselectiontype:checked").attr('id');
                    if(id==="todaylive"){
                        that.metadataObj["type"] = "today"
                    }else if(id==="customlive"){
                        that.metadataObj["type"] = $(".customlive").val();
                    }
                }
                that.metadataObj["isWsLiveEnabled"] = $("#streamingstatus").prop('checked');
            }
            var data = new FormData();
            data.append("workspacename", workspacename);
            data.append("workspacedescr", workspacedescr);
            data.append("hubId", hubId);
            data.append("entities", JSON.stringify(entityArray));
            data.append("workspaceId", that.workspaceId);
            data.append("syncScheduleData", syncScheduleData);
            data.append("wsmetadata", JSON.stringify(that.metadataObj));

            SendRequest({
                url: "saveworkspace",
                queryString: "",
                body: data,
                sucessFunction: (collection) => {
                    if (collection.hasOwnProperty('error')) {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(collection.errorCode);
                        $('.birdmessage-info').empty().text(collection.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(collection.errorTrace)
                        $("#savesworkspace").modal("show");
                        $(".spinner").hide();
                        return;
                    } else {
                        $(".spinner").hide();
                        $("#savesworkspace").modal("hide");
                        setTimeout(() => {
                            this.props.history.push('/' + window.localStorage.getItem("appName") + '/welcome/businessworkspaces');
                        }, 2000);
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        } else {
            $(".spinner").hide();
            $(".wsname-error").removeClass("hidden");
            $(".wsname-error").html(lang["workspace.entervalidname"]);
        }
    }
    search = (e) => {
        var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $("#workspacedbitems input:checkbox[class='scopeitem']").parents(".viewreport-list-item").each(function () {
            var text = $(this).find('label').eq(0).attr("title").toLowerCase();
            if (text.indexOf(valThis) >= 0) {
                $(this).addClass('search-success');
                $(this).removeClass('hide');
            } else {
                $(this).removeClass('search-success');
                $(this).addClass('hide');
            }
        });
        _.each($("#finalconnectionlistcontainer h4.panelboc-title"), function (i) {
            var targetContainer = $(i).attr("href").replace("#", "");
            var successLength = $("#" + targetContainer + " .viewreport-list-item.search-success").length;
            if (successLength > 0) {
                $(i).removeClass("hide");
                $(i).removeClass("collapsed");
                $(i).attr("aria-expanded", true);
                $("#" + targetContainer + "").css("height", "auto")
                $("#" + targetContainer + "").addClass("in");
                $("#" + targetContainer + "").attr("aria-expanded", true);
            } else {
                $(i).addClass("hide");
                $(i).addClass("collapsed");
                $(i).attr("aria-expanded", false);
                $("#" + targetContainer + "").css("height", "0px")
                $("#" + targetContainer + "").removeClass("in");
                $("#" + targetContainer + "").attr("aria-expanded", false);
            }
        });
        _.each($("#workspacedbitems #finalconnectionlistcontainer .panelboc-content h4"), function (i) {
            var successLength = $(i).next().find('li.search-success').length;
            if (successLength > 0) {
                $(i).removeClass("hide");
            } else {
                $(i).addClass("hide");
            }
        });
    }
    factcheck = (e) => {
        var $this = e.currentTarget,
            isChecked = $($this).is(':checked'),
            targetItem = $($this).closest("li");
        if (isChecked) { $(targetItem).addClass('activefact') } else { $(targetItem).removeClass('activefact') }
    }
    workspacesynclogs = (e) => {
        var location = window.location;
        var href = location.origin + "/" + window.localStorage.getItem("appName") + "/welcome/workspace_sync_logbook/" + btoa(this.workspaceId)
        window.open(href, '_blank');
    }
    checkForCustomQueryOrStreamingEntiyPresent = () => {
        var entity_collection = dataHubEntitiesCollection.getAllEntityDetails();
        var streamingentity_id = [];
        var bool = true;
        entity_collection.map(e => {
            if (e != undefined && e != null && e.connectionsData != undefined && e.connectionsData != null) {
                if (e.sourceType.toLowerCase() == 'rabbitmq' || e.sourceType.toLowerCase() == 'kafka'
                    || e.sourceType.toLowerCase() == 'websocket' || e.sourceType.toLowerCase() == 'eventhub') {
                    var connectionsData = e.connectionsData;
                    connectionsData.map(entData => {
                        if (entData != undefined && entData.entitiesData != undefined) {
                            var entitiesData = entData.entitiesData;
                            entitiesData.map(entity => {
                                if (entity.entityId)
                                    streamingentity_id.push(entity.entityId)
                            }
                            )
                        }
                    })
                }
            }
        });


        _.each($("#workspaceentitycontainer input[type='checkbox'].scopeitem"),function(element) {
            var $this = element;
            var entityId = parseInt($($this).attr("entity_id"));
            if (streamingentity_id.indexOf(entityId) != -1) {
                bool = false;
            } else if ($($this).hasClass('wscustomqueryscope')) {
                bool = false;
            }
        });

        return bool;
    }
    render() {
        return (
            <WorkspaceHtml
                wsforcesync={this.wsforcesync}
                displayFooter={this.displayFooter}
                workspaceId={this.workspaceId}
                openSaveWorkspaceModal={this.openSaveWorkspaceModal}
                workspacesynclogs={this.workspacesynclogs}
                wsmetadata={this.state.wsmetadata}
            />
        );
    }
}