
export var campaign_type = "";
export var filter = [];
export var content = "";
export var campaign_name = "";
export var selectedTemplate = "";
export function resetCollection() {
    campaign_type = "";
    filter = [];
    content = "";
    campaign_name = "";
    selectedTemplate = "";
}
export function updateFilter(filterArray) {
    filter = filterArray;
}
export function updateCampaignType(campaignType) {
    campaign_type = campaignType;
}
export function udateContent(campaignContent) {
    content = campaignContent;
}
export function updateCampaignName(name){
    campaign_name = name;
}
export function updateSeletedTemplate(template_name){
    selectedTemplate = template_name;
}
export function getAllData() {
    let obj = {};
    obj["category"] = campaign_type;
    obj["filters"] = filter;
    obj["content"] = content;
    obj["campaign_name"] = campaign_name;
    obj["selectedTemplate"] = selectedTemplate;
    return obj;
}

