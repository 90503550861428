import React, { useState, useEffect } from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import SendRequest from "../SendRequest";
import EditFormData from "./editDataForm"
import SurveyForm from "./surveyForm"
import ReactDOM from "react-dom";
import { FiltersTab } from "./filterstab";
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import * as ChartUtil from '../js/helpers/utils/chartutil';
import { SearchData } from "./searchData";
import CampaignPreview from "./campaignPreview";
import { lang } from "../js/helpers/utils/languages/language";
import { CampaignFormHtml } from "./campaignForm.jsx"
import { getMessage } from '../js/helpers/utils/BirdMessage';

const $ = window.$;
export const EditDataPreview = (props) => {
    const [dataArray, setData] = useState([]);
    const [columns, setTableColumns] = useState([]);
    const [allColumns, setColumns] = useState([]);
    const [allForms, setForms] = useState([]);
    const { isCustomQuery, tableName, entityId, entityName, columnsString, uniqueColumn, connId, filterObject, tableColumns, formDisableColumns, formVisibleColumns } = props.editData
    const isSurvey = window.location.href.indexOf("survey") >= 0 ? true : false;
    const isCampaign = window.location.href.indexOf("campaign") >= 0 ? true : false;
    let  hierachyAccess;
    if(window.localStorage.hierachyAccess){
                hierachyAccess= window.localStorage.hierachyAccess;
     }
    const fetchCompaignData = (filterArray) => {
        if (isCampaign) {
            let columnsArray = JSON.parse(columnsString);
            let data_headers = [], columnheaders = [], allcolumns = [];
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "getAllCampaignData",
                queryString: "?entity_id=" + entityId,
                sucessFunction: response => {
                    $(".spinner").hide();
                    if (response.hasOwnProperty('error')) {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.error)
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                        return;
                    }
                    let { header, data } = response;
                    let keysArray = Object.keys(header);
                    let formatter_keys = ["campaign_edit", "campaign_download", "campaign_delete", "campaign_preview"]
                    keysArray.map((key, i) => {
                        if (formatter_keys.indexOf(key) != -1) {
                            columnheaders.push({
                                id: (i + 1) + "",
                                name: key,
                                field: header[key],
                                sortable: false,
                                removable: true,
                                tooltip: key,
                                type: "string",
                                isKey: key === "id" ? true : false,
                                hidden: key === "id" ? true : false,
                                formatter: formatter
                            });
                            return
                        }
                        columnheaders.push({
                            id: (i + 1) + "",
                            name: key,
                            field: header[key],
                            sortable: true,
                            removable: true,
                            tooltip: key,
                            type: "string",
                            isKey: key === "id" ? true : false,
                            hidden: key === "id" ? true : false
                        });

                    })
                    columnsArray.map((val, i) => {
                        allcolumns.push({
                            id: i + "",
                            name: val.columnName,
                            field: val.columnName,
                            sortable: true,
                            removable: true,
                            tooltip: val.columnName,
                            type: val.tableDisplayType ? val.tableDisplayType : val.type,
                            isKey: val.columnName == uniqueColumn ? true : false
                        });
                    })
                    data_headers = [...data_headers, ...columnheaders]
                    setTableColumns(data_headers);
                    setData(data)
                    setColumns(allcolumns)
                }, rejectFunction: () => { },
                headers: requestHeaders
            });
        }
    }
    const fetchData = (filterArray, searchText) => {
        if (isCampaign) {
            $('.spinner').hide()
            return;
        }
        /////////////////////// adding custom filters if any ////////////////////////////////////
        if (filterObject != null && filterObject != undefined && filterObject != "") {
            var userName = window.localStorage.userName;
            if (userName != undefined && userName != null && userName != "") {
                var custFilters = JSON.parse(filterObject.replaceAll('@@@@', userName));
                window.localStorage.setItem("editDataFilters", filterObject.replaceAll('@@@@', userName));
                if (custFilters != undefined && custFilters != null && custFilters != "") {
                    filterArray = filterArray.concat(custFilters);
                }
            }
        }

        /////////////////////// adding custom filters if any ////////////////////////////////////

        var data = new FormData();
        data.append("entityname", entityName);
        data.append("tablename", tableName);
        data.append("hubId", "");
        data.append("filters", JSON.stringify(filterArray));
        data.append("customfields", "");
        data.append("connId", connId);
        data.append("workspaceId", "");
        data.append("customquery", isCustomQuery);
        data.append("columns", JSON.stringify(columnsString));
        if (searchText == undefined) {
            searchText = $("input.searchedittable").val()
        }
        if (searchText) {
            data.append("searchText", searchText);
            data.append("searchColumns", JSON.stringify(tableColumns));
        }
        SendRequest({
            url: "getworkspacePreviewData",
            queryString: "",
            body: data,
            sucessFunction: (response) => {
                $(".spinner").hide();
                if (response.hasOwnProperty('error')) {
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.error)
                    setTimeout(function () {
                        $('.birderror').remove();
                    }, 3000);
                } else {
                    let headers = [];
                    let columnheaders = [];
                    let allcolumns = [];
                    let columnTypes = {};
                    if (response.length == 0) {
                        $("#editdatapreviewempty").text("No data to display");
                        setData([])
                        return
                    }
                    if (isSurvey) {
                        headers.push({
                            id: 0 + "",
                            name: "form",
                            field: "Form",
                            sortable: false,
                            removable: false,
                            tooltip: "Form",
                            type: "string",
                            formatter: formatter
                        })
                    } else {
                        headers.push({
                            id: 0 + "",
                            name: "edit",
                            field: "Edit",
                            sortable: false,
                            removable: false,
                            tooltip: "Edit",
                            type: "string",
                            formatter: formatter
                        })
                    }
                    for (var i = 0; i < response[response.length - 1]["tablecolumns"].length; i++) {
                        var columnName = response[response.length - 1]["tablecolumns"][i];
                        var columnDataTypes = response[response.length - 1]["tableColumnDataTypes"][i];

                        /*if(tableColumns&&tableColumns.indexOf(columnName)!=-1|| uniqueColumn==columnName){
                             columnheaders.push({
                                 id: (i+1) + "",
                                 name: columnName,
                                 field: columnName,
                                 sortable: true,
                                 removable: true,
                                 tooltip: columnName,
                                 type: columnDataTypes,
                                 isKey: columnName == uniqueColumn ? true : false,
                                 hidden:tableColumns&&tableColumns.indexOf(uniqueColumn)==-1&&columnName==uniqueColumn?true:false
                             });
                         }*/
                        allcolumns.push({
                            id: (i + 1) + "",
                            name: columnName,
                            field: columnName,
                            sortable: true,
                            removable: true,
                            tooltip: columnName,
                            type: columnDataTypes,
                            isKey: columnName == uniqueColumn ? true : false
                        });
                        columnTypes[columnName] = columnDataTypes
                    }
                    allcolumns.sort(function (a, b) {
                        var x = a.name.toLowerCase();
                        var y = b.name.toLowerCase();
                        return x < y ? -1 : x > y ? 1 : 0;
                    });
                    tableColumns.map((columnName, i) => {
                        columnheaders.push({
                            id: (i + 1) + "",
                            name: columnName,
                            field: columnName,
                            sortable: true,
                            removable: true,
                            tooltip: columnName,
                            type: columnTypes[columnName] ? columnTypes[columnName] : "string",
                            isKey: columnName == uniqueColumn ? true : false
                        });
                    })
                    if (tableColumns.indexOf(uniqueColumn) == -1) {
                        columnheaders.push({
                            id: (columnheaders.length + 1) + "",
                            name: uniqueColumn,
                            field: uniqueColumn,
                            sortable: true,
                            removable: true,
                            tooltip: uniqueColumn,
                            type: columnTypes[uniqueColumn] ? columnTypes[uniqueColumn] : "string",
                            isKey: true,
                            hidden: true
                        });
                    }
                    headers = [...headers, ...columnheaders]
                    setTableColumns(headers);
                    setColumns(allcolumns)
                    let dataArr = [];
                    response.map((data, idex) => {
                        dataArr.push(response[idex].data)
                    })
                    setData(dataArr);
                    $(".spinner").hide();
                    if ($(window).width() <= 986) {
                        var fheight = $(".tab-filters-container").outerHeight() + $(".react-bs-table-tool-bar").outerHeight() + $(".react-bs-container-header").outerHeight() + $(".react-bs-table-pagination").outerHeight() + 12
                        if (fheight != "NaN") {
                            $(".react-bs-container-body").css("max-height", "calc(100vh - " + fheight + "px");
                        }
                    }
                }
            },
            rejectFunction: (error) => { $(".spinner").hide(); },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    const fetchForms = () => {
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getAllForms",
            queryString: "",
            sucessFunction: response => {
                if (response.hasOwnProperty('error')) {
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.error)
                    setTimeout(function () {
                        $('.birderror').remove();
                    }, 3000);
                } else {
                    setForms(response);
                }
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
    }
    useEffect(() => {
        if (isCampaign) {
            fetchCompaignData([]);
        }
        else {
            fetchData([])
            fetchForms([])

        }
        ChartInfo.functions.resetEditFilterArray();
        var that = this;
        $('html').on('click', function (e) {
            //e.preventDefault();
            try {
                var container = $(".tab-filters-container .panelboc-content");
                if (!container.is(e.target) && container.has(e.target).length === 0
                    && e.target.className != "applyBtn pull-right btn btn-bird"
                    && e.target.className != "daterangepicker dropdown-menu ltr opensleft show-calendar"
                    && !(e.target.className == "table-condensed" && e.target.parentElement.getAttribute('class') == "calendar-table calendar-date")
                    && !(e.target.offsetParent != null && e.target.offsetParent.parentElement != null && e.target.offsetParent.className == "table-condensed" && e.target.offsetParent.parentElement.getAttribute('class') == "calendar-table calendar-date")
                    && !(e.target.offsetParent != null && e.target.offsetParent.parentElement != null && e.target.offsetParent.className == "daterangepicker dropdown-menu ltr opensleft show-calendar" && e.target.offsetParent.parentElement.getAttribute('class') == "day")
                    && e.target.className != "monthselect"
                    && e.target.className != "yearselect"
                    && e.target.className != "input-mini"
                    && e.target.className != "input-mini active"
                    && e.target.className != "ranges"
                    && e.target.className != "calendar first left"
                    && e.target.className != "calendar second right"
                    && e.target.className != "boc-list-item singleliitems active"
                    && e.target.tagName != "OPTION"
                    && e.target.className != "boc-list-item singleliitems"
                ) {
                    $("#filteraccordion .panel-collapse.in").collapse('hide');
                    // $(this.state.element + " .panel-collapse.in").collapse('hide');
                    setTimeout(function () {
                        if ($('.fullscreenmain').length > 0 && $("#advancedsharestoryboardpermissionmodal.in").length === 0) {
                            if ($(".tab-filters-container .filteritem-container .panel-collapse[aria-expanded='true']").length > 0) {
                                $(".tab-filters-container").css("z-index", "9999")
                            } else {
                                $(".tab-filters-container").css("z-index", "")
                            }
                        } else {
                            $(".tab-filters-container").css("z-index", "")
                        }
                    }, 0);
                }
            } catch (e) { }
        });
    }, []);
    const createEditor = (row, clmindex, rowindex, e) => {
        e.stopPropagation();
        if (isCampaign) {
            let { is_owner } = row;
            if (!is_owner && !$(e.target).closest("button").hasClass('campaignpreview')) return;
        }
        if ($(e.target).hasClass('edit-data')) {
            e.stopPropagation()
            $("#datatablepopupmodal").remove();
            $("body").append("<div id='datatablepopupmodal'></div>");
            ReactDOM.render(<EditFormData fetchData={fetchData} allColumns={allColumns} editData={props.editData} data={row} row={row} index={rowindex} e={e} uniqueColumn={uniqueColumn} columns={columns} {...props} />, document.getElementById('datatablepopupmodal'));
        } else if ($(e.target).hasClass('form-data')) {
            e.stopPropagation()
            if (allForms && allForms.length > 0) {
                var top = e.clientY;
                var left = e.clientX;
                let forms = "";
                allForms.map((obj, i) => {
                    forms += '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut selected-form" formid="' + obj.formid + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label" id="viewdatacustom1"><span class="pietooltip">' + obj.form_name + '</span><i class="fa fa-list-alt"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                })
                $("#datatablepopupmodal").remove();
                $("body").append("<div id='datatablepopupmodal'></div>");
                $('#datatablepopupmodal').before('<div class="pie-menu summarypie" style="top:' + top + 'px;left:' + left + 'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close" id="piemenuclosecustom1"><i class="fa fa-times"></i></div>'
                    + '<div class="pie-list-wrapper">'
                    + forms
                    + '</div>'
                    + '<span class="wrapper"></span>'
                );

                $('.wrapper').on("click", function () {
                    $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                    setTimeout(function () {
                        $('.pie-menu').remove();
                    }, 200);
                });
                $('.selected-form').on("click", function (e) {
                    let formId = $(e.currentTarget).attr("formid");
                    let formData = $.grep(allForms, function (e) { return e.formid == formId });
                    let mutipleResponses = formData != undefined && formData[0] != undefined ? formData[0].mutipleResponses : "N";
                    let uniqueColumn = mutipleResponses == "N" && formData != undefined && formData[0] != undefined ? formData[0].primarycolumn : "";
                    let uniqueColumnId = row[uniqueColumn];
                    let requestHeaders = {
                        method: "get",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    };
                    SendRequest({
                        url: "getFormDataById",
                        queryString: "?formid=" + formId + "&uniquecolumn=" + uniqueColumnId,
                        sucessFunction: response => {
                            if (response.hasOwnProperty('error')) {
                                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').removeClass('hide');
                                $('.birdmessage h2').empty().append('Error : ');
                                $('.birdmessage h2').append('<span class="errorcode">');
                                $('.birdmessage-info').empty().text(response.error);
                                $('.details').addClass('show');
                                $('.messagedetails xmp').empty().append(response.error)
                                setTimeout(function () {
                                    $('.birderror').remove();
                                }, 3000);
                            } else if (response.hasOwnProperty('warning')) {
                                $(".queryinfomodal.modalfilter.singlefilterdropdown").addClass('hide');
                                $(".spinner").hide();
                                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').removeClass('show');
                                $('.details').addClass('hide');
                                $('.birdmessage h2').empty().append('Warning');
                                $('.birdmessage-info').empty().text(response.warning);
                            } else {
                                $('.pie-menu').remove();
                                $("#datatablepopupmodal").remove();
                                $("body").append("<div id='datatablepopupmodal'></div>");
                                ReactDOM.render(<SurveyForm fetchData={response} allColumns={allColumns} formData={formData} formId={formId} uniqueColumnId={uniqueColumnId} editData={props.editData} data={row} row={row} index={rowindex} e={e} uniqueColumn={uniqueColumn} columns={columns} {...props} />, document.getElementById('datatablepopupmodal'));
                            }
                        },
                        rejectFunction: () => { },
                        headers: requestHeaders
                    });
                });
            }
        } else if ($(e.target).closest("button").hasClass('campaignedit')) {
            campaignEdit(row, clmindex, rowindex, e);
        } else if ($(e.target).closest("button").hasClass('campaigndelete')) {
            campaignDelete(row, clmindex, rowindex, e);
        } else if ($(e.target).closest("button").hasClass('campaignpreview')) {
            campaignPreview(row, clmindex, rowindex, e);
        }
    }
    const options = {
        sizePerPage: 10,
        onRowClick: createEditor,
        searchField: (searchprops) => (isCampaign ? <React.Fragment /> : <SearchData searchprops={searchprops} fetchData={fetchData} {...props} />)
    }
    const formatter = (cell, row) => {
        return isCampaign ? cell : isSurvey ? <button className="btn btn-admin editaccount3" id="form-data" type="button" ><i className="fa fa-paste form-data"></i></button> : <button className="btn btn-admin editaccount3" id="edit-data" type="button" ><i className="fa fa-pencil edit-data"></i></button>;
    }
    const campaignDelete = (row, clmindex, rowindex, e) => {
        let $this = e.currentTarget;
        let { id } = row
        $(".deleteyes").unbind("click");
        $(".deleteno").unbind("click");
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').addClass('show');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning165'));
        $(".deleteyes").click(() => {
            $(".spinner").show();
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "deleteCampaignDataById",
                queryString: "?Id=" + id,
                sucessFunction: response => {
                    $(".spinner").hide();
                    $('.birdmessage-center').removeClass("message-top-in");
                    if (response.hasOwnProperty('error')) {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.error)
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    } else {
                        
                        let changed_Array = dataArray.filter(val => val.id != id);
                        setData(changed_Array);
                        props.history.push(`/${window.localStorage.getItem("appName")}/welcome/campaign/` + btoa(entityId))
                    }
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });
        })
        $(".deleteno").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
        });
    }
    const campaignPreview = (row, clmindex, rowindex, e) => {
        const { id } = row;
        $("#datatablepopupmodal").remove();
        $("body").append(`<div id="datatablepopupmodal"></div>`);
        ReactDOM.render(<CampaignPreview id={id} row={row} />, document.getElementById('datatablepopupmodal'));
        // $("body").append(`<div id="datatablepopupmodal"><div class="pivotdataviewwrap custombrandform"><div class="pivotdataview"><button class="campaignpreviewdownload pull-right" id="campaignpreviewdownload" data-id="${id}"><i class="fa fa-download" data-id="${id}"></i></button><div class="pivotdataviewclose" id="pivotdataviewdatapop"><i class="fa fa-times"></i></div><div class="boc-carousel-area-header-title pivottile" style="color: rgb(39, 55, 163);">Campaign Preview</div><div class="container-fluid marg-bot-10 nopadding"><div class="row"><div id="pivotdataview"></div></div></div></div></div></div>`);
    }
    const campaignEdit = (row, clmindex, rowindex, e) =>{
        $("#createcampaignform").remove();
        $("body").append('<div id="createcampaignform"></div>');
        ReactDOM.render(<CampaignFormHtml allColumns={allColumns} columns={columns} isCustomQuery={isCustomQuery} entityName={entityName} tableName={tableName}
            fetchData={fetchData} fetchCompaignData={fetchCompaignData} campaign_edit_data={row}
            connId={connId} entityId={entityId} {...props.editData} {...props} />, document.getElementById('createcampaignform'));
    }
    const createCampaign = (e, props) => {
        $("#createcampaignform").remove();
        $("body").append('<div id="createcampaignform"></div>');
        ReactDOM.render(<CampaignFormHtml allColumns={allColumns} columns={columns} isCustomQuery={isCustomQuery} entityName={entityName} tableName={tableName}
            fetchData={fetchData} fetchCompaignData={fetchCompaignData} connId={connId} entityId={entityId} {...props.editData} {...props} />, document.getElementById('createcampaignform'));

    }
    return (
        columns.length > 0 ? (
            <div className="container-fluid marg-top-20 customformpage">
                {isCampaign ? <React.Fragment /> :
                    <FiltersTab allColumns={allColumns} columns={columns} isCustomQuery={isCustomQuery} entityName={entityName} tableName={tableName}
                        fetchData={fetchData} connId={connId} entityId={entityId} {...props} />}
               {isCampaign == true ?
                <div className="datamain-fixed-head-container">
                    <span className="subhead">
                        <i className="fa fa-line-chart marg-righ-10 fa-solid"></i>
                        <span className="">Marketing</span>
                    </span>
                    {hierachyAccess && (hierachyAccess  =="state" || hierachyAccess  =="district" || hierachyAccess  =="constituency")?
            <button className="btn btn-bird-group active pull-right createcampaign" onClick={e => createCampaign(e, props)}><i className="fa fa-plus"></i> Create</button> 
                   :""}
                </div>
                : ""}
                <div className="row" style={{ height: '100%', margin: 0 }}>
                    {/* <h6 style={{ margin: "0", padding: "0 8px", color: "#00abab" }}>
                        {lang["workspace.preview"]} {dataArray.length} {lang["workspace.rows"]}
                    </h6> */}
                    
                    <BootstrapTable data={dataArray} options={options} bordered={false} striped hover pagination maxHeight={'calc(100vh - 250px)'}
                        scrollTop={'Bottom'}
                        noDataIndication={"No matching records found"}
                        search

                    >
                        {
                            columns.map((column, index) => {
                                if (index == 0) {
                                    return (<TableHeaderColumn key={index} width={'60px'} className={column.type === 'number' ? 'align-right' : ''} thStyle={{ textAlign: column.type === 'number' ? 'right' : 'center' }}
                                        tdStyle={{ whiteSpace: 'normal', minWidth: '60px', maxWidth: '60px', textAlign: column.type === 'number' ? 'right' : 'center' }}
                                        isKey={column.isKey} hidden={column.hidden}
                                        dataField={column.name} dataSort={column.sortable}
                                        dataFormat={column.formatter}>{column.field}</TableHeaderColumn>)
                                } else {


                                    return (<TableHeaderColumn key={index} width={'150px'} className={column.type === 'number' ? 'align-right' : ''} thStyle={{ textAlign: column.type === 'number' ? 'right' : 'left' }}
                                        tdStyle={{ whiteSpace: 'normal', minWidth: '150px', maxWidth: '200px', textAlign: column.type === 'number' ? 'right' : 'left' }}
                                        isKey={column.isKey} hidden={column.hidden}
                                        dataField={column.name} dataSort={column.sortable}
                                        dataFormat={column.formatter}>{column.field}</TableHeaderColumn>)
                                }
                            })
                        }
                    </BootstrapTable>

                </div>
            </div>
        ) : <div>
            <h1 id="editdatapreviewempty" style={{ textAlign: 'center', color: '#212121' }}>Loading</h1>
        </div>
    )


}