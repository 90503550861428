import React from 'react';
import ReactDOM from 'react-dom';
import DataHub from './Datahub';
import LogfilesTablesHtml from './LogfilesTablesHtml.jsx';
import LogfileConnectionHtml from './LogfileConnectionHtml.jsx';
import SendRequest from '../SendRequest';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import { lang } from "../js/helpers/utils/languages/language";
import FtpConnectorHtml from './FtpConnectorHtml';
const $ = window.$;

export default class LogfileConnection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customLogsCollection: [],
            connectiondetails: {},
        }
        this.schemaId = props.schemaId;
        this.schemaName = props.schemaName;
        this.connectionId = props.connectionId;
        this.schemaDisplayName = props.schemaDisplayName;
        // this.uploadsize=props.connectiondetails[0].uploadsize;
        // this.allow=props.connectiondetails[0].allow;
        this.isFromEditFlatFiles = props.isFromEditFlatFiles;
        this.scopename = props.scopename;
        this.ftpurl = props.ftpurl;
        this.ftpusername = props.ftpusername;
        this.ftppassword = props.ftppassword;
        this.editfilename = props.editfilename;
        this.ftpfilename = props.ftpfilename;
        this.selectedfiletype = props.selectedfiletype;
        this.ftplogtype = props.logtype;
        this.uploadtype = props.uploadtype;
        this.connectionname = props.connectiondetails != undefined ? props.connectiondetails[0].connectionname : "";
    }
    requestHeaders = {
        method: "get",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    };
    componentDidMount() {
        // SendRequest({
        //     url: "schemadetails",
        //     queryString: "?schemaId=" + this.schemaId,
        //     sucessFunction: (response) => {
        //         this.setState({
        //             connectiondetails: response.connectiondetails
        //         });
        //     },
        //     rejectFunction: (error) => { },
        //     headers: this.requestHeaders
        // });

        // if (this.isFromEditFlatFiles === true) {
        //     $("#logtypeselectadd").val(this.ftplogtype).change();
        // }
        if (this.isFromEditFlatFiles === true) {
            $('.connectionname').val(this.ftpfilename);
            $('.ftpfileurl').val(this.ftpurl);
            $('.ftpusername').val(this.ftpusername);
            $('.ftppassword').val(this.ftppassword); 
            $('input[name="ftpfiletype"]:checked').val(this.selectedfiletype);
            $('input[name="filelocation"]:checked').val(this.uploadtype);

            }
    }
    logfilemethod = (e) => {
        var $this = e.currentTarget;
        var val = $($this).val();
        if (val === "PDF") {
            $(".pdfoptionscontainer").removeClass("hide");
        } else {
            $(".pdfoptionscontainer").addClass("hide");
        }
    }

    valid = () => {
        var returnValue = true;
        if ($('.connectionname').val() === "" || $('.connectionname').val() === undefined) {
            $('.csvnameerror').removeClass("hide");
            return false;
        }
        if ($('.ftpfileurl').val() === '' || $('.ftpfileurl').val() === undefined) {
            $(".ftpurlemptyerror").removeClass("hide");
            returnValue = false;
        }
        if ($('.ftpusername').val() === '') {
            $(".ftpusernameemptyerror").removeClass("hide");
            returnValue = false;
        }
        if ($('.ftppassword').val() === '') {
            $(".ftppasswordemptyerror").removeClass("hide");
            returnValue = false;
        }
        return returnValue;
    }
    ftptestconnection = (e) => {
        e.preventDefault();
        e.stopPropagation();
        var $this = e.currentTarget;
        this.ftpurl = $('.ftpfileurl').val();
        this.ftpusername = $('.ftpusername').val();
        this.ftppassword = $('.ftppassword').val();
        this.selectedfiletype = $('input[name="ftpfiletype"]:checked').val();
        this.uploadfiletype = $('input[name="filelocation"]:checked').val();
        if (this.valid()) {
            $(".ftpurlemptyerror").addClass("hide");
            $(".ftpusernameemptyerror").addClass("hide");
            $(".ftppasswordemptyerror").addClass("hide");
            $('.connectionnameerror').addClass("hide");
            var tempObj = {};
            var data = {};
            tempObj["ftpurl"] = this.ftpurl;
            tempObj["delimiter"] = this.props.delimiter;
            tempObj["connectionaccessid"] = null;
            tempObj["logPattern"] = this.logpattern;
            tempObj["ftpusername"] = this.ftpusername;
            tempObj["ftppassword"] = this.ftppassword;
            tempObj["command"] = "testConnection";
            tempObj["connectionName"] = "ftp";
            tempObj["uploadfiletype"] =  this.uploadfiletype ;
            tempObj["selectedfiletype"] = this.selectedfiletype;
            data["connectionObject"] = tempObj;
            data["schemaId"] = this.schemaId;
            SendRequest({
                url: "entities",
                queryString: "?connectionObject=" + encodeURIComponent(JSON.stringify(tempObj)) + "&schemaId=" + this.schemaId,
                // body:data,
                sucessFunction: (response) => {
                    this.response = response;
                    if (response.status === 'error' || response.hasOwnProperty('error') || response.success === false) {
                        $('.spinner').hide();
                        setTimeout(function () {
                            $(".logfileprocessor").steps("previous");
                        }, 800);
                        $('.browselogfiles').addClass('disabled');
                        $('.notifications-table-container').empty();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning83'));
                        return false;
                    } else {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess41'));
                        sessionStorage.setItem("changesdone", 0);
                        return true;
                    }
                },
                rejectFunction: () => { },
                headers: this.requestHeaders
            })
        }
    }
    savenewlogconnection=(e)=>{
        $(".spinner").show();
        var connectionName=$('.connectionname').val();
        if(connectionName==="" || connectionName===undefined){
            $(".csvnameerror").removeClass("hide").show();
            $(".spinner").hide();
            return false;
        }
        else if($('.connectionname').val().replace(/\s+/g, '').length == 0){
            $(".csvnameerror").show();
            $('.spinner').hide();
              return;
        }else{
            if(/[^a-zA-Z0-9_ ]/.test(connectionName)){
                $(".csvnameerror").show();
                $('.spinner').hide();
                return;
                }else{
                $(".csvnameerror").hide();
                }
        }
            this.ftptestconnection(e);
            this.selectedfiletype=$('input[name="ftpfiletype"]:checked').val();
            //that.browselogfiles(e,false);
            this.ftpfilename=$('.connectionname').val();
            if(this.ftpfilename!==undefined){
                 if(true){
                    var tempObj  = {};
                    var data = {};
                        tempObj["ftpurl"] = this.ftpurl;
                        tempObj["ftpusername"] = this.ftpusername;
                        tempObj["ftppassword"] = this.ftppassword;
                        tempObj["ftpfilename"] =this.ftpfilename;
                        tempObj["fileName"] =this.ftpfilename;
                    tempObj["delimiter"] = this.props.delimiter; 
                    tempObj["connectionaccessid"] = null;
                    tempObj["connectionName"] = "ftp";
                    tempObj["uploadfiletype"]=$('input[name="filelocation"]:checked').val();
                    tempObj["selectedfiletype"] = this.selectedfiletype;
                    tempObj["command"] = "getTables";
                    tempObj["connection_access_name"] = $('.connectionname').val();
                    tempObj["connectionname"] = this.props.schemaName;
                    tempObj["connection_id"] = this.props.schemaId;
                    tempObj["dbType"] = "ftp";
                    tempObj["connection_access_id"] = this.props.connectionId;
                    tempObj["connection_details"] = JSON.stringify(tempObj);

                    data["connectionObject"] = tempObj;
                    data["schemaId"] = this.schemaId;
                    this.connectionObject=tempObj;
                    SendRequest({
                        url:"entities",
                        queryString: "?connectionObject="+encodeURIComponent(JSON.stringify(tempObj))+"&schemaId="+this.schemaId,
                     //   body:data,
                        sucessFunction:(response)=>{
                            if(response.hasOwnProperty('error')){
                                $(".spinner").hide();
                                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').removeClass('hide');
                                $('.birdmessage h2').empty().append('Error : ');
                                $('.birdmessage h2').append('<span class="errorcode">');
                                $('.birdmessage .errorcode').empty().append(response.errorCode);
                                $('.birdmessage-info').empty().text(response.error);
                                $('.details').addClass('show');
                                $('.messagedetails xmp').empty().append(response.errorTrace)
                                return false;
                            }else{
                                $(".spinner").hide();
                                this.tablenames=response;
                                this.csvdata=response.data;
                                if(response.columns.length==0){
                                    $("a[href='#next']").parent().addClass("disabled");
                                    $('.csvmodifyentities> .table').empty();
                                    $('.spinner').hide();
                                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass('birdmessage-error');
                                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-times-circle-o');
                                    $('.birdmessagebuttons').removeClass('show');
                                    $('.birdmessagebuttons').addClass('hide');
                                    $('.birdmessage-center').addClass("message-top-in");
                                    $('.details').removeClass('show');
                                    $('.details').addClass('hide');
                                    $('.birdmessage h2').empty().append('Warning');
                                    $('.birdmessage-info').empty().text(getMessage('BirdWarning82'));
                                    return false;
                                }else{
                                    this.saveconnection();
                                }
                            }
                        },
                        rejectFunction:(error)=>{},
                        headers:this.requestHeaders
                    })
                }
                else{
                    $('.spinner').hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(getMessage('BirdWarning80'));
                    return false;
                }
            }else{
                $('.spinner').hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning81'));
                return false;
            }
    }
    saveconnection = (e) => {
        //For storing ftp connection details
        var connectionObject = {};
        var columns;
        var that = this;
        // if (!this.ftptestconnection()) {
        //     return;
        // }
        // this.props.connectiondetails[0].scopeName = $('.connectionname').val();
        // this.props.connectiondetails[0].connectionname = $('.connectionname').val();
        // this.props.connectiondetails[0].logType = this.logType;
        // this.props.connectiondetails[0].uploadfiletype = this.uploadfiletype;
        // this.selectedfiletype = $('.filelocation').val();
        // if (this.selectedfiletype == "sftp") {
        //     this.props.connectiondetails[0].dbType = "sftp";
        //     this.props.connectiondetails[0].uploadFileType = "sftp";
        // } else {
        //     this.props.connectiondetails[0].dbType = "ftp";
        //     this.props.connectiondetails[0].uploadFileType = "ftp";
        // }
        // if (this.ftpfilename != undefined) {
        //     this.props.connectiondetails[0].ftpurl = this.ftpurl;
        //     this.props.connectiondetails[0].ftpusername = this.ftpusername;
        //     this.props.connectiondetails[0].ftppassword = this.ftppassword;
        //     this.props.connectiondetails[0].ftpfilename = this.ftpfilename;
        // }
        // this.conObject = this.props.connectiondetails[0];
        // this.selectedfiletype = $('input[name="ftpfiletype"]:checked').val();
        // connectionObject["connection_id"] = parseInt(this.schemaId);
        // //  connectionObject["logPattern"]=this.logpattern;
        // connectionObject["tablename"] = this.fileName;
        // connectionObject["filePath"] = this.filePath;
        // connectionObject["connection_access_name"] = $('.connectionname').val().trim();
        // connectionObject["connectionname"] = "ftp";
        // connectionObject["connection_access_id"] = this.connectionId;
        // connectionObject["connection_details"] = JSON.stringify(this.props.connectiondetails[0]);
        // connectionObject["sqlquery"] = "";
        // connectionObject["isForTemp"] = false;
        // connectionObject["selectedfiletype"] = this.selectedfiletype;
        // connectionObject["uploadfiletype"] = $('input[name="filelocation"]:checked').val();
        // columns = JSON.stringify(this.reportcolumns);
        // connectionObject["report_columns"] = columns;
        // if (this.selectedfiletype == "pdf") {
        //     var scannedPageValue = $("input[name ='scanpages']:checked").val();
        //     var pagenumber = 1;
        //     var fromRange = 0;
        //     var toRange = 1;
        //     if (scannedPageValue == "single") {
        //         pagenumber = $("#pagenumber").val();
        //     }
        //     else if (scannedPageValue == "range") {
        //         fromRange = $("#fromrange").val();
        //         toRange = $("#torange").val();
        //     }
        //     connectionObject["pagenumber"] = pagenumber;
        //     connectionObject["fromrange"] = fromRange;
        //     connectionObject["torange"] = toRange;
        //     connectionObject["scannedpagevalue"] = scannedPageValue;
        // }
        var data = new FormData();
        data.append("connectionObject", JSON.stringify(this.connectionObject));
        data.append("isFromModel", 0);
        SendRequest({
            url: "saveConnectionDetails",
            queryString: "",
            body: data,
            sucessFunction: (response) => {
                $(".connection-center").remove()
                if (response.hasOwnProperty('error')) {
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.error)
                    return false;
                    setTimeout(function () {
                        $('.birderror').remove();
                    }, 3000);
                } else {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Success');
                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess41'));
                    sessionStorage.setItem("changesdone", 0);
                    $('.browselogfiles').removeClass('disabled');
                    $(".connection-center").removeClass("connection-in");
                    setTimeout(function () { $("body").removeClass("connection-open creating-new-connection"); }, 800);

                    this.datahub = new DataHub();
                    this.datahub.loadConnections(response.connectionAccessId);

                }
            },
            rejectFunction: (error) => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    validateflatfilesForSpecialChars = (text) => {
        $(".spinner").hide();
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(lang["Datahub.filespclchar"]);
        return false;
    }
    uploadlog = (url, redirect) => {
        try {
            $('.spinner').show();
            var delimiter = this.delimiter;
            var file_data = $("#fileupload").prop("files")[0];
            var reportId = "";
            if (true && !redirect) {
                var form_data = new FormData();
                form_data.append("file", file_data);
                form_data.append("name", file_data.name);
                form_data.append("csvfiledata", JSON.stringify(this.jsonarray));
                form_data.append("delimiter", ',');
                form_data.append("reportId", reportId);
                form_data.append("isFromMashUp", true);
                form_data.append("schemaname", this.props.schemaType);
                form_data.append("reportcolumns", JSON.stringify(this.reportcolumns));
                form_data.append("isTemp", redirect);
                SendRequest({
                    url: url,
                    queryString: "",
                    body: form_data,
                    sucessFunction: (response) => {
                        if (response.hasOwnProperty('error')) {
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.error)
                            setTimeout(function () {
                                $('.birderror').remove();
                            }, 3000);
                        } else {
                            {
                                this.filePath = response.filePath;
                                var file_data = $("#fileupload").prop("files")[0];
                                this.fileName = file_data.name;
                                if (this.logType == "customlog") {
                                    this.logpattern = $('.customlogformatdata').val();
                                }
                                var tempObj = {};
                                var data = {};
                                tempObj["fileName"] = file_data.name;
                                tempObj["filePath"] = this.filePath;
                                tempObj["delimiter"] = this.props.delimiter;
                                tempObj["connectionaccessid"] = null;
                                tempObj["connectionName"] = "log";
                                tempObj["logPattern"] = this.logpattern;
                                tempObj["uploadfiletype"] = this.uploadfiletype;
                                tempObj["command"] = "getTables";
                                data["connectionObject"] = tempObj;
                                data["schemaId"] = this.schemaId;
                                SendRequest({
                                    url: "entities",
                                    queryString: "?connectionObject=" + encodeURIComponent(JSON.stringify(tempObj)) + "&schemaId=" + this.schemaId,
                                    // body:data,
                                    sucessFunction: (response) => {
                                        if (response.hasOwnProperty('error')) {
                                            $(".spinner").hide();
                                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                            $('.birdmessagebuttons').removeClass('show');
                                            $('.birdmessagebuttons').addClass('hide');
                                            $('.birdmessage-center').addClass("message-top-in");
                                            $('.details').removeClass('hide');
                                            $('.birdmessage h2').empty().append('Error : ');
                                            $('.birdmessage h2').append('<span class="errorcode">');
                                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                                            $('.birdmessage-info').empty().text(response.error);
                                            $('.details').addClass('show');
                                            $('.messagedetails xmp').empty().append(response.errorTrace)
                                        } else {
                                            $(".spinner").hide();
                                            this.tablenames = response;
                                            this.csvdata = response.data;
                                            if (response.columns.length === 0) {
                                                $("a[href='#next']").parent().addClass("disabled");
                                                $('.csvmodifyentities> .table').empty();
                                                $('.spinner').hide();
                                                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass('birdmessage-error');
                                                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-times-circle-o');
                                                $('.birdmessagebuttons').removeClass('show');
                                                $('.birdmessagebuttons').addClass('hide');
                                                $('.birdmessage-center').addClass("message-top-in");
                                                $('.details').removeClass('show');
                                                $('.details').addClass('hide');
                                                $('.birdmessage h2').empty().append('Warning');
                                                $('.birdmessage-info').empty().text(getMessage('BirdWarning82'));
                                                return false;
                                            } else {
                                                this.saveconnection();
                                            }

                                        }
                                    },
                                    rejectFunction: (error) => { },
                                    headers: this.requestHeaders
                                })
                                $('.spinner').hide();
                            }
                        }
                    },
                    rejectFunction: (error) => { },
                    headers: {
                        method: "post",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    }
                })
            }
        } catch (e) {
            $('.spinner').hide();

        }
    }
    render() {
        return (
            <FtpConnectorHtml
                schemaName={this.schemaName}
                editfilename={this.editfilename}
                uploadsize={this.uploadsize}
                scopename={this.scopename}
                connectionId={this.connectionId}
                isFromEditFlatFiles={this.isFromEditFlatFiles}
                allow={this.allow}
                logpattern={this.logpattern}
                ftplogtype={this.ftplogtype}
                ftpfilename={this.ftpfilename}
                schemaDisplayName={this.schemaDisplayName}
                connectionname={this.connectionname}
                uploadtype={this.uploadtype}
                ftpurl={this.ftpurl}
                ftpusername={this.ftpusername}
                ftppassword={this.ftppassword}
                logscollection={this.state.customLogsCollection}
                logfilemethod={this.logfilemethod}
                logtypeselect={this.logtypeselect}
                ftptestconnection={this.ftptestconnection}
                saveconnection={this.savenewlogconnection}
                close={this.props.close}
            />
        );
    }
}