import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import PubSub from 'pubsub-js';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import SendRequest from '../../SendRequest';
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import * as collection from "../../js/helpers/utils/SavedReportCollection";
import MLReportTemplate from "./viewmlreport.jsx";
import MlFeatureEngineeringView from "./mlfeatureengineeringview";
import ViewMLParametersView from "./mlparametersview"
import ViewMLReportBodyView from "./viewmlreportbodyview";
import CustomFieldModal from "../customfieldmodal";
import CustomFieldView from "../attrmodalcustomfieldview";
import * as ModalProperties from "../../js/helpers/utils/modalproperties";
import MLReportHeaderView from "./mlreportheaderview";
import ViewReportFilterView from '../viewreportfilter';
import ViewReportAddFilterView from "../viewreportaddfilterview";
import { lang } from "../../js/helpers/utils/languages/language";
import ViewReportAddCustomParameterFilters from "../customparameterfield/viewreportaddcustomparameterfilter";
import MLSamplingPreview from './mlsamplingpreview.jsx';
const $ = window.$;
export default class MLReportView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayFooter: true,
            tabid: 0,
            features: [],
            measures: [],
            dimensions: [],
            multifact: "false",
            renderreturn: false,
            reportInfo: [],
            reportcolumns: [],
            view_Only_Flag: false,
            reportNumber: null,
            reportName: "",
            filtersArray: [],
            reportId: null,
            isTempStoryBoard: false,
            isDirect: 0,
            isFromStoryBook: false,
            isFromTabClick: false,
            el: "main",
            storybookId: "",
            isSharedStoryBook: false,
            datamodelId: atob(this.props.match.params.datamodelId),
            isSampling:false
        }
        ChartInfo.functions.resetChartDetails();
        this.unbindEvents();
        this.updateFiltersEve=PubSub.subscribe('updateFilters', this.updateFiltersEve.bind(this));
        this.addcustomfieldEve = PubSub.subscribe('addcustomfield', this.addcustomfieldEve.bind(this));
        PubSub.subscribe('showcustomfielddialog', this.showcustomfielddialog.bind(this));
        this.loadNextPageEve = PubSub.subscribe('loadNextPage', this.loadNextPageEve.bind(this));
        this.runmodelbtn = this.runmodelbtn.bind(this);
        this.refreshmlcharts = this.refreshmlcharts.bind(this);
    }
    componentWillUnmount() {
        PubSub.unsubscribe('updateFilters', this.updateFiltersEve);
        PubSub.unsubscribe('addcustomfield', this.addcustomfieldEve);
        PubSub.unsubscribe('showcustomfielddialog', this.showcustomfielddialog);
        PubSub.unsubscribe('loadNextPage', this.loadNextPage);
    }
    updateFiltersEve() {
        this.updateFilters();
    }
    updateFilters() {
        var filterObject = ChartInfo.functions.getFilterArray();
        _.each(filterObject, function (filter, index) {
            if (filter.customfield == undefined) {
                _.each(filter["data"].split("@@"), function (value, k) {
                    $(".filtervalue[displayname='" + filter["columndisplayname"] + "'][data-value='" + value.replace(/([;?&,%#+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").prop("checked", true);
                })
            } else if (filter.customfield.sqlcondition != undefined &&
                filter.customfield.sqlcondition.sqldata != undefined) {
                var dataList = filter.customfield.sqlcondition.sqldata.split("@@");
                _.each(dataList, function (value, j) {
                    $(".filtervalue[displayname='" + filter["columndisplayname"] + "'][data-value='" + value.replace(/([;?&,%#+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").prop("checked", true);
                });
            }
        });
    }
    addcustomfieldEve(msg, data) {
        this.addcustomfield(data);
    }
    addcustomfield(customObject) {
        $('.spinner').show();
        var that = this;
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".mlcustomfieldscontainer .customdimensions").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
        var customfieldView = new CustomFieldView({
            deletecustomfield: this.deletecustomfield,
            dynamicdiv: dynamicdiv,
            columnDisplayName: customObject.name,
            columnName: customObject.name,
            dataType: (customObject.dataType == undefined) ? "string" : customObject.dataType,
            aggregation: "Sum",
            multifact: this.state.multifact,
            multifactentities: this.state.multifactentities,
        });

        $(".mlcustomfieldscontainer .customdimensions").append($("#" + dynamicdiv));
        //for appending custom fields in Dual list box
        var select = $('select[name="ml_duallistbox"]');
        var option = '<option value="@value" aggregation ="@aggregation" columnDisplayName="@columnDisplayName" tableDisplayType="@tableDisplayType" columnName="@columnName" '
            + '>@displayValue</option>';
        $("#mlallcolumnscontainer").empty();
        $('#mlallcolumnscontainer').append(select);
        var tempOption = option.replace('@value', customObject.name);
        tempOption = tempOption.replace('@columnName', customObject.name);
        tempOption = tempOption.replace('@displayValue', customObject.name);
        tempOption = tempOption.replace('@tableDisplayType', customObject.dataType);
        tempOption = tempOption.replace('@aggregation', "sum");
        tempOption = tempOption.replace('@columnDisplayName', customObject.name);
        $(select).append(tempOption);
        if ($(window).width() > 500) {
            $('select[name="ml_duallistbox"]').bootstrapDualListbox({
                nonSelectedListLabel: '<i class="fa fa-table"></i> &nbsp;'+lang["mlreports.listofcolms"],
                selectedListLabel: '<i class="fa fa-table"></i> &nbsp;'+lang["mlreports.slctlistcolms"],
                preserveSelectionOnMove: 'moved',
                moveOnSelect: false,
            });
        }

        this.dimensioncount++;
        $(".dimensioncount").text(this.dimensioncount);
        var that = this;
        $(".createfeatureengineeringfield").click(function (e) {
            that.createFeatureEngineeringField(e)
        })
        $("#createcustomfield").modal("hide");
        $('.spinner').hide();

    }
    togglefieldengineering = (e) => {
        var $this = e.currentTarget,
            $targetScroller = $("section.boc-viewreport-section.mlreport"),
            $targetFeaturesContainer = $(".featureengineeringcontainer"),
            $targetParameterContainer = $(".mlmodelparameterscontainer"),
            isShowingFeature, isShowingParameters;
        $targetFeaturesContainer.slideToggle(300);
        setTimeout(function () {
            isShowingFeature = $targetFeaturesContainer.css("display");
            isShowingParameters = $targetParameterContainer.css("display");
            if (isShowingFeature == "block" || isShowingParameters == "block") { $targetScroller.addClass("modalopen"); } else { $targetScroller.removeClass("modalopen"); }
        }, 500);
    }
    togglemodelparameters = (e) => {
        var $this = e.currentTarget;
        var that = this;
        new ViewMLParametersView({
            broker: that.broker,
            viewreportCollection: that.collection,
            features: that.features,
            reportId: that.reportId,
            schemaId: that.props.schemaId,
            measures: that.collection.getMeasures(),
            dimensions: that.collection.getDimensions(),
            datamodelId: that.datamodelId,
            isFromStoryBook: that.isFromStoryBook
        });
    }
    showaddfiltermodal = () => {
        $('.trend-stats-container').remove();
        $('.viewdatafromchart').addClass('hide');
        $('.viewchartsummary').addClass('hide');
        this.state.viewReportAddFilterView.render();
    }
    addFilter = () => {
        this.state.viewReportAddFilterView.addFilter();
    }
    addCustomParameterFilter = () => {
        this.state.viewReportAddFilterView.addFilter();
    }
    togglefiltersmenu = (e) => {
        if ($(".instant-floating-filters").hasClass("changesdone")) {
            $(".instant-floating-filters.rightpane.changesdone .filtershead.collapsed").click();
            $("#rightfiltersapplys").addClass("tooltip-highlight"); setTimeout(function () { $("#rightfiltersapplys").removeClass("tooltip-highlight"); }, 2500);
        } else {
            if ($(".instant-floating-filters").is(":visible")) {
                $(".instant-floating-filters").hide();
            } else {
                $('.instant-floating-filters').toggle('slow')
            }
        }
    }
    loadNextPageEve(message, data) {
        this.loadNextPage(data.currentIndex, data.newIndex, data.isRunModel)
    }
    loadNextPage(currentIndex, newIndex, isRunModel) {
        var that = this;
        var isSelectedColumnsChanged = false;
        $(".actions.clearfix a[href='#next']").removeClass('disabled')
        if ((currentIndex == 0 && newIndex == 1) || newIndex == 1) {
            $('.spinner').show();
            if (that.isLoadMLReport) {
                setTimeout(function () {
                    $("#mlsplitcontainer").steps("next");
                }, 1500);
            }
            that.mlFeatureEngineeringView = new MlFeatureEngineeringView({
                features: that.state.features,
                reportId: that.state.reportId,
                schemaId: "",
                measures: that.state.measures,
                dimensions: that.state.dimensions,
                parentReportId: "",
                datamodelId: that.state.datamodelId,
                deletecustomfield: that.deletecustomfield,
                createFeatureEngineeringField: that.createFeatureEngineeringField
            });
        } else if ((currentIndex == 1 && newIndex == 2)) {
            $(".spinner").show();
            if (that.isLoadMLReport) {
                setTimeout(function () {
                    $("#mlsplitcontainer").steps("next");
                }, 1000);
            }
            var selectedListItems = [];
            $.each($('select[name="ml_duallistbox"] option:selected'), function () {
                selectedListItems.push(this);
            });
            var isDualList = $("#mlallcolumnscontainer:not(.hide) .bootstrap-duallistbox-container").length;
            if ($(window).width() <= 500) { isDualList = 1 }
            if (selectedListItems.length == 0 && isDualList > 0) {
                $(".mlallcolumnscontainer .bootstrap-duallistbox-container.row>.box2>select.form-control").addClass("error");
                if ($(window).width() <= 500) { $('select[name="ml_duallistbox"]').addClass('ui-state-error') }
                $('.spinner').hide();
                return false;
            }
            else {
                $(".mlallcolumnscontainer .bootstrap-duallistbox-container.row>.box2>select.form-control").removeClass("error");
                if ($(window).width() <= 500) { $('select[name="ml_duallistbox"]').removeClass('ui-state-error') }
                var selectedColumns = [];
                var selectedColumnNamesList = [];
                $(selectedListItems).each(function (index, value) {
                    var columnObj = {};
                    columnObj["aggregation"] = $(value).attr("aggregation");
                    columnObj["columnDisplayName"] = $(value).attr("columnDisplayName");
                    columnObj["columnName"] = $(value).attr("columnname");
                    columnObj["columnssrename"] = $(value).attr("columnssrename");
                    columnObj["dateFormat"] = $(value).attr("dateFormat");
                    columnObj["id"] = ($(value).attr("id") == undefined) ? index : $(value).attr("id");
                    columnObj["keycolumn"] = $(value).attr("keycolumn");
                    columnObj["orginalColumnName"] = $(value).attr("orginalColumnName");
                    columnObj["priorityColumn"] = $(value).attr("priorityColumn");
                    columnObj["tableAliasName"] = $(value).attr("tableAliasName");
                    columnObj["tableDisplayName"] = $(value).attr("tableDisplayName");
                    columnObj["tableDisplayType"] = $(value).attr("tableDisplayType");
                    columnObj["tableName"] = $(value).attr("tableName");
                    columnObj["unique"] = $(value).attr("unique");
                    columnObj["visible"] = $(value).attr("visible");
                    selectedColumns.push(columnObj);
                    selectedColumnNamesList.push($(value).attr("columnname"));
                });
                that.selectedColumns = selectedColumns;
                ChartInfo.functions.setMLSelectedColumns(selectedColumns);
                //Add ML Model
                that.ViewMLParametersView = new ViewMLParametersView({
                    viewreportCollection: that.collection,
                    features: that.state.features,
                    reportId: that.state.reportId,
                    schemaId: "",
                    measures: that.state.measures,
                    dimensions: that.state.dimensions,
                    parentReportId: "",
                    selectedColumns: that.state.reportcolumns,
                    datamodelId: that.state.datamodelId,
                    selectedColumnNamesList: selectedColumnNamesList
                });
            }
        }
        else if ((currentIndex == 2 && newIndex == 3) || newIndex == 3) {
            if (isRunModel != true) {
                $(".spinner").show();
            }

            if (that.parentReportId == undefined) {
                isRunModel = true;
            }
            if (isRunModel || that.parentReportId == undefined || isSelectedColumnsChanged) {
                $('.runmodelbtn').addClass("disabled");
                $('.mlloadingbtn').removeClass("hide");
            }
            if ($('#mlpredictionsettingpop').val() != undefined && $('#mlpredictionsettingpop').val() == "mlselect") {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Information');
                $('.birdmessage-info').empty().text(lang["mlreports.onemodel"]);
                return false;
            } else {
                if (that.isLoadMLReport) {
                    setTimeout(function () {
                        $("#mlsplitcontainer").steps("next");
                        that.isLoadMLReport = false;
                    }, 1000);
                    that.ViewMLParametersView.runmodelparameters(that.selectedColumns, isRunModel, that.mlSavedModeldata);
                } else {
                    that.ViewMLParametersView.runmodelparameters(that.selectedColumns, isRunModel, "");
                }
            }
        }
        return true;
    }
    loadSamplingNextPage(currentIndex, newIndex, isRunModel) {
        var that = this;
        $(".actions.clearfix a[href='#next']").removeClass('disabled')
        if ((currentIndex == 0 && newIndex == 1) || newIndex == 1) {
            $('.spinner').show();
            ReactDOM.render(<MLSamplingPreview></MLSamplingPreview>,document.getElementById("datapreviewcontainer"))
            $(".spinner").hide()
        } else if ((currentIndex == 1 && newIndex == 2)) {
            $(".spinner").show();
            $(".spinner").hide()
        }
        /*else if ((currentIndex == 2 && newIndex == 3) || newIndex == 3) {
            
        }*/
        return true;
    }
    loadjquerysteps=()=> {
        var that = this;
        $("#mlsplitcontainer").steps({
            headerTag: "h3",
            bodyTag: "section",
            transitionEffect: "slideLeft",
            enableKeyNavigation: false,
            autoFocus: true,
            onStepChanging: function (e, currentIndex, newIndex) {
                if(that.state.isSampling){
                    return that.loadSamplingNextPage(currentIndex,newIndex)
                }else{
                    return that.loadNextPage(currentIndex, newIndex);
                }
            },
            onFinishing: function (event, currentIndex) {
                return true;
            },
            onFinished: function (event, currentIndex) {
                return false;
            }
        });
        $('a[href="#finish"]').addClass("hide")
    }
    componentDidMount() {
        var that = this;
        if(window.location.pathname.indexOf("viewsample")!=-1){
            that.setState({isSampling:true})
        }
        that.loadjquerysteps();
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getfeatures",
            queryString: "",
            sucessFunction: props => {
                that.state.features = props;
                ChartInfo.functions.setAllFeatures(props);
            },
            rejectFunction: () => { },
            headers: requestHeaders
        })
        SendRequest({
            url: "getdatamodelrelationinfo",
            queryString: "?reportId= &datamodelId=" + this.state.datamodelId,
            sucessFunction: props => {
                that.state.multifact = props.multifact;
                that.state.multifactentities = props.entities;
                that.state.facttables = props.facttables;
                var shredmodel = false;
                that.state.isFromSharedModel = sessionStorage.getItem("isSharedModel" + that.state.datamodelId);
                if (that.state.isFromSharedModel == "true") {
                    shredmodel = "shredmodel";
                }
                if(that.state.isSampling){
                    shredmodel = "governance";
                }
                SendRequest({
                    url: "getcolumns",
                    queryString: "?datamodelId=" + that.state.datamodelId + " &multifact=" + that.state.multifact + "&isfrom=" + shredmodel,
                    sucessFunction: cols => {
                        var that = this;
                        that.state.reportcolumns = cols;
                        that.state.dimensions = cols;
                        collection.createDataTypeBucketsForTemp(that.state);
                        that.state.collection = collection;
                        var model = ChartInfo.functions.getDummyDataForReport();
                        var mlreportdata = [];
                        var detailsObj = {};
                        var name = "";
                        var folder = "";
                        var desc = "";
                        //this.collection.updateLocalStorage();
                        this.isLoadMLReport = false;
                        var mlSavedFilterObj = [];
                        var mlSavedSnapshotData = "";
                        this.mlSavedModeldata = "";
                        var customparameterfilters;
                        var title;
                        var isSharedReport = false
                        if (model.mlreportdata != undefined && model.mlreportdata != null && model.mlreportdata.length > 0) {
                            this.isLoadMLReport = true;
                            $(".mlstoryboardtabs.fill-height").addClass("loadingmlreport");
                            $(".spinner").show();
                            mlreportdata = JSON.parse(model.get("mlreportdata"));
                            detailsObj = mlreportdata[1]["details"];
                            name = detailsObj["name"];
                            folder = detailsObj["folder"];
                            desc = detailsObj["desc"];
                            title = detailsObj["title"];
                            mlSavedFilterObj = mlreportdata[2]["filter"];
                            ChartInfo.functions.setAllMLChartDetails(mlreportdata[0]["axis"]);
                            ChartInfo.functions.setMLGridDetails(mlreportdata[3]["grid"]);
                            sessionStorage.setItem("customFields", mlreportdata[4]["customFields"]);
                            ChartInfo.functions.setMLModelDetails(mlreportdata[5]);
                            this.oldMLSelectedColumns = mlreportdata[6]
                            ChartInfo.functions.setMLSelectedColumns(mlreportdata[6]);
                            mlSavedSnapshotData = model.get("ml_snapshot_data");
                            this.mlSavedModeldata = model.get("ml_model_data");
                            if (model.get("is_ml_saved") == 0) {
                                $('.runmodelbtn').addClass("disabled");
                                $('.mlloadingbtn').removeClass("hide");
                            } else if (model.get("is_ml_saved") == -1) {
                                $('.mlwarning').removeClass("hide");
                                var info = lang["mlreports.modelfailed"] + model.get("ml_error_info");
                                $('.mlwarning').attr("title", info);
                            } else if (model.get("is_ml_saved") == 1) {
                                $('.runmodelbtn').removeClass("disabled");
                                $('.mlloadingbtn').addClass("hide");
                            }
                            if (isSharedReport) {
                                $('.runmodelbtn').addClass("disabled");
                            }
                            //
                            if (mlreportdata[7] != undefined) {
                                var customParameter = mlreportdata[7]["customParameter"];
                                ChartInfo.functions.setCustomParameter(JSON.parse(customParameter));
                            }
                            if (mlreportdata[8] != undefined) {
                                customparameterfilters = mlreportdata[8]['customParameterFilters'];
                            }
                        } else {
                            $('.runmodelbtn').addClass("disabled");
                            $('.mlloadingbtn').removeClass("hide");
                        }
                        //Add Header
                        $("#reportheader").empty();
                        var today = new Date();
                        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                        $("#reportheader").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                        that.state.viewReportHeader = new MLReportHeaderView({
                            dynamicdiv: dynamicdiv,
                            viewreportCollection: collection,
                            features: that.state.features,
                            reportId: null,
                            schemaId: "",
                            isPurgedReport: false,
                            reportRequestFrom: "viewmlmodel",
                            isDirect: ChartInfo.functions.getIsDirect(),
                            tempID: that.props.entities,
                            title: lang["mlreports.analyticsboard"],
                            name: "New ML Report",
                            folder: "",
                            desc: "",
                            datamodelId: that.state.datamodelId,
                            collection: that.state.collection
                        });
                        if (this.isLoadMLReport) {
                            setTimeout(function () {
                                $('a[href="#next"]').trigger("click");
                            }, 1500);
                        }
                        that.state.globalfilterArray = [];
                        that.state.viewreportfilterview = new ViewReportFilterView({
                            features: that.state.features,
                            tabid: that.state.tabid,
                            isFromStoryBook: that.state.isFromStoryBook,
                            scheduleid: that.props.scheduleid,
                            multifact: that.state.multifact,
                            facttables: that.state.facttables,
                            multifactentities: that.state.multifactentities,
                            mlrequestfrom:"temp"
                        });
                        $(".sidetheme").empty();
                        $(".sidetheme").append('<div class="panel-group rightfilter" id="filteraccordion"></div>');
                        if (that.state.viewReportAddFilterView != null) {
                            that.state.viewReportAddFilterView = undefined;
                        }
                        var viewReportAddFilterCollection = [];
                        var columns = that.state.dimensions
                        var columnsArray = that.state.dimensions
                        _.each(columnsArray, function (column) {
                            var viewreportaddfiltermodel = {};
                            viewreportaddfiltermodel["tableName"] = column["tableName"];
                            viewreportaddfiltermodel["columnDisplayType"] = column["tableDisplayType"];
                            viewreportaddfiltermodel["tableDisplayType"] = column["tableDisplayType"];
                            viewreportaddfiltermodel["columnName"] = column["columnName"];
                            viewreportaddfiltermodel["columnDisplayName"] = column["columnDisplayName"];
                            viewreportaddfiltermodel["priorityColumn"] = column["priorityColumn"];
                            viewReportAddFilterCollection.push(viewreportaddfiltermodel);
                        });
                        that.state.viewReportAddFilterView = new ViewReportAddFilterView(
                            {
                                collection: viewReportAddFilterCollection,
                                datamodelId: that.state.datamodelId,
                                reportId: that.props.reportId,
                                tabid: that.state.tabid,
                                isFromStoryBook: that.state.isFromStoryBook,
                                scheduleid: that.props.scheduleid,
                                filterArray: that.state.globalfilterArray,
                                viewreportCollection: collection,
                                multifact: that.state.multifact,
                                facttables: that.state.facttables,
                                multifactentities: that.state.multifactentities
                            });
                        if (that.state.globalfilterArray.length == 0) {
                            $(".hidebutton").trigger(
                                'click');
                        } else {
                            $(".showbutton").trigger('click');
                            $('.instant-floating-filters').draggable({ cancel: '.col-lg-12.nopadding', containment: '#main>.boc-viewreport-section', handle: '.panel-heading' });
                        }
                        if(!that.state.isSampling){
                            new ViewMLReportBodyView({
                                reportId: that.props.reportId,
                                filterArray: [],
                                features: that.state.features,
                                viewcollection: collection,
                                columnsArray: that.state.reportcolumns,
                                datamodelId: that.state.datamodelId,
                                mlSavedSnapshotData: (that.isLoadMLReport == true) ? mlSavedSnapshotData : ""
                            });
                        }else{
                                var selectedColumns = [];
                                var selectedColumnNamesList = [];
                               _.each(that.state.dimensions,function(val,index){
                                    var columnObj = {};
                                    columnObj["aggregation"] = val.aggregation?val.aggregation:"count";
                                    columnObj["columnDisplayName"] = val.columnDisplayName;
                                    columnObj["columnName"] = val.columnName;
                                    columnObj["columnssrename"] = val.columnssrename;
                                    columnObj["dateFormat"] = val.dateFormat?val.dateFormat:"";
                                    columnObj["id"] = index ;
                                    columnObj["keycolumn"] = val.keycolumn;
                                    columnObj["orginalColumnName"] =val.orginalColumnName;
                                    columnObj["priorityColumn"] = val.priorityColumn;
                                    columnObj["tableAliasName"] = val.tableAliasName;
                                    columnObj["tableDisplayName"] = val.tableDisplayName
                                    columnObj["tableDisplayType"] = val.tableDisplayType;
                                    columnObj["tableName"] = val.tableName;
                                    columnObj["unique"] = val.unique;
                                    columnObj["visible"] = val.visible;
                                    selectedColumns.push(columnObj);
                                    selectedColumnNamesList.push(val.columnName?val.columnName:"");
                                });
                                that.selectedColumns = selectedColumns;
                                ChartInfo.functions.setMLSelectedColumns(selectedColumns);

                                that.ViewMLParametersView = new ViewMLParametersView({
                                    viewreportCollection: that.collection,
                                    features: that.state.features,
                                    reportId: that.state.reportId,
                                    schemaId: "",
                                    measures: that.state.measures,
                                    dimensions: that.state.dimensions,
                                    parentReportId: "",
                                    selectedColumns: that.state.reportcolumns,
                                    datamodelId: that.state.datamodelId,
                                    selectedColumnNamesList: selectedColumnNamesList,
                                    isSampling:that.state.isSampling
                                });
                
                        }
                        
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders
                });
                if (that.state.multifact != null && that.state.multifact != undefined && that.state.multifact === "true") {
                    $("body").addClass('multifact_enabled');
                } else {
                    $("body").removeClass('multifact_enabled')
                }
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
        var urlparameters = window.location.search.replace("?", "").split("&");
        $.each(urlparameters, function (i, u) {
            var parameter = u.split("=");
            if (parameter[0].toLowerCase() == "bottomfooter" && parameter[1].toLowerCase() == "false") {
                that.setState({ displayFooter: false });
            }
        });
        this.eventFunctions();
    }
    eventFunctions() {
        var that = this;
        $('#refreshmlcharts').click(function (e) {
            that.refreshmlcharts(e);
        });
        $(".createfeatureengineeringfield").click(function (e) {
            that.createFeatureEngineeringField(e);
        });
        $(".togglefieldengineering").click(function (e) {
            that.togglefieldengineering(e);
        });
        $(".rightfiltersapply").click(function (e) {
            that.rightfiltersapply(e);
        });
        $(".modalfilter").click(function (e) {
            that.showfilter(e);
        });
        $(".maxcustomparameterfiltersdropdown").click(function (e) {
            that.showaddcustomparameterfiltermodal(e);
        });
        $('.addFiltermodal').click(function (e) {
            that.showaddfiltermodal(e);
        });
        $(".maxfiltersdropdown").click(function (e) {
            that.showaddfiltermodal(e);
        });
        $(".modalApply").click(function (e) {
            that.addFilter(e);
        });
        $(".customparameterfiltermodal").click(function (e) {
            this.addCustomParameterFilter(e);
        });
        $(".toggleinstantfiltersmenu").click(function (e) {
            that.togglefiltersmenu(e);
        });
        $(".hidebutton").click(function (e) {
            that.hiderightpane(e);
        });
        $(".deletecustomfield").click(function (e) {
            that.deletecustomfield(e);
        });
        $(".runmodelbtn").click(function (e) {
            that.runmodelbtn(e);
        });

    }
    deletecustomfield = (event) => {
        var that = this;
        var removableitem = event.currentTarget.title;
        $(".deleteyes").unbind("click");
        $(".deleteno").unbind("click");
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessage-info').attr('data-num', undefined);
        $('.birdmessagebuttons').addClass('show');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning51'));
        $(".deleteyes").click(function (e1) {
            // e1.preventDefault();
            $('.birdmessage-center').removeClass("message-top-in");
            // var removableitem = e.currentTarget.title;
            var customFields = JSON.parse(sessionStorage.customFields);
            for (var i = 0; i < customFields.length; i++) {
                if (customFields[i].name === removableitem) {
                    customFields.splice(i, 1);
                }
            }
            sessionStorage.customFields = JSON.stringify(customFields);
            var customfieldscount = JSON.parse(sessionStorage.customFields);
            if (customfieldscount.length === 0) {
                sessionStorage.removeItem('customFields');
            }
            $('.attributescustomfields[data-name="' + removableitem + '"]').remove();
            $('.attributesmeasures[data-name="' + removableitem + '"]').remove();
            $('.singleliitems[data-name="' + removableitem + '"]').remove();
            var convertedValues = sessionStorage.getItem("convertedvalues");
            var parsedValues = JSON.parse(convertedValues);
            if (parsedValues != null && parsedValues != undefined) {
                var res = $.grep(parsedValues, function (val) {
                    return val.columnDisplayName != removableitem;
                });
                sessionStorage.setItem("convertedvalues", JSON.stringify(res));
            }
            $("#bootstrap-duallistbox-nonselected-list_ml_duallistbox option[value= '" + removableitem + "' ]").remove();
            $("#bootstrap-duallistbox-selected-list_ml_duallistbox option[value= '" + removableitem + "']").remove();
            $('select[name="ml_duallistbox"] option[value= "' + removableitem + '" ]').remove();
        });
        $(".deleteno").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
        });
    }
    hiderightpane = () => {
        $('.hidebutton').css("display", "none");
        $('.showbutton').css("display", "block");
        $('.instant-floating-filters').hide();
        $('.instant-floating-filters').draggable({ cancel: '.col-lg-12.nopadding', containment: '#main>.boc-viewreport-section', handle: '.panel-heading' });
    }
    addCustomParameterFilter = () => {
        this.state.viewReportAddFilterView.addFilter();
    }
    showaddcustomparameterfiltermodal = () => {
        $('.trend-stats-container').remove();
        $('.viewdatafromchart').addClass('hide');
        $('.viewchartsummary').addClass('hide');
        var that = this;
        var customParameterFields = ChartInfo.functions.getCustomParameter();
        var customParameterModal = new ViewReportAddCustomParameterFilters({
            customparameterfileds: customParameterFields,
            datamodelId: that.state.datamodelId
        });
        $('.modal-anim-left').prop('class', 'modal fade').addClass("left");
        $('#mySmallModal0').modal("show");
        ModalProperties.resize();

    }
    showfilter = (e) => {

    }
    rightfiltersapply = (e) => {
        sessionStorage.setItem("changesdone", 1);
        ChartInfo.functions.getFilters(false);
        let selectedModel = $('.mlpredicttionmodal').val();
        if(selectedModel == "automl"){
            ChartInfo.functions.setIsFilterAppliedForAutoML(true)
        }
        $("#mlsnapshotdatacontainer").empty();
        $(".smartinsightintro-container").removeClass("hide");
        $('.runmodelbtn').addClass("disabled");
        $('.mlloadingbtn').removeClass("hide");
        var isRunModel = true;
        this.mlContainerCurrentIndex = $('#mlsplitcontainer').steps('getCurrentIndex');
        var mapdata = {};
        mapdata["isRunModel"] = isRunModel;
        mapdata["isReload"] = false;
        PubSub.publish('applyfiltesandredrawmlchart', mapdata);
        if (this.mlContainerCurrentIndex == 3) {
            this.loadNextPage(undefined, this.mlContainerCurrentIndex, true);
        } else {
            $("a[href=#mlsplitcontainer-h-" + this.mlContainerCurrentIndex + "]").trigger("click");
        }
        $(".instant-floating-filters.rightpane").removeClass("changesdone");
    }
    createFeatureEngineeringField = (e) => {
        var $this = e.currentTarget;
        var edit = $($this).attr("data-edit") != undefined;
        var columnname = $($this).attr("data-name");
        var dimensions = this.state.dimensions;
        dimensions.sort(function (a, b) {
            var x = a.columnDisplayName.toLowerCase();
            var y = b.columnDisplayName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        this.showcustomfielddialog(dimensions, edit, columnname);
    }
    showcustomfielddialog(dimensions, isEdit, columnName) {
        var that = this;
        var customParameterFields = ChartInfo.functions.getCustomParameter();
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".mlcustomfieldsmodal").empty();
        $(".mlcustomfieldsmodal").append("<div class='custommeasurediv0 " + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
        that.customMeasureModal = new CustomFieldModal({
            dynamicdiv: dynamicdiv,
            dimensions: dimensions,
            isEdit: isEdit,
            columnName: columnName,
            chartnumber: 0,
            datamodelId: that.state.datamodelId,
            reportId: that.state.reportId != null && that.state.reportId != undefined ? that.state.reportId : "",
            multifact: that.state.multifact,
            multifactentities: that.state.multifactentities,
            customparameters: customParameterFields,
            facttables: that.state.facttables,
            schemaType: that.state.schemaType,
        });
        $(".mlcustomfieldsmodal").append($("#" + dynamicdiv));
        that.customMeasureModal.addData();
        try {
            $('.modal-anim-left').prop('class', 'modal fade').addClass("left");
            // $("#createcustomfield").modal("show");
            ModalProperties.resize();
            that.customMeasureModal.textAreaAutoComplete();
        } catch (e) {
            console.log(e);
        }
        $(window).on("resize", function () {
            ModalProperties.resize();
        });
    }
    runmodelbtn = (e) => {
        $('.runmodelbtn').addClass("disabled");
        $('.mlloadingbtn').removeClass("hide");
        ChartInfo.functions.getFilters(false);
        var mapdata = {};
        mapdata["isRunModel"] = true;
        mapdata["isReload"] = false;
        PubSub.publish('applyfiltesandredrawmlchart', mapdata);
        this.mlContainerCurrentIndex = $('#mlsplitcontainer').steps('getCurrentIndex');
        this.loadNextPage(undefined, this.mlContainerCurrentIndex, true);
    }
    refreshmlcharts = (e) => {
        $("#mlsnapshotdatacontainer").empty();
        $(".smartinsightintro-container").removeClass("hide");
        $('.runmodelbtn').addClass("disabled");
        $('.refreshmlcharts').addClass("disabled");
        ChartInfo.functions.getFilters(false);
        var mapdata = {};
        mapdata["isRunModel"] = false;
        mapdata["isReload"] = true;
        PubSub.publish('applyfiltesandredrawmlchart', mapdata);
        this.mlContainerCurrentIndex = $('#mlsplitcontainer').steps('getCurrentIndex');
        this.loadNextPage(undefined, this.mlContainerCurrentIndex, false);
    }
    render() {
        return (
            <MLReportTemplate createFeatureEngineeringField={this.createFeatureEngineeringField} tabid={this.state.tabid} displayFooter={this.state.displayFooter} loadjquerysteps={this.loadjquerysteps} />
        );
    }
    unbindEvents() {
        ChartInfo.functions.resetChartDetails();
        sessionStorage.removeItem("convertedvalues");
        sessionStorage.removeItem("customFields");
        sessionStorage.removeItem("customfieldentities");
        sessionStorage.removeItem("renameMeasuresorDimensions");
        sessionStorage.removeItem("datatable_popup_yaxis");
        sessionStorage.removeItem("convertedvalues");
        sessionStorage.removeItem("changesdone");
        sessionStorage.removeItem('isconvettedvaluesapplied');
        sessionStorage.removeItem('isconvettedtypesapplied');
        sessionStorage.removeItem("renameMeasuresorDimensions");
        sessionStorage.removeItem("datatable_popup_filterdata");
        sessionStorage.removeItem("instantExcludeFilters");
        sessionStorage.removeItem("instantIncludeFilters");
        sessionStorage.removeItem("instantExcludeCustomFilters");

        for (var i = 0; i < sessionStorage.length; i++) {
            var key = sessionStorage.key(i);
            if (key.match(/drilldown/g) != null) {
                sessionStorage.removeItem(key);
            }
        }
    }
}