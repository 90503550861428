import React from 'react';
import ReactDOM from 'react-dom';
import { config } from 'process';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class SaveWorkspaceModalHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sync_disable: false,
            realtimeValue:"24"
        }
    }
    render() {
        return (
            <div className="modal fade in" id="savesworkspace" tabIndex="-1" role="dialog" aria-labelledby="savesworkspace" aria-hidden="true" style={{ display: "block" }}>
                <div class="modal-backdrop fade in" onClick={() => { ReactDOM.unmountComponentAtNode(document.getElementById('saveworkspacemodal')); }}></div>
                <div className="modal-dialog modal-lg modal-save-report modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="storyboardclose" aria-hidden="true" data-dismiss="modal" onClick={() => { ReactDOM.unmountComponentAtNode(document.getElementById('saveworkspacemodal')); }}>&times;</button>
                            <h4 className="modal-title"><i className="fa fa-save"></i> {lang["workspace.savespace"]}</h4>
                        </div>
                        <div className="modal-body scrollset marg-top-20">
                            <div className="container-fluid">
                                <div className="row">
                                    <fieldset className="savereport-border">
                                        <legend className="savereport-border">{lang["workspace.details"]}</legend>
                                        <div className="control-group">
                                            <div className="validationgroup form-group" style={{ marginTop: "-2px" }}>
                                                <span className="required right legend"></span>
                                                <label className="subhead" htmlFor="workspacename">{lang["workspace.name"]} </label>
                                                <input type="name" id="workspacename" name="workspacename" defaultValue={this.props.workspaceName} className="form-control" placeholder={lang["workspace.placeholdname"]} />
                                                <span className="help-block  marg-left-10 wsname-error"></span>
                                            </div>
                                            <div className="validationgroup form-group marg-top-10">
                                                <label className="subhead nopadding">{lang["workspace.describe"]} </label>
                                                <textarea id="workspacedesc" placeholder={lang["workspace.placeholddescribe"]} defaultValue={this.props.workspaceDesc} name="workspacedesc" className="form-control resize-vertical"></textarea>
                                                <span className="help-block hidden"></span>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset className="savereport-border">
                                        <legend className="savereport-border">{lang["workspace.livestatus"]}</legend>
                                        <div className="row datasyncschedulecontrollers">
                                            <div className="col-xs-12">
                                                <div class="col-xs-12 marg-top-10 streamingsetting" id="wsstreamingsetting">
                                                    {/* <span class="title">{lang["datahub.status"]}</span> */}
                                                    <div class="checkbox pull-left folderview">
                                                        <span><i class=""></i> {lang["workspace.liveoff"]} </span>
                                                        <label class="checkbox-slider--c">
                                                            <input type="checkbox" id="streamingstatus" onClick={this.livetoggle} class="dashboard-view-style dashboardview" />
                                                            <span></span>
                                                        </label>
                                                        <div><i class=""></i> {lang["workspace.liveon"]}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 marg-top-10 hide" id="noteforlive">
                                                <div className="livetype">
                                                    <label><input type="radio"  id="todaylive" className="realtimedataselectiontype" name="realtimedateselection" defaultChecked onClick={(e) => { this.changedataloadconfigtype(e) }} />&nbsp;{lang["workspace.todaylive"]}&nbsp;</label>
                                                    <label><><input type="radio" id="customlive" className="realtimedataselectiontype" name="realtimedateselection" onClick={(e) => { this.changedataloadconfigtype(e) }} />&nbsp;<span><input type="number" className="customlive" placeholder="1" min="1" max="24" defaultValue="24" onChange={this.validateInput}/></span> {lang["workspace.customlive"]}</></label>
                                                </div>
                                                {/* <div className="livetype">
                                                </div> */}
                                                <p className="fa-red todaylive"><i>{lang["workspace.notefortodaylive"]}</i></p>
                                                <p className="fa-red customlive hide"><i>{lang["workspace.noteforlive"]}</i></p>
                                            </div>
                                        </div>
                                    </fieldset>
                                    {!this.state.sync_disable ? <fieldset className="savereport-border" id="workspacesync">
                                        <legend className="savereport-border">{lang["workspace.syncschedule"]}</legend>
                                        <div className="row datasyncschedulecontrollers">
                                            <div className="col-xs-12">
                                                <select className="modal-form-fields syncconfig syncdomelement syncdomelementworkspace" id="syncdomelements">
                                                    {this.props.syncConfigs != undefined ?
                                                        this.props.syncConfigs.map((config, index) => {
                                                            return <option key={index} value={config.sync_id} status={config.status}>{`${config.sync_details != undefined && JSON.parse(config.sync_details).cronDetails != undefined ? JSON.parse(config.sync_details).cronDetails : ""} -- [ ${config.sync_name} ]`}</option>

                                                            //   return <option key={index} value={config.sync_id}>{`${config.sync_name} -- [ ${ config.sync_details!=undefined && JSON.parse(config.sync_details).cronDetails !=undefined ? JSON.parse(config.sync_details).cronDetails:""} ]`}</option>
                                                        })
                                                        : null}
                                                </select>
                                            </div>
                                            {/* <div className="control-group col-lg-6">
                                                <div className="col-lg-12 marg-top-5 marg-left-10">
                                                    <div className="col-xs-5 col-lg-4 nopadding">
                                                        <label><input type="radio" name="datasync" id="defaultcontainer" className="datasynctype" defaultChecked onChange={(e)=>{this.props.changeSyncType(e);}} />&nbsp;Default</label>
                                                    </div>
                                                    <div className="col-xs-7 col-lg-4 nopadding">
                                                        <label><input type="radio" name="datasync" id="custom" className="datasynctype" onChange={(e)=>{this.props.changeSyncType(e);}} />&nbsp;Custom</label>
                                                    </div>
                                                </div>
                                                <div className="datasync-container defaultcontainer">
                                                    <div className="col-xs-3">
                                                        <span className="prefix">Every</span>
                                                    </div>
                                                    <div className="col-xs-5">
                                                        <input type="number" value="30" className="modal-form-fields syncdomelement" disabled="disabled" />
                                                    </div>
                                                    <div className="col-xs-3">
                                                        <span className="suffix">Minute(s)</span>
                                                    </div>
                                                </div>
                                                <div className="datasync-container custom hide ">
                                                    <div className="col-xs-12">
                                                        <select className="modal-form-fields syncreport syncdomelement" id="syncdomelements" onChange={(e)=>{this.props.changeSyncOption(e)}}>
                                                            <option value="days">Days</option>
                                                            <option value="hours">Hours</option>
                                                            <option value="minutes" selected>Minutes</option>
                                                            <option value="seconds" id="secondsdisable">Seconds</option>
                                                        </select>
                                                    </div>
                                                    <div className="synccontainer seconds hide">
                                                        <div className="col-xs-3">
                                                            <span className="prefix">Every</span>
                                                        </div>
                                                        <div className="col-xs-5">
                                                            <input type="number" name="seconds" id="seconds" max="59" min="5" defaultValue="30" className="modal-form-fields syncdomelement" />
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <span className="suffix">Second(s)</span>
                                                        </div>
                                                    </div>
                                                    <div className="synccontainer minutes">
                                                        <div className="col-xs-3">
                                                            <span className="prefix">Every</span>
                                                        </div>
                                                        <div className="col-xs-5">
                                                            <input type="number" name="minutes" id="minutes" max="59" min="1" defaultValue="30" className="modal-form-fields syncdomelement" />
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <span className="suffix">Minute(s)</span>
                                                        </div>
                                                    </div>
                                                    <div className="synccontainer hours hide">
                                                        <div className="col-xs-3">
                                                            <span className="prefix">Every</span>
                                                        </div>
                                                        <div className="col-xs-5">
                                                            <input type="number" name="hours" id="hours" max="23" min="1" defaultValue="1" className="modal-form-fields syncdomelement" />
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <span className="suffix">Hour(s)</span>
                                                        </div>
                                                    </div>
                                                    <div className="synccontainer days hide">
                                                        <div className="col-xs-3">
                                                            <span className="prefix">Every</span>
                                                        </div>
                                                        <div className="col-xs-5">
                                                            <input type="number" name="days" id="days" max="31" min="1" defaultValue="1" className="modal-form-fields syncdomelement" />
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <span className="suffix">Day(s)</span>
                                                        </div>
                                                        <div style={{ color: "red", fontSize: "14px", top: "-10px" }} className="col-xs-12  syncerrordays hide"></div>
                                                        <div className="clear"></div>
                                                        <div className="col-xs-3 marg-top-10 text-right nopadding">
                                                            <span className="subhead">Time</span>
                                                        </div>
                                                        <div className="col-xs-8 nopadding">
                                                            <div className="input-group sync-day-time input-group-sm">
                                                                <input type="text" name="sync-day-time-start" id="sync-day-time-start" placeholder="HH:mm" className="form-control marg-left-5" defaultValue="" maxLength="5" />
                                                                <div className="input-group-addon"><i className="fa fa-clock-o"></i></div>
                                                            </div>
                                                        </div>
                                                        <div style={{ color: "red", fontSize: "14px", top: "-1px" }} className="col-xs-12 syncdaytimeerror" id="errormsg"></div>
                                                    </div>
                                                </div>
                                                <div style={{ color: "red" }} className="col-xs-12 syncerror hide"></div>
                                            </div> */}
                                            <div className="col-xs-12 marg-top-10">
                                                <p className="fa-red"><i>{lang["workspace.note"]}</i></p>
                                            </div>
                                        </div>
                                    </fieldset> : <React.Fragment />}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" data-dismiss="modal" onClick={() => { ReactDOM.unmountComponentAtNode(document.getElementById('saveworkspacemodal')); }}><i className="fa fa-times"></i> {lang["workspace.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird saveworkspacemodal" id="saveworkspacemodal" onClick={this.props.saveworkspace}><i className="fa fa-check"></i> {lang["workspace.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    componentDidMount() {
        var metadataObj = this.props.metadataObj;
        var bool = this.props.checkForCustomQueryOrStreamingEntiyPresent();
        this.setState({ sync_disable: bool })
        if (metadataObj != undefined && metadataObj.isWsLiveEnabled != undefined && metadataObj.isWsLiveEnabled) {
            $("#streamingstatus").prop('checked', true);
            $("#noteforlive").removeClass("hide");
            $("#workspacesync").addClass("hide");
            if(metadataObj.type!="today"){
                $("#customlive").prop('checked', true);
                if(metadataObj.type){
                    $(".customlive").val(metadataObj.type);
                    $("#noteforlive>p.customlive>i").text($("#noteforlive>p.customlive>i").text().replace(this.state.realtimeValue.toString(),metadataObj.type))
                    this.state.realtimeValue = metadataObj.type;
                }
                $("p.customlive").removeClass('hide')
                $("p.todaylive").addClass('hide')
            }
        } else if (metadataObj != undefined && metadataObj.isWsLiveEnabled != undefined && !metadataObj.isWsLiveEnabled) {
            $("#wsstreamingsetting").addClass('disabled');
        }
    }
    livetoggle() {
        if ($("#streamingstatus").prop('checked')) {
            $("#noteforlive").removeClass("hide");
            $("#workspacesync").addClass("hide");
        } else {
            $("#noteforlive").addClass("hide");
            $("#workspacesync").removeClass("hide");
        }
    }
    changedataloadconfigtype = (e) =>{
        var $this = e.currentTarget
        var id = $($this).attr('id');
        if(id==="todaylive"){
            $("p.customlive").addClass('hide')
            $("p.todaylive").removeClass('hide')
        }else{
            $("p.customlive").removeClass('hide')
            $("p.todaylive").addClass('hide')
        }

    }
    validateInput = (e) =>{
        if(this.isFloat(e.target.value)){
            e.target.value = e.target.value.split(".")[0]
        }else if(e.target.value>24){
            e.target.value = 24
        }else if(e.target.value&&e.target.value<1){
            e.target.value = 1
        }
        if(e.target.value){
            $("#noteforlive>p.customlive>i").text($("#noteforlive>p.customlive>i").text().replace(this.state.realtimeValue.toString(),e.target.value))
            this.state.realtimeValue = e.target.value.toString();
        }
    }
    isFloat(n) {
        return n % 1 !== 0;
    }
}