import React from "react";
import ReactDOM from "react-dom";
import _ from 'underscore';
import jstz from 'jstz'
import $ from 'jquery/dist/jquery';
import 'gridstack/dist/gridstack.all';
import PubSub from 'pubsub-js'
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import ReportGridHtml from "./reportgridhtml";
import AttrModalView from "./attrmodalview";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as TabInfo from "../js/helpers/utils/tabinfo";
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import * as collection from "../js/helpers/utils/SavedReportCollection";
import * as canvg from 'canvg';
import * as html2canvas from 'html2canvas';
import ReportBody from './ReportBody.jsx';
import DataTablePopupView from "./DataTablePopupView";
import { getMessage, messagesMap } from '../js/helpers/utils/BirdMessage';
import ViewReportFilterView from './viewreportfilter';
import customParameterAddFilterView from "./customparameterfield/viewreportaddcustomparameterfilter"
import ViewReportAddFilterView from "./viewreportaddfilterview";
import SummarytableDrilldownModal from './summarytable/summarytabledrilldownmodal.jsx';
import QuickTemplate from './drilldownfilters.jsx';
import ViewReportHeader from './viewreportheader';
import SmartInsightsView from './smartinsights/smartinsightsview'
import SavedSmartInsightsView from './smartinsights/savedsmartinsightview'
import SmartInsightSpinner from '../images/birdimages/smartinsightspinner.png'
import tileFiltersModal from './tilefiltersmodal';
import * as attrchange from "attrchange";
import StoryboardSmartInsightsView from "../ViewStoryboard/storyboardsmartinsightsview";

var dobycolumnnames;
export default class ReportView extends React.Component {
	constructor(props) {
		super(props);
		//	this.instantExclFiltersData = [];
		this.state = {
			isTempStoryBoard: props.isTempStoryBoard,
			scheduleid: props.scheduleid,
			date: props.date,
			model: props.model,
			instantExclFiltersData: [],
			allfeatures: props.features,
			features: props.features,
			reportInformation: props.reportInformation,
			multifactentities: props.multifactentities,
			facttables: props.facttables,
			multifact: props.multifact != undefined && props.multifact != null ? props.multifact : false,
			view_Only_Flag: props.view_Only_Flag,
			isViewOnly: props.view_Only_Flag,
			reportId: props.reportId,
			datamodelId: props.datamodelId,
			isDrillDownAllenable:false,
			isDirect: props.isDirect,
			isStoredProcedure: false,
			isEmbeddedReport: false,
			isPurgedReport: false,
			isSharedReport: false,
			isScheduleReport: false,
			displayFooter: true,
			isPublished: props.isPublished,
			schemaType: null,
			isMicrosoftEdge: null,
			charttitle: null,
			grid: null,
			reportcolumns: props.reportcolumns,
			isForAddInstantFilters: false,
			grids: [],
			newgrids: [],
			update: false,
			isFromCloneTile: false,
			isFromStoryBook: props.isFromStoryBook,
			isFromTabClick: props.isFromTabClick,
			tabid: props.tabid,
			el: props.el,
			isFromSmartInsigt: props.isFromSmartInsigt,
			isFromSavedSmartInsight: props.isFromSavedSmartInsight,
			isVersioned: props.isVersioned,
			versionname: props.versionname,
			userRole:props.userRole,
			isAlreadyCalled:false,
			isDrillAlreadyCalled:false
		}

		if(props.model!=undefined && props.model.reportdata!=undefined && props.model.reportdata!=""){
			var intRepInfo  = JSON.parse(props.model.reportdata);
			var drillAllStatus = intRepInfo.find(o => o.isDrillDownAllenable != undefined).isDrillDownAllenable;
			if(drillAllStatus!=null && drillAllStatus!=undefined && drillAllStatus!="" && drillAllStatus === true){
				this.state.isDrillDownAllenable = true
			}
		}

		if (window.location.pathname.indexOf("embeddedreport") >= 0 || window.location.pathname.indexOf("alertreport") >= 0) {
			this.state.isEmbeddedReport = true;
			sessionStorage.setItem("isDataTableDataExport", false);
		}
		if (window.location.pathname.indexOf("sharereport") >= 0) {
			this.state.isSharedReport = true;
		}
		if (window.location.pathname.indexOf("schedulereport") >= 0) {
			this.state.isScheduleReport = true;
			sessionStorage.setItem("isDataTableDataExport", false);
		}
		this.state.isImportedReport = false;
		if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
			this.state.isImportedReport = true;
		}
		this.pathArr = this.props.location != undefined ? this.props.location.pathname.split("/") : window.location.pathname.split("/");
		this.viewreportfilterview = "";
		this.viewReportAddFilterView = "";
		PubSub.unsubscribe('newcharttile', this.newcharttile.bind(this));
		PubSub.unsubscribe("drawhistogram", this.drawHistogramEve.bind(this));
		PubSub.unsubscribe("quickfilterremove", this.quickFilterRemoveEve.bind(this));
		PubSub.unsubscribe('cloneTile', this.cloneTile.bind(this));
		this.removeFilter = PubSub.subscribe("quickfilterremove", this.quickFilterRemoveEve.bind(this));
		this.ResetsummarytableEve = PubSub.subscribe("resetsummarytable", this.resetsummarytableEve.bind(this));
		this.newcharttileEvee = PubSub.subscribe('newcharttile', this.newcharttile.bind(this));
		this.viewDataTablePopUpEve = PubSub.subscribe('viewDataTablePopUp', this.viewDataTablePopUpEve.bind(this));
		this.viewDataTablePopUp = this.viewDataTablePopUp.bind(this);
		this.excludechartpointEve = PubSub.subscribe('exclude-point', this.excludechartpointEve.bind(this));
		this.excludechartpoint = this.excludechartpoint.bind(this);
		this.keeponlychartpointEve = PubSub.subscribe('keeponly-point', this.keeponlychartpointEve.bind(this));
		this.keeponlychartpoint = this.keeponlychartpoint.bind(this);
		this.cloneTileEve = PubSub.subscribe('cloneTile', this.cloneTile.bind(this));
		this.deleteTileEve = PubSub.subscribe('deleteTile', this.deleteTile.bind(this));
		this.viewsummarytabledrilldownEve = PubSub.subscribe("viewsummarytabledrilldown", this.summaryTableDrilldown.bind(this));
		this.DrawSummarytableDatehistogramEve = PubSub.subscribe("drawsummarytabledatehistogram", this.drawsummarytabledatehistogramEve.bind(this));
		this.togglevisualizationsettingsEve = PubSub.subscribe('togglevisualizationsettings', this.togglevisualizationsettingsEve.bind(this));
		this.rightfiltersapplyStoryEve = PubSub.subscribe('rightfiltersapplyStory', this.rightfiltersapply.bind(this));
		this.addCustomParameterFilterEve = PubSub.subscribe('addCustomParameterFilter', this.addCustomParameterFilter.bind(this))
		this.maxfiltersdropdownoldEve = PubSub.subscribe('maxfiltersdropdownold', this.maxfiltersdropdownoldEve.bind(this))
		this.maxcustomparameterfiltersdropdownoldEve = PubSub.subscribe('maxcustomparameterfiltersdropdownold', this.maxcustomparameterfiltersdropdownoldEve.bind(this))
		this.updateFiltersEve = PubSub.subscribe('updateFilters', this.updateFiltersEve.bind(this));
		this.ApplySummarytableDrilldownEve = PubSub.subscribe("applysummarytabledrilldown", this.applysummarytabledrilldownEve.bind(this));
		this.drawHistogramEven = PubSub.subscribe("drawhistogram", this.drawHistogramEve.bind(this));
		this.removedrilldown = PubSub.subscribe("removedrilldown", this.removeDrilldownEve.bind(this));
		this.downloadPNGE = PubSub.subscribe("storyboard-download", this.downloadPNGEve.bind(this));
		this.appendFiltersToTilesEvee = PubSub.subscribe("appendfilterstotiles", this.appendFiltersToTilesEve.bind(this));
		this.tileFilterMenuToggleEvee = PubSub.subscribe('tileFilterMenuToggle', this.tileFilterMenuToggleEve.bind(this));
		this.maxdatatablesEvee = PubSub.subscribe('maxdatatables', this.maxdatatablesEve.bind(this));
		this.showdobyselectedcolumnsEvee = PubSub.subscribe('showdobyselectedcolumns', this.showdobyselectedcolumnsEve.bind(this));
		this.applydobysettingsEvee = PubSub.subscribe('applydobysettings', this.applydobysettingsEve.bind(this));
	}
	componentWillUnmount() {
		PubSub.unsubscribe(this.cloneTileEve);
		PubSub.unsubscribe(this.newcharttileEvee);
		PubSub.unsubscribe(this.deleteTileEve);
		PubSub.unsubscribe(this.togglevisualizationsettingsEve);
		PubSub.unsubscribe(this.keeponlychartpointEve);
		PubSub.unsubscribe(this.excludechartpointEve);
		PubSub.unsubscribe(this.viewDataTablePopUpEve);
		PubSub.unsubscribe(this.ApplySummarytableDrilldownEve);
		PubSub.unsubscribe(this.viewsummarytabledrilldownEve);
		PubSub.unsubscribe(this.tileFilterMenuToggleEvee);
		PubSub.unsubscribe(this.showdobyselectedcolumnsEvee);
		PubSub.unsubscribe(this.drawHistogramEven);
		PubSub.unsubscribe(this.applydobysettingsEvee);
	}
	componentDidMount() {
		//$("body>.spinner").removeClass("white");
		var that = this;
		if (!this.state.isFromTabClick) {
			this.loadFiltersData();
			this.loadQuickFilters();
		}
		const options = {
			vertical_margin: 8,
			auto: true,
			float: false,
			draggable: {
				handle: '.dragme',
				scroll: true
			},
			resizable: {
				handles: 'se, sw'
			}
		};
		this.state.grid = $('.grid-stack').gridstack(options).data('gridstack');
		if(this.state.reportId!=""){$(".tileview").addClass("loading");}
		if (this.state.reportInformation != undefined && !this.state.isFromSmartInsigt) {
			// var gridInfo = this.state.reportInformation[3].grid;
			// gridInfo.map((child, index) => {
			// 	var idAttribute = "chartnum" + child.num;
			// 	var gridName = "chartnum" + child.num;
			// 	var tileIndex = this.state.grid.grid.nodes.findIndex(function (a, index) {
			// 		return a.el[0] != undefined && a.el[0].getAttribute("data-num") == child.num
			// 	});
			// 	if (tileIndex >= 0) {
			// 		this.state.grid.grid.nodes[tileIndex].width = child.width;
			// 		this.state.grid.update("chart" + child.num, child.x, child.y, child.width, child.height);
			// 		// //this.state.grid.makeWidget("chart" + child.num);
			// 		// //this.state.grid.grid.nodes[tileIndex].width = child.width;
			// 		$("#tileview" + child.num + "").attr("data-gs-width", child.width);
			// 	}
			// })
		}else{
			$(".tileview").removeClass("loading");
		}
		$('.grid-stack').on('resizestop', function (event, ui) {
			var element = event.target;
			if (element.getAttribute('data-num') != undefined && element.getAttribute('data-num') != null && element.getAttribute('data-num') != "") {
				PubSub.publish('modifyChartHeightOnGridResize', element);
				setTimeout(function () {
					//PubSub.publish('modifyChartHeightOnGridResize', element);
				}, 500);
			}
		});
		$('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) })
		//Smartinsights code
		if (this.state.isFromSavedSmartInsight != undefined && this.state.isFromSavedSmartInsight) {
			var globalfilterArray = ChartInfo.functions.getFilterArray();
			var that = this;
			that.smartInsightsView = new SavedSmartInsightsView({
				viewreportCollection: collection,
				reportId: that.props.reportId,
				datamodelId: that.props.datamodelId,
				features: that.props.features,
				isPurgedReport: false,
				schemaType: null,
				globalfilterArray: JSON.stringify(globalfilterArray),
				element: that.props.tabid != undefined ? 'storybooktabcontent' + that.props.tabid : '',
				isFromStoryBook: that.props.isFromStoryBook,
				tabid: that.props.tabid,
				isFromTabClick: that.state.isFromTabClick,
				dummyelement: that.state.el
			});
		} else if (this.state.isFromSmartInsigt != undefined && this.state.isFromSmartInsigt) {
			var globalfilterArray = ChartInfo.functions.getFilterArray();
			var smartInsightsView = new SmartInsightsView({
				viewreportCollection: collection,
				reportId: null,
				datamodelId: that.props.datamodelId,
				features: that.props.features,
				isPurgedReport: false,
				schemaType: null,
				globalfilterArray: JSON.stringify(globalfilterArray)
			});
		}

		window.$('.gridspinner').attrchange({
			trackValues: true,
			callback: function (event) {
				var $this = event.target;
				var chartid = $($this).attr('data-num');
				$('#spinner' + chartid + ' .gridspinnermessage').empty();
				$('#spinner' + chartid + ' .gridspinnermessage').removeClass('showopacity')
				$('#spinner' + chartid + ' .gridspinnermessage').removeClass('oops');
				$('#spinner' + chartid + ' .gridspinnercontent img').removeClass('hide');

				setTimeout(function () {
					var state = $('#spinner' + chartid + '').css('display');
					if (state === 'block') {
						var currenttext = $('#spinner' + chartid + ' .gridspinnermessage').html();
						if (currenttext != lang["storyboard.serverresponse"] + ' <div class="btn btn-transparent gridspinnercontinue singlespinnerwait' + chartid + '"><i class="fa fa-check-circle"></i>&nbsp;' + lang["storyboard.wait"] + '</div>&nbsp;(or)&nbsp;<div class="btn btn-transparent singlespinnerclose' + chartid + ' gridspinnerclose"><i class="fa fa-times-circle"></i>&nbsp;' + lang["storyboard.close"] + '</div>') {
							$('#spinner' + chartid + ' .gridspinnermessage').text('We are processing your request...');
						}
						$('#spinner' + chartid + ' .gridspinnermessage').addClass('showopacity');
						setTimeout(function () {
							$('#spinner' + chartid + ' .gridspinnercontent img').addClass('hide');
							$('#spinner' + chartid + ' .gridspinnermessage').html(lang["storyboard.serverresponse"] + '<div class="btn btn-transparent gridspinnercontinue singlespinnerwait' + chartid + '"><i class="fa fa-check-circle"></i>&nbsp;' + lang["storyboard.wait"] + '</div>&nbsp;(or)&nbsp;<div class="btn btn-transparent singlespinnerclose' + chartid + ' gridspinnerclose"><i class="fa fa-times-circle"></i>&nbsp;' + lang["storyboard.close"] + '</div>');
							$('#spinner' + chartid + ' .gridspinnermessage').addClass('oops');

							$('.singlespinnerclose' + chartid + '').on("click", function () {
								$('#spinner' + chartid + ' .gridspinnermessage').empty();
								$('#spinner' + chartid + ' .gridspinnermessage').removeClass('showopacity')
								$('#spinner' + chartid + ' .gridspinnermessage').removeClass('oops');
								$('#spinner' + chartid + ' .gridspinnercontent img').removeClass('hide');
								$('#spinner' + chartid).hide();
							});
							$('.singlespinnerwait' + chartid).on("click", function () {
								$('#spinner' + chartid + ' .gridspinnermessage').empty();
								$('#spinner' + chartid + ' .gridspinnermessage').removeClass('showopacity')
								$('#spinner' + chartid + ' .gridspinnermessage').removeClass('oops');
								$('#spinner' + chartid + ' .gridspinnercontent img').removeClass('hide');
								$('#spinner' + chartid).hide();
								$('#spinner' + chartid).show();
							});
						}, 15000);
					} else {
						$('#spinner' + chartid + ' .gridspinnermessage').empty();
						$('#spinner' + chartid + ' .gridspinnermessage').removeClass('showopacity');
						$('#spinner' + chartid + ' .gridspinnermessage').removeClass('oops');
						$('#spinner' + chartid + ' .gridspinnercontent img').removeClass('hide');
					}

				}, 8000);
			}
		});
		
	}

	render() {
		var that = this;
		this.customscript();
		if (!this.state.isFromTabClick && !this.state.isFromCloneTile) {
			that.loadFiltersData();
			setTimeout(function () {
				that.loadQuickFilters();
			}, 1000);
		}

		var tabId = 0;
		if (this.props.isFromStoryBook != undefined && this.props.isFromStoryBook) {
			tabId = this.props.tabid;
		}
		var finalgrids = [];
		if (!this.state.update) {
			this.state.grids = [];
			var gridInfo = this.state.reportInformation[3].grid;
			$("#root>.spinner").addClass("white story").show();
			_.each(gridInfo, function (child, index) {
				if (that.state.reportInformation[0].axis[index] != undefined) {
					that.state.grids.push(<div key={index} className="tileview col-xs-12 maxanim1 grid-stack-item" data-gs-y={child.y} data-gs-x={child.x} data-gs-width={child.width} data-gs-height={child.height} data-num={that.state.reportInformation[0].axis[index].chartnumber} tab-id={tabId} tab-name="" id={`tileview${that.state.reportInformation[0].axis[index].chartnumber}`}>
						<ReportGridHtml storyBookel={that.state.el} isFromTabClick={that.state.isFromTabClick} chartid={that.state.reportInformation[0].axis[index].chartnumber} chartitle={that.state.charttitle} multifact={that.state.multifact}
							scheduleid={that.state.scheduleid} date={that.state.date} chartnumber={that.state.reportInformation[0].axis[index].chartnumber} current_user_role="ROLE_Power_User" isEmbeddedReport={that.state.isEmbeddedReport} allfeatures={that.state.allfeatures}
							isSharedReport={that.state.isSharedReport} isScheduleReport={that.state.isScheduleReport} isViewOnlyFlags={that.state.view_Only_Flag} isPurgedReport={that.state.isPurgedReport} reportId={that.state.reportId}
							isDirect={that.state.isDirect} isStoredProcedure={that.state.isStoredProcedure} chartType={that.state.reportInformation[0].axis[index].chartType}
							schemaType={that.state.schemaType} isMicrosoftEdge={that.state.isMicrosoftEdge} tileinfo={that.state.reportInformation[0].axis[index]} reportcolumns={that.state.reportcolumns}
							isPublished={that.state.isPublished} multifactentities={that.state.multifactentities} facttables={that.state.facttables} datamodelId={that.state.datamodelId} grid={that.state.grid} /></div>);
				}
			});
			finalgrids = this.state.grids;
		} else {
			finalgrids = this.state.grids;
		}
		if (this.state.isFromSmartInsigt) {
			finalgrids = [];
		}
		return (
			<div>
				<section className="boc-viewreport-section" >
					<div className="boc-home boc-dark-background">
						<div id="reportheader">
							{/* <!-- Report Title & options Goes Here (3_report_titlenoptions)--> */}
							{(!this.props.isFromStoryBook) ?
								<ViewReportHeader
									{...this.props}
									userRole={this.state.userRole}
									versionname={this.state.versionname}
									allfeatures={this.props.features}
									alertsInfo={this.props.headerdata.alertsInfo}
									filtersArray={this.props.headerdata.filtersArray}
									scheduleReportModel={this.props.headerdata.scheduleReportModel}
									reportInfo={this.props.headerdata.reportInfo}
									scheduleInfo={this.props.headerdata.scheduleInfo}
									sharedInfo={this.props.headerdata.sharedInfo}
									Report_Info={this.props.headerdata.Report_Info}
									viewreportCollection={this.props.collection}
									title={this.props.headerdata.title}
									name={this.props.headerdata.reportName}
									timeZoneType={undefined}
									folder={this.props.headerdata.reportfolder}
									desc={this.props.headerdata.reportDesc}
									syncConfigData={undefined}
									reportId={this.props.headerdata.reportId}
									features={this.props.headerdata.allfeatures}
									schemaType={undefined}
									worker={this.props.worker}
									isPurgedReport={false}
									reportRequestFrom={"viewsavedreportview"}
									isImportedReport={this.state.isImportedReport}
									isDirect={this.props.headerdata.isDirect}
									isStoredProcedure={false}
									datamodelId={this.props.headerdata.datamodelId}
									facttables={this.props.headerdata.facttables}
									multifact={this.props.headerdata.multifact}
									multifactentities={this.props.headerdata.multifactentities}
									scheduleid={this.props.headerdata.scheduleid}
									date={this.props.headerdata.date}
									isFromSmartInsigt={this.state.isFromSmartInsigt}
									isVersioned={this.state.isVersioned}
								/> : null}
						</div>
						<div className="container-fluid">
							{/* <!-- <div className="row"><div className="col-xs-12 quickfiltercontainer header-quickfiltercontainer" tab-id="0"></div></div> --> */}
							<div className="row">
								{/* <!-- left div starts --> */}
								{/* <!-- <div className="col-lg-9 col-md-9 col-sm-8 col-xs-1 leftpane pull-left"> --> */}

								<div className="storyboardtabs">
									<div role="tabpanel" id="reportcontainertabs">
										<ul className="nav nav-tabs tabcontainer hide" role="tablist">
											<li role="presentation" className="tabheadli active storyboardtab">
												<a href="#storyboardtab" id="storyboardtabclick" aria-controls="storyboardtab" role="tab" data-toggle="tab">{lang["storyboard.board"]}</a>
											</li>
											<li role="presentation" className="tabheadli smartinsights" data-insight-tab-count="0">
												<a href="#smartinsights0" className="smartinsightsclick" aria-controls="smartinsights" role="tab" data-toggle="tab" data-insight-tab-count="0"><span>{lang["storyboard.smartinsights"]}</span></a>
												<button className="btn btn-transparent btn-sm removesmartinsight"><i className="fa fa-times-circle"></i></button>
											</li>
										</ul>
										<div className="tab-content">
											{/* <!---First Tab Start--> */}
											<div role="tabpanel" className="tab-pane active boc-snapshot-container tabviewitems boc-gray-inner" id="storyboardtab">
												<div className="tab-filters-container">
													<div className="gridload"><div></div><div></div><div></div><div></div></div>
													<div className="filteritem dropdown-toggle" data-direction="left" data-toggle="dropdown" aria-expanded="true" title={lang["storyboard.filters"]}><i className="fa fa-filter"></i> {lang["storyboard.filters"]}</div>
													{(that.state.isEmbeddedReport != true) ? (
														that.state.allfeatures.map((featuresModule) => {
															return (featuresModule.moduleName == 'story_board') ? (
																(that.state.isSharedReport == false) ? (
																	(that.state.isScheduleReport === true) ? (
																		(ChartInfo.functions.getUserRole()!=null && ChartInfo.functions.getUserRole()!=undefined && ChartInfo.functions.getUserRole()[0]!=undefined && ChartInfo.functions.getUserRole()[0] == "ROLE_Viewer")?
																		<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
																			<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxfiltersdropdown" onClick={that.showaddfiltermodal} id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addfilter"]}</a></li>
																			<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxcustomparameterfiltersdropdown" onClick={this.showaddcustomparameterfiltermodal} id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addparameter"]}</a></li>
																		</ul>
																	:	
																		featuresModule.features.map((feature, index) => {
																			return ((that.state.isDirect == 0 || that.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_embed_report_and_email' && feature.allow == true) ? (
																				<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
																					<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxfiltersdropdown" onClick={that.showaddfiltermodal} id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addfilter"]}</a></li>
																					<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxcustomparameterfiltersdropdown" onClick={this.showaddcustomparameterfiltermodal} id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addparameter"]}</a></li>
																				</ul>
																			) : null
																		})
																	) :(ChartInfo.functions.getUserRole()!=null && ChartInfo.functions.getUserRole()!=undefined && ChartInfo.functions.getUserRole()[0]!=undefined && ChartInfo.functions.getUserRole()[0] == "ROLE_Viewer")?
																	<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
																		<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxfiltersdropdown" onClick={that.showaddfiltermodal} id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addfilter"]}</a></li>
																		<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxcustomparameterfiltersdropdown" onClick={this.showaddcustomparameterfiltermodal} id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addparameter"]}</a></li>
																	</ul>
																:	featuresModule.features.map((feature, index) => {
																			return ((that.state.isDirect == 0 || that.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_filters' && feature.allow == true) ? (
																				<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
																					<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxfiltersdropdown" onClick={that.showaddfiltermodal} id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addfilter"]}</a></li>
																					<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxcustomparameterfiltersdropdown" onClick={this.showaddcustomparameterfiltermodal} id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addparameter"]}</a></li>
																				</ul>
																			) : null
																		})

																) : (that.state.isSharedReport === true) ? (
																	(ChartInfo.functions.getUserRole()!=null && ChartInfo.functions.getUserRole()!=undefined && ChartInfo.functions.getUserRole()[0]!=undefined && ChartInfo.functions.getUserRole()[0] == "ROLE_Viewer")?
																		<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
																			<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxfiltersdropdown" onClick={that.showaddfiltermodal} id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addfilter"]}</a></li>
																			<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxcustomparameterfiltersdropdown" onClick={this.showaddcustomparameterfiltermodal} id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addparameter"]}</a></li>
																		</ul>
																	:
																	featuresModule.features.map((feature, index) => {
																		return (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) ? (
																			featuresModule.features.map((feature, index) => {
																				return ((that.state.isDirect == 0 || that.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_filters' && feature.allow == true && that.state.isViewOnly == false) ? (
																					<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
																						<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxfiltersdropdown" onClick={that.showaddfiltermodal} id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addfilter"]}</a></li>
																						<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxcustomparameterfiltersdropdown" onClick={this.showaddcustomparameterfiltermodal} id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addparameter"]}</a></li>
																					</ul>
																				) : null
																			})
																		) : null
																	})
																) : null
															) : null
														})
													) : (that.state.isEmbeddedReport == true) ? (
														that.state.allfeatures.map((featuresModule, index) => {
															return (featuresModule.moduleName == 'story_board') ? (
																featuresModule.features.map((feature, index) => {
																	return ((that.state.isDirect == 0 || that.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_embeded_filters' && feature.allow == true && that.state.isPurgedReport == false) ? (
																		<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
																			<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxfiltersdropdown" id="maxfiltersdropdowns" onClick={that.showaddfiltermodal} data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addfilter"]}</a></li>
																			<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxcustomparameterfiltersdropdown" onClick={this.showaddcustomparameterfiltermodal} id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addparameter"]}</a></li>
																		</ul>
																	) : null
																})
															) : null
														})
													) : null}
													<div className="panel-group rightfilter filteritem-container" id="filteraccordion"><div className="instant-filter-tags addInstanceFilterData"></div><div className="instant-filter-tags addURLInstanceFilterData"></div></div>
													<div className="filter-menu-toggle"><span onClick={this.filterMenuToggle} title={lang["storyboard.showhidefilters"]}><i className="fa fa-angle-up"></i></span></div>
												</div>

												<div className="col-xs-12 leftpane pull-left">

													{/* <!-- Gridster Start --> */}
													<section className="boc-snapshot-container">
														<div className="container-fluid">
															<div className="row">
																<div className="col-xs-12 quickfiltercontainer header-quickfiltercontainer" tab-id="0"></div>
															</div>
														</div>
														<div className="container-fluid">
															<div className="row grid-stack" id="charts">
																{/* <!-- Graph Small Items goes here (4_small_graph_items) -->	 */}
																{finalgrids}
															</div>
														</div>
													</section>
													{/* <!-- Gridster End --> */}

													{/* <!-- Button to toggle right div Start --> */}
													<span className="graph_resize hidebutton marg-righ-5"></span>
													<span className="graph_resize showbutton" style={{ display: "none" }}></span>
													{/* <!-- Button to toggle right div End --> */}
													<canvas id="storyboardcanvas" width="1920" height="1080" style={{ border: "1px solid #000", display: "none" }}>
													</canvas>
													<canvas id="singlecanvas" width="1920" height="1080" style={{ border: "1px solid #000", display: "none" }}>
													</canvas>

												</div>
												{/* <!-- left div End --> */}
												{/* <!-- Attributes --> */}
												<div className="rightattributes"><div className="gridload"><div></div><div></div><div></div><div></div></div></div>
												{/* <!--\ Attributes --> */}
												{/* <!-- right div starts --> */}
												{/* <!-- <div className="col-lg-3 col-md-3 col-sm-4 col-xs-11 rightpane pull-right"> --> */}
												<div className="col-xs-12 instant-floating-filters rightpane theme1 pull-right" style={{ display: "none" }}>
													<div className="minifiltertoggle"><i className="fa fa-filter"></i></div>
													<div className="panel panel-default" id="panel1">
														<div className="panel-heading ui-draggable-handle">
															<h4 className="panel-title">
																<a data-toggle="collapse" data-target="#collapseOne" href="javascript:void(0)" aria-expanded="true" className="filtershead" id="filtershead">
																	{lang["storyboard.filters"]}
																</a>
																<a href="javascript:void(0)" className="toggleinstantfiltersmenu" id="closefiltersmenu" data-close="close" style={{ display: "block", float: "right" }}><i className="fa fa-times-circle"></i></a>
																{/* <!-- 												<a href="javascript:void(0)" className="marg-righ-10 dropdown-toggle" id="excludenull"  data-toggle="dropdown" aria-expanded="false" style="display: block;float: right;" title="Exclude NULL Values"><i className="fa fa-circle-thin"></i></a> --> */}
																<ul className="dropdown-menu excludenulldropdown" role="menu">
																	<li className="aggregation-li-item">
																		<label><input type="checkbox" className="isexcludenulls" id="isexcludenullse" />&nbsp;{lang["storyboard.exclude"]} <b>{lang["storyboard.null"]}</b> {lang["storyboard.values"]}</label>
																	</li>
																</ul>
																{/* <!-- <a href="javascript:void(0)" className="tinyfilter"></a> --> */}
															</h4>
														</div>

														<div id="collapseOne" className="panel-collapse collapse in" aria-expanded="true">
															<div className="panel-body">
																<div className="row">
																	<div className="col-xs-12">
																		<button type="button" className="btn btn-bird btn-sm marg-left-10 modalfilter maxfiltersdropdown tooltip-right" onClick={that.showaddfiltermodal} id="maxfiltersdropdowns" data-tooltip={lang["storyboard.add"]} title={lang["storyboard.addnewfilter"]} data-direction="left" data-toggle="modal" data-target="#mySmallModal"><i className="fa fa-plus marg-righ-5"></i>{lang["storyboard.addnewfilter"]}</button>
																		<button type="button" className="btn btn-bird btn-sm marg-left-5 modalfilter maxcustomparameterfiltersdropdown tooltip-left" onClick={this.showaddcustomparameterfiltermodal} id="maxcustomparameterfiltersdropdowns" data-tooltip={lang["storyboard.add"]} title={lang["storyboard.addparameter"]} data-direction="left" data-toggle="modal" data-target="#mySmallModal"><i className="fa fa-plus marg-righ-5"></i>{lang["storyboard.addparameter"]}</button>
																		<button className="btn btn-bird btn-sm marg-righ-15 pull-right rightfiltersapply tooltip-left hide" id="rightfiltersapplys" data-tooltip="APPLY" title={lang["storyboard.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applyfilters"]}</button>
																	</div>
																</div>
																{/* <!-- <div className="col-xs-12 text-left marg-top-5">
													<label><input type="checkbox" className="isexcludenulls">&nbsp;Exclude 'null' Values</label>
												</div> --> */}
																{/* <!-- Instant Filters --> */}
																<div className="instant-filter-container hide">
																	<div className="row">
																		<div className="container-fluid">
																			<div className="allscopeslistcontainer">
																				<div className="subhead">{lang["storyboard.instantfilters"]} <div className="instant-filter-title"><span><i className="fa fa-circle"></i>{lang["storyboard.exclude"]}</span>&nbsp;/<span><i className="fa fa-circle"></i>{lang["storyboard.keeponly"]}</span></div></div>
																				<div className="allscopeslistinnercontainer">
																					<div className="instant-filter-tags addInstanceFilterData"></div>
																				</div>
																				<div className="parameterfiltersgroupdiv hide">
																					{/* <!-- <div className="subhead"> -->Parameter Filters <!-- </div> --> */}
																					<div className="instancefilterlistinnercontainer">
																						<div className="instant-filter-tags addURLInstanceFilterData">
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>

																<div className="col-lg-12 nopadding">
																	<div className="navbar ">
																		<div className="container-fluid">
																			<div className="row">
																				<div className="sidetheme filterdata">
																					{/* <!-- Accordion Goes Here --> */}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</div>
												</div>
												{/* <!-- right div End --> */}

											</div>
											{/* <!---\First Tab--> */}
											{/* <!--- Second Tab--> */}
											<div role="tabpanel" className="tab-pane boc-snapshot-container tabviewitems boc-gray-inner" id="smartinsights0">
												<div className="tab-filters-container">
													<div className="filteritem dropdown-toggle" data-direction="left" data-toggle="dropdown" aria-expanded="true" title={lang["storyboard.filters"]}><i className="fa fa-filter"></i> {lang["storyboard.filters"]}</div>
													{(that.state.isEmbeddedReport != true) ? (
														that.state.allfeatures.map((featuresModule) => {
															return (featuresModule.moduleName == 'story_board') ? (
																(that.state.isSharedReport == false) ? (
																	(that.state.isScheduleReport === true) ? (
																		featuresModule.features.map((feature, index) => {
																			return ((that.state.isDirect == 0 || that.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_embed_report_and_email' && feature.allow == true) ? (
																				<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
																					<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxfiltersdropdown" onClick={that.showaddfiltermodal} id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addfilter"]}</a></li>
																					<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxcustomparameterfiltersdropdown" onClick={this.showaddcustomparameterfiltermodal} id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addparameter"]}</a></li>
																				</ul>
																			) : null
																		})
																	) :
																		featuresModule.features.map((feature, index) => {
																			return ((that.state.isDirect == 0 || that.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_filters' && feature.allow == true) ? (
																				<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
																					<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxfiltersdropdown" onClick={that.showaddfiltermodal} id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addfilter"]}</a></li>
																					<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxcustomparameterfiltersdropdown" onClick={this.showaddcustomparameterfiltermodal} id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addparameter"]}</a></li>
																				</ul>
																			) : null
																		})

																) : (that.state.isSharedReport === true) ? (
																	featuresModule.features.map((feature, index) => {
																		return (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) ? (
																			featuresModule.features.map((feature, index) => {
																				return ((that.state.isDirect == 0 || that.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_filters' && feature.allow == true && that.state.isViewOnly == false) ? (
																					<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
																						<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxfiltersdropdown" onClick={that.showaddfiltermodal} id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addfilter"]}</a></li>
																						<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxcustomparameterfiltersdropdown" onClick={this.showaddcustomparameterfiltermodal} id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addparameter"]}</a></li>
																					</ul>
																				) : null
																			})
																		) : null
																	})
																) : null
															) : null
														})
													) : (that.state.isEmbeddedReport == true) ? (
														that.state.allfeatures.map((featuresModule, index) => {
															return (featuresModule.moduleName == 'story_board') ? (
																featuresModule.features.map((feature, index) => {
																	return ((that.state.isDirect == 0 || that.state.isStoredProcedure == 0) && feature.featureName == 'viewreport_embeded_filters' && feature.allow == true && that.state.isPurgedReport == false) ? (
																		<ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
																			<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxfiltersdropdown" onClick={that.showaddfiltermodal} id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addfilter"]}</a></li>
																			<li><a href="javascript:void(0)" className="queryinfomodal modalfilter maxcustomparameterfiltersdropdown" onClick={this.showaddcustomparameterfiltermodal} id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["storyboard.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["storyboard.addparameter"]}</a></li>
																		</ul>
																	) : null
																})
															) : null
														})
													) : null}
													<div className="panel-group rightfilter filteritem-container" id="filteraccordion"><div className="instant-filter-tags addInstanceFilterData"></div><div className="instant-filter-tags addURLInstanceFilterData"></div></div>
													<div className="filter-menu-toggle"><span onClick={this.filterMenuToggle} title={lang["storyboard.showhidefilters"]}><i className="fa fa-angle-up"></i></span></div>
												</div>
												{/* <!-- SmartInsights Loading Screen --> */}
												<div className="smartinsightintro-container">
													<div className="smartinsightintro">
														<img className="smartintroicon rotateanim" alt={lang["storyboard.runinsights"]} src={SmartInsightSpinner} />
														<span>{lang["storyboard.findinsights"]} </span>
													</div>
												</div>
												{/* <!--\SmartInsights Loading Screen --> */}
												{/* <!-- SmartInsights Data Container --> */}
												<div className="smartinsightdatacontainer" id="smartinsightdatacontainer"></div>
												{/* <!-- SmartInsights Data Container --> */}
											</div>
											{/* <!---\Second Tab--> */}
										</div>
										{/* <!--\TabContent --> */}
									</div>
									{/* <!--\Tab panel --> */}
								</div>
								{/* <!--\Tab viewer --> */}
							</div>
						</div>
						{(that.state.displayFooter != false) ? (
							<footer className="footer">
								<div className="container-fluid">
									<div className="row">
										<div className="col-xs-7">
											{/* <!-- <p className="text-muted"><%=whitelableMap["footertext"]%></p> --> */}
											{/* <!-- <div className="timezone hide pull-right">
								<span className="servertime"><b>Server Time:</b></span> <span className="servertimezone" style="margin-right:20px">Fri Sep 27 2019 10:37:00 GMT+0530 </span>
								<span className="localtime"><b>Client Time:</b></span> <span className="localtimezone"> Fri Sep 27 2019 10:37:00 GMT+0530 </span>
						</div> --> */}
										</div>

										<div className="col-xs-5">

											<div className="itsaudit hide">
												<span className="livecirclecontainer dataauditlivecontainer hide">
													<span className="livecircle"></span> {lang["storyboard.dataaudit"]}
												</span>
												<span className="livecirclecontainer metadataauditlivecontainer hide">
													<span className="livecircle"></span>   {lang["storyboard.metaudit"]}
												</span>
											</div>
											<div className="itslive hide"><span className="liveupdatetime"></span><span className="livecirclecontainer"><span className="livecircle"></span>   {lang["storyboard.live"]}</span></div>
										</div>
									</div>
								</div>
							</footer>
						) : null}
					</div>
				</section>
				<div className="screenshotmessage hide"><div className="screenshot-message">{lang["storyboard.screenmsg"]}</div>
				</div>
			</div>
		);
	}
	loadFiltersData() {
		var isSharedReport = false;
		if (window.location.pathname.indexOf("sharereport") >= 0) {
			isSharedReport = true;
		}
		var that = this;
		var isDirect = collection.getIsDirect();
		var isViewOnly = false;
		if (collection.getSharedViewOnlyFlag() != undefined) {
			var parsedViewFlag = JSON.parse(collection.getSharedViewOnlyFlag());
			if (parsedViewFlag == "1") {
				isViewOnly = true;
			}
		}
		if (this.props.isFromStoryBook != undefined && this.props.isFromStoryBook) {
			$('#' + this.props.el + " .row.grid-stack").attr("id", this.props.el + "charts");
			$('#storybooktabcontent' + this.props.tabid + ' .quickfiltercontainer').attr("tab-id", this.props.tabid);
		}

		ChartInfo.functions.setIsDirect(collection.getIsDirect());
		ChartInfo.functions.setIsStoredProcedure(this.state.isStoredProcedure);
		sessionStorage.setItem("applicablefilters", ",");
		//sessionStorage.setItem("stroyboardcolortheme", "");
		this.state.showError = false;
		var reportdetails = this.state.reportInformation;
		var model = this.state.model;
		var detailsObj = reportdetails[1]["details"];
		var auditMetaDataObj = {};
		if (reportdetails[16] != undefined && reportdetails[16]["auditMetaDataOptions"] != undefined) {
			auditMetaDataObj = JSON.parse(reportdetails[16]["auditMetaDataOptions"]);
		} else {
			auditMetaDataObj["metaDataAudit"] = 1;
		}
		ChartInfo.functions.setAuditMetaDataOptions(auditMetaDataObj);

		var name = detailsObj["name"];
		var folder = detailsObj["folder"];
		var desc = detailsObj["desc"];
		var timeZoneType = detailsObj["timeZoneType"];
		if (collection.getIsDirect() != undefined) {
			this.state.isDirect = collection.getIsDirect();
		} else if (this.props.isDirect != undefined) {
			this.state.isDirect = this.props.isDirect;
		}
		ChartInfo.functions.setPurgeConfig(detailsObj["maxage"], detailsObj["maxageunits"], detailsObj["purgetime"], detailsObj["maxpurgeindex"]);
		var isStoryboardLIVE;
		//get drill through reports
		if (!this.state.isImportedReport) {
			if(!that.state.isDrillAlreadyCalled){
				that.state.isDrillAlreadyCalled=true
			ChartInfo.functions.setDrillThroughReports("[]");
			let requestHeaders = {
				method: "get",
				headers: new Headers({
					"Content-Type": "application/json"
				})
			};
			SendRequest({
				url: "getDrillThroughReports",
				queryString: "?reportId=" + that.props.reportId,
				sucessFunction: response => {
					if (response.success) {
						ChartInfo.functions.setDrillThroughReports(response.drillThroughReports);
					}
				},
				rejectFunction: response => {
					console.log(response);
				},
				headers: requestHeaders
			});
		}
		}
		if (reportdetails[0]["axis"].length > 1 || (model != undefined && model.visualizationtype === "story")) {
			//	this.broker.on("hideExportOption", this.hideExportOption, this);
			$('.highcharts-button:not([states])').hide();
			var that = this;
			if (collection != undefined && collection.getSharedFilters() != undefined) {
				ChartInfo.functions.setshredFilterArrayForDrillDown(JSON.parse(collection.getSharedFilters()));
			}
			if (this.props.isFromStoryBook != undefined && this.props.isFromStoryBook) {
				var tabId = this.props.tabid;
				TabInfo.functions.updateChartInfoForTab(tabId, ChartInfo.functions.getAllChartDetails());

			}
			collection.setFilterObject();
			var reportFilterObject = collection.getFilterObject();
			// if (this.state.isFromStoryBook != undefined && this.state.isFromStoryBook && TabInfo.functions.getTabFiltersBeforeRefresh(this.state.tabid) != undefined && TabInfo.functions.getTabFiltersBeforeRefresh(this.state.tabid).length > 0) {
			// 	reportFilterObject = TabInfo.functions.getTabFiltersBeforeRefresh(this.state.tabid);
			// }
			var sharedFilters = collection.getSharedFilters();
			var embedFilters = collection.getEmbedReportFilters();
			var columns = collection.getColumns();
			var columnsArray = JSON.parse(columns);
			var urlParameterFilters = [];
			var fromRange;
			var toRange;
			var flag = false;
			var shared = "";
			var showErrorMessageString = true;
			ChartInfo.functions.setSharedFiltersForLiveReport(JSON.parse(sharedFilters));
			var customFields = JSON.parse(sessionStorage.getItem("customFields"));
			if (embedFilters.length > 0) {
				embedFilters = decodeURIComponent(embedFilters);
				_.each(JSON.parse(embedFilters), (value, key) => {
					var isColumnNotFound = true;
					_.each(columnsArray, (column) => {
						if (key.toLowerCase().trim() == column.columnDisplayName.toLowerCase().trim() || key.toLowerCase().trim() == column.columnName.toLowerCase().trim()) {
							isColumnNotFound = false;
							var filterObject = {};
							filterObject["dataType"] = column.tableDisplayType;
							filterObject["tablename"] = column.tableName;
							filterObject["columndisplayname"] = column.columnDisplayName;
							filterObject["columnname"] = column.columnName;
							filterObject["isembeddedfilter"] = true;
							filterObject["isDrillThroughFilter"] = true;
							try {
								flag = false;
								var prevvalue = value;
								value = JSON.parse(value);
								if (column.tableDisplayType != undefined && column.tableDisplayType == 'string') {
									flag = true;
									value = prevvalue
								}
							} catch (e) {
								flag = true;
							}
							if (flag == true) {
								var updatedString = "";
								var excludeString = "";
								_.each(value, function (stringValue) {
									var initialValue = stringValue.split(",")[0];
									_.each(stringValue.split(","), function (stringsplit) {
										stringsplit = stringsplit.replace(/``/g, ",");
										var operatorSplit = [];
										var firstValue = stringsplit;
										var filterObject = {};
										filterObject["dataType"] = column.tableDisplayType;
										filterObject["tablename"] = column.tableName;
										filterObject["columndisplayname"] = column.columnDisplayName;
										filterObject["columnname"] = column.columnName;
										filterObject["isembeddedfilter"] = true;
										filterObject["isDrillThroughFilter"] = true;
										if (stringsplit.indexOf("!") != -1 && stringsplit.split("!").length > 1 && stringsplit.split("!") != "") {
											operatorSplit = stringsplit.split("!");
											if (operatorSplit.length > 1) {
												stringsplit = operatorSplit[1];
											} else {
												stringsplit = operatorSplit[0];
											}
											excludeString += stringsplit + "@@";
										} else if (stringsplit.indexOf(">eq") != -1 && stringsplit.split(">eq").length > 1 && stringsplit.split(">eq") != "") {
											operatorSplit = stringsplit.split(">eq");
											filterObject["filterlessthanarequalvalue"] = null;
											if (operatorSplit.length > 1) {
												filterObject["filtergreaterthanarequalvalue"] = parseInt(operatorSplit[1]);
											} else {
												filterObject["filtergreaterthanarequalvalue"] = parseInt(operatorSplit[0]);
											}
											urlParameterFilters.push(filterObject);
										} else if (stringsplit.indexOf("<eq") != -1 && stringsplit.split("<eq").length > 1 && stringsplit.split("<eq") != "") {
											operatorSplit = stringsplit.split("<eq");
											filterObject["filtergreaterthanarequalvalue"] = null;
											if (operatorSplit.length > 1) {
												filterObject["filterlessthanarequalvalue"] = parseInt(operatorSplit[1]);
											} else {
												filterObject["filterlessthanarequalvalue"] = parseInt(operatorSplit[0]);
											}
											urlParameterFilters.push(filterObject);
										} else if (stringsplit.indexOf(">") != -1 && stringsplit.split(">").length > 1 && stringsplit.split(">") != "") {
											operatorSplit = stringsplit.split(">");
											filterObject["filterlessthanvalue"] = null;
											if (operatorSplit.length > 1) {
												filterObject["filtergreaterthanvalue"] = parseInt(operatorSplit[1]);
											} else {
												filterObject["filtergreaterthanvalue"] = parseInt(operatorSplit[0]);
											}
											urlParameterFilters.push(filterObject);
										} else if (stringsplit.indexOf("<") != -1 && stringsplit.split("<").length > 1 && stringsplit.split("<") != "") {
											operatorSplit = stringsplit.split("<");
											filterObject["filtergreaterthanvalue"] = null;
											if (operatorSplit.length > 1) {
												filterObject["filterlessthanvalue"] = parseInt(operatorSplit[1]);
											} else {
												filterObject["filterlessthanvalue"] = parseInt(operatorSplit[0]);
											}
											urlParameterFilters.push(filterObject);
										} else {
											updatedString += stringsplit + "@@";
										}
									});
								});
								if (updatedString != "" && updatedString.length > 0) {
									var filterObject = {};
									filterObject["dataType"] = column.tableDisplayType;
									filterObject["tablename"] = column.tableName;
									filterObject["columndisplayname"] = column.columnDisplayName;
									filterObject["columnname"] = column.columnName;
									filterObject["isembeddedfilter"] = true;
									filterObject["isDrillThroughFilter"] = true;
									filterObject["data"] = updatedString.substring(0, updatedString.length - 2);
									urlParameterFilters.push(filterObject);
								}
								if (excludeString != "" && excludeString.length > 0) {
									var filterObject = {};
									filterObject["dataType"] = column.tableDisplayType;
									filterObject["tablename"] = column.tableName;
									filterObject["columndisplayname"] = column.columnDisplayName;
									filterObject["columnname"] = column.columnName;
									filterObject["isembeddedfilter"] = true;
									filterObject["isDrillThroughFilter"] = true;
									filterObject["excludeFilterData"] = "excludeFilterData";
									filterObject["data"] = excludeString.substring(0, excludeString.length - 2);
									urlParameterFilters.push(filterObject);
								}
							} else {
								filterObject["fromRange"] = value[0];
								filterObject["toRange"] = value[1];
								if (filterObject.fromRange != undefined && filterObject.toRange != undefined) {
									urlParameterFilters.push(filterObject);
								} else {
									//	if(Backbone.history.getFragment().indexOf( "viewreport")>=0 && Backbone.history.location.search.length>0){
									if ((this.pathArr[3] == "viewreport" && this.pathArr[4] >= 0) && (window.location.search.length > 0)) {
										if ((column.tableDisplayType === 'number' || column.tableDisplayType === 'date')
											&& Object.prototype.toString.call(value) == "[object Array]") {

											filterObject["dataType"] = "number";
											filterObject["fromRange"] = value;
											filterObject["toRange"] = value;
										}
									}
									var filterObjectForEachValue = JSON.parse(JSON.stringify(filterObject));
									filterObjectForEachValue["data"] = value;
									urlParameterFilters.push(filterObjectForEachValue);
								}
							}
						}
					});
					if (isColumnNotFound && customFields != undefined && customFields != null && customFields != "null") {
						var customParameterFilterArray = collection.getCustomParameterFilterObject();
						var temp = customFields;
						_.each(temp, function (customfields) {
							_.each(customfields.parametrFieldsArray, function (value) {
								var customparameter = ChartInfo.functions.getCustomParameter();
								if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
									customparameter = customParameterFilterArray;
								}
								var res = $.grep(customparameter, function (e) {
									return e.parametername == value;
								})
								var formula = customfields.formula;
								var scriptcondition = customfields.scriptcondition;
								var fields = customfields.fields;
								var sqlformula = customfields.sqlformula;
								if (res.length != 0) {
									customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
									customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
									customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
									customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
								} else {
									_.each(ChartInfo.functions.getCustomParameter(), function (values) {
										if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
											customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
											customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
											customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
											customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
										}
									});
								}
							});
						});
						var customObject = {};
						_.each(customFields, function (customfield) {
							if (key.toLowerCase().trim() == customfield.name.toLowerCase().trim()) {
								var filterObject = {};
								filterObject["dataType"] = customfield.dataType;
								filterObject["tablename"] = customfield.name;
								filterObject["columndisplayname"] = customfield.name;
								filterObject["columnname"] = customfield.name;
								filterObject["isembeddedfilter"] = true;
								filterObject["isDrillThroughFilter"] = true;
								filterObject["customfield"] = customfield;
								_.each(value, function (stringValue) {
									try {
										flag = false;
										value = JSON.parse(value);
										if (typeof value === 'number') {
											flag = true
										}
									} catch (e) {
										flag = true;
									}
									if (flag == true) {
										_.each(stringValue.split(","), function (stringsplit) {
											var sqlFilterObject = {};
											sqlFilterObject["sqldata"] = stringsplit;
											stringsplit = stringsplit.replace(/``/g, ",");
											filterObject["data"] = stringsplit
											customfield["sqlcondition"] = sqlFilterObject;
											var scriptFilterCondition = customfield['scriptcondition'];
											var finalScriptCondition = ";" + "if(";
											if (filterObject["dataType"] == "string" || filterObject["dataType"] == "number") {
												finalScriptCondition += "(" + scriptFilterCondition + ").toString().equals('" + stringsplit + "')";
												customfield['scriptcondition'] = finalScriptCondition;
												finalScriptCondition += ") return true else false";
												customfield['scriptcondition'] = finalScriptCondition;
												urlParameterFilters.push(filterObject);
											}
										});
									} else {
										filterObject["fromRange"] = value[0];
										filterObject["toRange"] = value[1];
										var sqlFilterObject = {};
										sqlFilterObject["fromRange"] = value[0];
										sqlFilterObject["toRange"] = value[1];;
										customfield["sqlcondition"] = sqlFilterObject;
										var scriptFilterCondition = customfield['scriptcondition'];
										var finalScriptCondition = ";" + "if(";
										if (filterObject["dataType"] == "date" || filterObject["dataType"] == "number") {
											finalScriptCondition += scriptFilterCondition + ">=" + value[0] + "&&" + scriptFilterCondition + "<=" + value[1] + ")";
											customfield['scriptcondition'] = finalScriptCondition;
											finalScriptCondition += ") return true else false";
											customfield['scriptcondition'] = finalScriptCondition;
											urlParameterFilters.push(filterObject);
										}

									}
								});
							}
						});
					}
				});
			}
			if (reportFilterObject.length > 0 && sharedFilters != undefined && sharedFilters.length > 2 && urlParameterFilters.length > 0) {
				ChartInfo.functions.setEmbedFilterArray(urlParameterFilters);
			}
			ChartInfo.functions.setEmbedFilterArray(urlParameterFilters);
			ChartInfo.functions.setFilterArray(reportFilterObject);
			var isHeadersAdded = false;

			var url = window.location.search;
			if (url.length > 0) {
				url = url.replace('?', "").split("&");
				for (var i = 0; i < url.length; i++) {
					var key = url[i].split("=")[0];
					if (key.indexOf("leftpanel") == -1 && key.indexOf("topheader") == -1 && key.indexOf("bottomfooter") == -1) {
						isHeadersAdded = true;
					}
					if (key.indexOf("sharemanage") != -1 && url[i].split("=")[1]!=undefined && url[i].split("=")[1] == "true") {
						$("body").addClass('sharemanage');
					}
				}
			}
			if (that.viewreportfilterview != null) {
				that.viewreportfilterview = undefined;
				PubSub.unsubscribe('addfilteritem');
			}
			that.viewreportfilterview = new ViewReportFilterView({
				features: that.state.features,
				tabid: that.state.tabid,
				isFromStoryBook: that.state.isFromStoryBook,
				scheduleid: that.props.scheduleid,
				multifact: that.state.multifact,
				facttables: that.state.facttables,
				multifactentities: that.state.multifactentities
			});
			$(".sidetheme").empty();
			$(".sidetheme").append(<div class="panel-group rightfilter" id="filteraccordion"></div>);
			if (that.state.isFromStoryBook) {
				$('.sidetheme #filteraccordion').attr("id", "filteraccordiontemp");
			}
			if (reportFilterObject != undefined) {
				var col_name = "";
				var filtervalues = "";
				var filterObjects;
				if (isSharedReport == true) {
					var sharedFilters1 = JSON.parse(sharedFilters);
					var totalfilterArray = $.merge($.merge([], []), reportFilterObject);
					_.each(totalfilterArray, function (filter1, index1) {
						_.each(sharedFilters1, function (filter, index) {
							var sharedfiltercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
							if (filter1 != undefined) {
								if (filter1.columndisplayname == sharedfiltercolumndisplayname) {
									if (filter.dataType != undefined) {
										if (filter.dataType == "number") {
											var fromRange = filter.fromRange,
												toRange = filter.toRange,
												customRange = filter.customfiltervalue;
											var mainfromRange = filter1.fromRange,
												maintoRange = filter1.toRange,
												maincustomRange = filter1.customfiltervalue;
											if (fromRange == undefined && toRange == undefined) {
												fromRange = filter.filtergreaterthanvalue;
												toRange = filter.filterlessthanvalue;
											}
											if (fromRange > maintoRange || mainfromRange > toRange) {
												if (reportFilterObject.length != totalfilterArray.length) {
													reportFilterObject.splice(index1 - 1, 1);
												}
												else {
													reportFilterObject.splice(index1, 1);
												}
											}
										}
										else if (filter.dataType == "date") {
											var fromDateRange = filter.fromRange,
												toDateRange = filter.toRange,
												dateRange = filter.daterange;
											var mainfromRange = filter1.fromRange,
												maintoRange = filter1.toRange,
												maindateRange = filter1.daterange;
											var dates = $('#filteraccordion .daterange-center[displayname="' + filter.columndisplayname + '"] span').text();
											var sharedFrom = Date.parse(fromDateRange);
											var mainTo = Date.parse(maintoRange);
											var sharedTo = Date.parse(toDateRange);
											var mainFrom = Date.parse(mainfromRange);
											if (sharedFrom > mainTo || mainFrom > sharedTo) {
												if (reportFilterObject.length != totalfilterArray.length) {
													reportFilterObject.splice(index1 - 1, 2);
												}
												else {
													reportFilterObject.splice(index1, 2);
												}																				// accessa.push(reportFilterObject[index1]);
											}
		/*if(customRange==undefined ||customRange===""){
					$('.filteritem-container  .acc-content[data-id="'+filtercolumndisplayname+'"].filteredvalues').text(dates).attr("title",dates);
		}*/}
										if (filter.dataType == "string") {
											var sharedYes = false;
											if (filter.customfield == undefined) {
												var filtereddatatext = filter["data"].replace(/\@@/g, ', ');
												var newfiltereddatatext = filtereddatatext.split(",");
												var filtereddatatext1 = filter1["data"].replace(/\@@/g, ', ');
												var newfiltereddatatext1 = filtereddatatext1.split(",");

												_.each(newfiltereddatatext, function (shared, i) {
													var filtereddatatext1 = filter1["data"].replace(/\@@/g, ', ');
													if (filtereddatatext1.includes(shared)) {
														sharedYes = true
													}
												});
												if (sharedYes == false) {
													if (reportFilterObject.length != totalfilterArray.length) {
														reportFilterObject.splice(index1 - 1, 1);
													}
													else {
														reportFilterObject.splice(index1, 1);
													}
												}
											} else {
												var filtereddatatext = filter.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
												var newfiltereddatatext = filtereddatatext.split(",");
												var filtereddatatext1 = filter1.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
												var newfiltereddatatext1 = filtereddatatext1.split(",");
											}
										}

									}
								}
							}
						});
					});
				}
			// if($("#storyboardtab #filteraccordion.filteritem-container").length>0){
			// 	var filterCount = reportFilterObject.length;
			// 	var currentFilterCount = 0;
			// 	var filterOrder="";
			// 	_.each(reportFilterObject, function (filter, i) {
			// 		if (filter != undefined) {
			// 			if (col_name === filter["tablename"] + "." + filter["columnname"]) {
			// 			} else {
			// 				col_name = filter["tablename"] + "." + filter["columnname"];
			// 				filterObjects = collection.getFilterObject();
			// 				that.viewreportfilterview.addfilteritem(filter["filterDisplayName"], filter["filterIgnore"], filter["tablename"], filter["columnname"], filter["columndisplayname"], filter["dataType"], filter["fromRange"], filter["toRange"], filterObjects, that.props.datamodelId, "", filter["daterange"], "", undefined, undefined, sharedFilters);
			// 				filtervalues = filtervalues + filter["columndisplayname"].replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, "") + ",";
			// 				filterOrder = filterOrder + filter["columnname"] + ",";
			// 				currentFilterCount++
			// 			}
			// 		}
			// 	});

			// 	 var sortFilterItems  = function(){
			// 		var partsOrder = filterOrder;

			// 		var parts = $("#storyboardtab #filteraccordion.filteritem-container .panelboc-title");
					
			// 		// set target index for elements
			// 		_.each(partsOrder, function(i, v){
			// 		  $('.' + v, parts).data('index', i);
			// 		});
					
			// 		// sort array on index
			// 		var items = parts.find('[classname]');
			// 		items.sort(function(a,b){ 
			// 			return $(a).data('index') - $(b).data('index'); 
			// 		});
					
			// 		// move elements into array order
			// 		parts.append(items);
			// 	}
			// 	function sortStart(){
			// 		if($("#storyboardtab #filteraccordion.filteritem-container div[classname]").length==currentFilterCount){
			// 			sortFilterItems()
			// 		}else{
			// 			setTimeout(function(){
			// 				sortStart()
			// 			},1000)
			// 		}
			// 	}
			// 	sortStart()
				
			// }
			if($("#storyboardtab #filteraccordion.filteritem-container").length>0){
				if(reportFilterObject!=undefined && reportFilterObject.length>0){
					$(".tab-filters-container").addClass("loading");
					var totalFilterItemsCount = reportFilterObject.length;
					var FilterCount = 0;
					var checkColumnName = "";
			
					//First filter item request
					var filter = reportFilterObject[0];
					if (col_name === filter["tablename"] + "." + filter["columnname"]) {
					} else {
						checkColumnName=filter["columnname"];
						col_name = filter["tablename"] + "." + filter["columnname"];
						filterObjects = collection.getFilterObject();
						that.viewreportfilterview.addfilteritem(filter["filterDisplayName"], filter["filterIgnore"], filter["tablename"], filter["columnname"], filter["columndisplayname"], filter["dataType"], filter["fromRange"], filter["toRange"], filterObjects, that.props.datamodelId, "", filter["daterange"], "", undefined, undefined, sharedFilters);
						filtervalues = filtervalues + filter["columndisplayname"].replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, "") + ",";
						FilterCount++
					}
			
					//Loop function for next filter items
					function requestFilterItem(){
						if($("#storyboardtab #filteraccordion .panelboc-title[title='"+checkColumnName+"']").length!=0){
							var filter = reportFilterObject[FilterCount];
							if(filter!=undefined){
								if (col_name === filter["tablename"] + "." + filter["columnname"]) {
								} else {
									checkColumnName=filter["columnname"];
									col_name = filter["tablename"] + "." + filter["columnname"];
									filterObjects = collection.getFilterObject();
									that.viewreportfilterview.addfilteritem(filter["filterDisplayName"], filter["filterIgnore"], filter["tablename"], filter["columnname"], filter["columndisplayname"], filter["dataType"], filter["fromRange"], filter["toRange"], filterObjects, that.props.datamodelId, "", filter["daterange"], "", undefined, undefined, sharedFilters);
									filtervalues = filtervalues + filter["columndisplayname"].replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, "") + ",";
									FilterCount++
									requestFilterItem();
								}
							}else{
								$(".tab-filters-container").removeClass("loading");
							}
						}else{
							if(filtervalues!=undefined && filtervalues!=""){
								sessionStorage.setItem('applicablefilters', filtervalues);
							}
							setTimeout(function(){
								if(totalFilterItemsCount!=FilterCount){
									requestFilterItem();
								}else{
									var spinnerCount = 0;
									function closeSpinner(){
										if($(".singlereportview.fullscreenmain.maxanim1").length>0){
											var appenedFilters = $(".tab-filters-container #filteraccordion [classname]").length
										}else if($("#storyboardtab .tab-filters-container").length>0){
											var appenedFilters = $("#storyboardtab .tab-filters-container #filteraccordion [classname]").length
										}

										if(appenedFilters==undefined || appenedFilters==FilterCount || spinnerCount==6){
											$(".tab-filters-container").removeClass("loading");
										}else{
											setTimeout(function(){
												spinnerCount++
												closeSpinner()
											},1000);
										}
									}
									closeSpinner();
								}
							},1000)
						}
					}
			
					//Initiate fiter Loop function
					requestFilterItem();
			
				}
			}

				sessionStorage.setItem('applicablefilters', filtervalues);
			}
			var model = that.state.model;
			var reportdata = JSON.parse(model.reportdata);
			var customparameterfilters;
			if (reportdata[11] != undefined) {
				customparameterfilters = reportdata[11]['customParameterFilters'];
			}
			if (customparameterfilters != undefined) {
				var col_name = "";
				var filterObjects;
				_.each(customparameterfilters, function (filter, i) {
					col_name = filter.parametername
					that.viewreportfilterview.addfilteritem(filter["filterDisplayName"], filter["filterIgnore"], '', filter.parametername, filter.parametername, filter.valueType, '', '', '', that.props.datamodelId, "", filter.parametercurrentvalue);
				});
			}
			var isExcludeNulls;
			if (reportdata[13] != undefined) {
				isExcludeNulls = reportdata[13]['isExcludeNulls'];
				$('.isexcludenulls').prop('checked', isExcludeNulls);
				ChartInfo.functions.setIsExcludeNulls(isExcludeNulls);
			}
			var isFilterCollapsed = false;
			if (reportdata[20] != undefined && reportdata[20].isFilterCollapsed != undefined) {
				isFilterCollapsed = reportdata[20].isFilterCollapsed;
			}
			if (isFilterCollapsed) { $("body").addClass("filters-hidden"); } else { $("body").removeClass("filters-hidden"); }
			var viewReportAddFilterCollection = [];
			var columns = collection.getColumns();
			var columnsArray = JSON.parse(columns);
			dobycolumnnames = columnsArray;
			_.each(columnsArray, function (column) {
				var viewreportaddfiltermodel = {};
				viewreportaddfiltermodel["tableName"] = column["tableName"];
				viewreportaddfiltermodel["columnDisplayType"] = column["tableDisplayType"];
				viewreportaddfiltermodel["tableDisplayType"] = column["tableDisplayType"];
				viewreportaddfiltermodel["columnName"] = column["columnName"];
				viewreportaddfiltermodel["columnDisplayName"] = column["columnDisplayName"];
				viewreportaddfiltermodel["priorityColumn"] = column["priorityColumn"];
				viewReportAddFilterCollection.push(viewreportaddfiltermodel);
			});

			if (that.viewReportAddFilterView != null) {
				that.viewReportAddFilterView = undefined;
			}
			that.viewReportAddFilterView = new ViewReportAddFilterView(
				{
					collection: viewReportAddFilterCollection,
					datamodelId: that.props.datamodelId,
					reportId: that.props.reportId,
					tabid: that.state.tabid,
					isFromStoryBook: that.state.isFromStoryBook,
					scheduleid: that.props.scheduleid,
					filterArray: reportFilterObject,
					viewreportCollection: collection,
					multifact: that.state.multifact,
					facttables: that.state.facttables,
					multifactentities: that.state.multifactentities,
				});
			if (reportFilterObject.length == 0) {
				$(".hidebutton").trigger(
					'click');
			} else {
				$(".showbutton").trigger('click');
				$('.instant-floating-filters').draggable({ cancel: '.col-lg-12.nopadding', containment: '#main>.boc-viewreport-section', handle: '.panel-heading' });
			}

			if (that.props.isFromStoryBook != undefined && that.props.isFromStoryBook) {
				var tabid = that.props.tabid;
				if (TabInfo.functions.getDrilldownFilterFromSession(tabid) != undefined && Object.keys(TabInfo.functions.getDrilldownFilterFromSession(tabid)).length != 0) {
					var drilldownChartNo = Object.keys(TabInfo.functions.getDrilldownFilterFromSession(tabid))[0];
					ChartInfo.functions.setDrillDownChartNumber(drilldownChartNo);
					var filterArray = [];
					if (TabInfo.functions.getDrilldownFilterFromSession(tabid)[drilldownChartNo] != undefined) {
						filterArray = JSON.parse(TabInfo.functions.getDrilldownFilterFromSession(tabid)[drilldownChartNo]);

						_.each(filterArray, function (i, j) {
							if (i.isDrillDownAll == true) {
								var name = decodeURIComponent(filterArray[j].columndisplayname);
								if (name === null) {
									var name = decodeURIComponent(filterArray[j].name);
								}
								if (filterArray[j].type == "date") {
									//name = $(name).find('li.drilldown:first').attr('column-display-name');
									if (name === undefined && filterArray[j].columndisplayname != undefined) {
										name = filterArray[j].columndisplayname;
									}
								}
								var today = new Date();
								var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
								dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
								$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
								ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={filterArray[j].data} />, document.getElementById(dynamicdiv));
								$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
								$("#" + dynamicdiv).remove();
								$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
								$('.fullscreensample').addClass('singlereportdrilldownmarg');
							}
						});
					}
				}
			}
			var isPublished='false';
			if(that.props.model !=undefined){
				isPublished=that.props.model.isPublished;
				if(isPublished == undefined){
					isPublished="false";
				}
			}
			if (that.state.multifact != "true") {
				var timeZone;
				var TimeZoneSettingsValueForstoryboard = ChartInfo.functions.getTimeZoneSettings("-1");
				if (TimeZoneSettingsValueForstoryboard == "localtimezone") {
					timeZone = jstz.determine().name();
				}
			if(!that.state.isAlreadyCalled){
				that.state.isAlreadyCalled=true
				let requestHeaders = {
					method: "get",
					headers: new Headers({
						"Content-Type": "application/json"
					})
				};
				SendRequest({
					url: "gettotaldatacount",
					queryString: "?reportId=" + that.props.reportId + " &datamodelId=" + that.props.datamodelId + " &timeZone=" + timeZone + "&isPurgedReport=" + false + "&isImportedReport=" + that.state.isImportedReport + "&isPublished=" + ChartInfo.functions.getisPublished(),
					sucessFunction: response => {
						if (response.hasOwnProperty('error')) {
							$(".spinner").hide();
							$(".gridspinner").hide();
							$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
							$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
							$('.birdmessagebuttons').removeClass('show');
							$('.birdmessagebuttons').addClass('hide');
							$('.birdmessage-center').addClass("message-top-in");
							$('.details').removeClass('hide');
							$('.birdmessage h2').empty().append('Error : ');
							$('.birdmessage h2').append('<span class="errorcode">');
							$('.birdmessage .errorcode').empty().append(response.errorCode);
							$('.birdmessage-info').empty().text(response.error);
							$('.details').addClass('show');
							$('.messagedetails xmp').empty().append(response.errorTrace)
						}
						that.state.datacount = response.size;
						$(".publishinfo").text("Dataset Count: " + response.size);
						ChartInfo.functions.setDataSetCount(response.size);
						if (response.lastexecutedtimestamp != undefined && response.lastexecutedtimestamp.length > 0) {
							$(".lastexecutedtimestamp").text("Last Data Sync: " + response.lastexecutedtimestamp);
						}
					},
					rejectFunction: () => { },
					headers: requestHeaders
				});
			}
			}

			setTimeout(function () {
				if ($(window).width() < 780) {
					$(".tinyfilter").trigger('click');
					//$('.refreshcharts').eq(0).click();
					$('.addInstanceFilterData').empty();
					if (reportFilterObject.length != 0) {
						$('.minisidefilter').show();
					}
					//Sort tiles in mobile view//
					var elems = $('.tileview');
					elems.sort(function (a, b) {
						return a.getAttribute('data-gs-x') > b.getAttribute('data-gs-x');
					}).appendTo($('.tileview').parent());

					elems.sort(function (a, b) {
						return a.getAttribute('data-gs-y') > b.getAttribute('data-gs-y');
					}).appendTo($('.tileview').parent());
					//--Sort tiles in mobile view//
				}
				$(".tinyfilter").trigger('click');
				$('.instant-floating-filters').hide();
				var reportdetails = JSON.parse(that.state.model.reportdata);
				if ((reportdetails[17] != undefined)) {
					var isDrilldownAllEnable = (reportdetails[17]["isDrillDownAllenable"]);
					if (isDrilldownAllEnable == true) {
						$("#drilldownallchartsinstoryboard").prop("checked", true);
					}
					if (isDrilldownAllEnable == false) {
						$("#drilldownallchartsinstoryboard").prop("checked", false);
					}
					if (that.state.isFromStoryBook != undefined && that.state.isFromStoryBook) {
						TabInfo.functions.updateDrilldownStatusForTab(that.state.tabid, isDrilldownAllEnable);
					}
				}
			}, 3000);
			//	this.startliveupdate(-1, "storyboard");
			var auditMetaDataFeature = false;
			var auditDataFeature = false;
			$.each(this.state.allfeatures, function (index1, feature1) {
				if (feature1.moduleName == 'story_board') {
					$.each(feature1.features, function (index2, feature2) {
						if (feature2.featureName == 'audit_meta_data' && feature2.allow == true) {
							auditMetaDataFeature = true;
							auditDataFeature = true;
						}
					});
				}
			});
			ChartInfo.functions.displaymetadata(auditMetaDataObj, auditMetaDataFeature, auditDataFeature);
			// Add url paramters to html
			var that = this;
			var urlParameterFilters = ChartInfo.functions.getEmbedFilterArray();
			if (urlParameterFilters.length != 0) {
				$(".addURLInstanceFilterData").empty();
				$(".instant-filter-container").removeClass('hide');
				$(".instant-filter-container").addClass('show');
				$(".parameterfiltersgroupdiv").removeClass('hide');
				$(".parameterfiltersgroupdiv").addClass('show');
			}
			setTimeout(function (e) {
				$(".addURLInstanceFilterData").empty();
				_.each(urlParameterFilters, function (drilldownfiltervaue) {
					var value = drilldownfiltervaue.data;
					if (drilldownfiltervaue.hasOwnProperty("fromRange")) {
						if (drilldownfiltervaue["dataType"] != null && drilldownfiltervaue["dataType"] == 'date') {
							var optionsval = {
								year: 'numeric',
								month: 'long',
								day: 'numeric',
							};
							value = new Date(drilldownfiltervaue["fromRange"]).toLocaleString('en-us', optionsval) + " - " + new Date(drilldownfiltervaue["toRange"]).toLocaleString('en-us', optionsval);
						} else {
							value = drilldownfiltervaue["fromRange"] + " - " + drilldownfiltervaue["toRange"];
						}
					}
					var urlparamFilters = "<div class=\"ob closablediv urlparameterfilters\" data-value=\'" + value + "'\">\n\t<span id=\"text\" class=\"tagtext ke urlparameterfiltersspantext\" title=\'" + value + "'\" \n\t\tdata-value=\'" + value + "'\">" + drilldownfiltervaue.columndisplayname + " [" + value + "]\n\t</span>\n</div>"
					$(".addURLInstanceFilterData").append(urlparamFilters);
					if (drilldownfiltervaue["data"] != undefined && drilldownfiltervaue["data"] != null && typeof drilldownfiltervaue["data"] == 'string' && drilldownfiltervaue["data"].indexOf("@@") >= 0) {
						var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value);
						var appendValue = "";
						var ulEle = "";
						if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
							appendValue = breadCrumpElements["span"];
							ulEle = breadCrumpElements["ulelement"];
							$(".urlparameterfiltersspantext[data-value='" + drilldownfiltervaue["data"] + "']").html(drilldownfiltervaue.columndisplayname + ' [' + appendValue + ']' + ulEle)
						}
						$(".drillmoreitemslist").click(function (e) {
							that.drillmoreitemsEvent(e);
						});
					}
				})
			}, 3000);
		} else if (this.state.isTempStoryBoard || this.state.isFromSmartInsigt) {
			if (that.viewreportfilterview != null) {
				that.viewreportfilterview = undefined;
			}
			that.viewreportfilterview = new ViewReportFilterView({});
			$(".sidetheme").append(<div class="panel-group rightfilter" id="filteraccordion"></div>);
			$('.instant-floating-filters').draggable({ cancel: '.col-lg-12.nopadding', containment: '#main>.boc-viewreport-section', handle: '.panel-heading' });
			$(".hidebutton").trigger('click');
			if (that.state.multifact != "true") {
				var timeZone;
				var TimeZoneSettingsValueForstoryboard = ChartInfo.functions.getTimeZoneSettings("-1");
				if (TimeZoneSettingsValueForstoryboard == "localtimezone") {
					timeZone = jstz.determine().name();
				}

				let requestHeaders = {
					method: "get",
					headers: new Headers({
						"Content-Type": "application/json"
					})
				};
				SendRequest({
					url: "gettotaldatacount",
					queryString: "?reportId=" +( that.props.reportId==null?'':that.props.reportId) + " &datamodelId=" + that.props.datamodelId + " &timeZone=" + timeZone + "&isPurgedReport=" + false + "&isImportedReport=" + that.state.isImportedReport + "&isPublished=" + ChartInfo.functions.getisPublished(),
					sucessFunction: response => {
						if (response.hasOwnProperty('error')) {
							$(".spinner").hide();
							$(".gridspinner").hide();
							$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
							$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
							$('.birdmessagebuttons').removeClass('show');
							$('.birdmessagebuttons').addClass('hide');
							$('.birdmessage-center').addClass("message-top-in");
							$('.details').removeClass('hide');
							$('.birdmessage h2').empty().append('Error : ');
							$('.birdmessage h2').append('<span class="errorcode">');
							$('.birdmessage .errorcode').empty().append(response.errorCode);
							$('.birdmessage-info').empty().text(response.error);
							$('.details').addClass('show');
							$('.messagedetails xmp').empty().append(response.errorTrace)
						}
						that.state.datacount = response.size;
						$(".publishinfo").text("Dataset Count: " + response.size);
						ChartInfo.functions.setDataSetCount(response.size);
						if (response.lastexecutedtimestamp != undefined && response.lastexecutedtimestamp.length > 0) {
							$(".lastexecutedtimestamp").text("Last Data Sync: " + response.lastexecutedtimestamp);
						}
					},
					rejectFunction: () => { },
					headers: requestHeaders
				});
			}
			var viewReportAddFilterCollection = [];
			var columnsArray = this.state.reportcolumns;
			dobycolumnnames = columnsArray;
			_.each(columnsArray, function (column) {
				var viewreportaddfiltermodel = {};
				viewreportaddfiltermodel["tableName"] = column["tableName"];
				viewreportaddfiltermodel["columnDisplayType"] = column["tableDisplayType"];
				viewreportaddfiltermodel["tableDisplayType"] = column["tableDisplayType"];
				viewreportaddfiltermodel["columnName"] = column["columnName"];
				viewreportaddfiltermodel["columnDisplayName"] = column["columnDisplayName"];
				viewreportaddfiltermodel["priorityColumn"] = column["priorityColumn"];
				viewReportAddFilterCollection.push(viewreportaddfiltermodel);
			});

			that.viewReportAddFilterView = new ViewReportAddFilterView({
				collection: viewReportAddFilterCollection,
				viewreportcollection: viewReportAddFilterCollection,
				viewreportCollection: collection,
				multifact: that.state.multifact,
				facttables: that.state.facttables,
				multifactentities: that.state.multifactentities,
				datamodelId: that.props.datamodelId,
				isFromSharedModel: that.state.isFromSharedModel,
				tableNames: that.props.tableDisplayNames,
				entities: that.props.entities
			});

		} else {
			console.log("single report")
			// single report
			// this.$el.empty();
			// var chartnumber = 0;
			// var axisData = reportdetails[0]["axis"];
			// _.each(axisData, function(axisdetails) {
			// 	chartnumber = axisdetails["chartnumber"];
			// });
			// var that = this;
			// that.reportdetails = reportdetails;
			// var jsonObject = {};
			// if(reportdetails[reportdetails.length-3]["auditMetaDataOptions"] != undefined){
			// var auditMetaDataObj = JSON.parse(reportdetails[reportdetails.length-3]["auditMetaDataOptions"]);
			// var metaDataAudit = auditMetaDataObj.metaDataAudit;
			// jsonObject["metaDataAudit"] = metaDataAudit;
			// ChartInfo.functions.setAuditMetaDataOptions(jsonObject);
			// }
			// else if(reportdetails[reportdetails.length-1]["auditMetaDataOptions"] != undefined){
			// 	var auditMetaDataObj = JSON.parse(reportdetails[reportdetails.length-1]["auditMetaDataOptions"]);
			// 	var metaDataAudit = auditMetaDataObj.metaDataAudit;
			// 	jsonObject["metaDataAudit"] = metaDataAudit;
			// 	ChartInfo.functions.setAuditMetaDataOptions(jsonObject);
			// 	}
			// require(['views/viewreport/savedreport/viewsinglesavedreportview'], 
			// 		function(ViewSingleSavedReport) {
			// 	if (that.viewSingleSavedReport != null) {
			// 		that.viewSingleSavedReport.cleanup();
			// 	}
			// 	sessionStorage.removeItem("customhierarchy"+chartnumber);
			// 	that.viewSingleSavedReport = new ViewSingleSavedReport(
			// 			{
			// 				broker : that.broker,
			// 				reportId : that.props.reportId,
			// 				isFromStoryBook:that.props.isFromStoryBook,
			// 				tabid:that.props.tabid,
			// 				reportmodel : model,
			// 				chartnumber : chartnumber,
			// 				name : name,
			// 				folder : folder,
			// 				desc : desc,
			// 				features : that.features,
			// 				schedule : that.props.schedule,
			// 				scheduleid : that.props.scheduleid,
			// 				date : that.props.date,
			// 				schemaType:that.schemaType,
			// 				isPurgedReport:that.isPurgedReport,
			// 				datamodelId:that.props.datamodelId,
			// 				isImportedReport:that.isImportedReport,
			// 				multifact:that.multifact,
			// 				facttables:that.facttables,
			// 				multifactentities:that.multifactentities,
			// 			});
			// 	if(that.viewSingleSavedReport.showError == true){
			// 	return false
			// 	}
			// 	that.$el.append(that.viewSingleSavedReport.el);
			// 	var chartDetails=JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
			// 	if(that.props.isFromStoryBook!=undefined && that.props.isFromStoryBook){
			// 		var tabId = that.props.tabid;
			// 		TabInfo.functions.updateChartInfo.functionsForTab(tabId, ChartInfo.functions.getAllChartDetails());

			// 	}
			// 	var chartType = chartDetails.chartType;
			// 	if("doby" === chartType){
			// 		$("#sinbleviewchartfromchartdataview[data-num='"+chartnumber+"']").hide();
			// 		$("#sinbleviewchartdataview[data-num='"+chartnumber+"']").hide();
			// 	}

			// 	/*//displaying time zone on footer
			// 	var d=that.getServerDateTimeCollection.toJSON();
			// 	$(".servertimezone").text(d[0].serverDateTime);
			// 	var dt = new Date();
			// 	$(".localtimezone").text(dt);
			// 	$(".timezone").removeClass("hide");*/

			// 	// If report is saved text chart. I am calling textChart method manually
			// 	that.viewSingleSavedReport.redrawTextChart(chartnumber)

			// 	that.startliveupdate(chartnumber,"singlereport");
			// 	if(that.isImportedReport){
			// 		var singleReportTitle=model.get("title");
			// 		$(".boc-viewreport-title[data-num="+chartnumber+"]").text(singleReportTitle).attr("title",singleReportTitle);
			// 	}
			// 	/*for Safari Browser MyReport chart height Issue Fix*/
			// 	function resizeForSafari(){
			// 		var browser = "";
			// 		var chrome = /chrom(e|ium)/.test(navigator.userAgent.toLowerCase());
			// 		var safari = /safari/.test(navigator.userAgent.toLowerCase());

			// 		if(chrome!=true && safari==true){
			// 			var actual = $("body").height();
			// 			var header = $(".navbar.navbar-bird.navbar-fixed-top").height();
			// 			var controlbar = $(".row.controlbar-main").height();
			// 			var chartcontrols = $(".row.maxanim9").height();
			// 			var setheight = actual - header - controlbar - chartcontrols - 70;
			// 			$(".singlereportview.fullscreenmain .maxanim10").css("height",setheight);
			// 		}
			// 	}									
			// 	resizeForSafari();
			// 	$(window).on("resize",function(){resizeForSafari();})
			// 	var auditMetaDataFeature = false;
			// 	var auditDataFeature = false;
			// 	$.each(that.features,function(index1,feature1){
			// 		if(feature1.moduleName=='my_report'){
			// 			$.each(feature1.features,function(index2,feature2){
			// 					if(feature2.featureName=='audit_meta_data_maximize' && feature2.allow==true){
			// 						auditMetaDataFeature = true;
			// 						auditDataFeature = true;
			// 					}
			// 			});
			// 		}
			//   });
			//  ChartInfo.functions.displaymetadata(auditMetaDataObj,auditMetaDataFeature,auditDataFeature);
			// });
		}
		if (that.state.isFromSmartInsigt) {
			return;
		}
		if (that.state.isDirect != undefined) {
			ChartInfo.functions.setIsDirect(that.state.isDirect);
		}
		ChartInfo.functions.setIsStoredProcedure(that.state.isStoredProcedure);
	}
	loadQuickFilters() {
		var isSharedReport = false;
		var thatvar = this;
		if (window.location.pathname.indexOf("sharereport") >= 0) {
			isSharedReport = true;
		}
		if (this.state.isFromSmartInsigt) {
			return;
		}
		var chartNumber = Object.keys(ChartInfo.functions.getAllChartDetails())[0];
		var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(chartNumber));
		var drillDownAllFilter = _.unique(chartDetails["drillDownAllFilter"], 'columnname');
		$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
		$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").attr("drilled-chart", this.props.chartnumber);
		$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
		$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer").attr("drilled-chart", this.props.chartnumber);
		if (drillDownAllFilter != undefined) {
			_.each(drillDownAllFilter, (i, j) => {
				var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
				if (name === null) {
					name = decodeURIComponent(drillDownAllFilter[j].name);
				}
				else if (name == "undefined") {
					name = decodeURIComponent(drillDownAllFilter[j].displayName);
					if (drillDownAllFilter[j].customfield != undefined) {
						name = drillDownAllFilter[j].customfield.name;
						if (drillDownAllFilter[j].data == undefined) {
							drillDownAllFilter[j].data = drillDownAllFilter[j].customfield.parameter;
						}
					}
				}
				if (drillDownAllFilter[j].type == "date") {
					name = $(name).find('li:first').attr('column-display-name');
					if (name === undefined) {
						name = drillDownAllFilter[j].displayName;
					}
				}
				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
				ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data.toString()} />, document.getElementById(dynamicdiv));
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
				$("#" + dynamicdiv).remove();
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
				$('.quickfilterremove').click((e) => { thatvar.quickFilterRemove(e) })
				if (isSharedReport == true && collection.getSharedViewOnlyFlag() != null && collection.getSharedViewOnlyFlag() != undefined && collection.getSharedViewOnlyFlag() == '1') {
					$(".quickfilterremove").remove();
				} else {
					$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>")
				}
			});
		}
	}
	quickFilterRemoveEve(msg, data) {
		this.quickFilterRemove(data);
	}
	quickFilterRemove(e) {
		var $this = e.currentTarget;
		var that = this;
		var charts = ChartInfo.functions.getAllChartDetails();
		if (that.state.isFromStoryBook != undefined && that.state.isFromStoryBook) {
			var tabid = TabInfo.functions.getCurrentTabId();
			_.each(charts, function (i, j) {
				var tempObj = {};
				tempObj[j] = undefined;
				TabInfo.functions.updateDrilldownFilterFromSession(tabid, tempObj);
				sessionStorage.removeItem("drilldown" + j);
			});
		}
		var drillDownArray = [];
		var removeObject = {};
		var columnname = $($this).attr("columnname");
		var data = $($this).attr("data");
		_.each(charts, function (i, j) {
			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(j));
			drillDownArray = chartDetails['drillDownAllFilter'];
			if (drillDownArray != undefined) {
				_.each(drillDownArray, function (value) {
					value.columndisplayname = decodeURI(value.columndisplayname);
					if (value.data == data && value.columndisplayname == columnname) {
						removeObject = value
						return;
					}
					else if (value['customfield'] != undefined && value['customfield'].name == columnname) {
						removeObject = value;
						return;
					}
				});
				if (drillDownArray.length != 0) {
					drillDownArray = $.grep(drillDownArray, function (value) {
						if (value['customfield'] != undefined && removeObject['customfield'] != undefined) {
							if (value['customfield'].name == removeObject['customfield'].name && value['customfield'].parameter == data) {
								return false;
							}
							else {
								return true;
							}
						}
						else if (value.data == data && value.columndisplayname == columnname) {
							return false;
						}
						else {
							return true;
						}

					});
				}
			}

			//DrillDown filter
			var drillDownArrayOfEachChart = chartDetails['drillDownFilter'];
			if (drillDownArrayOfEachChart != undefined) {
				_.each(drillDownArrayOfEachChart, function (value) {
					value.columndisplayname = decodeURI(value.columndisplayname);
					if (value.data == data && value.columndisplayname == columnname) {
						removeObject = value
						return;
					}
					else if (value['customfield'] != undefined && value['customfield'].name == columnname) {
						removeObject = value;
						return;
					}
				});
				if (drillDownArrayOfEachChart.length != 0) {
					drillDownArrayOfEachChart = $.grep(drillDownArrayOfEachChart, function (value) {
						if (value['customfield'] != undefined && removeObject['customfield'] != undefined) {
							if (value['customfield'].name == removeObject['customfield'].name && value['customfield'].parameter == data) {
								return false;
							}
							else {
								return true;
							}
						}
						else if (value.data == data && value.columndisplayname == columnname) {
							return false;
						}
						else {
							return true;
						}

					});
				}
			}

			var customfilterSaved = chartDetails["customfieldFilterObject"];
			if (customfilterSaved != undefined && customfilterSaved.length != 0) {
				customfilterSaved = $.grep(customfilterSaved, function (value) {
					if (value['customfield'] != undefined && removeObject['customfield'] != undefined) {
						if (value['customfield'].name == removeObject['customfield'].name && value.data == data) {
							return false;
						}
						else {
							return true;
						}
					}
					else {
						return true;
					}
				});
				chartDetails["customfieldFilterObject"] = customfilterSaved;
			}


			chartDetails["drillDownAllFilter"] = drillDownArray;
			chartDetails["drillDownFilter"] = drillDownArrayOfEachChart;
			ChartInfo.functions.setSavedDrilldownAll(drillDownArray);
			if (drillDownArray != undefined && drillDownArray.length == 0) {
				chartDetails["legendfilterall"] = drillDownArray;
			}
			ChartInfo.functions.updateChartDetails(j, JSON.stringify(chartDetails));
			if (chartDetails['chartType'] == "summarytable") {
				//that.broker.trigger("summarytabledrilldown", $this,drillDownArray, 123,j, undefined);
				var propObj = {};
				propObj["currentChartNumber"] = j;
				propObj["measures"] = collection.getMeasures();
				propObj["dimensions"] = collection.getDimensions();
				propObj["pos"] = "";
				propObj["chartDetails"] = chartDetails;
				propObj["isExcludeorInclude"] = undefined;
				propObj["isDateHistogram"] = undefined;
				propObj["dateHistomgramColName"] = undefined;
				propObj["applyattributes"] = undefined;
				PubSub.publish("viewSummaryTabe", propObj);
				//ChartInfo.functions.setTempSummaryTableFilterValues(drillDownArray, j);				 					
			}
			else{
				var applyObj = {};
		applyObj["pos"] = undefined;
		applyObj["currentchartnum"] = j;
		applyObj["applyattributes"] = "applyattributes";
		applyObj["dateHistogramInterval"] = undefined;
		applyObj["calculation"] = undefined;
		applyObj["isExcludeorIncludeFilters"] = undefined;
		applyObj["isChartSummaryData"] = undefined;
		applyObj["selecteddobycolumns"] = undefined;
		applyObj["filterArray"] = [];
		applyObj["navigatefrom"] = "drilldownview";
		applyObj["isCustomparameterFilter"] = undefined;
		applyObj["tableInfo"] = undefined;
		PubSub.publish('applyfiltesandredrawchart', applyObj);
			}
		});
		var tempdrillDownAllFilter = drillDownArray;//ChartInfo.functions.getdrillfitlerArray();
		
		_.each(charts, function (i, j) {
			$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
			$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").attr("drilled-chart", j);
			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(j));
			var drillDownAllFilter = _.unique(chartDetails["drillDownAllFilter"], 'columnname');
			_.each(drillDownAllFilter, function (i, j) {
				var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
				if (name === null) {
					name = decodeURIComponent(drillDownAllFilter[j].name);
				}
				else if (name == undefined) {
					var name = decodeURIComponent(drillDownAllFilter[j].displayName);
					if (name === undefined) {
						name = drillDownAllFilter[j].customfield.name;
					}
				} else if (name == "undefined") {
					var name = decodeURIComponent(drillDownAllFilter[j].displayName);
					if (drillDownAllFilter[j].customfield != undefined) {
						name = drillDownAllFilter[j].customfield.name;
						if (drillDownAllFilter[j].data == undefined) {
							drillDownAllFilter[j].data = drillDownAllFilter[j].customfield.parameter;
						}
					}
				}
				if (drillDownAllFilter[j].type == "date") {
					name = $(name).find('li:first').attr('column-display-name');
					if (name === undefined) {
						name = drillDownAllFilter[j].displayName;
					}
				}
				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
				ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data.toString()} />, document.getElementById(dynamicdiv));
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
				$("#" + dynamicdiv).remove();
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
				$('.quickfilterremove').click((e) => { that.quickFilterRemove(e) })
			});
			$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] .quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>")


			//				 			   _.each(tempdrillDownAllFilter,function(i, j){
			//				 				  var name = decodeURIComponent(tempdrillDownAllFilter[j].columndisplayname);
			//				 				  if (name===null){
			//				 					  var name = decodeURIComponent(tempdrillDownAllFilter[j].name);
			//				 				  }
			//				 				 else if(name=="undefined"){
			//					    			 var name = decodeURIComponent(tempdrillDownAllFilter[j].displayName); 
			//					    		 }
			//				 				  if(tempdrillDownAllFilter[j].type=="date"){
			//				 					  name = $(name).find('li:first').attr('column-display-name');
			//				 					  if(name===undefined){
			//				 						  	name= tempdrillDownAllFilter[j].displayName;
			//				 						   if(tempdrillDownAllFilter[j].customfield!=undefined){
			//							    				name=tempdrillDownAllFilter[j].customfield.name;
			//							    			}
			//				 					  }
			//				 				  }
			//				 				  $(".quickfiltercontainer[tab-id="+TabInfo.getCurrentTabId()+"]").append(that.quicktemplate({columnDisplayName:name, filterValues :tempdrillDownAllFilter[j].data}));
			//				 			  });				 			  
			var data = JSON.parse(ChartInfo.functions.getChartDetails(j));
			if (data["drillDownFilter"] != undefined) {
				$(".breadcrumb[data-num=" + j + "]").remove();
				$("#chart" + j + "").before("<ul class='breadcrumb' data-num=" + j + "></ul>");
				var breadCrumbElement = $('.breadcrumb[data-num=' + j + ']')
				_.each(data["drillDownFilter"], function (value, index) {
					if (value["saved"] == "true") {
						var appendValue = value.data;
						var ulEle = "";
						var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
						if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
							appendValue = breadCrumpElements["span"];
							ulEle = breadCrumpElements["ulelement"];
						}
						if (value.columndisplayname != undefined) {
							breadCrumbElement.append("<li class=drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name='" + value.data + "' class='close removedrilldown' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
						}

					}
				});
			}
		});
		sessionStorage.setItem("changesdone", 1);
	}
	customscript() {
		var that = this;
		function executeUserCustomCode() {
			var emptycharts = $(".maxanim10:empty").length;
			if (emptycharts == 0) {
				$(".spinner").removeClass("white story").hide();
				setTimeout(function () {
					that.userscript();
					$(".doby-grid-viewport").on("scroll", function () {
						that.userscript();
					});
				}, 1000);
			} else {
				setTimeout(function () {
					executeUserCustomCode();
				}, 2000);
			}
		}
		setTimeout(function () { executeUserCustomCode(); }, 2000);
	}
	userscript() {
		var userCustomScript = ChartInfo.functions.getUserCustomScript(this.props.reportId);
		if(userCustomScript!=null && userCustomScript!=undefined){
			eval(decodeURI(userCustomScript));
			
			// try {
			// 	Function(decodeURI(userCustomScript))();
			// } catch (e) {
			// 	console.log(e.message);
			// }

			setTimeout(function () {
				var time = 10;
				if($(".storybooksection").length>0){
					if ($(".storybookpagecontent.active .fullscreenmain").length != 1) {
						_.each($(".storybookpagecontent.active:not(.reportloaded) .tileview"), function (i, j) {
							setTimeout(function () {
								var acd = {};
								acd["element"] = i;
								acd["pos"] = "min";
								PubSub.publish('adjustchartheight', acd);
							}, time)
							time = time + 500;
						});

						if($(".storybookpagecontent.active.reportloaded .tileview .leafletcontainer").length>0){
							time = 0;
							_.each($(".storybookpagecontent.active.reportloaded .tileview .leafletcontainer"), function (i, j) {
								var leafid = $(i).attr("id").replace("leaflet","tileview");
								var leaftarget = $("#"+leafid);
								setTimeout(function () {
									var acd = {};
									acd["element"] = leaftarget;
									acd["pos"] = "min";
									PubSub.publish('adjustchartheight', acd);
								}, time)
								time = time + 500;
							});
						}
					}
				}else{
					if ($(".fullscreenmain").length != 1) {
						_.each($(".tileview"), function (i, j) {
							setTimeout(function () {
								var acd = {};
								acd["element"] = i;
								acd["pos"] = "min";
								PubSub.publish('adjustchartheight', acd);
							}, time)
							time = time + 500;
						});
					}
				}
			}, 1000);
		}
	}
	viewDataTablePopUpEve(msg, data) {
		this.viewDataTablePopUp(data);
	}
	viewDataTablePopUp(e, currentChartNumber) {
		var $this = e.currentTarget;
		var that = this.state;
		if (this.state.tabid != TabInfo.functions.getCurrentTabId()) {
			return;
		}
		if (currentChartNumber == undefined) {
			currentChartNumber = $($this).attr('data-num');
		}
		var dobycolumnnames = that.reportcolumns;
		$('.pie-menu-start').toggleClass("opacityfull");
		$('.cn-wrapper').toggleClass("opened-nav");
		$('.pie-menu').remove();
		//							$('.trend-stats-container').remove();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		$('.pivotdataview[data-number=' + currentChartNumber + ']').remove();
		var dataTableProps = {};
		dataTableProps["currentChartNumber"] = currentChartNumber;
		dataTableProps["target"] = $this;
		dataTableProps["facttables"] = that.facttables;
		dataTableProps["multifact"] = that.multifact;
		dataTableProps["multifactentities"] = that.multifactentities;
		dataTableProps["dimensions"] = dobycolumnnames;
		//	var objTemp= new DataTablePopupView(dataTableProps);
		//	objTemp.render();
		$("#datatablepopupmodal" + currentChartNumber + "").remove();
		$("body").append("<div id='datatablepopupmodal" + currentChartNumber + "'></div>");
		ReactDOM.unmountComponentAtNode(document.getElementById("datatablepopupmodal" + currentChartNumber));

		ReactDOM.render(<DataTablePopupView dimensions={dobycolumnnames} currentChartNumber={currentChartNumber} target={$this} multifactentities={that.multifactentities} multifact={that.multifact} facttables={that.facttables}  ></DataTablePopupView>, document.getElementById("datatablepopupmodal" + currentChartNumber));

		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.viewchartdata').removeClass("active");
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.viewchartfromchartdata').addClass("active");
		$("#spinnerchart" + currentChartNumber).show();
		var chartData = ChartInfo.functions.getChartDetails(currentChartNumber);
		var data = JSON.parse(chartData);
		if (data.chartType != null && data.chartType == 'summarytable' && data.summarytableaggregationOriginalColumns != undefined) {
			sessionStorage.setItem("datatable_popup_yaxis", data.summarytableaggregationOriginalColumns[0]);
		}
		var yaxisdata = data["yAxis"];
		var yentities = [];
		_.each(yaxisdata, function (col) {
			yentities.push(col.columnName);
		});
		var chartdobyselectcolumns = data["dobyselectedcolumns"];
		var comparedSelectColumns = [];
		_.each(chartdobyselectcolumns, function (i, j) {
			_.each(dobycolumnnames, function (l, m) {
				if (dobycolumnnames[m].columnDisplayName === chartdobyselectcolumns[j].displaynames) {
					comparedSelectColumns.push(chartdobyselectcolumns[j]);
				}
			})
		})
		if (comparedSelectColumns != undefined && comparedSelectColumns.length == 0) {
			that.dobycolumns = data["dobyselectedcolumns"];
		} else {
			that.dobycolumns = comparedSelectColumns;
		}
		_.each(dobycolumnnames, function (i, j) {
			var columnDisplayName = dobycolumnnames[j].columnDisplayName;
			var columnName = dobycolumnnames[j].columnName;
			var datatype = dobycolumnnames[j].tableDisplayType;
			$('.innerdobyselectedcolumns' + currentChartNumber).append("<li class='doby-list-item' tite= '" + columnDisplayName + "' ><label><input type='checkbox' class = 'filtercheckbox' " + " name= '" + columnName + "' displayname='" + columnDisplayName + "' datatype= '" + datatype + "' > </i>&nbsp;" + columnDisplayName + "</label></li>");
		});
		if (that.dobycolumns == undefined || that.dobycolumns.length === 0) {
			var selecteddata = [];
			if (that.multifact == "true") {
				var disable = false;
				$('.innerdobyselectedcolumns' + currentChartNumber + ' .doby-list-item label input').each(function (i) {
					if (selecteddata.length < 10) {
						var temp = {};
						disable = ChartInfo.functions.getAllowedEntitiesForInstantFilters(JSON.parse(that.multifactentities), $(this).attr("name"), undefined, yentities, undefined, that.facttables);
						if (!disable) {
							temp["actualnames"] = $(this).attr("name");
							temp["displaynames"] = $(this).attr("displayname");
							temp["datatype"] = $(this).attr("datatype");
							selecteddata.push(temp);
						}
					}
				});

			} else {
				$('.innerdobyselectedcolumns' + currentChartNumber + ' .doby-list-item label input').each(function (i) {
					if (i < 10) {
						var temp = {};
						temp["actualnames"] = $(this).attr("name");
						temp["displaynames"] = $(this).attr("displayname");
						temp["datatype"] = $(this).attr("datatype");
						selecteddata.push(temp);
					}
				});
			}


			that.dobycolumns = selecteddata;
		}
		data["dobyselectedcolumns"] = that.dobycolumns;
		$(".dobypagination").show();
		ChartInfo.functions.updateChartDetails(currentChartNumber, JSON.stringify(data));

		if ($($this).closest('.dobygrid').find(".fa").hasClass("fa-bar-chart")) {
			var storedValue = ChartInfo.functions.getChartDetails(currentChartNumber);
			var storedObect = JSON.parse(storedValue);
			var chartType = storedObect["chartType"];
		}
		var previousArray = [];
		if (data["chartType"] == "summarytable") {
			if (ChartInfo.functions.getTempSummaryTableFilterValues(currentChartNumber) != undefined) {
				previousArray = JSON.parse(JSON.stringify(ChartInfo.functions.getTempSummaryTableFilterValues(currentChartNumber)));
			}
			if (that.summarytablefiltervalues != undefined && that.summarytablefiltervalues[0] != undefined) {
				previousArray.push(that.summarytablefiltervalues[0]);
			}
			if (that.summarytablefiltervalues != undefined && that.summarytablefiltervalues[1] != undefined) {
				previousArray.push(that.summarytablefiltervalues[1]);
			}
		}
		var viewDataTableObj = {};
		viewDataTableObj["currentChartNumber"] = currentChartNumber;
		viewDataTableObj["selectedcolumns"] = that.dobycolumns;
		viewDataTableObj["state"] = "";
		viewDataTableObj["pos"] = "max";
		viewDataTableObj["popupwindow"] = "popupview";
		viewDataTableObj["target"] = $this;
		viewDataTableObj["applyattributes"] = undefined;
		viewDataTableObj["filters"] = previousArray;
		viewDataTableObj["tableInfo"] = undefined;
		PubSub.publish("viewDataTable", viewDataTableObj);
		// this.setState({
		// 	isForAddInstantFilters: true
		// })
		this.componentDidUpdateCopy();

	}
	excludechartpointEve(msg, data) {
		this.excludechartpoint(data);
	}
	excludechartpoint(e) {
		$(".pie-menu").remove();
		$('.exclude-point').addClass('disabled');
		setTimeout(function () {
			$('.exclude-point').removeClass('disabled');
		}, 5000);
		this.excludechartpointForDQ(e);
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber))
		$(".breadcrumb[data-num=" + currentChartNumber + "]").remove();
		$("#chart" + currentChartNumber + "").before("<ul class='breadcrumb' data-num=" + currentChartNumber + "></ul>");
		if (currentChartNumber != undefined) {
			if (chartDetails["drillDownFilter"] != undefined) {
				var breadCrumbElement = $('.breadcrumb[data-num=' + currentChartNumber + ']')
				_.each(chartDetails["drillDownFilter"], function (value, index) {
					var appendValue = value.data;
					var ulEle = "";
					var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
					if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
						appendValue = breadCrumpElements["span"];
						ulEle = breadCrumpElements["ulelement"];
					}
					if (value["saved"] == "true") {
						breadCrumbElement.append("<li class=drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name='" + value.data + "' class='close removedrilldownsinglereport' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
					}
				});
			}
		}
		// this.setState({
		// 	isForAddInstantFilters: true
		// })
		this.componentDidUpdateCopy();
	}
	excludechartpointForDQ(e) {
		$('.boc-piemenu-close').click();
		$('.instant-floating-filters').show();
		$('.trend-stats-container').remove();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		$('.allscopeslistcontainer').show();
		e.preventDefault();
		e.stopPropagation();
		var that = this;
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		$("#spinnerchart" + currentChartNumber).show();
		$("#spinner" + currentChartNumber).show();
		var legendfilter = [];
		ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
		ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
		var legendfilter = [];
		ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
		ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
		if (ChartInfo.functions.getAllChartDetails() != undefined) {
			_.each(ChartInfo.functions.getAllChartDetails(), function (i, j) {
				sessionStorage.removeItem("drilldown" + j);
			});
		}
		var excludeFiltersData = ChartInfo.functions.getExcludeFilterData();
		var filterArray = [];
		var drillDown = JSON.parse(sessionStorage.getItem("drilldown" + currentChartNumber));
		var customFields = JSON.parse(sessionStorage.getItem("customFields"));
		var temp = customFields;
		var res;
		_.each(temp, function (customfields) {
			_.each(customfields.parametrFieldsArray, function (value) {
				var customparameter = ChartInfo.functions.getCustomParameter();
				var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters('applyattributes');
				if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
					customparameter = customParameterFilterArray;
				}
				res = $.grep(customparameter, function (e) {
					return e.parametername == value;
				})
				var formula = customfields.formula;
				var scriptcondition = customfields.scriptcondition;
				var fields = customfields.fields;
				var sqlformula = customfields.sqlformula;
				if (res.length != 0) {
					customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
				} else {
					_.each(ChartInfo.functions.getCustomParameter(), function (values) {
						if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
							customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
							customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
						}
					});
				}
			});
		});
		customFields = temp;
		if (excludeFiltersData.length != 0) {
			_.each(excludeFiltersData, function (data, i) {
				var columnDisplayName;
				var columnName = data.data;
				if (data.dataType == "date") {
					columnDisplayName = data.columndisplayname;
				}
				else {
					columnDisplayName = data.columndisplayname;
				}
				if (customFields == null || customFields == undefined) {
					var InstanceFilterData = ChartInfo.functions.getInstanceFilterData();
					var filterObject = {};
					var filterdata = [];
					var instantExcludeFilters = "";
					filterObject["dataType"] = data.dataType;
					filterObject["tablename"] = $($this).attr("data-parent");
					filterObject["columndisplayname"] = data.columnname;
					filterObject["columnname"] = data.columnname;
					if (data.dataType == "date") {
						if (data.data.length > 0) {
							var newDate = data.data;//Date.parse(data.data.replace(/-/g,'/'));
							if (isNaN(newDate)) {
								data.data = data.data;
							}
							else {
								data.data = newDate;//new Date(newDate).getTime();
							}
						}
					}
					filterObject["data"] = data.data;
					filterObject["excludeFilterData"] = "excludeFilterData";
					filterdata.push(filterObject);
					instantExcludeFilters = JSON.parse(sessionStorage.getItem("instantExcludeFilters"));
					if (instantExcludeFilters != null && instantExcludeFilters != "null") {
						instantExcludeFilters.push(filterObject);
						sessionStorage.setItem("instantExcludeFilters", JSON.stringify(instantExcludeFilters));
						ChartInfo.functions.setInstanceFilterData(instantExcludeFilters);
					} else {
						sessionStorage.setItem("instantExcludeFilters", JSON.stringify(filterdata));
						ChartInfo.functions.setInstanceFilterData(filterdata);
					}
				} else {
					_.each(customFields, function (customFieldData, j) {
						var customObject = {};
						if (!that.iscustomfield(customFields, data.columnname)) {
							var InstanceFilterData = ChartInfo.functions.getInstanceFilterData();
							var filterObject = {};
							var filterdata = [];
							var instantExcludeFilters = "";
							columnName = data.data;
							filterObject["dataType"] = data.dataType;
							filterObject["tablename"] = $($this).attr("data-parent");
							filterObject["columndisplayname"] = data.columnname;
							filterObject["columnname"] = data.columnname;
							if (data.dataType == "date") {
								if (data.data.length > 0) {
									var newDate = data.data;//Date.parse(data.data.replace(/-/g,'/'));
									if (isNaN(newDate)) {
										data.data = data.data;
									}
									else {
										data.data = newDate;//new Date(newDate).getTime();
									}
								}
							}
							filterObject["data"] = data.data;
							filterObject["excludeFilterData"] = "excludeFilterData";
							filterdata.push(filterObject);
							instantExcludeFilters = JSON.parse(sessionStorage.getItem("instantExcludeFilters"));
							if (instantExcludeFilters != null && instantExcludeFilters != "null") {
								instantExcludeFilters.push(filterObject);
								sessionStorage.setItem("instantExcludeFilters", JSON.stringify(instantExcludeFilters));
								ChartInfo.functions.setInstanceFilterData(instantExcludeFilters);
							} else {
								sessionStorage.setItem("instantExcludeFilters", JSON.stringify(filterdata));
								ChartInfo.functions.setInstanceFilterData(filterdata);
							}
						} else if (customFieldData.name == data.columnname) {
							var sqlFilterObject = {};
							var filterObject = {};
							var filterdata = [];
							sqlFilterObject["sqldata"] = data.data;
							customFieldData["sqlcondition"] = sqlFilterObject;
							customObject = customFieldData;
							customObject["parameter"] = data.data;
							filterObject["customfield"] = customObject;
							filterObject["dataType"] = data.dataType;
							filterObject["excludeFilterData"] = "excludeFilterData";
							var scriptFilterCondition = customObject['scriptcondition'];
							var finalScriptCondition = ";" + "if(";
							instantExcludeFilters = JSON.parse(sessionStorage.getItem("instantExcludeFilters"));
							if (filterObject["dataType"] == "string" || filterObject["dataType"] == "number") {
								finalScriptCondition += "!((" + scriptFilterCondition + ").toString().equals('" + excludeFiltersData[0].data + "')";
								finalScriptCondition += ")) return true else false";
								customObject['scriptcondition'] = finalScriptCondition;
							}
							if (filterObject["dataType"] == "date") {
								$.each(excludeFiltersData, function (index, value) {
									if (index == 0) {
										finalScriptCondition += "(" + scriptFilterCondition + ").toString().equals('" + value.data + "')";
									} else {
										finalScriptCondition += " || " + "(" + scriptFilterCondition + ").toString().equals('" + value.data + "')";
									}
								});
								if (instantExcludeFilters != null) {
									$.each(instantExcludeFilters, function (index, value) {
										if (value != undefined)
											finalScriptCondition += " || " + "(" + scriptFilterCondition + ").toString().equals('" + value.data + "')";
									});
								}
							}
							filterObject["data"] = data.data;
							filterObject["columndisplayname"] = data.columndisplayname;
							filterObject["columnname"] = data.columnname;
							filterdata.push(filterObject);
							var instantExcludeCustomFilters = JSON.parse(sessionStorage.getItem("instantExcludeCustomFilters"));
							if (instantExcludeCustomFilters != null && instantExcludeCustomFilters != "null") {
								instantExcludeCustomFilters.push(filterObject);
								sessionStorage.setItem("instantExcludeCustomFilters", JSON.stringify(instantExcludeCustomFilters));
								ChartInfo.functions.setInstanceFilterData(instantExcludeCustomFilters);
							} else {
								sessionStorage.setItem("instantExcludeCustomFilters", JSON.stringify(filterdata));
								ChartInfo.functions.setInstanceFilterData(filterdata);
							}
						}
					});
				}
				$('.singlerportfilters').click();
				//	$('#singlreportfiltermodal').modal("show");
				//	$(".instant-filter-container").removeClass('hide');
				$(".instant-filter-container").addClass('hide');
				var dataValue = data.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
				$('.addInstanceFilterData').append('<div class="ob closablediv closedeleteinstantfilter" data-value="' + dataValue + '"><a class="close deleteinstantfilterdata deleteinstantfilter" id="deleteinstantfilterdata" data-value="' + dataValue + '"><i class="fa fa-close"></i></a><span id="text"  class="tagtext ex addinstancefilter" title="' + data.data + '" data-value="' + dataValue + '" ></span></div>');
				// that.setState({
				// 	isForAddInstantFilters: true
				// })
				that.componentDidUpdateCopy();
				var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(columnName);
				var appendValue = columnName;
				var ulEle = "";
				if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
					appendValue = breadCrumpElements["span"];
					ulEle = breadCrumpElements["ulelement"];
					$(".addinstancefilter[data-value='" + dataValue + "']").html(columnDisplayName + ' [' + appendValue + ']' + ulEle);
					$(".addinstancefilter[data-value='" + dataValue + "']").attr("title", "");
				} else {
					$(".addinstancefilter[data-value='" + dataValue + "']").text(columnName + ' [' + columnDisplayName + ']');
				}

				$(".deleteinstantfilter[data-value='" + dataValue + "']").attr("data-child", data.columnname);
				$(".deleteinstantfilter[data-value='" + dataValue + "']").attr("col-name", data.columnname);
				$(".deleteinstantfilter[data-value='" + dataValue + "']").attr("data-type", data.dataType);
				$(".deleteinstantfilter[data-value='" + dataValue + "']").attr("data-value", dataValue);
				$(".deleteinstantfilter[data-value='" + dataValue + "']").attr("data-num", currentChartNumber);
			});
		}
		ChartInfo.functions.getFilters(true);
		PubSub.publish('applyfiltesandredrawchart');
		//	this.broker.trigger('applyfiltesandredrawchart', undefined,undefined,undefined,undefined,"yes");
		var chartData = ChartInfo.functions.getChartDetails(currentChartNumber);
		var data = JSON.parse(chartData);
		var legendfilter = [];
		ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
		ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
		sessionStorage.removeItem("drilldown" + currentChartNumber);
		$(".breadcrumb[data-num=" + currentChartNumber + "]").remove();
		$("#chart" + currentChartNumber + "").before("<ul class='breadcrumb' data-num=" + currentChartNumber + "></ul>");
		if (currentChartNumber != undefined) {
			if (data["drillDownFilter"] != undefined) {
				var breadCrumbElement = $('.breadcrumb[data-num=' + currentChartNumber + ']')
				_.each(data["drillDownFilter"], function (value, index) {
					var appendValue = value.data;
					var ulEle = "";
					var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
					if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
						appendValue = breadCrumpElements["span"];
						ulEle = breadCrumpElements["ulelement"];
					}
					if (value["saved"] == "true") {
						breadCrumbElement.append("<li class=drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name='" + value.data + "' class='close removedrilldownsinglereport' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
					}
				});
			}
		}
	}
	keeponlychartpointEve(msg, data) {
		this.keeponlychartpoint(data);
	}
	keeponlychartpoint(e) {
		$(".pie-menu").remove();
		$('.keeponly-point').addClass('disabled');
		setTimeout(function () {
			$('.keeponly-point').removeClass('disabled');
		}, 5000);
		this.keeponlychartpointForDQ(e);
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		var data = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber))
		$(".breadcrumb[data-num=" + currentChartNumber + "]").remove();
		$("#chart" + currentChartNumber + "").before("<ul class='breadcrumb' data-num=" + currentChartNumber + "></ul>");
		if (currentChartNumber != undefined) {
			if (data["drillDownFilter"] != undefined) {
				var breadCrumbElement = $('.breadcrumb[data-num=' + currentChartNumber + ']')
				_.each(data["drillDownFilter"], function (value, index) {
					var appendValue = value.data;
					var ulEle = "";
					var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
					if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
						appendValue = breadCrumpElements["span"];
						ulEle = breadCrumpElements["ulelement"];
					}
					if (value["saved"] == "true") {
						breadCrumbElement.append("<li class=drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name='" + value.data + "' class='close removedrilldownsinglereport' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
					}
				});
			}
		}

		// this.setState({
		// 	isForAddInstantFilters: true
		// })
		this.componentDidUpdateCopy();
	}
	keeponlychartpointForDQ(e) {
		$('.boc-piemenu-close').click();
		$('.instant-floating-filters').show();
		$('.trend-stats-container').remove();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		$('.allscopeslistcontainer').show();
		e.preventDefault();
		var that = this;
		e.stopPropagation();
		var $this = e.currentTarget;
		var currentChartNumber = $($this).attr("data-num");
		var legendfilter = [];
		ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
		ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
		sessionStorage.removeItem("drilldown" + currentChartNumber);
		$("#spinnerchart" + currentChartNumber).show();
		$("#spinner" + currentChartNumber).show();
		var legendfilter = [];
		ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
		ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
		if (ChartInfo.functions.getAllChartDetails() != undefined) {
			_.each(ChartInfo.functions.getAllChartDetails(), function (i, j) {
				sessionStorage.removeItem("drilldown" + j);
			});
		}
		var excludeFiltersData = ChartInfo.functions.getExcludeFilterData();
		var filterArray = [];
		var drillDown = JSON.parse(sessionStorage.getItem("drilldown" + currentChartNumber));
		var customFields = JSON.parse(sessionStorage.getItem("customFields"));
		var temp = customFields;
		var res;
		_.each(temp, function (customfields) {
			_.each(customfields.parametrFieldsArray, function (value) {
				var customparameter = ChartInfo.functions.getCustomParameter();
				var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters('applyattributes');
				if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
					customparameter = customParameterFilterArray;
				}
				res = $.grep(customparameter, function (e) {
					return e.parametername == value;
				})
				var formula = customfields.formula;
				var scriptcondition = customfields.scriptcondition;
				var fields = customfields.fields;
				var sqlformula = customfields.sqlformula;
				if (res.length != 0) {
					customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
				} else {
					_.each(ChartInfo.functions.getCustomParameter(), function (values) {
						if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
							customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
							customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
						}
					});
				}
			});
		});
		customFields = temp;
		_.each(excludeFiltersData, function (data, j) {
			var columnDisplayName;
			var columnName = '';
			if (data.dataType == "date") {
				columnDisplayName = data.columndisplayname;
			}
			else {
				columnDisplayName = data.columndisplayname;
			}
			if (customFields == undefined || customFields == null) {
				var InstanceFilterData = ChartInfo.functions.getInstanceIncludeFilterData();
				var instantIncludeFilters = ""
				data["columndisplayname"] = data.columnname;
				var instantIncludeFilters = "";
				columnName = data.data;
				var filterdata = [];
				if (data.dataType == "date") {
					if (data.data.length > 0) {
						var newDate = data.data;//Date.parse(data.data.replace(/-/g,'/'));
						if (isNaN(newDate)) {
							data.data = data.data;
						}
						else {
							data.data = newDate;//new Date(newDate).getTime();
						}
						data["data"] = data.data;
					}
				}
				filterdata.push(data);
				var includeInstanceFilterData = ChartInfo.functions.getInstanceIncludeFilterData(excludeFiltersData);
				if (includeInstanceFilterData != undefined) {
					_.each(includeInstanceFilterData, function (filterdata, j) {
						if (filterdata != undefined) {
							filterArray.push(filterdata);
						}
					})
				}
				ChartInfo.functions.setInstanceIncludeFilterData(filterArray);
				instantIncludeFilters = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
				if (instantIncludeFilters != null && instantIncludeFilters != "null") {
					if (instantIncludeFilters.length != 0) {
						instantIncludeFilters.push(data);
					} else {
						instantIncludeFilters.push(data);
					}
					sessionStorage.setItem("instantIncludeFilters", JSON.stringify(instantIncludeFilters));
					ChartInfo.functions.setInstanceIncludeFilterData(instantIncludeFilters);
				} else {
					sessionStorage.setItem("instantIncludeFilters", JSON.stringify(filterdata));
					ChartInfo.functions.setInstanceIncludeFilterData(filterdata);
				}

			} else {
				_.each(customFields, function (customFieldData, j) {
					if (!that.iscustomfield(customFields, data.columnname)) {
						var filterdata = [];
						var filterObject = {};
						filterObject["dataType"] = data.dataType;
						filterObject["tablename"] = "";
						filterObject["columndisplayname"] = data.columndisplayname;
						filterObject["columnname"] = data.columnname;
						filterObject["isdrilldownfilter"] = data.isdrilldownfilter;
						var value = data.data;
						if (filterObject["dataType"] == "date") {
							if (value.length > 0) {
								var newDate = value;//Date.parse(value.replace(/-/g,'/'));
								if (isNaN(newDate)) {
									//var newdate1=moment(myDate,dateFormat);
									value = value;
								}
								else {
									value = newDate;//new Date(newDate).getTime();
								}
							}
						}
						filterObject["data"] = value;
						filterdata.push(filterObject);
						var includeInstanceFilterData = ChartInfo.functions.getInstanceIncludeFilterData(excludeFiltersData);
						if (includeInstanceFilterData != undefined) {
							_.each(includeInstanceFilterData, function (filterdata, j) {
								if (filterdata != undefined) {
									filterArray.push(filterdata);
								}
							})
						}
						var isexists = false;
						ChartInfo.functions.setInstanceIncludeFilterData(filterArray);
						instantIncludeFilters = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
						if (instantIncludeFilters != null && instantIncludeFilters != "null") {
							if (instantIncludeFilters.length != 0) {
								_.each(instantIncludeFilters, function (existingdata) {
									if (data["data"] == existingdata["data"]) {
										isexists = true
									}

								});

								if (!isexists) {
									instantIncludeFilters.push(data)
								}
							}
							else {
								instantIncludeFilters.push(data);
							}
							sessionStorage.setItem("instantIncludeFilters", JSON.stringify(instantIncludeFilters));
							ChartInfo.functions.setInstanceIncludeFilterData(instantIncludeFilters);
						} else {
							sessionStorage.setItem("instantIncludeFilters", JSON.stringify(filterdata));
							ChartInfo.functions.setInstanceIncludeFilterData(filterdata);
						}
					}
					if (customFieldData.name == data.columnname) {
						var includeInstanceFilterData = ChartInfo.functions.getInstanceIncludeFilterData(excludeFiltersData);
						var filterObject = {};
						var filterdata = [];
						var customObject;
						columnName = data.data;
						filterObject["dataType"] = data.dataType;
						filterObject["tablename"] = $($this).attr("data-parent");
						filterObject["columndisplayname"] = data.columnname;
						filterObject["columnname"] = data.columnname;
						filterObject["isdrilldownfilter"] = data.isdrilldownfilter;
						var sqlFilterObject = {};
						sqlFilterObject["sqldata"] = data.data;
						customFieldData["sqlcondition"] = sqlFilterObject;
						customObject = customFieldData;
						customObject["parameter"] = data.data;
						filterObject["customfield"] = customObject;
						if (data.dataType == "date") {
							if (data.data.length > 0) {
								var newDate = data.data;
								if (isNaN(newDate)) {
									data.data = data.data;
								}
								else {
									data.data = newDate;//new Date(newDate).getTime();
								}
							}
						}
						filterObject["data"] = data.data;
						filterObject["dataType"] = data.dataType;

						var includeInstanceFilterData = ChartInfo.functions.getInstanceIncludeFilterData(excludeFiltersData);
						if (includeInstanceFilterData != undefined) {
							_.each(includeInstanceFilterData, function (filterdata, j) {
								if (filterdata != undefined) {
									filterArray.push(filterdata);
								}
							})
						}
						if (includeInstanceFilterData != undefined && includeInstanceFilterData.length == 0) {
							filterArray.push(filterObject);
							filterdata.push(filterObject);
						}
						var cfDataIsexists = false;
						ChartInfo.functions.setInstanceIncludeFilterData(filterArray);
						instantIncludeFilters = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
						if (instantIncludeFilters != null && instantIncludeFilters != "null" && instantIncludeFilters.length > 0) {
							if (instantIncludeFilters.length != 0) {
								_.each(instantIncludeFilters, function (existingdata) {
									if (data["data"] == existingdata["data"]) {
										cfDataIsexists = true;
									}
								});
								if (!cfDataIsexists) {
									instantIncludeFilters.push(data);
								}
							}
							else {
								instantIncludeFilters.push(data);
							}
							sessionStorage.setItem("instantIncludeFilters", JSON.stringify(instantIncludeFilters));
							ChartInfo.functions.setInstanceIncludeFilterData(instantIncludeFilters);
						} else {
							sessionStorage.setItem("instantIncludeFilters", JSON.stringify(filterdata));
							ChartInfo.functions.setInstanceIncludeFilterData(filterdata);
						}
					}
					else {
						if (customFields != null && customFields != "null") {
							_.each(excludeFiltersData, function (excludeFiltersData1) {
								var includeInstanceFilterData = ChartInfo.functions.getInstanceIncludeFilterData();
								var instantIncludeFilters;
								var filterObject = {};
								var filterdata = [];
								filterObject["dataType"] = "string";
								if (excludeFiltersData1.dataType != undefined && excludeFiltersData1.dataType.length > 0 && (excludeFiltersData1.dataType == "date" || excludeFiltersData1.dataType == "number")) {
									filterObject["dataType"] = excludeFiltersData1.dataType;
								}
								if (customFields != undefined && customFields != null && customFields != "null") {
									var customObject = {};
									_.each(customFields, function (customfield) {
										if (customfield.name == excludeFiltersData1.columnname) {
											var sqlFilterObject = {};
											sqlFilterObject["sqldata"] = excludeFiltersData1.data;
											customfield["sqlcondition"] = sqlFilterObject;
											customObject = customfield;
											filterObject["tablename"] = "";
											filterObject["columndisplayname"] = excludeFiltersData1.columndisplayname;
											filterObject["columnname"] = excludeFiltersData1.columnname;
											customObject["parameter"] = excludeFiltersData1.data;
											filterObject["customfield"] = customObject;
											filterObject["data"] = excludeFiltersData1.data;
											filterObject["dataType"] = excludeFiltersData1.dataType;
											filterObject["isdrilldownfilter"] = excludeFiltersData1.isdrilldownfilter;
											var scriptFilterCondition = customObject['scriptcondition'];
											var finalScriptCondition = ";" + "if(";
											if (filterObject["dataType"] == "string") {
												finalScriptCondition += "(" + scriptFilterCondition + ").toString().equals('" + excludeFiltersData[0].data + "')";
												customObject['scriptcondition'] = finalScriptCondition;
												finalScriptCondition += ") return true else false";
												customObject['scriptcondition'] = finalScriptCondition;
												var filterdataExists = false;
												if (filterdata.length != 0) {
													_.each(filterdata, function (existingdata) {
														if (filterObject["data"] == existingdata["data"]) {
															filterdataExists = true;
														}
													});
													if (!filterdataExists) {
														instantIncludeFilters.push(data);
													}
												}
												else {
													filterdata.push(filterObject);
												}
												//filterdata.push(filterObject);
												var cfDataIsexists = false;
												instantIncludeFilters = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
												if (instantIncludeFilters != null && instantIncludeFilters != "null") {
													if (instantIncludeFilters.length != 0) {
														_.each(instantIncludeFilters, function (existingdata) {
															if (data["data"] == existingdata["data"]) {
																cfDataIsexists = true;
															}
														});
														if (!cfDataIsexists) {
															instantIncludeFilters.push(data);
														}
													}
													else {
														instantIncludeFilters.push(data);
													}
													sessionStorage.setItem("instantIncludeFilters", JSON.stringify(instantIncludeFilters));
													ChartInfo.functions.setInstanceFilterData(instantIncludeFilters);
												} else {
													sessionStorage.setItem("instantIncludeFilters", JSON.stringify(filterdata));
													ChartInfo.functions.setInstanceFilterData(filterdata);
												}
											}
											if (filterObject["dataType"] == "date") {
												$.each(excludeFiltersData, function (index, value) {
													if (index == 0) {
														finalScriptCondition += "(" + scriptFilterCondition + ").toString().equals('" + value.data + "')";
													} else {
														finalScriptCondition += " || " + "(" + scriptFilterCondition + ").toString().equals('" + value.data + "')";
													}
												});
												$.each(ChartInfo.functions.getInstanceIncludeFilterData(), function (index, value) {
													if (value != undefined)
														finalScriptCondition += " || " + "(" + scriptFilterCondition + ").toString().equals('" + value.data + "')";
												});
												finalScriptCondition += ") return true else false";
												customObject['scriptcondition'] = finalScriptCondition;
												/*if(filterObject["dataType"] != undefined && filterObject["dataType"].length > 0 && ChartInfo.functions.getIsDirect() == 1 
														&& (filterObject["dataType"]== "date" || filterObject["dataType"] == "customrange")){
												}else if(ChartInfo.functions.getIsDirect() == 1){
													filterObject["dataType"] = "string";
												}*/
												var filterdataExists = false;
												if (filterdata.length != 0) {
													_.each(filterdata, function (existingdata) {
														if (filterObject["data"] == existingdata["data"]) {
															filterdataExists = true;
														}
													});
													if (!filterdataExists) {
														instantIncludeFilters.push(data);
													}
												}
												else {
													filterdata.push(filterObject);
												}
												//filterdata.push(filterObject);
												var cfDataIsexists = false;
												var instantIncludeFilters = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
												if (instantIncludeFilters != null && instantIncludeFilters != "null") {
													if (instantIncludeFilters.length != 0) {
														_.each(instantIncludeFilters, function (existingdata) {
															if (data["data"] == existingdata["data"]) {
																cfDataIsexists = true;
															}
														});
														if (!cfDataIsexists) {
															instantIncludeFilters.push(data);
														}
													}
													else {
														instantIncludeFilters.push(data);
													}
													sessionStorage.setItem("instantIncludeFilters", JSON.stringify(instantIncludeFilters));
													ChartInfo.functions.setInstanceIncludeFilterData(instantIncludeFilters);
												} else {
													sessionStorage.setItem("instantIncludeFilters", JSON.stringify(filterdata));
													ChartInfo.functions.setInstanceIncludeFilterData(filterdata);
												}
											}
											if (filterObject["dataType"] == "number") {
												$.each(excludeFiltersData, function (index, value) {
													if (index == 0) {
														finalScriptCondition += "(" + scriptFilterCondition + ").toString().equals('" + value.data + "')";
													} else {
														finalScriptCondition += " || " + "(" + scriptFilterCondition + ").toString().equals('" + value.data + "')";
													}
												});
												$.each(ChartInfo.functions.getInstanceIncludeFilterData(), function (index, value) {
													if (value != undefined)
														finalScriptCondition += " || " + "(" + scriptFilterCondition + ").toString().equals('" + value.data + "')";
												});
												finalScriptCondition += ") return true else false";
												customObject['scriptcondition'] = finalScriptCondition;
												/*if(filterObject["dataType"] != undefined && filterObject["dataType"].length > 0 && ChartInfo.functions.getIsDirect() == 1 
														&& (filterObject["dataType"]== "date" || filterObject["dataType"] == "customrange")){
												}else if(ChartInfo.functions.getIsDirect() == 1){
													filterObject["dataType"] = "string";
												}*/
												var filterdataExists = false;
												if (filterdata.length != 0) {
													_.each(filterdata, function (existingdata) {
														if (filterObject["data"] == existingdata["data"]) {
															filterdataExists = true;
														}
													});
													if (!filterdataExists) {
														instantIncludeFilters.push(data);
													}
												}
												else {
													filterdata.push(filterObject);
												}
												//filterdata.push(filterObject);
												var cfDataIsexists = false;
												var instantIncludeFilters = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
												if (instantIncludeFilters != null && instantIncludeFilters != "null") {
													if (instantIncludeFilters.length != 0) {
														_.each(instantIncludeFilters, function (existingdata) {
															if (data["data"] == existingdata["data"]) {
																cfDataIsexists = true;
															}
														});
														if (!cfDataIsexists) {
															instantIncludeFilters.push(data);
														}
													}
													else {
														instantIncludeFilters.push(data);
													}
													sessionStorage.setItem("instantIncludeFilters", JSON.stringify(instantIncludeFilters));
													ChartInfo.functions.setInstanceIncludeFilterData(instantIncludeFilters);
												} else {
													sessionStorage.setItem("instantIncludeFilters", JSON.stringify(filterdata));
													ChartInfo.functions.setInstanceIncludeFilterData(filterdata);
												}
											}
										} else {
											filterObject["tablename"] = "";
											filterObject["columndisplayname"] = excludeFiltersData1.columnname;
											filterObject["columnname"] = excludeFiltersData1.columnname;
											var value = excludeFiltersData1.data;
											if (filterObject["dataType"] == "date") {
												if (value.length > 0) {
													var newDate = value;//Date.parse(value.replace(/-/g,'/'));
													if (isNaN(newDate)) {
														value = value;
													}
													else {
														value = newDate;//new Date(newDate).getTime();
													}
												}
											}
											var sqlFilterObject = {};
											sqlFilterObject["sqldata"] = excludeFiltersData1.data;
											customObject = customfield;
											customObject["sqlcondition"] = sqlFilterObject;
											filterObject["customfield"] = customObject;
											filterObject["data"] = value;
											/*if(filterObject["dataType"] != undefined && filterObject["dataType"].length > 0 && ChartInfo.functions.getIsDirect() == 1 
													&& (filterObject["dataType"]== "date" || filterObject["dataType"] == "customrange")){
											}else if(ChartInfo.functions.getIsDirect() == 1){
												filterObject["dataType"] = "string";
											}*/
											filterdata.push(filterObject);
											ChartInfo.functions.setInstanceIncludeFilterData(filterdata);
										}
									});
									var filterObject = {};
									var customFilterArray = [];
									columnName = data.data;
									filterObject["dataType"] = excludeFiltersData1.dataType;
									filterObject["tablename"] = $($this).attr("data-parent");
									filterObject["columndisplayname"] = data.columnname;
									filterObject["columnname"] = excludeFiltersData1.columnname;
									filterObject["isdrilldownfilter"] = excludeFiltersData1.isdrilldownfilter;
									if (excludeFiltersData1.dataType == "date") {
										if (excludeFiltersData1.data.length > 0) {
											var newDate = excludeFiltersData1.data;//Date.parse(excludeFiltersData1.data.replace(/-/g,'/'));
											if (isNaN(newDate)) {
												excludeFiltersData1.data = excludeFiltersData1.data;
											}
											else {
												excludeFiltersData1.data = newDate;//new Date(newDate).getTime();
											}
										}
									}
									filterObject["data"] = excludeFiltersData1.data;
									/*if(filterObject["dataType"] != undefined && filterObject["dataType"].length > 0 && ChartInfo.functions.getIsDirect() == 1 
											&& (filterObject["dataType"]== "date" || filterObject["dataType"] == "customrange")){
									}else if(ChartInfo.functions.getIsDirect() == 1){
										filterObject["dataType"] = "string";
									}*/
									customFilterArray.push(filterObject);
									ChartInfo.functions.setInstanceIncludeFilterData(customFilterArray);
								} else {
									var Object = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == that.xaxisName });
									if (Object.length > 0) {
										filterObject["tablename"] = "";
										filterObject["columndisplayname"] = Object[0].columnDisplayName;
										filterObject["columnname"] = Object[0].columnName;
										filterObject["dataType"] = Object[0].dataType;
										var value = Object[0].value;
										if (filterObject["dataType"] == "date") {
											if (value.length > 0) {
												var newDate = value;//Date.parse(value.replace(/-/g,'/'));
												if (isNaN(newDate)) {
													value = value;
												}
												else {
													value = newDate;//new Date(newDate).getTime();
												}
											}
										}
										filterObject["data"] = value;
									} else {
										filterObject["tablename"] = "";
										filterObject["columndisplayname"] = excludeFiltersData1.columnname;
										filterObject["columnname"] = excludeFiltersData1.columnname;
										filterObject["isdrilldownfilter"] = excludeFiltersData1.isdrilldownfilter;
										var sqlFilterObject = {};
										sqlFilterObject["sqldata"] = excludeFiltersData1.data;
										// customfield["sqlcondition"] = customfield.sqlformula;
										// customObject=customfield;
										// customObject["parameter"]= value;
										filterObject["customfield"] = customObject;
										var value = excludeFiltersData1.data;
										if (filterObject["dataType"] == "date") {
											if (value.length > 0) {
												var newDate = value;//Date.parse(value.replace(/-/g,'/'));
												if (isNaN(newDate)) {
													value = value;
												}
												else {
													value = newDate;//new Date(newDate).getTime();
												}
											}
										}
										filterObject["data"] = value;
									}
								}
							});
						}
					}
				});
			}
			$('.singlerportfilters').click();
			//	$('#singlreportfiltermodal').modal("show");
			$(".instant-filter-container").removeClass('hide');
			//	$(".instant-filter-container").addClass('show');
			var dataValue = data.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
			$('.addInstanceFilterData').append('<div class="ob closablediv closeexcclinstantincludefilter" data-value="' + dataValue + '"><a class="close deleteinstantincludefilterdata deleteinstantincludefilter" id="deleteinstantincludefilter" data-value="' + dataValue + '"><i class="fa fa-close"></i></a><span id="text"  class="tagtext ke addinstanceincludefilter" title="' + data.data + '" data-value="' + dataValue + '" ></span></div>');
			// that.setState({
			// 	isForAddInstantFilters: true
			// })
			that.componentDidUpdateCopy();
			var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(columnName);
			var appendValue = columnName;
			var ulEle = "";
			if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
				appendValue = breadCrumpElements["span"];
				ulEle = breadCrumpElements["ulelement"];
				$(".addinstanceincludefilter[data-value='" + dataValue + "']").html(columnDisplayName + ' [' + appendValue + ']' + ulEle);
				$(".addinstanceincludefilter[data-value='" + dataValue + "']").attr("title", "");
			} else {
				$(".addinstanceincludefilter[data-value='" + dataValue + "']").text(columnName + ' [' + columnDisplayName + ']');
			}
			$(".deleteinstantincludefilter[data-value='" + dataValue + "']").attr("data-child", data.columnname);
			$(".deleteinstantincludefilter[data-value='" + dataValue + "']").attr("col-name", data.columnname);
			$(".deleteinstantincludefilter[data-value='" + dataValue + "']").attr("data-type", data.dataType);
			$(".deleteinstantincludefilter[data-value='" + dataValue + "']").attr("data-value", dataValue);
			$(".deleteinstantincludefilter[data-value='" + dataValue + "']").attr("data-num", currentChartNumber);
		});
		ChartInfo.functions.getFilters(true);
		PubSub.publish('applyfiltesandredrawchart');
		//this.broker.trigger('applyfiltesandredrawchart', undefined,undefined,undefined,undefined,"yes");
	}

	componentDidUpdateCopy() {
		$(".deleteinstantincludefilterdata").click(function (e) {
			e.preventDefault();
			e.stopPropagation();
			var $this = e.currentTarget;
			var currentChartNumber = $($this).attr("data-num");
			$("#spinnerchart" + currentChartNumber).show();
			$('.pie-menu').empty();
			var excludeFiltersData = ChartInfo.functions.getExcludeFilterData();
			var filterArray = [];
			var drillDown = JSON.parse(sessionStorage.getItem("drilldown" + currentChartNumber));
			var customFields = JSON.parse(sessionStorage.getItem("customFields"));
			var temp = customFields;
			var res;
			_.each(temp, function (customfields) {
				_.each(customfields.parametrFieldsArray, function (value) {
					res = $.grep(ChartInfo.functions.getCustomParameter(), function (e) {
						return e.parametername == value;
					})
					var formula = customfields.formula;
					var scriptcondition = customfields.scriptcondition;
					var fields = customfields.fields;
					var sqlformula = customfields.sqlformula;
					if (res.length != 0) {
						customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
						customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
						customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
						customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
					} else {
						_.each(ChartInfo.functions.getCustomParameter(), function (values) {
							if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
								customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
								customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
								customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
								customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							}
						});
					}
				});
			});
			customFields = temp;
			var InstantFilterData = ChartInfo.functions.getInstanceIncludeFilterData();
			if (customFields != undefined) {
				_.each(customFields, function (customFilterData, i) {
					var retrieveSessionDataArray = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
					_.each(retrieveSessionDataArray, function (data, i) {
						if (data != undefined && data.customfield != undefined) {
							var value = data.customfield.parameter.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
							if (value == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
								delete ChartInfo.functions.getInstanceIncludeFilterData().splice(i, 1);
								InstantFilterData = $.grep(InstantFilterData, function (index, e) {
									return index.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "") != $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
								});
								$(".closedeleteinstantincludefilter[data-value='" + ($($this).attr('data-value') + '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "") + "']").remove();
								$($this).parent().remove();
							}
							var retrieveSessionDataArray = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
							_.each(retrieveSessionDataArray, function (data, j) {
								if (data != undefined && data.customfield != undefined) {
									var data = data.customfield.parameter.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
									if (data == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
										delete retrieveSessionDataArray.splice(j, 1);
										retrieveSessionDataArray = $.grep(retrieveSessionDataArray, function (index, e) {
											return index.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "") != $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
										});
									}
								}
							});
							sessionStorage.instantIncludeFilters = JSON.stringify(retrieveSessionDataArray);
						}
						else {
							if (data != undefined) {
								var value = data.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
								if (value == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
									delete ChartInfo.functions.getInstanceIncludeFilterData().splice(i, 1);
									InstantFilterData = $.grep(InstantFilterData, function (index, e) {
										return index.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "") != $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
									});
									$(".closedeleteinstantincludefilter[data-value='" + ($($this).attr('data-value') + '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "") + "']").remove();
									$($this).parent().remove();
								}
							}
							var retrieveSessionDataArray = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
							_.each(retrieveSessionDataArray, function (data, j) {
								if (data != undefined) {
									var data = data.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
									if (data == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
										delete retrieveSessionDataArray.splice(j, 1);
										retrieveSessionDataArray = $.grep(retrieveSessionDataArray, function (index, e) {
											return index.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "") != $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
										});
									}
								}
							});
							sessionStorage.instantIncludeFilters = JSON.stringify(retrieveSessionDataArray);
						}
					});
					_.each(InstantFilterData, function (data, i) {

						if (data != undefined) {
							var value = data.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
							if (value == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
								delete ChartInfo.functions.getInstanceIncludeFilterData().splice(i, 1);
								InstantFilterData = $.grep(InstantFilterData, function (index, e) {
									return e.data != $($this).attr('data-value')
								});
								$(".closedeleteinstantincludefilter[data-value='" + ($($this).attr('data-value') + '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "") + "']").remove();
								$($this).parent().remove();
							}
						}
						var retrieveSessionDataArray = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
						_.each(InstantFilterData, function (data, j) {
							if (data != undefined && data.customfield != undefined) {
								var data = data.customfield.parameter.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
								if (data == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
									delete retrieveSessionDataArray.splice(j, 1);
									retrieveSessionDataArray = $.grep(retrieveSessionDataArray, function (index, e) {
										return e.data != $($this).attr('data-value')
									});
								}
							}
							else {
								if (data != undefined) {
									var data = data.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
									if (data == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
										delete retrieveSessionDataArray.splice(j, 1);
										retrieveSessionDataArray = $.grep(retrieveSessionDataArray, function (index, e) {
											return e.data != $($this).attr('data-value')
										});
									}
								}
							}
						});
						sessionStorage.instantIncludeFilters = JSON.stringify(retrieveSessionDataArray);

					});

				});

			}
			else {
				_.each(ChartInfo.functions.getInstanceIncludeFilterData(), function (data, i) {
					if (data != undefined) {
						var value = data.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
						if (value == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
							delete ChartInfo.functions.getInstanceIncludeFilterData().splice(i, 1);
							InstantFilterData = $.grep(InstantFilterData, function (index, e) {
								return e.data != $($this).attr('data-value')
							});
							$(".closedeleteinstantincludefilter[data-value='" + ($($this).attr('data-value') + '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "") + "']").remove();
							$($this).parent().remove();
						}
					}
				});
				var retrieveSessionDataArray = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
				_.each(retrieveSessionDataArray, function (data, j) {
					if (data != undefined) {
						var data = data.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
						if (data == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
							delete retrieveSessionDataArray.splice(j, 1);
							retrieveSessionDataArray = $.grep(retrieveSessionDataArray, function (index, e) {
								return e.data != $($this).attr('data-value')
							});
						}
					}
				});
				sessionStorage.instantIncludeFilters = JSON.stringify(retrieveSessionDataArray);
			}
			if ($('.acc-content').length == 0 && $('.addInstanceFilterData').text().trim().length == 0) {
				if ($('.addURLInstanceFilterData').text().trim().length == 0) {
					$('.allscopeslistcontainer').hide();
					$('.singlebadge').remove();
					$('.addInstanceFilterData').empty();
				}
			}
			ChartInfo.functions.getFilters(true);
			var tempfilterArray = [];
			_.each(ChartInfo.functions.getdrillfitlerArray(), function (data, i) {
				if (data != undefined && (data.saved)) {
					tempfilterArray.push(data);
				}
			});
			ChartInfo.functions.setdrillfitlerArray(tempfilterArray);
			//							if($('li').hasClass('showactive')){
			//								this.broker.trigger('applyfiltesandredrawchart', undefined,currentChartNumber,"", undefined, $('li.showactive').attr('id'),"yes");
			//							}
			//							else{
			sessionStorage.removeItem("drilldown" + ChartInfo.functions.getDrillDownChartNumber());
			sessionStorage.removeItem("drilldowndata" + ChartInfo.functions.getDrillDownChartNumber());

			PubSub.publish('applyfiltesandredrawchart');
			//	this.broker.trigger('applyfiltesandredrawchart', undefined, undefined, undefined, undefined, "yes");
			//}
			if ($('li').hasClass('showactive') == "false") {
				sessionStorage.removeItem("drilldown" + currentChartNumber);
				var applyObj = {};
				applyObj["pos"] = undefined;
				applyObj["currentchartnum"] = currentChartNumber;
				applyObj["applyattributes"] = "";
				applyObj["dateHistogramInterval"] = undefined;
				applyObj["calculation"] = $('li.showactive').attr('id');
				applyObj["isExcludeorIncludeFilters"] = "yes";
				applyObj["isChartSummaryData"] = undefined;
				applyObj["selecteddobycolumns"] = undefined;
				applyObj["filterArray"] = undefined;
				applyObj["navigatefrom"] = undefined;
				applyObj["isCustomparameterFilter"] = undefined;
				applyObj["tableInfo"] = undefined;
				PubSub.publish('applyfiltesandredrawchart', applyObj);
				//this.broker.trigger('applyfiltesandredrawchart', undefined, currentChartNumber, "", undefined, $('li.showactive').attr('id'), "yes");
				$('.trendtype[data-num=' + currentChartNumber + ']').removeClass("showactive");
			}
			var data = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber))
			var legendfilter = [];
			ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
			ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
			sessionStorage.removeItem("drilldown" + currentChartNumber);
			var that = this;
			_.each(ChartInfo.functions.getAllChartDetails(), function (i, j) {
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
				$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").attr("drilled-chart", j);
				var chartDetails = JSON.parse(i);
				var drillDownAllFilter = chartDetails['drillDownAllFilter'];
				_.each(drillDownAllFilter, function (i, j) {
					var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
					if (name === null) {
						var name = decodeURIComponent(drillDownAllFilter[j].name);
					}
					else if (name == "undefined") {
						name = decodeURIComponent(drillDownAllFilter[j].displayName);
						if (drillDownAllFilter[j].customfield != undefined) {
							name = drillDownAllFilter[j].customfield.name;
						}
					}
					if (drillDownAllFilter[j].type == "date") {
						name = $(name).find('li:first').attr('column-display-name');
						if (name === undefined) {
							name = drillDownAllFilter[j].columndisplayname;
						}
					}
					var today = new Date();
					var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
					dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
					ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
					$("#" + dynamicdiv).remove();
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
				});
				sessionStorage.removeItem("drilldown" + j);
				sessionStorage.removeItem("drilldowndata" + j);
			});
			$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>");
			$('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) });
			$(".breadcrumb[data-num=" + currentChartNumber + "]").remove();
			$("#chart" + currentChartNumber + "").before("<ul class='breadcrumb' data-num=" + currentChartNumber + "></ul>");
			if (currentChartNumber != undefined) {
				if (data["drillDownFilter"] != undefined) {
					var breadCrumbElement = $('.breadcrumb[data-num=' + currentChartNumber + ']')
					_.each(data["drillDownFilter"], function (value, index) {
						var appendValue = value.data;
						var ulEle = "";
						var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
						if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
							appendValue = breadCrumpElements["span"];
							ulEle = breadCrumpElements["ulelement"];
						}
						if (value["saved"] == "true") {
							breadCrumbElement.append("<li class=drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name='" + value.data + "' class='close removedrilldownsinglereport' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
						}
					});
				}
			}
			$(".pie-menu").remove();
			$(".fullscreensample").removeClass("nopie");
		});
		$(".deleteinstantfilterdata").click(function (e) {
			e.preventDefault();
			e.stopPropagation();
			var $this = e.currentTarget;
			var currentChartNumber = $($this).attr("data-num");
			$("#spinnerchart" + currentChartNumber).show();
			$('.pie-menu').empty();
			var excludeFiltersData = ChartInfo.functions.getExcludeFilterData();
			var drillDown = JSON.parse(sessionStorage.getItem("drilldown" + currentChartNumber));
			var customFields = JSON.parse(sessionStorage.getItem("customFields"));
			var temp = customFields;
			var res;
			_.each(temp, function (customfields) {
				_.each(customfields.parametrFieldsArray, function (value) {
					res = $.grep(ChartInfo.functions.getCustomParameter(), function (e) {
						return e.parametername == value;
					})
					var formula = customfields.formula;
					var scriptcondition = customfields.scriptcondition;
					var fields = customfields.fields;
					var sqlformula = customfields.sqlformula;
					if (res.length != 0) {
						customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
						customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
						customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
						customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
					} else {
						_.each(ChartInfo.functions.getCustomParameter(), function (values) {
							if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
								customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
								customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
								customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
								customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							}
						});
					}
				});
			});
			customFields = temp;
			var InstantFilterData = ChartInfo.functions.getInstanceFilterData();
			if (customFields != undefined) {
				_.each(customFields, function (customFilterData, i) {
					var retrieveSessionDataArray = JSON.parse(sessionStorage.getItem("instantExcludeCustomFilters"));
					_.each(retrieveSessionDataArray, function (data, i) {
						if (data != undefined && data.customfield != undefined) {
							$(".closedeleteinstantfilter[data-value='" + ($($this).attr('data-value') + '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "") + "']").remove();
							$($this).parent().remove();
							var retrieveSessionDataArray = JSON.parse(sessionStorage.getItem("instantExcludeCustomFilters"));
							_.each(retrieveSessionDataArray, function (data, j) {
								if (data != undefined) {
									var value = data.customfield.parameter.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
									if (value == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
										delete retrieveSessionDataArray.splice(j, 1);
										retrieveSessionDataArray = $.grep(retrieveSessionDataArray, function (index, e) {
											return e.data != $($this).attr('data-value')
										});
									}
								}
							});
							sessionStorage.instantExcludeCustomFilters = JSON.stringify(retrieveSessionDataArray);
						}
					});
					var retrieveSessionDataArrays = JSON.parse(sessionStorage.getItem("instantExcludeFilters"));
					_.each(retrieveSessionDataArrays, function (data, i) {
						if (data != undefined) {
							var value = data.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
							if (value == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
								delete ChartInfo.functions.getInstanceFilterData().splice(i, 1);
								InstantFilterData = $.grep(InstantFilterData, function (index, e) {
									return e.data != $($this).attr('data-value')
								});
								$(".closedeleteinstantfilter[data-value='" + ($($this).attr('data-value') + '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "") + "']").remove();
								$($this).parent().remove();
							}
						}
						var retrieveSessionDataArray = JSON.parse(sessionStorage.getItem("instantExcludeFilters"));
						_.each(retrieveSessionDataArray, function (data, j) {
							if (data != undefined) {
								var data = data.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
								if (data == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
									delete retrieveSessionDataArray.splice(j, 1);
									retrieveSessionDataArray = $.grep(retrieveSessionDataArray, function (index, e) {
										return e.data != $($this).attr('data-value')
									});
								}
							}
						});
						sessionStorage.instantExcludeFilters = JSON.stringify(retrieveSessionDataArray);
					});


				});

			}
			else {
				_.each(ChartInfo.functions.getInstanceFilterData(), function (data, i) {
					if (data != undefined) {
						var value = data.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
						if (value == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
							delete ChartInfo.functions.getInstanceFilterData().splice(i, 1);
							InstantFilterData = $.grep(InstantFilterData, function (index, e) {
								return e.data != $($this).attr('data-value')
							});
							$(".closedeleteinstantfilter[data-value='" + $($this).attr('data-value') + "']").remove();
							$($this).parent().remove();
						}
					}
				});
				var retrieveSessionDataArray = JSON.parse(sessionStorage.getItem("instantExcludeFilters"));
				_.each(retrieveSessionDataArray, function (data, j) {
					if (data != undefined) {
						var data = data.data.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
						if (data == $($this).attr('data-value').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")) {
							delete retrieveSessionDataArray.splice(j, 1);
							retrieveSessionDataArray = $.grep(retrieveSessionDataArray, function (index, e) {
								return e.data != $($this).attr('data-value')
							});
						}
					}
				});
				sessionStorage.instantExcludeFilters = JSON.stringify(retrieveSessionDataArray);
			}
			if ($('.acc-content').length == 0 && $('.addInstanceFilterData').text().trim().length == 0) {
				if ($('.addURLInstanceFilterData').text().trim().length == 0) {
					$('.allscopeslistcontainer').hide();
					$('.singlebadge').remove();
					$('.addInstanceFilterData').empty();
				}
			}
			ChartInfo.functions.getFilters(true);
			var tempfilterArray = [];
			_.each(ChartInfo.functions.getdrillfitlerArray(), function (data, i) {
				if (data != undefined && (data.saved)) {
					tempfilterArray.push(data);
				}
			});
			ChartInfo.functions.setdrillfitlerArray(tempfilterArray);
			var legendfilter = [];
			ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
			ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
			sessionStorage.removeItem("drilldown" + currentChartNumber);
			//							if($('li.trendtype').hasClass('showactive')){
			//								this.broker.trigger('applyfiltesandredrawchart', undefined,undefined,"", undefined, undefined,"yes");
			//							}
			//							else{
			PubSub.publish('applyfiltesandredrawchart');

			//			this.broker.trigger('applyfiltesandredrawchart', undefined,undefined,undefined,undefined,"yes");
			//							}
			if ($('li').hasClass('showactive') == "false") {
				var applyObj = {};
				applyObj["pos"] = undefined;
				applyObj["currentchartnum"] = undefined;
				applyObj["applyattributes"] = "";
				applyObj["dateHistogramInterval"] = undefined;
				applyObj["calculation"] = $('li.showactive').attr('id');
				applyObj["isExcludeorIncludeFilters"] = "yes";
				applyObj["isChartSummaryData"] = undefined;
				applyObj["selecteddobycolumns"] = undefined;
				applyObj["filterArray"] = [];
				applyObj["navigatefrom"] = "drilldownview";
				applyObj["isCustomparameterFilter"] = undefined;
				applyObj["tableInfo"] = undefined;
				PubSub.publish('applyfiltesandredrawchart', applyObj);

				//	this.broker.trigger('applyfiltesandredrawchart', undefined,undefined,"", undefined, $('li.showactive').attr('id'),"yes");
				$('.trendtype[data-num=' + currentChartNumber + ']').removeClass("showactive");
			}

			var that = this;
			_.each(ChartInfo.functions.getAllChartDetails(), function (i, j) {
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
				$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").attr("drilled-chart", j);
				var chartDetails = JSON.parse(i);
				var drillDownAllFilter = chartDetails['drillDownAllFilter'];
				_.each(drillDownAllFilter, function (i, j) {
					var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
					if (name === null) {
						var name = decodeURIComponent(drillDownAllFilter[j].name);
					}
					else if (name == "undefined") {
						name = decodeURIComponent(drillDownAllFilter[j].displayName);
						if (drillDownAllFilter[j].customfield != undefined) {
							name = drillDownAllFilter[j].customfield.name;
						}
					}
					if (drillDownAllFilter[j].type == "date") {
						name = $(name).find('li:first').attr('column-display-name');
						if (name === undefined) {
							name = drillDownAllFilter[j].columndisplayname;
						}
					}
					var today = new Date();
					var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
					dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
					ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
					$("#" + dynamicdiv).remove();
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
				});
				sessionStorage.removeItem("drilldown" + j);
				sessionStorage.removeItem("drilldowndata" + j);
			});

			var data = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber))
			$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>");
			$('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) });
			$(".breadcrumb[data-num=" + currentChartNumber + "]").remove();
			$("#chart" + currentChartNumber + "").before("<ul class='breadcrumb' data-num=" + currentChartNumber + "></ul>");
			if (currentChartNumber != undefined) {
				if (data["drillDownFilter"] != undefined) {
					var breadCrumbElement = $('.breadcrumb[data-num=' + currentChartNumber + ']')
					_.each(data["drillDownFilter"], function (value, index) {
						var appendValue = value.data;
						var ulEle = "";
						var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
						if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
							appendValue = breadCrumpElements["span"];
							ulEle = breadCrumpElements["ulelement"];
						}
						if (value["saved"] == "true") {
							breadCrumbElement.append("<li class=drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name='" + value.data + "' class='close removedrilldownsinglereport' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
						}
					});
				}
			}
			//							 $(".quickfiltercontainer[tab-id="+TabInfo.functions.getCurrentTabId()+"]").empty();
			//								$(".breadcrumb[data-num="+currentChartNumber+"]").remove();	
			$(".pie-menu").remove();
			$(".fullscreensample").removeClass("nopie");
		});
		var that = this;
		$(".drillmoreitemslist").click(function (e) {
			that.drillmoreitemsEvent(e);
		});
	}
	drillmoreitemsEvent = (e) => {
		e.stopPropagation();
		var $this = e.currentTarget;
		var top = e.pageY + 14;
		if (top < 100) { top = 100; }
		var left = e.pageX - 10;
		$("body>.quickfilterslist, body>span.quickfiltermask").remove();
		var clonedEle = $($this).parent().find(".quickfilterslist").clone();
		$("body").append(clonedEle.removeClass("hide").css({ top: top, left: left })).append("<span class='quickfiltermask'></span>");
		$("body span.quickfiltermask").on("click", function (e) {
			$("body>.quickfilterslist, body>span.quickfiltermask").remove();
		});
	}
	newcharttile() {
		$(".spinner").show();
		var that = this;
		this.state.newgrids = [];
		var chartnumber = Object.keys(ChartInfo.functions.getAllChartDetails())[0];
		var filtersArray = JSON.parse(JSON.stringify(ChartInfo.functions.getFilters(true)));
		if (ChartInfo.functions.getdrillfitlerArray() != undefined && ChartInfo.functions.getdrillfitlerArray() != "") {
			filtersArray = filtersArray.concat(ChartInfo.functions.getdrillfitlerArray());
		}
		var newChartnumber = ChartInfo.functions.getNewChartNumber();
		ChartInfo.functions.updateChartDetails(newChartnumber, ChartInfo.functions.getChartDetails(chartnumber));
		ChartInfo.functions.setConditionalFormatData(newChartnumber, ChartInfo.functions.getConditionalFormatData(chartnumber));
		var referencelineobj = ChartInfo.functions.getreferenceLineValues(chartnumber);
		ChartInfo.functions.setreferenceLineValues(newChartnumber, referencelineobj);
		var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
		var child = collection.getgriddetails(chartnumber);
		child["x"] = $(".maxanim1[data-num='" + chartnumber + "']").attr("data-gs-x");
		child["y"] = $(".maxanim1[data-num='" + chartnumber + "']").attr("data-gs-y");
		child["width"] = $(".maxanim1[data-num='" + chartnumber + "']").attr("data-gs-width");
		child["height"] = $(".maxanim1[data-num='" + chartnumber + "']").attr("data-gs-height");
		that.state.newgrids.push(<div className="tileview col-xs-12 maxanim1 grid-stack-item" data-gs-y={child.y} data-gs-x={child.x} data-gs-width={child.width} data-gs-height={child.height} data-gs-auto-position={1} data-num={newChartnumber} tab-id="0" id={`tileview${newChartnumber}`}>
			<ReportGridHtml chartid={newChartnumber} chartitle={that.state.charttitle} multifact={that.state.multifact}
				chartnumber={newChartnumber} current_user_role="ROLE_Power_User" isEmbeddedReport={that.state.isEmbeddedReport} allfeatures={that.state.allfeatures}
				isSharedReport={that.state.isSharedReport} isScheduleReport={that.state.isScheduleReport} isViewOnlyFlags={that.state.view_Only_Flag} isPurgedReport={that.state.isPurgedReport} reportId={that.state.reportId}
				isDirect={that.state.isDirect} isStoredProcedure={that.state.isStoredProcedure} chartType={chartDetails["chartType"]}
				schemaType={that.state.schemaType} isMicrosoftEdge={that.state.isMicrosoftEdge} tileinfo={chartDetails} reportcolumns={that.state.reportcolumns}
				isPublished={that.state.isPublished} multifactentities={that.state.multifactentities} facttables={that.state.facttables} datamodelId={that.state.datamodelId} /></div>);


		this.state.grids.push(this.state.newgrids[0]);
		this.state.isFromCloneTile = true;
		if ($(window).width() <= 780) {
			this.state.grid.grid.nodes[this.state.grid.grid.nodes.length - 1].width = parseInt(child.width);
		}
		this.setState({ update: true })
		this.state.grid.addWidget(document.getElementById("tileview" + newChartnumber), child.x, child.y, child.width, child.height, true);
		if ($(window).width() <= 780) {
			$("#tileview" + newChartnumber + "").attr("data-gs-width", child.width);
		}
		$('.datacount[data-num=' + newChartnumber + ']  .publishinfo').text("Dataset Count: " + this.state.datacount);
		var body = {};
		body["chartid"] = newChartnumber;
		body["multifact"] = that.state.multifact;
		body["chartnumber"] = newChartnumber;
		body["current_user_role"] = "ROLE_Power_User";
		body["isEmbeddedReport"] = that.state.isEmbeddedReport;
		body["isSharedReport"] = that.state.isSharedReport;
		body["allfeatures"] = that.state.allfeatures;
		body["isViewOnlyFlags"] = that.state.view_Only_Flag;
		body["reportcolumns"] = that.state.reportcolumns;
		body["tileinfo"] = chartDetails;
		body["multifactentities"] = that.state.multifactentities;
		body["facttables"] = that.state.facttables;
		body["reportId"] = that.state.reportId;
		body["datamodelId"] = that.state.datamodelId;
		body["isPublished"] = ChartInfo.functions.getisPublished();
		var obj = new ReportBody(body);
		obj.fetchReportData();
		if ($("#drilldownallchartsinstoryboard").is(":checked") && $(".fullscreensample.nopie").length > 0) {
			$(".maxanim7[data-num=" + newChartnumber + "] .fullscreensample").addClass("nopie");
		}
		$(".maxanim1[data-num=" + newChartnumber + "] .dropdown").find('#dropdownmorechart').removeClass("disabled");
		$(".spinner").hide();
	}
	cloneTile(msg, chartnumber) {
		$(".spinner").show();
		var that = this;
		this.state.newgrids = [];
		var filtersArray = JSON.parse(JSON.stringify(ChartInfo.functions.getFilters(true)));
		if (ChartInfo.functions.getdrillfitlerArray() != undefined && ChartInfo.functions.getdrillfitlerArray() != "") {
			filtersArray = filtersArray.concat(ChartInfo.functions.getdrillfitlerArray());
		}
		var newChartnumber = ChartInfo.functions.getNewChartNumber();
		ChartInfo.functions.updateChartDetails(newChartnumber, ChartInfo.functions.getChartDetails(chartnumber));
		ChartInfo.functions.setConditionalFormatData(newChartnumber, ChartInfo.functions.getConditionalFormatData(chartnumber));
		var referencelineobj = ChartInfo.functions.getreferenceLineValues(chartnumber);
		ChartInfo.functions.setreferenceLineValues(newChartnumber, referencelineobj);
		var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));

		var tileLevelFilters = ChartInfo.functions.getTileLevelFilters(chartnumber);
		if (tileLevelFilters != undefined) {
			var tileLevelFiltersCopy = [];
			var storyboards = _.clone(tileLevelFilters[0]);
			var tiles = _.clone(tileLevelFilters[1]);
			storyboards.chartid = newChartnumber;
			var storyboardFiltersCopy = storyboards.storyboardFilters;
			var storyboardFilters = [];
			_.each(storyboardFiltersCopy, function (mfvalue, index1) {
				storyboardFilters.push(_.clone(mfvalue));
			});
			_.each(storyboardFilters, function (mfvalue, index1) {
				mfvalue.chartid = newChartnumber;
			});
			storyboards.storyboardFilters = storyboardFilters;
			tiles.chartid = newChartnumber;
			tileLevelFiltersCopy[0] = storyboards;
			tileLevelFiltersCopy[1] = tiles;
			ChartInfo.functions.updateTileLevelFilters(newChartnumber, tileLevelFiltersCopy);
		}

		var child = collection.getgriddetails(chartnumber);
		child["x"] = $(".maxanim1[data-num='" + chartnumber + "']").attr("data-gs-x");
		child["y"] = $(".maxanim1[data-num='" + chartnumber + "']").attr("data-gs-y");
		child["width"] = $(".maxanim1[data-num='" + chartnumber + "']").attr("data-gs-width");
		child["height"] = $(".maxanim1[data-num='" + chartnumber + "']").attr("data-gs-height");
		that.state.newgrids.push(<div className="tileview col-xs-12 maxanim1 grid-stack-item" data-gs-y={child.y} data-gs-x={child.x} data-gs-width={child.width} data-gs-height={child.height} data-gs-auto-position={1} data-num={newChartnumber} tab-id="0" id={`tileview${newChartnumber}`}>
			<ReportGridHtml chartid={newChartnumber} chartitle={that.state.charttitle} multifact={that.state.multifact}
				chartnumber={newChartnumber} current_user_role="ROLE_Power_User" isEmbeddedReport={that.state.isEmbeddedReport} allfeatures={that.state.allfeatures}
				isSharedReport={that.state.isSharedReport} isScheduleReport={that.state.isScheduleReport} isViewOnlyFlags={that.state.view_Only_Flag} isPurgedReport={that.state.isPurgedReport} reportId={that.state.reportId}
				isDirect={that.state.isDirect} isStoredProcedure={that.state.isStoredProcedure} chartType={chartDetails["chartType"]}
				schemaType={that.state.schemaType} isMicrosoftEdge={that.state.isMicrosoftEdge} tileinfo={chartDetails} reportcolumns={that.state.reportcolumns}
				isPublished={that.state.isPublished} multifactentities={that.state.multifactentities} facttables={that.state.facttables} datamodelId={that.state.datamodelId} /></div>);
		this.state.grids.push(this.state.newgrids[0]);
		this.state.isFromCloneTile = true;
		if ($(window).width() <= 780) {
			this.state.grid.grid.nodes[this.state.grid.grid.nodes.length - 1].width = parseInt(child.width);
			var nodeWidths = [];
			_.each(this.state.grid.grid.nodes, function (i) {
				if (i.el.length != 0) {
					nodeWidths.push({ width: i.el.attr("data-gs-width"), chartno: i.el.attr("data-num") })
				} else {
					nodeWidths.push({ width: parseInt(child.width), chartno: newChartnumber })
				}
			});
		}
		this.setState({ update: true })
		this.state.grid.addWidget(document.getElementById("tileview" + newChartnumber), child.x, child.y, child.width, child.height, false);
		$('.datacount[data-num=' + newChartnumber + ']  .publishinfo').text("Dataset Count: " + this.state.datacount);
		if ($(window).width() <= 780) {
			$("#tileview" + newChartnumber + "").attr("data-gs-width", child.width);
			_.each(this.state.grid.grid.nodes, function (i, index) {
				var curnum = i.el.attr("data-num"),
					curwidth = i.width;
				_.each(nodeWidths, function (j) {
					if (j.chartno == curnum) { curwidth = j.width }
				});
				i.width = curwidth;
				$("#tileview" + curnum + "").attr("data-gs-width", curwidth);
			});
		}
		var body = {};
		body["chartid"] = newChartnumber;
		body["multifact"] = that.state.multifact;
		body["chartnumber"] = newChartnumber;
		body["current_user_role"] = "ROLE_Power_User";
		body["isEmbeddedReport"] = that.state.isEmbeddedReport;
		body["isSharedReport"] = that.state.isSharedReport;
		body["allfeatures"] = that.state.allfeatures;
		body["isViewOnlyFlags"] = that.state.view_Only_Flag;
		body["reportcolumns"] = that.state.reportcolumns;
		body["tileinfo"] = chartDetails;
		body["multifactentities"] = that.state.multifactentities;
		body["facttables"] = that.state.facttables;
		body["reportId"] = that.state.reportId;
		body["datamodelId"] = that.state.datamodelId;
		body["isPublished"] = ChartInfo.functions.getisPublished();
		var obj = new ReportBody(body);
		obj.fetchReportData();
		if ($("#drilldownallchartsinstoryboard").is(":checked") && $(".fullscreensample.nopie").length > 0) {
			$(".maxanim7[data-num=" + newChartnumber + "] .fullscreensample").addClass("nopie");
		}
		$(".maxanim1[data-num=" + newChartnumber + "] .dropdown").find('#dropdownmorechart').removeClass("disabled");
		$(".spinner").hide();
	}
	deleteTile(msg, chartnumber) {
		var that = this;
		if ($('button[id="deletegrid"]') != undefined && $('button[id="deletegrid"]').length > 1) {
			$(".deleteyes").unbind("click");
			$(".deleteno").unbind("click");
			$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
			$('.birdmessage-info').attr('data-num', chartnumber);
			$('.birdmessagebuttons').addClass('show');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Warning');
			$('.birdmessage-info').empty().text(getMessage('BirdWarning29'));
			$(".deleteyes").click(function (e) {
				var flag = true;
				if (sessionStorage.getItem("drilldown" + chartnumber) != undefined) {
					var fiterdata = JSON.parse(sessionStorage.getItem("drilldown" + chartnumber));
					_.each(fiterdata, function (data) {
						if (data["isDrillDownAll"] == true) {
							flag = false;
							return;
						}
					});
				}
				_.each($(".tileview[data-num!=" + chartnumber + "]"), function (i) {
					var $this = i
					var chartNumber = $($this).attr("data-num");
					if (chartNumber != chartnumber) {
						if (!flag) {
							if (ChartInfo.functions.getChartDetails(chartnumber) != undefined) {
								$(".pie-menu").remove();
								$(".fullscreensample").removeClass("nopie");
								var applyObj = {};
								applyObj["pos"] = undefined;
								applyObj["currentchartnum"] = chartNumber;
								applyObj["applyattributes"] = undefined;
								applyObj["dateHistogramInterval"] = undefined;
								applyObj["calculation"] = undefined;
								applyObj["isExcludeorIncludeFilters"] = undefined;
								applyObj["isChartSummaryData"] = undefined;
								applyObj["selecteddobycolumns"] = undefined;
								applyObj["filterArray"] = [];
								applyObj["navigatefrom"] = undefined;
								applyObj["isCustomparameterFilter"] = undefined;
								applyObj["tableInfo"] = undefined;
								PubSub.publish('applyfiltesandredrawchart', applyObj);
								//		that.applyfiltesandredrawchart(undefined,chartNumber,undefined,null,undefined,undefined,undefined,"no",[],undefined);
							}
						}
					}
				});
				var legendfilter = [];
				ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
				ChartInfo.functions.setLegendFilterobj(legendfilter, chartnumber);
				sessionStorage.removeItem("drilldown" + chartnumber);
				e.preventDefault();
				var datanum = $('.birdmessage-info').attr('data-num');
				$('.birdmessage-center').removeClass("message-top-in");
				that.state.grid.removeWidget($('.tileview[data-num=' + datanum + ']'));
				ChartInfo.functions.deleteChartDetails(datanum);
				sessionStorage.setItem("changesdone", 1);
			});
			$(".deleteno").click(function () {
				$('.birdmessage-center').removeClass("message-top-in");
			});
		} else if ($('button[id="deletegrid"]').length == 1) {
			$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
			$('.birdmessage-info').attr('data-num', chartnumber);
			$('.birdmessagebuttons').removeClass('show').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Warning');
			$('.birdmessage-info').empty().text(getMessage('BirdWarning114'));
		}
	}
	togglevisualizationsettingsEve(msg, data) {
		$('.spinner').show();
		this.togglevisualizationsettings(data);
	}
	togglevisualizationsettings(e, issummarytable) {
		var $this = e;
		var that = this;
		$($this).addClass("disabled");
		setTimeout(function () { $($this).removeClass("disabled"); }, 1000);
		var chartnumber = $($this).closest('.maxanim1').attr("data-num");
		var chartno = $($this).closest('.maxanim1').attr("data-num");
		var chartDetails = ChartInfo.functions.getChartDetails(chartnumber);
		var colorThemeIndex = chartDetails != undefined ? $.parseJSON(chartDetails)['colorThemeIndex'] : 0;
		if (colorThemeIndex == undefined) {
			colorThemeIndex = 0;
		}
		var currentTile = $($this).closest('.maxanim1');
		var isOpen = $(".rightattributes").hasClass("open");
		$('#spinner' + chartnumber).show();
		
		if (!isOpen) {
			$(".maxanim1[data-num=" + $(e.currentTarget).closest('.maxanim1').attr("data-num") + "]").addClass("highlight");
			//unMountElelements
			if($('ul.dimensions>div').length>0){
				_.each($('ul.dimensions>div'),function(el){
					if(el!=null && el!=undefined){
						ReactDOM.unmountComponentAtNode(el)
					}
				});
				$('ul.dimensions>div').empty();
			}
			if($('ul.measures>div').length>0){
				_.each($('ul.measures>div'),function(el){
					if(el!=null && el!=undefined){
						ReactDOM.unmountComponentAtNode(el)
					}
				});
				$('ul.measures>div').empty();
			}
			if($(".rightattributes.open>div.dynodiv").length!=0){
				ReactDOM.unmountComponentAtNode(document.getElementById($(".rightattributes.open>div.dynodiv").attr("id")));
				$(".rightattributes.open>div.dynodiv").remove();
			}

			$(".rightattributes").addClass("open");
			$(".leftpane").toggleClass("slidetoleft");
			$("body").addClass("rightpanelopen");

			var acd = {};
			if ($(".fullscreenmain").length != 1) {
				acd["element"] = $(".tileview[data-num='"+chartnumber+"']");
				acd["pos"] = "min";
			}else{
				acd["element"] = currentTile;
				acd["pos"] = "max";
			}
						
			PubSub.publish('adjustchartheight', acd);
			
			//Attributes modal
			// if (that.attrModalView != null) {
			// 	that.attrModalView.cleanup;
			// }
			that.attrModalView = new AttrModalView({
				measures: collection.getMeasures(),
				dimensions: collection.getDimensions(),
				chartnumber: $($this).closest('.maxanim1').attr("data-num"),
				colorThemeIndex: colorThemeIndex,
				issummarytable: issummarytable,
				collection: collection,
				features: that.state.allfeatures,
				reportId: that.state.reportId,
				datamodelId: that.state.datamodelId,
				multifact: that.state.multifact,
				facttables: that.state.facttables,
				multifactentities: that.state.multifactentities,
				is_storyboard: "Y"
			});
			//	that.$(".rightattributes.open").html(that.attrModalView.$el);
			that.attrModalView.addContent();
			try {
				// For Metric Chart - Start
				var activechart = $('.charttype.active').attr('id');
				if (activechart == 'metric') {
					$('.mainattibutes .subhead span').first().text("Metric Value");
					//$('.commonattributes').addClass('hide');
					$('.commonattributes>div:not(:first-child)').addClass('hide');
					$('.xaxisattributes').parent().addClass('disabled');
					$('.commonattributes').removeClass('hide');

					$('.metricattributes').removeClass('hide');
					$('.metricattributes').removeClass('hide');
					$('.metricdatefields').removeClass('hide');
					$('.metricprefix').removeClass('hide');
					$('.metricsuffix').removeClass('hide');
				}
				// For Metric Chart - End
				ModalProperties.resize();
				$('.spinner').hide();
				$('#spinner' + chartnumber).hide();
			} catch (e) {
				console.log(e);
				$('.spinner').hide();
				$('#spinner' + chartnumber).hide();
			}
			
			// setTimeout(function () {
			// 	$(".leftpane").toggleClass("slidetoleft");
			// 	$("body").addClass("rightpanelopen");
			// 	setTimeout(function () { $('#spinner' + chartnumber).hide(); }, 1000);
			// }, 0);
			// setTimeout(function () {
			// 	var time = 10
			// 	if ($(".fullscreenmain").length != 1) {
			// 		var acd = {};
			// 	setTimeout(function () {$('#spinner'+chartnumber).hide();}, 1000);
			// 			acd["element"] = $(".tileview[data-num='"+chartnumber+"']");
			// 			acd["pos"] = "min";
			// 			PubSub.publish('adjustchartheight', acd);
			// 		_.each($(".tileview"), function (i, j) {
			// 			setTimeout(function () {
			// 				var acd = {};
			// 				acd["element"] = i;
			// 				acd["pos"] = "min";
			// 			//	PubSub.publish('adjustchartheight', acd);
			// 			}, time)
			// 			time = time + 1000;
			// 		});
			// 	} else {
			// 		var acd = {};
			// 		acd["element"] = currentTile;
			// 		acd["pos"] = "max";
			// 		PubSub.publish('adjustchartheight', acd);
			// 	}
			// }, 0);
			// $(".maxanim1[data-num=" + $(e.currentTarget).closest('.maxanim1').attr("data-num") + "]").addClass("highlight");
			

		} else {
			var currentChart = $.parseJSON(chartDetails)['chartType'];
			if (currentChart != undefined && (currentChart == "bartimeseries" || currentChart == "linetimeseries" || currentChart == "areatimeseries")) {
				$('.highcharts-range-selector.hasDatepicker').css({ width: "1px", height: "1px" });
			}
			$(".leftpane").removeClass("slidetoleft");
			var that = this;
			setTimeout(function () {
				$(".rightattributes").removeClass("open");
				$(".maxanim1").removeClass("highlight");
				$("body").removeClass("rightpanelopen");
				var time = 10
				if ($(".fullscreenmain").length != 1) {
					var acd = {};
						acd["element"] = $(".tileview[data-num='"+chartnumber+"']");
						acd["pos"] = "min";
						PubSub.publish('adjustchartheight', acd);
					_.each($(".tileview"), function (i, j) {
						setTimeout(function () {
							var acd = {};
							acd["element"] = i;
							acd["pos"] = "min";
							//PubSub.publish('adjustchartheight', acd);
						}, time)
						time = time + 10;
					});
				} else {
					var acd = {};
					acd["element"] = currentTile;
					acd["pos"] = "max";
					PubSub.publish('adjustchartheight', acd);
				}
			}, 150);
			//unMountElelements
			if($('ul.dimensions>div').length>0){
				_.each($('ul.dimensions>div'),function(el){
					if(el!=null && el!=undefined){
						ReactDOM.unmountComponentAtNode(el)
					}
				});
				$('ul.dimensions').empty();
			}
			if($('ul.measures>div').length>0){
				_.each($('ul.measures>div'),function(el){
					if(el!=null && el!=undefined){
						ReactDOM.unmountComponentAtNode(el)
					}
				});
				$('ul.measures').empty();
			}
			if($(".rightattributes.open>div.dynodiv").length!=0){
				ReactDOM.unmountComponentAtNode(document.getElementById($(".rightattributes.open>div.dynodiv").attr("id")));
				$(".rightattributes.open>div.dynodiv").remove();
			}
			$('#spinner' + chartnumber).hide();
			$('.spinner').hide();
		}
	}
	iscustomfield(customFields, columnname) {
		var result = false;
		_.each(customFields, function (customFieldData, j) {
			if (customFieldData.name == columnname) {
				result = true;
				return;
			}
		});
		return result;
	}
	filterMenuToggle = () => {
		$("body").toggleClass("filters-hidden");
		//$("#charts").toggleClass("t-66");
	}
	maxfiltersdropdownoldEve() {
		this.showaddfiltermodal();
	}
	showaddfiltermodal = () => {
		$('.trend-stats-container').remove();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		this.viewReportAddFilterView.render();
	}
	rightfiltersapply = (e) => {
		this.customscript();
		if (this.state.tabid != TabInfo.functions.getCurrentTabId()) {
			return;
		}
		if ((window.location.pathname.indexOf("viewsmartinsight") >= 0 || window.location.pathname.indexOf("embeddedsmartinsightreport") >= 0 || window.location.pathname.indexOf("sharesmartinsightreport") >= 0)) {
			PubSub.publish('smartinsightrightfilters', e);
			return;
		}
		if (window.location.pathname.indexOf('viewstorybook') >= 0) {
			var tabData = ChartInfo.functions.getStoryBookTabDetails(TabInfo.functions.getCurrentTabId());
			if (tabData != undefined && tabData.reportType != undefined && tabData.reportType == "smartinsight_storyboard") {
				PubSub.publish('smartinsightrightfilters', e);
				return;
			}
		}

		var globalfilterArray = [];
		var filterAccordianLength = $('#filteraccordion .acc-content').length;
		//var flag = false;
		for (var i = 0; i < filterAccordianLength; i++) {
			var $this = $('#filteraccordion .acc-content').eq(i);
			var fromRangeVal = $($this).find('.irs-min').text();
			var toRangeVal = $($this).find('.irs-max').text();
			var greaterThanValue = $($this).find('#greaterthanvalue').val();
			var lessThanValue = $($this).find('#lessthanvalue').val();
			var equalsValue = $($this).find('#equalvalue').val();
			var inValue = $($this).find('#invalue').val();
			if (equalsValue != "") {
				lessThanValue = equalsValue;
				greaterThanValue = equalsValue;
			}
			$($this).find('.filtergreaterthanvalue.filter-error').addClass("hide");
			$($this).find('.filterlessthanvalue.filter-error').addClass("hide");
			$($this).find('.filterequalsvalue.filter-error').addClass("hide");
			$($this).find('.filterinvalue.filter-error').addClass("hide");
			if (fromRangeVal != undefined && toRangeVal != undefined && $($this).attr('id') != undefined) {
				$('#filteraccordion .acc-content[data-id="' + $($this).attr('id') + '"] .filteredvalues').text(fromRangeVal + " - " + toRangeVal).attr("title", fromRangeVal + " - " + toRangeVal);
			}

			/*if(greaterThanValue != undefined && greaterThanValue !="" &&
				(!(parseFloat(greaterThanValue) >= parseFloat(fromRangeVal)) || !(parseFloat(greaterThanValue) <= parseFloat(toRangeVal)))){
				$($this).find('.filtergreaterthanvalue.filter-error').removeClass("hide").text(getMessage('BirdWarning108'));
				$($this).find('#greaterthanvalue').focus();
				flag = true;
			}
			if(lessThanValue != undefined && lessThanValue !="" &&
				(!(parseFloat(lessThanValue) >= parseFloat(fromRangeVal)) || !(parseFloat(lessThanValue) <= parseFloat(toRangeVal)))){
				$($this).find('.filterlessthanvalue.filter-error').removeClass("hide").text(getMessage('BirdWarning108'));
				$($this).find('#lessthanvalue').focus();
				flag = true;
			}*/
		}
		/*if(flag){
			return false;
		}*/
		sessionStorage.setItem("changesdone", 1);

		var intelligentsearchwindow = $(".is-container").css("display");
		var intelligentsearchvalue = $("#birdintelligentsearch").val();
		if (this.state.isFromStoryBook == undefined || !this.state.isFromStoryBook) {
			$('.gridspinner').show();
		}
		$('.trend-stats-container').remove();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		$('.addInstanceFilterData').empty();
		if ($('#filteraccordion .acc-content').length == 0 && $('.addInstanceFilterData').text().trim().length == 0) {
			$('.instant-floating-filters').hide();
		}
		ChartInfo.functions.setisFilterModalOpen(true);
		sessionStorage.removeItem("instantExcludeFilters");
		sessionStorage.removeItem("instantIncludeFilters");
		sessionStorage.removeItem("instantExcludeCustomFilters");
		ChartInfo.functions.setInstanceIncludeFilterData("");
		ChartInfo.functions.setInstanceFilterData("");
		ChartInfo.functions.setIncludeFilterData("");
		ChartInfo.functions.setInstanceFilterData("");
		$('.showtrending').removeClass('active');
		$('.trendtype').removeClass('showactive');
		var bool = $('.isexcludenulls').is(":checked")
		ChartInfo.functions.setIsExcludeNulls(bool);
		ChartInfo.functions.getFilters(false);
		var charts = ChartInfo.functions.getAllChartDetails();
		_.each(charts, function (val, index) {
			if (val.chartType != "summarytable")
				sessionStorage.removeItem("drilldown" + index);
		});
		if (ChartInfo.functions.getdrillfitlerArray() != undefined && ChartInfo.functions.getdrillfitlerArray().length != 0) {
			_.each(sessionStorage.getItem("drilldown" + this.props.chartnumber), function (value) {
				_.each(ChartInfo.functions.getdrillfitlerArray(), function (value2) {
					if (value2 == value) {
						globalfilterArray.push(value);
					}
				});
			});
		}
		var filterArray = ChartInfo.functions.getFilters(false);
		//Updated Filtered Data in UI
		var filterCustomParametersArray = ChartInfo.functions.getCustomParametersFilters(),
			normalFilters = ChartInfo.functions.getFilters(false),
			totalfilterArray = $.merge($.merge([], normalFilters), filterCustomParametersArray);

		if (totalfilterArray.length != 0) {
			if (totalfilterArray != null && totalfilterArray != undefined && totalfilterArray != "") {
				_.each(totalfilterArray, function (filter, index) {
					if (filter.dataType != undefined) {
						var filtercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
						if (filter.dataType == "string") {
							if (filter.customfield == undefined) {
								var filtereddatatext = filter["data"].replace(/\@@/g, ', ');
							} else {
								var filtereddatatext = filter.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ')
							}
							var TotalFilterItems = $(".filtervalue[data-name='" + filtercolumndisplayname + "']").length;
							var unCheckedFilterItems = $(".filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)").length;
							if (unCheckedFilterItems == 0) {
								$('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("All").attr("title", "All");
							}
							//  else if (TotalFilterItems <= 5 || (unCheckedFilterItems > 5 && TotalFilterItems > 5)) {
							// 	$('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
							// } else if (unCheckedFilterItems <= 5) {
							// 	var unCheckedItems = [];
							// 	_.each($(".filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)"), function (i) {
							// 		unCheckedItems.push($(i).attr("data-value"));
							// 	});
							// 	unCheckedItems = unCheckedItems.join(", ")
							// 	$('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("Excluding : " + unCheckedItems + "").attr("title", "Excluding : " + unCheckedItems + "");
							// }
							else {
								$('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
							} 
						} else if (filter.dataType == "number") {
							var fromRange = filter.fromRange,
								toRange = filter.toRange,
								customRange = filter.customfiltervalue;
							if (fromRange == undefined && toRange == undefined) {
								fromRange = filter.filtergreaterthanvalue;
								toRange = filter.filterlessthanvalue;
							}
							var inValue = "";
							if (filter.invalue != undefined) {
								inValue = filter.invalue;
							}
							var equalsValue = "";
							if (filter.equalvalue != undefined) {
								equalsValue = filter.equalvalue;
							}
							if ((customRange == undefined || customRange === "") && (fromRange != undefined && toRange != undefined) && (equalsValue == "" || equalsValue == undefined) && (inValue == "" || inValue == undefined)) {
								$('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(fromRange + " - " + toRange).attr("title", fromRange + " - " + toRange);
							} else if (inValue != "") {
								$('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(inValue).attr("title", inValue);
							}
							else {
								$('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(fromRange).attr("title", fromRange);
							}
						} else if (filter.dataType == "date") {
							var fromDateRange = filter.fromRange,
								toDateRange = filter.toRange,
								dateRange = filter.daterange;
							var dates = $('#filteraccordion .daterange-center[displayname="' + filter.columndisplayname + '"] span').text();
							//if(customRange== undefined || customRange===""){
							$('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(dates).attr("title", dates);
							//}
						}
					} else if (filter.parametername != undefined && filter.parametername != "") {
						if (filter.valueType == "all" || filter.valueType == "range") {
							$('#filteraccordion .acc-content[data-id="' + filter.parametername + '"] .filteredvalues').text(filter.parametercurrentvalue).attr("title", filter.parametercurrentvalue);
						} else if (filter.valueType == "list") {
							var selectedplistvalue = $('.filtercustomparamerelist[data-id="acc' + filter.parametername + '"] select').val()
							$('#filteraccordion .acc-content[data-id="' + filter.parametername + '"] .filteredvalues').text(selectedplistvalue).attr("title", selectedplistvalue);
						}
					}

					//Update Filter Display Name
					if (filter.filterDisplayName != null && filter.filterDisplayName != undefined) {
						var targetName = filter.columndisplayname;
						if (filter.parametername != undefined && filter.parametername != "") {
							targetName = filter.parametername;
						}
						if (filter.filterDisplayName == "") {
							$("#filteraccordion .acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter.columndisplayname).attr("title", filter.columndisplayname);
							$(".itemname[data-name='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").val(filter.columndisplayname);
						} else {
							$("#filteraccordion .acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter.filterDisplayName).attr("title", filter.filterDisplayName);
						}
					}
				});
			}
		}
		if (filterArray.length != 0) {
			_.each(ChartInfo.functions.getdrillfitlerArray(), function (filterdata, index) {
				if (filterdata != undefined && filterdata["isDrillDownAll"] == true) {
					ChartInfo.functions.setdrillfitlerArray([])
				}
			});
		}
		if (intelligentsearchwindow != "block") {
			PubSub.publish('applyfiltesandredrawchart');

			_.each(charts, function (i, j) {
				var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(j));
				if (chartDetails["drillDownFilter"] != undefined) {
					$("#chart" + j + "").before("<ul class='breadcrumb' data-num=" + j + "></ul>");
					var breadCrumbElement = $('.breadcrumb[data-num=' + j + ']')
					_.each(chartDetails["drillDownFilter"], function (value, index) {
						if (value["saved"] == "true") {
							var appendValue = value.data;
							var ulEle = "";
							var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
							if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
								appendValue = breadCrumpElements["span"];
								ulEle = breadCrumpElements["ulelement"];
							}
							if (value.columnname != undefined) {
								breadCrumbElement.append("<li class=drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name='" + value.data + "' class='close removedrilldown' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
							} else {
								breadCrumbElement.append("<li class=drilldown saved>" + value.displayName + " [" + appendValue + "] <a columnname='" + value.displayName + "'  name='" + value.data + "' class='close removedrilldown' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
							}
						}
					});
				}
			});
			$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
			$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").attr("drilled-chart", this.state.chartnumber);
			var that = this;
			var charts = ChartInfo.functions.getAllChartDetails();
			var chartDetails;
			_.each(charts, function (chart) {
				if (chart != undefined) {
					chartDetails = JSON.parse(chart);
					return;
				}
			});
			this.appendDrillDownAllToChart(chartDetails);
			$(".pie-menu").remove();
			$(".fullscreensample").removeClass("nopie");

		} else {
			$('.gridspinner').hide();
			sessionStorage.setItem("intelligentfilter", 1);
			if (intelligentsearchvalue != "") {
				$(".is-container #clicksearchbutton").click();
			}
		}
		$(".instant-floating-filters.rightpane").removeClass("changesdone");
		$("#rightfiltersapplys").removeClass("btn-highlight");
	}
	updateFiltersEve() {
		this.updateFilters();
	}
	addCustomParameterFilter() {
		this.viewReportAddFilterView.addFilter();
	}
	updateFilters() {
		var element = "#filteraccordion";
		var filterObject = ChartInfo.functions.getFilterArray();
		if ($("#tilefiltersmodal").length > 0) {
			element = "#tilefilterslist";
			var currentTile = $(element).attr("tile-id");
			var tileLevelFilters = ChartInfo.functions.getTileLevelFilters(currentTile);
			if (tileLevelFilters != undefined && tileLevelFilters[1].tileFiltersList != undefined) {
				filterObject = tileLevelFilters[1].tileFiltersList;
			} else {
				filterObject = [];
			}
		}
		var that = this;
		setTimeout(function () { that.updateFilterDisplayName(); }, 0);
		_.each(filterObject, function (filter, index) {
			if (filter != undefined) {
				if (filter.customfield == undefined && filter["data"] != undefined) {
					_.each(filter["data"].split("@@"), function (value, k) {
						if ($(element + " .filtervalue[displayname='" + filter["columndisplayname"] + "'][data-value='" + value.replace(/([;?&,%#+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").is(':checked') == false)
							$(element + " .filtervalue[displayname='" + filter["columndisplayname"] + "'][data-value='" + value.replace(/([;?&,%#+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").prop("checked", true);
					})
				} else if (filter.customfield != undefined && filter.customfield.sqlcondition != undefined &&
					filter.customfield.sqlcondition.sqldata != undefined) {
					var dataList = filter.customfield.sqlcondition.sqldata.split("@@");
					_.each(dataList, function (value, j) {
						if ($(element + " .filtervalue[displayname='" + filter["columndisplayname"] + "'][data-value='" + value.replace(/([;?&,%#+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").is(':checked') == false)
							$(element + " .filtervalue[displayname='" + filter["columndisplayname"] + "'][data-value='" + value.replace(/([;?&,%#+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").prop("checked", true);
					});
				}
			}
		});
	}
	updateFilterDisplayName() {
		// Updated Filtered Data in UI
		var that = this;
		var element = "#filteraccordion";
		var sharedFilters = JSON.parse(collection.getSharedFilters());
		var filterCustomParametersArray = ChartInfo.functions.getCustomParametersFilters();
		var normalFilters = ChartInfo.functions.getFilters(false);
		if ($("#tilefiltersmodal").length > 0) {
			element = "#tilefilterslist";
			var currentTile = $(element).attr("tile-id");
			var tileLevelFilters = ChartInfo.functions.getTileLevelFilters(currentTile);
			if (tileLevelFilters != undefined && tileLevelFilters[1].tileFiltersList != undefined) {
				normalFilters = tileLevelFilters[1].tileFiltersList;
			} else {
				normalFilters = [];
			}
		}
		var totalfilterArray = $.merge($.merge([], normalFilters), filterCustomParametersArray);
		var isSharedReport = false;
		if (window.location.pathname.indexOf("sharereport") >= 0) { isSharedReport = true; }
		var isUpdated = false;
		if (isSharedReport == true && sharedFilters != undefined && sharedFilters != "") {
			_.each(sharedFilters, function (filter, index) {
				_.each(totalfilterArray, function (filter1, index) {
					var sharedfiltercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
					if (filter1.columndisplayname == sharedfiltercolumndisplayname) {
						isUpdated = true;
						if (filter.dataType != undefined) {
							var filtercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
							if (filter.dataType == "string") {
								if (filter.customfield == undefined) {
									var filtereddatatext = filter1["data"].replace(/\@@/g, ', ');
									var sharefiltereddatatext = filter["data"].replace(/\@@/g, ', ');
								} else {
									var filtereddatatext = filter1.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
									var sharefiltereddatatext = filter.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
								}
								var TotalFilterItems = $(element + " .filtervalue[data-name='" + filtercolumndisplayname + "']").length;
								var unCheckedFilterItems = $(element + " .filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)").length;
								if (unCheckedFilterItems == 0) {
									$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("All").attr("title", "All");
								} 
								// else if (TotalFilterItems <= 5 || (unCheckedFilterItems > 5 && TotalFilterItems > 5)) {
								// 	var checkedItems = [];
								// 	_.each($(element + " .filtervalue[data-name='" + sharedfiltercolumndisplayname + "']:checked"), function (i) {
								// 		checkedItems.push($(i).attr("data-value"));
								// 	});
								// 	$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(checkedItems).attr("title", checkedItems);
								// } else if (unCheckedFilterItems <= 5) {
								// 	var unCheckedItems = [];
								// 	_.each($(element + " .filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)"), function (i) {
								// 		unCheckedItems.push($(i).attr("data-value"));
								// 	});
								// 	unCheckedItems = unCheckedItems.join(", ")
								// 	$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("Excluding : " + unCheckedItems + "").attr("title", "Excluding : " + unCheckedItems + "");
								// }
								else {
									var checkedItems = [];
									_.each($(element + " .filtervalue[data-name='" + sharedfiltercolumndisplayname + "']:checked"), function (i) {
										checkedItems.push($(i).attr("data-value"));
									});
									$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(checkedItems).attr("title", checkedItems);
								}
							} else if (filter.dataType == "number") {
								var fromRange = filter.fromRange,
									toRange = filter.toRange,
									customRange = filter.customfiltervalue;
								var neqw = $("#acc" + filtercolumndisplayname + "").data('ionRangeSlider').old_from;
								if (fromRange == undefined && toRange == undefined) {
									fromRange = filter.filtergreaterthanvalue;
									toRange = filter.filterlessthanvalue;
								}
								else if (customRange == undefined || customRange === "" && (fromRange != undefined && toRange != undefined)) {
									$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(fromRange + " - " + toRange).attr("title", fromRange + " - " + toRange);
								}
							} else if (filter.dataType == "date") {
								var fromDateRange = filter.fromRange,
									toDateRange = filter.toRange,
									dateRange = filter.daterange;
								var dates = $(element + ' .daterange-center[displayname="' + filter.columndisplayname + '"] span').text();
								$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(dates).attr("title", dates);
							}
						} else if (filter.parametername != undefined && filter.parametername != "") {
							if (filter.valueType == "all" || filter.valueType == "range") {
								$(element + ' .acc-content[data-id="' + filter.parametername + '"] .filteredvalues').text(filter.parametercurrentvalue).attr("title", filter.parametercurrentvalue);
							} else if (filter.valueType == "list") {
								var selectedplistvalue = $('.filtercustomparamerelist[data-id="acc' + filter.parametername + '"] select').val()
								$(element + ' .acc-content[data-id="' + filter.parametername + '"] .filteredvalues').text(selectedplistvalue).attr("title", selectedplistvalue);
							}
						}

						// Update Filter Display Name
						if (filter.filterDisplayName != null && filter.filterDisplayName != undefined) {
							var targetName = filter.columndisplayname;
							if (filter.parametername != undefined && filter.parametername != "") {
								targetName = filter.parametername;
							}
							if (filter.filterDisplayName == "") {
								$(element + " .acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter.columndisplayname).attr("title", filter.columndisplayname);
								$(element + " .itemname[data-name='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").val(filter.columndisplayname);
							} else {
								$(element + " .acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter.filterDisplayName).attr("title", filter.filterDisplayName);
							}
						}
					}
					else if (isUpdated == false) {
						if (filter1.dataType != undefined) {
							var filtercolumndisplayname = filter1.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
							if (filter1.dataType == "string") {
								if (filter1.customfield == undefined) {
									var filtereddatatext = filter1["data"].replace(/\@@/g, ', ');
								} else {
									var filtereddatatext = filter1.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
								}
								var TotalFilterItems = $(element + " .filtervalue[data-name='" + filtercolumndisplayname + "']").length;
								var unCheckedFilterItems = $(element + " .filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)").length;
								if (unCheckedFilterItems == 0) {
									$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(lang["storyboard.all"]).attr("title", lang["storyboard.all"]);
								} 
								// else if (TotalFilterItems <= 5 || (unCheckedFilterItems > 5 && TotalFilterItems > 5)) {
								// 	$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
								// } else if (unCheckedFilterItems <= 5) {
								// 	var unCheckedItems = [];
								// 	_.each($(element + " .filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)"), function (i) {
								// 		unCheckedItems.push($(i).attr("data-value"));
								// 	});
								// 	unCheckedItems = unCheckedItems.join(", ")
								// 	$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("Excluding : " + unCheckedItems + "").attr("title", "Excluding : " + unCheckedItems + "");
								// }
								else {
									$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
								}
							} else if (filter1.dataType == "number") {
								var fromRange = filter1.fromRange,
									toRange = filter1.toRange,
									customRange = filter1.customfiltervalue;

								if (fromRange == undefined && toRange == undefined) {
									fromRange = filter1.filtergreaterthanvalue;
									toRange = filter1.filterlessthanvalue;
								}
								if (customRange == undefined || customRange === "" && (fromRange != undefined && toRange != undefined)) {
									$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(fromRange + " - " + toRange).attr("title", fromRange + " - " + toRange);
								}
							} else if (filter1.dataType == "date") {
								var fromDateRange = filter1.fromRange,
									toDateRange = filter1.toRange,
									dateRange = filter1.daterange;

								var dates = $(element + ' .daterange-center[displayname="' + filter1.columndisplayname + '"] span').text();
								// if(customRange== undefined ||
								// customRange===""){
								$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(dates).attr("title", dates);
								// }
							}
						} else if (filter1.parametername != undefined && filter1.parametername != "") {
							if (filter1.valueType == "all" || filter1.valueType == "range") {
								$(element + ' .acc-content[data-id="' + filter1.parametername + '"] .filteredvalues').text(filter1.parametercurrentvalue).attr("title", filter1.parametercurrentvalue);
							} else if (filter1.valueType == "list") {
								var selectedplistvalue = $('.filtercustomparamerelist[data-id="acc' + filter1.parametername + '"] select').val()
								$(element + ' .acc-content[data-id="' + filter1.parametername + '"] .filteredvalues').text(selectedplistvalue).attr("title", selectedplistvalue);
							}
						}

						// Update Filter Display Name
						if (filter1.filterDisplayName != null && filter1.filterDisplayName != undefined) {
							var targetName = filter1.columndisplayname;
							if (filter1.parametername != undefined && filter1.parametername != "") {
								targetName = filter1.parametername;
							}
							if (filter1.filterDisplayName == "") {
								$(element + " .acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter1.columndisplayname).attr("title", filter1.columndisplayname);
								$(element + " .itemname[data-name='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").val(filter1.columndisplayname);
							} else {
								$(element + " .acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter1.filterDisplayName).attr("title", filter1.filterDisplayName);
							}
						}
					}
				});
			});
		}
		else {
			_.each(totalfilterArray, function (filter, index) {
				if (filter.dataType != undefined) {
					var filtercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
					if (filter.dataType == "string") {
						if (filter.customfield == undefined) {
							var filtereddatatext = filter["data"].replace(/\@@/g, ', ');
						} else {
							var filtereddatatext = filter.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
						}
						var TotalFilterItems = $(element + " .filtervalue[data-name='" + filtercolumndisplayname + "']").length;
						var unCheckedFilterItems = $(element + " .filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)").length;
						if (unCheckedFilterItems == 0) {
							$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(lang["storyboard.all"]).attr("title", lang["storyboard.all"]);
						} 
						// else if (TotalFilterItems <= 5 || (unCheckedFilterItems > 5 && TotalFilterItems > 5)) {
						// 	$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
						// } else if (unCheckedFilterItems <= 5) {
						// 	var unCheckedItems = [];
						// 	_.each($(element + " .filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)"), function (i) {
						// 		unCheckedItems.push($(i).attr("data-value"));
						// 	});
						// 	unCheckedItems = unCheckedItems.join(", ")
						// 	$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("Excluding : " + unCheckedItems + "").attr("title", "Excluding : " + unCheckedItems + "");
						// }
						else {
							$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
						}
					} else if (filter.dataType == "number") {
						var fromRange = filter.fromRange,
							toRange = filter.toRange,
							customRange = filter.customfiltervalue;
						var inValue = "";
						if (filter.invalue != undefined) {
							inValue = filter.invalue;
						}
						var equalsValue = "";
						if (filter.equalvalue != undefined) {
							equalsValue = filter.equalvalue;
						}
						if (fromRange == undefined && toRange == undefined) {
							fromRange = filter.filtergreaterthanvalue;
							toRange = filter.filterlessthanvalue;
						}

						if ((customRange == undefined || customRange === "") && (fromRange != undefined && toRange != undefined) && (equalsValue == "" || equalsValue == undefined) && (inValue == "" || inValue == undefined)) {
							$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(fromRange + " - " + toRange).attr("title", fromRange + " - " + toRange);
						}
						else if (inValue != "") {
							$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(inValue).attr("title", inValue);
						}
						else {
							$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(fromRange).attr("title", fromRange);
						}
					} else if (filter.dataType == "date") {
						var fromDateRange = filter.fromRange,
							toDateRange = filter.toRange,
							dateRange = filter.daterange;
						var dates = $(element + ' .daterange-center[displayname="' + filter.columndisplayname + '"] span').text();
						// if(customRange== undefined ||
						// customRange===""){
						$(element + ' .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(dates).attr("title", dates);
						// }
					}
				} else if (filter.parametername != undefined && filter.parametername != "") {
					if (filter.valueType == "all" || filter.valueType == "range") {
						$(element + ' .acc-content[data-id="' + filter.parametername + '"] .filteredvalues').text(filter.parametercurrentvalue).attr("title", filter.parametercurrentvalue);
					} else if (filter.valueType == "list") {
						var selectedplistvalue = $('.filtercustomparamerelist[data-id="acc' + filter.parametername + '"] select').val()
						$(element + ' .acc-content[data-id="' + filter.parametername + '"] .filteredvalues').text(selectedplistvalue).attr("title", selectedplistvalue);
					}
				}

				// Update Filter Display Name
				if (filter.filterDisplayName != null && filter.filterDisplayName != undefined) {
					var targetName = filter.columndisplayname;
					if (filter.parametername != undefined && filter.parametername != "") {
						targetName = filter.parametername;
					}
					if (filter.filterDisplayName == "") {
						$(element + " .acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter.columndisplayname).attr("title", filter.columndisplayname);
						$(element + " .itemname[data-name='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").val(filter.columndisplayname);
					} else {
						$(element + " .acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter.filterDisplayName).attr("title", filter.filterDisplayName);
					}
				}
			});
		}
	}
	maxcustomparameterfiltersdropdownoldEve() {
		this.showaddcustomparameterfiltermodal();
	}
	showaddcustomparameterfiltermodal = (e) => {
		$('.trend-stats-container').remove();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		var that = this;
		var customParameterFields = ChartInfo.functions.getCustomParameter();
		var customParameterModal = new customParameterAddFilterView({
			customparameterfileds: customParameterFields
		});
		$('.modal-anim-left').prop('class', 'modal fade').addClass("left");
		//	$('#mySmallModal0').modal("show");
		ModalProperties.resize();
	}
	appendDrillDownAllToChart(chartDetails) {
		if (chartDetails != undefined && chartDetails['drillDownAllFilter'] != undefined) {
			var drillDownAllFilter = chartDetails['drillDownAllFilter'];
			var that = this;
			_.each(drillDownAllFilter, function (i, j) {
				var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
				if (name === null) {
					var name = decodeURIComponent(drillDownAllFilter[j].name);
				}
				else if (name == "undefined") {
					name = decodeURIComponent(drillDownAllFilter[j].displayName);
					if (drillDownAllFilter[j].customfield != undefined) {
						name = drillDownAllFilter[j].customfield.name;
					}
				}
				if (drillDownAllFilter[j].type == "date") {
					name = $(name).find('li:first').attr('column-display-name');
					if (name === undefined) {
						name = drillDownAllFilter[j].displayName;
					}
				}
				var fvalue = drillDownAllFilter[j].data;
				if (fvalue == undefined && drillDownAllFilter[j].customfield != null && drillDownAllFilter[j].customfield != undefined) {
					fvalue = drillDownAllFilter[j].customfield.parameter;
				}
				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
				ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
				$("#" + dynamicdiv).remove();
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
				$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>");
				$('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) });
			});
		}
	}
	viewdataforsummarytable($this, chartnumber) {
		if (ChartInfo.functions.getSummaryTableDateHistogramStatus(chartnumber) != "true") {
			var indexPosition = $($this).index();
			var name = $("#summartable" + chartnumber + " thead tr th").eq(indexPosition).text();
			var measureHierarachy = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name == name });
			var selectedcolumns = [];
			if (measureHierarachy[0] != undefined) {
				_.each(measureHierarachy[0].hierarchelist, function (value) {
					var temp = {};
					temp["actualnames"] = value.columnName;
					temp["displaynames"] = value.columnDisplayName;
					temp["datatype"] = value.dataType;
					var secondmeasure = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name == value.columnName })
					if (secondmeasure.length > 0) {
						var secondhierarchylist = secondmeasure[0].hierarchelist;
						temp["actualnames"] = secondhierarchylist[0].columnName;
						temp["displaynames"] = secondhierarchylist[0].columnDisplayName;
						temp["datatype"] = secondhierarchylist[0].dataType;
					}
					selectedcolumns.push(temp);
				});
			}
			var that = this;
			$("#datatablepopupview" + chartnumber).remove();
			$('body').append('<div id=' + "datatablepopupview" + chartnumber + '></div>');
			ReactDOM.render(
				<DataTablePopupView
					currentChartNumber={chartnumber}
					target={$this}
					dimensions={this.state.reportcolumns}
					appendheaders={false}
				/>,
				document.getElementById("datatablepopupview"+chartnumber+"")
			);
			var applyObj = {};
			applyObj["chartnumber"] = chartnumber;
			applyObj["selectedcolumn"] = selectedcolumns;
			applyObj["popupwindow"] = "popupview";
			applyObj["$this"] = $this;
			applyObj["selectedname"] = name;

			PubSub.publish("viewDataTableForSummaryTable", applyObj);
			//that.broker.trigger("viewDataTableForSummaryTable",chartnumber, selectedcolumns,"popupview",$this,name);

			// require(["views/viewreport/datatablepopupview"],function(dataTablePopupView){
			// 	var view = new dataTablePopupView({
			// 		currentChartNumber:chartnumber,
			// 		broker:that.broker,
			// 		target:$this,
			// 		dimensions:that.collection,
			// 		appendheaders:false
			// 	});
			// 	that.broker.trigger("viewDataTableForSummaryTable",chartnumber, selectedcolumns,"popupview",$this,name);
			// });
		} else {
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation47'));
			return;
		}
	}

	summaryTableDrilldown(msg, data) {
		this.summarytabledrilldown(data);
	}
	summarytabledrilldown(e) {
		e.preventDefault();
		e.stopPropagation();
		var filterData = [];
		var that = this;
		var $this = e.currentTarget;
		var drillDownAllCharts = $("#drilldownallchartsinstoryboard").prop("checked");
        if(!drillDownAllCharts){
            drillDownAllCharts = collection.isDrillDownAllEnable();
        }
		var chartnumber = $($this).closest('table').attr('data-num');
		$('.summarydrill').removeClass('summarydrill');
		$($this).parent().addClass('summarydrill');
		var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
		if ($('.tileview[data-num=' + chartnumber + ']').length != 0) {
			var top = e.pageY + 20;
			var left = e.pageX + 20;
			var chartwidth = $("#chart" + chartnumber).width() - 100;
			if (left > chartwidth) {
				left = left - 120;
			}
		} else {
			var top = e.clientY;
			if (top < 100) {
				top = 100;
			}
			var left = e.clientX;
			var chartwidth = $("#chart" + chartnumber).width() - 100;
			if (left > chartwidth) {
				left = chartwidth;
			}
		}
		$('#chart' + chartnumber + ' .highcharts-container .pie-menu').remove();
		$('.pie-menu').remove();
		if ($($this).hasClass("summarytable-viewdatapopup")) {
			this.viewdataforsummarytable($this, chartnumber);
			return true;
		}
		if ($($this).hasClass("summarytable-smartinsights-viewdata-more")) {
			let that = this;
			$('#chart' + chartnumber + '').before('<div class="pie-menu summarypie" style="top:' + top + 'px;left:' + left + 'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close" id="piemenuclosecustom1"><i class="fa fa-times"></i></div>'
					+ '<div class="pie-list-wrapper">'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut disabled"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + "summarytable-piemenu-viewdata" + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label" id="viewdatacustom1"><span class="pietooltip">' + lang["storyboard.view"] + '<br>' + lang["storyboard.data"] + '</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut disabled"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"> </span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut pie-drillthrough disabled ' + isDrillthrough + '" data-num="' + chartnumber + '""> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"><span class="pietooltip">' + lang["storyboard.drill"] + '<br>' + lang["storyboard.through"] + '</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut disabled ' + isSummaryTableDrillDownEnable + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label" id="drilldowncustom1"><span class="pietooltip">' + lang["storyboard.drill"] + '<br>' + lang["storyboard.down"] + '</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut summarytable-piemenu-smartinsights"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label" id="smartcustom1"><span class="pietooltip">'+lang["storyboard.smartinsights"]+ '</span><i class="fa fa-smartinsights small-14 marg-righ-10"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut disabled"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					/*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'*/
					+ '<span class="piemenuwrapper"></span>'
					+ '</div>'
					+ '<span class="wrapper"></span>'
				);
				setTimeout(function () {
					$('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
				}, 200);
				$('.boc-piemenu-close, .piemenuwrapper').on("click", function () {
					$('.summarydrill').removeClass('summarydrill');
					$('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
					setTimeout(function () {
						$('.pie-menu').remove();
					}, 500);
				});
				$(".summarytable-piemenu-viewdata").click(function(){
					that.viewdataforsummarytable($this, chartnumber);
					setTimeout(function () {
						$('.pie-menu').remove();
					}, 500);
				})
				$(".summarytable-piemenu-smartinsights").click(function(){
					let drilldownProps = {};
					drilldownProps["chartnumber"] = chartnumber;
					drilldownProps["columns"] = JSON.stringify(that.props.reportcolumns);
					
					drilldownProps["pos"] = sessionStorage.getItem("pos");
					drilldownProps["iscuststroyboard"] = that.props.iscustomstoryboard;
					drilldownProps["datamodelId"] = that.props.datamodelId;
					drilldownProps["ismultifact"] = that.props.ismultifact;
					drilldownProps["multifactentities"] = that.props.multifactentities;
					drilldownProps["facttables"] = that.props.facttables;
					drilldownProps["isfrom"] = "summary-table"
					drilldownProps["$this"] = $this;
					drilldownProps["drilldownyAxisSeries"] = $($this).attr('data-columnname');
					let storyboardmsmartinsightview = new StoryboardSmartInsightsView(drilldownProps)
					setTimeout(function () {
						$('.pie-menu').remove();
					}, 500);
				})
			return true;
		}
		var indexPosition = $($this).index();
		var temp;
		var drilldownon = "textcolumn";
		if ($($this).hasClass('groupTitle') || $($this).closest("tr.groupTR").length != 0) {
			var columnName = chartDetails["summarytablegroupbys"].columnName;
			temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == columnName });
			drilldownon = "group";
		} else {
			if (chartDetails["summarytablecolumns"][indexPosition] != undefined) {
				var columnName = chartDetails["summarytablecolumns"][indexPosition].columnName
				temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == columnName });
			}
		}
		var sessionValue = JSON.parse(sessionStorage.getItem('customhierarchy' + chartnumber));
		var levelmapping;
		_.each(sessionValue, function (data, i) {
			levelmapping = data[0][columnName];
		});
		var convertedTypes = {};
		this.levelmapping = {};
		_.each(JSON.parse(sessionStorage.getItem("convertedtypes")), function (list) {
			convertedTypes[list["columnName"]] = list["dataType"]
		})
		this.summarytablefiltervalues = [];
		var filterid = 0;
		if (ChartInfo.functions.getTempSummaryTableFilterValues(chartnumber) != undefined) {
			filterid = ChartInfo.functions.getTempSummaryTableFilterValues(chartnumber).length;
		}
		var customFields = sessionStorage.getItem("customFields");
		var tempCF = JSON.parse(customFields);
		var res;
		_.each(tempCF, function (customfields) {
			_.each(customfields.parametrFieldsArray, function (value) {
				res = $.grep(ChartInfo.functions.getCustomParameter(), function (e) {
					return e.parametername == value;
				})
				var formula = customfields.formula;
				var scriptcondition = customfields.scriptcondition;
				var fields = customfields.fields;
				var sqlformula = customfields.sqlformula;
				if (res.length != 0) {
					customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
				} else {
					_.each(ChartInfo.functions.getCustomParameter(), function (values) {
						if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
							customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
							customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
						}
					});
				}
			});
		});
		customFields = JSON.stringify(tempCF);
		if (chartDetails["summarytablegroupbys"] != undefined && chartDetails["summarytablegroupbys"] != null) {
			var groupNo = $($this).parent().attr('group-no');
			var groupName = $('.groupTR[group-no=' + groupNo + '][data-num=' + chartnumber + ']').attr('group-name');
			var Object = $.grep(collection.getDimensions(), function (e) { return e.columnDisplayName == groupName.split(':')[0] });
			var filterObject = {};
			if (Object != undefined && Object.length > 0) {
				if (drillDownAllCharts == true) {
					filterObject['drilldownAll'] = true;
					filterObject['isDrillDownAll'] = true;
					filterObject["summaryTableFilter"] = true;
				}
				Object = Object[0];
				filterObject["dataType"] = Object.tableDisplayType;
				filterObject["tablename"] = Object.tableName;
				filterObject["columndisplayname"] = Object.columnDisplayName;
				filterObject["columnname"] = Object.columnName;
				filterObject["isdrilldownfilter"] = true;
				filterObject["filterid"] = filterid;
				if (Object.tableDisplayType == 'date') {
					filterObject["data"] = groupName.substring(groupName.indexOf(":") + 1, groupName.length).trim();
				} else {
					filterObject["data"] = groupName.substring(groupName.indexOf(":") + 1, groupName.length).trim();
				}
				if (filterObject["dataType"] == "date") {
					if (filterObject["data"].length > 0) {
						var newDate = filterObject["data"]// Date.parse(filterObject["data"].replace(/-/g,'/'));
						if (isNaN(newDate)) {
							filterObject["data"] = filterObject["data"];
						}
						else {
							filterObject["data"] = newDate;//new Date(newDate).getTime();
						}

					}
				}
				if (drilldownon == "group") {
					filterObject["breadcrump"] = true;
				}
				filterData[0] = filterObject;
				ChartInfo.functions.setExcludeFilterData(filterData);
			}
			if (customFields != undefined && customFields != null && customFields != "null") {
				var customObject = {};
				var tempcustomObject = JSON.parse(customFields);
				_.each(tempcustomObject, function (customfield) {
					if (customfield.name == groupName.split(':')[0]) {
						customObject = customfield;
						var sqlFilterObject = {};
						sqlFilterObject["sqldata"] = groupName.split(": ")[1].trim();
						customObject["sqlcondition"] = sqlFilterObject;

						customObject["parameter"] = groupName.split(": ")[1].trim();
						filterObject["isdrilldownfilter"] = true;
						filterObject["filterid"] = filterid;
						if (drillDownAllCharts == true) {
							filterObject['drilldownAll'] = true;
							filterObject['isDrillDownAll'] = true;
							filterObject["summaryTableFilter"] = true;
						}
						if (drilldownon == "group") {
							filterObject["breadcrump"] = true;
						}
						filterObject["customfield"] = customObject;
						filterObject["dataType"] = convertedTypes[groupName.split(':')[0]] == undefined ? (groupName.dataType == undefined ? "string" : groupName.dataType) : convertedTypes[groupName.split(':')[0]];
						var scriptFilterCondition = customObject['scriptcondition'];
						customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ")==(parameter).toString()) return true else return false";
						if (filterObject["dataType"] == "date") {
							customObject['scriptcondition'] = "; if(new Date(" + scriptFilterCondition + ")==new Date(parameter)) return true else return false";
							if (customObject["parameter"].length > 0) {
								var newDate = customObject["parameter"];//Date.parse(customObject["parameter"].replace(/-/g,'/'));
								if (isNaN(newDate)) {
									customObject["parameter"] = customObject["parameter"];
								}
								else {
									customObject["parameter"] = newDate;//new Date(newDate).getTime();
								}
							}
						}
						filterObject["columnname"] = customObject["name"];
						filterObject["data"] = groupName.split(": ")[1].trim();
					}
				});
				filterData[0] = filterObject;
				ChartInfo.functions.setExcludeFilterData(filterData);
			}
			this.summarytablefiltervalues.push(filterObject);
		}
		if (!$($this).hasClass('groupTitle')) {
			var drilldownColumnName = $('.summarytablestyling[data-num=' + chartnumber + '] thead tr th').eq($($this).index()).html();
			var Object = $.grep(collection.getDimensions(), function (e) { return e.columnDisplayName == drilldownColumnName });
			var filterObject = {};
			if (Object != undefined && Object.length > 0) {
				Object = Object[0];
				if ($($this).closest("tr.groupTR").length == 0) {
					if (drillDownAllCharts == true) {
						filterObject['drilldownAll'] = true;
						filterObject['isDrillDownAll'] = true;
						filterObject["summaryTableFilter"] = true;
					}
					filterObject["dataType"] = Object.tableDisplayType;
					filterObject["tablename"] = Object.tableName;
					filterObject["columndisplayname"] = Object.columnDisplayName;
					filterObject["columnname"] = Object.columnName;
					filterObject["isdrilldownfilter"] = true;
					filterObject["filterid"] = filterid;
					filterObject["data"] = $($this).text().trim();
					if (filterObject["dataType"] == "date") {
						if (filterObject["data"].length > 0) {
							var newDate = filterObject["data"];//Date.parse(filterObject["data"].replace(/-/g,'/'));
							if (isNaN(newDate)) {
								filterObject["data"] = filterObject["data"];
							}
							else {
								filterObject["data"] = newDate;//new Date(newDate).getTime();
							}

						}
					}
					if (drilldownon == "textcolumn") {
						filterObject["breadcrump"] = true;
					}
					filterData[0] = filterObject;
					ChartInfo.functions.setExcludeFilterData(filterData);
				}
			}
			if (customFields != undefined && customFields != null && customFields != "null") {
				var customObject = {};
				var tempcustomObject = JSON.parse(customFields);
				_.each(tempcustomObject, function (customfield) {
					if (customfield.name == drilldownColumnName && $($this).closest("tr.groupTR").length == 0) {
						customObject = customfield;
						var sqlFilterObject = {};
						sqlFilterObject["sqldata"] = $($this).text().trim();
						customObject["sqlcondition"] = sqlFilterObject;

						customObject["parameter"] = $($this).text().trim();
						filterObject["isdrilldownfilter"] = true;
						filterObject["filterid"] = filterid;
						if (drillDownAllCharts == true) {
							filterObject['drilldownAll'] = true;
							filterObject['isDrillDownAll'] = true;
							filterObject["summaryTableFilter"] = true;
						}
						if (drilldownon == "textcolumn") {
							filterObject["breadcrump"] = true;
						}
						filterObject["customfield"] = customObject;
						filterObject["dataType"] = convertedTypes[drilldownColumnName] == undefined ? (customfield.dataType == undefined ? "string" : customfield.dataType)
							: convertedTypes[drilldownColumnName];
						var scriptFilterCondition = customObject['scriptcondition'];
						customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ")==(parameter).toString()) return true else return false";
						if (filterObject["dataType"] == "date") {
							customObject['scriptcondition'] = "; if(new Date(" + scriptFilterCondition + ")==new Date(parameter)) return true else return false";
							if (customObject["parameter"].length > 0) {
								var newDate = customObject["parameter"];//Date.parse(customObject["parameter"].replace(/-/g,'/'));
								if (isNaN(newDate)) {
									customObject["parameter"] = customObject["parameter"];
								}
								else {
									customObject["parameter"] = newDate;//new Date(newDate).getTime();
								}
							}
						}
						filterObject["columnname"] = customObject["name"]
						filterObject["data"] = $($this).text().trim();
					}
				});
				filterData[0] = filterObject;
				ChartInfo.functions.setExcludeFilterData(filterData);
			}
			if (!(_.isEmpty(filterObject))) {
				this.summarytablefiltervalues.push(filterObject);
			}
		}
		var isSummaryTableDrillDownEnable = "summarytable-drilldownmodel";
		var isSummaryTableViewDataEnable = "summarytable-pie-viewdata";
		if (this.isDirect == 1 && this.isStoredProcedure == 1) {
			isSummaryTableDrillDownEnable = "summarytable-drilldownmodel disabled";
			isSummaryTableViewDataEnable = "summarytable-pie-viewdata disabled";
		}
		var drillReports = ChartInfo.functions.getDrillThroughReports();
		if (drillReports == undefined || drillReports == "" || drillReports == "[]"
			|| (this.pathArr[3] == "schedulereport" && this.pathArr[4] == 0) || ((this.pathArr[3] == "embeddedreport" || this.pathArr[3] == "alertreport") && this.pathArr[4] == 0)) {
			var isDrillthrough = "disabled"
		}
		var isSharedReport = false;
		if (this.pathArr[3] == "sharereport" && this.pathArr[4] >= 0) {
			isSharedReport = true;
		}
		if (isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true) {
			isSummaryTableDrillDownEnable = "summarytable-drilldownmodel disabled";
			isSummaryTableViewDataEnable = "summarytable-pie-viewdata disabled";
		}
		if (ChartInfo.functions.getSummaryTableDateHistogramStatus(chartnumber) != undefined && ChartInfo.functions.getSummaryTableDateHistogramStatus(chartnumber) != "true") {
			if (temp != undefined && temp.length > 0 && levelmapping < temp[0]['hierarchelist'].length - 1) {
				$('#chart' + chartnumber + '').before('<div class="pie-menu summarypie" style="top:' + top + 'px;left:' + left + 'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close" id="piemenuclosecustom1"><i class="fa fa-times"></i></div>'
					+ '<div class="pie-list-wrapper">'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut disabled"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut summarytable-pie-viewdata" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label" id="viewdatacustom1"><span class="pietooltip">' + lang["storyboard.view"] + '<br>' + lang["storyboard.data"] + '</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut disabled"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"> </span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut pie-drillthrough ' + isDrillthrough + '" data-num="' + chartnumber + '""> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"><span class="pietooltip">' + lang["storyboard.drill"] + '<br>' + lang["storyboard.through"] + '</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut pie-drilldown"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label" id="drilldowncustom1"><span class="pietooltip">' + lang["storyboard.drill"] + '<br>' + lang["storyboard.down"] + '</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut disabled"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					/*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'*/
					+ '<span class="piemenuwrapper"></span>'
					+ '</div>'
					+ '<span class="wrapper"></span>'
				);
			} else {
				$('#chart' + chartnumber + '').before('<div class="pie-menu summarypie" style="top:' + top + 'px;left:' + left + 'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close" id="piemenuclosecustom1"><i class="fa fa-times"></i></div>'
					+ '<div class="pie-list-wrapper">'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut disabled"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isSummaryTableViewDataEnable + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label" id="viewdatacustom1"><span class="pietooltip">' + lang["storyboard.view"] + '<br>' + lang["storyboard.data"] + '</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut disabled"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"> </span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut pie-drillthrough ' + isDrillthrough + '" data-num="' + chartnumber + '""> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"><span class="pietooltip">' + lang["storyboard.drill"] + '<br>' + lang["storyboard.through"] + '</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isSummaryTableDrillDownEnable + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label" id="drilldowncustom1"><span class="pietooltip">' + lang["storyboard.drill"] + '<br>' + lang["storyboard.down"] + '</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					+ '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut disabled"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
					/*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'*/
					+ '<span class="piemenuwrapper"></span>'
					+ '</div>'
					+ '<span class="wrapper"></span>'
				);
			}
		}
		else {

			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().append(messagesMap["BirdInformation23"]);

		}
		setTimeout(function () {
			$('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
		}, 200);
		$('.boc-piemenu-close, .piemenuwrapper').on("click", function () {
			$('.summarydrill').removeClass('summarydrill');
			$('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
			setTimeout(function () {
				$('.pie-menu').remove();
			}, 500);
		});
		that = this;
		$('.summarytable-drilldownmodel').on("click", function (event) {
			event.preventDefault();

			that.appendsummarytabledrilldownmodel(drilldownon, chartnumber, $($this).index())
		});
		$('.pie-drilldown, .summarytable-pie-viewdata').on("click", function (event) {
			$('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
			if ($(event.currentTarget).find('.boc-pie-menu-label').text() == "DrillDown") {
				var drilldown;
				if ($($this).hasClass("groupTD")) {
					drilldown = "group";
				}
				var filters = ChartInfo.functions.getTempSummaryTableFilterValues(chartnumber);
				if (filters == undefined || filters.length == 0) {
					filters = that.summarytablefiltervalues;
				} else {
					filters = filters.concat(that.summarytablefiltervalues);
				}
				ChartInfo.functions.setTempSummaryTableFilterValues(filters, chartnumber);
				var applyObj = {};
				applyObj["currentTarget"] = $this;
				applyObj["filtervalues"] = filters;
				applyObj["drilldownon"] = drilldown;
				applyObj["currentchartno"] = chartnumber;
				applyObj["index"] = undefined;
				applyObj["isDateHistogram"] = undefined;
				applyObj["dateHistomgramColName"] = undefined;
				PubSub.publish("summarytabledrilldown", applyObj);
				that.applyDrillDownOnAllChartsFromSummarytable(filters, chartnumber, filters);
			}
			if ($(event.currentTarget).find('.boc-pie-menu-label').text() == "ViewData") {
				that.viewDataTablePopUp(e, chartnumber);
			}
			setTimeout(function () {
				$('.pie-menu').remove();
			}, 500);
		});
		$('.wrapper').on("click", function () {
			$('.pie-menu').remove();
		});
		$('.pie-drillthrough').on("click", function (e) {
			e.preventDefault();
			e.stopPropagation();
			PubSub.publish('pie-drillthrough', e);
		});
	}
	appendsummarytabledrilldownmodel(drilldownon, chartnumber, index) {
		var that = this;
		//$('#chartdrilldowndiv[data-num="' + chartnumber + '"]').empty();
		var customFields = JSON.parse(sessionStorage.getItem("customFields"));
		var priorityColumns = ChartInfo.functions.getPriorityColumns();
		var totalColumns = collection.getDimensions();
		var priorityColumnsList = {};
		if (priorityColumns != undefined && priorityColumns != null && priorityColumns.length > 0) {
			priorityColumnsList = priorityColumns;
		} else {
			priorityColumnsList = JSON.parse(JSON.stringify(totalColumns));
		}
		var temp = customFields;
		var allowedColumns = [];
		var disable = false;
		var aggcolumsArray = [];
		var summarytableFilterColumns = [];
		var valuesForCfsCheck = [];
		if (that.state.multifact == "true") {
			if (that.summarytablefiltervalues != undefined && that.summarytablefiltervalues.length > 0) {
				if (ChartInfo.functions.getChartDetails(chartnumber) != undefined) {
					var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
					aggcolumsArray = chartDetails.summarytableaggregationOriginalColumns;
					valuesForCfsCheck = chartDetails.summarytablevalues;
				}
				_.each(that.summarytablefiltervalues, function (list) {
					summarytableFilterColumns.push(list.columnname);
				});
				_.each(priorityColumnsList, function (list) {
					disable = ChartInfo.functions.getAllowedEntitiesForInstantFilters(JSON.parse(that.state.multifactentities), list.columnName, undefined, aggcolumsArray, summarytableFilterColumns, that.state.facttables, "xdropdown");
					if (!disable) {
						allowedColumns.push(list);
					}
				});
				var temp = [];
				_.each(customFields, function (val, index) {
					var isvalid = true;
					if (val.usedFields.length > 0) {
						_.each(val.usedFields, function (used, index) {
							disable = ChartInfo.functions.getAllowedEntitiesForInstantFilters(JSON.parse(that.state.multifactentities), used, undefined, aggcolumsArray, summarytableFilterColumns, that.state.facttables, "xdropdown");
							if (disable) {
								isvalid = false;
							}
						});
						if (isvalid) {
							temp.push(val);
						}
					} else {
						temp.push(val);
					}
				});
			} else {
				allowedColumns = priorityColumnsList;
			}
		} else {
			allowedColumns = priorityColumnsList;
		}
		var res;
		_.each(temp, function (customfields) {
			_.each(customfields.parametrFieldsArray, function (value) {
				res = $.grep(ChartInfo.functions.getCustomParameter(), function (e) {
					return e.parametername == value;
				})
				var formula = customfields.formula;
				var scriptcondition = customfields.scriptcondition;
				var fields = customfields.fields;
				var sqlformula = customfields.sqlformula;
				if (res.length != 0) {
					customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
				} else {
					_.each(ChartInfo.functions.getCustomParameter(), function (values) {
						if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
							customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
							customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
						}
					});
				}
			});
		});
		customFields = temp;
		var convertedTypes = {};
		_.each(JSON.parse(sessionStorage.getItem("convertedtypes")), function (list) {
			convertedTypes[list["columnName"]] = list["dataType"]
		});
		ReactDOM.render(
			<SummarytableDrilldownModal
				columns={allowedColumns}
				calculatedFileds={customFields}
				convertedTypes={convertedTypes}
				drilldownon={drilldownon}
				currentchartno={chartnumber}
				index={index}
				isCustomstoryboard={false}
				close={this.closeSummaryDrilldown}
			/>,
			document.querySelector('#chartdrilldowndiv[data-num="' + chartnumber + '"]')
		);
		//$("#summarytable-drilldown").css("display","block");
		$('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
		setTimeout(function () {
			$('.pie-menu').remove();
		}, 500)
		var i = 0;
		var j = 0;
		_.each(priorityColumnsList, function (obj) {
			if (obj.priorityColumn) {
				i++;
			}
		});
		_.each(customFields, function (obj) {
			if (obj.priorityColumn) {
				j++;
			}
		});
		if (i + j == 0) {
			$('.prioritycolumns').addClass("hide");
		} else {
			$('.prioritycolumns').removeClass("hide");
		}
		_.each(customFields, function (obj) {
			if (obj.priorityColumn) {
				j++;
			}
		});
		if (i + j == 0) {
			$('.prioritycolumns').addClass("hide");
		} else {
			$('.prioritycolumns').removeClass("hide");
		}
		if (customFields != null && priorityColumnsList != null && customFields.length + priorityColumnsList.length == j + i) {
			$('.availablecolumns').addClass("hide");
		} else {
			$('.availablecolumns').removeClass("hide");
		}
		ModalProperties.resize();
	}
	applyDrillDownOnAllChartsFromSummarytable(unique_array, chartno, filterArray) {
		var drillDownAllCharts = $("#drilldownallchartsinstoryboard").prop("checked");
        if(!drillDownAllCharts){
            drillDownAllCharts = collection.isDrillDownAllEnable();
        }
		if (drillDownAllCharts || this.props.isFromStoryBook) {
			ChartInfo.functions.setdrillfitlerArray(filterArray);
			var that = this;
			sessionStorage.setItem("currentsummarytbaledrilldown", chartno);
			var currentTabID = $(".maxanim1[data-num=" + chartno + "]").attr("tab-id");
			_.each($(".maxanim1[data-num!=" + chartno + "][tab-id=" + currentTabID + "]"), function (i) {
				var $this = i
				var chartnumber = $($this).attr("data-num");
				var tabid = $(".maxanim1[data-num=" + chartnumber + "]").attr("tab-id");
				$(".maxanim1[data-num=" + chartnumber + "][tab-id=" + tabid + "] .fullscreensample").addClass("nopie");
				if (unique_array.length > 0) {
					$(".maxanim1[data-num=" + chartnumber + "][tab-id=" + tabid + "] .fullscreensample").addClass("nopie");
				} else {
					$(".maxanim1[data-num=" + chartnumber + "][tab-id=" + tabid + "] .fullscreensample").removeClass("nopie");
				}
				var applyObj = {};
				applyObj["pos"] = undefined;
				applyObj["currentchartnum"] = chartnumber;
				applyObj["applyattributes"] = "applyattributes";
				applyObj["dateHistogramInterval"] = undefined;
				applyObj["calculation"] = undefined;
				applyObj["isExcludeorIncludeFilters"] = undefined;
				applyObj["isChartSummaryData"] = undefined;
				applyObj["selecteddobycolumns"] = undefined;
				applyObj["filterArray"] = filterArray;
				applyObj["navigatefrom"] = "drilldownview";
				applyObj["isCustomparameterFilter"] = undefined;
				applyObj["tableInfo"] = undefined;
				PubSub.publish('applyfiltesandredrawchart', applyObj);
			});
			$(".clonetile[data-num=" + chartno + "]").addClass("disabled");
			$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
			$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer").attr("drilled-chart", chartno);
			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(chartno));
			if (chartDetails["drillDownAllFilter"] != undefined && chartDetails["drillDownAllFilter"].length != 0) {
				var drillDownAllFilter = _.unique(chartDetails["drillDownAllFilter"], 'columnname');
				_.each(drillDownAllFilter, function (i, j) {
					var name = drillDownAllFilter[j].columnname;
					if (name === null) {
						var name = drillDownAllFilter[j].name;
					}
					else if (name == "undefined" || name == undefined) {
						name = decodeURIComponent(drillDownAllFilter[j].displayName);
						if (drillDownAllFilter[j].customfield != undefined) {
							name = drillDownAllFilter[j].customfield.name;
						}
					}
					if (drillDownAllFilter[j].type == "date") {
						name = $(name).find('li:first').attr('column-display-name');
						if (name === undefined) {
							name = drillDownAllFilter[j].columndisplayname;
						}
					}
					var today = new Date();
					var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
					dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
					ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
					$("#" + dynamicdiv).remove();
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
					$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i></span>")
					$('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) });
				});
			}
			_.each(unique_array, function (i, j) {
				if (unique_array[j]['saved'] == undefined && unique_array[j]["breadcrump"] == true && (unique_array[j]['isDrillDownAll'] == true || unique_array[j]['drilldownAll'] == true)) {
					var name = unique_array[j].columnname;
					if (name === null) {
						var name = unique_array[j].name;
					}
					else if (name == "undefined" || name == undefined) {
						name = decodeURIComponent(unique_array[j].displayName);
						if (unique_array[j].customfield != undefined) {
							name = unique_array[j].customfield.name;
						}
					}
					if (unique_array[j].type == "date") {
						name = $(name).find('li:first').attr('column-display-name');
						if (name === undefined) {
							name = unique_array[j].columndisplayname;
						}
					}
					var data = unique_array[j].data;
					if (unique_array[j].hasOwnProperty("customfield")) {
						data = unique_array[j].customfield.sqlcondition.sqldata;
					}
					var today = new Date();
					let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
					dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
					ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={data} />, document.getElementById(dynamicdiv));
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
					$("#" + dynamicdiv).remove();
					$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
				}
			});
		}
	}
	drawsummarytabledatehistogramEve(msg, data) {
		this.drawsummarytabledatehistogram(data);
	}
	drawsummarytabledatehistogram(e) {
		var $this = e.currentTarget;
		$(".spinner").show();
		var isDateHistogram = "false";
		var histogramInterval = $($this).attr('data-interval');
		if (histogramInterval != "default") {
			isDateHistogram = "true";
		}
		var chartId = $($this).attr('data-num');
		ChartInfo.functions.setSummaryTableDateHistogramStatus(chartId, isDateHistogram);
		var dateHistogramXaxisData = [{ columnName: $($this).attr('column-name'), displayName: $($this).attr('column-display-name'), dataType: $($this).attr('column-type') }];
		var chartData = ChartInfo.functions.getChartDetails(chartId);
		var obj = {};
		obj["dateHistogramColName"] = $($this).attr('column-name');
		obj["histogramInterval"] = histogramInterval;
		var data = JSON.parse(chartData);
		if (data["chartType"] != "pivot" && data["chartType"] != "summarytable" && data["chartType"] != "predictionchart")
			if (data["chartType"] != "pivot" && data["chartType"] != "summarytable" && data["chartType"] != "doby")
				data["oldcharttype"] = data["chartType"];
		data["chartType"] = "summarytable";
		ChartInfo.functions.updateChartDetails(chartId, JSON.stringify(data));
		var paramObj = {};
		paramObj["currentTarget"] = $this;
		paramObj["filtervalues"] = ChartInfo.functions.getTempSummaryTableFilterValues(chartId);
		paramObj["drilldownon"] = null;
		paramObj["currentchartno"] = chartId;
		paramObj["index"] = undefined;
		paramObj["isDateHistogram"] = isDateHistogram;
		paramObj["dateHistomgramColName"] = obj;
		PubSub.publish("summarytabledrilldown", paramObj);
	}
	applysummarytabledrilldownEve(msg, data) {
		var summarytriggerEvent = sessionStorage.getItem("summarydrilldowntrigger");
		if(summarytriggerEvent==null || summarytriggerEvent == undefined || summarytriggerEvent=="0"){
			if (data != undefined) {
				this.applysummarytabledrilldown(data.target, data.drilldownon, data.chartno, data.index);
			} else {
				this.applysummarytabledrilldown();
			}
		}
	}
	applysummarytabledrilldown(e, drilldownon, chartno, index) {
		if (e != undefined && $(".drilldowncheckbox:checked").attr("data-child") == undefined) {
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation20'));
			this.closeSummaryDrilldown(chartno);
			$(".gridspinner").hide();
			return
		}
		//		e.preventDefault();
		sessionStorage.setItem("summarydrilldowntrigger","1");
		var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(chartno));
		var filterArray = [];//ChartInfo.getTempSummaryTableFilterValues(chartno);
		var unique_array = []
		if (ChartInfo.functions.getTempSummaryTableFilterValues(chartno) != undefined) {
			filterArray = ChartInfo.functions.getTempSummaryTableFilterValues(chartno);
		}
		if (this.summarytablefiltervalues != undefined && this.summarytablefiltervalues[0] != undefined) {
			filterArray.push(this.summarytablefiltervalues[0]);
		}
		if (this.summarytablefiltervalues != undefined && this.summarytablefiltervalues[1] != undefined) {
			filterArray.push(this.summarytablefiltervalues[1]);
		}
		ChartInfo.functions.setTempSummaryTableFilterValues(filterArray, chartno);
		ChartInfo.functions.setdrillfitlerArray(filterArray);
		var propObj = {};
		propObj["currentTarget"] = e;
		propObj["filtervalues"] = filterArray;
		propObj["drilldownon"] = drilldownon;
		propObj["currentchartno"] = chartno;
		propObj["index"] = index;
		propObj["isDateHistogram"] = undefined;
		propObj["dateHistomgramColName"] = undefined;
		var drillDownAllCharts = $("#drilldownallchartsinstoryboard").prop("checked");
        if(!drillDownAllCharts){
            drillDownAllCharts = collection.isDrillDownAllEnable();
        }
		if (drillDownAllCharts || this.state.isDrillDownAllenable === true) {
			var that = this;
			for (var i = 0; i < filterArray.length; i++) {
				unique_array.push(filterArray[i]);
			}
			var Obj = {};
			Obj["currentTarget"] = e;
			Obj["filtervalues"] = filterArray;
			Obj["drilldownon"] = drilldownon;
			Obj["currentchartno"] = chartno;
			Obj["index"] = index;
			Obj["isDateHistogram"] = undefined;
			Obj["dateHistomgramColName"] = undefined;
			PubSub.publish("summarytabledrilldown", Obj);
			that.applyDrillDownOnAllChartsFromSummarytable(unique_array, chartno, filterArray)
		}else {
			PubSub.publish("summarytabledrilldown", propObj);
		}
		var that = this;
		setTimeout(()=>{
            that.closeSummaryDrilldown(chartno);
        }, 800);
		
	}
	closeSummaryDrilldown(chartnumber) {
		//$("#summarytable-drilldown").css("display","none");
		ReactDOM.unmountComponentAtNode(document.querySelector('#chartdrilldowndiv[data-num="' + chartnumber + '"]'));
	}
	drawHistogramEve(msg, data) {
		this.drawHistogram(data);
	}
	drawHistogram(e) {
		//$('.gridspinner').show();
		var $this = e.currentTarget;
		var chartn = $($this).attr('chart-id');
		$('#spinner' + chartn).show();
		var selectedValue = $($this).attr('data-val');
		var charts = ChartInfo.functions.getAllChartDetails();
		_.each(charts, function (chart, j) {
			sessionStorage.removeItem("drilldown" + j);
		});
		if (selectedValue == "reset") {
			var chartId = $($this).attr('chart-id');
			ChartInfo.functions.setDateHistogramStatus(chartId, false);
			var dateHistogramXaxisData = [{ columnName: $($this).attr('column-name'), displayName: $($this).attr('column-display-name'), dataType: $($this).attr('column-type') }];
			ChartInfo.functions.setDateHistogramXaxisData(dateHistogramXaxisData);
			ChartInfo.functions.getFilters(false);
			ChartInfo.functions.setDateHistogramState(selectedValue, chartId);
			var chartData = ChartInfo.functions.getChartDetails(chartId);
			var jsonData = JSON.parse(chartData);
			var chartType = jsonData.chartType;
			var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
			let compareDateRangeObj = jsonData!=undefined && jsonData["compareDateRangeObj"]!=null?jsonData["compareDateRangeObj"]:null;
			let tempYaxis = jsonData!=undefined?jsonData["yAxis"]:[];
			let checkcondition = false;
			if(compareDateRangeObj!=null && compareDateRangeObj.hasOwnProperty("0") && compareDateRangeObj["0"]!=null){
				let yjsObj = compareDateRangeObj["0"]; 
				_.each(tempYaxis,function(val,index){
				if(val.columnName!=undefined&&val.columnName!=null&&yjsObj.hasOwnProperty(val.columnName)){
					checkcondition = true;
				}
			});
			}
			var applyObj = {};
			applyObj["pos"] = undefined;
			applyObj["currentchartnum"] = $($this).attr('chart-id');
			applyObj["applyattributes"] = "applyattributes";
			applyObj["dateHistogramInterval"] = $($this).attr('data-val');
			applyObj["calculation"] = undefined;
			applyObj["isExcludeorIncludeFilters"] = undefined;
			applyObj["isChartSummaryData"] = undefined;
			applyObj["selecteddobycolumns"] = undefined;
			applyObj["filterArray"] = undefined;
			applyObj["navigatefrom"] = undefined;
			applyObj["isCustomparameterFilter"] = undefined;
			applyObj["tableInfo"] = undefined;
			PubSub.publish('applyfiltesandredrawchart', applyObj);

			setTimeout(function () {
				$('#chart' + chartId).find('.drawhistogram[data-val="' + selectedValue + '"]').addClass("active");
				$(".showtrending[data-num=" + chartId + "]").show();
				if(checkcondition&&comparedaterangecharttpes.indexOf(chartType)>=0){
					$(".showtrending[data-num=" + chartId + "]").hide();
				}
			}, 2000);
		}
		else {
			var chartId = $($this).attr('chart-id');
			ChartInfo.functions.setDateHistogramStatus(chartId, true);
			var dateHistogramXaxisData = [{ columnName: $($this).attr('column-name'), displayName: $($this).attr('column-display-name'), dataType: $($this).attr('column-type') }];
			ChartInfo.functions.setDateHistogramXaxisData(dateHistogramXaxisData);
			ChartInfo.functions.getFilters(false);
			ChartInfo.functions.setDateHistogramState(selectedValue, chartId);

			var applyObj = {};
			applyObj["pos"] = undefined;
			applyObj["currentchartnum"] = $($this).attr('chart-id');
			applyObj["applyattributes"] = "applyattributes";
			applyObj["dateHistogramInterval"] = $($this).attr('data-val');
			applyObj["calculation"] = undefined;
			applyObj["isExcludeorIncludeFilters"] = undefined;
			applyObj["isChartSummaryData"] = undefined;
			applyObj["selecteddobycolumns"] = undefined;
			applyObj["filterArray"] = undefined;
			applyObj["navigatefrom"] = undefined;
			applyObj["isCustomparameterFilter"] = undefined;
			applyObj["tableInfo"] = undefined;
			PubSub.publish('applyfiltesandredrawchart', applyObj);

			setTimeout(function () {
				$('#chart' + chartId).find('.drawhistogram[data-val="' + selectedValue + '"]').addClass("active");
				$(".showtrending[data-num=" + chartId + "]").hide();
			}, 2000);
		}
		var isSharedReport = false;

		if (this.pathArr[3] == "sharereport" && this.pathArr[4] >= 0) {
			isSharedReport = true;
		}
		var data = JSON.parse(ChartInfo.functions.getChartDetails(chartId));
		$("#chart" + chartId + " ").before("<ul class='breadcrumb' data-num=" + chartId + "></ul>");
		if (chartId != undefined) {
			if (data["drillDownFilter"] != undefined) {
				var breadCrumbElement = $('.breadcrumb[data-num=' + chartId + ']')
				var that = this;
				_.each(data["drillDownFilter"], function (value, index) {
					if (value["saved"] == "true") {
						var appendValue = value.data;
						var ulEle = "";
						var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
						if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
							appendValue = breadCrumpElements["span"];
							ulEle = breadCrumpElements["ulelement"];
						}
						if (isSharedReport != true && ChartInfo.functions.getisViewOnlyFlag() != true) {
							breadCrumbElement.append("<li class=drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name='" + value.data + "' class='close removedrilldownsinglereport' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
						} else {
							breadCrumbElement.append("<li class=drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name='" + value.data + "' class='close removedrilldownsinglereport' ></a>" + ulEle + "</li>");
						}
					}
				});
			}
		}
	}
	resetsummarytableEve(msg, data) {
		this.resetsummarytable(data);
	}
	resetsummarytable(e) {
		e.preventDefault();
		//sessionStorage.setItem("changesdone",1);
		var $this = e.currentTarget;

		var chartno = $($this).parent().attr("data-num");
		$("#spinner" + chartno).show();
		var summarytablefiltervalues = ChartInfo.functions.getTempSummaryTableFilterValues(chartno);
		if(summarytablefiltervalues!=undefined){
			var levelid = $($this).parent().attr("level");
			var filterArray = $.grep(summarytablefiltervalues, function (e) { return e.filterid < levelid });
			this.applyDrillDownOnAllChartsFromSummarytable(filterArray, chartno, filterArray);
			PubSub.publish("summarytabledrilldownwithbreadcrumb", $this);
			// setTimeout(function(){$("#spinner"+chartno).hide();},1000)
		}else{
			this.applyDrillDownOnAllChartsFromSummarytable([], chartno, []);
			PubSub.publish("summarytabledrilldownwithbreadcrumb", $this);
		}
	}
	removeDrilldownEve(msg, data) {
		this.removeDrildown(data);
	}
	removeDrildown(e) {
		var $this = e.currentTarget;
		var chartnumber = $($this).closest('ul').attr('data-num');
		$('.breadcrumb[data-num=' + chartnumber + ']').remove();
		var data = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
		var drillDownArray = data["drillDownFilter"];
		var removeObject = {};
		var name = $($this).attr("name");
		var columnname = $($this).attr("columnname");
		if (drillDownArray != undefined) {
			_.each(drillDownArray, function (value) {
				if (value.data == name && value.columnname == columnname.trim()) {
					removeObject = value
				}
			});
			var storedData = JSON.parse(sessionStorage.getItem("drilldown" + chartnumber));
			if (drillDownArray.length != 0) {
				drillDownArray = $.grep(drillDownArray, function (value) {
					if (removeObject.columnname == value.columnname && value.data == removeObject.data) {
						return false;
					}
					else {
						return true;
					}
				});
			}
			//	sessionStorage.setItem("drilldown"+chartnumber, JSON.stringify(drillDownArray));
		}
		var customfieldsdrilldownobject = data["customfieldFilterObject"];
		var CustomFieidsremoveObject = "";
		if (customfieldsdrilldownobject != undefined && customfieldsdrilldownobject.length > 0) {
			_.each(customfieldsdrilldownobject, function (value) {
				if (value.customfield != undefined) {
					if (value.customfield['parameter'] == name && value.customfield['name'] == columnname.trim()) {
						CustomFieidsremoveObject = value;
					}
				}
				else {
					if (value.data == name && value.columnname == columnname.trim()) {
						CustomFieidsremoveObject = value;
					}
				}
			});
			customfieldsdrilldownobject = $.grep(customfieldsdrilldownobject, function (value) {
				if (CustomFieidsremoveObject.customfield != undefined && value.customfield != undefined) {
					if (CustomFieidsremoveObject.customfield.name == value.customfield.name && CustomFieidsremoveObject.customfield.parameter == value.customfield.parameter) {
						return false;
					}
					else {
						return true;
					}
				}
				else {
					if (value.data == name && value.columnname == columnname.trim()) {
						return false;
					}
					else {
						return true;
					}
				}

			});
		}
		var parsedString = ChartInfo.functions.getTempSummaryTableFilterValues(chartnumber) || [];
		if (parsedString != undefined && parsedString != "undefined" && parsedString != null
			&& parsedString != "null" && parsedString.length > 0) {
			var filterArray = [];
			if (parsedString != null && parsedString != undefined && parsedString.length > 0) {
				filterArray = parsedString[parsedString.length - 1][1];

			}

			filterArray = $.grep(filterArray, function (value) {
				if (removeObject.columnname == value.columnname && value.data == removeObject.data) {
					return false;
				}
				else {
					return true;
				}
			});

			sessionStorage.setItem("customhierarchy" + chartnumber, JSON.stringify(filterArray));
		}
		data["customfieldFilterObject"] = customfieldsdrilldownobject;
		data['drillDownFilter'] = drillDownArray;
		if (drillDownArray != undefined && drillDownArray.length == 0) {
			data["legendfilter"] = drillDownArray;
		}
		ChartInfo.functions.updateChartDetails(chartnumber, JSON.stringify(data));
		var applyObj = {};
		applyObj["pos"] = undefined;
		applyObj["currentchartnum"] = chartnumber;
		applyObj["applyattributes"] = "applyattributes";
		applyObj["dateHistogramInterval"] = undefined;
		applyObj["calculation"] = undefined;
		applyObj["isExcludeorIncludeFilters"] = undefined;
		applyObj["isChartSummaryData"] = undefined;
		applyObj["selecteddobycolumns"] = undefined;
		applyObj["filterArray"] = drillDownArray;
		applyObj["navigatefrom"] = "drilldownview";
		applyObj["isCustomparameterFilter"] = undefined;
		applyObj["tableInfo"] = undefined;
		PubSub.publish('applyfiltesandredrawchart', applyObj);

		$("#chart" + chartnumber + "").before("<ul class='breadcrumb' data-num=" + chartnumber + "></ul>");
		var drillDownFilter = data["drillDownFilter"];
		if (chartnumber != undefined) {
			if (drillDownArray != undefined) {
				_.each(drillDownArray, function (value) {
					if (value["saved"] == "true") {
						var appendValue = value.data;
						var ulEle = "";
						var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
						if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
							appendValue = breadCrumpElements["span"];
							ulEle = breadCrumpElements["ulelement"];
						}
						if (value.columndisplayname != undefined) {
							$('.breadcrumb[data-num=' + chartnumber + ']').append("<li class=drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columndisplayname + "'  name='" + value.data + "' class='close removedrilldown' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
						} else {
							$('.breadcrumb[data-num=' + chartnumber + ']').append("<li class=drilldown saved>" + value.displayName + " [" + appendValue + "] <a  displayname='" + value.columnname + "' columnname='" + value.displayName + "'  name='" + value.data + "' class='close removedrilldown' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
						}
					}
				});
			}
		}
		sessionStorage.setItem("changesdone", 1);
	}
	downloadPNGEve(msg, data) {
		this.downloadPNG(data);
	}
	downloadPNG(e) {
		$(".spinner").show();
		var that = this;
		$('.narrativecontainer').css("overflow", "hidden");
		if (this.state.reportId == null || this.state.reportId == undefined) {
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage("BirdInformation01"));
			$(".spinner").hide();
			return;
		} else {
			var downloadReportModel = {}; //url: downloadreport
			downloadReportModel["reportId"] = this.state.reportId;
			//downloadReportModel.on('error', that.handleModelError, that);
			SendRequest({
				url: "downloadreport",
				queryString: "?reportId=" + this.state.reportId,
				sucessFunction: (response) => {
					if (response.hasOwnProperty('error')) {
						$(".spinner").hide();
						$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
						$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
						$('.birdmessagebuttons').removeClass('show');
						$('.birdmessagebuttons').addClass('hide');
						$('.birdmessage-center').addClass("message-top-in");
						$('.details').removeClass('hide');
						$('.birdmessage h2').empty().append('Error : ');
						$('.birdmessage h2').append('<span class="errorcode">');
						$('.birdmessage .errorcode').empty().append(response.errorCode);
						$('.birdmessage-info').empty().text(response.error);
						$('.details').addClass('show');
						$('.messagedetails xmp').empty().append(response.errorTrace);
						$(".spinner").hide();
						return;
					}
				},
				rejectFunction: (error) => { },
				headers: {
					method: "post",
					headers: new Headers({
						"Content-Type": "application/json"
					})
				}
			});
		}

		$('.dataTables_scrollBody').css({ overflow: 'hidden' });
		$('.doby-grid-viewport').scrollTop(0);
		$(".seriescolors").addClass("hide");
		setTimeout(function () {
			$(".storyboardtoolbarfull .dropdown.bgcommon").removeClass("open");
			var $this = e.currentTarget;
			e.preventDefault();
			var dataurl;
			var bgcolor;
			$('footer.footer').css("display", "none");
			/*$('.tileview').css('overflow','hidden');
			for(var i=0; i < 15;i++){
				$('#chart'+i).css('overflow','hidden');
			}*/
			var dobysize = $('.doby-grid-cell').length;
			for (var i = 0; i < dobysize; i++) {
				var dobyright = $('.doby-grid-cell.r' + i).css('right');
				var dobyleft = $('.doby-grid-cell.l' + i).css('left');
				$('.doby-grid-cell.r' + i).css('right', dobyright);
				$('.doby-grid-cell.l' + i).css('left', dobyleft);
			}
			//Fix for bootstrap table download not working
			var fhtcellExists = $('.fht-cell').length > 0 ? true : false;
			var fhtcelllength = $('.fht-cell').length
			if (fhtcellExists) {
				for (var i = 0; i < fhtcelllength; i++) {
					$(".fht-cell" + i + "").hide();
					$(".th-inner").css("background-image", "none")
				}
			}
			$(".fixed-table-body").css("overflow", "hidden");
			var canvas = document.querySelector("#storyboardcanvas");
			var storyboardwidth = $(window).width()
			var storyboardheight = $('.leftpane .grid-stack').height() + 120;
			if ($('.fullscreenmain .maxanim8').length != 1 && $("body.filters-hidden").length != 1) {
				storyboardheight = storyboardheight + 60
			}
			canvas.setAttribute('width', storyboardwidth);
			canvas.setAttribute('height', storyboardheight);


			var tilesize = $('.tileview').length;
			//var queryselector=$('.boc-dark-background');
			var queryselector = $('.leftpane');

			if ($('.fullscreenmain .maxanim8').length == 1) {
				//$('.fullscreenmain .maxanim8').find('.refreshsinglecharts1').eq(0).click();

				// Hide chart and show image
				/*$('.fullscreenmain .maxanim8').find('.thumbimage').css('display','block');
				$('.highcharts-container').css('display','none');
				$('.metric-container').css('display','block');
				$('.worldmaparea').css('display','block');*/

				queryselector = $('.fullscreenmain .maxanim7');
				bgcolor = $('.fullscreenmain .maxanim10').css('background-color');
				if (bgcolor == 'rgba(0, 0, 0, 0)' || bgcolor == undefined) {
					$('.fullscreenmain .maxanim7').css('background-color', 'rgb(255,255,255)');
				}
				canvas = document.querySelector("#singlecanvas");
				var singlewidth = $(window).width()
				var singleheight = $('.fullscreenmain').height() + 100;
				canvas.setAttribute('width', singlewidth);
				canvas.setAttribute('height', singleheight);
			}
			else {
				for (var i = 0; i < 15; i++) {
					if ($('#chart' + i + '>.summartytablecontainer').length != 1) {
						$(".tileview[data-num=" + i + "] .fullscreensample").css('overflow', 'visible');
					}
				}

				for (var i = 0; i < tilesize; i++) {
					if ($('.tileview[data-num=' + i + ']').length == 1) {
						var temp = $('.tileview[data-num=' + i + ']').position().top;
						$('.tileview[data-num=' + i + ']').css('top', temp);
					} else {
						tilesize++;
					}
				}
				// Hide chart and show image
				/*$('.thumbimage').css('display','block');
				if(embedscreenshot=="embedscreenshotyes"){
					$('.highcharts-container').css('display','none');	
				}
				$('.metric-container').css('display','block');
				$('.worldmaparea').css('display','block');*/
			}

			$('.ui-resizable-handle').css('display', 'none');

			//Fix for Browser SVG Render Issues 
			var svgElements = $("body").find('svg');
			svgElements.each(function () {
				var canvas, xml;
				canvas = document.createElement("canvas");
				canvas.className = "screenShotTempCanvas";
				xml = (new XMLSerializer()).serializeToString(this);
				xml = xml.replace(/xmlns=\"http:\/\/www\.w3\.org\/2000\/svg\"/, '');
				canvg(canvas, xml);
				$(canvas).insertAfter(this);
				$(this).attr('class', 'tempHide');
				$(this).hide();
			});

			//Fix for Leaflet Overlapping
			var leafletElemetns = $("body").find(".leafletcontainer");
			leafletElemetns.each(function () {
				var $this = this
				var mapPane = $($this).find(".leaflet-map-pane")[0];
				var mapTransform = mapPane.style.transform.replace("translate3d(", "").split(",");
				var mapX = parseFloat(mapTransform[0].replace("px", ""));
				var mapY = parseFloat(mapTransform[1].replace("px", ""));
				mapPane.style.transform = "translate3d(0px,0px,0px)";

				var tilePane = $($this).find(".leaflet-pane .leaflet-tile-pane .leaflet-tile-container");
				var tilePaneLeft = [];
				var tilePaneTop = [];
				for (var i = 0; i < tilePane.length; i++) {
					var tilePanetransform = tilePane[i].style.transform.replace("translate3d(", "").split(",");
					if (tilePanetransform[0] != "") {
						tilePaneLeft.push(parseFloat(tilePanetransform[0].replace("px", "")));
						tilePaneTop.push(parseFloat(tilePanetransform[1].replace("px", "")));
						tilePane[i].style.left = (tilePaneLeft[i] + mapX) + "px";
						tilePane[i].style.top = (tilePaneTop[i] + mapY) + "px";
					}
				}
				$($this).find(".leaflet-pane .leaflet-tile-pane .leaflet-tile-container").css("transform", "");

				//Map bg images
				var myTiles = $($this).find(".leaflet-map-pane img.leaflet-tile");
				var tilesLeft = [];
				var tilesTop = [];
				for (var i = 0; i < myTiles.length; i++) {
					var tileTransform = myTiles[i].style.transform.replace("translate3d(", "").split(",");
					if (tileTransform[0] != "") {
						tilesLeft.push(parseFloat(tileTransform[0].replace("px", "")));
						tilesTop.push(parseFloat(tileTransform[1].replace("px", "")));
						myTiles[i].style.left = (tilesLeft[i] + mapX) + "px";
						myTiles[i].style.top = (tilesTop[i] + mapY) + "px";
					}
				}
				$($this).find(".leaflet-map-pane img.leaflet-tile").css("transform", "");
				//Map Markers
				var myMarkerTiles = $($this).find(".leaflet-map-pane img.leaflet-marker-icon");
				var markerTilesLeft = [];
				var markerTilesTop = [];
				for (var i = 0; i < myMarkerTiles.length; i++) {
					var markerTileTransform = myMarkerTiles[i].style.transform.replace("translate3d(", "").split(",");
					if (markerTileTransform[0] != "") {
						markerTilesLeft.push(parseFloat(markerTileTransform[0].replace("px", "")));
						markerTilesTop.push(parseFloat(markerTileTransform[1].replace("px", "")));
						myMarkerTiles[i].style.left = (markerTilesLeft[i] + mapX) + "px";
						myMarkerTiles[i].style.top = (markerTilesTop[i] + mapY) + "px";
					}
				}
				$($this).find(".leaflet-map-pane img.leaflet-marker-icon").css("transform", "");

				//Map Marker shadows
				var myMarkerShadowTiles = $($this).find(".leaflet-map-pane img.leaflet-marker-shadow");
				var markerShadowsLeft = [];
				var markerShadowsTop = [];
				for (var i = 0; i < myMarkerShadowTiles.length; i++) {
					var markerShadowTransform = myMarkerShadowTiles[i].style.transform.replace("translate3d(", "").split(",");
					if (markerShadowTransform[0] != "") {
						markerShadowsLeft.push(parseFloat(markerShadowTransform[0].replace("px", "")));
						markerShadowsTop.push(parseFloat(markerShadowTransform[1].replace("px", "")));
						myMarkerShadowTiles[i].style.left = (markerShadowsLeft[i] + mapX) + "px";
						myMarkerShadowTiles[i].style.top = (markerShadowsTop[i] + mapY) + "px";
					}
				}
				$($this).find(".leaflet-map-pane img.leaflet-marker-shadow").css("transform", "")
			});

			//Pivot Table overlapping issue
			_.each($(".pivotscroll"), function (i) {
				i.style.overflow = 'hidden';
			});
			$(".sparklinecontainer").parent().css("overflow", "hidden");
			html2canvas(queryselector, { canvas: canvas, taintTest: true, logging: true, useCORS: true }).then(function (canvas) {
				setTimeout(function () {
					$("a.tempdownload").remove();
					$("#downloadimage").remove();
					var title = $("h2.settingmodal").text();
					//var a = document.createElement('a');
					$("body").append("<a href='' id='downloadimage' ></a>");
					var a = $('#downloadimage').get(0);
					// toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
					a.className = "tempdownload";
					//a.href = canvas.toDataURL("image/jpeg").replace("image/png", "image/octet-stream");

					var browser = "";
					var chrome = /chrom(e|ium)/.test(navigator.userAgent.toLowerCase());
					var safari = /safari/.test(navigator.userAgent.toLowerCase());

					if (canvas.msToBlob) { //for IE
						var blob = canvas.msToBlob();
						window.navigator.msSaveBlob(blob, title + '.png');
					} else if (chrome == false && safari == true) { //for Safari
						a.href = canvas.toDataURL("image/png");
						a.download = title + '.png';
						a.target = '_blank';
						var evObj = document.createEvent('MouseEvents');
						evObj.initMouseEvent("click", true, true, window);
						a.dispatchEvent(evObj);
					} else {
						a.href = canvas.toDataURL("image/png");
						a.download = title + '.png';
						a.click();
					}

					setTimeout(function () {
						$('.narrativecontainer').css("overflow", "auto");
						$("#downloadimage").remove();
						// Show chart and hide image
						$('.thumbimage').css('display', 'none');
						$('.highcharts-container').css('display', 'block');

						$('.screenshotmessage').addClass('hide');

						for (var i = 0; i < tilesize; i++) {
							$('.tileview[data-num=' + i + ']').css("top", "");
							$('#chart' + i + '').css('overflow', '');
							if (bgcolor == 'rgb(255,255,255)') {
								$('.fullscreenmain .maxanim7').css('background-color', '');
							}
						}
						for (var i = 0; i < dobysize; i++) {
							$('.doby-grid-cell.r' + i + '').css('right', '');
							$('.doby-grid-cell.l' + i + '').css('left', '');
						}
						if (fhtcellExists) {
							for (var i = 0; i < fhtcelllength; i++) {
								$(".fht-cell" + i + "").show();
								$(".th-inner").css("background-image", "")
							}
						}
						$('.dataTables_scrollBody').css({ overflow: 'auto' });
						$(".fixed-table-body").css('overflow', '');
						$(".fullscreensample").css('overflow', '');
						$("body").find('.screenShotTempCanvas').remove();
						$("body").find('.tempHide').show().removeClass('tempHide');
						var $this = $("body").find(".leafletcontainer");
						//Re-set Map Markers
						var myMarkerTiles = $($this).find(".leaflet-map-pane img.leaflet-marker-icon");
						var markerTilesLeft = [];
						var markerTilesTop = [];
						for (var i = 0; i < myMarkerTiles.length; i++) {
							myMarkerTiles[i].style.transform = "translate3d(" + myMarkerTiles[i].style.left + "," + myMarkerTiles[i].style.top + ",0)";
							myMarkerTiles[i].style.left = "";
							myMarkerTiles[i].style.top = "";
						}

						//Re-set Map Marker shadows
						var myMarkerShadowTiles = $($this).find(".leaflet-map-pane img.leaflet-marker-shadow");
						var markerShadowsLeft = [];
						var markerShadowsTop = [];
						for (var i = 0; i < myMarkerShadowTiles.length; i++) {
							myMarkerShadowTiles[i].style.transform = 'translate3d(' + myMarkerShadowTiles[i].style.left + "," + myMarkerShadowTiles[i].style.top + ",0)";
							myMarkerShadowTiles[i].style.left = "";
							myMarkerShadowTiles[i].style.top = "";
						}

						var tilePane = $($this).find(".leaflet-pane .leaflet-tile-pane .leaflet-tile-container");
						for (var i = 0; i < tilePane.length; i++) {
							tilePane[i].style.transform = 'translate3d(' + tilePane[i].style.left + "," + tilePane[i].style.top + ",0)";
							tilePane[i].style.left = "";
							tilePane[i].style.top = "";
						}

						//Re-set Pivot Table overlapping issue
						_.each($(".pivotscroll"), function (i) {
							i.style.overflow = 'auto';
						});
						$(".seriescolors").removeClass("hide");
						$(".spinner").hide();
						$(".sparklinecontainer").parent().css("overflow", "");
					}, 5000);
					$('footer.footer').css("display", "block");
				}, 0);
			});
		}, 1000);
	}
	tileFilterMenuToggleEve(msg, data) {
		this.tileFilterMenuToggle(data);
	}
	tileFilterMenuToggle(e) {
		var that = this;
		var $this = e;
		var tileNumber = $($this).attr("data-num");
		$(".cancelmodal").click();
		var StoryboardFilters = collection.getFilterObject();
		var tileFilterView = new tileFiltersModal({
			tileNumber: tileNumber,
			storyboardfilters: StoryboardFilters
		});
		setTimeout(function () {
			$("body").addClass("rightpanelopen");
			$(".tileview[data-num='" + tileNumber + "']").addClass("highlight");
			that.appendFiltersToTiles(tileNumber);
		}, 1500);
	}
	appendFiltersToTilesEve(msg, data) {
		this.appendFiltersToTiles(data);
	}
	appendFiltersToTiles(tileNumber) {
		var that = this;
		var tileLevelFilters = ChartInfo.functions.getTileLevelFilters(tileNumber);
		if (tileLevelFilters != undefined) {
			var isSharedReport = false;
			if (window.location.pathname.indexOf("sharereport") >= 0) { isSharedReport = true; }
			var reportFilterObject = tileLevelFilters[1].tileFiltersList;
			if (reportFilterObject != undefined) {
				var col_name = "";
				var filtervalues = "";
				var filterObjects;
				if (isSharedReport == true) {
					var sharedFilters = collection.getSharedFilters();
					var sharedFilters1 = JSON.parse(sharedFilters);
					var totalfilterArray = $.merge($.merge([], []), reportFilterObject);
					_.each(totalfilterArray, function (filter1, index1) {
						_.each(sharedFilters1, function (filter, index) {
							var sharedfiltercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
							if (filter1 != undefined) {
								if (filter1.columndisplayname == sharedfiltercolumndisplayname) {
									if (filter.dataType != undefined) {
										if (filter.dataType == "number") {
											var fromRange = filter.fromRange,
												toRange = filter.toRange,
												customRange = filter.customfiltervalue;
											var mainfromRange = filter1.fromRange,
												maintoRange = filter1.toRange,
												maincustomRange = filter1.customfiltervalue;
											if (fromRange == undefined && toRange == undefined) {
												fromRange = filter.filtergreaterthanvalue;
												toRange = filter.filterlessthanvalue;
											}
											if (fromRange > maintoRange || mainfromRange > toRange) {
												if (reportFilterObject.length != totalfilterArray.length) {
													reportFilterObject.splice(index1 - 1, 1);
												}
												else {
													reportFilterObject.splice(index1, 1);
												}
											}
										}
										else if (filter.dataType == "date") {
											var fromDateRange = filter.fromRange,
												toDateRange = filter.toRange,
												dateRange = filter.daterange;
											var mainfromRange = filter1.fromRange,
												maintoRange = filter1.toRange,
												maindateRange = filter1.daterange;
											var dates = $('#filteraccordion .daterange-center[displayname="' + filter.columndisplayname + '"] span').text();
											var sharedFrom = Date.parse(fromDateRange);
											var mainTo = Date.parse(maintoRange);
											var sharedTo = Date.parse(toDateRange);
											var mainFrom = Date.parse(mainfromRange);
											if (sharedFrom > mainTo || mainFrom > sharedTo) {
												if (reportFilterObject.length != totalfilterArray.length) {
													reportFilterObject.splice(index1 - 1, 2);
												}
												else {
													reportFilterObject.splice(index1, 2);
												}																				// accessa.push(reportFilterObject[index1]);
											}
										}
										if (filter.dataType == "string") {
											var sharedYes = false;
											if (filter.customfield == undefined) {
												var filtereddatatext = filter["data"].replace(/\@@/g, ', ');
												var newfiltereddatatext = filtereddatatext.split(",");
												var filtereddatatext1 = filter1["data"].replace(/\@@/g, ', ');
												var newfiltereddatatext1 = filtereddatatext1.split(",");

												_.each(newfiltereddatatext, function (shared, i) {
													var filtereddatatext1 = filter1["data"].replace(/\@@/g, ', ');
													if (filtereddatatext1.includes(shared)) {
														sharedYes = true
													}
												});
												if (sharedYes == false) {
													if (reportFilterObject.length != totalfilterArray.length) {
														reportFilterObject.splice(index1 - 1, 1);
													}
													else {
														reportFilterObject.splice(index1, 1);
													}
												}
											} else {
												var filtereddatatext = filter.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
												var newfiltereddatatext = filtereddatatext.split(",");
												var filtereddatatext1 = filter1.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
												var newfiltereddatatext1 = filtereddatatext1.split(",");
											}
										}

									}
								}
							}
						});
					});
				}

				_.each(reportFilterObject, function (filter, i) {
					if (filter != undefined) {
						if (col_name === filter["tablename"] + "." + filter["columnname"]) {
						} else {
							col_name = filter["tablename"] + "." + filter["columnname"];
							filterObjects = collection.getFilterObject();
							that.viewreportfilterview.addfilteritem(filter["filterDisplayName"], filter["filterIgnore"], filter["tablename"], filter["columnname"], filter["columndisplayname"], filter["dataType"], filter["fromRange"], filter["toRange"], filterObjects, that.props.datamodelId, "", filter["daterange"], "", undefined, undefined, sharedFilters);
							filtervalues = filtervalues + filter["columndisplayname"].replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, "") + ",";
						}
					}
				});
			}
			var viewReportAddFilterCollection = [];
			var columnsArray = that.state.reportcolumns;
			_.each(columnsArray, function (column) {
				var viewreportaddfiltermodel = {};
				viewreportaddfiltermodel["tableName"] = column["tableName"];
				viewreportaddfiltermodel["columnDisplayType"] = column["tableDisplayType"];
				viewreportaddfiltermodel["tableDisplayType"] = column["tableDisplayType"];
				viewreportaddfiltermodel["columnName"] = column["columnName"];
				viewreportaddfiltermodel["columnDisplayName"] = column["columnDisplayName"];
				viewreportaddfiltermodel["priorityColumn"] = column["priorityColumn"];
				viewReportAddFilterCollection.push(viewreportaddfiltermodel);
			});
			var viewReportAddFilterView = new ViewReportAddFilterView(
				{
					collection: viewReportAddFilterCollection,
					datamodelId: that.props.datamodelId,
					reportId: that.props.reportId,
					tabid: TabInfo.functions.getCurrentTabId(),
					isFromStoryBook: that.state.isFromStoryBook,
					scheduleid: that.props.scheduleid,
					filterArray: reportFilterObject,
					viewreportCollection: collection,
					multifact: that.state.multifact,
					facttables: that.state.facttables,
					multifactentities: that.state.multifactentities,
				});
			if (reportFilterObject.length == 0) {
				$(".hidebutton").trigger('click');
			} else {
				$(".showbutton").trigger('click');
				$('.instant-floating-filters').draggable({ cancel: '.col-lg-12.nopadding', containment: '#main>.boc-viewreport-section', handle: '.panel-heading' });
			}
		}
	}
	maxdatatablesEve(msg, data) {
		this.maxdatatables(data);
	}
	maxdatatables(e) {
		$('.dobyexport').removeClass('hide');
		sessionStorage.setItem("changesdone", 1);
		var $this = e;
		var that=this;
		var currentChartNumber = $($this).attr("data-num");
		$(".chartadvancedoptions[data-num='chart" + currentChartNumber + "']").hide();
		$("#chart" + currentChartNumber).empty();
		ChartInfo.functions.setSummaryTableDateHistogramStatus(currentChartNumber, false);
		$('.trend-stats-container').remove();
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		$(".dobyselectcolumns[data-num='" + currentChartNumber + "']").removeClass('hide')
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.max-data-buttons').hide();
		$(".chartchanger[data-num='" + currentChartNumber + "']").hide();
		$(".maxanim1[data-num='" + currentChartNumber + "'] #dropdownmorechart").removeClass("disabled");
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.showtrending').hide()
		$(".summarymodal[data-num='" + currentChartNumber + "']").addClass('hide');
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.viewchartdata').removeClass("active");
		$(".maxanim1[data-num='" + currentChartNumber + "']").find('.viewchartfromchartdata').addClass("active");
		$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + '].previewChart').removeClass("showactive");
		$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + '].pivotgrid').removeClass("showactive");
		$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + '].summarytable').removeClass("showactive");
		$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + '].dobygrid').addClass("showactive");
		$("#spinnerchart" + currentChartNumber).show();
		var currentSelection = $($this).closest('.dobygrid').find('.maxscreen-lft-filtertitle').text();
		if (currentSelection == 'Data') {
			$('.boc-maxscreen-left-item[data-num=' + currentChartNumber + ']').removeClass("showactive");
			$('.boc-maxscreen-hamburger[data-num=' + currentChartNumber + ']').removeClass("showactive");
			$($this).addClass("showactive");
			var chartData = ChartInfo.functions.getChartDetails(currentChartNumber);
			var data = JSON.parse(chartData);
			if (this.state.multifact === "true") {
				var factatables = this.state.facttables;
				if (data.xAxis.length == 0 && data.yAxis.length != 0) {
					var counter = 0;
					var yaxisfact;
					var isvalidYaxis = true;
					_.each(data.yAxis, function (yaxis, i) {
						yaxisfact = yaxis.columnName.split(".").shift();
						_.each(factatables, function (fact, j) {
							if (fact == yaxisfact) {
								counter++;
							}
						});
					});
					var customFields = sessionStorage.getItem("customFields");
					var customFeildsArray = JSON.parse(customFields);
					if (customFields != undefined && customFields.length > 0) {
						var ycustomFieldCheck;
						_.each(customFeildsArray, function (i, j) {
							_.each(data.yAxis, function (yaxis, iy) {
								if (yaxis.columnName.split(".").shift() == i.name) {
									var usedFieldstest = i.usedFields;
									if (usedFieldstest.length > 0) {
										_.each(usedFieldstest, function (ii, jj) {
											ycustomFieldCheck = ii;
											ycustomFieldCheck = ycustomFieldCheck.split(".").shift();
											_.each(factatables, function (fact, j) {
												if (fact == ycustomFieldCheck) {
													counter++;
												}
											});
										});
									} else {
										isvalidYaxis = false;
									}
								}
							});
						});
					}
					//for comparing custom measure and Y axis Name 
					if (counter <= 0) {
						var cumstoMeasures = ChartInfo.functions.getCustomMeasureField();
						if (cumstoMeasures != undefined && cumstoMeasures.length > 0) {
							var customMeasureCheck;
							_.each(cumstoMeasures, function (i, j) {
								_.each(data.yAxis, function (yaxis, iy) {
									if (yaxis.columnName.split(".").shift() == i.name) {
										var usedcustommeasures = i.usedFields.split(",");
										_.each(usedcustommeasures, function (ii, jj) {
											customMeasureCheck = ii;
											// for checking customfields in Custommeasures
											if (customFields != undefined && customFields.length > 0) {
												var customFieldCheckInMeasure;
												_.each(customFeildsArray, function (i, j) {
													if (customMeasureCheck == i.name) {
														var usedFieldstest = i.usedFields;
														_.each(usedFieldstest, function (ii, jj) {
															customFieldCheckInMeasure = ii;
															customFieldCheckInMeasure = customFieldCheckInMeasure.split(".").shift();
														});
													}
												});
											}
											customMeasureCheck = customMeasureCheck.split(".").shift();
											_.each(factatables, function (fact, j) {
												if (fact == customMeasureCheck || fact == customFieldCheckInMeasure) {
													counter++;
												}
											});
										});
									}
								});
							});
						}
					}
					//for comparing custom measurehierarchy and Y axis Name 
					if (counter <= 0) {
						var custommeasurehierarchyArray = ChartInfo.functions.getMeasureHierarchy();
						var yaxismeasurehierarchycheck;
						var yaxismeasurehierarchycheckInmh;
						if (custommeasurehierarchyArray != undefined && custommeasurehierarchyArray != null) {
							_.each(custommeasurehierarchyArray, function (i, j) {
								_.each(data.yAxis, function (yaxis, iy) {
									var hierarchelist = custommeasurehierarchyArray[j].hierarchelist;
									_.each(hierarchelist, function (object, index) {
										if (custommeasurehierarchyArray[j].name == yaxis.columnName.split(".").shift()) {
											yaxismeasurehierarchycheck = hierarchelist[index].columnName.split(".").shift();
											// for checking custom measurehierarchy in custom measurehierarchy
											_.each(custommeasurehierarchyArray, function (ii, jj) {
												if (custommeasurehierarchyArray[jj].name == yaxismeasurehierarchycheck) {
													var hierarchelistmh = custommeasurehierarchyArray[jj].hierarchelist;
													_.each(hierarchelistmh, function (obj, ind) {
														yaxismeasurehierarchycheckInmh = hierarchelistmh[ind].columnName.split(".").shift();
													});
												}
											});
											_.each(factatables, function (fact, j) {
												if (fact == yaxismeasurehierarchycheck || fact == yaxismeasurehierarchycheckInmh) {
													counter++;
												}
											});
										}
									});
								});
							});
						}
					}
					if (counter === 0) {
						//inner elements can not be hided hide with chartId
						// $('.gridload').hide();
						$(".spinner").hide();
						$(".gridspinner").hide();
						$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
						$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
						$('.birdmessagebuttons').removeClass('show');
						$('.birdmessagebuttons').addClass('hide');
						$('.birdmessage-center').addClass("message-top-in");
						$('.details').removeClass('show');
						$('.details').addClass('hide');
						$('.birdmessage h2').empty().append('Warning');
						$('.birdmessage-info').empty().text(getMessage('BirdWarning138'));
						return;
					}
				}
			}
			this.state.dobycolumns = data["dobyselectedcolumns"];
			_.each(dobycolumnnames, function (i, j) {
				var columnDisplayName = dobycolumnnames[j].columnDisplayName;
				var columnName = dobycolumnnames[j].columnName;
				var datatype = dobycolumnnames[j].tableDisplayType;
				$('.innerdobyselectedcolumns' + currentChartNumber).append("<li class='doby-list-item' tite= '" + columnDisplayName + "' ><label><input type='checkbox' class = 'filtercheckbox1' " + " name= '" + columnName + "' displayname='" + columnDisplayName + "' datatype= '" + datatype + "' data-num=" + currentChartNumber + "> </i>&nbsp;" + columnDisplayName + "</label></li>");
			});
			if (this.state.dobycolumns == undefined || this.state.dobycolumns.length === 0) {
				var selecteddata = [];
				var count=10;
					if(this.state.multifact =="true"){
						count=100
					}
				$('.innerdobyselectedcolumns' + currentChartNumber + ' .doby-list-item label input').each(function (i) {
					
					if (i < count) {
						var temp = {};
						temp["actualnames"] = $(this).attr("name");
						temp["displaynames"] = $(this).attr("displayname");
						temp["datatype"] = $(this).attr("datatype");
						selecteddata.push(temp);
					}
				});
				this.state.dobycolumns = selecteddata;
			}
			if (this.state.multifact == "true" && data.xAxis.length == 0 && data.yAxis.length == 0) {
				var that = this;
				var newdobycolumns = [];
				var existedfact;
				_.each(that.state.dobycolumns, function (column) {
					if (that.state.facttables.indexOf(column.actualnames.split(".")[0]) != -1) {
						if (existedfact == undefined) {
							existedfact = column.actualnames.split(".")[0];
							newdobycolumns.push(column);
						} else {
							if (existedfact == column.actualnames.split(".")[0]) {
								newdobycolumns.push(column);
							}
						}
					}
				})
				that.state.dobycolumns = newdobycolumns
			} else {
				if (this.state.multifact == "true" && data.xAxis.length != 0 && data.yAxis.length != 0) {
					var selecteddata = [];
					var yaxisdata = data["yAxis"];
					var yentities = [];
					_.each(yaxisdata, function (col) {
						yentities.push(col.columnName);
					});
					var that = this;
					var i = 0;
					_.each(dobycolumnnames, function (dimension) {
						var tablename = dimension.columnName.split(".")[0];
						_.each(yentities, function (yaxisFeilds) {
							var yaxisTableName = yaxisFeilds.split(".")[0];

							if (tablename == yaxisTableName) {
								if (i <= 10) {
									var temp = {};
									temp["actualnames"] = dimension.columnName;
									temp["displaynames"] = dimension.columnDisplayName;
									temp["datatype"] = dimension.tableDisplayType;
									selecteddata.push(temp);
									i++;
								}
								else {
									var columnName = dimension.columnName;
									if (yaxisFeilds == columnName) {
										var temp = {};
										temp["actualnames"] = dimension.columnName;
										temp["displaynames"] = dimension.columnDisplayName;
										temp["datatype"] = dimension.tableDisplayType;
										selecteddata.push(temp);
										i++;
									}
								}
							}
						});
					});

					_.each(data.xAxis, function (filters) {
						var temp = {};
						temp["actualnames"] = filters['columnName'];
						temp["displaynames"] = filters['displayName'];
						temp["datatype"] = filters['dataType'];
						selecteddata.push(temp);
					});
					this.state.dobycolumns = selecteddata;
				}
			}
			if (data["chartType"] != "pivot" && data["chartType"] != "summarytable" && data["chartType"] != "doby" && data["chartType"] != "predictionchart") {
				data["oldcharttype"] = data["chartType"];
			}
			data["chartType"] = "doby";
			data["dobyselectedcolumns"] = this.state.dobycolumns;
			$(".dobypagination").show();
			ChartInfo.functions.updateChartDetails(currentChartNumber, JSON.stringify(data));
		}
		if ($($this).closest('.dobygrid').find(".fa").hasClass("fa-bar-chart")) {
			var storedValue = ChartInfo.functions.getChartDetails(currentChartNumber);
			var storedObect = JSON.parse(storedValue);
			var chartType = storedObect["chartType"];
		}
		var drilldownchartnumber = ChartInfo.functions.getDrillDownChartNumber();
		var drilldown = [];
		if (drilldownchartnumber != undefined && drilldownchartnumber != currentChartNumber) {
			drilldown = JSON.parse(sessionStorage.getItem("drilldown" + drilldownchartnumber));
			if (drilldown != null) {
				drilldown = $.grep(drilldown, function (value) { return value.isDrillDownAll; });
			}
		}
		var viewDataTableObj = {};
		viewDataTableObj["currentChartNumber"] = currentChartNumber;
		viewDataTableObj["selectedcolumns"] = this.state.dobycolumns;
		viewDataTableObj["state"] = "";
		viewDataTableObj["pos"] = "max";
		viewDataTableObj["popupwindow"] = "nopopup";
		viewDataTableObj["target"] = drilldown;
		viewDataTableObj["applyattributes"] = undefined;
		viewDataTableObj["filters"] = undefined;
		viewDataTableObj["tableInfo"] = undefined;
		PubSub.publish("viewDataTable", viewDataTableObj);

		$this = $('.maxanim1[data-num=' + currentChartNumber + ']');
		var acd = {};
		acd["element"] = this;
		acd["pos"] = "max";
		PubSub.publish('adjustchartheight', acd);
		if (ChartInfo.functions.getDrillDownChartNumber() != undefined && ChartInfo.functions.getDrillDownChartNumber() == currentChartNumber) {
			var legendfilter = [];
			ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
			ChartInfo.functions.setLegendFilterobj(legendfilter, currentChartNumber);
			$(".pie-menu").remove();
			$(".fullscreensample").removeClass("nopie");
			$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
			$('.breadcrumb[data-num=' + currentChartNumber + ']').remove();
			var drilldown = JSON.parse(sessionStorage.getItem("drilldown" + currentChartNumber));
			if (drilldown != null) {
				drilldown = $.grep(drilldown, function (value) { return value.isDrillDownAll; });
			}
			sessionStorage.removeItem("drilldown" + currentChartNumber);
			$(".clonetile[data-num=" + currentChartNumber + "]").removeClass("disabled");
			if (drilldown != undefined && drilldown.length != 0) {
				var that = this;
				_.each($(".maxanim1[data-num!=" + currentChartNumber + "]"), function (i) {
					var $this = i
					var chartnumber = $($this).attr("data-num");
					var applyObj = {};
					applyObj["pos"] = undefined;
					applyObj["currentchartnum"] = chartnumber;
					applyObj["applyattributes"] = "applyattributes";
					applyObj["dateHistogramInterval"] = undefined;
					applyObj["calculation"] = undefined;
					applyObj["isExcludeorIncludeFilters"] = undefined;
					applyObj["isChartSummaryData"] = undefined;
					applyObj["selecteddobycolumns"] = undefined;
					applyObj["filterArray"] = [];
					applyObj["navigatefrom"] = "drilldownview";
					applyObj["isCustomparameterFilter"] = undefined;
					applyObj["tableInfo"] = undefined;
					PubSub.publish('applyfiltesandredrawchart', applyObj);
				});
			}
			$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
			$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").attr("drilled-chart", currentChartNumber);
			var drillDownAllFilter = data['drillDownAllFilter'];
			var that = this;
			_.each(drillDownAllFilter, function (i, j) {
				// if(drillDownAllFilter[j]['save'])
				var name = decodeURIComponent(drillDownAllFilter[j].columnname);
				if (name === null) {
					var name = decodeURIComponent(drillDownAllFilter[j].name);
				}
				if (drillDownAllFilter[j].type == "date") {
					name = $(name).find('li.drilldown:first').attr('column-display-name');
					if (name === undefined) {
						name = drillDownAllFilter[j].columnname;
					}
				}
				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
				ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
				$("#" + dynamicdiv).remove();
				$(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ." + dynamicdiv).remove();
			});
			$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>")
			$('.quickfilterremove').click((e) => { that.quickFilterRemove(e) })
			$('.breadcrumb[data-num=' + currentChartNumber + ']').remove();
			$("#chart" + currentChartNumber + "").before("<ul class='breadcrumb' data-num=" + currentChartNumber + "></ul>");
			if (currentChartNumber != undefined) {
				if (data["drillDownFilter"] != undefined) {
					var breadCrumbElement = $('.breadcrumb[data-num=' + currentChartNumber + ']')
					_.each(data["drillDownFilter"], function (value, index) {
						var appendValue = value.data;
						var ulEle = "";
						var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
						if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
							appendValue = breadCrumpElements["span"];
							ulEle = breadCrumpElements["ulelement"];
						}
						if (value["saved"] == "true") {
							breadCrumbElement.append("<li class= drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name=" + value.data + " class='close removedrilldown' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
						}
					});
				}
			}
		}
	}
	showdobyselectedcolumnsEve(msg, data) {
		this.showdobyselectedcolumns(data);
	}
	showdobyselectedcolumns(e) {
		var that = this;
		var currenttarget = e;
		var chartnumber = $(currenttarget).attr("data-num")
		$('.innerdobyselectedcolumns' + chartnumber).empty();
		var isColumnSelectionEnable = false;
		$.each(this.state.features, function (index, featuresModule) {
			if (featuresModule.moduleName == 'my_report') {
				$.each(featuresModule.features, function (index, feature) {
					if (feature.featureName == 'viewreport_maximize_data_table_column_selection' && feature.allow == true) {
						isColumnSelectionEnable = true;
					}
				})
			}
		});
		var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
		var parsedData;
		if ($(currenttarget).hasClass("closeselectedcolumns")) {
			$('.doby-offcanvas-columnlist[data-num="' + chartnumber + '"]').toggleClass("show");
		} else {
			$('.innerdobyselectedcolumns' + chartnumber).append("<li class='doby-list-item boc-list-item' ><input class='form-control form-control-dobycolumns search-image' placeholder=" + lang['storyboard.search'] + " id='searchinput' type='text'></li>");
			var reportnames = that.state.reportcolumns;
			var dobycolumnnames = that.state.reportcolumns.sort(function (a, b) {
				if (a.columnDisplayName.toLowerCase() < b.columnDisplayName.toLowerCase()) return -1;
				if (a.columnDisplayName.toLowerCase() > b.columnDisplayName.toLowerCase()) return 1;
				return 0;
			});
			_.each(dobycolumnnames, function (i, j) {
				var columnDisplayName;
				_.each(reportnames, function (names) {
					if (dobycolumnnames[j].columnName == names.columnName) {
						columnDisplayName = names.columnDisplayName;
					}
					else {
						columnDisplayName = dobycolumnnames[j].columnDisplayName;
					}
				});
				if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
					$.each(dobycolumnnames, function (index, data) {
						parsedData = JSON.parse(renameMeasuresorDimensions);
						if (parsedData[data.columnName] != undefined) {
							data['columnDisplayName'] = parsedData[data.columnName];
						}
					});
				}
				var columnName = dobycolumnnames[j].columnName;
				var datatype = dobycolumnnames[j].tableDisplayType;
				columnDisplayName = dobycolumnnames[j].columnDisplayName;
				if (isColumnSelectionEnable) {
					$('.innerdobyselectedcolumns' + chartnumber).append("<li class='doby-list-item boc-list-item' tite= '" + columnDisplayName + "' ><label><input type='checkbox' data-num=" + chartnumber + " class = 'filtercheckbox1' " + " name= '" + columnName + "' displayname='" + columnDisplayName + "'  datatype= '" + datatype + "' > </i>&nbsp;" + columnDisplayName + "</label></li>");
				} else {
					$('.innerdobyselectedcolumns' + chartnumber).append("<li class='doby-list-item boc-list-item' tite= '" + columnDisplayName + "' ><label><input type='checkbox' data-num=" + chartnumber + " class = 'filtercheckbox1' " + " name= '" + columnName + "' displayname='" + columnDisplayName + "'  datatype= '" + datatype + "' disabled='true'> </i>&nbsp;" + columnDisplayName + "</label></li>");
				}
			})
			$('.doby-offcanvas-columnlist[data-num="' + chartnumber + '"]').toggleClass("show");
			var that = this;
			$('.filtercheckbox1').click(function (event) {
				var $this = event.currentTarget;
				var yAxisNamesArray = [];
				yAxisNamesArray.push($($this).attr('name'))
				var chartnumber = $($this).attr('data-num')
				if (that.state.multifact == 'true') {
					var selecteddata = [];
					$.each(collection.getDimensions(), function (index1, filter) {
						//if(selecteddata.length >10){
						var name = filter.columnName;
						var temp = {};
						var disable = ChartInfo.functions.getAllowedEntitiesForInstantFilters(JSON.parse(that.state.multifactentities), name, undefined, yAxisNamesArray, undefined, that.state.facttables, "xdropdown");

						if (disable) {
							$('.innerdobyselectedcolumns' + chartnumber + ' .boc-list-item label input[displayname="' + name + '"]').closest('li').addClass('hide');
							$('.innerdobyselectedcolumns' + chartnumber + ' .boc-list-item label input[displayname="' + name + '"]').prop("checked", false);
						} else {
							$('.innerdobyselectedcolumns' + chartnumber + ' .boc-list-item label input[displayname="' + name + '"]').closest('li').removeClass('hide');
						}
						//}
					});
				}

				if ($(".innerdobyselectedcolumns" + chartnumber + " .filtercheckbox1:checked").length == 0) {
					$($this).prop("checked", true);
				}
			})
			var getdobyselecteddata = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
			$('.datatablerowlimit' + chartnumber + ' option[value=' + getdobyselecteddata["dobyrowlimit"] + ']').prop('selected', true);
			var dobyselecteddata = getdobyselecteddata["dobyselectedcolumns"];
			var xaxisname = getdobyselecteddata["xAxis"];
			if (dobyselecteddata === undefined) {
				dobyselecteddata = this.state.dobycolumns
				if (dobyselecteddata == undefined) {
					dobyselecteddata = xaxisname.displayName
				}
			}
			$(dobyselecteddata).each(function (i, j) {

				var displayname;
				if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null" && parsedData[dobyselecteddata[i].actualnames] != undefined) {
					displayname = parsedData[dobyselecteddata[i].actualnames].replace(/([#;?&,. +*~\':"!^$[\]()<=>|\/@])/g, "\\$1");
				} else {
					displayname = dobyselecteddata[i].displaynames.replace(/([#;?&,. +*~\':"!^$[\]()<=>|\/@])/g, "\\$1");
				}
				$('.innerdobyselectedcolumns' + chartnumber + ' .boc-list-item label input[displayname=' + displayname + ']').prop("checked", true)
			})
		}
		if (this.state.isDirect == 1 && this.state.isStoredProcedure == 1) {
			$(".innerdobyselectedcolumns" + chartnumber).parent().find('#datatablerowid').addClass("hide");
			$('.datatablerowlimit' + chartnumber).addClass('hide');
		} else {
			$(".innerdobyselectedcolumns" + chartnumber).parent().find('#datatablerowid').removeClass("hide");
			$('.datatablerowlimit' + chartnumber).removeClass('hide');
		}
		$('.form-control-dobycolumns').keyup(function (e) {
			var $this = e.currentTarget;
			var valThis = $($this).val().toLowerCase();
			$("input:checkbox[class='filtercheckbox1']").parents(".boc-list-item").each(
				function () {
					var text = $(this).text().toLowerCase();
					(text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
				});
		})
	}
	applydobysettingsEve(msg, data) {
		this.applydobysettings(data);
	}
	applydobysettings(e) {
		var currenttarget = e;
		var chartnumber = $(currenttarget).attr("data-num");
		var selecteddata = [];
		var counter = 0;
		var that = this;
		$('.innerdobyselectedcolumns' + chartnumber + ' .filtercheckbox1').each(function () {
			if ($(this).is(":checked")) {
				var temp = {};
				temp["actualnames"] = $(this).attr("name");
				temp["displaynames"] = $(this).attr("displayname");
				temp["datatype"] = $(this).attr("datatype");
				selecteddata.push(temp);
				if (that.state.multifact != undefined && that.state.multifact == 'true') {
					_.each(that.state.facttables, function (fact, j) {
						if (fact == temp["actualnames"].split(".")[0]) {
							counter++;
						}
					});
				}
			}
		});
		if (this.state.multifact != undefined && this.state.multifact == 'true' && counter <= 0) {
			$('.gridspinner').hide();
			$(".spinner").hide();
			$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Warning');
			$('.birdmessage-info').empty().text(getMessage('BirdWarning138'));
			return
		}
		this.state.dobycolumns = selecteddata;
		var chartData = ChartInfo.functions.getChartDetails(chartnumber);
		var data = JSON.parse(chartData);
		data["dobyselectedcolumns"] = selecteddata;
		data["dobyrowlimit"] = $('.datatablerowlimit' + chartnumber + ' option:selected').val();
		ChartInfo.functions.updateChartDetails(chartnumber, JSON.stringify(data));
		var viewDataTableObj = {};
		viewDataTableObj["currentChartNumber"] = chartnumber;
		viewDataTableObj["selectedcolumns"] = selecteddata;
		viewDataTableObj["state"] = "applymethod";
		viewDataTableObj["pos"] = "max";
		viewDataTableObj["popupwindow"] = undefined;
		viewDataTableObj["target"] = undefined;
		viewDataTableObj["applyattributes"] = undefined;
		viewDataTableObj["filters"] = undefined;
		viewDataTableObj["tableInfo"] = undefined;
		PubSub.publish("viewDataTable", viewDataTableObj);
		//this.broker.trigger("viewDataTable", chartnumber, selecteddata, "applymethod","max");
		if ($('.doby-offcanvas-columnlist[data-num="' + chartnumber + '"]').hasClass("show")) {
			$('.doby-offcanvas-columnlist[data-num="' + chartnumber + '"]').toggleClass("show");
		}
	}
}