import React, { useState,useEffect } from "react";
import { EditDataPreview } from "./editDataPreview";
import _ from "underscore";
import SendRequest from "../SendRequest";
const $=window.$;
export const  GetEditData = (props)=>{
    const entityId = atob(props.match.params.entityId)
    const [editData, setData] = useState({});
 useEffect(()=>{
    SendRequest({
        url: "getEditDataDetails",
        queryString: "?entityId=" + entityId,
        sucessFunction: (response) => {
            if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('hide');
                $('.birdmessage h2').empty().append('Error : ');
                $('.birdmessage h2').append('<span class="errorcode">');
                $('.birdmessage-info').empty().text(response.error);
                $('.details').addClass('show');
                $('.messagedetails xmp').empty().append(response.error)
                setTimeout(function () {
                    $('.birderror').remove();
                }, 3000);
            } else {
                setData(response);
                $(".spinner").hide();
            }
        },
            rejectFunction: (error) => { $(".spinner").hide(); },
            headers: {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
        
 },[])
 return( _.isEmpty(editData)?<h1 id="editdatapreviewempty" style={{ textAlign: 'center', color: '#212121' }}>Loading</h1>:<EditDataPreview editData={editData} {...props} />)
}