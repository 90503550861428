import React, { useState, useEffect } from "react";
import { lang } from "../../../js/helpers/utils/languages/language";
const $ = window.$;
export default class pageMessage extends React.Component  {
    constructor(props){
        super(props);
    }
    componentDidMount(){
        $('.spinner').hide();
    }
    render(){
        return <div id="pagemessage"><h1>{this.props.location.state!=undefined?this.props.location.state:lang["error.pagenotfound"]}</h1></div>;
    }
  }
 