import _ from 'lodash';
import React from "react";
import ReactDOM from "react-dom";
import PubSub from "pubsub-js"
import SendRequest from '../../SendRequest';
import * as ModalProperties from "../../js/helpers/utils/modalproperties";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import { lang } from "../../js/helpers/utils/languages/language";
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import PivotModalView from "./pivotmodalview"
import pivotChartSettingsModal from "./pivotchartsettingsmodal"
import BootStrapTable from "../../js/charts/bootstraptable";
import $ from "jquery/dist/jquery";
import pivot from "pivottable"


export default class PivotView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPagination: 0,
            chartnumber: props.chartNumber,
            customFields: props.customFields,
            reportId: props.reportId,
            features: props.features,
            categories: undefined,
            seriesdata: undefined,
            aggregation: undefined,
            valueFieldName: undefined,
            pos: props.pos,
            measures: props.measures,
            entities: props.entities,
            dimensions: props.dimensions,
            filterObject: props.filterArray,
            datamodelId: props.datamodelId,
            multifact: props.multifact,
            multifactentities: props.multifactentities
        }
        PubSub.unsubscribe("showPivotModal", this.showPivotModalEve.bind(this));
		PubSub.unsubscribe("redrawPivot", this.redrawPivotEve.bind(this));
		PubSub.unsubscribe('showPivotchart', this.showPivotchartEve.bind(this));
		PubSub.unsubscribe('loadPivotRows', this.loadPivotRowsEve.bind(this));
		PubSub.unsubscribe('showprevious', this.showpreviousEve.bind(this));
		PubSub.unsubscribe('shownext', this.shownextEve.bind(this));
		PubSub.unsubscribe('showPivotChartSettingsModal', this.showPivotChartSettingsModalEve.bind(this));
        $("#chartmetric"+this.state.chartnumber).remove();
        this.showPivotModalEvee = PubSub.subscribe("showPivotModal", this.showPivotModalEve.bind(this));
        this.state.el = "#chart" + this.state.chartnumber;
        this.state.isStoredProcedure = ChartInfo.functions.getIsStoredProcedure();
        this.state.isDirect = ChartInfo.functions.getIsDirect();
        this.redrawPivotEvee = PubSub.subscribe("redrawPivot", this.redrawPivotEve.bind(this));
        this.showPivotchartEvee = PubSub.subscribe('showPivotchart', this.showPivotchartEve.bind(this));
        this.loadPivotRowsEvee = PubSub.subscribe('loadPivotRows', this.loadPivotRowsEve.bind(this));
        this.showpreviousEvee = PubSub.subscribe('showprevious', this.showpreviousEve.bind(this));
        this.shownextEvee = PubSub.subscribe('shownext', this.shownextEve.bind(this));
        this.showPivotChartSettingsModalEvee = PubSub.subscribe('showPivotChartSettingsModal', this.showPivotChartSettingsModalEve.bind(this));
        this.render();
    }
    render() {
        $('#chart' + this.state.chartnumber).empty();
        $('#chart' + this.state.chartnumber).append('<div id="pivottable1" className="pivotcontainer">')
        this.updatePivot();
        return (<div></div>)
    }
    updatePivot() {
        var attropen = $(".rightattributes.open").length;
        var attrwidth = $(".rightattributes.open").width();
        if (attropen > 0) { attrwidth = attrwidth + 80 }
        $(".fullscreenmain #chart" + this.state.chartnumber + "").css("max-width", "calc(100vw - " + attrwidth + "px)");

        $("#pivotcolumns").hide();
        if (this.state.pos === "max") {
            $('.pivotsettingsmain' + this.state.chartnumber).show();
            $(".pivotcharticon" + this.state.chartnumber).show();
            $(".pivotnavigationbuttons" + this.state.chartnumber).show();
        } else {
            $('.pivotsettingsmain' + this.state.chartnumber).hide();
        }

        var chartData = ChartInfo.functions.getChartDetails(this.state.chartnumber);
        var data = JSON.parse(chartData);
        //			$("#chart"+this.state.chartnumber).prepend("<div class='metricchart h3'>Click "+'<button class="btn btn-bird btn-sm "><i class="fa fa-wrench fa-flip-horizontal"></i> </button>'+"  To Configure Pivot Table</div>");

        /*Show Pivot Message*/
        if (data['columns'] != undefined) {
            $(".pivotnavigationbuttons" + this.state.chartnumber + " .pivotmessage").remove();
        } else {
            $('.pivotcolumnmodal[data-num=' + this.state.chartnumber + ']').click();
            $(".pivotnavigationbuttons" + this.state.chartnumber + " .pivotmessage").remove();
            $(".pivotnavigationbuttons" + this.state.chartnumber).prepend("<div class='pivotmessage'>"+lang["storyboard.configpivot"]+"</div>");
        }
        /*\Show Pivot Message*/

        if (data['rows'] == undefined) {
            var dimension1 = undefined;
            _.each(this.state.dimensions, function (dimension) {
                if (dimension.tableDisplayType == '') {
                    dimension1 = dimension;
                    return;
                }
            });
            if (dimension1 == undefined) {
                $(".spinner").hide();
                $(".gridspinner").hide();
                return;
            }
            var rowsArray = [];
            /*$('#chart1').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">If you inactivate this group, all users under this group will be Inactivate</div>');
                   setTimeout(function() {
                    $('.birderror').remove();	
                }, 3000);*/
            var rows = {};

            rows["columnName"] = dimension1.columnName;
            rows["displayName"] = dimension1.columnDisplayName;
            rows["dataType"] = dimension1.tableDisplayType;
            rows["aggregation"] = "Count";
            rows["tableName"] = dimension1.tableName;
            rowsArray.push(rows);

            data['rows'] = rowsArray;
            data['columns'] = rowsArray;
            data['values'] = rowsArray;
            ChartInfo.functions.updateChartDetails(this.state.chartnumber, JSON.stringify(data));
            this.redrawPivot(this.state.chartnumber, "max");
        }
        else {
            this.redrawPivot(this.state.chartnumber, "max");
        }
    }
    redrawPivotEve(msg, data) {
        this.redrawPivot(data.chartNumber, data.pos);
    }
    redrawPivot(chartNumber, pos) {
        try {
            this.state.seriesdata = undefined;
            this.state.categories = undefined
            var isPublished='false';
		    if(this.props!=undefined){
			    isPublished=this.props.isPublished
		    }
            var chartData = ChartInfo.functions.getChartDetails(chartNumber);
            var data = JSON.parse(chartData);
            var rows = data['rows'];
            var columns = data['columns'];
            var values = data['values'];
            this.state.isImportedReport = false;
            if (sessionStorage.getItem("isImportedReport")|| window.location.pathname.indexOf("importreport") >= 0) {
                this.state.isImportedReport = true;
            }
            this.href = window.location.pathname;
            this.state.isPurgedReport = false;
            if (this.href.toLowerCase().indexOf("viewpurgereport") >= 0) {
                this.state.isPurgedReport = true;
            }
            if (this.href.toLowerCase().indexOf("sharereport") >= 0) {
                this.state.isSharedReport = true;
            }
            var that = this;
            var customFields = sessionStorage.getItem("customFields") == null || sessionStorage.getItem("customFields") == 'null' ? this.state.customFields : sessionStorage.getItem("customFields");
            if (customFields != null && customFields != undefined) {
                var temp = JSON.parse(customFields);
                var res;
                var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(this.props.applyattributes);
                _.each(temp, function (customfields) {
                    _.each(customfields.parametrFieldsArray, function (value) {
                        var customparameter = ChartInfo.functions.getCustomParameter();
                        if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                            customparameter = customParameterFilterArray;
                        }
                        res = $.grep(customparameter, function (e) {
                            return e.parametername == value;
                        })
                        var formula = customfields.formula;
                        var scriptcondition = customfields.scriptcondition;
                        var fields = customfields.fields;
                        var sqlformula = customfields.sqlformula;
                        if (res.length != 0) {
                            customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        } else {
                            _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                                if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                    customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                    customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                }
                            });
                        }
                    });
                });
                customFields = JSON.stringify(temp);
            }
            var isExcludeNulls = ChartInfo.functions.getIsExcludeNulls();
            var storymetaobj = {};
            storymetaobj["xdatesortingEnabled"] = data && data["xdatesortingEnabled"]!=undefined ? data["xdatesortingEnabled"]: false;
            var formdata = new FormData();
            formdata.set("reportId", that.state.reportId);
            formdata.set("rows", JSON.stringify(rows));
            formdata.set("columns", JSON.stringify(columns));
            formdata.set("values", JSON.stringify(values));
            formdata.set("script", customFields);
            formdata.set("filtervalues", that.state.filterObject);
            formdata.set("entities", that.state.entities);
            formdata.set("datamodelId", that.state.datamodelId);
            formdata.set("isExcludeNulls", isExcludeNulls);
            formdata.set("timeZone", that.props.timeZone);
            formdata.set("isPurgedReport", that.state.isPurgedReport);
            formdata.set("isImportedReport", that.state.isImportedReport);
            formdata.set("isPublished", ChartInfo.functions.getisPublished());
            formdata.set("storymetaobj", JSON.stringify(storymetaobj));

            if(chartNumber != undefined && chartNumber !='' && chartNumber !=null){
                var nlprowlimitobj = ChartInfo.functions.getNLPTableRowLimitDetails(chartNumber);
                if(nlprowlimitobj!=undefined && nlprowlimitobj!="" && nlprowlimitobj!=null)
                formdata.set("nlpDetailsObj", JSON.stringify(nlprowlimitobj));   
            }
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "getPivotData",
                body: formdata,
                sucessFunction: (response) => {
                    try {
                        var parsedData = response;
                        if (parsedData != undefined) {
                            response = JSON.parse(decodeURIComponent(parsedData));
                        }
                    } catch (e) {
                    }
                    if (response != undefined && response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage .errorcode').empty().append('');
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                        $('.pivotcolumnmodal[data-num=' + chartNumber + ']').click();
                        $(".pivotnavigationbuttons" + chartNumber + " .pivotmessage").remove();
                        $(".pivotnavigationbuttons" + chartNumber).prepend("<div class='pivotmessage'>"+lang["storyboard.configpivot"]+"</div>");
                    }
                    if (response[0] != undefined && response[0].hasOwnProperty('legendExceedDataInfo')) {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage("BirdWarning110"));
                    }
                    $(".spinner").hide();
                    $(".gridspinner").hide();
                    that.state.pivotdata1 = response;
                    that.redrawPivotOnChange(response);
                    if (that.state.isDirect == 1 && that.state.isStoredProcedure == 1 ) {
                        $(".viewvaluedata").removeClass("viewvaluedata");
                    }
                    if (that.state.isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true) {
                        $(".viewvaluedata").removeClass("viewvaluedata");
                    }
                    $('.viewvaluedata').click(function (e) {
                        e.stopImmediatePropagation();
                        e.stopPropagation();
                        that.viewValueData(e);
                    });
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        } catch (e) {
            console.log(e);
        }
        var charno = chartNumber;
        /*Show Pivot Message*/
        if (data['columns'] != undefined) {
            $(".pivotnavigationbuttons" + charno + " .pivotmessage").remove();
        } else {
            $('.pivotcolumnmodal[data-num=' + charno + ']').click();
            $(".pivotnavigationbuttons" + charno + " .pivotmessage").remove();
            $(".pivotnavigationbuttons" + charno).prepend("<div class='pivotmessage'>"+lang["storyboard.configpivot"]+"</div>");
        }
        /*\Show Pivot Message*/
        /*setTimeout(function(){
            if ($("#chart"+charno+" .pivotscroll").length==1 || $("#chart"+charno+" .highcharts-container").length==1){
                $(".pivotnavigationbuttons"+charno+" .pivotmessage").remove();
            }else {
                $(".pivotnavigationbuttons"+charno+" .pivotmessage").remove();
                $(".pivotnavigationbuttons"+charno).prepend("<div class='pivotmessage'>Configure Pivot Table</div>");
            }
        },3000);*/
        this.state.chartnumber = chartNumber;
    }
    redrawPivotOnChange(response) {
        var chartData = ChartInfo.functions.getChartDetails(this.state.chartnumber);
        var data = JSON.parse(chartData);
        var rowsArray = [];
        var colsArray = [];
        var valuesArray = [];
        var that = this;
        var parsedData;
        var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
        if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
            parsedData = JSON.parse(renameMeasuresorDimensions);
        }
        _.each(data.rows, function (row) {
            if (parsedData != undefined && parsedData != null && parsedData[row.columnName] != undefined) {
                rowsArray.push(parsedData[row.columnName]);
            } else {
                rowsArray.push(row.displayName);
            }
        });
        _.each(data.columns, function (column) {
            if (parsedData != undefined && parsedData != null && parsedData[column.columnName] != undefined) {
                colsArray.push(parsedData[column.columnName]);
            } else {
                colsArray.push(column.displayName);
            }
        });
        _.each(data.values, function (value) {
            if (parsedData != undefined && parsedData != null && parsedData[value.columnName] != undefined) {
                valuesArray.push(parsedData[value.columnName]);
            } else {
                valuesArray.push(value.displayName);
            }
        });
        var sum = $.pivotUtilities.aggregatorTemplates.sum;
        var numberFormat = $.pivotUtilities.numberFormat;
        var intFormat = numberFormat({ digitsAfterDecimal: 2 });

        $("#chart" + this.state.chartnumber).empty();
        $("#chart" + this.state.chartnumber).append("<div class='pivotscroll'></div>");

        $("#chart" + this.state.chartnumber + " .pivotscroll").pivot(response[0], {
            rows: rowsArray,
            cols: colsArray,
            aggregator: sum(intFormat)(valuesArray),
            sorted: true
        });
        var number = parseInt($(".numberofpivotrows[data-num='" + this.state.chartnumber + "']").val());
        var count = 0;
        var tempCount = 0;
        _.each($('.birdRow'), function (list, k) {
            if ($(list).find('#rowcount').text().length > 0 && count <= number) {
                count++;
                that.state.currentPagination = k;
                tempCount = k;
            }
            tempCount = k;
        });
        if (count < number) {
            that.state.currentPagination = tempCount + 1;
        }
        $(".prev").prop('disabled', true);
        $('.spinner').hide();
        $("#spinnerchart" + this.state.chartnumber).hide();
        $("#spinner" + this.state.chartNumber).hide();
        var totalrecords = $("#chart" + this.state.chartnumber + " .birdRow").length
        if (this.state.currentPagination >= totalrecords) {
            $(".next").prop('disabled', true);
        }
        else {
            $(".next").prop('disabled', false);
        }
        $("#chart" + this.state.chartnumber + " .birdRow").slice(0, this.state.currentPagination).show();
        if ($('.tileview[data-num=' + this.state.chartnumber + ']').length > 0) {
            var setheight = $('.tileview[data-num=' + this.state.chartnumber + ']').height() + -100;
            $("#chart" + this.state.chartnumber + " .pivotscroll").css("max-height", setheight);
        }
    }
    showPivotModalEve(msg, data) {
        this.showPivotModal(data);
    }
    showPivotModal(chartNumber) {

        var pivotmodalView = new PivotModalView({
            chartnumber: chartNumber,
            measures: this.state.measures,
            dimensions: this.state.dimensions,
            datamodelId: this.state.datamodelId,
            entities: this.state.entities,
            facttables: this.props.facttables,
            timeZone: this.props.timeZone,
            multifact: this.state.multifact,
            multifactentities: this.state.multifactentities,
        });
        document.body.classList.add("modal-open");
        // $(".pivotcolumnmodal"+chartNumber).append(document.querySelector(".pivotcolumnmodal"+chartNumber));
        ModalProperties.resize();
    }
    showPivotchartEve(msg, data) {
        this.showPivotchart(data.chartNumber, data.chartType);
    }
    showPivotchart(chartNumber, chartType) {
       
        if (this.state.seriesdata == undefined || this.state.categories == undefined) {
            this.getChartData(chartNumber);
        }
        if( this.state.categories.length<=0){
            return
        }
        $(".pivotsettingsmain" + this.state.chartnumber).hide();
        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(chartNumber));
        var chartData = ChartInfo.functions.getChartDetails(chartNumber);
        var parsePivotJson = $.parseJSON(chartData)
        var colorThemeIndex = parsePivotJson.colorThemeIndex;
        if (colorThemeIndex == undefined) {
            colorThemeIndex = 0;
        }
        var chartSettings = chartDetails['pivotChartSettings'];
        if (chartSettings == undefined) {
            chartSettings = { 'chartTitle': true, 'legend': false, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true };
        }
        if (chartSettings['chartTitle'] == true) {
            //$(".boc-viewreport-title[data-num="+this.props.chartnumber+"]").show();
            $(".maxleftpanehidden[data-num=" + this.state.chartnumber + "]").removeClass('hide');
            $(".boc-snapshot-title[data-num=" + this.props.chartnumber + "]").removeClass('invisible');
        } else {
            //$(".boc-viewreport-title[data-num="+this.props.chartnumber+"]").hide();
            $(".maxleftpanehidden[data-num=" + this.state.chartnumber + "]").addClass('hide');
            $(".boc-snapshot-title[data-num=" + this.props.chartnumber + "]").addClass('invisible');
        }
        var yAxisNameArray = [];
        var xaxisName = "";
        _.each(chartDetails["values"], function (val) {
            yAxisNameArray.push(val.displayName);
        });
        _.each(chartDetails["rows"], function (xaxisname) {
            xaxisName += xaxisname.displayName + ",";
        });
        xaxisName = xaxisName.substring(0, xaxisName.length - 1);
        var number = 10;
        if (parsePivotJson.rows.length == 1) {
            number = this.state.categories.length < number ? this.state.categories.length - 1 : number;
        }
        if (parsePivotJson.rows.length > 1) {
            number = this.state.categories[0].categories.length < number ? this.state.categories.length - 1 : number;
        }
        var type = "column";
        if (chartType == "pivotbar") {
            type = "column";
            ChartUtil.drawPivotChart(this.state.seriesdata, this.state.categories, xaxisName, yAxisNameArray, "max", "chart" + chartNumber, type, colorThemeIndex, chartSettings, number, this.state.aggregation, this.state.valueFieldName);
            $('#spinnerchart' + chartNumber).hide();
        } else if (chartType == "pivotline") {
            type = "line";
            ChartUtil.drawPivotChart(this.state.seriesdata, this.state.categories, xaxisName, yAxisNameArray, "max", "chart" + chartNumber, type, colorThemeIndex, chartSettings, number, this.state.aggregation, this.state.valueFieldName);
        } else if (chartType == "pivotarea") {
            type = "area";
            ChartUtil.drawPivotChart(this.state.seriesdata, this.state.categories, xaxisName, yAxisNameArray, "max", "chart" + chartNumber, type, colorThemeIndex, chartSettings, number, this.state.aggregation, this.state.valueFieldName);
        }
        else if (chartType == "pivotstackedbar") {
            type = "pivotstackedbar";
            ChartUtil.drawPivotChart(this.state.seriesdata, this.state.categories, xaxisName, yAxisNameArray, "max", "chart" + chartNumber, type, colorThemeIndex, chartSettings, number, this.state.aggregation, this.state.valueFieldName);
        }
        else if (chartType == "pivotstackedarea") {
            type = "pivotstackedarea";
            ChartUtil.drawPivotChart(this.state.seriesdata, this.state.categories, xaxisName, yAxisNameArray, "max", "chart" + chartNumber, type, colorThemeIndex, chartSettings, number, this.state.aggregation, this.state.valueFieldName);
        }
        else if (chartType == "pivot") {
            $(".pivotsettingsmain" + chartNumber).show();
            $(".pivotnavigationbuttons" + chartNumber).show();
            this.redrawPivot(chartNumber, "max");
        }
        else {
            ChartUtil.drawPivotChart(this.state.seriesdata, this.state.categories, xaxisName, yAxisNameArray, "max", "chart" + chartNumber, type, colorThemeIndex, chartSettings, number, this.state.aggregation, this.state.valueFieldName);
        }
        $('#spinnerchart' + chartNumber).hide();
    }
    getChartData(chartnumber) {
        var chartData = ChartInfo.functions.getChartDetails(chartnumber);
        var data = JSON.parse(chartData);
        var rows = data['rows'];
        var columns = data['columns'];
        var values = data['values'];

        var series = [];
        var seriesData = [];
        var aggType = "";

        var colLength = columns.length;

        var colfield1 = columns[0]["displayName"];
        var colfield2 = "";
        var colfield3 = "";
        if (colLength == 2) {
            colfield2 = columns[1]["displayName"];
        }
        if (colLength == 3) {
            colfield2 = columns[1]["displayName"];
            colfield3 = columns[2]["displayName"];
        }

        var rowLength = rows.length;

        var rowfield1 = rows[0]["displayName"];
        var rowfield2 = "";
        var rowfield3 = "";
        if (rowLength == 2) {
            rowfield2 = rows[1]["displayName"];
        }
        if (rowLength == 3) {
            rowfield2 = rows[1]["displayName"];
            rowfield3 = rows[2]["displayName"];
        }
        var valuefield = values[0]["displayName"];
        this.state.valueFieldName = valuefield;

        var that = this;
        aggType = that.state.pivotdata1[1]["aggType"];
        _.each(that.state.pivotdata1[0], function (value) {
            var combination = value[colfield1];
            if (colLength == 2) {
                combination += "-" + value[colfield2];
            }
            if (colLength == 3) {
                combination += "-" + value[colfield2] + "-" + value[colfield3];
            }

            if (series.indexOf(combination) < 0) {
                series.push(combination);
                var finalseriesdata = { name: " ", data: [] };
                finalseriesdata.name = combination;
                seriesData.push(finalseriesdata);
            }

        });

        seriesData.sort(function (a, b) {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            else
                return 0;
        });
        var jsonarr = null;
        var categories = [];
        var temp = [];
        var name = null;
        var currentdata = "";
        var key = [];
        if (rows.length == 2) {
            $("#chart" + that.state.chartnumber + ' .birdRow').find('#rowcount').each(function (m, n) {
                var name = $(n).attr('name');
                var count = 0;
                var categories = [];
                jsonarr = {};
                if (name != undefined && name != null && name != "null" && name.length > 0) {
                    $("#chart" + that.state.chartnumber + ' .birdRow[name="' + name.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1") + '"]').each(function (i, j) {
                        if (count == 0) {
                            categories.push($(j).find(':eq(1)').attr('name'))
                            count++;
                        } else {
                            categories.push($(j).find(':eq(0)').attr('name'))
                            count++;
                        }
                    });
                    jsonarr['name'] = name;
                    jsonarr['categories'] = categories
                    key.push(jsonarr);
                }
            });
        } else if (rows.length == 3) {
            $("#chart" + that.state.chartnumber + ' .birdRow').find('#rowcount').each(function (m, n) {
                var name = $(n).attr('name');
                var count = 0;
                var categories = [];
                var jsonarr = {};
                var jsonarr1 = [];
                var tempcount = 0
                if (name != undefined && name != null && name != "null" && name.length > 0) {
                    $("#chart" + that.state.chartnumber + ' .birdRow[name="' + name.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1") + '"]').each(function (i, j) {
                        var name1;
                        var categories1 = [];
                        var jsonarr2 = {};
                        var bool = false;
                        if (tempcount == 0) {
                            name1 = $(j).find(':eq(1)').attr('name')
                            tempcount = 1
                        } else {
                            name1 = $(j).find(':eq(0)').attr('name')
                        }
                        $("#chart" + that.state.chartnumber + ' .birdRow[name="' + name + '"] #' + name1.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1")).each(function (i, j) {
                            if (i != 0)
                                categories1.push($(j).attr('name'))
                            bool = true;
                        })
                        if (bool) {
                            jsonarr2['name'] = name1;
                            jsonarr2["categories"] = categories1;
                            jsonarr1.push(jsonarr2);
                        }
                    });

                    jsonarr['name'] = name;
                    jsonarr['categories'] = jsonarr1
                    key.push(jsonarr);
                }
            });
        } else {
            $("#chart" + that.state.chartnumber + ' .birdRow').find('#rowcount').each(function (m, n) {
                key.push($(n).attr('name'))
            });
        }

        var columnLength = seriesData.length;
        var i = 0;
        $("#chart" + this.state.chartnumber + " .birdRow").find("td").each(function (row, val) {
            var text = $(val).text();
            if (i < columnLength) {
                if (text.length <= 0) {
                    text = null;
                }
                if (text != null) {
                    text = text.replace(',', '').replace(',', '')
                    seriesData[i].data.push(parseFloat(text));
                } else {
                    seriesData[i].data.push(text);
                }
                i++;
            }
            else {
                i = 0;
            }
        });
        this.state.seriesdata = seriesData;
        this.state.aggregation = aggType;
        this.state.categories = key;
    }
    loadPivotRowsEve(msg, data) {
        this.loadPivotRows(data);
    }
    loadPivotRows(chartnumber) {
        if (chartnumber == this.state.chartnumber) {
            var that = this;
            $(".prev").prop('disabled', true);
            $("#chart" + this.state.chartnumber + " .birdRow").hide();
            var number = parseInt($(".numberofpivotrows[data-num='" + this.state.chartnumber + "']").val());
            this.state.currentPagination = number;
            var totalrecords = $("#chart" + this.state.chartnumber + " .birdRow").length;
            var count = 0;
            var tempCount = 0;
            _.each($('.birdRow'), function (list, k) {
                if ($(list).find('#rowcount').text().length > 0 && count <= number) {
                    count++;
                    that.state.currentPagination = k;
                    tempCount = k;
                }
                tempCount = k;
            });
            if (count <= number) {
                that.state.currentPagination = tempCount + 1;
            }
            $("#chart" + this.state.chartnumber + " .birdRow").slice(0, this.state.currentPagination).show();
            if (this.state.currentPagination >= totalrecords) {
                $(".next").prop('disabled', true);
            }
            else {
                $(".next").prop('disabled', false);
            }
        }
    }
    showpreviousEve(msg, data) {
        this.loadPrevious(data);
    }
    loadPrevious(chartnumber) {
        if (chartnumber == this.state.chartnumber) {
            var that = this;
            $(".next").prop('disabled', false);
            var tempPagNumber = this.state.previouspagination;
            $("#chart" + this.state.chartnumber + " .birdRow").hide();
            var number = parseInt($(".numberofpivotrows[data-num='" + this.state.chartnumber + "']").val());
            this.state.currentPagination = this.state.previouspagination
            //				if(tempPagNumber == 0){
            //					$(".prev").prop('disabled', true);
            //				}
            var count = 1;
            tempPagNumber = tempPagNumber - 1;
            $('.birdRow').each(function (list, k) {
                if ($('.birdRow').eq(tempPagNumber).find('#rowcount').text().length > 0 && count <= number) {
                    count++;
                }
                if (tempPagNumber == 0) {
                    return;
                }
                if (count <= number)
                    tempPagNumber--;

            });
            $("#chart" + this.state.chartnumber + " .birdRow").slice(tempPagNumber, this.state.previouspagination).show();
            this.state.previouspagination = tempPagNumber
            if (tempPagNumber == 0) {
                $(".prev").prop('disabled', true);
            }
        }
    }
    shownextEve(msg, data) {
        this.loadNext(data);
    }
    loadNext(chartnumber) {
        if (chartnumber == this.state.chartnumber) {
            var that = this;
            $(".prev").prop('disabled', false);
            var tempPagNumber = this.state.currentPagination;
            $("#chart" + this.state.chartnumber + " .birdRow").hide();
            var number = parseInt($(".numberofpivotrows[data-num='" + this.state.chartnumber + "']").val());
            this.state.currentPagination = this.state.currentPagination + number;
            var totalrecords = $("#chart" + this.state.chartnumber + " .birdRow").length;
            if (this.state.currentPagination >= totalrecords) {
                $(".next").prop('disabled', true);
            }
            var count = 1;
            $('.birdRow').each(function (list, k) {
                if ($(k).find('#rowcount').text().length > 0 && count <= number && list > tempPagNumber) {
                    count++;
                    that.state.currentPagination = list+1;
                }
            });
            this.state.previouspagination = tempPagNumber;
            $("#chart" + this.state.chartnumber + " .birdRow").slice(tempPagNumber, this.state.currentPagination).show();
        }
    }
    showPivotChartSettingsModalEve(msg, data) {
        this.showPivotChartSettingsModal(data);
    }
    showPivotChartSettingsModal(chartNumber) {
        var that = this;
        var charttype = $('.pivotcharttypes').attr('data-type');
        var PivotChartSettingsModal = new pivotChartSettingsModal({
            chartnumber: chartNumber,
            measures: that.state.measures,
            dimensions: that.state.dimensions,
            charttype: charttype,
            multifact: that.state.multifact,
            multifactentities: that.state.multifactentities,
        });
        document.body.classList.add("modal-open");
        ModalProperties.resize();
    }
    viewValueData(e) {
        var $this = e.currentTarget;
        e.stopPropagation();
        var isDataBehindPivotEnabled = false;
        $.each(this.state.features, function (index, module) {
            if (module.moduleName == 'my_report') {
                $.each(module.features, function (index, feature) {
                    if (feature.featureName == 'viewreport_maximize_data_behind_pivot' && feature.allow == true) {
                        isDataBehindPivotEnabled = true;
                    }
                });
            }
        });
        if (isDataBehindPivotEnabled) {
            var that = this;
            var colData = $($this).attr("col-data").split("###");
            var rowData = $($this).attr("row-data").split("###");
            var chartNumber = this.state.chartnumber;
            if (chartNumber == undefined) {
                chartNumber = this.props.chartNumber;
            }
            var chartData = ChartInfo.functions.getChartDetails(chartNumber);
            var data = JSON.parse(chartData);
            var rowlimit = data["dobyrowlimit"];
            var rowsArray = [];
            var colsArray = [];
            var valuesArray = [];
            var chartDobyColumns = [];
            var filterArray = JSON.parse(this.state.filterObject);
            var finalCustomFields = [];
            _.each(filterArray, function (filtercolumn, index) {
                var isCustomField = false;
                var customFieldIndex = 0;
                var customFieldsArray;
                customFieldsArray = (sessionStorage.getItem("customFields") == null || sessionStorage.getItem("customFields") == 'null') && that.state.customFields != undefined ? that.state.customFields : sessionStorage.getItem("customFields");
                var temp = JSON.parse(customFieldsArray);
                var res;
                var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(that.props.applyattributes);
                _.each(temp, function (customfield) {
                    _.each(customfield.parametrFieldsArray, function (value) {
                        var customparameter = ChartInfo.functions.getCustomParameter();
                        if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                            customparameter = customParameterFilterArray;
                        }
                        res = $.grep(customparameter, function (e) {
                            return e.parametername == value;
                        })
                        var formula = customfield.formula;
                        var scriptcondition = customfield.scriptcondition;
                        var fields = customfield.fields;
                        var sqlformula = customfield.sqlformula;
                        if (res.length != 0) {
                            customfield.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfield.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfield.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfield.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        } else {
                            _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                                if (customfield.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                    customfield.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfield.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfield.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                    customfield.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                }
                            });
                        }
                    });
                });
                customFieldsArray = JSON.stringify(temp);
                var customFieldsParsedArray = [];
                if (customFieldsArray != "null" && customFieldsArray != undefined) {
                    customFieldsParsedArray = JSON.parse(customFieldsArray);
                }
                $.each(customFieldsParsedArray, function (index, customField) {
                    if (customField.name == filtercolumn["columnname"]) {
                        isCustomField = true;
                        customFieldIndex = index;
                    }
                });

                var object = {
                    "actualnames": filtercolumn["columnname"],
                    "displaynames": filtercolumn["columndisplayname"],
                    "datatype": filtercolumn["dataType"]
                };
                var customFieldObject = {};
                if (isCustomField == true) {
                    customFieldObject = customFieldsParsedArray[customFieldIndex];
                    var convertedTypes = sessionStorage.getItem("convertedtypes");
                    object["datatype"] = "string";
                    if (convertedTypes != undefined) {
                        var parsedValues = JSON.parse(convertedTypes);
                        _.each(parsedValues, function (parsedValue) {
                            if (parsedValue["columnName"] == customFieldObject["name"]) {
                                object["datatype"] = parsedValue["dataType"];
                            }
                        });
                    }
                    finalCustomFields.push(customFieldObject);
                }


            });
            _.each(data.rows, function (row, index) {
                rowsArray.push(row.displayName);
                var isCustomField = false;
                var customFieldIndex = 0;
                var customFieldsArray;
                customFieldsArray = (sessionStorage.getItem("customFields") == null || sessionStorage.getItem("customFields") == 'null') && that.state.customFields != undefined ? that.state.customFields : sessionStorage.getItem("customFields")
                var temp = JSON.parse(customFieldsArray);
                var res;
                var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(that.props.applyattributes);
                _.each(temp, function (customfield) {
                    _.each(customfield.parametrFieldsArray, function (value) {
                        var customparameter = ChartInfo.functions.getCustomParameter();
                        if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                            customparameter = customParameterFilterArray;
                        }
                        res = $.grep(customparameter, function (e) {
                            return e.parametername == value;
                        })
                        var formula = customfield.formula;
                        var scriptcondition = customfield.scriptcondition;
                        var fields = customfield.fields;
                        var sqlformula = customfield.sqlformula;
                        if (res.length != 0) {
                            customfield.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfield.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfield.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfield.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        } else {
                            _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                                if (customfield.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                    customfield.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfield.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfield.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                    customfield.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                }
                            });
                        }
                    });
                });
                customFieldsArray = JSON.stringify(temp);
                var customFieldsParsedArray = [];
                if (customFieldsArray != "null" && customFieldsArray != undefined) {
                    customFieldsParsedArray = JSON.parse(customFieldsArray);
                }
                $.each(customFieldsParsedArray, function (index, customField) {
                    if (customField.name == row["columnName"]) {
                        isCustomField = true;
                        customFieldIndex = index;
                    }
                });
                var object = {
                    "actualnames": row["columnName"],
                    "displaynames": row["displayName"],
                    "datatype": row["dataType"]
                };
                var customFieldObject = {};
                if (isCustomField == true) {
                    customFieldObject = customFieldsParsedArray[customFieldIndex];
                    var convertedTypes = sessionStorage.getItem("convertedtypes");
                    object["datatype"] = "string";
                    if (convertedTypes != undefined) {
                        var parsedValues = JSON.parse(convertedTypes);
                        _.each(parsedValues, function (parsedValue) {
                            if (parsedValue["columnName"] == customFieldObject["name"]) {
                                object["datatype"] = parsedValue["dataType"];
                            }
                        });
                    }
                    finalCustomFields.push(customFieldObject);
                    if (that.state.multifact != undefined && that.state.multifact == "true") {
                        _.each(customFieldObject.usedFields, function (usedField) {
                            var customObject = {
                                "actualnames": usedField,
                                "displaynames": usedField,
                                "datatype": customFieldObject.dataType
                            };
                            chartDobyColumns.push(customObject);
                        });
                    }
                } else {
                    chartDobyColumns.push(object);
                }
                var filterObject = {};
                filterObject["dataType"] = row["dataType"];
                filterObject["tablename"] = row["tableName"];
                filterObject["columndisplayname"] = row["displayName"];
                filterObject["columnname"] = row["columnName"];
                if (isCustomField == true) {
                    filterObject["dataType"] = row["dataType"];
                    //row["dataType"]="string";
                    var convertedTypes = sessionStorage.getItem("convertedtypes");
                    if (convertedTypes != undefined) {
                        var parsedValues = JSON.parse(convertedTypes);
                        _.each(parsedValues, function (parsedValue) {
                            if (parsedValue["columnName"] == customFieldObject["name"]) {
                                filterObject["dataType"] = parsedValue["dataType"];
                                row["dataType"] = parsedValue["dataType"];
                            }
                        });
                    }
                }
                if (row["dataType"] == "date") {
                    var dateString = rowData[index];
                    filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                    //filterObject["toRange"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                    if (isCustomField == true) {
                        var sqlFilterObject = {};
                        sqlFilterObject["sqldata"] = dateString;
                        //sqlFilterObject["toRange"] = dateString;
                        var fromdate = Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                        var todate = Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                        var customFieldObject = customFieldsParsedArray[customFieldIndex];
                        var finalScriptCondition = customFieldObject.scriptcondition;
                        finalScriptCondition = ";" + "if( new Date(" + finalScriptCondition + ") >= new Date(" + fromdate + ")" + " &&  new Date(" + finalScriptCondition + ") <= new Date(" + todate + ")) return true else false";
                        customFieldObject["scriptcondition"] = finalScriptCondition;
                        customFieldObject["sqlcondition"] = sqlFilterObject;
                        customFieldObject["parameter"] = dateString;
                        filterObject["customfield"] = customFieldObject;
                    }
                } else if (row["dataType"] == "number") {
                    filterObject["filtergreaterthanvalue"] = rowData[index];
                    filterObject["filterlessthanvalue"] = rowData[index];
                    if (isCustomField == true) {
                        var sqlFilterObject = {};
                        sqlFilterObject["filtergreaterthanvalue"] = rowData[index];
                        sqlFilterObject["filterlessthanvalue"] = rowData[index];
                        var filtergreaterthanvalue = rowData[index];
                        var filterlessthanvalue = rowData[index];
                        var customFieldObject = customFieldsParsedArray[customFieldIndex];
                        var finalScriptCondition = customFieldObject.scriptcondition;
                        finalScriptCondition = ";" + "if(" + finalScriptCondition + ">=" + filtergreaterthanvalue + "&&" + finalScriptCondition + "<=" + filterlessthanvalue + ") return true else false";
                        if (filtergreaterthanvalue != "" || filterlessthanvalue != "") {
                            customFieldObject["scriptcondition"] = finalScriptCondition;
                            customFieldObject["sqlcondition"] = sqlFilterObject;
                            customFieldObject["parameter"] = null;
                            filterObject["customfield"] = customFieldObject;
                            filterArray.push(filterObject);
                        }
                    }
                } else {
                    if (isCustomField == true) {
                        var finalScriptCondition = customFieldObject.scriptcondition;
                        finalScriptCondition = ";" + "if((" + finalScriptCondition + ").equals(parameter)) return true else false";
                        customFieldObject["scriptcondition"] = finalScriptCondition;
                        customFieldObject["parameter"] = rowData[index];
                        var sqlFilterObject = {};
                        sqlFilterObject["sqldata"] = rowData[index];
                        customFieldObject["sqlcondition"] = sqlFilterObject;
                        filterObject["dataType"] = row["dataType"];
                        filterObject["customfield"] = customFieldObject;
                    } else {
                        filterObject["data"] = rowData[index];
                    }
                }
                filterArray.push(filterObject);
            });
            _.each(data.columns, function (column, index) {
                var isCustomField = false;
                var customFieldIndex = 0;
                var customFieldsArray = (sessionStorage.getItem("customFields") == null || sessionStorage.getItem("customFields") == 'null') && that.state.customFields != undefined ? that.state.customFields : sessionStorage.getItem("customFields")
                var temp = JSON.parse(customFieldsArray);
                var res;
                var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(that.props.applyattributes);
                _.each(temp, function (customfield) {
                    _.each(customfield.parametrFieldsArray, function (value) {
                        var customparameter = ChartInfo.functions.getCustomParameter();
                        if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                            customparameter = customParameterFilterArray;
                        }
                        res = $.grep(customparameter, function (e) {
                            return e.parametername == value;
                        })
                        var formula = customfield.formula;
                        var scriptcondition = customfield.scriptcondition;
                        var fields = customfield.fields;
                        var sqlformula = customfield.sqlformula;
                        if (res.length != 0) {
                            customfield.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfield.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfield.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfield.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        } else {
                            _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                                if (customfield.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                    customfield.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfield.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfield.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                    customfield.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                }
                            });
                        }
                    });
                });
                customFieldsArray = JSON.stringify(temp);
                var customFieldsParsedArray = [];
                if (customFieldsArray != "null" && customFieldsArray != undefined) {
                    customFieldsParsedArray = JSON.parse(customFieldsArray);
                }
                $.each(customFieldsParsedArray, function (index, customField) {
                    if (customField.name == column["columnName"]) {
                        isCustomField = true;
                        customFieldIndex = index;
                    }
                });
                colsArray.push(column.displayName);
                var object = {
                    "actualnames": column["columnName"],
                    "displaynames": column["displayName"],
                    "datatype": column["dataType"]
                };
                var customFieldObject = {};
                if (isCustomField == true) {
                    customFieldObject = customFieldsParsedArray[customFieldIndex];
                    var convertedTypes = sessionStorage.getItem("convertedtypes");
                    //object["datatype"]="string";
                    if (convertedTypes != undefined) {
                        var parsedValues = JSON.parse(convertedTypes);
                        _.each(parsedValues, function (parsedValue) {
                            if (parsedValue["columnName"] == customFieldObject["name"]) {
                                object["datatype"] = parsedValue["dataType"];
                            }
                        });
                    }
                    finalCustomFields.push(customFieldObject);
                    if (that.state.multifact != undefined && that.state.multifact == "true") {
                        _.each(customFieldObject.usedFields, function (usedField) {
                            var customObject = {
                                "actualnames": usedField,
                                "displaynames": usedField,
                                "datatype": customFieldObject.dataType
                            };
                            chartDobyColumns.push(customObject);
                        });
                    }
                } else {
                    chartDobyColumns.push(object);
                }


                var filterObject = {};
                filterObject["dataType"] = column["dataType"];
                filterObject["tablename"] = column["tableName"];
                filterObject["columndisplayname"] = column["displayName"];
                filterObject["columnname"] = column["columnName"];

                if (isCustomField == true) {
                    filterObject["dataType"] = column["dataType"];
                    //column["dataType"]="string";
                    var convertedTypes = sessionStorage.getItem("convertedtypes");
                    if (convertedTypes != undefined) {
                        var parsedValues = JSON.parse(convertedTypes);
                        _.each(parsedValues, function (parsedValue) {
                            if (parsedValue["columnName"] == customFieldObject["name"]) {
                                filterObject["dataType"] = parsedValue["dataType"];
                                column["dataType"] = parsedValue["dataType"];
                            }
                        });
                    }
                }
                if (column["dataType"] == "date") {
                    var dateString = colData[index];
                    filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                    //filterObject["toRange"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                    if (isCustomField == true) {
                        var sqlFilterObject = {};
                        sqlFilterObject["sqldata"] = dateString;
                        //sqlFilterObject["toRange"] = dateString;
                        var fromdate = Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                        var todate = Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                        var customFieldObject = customFieldsParsedArray[customFieldIndex];
                        var finalScriptCondition = customFieldObject.scriptcondition;
                        finalScriptCondition = ";" + "if( new Date(" + finalScriptCondition + ") >= new Date(" + fromdate + ")" + " &&  new Date(" + finalScriptCondition + ") <= new Date(" + todate + ")) return true else false";
                        customFieldObject["scriptcondition"] = finalScriptCondition;
                        customFieldObject["sqlcondition"] = sqlFilterObject;
                        customFieldObject["parameter"] = dateString;
                        filterObject["customfield"] = customFieldObject;
                    }

                } else if (column["dataType"] == "number") {
                    filterObject["filtergreaterthanvalue"] = colData[index];
                    filterObject["filterlessthanvalue"] = colData[index];
                    if (isCustomField == true) {
                        var filtergreaterthanvalue = colData[index];
                        var filterlessthanvalue = colData[index];
                        var customFieldObject = customFieldsParsedArray[customFieldIndex];
                        var finalScriptCondition = customFieldObject.scriptcondition;
                        finalScriptCondition = ";" + "if(" + finalScriptCondition + ">=" + filtergreaterthanvalue + "&&" + finalScriptCondition + "<=" + filterlessthanvalue + ") return true else false";
                        if (filtergreaterthanvalue != "" || filterlessthanvalue != "") {
                            var sqlFilterObject = {};
                            sqlFilterObject["filtergreaterthanvalue"] = colData[index];
                            sqlFilterObject["filterlessthanvalue"] = colData[index];
                            customFieldObject["scriptcondition"] = finalScriptCondition;
                            customFieldObject["sqlcondition"] = sqlFilterObject;
                            customFieldObject["parameter"] = null;
                            filterObject["customfield"] = customFieldObject;
                            filterArray.push(filterObject);
                        }
                    }
                } else {
                    if (isCustomField == true) {
                        var finalScriptCondition = customFieldObject.scriptcondition;
                        finalScriptCondition = ";" + "if((" + finalScriptCondition + ").equals(parameter)) return true else false";
                        customFieldObject["scriptcondition"] = finalScriptCondition;
                        customFieldObject["parameter"] = colData[index];
                        var sqlFilterObject = {};
                        sqlFilterObject["sqldata"] = colData[index];
                        customFieldObject["sqlcondition"] = sqlFilterObject;
                        filterObject["dataType"] = column["dataType"];
                        filterObject["customfield"] = customFieldObject;
                    } else {
                        filterObject["data"] = colData[index];
                    }
                }
                filterArray.push(filterObject);
            });
            _.each(data.values, function (value) {
                var isCustomField = false;
                var customFieldIndex = 0;
                var customFieldsArray = (sessionStorage.getItem("customFields") == null || sessionStorage.getItem("customFields") == 'null') && that.state.customFields != undefined ? that.state.customFields : sessionStorage.getItem("customFields")

                var temp = JSON.parse(customFieldsArray);
                var res;
                var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(that.props.applyattributes);
                _.each(temp, function (customfield) {
                    _.each(customfield.parametrFieldsArray, function (value) {
                        var customparameter = ChartInfo.functions.getCustomParameter();
                        if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                            customparameter = customParameterFilterArray;
                        }
                        res = $.grep(customparameter, function (e) {
                            return e.parametername == value;
                        })
                        var formula = customfield.formula;
                        var scriptcondition = customfield.scriptcondition;
                        var fields = customfield.fields;
                        var sqlformula = customfield.sqlformula;
                        if (res.length != 0) {
                            customfield.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfield.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfield.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfield.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        } else {
                            _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                                if (customfield.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                    customfield.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfield.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfield.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                    customfield.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                }
                            });
                        }
                    });
                });
                customFieldsArray = JSON.stringify(temp);
                var customFieldsParsedArray = [];
                if (customFieldsArray != "null" && customFieldsArray != undefined) {
                    customFieldsParsedArray = JSON.parse(customFieldsArray);
                }
                $.each(customFieldsParsedArray, function (index, customField) {
                    if (customField.name == value["columnName"]) {
                        isCustomField = true;
                        customFieldIndex = index;
                    }
                });
                valuesArray.push(value.displayName);
                var object = {
                    "actualnames": value["columnName"],
                    "displaynames": value["displayName"],
                    "datatype": value["dataType"]
                };
                var customFieldObject = {};
                if (isCustomField == true) {
                    customFieldObject = customFieldsParsedArray[customFieldIndex];
                    var convertedTypes = sessionStorage.getItem("convertedtypes");
                    //object["datatype"]="string";
                    if (convertedTypes != undefined) {
                        var parsedValues = JSON.parse(convertedTypes);
                        _.each(parsedValues, function (parsedValue) {
                            if (parsedValue["columnName"] == customFieldObject["name"]) {
                                object["datatype"] = parsedValue["dataType"];
                            }
                        });
                    }
                    finalCustomFields.push(customFieldObject);
                    if (that.state.multifact != undefined && that.state.multifact == "true") {
                        _.each(customFieldObject.usedFields, function (usedField) {
                            var customObject = {
                                "actualnames": usedField,
                                "displaynames": usedField,
                                "datatype": customFieldObject.dataType
                            };
                            chartDobyColumns.push(customObject);
                        });
                    }else if(chartDobyColumns.length==0){
                        _.each(customFieldObject.usedFields, function (usedField) {
                            var customObject = {
                                "actualnames": usedField,
                                "displaynames": usedField,
                                "datatype": customFieldObject.dataType
                            };
                            chartDobyColumns.push(customObject);
                        });
                    }
                } else {
                    chartDobyColumns.push(object);
                }
            });
            if (this.state.href != undefined && this.state.href.split("/")[0] == "viewcustomstoryboardreport") {
                this.props.isPurgedReport = false;
            }
            var gridmodel = new FormData();
            gridmodel.set("aggregation", null);
            gridmodel.set("xaxis", null);
            gridmodel.set("xaxisdataType", null);
            gridmodel.set("yaxis", null);
            gridmodel.set("datamodelId", this.props.datamodelId);
            gridmodel.set("customFields", JSON.stringify(finalCustomFields));
            gridmodel.set("script", JSON.stringify(finalCustomFields));
            gridmodel.set("reportId", this.props.reportId);
            gridmodel.set("filterObject", JSON.stringify(filterArray));
            gridmodel.set("dobyrowlimit", rowlimit);
            gridmodel.set("selectedcolumns", JSON.stringify(chartDobyColumns));
            gridmodel.set("timeZone", this.props.timeZone);
            gridmodel.set("isPurgedReport", false);
            gridmodel.set("isImportedReport", this.state.isImportedReport)
            var tableInfo = {
                chartType: "pivot"
            };
            $(".pivotdataviewwrap, .pivotdataview").remove();
            $('body').append("<div class='opacityhide pivotdataviewwrap' data-number='" + chartNumber + "'><div class='opacityhide pivotdataview' data-number='" + chartNumber + "'></div></div>");
            $(".pivotdataview[data-number=" + chartNumber + "]").empty();
            $(".pivotdataview[data-number=" + chartNumber + "]").append("<div class=pivotdataviewclose><i class='fa fa-times-circle'></i></div><script>$('.pivotdataviewclose').on('click',function(){$('.pivotdataview[data-number=" + chartNumber + "], .pivotdataviewwrap[data-number=" + chartNumber + "]').addClass('opacityhide'); $('.pivotdataviewwrap').remove(); })</script>");
            $(".pivotdataview[data-number=" + chartNumber + "]").append("<div class=pivottile>Dataview</div>");          
            $(".pivotdataview[data-number=" + chartNumber + "], .pivotdataviewwrap[data-number=" + chartNumber + "]").removeClass('opacityhide');
            if (this.state.multifact != "true") {
                $(".pivotdataview[data-number=" + chartNumber + "]").append("<div class='container-fluid marg-bot-10'> <div class='row'><div class='col-lg-8 nopadding pivotdatacountcontainer'><span>Dataset Count: <span class='pivot_datasetcount'>" + ChartInfo.functions.getDataSetCount() + "</span></span><span> | </span><span>Filtered Count: <span class='pivot_filtered_datasetcount'></span></span></div><div id='pivotdataview" +chartNumber+ "'></div></div></div>");
            }
 			if (this.state.multifact == "true") {
                $(".pivotdataview[data-number=" + chartNumber + "]").append("<div id='pivotdataview" +chartNumber+ "'></div>");
            }
            $(".pivotdataview[data-number=" + chartNumber + "]").append("<div class='ball-pulse'><div></div><div></div><div></div></div>");
            var bootObj = {};
            bootObj["currentChartNumber"] = chartNumber;
            bootObj["gridmodel"] = gridmodel;
            bootObj["tableInfo"] = tableInfo;
            bootObj["pos"] = undefined;
            bootObj["popupwindow"] = "popupview";
            bootObj["reporttype"] = undefined;
            bootObj["target"] = undefined;
            bootObj["isFromPagenation"] = undefined;
            var bootStrapTable = new BootStrapTable(bootObj);
            bootStrapTable.render();
            //  ChartUtils.drawBootstrapTable(chartNumber, gridmodel, tableInfo, null, "popupview");
        }
    }
}
