import React from 'react'
import _ from 'lodash';
import moment from 'moment';
import { getMessage } from './BirdMessage';
import * as ChartInfo from "./chartinfo";
import { whitelableMap } from "./whitelable";
import * as Highcharts from 'highcharts/highstock';
import drilldown from "highcharts/modules/drilldown";
import SendRequest from '../../../SendRequest';
import { lang } from "../../../js/helpers/utils/languages/language";
import L from 'leaflet';
import InputValidate from '../InputValidate';
const $ = window.$;
export var columnsData = {};
export var charts = {};
export var streamingcharts = [];
var colorThemesArray = ChartInfo.functions.getColorThemes();
drilldown(Highcharts);

export function remove() {
    this.remove()
}
export function modifyChartHeightOnResize(chartnumber, pos) {
    console.log(chartnumber);
    var compos = ChartInfo.functions.getChartComponents(chartnumber);
    var offsetheight = $(".maxanim1[data-num='" + chartnumber + "']").height();
    var offsetwidth = $(".maxanim1[data-num='" + chartnumber + "']").width();
    offsetheight = offsetheight - 40;
    if (compos != undefined && compos.chartType != undefined && (compos.chartType === "wordcloud" || compos.chartType == "boxplot" || compos.chartType === "bartimeseries" || compos.chartType === "linetimeseries" || compos.chartType === "areatimeseries")) {
        if ($(".fullscreenmain[data-num=" + chartnumber + "]").length > 0) {
            pos = "max"
        }
    }
    if (pos != "max") {
        if (compos != undefined && compos.chartType != undefined) {
            const chart = compos.chart;
            charts['chart' + chartnumber] = chart;
            if (compos.chartType === "trellis") {
                const $chart = compos.chart;
                $.each($chart, function (i, chart) {
                    var chartSettings;
                    if (ChartInfo.functions.getChartDetails(chartnumber) != undefined) {
                        chartSettings = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber)).chartSettings;
                    }
                    if (chartSettings != undefined && chart.container.parentElement.hasAttribute('data-highcharts-chart')) {
                        offsetheight = $('#chart' + chartnumber).height() - 10;
                        var acwidth = $('#chart' + chartnumber).width() - 10;
                        var cellslength = $("#trellis[data-num=" + chartnumber + "]>tbody>tr>td").length;
                        var twidth = acwidth / cellslength;
                        if (cellslength > 4) {
                            if (chartSettings != undefined && chartSettings.trellisFitToWidth != undefined && chartSettings.trellisFitToWidth == true) {
                                var twidthfirst = twidth + 100;
                                var twidthfirst = twidthfirst - (100 / cellslength);
                                var twidth = twidth - (100 / cellslength);
                                if (twidth < 100) { twidth = 100; }
                            } else {

                                if (twidth < 300) { twidth = 300; }
                                var twidthfirst = twidth + 100;
                            }
                        } else {
                            var twidthfirst = twidth;
                        }
                        if (i == 0) {
                            chart.setSize(twidthfirst, offsetheight, false);
                        } else {
                            chart.setSize(twidth, offsetheight, false);
                        }
                    } else {
                        chart.setSize(offsetwidth, offsetheight, false);
                    }
                });
            } else if (compos.chartType === "leaflet" || compos.chartType === "srilankamap" || (compos.chartType === "world" && compos.map != undefined)) {
                if (compos != undefined && compos.map != undefined) {
                    compos.map.invalidateSize()
                }
            } else {
                if (compos != undefined && compos.chart != undefined && compos.chart.userOptions != undefined && compos.chart.userOptions.chart != undefined && compos.chart.userOptions.chart.className != undefined && compos.chart.userOptions.chart.className == "pivotchart") { //Fix for Pivot Chart
                    offsetheight = offsetheight - 40;
                }
                chart.setSize(offsetwidth, offsetheight, false);
            }
        } else if (compos != undefined && compos.chartComponent != undefined && compos.chartparent != undefined) {
            const component = compos.chartComponent;
            const divcomponent = compos.chartparent;
            charts['chart' + chartnumber] = component.chart;
            // divcomponent.style.height = offsetheight + "px";
            // divcomponent.style.width = offsetwidth + "px";
            var chartNumber = chartnumber;
            var navh = $("nav.navbar.navbar-bird.navbar-fixed-top").height() ?? 0;
            var controlh = $(".reporttoolbar[data-num=" + chartNumber + "] .controlbar-main").height() ?? 0;
            var charcontrolh = $(".maxanim9[data-num=" + chartNumber + "]").height() ?? 0;
            var quickfilterh = $(".quickfiltercontainer[data-num=" + chartNumber + "]:not(:empty)").height() ?? 0;
            var newFilterh = $('.tab-filters-container').outerHeight() + $(".filter-menu-toggle").outerHeight() ?? 0;
            var breadcrumbh = $(".breadcrumb[data-num=" + chartNumber + "]").outerHeight() ?? 0;
            var footerh = $("footer.footer").height() ?? 0;

            var finalh = navh + controlh + charcontrolh + quickfilterh + breadcrumbh + newFilterh + footerh + 13;
            var vizWidth = $("#newslideoutattributes").width();

            if ($(".narrativecontainer[chart-id=" + chartNumber + "]").length > 0) {
                finalh = finalh + $(".narrativecontainer[chart-id=" + chartNumber + "]").height();
            }

            if ($('.fullscreenmain').length > 0) {
                offsetwidth = offsetwidth - 30
            }
            var isChartMaximized = $(".maxanim1[data-num=" + chartNumber + "]").hasClass("fullscreenmain");
            var chartHeight = "calc(100vh - " + finalh + "px)";
            if (isChartMaximized) {
                var chartHeight = "calc(100vh - " + finalh + "px)";
                $("#chart" + chartNumber + "").css("height", chartHeight);
            } else {
                var narrativeHeight = 0;
                if ($(".narrativecontainer[chart-id=" + chartNumber + "]").length > 0) {
                    narrativeHeight = $(".narrativecontainer[chart-id=" + chartNumber + "]").height();
                }
                var chartHeight = $(".maxanim1[data-num=" + chartNumber + "]").height() - $(".maxanim3[data-num=" + chartNumber + "]").height() - narrativeHeight - 10 + "px";

            }
            offsetwidth = offsetwidth - 2;
            divcomponent.style.height = chartHeight;
            divcomponent.style.width = offsetwidth + "px";
            component.container.current.style.height = chartHeight;
            component.container.current.style.width = "100%";
            try {
                component.chart.reflow();
                component.chart.setSize(offsetwidth, chartHeight, false);
                if ($(".narrativecontainer[chart-id=" + chartNumber + "]").length > 0) {
                    component.chart.setSize(offsetwidth, chartHeight, false);
                }
            } catch (e) {
                console.log('error' + e)
            }

            //For Combination Chart
            if (component != undefined && component.chart != undefined && component.chart.userOptions != undefined && component.chart.userOptions.chart != undefined && component.chart.userOptions.chart.className == "combinationchart") {
                var noOfSeries = component.chart.userOptions.yAxis.length;
                var minSeriesWidth = (noOfSeries * 60) + 250;
                if (offsetwidth < minSeriesWidth || +$("#chart" + chartNumber + " rect.highcharts-plot-background").attr("width") <= 150) {
                    offsetwidth = minSeriesWidth + (noOfSeries * 50);
                    chartHeight = chartHeight - 10;
                    divcomponent.style.height = chartHeight;
                    divcomponent.style.overflowY = "hidden";
                    divcomponent.style.overflowX = "auto";


                    component.chart.setSize(offsetwidth, chartHeight, false);
                }

            } else {
                divcomponent.style.overflowX = "";
                divcomponent.style.overflowY = "";
            }
        }
    } else {
        offsetwidth = offsetwidth - 30;
        if (compos != undefined && compos.chartType != undefined) {
            const chart = compos.chart;
            charts['chart' + chartnumber] = chart;
            if (compos.chartType === "trellis") {
                if ($(".rightattributes.open").length == 0 && $(".singlereportview").length > 0) {
                    $('#chart' + chartnumber).css("max-width", "initial")
                }
                const $chart = compos.chart;
                $.each($chart, function (i, chart) {
                    var chartSettings;
                    if (ChartInfo.functions.getChartDetails(chartnumber) != undefined) {
                        chartSettings = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber)).chartSettings;
                    }
                    if (chartSettings != undefined && chart.container.parentElement.hasAttribute('data-highcharts-chart')) {
                        offsetheight = $('#chart' + chartnumber).height() - 10;
                        var acwidth = $('#chart' + chartnumber).width() - 10;
                        var cellslength = $("#trellis[data-num=" + chartnumber + "]>tbody>tr>td").length;
                        var twidth = acwidth / cellslength;
                        if (cellslength > 4) {
                            if (chartSettings != undefined && chartSettings.trellisFitToWidth != undefined && chartSettings.trellisFitToWidth == true) {
                                var twidthfirst = twidth + 100;
                                var twidthfirst = twidthfirst - (100 / cellslength);
                                var twidth = twidth - (100 / cellslength);
                                if (twidth < 100) { twidth = 100; }
                            } else {
                                if (twidth < 300) { twidth = 300; }
                                var twidthfirst = twidth + 100;
                            }
                        } else {
                            var twidthfirst = twidth;
                        }
                        if (i == 0) {
                            chart.setSize(twidthfirst, offsetheight, false);
                        } else {
                            chart.setSize(twidth, offsetheight, false);
                        }
                    } else {
                        chart.setSize(offsetwidth, offsetheight, false);
                    }
                });
            } else if (compos.chartType === "leaflet" || compos.chartType === "srilankamap" || (compos.chartType === "world" && compos.map != undefined)) {
                if (compos != undefined && compos.map != undefined) {
                    compos.map.invalidateSize()
                }
            } else if (compos.chartType === "wordcloud" || compos.chartType === "indiamap" || (compos.chartType === "world" && compos.map == undefined) || compos.chartType === "boxplot" || compos.chartType === "bartimeseries" || compos.chartType === "linetimeseries" || compos.chartType === "areatimeseries" || compos.chartType === "countrymap") {
                var chartNumber = chartnumber;
                var navh = $("nav.navbar.navbar-bird.navbar-fixed-top").height() ?? 0;
                var controlh = $(".reporttoolbar[data-num=" + chartNumber + "] .controlbar-main").height() ?? 0;
                var charcontrolh = $(".maxanim9[data-num=" + chartNumber + "]").height() ?? 0;
                var quickfilterh = $(".quickfiltercontainer[data-num=" + chartNumber + "]:not(:empty)").height() ?? 0;
                var newFilterh = $('.tab-filters-container').outerHeight() + $(".filter-menu-toggle").outerHeight() ?? 0;
                var breadcrumbh = $(".breadcrumb[data-num=" + chartNumber + "]").outerHeight() ?? 0;
                var footerh = $("footer.footer").height() ?? 0;

                var finalh = navh + controlh + charcontrolh + quickfilterh + breadcrumbh + newFilterh + footerh + 13;
                if ($(".narrativecontainer[chart-id=" + chartNumber + "]").length > 0) {
                    finalh = finalh + $(".narrativecontainer[chart-id=" + chartNumber + "]").height();
                }
                var vizWidth = $("#newslideoutattributes").width();
                offsetwidth = $("#chart" + chartNumber).width();
                offsetheight = window.innerHeight - finalh;
                $("#chart" + chartnumber).height("calc(100vh - " + finalh + "px)");
                chart.setSize(offsetwidth, offsetheight, false);
            } else {
                chart.setSize(offsetwidth, offsetheight, false);
                if (compos != undefined && compos.chart != undefined && compos.chart.userOptions != undefined && compos.chart.userOptions.chart != undefined && compos.chart.userOptions.chart.className != undefined && compos.chart.userOptions.chart.className == "pivotchart") { //Fix for Pivot Chart
                    var chartNumber = chartnumber;
                    var navh = $("nav.navbar.navbar-bird.navbar-fixed-top").height() ?? 0;
                    var controlh = $(".reporttoolbar[data-num=" + chartNumber + "] .controlbar-main").height() ?? 0;
                    var charcontrolh = $(".maxanim9[data-num=" + chartNumber + "]").height() ?? 0;
                    var quickfilterh = $(".quickfiltercontainer[data-num=" + chartNumber + "]:not(:empty)").height() ?? 0;
                    var newFilterh = $('.tab-filters-container').outerHeight() + $(".filter-menu-toggle").outerHeight() ?? 0;
                    var breadcrumbh = $(".breadcrumb[data-num=" + chartNumber + "]").outerHeight() ?? 0;
                    var footerh = $("footer.footer").height() ?? 0;

                    var finalh = navh + controlh + charcontrolh + quickfilterh + breadcrumbh + newFilterh + footerh + 13;
                    if ($(".narrativecontainer[chart-id=" + chartNumber + "]").length > 0) {
                        finalh = finalh + $(".narrativecontainer[chart-id=" + chartNumber + "]").height();
                    }
                    var vizWidth = $("#newslideoutattributes").width();
                    offsetwidth = $("#chart" + chartNumber).width();
                    offsetheight = window.innerHeight - finalh - 40;
                    $("#chart" + chartnumber).height("calc(100vh - " + finalh + "px)");
                    chart.setSize(offsetwidth, offsetheight, false);
                }


            }
        } else if (compos != undefined && compos.chartComponent != undefined && compos.chartparent != undefined) {
            const component = compos.chartComponent;
            const divcomponent = compos.chartparent;
            charts['chart' + chartnumber] = component.chart;
            var chartNumber = chartnumber;
            var navh = $("nav.navbar.navbar-bird.navbar-fixed-top").height() ?? 0;
            var controlh = $(".reporttoolbar[data-num=" + chartNumber + "] .controlbar-main").height() ?? 0;
            var charcontrolh = $(".maxanim9[data-num=" + chartNumber + "]").height() ?? 0;
            var quickfilterh = $(".quickfiltercontainer[data-num=" + chartNumber + "]:not(:empty)").height() ?? 0;
            var newFilterh = $('.tab-filters-container').outerHeight() + $(".filter-menu-toggle").outerHeight() ?? 0;
            var breadcrumbh = $(".breadcrumb[data-num=" + chartNumber + "]").outerHeight() ?? 0;
            var footerh = $("footer.footer").height() ?? 0;

            var finalh = navh + controlh + charcontrolh + quickfilterh + breadcrumbh + newFilterh + footerh + 13;
            if ($(".narrativecontainer[chart-id=" + chartNumber + "]").length > 0) {
                finalh = finalh + $(".narrativecontainer[chart-id=" + chartNumber + "]").height();
            }
            var vizWidth = $("#newslideoutattributes").width();
            offsetwidth = offsetwidth - 30;
            $("#chart" + chartNumber + "").css("height", "calc(100vh - " + finalh + "px)");
            divcomponent.style.height = "calc(100vh - " + finalh + "px)";
            divcomponent.style.width = offsetwidth + "px";
            if ($(".maxanim1[data-num=" + chartNumber + "]").hasClass("singlereportview")) {
                offsetwidth = $("#chart" + chartNumber + "").width();
            }
            // component.container.current.style.height = "calc(100vh - " + finalh + "px)";
            // component.container.current.style.width = "100%";

            // divcomponent.style.height = offsetheight + "px";
            // divcomponent.style.width = offsetwidth + "px";
            component.container.current.style.height = window.innerHeight - finalh + "px";
            // component.container.current.style.width = "100%";
            component.chart.reflow();
            component.chart.setSize(offsetwidth, window.innerHeight - finalh + "px", false);
            if ($(".narrativecontainer[chart-id=" + chartNumber + "]").length > 0) {
                //divcomponent.style.width = "100%";
                var fwidth = component.chart.chartWidth,
                    fheigth = component.chart.chartHeight - $(".narrativecontainer[chart-id=" + chartNumber + "]").height() - 10 + "px";
                component.chart.setSize(offsetwidth, fheigth, false);
            } else {
                component.chart.reflow();
            }

            //For Combination Chart
            if (component != undefined && component.chart != undefined && component.chart.userOptions != undefined && component.chart.userOptions.chart != undefined && component.chart.userOptions.chart.className == "combinationchart") {
                var noOfSeries = component.chart.userOptions.yAxis.length;
                var minSeriesWidth = (noOfSeries * 60) + 250;
                if (offsetwidth < minSeriesWidth || +$("#chart" + chartNumber + " rect.highcharts-plot-background").attr("width") <= 150) {
                    offsetwidth = minSeriesWidth + (noOfSeries * 50);
                    chartHeight = offsetheight - 10;

                    if ($(".fullscreenmain[data-num=" + chartNumber + "]").length > 0) {
                        chartHeight = $("#chart" + chartNumber).height();
                        $("#chart" + chartNumber + ">div>div>div").attr('style', "overflow:visible !important");
                    }
                    divcomponent.style.height = chartHeight;
                    divcomponent.style.overflowY = "hidden";
                    divcomponent.style.overflowX = "auto";


                    component.chart.setSize(offsetwidth, chartHeight, false);
                }

            } else {
                divcomponent.style.overflowX = "";
                divcomponent.style.overflowY = "";
            }
        }
    }
}

export function conditionalFormating(chartnumber, conditionalFormatData, options) {
    var that = this;
    var chartType = options.chart.type;
    _.each(conditionalFormatData, function (conditional, i) {
        var zone2 = [];
        //SORT by range
        conditional.conditions = conditional.conditions.sort(function (a, b) {
            var nameA = a.range, nameB = b.range
            if (nameA < nameB)
                return -1
            if (nameA > nameB)
                return 1
            return 0
        });
        var lessvalues = [], greatvalues = []
        _.each(conditional.conditions, function (i) {
            if (i.range == "<") {
                lessvalues.push(i)
            } else {
                greatvalues.push(i)
            }
        });


        lessvalues = lessvalues.sort(function (a, b) { return parseFloat(a.value) - parseFloat(b.value); });
        greatvalues = greatvalues.sort(function (a, b) { return parseFloat(a.value) - parseFloat(b.value); });
        var totalconditions = $.merge(lessvalues, greatvalues);
        //conditional.conditions = conditional.conditions.sort(function(a, b) { return parseFloat(a.value) - parseFloat(b.value); });
        var greatervalue = totalconditions.filter(function (e) { return e.range == ">" });
        //greatervalue = Math.max.apply(Math, greatervalue.map(function(o) { return o.value; }))
        var incrementval;
        var greatercount = 0;
        var pad = function (num, size) {
            var s = num + "";
            while (s.length < size) s = "0" + s;
            return s;
        }
        var increaseDecimals = function (value) {
            //						    if(Math.floor(value) === value) return value + 1;
            if (Math.floor(value) === value) return value + 0.0001;
            var mainvalue = value.toString().split(".")[0];
            var decimalvalue = value.toString().split(".")[1];
            var decimalsize = value.toString().split(".")[1].length;
            decimalvalue = parseInt(decimalvalue);
            var decimalvalue = pad(decimalvalue, decimalsize);
            return JSON.parse(mainvalue + "." + decimalvalue) + 0.0001;
        }
        _.each(totalconditions, function (i, k) {
            if (i.value != undefined && i.value != "") {
                var val = 0;
                if (i.value != undefined && i.value != 0) {
                    val = parseFloat(i.value);
                }
                var range = i.range
                var color = i.color;
                var obj = {};
                if (range == "<") {
                    if (val) {
                        obj["color"] = color;
                        obj["value"] = val;
                        zone2.push(obj);
                    }
                } else {
                    if (greatervalue.length <= 1) {
                        obj["value"] = increaseDecimals(val);
                        zone2.push(obj);
                        obj = {};
                        obj["color"] = color;
                        zone2.push(obj);

                    } else {
                        if (greatercount == 0) {
                            obj["value"] = increaseDecimals(val);
                            zone2.push(obj);
                            obj = {}
                        }
                        greatercount = greatercount + 1
                        var cdata = options;
                        obj["color"] = color;
                        if (greatervalue[greatercount] != undefined) {
                            obj["value"] = parseFloat(greatervalue[greatercount].value) + 0.0001;
                        }
                        zone2.push(obj);
                    }
                }
            }
        });
        //zone2.sort(function(a, b) { return parseFloat(b.value) - parseFloat(a.value); });
        //console.log(zone2)

        var seriesList = options.series;
        var seriesObj = seriesList.filter(function (seriesList, i) {
            //return seriesList.name === conditional.name
            //return seriesList.name + seriesList.aggType + i === conditional.name + conditional.agg + conditional.dataNumYaxis
            return seriesList.name + i === conditional.name + conditional.dataNumYaxis
        });
        if (seriesObj != undefined && seriesObj.length > 0) {
            seriesObj[0].zones = zone2;
            seriesObj[0].zoneAxis = 'y'
        }
    });
}

export function autoLabelRotationReset(chartdata) {
    //Fix: When x-xais labels are too long, and drillup function fired, then Chart plot area not re-sizing properly.
    var chartDrawn = chartdata;
    if (chartDrawn != undefined) {
        //Remove Selection Menu after drill-up for 3D Bar Charts
        $("#" + chartDrawn.userOptions.chart.renderTo + " .chartpointoptions").remove();
        chartDrawn.showLoading();
    }
    setTimeout(function () {
        if (chartDrawn != undefined) {
            chartDrawn.update({ xAxis: [{ labels: { autoRotationLimit: 80 } }] })
            setTimeout(function () {
                //chartDrawn.hideLoading();

                //Fix: For 3DBar charts, after drill-up function, not able to drill-down again. 
                if (chartDrawn != undefined && chartDrawn.userOptions != undefined && chartDrawn.userOptions.chart.options3d != undefined && chartDrawn.userOptions.chart.options3d.enabled != undefined) {
                    setTimeout(function () {
                        var datalabelStatus = chartDrawn.userOptions.plotOptions.series.dataLabels.enabled;
                        if (datalabelStatus === true) { datalabelStatus = false } else { datalabelStatus = true }
                        chartDrawn.update({ plotOptions: { series: { dataLabels: { enabled: datalabelStatus } } } }, true, true);
                        if (datalabelStatus === true) { datalabelStatus = false } else { datalabelStatus = true }
                        chartDrawn.update({ plotOptions: { series: { dataLabels: { enabled: datalabelStatus } } } }, true, true);
                        chartDrawn.hideLoading();
                    }, 1000);
                } else {
                    chartDrawn.hideLoading();
                }

            }, 2150);
        }
    }, 100);
}

export function navCorrection(e) {
    try {
        if (e.target.scroller != undefined) {
            var navdata = e.seriesOptions.data,
                len = navdata.length - 1,
                nav = e.target.scroller;
            nav.series.update({
                data: navdata
            }, false);
            var chart = e.target;
            var min = chart.xAxis[0].dataMin,
                // max= chart.xAxis[0].dataMax;
                max = min + len * chart.series[1].pointInterval;
            chart.xAxis[1].update({
                min: min,
                max: max
            });
        }
    } catch (e) {

    }
}
export function showSeriesExeededWarning() {
    $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
    $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
    $('.birdmessagebuttons').removeClass('show');
    $('.birdmessagebuttons').addClass('hide');
    $('.birdmessage-center').addClass("message-top-in");
    $('.details').removeClass('show');
    $('.details').addClass('hide');
    $('.birdmessage h2').empty().append('Warning');
    $('.birdmessage-info').empty().text(getMessage("BirdWarning110"));
}

export function checkDrillDownEnable(allfeatures, position, isSharedReport, iscustomstoryboard) {
    var href = window.location.pathname;
    var isDrillDownEnable = false;
    if (ChartInfo.functions.getIsDirect() != undefined) {
        this.isDirect = ChartInfo.functions.getIsDirect();
    }
    if (ChartInfo.functions.getIsStoredProcedure() != undefined) {
        this.isStoredProcedure = ChartInfo.functions.getIsStoredProcedure();
    }
    if (href.toLowerCase().indexOf("embeddedreport") < 0 && href.toLowerCase().indexOf("alertreport") < 0) {
        var that = this;
        if (isSharedReport == false) {
            $.each(allfeatures, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                    $.each(featuresModule.features, function (index, feature) {
                        if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_drill_down' && feature.allow == true) {
                            isDrillDownEnable = true;
                        }
                    })
                } else if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                    $.each(featuresModule.features, function (index, feature) {
                        if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_drill_down' && feature.allow == true) {
                            isDrillDownEnable = true;
                        }
                    })
                }
            });
        } else {
            $.each(allfeatures, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
                            $.each(featuresModule.features, function (index, feature) {
                                if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_drill_down' && feature.allow == true) {
                                    isDrillDownEnable = true;
                                }
                            })
                        }
                    })
                } else if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_maximize_save_shared_report' && feature.allow == true) {
                            $.each(featuresModule.features, function (index, feature) {
                                if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_drill_down' && feature.allow == true) {
                                    isDrillDownEnable = true;
                                }
                            })
                        }
                    })
                }
            });
        }
    }
    if ((href.toLowerCase().indexOf("embeddedreport") > 0 || href.toLowerCase().indexOf("alertreport") > 0) && iscustomstoryboard != true) {
        var that = this;
        $.each(allfeatures, function (index, featuresModule) {
            if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                $.each(featuresModule.features, function (index, feature) {
                    if (feature.featureName == 'viewreport_maximize_embeded_drilldown' && feature.allow == true) {
                        $.each(featuresModule.features, function (index, feature) {
                            if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_embeded_drilldown' && feature.allow == true) {
                                isDrillDownEnable = true;
                            }
                        })
                    }
                })
            }
            if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                $.each(featuresModule.features, function (index, feature) {
                    if (feature.featureName == 'viewreport_embeded_drilldown' && feature.allow == true) {
                        $.each(featuresModule.features, function (index, feature) {
                            if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_embeded_drilldown' && feature.allow == true) {
                                isDrillDownEnable = true;
                            }
                        })
                    }
                })
            }
        })
    }
    if (isDrillDownEnable != true && Object.keys(allfeatures).length == 0 && (href.toLowerCase().indexOf("embeddedreport") > 0 || href.toLowerCase().indexOf("alertreport") > 0)) {
        isDrillDownEnable = true;
    }
    if (iscustomstoryboard && href.toLowerCase().indexOf("embeddedreport") < 0 && href.toLowerCase().indexOf("alertreport") < 0) {
        isDrillDownEnable = true;
    }

    if (isDrillDownEnable != true && Object.keys(allfeatures).length == 0 && href.toLowerCase().indexOf("schedulereport") > 0) {
        isDrillDownEnable = true;
    }
    if(ChartInfo.functions.getUserRole()!=null && ChartInfo.functions.getUserRole()!=undefined && ChartInfo.functions.getUserRole()[0]!=undefined && ChartInfo.functions.getUserRole()[0] == "ROLE_Viewer"){
        isDrillDownEnable = true;
    }

    //					if(isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true){
    //						isDrillDownEnable=false; 
    //					}	
    return isDrillDownEnable;
}

export function viewDataEnable(allfeatures, position, isSharedReport, iscustomstoryboard) {
    var isViewDataEnable = false;
    var href = window.location.pathname;
    if (href.toLowerCase().indexOf("embeddedreport") < 0 && href.toLowerCase().indexOf("alertreport") < 0) {
        if (isSharedReport == false) {
            var that = this;
            $.each(allfeatures, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                    $.each(featuresModule.features, function (index, feature) {
                        if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_view_data' && feature.allow == true) {
                            isViewDataEnable = true;
                        }
                    })
                } else if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                    $.each(featuresModule.features, function (index, feature) {
                        if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_view_data' && feature.allow == true) {
                            isViewDataEnable = true;
                        }
                    })
                }
            });
        } else {
            var that = this;
            $.each(allfeatures, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
                            $.each(featuresModule.features, function (index, feature) {
                                if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_view_data' && feature.allow == true) {
                                    isViewDataEnable = true;
                                }
                            })
                        }
                    })
                } else if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_maximize_save_shared_report' && feature.allow == true) {
                            $.each(featuresModule.features, function (index, feature) {
                                if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_view_data' && feature.allow == true) {
                                    isViewDataEnable = true;
                                }
                            })
                        }
                    })
                }
            });
        }
    }
    if ((href.toLowerCase().indexOf("embeddedreport") > 0 || href.toLowerCase().indexOf("alertreport") > 0) && iscustomstoryboard != true) {
        var that = this;
        $.each(allfeatures, function (index, featuresModule) {
            if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                $.each(featuresModule.features, function (index, feature) {
                    if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_embeded_view_data' && feature.allow == true) {
                        isViewDataEnable = true;
                    }
                })
            } else if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                $.each(featuresModule.features, function (index, feature) {
                    if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_embeded_view_data' && feature.allow == true) {
                        isViewDataEnable = true;
                    }
                })
            }
        });
    }
    if (href.toLowerCase().indexOf("schedulereport") >= 0) {
        isViewDataEnable = false;
    }

    if (isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true) {
        isViewDataEnable = false;
    }
    return isViewDataEnable;
}

export function checkFilterEnable(allfeatures, position, isSharedReport, iscustomstoryboard) {
    var isFilterEnable = false;
    var href = window.location.pathname;
    if (href.toLowerCase().indexOf("embeddedreport") < 0 && href.toLowerCase().indexOf("alertreport") < 0) {
        if (isSharedReport == false) {
            var that = this;
            $.each(allfeatures, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                    $.each(featuresModule.features, function (index, feature) {
                        if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_filters' && feature.allow == true) {
                            isFilterEnable = true;
                        }
                    })
                } else if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                    $.each(featuresModule.features, function (index, feature) {
                        if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_filters' && feature.allow == true) {
                            isFilterEnable = true;
                        }
                    })
                }
            });
        } else {
            var that = this;
            $.each(allfeatures, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
                            $.each(featuresModule.features, function (index, feature) {
                                if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_filters' && feature.allow == true) {
                                    isFilterEnable = true;
                                }
                            })
                        }
                    })
                } else if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_maximize_save_shared_report' && feature.allow == true) {
                            $.each(featuresModule.features, function (index, feature) {
                                if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_filters' && feature.allow == true) {
                                    isFilterEnable = true;
                                }
                            })
                        }
                    })
                }
            });
        }
    }
    if ((href.toLowerCase().indexOf("embeddedreport") > 0 || href.toLowerCase().indexOf("alertreport") > 0) && iscustomstoryboard != true) {
        var that = this;
        $.each(allfeatures, function (index, featuresModule) {
            if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                $.each(featuresModule.features, function (index, feature) {
                    if (feature.featureName == 'viewreport_maximize_embeded_filters' && feature.allow == true) {
                        $.each(featuresModule.features, function (index, feature) {
                            if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_embeded_filters' && feature.allow == true) {
                                isFilterEnable = true;
                            }
                        })
                    }
                })
            }
            if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                $.each(featuresModule.features, function (index, feature) {
                    if (feature.featureName == 'viewreport_embeded_filters' && feature.allow == true) {
                        $.each(featuresModule.features, function (index, feature) {
                            if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_embeded_filters' && feature.allow == true) {
                                isFilterEnable = true;
                            }
                        })
                    }
                })
            }
        })
    }
    if (href.toLowerCase().indexOf("schedulereport") >= 0) {
        isFilterEnable = false;
    }

    if (isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true) {
        isFilterEnable = false;
    }
    if (iscustomstoryboard && href.toLowerCase().indexOf("embeddedreport") < 0 && href.toLowerCase().indexOf("alertreport") < 0) {
        isFilterEnable = false;
        //  isViewDataEnable=false;
    }
    return isFilterEnable;
}

export function checkDrillThroughEnable(allfeatures, position, isSharedReport, iscustomstoryboard) {

    var isDrillThroughEnable = false;
    var href = window.location.pathname;
    var drillthroughreports = ChartInfo.functions.getDrillThroughReports();
    if (iscustomstoryboard) {
        isDrillThroughEnable = false;
    }
    if (href.toLowerCase().indexOf("embeddedreport") < 0 || href.toLowerCase().indexOf("alertreport") < 0) {
        var that = this;
        if (isSharedReport == false) {
            $.each(allfeatures, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                    $.each(featuresModule.features, function (index, feature) {
                        if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_drill_through' && feature.allow == true) {
                            if (drillthroughreports != null && drillthroughreports != undefined && drillthroughreports.length > 2) {
                                isDrillThroughEnable = true;
                            }
                        }
                    })
                } else if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                    $.each(featuresModule.features, function (index, feature) {
                        if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_drill_through' && feature.allow == true) {
                            if (drillthroughreports != null && drillthroughreports != undefined && drillthroughreports.length > 2) {
                                isDrillThroughEnable = true;
                            }
                        }
                    })
                }
            });
        } else {
            $.each(allfeatures, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
                            $.each(featuresModule.features, function (index, feature) {
                                if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_drill_through' && feature.allow == true) {
                                    if (drillthroughreports != null && drillthroughreports != undefined && drillthroughreports.length > 2) {
                                        isDrillThroughEnable = true;
                                    }
                                }
                            })
                        }
                    })
                } else if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_maximize_save_shared_report' && feature.allow == true) {
                            $.each(featuresModule.features, function (index, feature) {
                                if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_drill_through' && feature.allow == true) {
                                    if (drillthroughreports != null && drillthroughreports != undefined && drillthroughreports.length > 2) {
                                        isDrillThroughEnable = true;
                                    }
                                }
                            })
                        }
                    })
                }
            });
        }
    }
    if ((href.toLowerCase().indexOf("embeddedreport") > 0 || href.toLowerCase().indexOf("alertreport") > 0) && iscustomstoryboard != true) {
        var that = this;
        $.each(allfeatures, function (index, featuresModule) {
            if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                $.each(featuresModule.features, function (index, feature) {
                    if (feature.featureName == 'viewreport_maximize_embeded_drilldown' && feature.allow == true) {
                        $.each(featuresModule.features, function (index, feature) {
                            if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_embeded_drilldown' && feature.allow == true) {
                                if (drillthroughreports != null && drillthroughreports != undefined && drillthroughreports.length > 2) {
                                    isDrillThroughEnable = true;
                                }
                            }
                        })
                    }
                })
            }
            if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                $.each(featuresModule.features, function (index, feature) {
                    if (feature.featureName == 'viewreport_embeded_drilldown' && feature.allow == true) {
                        $.each(featuresModule.features, function (index, feature) {
                            if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_embeded_drilldown' && feature.allow == true) {
                                if (drillthroughreports != null && drillthroughreports != undefined && drillthroughreports.length > 2) {
                                    isDrillThroughEnable = true;
                                }
                            }
                        })
                    }
                })
            }
        })
    }
    if (href.toLowerCase().indexOf("schedulereport") >= 0) {
        if (drillthroughreports != null && drillthroughreports != undefined && drillthroughreports.length > 2) {
            isDrillThroughEnable = false;
        }
    }

    if (isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true) {
        if (drillthroughreports != null && drillthroughreports != undefined && drillthroughreports.length > 2) {
            isDrillThroughEnable = false;
        }
    }
    if (iscustomstoryboard) {
        isDrillThroughEnable = false;
    }
    return isDrillThroughEnable;
}

export function checkExportChartEnable(allfeatures, position, isSharedReport) {
    var isExportChartEnable = false
    var href = window.location.pathname;
    var reportType = href.split("/");
    if (href.toLowerCase().indexOf("embeddedreport") < 0 && href.toLowerCase().indexOf("alertreport") < 0) {
        if (isSharedReport == false || isSharedReport == undefined) {
            $.each(allfeatures, function (index, featuresModule) {
                if (featuresModule.moduleName == 'my_report') {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_maximize_export_option' && feature.allow == true) {
                            isExportChartEnable = true;
                        }
                    })
                }
            });
        } else if (isSharedReport == true) {
            $.each(allfeatures, function (index, featuresModule) {
                if (featuresModule.moduleName == 'my_report') {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_maximize_save_shared_report' && feature.allow == true) {
                            if (feature.featureName == 'viewreport_maximize_export_option' && feature.allow == true) {
                                isExportChartEnable = true;
                            }
                        }
                    })
                }
            });
        }

    }
    if (reportType[0] === "schedulereport") {
        isExportChartEnable = false;
    }
    if (isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true) {
        isExportChartEnable = false;
    }
    return isExportChartEnable;
}

export function invertColor(hex, bw) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    //return "#" + padZero(r) + padZero(g) + padZero(b);
    return "#" + "0" + r + "0" + g + "0" + b;
}

export function rgb2hex(rgb) {
    rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return (rgb && rgb.length === 4) ? "#" +
        ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
}

export function customTextOutline(advanced, chartnumber) {
    //var advanced = advanced;
    var textOutline = advanced.plotOptions.series.dataLabels.style.textOutline;

    if (advanced.chart.type != undefined && (advanced.chart.type == "pie" || advanced.chart.type == "donut" || advanced.chart.type == "semicircle" || advanced.chart.type == "3dpie")) {
        var textOutlines = advanced.plotOptions.series.dataLabels.style.textOutline.split(" ");
        var textOutlineWidth = textOutlines[0]
        var textOutlineColor = textOutlines[1]
        if (textOutlineColor == "contrast") {
            var previouscolor = $("#chart" + chartnumber + " .highcharts-data-labels>div>span").eq(0).css("color");
            var newcolor = invertColor(rgb2hex(previouscolor), true);
            if (previouscolor != undefined) {
                $("#chart" + chartnumber + " .highcharts-data-labels").css('-webkit-text-stroke-width', textOutlineWidth);
                $("#chart" + chartnumber + " .highcharts-data-labels").css('-webkit-text-stroke-color', newcolor);
            }

        } else {
            $("#chart" + chartnumber + " .highcharts-data-labels").css('-webkit-text-stroke', textOutline);
        }
    } else {
        if (textOutline != "") {
            $("#chart" + chartnumber + " .highcharts-drilldown-data-label>text").css('stroke-linejoin', 'round')
            var textOutlines = textOutline.split(" ");
            var textOutlineWidth = textOutlines[0]
            var textOutlineColor = textOutlines[1]

            if (textOutlineWidth != "") {
                $("#chart" + chartnumber + " .highcharts-drilldown-data-label>text").css('stroke-width', textOutlineWidth)
            }
            if (textOutlineColor != "") {
                if (textOutlineColor == "contrast") {
                    var previouscolor = $("#chart" + chartnumber + " .highcharts-drilldown-data-label>text").eq(0).css("color")
                    if (previouscolor != undefined) {
                        $("#chart" + chartnumber + " .highcharts-drilldown-data-label>text").css('stroke', invertColor(rgb2hex(previouscolor), true));
                    }
                } else {
                    $("#chart" + chartnumber + " .highcharts-drilldown-data-label>text").css('stroke', textOutlineColor);
                }
                if (advanced.chart.type == 'funnel' || advanced.chart.type == 'pyramid') {
                    $("#chart" + chartnumber + " .highcharts-drilldown-data-label>text").css('text-shadow', 'none');
                }
            }
        }
    }
}

export function drawPivotChart(data, categories, xaxisname, yaxisnameArray, position, chartid, chartType, colorThemeIndex, chartSettings, number, aggType, valueFieldName) {
    var charts = {};
    var colorThemesArray = ChartInfo.functions.getColorThemes();
    var isExportChartEnable = false;

    /*Set Chart height to fix resize issues */
    var chartnumber = chartid.replace("chart", "");

    if ($('.tileview[data-num=' + chartnumber + ']').length === 1) {
        var setheight = $('.tileview[data-num=' + chartnumber + ']').height() - 80;
        $("#chart" + chartnumber).height(setheight);
    } else {
        $("#chart" + chartnumber).height("69vh");
    }

    var bgcolor = $('.maxanim7[data-num=' + chartnumber + ']').css('background-color');
    if (bgcolor == undefined || bgcolor === 'rgba(0, 0, 0, 0)') { bgcolor = 'rgba(255, 255, 255, 1)' }

    var options = {
        chart: {
            events: {
                click: function (event) {
                    var $this = event.currentTarget;
                    if (event.target.hasAttribute('href')) {
                        var bgcolor = $('.maxanim7[data-num=' + chartnumber + ']').css('background-color');
                        if (bgcolor == undefined || bgcolor === 'rgba(0, 0, 0, 0)') { bgcolor = 'rgba(255, 255, 255, 1)' }
                        this.options.exporting.chartOptions.chart.backgroundColor = bgcolor;
                    }
                },
            },
            type: chartType,
            inverted: false,
            renderTo: chartid,
            zoomType: 'xy',
            options3d: {},
            style: {
                fontFamily: whitelableMap["fontfamily"],
            },
            backgroundColor: 'transparent',
            animation: false,
            className: 'pivotchart'
        },
        title: {
            text: ''
        },
        scrollbar: {
            enabled: true
        },
        xAxis: [{
            categories: categories,
            type: "",
            max: number,
            title: {
                text: xaxisname,
                style: {}
            },
            labels: {
                /* For multi x*/
                groupedOptions: [{
                    y: 20,
                    rotation: -45,
                    style: {
                        padding: 0,
                        display: 'block',
                        overflow: 'hidden',
                        width: '100',
                        textOverflow: 'ellipsis',

                    }
                }],
                //rotation: 0,
                style: {}
            },
        }],
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name} [' + valueFieldName + '] <span class=bocaggtype> [' + aggType + '] </span> : </td>'
                + '<td style="padding:0"><b>{point.y} </b></td></tr>',
            footerFormat: '</table>',
            /*	formatter: function () {
                    var name='<span style="font-size:10px">'+this.points[0].key+'</span><table>'
                       // var s = '<b>' + this.points[0].key + '</b>';
                        $.each(this.points, function () {
                            name +='<tr><td style="color:'+this.series.color+';padding:0">'+this.series.name + ': </td>'
                            + '<td style="padding:0"><b>'+ $.formatNumber(this.y,{format:"#,###.##",locale:locale})+'</b></td></tr>'
                            s += '<br/>' + this.series.name + ': ' +
                            $.formatNumber(this.y,{format:"#,###.##",locale:names});
                        });
    
                        return name;
                    },*/
            shared: false,
            useHTML: true
        },
        yAxis: [{
            title: {
                margin: 20,
                text: yaxisnameArray,
                style: {}
            },
            labels: {
                overflow: 'justify',
                style: {}
            }
        }],
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true
                }
            },
            area: {
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    radius: 2,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            },
            series: {
                dataLabels: {
                    dataLabels: {
                        enabled: false,
                        style: { textShadow: 'none' },
                        formatter: function () {

                            return this.point.y;
                        }
                    },
                    style: { textShadow: 'none' },
                    color: '#000'
                }
            },
            column: {
                colorByPoint: false
            },
        },
        legend: {
            align: 'right',
            verticalAlign: 'bottom',
            shadow: false,
            itemStyle: {}
        },
        boost: {
            enabled: false
        },
        series: data,
        colors: colorThemesArray[colorThemeIndex],
        exporting: {
            enabled: false,
            chartOptions: {
                chart: {
                    width: 800,
                    backgroundColor: bgcolor
                },
            }
        },
        credits: {
            enabled: false
        }
    };
    //options.exporting.enabled = true;
    if (isExportChartEnable) {
        options.exporting.enabled = true;
    }
    if ($(window).width() < 780) {
        options.chart.zoomType = 'none';
    }
    if (chartType === 'pivotstackedbar') {
        options.plotOptions.column.colorByPoint = false;
        options.plotOptions.series.stacking = "normal";
        options.legend.reversed = true;
        options.chart.type = "column";
    }
    else if (chartType === 'pivotstackedarea') {
        options.chart.type = "area";
        options.plotOptions.area.stacking = 'normal';
    }
    if (chartSettings['legend'] == true) {
        options.legend.enabled = true;
    } else {
        options.legend.enabled = false;
    }

    if (chartSettings["chartTitle"] == true) {
        $(".boc-viewreport-title").removeClass("hide");
    } else {
        $(".boc-viewreport-title").addClass("hide");
    }

    if (chartSettings['xAxisLabel'] == true) {
        var chartnumber = chartid.replace("chart", "");
        options.xAxis[0].title.text = xaxisname;
    } else {
        options.xAxis[0].title.text = xaxisname;
        options.xAxis[0].title.enabled = false;
    }
    if (chartSettings['yAxisLabel'] == true) {
        //options.yAxis[0].title.text= yaxisnameArray;
        setTimeout(function () {
            $('#' + chartid + ' .highcharts-yaxis-title').empty();
            $('#' + chartid + ' .highcharts-yaxis-title').css("left", "-=10");
            $.each(yaxisnameArray, function (i) {
                if (i < 100) {
                    $('#' + chartid + ' .highcharts-yaxis-title').append("<span class='yaxisitem' title='" + yaxisnameArray[i] + "'>" + yaxisnameArray[i] + "</span>");
                } else {
                    $('#' + chartid + ' .highcharts-yaxis-title').append("<span class='yaxis-extra-item' title='" + yaxisnameArray.splice(3) + "'>&nbsp;&nbsp;...</span>");
                    return false;
                }
            });
        }, 500);
    } else {
        options.yAxis[0].title.text = null;
    }
    if (chartSettings['crossHairs'] == true) {
        options.tooltip.crosshairs = [{
            width: 2,
            color: 'gray',
            dashStyle: 'shortdot'
        }, {
            width: 2,
            color: 'gray',
            dashStyle: 'shortdot'
        }];
    } else {
        options.tooltip.crosshairs = false;
    }
    if (chartSettings['gridLine'] == true) {
        options.yAxis[0].gridLineWidth = 1;
    } else {
        options.yAxis[0].gridLineWidth = 0;
    }
    /*if(chartSettings['dataLabel']==true){
        options.plotOptions.series.dataLabels.enabled= true;
    }else{
        options.plotOptions.series.dataLabels.enabled= false;
    }*/
    if (chartSettings['dataLabel'] == true) {
        options.plotOptions.series.dataLabels.enabled = true;
        if (chartType === 'bar' || chartType === 'multicolorbar') { //fix for overflowing chart data labels
            setTimeout(function () {
                var maxnum = $('#' + chartid + ' .highcharts-drilldown-data-label tspan').map(function () { return $(this).width(); }).get();
                var maxnum = Math.max.apply(Math, maxnum);
                if (maxnum < 30) {
                    options.chart.marginTop = 10;
                } else if (maxnum > 30 && maxnum < 50) {
                    options.chart.marginTop = 40;
                } else if (maxnum > 50 && maxnum < 80) {
                    options.chart.marginTop = 60;
                } else if (maxnum > 80 && maxnum < 100) {
                    options.chart.marginTop = 100;
                } else if (maxnum >= 100) {
                    options.chart.marginTop = 140;
                }
                var chart = new Highcharts.Chart(options);
                charts[chartid] = chart;
                $("#" + chartid + " svg>title").remove();
            }, 200);
            // options.chart.spacingTop = 0;
            if (isExportChartEnable) {
                options.navigation = { "buttonOptions": { "verticalAlign": "top", "y": 20 } };
            }
        }
    } else {
        options.plotOptions.series.dataLabels.enabled = false;
    }
    var chart = new Highcharts.Chart(options);
    charts[chartid] = chart;
    $("#" + chartid + " svg>title").remove();
    var chartnum = chartid.replace("chart", "");
    ChartInfo.functions.setChartSize(chartnum, chart);

    var compos = {};
    compos["chart"] = chart;
    compos["chartType"] = chartType;
    ChartInfo.functions.setChartComponents(chartnum, compos);
}

export function deletetrendstats(e) {
    var $this = e.currentTarget;
    var currentChartNumber = $(".trendstatstable.table").attr("data-num");
    var data = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber));
    data["trendLine"] = undefined;
    $('.trendtype[data-num=' + currentChartNumber + ']').removeClass("showactive");
    ChartInfo.functions.updateChartDetails(currentChartNumber, JSON.stringify(data));
    $('.trend-stats-container').remove();
    //this.broker.trigger('applyfiltesandredrawchart', undefined,currentChartNumber,"", undefined,undefined,"yes");
}

export function addNarrativeContent(chartnumber, chartSettings, chart, trendstatsdata) {
    chart = charts['chart' + chartnumber];
    //Get Current Chart Information
    var currentChartInfo = ChartInfo.functions.getChartDetails(chartnumber);
    if (currentChartInfo != null && currentChartInfo != undefined && currentChartInfo != "" && currentChartInfo.legend == undefined) {
        currentChartInfo = JSON.parse(currentChartInfo);
        if (currentChartInfo.legend == undefined) {
            var SortingOrder = currentChartInfo.orderType.substr(0, 1).toUpperCase() + currentChartInfo.orderType.substr(1);
            var ColumnLimit = currentChartInfo.colLimit
            if (SortingOrder == "None") { SortingOrder = ""; ColumnLimit = ""; }
            /*if(currentChartInfo.legend!=undefined){
                var objLength = 0;
                trendstatsdata = trendstatsdata.filter(function(value, index, Arr) {
                                    return index % 3 != 0;
                                 });
            }*/
            //if(trendstatsdata[1][trendstatsdata[0].name][1]=="trendstats"){
            //Narrative Content Making
            var xAxisName = chart.userOptions.xAxis[0].title.text;
            if (xAxisName.indexOf("histogram") > 0) {
                xAxisName = chart.userOptions.xAxis[0].title.orginaltextName;
            }
            var yAxisNames = [];
            var yAxisTitles = [];
            var yAxisTotals = {};
            var yAxisMinMax = {};
            var yAxisMinMaxPercentages = {};
            var yAxisAverages = {};
            _.each(trendstatsdata, function (trend, a) {
                if (trend.type != undefined && trend.type === "pareto") {
                } else {
                    if (a % 2 != 0 && trend.yaxisname == undefined) {
                        yAxisNames.push(Object.keys(trend)[0])
                    } else {
                        var aggregationSelected = trend.aggType.toLowerCase() + " " + lang["reports.narrative.of"]
                        if (trend.aggType.toLowerCase() == "customaggregatedfield" || trend.aggType.toLowerCase() == "custommeasurehierarchy") {
                            aggregationSelected = ""
                        }
                        var totaltrenddata = ChartInfo.functions.addFormatting(trend.totaldata, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                        yAxisTitles.push(aggregationSelected + " <b>" + trend.displayname + "</b>");
                        yAxisTotals[trend.displayname] = lang["reports.narrative.thetotal"] + " " + aggregationSelected + " <b>" + trend.displayname + "</b> is " + totaltrenddata + ", " + " " + lang["reports.narrative.distributedacross"] + " " + trend.data.length + " <b>" + xAxisName + "</b>"
                        var averagetrenddata = ChartInfo.functions.addFormatting(Math.round((trend.totaldata / trend.data.length) * 100) / 100, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                        yAxisAverages[trend.displayname] = lang["reports.narrative.theavgof"] + " " + aggregationSelected + " <b>" + trend.displayname + "</b> is " + averagetrenddata + "";
                        //Find Minimum and Maximum Points
                        var lowest = Math.min.apply(Math, trend.data.map(function (o) { return o.y; }));
                        var highest = Math.max.apply(Math, trend.data.map(function (o) { return o.y; }));
                        var lowestcount = ChartInfo.functions.addFormatting(lowest, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                        var highestcount = ChartInfo.functions.addFormatting(highest, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                        var lowestnames = [];
                        var highestnames = [];
                        var lcount = 0, mcount = 0;
                        var ltotalcount = 0, mtotalcount = 0;
                        trend.data.filter(function (elem) {
                            if (elem.y == lowest) { ltotalcount++ }
                            if (elem.y == lowest && lcount <= 3) { lowestnames.push(elem.name); lcount++ } else if (lcount == 4) { lowestnames.push("..."); lcount++ }
                        });
                        trend.data.filter(function (elem) {
                            if (elem.y == highest) { mtotalcount++ }
                            if (elem.y == highest && mcount <= 3) { highestnames.push(elem.name); mcount++ } else if (mcount == 4) { highestnames.push("..."); mcount++ }
                        });

                        var lpercent = (ltotalcount / trend.data.length) * 100;
                        var mpercent = (mtotalcount / trend.data.length) * 100;
                        lpercent = Math.round(lpercent * 100) / 100;
                        mpercent = Math.round(mpercent * 100) / 100;

                        if (lpercent != 0 && mpercent != 0) {
                            yAxisMinMaxPercentages[trend.displayname] = lang["reports.narrative.minvalof"] + " " + lowestcount + " " + lang["reports.narrative.for"] + " " + aggregationSelected + " <b>" + trend.displayname + "</b> " + lang["reports.narrative.isdistributedby"] + " " + lpercent + lang["reports.narrative.maxvalof"] + " " + highestcount + "  " + lang["reports.narrative.isdistributedby"] + " " + mpercent + lang["reports.narrative.%ofdata"]
                        }
                        //console.log(ltotalcount+", %"+lpercent);
                        //console.log(mtotalcount+", %"+mpercent)
                        if (lowestnames.length > 1) {
                            lowestnames = Object.values(lowestnames).join(", ");
                            lowestnames = "(" + lowestnames + ")";
                        } else {
                            lowestnames = Object.values(lowestnames)[0]
                        }
                        if (highestnames.length > 1) {
                            highestnames = Object.values(highestnames).join(", ");
                            highestnames = "(" + highestnames + ")";
                        } else {
                            highestnames = Object.values(highestnames)[0]
                        }
                        yAxisMinMax[trend.displayname] = lang["reports.narrative.For"] + " " + aggregationSelected + " <b>" + trend.displayname + "</b> " + lang["reports.narrative.minvalis"] + " " + lowestcount + " " + lang["reports.narrative.drivenby"] + " " + lowestnames + ", " + lang["reports.narrative.andmaxvalis"] + " " + highestcount + " " + lang["reports.narrative.drivenby"] + " " + highestnames + "."
                        //console.log(highest, highestname, lowest, lowestname);
                    }
                }
            })

            if (yAxisTitles.length > 1) {
                yAxisTitles = Object.values(yAxisTitles).join(", and ")
            } else {
                yAxisTitles = Object.values(yAxisTitles)[0]
            }
            //Start Narration Appending
            $("#chart" + chartnumber + "").prepend('<div class="narrativecontainer top" chart-id="' + chartnumber + '"></div>');
            var $narrativeContainer = $("#chart" + chartnumber + " .narrativecontainer");
            //Append Narration Intro
            $($narrativeContainer).append('<p>' + lang["reports.narrative.thisanalysisshows"] + " " + SortingOrder + ' ' + ColumnLimit + ' ' + yAxisTitles + " " + lang["reports.narrative.by"] + ' <b>' + xAxisName + '</b>.</p>');
            $($narrativeContainer).append('<ul class="narrative-list-container"></ul>');
            var $narrativeContainerList = $("#chart" + chartnumber + " .narrativecontainer>ul.narrative-list-container");
            //Append Narration Points
            _.each(yAxisTotals, function (a) {
                $($narrativeContainerList).append('<li>' + a + '</li>');
            });
            _.each(yAxisMinMax, function (a) {
                $($narrativeContainerList).append('<li>' + a + '</li>');
            });
            _.each(yAxisMinMaxPercentages, function (a) {
                $($narrativeContainerList).append('<li>' + a + '</li>');
            });
            _.each(yAxisAverages, function (a) {
                $($narrativeContainerList).append('<li>' + a + '</li>');
            });
            var narrativeHieght = (chart.containerHeight / 4) * 3;
            //Update Top Space
            chart.update({ chart: { spacing: [20, 5, 5, 5] } }, true)
            chart.setSize(chart.containerWidth, narrativeHieght, false);
            chart.reflow();
            //Fix for Series Colors Position
            $(".chartadvancedoptions[data-num='chart" + chartnumber + "']").css({ "top": "25%" });
            //}
        }
    }
}

export function addColumnsData(jsondata) {
    columnsData = jsondata;
}

export function addpointforliveData(response, chartnumber, reportId) {
    var data = response[0]["data"];
    var defaultDateFormat = response["defaultDateFormat"];
    var dataCount = response[0].filterCount;
    let chartComponent = ChartInfo.functions.getChartComponents(chartnumber);
    let chart = chartComponent && chartComponent["chartComponent"]&&chartComponent["chartComponent"]["chart"]?chartComponent["chartComponent"]["chart"]:undefined;
    if(!charts["chart" + chartnumber] && chart) charts["chart" + chartnumber]=chart
    if (chartnumber == -1) {
    } else {
        var webSocket_ReportId_Mapping = ChartInfo.functions.getwebsocketwithchartnumber(reportId);
        if (webSocket_ReportId_Mapping != undefined && webSocket_ReportId_Mapping.indexOf(chartnumber) > -1) {
            return true;
        }
        var details = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
        if (details['chartType'] == "pivot" || details['chartType'] == "summarytable" || details['chartType'] == "doby"
            || details['chartType'] == "textchart" || details['chartType'] == "boxplot" || details['chartType'] == "heatmap"
            || details['chartType'] == "sankey" || details['chartType'] == "wordcloud" || details['chartType'] == "world" || details['chartType'] == "srilankamap" || details['chartType'] == "leaflet" || details['chartType'] == "countrymap" || details['chartType'] == "indiamap") {
            return false;
        }
        if (details['chartType'] == "multixbar") {
            charts["chart" + chartnumber].xAxis[0].setCategories(response[0].categories);
        }
        if (details['chartType'] == "metric") {

            //Conditional Formating
            var metriccolor = "";
            var conditionalFormatData = [];
            if (ChartInfo.functions.getConditionalFormatData(chartnumber) != undefined) {
                conditionalFormatData = ChartInfo.functions.getConditionalFormatData(chartnumber);
            }
            if (conditionalFormatData != undefined && conditionalFormatData.length > 0) {
                var datavalue = response[0].y;
                _.each(conditionalFormatData, function (conditions) {
                    if (details.yAxis[0].displayName == conditions.name) {
                        _.each(conditions.conditions, function (i) {
                            var value = i.value;
                            var range = i.range;
                            if (range == "<") {
                                if (datavalue < value) {
                                    metriccolor = i.color;
                                }
                            } else {
                                if (datavalue > value) {
                                    metriccolor = i.color;
                                }
                            }
                        });
                    }
                });
            }
            $("#chart" + chartnumber + " .metricchart .metricvaluecontainer").html(response[0].y);
            if (metriccolor != undefined && metriccolor != "") {
                $("#chart" + chartnumber + " .metricchart").css("color", metriccolor)
            }

            var metrictarget = $("#chart" + chartnumber + " .metrictargetvaluecontainer").attr("target-value");
            if (metrictarget != null && metrictarget != undefined && metrictarget != "") {
                var metricachievement = ($.formatNumber((response[0].y / metrictarget * 100), { format: "#,###.##" }))
                if (metrictarget == 0) {
                    metricachievement = 0
                }
                $("#chart" + chartnumber + " .metricchartachievement .metricachievementvaluecontainer").html(metricachievement);
            }

        } else if (details['chartType'] == "solidgauge" || details['chartType'] == "fullcirclegauge") {
            if(charts["chart" + chartnumber]){
                charts["chart" + chartnumber].series[0].points[0].update(response[0].y);
                charts["chart" + chartnumber].userOptions.yAxis[0].tickPositions[3] = response[0].y;
                charts["chart" + chartnumber].render();
            }
        } else if (details['chartType'] == "heatmap") {
            /*//	charts["chart"+chartnumber].yAxis[0].setCategories(response[1]);
                //charts["chart"+chartnumber].xAxis[0].setCategories(response[0]);
                charts["chart"+chartnumber].series[0].setData(response[2]);*/
        } else {
            var updatedData = createDataAsPerChartType(details, response);
            if (details.chartType.indexOf("series") >= 0) {
                if(charts["chart" + chartnumber]){
                    _.each(updatedData, function (data, index) {
                        charts["chart" + chartnumber].series[index].setData(data,true);
                    });
                }
            } else {
                var tempdata = [];
                var int = 0;
                if (details.legend != undefined) {
                    let compareDateRangeObj = details != undefined && details["compareDateRangeObj"] != null ? details["compareDateRangeObj"] : null;
                    let hasCategories = false;
                    let legendindex = 0;
                    let seriesData = [];
                    if (compareDateRangeObj != null && compareDateRangeObj[0] != null) {
                        _.each(response, function (dataitem, index) {
                            hasCategories = false;
                            let categories = [];

                            if (dataitem != undefined && dataitem.categories != undefined && dataitem.categories != null && dataitem.compareSeriesEnabled) {
                                categories = dataitem.categories;
                                hasCategories = true;
                                response.splice(index, 1);
                            }
                            if (legendindex != 0 && compareDateRangeObj != null && hasCategories) {
                                hasCategories = true;
                                legendindex++;
                            } else if (legendindex == 0 && compareDateRangeObj != null && hasCategories) {
                                try {
                                    charts["chart" + chartnumber].xAxis[legendindex].setCategories(categories);
                                } catch (e) {
                                    console.error(e)
                                }
                                legendindex++;
                            }
                        });
                        let legendindexer = 0;
                        _.each(response, function (dataitem, index) {
                            if (index & 1 == 1) {
                                if (dataitem != undefined) {
                                    if (legendindexer == 0) {
                                        _.each(dataitem, function (i, j) {
                                            let data = {}
                                            data["data"] = i.data;
                                            seriesData.push(data)
                                        })
                                    }
                                    legendindexer++
                                }
                            }
                        })
                        _.each(seriesData, function (val, index) {
                            charts["chart" + chartnumber].series[index].setData(val.data);
                        })
                    } else {
                        try {
                            // charts["chart" + chartnumber].xAxis[0].setCategories(response[0]);
                            charts["chart" + chartnumber].xAxis[0].update({categories: response[0]});
                        } catch (e) {
                            console.error(e)
                        }
                        let yindex=0;
                        _.each(response, function (obj, index) {
                            if (index & 1 == 1) {
                                _.each(obj, function (i, j) {
                                    // charts["chart" + chartnumber].series[j].setData(i.data);
                                    // fix for multi-y live issue
                                    charts["chart" + chartnumber].series[yindex].setData(i.data);
                                    yindex++;
                                })
                            }
                        })
                    }
                } else {
                    _.each(response, function (obj, index) {
                        charts["chart" + chartnumber].series[index].setData(obj.data);
                    })
                }
            }
        }
    }
    $(".publishinfo").text("Dataset Count: " + dataCount);
    $(".liveupdatetime").html("Last Updated: " + new Date().toLocaleString());
    $(".itslive").attr("title", "Last Updated: " + new Date().toLocaleString());
}
export function addpointOnStreaming(data, reportId) {

    // The below code update metric chart only
    //					var updatedatacount = false;
    var AllChartDetails = ChartInfo.functions.getAllChartDetails();
    //					var dataCount = data[0].datacount; 
    //					ChartInfo.functions.setDataSetCount(dataCount);
    _.each(streamingcharts, function (chartnumber) {
        if (ChartInfo.functions.getChartDetails(chartnumber) == undefined) {
            return false;
        }
        var webSocket_ReportId_Mapping = ChartInfo.functions.getwebsocketwithchartnumber(reportId);
        var details = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
        let chartComponent = ChartInfo.functions.getChartComponents(chartnumber);
        let chart = chartComponent && chartComponent["chartComponent"]&&chartComponent["chartComponent"]["chart"]?chartComponent["chartComponent"]["chart"]:undefined;
        if(!charts["chart" + chartnumber] && chart) charts["chart" + chartnumber]=chart
        if (details["reportid"] != undefined && details["reportid"] + "" != reportId) {
            return false;
        }
        if (webSocket_ReportId_Mapping != undefined && (webSocket_ReportId_Mapping.indexOf("-1") >= 0
            || webSocket_ReportId_Mapping.indexOf(chartnumber) >= 0)) {

            if (ChartInfo.functions.getIntervalValue(chartnumber) != undefined) {
                return false;
            }
            if (details['chartType'] == "metric") {
                var currentLayout = "0";
                if (details.metricfont != undefined && details.metricfont.layout != undefined) {
                    currentLayout = details.metricfont.layout
                }
                if (currentLayout == "0") {
                    var yaxisArray = details.yAxis;
                    var xaxisname = details.xAxis[0];
                    var metriccolor = "";
                    var conditionalFormatData = [];
                    if (ChartInfo.functions.getConditionalFormatData(chartnumber) != undefined) {
                        conditionalFormatData = ChartInfo.functions.getConditionalFormatData(chartnumber);
                    }

                    _.each(data, function (obj) {
                        _.each(yaxisArray, function (value, index) {
                            $("#chart" + chartnumber + " .metricvaluecontainer").html(obj[yaxisArray[index].columnName]);
                            var datavalue = obj[yaxisArray[index].columnName];
                            if (conditionalFormatData != undefined && conditionalFormatData.length > 0) {
                                _.each(conditionalFormatData, function (conditions) {
                                    if (yaxisArray[index].displayName == conditions.name) {
                                        _.each(conditions.conditions, function (i) {
                                            var value = i.value;
                                            var range = i.range;
                                            if (range == "<") {
                                                if (datavalue < value) {
                                                    metriccolor = i.color;
                                                }
                                            } else {
                                                if (datavalue > value) {
                                                    metriccolor = i.color;
                                                }
                                            }
                                        });
                                    }
                                });
                            }

                            if (metriccolor != undefined && metriccolor != "") {
                                $("#chart" + chartnumber + " .metricvaluecontainer").css("color", metriccolor)
                            }
                        });
                    });
                    //								if(!$(".maxscreendatacount[data-num="+chartnumber+"]").hasClass("hide")){
                    //									$(".maxscreendatacount[data-num="+chartnumber+"] .publishinfo").text("Dataset Count: "+ dataCount);
                    //								}
                    //								updatedatacount= true;
                }
            } else if (details['chartType'] == "solidgauge" || details['chartType'] === "fullcirclegauge") {
                var yaxisArray = details.yAxis;
                var xaxisname = details.xAxis[0];
                _.each(data, function (obj) {
                    _.each(yaxisArray, function (value, index) {
                        charts["chart" + chartnumber].series[index].points[0].update(obj[yaxisArray[index].columnName]);
                        charts["chart" + chartnumber].userOptions.yAxis[0].tickPositions[3] = obj[yaxisArray[index].columnName];
                        charts["chart" + chartnumber].render();
                    });
                });
                //								if(!$(".maxscreendatacount[data-num="+chartnumber+"]").hasClass("hide")){
                //									$(".maxscreendatacount[data-num="+chartnumber+"] .publishinfo").text("Dataset Count: "+ dataCount);
                //								}
                //								updatedatacount= true;
            } else if (details['chartType'] == "bartimeseries" || details['chartType'] == "linetimeseries" || details['chartType'] == "areatimeseries") {
                var yaxisArray = details.yAxis;
                var xaxisname = details.xAxis[0];
                _.each(data, function (obj) {
                    _.each(yaxisArray, function (value, index) {
                        if (obj[xaxisname.columnName] != undefined && obj[yaxisArray[index].columnName] != undefined) {
                            var xAxisValue = obj[xaxisname.columnName] + "";
                            if (xaxisname.dataType == "date") {
                                xAxisValue = moment(xAxisValue, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD HH:mm:ss.SSS");
                            }
                            if (xaxisname.dataType == "date" && details['chartType'].indexOf("series") >= 0) {
                                var utcDate = moment.utc();
                                xAxisValue = Date.UTC(moment().year(), moment().month(), moment().date(), moment().hours(), moment().minute(), moment().seconds());
                            }
                            try {
                                charts["chart" + chartnumber].series[index].addPoint([xAxisValue, parseInt(obj[yaxisArray[index].columnName])]);
                                if ($("#chart" + chartnumber + ".streamingnow").length == 0) {
                                    $("#chart" + chartnumber + " .highcharts-container .highcharts-range-selector-buttons>g").eq(1).click();
                                    $("#chart" + chartnumber).addClass("streamingnow");
                                }
                            } catch (e) {

                            }
                        }
                    });
                });
                //								if(!$(".maxscreendatacount[data-num="+chartnumber+"]").hasClass("hide")){
                //									$(".maxscreendatacount[data-num="+chartnumber+"] .publishinfo").text("Dataset Count: "+ dataCount);
                //								}
                //								updatedatacount= true;
            }
        }
    });
    //					if(updatedatacount){
    //						$(".storyboarddatacount .publishinfo").text("Dataset Count: "+ dataCount);
    //					}
    $(".liveupdatetime").html("Last Updated: " + new Date().toLocaleString());
    $(".itslive").attr("title", "Last Updated: " + new Date().toLocaleString());
}
export const createDataAsPerChartType = (details, data) => {

    var xaxisName = "";
    var xaxisColumnName = "";
    var xaxisdataType = "";
    var title = "";
    xaxisColumnName = details["xAxis"][0].columnName;
    xaxisdataType = details["xAxis"][0].dataType;
    var metrictype = details["metricdatetype"];
    var metricdatefield = details["metricdatefield"];
    var chartType = details["chartType"];
    var referenceLine = details["reference"];
    var colorThemeIndex = details["colorThemeIndex"];
    var yAxisNameArray = [];
    _.each(details["yAxis"], function (val) {
        yAxisNameArray.push(val.displayName);
    });
    var yAxisOriginalNameArray = [];
    _.each(details["yAxis"], function (val) {
        yAxisOriginalNameArray.push(val.columnName);
    });
    var tooltipNameArray = [];
    _.each(details["tooltip"], function (val) {
        tooltipNameArray.push(val.displayName);
    });
    var zAxisNameArray = [];
    _.each(details["zAxis"], function (val) {
        zAxisNameArray.push(val.columnName);
    });
    var legend = details["legend"];
    if (details.chartType.indexOf("series") >= 0) {
        var data_multiple = [];
        if (legend != undefined && legend.toString().length > 0
            && legend.columnName != undefined
            && legend.columnName.length > 0) {
            _.each(data[1], function (m, k) {
                var multi = [];
                var x = data[1][k].data;
                _.each(x, function (j, i) {
                    var newData = [];
                    var myDate = x[i].name;
                    if (myDate.length > 0) {
                        var newDate = moment.utc(myDate);
                        if (isNaN(newDate)) {
                            var newdate1 = newDate;
                            newData.push(newdate1);
                        } else
                            newData.push(new Date(newDate).getTime());
                    } else
                        newData.push(x[i].name);
                    newData.push(x[i].y);
                    if (newData[0].length == 0) {
                        var newData1 = [];
                        newData1.push(new Date().getTime());
                        newData1.push(0);
                        multi.push(newData1);
                    } else {
                        multi.push(newData);
                    }
                });
                multi = multi.sort(function sort_by_column(a, b) {
                    return ((a[0] < b[0]) ? -1 : ((a[0] > b[0]) ? 1
                        : 0));
                });
                data_multiple.push(multi);
            });
        } else {
            var seriesOptions = [];
            _.each(data, function (m, k) {
                var x = data[k].data;
                var multi = [];
                _.each(x, function (j, i) {
                    var newData = [];
                    var myDate = x[i].name;
                    var newDate = moment.utc(myDate);
                    if (isNaN(newDate)) {
                        newData.push(newDate);
                    } else
                        newData.push(new Date(newDate).getTime());
                    newData.push(x[i].y);
                    multi.push(newData);
                });
                multi = multi.sort(function sort_by_column(a, b) {
                    return ((a[0] < b[0]) ? -1 : ((a[0] > b[0]) ? 1
                        : 0));
                });

                seriesOptions[k] = {
                    name: m.name,
                    data: multi,
                    aggType: m.aggType,
                    gapSize: 5,
                    tooltip: {
                        valueDecimals: 2
                    },
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, colorThemesArray[colorThemeIndex][k]],
                            [1, Highcharts.Color(colorThemesArray[colorThemeIndex][k]).setOpacity(0.5).get('rgba')]
                        ]
                    }
                };
                data_multiple.push(multi);
            });
        }
        return data_multiple;
    }
    return data[0]["data"];
}
export function isTrendSupport(chartType) {
    var trendNotSupportCharts = ["doby", "summarytable", "pivot", "heatmap", "metric", "pie", "donut", "scatter", "metric", "funnel", "pyramid", "wordcloud", "3dpie", "fullcirclegauge", "solidgauge", "srilankamap", "world", "leaflet", "countrymap", "indiamap", "semicircle", "boxplot", "textchart", "trellis", "bubble", "sankey", "combinationchart"];
    if (trendNotSupportCharts.indexOf(chartType) >= 0) {
        return false;
    }
    else {
        return true;
    }
}
export function drawBootstrapTableForNLP(headers, response) {
    var $el;
    $el = $("#datasearch");

    var coldef = [];
    _.each(headers, function (value) {
        var col = {};
        col["title"] = value.name;
        col["field"] = value.name;
        col["align"] = value.type != null && value.type == 'number' ? 'right' : "left";
        coldef.push(col);
    })
    var dataArray = [];
    _.each(response, function (value) {
        if (value["data"] != undefined)
            Object.keys(value["data"]).map(key=> {
                if(value["data"][key] && InputValidate({"text":value["data"][key],"isFrom":"nlp"})){
                    value["data"][key] = value["data"][key].replaceAll("<","&lt").replaceAll(">","&gt");
            }});
            dataArray.push(value["data"])
    });
    $el.bootstrapTable('destroy');
    var tableHeight = 510;
    if (dataArray.length === 0) {
        tableHeight = 266
    }
    $el.bootstrapTable({ columns: coldef, data: dataArray, height: tableHeight })
    var that = this;
    //that.resizeNLPTable($el);
    var tableHeight = 510;
    tableHeight = window.innerHeight - ($("nav.navbar").height() + $(".datamodelsearchcontainer>.container-fluid:first-child").height() + $("#paginate").height() + 50)
    $el.bootstrapTable('resetView', { height: tableHeight });
    $(window).on("resize", function () {
        // that.resizeNLPTable($el)
        var tableHeight = 510;
        tableHeight = window.innerHeight - ($("nav.navbar").height() + $(".datamodelsearchcontainer>.container-fluid:first-child").height() + $("#paginate").height() + 50)
        $el.bootstrapTable('resetView', { height: tableHeight });
    })


}
export function resizeNLPTable(el) {
    var $el = el;
    var tableHeight = 510;
    tableHeight = window.innerHeight - ($("nav.navbar").height() + $(".datamodelsearchcontainer>.container-fluid:first-child").height() + $("#paginate").height() + 50)
    $el.bootstrapTable('resetView', { height: tableHeight });

}
export function addMetricToChart(chartnumber, chartSettings, chart, data, color) {
    $("#chartmetric" + chartnumber).remove();
    var advanced = chartSettings["AdvancedChartSettings"];
    //Fix for Trend
    if (data != undefined) {
        var newdata = [];
        var actualdata = data;
        _.each(data, function (ditem) {
            if (ditem.displayname != undefined) {
                newdata.push(ditem)
            }
        });
        data = newdata;
    }
    if (data != undefined && data.length == 1 && chartSettings != undefined && chartSettings["AdvancedChartSettings"] != undefined && advanced.xAxis[0].lineWidth == "0" && advanced.xAxis[0].tickWidth == "0" && advanced.yAxis[0].visible == false) {
        var currentvalue = data[0].totaldata;
        if (data[0].aggType == "Average") {
            currentvalue = data[0].totaldata / data[0].data.length;
        }
        currentvalue = currentvalue.toFixed(2);
        if (currentvalue != null && currentvalue != undefined && currentvalue != "") {
            currentvalue = parseFloat(currentvalue);
        }
        $("#chartmetric" + chartnumber).remove();
        $("#chart" + chartnumber).after('<div id="chartmetric' + chartnumber + '" class="metricchart new" style="color:' + color + '"><span class="metricprefixcontent" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; font-size: 10px;display: block;"></span><span class="metricvaluecontainer" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; font-size: 28px;">' + currentvalue + '</span><span class="metricsuffixcontent" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; line-height: 1em; font-size: 28px;"></span><br></div>');
    } else if (data != undefined && data.length == 2 && chartSettings != undefined && chartSettings["AdvancedChartSettings"] != undefined && advanced.xAxis[0].lineWidth == "0" && advanced.xAxis[0].tickWidth == "0" && advanced.yAxis[0].visible == false) {
        var currentvalue = data[1].totaldata;
        var previousvalue = data[0].totaldata;

        if (data[0].aggType == "Average" && data[1].aggType == "Average") {
            if (data[0].totaldata != 0 && data[0].data.length != 0) {
                previousvalue = data[0].totaldata / data[0].data.length;
            }
            if (data[1].totaldata != 0 && data[1].data.length != 0) {
                currentvalue = data[1].totaldata / data[1].data.length;
            }
        }
        if (previousvalue == undefined || previousvalue == 0) {
            var difference = 0;
        } else {
            var difference = ((currentvalue - previousvalue) * 100) / previousvalue;
        }

        currentvalue = currentvalue.toFixed(2);
        if (currentvalue != null && currentvalue != undefined && currentvalue != "") {
            currentvalue = parseFloat(currentvalue);
        }

        $("#chartmetric" + chartnumber).remove();
        $("#chart" + chartnumber).after('<div id="chartmetric' + chartnumber + '" class="metricchart new" style="color:' + color + '"><span class="metricprefixcontent" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; font-size: 10px;display: block;"></span><span class="metricvaluecontainer" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; font-size: 28px;">' + currentvalue + '</span><span class="metricsuffixcontent" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; line-height: 1em; font-size: 28px;"></span><br></div>');
        if (0 < difference) {
            $("#chartmetric" + chartnumber).append('<span class="label-metric metric-increase" data-toggle="tooltip" data-placement="top" title=""><i class="fa fa-caret-up fa-solid"></i><span>&nbsp;' + difference.toFixed(2) + '%</span></span>');
        } else if (0 == difference) {
            $("#chartmetric" + chartnumber).append('<span class="label-metric metric-stable" data-toggle="tooltip" data-placement="top" title=""><i class="fa fa-sort fa-solid"></i><span>&nbsp;0%</span></span>');
        } else if (0 > difference) {
            $("#chartmetric" + chartnumber).append('<span class="label-metric metric-decrease" data-toggle="tooltip" data-placement="top" title=""><i class="fa fa-caret-down fa-solid"></i><span>&nbsp;' + difference.toFixed(2) + '%</span></span>');
        }
    } else if (data != undefined && data[0].data != undefined && data[0].data.length > 1) {
        var labelname = data[0].data[data[0].data.length - 1].name;
        var currentvalue = data[0].data[data[0].data.length - 1].y;
        var previousvalue = data[0].data[data[0].data.length - 2].y;
        if (currentvalue == null && previousvalue == null) {
            currentvalue = "Null";
            $("#chartmetric" + chartnumber).remove();
            $("#chart" + chartnumber).after('<div id="chartmetric' + chartnumber + '" class="metricchart new" style="color:' + color + '"><span class="metricprefixcontent" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; font-size: 10px;display: block;"></span><span class="metricvaluecontainer" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; font-size: 28px;">' + "0" + '</span><span class="metricsuffixcontent" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; line-height: 1em; font-size: 28px;"></span><br></div>');
        }
        else if (currentvalue != null && previousvalue == null) {
            currentvalue = parseFloat(currentvalue);
            $("#chartmetric" + chartnumber).remove();
            $("#chart" + chartnumber).after('<div id="chartmetric' + chartnumber + '" class="metricchart new" style="color:' + color + '"><span class="metricprefixcontent" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; font-size: 10px;display: block;"></span><span class="metricvaluecontainer" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; font-size: 28px;">' + currentvalue + '</span><span class="metricsuffixcontent" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; line-height: 1em; font-size: 28px;"></span><br></div>');
        }
        else if (currentvalue == null && previousvalue != null) {
            currentvalue = "Null";
            $("#chartmetric" + chartnumber).remove();
            $("#chart" + chartnumber).after('<div id="chartmetric' + chartnumber + '" class="metricchart new" style="color:' + color + '"><span class="metricprefixcontent" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; font-size: 10px;display: block;"></span><span class="metricvaluecontainer" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; font-size: 28px;">' + "0" + '</span><span class="metricsuffixcontent" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; line-height: 1em; font-size: 28px;"></span><br></div>');
        }
        else {
            var difference = ((currentvalue - previousvalue) * 100) / previousvalue;
            currentvalue = currentvalue.toFixed(2);
            if (currentvalue != null && currentvalue != undefined && currentvalue != "") {
                currentvalue = parseFloat(currentvalue);
            }
            $("#chartmetric" + chartnumber).remove();
            $("#chart" + chartnumber).after('<div id="chartmetric' + chartnumber + '" class="metricchart new" style="color:' + color + '"><span class="metricprefixcontent" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; font-size: 10px;display: block;"></span><span class="metricvaluecontainer" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; font-size: 28px;">' + currentvalue + '</span><span class="metricsuffixcontent" style="font-family: Lato, &quot;Source Sans Pro&quot;, sans-serif; line-height: 1em; font-size: 28px;"></span><br></div>');
            if (0 < difference) {
                $("#chartmetric" + chartnumber).append('<span class="label-metric metric-increase" data-toggle="tooltip" data-placement="top" title=""><i class="fa fa-caret-up fa-solid"></i><span>&nbsp;' + difference.toFixed(2) + '%</span></span>');
            } else if (0 == difference) {
                $("#chartmetric" + chartnumber).append('<span class="label-metric metric-stable" data-toggle="tooltip" data-placement="top" title=""><i class="fa fa-sort fa-solid"></i><span>&nbsp;0%</span></span>');
            } else if (0 > difference) {
                $("#chartmetric" + chartnumber).append('<span class="label-metric metric-decrease" data-toggle="tooltip" data-placement="top" title=""><i class="fa fa-caret-down fa-solid"></i><span>&nbsp;' + difference.toFixed(2) + '%</span></span>');
            }
        }
    }
}

export function updateYAxisScale(chartSettings, options, chartnumber) {
    /*if(chartSettings!=undefined && chartSettings.yAxis!=undefined){
        if(chartSettings.yAxis.min!=""){
            options.yAxis[0].min = chartSettings.yAxis.min;
            options.yAxis[0].startOnTick= false;
        }
        if(chartSettings.yAxis.max!=""){
            options.yAxis[0].max = chartSettings.yAxis.max;
            options.yAxis[0].endOnTick= false;
            options.yAxis[0].showLastLabel= true
        }
    }*/
    if (chartSettings != undefined && chartSettings.yAxis != undefined) {
        var chart = charts['chart' + chartnumber];
        var min = chartSettings.yAxis.min,
            max = chartSettings.yAxis.max;
        if (chartSettings.yAxis.min != "" || chartSettings.yAxis.max != "") {
            if (chartSettings.yAxis.min != "") {
                min = parseInt(min);
                chart.yAxis[0].update({
                    min: min
                });
            }
            if (chartSettings.yAxis.max != "") {
                max = parseInt(max);
                chart.yAxis[0].update({
                    max: max
                });
            }
            if (chartSettings.yAxis.min != "") {
                chart.yAxis[0].tickPositions.unshift(min);
            }
            if (min != "" && chart.yAxis[0].tickPositions[0] >= chart.yAxis[0].tickPositions[1]) {
                var index = chart.yAxis[0].tickPositions.indexOf(chart.yAxis[0].tickPositions[1]);
                chart.yAxis[0].tickPositions.splice(index, 1);
            }
            if (chartSettings.yAxis.max != "") {
                chart.yAxis[0].tickPositions.push(max);
            }
            chart.yAxis[0].update({
                tickPositions: chart.yAxis[0].tickPositions
            });
        }
    }
}
export function addAnnotation(currentChart, chartSettings, chart, data, e) {
    //Chart Info
    var currentChartInfo = JSON.parse(ChartInfo.functions.getChartDetails(currentChart));
    chartSettings = currentChartInfo.chartSettings;
    $(".annotatation-text-box").remove();
    $("body").prepend('<div class="annotatation-text-box" data-num="' + currentChart + '">'
        + '<div class="annotatation-form-box" data-num="' + currentChart + '">'
        + '<div class="container-fluid marg-top-10 marg-bot-15">'
        + '<legend class="subhead marg-bot-5"><div class="attraxisnames">Add Text</div></legend>'
        + '<input type="text" class="form-control annotatation-text" data-num="' + currentChart + '">'
        + '</div>'
        + '<div class="col-xs-12 marg-bot-10 text-right">'
        + '<button type="button" class="btn btn-bird cancelannotation pull-left" data-num="' + currentChart + '"><i class="fa fa-times"></i> Cancel</button>'
        + '<button type="button" class="btn btn-bird btn-highlight applyannotation" data-num="' + currentChart + '"><i class="fa fa-check"></i> Add</button>'
        + '</div>'
        + '</div>'
        + '</div>');
    $(".cancelannotation").on("click", function () {
        $(".annotatation-text-box").remove();
    });
    if (chartSettings.annotations == undefined) {
        var annotations = [{
            id: currentChart,
            labelOptions: {
                backgroundColor: 'rgba(255,255,255,0.5)',
                verticalAlign: 'top',
            },
            labels: [],
            events: {
                click: function (e) {
                    //console.log("Annotation clicked:", this.labels[0].annotation.userOptions.labels[0].text);
                    this.chart.removeAnnotation(this)
                }
            }

        }];
    } else {
        var annotations = chartSettings.annotations
    }
    $(".applyannotation").on("click", function (event) {
        event.preventDefault();
        event.stopPropagation();
        var x = e.point.x;
        var y = e.point.y;
        var currentAnnotation = {};

        currentAnnotation.backgroundColor = 'rgba(255,255,255,1)';
        currentAnnotation.text = $("input.annotatation-text").val();
        currentAnnotation.point = { xAxis: 0, yAxis: 0 };
        currentAnnotation.point.x = x;
        currentAnnotation.point.y = y;
        annotations[0].labels.push(currentAnnotation);
        var chart = charts["chart" + currentChart];

        currentChartInfo.chartSettings.annotations = annotations
        ChartInfo.functions.updateChartDetails(currentChart, JSON.stringify(currentChartInfo));

        chart.removeAnnotation(currentChart);
        chart.addAnnotation(annotations[0], true);

        $(".annotatation-text-box").remove();
    });
}

export function checkSmartInsightsEnable(allfeatures, position, isSharedReport, iscustomstoryboard) {
    var isSmartInsightEnable = false;
    var href = window.location.pathname;
    if (href.toLowerCase().indexOf("embeddedreport") < 0 && href.toLowerCase().indexOf("alertreport") < 0) {
        if (isSharedReport == false) {
            var that = this;
            $.each(allfeatures, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                    $.each(featuresModule.features, function (index, feature) {
                        if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_smart_insights' && feature.allow == true) {
                            isSmartInsightEnable = true;
                        }
                    })
                } else if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                    $.each(featuresModule.features, function (index, feature) {
                        if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_smart_insights' && feature.allow == true) {
                            isSmartInsightEnable = true;
                        }
                    })
                }
            });
        } else {
            var that = this;
            $.each(allfeatures, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
                            $.each(featuresModule.features, function (index, feature) {
                                if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_smart_insights' && feature.allow == true) {
                                    isSmartInsightEnable = true;
                                }
                            })
                        }
                    })
                } else if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_maximize_save_shared_report' && feature.allow == true) {
                            $.each(featuresModule.features, function (index, feature) {
                                if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_smart_insights' && feature.allow == true) {
                                    isSmartInsightEnable = true;
                                }
                            })
                        }
                    })
                }
            });
        }
    }
    if ((href.toLowerCase().indexOf("embeddedreport") > 0 || href.toLowerCase().indexOf("alertreport") > 0) && iscustomstoryboard != true) {
        var that = this;
        $.each(allfeatures, function (index, featuresModule) {
            if (featuresModule.moduleName == 'story_board' && (sessionStorage.getItem("pos") == 'min' || sessionStorage.getItem("pos") == "undefined")) {
                $.each(featuresModule.features, function (index, feature) {
                    if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_smart_insights' && feature.allow == true) {
                        isSmartInsightEnable = true;
                    }
                })
            } else if (featuresModule.moduleName == 'my_report' && sessionStorage.getItem("pos") == 'max') {
                $.each(featuresModule.features, function (index, feature) {
                    if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_maximize_smart_insights' && feature.allow == true) {
                        isSmartInsightEnable = true;
                    }
                })
            }
        });
    }
    if (href.toLowerCase().indexOf("schedulereport") >= 0) {
        isSmartInsightEnable = false;
    }

    if (isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true) {
        isSmartInsightEnable = false;
    }
    return isSmartInsightEnable;
}

const crypt = (salt, text) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
};

const decrypt = (salt, encoded) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
};

export function birdbtoa(key, data) {
   return crypt(key, data); 

}
export function birdatob(key, data) {
    return decrypt(key, data); 

}

export function auditMessage(reportId, msg) {
    setTimeout(function () { $(".spinner").hide(); }, 1000);
    let requestHeaders = {
        method: "post",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    };
    var formData = new FormData();
    formData.set("reportId", reportId);
    formData.set("msg", msg)
    SendRequest({
        url: "nlpchartdownload",
        body: formData,
        sucessFunction: (response) => {
            if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('hide');
                $('.birdmessage h2').empty().append('Error : ');
                $('.birdmessage h2').append('<span class="errorcode">');
                $('.birdmessage .errorcode').empty().append(response.errorCode);
                $('.birdmessage-info').empty().text(response.error);
                $('.details').addClass('show');
                $('.messagedetails xmp').empty().append(response.errorTrace);
                $(".spinner").hide();
                return;
            }
        },
        rejectFunction: (error) => { },
        headers: requestHeaders

    });
}
export function HighchartsThemeSet() {
    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [
            // Arrow stem
            'M', x + w * 0.5, y,
            'L', x + w * 0.5, y + h * 0.7,
            // Arrow head
            'M', x + w * 0.3, y + h * 0.5,
            'L', x + w * 0.5, y + h * 0.7,
            'L', x + w * 0.7, y + h * 0.5,
            // Box
            'M', x, y + h * 0.9,
            'L', x, y + h,
            'L', x + w, y + h,
            'L', x + w, y + h * 0.9
        ];
        return path;
    };
    Highcharts.theme = {
        //colors: ["#1f77b4", "#7798BF", "#55BF3B", "#DF5353", "#aaeeee", "#ff0066", "#eeaaee", "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],
        //colors: ['#F03B20', '#F35128', '#F45C2C', '#F56831', '#F77335', '#F87E39', '#FB9441', '#FC9F45', '#FDAB49', '#FEC161', '#FECC71', '#FEE290'],
        //colors: ["#5bd3e4"],
        //colors: ["#56fea1"],
        //colors: ["#5bd3e4", "#ff8651", "#57ef63", "#e257ef", "#7572f6", "#a6ef57", "#fe5662", "#56fea1", "#fed456"],

        lang: {
            drillUpText: '\u2190 Back',
            contextButtonTitle: 'Download',
            decimalPoint: '.',
            thousandsSep: ',',
            BacktoMap_key: 'Back to Map'
        },
        chart: {
            // backgroundColor: {
            // linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            // stops: [
            // [0, 'rgb(96, 96, 96)'],
            // [1, 'rgb(16, 16, 16)']
            // ]
            // },
            title: { text: "" },
            borderWidth: 0,
            borderRadius: 0,
            plotBackgroundColor: null,
            plotShadow: false,
            plotBorderWidth: 0,
            spacing: [20, 20, 20, 5],
            resetZoomButton: {
                theme: {
                    class: "highcharts-reset-zoom-button",
                    'stroke-width': 1,
                    stroke: '#ccc',
                    r: 0,
                    fill: '#fff',
                    cursor: 'pointer',
                    states: {
                        hover: {
                            fill: '#e2e2e2',
                            stroke: '#e2e2e2'
                        },
                        select: {
                            fill: '#e2e2e2',
                            stroke: '#e2e2e2'
                        }
                    }
                },
                position: {
                    y: 30,
                    x: 0
                },
            },
            animation: false
        },

        title: {
            enabled: false,
            widthAdjust: "",
            style: {
                color: '#333',
                fontWeight: '300',
                fontSize: '14px',
                fontFamily: '' + whitelableMap["fontfamily"] + ''
            }
        },
        subtitle: {
            style: {
                color: '#666',
                fontWeight: 'normal',
                fontSize: '12px',
                fontFamily: '' + whitelableMap["fontfamily"] + ''
            }
        },
        xAxis: {
            gridLineWidth: 0,
            lineColor: '#ccc',
            tickColor: '#ccc',
            tickWidth: '0',
            labels: {
                style: {
                    color: '#333',
                    fontSize: '10px',
                    fontWeight: '400'
                }
            },
            title: {
                useHTML: true,
                style: {
                    color: '#666',
                    font: '300 12px ' + whitelableMap["fontfamily"],
                    backgroundColor: 'transparent',
                    borderRadius: '0px',
                    padding: '2px 5px 4px 5px',
                    cursor: 'pointer'
                }
            }
        },
        drilldown: {
            activeAxisLabelStyle: {
                color: '#666',
                fontSize: '10px',
                fontWeight: '400',
                cursor: 'pointer',
                textDecoration: 'none'
            },
            activeDataLabelStyle: {
                cursor: 'pointer',
                textDecoration: 'none',
                color: '#333',
                fontWeight: '300'
            },
            drillUpButton: {
                relativeTo: 'spacingBox',
                position: {
                    y: 2,
                    x: -30,
                },
                theme: {
                    width: 40,
                    height: 6,
                    class: "highcharts-drillup-button",
                    'stroke-width': 1,
                    stroke: '#e8eaf1',
                    r: 0,
                    fill: '#fff',
                    cursor: 'pointer',
                    states: {
                        hover: {
                            fill: '#f9f9fd',
                            stroke: '#e8eaf1'
                        },
                        select: {
                            fill: '#f9f9fd',
                            stroke: '#e8eaf1'
                        }
                    }
                },
                useHTML: true
            }
        },
        yAxis: {
            alternateGridColor: null,
            minorTickInterval: null,
            gridLineWidth: 1,
            gridLineColor: 'rgba(238,238,238,1)',
            gridLineDashStyle: 'ShortDash',
            minorGridLineColor: 'rgba(238,238,238,1)',
            minorGridLineWidth: 0,
            lineWidth: 1,
            lineColor: '#ccc',
            tickWidth: '0',
            tickColor: '#ccc',
            labels: {
                style: {
                    color: '#333',
                    fontSize: '10px',
                    fontWeight: '400'
                }
            },
            title: {
                useHTML: true,
                style: {
                    color: '#666',
                    font: '300 12px ' + whitelableMap["fontfamily"],
                    //backgroundColor: '#939393',
                    //borderRadius: '10px',
                    padding: '2px 5px 4px 5px',
                    cursor: 'pointer'
                },
                //margin: 30
            }
        },
        colors: ["#57c17b", "#006e8a", "#6f87d8", "#663db8", "#bc52bc", "#9e3533", "#daa05d", "#bfaf40", "#4050bf", "#bf5040", "#40afbf", "#70bf40", "#8f40bf", "#bf40a7", "#40bf58", "#bf9740", "#4068bf", "#bf4048", "#40bfb7", "#87bf40", "#7840bf", "#bf4078", "#40bf87", "#b7bf40"],

        legend: {
            align: 'right',
            itemStyle: {
                color: '#666',
                fontFamily: whitelableMap["fontfamily"],
                fontWeight: '300',
                fontSize: '12px'
            },
            itemHoverStyle: {
                color: '#000'
            },
            itemHiddenStyle: {
                color: '#eee'
            }
        },
        labels: {
            style: {
                color: '#CCC'
            }
        },
        tooltip: {
            borderRadius: '0',
            followPointer: false,
            shadow: false,
            /*backgroundColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                    [0, 'rgba(96, 96, 96, 1)'],
                    [1, 'rgba(16, 16, 16, 1)']
                ]
            },*/
            backgroundColor: '#f5f5f5',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#bbb',
            style: {
                color: '#191919'
            },
            padding: 0
        },
        plotOptions: {
            series: {
                animation: false,
                nullColor: '#F5F5F5',
                borderWidth: 0,
                marker: {
                    states: {
                        select: {
                            fillColor: '#cccccc',
                            lineWidth: 2,
                        }
                    }
                }
            },
            line: {
                lineWidth: 1,
                states: {
                    hover: {
                        enabled: true,
                        lineWidth: 1
                    }
                },
                dataLabels: {
                    color: '#CCC'
                },
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    radius: 2,
                    lineColor: '#333',
                    states: {
                        enabled: true,
                        hover: {
                            radius: 5
                        },
                        select: {
                            radius: 5
                        }
                    },
                }
            },
            spline: {
                lineWidth: 1,
                states: {
                    hover: {
                        enabled: true,
                        lineWidth: 1
                    }
                },
                dataLabels: {
                    color: '#CCC'
                },
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    radius: 2,
                    lineColor: '#333',
                    states: {
                        enabled: true,
                        hover: {
                            radius: 5
                        },
                        select: {
                            radius: 5
                        }
                    },
                }
            },
            scatter: {
                marker: {
                    lineColor: '#333'
                }
            },
            candlestick: {
                lineColor: 'white'
            }
        },

        toolbar: {
            itemStyle: {
                color: '#CCC'
            }
        },

        navigation: {
            buttonOptions: {
                //symbolStroke: '#FFF',
                symbolStroke: '#000',
                //hoverSymbolStroke: '#000',
                theme: {
                    //fill: {
                    //	linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    //stops: [
                    //[0.5, '#606060'],
                    //[0.6, '#333333']
                    //	]
                    //},
                    //stroke: '#000'
                    //fill: '#595959'
                    fill: '#000'
                }
            },

            menuStyle: {
                background: '#fff',
                color: '#303030',
                borderWidth: '1px',
                borderColor: '#2b333c',
                borderStyle: 'solid',
                padding: '0'
            },
            menuItemStyle: {
                padding: '3px 20px',
                color: '#333',
                fontFamily: whitelableMap["fontfamily"],
                fontWeight: '400'
            },
            menuItemHoverStyle: {
                background: '#2b333c',
                color: '#FFF'
            }
        },

        // scroll charts
        rangeSelector: {
            dropdown: 'always',
            buttonTheme: {
                fill: '#f7f7f7',
                r: 0,
                style: {
                    color: '#666',
                    fontWeight: '300',
                    fontFamily: whitelableMap["fontfamily"]
                },
                states: {
                    select: {
                        fill: '#e4e4e4',
                        style: {
                            color: '#333',
                            fontWeight: '300',
                        }
                    }
                }
            },
            inputStyle: {
                //backgroundColor: '#fff',
                color: '#666'
            },
            labelStyle: {
                color: '#999'
            },
            inputBoxBorderColor: '#f7f7f7'
        },
        navigator: {
            handles: {
                backgroundColor: '#999',
                borderColor: '#ffffff'
            },
            outlineColor: '#eee',
            maskFill: 'rgba(51, 51, 51, 0.1)',
            series: {
                color: '#7798BF',
                lineColor: '#2b333c',
            },
            xAxis: {
                labels: {
                    style: {
                        color: '#666',
                        fontFamily: whitelableMap["fontfamily"],
                        fontWeight: '300',
                        fontSize: '10px'
                    }
                }
            },
            yAxis: {
                lineColor: '#fff'
            }
        },

        scrollbar: {
            barBackgroundColor: '#ddd',
            //barBackgroundColor: {
            //		linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            //		stops: [
            //			[0.4, '#888'],
            //			[0.6, '#555']
            //		]
            //	},
            barBorderColor: '#ddd',
            buttonArrowColor: '#fff',
            buttonBackgroundColor: '#ddd',
            //buttonBackgroundColor: {
            //		linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            //		stops: [
            //			[0.4, '#888'],
            //			[0.6, '#555']
            //		]
            //	},
            buttonBorderColor: '#ddd',
            rifleColor: '#fff',
            trackBackgroundColor: /*'#2b333c',*/'#fbfbfb',
            //trackBackgroundColor: {
            //	linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            //	stops: [
            //		[0, '#000'],
            //		[1, '#333']
            //	]
            //},
            trackBorderColor: '#f1f1f1'
        },
        exporting: {
            enabled: false,
            buttons: {
                contextButton: {
                    y: 2,
                    symbol: 'download',
                    symbolFill: "transparent",
                    symbolStrokeWidth: 0,
                    theme: {
                        fill: '#ffffff',
                        padding: 5,
                        stroke: 'none',
                    },
                    // symbolSize:'22',
                    // symbolX: 22,
                    // symbolY: 22,
                    // theme: {
                    //     'stroke-width': 1,
                    //     stroke: '#e8eaf1',
                    //     r: 0,
                    //     fill: '#fff',
                    //     cursor: 'pointer',
                    //     states: {
                    //         hover: {
                    //             fill: '#f9f9fd',
                    //             stroke: '#e8eaf1'
                    //         },
                    //         select: {
                    //             fill: '#f9f9fd ',
                    //             stroke: '#e8eaf1'
                    //         }
                    //     }
                    // }
                }
            }
        },

        // special colors for some of the demo examples
        legendBackgroundColor: 'rgba(48, 48, 48, 0.8)',
        background2: 'rgb(70, 70, 70)',
        dataLabelsColor: '#444',
        textColor: '#E0E0E0',
        maskColor: 'rgba(255,255,255,0.3)'
    };

    // Apply the theme
    var highchartsOptions = Highcharts.setOptions(Highcharts.theme);
}
HighchartsThemeSet();