import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
import WorkspaceAccordian from "../Workspace/WorkspaceAccordian";
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import _ from "underscore";
const $=window.$;
export const EditFilterModel = (props)=>{
    const {filterfields,connId,entityName,isCustomQuery,applyworkspacefilter,fetchData,entityId,editdeleteaccordian,collapseOther} = props;
    
    const WorkSpaceFilters = ()=>{
        var that = this;
        var filtersArray=document.getElementsByClassName("reportfiltercheckbox");
        var count=0;
        $(".reportfiltercheckbox").each(function (element,index) {
            var $this = this;
            var type = $($this).attr("data-type");
            var tableName = $($this).attr("data-parent");
            var columnName = $($this).attr("data-child");
            var columnDisplayName = $($this).attr("data-name");
            if ($($this).is(":checked")) {
                if($('#filteraccordion' + " #accordion"+count +" div").length === 0){
                    $('#filteraccordion #accordion'+count).remove()
                                }
                if($('#filteraccordion' + " #accordion"+count).length === 0){
                $('#filteraccordion').append("<div data-accordion-id="+'accordion'+count+"' id="+'accordion'+count+"></div>");
                ReactDOM.render(
                    <WorkspaceAccordian
                        index={count}
                        isCustomQuery={isCustomQuery}
						tableName={tableName}
						columnName={columnName}
						columnDisplayName={columnDisplayName}
						columnType={type}
						entityId={entityId}
						entityName={entityName}
						fromrange={null}
						torange={null}
						filterArray={undefined}
						id={columnDisplayName}
						filterObject={''}
						script={null}
						connId={connId}
						daterange={undefined}
						customFields={"[]"}
						entitycollection ={ []}
                        applyeditworkspacefilter={applyworkspacefilter}
                        editdeleteaccordian={editdeleteaccordian}
                        collapseOther={collapseOther}
                    />,
                    document.getElementById(`accordion${count}`)
                );
                }
            }
            else {
                tableName = $($this).attr("data-parent");
                columnDisplayName = $($this).attr("data-name");
                $("#workspaceitemsettingsfiltercontainer .acc-content[data-id='" + columnDisplayName + "']").remove();
            }
            count++;
        });
        $(".applyworkspacefilters").removeClass("disabled");
        // $("#workspaceitemsfiltermodal").modal("hide");
        $('#filtermodal').remove();
        $('.modal-backdrop').remove();
    }
    const search = (e)=>{
        var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $("#workspaceitemsfiltermodal input:radio[class='reportfiltercheckbox']").parents(".boc-list-item").each(function () {
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });
    }
    const closeModal =()=>{
        $('#workspacefiltermodal').remove();
        $('.modal-backdrop').remove();
    }
    return(
        <div className="modal fade" id="workspaceitemsfiltermodal" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-dialog-center" style={{zIndex:"1050"}}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="close" data-dismiss="modal" aria-hidden="true" onClick={closeModal}>&times;</button>
                            <h4 className="modal-title"><i className="fa fa-filter"></i> {lang["workspace.filter"]}</h4>
                        </div>
                        <div className="modal-body scrollsetnew">
                            <div className="container-fluid marg-top-5 nopadding">
                                <input className="form-control search-image" placeholder={lang["workspace.search"]} id="searchinputfilter" type="text" onChange={(e)=>{search(e)}} />
                            </div>
                            <div className="container-fluid">
                                <div className="row filtercontent dobyfilteritems">
                                    <ul className="list-group scrollsetul">
                                        {
                                            (filterfields!==undefined)? (
                                                filterfields.map((valueObject, index) => {
                                                    return (
                                                        <>
                                                            <li className="boc-list-item" key={index}>
                                                                <label title={valueObject.columnDisplayName}>
                                                                    {/* <input type="checkbox" className="reportfiltercheckbox" id="reportfiltercheckboxs" data-type={valueObject.tableDisplayType} data-parent={valueObject.tablename} data-child={valueObject.orginalColumnName} name={valueObject.columnDisplayName.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>`|\/@])/g, "")}/>
                                                                     */}
                                                                    <input type="radio" className="reportfiltercheckbox" id="reportfiltercheckboxs" data-type={valueObject.tableDisplayType} data-parent={valueObject.tablename} data-child={valueObject.orginalColumnName} name={valueObject.tablename} data-name={valueObject.columnDisplayName.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>`|\/@])/g, "")}/>{
                                                                        (valueObject.tableDisplayType === 'date' || valueObject.tableDisplayType === 'datetime') ? (<i className="fa fa-calendar fa-filter-icon"></i>) : null
                                                                    }
                                                                    {
                                                                        (valueObject.tableDisplayType === 'string') ? (<i className="fa fa-sort-alpha-asc fa-filter-icon"></i>) : null
                                                                    }
                                                                    {
                                                                        (valueObject.tableDisplayType === 'number') ? (<i className="fa fa-sort-numeric-asc fa-filter-icon"></i>) : null
                                                                    }
                                                                    {
                                                                        (valueObject.tableDisplayType === 'month') ? (<i className="fa fa-calendar-o fa-filter-icon"></i>) : null
                                                                    }
                                                                    {
                                                                        (valueObject.tableDisplayType == 'day') ? (<i className="fa fa-calendar-o fa-filter-icon"></i>) : null
                                                                    }
                                                                    {valueObject.columnDisplayName}
                                                                </label>
                                                            </li>
                                                        </>
                                                    );
                                                })
                                            ):null
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-bird" data-dismiss="modal" onClick={closeModal}><i className="fa fa-times"></i> {lang["workspace.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird workspacefiltersapply" id="workspacefiltersapply" data-parent={connId} name={entityName} onClick={WorkSpaceFilters}><i className="fa fa-check"></i> {lang["workspace.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
}