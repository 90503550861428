import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import moment from 'moment';
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import WorkspaceAccordianHtml from './WorkspaceAccordianHtml.jsx';
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
const $ = window.$;

export default class WorkspaceAccordian extends React.Component {
    constructor(props) {
        super(props);
        this.isCustomQuery = this.props.isCustomQuery;
        this.element = "#filteraccordion";
        this.state = {
            response: [],
            collection: [],
            filtercollection: [],
            res_length: {},
            pagination_offset: {},
            scrolled: false
        }
        this.script = this.props.script;
        this.filterArray = this.props.filterArray;
        this.tableName = this.props.tableName;
        this.columnDisplayName = this.props.columnDisplayName;
        this.columnName = this.props.columnName;
        this.columnType = this.props.columnType;
        this.connId = this.props.connId;
        this.entityId = this.props.entityId;
        this.fromrange = this.props.fromrange;
        this.torange = this.props.torange;
        this.filtergreaterthanarequalvalue = this.props.filtergreaterthanarequalvalue;
        this.filterlessthanarequalvalue = this.props.filterlessthanarequalvalue;
        this.displayName = this.columnDisplayName;
        this.customFields = this.props.customFields
        this.entitycollection = this.props.entitycollection
        this.entityName = this.props.entityName
    }
    componentDidMount() {
        var that = this;
        var filters;
        let is_From_Edit_Data = false;
        if (this.displayName != undefined) {
            this.displayName = this.displayName.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>`|\/@])/g, "");
        }
        /*if (this.isCustomQuery && that.entityName != undefined) {
            filters = that.entitycollection.getEntityFilters(that.entityName, "wscsq");
        } else {
            filters = that.entitycollection.getEntityFilters(that.entityName, that.connId);
        }*/
        if (window.location.pathname.indexOf("edit_data") >= 0 || window.location.pathname.indexOf("survey") >= 0 || window.location.pathname.indexOf("campaign") >= 0) {
            is_From_Edit_Data = true;
        }
        if (is_From_Edit_Data) {
            filters = ChartInfo.functions.getEditFilterArray();
            if (filters && filters.length) {
                let resultArray = [], splicedArray = [...filters], push = true;
                splicedArray.map((val, index) => {
                    if (val.columndisplayname == that.columnDisplayName) {
                        push = false;
                    }
                    if (push) {
                        resultArray.push(val);
                    }
                })
                filters = resultArray;
            }
        }
        if (filters) {
            filters = JSON.stringify(filters);
        }
        // if(this.columnType == "date" || this.columnType == "dateTime"){
        //     if($(this.element+" #acc"+this.displayName).length === 0){
        //         this.setState({
        //             collection:[]
        //         });
        //         this.render1(this.state.collection);
        //     }
        // }
        //  else{
        if ($(this.element + " #acc" + this.displayName).length === 0) {

            var data = new FormData();
            data.append("isCustomQuery", that.isCustomQuery);
            data.append("tableName", that.tableName);
            data.append("columnDisplayName", that.columnDisplayName);
            data.append("columnName", that.columnName);
            data.append("columnType", that.props.columnType);
            data.append("filters", (filters !== undefined) ? filters : "");
            var script = (that.script !== undefined) ? that.script : "";
            data.append("script", script);
            var entityId = (that.entityId !== undefined) ? that.entityId : "";
            data.append("enitiyId", entityId);
            data.append("customFields", that.customFields);
            var connId = (that.connId !== undefined) ? that.connId : "";
            data.append("connId", connId);
            if(that.props.columnType && that.props.columnType=="string"){
                data.append("limit", "50");
                data.append("offset", "0");
            }
            SendRequest({
                url: "getentityfilterdata",
                queryString: "",
                body: data,
                sucessFunction: (collection, response) => {
                    that.state.res_length[that.displayName] = collection.length;
                    that.state.pagination_offset[that.displayName] = 50;
                    if (window.localStorage.getItem("editDataFilters") != undefined && window.localStorage.getItem("editDataFilters") != null) {
                        var editDataFilters = JSON.parse(window.localStorage.getItem("editDataFilters"))[0];
                        var filterVals = [];
                        if (editDataFilters.columnname == that.columnName) {
                            var filterObj = { "value": editDataFilters.data };
                            filterVals.push(filterObj);
                            this.setState({
                                collection: filterVals
                            })
                        } else {
                            this.setState({
                                collection: collection
                            })
                        }
                    } else {
                        this.setState({
                            collection: collection
                        })
                    }
                    this.render1(this.state.collection);
                },
                rejectFunction: (error) => { console.log("rejected") },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        }
        //  }
    }
    showadvancedfilters = (e) => {
        var $this = e.currentTarget;
        $($this).parent().parent().find('.intfiltercontainer, .filtersearching').toggle('slow');
    }
    daterangepicker = (e) => {
        var $this = e.currentTarget;
        var className = (this.className == undefined) ? 'daterange-center' : this.className;
        $('.' + className).removeClass('activedatefield')
        $($this).addClass('activedatefield');
        $('.' + className + '.activedatefield').on('apply.daterangepicker', function (ev, picker) {
            $('.' + className + '.activedatefield:nth-child(1n) span').html(picker.startDate.format('MMMM D, YYYY') + ' - ' + picker.endDate.format('MMMM D, YYYY'));
            $('.' + className + '.activedatefield:nth-child(1n)').attr("date-range", picker.chosenLabel.toLowerCase());

        });
        $('.daterangepicker').addClass("daterangefixright");
    }
    searchfiltersdata = (e) => {
        var temp = e.currentTarget;
        var filterArray = [];

        let edit_filterArray = ChartInfo.functions.getEditFilterArray();
        let splicedArray = [];
        var filterOrder = [];
        let is_matched = false;
        $(".tab-filters-container #filteraccordion  [data-accordion-id]").each(function (index, a) {
            let name = $(a).find('.acc-content').attr("data-id");
            let column_name = temp.getAttribute("data-child").replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
            if (name == column_name)
                is_matched = true;
            if (!is_matched)
                filterOrder.push(name);
        });
        edit_filterArray.map(val => {
            if (filterOrder.indexOf(val.columndisplayname) != -1)
                splicedArray.push(val)
        });
        var $this = this;
        var filterObject = {};
        filterObject["dataType"] = temp.getAttribute('data-type');
        filterObject["tablename"] = temp.getAttribute("data-parent");
        filterObject["columnname"] = temp.getAttribute("data-child");
        var columnDataType = temp.getAttribute('data-type');
        var script = temp.getAttribute("script");
        var start = $("#" + (temp.getAttribute("data-name").replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + "start")).val();
        var end = $("#" + (temp.getAttribute("data-name").replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + "end")).val();
        var contain = $("#" + (temp.getAttribute("data-name").replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + "contains")).val();
        this.temp_columndisplayname = temp.getAttribute("data-child");

        filterObject["startwith"] = start;
        filterObject["endwith"] = end;
        filterObject["contains"] = contain;
        var that = this;
        if (this.props.script != null) {
            var customFields = sessionStorage.getItem("customFields");
            if (customFields != undefined && customFields != null) {
                var parsedCustomFields = JSON.parse(customFields);
                _.each(parsedCustomFields, function (customField) {
                    if (customField.name == that.temp_columndisplayname) {
                        filterObject["sqlcondition"] = customField.sqlformula;
                        return;
                    }
                })
            }
        }
        filterArray.push(filterObject);

        var postdata = new FormData();
        postdata.append("tableName", this.entityName);
        postdata.append("columnDisplayName", temp.getAttribute("data-name"));
        postdata.append("columnName", temp.getAttribute("data-child"));
        postdata.append("columnType", temp.getAttribute('data-type'));
        postdata.append("reportId", this.isCustomQuery ? 0 : this.entityId ? this.entityId : 0);
        postdata.append("filterObject", JSON.stringify(filterArray));
        postdata.append("script", that.customFields);
        postdata.append("entities", "");
        postdata.append("schemaId", "");
        postdata.append("sharedFilters", "");
        postdata.append("indexName", !this.isCustomQuery ? "" : this.tableName);
        postdata.append("esType", "");
        postdata.append("isImportedReport", false);
        postdata.append("dependentfilterObject", JSON.stringify(splicedArray));

        SendRequest({
            url: "getwssearchingfiltervalues",
            queryString: "",
            body: postdata,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $(".gridspinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                    /*$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">'+response.error+'</div>');
                        setTimeout(function() {
                            $('.birderror').remove();	
                        }, 3000);*/
                } else {
                    that.state.filtercollection = response;
                    that.render2(that.temp_columndisplayname, columnDataType);
                }
            },
            rejectFunction: (error) => { },
            headers: this.requestHeaders
        })
        //that.listenTo(this.filtercollection, 'reset');
    }
    requestHeaders = {
        method: "post",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    }
    addDateTimeFilter = (name, collectionResponse) => {
        var minDateinData = new Date(collectionResponse[0].min);
        var maxDateinData = new Date(collectionResponse[0].max);
        var last30days = moment(maxDateinData).subtract(29, 'days').toDate();
        if (minDateinData > last30days) {
            var fromDate = minDateinData;
        } else {
            var fromDate = last30days;
        }
        var currentDate = moment(new Date()).subtract(29, 'days').toDate();
        if (this.fromrange == undefined && this.torange == undefined &&
            minDateinData <= maxDateinData && maxDateinData < currentDate && this.props.daterange == undefined) {
            $('.daterange-center:nth-child(1n)[data-child="' + name + '"]').attr("date-range", "Custom")
        }
        name = name.replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
        $(this.element + ' #daterange' + name + ':nth-child(1n) span').html(moment(fromDate).format('MMMM D, YYYY') + ' - ' + moment(maxDateinData).format('MMMM D, YYYY'));
        var minDate = '01/01/1970';
        var maxDate = '12/31/2020';
        var tempminDate = new Date(collectionResponse[0].min);
        var tempmaxDate = new Date(collectionResponse[0].max);

        var tempminday = tempminDate.getDate();
        var tempminmonth = tempminDate.getMonth();
        var tempminyear = tempminDate.getFullYear();
        var finaltempminDate = tempminmonth + 1 + '/' + tempminday + '/' + tempminyear;

        var tempmaxday = tempmaxDate.getDate();
        var tempmaxmonth = tempmaxDate.getMonth();
        var tempmaxyear = tempmaxDate.getFullYear();
        var finaltempmaxDate = tempmaxmonth + 1 + '/' + tempmaxday + '/' + tempmaxyear;
        minDate = (collectionResponse[0] === undefined) ? minDate : finaltempminDate;
        maxDate = (collectionResponse[0] === undefined) ? maxDate : finaltempmaxDate;
        var that = this;
        if (this.sharedFilters != null && this.sharedFilters.length > 2) {
            _.each(JSON.parse(this.sharedFilters), function (value) {
                if (value.columnname == name) {
                    minDate = moment(that.fromrange).format("MM/DD/YYYY");
                    maxDate = moment(that.torange).format("MM/DD/YYYY");
                }
            })
        }

        $(this.element + ' #daterange' + name + ':nth-child(1n)').daterangepicker({
            format: 'MM/DD/YYYY',
            startDate: fromDate,
            endDate: maxDateinData,
            minDate: minDate,
            maxDate: maxDate,
            showDropdowns: true,
            showWeekNumbers: true,
            timePicker: false,
            timePickerIncrement: 1,
            autoUpdateInput: true,
            timePicker12Hour: true,
            linkedCalendars: false,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'This Year': [moment().startOf('year'), moment().endOf('year')],
                'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]

            },
            opens: 'left',
            drops: 'down',
            buttonClasses: ['btn'],
            applyClass: 'btn-bird',
            cancelClass: 'btn-bird',
            separator: ' to ',
            locale: {
                applyLabel: lang["workspace.submitbtn"],
                cancelLabel: lang["workspace.cancelbtn"],
                fromLabel: 'From',
                toLabel: 'To',
                customRangeLabel: 'Custom',
                daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                firstDay: 1,
            }
        }, function (start, end, label) {
            console.log(start.toISOString(), end.toISOString(), label);
            $(that.element + ' #daterange' + name + ':nth-child(1n) span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        });

        if (this.fromrange != undefined && this.torange != undefined) {
            var startdate = new Date();
            var enddate = new Date();
            if (this.props.daterange == "today") {
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            else if (this.props.daterange == "yesterday") {
                startdate.setDate(startdate.getDate() - 1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setDate(enddate.getDate() - 1);
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            else if (this.props.daterange == "last 7 days") {
                startdate.setDate(startdate.getDate() - 6);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setDate(enddate.getDate());
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            else if (this.props.daterange == "last 30 days") {
                startdate.setDate(startdate.getDate() - 29);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setDate(enddate.getDate());
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            else if (this.props.daterange == "this month") {
                startdate.setDate(1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setDate((new Date(enddate.getFullYear(), enddate.getMonth() + 1, 0)).getDate());
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            else if (this.props.daterange == "last month") {
                startdate.setMonth(startdate.getMonth() - 1);
                startdate.setDate(1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setMonth(enddate.getMonth() - 1);
                var date = new Date();
                var lastday = new Date(date.getFullYear(), date.getMonth(), 0);
                enddate.setDate(lastday.getDate());
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(58);
            }
            else if (this.props.daterange == "this week") {
                startdate = moment().startOf('week')._d;
                enddate = moment().endOf('week')._d;
            }
            else if (this.props.daterange == "last week") {
                startdate = moment().subtract(1, 'week').startOf('week')._d;
                enddate = moment().subtract(1, 'week').endOf('week')._d;
            }
            else if (this.props.daterange == "last year") {
                startdate.setFullYear(startdate.getFullYear() - 1);
                startdate.setMonth(0);
                startdate.setDate(1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setFullYear(enddate.getFullYear() - 1);
                enddate.setMonth(11);
                enddate.setDate(31);
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            else if (this.props.daterange == "this year") {
                startdate.setMonth(0);
                startdate.setDate(1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setMonth(11);
                enddate.setDate(31);
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            if (this.props.daterange == "custom") {
                var start = moment(fromDate.getTime());
                var end = moment(maxDateinData.getTime());
                $(this.element + ' #daterange' + name + ':nth-child(1n) span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
                $(this.element + ' #daterange' + name + ':nth-child(1n)').data('daterangepicker').setStartDate(moment(start.format("YYYY/MM/DD")));
                $(this.element + ' #daterange' + name + ':nth-child(1n)').data('daterangepicker').setEndDate(moment(end.format("YYYY/MM/DD")));
            } else {
                var start = moment(startdate.getTime());
                var end = moment(enddate.getTime());
                $(this.element + ' #daterange' + name + ':nth-child(1n) span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
                $(this.element + ' #daterange' + name + ':nth-child(1n)').data('daterangepicker').setStartDate(moment(start.format("YYYY/MM/DD")));
                $(this.element + ' #daterange' + name + ':nth-child(1n)').data('daterangepicker').setEndDate(moment(end.format("YYYY/MM/DD")));
            }
        }
    }
    selectall = (event) => {
        var temp = event.currentTarget;
        $('#uncheckall[name=' + temp.getAttribute('name').replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + ']').prop('checked', false);
        if ($('#checkall[name=' + temp.getAttribute('name').replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + ']').is(":checked")) {
            if (temp.name) $('input[data-name=' + temp.name.replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + ']').each(function () {
                this.checked = true;
            });
        }
    }
    unselectall = (event) => {
        var temp = event.currentTarget;
        $('#checkall[name=' + temp.getAttribute('name').replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + ']').prop('checked', false);
        if ($('#uncheckall[name=' + temp.getAttribute('name').replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + ']').is(":checked")) {
            if (temp.name) $('input[data-name=' + temp.name.replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + ']').each(function () {
                this.checked = false;
            });
        }
    }
    filterchecked = (event) => {
        var temp = event.currentTarget;
        $('#checkall[name=' + temp.getAttribute('data-name').replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + ']').removeAttr('checked');
        $('#uncheckall[name=' + temp.getAttribute('data-name').replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + ']').removeAttr('checked');
    }
    deleteaccordian = (event) => {
        var that = this;
        var temp = event.currentTarget;
        var that = this;
        var filterDataObj = that.entitycollection.getEntityFilters(that.entityName, that.connId);
        var values = [];
        if (filterDataObj != undefined) {
            var oldName = temp.name;
            _.each(filterDataObj, function (obj, i) {
                if (obj.columndisplayname != temp.name) {
                    values.push(filterDataObj[i]);
                }
            })
        }
        if (values != undefined && values.length > 0) {
            that.entitycollection.updateEntityFilters(values, that.entityName, that.connId);
        } else {
            that.entitycollection.updateEntityFilters(undefined, that.entityName, that.connId);
        }
        $(temp).parent().parent().parent().parent().remove();
    }
    render() {
        return (<></>);
    }
    render1 = (response) => {
        var that = this;
        let is_From_Edit_Data = false;
        if (window.location.pathname.indexOf("edit_data") >= 0 || window.location.pathname.indexOf("survey") >= 0 || window.location.pathname.indexOf("campaign") >= 0) {
            is_From_Edit_Data = true;
        }
        if (response !== undefined) {
            if (response.hasOwnProperty("error")) {
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('hide');
                $('.birdmessage h2').empty().append('Error : ');
                $('.birdmessage h2').append('<span class="errorcode">');
                $('.birdmessage .errorcode').empty().append(response.errorCode);
                $('.birdmessage-info').empty().text(lang["workspace.unablefetch"]);
                $('.details').addClass('show');
                $('.messagedetails xmp').empty().append(response.errorTrace);
                $('.spinner').hide();
            }
        }
        var filterminvalue = (response === undefined || response[0] === undefined) ? -1 : response[0].min;
        var filtermaxvalue = (response === undefined || response[0] === undefined) ? -1 : response[0].max;
        try {
            if ($(this.element + " #acc" + this.displayName).length === 0) {
                $('.spinner').show();
                if (!(this.columnType == 'all' || this.columnType == 'list' || this.columnType == 'range')) {
                    if (this.columnType != "date" && this.columnType != "dateTime" && this.columnType != "datetime") {
                        ReactDOM.render(
                            <WorkspaceAccordianHtml
                                tableName={this.tableName}
                                columnName={this.columnName}
                                columnDisplayName={this.columnDisplayName}
                                columnType={this.columnType}
                                filterValues={response}
                                filterArray={this.filterArray}
                                columnDisplayNames={this.displayName}
                                filterminvalue={filterminvalue}
                                filtermaxvalue={filtermaxvalue}
                                script={this.script}
                                className={this.className}
                                deleteaccordian={this.deleteaccordian}
                                daterangepicker={this.daterangepicker}
                                showadvancedfilters={this.showadvancedfilters}
                                searchfiltersdata={this.searchfiltersdata}
                                filterchecked={this.filterchecked}
                                selectall={this.selectall}
                                unselectall={this.unselectall}
                                applyeditworkspacefilter={this.props.applyeditworkspacefilter}
                                editdeleteaccordian={this.props.editdeleteaccordian}
                                collapseOther={this.props.collapseOther}
                                accordionid={`accordion` + this.props.index}
                            />,
                            document.getElementById(`accordion${this.props.index}`)
                        );
                        if (this.filterArray != undefined) {
                            _.each(this.filterArray, function (val, index) {
                                if (val.dataType === "string") {
                                    var filterData = val.data.split("@@");
                                    _.each(filterData, function (stringFilter, index2) {
                                        $(".filtervalue[data-value='" + stringFilter.replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + "']").prop("checked", true)
                                    });
                                }
                            });
                        }
                        if ($(`#accordion${this.props.index}`).length > 0 && $(window).width() <= 986) {
                            $(`#accordion${this.props.index}`).get(0).scrollIntoView({ behavior: 'smooth' });
                        }
                        $('.spinner').hide();
                        if (this.columnType === "number") {
                            var steps = 0;
                            var count = 0;
                            _.each(response, function (data) {
                                if (count <= 1000) {
                                    if (data.value % 1 != 0) {
                                        steps = 0.5;
                                    }
                                    count++;
                                }
                            })
                            var temFilterMaxValue = filtermaxvalue;
                            $(this.element + " #acc" + this.displayName + " #numberrangeslider").ionRangeSlider({
                                type: "double",
                                grid: true,
                                from: this.fromrange,
                                to: this.torange,
                                min: filterminvalue,
                                max: temFilterMaxValue,
                                force_edges: true,
                                prettify_enabled: false,
                                onStart: function (data) {
                                    if (filterminvalue === filtermaxvalue && filterminvalue != -1 && filtermaxvalue != -1) {
                                        var tempdata = '';
                                        tempdata = sessionStorage.getItem('applicablefilters');
                                        var temp = (sessionStorage.applicablefilters == undefined) ? "" : sessionStorage.applicablefilters.split(',');
                                        if (temp.indexOf($(data.input).find('input').attr('data-name')) === -1 || temp.length == 0) {
                                            tempdata = tempdata + encodeURIComponent($(data.input).find('input').attr('data-name')) + ",";
                                            sessionStorage.setItem('applicablefilters', tempdata);
                                        }
                                    }
                                },
                                onChange: function (data) {
                                    var tempdata = '';
                                    tempdata = sessionStorage.getItem('applicablefilters');
                                    var temp = (sessionStorage.applicablefilters == undefined) ? "" : sessionStorage.applicablefilters.split(',');
                                    if (temp.indexOf($(data.input).find('input').attr('data-name')) === -1 || temp.length == 0) {
                                        tempdata = tempdata + encodeURIComponent($(data.input).find('input').attr('data-name')) + ",";
                                        sessionStorage.setItem('applicablefilters', tempdata);
                                    }
                                },
                                onFinish: function (data) {

                                },
                                onUpdate: function (data) {
                                }
                            });
                            if (filterminvalue > 10000000 || temFilterMaxValue > 10000000) {
                                $(this.element + " #acc" + this.displayName + " #numberrangeslider").hide();
                                $(this.element + " #acc" + this.displayName + " #numberrangeslider").parent().hide()
                                $(this.element + " #acc" + this.displayName + " #numberrangeslider").parent().parent().find(".intfiltercontainer, .filtersearching").toggle();
                                $($(this.element + " #acc" + this.displayName + " #numberrangeslider").parent().parent().find(".intfiltercontainer span")[0]).hide();
                            }

                            var that = this;
                            _.each(this.filterArray, function (i, j) {
                                if (that.filterArray[j].columnDisplayName == that.columnDisplayName || that.filterArray[j].columndisplayname == that.columnDisplayName) {
                                    if (that.filterArray[j].filtergreaterthanarequalvalue != undefined || that.filterArray[j].filterlessthanarequalvalue != undefined) {
                                        $(that.element + " #acc" + that.displayName + " .intfiltervalue .filtergreaterthanvalue").parent().parent().show('slow');
                                        $(that.element + " #acc" + that.displayName + " .intfiltervalue .filtergreaterthanvalue").attr("value", that.filterArray[j].filtergreaterthanarequalvalue);
                                        $(that.element + " #acc" + that.displayName + " .intfiltervalue .filterlessthanvalue").attr("value", that.filterArray[j].filterlessthanarequalvalue);

                                    }
                                }
                            });

                        }
                    }
                    else {
                        var daterange = (this.props.daterange == undefined) ? "last 30 days" : this.props.daterange;
                        ReactDOM.render(
                            <WorkspaceAccordianHtml
                                tableName={this.tableName}
                                columnName={this.columnName}
                                columnDisplayName={this.columnDisplayName}
                                columnType={this.columnType}
                                filterValues={response}
                                filterArray={this.filterArray}
                                columnDisplayNames={this.displayName}
                                script={this.script}
                                daterange={daterange}
                                className={this.className}
                                deleteaccordian={this.deleteaccordian}
                                daterangepicker={this.daterangepicker}
                                showadvancedfilters={this.showadvancedfilters}
                                searchfiltersdata={this.searchfiltersdata}
                                filterchecked={this.filterchecked}
                                addDateTimeFilter={this.addDateTimeFilter}
                                selectall={this.selectall}
                                unselectall={this.unselectall}
                                applyeditworkspacefilter={this.props.applyeditworkspacefilter}
                                editdeleteaccordian={this.props.editdeleteaccordian}
                                accordionid={`accordion` + this.props.index}
                            />,
                            document.getElementById(`accordion${this.props.index}`)
                        );
                        if ($(`#accordion${this.props.index}`).length > 0 && $(window).width() <= 986) {
                            $(`#accordion${this.props.index}`).get(0).scrollIntoView({ behavior: 'smooth' });
                        }
                        $('.spinner').hide();
                    }
                }
                else {
                    var customparameterfields = ChartInfo.functions.getCustomParameter();
                    var parameterlistvlaues = '';
                    var parameterrangevalues = '';
                    var parametername = this.columnName;
                    var parametercurrentvalue = '';
                    _.each(customparameterfields, function (fields) {
                        if (fields.parametername == parametername) {
                            parameterlistvlaues = fields.parameterlistvalues;
                            parameterrangevalues = fields.parameterrangevalues;
                            parametercurrentvalue = fields.parametercurrentvalue;
                        }
                    });
                    ReactDOM.render(
                        <WorkspaceAccordianHtml
                            tableName={this.tableName}
                            columnName={this.columnName}
                            columnDisplayName={this.columnDisplayName}
                            columnType={this.columnType}
                            value={this.props.daterange}
                            parameterlistvlaues={parameterlistvlaues}
                            parametercurrentvalue={parametercurrentvalue}
                            filterValues={response}
                            filterArray={this.filterArray}
                            columnDisplayNames={this.displayName}
                            filterminvalue={filterminvalue}
                            filtermaxvalue={filtermaxvalue}
                            script={this.script}
                            deleteaccordian={this.deleteaccordian}
                            daterangepicker={this.daterangepicker}
                            showadvancedfilters={this.showadvancedfilters}
                            searchfiltersdata={this.searchfiltersdata}
                            filterchecked={this.filterchecked}
                            selectall={this.selectall}
                            unselectall={this.unselectall}
                            applyeditworkspacefilter={this.props.applyeditworkspacefilter}
                            editdeleteaccordian={this.props.editdeleteaccordian}
                            accordionid={`accordion` + this.props.index}
                        />,
                        document.getElementById(`accordion${this.props.index}`)
                    );
                    if ($(`#accordion${this.props.index}`).length > 0 && $(window).width() <= 986) {
                        $(`#accordion${this.props.index}`).get(0).scrollIntoView({ behavior: 'smooth' });
                    }
                    $('.spinner').hide();
                    if (this.columnType === "range") {
                        var steps = 0;
                        var count = 0;
                        _.each(response, function (data) {
                            if (count <= 1000) {
                                if (data.value % 1 != 0) {
                                    steps = 0.5;
                                }
                                count++;
                            }
                        })
                        parametercurrentvalue = this.props.daterange
                        $(this.element + " #acc" + this.columnName + " #numberrangeslider").ionRangeSlider({
                            type: "single",
                            grid: true,
                            step: parameterrangevalues.rangestersize,
                            from: parametercurrentvalue,
                            min: parameterrangevalues.rangemin,
                            max: parameterrangevalues.rangemax,
                            force_edges: true,
                            prettify_enabled: false,
                            onStart: function (data) {
                            },
                            onChange: function (data) {
                                var tempdata = '';
                            },
                            onFinish: function (data) {

                            },
                            onUpdate: function (data) {
                            }
                        });
                        var that = this;

                    }
                }
            }
            if (this.props.elelement == undefined) {
                var fcount = $('#filteraccordion .acc-content').length;
                if (fcount > 0) {
                    $('.rightfiltersapply').removeClass('hide');
                    $('.filters-applied').remove();
                    $('.leftpane').append('<div class="filters-applied"><i class="fa fa-filter"></i>' + fcount + lang["workspace.filtersapplied"] + ' </div>');
                    $('.singlebadge').remove();
                    $('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                    $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                }
                else {
                    $('.filters-applied').remove();
                    $('.rightfiltersapply').addClass('hide');
                    $('.singlebadge').remove();
                }
            }
            if (this.reportId != "") {
                $('#' + this.displayName + 'rangevaluemin').text(this.fromrange);

                $('#' + this.displayName + 'rangevaluemax').text(this.torange);
                $('#slider').find('input').closest("#rangeinput").val(this.fromrange);
                $('#slider').find('input').closest("#rangeoutput").val(this.torange);
            }
            if (this.columnType == "date" || this.columnType == "dateTime" || this.columnType == "datetime") {

                this.addDateTimeFilter(this.columnDisplayName, response);
            }
            if (this.props.elelement == undefined) {
                // this.broker.trigger('updateFilters');
            } else {
                //this.broker.trigger('previousfiltersdata', this.columnName, this.props.filterid);
            }
            if (this.filterArray && is_From_Edit_Data) {
                this.filterArray.map(filter => {
                    if (filter.columndisplayname) {
                        var filtercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
                        if (filter["data"] && filter.dataType && filter.dataType === "string") {
                            let filtereddatatext = filter["data"].replace(/\@@/g, ', ');
                            var unCheckedFilterItems = $(".filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)").length;
                            if (unCheckedFilterItems == 0) {
                                $('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("All").attr("title", "All");
                            } else {
                                $('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
                            }
                        } else if (filter.dataType && filter.dataType === "date") {
                            var dates = $('#filteraccordion .daterange-center[displayname="' + filter.columndisplayname + '"] span').text();
                            $('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(dates).attr("title", dates);
                        } else if (filter.dataType && filter.dataType === "number") {
                            var fromRange = filter.fromRange, toRange = filter.toRange
                            if (fromRange == undefined && toRange == undefined) {
                                fromRange = filter.filtergreaterthanarequalvalue;
                                toRange = filter.filterlessthanarequalvalue;
                            }
                            $('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(fromRange + " - " + toRange).attr("title", fromRange + " - " + toRange);
                        }
                        $(`#accordion${this.props.index}`).addClass('is-existing');
                    }
                });
            }
            $(".list-group.checkhelpitems").scroll(e => {
                e.stopImmediatePropagation();
                e.stopPropagation();
                let map_obj = {}
                map_obj["element"] = that.element
                map_obj["displayName"] = that.displayName
                map_obj["isCustomQuery"] = that.isCustomQuery
                map_obj["tableName"] = that.tableName
                map_obj["columnDisplayName"] = that.columnDisplayName
                map_obj["columnName"] = that.columnName
                map_obj["columnType"] = that.columnType
                map_obj["script"] = that.script
                that.appendDataWhenScrollReachesLost(e, map_obj);
            })
        } catch (e) {
            // TODO: handle exception
        }
        return (<></>)
    }
    render2 = (DisplayName, columnDataType) => {
        var that = this;
        var filterArray = [];
        if (columnDataType != undefined) {
            this.columnType = columnDataType;
        }
        var className = (this.className == undefined) ? 'filtervalue' : "advanced-access-filtervalue";
        $('.' + className).each(function () {
            var $this = this;
            if ($($this).is(":checked")) {
                var filterObject = {};
                filterObject["dataType"] = $($this).attr("data-type");
                filterObject["tablename"] = $($this).attr("data-parent");
                filterObject["columndisplayname"] = $($this).attr("data-name");
                filterObject["columnname"] = $($this).attr("data-child");
                filterObject["data"] = $($this).parent().text();
                filterArray.push(filterObject);
            }
        });
        if (this.state.filtercollection.length == 0) {
            if (filterArray.length == 0) {
                $(that.element + ' #acc' + DisplayName.replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + ' ul.checkhelpitems').empty();
            }
            let has_match = false;
            _.each(filterArray, function (data, k) {
                $('.' + className + '[data-child="' + DisplayName + '"][data-parent="' + data.tablename + '"]:not(:checked)').parent().parent().remove();
                if ($('.' + className + '[data-child="' + DisplayName + '"][data-parent="' + data.tablename + '"]:not(:checked)').parent().parent().length > 0) {
                    has_match = true;
                }
            });
            if (!has_match && filterArray.length > 0) {
                let matched_array = filterArray.filter(val => val.columndisplayname.replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") == DisplayName.replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, ""));
                if (matched_array.length == 0) {
                    $(that.element + ' #acc' + DisplayName.replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + ' ul.checkhelpitems').empty();
                }
            }
        }
        _.each(this.state.filtercollection, function (i, j) {
            var tableName = $('.' + className + '[data-child="' + i.columnName + '"]').attr('data-parent');
            if (j == 0) {
                $('.' + className + '[data-child="' + i.columnName + '"][data-parent="' + tableName + '"]:not(:checked)').parent().parent().remove();
            }
            if ($('.' + className + '[data-name="' + i.columnName + '"][data-parent="' + tableName + '"][data-value="' + (i.value + '').replace(/([;?&,%#+*~\':"!^$[\]()<=>`|\/@])/g, "") + '"]').is(":checked") == false)
                $(that.element + ' #acc' + i.columnDisplayName.replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + ' ul.checkhelpitems').append("<li class='boc-list-item nopadding-xs	valueoffilters'><label><input type='checkbox' class='" + className + "' title='" + i.value + "' data-col='" + i.columnName + "' displayname='" + i.columnDisplayName.replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + "' data-type='" + that.columnType + "' data-parent='" + tableName + "' data-child='" + i.columnName + "' data-value='" + (i.value + '').replace(/([;?&,%#+*~\':"!^$[\]()<=>`|\/@])/g, "") + "' data-name='" + i.columnName.replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + "'>" + i.value + "</label></li>");
        });
        if (this.columnType == "date" || this.columnType == "dateTime") {
            this.addDateTimeFilter(this.columnDisplayName);
        }
        //this.broker.trigger('updateFilters');
    }
    appendDataWhenScrollReachesLost = (e, map_obj) => {
        e.stopImmediatePropagation();
        // Get the ul element.
        let that = this;
        let $this = e.currentTarget;
        var ul = $this;

        // Get the scroll position of the ul element.
        var scrollPosition = ul.scrollTop;

        // Get the height of the ul element.
        var ulHeight = ul.clientHeight;

        // If the scroll position is at the bottom of the ul element, append the li data.


        if ((scrollPosition + ulHeight + 100 >= ul.scrollHeight) && !that.state.scrolled) {
            that.state.scrolled = true;
            // Append the li data to the ul element.
            let filters;
            let is_From_Edit_Data = false;
            if (window.location.pathname.indexOf("edit_data") >= 0 || window.location.pathname.indexOf("survey") >= 0 || window.location.pathname.indexOf("campaign") >= 0) {
                is_From_Edit_Data = true;
            }
            let { element, displayName, isCustomQuery, tableName, columnDisplayName, columnName, columnType } = map_obj
            if (is_From_Edit_Data) {
                filters = ChartInfo.functions.getEditFilterArray();
                if (filters && filters.length) {
                    let resultArray = [], splicedArray = [...filters], push = true;
                    splicedArray.map((val, index) => {
                        if (val.columndisplayname == that.columnDisplayName) {
                            push = false;
                        }
                        if (push) {
                            resultArray.push(val);
                        }
                    })
                    filters = resultArray;
                }
            }
            if (filters) {
                filters = JSON.stringify(filters);
            }
            if ($(element + " #acc" + displayName).length > 0) {
                let len = that.state.res_length[displayName]
                if (len < 50) return
                let offset = that.state.pagination_offset[that.displayName]
                let data = new FormData();
                data.append("isCustomQuery", isCustomQuery);
                data.append("tableName", tableName);
                data.append("columnDisplayName", columnDisplayName);
                data.append("columnName", columnName);
                data.append("columnType", columnType);
                data.append("filters", (filters !== undefined) ? filters : "");
                let script = (that.script !== undefined) ? that.script : "";
                data.append("script", script);
                let entityId = (that.entityId !== undefined) ? that.entityId : "";
                data.append("enitiyId", entityId);
                data.append("customFields", that.customFields);
                let connId = (that.connId !== undefined) ? that.connId : "";
                data.append("connId", connId);
                data.append("limit", "50");
                data.append("offset", offset + "");
                SendRequest({
                    url: "getentityfilterdata",
                    queryString: "",
                    body: data,
                    sucessFunction: (response) => {
                        if (response && response.hasOwnProperty("error")) {
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(lang["workspace.unablefetch"]);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.errorTrace);
                            $('.spinner').hide();
                            that.state.scrolled = false;
                            return
                        }
                        that.state.res_length[displayName] = response.length;
                        that.state.pagination_offset[that.displayName] = offset + 50;
                        let newLi = "";
                        response.map(val => {
                            let { value } = val;
                            newLi += `<li class="boc-list-item valueoffilters"><label title="${value}"><input type="checkbox" class="filtervalue" data-type="string" data-parent="${tableName}" data-child="${columnName}" data-name="${columnDisplayName}" displayname="${displayName}" data-value="${value}">${value}</label></li>`;

                        })
                        if (newLi) {
                            $(ul).append(newLi);
                        }

                        if (that.filterArray != undefined) {
                            _.each(that.filterArray, function (val, index) {
                                if (val.dataType === "string") {
                                    var filterData = val.data.split("@@");
                                    _.each(filterData, function (stringFilter, index2) {
                                        $(".filtervalue[data-value='" + stringFilter.replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + "']").prop("checked", true)
                                    });
                                }
                            });
                        }
                        that.state.scrolled = false;
                    },
                    rejectFunction: (error) => { console.log("rejected") },
                    headers: {
                        method: "post",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    }
                });
            }

        }
    }
}