import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class FtpConnectorHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            singlePageValue: 1,
            connectionName: props.schemaDisplayName
        }
    }
    closeconnectionpop = (e) => {
        $(".connection-center").removeClass("connection-in");
    }
    render() {
        return (
            <>
                {/* <!-- datasourceconnectionview.js --> */}
                <div className="modal-header boc-modal-hf-padding">
                    <h4 className="modal-title">
                        <i className="fa fa-link marg-righ-10"></i>
                        {lang["datahub.filestitle"]}
                    </h4>
                </div>
                <div className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12">
                                {/* <!-- <h3>Connect MySQL</h3> --> */}
                                <form className="form-horizontal marg-top-10">
                                    <div className="form-group form-group-sm">
                                        <label htmlFor="" className="col-sm-3 control-label">
                                            <img className="pull-right" src={require(`../images/birdimages/${this.props.schemaName.toLowerCase()}.png`)} />
                                        </label>
                                        <div className="col-sm-6">
                                            <h3>{this.props.schemaName} {lang["datahub.deatils"]}</h3>
                                        </div>
                                    </div>
                                    <div className="ftpurl">
                                        <div className="form-group form-group-sm required">
                                            <label htmlFor="" className="col-sm-3 control-label">{lang["datahub.cnctnname"]}</label>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    id="connectionName"
                                                    value={this.state.connectionName}
                                                    className="form-control connectionname"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        this.setState({
                                                            connectionName: e.target.value
                                                        })
                                                    }}
                                                />
                                                <span id="connectionname" className="help-block hide"></span>
                                                <span className="csvnameerror" style={{ display: "none", color: "red" }}>
                                                    {lang["datahub.namevalid"]}
                                                </span>
                                                <span class="csvnameerrors" style={{ display: "none", color: "red" }}>{lang["datahub.nameerror"]}</span>
                                            </div>
                                        </div>

                                        <div className="form-group form-group-sm disabled">
                                            <label htmlFor="" className="col-sm-3 control-label" style={{ fontSize: "15px" }}>{lang["datahub.srclctn"]}</label>
                                            <div className="col-sm-6">
                                                <label htmlFor="logftplocation" className="control-label marg-righ-10" style={{ fontSize: "15px", fontWeight: "400" }}>
                                                    <input type="radio" className="logfilemethod" id="filelocation"  name="filelocation" value="ftp" /> {lang["datahub.Ftp"]}
                                                </label>
                                                <label htmlFor="logsftplocation" className="control-label marg-righ-10" style={{ fontSize: "15px", fontWeight: "400" }}>
                                                    <input type="radio" className="logfilemethod" id="filelocation" defaultChecked name="filelocation" value="sftp" /> {lang["datahub.Sftp"]}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group form-group-sm required">
                                            <label htmlFor="" className="col-sm-3 control-label ftpurl">{lang["datahub.ftpurl"]}</label>
                                            <div className="col-sm-6">
                                                <input type="text" defaultValue="" className="form-control ftpfileurl" id="ftpfileurladds" placeholder="" />
                                                <span id="ftpurl" className="help-block hide ftpurlemptyerror fa-modal-alert text-center">{lang["datahub.ftpvalid"]}</span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-sm required">
                                            <label htmlFor="" className="col-sm-3 control-label">{lang["datahub.username"]}</label>
                                            <div className="col-sm-6">
                                                <input type="text" defaultValue="" className="form-control ftpusername" id="ftpusernameadds" placeholder="" />
                                                <span id="ftpusername" className="help-block hide ftpusernameemptyerror fa-modal-alert text-center">{lang["datahub.uservalid"]}</span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-sm required">
                                            <label htmlFor="" className="col-sm-3 control-label">{lang["datahub.pwd"]}</label>
                                            <div className="col-sm-6">
                                                <input type="password" defaultValue="" className="form-control ftppassword" id="ftppasswordadds" placeholder="" />
                                                <span id="ftppassword" className="help-block hide ftppasswordemptyerror fa-modal-alert text-center">{lang["datahub.pwdvalid"]}</span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-sm disabled">
                                            <label htmlFor="" className="col-sm-3 control-label">{lang["datahub.filetpye"]}</label>
                                            <div className="col-sm-6">
                                                <label htmlFor="ftpfiletype" className="control-label marg-righ-10" style={{ fontSize: "15px", fontWeight: "400" }}>
                                                    <input type="radio" className="ftpfiletype" id="ftpfiletype" name="ftpfiletype" value="EXCEL" onChange={this.props.logfilemethod} /> {lang["datahub.ftpexcel"]}
                                                </label>
                                                <label htmlFor="ftpfiletype" className="control-label marg-righ-10" style={{ fontSize: "15px", fontWeight: "400" }}>
                                                    <input type="radio" className="ftpfiletype" id="ftpfiletype" name="ftpfiletype" value="PDF" defaultChecked onChange={this.props.logfilemethod} /> {lang["datahub.ftppdf"]}
                                                </label>
                                                <label htmlFor="ftpfiletype" className="control-label marg-righ-10" style={{ fontSize: "15px", fontWeight: "400" }}>
                                                    <input type="radio" className="ftpfiletype" id="ftpfiletype" name="ftpfiletype" value="CSV" onChange={this.props.logfilemethod} /> {lang["datahub.ftpcsv"]}
                                                </label>
                                                <label htmlFor="ftpfiletype" className="control-label marg-righ-10" style={{ fontSize: "15px", fontWeight: "400" }}>
                                                    <input type="radio" className="ftpfiletype" id="ftpfiletype" name="ftpfiletype" value="JSON" onChange={this.props.logfilemethod} /> {lang["datahub.ftpjson"]}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-sm">
                                            <div className="col-sm-offset-3 col-sm-6">
                                                <button className="btn btn-bird pull-left ftptestconnection" id="ftptestconnectionclick" onClick={this.props.ftptestconnection}>
                                                    <i className="fa fa-play"></i> {lang["datahub.testcnctn"]}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="notifications-table-container hide"></div>
                                        </div>
                                    </div>
                                    <div className="col-xs-offset-3 col-xs-6 nopadding hide">
                                        {/* <!-- For PDF Connector --> */}
                                        <div className="pdfoptionscontainer hide" style={{ padding: "0 0 0 10px !important", margin: 0 }}>
                                            <h4 className="modal-title">{lang["datahub.scanpages"]}</h4>
                                            <p>{lang["datahub.spcfypages"]}</p>
                                            <form>
                                                <span className="appetitle">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="scanpages"
                                                            className="appetitle"
                                                            value="all"
                                                            //checked="checked"
                                                            defaultChecked
                                                        />
                                                        {lang["datahub.allpages"]}
                                                    </label>
                                                </span>
                                                <div className="clear"></div>
                                                <span className="appetitle">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="scanpages"
                                                            className="appetitle"
                                                            value="single"
                                                        />
                                                        {lang["datahub.snglepage"]}
                                                    </label>
                                                </span>
                                                <input type="number" value={this.state.singlePageValue} min="1" id="pagenumber" className="modal-form-fields" onChange={(e) => { this.setState({ singlePageValue: e.target.value }) }} />
                                                <div className="clear"></div>
                                                <span className="appetitle">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="scanpages"
                                                            className="appetitle"
                                                            value="range"
                                                        />{lang["datahub.range"]}
                                                    </label>
                                                </span>
                                                <div className="clear"></div>
                                                <div className="col-xs-12 marg-left-15">
                                                    <span className="appetitle">{lang["datahub.rangefm"]}</span>
                                                    <input type="number" id="fromrange" defaultValue="1" min="1" className="modal-form-fields" />
                                                    <span className="appetitle">{lang["datahub.rangeto"]}</span>
                                                    <input type="number" id="torange" defaultValue="2" min="1" className="modal-form-fields" />
                                                </div>
                                            </form>
                                        </div>
                                        {/* <!--\For PDF Connector --> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer marg-top-20">
                    <button type="button" className="btn btn-bird" id="closeconnectionpop" onClick={this.closeconnectionpop}>
                        <i className="fa fa-times"></i> {lang["datahub.canclbtn"]}
                    </button>
                    <button type="button" className="btn btn-bird active csvsavenewconnection" id="savenewconnection" onClick={this.props.saveconnection}>
                        <i className="fa fa-floppy-o"></i> {lang["datahub.savebtn"]}
                    </button>
                </div>
                {/* <!-- /datasourceconnectionview.js --> */}
            </>
        );
    }
}