import _ from 'lodash';
//import $ from 'jquery/dist/jquery';
import React from "react";
import ReactDOM from "react-dom";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import SendRequest from "../SendRequest";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import YaxisAttributesTemplate from "./yaxisattributes.jsx"
import ReferenceLineTemplate from "./referenceline.jsx"
import CompareDateRangeTemplate from "./compareDateRange.jsx"
import { lang } from "../js/helpers/utils/languages/language";
import { now } from 'moment';
const $ = window.$;
export default class YaxisAttributesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            yaxiscolumnDisplayName: props.yaxiscolumnDisplayName,
            yaxiscolumnName: props.yaxiscolumnName,
            yaxiscolumnType: props.yaxiscolumnType,
            aggregation: props.yaxisaggregation,
            chartType: props.chartType,
            yaxistableName: props.yaxistableName,
            percentileValue: props.percentileValue,
            referenceColumnName: props.referenceColumnName,
            referenceLinelabel: props.referenceLinelabel,
            referenceStyle: props.referenceStyle,
            referenceColor: props.referenceColor,
            viewmode: props.viewmode,
            sortType: props.sortType,
            iscustomfunction: props.iscustomfunction,
            //broker.on('changeaggregation',changeaggregation,this),
            //	template : _.template(YaxisAttributesTemplate),
            //	referenceLineTemplate : _.template(referenceLineTemplate),
            isDirect: ChartInfo.functions.getIsDirect(),
            isStoredProcedure: ChartInfo.functions.getIsStoredProcedure(),
            reportId: props.reportId,
            chartNumber: props.chartnumber,
            id: props.id,
            axisduplicate: props.axisduplicate,
            multifact: props.multifact,
            facttables: props.facttables,
            multifactentities: props.multifactentities,
        };
        this.render();
        this.changeyAxisAggregation = this.changeyAxisAggregation.bind(this);
        this.deleteelement = this.deleteelement.bind(this);
    }
    render() {
        this.state.el = "#" + this.state.dynamicdiv;
        if (this.state.aggregation != undefined) {
            this.state.aggregation = this.state.aggregation.trim();
        }
        if (this.state.chartNumber != undefined) {
            var ChartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.state.chartNumber));
            this.state.chartType = ChartDetails.chartType;
        }
        this.state.percentileValue = (this.state.percentileValue == undefined || this.state.percentileValue.length == 0) ? 25 : this.state.percentileValue;
        if (this.props.addreferencelinehtml != undefined && this.props.addreferencelinehtml == true) {
            ReactDOM.render(<ReferenceLineTemplate deletereferencelineelement={this.deletereferencelineelement} yaxiscolumnDisplayName={this.state.yaxiscolumnDisplayName} yaxiscolumnName={this.state.yaxiscolumnName}
                yaxiscolumnType={this.state.yaxiscolumnType} referencelinevalue={this.props.referencelinevalue} aggregation={this.props.referenceLineagg} yaxistableName={this.state.yaxistableName} percentileValue={this.state.percentileValue} chartType={this.state.chartType}
                isDirect={this.state.isDirect} isStoredProcedure={this.state.isStoredProcedure} id={this.props.id} chartnumber={this.props.chartnumber} referenceColumnName={this.state.referenceColumnName} referenceLinelabel={this.state.referenceLinelabel} referenceStyle={this.state.referenceStyle} referenceColor={this.state.referenceColor} chartNumber={this.state.chartNumber}
                iscustomfunction={this.state.iscustomfunction} features={this.props.features} is_storyboard={this.props.is_storyboard}
            ></ReferenceLineTemplate>, document.getElementById(this.state.dynamicdiv));
        } else {
            ReactDOM.render(<YaxisAttributesTemplate editcfname={this.editcfname} conditioncancel={this.conditioncancel} addconditionbtn={this.addconditionbtn} conditionalshow={this.conditionalshow} editname={this.editname} deleteelement={this.deleteelement} axisduplicate={this.state.axisduplicate} changeyAxisAggregation={this.changeyAxisAggregation} yaxiscolumnDisplayName={this.state.yaxiscolumnDisplayName} yaxiscolumnName={this.state.yaxiscolumnName}
                yaxiscolumnType={this.state.yaxiscolumnType} aggregation={this.state.aggregation} yaxistableName={this.state.yaxistableName} percentileValue={this.state.percentileValue} chartType={this.state.chartType} isDirect={this.state.isDirect} isStoredProcedure={this.state.isStoredProcedure} viewmode={this.state.viewmode} sortType={this.state.sortType} chartNumber={this.state.chartNumber} id={this.state.id}
                iscustomfunction={this.state.iscustomfunction} features={this.props.features} is_storyboard={this.props.is_storyboard} compareDateShow={this.compareDateShow}></YaxisAttributesTemplate>, document.getElementById(this.state.dynamicdiv));
            // this.$el.append(this.template({yaxiscolumnDisplayName:this.state.yaxiscolumnDisplayName,yaxiscolumnName:this.state.yaxiscolumnName,
            //     yaxiscolumnType:this.state.yaxiscolumnType, aggregation:this.state.aggregation,yaxistableName:this.state.yaxistableName,percentileValue:this.state.percentileValue,chartType:this.state.chartType,isDirect:this.state.isDirect,isStoredProcedure:this.state.isStoredProcedure,viewmode:this.state.viewmode,sortType:this.state.sortType,chartNumber:this.state.chartNumber,id:this.state.id,
            //     iscustomfunction:this.state.iscustomfunction,features:this.props.features,is_storyboard:this.props.is_storyboard}));
        }

        if (sessionStorage.getItem('currentChartNumberForSort') != "" && sessionStorage.getItem('currentChartNumberForSort') != null && ChartInfo.functions.getChartDetails(sessionStorage.getItem('currentChartNumberForSort')) != undefined) {
            var sortingAttributes = $.parseJSON(ChartInfo.functions.getChartDetails(sessionStorage.getItem('currentChartNumberForSort')));
            var colLimit = sortingAttributes.colLimit;
            var orderType = sortingAttributes.orderType;
            var sortType = sortingAttributes.sortType;
            var sortingColumn = sortingAttributes.sortingColumn;
            var xdatesortingEnabled = sortingAttributes.xdatesortingEnabled;

            if (sortType == 'desc') {
                $('input[id="sortby1"]').attr('checked', true);
                $("span.sortingorder").text("[ DSC ]");
            } else if (sortType == 'asc') {
                $('input[id="sortby2"]').attr('checked', true);
                $("span.sortingorder").text("[ ASC ]");
            }
            if (orderType == "top") {
                $('.ordertype option[value="top"]').attr('selected', 'selected');
            } else if (orderType == "bottom") {
                $('.ordertype option[value="bottom"]').attr('selected', 'selected');
            }else if(orderType == "None"){
                $('.collimit').attr("disabled",true);
                $('.rankingitem').attr("disabled",true);
                // $("#dropdowntooltip").addClass('disabled');
			    // $("#dropdownlegend").addClass('disabled');	
			    // $("#dropdownsorting").addClass('disabled');
            }
            $(".ordertype").trigger("change");
            if (colLimit != null && colLimit != "") {
                $('.collimit').val(colLimit);
            }
            if(xdatesortingEnabled!=undefined && xdatesortingEnabled!=false){
                $('#xdatesortingid').attr('checked',true)
            }

        }




        //Active drop down aggregation item
        if (this.state.aggregation === "sum" || this.state.aggregation === "Sum") {
            $(this.state.el).find(".aggsum").addClass("active");
        } else if (this.state.aggregation === "min" || this.state.aggregation === "Min") {
            $(this.state.el).find(".aggmin").addClass("active");
        } else if (this.state.aggregation === "max" || this.state.aggregation === "Max") {
            $(this.state.el).find(".aggmax").addClass("active");
        } else if (this.state.aggregation === "avg" || this.state.aggregation === "Avg") {
            $(this.state.el).find(".aggavg").addClass("active");
        } else if (this.state.aggregation === "variance" || this.state.aggregation === "Variance") {
            $(this.state.el).find(".aggvariance").addClass("active");
        } else if (this.state.aggregation === "standard deviation" || this.state.aggregation === "Standard Deviation") {
            $(this.state.el).find(".aggstandarddeviation").addClass("active");
        } else if (this.state.aggregation === "sum of squares" || this.state.aggregation === "Sum of Squares") {
            $(this.state.el).find(".aggsumofsquares").addClass("active");
        } else if (this.state.aggregation === "percentile" || this.state.aggregation === "Percentile") {
            $(this.state.el).find(".aggpercentile").addClass("active");
        } else if (this.state.aggregation === "median" || this.state.aggregation === "Median") {
            $(this.state.el).find(".aggmedian").addClass("active");
        } else if (this.state.aggregation === "count all" || this.state.aggregation === "Count (all)" || this.state.aggregation === "count") {
            $(this.state.el).find(".aggcount").addClass("active");
        } else if (this.state.aggregation === "Count (distinct)") {
            $(this.state.el).find(".aggcountdistinct").addClass("active");
        } else if (this.state.aggregation != undefined && this.state.aggregation.toLowerCase() === "none") {
            $(this.state.el).find(".aggnone").addClass("active");
            $("#dropdowntooltip").addClass('disabled');
			$("#dropdownlegend").addClass('disabled');	
			$("#dropdownsorting").addClass('disabled');
        }
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        if(that.state.reportId){
            SendRequest({
                url: "getalertsinfo",
                queryString: "?alertId=&reportId=" + that.state.reportId,
                sucessFunction: response => {
                    var filterid = window.localStorage.getItem("current")!=undefined ? atob(window.localStorage.getItem("current")):"";
					if(filterid!=undefined && filterid!=""){
						response = $.grep(response, function (e) { return e.filterid == filterid });
					}
                    ChartInfo.functions.setAlerts(response);
                },
                rejectFunction: () => { },
                headers: requestHeaders
        });
    }

        return (<div></div>);
    }
    changeyAxisAggregation = (e) => {
        var that = this;
        $(this.state.el).find(".aggregation-li-item").removeClass("active");
        $(e.currentTarget).addClass("active");
        var columnName = $(this.state.el).find('.singleliitems').attr("data-name");
        var columnDisplayName = $(this.state.el).find('.singleliitems').attr("title");
        var aggregation = $(e.currentTarget).attr("title");
        var aggregationtext = $(e.currentTarget).text();
        if(aggregationtext=="None"||aggregationtext=="none"){
            $("#dropdowntooltip").addClass('disabled');
			$("#dropdownlegend").addClass('disabled');
			$("#dropdownsorting").addClass('disabled');
            $("ul.tooltipattributes").empty();
			$("ul.legendAttributes").empty();
			$("ul.sortingattributes").empty();
            }else{
            $("#dropdowntooltip").removeClass('disabled');
			$("#dropdownlegend").removeClass('disabled');
			$("#dropdownsorting").removeClass('disabled');			
            }
        var percentileValue = $(this.state.el).find(".percentilebox").val();
        var selectedAggregation = $(e.currentTarget).text();
        if (aggregation === "percentile") { selectedAggregation = selectedAggregation + "(" + percentileValue + ")" }
        $(this.state.el).find(".aggregationtext").text("[ " + selectedAggregation + " ]");
        $(this.state.el).find('li.singleliitems').attr("percentile-value", percentileValue);
        $(this.state.el).find('.singleliitems').attr("data-agg", $(e.currentTarget).text());
        this.state.aggregation = aggregation;
        var index = $(e.currentTarget).parent().parent().parent().index();
        $(".combinationchart.singleliitems").eq(index).attr("data-agg", columnName + aggregationtext);
        if (this.state.yaxiscolumnName === columnName && this.state.yaxiscolumnDisplayName === columnDisplayName) {
            $(".measures .singleliitems[data-name = '" + this.state.yaxiscolumnName + "']").attr("data-agg", aggregationtext);
            $(".measures .singleliitems[data-name = '" + this.state.yaxiscolumnName + "']").attr("percentile-value", percentileValue);
            $(".measures .singleliitems[data-name = '" + this.state.yaxiscolumnName + "'] .percentilebox").val(percentileValue);
            $(".measures .singleliitems[data-name = '" + this.state.yaxiscolumnName + "'] #dropdownaggregation").text(aggregationtext);
            $(".measures .singleliitems[data-name = '" + this.state.yaxiscolumnName + "'] .aggregation-li-item").removeClass('active');
            $(".measures .singleliitems[data-name = '" + this.state.yaxiscolumnName + "'] li[title='" + aggregation + "']").addClass('active');

        }
        $(".rankingitem option[data-name='" + columnName + "']").attr("data-agg", aggregation);
        $(".rankingitem option[data-name='" + columnName + "']").text(columnDisplayName + ' (' + aggregation.replace(/[^A-Z0-9]/ig, "") + ')');
        this.updateMeasureAgginMeasureCollections(columnName, columnDisplayName, aggregation, percentileValue);

        var jsonArray = ChartInfo.functions.getAlerts();
        var that = this;
        _.each(jsonArray, function (value, index) {
            if (jsonArray != undefined && jsonArray.length != 0) {
                if (value.chartnumber == that.chartNumber) {
                    $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Information');
                    $('.birdmessage-info').empty().text(getMessage('BirdInformation30'));
                }
            }
        });
        /*		var isRankingItemAlreadyExist=false;
                _.each($('.rankingitem option'),function(rankingItem){
                    if($(rankingItem).attr("data-name")==columnName){
                        isRankingItemAlreadyExist=true;
                    }
                });
                if(isRankingItemAlreadyExist){*/
        //}

    }
    updateMeasureAgginMeasureCollections(columnName, columnDisplayName, aggregation, percentileValue) {
        var that = this;
        _.each($(".attributesmeasures"), function (measure, i) {
            $.each(that.props.measures, function (index, actualMeasure) {
                if ($(measure).attr("data-name") == actualMeasure.columnName) {
                    actualMeasure.aggregation = $(measure).find('#dropdownaggregation').text().replace(/[^A-Z0-9]/ig, "");
                }
            });
        });
        _.each(ChartInfo.functions.getMeasureHierarchy(), function (value, index) {
            _.each(value.hierarchelist, function (value1, index) {
                if (value1.columnName == columnName && value1.columnDisplayName == columnDisplayName) {
                    value1["aggregation"] = aggregation;
                    value1["percentilevalue"] = percentileValue
                }
            })
        });
    }
    deleteelement = (e) => {
        var $this = e.currentTarget;
        var flag = $($this).closest("ul").hasClass("yaxisattributes");
        if ($($this).closest("ul").hasClass("sortingattributes")) {
            $($this).closest('li').parent().parent().remove();
        } else {
            //var currentvalue = $($this).closest('span').prev().prev().text();
            var currentvalue = $($this).closest('li').attr('data-name')
            var aggType = $(e.currentTarget).closest("li").attr("data-agg");
            var dataNumYaxis = $($this).closest('li').attr('data-num-yaxis');
            if(aggType=="None"||aggType=="none"){
    			$("#dropdowntooltip").removeClass('disabled');
                $("#dropdownlegend").removeClass('disabled');
                $("#dropdownsorting").removeClass('disabled');
                }
            if (aggType == "Count (all)" || aggType == "Count (distinct)") { aggType = "count" }
            if($(".rankingitem option[data-name='" + currentvalue + "']").length > 1){
                $(".rankingitem option[data-name='" + currentvalue + "']")[0].remove(); 
            }else{
                $(".rankingitem option[data-name='" + currentvalue + "']").remove();
            }
            $(".combinationchart[data-num-yaxis='" + dataNumYaxis + "']").parent().remove();
            $($this).closest('li').parent().parent().remove();
        }
        if (this.state.multifact == "true" && flag) {
            $(".zaxisattributes div").remove();
            $(".legendAttributes div").remove();
            $(".referenceattributes div").remove();
            $(".sortingattributes div").remove();
            $(".metricdatefieldsAttributes div").remove();
        }
        var jsonArray = ChartInfo.functions.getAlerts();
        var that = this;
        _.each(jsonArray, function (value, index) {
            if (jsonArray != undefined && jsonArray.length != 0) {
                if (value.chartnumber == that.state.chartNumber) {
                    $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Information');
                    $('.birdmessage-info').empty().text(getMessage('BirdInformation30'));
                }
            }
        });
    }
    deletereferencelineelement = (e) => {
        var $this = e.currentTarget;
        $($this).closest("li").parent().remove();
    }
    editname = (e) => {
        var that = this;
        var $this = e.currentTarget;
        var isSmartInsights = $($this).closest("ul").hasClass("smartyaxisattributes");
        var isSorting = $($this).closest("ul").hasClass("sortingattributes");
        if (this.state.iscustomfunction != undefined && this.state.iscustomfunction == 1) {
            this.editcfname(e);
        } else if (!isSmartInsights && !isSorting) {
            $(".newslideoutattributes ul.yaxisattributes li span.axistitleedit").remove();
            $(".newslideoutattributes ul.yaxisattributes li span.itemname").show();
            var parentli = $($this).closest("li.singleliitems");
            var prevText = parentli.attr("title");
            var parentulreference = $(parentli).closest("ul").hasClass("referenceattributes");
            if (!parentulreference) {
                if ($(parentli).attr("data-type") == "customaggregatedfield" || $(parentli).attr("data-type") == "custommeasurehierarchy") {
                    $(parentli).find(".itemtype").click();
                } else {

                    if (this.props.is_storyboard == "Y") {
                        $.each(this.props.features, function (index, featuresModule) {
                            if (featuresModule.moduleName == 'story_board') {
                                $.each(featuresModule.features, function (index, feature) {
                                    if (feature.featureName == 'viewreport_rename_column' && feature.allow == true) {
                                        $(parentli).find("span.itemname").hide();
                                        $(parentli).find("span.itemname").after('<span class="overlay" title="Cancel Edit"></span><span class="axistitleedit"><input class="itemname" value="' + prevText + '"><button class="btn btn-transparent btn-sm applyaxisitemtitleedit" title="Apply"><i class="fa fa-check fa-green"></i></button><button class="btn btn-transparent btn-sm cancelaxisitemtitleedit" title="Cancel Edit"><i class="fa fa-times fa-dred"></i></button><span class="renameerror help-block marg-left-10 hide">'+lang["storyboard.nameesxists"]+'</span><span class="renameempty help-block marg-left-10 hide">'+lang["storyboard.entername"]+'</span><span class="specialcharacters help-block marg-left-10 hide">'+lang["storyboard.exceptdot"]+'</span></span>');
                                        $(".newslideoutattributes ul.yaxisattributes li input.itemname").focus();
                                    }
                                });
                            }

                        });
                    } else if (this.props.is_storyboard == "N") {
                        $.each(this.props.features, function (index, featuresModule) {
                            if (featuresModule.moduleName == 'my_report') {
                                $.each(featuresModule.features, function (index, feature) {
                                    if (feature.featureName == 'viewreport_maximize_rename_column' && feature.allow == true) {
                                        $(parentli).find("span.itemname").hide();
                                        $(parentli).find("span.itemname").after('<span class="overlay" title="Cancel Edit"></span><span class="axistitleedit"><input class="itemname" value="' + prevText + '"><button class="btn btn-transparent btn-sm applyaxisitemtitleedit" title="Apply"><i class="fa fa-check fa-green"></i></button><button class="btn btn-transparent btn-sm cancelaxisitemtitleedit" title="Cancel Edit"><i class="fa fa-times fa-dred"></i></button><span class="renameerror help-block marg-left-10 hide">'+lang["storyboard.nameesxists"]+'</span><span class="renameempty help-block marg-left-10 hide">'+lang["storyboard.entername"]+'</span><span class="specialcharacters help-block marg-left-10 hide">'+lang["storyboard.exceptdot"]+'</span></span>');
                                        $(".newslideoutattributes ul.yaxisattributes li input.itemname").focus();
                                    }
                                });
                            }
                        });
                    }
                }
            }
        }
        $('.cancelaxisitemtitleedit, li>span>.overlay').click(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            $($this).parent().parent().find("span.itemname").show();
            $(".newslideoutattributes ul.yaxisattributes li span.axistitleedit").remove();
            $(".newslideoutattributes ul.yaxisattributes li span.overlay").remove();
        });
        $('.applyaxisitemtitleedit').click(function (event) {
            event.stopPropagation();
            var $this = event.currentTarget;
            var columnRename = $($this).parent().find('input.itemname').val().trim();
            var measureslist = JSON.stringify(that.props.measures);
            var meauseres = JSON.parse(measureslist);
            var obj = {};
            obj["columnDisplayName"] = that.state.yaxiscolumnDisplayName;
            obj["columnName"] = that.state.yaxiscolumnName;
            obj["tableDisplayType"] = that.state.yaxisdataType;
            obj["tableName"] = that.state.yaxistableName;
            obj["tableDisplayName"] = that.state.yaxistableName;
            obj["aggregation"] = that.state.aggregation;
            var isAlreadyExist = false;
            meauseres.push(obj);
            var tempObject = sessionStorage.getItem("renameMeasuresorDimensions") == null ? {} : JSON.parse(sessionStorage.getItem("renameMeasuresorDimensions"));
            var columnRename = $($this).parent().find('input.itemname').val().trim();
            var reservedwordCheck = columnRename.toLowerCase();
            var status=false;
            var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
            _.each(reservedwordslist,function(reservedword,j){
				if(reservedwordCheck==reservedword){
				status=true
				}
			});	
            if (status) {
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text("Column " + "" + getMessage('BirdWarning147'));
                $(".spinner").hide();
                //$('.applyattributes').addClass("disabled");
                return;
            }
            $('.help-block.specialcharacters').addClass('hide');
            _.each(meauseres, function (cols) {
                var colName = $($this).parent().parent().parent().attr('data-name');
                if (cols.columnName == colName) {
                    _.each(that.props.dimensions, function (col) {
                        if (col.columnDisplayName == $($this).parent().find('input.itemname').val().trim()) {
                            col.columnDisplayName = $($this).parent().find('input.itemname').val().trim();
                            isAlreadyExist = true;
                        }
                    });
                    if (/[~`!@#$%^&*()+\=\[\]{};':\\|,<>\/?"]/.test(columnRename) || columnRename.trim().length == 0) {
                        $('.help-block.specialcharacters').removeClass('hide');
                    } else if ((columnRename.split('.').length > 2)) {
                        $('.help-block.specialcharacters').removeClass('hide');
                    } else if (isAlreadyExist) {
                        $('.help-block.renameempty').addClass('hide');
                        $('.help-block.renameerror').removeClass('hide');
                    } else if (columnRename.toLowerCase().trim() == "min" || columnRename.toLowerCase().trim() == "max"
                        || columnRename.toLowerCase().trim() == "avg" || columnRename.toLowerCase().trim() == "sum"
                        || columnRename.toLowerCase().trim() == "count" || columnRename.toLowerCase().trim() == "avgerage") {
                        $('.help-block.specialcharacters').removeClass('hide');
                    } else if ($($this).parent().find('input.itemname').val().trim() == "") {
                        $('.help-block.renameerror').addClass('hide');
                        $('.help-block.renameempty').removeClass('hide');
                    } else {
                        cols.columnDisplayName = $($this).parent().find('input.itemname').val().trim();
                        cols.columnssrename = 'yes';
                        tempObject[cols.columnName] = cols.columnDisplayName;
                        sessionStorage.setItem("renameMeasuresorDimensions", JSON.stringify(tempObject))
                        colName = colName.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\$1");
                        var convertedValues = sessionStorage.getItem("convertedvalues");
                        var parsedValues = JSON.parse(convertedValues);
                        _.each(parsedValues, function (val) {
                            if (val.columnName == cols.columnName) {
                                val.columnDisplayName = $($this).parent().find('input.itemname').val().trim();
                            }
                        });
                        sessionStorage.setItem("convertedvalues", JSON.stringify(parsedValues));
                        that.updateAllChartInfoByYaxisRenamed(tempObject);
                        $(".yaxisattributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemtype").attr("data-name", cols.columnDisplayName);
                        $(".combinationchartslistattributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".metricdatefieldsAttributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".metricdatefieldsAttributes").find(".singleliitems[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        $(".yaxisattributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".yaxisattributes").find(".singleliitems[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        $(".xaxisattributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".xaxisattributes").find(".singleliitems[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        $(".legendAttributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".legendAttributes").find(".singleliitems[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        $(".tooltipattributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".tooltipattributes").find(".singleliitems[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        $(".sortingattributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".zaxisattributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".zaxisattributes").find(".singleliitems[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        _.each($(".attributesmeasures"), function (e) {
                            var dimension = e;
                            if ($(dimension).attr('data-name') == colName) {
                                $(dimension).attr('title', $($this).parent().find('input.itemname').val().trim())
                                $(dimension).find('span.itemname').text($($this).parent().find('input.itemname').val().trim());
                            }
                        });
                        _.each($(".attributesdimensions"), function (e) {
                            var dimension = e;
                            if ($(dimension).attr('data-name') == colName) {
                                $(dimension).attr('title', $($this).parent().find('input.itemname').val().trim())
                                $(dimension).find('span.previewcolumndata').attr('data-displayname', $($this).parent().find('input.itemname').val().trim())
                                $(dimension).find('span.itemname').text($($this).parent().find('input.itemname').val().trim());
                                $(dimension).find('li.converttonumber').attr('data-value', $($this).parent().find('input.itemname').val().trim() + '_numb')
                                $(dimension).find('li.converttostring').attr('data-value', $($this).parent().find('input.itemname').val().trim() + '_str')
                                $(dimension).find('li.converttonumber').attr('title', $($this).parent().find('input.itemname').val().trim())
                                $(dimension).find('li.converttonumber').attr('title', $($this).parent().find('input.itemname').val().trim())
                            }
                        });
                        $(".rankingitem option[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        $(".rankingitem option[data-name='" + colName + "']").text(cols.columnDisplayName + '(' + $(".rankingitem option[data-name='" + colName + "']").attr('data-agg') + ')');
                        $(".sortingitem option[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        $(".sortingitem option[data-name='" + colName + "']").text(cols.columnDisplayName + '(' + $(".rankingitem option[data-name='" + colName + "']").attr('data-agg') + ')');
                        _.each(that.props.dimensions, function (columns) {
                            var colName = $($this).parent().parent().parent().attr('data-name');
                            if (columns.columnName == colName) {
                                columns.columnDisplayName = $($this).parent().find('input.itemname').val().trim();
                                columns.columnssrename = 'yes';
                            }
                        });
                        $($($($this).parent()).parent()).parent().find('span.itemname').text($($this).parent().find('input.itemname').val().trim());
                        $($($($this).parent()).parent()).parent().find('li:not(.aggregation-li-item)').attr('title', $($this).parent().find('input.itemname').val().trim());
                        //Rename CustomMeasure/Custom MeasureHierarchy
                        var columnName = colName,
                            changedName = $($this).parent().find('input.itemname').val().trim();
                        //$($($this).parent()).parent().hide();
                        //$('#filtersrename'+colName.replace(/([ ;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g,"")).text(columnRename);
                        $('#filtersrename' + colName.replace(/([ ;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "")).attr('title', columnRename);
                        $($this).parent().parent().find("span.itemname").show();
                        $(".newslideoutattributes ul.yaxisattributes li span.axistitleedit").remove();
                        $(".newslideoutattributes ul.yaxisattributes li span.overlay").remove();

                        //Update Conditional Formatting
                        $(".conditionalshow[data-name='" + colName + "']").attr("data-name", columnRename);
                        $(".attrabutesformatting[data-name='" + colName + "'] [data-name]").attr("data-name", columnRename);
                        $(".attrabutesformatting[data-name='" + colName + "']").attr("data-name", columnRename);

                        var conditions = ChartInfo.functions.getConditionalFormatData(that.chartNumber);
                        if (conditions != undefined) {
                            _.each(conditions, function (i) {
                                if (i.name == colName) {
                                    i.name = columnRename;
                                }
                            });
                            ChartInfo.functions.setConditionalFormatData(that.state.chartNumber, conditions)
                        }
                        //\Update Conditional Formatting

                        // update customRange
                        var rangeArray = ChartInfo.functions.getRange();
                        if (rangeArray != undefined && rangeArray.length > 0) {
                            _.each(rangeArray, function (j) {
                                if (j.xAxisName[0].columnName == colName) {
                                    j.xAxisName[0].columnDisplayName = columnRename;
                                }
                            });
                            ChartInfo.functions.setRange(rangeArray);
                        }

                    }
                }
                var chartInfoDetails = ChartInfo.functions.getAllChartDetails();
                _.each(chartInfoDetails, function (i, j) {
                    var chartData = JSON.parse(i);
                    if (chartData["drillDownFilter"] != undefined && chartData["drillDownFilter"].length != 0) {
                        _.each(chartData["drillDownFilter"], function (value, index) {
                            if (value.columnname == colName) {
                                chartData["drillDownFilter"][index]['columnDisplayName'] = $($this).parent().find('input.itemname').val().trim();
                                chartData["drillDownFilter"][index]['columndisplayname'] = $($this).parent().find('input.itemname').val().trim();
                            }
                            ChartInfo.functions.updateChartDetails(j, JSON.stringify(chartData));
                        });
                    }

                    if (chartData["drillDownAllFilter"] != undefined && chartData["drillDownAllFilter"].length != 0) {
                        _.each(chartData["drillDownAllFilter"], function (value, index) {
                            if (value.columnname == colName) {
                                chartData["drillDownAllFilter"][index]['columnDisplayName'] = $($this).parent().find('input.itemname').val().trim();
                                chartData["drillDownAllFilter"][index]['columndisplayname'] = $($this).parent().find('input.itemname').val().trim();
                            }
                            ChartInfo.functions.updateChartDetails(j, JSON.stringify(chartData));
                        });
                    }
                });
                var getAllTileLevelFilters = ChartInfo.functions.getAllTileLevelFilters();
                if (getAllTileLevelFilters != undefined) {
                    for (var key in getAllTileLevelFilters) {
                        if (getAllTileLevelFilters.hasOwnProperty(key)) {
                            var tileLevelFilters = getAllTileLevelFilters[key];
                            if (tileLevelFilters != undefined && tileLevelFilters[1] != undefined && tileLevelFilters[1].tileFiltersList != undefined) {
                                var tileFiltersList = tileLevelFilters[1].tileFiltersList;
                                $.each(tileFiltersList, function (index, filter) {
                                    if (filter["columnname"] == colName) {
                                        filter["columndisplayname"] = $($this).parent().find('input.itemname').val().trim();
                                        filter["filterDisplayName"] = $($this).parent().find('input.itemname').val().trim();
                                    }
                                });
                            }
                        }
                    }
                }

            });
        });
    }
    editcfname(e) {
        var $this = e.currentTarget;
        var isSmartInsights = $($this).closest("ul").hasClass("smartxaxisattributes");
        if (!isSmartInsights) {
            $(".newslideoutattributes ul.xaxisattributes li span.axistitleedit").remove();
            $(".newslideoutattributes ul.xaxisattributes li span.itemname").show();
            var parentli = $($this).closest("li.singleliitems");
            var prevText = parentli.attr("title");
            if ($(parentli).attr("iscustomfunction") != "0" && $(parentli).attr("iscustomfunction") != undefined) {
                $(parentli).find(".itemtype").click();
            } else {
                $(parentli).find("span.itemname").hide();
                $(parentli).find("span.itemname").after('<span class="overlay"></span><span class="axistitleedit"><input class="itemname" value="' + prevText + '"><button class="btn btn-transparent btn-sm applxaxisitemtitleedit"><i class="fa fa-check fa-green"></i></button><button class="btn btn-transparent btn-sm cancelaxisitemtitleedit"><i class="fa fa-times fa-dred"></i></button><span class="renameerror help-block marg-left-10 hide">'+lang["storyboard.nameesxists"]+'</span><span class="renameempty help-block marg-left-10 hide">Please Enter Name</span><span class="specialcharacters help-block marg-left-10 hide">'+lang["storyboard.spclcharacts"]+'</span></span>');
                $(".newslideoutattributes ul.xaxisattributes li input.itemname").focus();
            }
        }
    }

    updateAllChartInfoByYaxisRenamed(tempObject) {
        var chartInfoDetails = ChartInfo.functions.getAllChartDetails();
        _.each(chartInfoDetails, function (i, j) {
            var chartDetails = JSON.parse(i);
            var customhierarchy = ChartInfo.functions.getcustomhierarchy();
            _.each(customhierarchy, function (value, index) {
                _.each(value.hierarchelist, function (value1, index1) {
                    if (tempObject.hasOwnProperty(value1.columnName)) {
                        value1["displayName"] = tempObject[value1.columnName];
                    }
                });
            });
            ChartInfo.functions.setcustomhierarchy(customhierarchy);
            _.each(chartDetails['yAxis'], function (value, index) {
                if (tempObject.hasOwnProperty(value.columnName)) {
                    value["displayName"] = tempObject[value.columnName];
                }
            });
            if (chartDetails["metricdatefield"] != undefined && tempObject.hasOwnProperty(chartDetails["metricdatefield"])) {
                chartDetails["metricdatefielddisplay"] = tempObject[chartDetails["metricdatefield"]];
            }
            _.each(chartDetails['xAxis'], function (value, index) {
                if (tempObject.hasOwnProperty(value.columnName)) {
                    value["displayName"] = tempObject[value.columnName];
                }
            });
            _.each(chartDetails['tooltip'], function (value, index) {
                if (tempObject.hasOwnProperty(value.columnName)) {
                    value["displayName"] = tempObject[value.columnName];
                }
            });
            if (chartDetails['legend'] != undefined) {
                var columnName = chartDetails['legend']["columnName"];
                if (tempObject.hasOwnProperty(chartDetails['legend'].columnName)) {
                    chartDetails['legend']["displayName"] = tempObject[columnName];
                }
            }
            // for updating summary table
            _.each(chartDetails["summarytablecolumns"], function (xAxisObject) {
                if (xAxisObject.columnName != undefined && tempObject[xAxisObject.columnName] != undefined) {
                    xAxisObject.displayName = tempObject[xAxisObject.columnName];
                }
            });
            if (chartDetails["summarytablegroupbys"] != undefined && tempObject[chartDetails["summarytablegroupbys"].columnName] != undefined) {
                chartDetails["summarytablegroupbys"].displayName = tempObject[chartDetails["summarytablegroupbys"].columnName];
            }
            _.each(chartDetails["summarytablevalues"], function (yaxisObject) {
                if (tempObject[yaxisObject.columnName] != undefined) {
                    yaxisObject.displayName = tempObject[yaxisObject.columnName];
                }
            });
            //for updating pivot table
            _.each(chartDetails["rows"], function (row, i) {
                if (tempObject[row.columnName] != undefined) {
                    row.displayName = tempObject[row.columnName];
                }
            });
            _.each(chartDetails["columns"], function (xAxisObject, i) {
                if (tempObject[xAxisObject.columnName] != undefined) {
                    xAxisObject.displayName = tempObject[xAxisObject.columnName];
                }
            });
            _.each(chartDetails["values"], function (yaxisObject) {
                if (tempObject[yaxisObject.columnName] != undefined) {
                    yaxisObject.displayName = tempObject[yaxisObject.columnName];
                }
            });
            _.each(chartDetails["combinationchartslist"], function (val, i) {
                if (tempObject.hasOwnProperty(val.columnName)) {
                    val.displayName = tempObject[val.columnName]
                }
            })
            ChartInfo.functions.updateChartDetails(j, JSON.stringify(chartDetails));
        });
    }
    conditionalshow = (e) => {
        var that = this.state;
        var $this = e.currentTarget;
        var yaxisconditionalname = $($this).attr("data-name");
        var yaxisdatanum = $($this).attr("data-num-yaxis");
        var conditionFormateArrayAxis = ChartInfo.functions.getConditionalFormatData(this.state.chartNumber);
        if ($(".attrabutesformatting[data-name='" + yaxisconditionalname + "'][data-num-yaxis='" + yaxisdatanum + "']").hasClass('hide') == false) {
            return;
        }
        else {
            $(".yaxisattributes .conditionalformat1[data-name='" + yaxisconditionalname + "'][data-num-yaxis='" + yaxisdatanum + "']").empty();
            if (conditionFormateArrayAxis != undefined && conditionFormateArrayAxis.length != 0) {
                conditionFormateArrayAxis = $.grep(conditionFormateArrayAxis, function (e) { return e.name == yaxisconditionalname; });
                _.each(conditionFormateArrayAxis, function (conditionFormateArray) {
                    $(".yaxisattributes  .attrabutesformatting[data-name='" + yaxisconditionalname + "'][data-num-yaxis='" + yaxisdatanum + "']").removeClass("hide");
                    var templet = $(".yaxisattributes .attrabutesformatting[data-name='" + yaxisconditionalname + "'][data-num-yaxis='" + yaxisdatanum + "']").find(".conditionalformat1[data-name='" + yaxisconditionalname + "']");
                    if (conditionFormateArray.name == yaxisconditionalname && yaxisdatanum == conditionFormateArray.dataNumYaxis) {
                        _.each(conditionFormateArray.conditions, function (val) {
                            var condtionformaterobj = val;
                            if (condtionformaterobj["range"] == "<") {
                                templet.append("<div class='conditionalformat' data-name='" + yaxisconditionalname + "'><select class='modal-form-fields conditionalformstselect'>" +
                                    "<option value='smaller' selected><</option><option value='greater'>></option></select><input type='number'  value='" + condtionformaterobj["value"] + "' class='form-control conditionalfx' placeholder='0' title="+lang["storyboard.enterval"]+"/>" +
                                    "<span class='conditionalformattheme'><span class='input-group'><input type='text' placeholder="+lang["storyboard.placeholdheader"]+" value='" + condtionformaterobj["color"] + "' class='form-control colorcodenumber'/>" +
                                    "<span class='input-group-addon formattheme'><i></i></span></span></span><button class='btn btn-transparent deletecondition' data-name='" + yaxisconditionalname + "' type='button' title="+lang["storyboard.remove"]+">" +
                                    "<i class='fa fa-trash-o'></i></button></div>");
                                $('.conditionalformattheme>span.input-group').colorpicker();
                            }
                            else {
                                templet.append("<div class='conditionalformat' data-name='" + yaxisconditionalname + "'><select class='modal-form-fields conditionalformstselect'>" +
                                    "<option value='smaller'><</option><option value='greater' selected>></option></select><input type='number'  value='" + condtionformaterobj["value"] + "' class='form-control conditionalfx' placeholder='0' title="+lang["storyboard.enterval"]+"/>" +
                                    "<span class='conditionalformattheme'><span class='input-group'><input type='text' placeholder="+lang["storyboard.placeholdheader"]+" value='" + condtionformaterobj["color"] + "' class='form-control colorcodenumber'/>" +
                                    "<span class='input-group-addon formattheme'><i></i></span></span></span><button class='btn btn-transparent deletecondition' data-name='" + yaxisconditionalname + "' type='button' title="+lang["storyboard.remove"]+">" +
                                    "<i class='fa fa-trash-o'></i></button></div>");
                                $('.conditionalformattheme>span.input-group').colorpicker();
                            }
                        });
                    }
                });
                var conditionlength = $.grep(conditionFormateArrayAxis, function (e) { return e.name == yaxisconditionalname; }).length;
                if (conditionlength == 0) {
                    var $this = e.currentTarget;
                    $(".yaxisattributes .attrabutesformatting[data-name='" + yaxisconditionalname + "']").removeClass("hide");
                    $(".yaxisattributes .conditionalformat1[data-name='" + yaxisconditionalname + "']").append("<div class='conditionalformat' data-name='" + yaxisconditionalname + "'><select class='modal-form-fields conditionalformstselect'>" +
                        "<option value='smaller'><</option><option value='greater'>></option>" +
                        "</select><input type='number'  value='' class='form-control conditionalfx' placeholder='0' title="+lang["storyboard.enterval"]+"/><span class='conditionalformattheme'><span class='input-group'>" +
                        "<input type='text' placeholder="+lang["storyboard.placeholdheader"]+" value='' class='form-control colorcodenumber'/><span class='input-group-addon formattheme'><i></i></span></span></span>" +
                        "<button class='btn btn-transparent deletecondition' data-name='" + yaxisconditionalname + "' type='button' title="+lang["storyboard.remove"]+"><i class='fa fa-trash-o'></i></button></div>");
                    $('.conditionalformattheme>span.input-group').colorpicker();
                }
            } else {
                var $this = e.currentTarget;
                var yaxisconditionalname = $($this).attr("data-name");
                var yaxisdatanum = $($this).attr("data-num-yaxis");
                $(".yaxisattributes .attrabutesformatting[data-name='" + yaxisconditionalname + "'][data-num-yaxis='" + yaxisdatanum + "'] ").removeClass("hide");
                $(".yaxisattributes .conditionalformat1[data-name='" + yaxisconditionalname + "'][data-num-yaxis='" + yaxisdatanum + "']").append("<div class='conditionalformat' data-name='" + yaxisconditionalname + "'><select class='modal-form-fields conditionalformstselect'>" +
                    "<option value='smaller'><</option><option value='greater'>></option>" +
                    "</select><input type='number'  value='' class='form-control conditionalfx' placeholder='0' title="+lang["storyboard.enterval"]+"/><span class='conditionalformattheme'><span class='input-group'>" +
                    "<input type='text' placeholder="+lang["storyboard.placeholdheader"]+" value='' class='form-control colorcodenumber'/><span class='input-group-addon formattheme'><i></i></span></span></span>" +
                    "<button class='btn btn-transparent deletecondition' data-name='" + yaxisconditionalname + "' type='button' title="+lang["storyboard.remove"]+"><i class='fa fa-trash-o'></i></button></div>");
                $('.conditionalformattheme>span.input-group').colorpicker();
            }
        }
        $('.deletecondition').click(function (e) {
            var $this = e.currentTarget;
            e.preventDefault();
            var color = $(".colorcodenumber").val();
            var conditionFormateArrayAxis = ChartInfo.functions.getConditionalFormatData(that.chartNumber);
            var yaxisconditionalname = $($this).attr("data-name");
            _.each(conditionFormateArrayAxis, function (condtion) {
                for (var i = 0; i < condtion.conditions.length; i++)
                    //	if(condtion.conditions[i].color==color){
                    condtion.conditions.splice(i, 1);
                //}
            });
            $($this).closest('.conditionalformat[data-name="' + yaxisconditionalname + '"]').remove();
        });
    }
    addconditionbtn = (e) => {
        var that = this;
        var $this = e.currentTarget;
        var yaxisconditionalname = $($this).attr("data-name");
        var yaxisdatanum = $($this).attr("data-num-yaxis");
        $(".conditionalformat1[data-name='" + yaxisconditionalname + "'][data-num-yaxis=" + yaxisdatanum + "]").append("<div class='conditionalformat' data-name='" + yaxisconditionalname + "'><select class='modal-form-fields conditionalformstselect '>" +
            "<option value='smaller'><</option><option value='greater'>></option>" +
            "</select><input type='number'  value='' class='form-control conditionalfx' placeholder='0' title="+lang["storyboard.enterval"]+"/><span class='conditionalformattheme'><span class='input-group'>" +
            "<input type='text' placeholder="+lang["storyboard.placeholdheader"]+" value='' class='form-control colorcodenumber'/><span class='input-group-addon formattheme'><i></i></span></span></span>" +
            "<button class='btn btn-transparent deletecondition' data-name='" + yaxisconditionalname + "' type='button' title="+lang["storyboard.remove"]+"><i class='fa fa-trash-o'></i></button></div>");
        $('.conditionalformattheme>span.input-group').colorpicker();

        $('.deletecondition').click(function (e) {
            var $this = e.currentTarget;
            e.preventDefault();
            var color = $(".colorcodenumber").val();
            var conditionFormateArrayAxis = ChartInfo.functions.getConditionalFormatData(that.state.chartnumber);
            var yaxisconditionalname = $($this).attr("data-name");
            _.each(conditionFormateArrayAxis, function (condtion) {
                for (var i = 0; i < condtion.conditions.length; i++)
                    //	if(condtion.conditions[i].color==color){
                    condtion.conditions.splice(i, 1);
                //}
            });
            $($this).closest('.conditionalformat[data-name="' + yaxisconditionalname + '"]').remove();
        });
    }
    conditioncancel = (e) => {
        var $this = e.currentTarget;
        var yaxisconditionalname = $($this).attr("data-name");
        var yaxisdatanum = $($this).attr("data-num-yaxis");
        $('.attrabutesformatting[data-name="' + yaxisconditionalname + '"][data-num-yaxis="' + yaxisdatanum + '"]').addClass("hide");

    }
    compareDateShow = (e) => {
        var that = this.state;
        var $this = e.currentTarget;
        var today = new Date();
		var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
		dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
		$("body").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
        var xaxisArray = [];
            _.each($(".xaxisattributes .singleliitems"), function (listObj) {
				var xAxis = {};
				var columnName = $(listObj).attr("data-name");
				var columnDisplayName = $(listObj).attr("title");
				var columnDataType = $(listObj).attr("data-type");
				var xaxisidentity = $(listObj).attr("xaxisidentity");
				var tableName = $(listObj).attr("table-name");
				xAxis["columnName"] = columnName;
				xAxis["columnDisplayName"] = columnDisplayName;
				xAxis["tableDisplayType"] = columnDataType;
				xAxis["xaxisidentity"] = xaxisidentity;
				xAxis["tableName"] = tableName;
                xaxisArray.push(xAxis);
        })
        ReactDOM.render(<CompareDateRangeTemplate 
            createdNodeElement={dynamicdiv} state_data={that} $this={$this} dimensions={this.props.dimensions}
            xAxisData = {xaxisArray.length>=1?xaxisArray[0]:{}}
            multifact = {that.multifact}
            facttables = {that.facttables}
            multifactentities = {that.multifactentities}
        />,document.getElementById(dynamicdiv));
    }
}