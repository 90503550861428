import React from 'react'
import ReactDOM from 'react-dom'
import canvg from 'canvg'
import html2canvas from 'html2canvas'
import PubSub from 'pubsub-js'
import jstz from 'jstz'
import _ from 'underscore';
import SendRequest from '../../SendRequest';
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import * as ModalProperties from '../../js/helpers/utils/modalproperties';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import MLReportHeaderTemplate from './viewmlreportheader.jsx'
import { lang } from "../../js/helpers/utils/languages/language";
import SaveMLModel from './savemlstoryboardmodal'
import ShareReportModal from '../sharereportmodal'
import EmbedReportView from '../embedreportview';
import ViewSettingModal from '../smartinsights/viewreportsettingview';

const $ = window.$;
export default class MLReportHeaderView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.dynamicdiv = this.props.dynamicdiv;
        this.state.reportId = this.props.reportId;
        this.state.datamodelId = this.props.datamodelId;
        this.state.schemaId = this.props.schemaId;
        this.state.features = this.props.features;
        this.state.name = this.props.name;
        this.state.timeZoneType = this.props.timeZoneType;
        this.state.folder = this.props.folder;
        this.state.syncConfigData = this.props.syncConfigData;
        this.state.desc = this.props.desc;
        this.state.schemaType = undefined;
        this.state.isStoredProcedure = undefined;
        var that = this;
        this.state.schemaType = undefined;
        //this.state.quicktemplate = _.template(QuickTemplate);
        this.state.multifact = (this.props.multifact == 'true');
        this.state.multifactentities = this.props.multifact == 'true' ? JSON.parse(this.props.multifactentities) : this.props.multifactentities;
        that.render();
        if (that.props.isImportedReport) {
            var folder = this.props.folder
            if (folder === "") { folder = "Default" }
            var path = '<li><a class="navDashboard" href="#visualization"><i class="fa fa-bar-chart fa-solid"></i> '+lang["mlreports.visualization"]+'</a></li><li><a href="javascript:void(0)"><i class="fa fa-bar-chart"></i> '+lang["mlreports.newboard"]+'</a></li>'
        } else {
            var folder = this.props.folder
            if (folder === "") { folder = "Default" }
            var path = '<li><a class="navDashboard" href="#visualization"><i class="fa fa-bar-chart fa-solid"></i> '+lang["mlreports.visualization"]+'</a></li><li><a href="javascript:void(0)"><i class="fa fa-bar-chart"></i> '+lang["mlreports.newboard"]+'</a></li>'
            if (that.state.datamodelId != undefined && that.state.reportId != undefined) {
                var path = '<li><a class="navDashboard" href="#visualization"><i class="fa fa-bar-chart fa-solid"></i> '+lang["mlreports.visualization"]+'</a></li><li><a href="javascript:void(0)"><i class="fa fa-folder"></i> ' + folder + '</a></li><li><a href="#viewmodel/' + that.state.reportId + '/' + that.state.datamodelId + '"><i class="fa fa-bar-chart"></i> ' + that.props.name + '</a></li>'
            } else if (that.state.reportId != undefined) {
                var path = '<li><a class="navDashboard" href="#visualization"><i class="fa fa-bar-chart fa-solid"></i> '+lang["mlreports.visualization"]+'</a></li><li><a href="javascript:void(0)"><i class="fa fa-folder"></i> ' + folder + '</a></li><li><a href="#viewreport/' + that.state.reportId + '"><i class="fa fa-bar-chart"></i> ' + that.props.name + '</a></li>'
            }
        }
        $(".navbar.navbar-bird ul.nav-path").html(path);

    }
    render() {
        var tempColumns = [];
        var title = this.props.title;
        var schemaType;
        if (this.state.schemaType != undefined && this.state.schemaType != '' && this.state.schemaType != null)
            schemaType = (this.state.schemaType).toLowerCase();
        var schemaTypeFlag;
        var schemaTypeFlag1;
        var schemaTypeFlag2;
        var purgeHidden;
        if (title === undefined) {
            title = lang["mlreports.mlreport"];
        }
        var isSharedReport = false;
        if (window.location.pathname.indexOf("sharereport") >= 0) {
            isSharedReport = true;
        }
        this.state.isViewModel = false;
        if (window.location.pathname.indexOf("viewmodel") >= 0) {
            this.state.isViewModel = true;
        }
        var isEmbeddedReport = false;
        if (window.location.pathname.indexOf("embeddedsmartinsightreport") >= 0 || window.location.pathname.indexOf("embeddedreport") >= 0) {
            isEmbeddedReport = true;
        }
        var isScheduleReport = false;
        if (window.location.pathname.indexOf("schedulereport") >= 0) {
            isScheduleReport = true;
        }
        var isPurgedReport = false;
        if (window.location.pathname.indexOf("viewpurgereport") >= 0) {
            isPurgedReport = true;
        }
        if (window.location.pathname.indexOf("importreport") >= 0) {
            this.state.reportId = undefined;
        }
        var fragmentArray = window.location.pathname.split("/");
        if (fragmentArray.length == 3 && fragmentArray[0] === "sharereport") {
            this.state.isViewModel = true;
        }
        if (schemaType == "pdf" || schemaType == "csv" || schemaType == "fuse" || schemaType == "customstoryboard"
            || schemaType == "json" || schemaType == "log" || schemaType == "xls" || schemaType == "xlsx") {
            schemaTypeFlag1 = "hide";
        }
        if (schemaType == "fuse" || schemaType == "customstoryboard" || schemaType == "nlp" || schemaType == "birdinmemory") {
            schemaTypeFlag2 = "hide";
        }
        if (schemaType == "pdf" || schemaType == "csv" || schemaType == "elasticsearch" || schemaType == "mongodb" || schemaType == "fuse" || schemaType == "customstoryboard" || schemaType == "webdataconnector"
            || schemaType == "rabbitmq" || schemaType == "json" || schemaType == "eventhub" || schemaType == "kafka" || schemaType == "websocket" || schemaType == "log" || schemaType == "nlp" || schemaType == "xls" || schemaType == 'log_sftp' || schemaType == 'log_ftp'
            || schemaType == "birdinmemory") {
            schemaTypeFlag = "hide";
        }
        if (schemaType == "pdf" || schemaType == "csv" || schemaType == "customstoryboard" || schemaType == "json" || schemaType == "log" || schemaType == "xlsx" || schemaType == "xls" || schemaType == "nlp") {
            purgeHidden = "hide";
        }
        //			this.props.reportRequestFrom === "viewsavedreportview" 
        var isDirect = ChartInfo.functions.getIsDirect();
        if (this.state.isStoredProcedure != undefined) {
            ChartInfo.functions.setIsStoredProcedure(this.state.isStoredProcedure);
        }
        //If user is having view only access so making isEmbeddedReport and isPurgedReport true so that user cannot see the filter and other props of the right top corner
        if (this.props.viewreportCollection != undefined && this.props.viewreportCollection.getSharedViewOnlyFlag != undefined &&
            this.props.viewreportCollection.getSharedViewOnlyFlag() == 1) {
            isEmbeddedReport = true;
            isPurgedReport = true;
        }
        var isMicrosoftEdge = false;
        if (/Edge\/\d./i.test(navigator.userAgent)) {
            isMicrosoftEdge = true
        }
        ReactDOM.render(<MLReportHeaderTemplate
            title={title}
            current_user_role={$('.current_user_role').text()}
            isfavorite={undefined}
            isSharedReport={isSharedReport}
            isEmbeddedReport={isEmbeddedReport}
            isScheduleReport={isScheduleReport}
            allfeatures={this.state.features}
            isPurgedReport={isPurgedReport}
            reportRequestFrom={this.props.reportRequestFrom}
            reportId={this.state.reportId}
            schemaTypeFlag={schemaTypeFlag}
            schemaTypeFlag1={schemaTypeFlag1}
            schemaTypeFlag2={schemaTypeFlag2}
            purgeHidden={purgeHidden}
            isDirect={isDirect}
            isStoredProcedure={this.state.isStoredProcedure}
            schematype={schemaType}
            chartnumber={this.props.chartnumber}
            isViewModel={this.state.isViewModel}
            isMicrosoftEdge={isMicrosoftEdge}
            isMultiFact={this.state.multifact}
            savestoryboard={this.savestoryboard}
            clonereport={this.clonereport}
            setAsFavourite={this.setAsFavourite}
            showaddsettingmodal={this.showaddsettingmodal}
            sharemodal={this.sharemodal}
            embedreportmodal={this.embedreportmodal}
            redrawChart={this.redrawChart}
            togglefiltersmenu={this.togglefiltersmenu}
        />, document.getElementById(this.state.dynamicdiv));
        return (<div></div>);
    }
    togglefiltersmenu = (e) => {
        if ($(".instant-floating-filters").hasClass("changesdone")) {
            $(".instant-floating-filters.rightpane.changesdone .filtershead.collapsed").click();
            $("#rightfiltersapplys").addClass("tooltip-highlight"); setTimeout(function () { $("#rightfiltersapplys").removeClass("tooltip-highlight"); }, 2500);
        } else {
            if ($(".instant-floating-filters").is(":visible")) {
                $(".instant-floating-filters").hide();
            } else {
                $('.instant-floating-filters').toggle('slow')
            }
        }
    }
    redrawChart = (e) => {
        var that = this;
        $('.instant-floating-filters').hide();
        $('.addInstanceFilterData').empty();
        $('.quickfiltercontainer').empty();
        if ($('.acc-content').length == 0 && $('.addInstanceFilterData').text().trim().length == 0) {
            $('.instant-floating-filters').hide();
        }
        sessionStorage.removeItem("instantExcludeFilters");
        sessionStorage.removeItem("instantIncludeFilters");
        sessionStorage.removeItem("instantExcludeCustomFilters");
        ChartInfo.functions.setInstanceIncludeFilterData("");
        ChartInfo.functions.setInstanceFilterData("");
        ChartInfo.functions.setIncludeFilterData("");
        ChartInfo.functions.setInstanceFilterData("");
        var drilldown = [];
        ChartInfo.functions.setdrillfitlerArray(drilldown);
        var location = window.location;
        var isPurgedReport = false;
        if (location.pathname.indexOf("viewpurgereport") >= 0) {
            isPurgedReport = true;
        }
        $('.gridspinner').show();
        $('.showtrending').removeClass('active');
        $('.trendtype').removeClass('showactive');
        ChartInfo.functions.getFilters(false);
        $(".smartinsightintro-container").removeClass("hide");
        var mapdata = {}
        mapdata["isRunModel"] = true;
        mapdata["isReload"] = false;
        PubSub.publish('applyfiltesandredrawmlchart', mapdata);
        var mlContainerCurrentIndex = $('#mlsplitcontainer').steps('getCurrentIndex');
        mapdata = {}
        mapdata["currentIndex"] = undefined;
        mapdata["newIndex"] = mlContainerCurrentIndex;
        mapdata["isRunModel"] = true;
        PubSub.publish('loadNextPage', mapdata);
        var tilecount = $('.tileview').length;
        setTimeout(function () {
            for (var i = 0; i < tilecount; i++) {
                if ($('.fullscreenmain .maxanim8').length == 1) {
                } else {
                    if ($('.tileview[data-num=' + i + ']').length == 1) {
                        var elem = $('.tileview[data-num=' + i + ']');
                        var eastbar = elem.find(".ui-resizable-handle.ui-resizable-se").first();
                        var pageX = eastbar.offset().left;
                        var pageY = eastbar.offset().top;

                        (eastbar.trigger("mouseover")
                            .trigger({ type: "mousedown", which: 1, pageX: pageX, pageY: pageY })
                            .trigger({ type: "mousemove", which: 1, pageX: pageX - 1, pageY: pageY })
                            .trigger({ type: "mousemove", which: 1, pageX: pageX, pageY: pageY })
                            .trigger({ type: "mouseup", which: 1, pageX: pageX, pageY: pageY }));
                    } else {
                        tilecount++;
                    }
                }
            }
            $('.ui-resizable-handle').css("display", "none");
        }, 2000);
        var charts = ChartInfo.functions.getAllChartDetails();
        var legendfilter = [];
        ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
        _.each(charts, function (chart, j) {
            ChartInfo.functions.setLegendFilterobj(legendfilter, j);
            sessionStorage.removeItem("drilldown" + j);
        });
        sessionStorage.removeItem("smartinsightsfilter");
        $(".pie-menu").remove();
        $(".fullscreensample").removeClass("nopie");
    }
    savestoryboard = () => {
        $('.instant-floating-filters').hide();
        // $(".spinner").show();
        $('.savestoryboard').addClass('disabled');
        setTimeout(function () {
            $('.savestoryboard').removeClass('disabled');
        }, 5000);
        $("#" + this.state.dynamicdiv).find('.savestoryboarddiv').empty();
        var that = this;
        if (that.state.reportId == undefined || that.state.reportId == '' || that.state.reportId == null || that.state.reportId == 'null') {
            that.state.name = ''
        }
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $("#" + that.state.dynamicdiv).find('.savestoryboarddiv').append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
        var saveStoryBoardView = new SaveMLModel({
            dynamicdiv: dynamicdiv,
            viewreportCollection: that.props.viewreportCollection,
            reportId: that.state.reportId,
            schemaType: that.state.schemaType,
            name: that.state.name,
            timeZoneType: that.state.timeZoneType,
            folder: that.state.folder,
            desc: that.state.desc,
            features: that.state.features,
            syncConfigData: that.state.syncConfigData,
            isPurgedReport: false,
            isImportedReport: false,
            isDirect: that.props.isDirect,
            isViewModel: that.state.isViewModel,
            datamodelId: that.state.datamodelId,
            smartInsightCollection: that.props.collection
        });
        $(".spinner").hide();
        $('#incrementalrefreshfield').chosen({ 'width': '100%', search_contains: true });
        ModalProperties.resize();

    }
    clonereport = () => {
        var that = this;
        if (this.state.reportId == null || this.state.reportId == undefined || this.state.reportId == "" || window.location.pathname.indexOf("sharesmartinsightreport") >= 0) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage("BirdInformation01"));
        } else {
            var formdata = new FormData();
            formdata.set("reportId", that.state.reportId);
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "clonereport",
                body: formdata,
                sucessFunction: (response) => {
                    if (response.status == 'success') {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess02'));
                    } else if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
    }
    setAsFavourite = () => {
        if (this.state.reportId == null || this.state.reportId == undefined || window.location.pathname.indexOf("sharesmartinsightreport") >= 0) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
        }
        else {
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            var that = this;
            var favmodel = new FormData();
            favmodel.set("reportId", that.state.reportId);
            SendRequest({
                url: "setasfavourite",
                body: favmodel,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    } else
                        $('#setstoryboardasfavourite i').addClass("fa-yellow");
                }, rejectFunction: this.reportDataError,
                headers: requestHeaders

            });
        }
    }
    showaddsettingmodal = () => {
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $("#" + this.state.dynamicdiv).find('.settingmodalheaderdisplay').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        var settingModalView = new ViewSettingModal({
            dynamicdiv: dynamicdiv,
            viewreportCollection: this.props.viewreportCollection,
            reportId: this.state.reportId,
            name: this.state.name,
            folder: this.state.folder,
            desc: this.state.desc,
            title: this.props.title,
           });
        ModalProperties.resize();
    }
    sharemodal = () => {
        if (this.state.reportId === null || this.state.reportId === undefined || this.state.reportId === "" || window.location.pathname.indexOf("sharesmartinsightreport") >= 0) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
        }
        else {
            this.shareReportModel = new ShareReportModal({
                reportId: this.state.reportId,
                singlereport: false,
                dimensions: this.props.viewreportCollection.getDimensions(),
                sharedFilters: this.props.viewreportCollection.getSharedFilters(),
                schemaId: this.props.schemaId,
                isViewModel: this.state.isViewModel
            });
        }
    }
    embedreportmodal = (e) => {
        e.preventDefault();
        var name = e.currentTarget;
        ModalProperties.resize();
        var isFromSmartInsight = false;
        if (window.location.pathname.indexOf("viewsmartinsight") >= 0) {
            isFromSmartInsight = true;
        }
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getServerTime",
            queryString: "",
            sucessFunction: response => {
                var embedReportView = new EmbedReportView({
                    features: that.state.features,
                    reportId: that.state.reportId,
                    reportName: that.state.name,
                    servertimezone: response.serverTimeZone,
                    datamodelId: that.state.datamodelId,
                    isFromSmartInsight: isFromSmartInsight,
                    sendEmbeddedReportModel: this.sendEmbeddedReportModel
                });
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });

    }
    sendEmbeddedReportModel = (e) => {
        $('#embeddedspinner').show();
        $('.narrativecontainer').css("overflow", "hidden");
        var $this = e.currentTarget;
        var embedscreenshot = $(".embedscreenshots input[type='radio']:checked").attr('data-name');
        e.preventDefault();
        if ($('#emailto').val() == "" || !$('#emailto').val().match("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$")) {
            $('#embeddedspinner').hide();
            $('.email').addClass('show');
            return;
        }
        var dataurl = "";
        var bgcolor;
        if (embedscreenshot == "embedscreenshotyes") {
            $('footer.footer').css("display", "none");
            $('.tileview').css('overflow', 'hidden');
            for (var i = 0; i < 15; i++) {
                $('#chart' + i).css('overflow', 'hidden');
            }
            var dobysize = $('.doby-grid-cell').length;
            for (var i = 0; i < dobysize; i++) {
                var dobyright = $('.doby-grid-cell.r' + i).css('right');
                var dobyleft = $('.doby-grid-cell.l' + i).css('left');
                $('.doby-grid-cell.r' + i).css('right', dobyright);
                $('.doby-grid-cell.l' + i).css('left', dobyleft);
            }
            //Fix for bootstrap table download not working
            var fhtcellExists = $('.fht-cell').length > 0 ? true : false;
            var fhtcelllength = $('.fht-cell').length
            if (fhtcellExists) {
                for (var i = 0; i < fhtcelllength; i++) {
                    $(".fht-cell" + i + "").hide();
                    $(".th-inner").css("background-image", "none")
                }
            }
            var canvas = document.querySelector("#storyboardcanvas");
            var storyboardwidth = $(window).width()
            var storyboardheight = $('.leftpane .grid-stack').height() + 120;
            if ($('.fullscreenmain .maxanim8').length != 1 && $("body.filters-hidden").length != 1) {
                storyboardheight = storyboardheight + 60
            }
            canvas.setAttribute('width', storyboardwidth);
            canvas.setAttribute('height', storyboardheight);
            var tilesize = $('.tileview').length;
            var queryselector = $('.leftpane');
            if (embedscreenshot == "embedscreenshotyes") {
                $('.viewembedreportmodal').css('display', 'none');
                $('.screenshotmessage').removeClass('hide');

            }
            if ($('.fullscreenmain .maxanim8').length == 1) {
                //$('.fullscreenmain .maxanim8').find('.refreshsinglecharts1').eq(0).click();

                // Hide chart and show image
                //$('.fullscreenmain .maxanim8').find('.thumbimage').css('display','block');
                //$('.highcharts-container').css('display','none');
                $('.metric-container').css('display', 'block');
                $('.worldmaparea').css('display', 'block');

                queryselector = $('.fullscreenmain .maxanim7');
                bgcolor = $('.fullscreenmain .maxanim10').css('background-color');
                if (bgcolor == 'rgba(0, 0, 0, 0)' || bgcolor == undefined) {
                    $('.fullscreenmain .maxanim7').css('background-color', 'rgb(255,255,255)');
                }
                canvas = document.querySelector("#singlecanvas");
                var singlewidth = $(window).width()
                var singleheight = $('.fullscreenmain').height() + 100;
                canvas.setAttribute('width', singlewidth);
                canvas.setAttribute('height', singleheight);
            }
            else {
                for (var i = 0; i < tilesize; i++) {
                    if ($('.tileview[data-num=' + i + ']').length == 1) {
                        var temp = $('.tileview[data-num=' + i + ']').position().top;
                        $('.tileview[data-num=' + i + ']').css('top', temp);
                    } else {
                        tilesize++;
                    }
                }
            }
            var svgElements = $("body").find('svg');
            var getBackgroundColor = $(".maxanim7").css('background-color');
            if (getBackgroundColor == 'rgba(0, 0, 0, 0)' || getBackgroundColor == undefined) { getBackgroundColor = "rgb(255,255,255)" }
            svgElements.each(function () {
                var canvas, xml;
                canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                ctx.fillStyle = getBackgroundColor;
                canvas.className = "screenShotTempCanvas";
                xml = (new XMLSerializer()).serializeToString(this);
                xml = xml.replace(/xmlns=\"http:\/\/www\.w3\.org\/2000\/svg\"/, '');
                canvg.fromString(ctx, xml);
                $(canvas).insertAfter(this);
                $(this).attr('class', 'tempHide');
                $(this).hide();

            });
            //Fix for Leaflet Overlapping
            var leafletElemetns = $("body").find(".leafletcontainer");
            leafletElemetns.each(function () {
                var $this = this
                var mapPane = $($this).find(".leaflet-map-pane")[0];
                var mapTransform = mapPane.style.transform.replace("translate3d(", "").split(",");
                var mapX = parseFloat(mapTransform[0].replace("px", ""));
                var mapY = parseFloat(mapTransform[1].replace("px", ""));
                mapPane.style.transform = "translate3d(0px,0px,0px)";

                //Map bg images
                var myTiles = $($this).find(".leaflet-map-pane img.leaflet-tile");
                var tilesLeft = [];
                var tilesTop = [];
                for (var i = 0; i < myTiles.length; i++) {
                    var tileTransform = myTiles[i].style.transform.replace("translate3d(", "").split(",");
                    if (tileTransform[0] != "") {
                        tilesLeft.push(parseFloat(tileTransform[0].replace("px", "")));
                        tilesTop.push(parseFloat(tileTransform[1].replace("px", "")));
                        myTiles[i].style.left = (tilesLeft[i] + mapX) + "px";
                        myTiles[i].style.top = (tilesTop[i] + mapY) + "px";
                    }
                }
                $($this).find(".leaflet-map-pane img.leaflet-tile").css("transform", "")
                //Map Markers
                var myMarkerTiles = $($this).find(".leaflet-map-pane img.leaflet-marker-icon");
                var markerTilesLeft = [];
                var markerTilesTop = [];
                for (var i = 0; i < myMarkerTiles.length; i++) {
                    var markerTileTransform = myMarkerTiles[i].style.transform.replace("translate3d(", "").split(",");
                    if (markerTileTransform[0] != "") {
                        markerTilesLeft.push(parseFloat(markerTileTransform[0].replace("px", "")));
                        markerTilesTop.push(parseFloat(markerTileTransform[1].replace("px", "")));
                        myMarkerTiles[i].style.left = (markerTilesLeft[i] + mapX) + "px";
                        myMarkerTiles[i].style.top = (markerTilesTop[i] + mapY) + "px";
                    }
                }
                $($this).find(".leaflet-map-pane img.leaflet-marker-icon").css("transform", "");
                //Map Marker shadows
                var myMarkerShadowTiles = $($this).find(".leaflet-map-pane img.leaflet-marker-shadow");
                var markerShadowsLeft = [];
                var markerShadowsTop = [];
                for (var i = 0; i < myMarkerShadowTiles.length; i++) {
                    var markerShadowTransform = myMarkerShadowTiles[i].style.transform.replace("translate3d(", "").split(",");
                    if (markerShadowTransform[0] != "") {
                        markerShadowsLeft.push(parseFloat(markerShadowTransform[0].replace("px", "")));
                        markerShadowsTop.push(parseFloat(markerShadowTransform[1].replace("px", "")));
                        myMarkerShadowTiles[i].style.left = (markerShadowsLeft[i] + mapX) + "px";
                        myMarkerShadowTiles[i].style.top = (markerShadowsTop[i] + mapY) + "px";
                    }
                }
                $($this).find(".leaflet-map-pane img.leaflet-marker-shadow").css("transform", "");
            });
            /*$('.ui-resizable-handle').css('display','none');*/
            $(".sparklinecontainer").parent().css("overflow", "hidden");
            html2canvas(queryselector, { canvas: canvas, taintTest: true, logging: true, useCORS: true }).then(function (canvas) {
                if (embedscreenshot == "embedscreenshotyes") {
                    dataurl = canvas.toDataURL();
                } else {
                    dataurl = "";
                }
                setTimeout(function () {
                    $('.narrativecontainer').css("overflow", "auto");
                    // Show chart and hide image
                    $('.thumbimage').css('display', 'none');
                    //$('.highcharts-container').css('display','block');
                    $(".viewembedreportmodal").hide();
                    $('.screenshotmessage').addClass('hide');
                    // $('.viewembedreportmodal').css('display','block');
                    for (var i = 0; i < tilesize; i++) {
                        $('.tileview[data-num=' + i + ']').css("top", "");
                        $('#chart' + i + '').css('overflow', '');
                        if (bgcolor == 'rgb(255,255,255)') {
                            $('.fullscreenmain .maxanim7').css('background-color', '');
                        }
                    }
                    for (var i = 0; i < dobysize; i++) {
                        $('.doby-grid-cell.r' + i + '').css('right', '');
                        $('.doby-grid-cell.l' + i + '').css('left', '');
                    }
                    if (fhtcellExists) {
                        for (var i = 0; i < fhtcelllength; i++) {
                            $(".fht-cell" + i + "").show();
                            $(".th-inner").css("background-image", "")
                        }
                    }
                    $("body").find('.screenShotTempCanvas').remove();
                    $("body").find('.tempHide').show().removeClass('tempHide');
                }, 5000);

                var leafletElemetns = $("body").find(".leafletcontainer");
                leafletElemetns.each(function () {
                    var $this = this;
                    //Map Markers Reset
                    var myMarkerTiles = $($this).find(".leaflet-map-pane img.leaflet-marker-icon");
                    var markerTilesLeft = [];
                    var markerTilesTop = [];
                    for (var i = 0; i < myMarkerTiles.length; i++) {
                        myMarkerTiles[i].style.transform = "translate3d(" + myMarkerTiles[i].style.left + "," + myMarkerTiles[i].style.top + ",0)";
                        myMarkerTiles[i].style.left = "";
                        myMarkerTiles[i].style.top = "";
                    }
                    //Map Marker shadows Reset
                    var myMarkerShadowTiles = $($this).find(".leaflet-map-pane img.leaflet-marker-shadow");
                    var markerShadowsLeft = [];
                    var markerShadowsTop = [];
                    for (var i = 0; i < myMarkerShadowTiles.length; i++) {
                        myMarkerShadowTiles[i].style.transform = 'translate3d(' + myMarkerShadowTiles[i].style.left + "," + myMarkerShadowTiles[i].style.top + ",0)";
                        myMarkerShadowTiles[i].style.left = "";
                        myMarkerShadowTiles[i].style.top = "";
                    }
                })
                $(".sparklinecontainer").parent().css("overflow", "");
                $('footer.footer').css("display", "block");

                if (ChartInfo.functions.isScriptData($('#embedurl').val()) || ChartInfo.functions.isScriptData($('#emailto').val())
                    || ChartInfo.functions.isScriptData($('#emailsubject').val()) || ChartInfo.functions.isScriptData($('#emailbody').val())) {
                    return true;
                }
            });
        }
        var formData = new FormData();
        formData.set("embeddedReportUrl", $('#embedurl').val());
        formData.set("toEmail", $('#emailto').val());
        formData.set("emailSubject", $('#emailsubject').val());
        formData.set("emailBody", $('#emailbody').val());
        formData.set("dataurl", dataurl);

        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };

        SendRequest({
            url: "sendembeddedreportemail",
            body: formData,
            sucessFunction: (response) => {
                if (response.status == 'success') {
                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Success');
                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess04'));
                    //$('#main').prepend('<div class="alert alert-success birdsuccess alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 9999;left: 20%;">Message has been sent successfully</div>');
                    setTimeout(function () {
                        $('.birdmessage-center').removeClass("message-top-in");
                        $('#embeddedspinner').hide();
                        $(".spinner").hide();
                        ReactDOM.unmountComponentAtNode(document.querySelector('.embedreportdiv'))
                    }, 3000);
                } else if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('#embeddedspinner').hide();
                    //$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 9999;left: 20%;">'+response.error+'</div>');
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                    setTimeout(function () {
                        $('.birdmessage-center').removeClass("message-top-in");
                        $('#embeddedspinner').hide();
                        $(".spinner").hide();
                    }, 3000);
                }
                else {
                    $(".spinner").hide();
                    $('#embeddedspinner').hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(response.status);
                    //$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 9999;left: 20%;">'+response.status+'</div>');
                    setTimeout(function () {
                        $('.birdmessage-center').removeClass("message-top-in");
                        $('#embeddedspinner').hide();
                        $(".spinner").hide();
                    }, 3000);
                }
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders
        });
    }
    updatembbedreport() {
        var dateSelection;
        var timeSelection;
        var dateSelected = $('#embedreportenddate').val();
        var timeSelected = $('#embedreportendTime').val();
        if (dateSelected !== "" && timeSelected === "") {
            $('.spinner').hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning75'));

            $("#embedurl").prop("disabled", true);
            $("#emailembedurlbutton").prop("disabled", true);
            return false;

        } else if (timeSelected !== "" && dateSelected === "") {
            $('.spinner').hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning74'));
            $("#embedurl").prop("disabled", true);
            $("#emailembedurlbutton").prop("disabled", true);
            return false;

        } else if (timeSelected !== "" && dateSelected !== "") {
            dateSelection = dateSelected;
            timeSelection = timeSelected;
            $("#embedurl").prop("disabled", false);
            $("#emailembedurlbutton").prop("disabled", false);
        } else if (timeSelected === "" && dateSelected === "") {
            $('#embedreportendTime').val("");
            dateSelection = "00/00/0000";
            timeSelection = "00:00";
            $("#embedurl").prop("disabled", false);
            $("#emailembedurlbutton").prop("disabled", false);
        }


        var loginTypeReport = $(".reportLoginType input[type='radio']:checked").attr("id");
        var urlAccessingTypeReport = $(".reportUrlAccessing input[type='radio']:checked").attr("id");

        var that = this;
        var report = that.state.reportId;
        var embedtimezone = $('.embedtimezone:checked').attr("id")
        if (embedtimezone == "localtimezone") {
            var localTimezone = jstz.determine().name();
        }
        var accessPermissionId = $('.publishurl:checked').attr("id");
        var expDatetime = new Date(new Date(dateSelection+" "+timeSelection).toLocaleString("en-US", {timeZone: window.localStorage.getItem("serverTimezone")})).getTime() +"@@@"+window.localStorage.getItem("serverTimezone");
        var formData = new FormData();
        formData.set("dateSelectionn", ChartUtil.birdbtoa("embed",expDatetime));
        formData.set("timeSelectionn", timeSelection);
        formData.set("reportId", report);
        formData.set("datamodelId", btoa(that.state.datamodelId));
        formData.set("embeddedLoginId", accessPermissionId);
        formData.set("localTimezone", localTimezone);
        formData.set("isFromSmartInsight", true);
        formData.set("pathName", window.location.origin + "/" + window.localStorage.getItem("appName") + "/welcome")

        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "publishreport",
            body: formData,
            sucessFunction: (response) => {
                if (accessPermissionId == 1) {
                    $("#embedurl").html(response.private);
                    if ($('.publishurl:checked').attr("id") == "1") {
                        $('.publishurl:checked').attr("purl", response.private)
                    }
                } else if (accessPermissionId == 2) {
                    $("#embedurl").html(response.public);
                    if ($('.publishurl:checked').attr("id") == "2") {
                        $('.publishurl:checked').attr("purl", response.public)
                    }
                }
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders
        });
    }
}