import React, { Component } from "react";
import _ from 'lodash';
//import Highcharts from 'highcharts'
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d';
import PubSub from 'pubsub-js'
import drilldow from "highcharts/modules/drilldown";
import moment from 'moment';
import dataModule from "highcharts/modules/data";
import indicators from "highcharts/indicators/indicators";
import regressions from "highcharts/indicators/regressions";
import ema from "highcharts/indicators/ema";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import HighchartsMore from 'highcharts/highcharts-more'
import { whitelableMap } from "../../js/helpers/utils/whitelable";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import { lang } from "../../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
const $ = window.$;


highcharts3d(Highcharts);
drilldow(Highcharts);
dataModule(Highcharts);
HighchartsMore(Highcharts);
indicators(Highcharts);
ema(Highcharts);
regressions(Highcharts);

var charts = {};

export default class TimeseriesChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        var data = this.props.data, xaxisName = this.props.xaxisName, xaxisColumnName = this.props.xaxisColumnName, xaxisDataType = this.props.xaxisDataType, yaxisnameArray = this.props.yaxisnameArray, chartId = this.props.chartId, chartType = this.props.chartType, legend = this.props.legend, position = this.props.position,
            features = this.props.features, isSharedReport = this.props.isSharedReport, colorThemeIndex = this.props.colorThemeIndex, chartSettings = this.props.chartSettings, tooltipNameArray = this.props.tooltipNameArray, algorithm = this.props.algorithm, periodsvalue = this.props.periodsvalue, trendstats = this.props.trendstats, yAxisOriginalNameArray = this.props.yAxisOriginalNameArray, referenceLine = this.props.referenceLine,
            iscustomstoryboard = this.props.iscustomstoryboard, yAxisDataTypeArray = this.props.yAxisDataTypeArray, yAxisAggArray = this.props.yAxisAggArray, isMLReport = this.props.isMLReport, reportid = this.props.reportid;

        var colorThemesArray = ChartInfo.functions.getColorThemes();
        var isExportChartEnable = false;
        var categories = [];
        /*Set Chart height to fix resize issues */
        if(charts!=undefined && chartId!=undefined && charts[chartId]!=undefined && charts[chartId].length!=undefined){
            charts[chartId].destroy();
        }
        var chartnumber = chartId.replace("chart", "");
        this.state.chartnumber = chartnumber;
        this.state.chartType = chartType;
        this.state.chartId = chartId;
        ChartUtil.streamingcharts.push(chartnumber);
        if ($('.tileview[data-num=' + chartnumber + ']').length === 1) {
            var setheight = $('.tileview[data-num=' + chartnumber + ']').height() - 42;
            $("#chart" + chartnumber).height(setheight);
        }

        var bgcolor = $('.maxanim7[data-num=' + chartnumber + ']').css('background-color');
        if (bgcolor == undefined || bgcolor === 'rgba(0, 0, 0, 0)') { bgcolor = 'rgba(255, 255, 255, 1)' }

        isExportChartEnable = ChartUtil.checkExportChartEnable(features, position, isSharedReport);
        var userLang = navigator.language || navigator.userLanguage;
        var locale = "en_za";
        var that = this;
        var data_multiple = [];
        var seriesOptions = [];
        var seriesDataOptions = [];
        var dateFormat;
        var aggType = data[0].aggType;
        var trendstatsdata = data;
        var legendExceedDataInfo = false;
        if (legend != undefined && legend.columnName.length > 0) {
            _.each(data, function (dataObject, i) {
                if (dataObject.length > 0 && dataObject[0] != undefined && dataObject[0]["legendExceedDataInfo"] != undefined && dataObject[0]["legendExceedDataInfo"]) {
                    legendExceedDataInfo = true;
                }
            });
        }
        if (trendstats == undefined) {
            $('.trend-stats-container').remove();
        }
        if (trendstats == "trendstats") {
            var tempDataArray = [];
            var count = 0;
            _.each(data, function (dataObject, i) {
                count++;
                if (legend == undefined) {
                    if (dataObject["data"] != undefined) {
                        tempDataArray.push(dataObject);
                    }
                }
                else {
                    _.each(yaxisnameArray, function (yAxisObject, j) {
                        if (dataObject["data"] == undefined && dataObject[yAxisObject] != undefined) {
                            tempDataArray.push(dataObject);
                        }
                        else {
                            if (j == 0 && count % 3 != 0) {
                                tempDataArray.push(dataObject);
                            }

                        }
                    })
                }
            });
            data = tempDataArray;

        }
        var customfield = JSON.parse(sessionStorage.getItem("customFields"));
        var temp = customfield;
        var res;
        _.each(temp, function (customfields) {
            _.each(customfields.parametrFieldsArray, function (value) {
                res = $.grep(ChartInfo.functions.getCustomParameter(), function (e) {
                    return e.parametername == value;
                })
                var formula = customfields.formula;
                var scriptcondition = customfields.scriptcondition;
                var fields = customfields.fields;
                var sqlformula = customfields.sqlformula;
                if (res.length != 0) {
                    customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                } else {
                    _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                        if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                            customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                        }
                    });
                }
            });
        });
        customfield = JSON.stringify(temp);
        if (customfield != null && customfield != "null") {
            _.each(customfield, function (m, k) {
                if (m.name == xaxisName) {
                    var substring = m.formula.split("format")[1];
                    dateFormat = substring.substring(2, substring.length - 3)
                }
            });
        }
        if (legend != undefined && legend.toString().length > 0
            && legend.columnName != undefined
            && legend.columnName.length > 0) {
            _.each(data, function (q, p) {
                var r = 1;
                if ((trendstats == undefined) ? (p % 2 != 0) : ((p == r) || ((p - r) % 3 == 0))) {
                    //	if(p%2 != 0 ){
                    var dummydata_multiple = [];
                    _.each(data[p], function (m, k) {
                        var multi = [];
                        var x = data[p][k].data;
                        _.each(x, function (j, i) {
                            var newData = [];
                            var myDate = x[i].name;
                            var multiobj = {};
                            if (myDate.length > 0) {
                                var newDate = moment.utc(myDate);
                                if (isNaN(newDate)) {
                                    var newdate1 = newDate;
                                    multiobj["x"] = newDate;
                                } else
                                    multiobj["x"] = new Date(newDate).getTime();
                            } else
                                multiobj["x"] = x[i].name;
                            multiobj["y"] = x[i].y;
                            if (tooltipNameArray.length != 0) {
                                $.each(tooltipNameArray, function (j) {
                                    var tooltipnow = tooltipNameArray[j];
                                    var tooltipvalue = "t" + j;
                                    var tooltipagg = "ta" + j;
                                    multiobj[tooltipvalue] = x[i][tooltipvalue];
                                    multiobj[tooltipagg] = x[i][tooltipagg];
                                });
                            }
                            multi.push(multiobj);
                            /*if(newData[0].length==0){
                                var newData1=[];
                                newData1.push(new Date().getTime());
                                newData1.push(0);
                                multi.push(newData1);
                            }else{
                            multi.push(newData);
                            }*/
                        });
                        multi = multi.sort(function sort_by_column(a, b) {
                            return ((a[0] < b[0]) ? -1 : ((a[0] > b[0]) ? 1
                                : 0));
                        });
                        seriesDataOptions[p] = {
                            name: m.name,
                            data: multi,
                            color: m.color,
                            aggType: m.aggType,
                            gapSize: 5,
                            tooltip: {
                                valueDecimals: 2
                            },
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 1
                                },
                                /*stops : [ [ 0, "#57c17b" ],
                                // [0, Highcharts.getOptions().colors[0]],
                                [ 1, "#006e8a" ],// [1,
                                // Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                                ]*/
                                stops: [
                                    [0, colorThemesArray[colorThemeIndex][p]],
                                    [1, Highcharts.Color(colorThemesArray[colorThemeIndex][p]).setOpacity(0.5).get('rgba')]
                                ]
                            }
                        };
                        dummydata_multiple.push(multi);
                    });
                    data_multiple.push(dummydata_multiple);
                }
                else {
                    seriesDataOptions[p] = {};
                }
            })

        } else {
            _.each(data, function (m, k) {
                var x = data[k].data;
                var multi = [];
                _.each(x, function (j, i) {
                    var newData = [];
                    var myDate = x[i].name;
                    var multiobj = {};
                    if (tooltipNameArray != undefined && tooltipNameArray.length != 0) {
                        $.each(tooltipNameArray, function (j) {
                            var tooltipnow = tooltipNameArray[j];
                            var tooltipvalue = "t" + j;
                            var tooltipagg = "ta" + j;
                            multiobj[tooltipvalue] = x[i][tooltipvalue];
                            multiobj[tooltipagg] = x[i][tooltipagg];
                        });
                    }
                    var newDate = moment.utc(myDate);
                    if (isNaN(newDate)) {
                        //newData.push(newDate);
                        multiobj["x"] = newDate;
                    } else
                        multiobj["x"] = new Date(newDate).getTime();
                    multiobj["y"] = x[i].y;
                    multi.push(multiobj);
                    //multi.push({x:new Date(newDate).getTime(),y:x[i].y});
                    /*newData.push(new Date(newDate).getTime());
                    newData.push(x[i].y);
                    multi.push(newData);*/
                });
                multi = multi.sort(function sort_by_column(a, b) {
                    return ((a[0] < b[0]) ? -1 : ((a[0] > b[0]) ? 1
                        : 0));
                });
                seriesOptions[k] = {
                    name: m.name,
                    data: multi,
                    color: m.color,
                    aggType: m.aggType,
                    gapSize: 5,
                    tooltip: {
                        valueDecimals: 2
                    },
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        /*stops : [ [ 0, "#57c17b" ],
                    // [0, Highcharts.getOptions().colors[0]],
                    [ 1, "#006e8a" ],// [1,
                    // Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                    ]*/
                        stops: [
                            [0, colorThemesArray[colorThemeIndex][k]],
                            [1, Highcharts.Color(colorThemesArray[colorThemeIndex][k]).setOpacity(0.5).get('rgba')]
                        ]
                    }
                };
                data_multiple.push(multi);
            });
        }
        var options = {
            chart: {
                events: {
                    click: function (event) {
                        var $this = event.currentTarget;
                        if (event.target.hasAttribute('href')) {
                            var bgcolor = $('.maxanim7[data-num=' + chartnumber + ']').css('background-color');
                            if (bgcolor == undefined || bgcolor === 'rgba(0, 0, 0, 0)') { bgcolor = 'rgba(255, 255, 255, 1)' }
                            this.options.exporting.chartOptions.chart.backgroundColor = bgcolor;
                        }
                    },
                    load: function (e) {
                        // Get Real-Time Settings Values.
                        var $this = e.target;
                        var ChartNumber = chartnumber;
                        var time = $('.reltimedatasynctime[data-num=' + ChartNumber + ']').val();
                        var timeperiod = $('.realtimedatatimetype[data-num=' + ChartNumber + ']').val();
                        var timetosync;
                        if (timeperiod == "seconds") {
                            timetosync = time * 1000;
                        } else if (timeperiod == "minutes") {
                            timetosync = time * 60000;
                        } else if (timeperiod == "hours") {
                            timetosync = time * 3600000;
                        }
                        if ($('.timeseriesrealtimedatabuttons.hide[data-num=' + ChartNumber + ']').length != "1"
                            && $('.realtimedatatoggle[data-num=' + ChartNumber + ']').is(':checked') == true) {
                            var series = this.series;
                            series.splice(-1, 1);
                            _.each(series, function (c, i) {
                                // set up the updating of the chart
                                var seriesnew = c;
                                setInterval(function () {
                                    var items = Array(10, 100, 1000);
                                    var item = items[Math.floor(Math.random() * items.length)];
                                    var x = (new Date()).getTime(), // current time
                                        y = Math.round(Math.random() * item);
                                    if ($.isEmptyObject(seriesnew.chart) == false) {
                                        seriesnew.addPoint([x, y], true, true);
                                    }
                                }, timetosync);
                            });
                        }
                    }
                },
                alignTicks: false,
                renderTo: chartId,
                reportId: reportid,
                defaultSeriesType: '',
                zoomType: 'xy',
                backgroundColor: 'transparent',
                style: {
                    fontFamily: whitelableMap["fontfamily"],
                    fontWeight: '400',
                },
                className: 'timesereischart',
                //height:'auto'
                animation:false
            },
            rangeSelector: {
                buttons: [{
                    type: 'second',
                    count: 5,
                    text: '5s'
                }, {
                    type: 'minute',
                    count: 15,
                    text: '15m'
                }, {
                    type: 'hour',
                    count: 1,
                    text: '1h'
                }, {
                    type: 'week',
                    count: 1,
                    text: '1W'
                }, {
                    type: 'month',
                    count: 1,
                    text: '1M'
                }, {
                    type: 'month',
                    count: 3,
                    text: '3M'
                }, {
                    type: 'month',
                    count: 6,
                    text: '6M'
                }, {
                    type: 'ytd',
                    text: 'YTD'
                }, {
                    type: 'year',
                    count: 1,
                    text: '1Y'
                }, {
                    type: 'all',
                    text: 'All'
                }],
                selected: 9
            },
            title: {
                text: ''
            },
            tooltip: {
                /*headerFormat : '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat : '<tr><td style="color:{series.color};padding:0">{series.name}: </td>'
                        + '<td style="padding:0"><b>{point.y:,.2f}</b></td></tr>',
                footerFormat : '</table>',*/
                pointFormatter: function () {
                    var name = '<table>';
                    if (isMLReport != undefined && isMLReport) {
                        name = '<span style="font-size:10px">' + moment(this.x).format('MMM DD, YYYY') + '</span><table>';
                    }
                    var details;
                    if (ChartInfo.functions.getChartDetails(chartnumber) != undefined) {
                        details = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
                    }

                    var that = this;
                    if (details != undefined && details['yAxis'].length != 0 && this.series != undefined) {
                        var seriesName = (this.series.options.displayname == undefined) ? this.series.name : this.series.options.displayname;
                        name +='<tr><td style="color:'+that.series.color+';padding:0">'+this.series.options.name+' <span class=bocaggtype>['+(that.series.options.aggType =='customaggregatedfield'?'raw':that.series.options.aggType)+'] </span>' + ':&nbsp; </td>';
                    }
                    if (this.series != undefined && yAxisAggArray != undefined) {
                        var pointDataType = yAxisDataTypeArray[yAxisAggArray.indexOf(this.series.options.aggType.toLowerCase())];
                        if (pointDataType == 'date' && this.series.options.aggType.toLowerCase().indexOf("count") < 0) {
                            var date = new Date(this.y);
                            name += '<td style="padding:0"><b>' + date.toLocaleString() + '</b></td></tr>';
                        }
                        else {
                            if (chartSettings.numberformat != undefined) {
                                var num = ChartInfo.functions.addFormatting(this.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator);
                                name += '<td style="padding:0"><b>' + num + '</b></td></tr>';
                            } else {
                                name += '<td style="padding:0"><b>' + this.y + '</b></td></tr>';
                            }
                        }
                    }
                    var that = this;
                    if (tooltipNameArray != undefined && tooltipNameArray.length != 0) {
                        $.each(tooltipNameArray, function (i) {
                            var tooltipnow = tooltipNameArray[i];
                            var tooltipvalue = "t" + i;
                            var tooltipagg = "ta" + i;
                            name +='<tr><td style="color:'+that.series.color+';padding:0">'+tooltipnow+' <span class=bocaggtype>[';
							name+=(that[tooltipagg]=='customaggregatedfield'?'raw':that[tooltipagg])+'] </span>' + ': </td>';
                            //var pointDataType = yAxisDataTypeArray[yAxisAggArray.indexOf(that.point[tooltipagg].toLowerCase())]
                            if (that[tooltipvalue] == 0) {
                                name += '<td style="padding:0"><b>' + that[tooltipvalue] + '</b></td></tr>'
                            } else {
                                if (chartSettings.numberformat != undefined) {
                                    var num = ChartInfo.functions.addFormatting(that[tooltipvalue], chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                    name += '<td style="padding:0"><b>' + num + '</b></td></tr>'
                                }
                                else {
                                    name += '<td style="padding:0"><b>' + that[tooltipvalue] + '</b></td></tr>'
                                }
                            }
                        });
                    }
                    return name;

                },
                shared: false,
                split: false,
                useHTML: true
            },
            legend: {
                enabled: true,
                layout: 'horizontal',
                align: 'right',
                verticalAlign: 'bottom',
                borderWidth: 0,
                itemStyle: {}
            },
            xAxis: [{
                title: {
                    text: xaxisName,
                    orginaltextName: xaxisColumnName,
                    style: {}
                },
                ordinal: false,
                labels: {
                    style: {}
                },
            }],
            drilldown: {
                // activeAxisLabelStyle: {
                //     cursor: 'pointer',
                //     textDecoration: 'none',
                //     color: '#000',
                //     fontWeight: '400'
                // },
                // activeDataLabelStyle: {
                //     cursor: 'pointer',
                //     textDecoration: 'none',
                //     color: '#000',
                //     fontWeight: '400'
                // },
                series: []
            },
            yAxis: [{
                title: {
                    margin: 20,
                    text: yaxisnameArray,
                    style: {},
                    oppsite: true
                },
                labels: {
                    align: 'left',
                    style: {}
                },
                plotLines: [],
                style: {},
                formatter: function () {
                    if (yAxisDataTypeArray != undefined && yAxisDataTypeArray.length == 1 && yAxisDataTypeArray.indexOf('date') >= 0 && yAxisAggArray.toLocaleString().indexOf('count') < 0) {
                        var d = new Date(this.value);
                        return d.toLocaleString();
                    }
                    else {
                        if (chartSettings.numberformat != undefined) {
                            var num = ChartInfo.functions.addFormatting(this.value, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator);
                            return num;
                        } else {
                            return this.value;
                        }
                    }
                }
            }],
            plotOptions: {
                series: {
                    animation: false,
                    dataLabels: {
                        enabled: false,
                        style: { textShadow: 'none' },
                        formatter: function () {
                            if (this.point.ydatatype == 'date' && this.series.options.aggType.toLowerCase().indexOf("count") < 0) {
                                var date = new Date(this.y);
                                return date.toLocaleString();
                            } else {
                                if (chartSettings.numberformat != undefined) {
                                    var num = ChartInfo.functions.addFormatting(this.point.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator);
                                    return datalabelprefix + num + datalabelsuffix;
                                } else {
                                    return datalabelprefix + this.point.y + datalabelsuffix;
                                }
                            }
                        }
                    },
                    events: {
                        legendItemClick: function () {
                            if (chartType === "3dbar") {
                                chart.zoomOut();
                            }
                            var sisterSeries = this.chart.get(this.userOptions.id);
                            if (sisterSeries.linkedSeries == undefined) { return; }
                            else { var that = this; setTimeout(function () { if (that.visible) { } else { sisterSeries.linkedSeries[0].show(); } }, 10); }
                        }
                    }

                },
            },
            navigator: {
                series: {
                    color: colorThemesArray[colorThemeIndex][0],
                    lineColor: colorThemesArray[colorThemeIndex][0]
                }
            },
            boost: {
                enabled: (isMLReport != undefined) ? isMLReport : false
            },
            series: seriesOptions,
            colors: colorThemesArray[colorThemeIndex],
            exporting: {
                enabled: false,
                buttons: {
                    contextButton: {
                        symbol: 'download',
                        symbolFill:"#fff",
                        symbolStrokeWidth:0,
                        theme:{
                            fill:'#ffffff',
                            padding:5,
                            stroke:'none',
                            cursor:'pointer'
                        },
                        menuItems: [{
                            textKey: 'printChart',
                            onclick: function () {
                                //console.log("printchart");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart printed"
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.print();
                            }
                        }, {
                            separator: true
                        }, {
                            textKey: 'downloadPNG',
                            onclick: function () {
                                //console.log("downloadPNG");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart downloaded in PNG format"
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart();

                            }
                        }, {
                            textKey: 'downloadJPEG',
                            onclick: function () {
                                //console.log("downloadJPEG");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart downloaded in JPEG format";
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart({
                                    type: 'image/jpeg'
                                });
                            }
                        }, {
                            separator: true
                        }, {
                            textKey: 'downloadPDF',
                            onclick: function () {
                                //console.log("downloadPDF");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart downloaded in PDF format";
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart({
                                    type: 'application/pdf'
                                });
                            }
                        }, {
                            textKey: 'downloadSVG',
                            onclick: function () {
                                //console.log("downloadSVG");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart downloaded in SVG format";
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart({
                                    type: 'image/svg+xml'
                                });
                            }
                        }]

                    }
                },
                chartOptions: {
                    chart: {
                        width: 800,
                        backgroundColor: bgcolor
                    }
                }
            },
            credits: {
                enabled: false
            }
        };
        if (legendExceedDataInfo) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage("BirdWarning110"));
            /*options.subtitle={text:"Series Exceeds"}; 
            options.subtitle.style={color:"red"} */
        }
        if ($('.timeseriesrealtimedatabuttons.hide[data-num=' + chartnumber + ']').length != "1" && $('.realtimedatatoggle[data-num=' + chartnumber + ']').is(':checked') == true) {
            options.rangeSelector.inputEnabled = false;
            options.rangeSelector.selected = 0;
            options.rangeSelector.buttons = [{
                count: 1,
                type: 'minute',
                text: '1M'
            }, {
                count: 5,
                type: 'minute',
                text: '5M'
            }, {
                count: 15,
                type: 'minute',
                text: '15M'
            }, {
                type: 'all',
                text: 'All'
            }]
        }

        var dataclone = data;
        var refdata = data;
        var referenceLineMap = {}; //This var is only when legend is applied
        if (legend != undefined && legend.columnName.length > 0) {
            categories = data[0];
            var tempdata = [];
            var drilldowndata = [];
            drilldowndata.push($.extend({}, data[0]));
            sessionStorage.setItem("drilldowndata" + chartId.split("chart")[1], JSON.stringify(drilldowndata));
            if (data.length > 1) {
                _.each(data, function (i, j) {
                    if (trendstats != "trendstats") {
                        if (j & 1 == 1) {
                            if (data[j][i.length - 1] != undefined) {
                                referenceLineMap[data[j][i.length - 1].yaxisname] = data[j][i.length - 1].avgValue;
                                tempdata = tempdata.concat(data[j]);
                            }
                        }
                    }
                });
                if (trendstats == "trendstats") {
                    for (var c = 1; c < dataclone.length; c = c + 3) {
                        if (data[c] != undefined) {
                            tempdata = tempdata.concat(dataclone[c]);
                        }
                    }
                }
                refdata = tempdata;
            } else {
                refdata = data[1];
            }
        }

        //reference lines
        var plotLine_value = [];
        if (refdata != undefined && refdata[0] != undefined && refdata[0].referenceLineObj != undefined
            && refdata[0].referenceLineObj != null && Object.keys(refdata[0].referenceLineObj).length > 0) {
            if (Object.keys(refdata[0].referenceLineObj).length > 0 && chartSettings['referenceLine'] == true) {
                _.each(refdata[0].referenceLineObj, function (value, index) {
                    var temp = {};
                    temp['value'] = value.referenceValue;
                    temp['color'] = value.referenceColor
                    temp['dashStyle'] = value.referenceStyle;
                    temp['width'] = 2;
                    temp['zIndex'] = 4;
                    if (value.agg != undefined) {
                        temp['label'] = { "text": value.referenceLabel + "[" + value.agg + ":" + temp['value'] + "]" };
                    } else {
                        temp['label'] = { "text": value.referenceLabel + "[" + temp['value'] + "]" };
                    }
                    plotLine_value.push((temp['value']));
                    if (options.yAxis[0] != undefined) {
                        options.yAxis[0].plotLines[index] = temp;
                    } else {
                        options.yAxis.plotLines[index] = temp;
                    }
                });
                var reference_maxValue = Math.max.apply(null, plotLine_value);
                var reference_minValue = Math.min.apply(null, plotLine_value);
            }
        }

        //options.exporting.enabled = true;
        if (isExportChartEnable) {
            options.exporting.enabled = true;
        }
        if ($(window).width() < 780) {
            options.chart.zoomType = 'none';
        }
        if (chartType === 'linetimeseries') {
            options.chart.defaultSeriesType = "spline";
            options.navigator.series.type = "spline";
        } else if (chartType === 'areatimeseries') {
            options.chart.defaultSeriesType = "areaspline";
            options.navigator.series.type = "areaspline";
        } else if (chartType === 'bartimeseries') {
            options.chart.defaultSeriesType = "column";
            options.navigator.series.type = "column";
        }
        var newseries = {
            name: "",
            data: [],
            gapSize: 5,
            tooltip: {
                valueDecimals: 2
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                },
                /*stops : [ [ 0, "#57c17b" ], // [0,
                // Highcharts.getOptions().colors[0]],
                [ 1, "#006e8a" ],// [1,
                // Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                ]*/
                stops: [
                    [0, colorThemesArray[colorThemeIndex][0]],
                    [1, colorThemesArray[colorThemeIndex][1]]
                ]
            }
        };
        if (legend != undefined && legend.toString().length > 0
            && legend.columnName != undefined
            && legend.columnName.length > 0) {

            options.series.splice(0);
            var i = 1;
            _.each(data_multiple, function (d, index) {
                _.each(d, function (ii, jj) {
                    var tempSeries = _.clone(newseries);
                    tempSeries.name = data[i][jj].name;
                    tempSeries.data = ii;
                    tempSeries.aggType = seriesDataOptions[i].aggType;
                    options.series.push(tempSeries);
                })
                if (trendstats == undefined) {
                    i = i + 2;
                } else {
                    i = i + 3;
                }
            });
        }
        if ($(window).width() < 780) {
            options.chart.zoomType = 'none';
        }
        /*-------Chart Settings------*/
        if (chartSettings['legend'] == true) {
            options.legend.enabled = true;
        } else {
            options.legend.enabled = false;
        }
        if (chartSettings['xAxisLabel'] == true) {
            options.xAxis[0].title.text = xaxisName;
            options.xAxis[0].title.displayname = xaxisName;
        } else {
            options.xAxis[0].title.displayname = xaxisName;
            options.xAxis[0].title.text = xaxisName;
            options.xAxis[0].title.enabled = false;
        }
        if (chartSettings['yAxisLabel'] == true) {
            //options.yAxis.title.text= yaxisnameArray;
            setTimeout(function () {
                $('#' + chartId + ' .highcharts-yaxis-title').empty();
                $('#' + chartId + ' .highcharts-yaxis-title').css("left", "+=10");
                $.each(yaxisnameArray, function (i) {
                    if (i < 100) {
                        $('#' + chartId + ' .highcharts-yaxis-title').append("<span class='yaxisitem' title='" + yaxisnameArray[i] + "'>" + yaxisnameArray[i] + "</span>");
                    } else {
                        $('#' + chartId + ' .highcharts-yaxis-title').append("<span class='yaxis-extra-item' title='" + yaxisnameArray.splice(3) + "'>&nbsp;&nbsp;...</span>");
                        return false;
                    }
                });
            }, 500);
        } else {
            options.yAxis[0].title.text = null;
        }
        if (chartSettings['crossHairs'] == true) {
            options.tooltip.crosshairs = [{
                width: 2,
                color: 'gray',
                dashStyle: 'shortdot'
            }, {
                width: 2,
                color: 'gray',
                dashStyle: 'shortdot'
            }];
        } else {
            options.tooltip.crosshairs = false;
        }
        if (chartSettings['gridLine'] == true) {
            options.yAxis.gridLineWidth = 1;
        } else {
            options.yAxis.gridLineWidth = 0;
        }
        if (chartSettings['dataLabel'] == true) {
            options.plotOptions.series.dataLabels.enabled = true;
        } else {
            options.plotOptions.series.dataLabels.enabled = false;
        }

        /*Technical indicators*/
        var currenttrend = $('.trendtype.showactive[data-num=' + chartnumber + ']').length;
        if (currenttrend == 0) {
            currenttrend = $('.singlereport_trendtype.showactive[data-num=' + chartnumber + ']').length;
        }
        if (currenttrend != 0 || algorithm != undefined) {
            //remove trend applied previously 
            if (algorithm === "linear") {
                data = $.grep(data, function (e) { return e.algorithm != "SMA" && e.algorithm != "EMA" && e.iscalculationseries != true; });
            } else if (algorithm === "SMA") {
                data = $.grep(data, function (e) { return e.algorithm != "linear" && e.algorithm != "EMA" && e.iscalculationseries != true; });
            } else if (algorithm === "EMA") {
                data = $.grep(data, function (e) { return e.algorithm != "linear" && e.algorithm != "SMA" && e.iscalculationseries != true; });
            }

            if (algorithm != null && algorithm != undefined && algorithm.length > 0) {
                //apply the period value
                if (periodsvalue == undefined) {
                    if (algorithm === "SMA") {
                        periodsvalue = parseInt($('.smaperiods[data-num=' + chartnumber + ']').val());
                    } else if (algorithm === "EMA") {
                        periodsvalue = parseInt($('.emaperiods[data-num=' + chartnumber + ']').val());
                    } else {
                        periodsvalue = 1;
                    }
                }
                //Get Series Length
                var k = data.length
                //Add unique id for each series
                _.each(data, function (i, j) {
                    if (data[j].linkedTo == undefined) {
                        data[j].id = 'trend' + j;
                        options.series[j].id = 'trend' + j;
                    }

                });

                //Add Trend indicator series
                _.each(data, function (i, j) {
                    var algorithmValue;
                    if (data[j].linkedTo == undefined) {
                        var sid = data[j].id;
                        if (algorithm === "linear") {
                            var algorithmname = "Linear";
                            algorithmValue = "linearRegression";
                        } else {
                            var algorithmname = algorithm;
                            algorithmValue = algorithm.toLowerCase();
                        }
                        options.series[k] = {
                            name: algorithmname + ' ',
                            linkedTo: sid,
                            showInLegend: true,
                            enableMouseTracking: false,
                            type: algorithmValue,
                            params: {
                                period: periodsvalue,
                            },
                            color: colorThemesArray[colorThemeIndex][k]

                        };
                        k++;
                    }
                });
            }
            options.series = seriesOptions;
        }
        if (trendstats != undefined) {
            $('.trend-stats-container').remove();
            $('#' + chartId).before('<div class="trend-stats-container"><div class="stats-title">'+lang["storyboard.chartstatus"]+'</div><div class="deletetrendstats"><i class="fa fa-times-circle"></i></div><table style="width:100%" class="trendstatstable table table-bordered" data-num=' + chartnumber + '><thead><tr><th>'+lang["storyboard.aggtype"]+'</th></tr></thead><tbody><tr><td>'+lang["storyboard.countcap"]+'</td></tr><tr><td>'+lang["storyboard.count(d)"]+'</td></tr><tr><td>'+lang["storyboard.sumcap"]+'</td></tr><tr><td>'+lang["storyboard.maxcap"]+'</td></tr><tr><td>'+lang["storyboard.mincap"]+'</td></tr><tr><td>'+lang["storyboard.avgcap"]+'</td></tr><tr><td>'+lang["storyboard.stddevcap"]+'</td></tr><tr><td>'+lang["storyboard.percentilecap"]+'</td></tr><tr><td>'+lang["storyboard.mediancap"]+'</td></tr></tbody></table><div>');
            _.each(trendstatsdata, function (data, k) {
                var count = 0;
                $.each(yaxisnameArray, function (m) {
                    var name = yAxisOriginalNameArray[m];
                    var types = Object.keys(data);
                    if (types.indexOf(name) >= 0 && count == 0) {
                        $('.trendstatstable[data-num=' + chartnumber + '] thead tr').append('<th>' + yaxisnameArray[m] + '</th>');
                        if (data[yAxisOriginalNameArray[m]] != undefined && data[yAxisOriginalNameArray[m]] instanceof Array) {
                            if (data[yAxisOriginalNameArray[m]][2] == "customaggregatedfield") {
                                var j = 0;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td> NA </td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td> NA </td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td> NA </td>');
                                j = j + 1
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td> NA </td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td> NA </td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td> NA </td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td> NA </td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td> NA </td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td> NA </td>');
                                count++;
                            }
                            else {
                                var j = 0;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td class="align-right">' + data[yAxisOriginalNameArray[m]][0]["countall"] + '</td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td class="align-right">' + data[yAxisOriginalNameArray[m]][0]["countdistinct"] + '</td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td class="align-right">' + data[yAxisOriginalNameArray[m]][0]["sum"] + '</td>');
                                j = j + 1
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td class="align-right">' + data[yAxisOriginalNameArray[m]][0]["max"] + '</td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td class="align-right">' + data[yAxisOriginalNameArray[m]][0]["min"] + '</td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td class="align-right">' + data[yAxisOriginalNameArray[m]][0]["average"] + '</td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td class="align-right">' + data[yAxisOriginalNameArray[m]][0]["standarddeviation"] + '</td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td class="align-right">' + data[yAxisOriginalNameArray[m]][0]["percentile"] + '</td>');
                                j = j + 1;
                                $('.trendstatstable[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td class="align-right">' + data[yAxisOriginalNameArray[m]][0]["median"] + '</td>');
                                count++;
                            }

                        }

                    }
                });
            });
            $('.trend-stats-container').draggable({ containment: 'window' });

        }
        //Conditional Formating
        var conditionalFormatData = [];
        if (ChartInfo.functions.getConditionalFormatData(chartnumber) != undefined) {
            conditionalFormatData = ChartInfo.functions.getConditionalFormatData(chartnumber);
        }
        if (conditionalFormatData != undefined) {
            _.each(conditionalFormatData, function (conditional, i) {
                var zone2 = [];
                _.each(conditional.conditions, function (i) {
                    var val = i.value;
                    var range = i.range
                    var color = i.color;
                    var obj = {};
                    if (range == "<") {
                        if (val) {
                            obj["color"] = color;
                            obj["value"] = val;
                        }
                    }
                    else {
                        if (val < 1000000) {
                            var maxObj = {};
                            maxObj["color"] = color;
                            maxObj["value"] = 1000000;
                            //obj["color"]="#065e96";
                            obj["value"] = val;
                            zone2.push(maxObj);
                        }
                    }
                    zone2.push(obj);
                });
                zone2.sort(function (a, b) {
                    return parseFloat(a.value) - parseFloat(b.value);
                });

                var seriesList = options.series;
                var seriesObj = seriesList.filter(function (seriesList) {
                    return seriesList.name === conditional.name
                });
                if (seriesObj != undefined && seriesObj.length > 0) {
                    seriesObj[0].zones = zone2;
                }
                //options.plotOptions.series.zones=zone2;
            });
        }
        var datalabelprefix = "", datalabelsuffix = "";
        //Add Advanced Chart Settings
        if (chartSettings["AdvancedChartSettings"] != undefined) {
            var advanced = chartSettings["AdvancedChartSettings"];
            datalabelprefix = advanced.plotOptions.series.dataLabels.dataLabelPrefix;
            datalabelsuffix = advanced.plotOptions.series.dataLabels.dataLabelSuffix;
            $.extend(true, options, advanced);
        }
        if (options.series != null && options.series != undefined) {
            _.each(options.series, function (i) {
                i.turboThreshold = 500000
            })
        }
        var chart = new Highcharts.StockChart(options, function (chart) {
            setTimeout(function () {
                $('.highcharts-range-selector[name="min"]', $(chart.container).parent()).datepicker({
                    minDate: new Date(chart.xAxis[0].dataMin),
                    maxDate: new Date(chart.xAxis[0].dataMax),
                    dateFormat: 'yy-mm-dd',
                    onSelect: function (dateText) {
                        try {
                            this.onchange();
                            this.onblur();
                            $('.highcharts-range-selector.hasDatepicker').css({ width: "1px", height: "1px" })
                        } catch (err) {
                        }
                    }
                });
                $('.highcharts-range-selector[name="max"]', $(chart.container).parent()).datepicker({
                    minDate: new Date(chart.xAxis[0].dataMin),
                    maxDate: new Date(chart.xAxis[0].dataMax),
                    dateFormat: 'yy-mm-dd',
                    onSelect: function (dateText) {
                        try {
                            this.onchange();
                            this.onblur();
                            $('.highcharts-range-selector.hasDatepicker').css({ width: "1px", height: "1px" })
                        } catch (err) {
                        }
                    }
                });
                $('.ui-datepicker').addClass("zindex99999");
            }, 0);
        });
        charts[chartId] = chart;
        this.state.chart = chart;
        $("#" + chartId + " svg>title").remove();
        if (reference_maxValue > 0 && reference_maxValue != null
            && reference_maxValue != undefined
            && reference_maxValue > options.yAxis[0].max) {
            options.yAxis[0].max = reference_maxValue + 0.1;
            //     chart.yAxis[0].update({
            //     max: reference_maxValue + 0.1
            // });
        }
        if (reference_minValue < 0 && reference_minValue != null
            && reference_minValue != undefined
            && reference_minValue < options.yAxis[0].min) {
            options.yAxis[0].min = reference_minValue - 0.1;
            // chart.yAxis[0].update({
            //     min: reference_minValue - 0.1
            // });
        }
        var embedenable = $('.viewembedreportmodal .in').length;
        if (embedenable == 1) {
            setTimeout(function () {
                var chartno = chartId
                chartno = chartno.replace("chart", "");
                var acwidth = $('#' + chartId + ' .highcharts-container').width();

                if ($('.fullscreenmain .maxanim8').length == 1) {
                    var acheight = $('.maxanim1[data-num=' + chartno + ']').height() - 150;
                    $('#' + chartId).append("<img id='imageshot" + chartId + "' class='thumbimage' crossOrigin='Anonymous' style='z-index:9999;display: none;float: left;position: relative;top: 0;left: 0;width:" + acwidth + "px;max-height:" + acheight + "px;' width=" + acwidth + "   '>");
                } else {
                    var acheight = $('.maxanim1[data-num=' + chartno + ']').height() - 50;
                    $('#' + chartId).append("<img id='imageshot" + chartId + "' class='thumbimage' crossOrigin='Anonymous' style='z-index:9999;display: none;float: left;position: relative;top: 0;left: 0;width:" + acwidth + "px;max-height:" + acheight + "px;' width=" + acwidth + "   '>");
                }


                // URL to Highcharts export server
                var exportUrl = '//export.highcharts.com/';

                var thumbchart = chart.options;
                if ($('.fullscreenmain .maxanim8').length == 1) {

                    thumbchart.xAxis[0].labels.style.fontSize = '6px';
                    thumbchart.xAxis[0].title.style.fontSize = '8px';

                    thumbchart.yAxis[0].labels.style.fontSize = "6px";
                    thumbchart.yAxis[0].title.style.fontSize = '8px';

                    thumbchart.legend.itemStyle.fontSize = '6px';

                    setTimeout(function () {
                        thumbchart.xAxis[0].labels.style.fontSize = '11px';
                        thumbchart.yAxis[0].labels.style.fontSize = "11px";
                    }, 100);
                }
                else {

                    thumbchart.xAxis[0].labels.style.fontSize = '11px';
                    thumbchart.xAxis[0].title.style.fontSize = '8px';

                    thumbchart.yAxis[0].labels.style.fontSize = "11px";
                    thumbchart.yAxis[0].title.style.fontSize = '8px';

                    thumbchart.legend.itemStyle.fontSize = '8px';
                }


                // POST parameter for Highcharts export server
                var object = {
                    options: JSON.stringify(thumbchart),
                    type: 'image/png',
                    async: true,
                    width: acwidth,
                    //scale:0
                    //Height: acheight
                };
                $.ajax({
                    type: 'post',
                    url: exportUrl,
                    data: object,
                    success: function (data) {
                        // Update "src" attribute with received image URL
                        $('#imageshot' + chartId).attr('src', exportUrl + data);
                    }
                });
            }, 200);
        }
        $('.deletetrendstats').click(function (e) {
            ChartUtil.deletetrendstats(e);
        });
        return (
            <div style={{ height: "360px" }} ref="chartparent" >
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref="chartComponent"
                />
            </div>
        );
    }
    componentDidMount() {
        var compos = {};
        compos["chartComponent"] = this.refs.chartComponent;
        compos["chartComponent"]["chart"] = this.state.chart;
        compos["chartparent"] = this.refs.chartparent;
        compos["chart"] = this.state.chart;
        compos["chartType"] = this.state.chartType;
        ChartInfo.functions.setChartComponents(this.state.chartnumber, compos);
        if (this.state.chartid != undefined && this.state.chartid == "nlpchart") {
            this.refs.chartComponent.chart.setSize($("#nlpchart").width(),$("#nlpchart").height(),false);
        } else {
            if(this.refs!=undefined && this.refs.chartComponent!=undefined && this.refs.chartComponent.chart!=undefined){
                ChartUtil.charts[this.state.chartid]=this.refs.chartComponent.chart;
            }
            //ChartUtil.modifyChartHeightOnResize(this.state.chartnumber);
        }
    }
}