import React, { useEffect, useState } from "react";
import _ from "lodash";
import { FiltersTab} from "./filterstab";
import ReactDOM from "react-dom";
import * as camapaignCollection from "./campaignCollection";
import * as ChartInfo from "../js/helpers/utils/chartinfo"
import SendRequest from "../SendRequest";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import WorkspaceAccordian from "../Workspace/WorkspaceAccordian";
import PubSub from 'pubsub-js';
import download from "downloadjs";
const $ = window.$;
export const CampaignFormHtml = (props) => {
    const { allColumns, columns, isCustomQuery, entityName, tableName, fetchData, campaign_edit_data, connId, entityId, indexName } = props;
     
    const closewindow = () => {
        if (document.getElementById("createcampaignform") != null) {
            $('#createcampaignform').remove();
        }
    }
    const [isLoading,setISLoading] = useState(true)
    let iskeyup = false;
    let selectText = "";
    const setIsKeyUp = (bool) => {
        iskeyup = bool;
    }
    const getKeyUP = () => {
        return iskeyup;
    }
    const setSelectText = (text) => {
        selectText = text;
    }
    const getSelectText = () => {
        return selectText;
    }
   
    useEffect(() => {
        $(".spinner").show();
        let idval = 0;
        if (campaign_edit_data) {
            let { id } = campaign_edit_data;
            idval = parseInt(id)
        }
        ChartInfo.functions.resetEditFilterArray();
        camapaignCollection.resetCollection();
        let getrequestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getUniversalTemplates",
            queryString: "",
            sucessFunction: response => {
                setISLoading(false);
                $(".spinner").hide();
                if (response.length > 0) {
                    $(".select-template").show();
                }
                const steps =$("#campaignstepscontainer").steps({
                    headerTag: "h3",
                    bodyTag: "section",
                    enableKeyNavigation: false,
                    transitionEffect: "slideLeft",
                    autoFocus: true,
                    labels: { finish: 'Save' },
                    enableCancelButton: true,
                    onStepChanging: (event, currentIndex, newIndex) => {
                        if (currentIndex == "0" && newIndex == "1") {
                            let campaignType = $('input[type="radio"][name="campaign-type"]:checked').attr('data-value');
                            camapaignCollection.updateCampaignType(campaignType)
                            return true;
                        }
                        else if (currentIndex == "1" && newIndex == "2") {
                            //Height calculate
                            let filter_data = ChartInfo.functions.getEditFilterArray();
                            camapaignCollection.updateFilter(filter_data);
                            var textChartHeight = "calc(100vh - 540px)"
                            $("#campaignForm").summernote('destroy')
                            $("#campaignForm").summernote({
                                height: textChartHeight,
                                focus: false,
                                disableDragAndDrop: true,
                                toolbar: [
                                    ['style', ['style']],
                                    ['font', ['bold', 'italic', 'underline', 'clear']],
                                    ['fontname', ['fontname']],
                                    ['color', ['color']],
                                    ['para', ['ul', 'ol', 'paragraph']],
                                    ['height', ['height']],
                                    ['table', ['table']],
                                    ['insert', ['link', 'picture', 'hr']],
                                    ['view', ['codeview']],
                                ]
                            })
                            let campaignType = $('input[type="radio"][name="campaign-type"]:checked').attr('data-value');
                            let resultFilter = response.filter(val => val.category === campaignType.toLowerCase());
                            let options = `<option value="Select Template" data-value="Select Template">Select Template</option>`
                            resultFilter.forEach(val => {
                                let { category, content, name } = val
                                options += `<option value="${name}" data-value="${name}">${name}</option>`
                            })
                            if(campaignType && campaignType.toLowerCase() == "letter"){
                                $(".campaignpreviewdownload").show();
                            }else{
                                $(".campaignpreviewdownload").hide();
                            }
                            $(".campaigntemplateselect").html(options);
                            $('#campaignForm').summernote('code', `<p><br></p>`);
                            $('#campaignForm').on('summernote.keyup', () => { camapaignCollection.udateContent($('#campaignForm').summernote('code')); setIsKeyUp(true) })
                            //changes for Edit start
                            let { content, campaign_name, selectedTemplate } = camapaignCollection.getAllData();
                            if(!content && !getKeyUP()){
                                let filterval = resultFilter.filter(val => val.name == selectedTemplate);
                                if (filterval && filterval.length > 0) {
                                        let { content } = filterval[0];
                                        $('#campaignForm').summernote('code', content)
                                }
                            }
                            if (content) {
                                $('#campaignForm').summernote('code', content);
                            }
                            if (campaign_name) {
                                $("input[type='text'].campaign-name").val(campaign_name);
                            }
                            if (selectedTemplate) {
                                $(".campaigntemplateselect option[value='" + selectedTemplate + "']").prop("selected", true);
                            }
                            //changes for Edit End
                            let initial_value = $(".campaigntemplateselect option:selected").attr('data-value');
                            setSelectText(initial_value);
                            camapaignCollection.updateSeletedTemplate(initial_value);
                            $(".campaigntemplateselect").change(e => camapaintemplateChange(e, resultFilter))
                        }
                        return true;
                    },
                    onCanceled: closewindow,
                    onFinishing: function (event, currentIndex) {
                        let content = $('#campaignForm').summernote('code');
                        camapaignCollection.udateContent(content);
                        return true;
                    },
                    onFinished: function (event, currentIndex) {
                        let requestHeaders = {
                            method: "post",
                            headers: new Headers({
                                "Content-Type": "application/json"
                            })
                        };
                        let meta_data = camapaignCollection.getAllData();
                        meta_data["name"] = $("input[type='text'].campaign-name").val();
                        meta_data["entity_id"] = entityId;
                        meta_data["index"] = indexName;
                        meta_data["selected_template"] = $(".campaigntemplateselect option:selected").attr('data-value');
                        meta_data["entity_name"] = entityName;
                        let formdata = new FormData();
                        formdata.set('id', idval);
                        formdata.set('metadata', JSON.stringify(meta_data));
                        if (!meta_data["name"]) {
                            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Warning');
                            $('.birdmessage-info').empty().text(getMessage('BirdWarning162'));
                            return false;
                        }
                        SendRequest({
                            url: "saveOrUpdateCampaignData",
                            body: formdata,
                            sucessFunction: resp => {
                                if (resp != null && resp.hasOwnProperty('error')) {
                                    $(".spinner").hide();
                                    $(".gridspinner").hide();
                                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                    $('.birdmessagebuttons').removeClass('show');
                                    $('.birdmessagebuttons').addClass('hide');
                                    $('.birdmessage-center').addClass("message-top-in");
                                    $('.details').removeClass('hide');
                                    $('.birdmessage h2').empty().append('Error : ');
                                    $('.birdmessage h2').append('<span class="errorcode">');
                                    $('.birdmessage .errorcode').empty().append(resp.errorCode);
                                    $('.birdmessage-info').empty().text(resp.error);
                                    $('.details').addClass('show');
                                    $('.messagedetails xmp').empty().append(resp.errorTrace)
                                    return false
                                } else {
                                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                                    $('.birdmessagebuttons').removeClass('show');
                                    $('.birdmessagebuttons').addClass('hide');
                                    $('.birdmessage-center').addClass("message-top-in");
                                    $('.details').removeClass('show');
                                    $('.details').addClass('hide');
                                    $('.birdmessage h2').empty().append('Success');
                                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess72'));
                                    setTimeout(function () {
                                        $('.birdmessage-container .close').click();
                                    }, 3000);
                                }
                                closewindow()
                                props.fetchCompaignData()
                                props.history.push(`/${window.localStorage.getItem("appName")}/welcome/campaign/` + btoa(entityId))
                            },
                            rejectFunction: () => { },
                            headers: requestHeaders
                        })
                    }
                })
                
                ReactDOM.render(<FiltersTab allColumns={allColumns} columns={columns} isCustomQuery={isCustomQuery} entityName={entityName} tableName={tableName}
                    fetchData={fetchData} connId={connId} entityId={entityId} {...props} />, document.getElementById("campaignFilters"))
                    $("#campaignpreviewdownload").click(e=>previewDownload(e));
                if (campaign_edit_data) {
                    //changes for Edit start
                    let { name, category, content, filters, selected_template } = campaign_edit_data;
                    $('input[type="radio"][name="campaign-type"][data-value="' + category + '"]').prop('checked', true);
                    camapaignCollection.updateCampaignType(category)
                    if (filters) {
                        let parsedFilters = JSON.parse(filters);
                        camapaignCollection.updateFilter(parsedFilters);
                        ChartInfo.functions.setEditFilterArray(parsedFilters);
                        appendEditFilters(parsedFilters);
                        
                    }
                    camapaignCollection.udateContent(content);
                    camapaignCollection.updateCampaignName(name);
                    camapaignCollection.updateSeletedTemplate(selected_template);

                    for(let i=1;i<=2;i++){
                        $("#campaignstepscontainer").steps('next');
                    }
                    //changes for Edit End
                }
            }, rejectFunction: () => { },
            headers: getrequestHeaders
        });


    }, [])
    const camapaintemplateChange = (e, data) => {
        e.stopImmediatePropagation();
        let value = $(".campaigntemplateselect option:selected").attr('data-value');

        if (getKeyUP()) {
            $(".deleteyes").unbind("click");
            $(".deleteno").unbind("click");
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').addClass('show');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning166'));
            $(".deleteyes").click(() => {
                setIsKeyUp(false);
                $('.birdmessage-center').removeClass("message-top-in");
                $(".campaigntemplateselect option[value='" + value + "']").prop("selected", true);
                setSelectText(value);
                camapaignCollection.updateSeletedTemplate(value);
                if (value == "Select Template") {
                    $('#campaignForm').summernote('code', `<p><br></p>`);
                    camapaignCollection.udateContent(`<p><br></p>`)
                    return false;
                }
                let filterval = data.filter(val => val.name == value);
                if (filterval && filterval.length > 0) {
                    let { content } = filterval[0];
                    $('#campaignForm').summernote('code', content)
                    camapaignCollection.udateContent(content)
                }
            });
            $(".deleteno").click(function () {
                $('.birdmessage-center').removeClass("message-top-in");
            });

            let textval = getSelectText();
            $(".campaigntemplateselect option[value='" + textval + "']").prop("selected", true);
            camapaignCollection.udateContent( $('#campaignForm').summernote('code'));
            return false;
        }

        setSelectText(value);
        camapaignCollection.updateSeletedTemplate(value);
        if (value == "Select Template") {
            $('#campaignForm').summernote('code', `<p><br></p>`);
            camapaignCollection.udateContent(`<p><br></p>`)
            return false;
        }
        let filterval = data.filter(val => val.name == value);
        if (filterval && filterval.length > 0) {
            let { content } = filterval[0];
            $('#campaignForm').summernote('code', content)
            camapaignCollection.udateContent(content)
        }
    }
    const appendEditFilters = (filtersData)=>{
        if (filtersData !== undefined) {
            _.each(filtersData, (val, index) => {
                var type = val.dataType;
                var tableName = val.tablename;
                var columnName = val.columnname;
                var columnDisplayName = val.columndisplayname;
                $('#filteraccordion').append("<div data-accordion-id="+'accordion'+index+"' id=" + 'accordion' + index + "></div>");
                ReactDOM.render(
                    <WorkspaceAccordian
                        index={index}
                        isCustomQuery={isCustomQuery}
                        tableName={tableName}
                        columnName={columnName}
                        columnDisplayName={columnDisplayName}
                        columnType={type}
                        entityId={entityId}
                        entityName={entityName}
                        fromrange={val.fromrange}
                        torange={val.torange}
                        filtergreaterthanarequalvalue={val.filtergreaterthanarequalvalue}
                        filterlessthanarequalvalue={val.filterlessthanarequalvalue}
                        filterArray={filtersData}
                        id={columnDisplayName}
                        filterObject={''}
                        script={null}
                        connId={connId}
                        daterange={undefined}
                        customFields={"[]"}
                        entitycollection ={ []}
                        applyeditworkspacefilter={applyworkspacefilter}
                        editdeleteaccordian={editdeleteaccordian}
                        collapseOther={collapseOther}
                    />,
                    document.getElementById(`accordion${index}`));
            });
            $(".applyworkspacefilters").removeClass("disabled");
            $("#workspaceitemsfiltermodal").modal("hide");
           
        }
    }
    const applyworkspacefilter = (e) => {
        e.stopPropagation();
        let event_data = {};
        event_data["currentTarget"] = e.currentTarget
        PubSub.publish('applyworkspacefilter', event_data);
    }
    const editdeleteaccordian = (e) => {
        e.stopPropagation();
        let event_data = {};
        event_data["currentTarget"] = e.currentTarget
        PubSub.publish('editdeleteaccordian', event_data);
    }
    const collapseOther = (e) => {
        e.stopPropagation();
        let event_data = {};
        event_data["currentTarget"] = e.currentTarget
        PubSub.publish('collapseOther', event_data);
    }
    const previewDownload = (e) =>{
        let content = $('#campaignForm').summernote('code');
        var data = new FormData();
        data.append("id", 0);
        data.append("candidatename", "");
        data.append("content",content);
        SendRequest({
            url: "exportLetters",
            queryString: "",
            body: data,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.error)
                    setTimeout(function () {
                        $('.birderror').remove();
                    }, 3000);
                }else {
                    // that.plotDataTable(response);
                    download(response, "letters.pdf", "application/pdf");
                }
            } ,rejectFunction: (error) => { $(".spinner").hide(); },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        }); 
    }
    return (
        <div className="container-fluid marg-top-20 customformpage">
            {isLoading?
            <h1 id="editdatapreviewempty" style={{ textAlign: 'center', color: '#212121' }}>Loading</h1>:
            <div id="campaignstepscontainer">
                <h3>Message Channel</h3>
                <section>
                    <div id="campaigntypeselection">
                        <div className="col-xs-12" id="customforms">
                            <form id="survey1">
                                <div className="col-xs-12">
                                    <div className="form-group vertical">
                                        <label>
                                            <h3 className="name-label">What do you like to select?</h3>
                                        </label>
                                        <div className="form-group">
                                            <label>
                                                <input className="form-control" name="campaign-type" type="radio" data-value="Letter" defaultChecked /> Letter
                                            </label>
                                        </div>
                                        <div className="form-group surveychoice">
                                            <label>
                                                <input className="form-control" name="campaign-type" type="radio" data-value="Email" /> Email
                                            </label>
                                        </div>
                                        <div className="form-group surveychoice">
                                            <label>
                                                <input className="form-control" questionid="2" name="campaign-type" type="radio" data-value="Whatsapp" /> Whatsapp
                                            </label>
                                        </div>
                                        <div className="form-group surveychoice">
                                            <label>
                                                <input className="form-control" questionid="2" name="campaign-type" type="radio" data-value="SMS" /> SMS
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="stepspagination">
                        <p>Step 1 of 3</p>
                    </div>
                </section>
                <h3>Voter Audience</h3>
                <section>
                    <div id="campaignFilters">
                    </div>
                    <div className="stepspagination">
                        <p>Step 2 of 3</p>
                    </div>
                </section>
                <h3>Message Area</h3>
                <section>
                    <div id="campaignFormContainer">
                        <div className="col-xs-12 nopadding">
                            <div className="form-group vertical">
                                <label className="select-template" style={{ display: 'none' }}>
                                    <h4 className="name-label">Select a template</h4>
                                </label>
                                <div className="form-group select-template" style={{ display: 'none' }}>
                                    <select className="form-control campaigntemplateselect" value="" data-width="25">


                                    </select>
                                </div>
                                <label>
                                    <h4 className="name-label">Message Title</h4>
                                </label>
                                <div className="form-group">
                                    <input type="text" className="form-control campaign-name" defaultValue={""} data-width="25" />
                                </div>
                                <div className="col-xs-12 col-lg-6 col-lg-offset-6 nopadding marg-bot-10">
                                    <button className="btn btn-bird active pull-right campaignpreviewdownload " id="campaignpreviewdownload"><i className="fa fa-download"></i> Download Preview</button>
                                </div>
                            </div>
                        </div>
                        <div id="campaignForm"></div>
                    </div>
                    <div className="stepspagination">
                        <p>Step 3 of 3</p>
                    </div>
                </section>
            </div>}
        </div>
    )

}
