export var messagesMap = {};
messagesMap["BirdSuccess01"]= "Report Saved Successfully";
messagesMap["BirdSuccess02"]= "Report Cloned Successfully";
messagesMap["BirdSuccess03"]= "Report Shared Successfully";
messagesMap["BirdSuccess04"]= "Message  has been sent Successfully";
messagesMap["BirdSuccess05"]= "Report Scheduled Successfully";
messagesMap["BirdSuccess06"]="User Created Successfully";
messagesMap["BirdSuccess07"]="User Updated Successfully";
messagesMap["BirdSuccess08"]="User Deleted Successfully";
messagesMap["BirdSuccess09"]="Group Created Successfully";
messagesMap["BirdSuccess10"]="Group Updated Successfully";
messagesMap["BirdSuccess11"]="Group Deleted Successfully";
messagesMap["BirdSuccess12"]="Role Created Successfully";
messagesMap["BirdSuccess13"]="Role Updated Successfully";
messagesMap["BirdSuccess14"]="Role Deleted Successfully";
messagesMap["BirdSuccess15"]="Scope Saved Successfully";
messagesMap["BirdSuccess16"]="Scope Edited Successfully";
messagesMap["BirdSuccess17"]="Scope Assigned to User(s) Successfully";
messagesMap["BirdSuccess18"]="LDAP Configuration Details Saved Successfully";
messagesMap["BirdSuccess19"]="LDAP Configuration Details Updated Successfully";
messagesMap["BirdSuccess20"]="LDAP Connection Success";
messagesMap["BirdSuccess21"]="LDAP Configuration Details Deleted Successfully";
messagesMap["BirdSuccess22"]="Users Imported Successfully";
messagesMap["BirdSuccess23"]="Mail Configuration Details Saved Successfully";
messagesMap["BirdSuccess24"]="Mail Configuration Details Updated Successfully";
messagesMap["BirdSuccess25"]="An email has been sent with New Credentials";
messagesMap["BirdSuccess26"]="Scope Deleted Successfully";
messagesMap["BirdSuccess27"]="User Scope Access Deleted Successfully";
messagesMap["BirdSuccess28"]="Account Created Successfully";
messagesMap["BirdSuccess29"]="Account Deleted Successfully";
messagesMap["BirdSuccess30"]="Account Updated Successfully";
messagesMap["BirdSuccess31"]="Expression is Valid and Created Successfully";
messagesMap["BirdSuccess32"]="Expression is Valid";
messagesMap["BirdSuccess33"]="Report Shared/Unshared Successfully";
messagesMap["BirdSuccess34"]="License Details Updated Successfully";
messagesMap["BirdSuccess35"]="Schedule Report Deleted Successfully";
messagesMap["BirdSuccess36"]="XLS Scope Created Successfully";
messagesMap["BirdSuccess37"]="CSV Scope Created Successfully";
messagesMap["BirdSuccess38"]="Password Changed Successfully";
messagesMap["BirdSuccess39"]="License Created Successfully";
messagesMap["BirdSuccess40"]="License has been Renewed Successfully";
messagesMap["BirdSuccess41"]="Connection Success";
messagesMap["BirdSuccess42"]="Report Deleted Successfully";
messagesMap["BirdSuccess43"]="Password has been Reset Successfully";
messagesMap["BirdSuccess44"] = "Sync Initiated. Based on the Volume of Data to be Synced this Process might take Some Time.";
messagesMap["BirdSuccess45"] = "Scope Created Successfully";
messagesMap["BirdSuccess46"] = "Connection Assigned to User(s) Successfully";
messagesMap["BirdSuccess47"]= "Connection Shared Successfully";	
messagesMap["BirdSuccess48"]="Connection Shared/Unshared Successfully";
messagesMap["BirdSuccess49"]="Query Validated Successfully";
messagesMap["BirdSuccess50"]="' * ' symbol is not supported in Oracle / Cassandra / Hive. Please Specify the Columns to Execute the Query.";
messagesMap["BirdSuccess51"]= "Data Updated Successfully";
messagesMap["BirdSuccess52"]="Expression is Valid and Column Created Successfully;";
messagesMap["BirdSuccess53"]="SAML 2.0 Configuration Details Saved Successfully";
messagesMap["BirdSuccess54"]="Password Policy Updated Successfully";
messagesMap["BirdSuccess55"]="Email Disclaimer Message Saved Successfully";
messagesMap["BirdSuccess56"]="Filters Applied Successfully";
messagesMap["BirdSuccess57"]="Entity Saved Successfully";
messagesMap["BirdSuccess58"]="Business Model Saved Successfully";
messagesMap["BirdSuccess59"]="Passwords has been Reset Successfully";
messagesMap["BirdSuccess60"]="Column Names Updated Successfully";
messagesMap["BirdSuccess61"]= "Model Shared/Unshared Successfully";
messagesMap["BirdSuccess62"]= "Storybook Saved Successfully";
messagesMap["BirdSuccess63"]= "Storybook Deleted Successfully";
messagesMap["BirdSuccess64"]= "Data Hub has been Setup Successfully. As a Next Step you can Create/Edit a Business Workspace";
messagesMap["BirdSuccess65"]= "Custom Storyboard Deleted Successfully";
messagesMap["BirdSuccess66"]= "Sync Configuration Updated Successfully";
messagesMap["BirdSuccess67"]= "Report Versioned Successfully";
messagesMap["BirdSuccess68"]="Report Restored Successfully";
messagesMap["BirdSuccess69"]="Business Model Cache has been Cleared Successfully";
messagesMap["BirdSuccess70"]="Data Updated Successfully";
messagesMap["BirdSuccess71"]="We are processing your request and will download your document shortly";
messagesMap["BirdSuccess72"]="Campaign data saved successfully";
messagesMap["BirdSuccess73"]="We are processing your request and will send message as soon as possible";
messagesMap["BirdSuccess74"]="We are processing your request and will send email as soon as possible";


messagesMap["BirdInformation01"]= "Please Save the Report before Performing any Action";
messagesMap["BirdInformation02"]= "Please Save the Report";
messagesMap["BirdInformation03"]="Scope Already Exists for the Selected User(s)";
messagesMap["BirdInformation04"]="The Selected User(s) Already Registered";
messagesMap["BirdInformation05"]="Please Configure Your Mail Settings";
messagesMap["BirdInformation13"]="Maximum Charts Limit Reached";
messagesMap["BirdInformation14"]="Please Select Measures/Dimensions";
messagesMap["BirdInformation15"]="Please Select at least One Connected Sheet to Create Joins (or) You can Click 'Next' to Proceed Further with a Single Primary Sheet";
messagesMap["BirdInformation16"]="Add Column to Fuse Reports";
messagesMap["BirdInformation17"]="Please Select User/Scope"
messagesMap["BirdInformation18"]="Please Select Scope and Users";
messagesMap["BirdInformation19"]="Please Select at least one Column to Generate a Query";
messagesMap["BirdInformation20"]="Please Select Column";
messagesMap["BirdInformation21"]="Please Contact your Admin for the Access";
messagesMap["BirdInformation22"]="Custom Field Name Cannot be Empty";
messagesMap["BirdInformation23"]="Drill-down Feature is NOT Applicable for Date Histograms";
messagesMap["BirdInformation24"]="Please Select Source Report";
messagesMap["BirdInformation25"]="Please Select Target Report";
messagesMap["BirdInformation26"]="Please Select Group by For This Chart Type";
messagesMap["BirdInformation27"]="The Number of Columns Exceeded  to Render a Summary Table";
messagesMap["BirdInformation28"]="A Maximum of 3 Files are Permitted for Upload";
messagesMap["BirdInformation29"]="Please Save the Report Before you Maximize";
messagesMap["BirdInformation30"]= "You have Altered Y-Axis Attributes, Please Make Sure you have Updated your Alerts Accordingly";
messagesMap["BirdInformation31"]= "Please Select at least one report to save the Custom StoryBoard";
messagesMap["BirdInformation32"]= "Custom Measure not Applicable for this Type of Chart";
messagesMap["BirdInformation33"]="Please Select Legend for this Chart Type";
messagesMap["BirdInformation34"]="Connection Already Exists for the Selected User(s)";
messagesMap["BirdInformation35"]="Please Select Connection and Users";
messagesMap["BirdInformation36"]="There is no Matching Column";
messagesMap["BirdInformation37"]="Note: Streaming Applicable for Only Time Series, Metric(Layout 1) and Gauge Charts. While Streaming, Aggregations are not Considered and only RAW Data is Processed.";
messagesMap["BirdInformation38"]="Please Enter Valid Search Criteria (Eg : Sum of Id by Id)";
messagesMap["BirdInformation39"]="You have Modified the Sheets, Please Click 'Auto Join'";
messagesMap["BirdInformation40"]= "In Admin Module Auditing Feature is not Enabled";
messagesMap["BirdInformation41"]= "@Replace does not Exist in Table";
messagesMap["BirdInformation42"]= "You can Choose Two Fields and Select Fields type Longitude and Latitude or one Field(City) in X-Axis";
messagesMap["BirdInformation43"]= "Please Select Fields type as Longitude and Latitude";
messagesMap["BirdInformation44"]= "Joins between Entities is Mandatory before Proceeding Further";
messagesMap["BirdInformation45"]="Date Histogram Feature will be Disabled During Drill-Down";
messagesMap["BirdInformation46"]="Column Names Should not Contain 'Special Characters' and 'Aggregation Names'";
messagesMap["BirdInformation47"]="View Data Feature is NOT Applicable for Date Histograms";
messagesMap["BirdInformation48"]="Categorical Variables does not Exist";
messagesMap["BirdInformation49"]="Numerical Variables does not Exist";
messagesMap["BirdInformation50"]="Please Select Target and Date Columns";
messagesMap["BirdInformation51"]="Please Select Text Column";
messagesMap["BirdInformation52"]="Request has been Sent to Predictive Engine";
messagesMap["BirdInformation53"]="Sankey cannot be Plotted with Zero Values";
messagesMap["BirdInformation54"]="Please Select Date Column";
messagesMap["BirdInformation55"]="Please Select Y Axis";
messagesMap["BirdInformation56"]="Invalid Attributes";
messagesMap["BirdInformation57"]="Please select at least 4 Columns to Run Model";
messagesMap["BirdInformation58"]="Name should not Contain 'Reserved Words'";
messagesMap["BirdInformation59"]="Drill-down Feature is NOT Applicable for Compare Date";
messagesMap["BirdInformation60"]="Same Comparison will be applied to remaining Y-Axis Attributes";
messagesMap["BirdInformation61"]="Please Publish the Report before Performing any Action";
messagesMap["BirdInformation62"]="Please Select Target Variable";
messagesMap["BirdInformation63"]="Please Select Primary Column";
messagesMap["BirdInformation64"]="Please Select Sampling Column";
messagesMap["BirdInformation65"]="Please Select Sampling Criteria Value";
messagesMap["BirdInformation66"]="Please Select Sampling Parameters";


messagesMap["BirdError01"]= "Report Schedule Failed";
messagesMap["BirdError02"]="LDAP Connection Error";
messagesMap["BirdError03"]="Error Occurred While Deleting LDAP Configuration Details. Please Contact your Administrator";
messagesMap["BirdError04"]="Error Occurred while Importing Users";
messagesMap["BirdError06"]=" No Joins Defined ";
messagesMap["BirdError07"]="Add at least One Target Report";
messagesMap["BirdError08"]="Connection Failed";
messagesMap["BirdError09"]="Unable to Process Excel File. As Column Name Contain Special Characters";
messagesMap["BirdError10"]="Unable to Process Excel File, as No Data Exists";
messagesMap["BirdError11"]="The OTP You have Entered is Invalid";
messagesMap["BirdError12"]="Error Parsing Data: Invalid Data";
messagesMap["BirdError13"]="Access Denied: Invalid Filters";
messagesMap["BirdError14"]="Connection Name Should Not be Empty"
messagesMap["BirdError15"]="Range Format is not Supported for String Datatypes";
messagesMap["BirdError16"]="Please wait a bit and try gain.  Please Contact Admin for Further Information.";


messagesMap["BirdWarning01"]="If you Inactivate this Account, all Users under this Account will be Inactivated. Do you want to Proceed?";
messagesMap["BirdWarning02"]="If you Inactivate this Group, all users under this Group will be Inactivated. Do you want to Proceed?";
messagesMap["BirdWarning03"]="Inactivated User Cannot Login";
messagesMap["BirdWarning04"]="If you Inactivate this Role, all users under this Role will be Inactivated;";
messagesMap["BirdWarning05"]="Only one Attribute allowed in Legend;";
messagesMap["BirdWarning06"]="Only Three Y-Axis Attributes allowed for this Chart Type";
messagesMap["BirdWarning07"]="Only Three Y-Axis Attributes allowed for this Chart Type";
messagesMap["BirdWarning08"]="Only One Y-Axis Attribute allowed for this Chart Type";
messagesMap["BirdWarning09"]="This Chart Does not Require a Legend";
messagesMap["BirdWarning10"]="Time Series Charts Requires Date Attribute on X-Axis";
messagesMap["BirdWarning11"]="Area Range Chart Requires Numeric Attribute on X-Axis";
messagesMap["BirdWarning12"]="Bubble Charts Requires Numeric Attribute on X-Axis";
messagesMap["BirdWarning13"]="Chart Does not Require a Legend";
messagesMap["BirdWarning14"]="Time Series Charts Requires Date Attribute on X-Axis";
messagesMap["BirdWarning15"]="Please make sure to Select 'Country' on X-Axis";
messagesMap["BirdWarning16"]="Please make sure to Select 'State' on X-Axis";
messagesMap["BirdWarning17"]="Drill-down is not applicable for this type of Visualization";
messagesMap["BirdWarning18"]="Are you sure you want to Delete Report?";
messagesMap["BirdWarning19"]="If you delete this Group, all users under this Group will be Deleted. Are you sure you want to Delete Group?";
messagesMap["BirdWarning20"]="Are you sure you want to Delete Scope?";
messagesMap["BirdWarning21"]="Are you sure you want to Delete Schedule Report?";
messagesMap["BirdWarning22"]="If you delete this Role, all users under this Role will be Deleted.  Are you sure you want to Delete Role?";
messagesMap["BirdWarning23"]="Are you sure you want to Delete LDAP User?";
messagesMap["BirdWarning24"]="Are you sure you want to Delete Account?";
messagesMap["BirdWarning25"]="Deleted User Cannot Login and all reports under this users will be Deleted. Are you sure you want to Delete User?";
messagesMap["BirdWarning26"]="Are you sure you want to Delete User Scope Access Given?";
messagesMap["BirdWarning27"]="Are you sure you want to Delete Report from Folder?";
messagesMap["BirdWarning28"]="Your Session is Expired. Please Re-login";
messagesMap["BirdWarning29"]="Are you sure you want to Delete Tile?";
messagesMap["BirdWarning30"]="Expression is Invalid ";
messagesMap["BirdWarning31"]="Only Three Attributes are allowed in Columns";
messagesMap["BirdWarning32"]="Only Three Attributes are allowed in Rows";
messagesMap["BirdWarning33"]="Only One Attribute is allowed in Value";
messagesMap["BirdWarning34"]="SELECT' field cannot be blank";
messagesMap["BirdWarning35"]="'FROM' field cannot be blank";
messagesMap["BirdWarning36"]="Make sure your query contains '_id' column.  Check Documentation on how to Construct '_id' Unique Column";
messagesMap["BirdWarning37"]="Make sure your Query Contains 'LIMIT' Keyword with Default 1000 Records";
messagesMap["BirdWarning38"]="Make sure your Query Contains 'TOP' Keyword with Default 1000 Records";
messagesMap["BirdWarning39"]="Make sure your Query Contains 'ROWNUM' Keyword with Default 1000 Records";
messagesMap["BirdWarning40"]="Cannot Issue Empty Query";
messagesMap["BirdWarning41"]="Date Field is Mandatory";
messagesMap["BirdWarning42"]="Only one Attribute allowed in DateField";
messagesMap["BirdWarning43"]="Maximum Upload File Size is 3MB. Please Choose Another File";
messagesMap["BirdWarning44"]="Meta More File Does NOT Contain Unique Key Column. Please Add Unique Key Column to Meta More File";
messagesMap["BirdWarning45"]="Data Limit Size Exceeded Please apply Filters";
messagesMap["BirdWarning46"]="Sorting and Ranking not Aplicable for this type of Chart";
messagesMap["BirdWarning47"]="You have to Select both Sorting and Ranking";
messagesMap["BirdWarning48"]="Make sure your Query Contains LIMIT keyword with Default 1000";
messagesMap["BirdWarning49"]="Make sure your Query Contains TOP keyword with Default 1000";
messagesMap["BirdWarning50"]="Make sure your Query Contains ROWNUM keyword with Default 1000";
messagesMap["BirdWarning51"]="If you Delete this Custom Field, it will be Removed from Filters/ Tile Level Filters. Do you want to Proceed?";
messagesMap["BirdWarning52"]="If you Edit this Custom Field, it will be Removed from Filters/ Tile Level Filters. Do you want to Proceed?";
messagesMap["BirdWarning53"]="Only one attribute allowed in Group By";
messagesMap["BirdWarning54"]="Sheet Names Mismatched. Sheet Names and Column Names should match from Original";
messagesMap["BirdWarning55"]="Column Names Mismatched. Sheet Names and Column Names should match from Original";
messagesMap["BirdWarning56"]="Are you sure you want to Reset Password?";
messagesMap["BirdWarning57"]='Columns with "Empty Headers/Empty Data" will be Ignored';
messagesMap["BirdWarning58"]='Please Select a File with *.CSV, *.TXT, *.TSV Extension Only';
messagesMap["BirdWarning59"]='Please Select a File with *.XLS Extension Only';
messagesMap["BirdWarning60"]='Please Select a File or Remove the Empty Field';
messagesMap["BirdWarning61"]='This will Drop the Existing In-memory Data, and Replace with new Data. Are you sure you want to Force Sync ?';
messagesMap["BirdWarning62"]="You can not Delete this 'Custom Parameter' as it has been used in other 'Custom Fields/Filter'. If you Still want to Delete, then first remove from the 'Custom Fields/Filter' where this 'Custom Parameter' has been Used.";
messagesMap["BirdWarning63"]="Report Link not Available";
messagesMap["BirdWarning64"]="'Measure Names' not Applicable for this Type of Chart";
messagesMap["BirdWarning65"]="In the Hierarchy List, Make sure that 'CustomMeasure Hierarchy' Field is not in First Place";
messagesMap["BirdWarning66"]="Please Select at least ONE X-Axis & Y-Axis values Before Execution";
messagesMap["BirdWarning67"]="The 'Forecast' Function Requires 'DATE' value on X-Axis before Execution";
messagesMap["BirdWarning68"]="'DATE' value on X-Axis not Allowed other than 'Forecast' Function";
messagesMap["BirdWarning69"]="Please Select at least one 'Unique_id'";
messagesMap["BirdWarning70"]="Please click 'Generate' Button to Define Joins";
messagesMap["BirdWarning71"]="Are you sure you want to Delete Connection?";
messagesMap["BirdWarning72"]='Please Select a File with *.JSON Extension Only';
messagesMap["BirdWarning74"]='Please Select Expiry Date';
messagesMap["BirdWarning75"]='Please Select Expiry Time';
messagesMap["BirdWarning73"]='Make sure your Query Contains "_id" Column, to avoid Data Duplication';
messagesMap["BirdWarning76"]='Selected Right Option in Combination Charts List not Allowed for this Chart';
messagesMap["BirdWarning77"]='Are you sure want to Remove Custom Measure Hierarchy';
messagesMap["BirdWarning78"]='Select Reference Line Toggle to Apply Reference Line';
messagesMap["BirdWarning79"]='Enter Values in Reference Line';
messagesMap["BirdWarning80"]='Please Select a File with *.log Extension Only';
messagesMap["BirdWarning81"]='Please Click Browse Log Files Button and Select One Log File';
messagesMap["BirdWarning82"]='Please Select Correct Log type/Check the Syntax';
messagesMap["BirdWarning83"]='Please Check the Credentials';
messagesMap["BirdWarning84"]='Please Check You have Uploaded an Empty File';//This Message is Used in Log Connection and in CSV Connection
messagesMap["BirdWarning85"]='The Selected Aggregation "None" is not Applicable for this Type of Charts';
messagesMap["BirdWarning86"]='Please Select a File with *.CIDF Extension Only';
messagesMap["BirdWarning87"]='Please Make Sure that the Columns Must Match with the Exported Columns';
messagesMap["BirdWarning88"]="Are you Sure you Want to Delete LDAP Configuration?";
messagesMap["BirdWarning89"]='Please Select a File Having the Same Name as Previously Uploaded File';
messagesMap["BirdWarning90"]="Custom Measure Hierarchy is not Applicable for Metric Chart";
messagesMap["BirdWarning91"]="Custom Measure Hierarchy is not Applicable for Gauge Charts";
messagesMap["BirdWarning92"]='Please Fill Proper Column Display Name';
messagesMap["BirdWarning93"]='Column Length should not be greater than 40';
messagesMap["BirdWarning94"]="Imported Table not Exists in your DB/The Dragged Table doesn't Contains the Exported Columns";
messagesMap["BirdWarning95"]="Exceeds Maximum Allowed Upload Size!!";
messagesMap["BirdWarning96"]="Please Select at least one Visible Column";
messagesMap["BirdWarning97"]="One of Existing Report is deleted";
messagesMap["BirdWarning98"]="No Data Available";
messagesMap["BirdWarning99"]="Imported Details doesn't Contains the Exported Columns";
messagesMap["BirdWarning100"]='The Selected Aggregation "Raw" is not Applicable for this Type of Charts';
messagesMap["BirdWarning101"]="Please Select at least two Column Names Values before Execution";
messagesMap["BirdWarning102"]="Please Choose a Model to Test";
messagesMap["BirdWarning103"]="Please Create a Query and Validate";
messagesMap["BirdWarning104"]="Please complete the join process before proceeding further";
messagesMap["BirdWarning105"]="Please select at least one connection to proceed further."
messagesMap["BirdWarning106"]="Value Should be Less than Max Value";
messagesMap["BirdWarning107"]="Value should be Greater than Min Value";
messagesMap["BirdWarning108"]="Value should be between Min value and Max Value";
messagesMap["BirdWarning109"]="Inorder to Define Joins you need to Select more than one Entity";
messagesMap["BirdWarning110"]="Response Exceeds Maximum Number of Series";
messagesMap["BirdWarning111"]="Please Configure @replace Table Values";
messagesMap["BirdWarning112"]="Unable to Load the File. As Column Name Contain Special Characters";
messagesMap["BirdWarning113"]="Only One Attribute Allowed for Range Element";
messagesMap["BirdWarning114"]="Atleast One Chart Tile is required for a Storyboard";
messagesMap["BirdWarning115"]="Please add at least One Range";
messagesMap["BirdWarning116"]='Make sure Your Query Contains "__id" Column, to Avoid Data Duplication';
messagesMap["BirdWarning117"]='While "SAML 2.0" has been Configured for Login, you are not Allowed to Change Password';
messagesMap["BirdWarning118"]='Make sure Columns and Values should not be the Same';
messagesMap["BirdWarning119"]='Searched Criteria was not Found';
messagesMap["BirdWarning120"]="Please Run the Model Before Saving.";
messagesMap["BirdWarning121"]="You Can not Delete this Field Because it has been Existed in one of the Custom Fields.";
messagesMap["BirdWarning122"]="You can not Delete this 'Custom Field' as it has been used in @@fieldType. If you Still want to Delete, then First Remove from the @@fieldType where this 'Custom Field' has been Used.";
messagesMap["BirdWarning123"]='Please Select a File with @replace Extension Only';
messagesMap["BirdWarning124"]='No Matching Columns from Original/Invalid File'
messagesMap["BirdWarning125"]="If You Delete this Entity, It will be Deleted Permanently. Are you sure you want to Proceed?";
messagesMap["BirdWarning126"]="If You Delete this Custom Field, Dependent Custom fields will not Work. Do you want to Proceed?";
messagesMap["BirdWarning127"]="If You Delete this Work Space, Business Models/Storyboards/Reports Saved with this will also be Deleted. Do you want to Proceed?";
messagesMap["BirdWarning128"]='Please Select at least One Filter Value';
messagesMap["BirdWarning129"]="If You Delete this Model, StoryBoards/Reports saved with this will also be Deleted. Do you want to Proceed?";
messagesMap["BirdWarning130"]="Business Model has been Deleted Successfully ";
messagesMap["BirdWarning131"]="Workspace has been Deleted Successfully ";
messagesMap["BirdWarning132"]="If you Delete this Join, It will be Deleted Permanently. Are you Sure you want to Remove this Join?";
messagesMap["BirdWarning133"]="Only one Entity is Allowed to Save with out Joins";
messagesMap["BirdWarning134"]="Please Select at least one Entity";
messagesMap["BirdWarning135"]="Empty Custom Queries are not Allowed";
messagesMap["BirdWarning136"]="Any Unsaved Changes will be Lost, Are you sure you want to Force Sync Workspace?";
messagesMap["BirdWarning137"]="At least One Fact Table Must be Selected in X/Y Axis";
messagesMap["BirdWarning138"]="Atleast One Fact Table Must be Selected";
messagesMap["BirdWarning139"]="Are you sure, you want to Delete this Custom Query?";
messagesMap["BirdWarning140"]="At least one 'Fact Column' is Required in X-Axis or Y-Axis to get Data View. Your Current Selection didn't have any 'Fact Column' Selected.";
messagesMap["BirdWarning141"]="Please Select at least One Entity to Save Workspace";
messagesMap["BirdWarning142"]="To share 'Multi Fact' Data Model, you must select atleast two 'Fact Columns' from two differnt 'Fact Tables'";
messagesMap["BirdWarning143"]="Empty 'Custom Query' Entities Found";
messagesMap["BirdWarning144"]="Are you sure you want to Delete this Tab ?";
messagesMap["BirdWarning145"]="Are you sure you want to Delete this Storybook ?";
messagesMap["BirdWarning146"]="Please Update Entities of New Source Before Proceeding Further.";
messagesMap["BirdWarning147"]="Name Should not Contain 'Reserved Words'";
messagesMap["BirdWarning148"]="Make sure your Query Contains 'id_' Column, to Avoid Data Duplication";
messagesMap["BirdWarning149"]="is a Reserved Word. Please Update the Column Name in your Source"
messagesMap["BirdWarning150"]='Please Select a File with *.jar Extension Only';
messagesMap["BirdWarning151"]='You have Already Selected 1000 entities, which is the Maximum Permissible Limit';
messagesMap["BirdWarning152"]='The Number of Tables that can be Loaded into the Datahub has Exceeded the Maximum Limit(20,000)';
messagesMap["BirdWarning153"]="Are you Sure you Want to Delete this Custom Storyboard ?";
messagesMap["BirdWarning154"]="Are you sure you want to Restore Report?";
messagesMap["BirdWarning155"]="If you inactivate this role, all users under this role will be Inactivated. Do you want to proceed?"
messagesMap["BirdWarning156"]="Inactivated User Cannot Login. Do you want to proceed?";
messagesMap["BirdWarning157"]= "Application Can Save maximum of 100 Tables at a time. User can save in Batches of 100 Tables each time to save more Entities";
messagesMap["BirdWarning158"]= "Are you sure you want to Clear Model Cache?";
messagesMap["BirdWarning159"]= "Please re-apply @replace filter's"
messagesMap["BirdWarning160"]= "Before addding a new filter, make sure to select the filter values and apply";
messagesMap["BirdWarning161"]= "Please answer all mandatory questions";
messagesMap["BirdWarning162"]="Message title should not be empty";
messagesMap["BirdWarning165"]="Are you sure you want to delete campaign data?";
messagesMap["BirdWarning166"]="If you change the template, previous changes will be lost. Do you want to Proceed?";



messagesMap["ValidationMessage1"] ="@replace is Required";
messagesMap["ValidationMessage2"] ="Please Enter Valid @replace";
messagesMap["ValidationMessage3"] ='<p class="joinsuggestion marg-bot-30 marg-top-20 text-center"> No Parameters Found, you can Proceed by Clicking the <span class="fa-dblue">"Next"</span> button.</p>';
messagesMap["ValidationMessage4"] ='<p class="joinsuggestion marg-bot-30 marg-top-20 text-center"> If you want to Define Self-Join Click <span class="fa-dblue">"Create Join"</span> Button, else you can Proceed by Clicking the <span class="fa-dblue">"Next"</span> button.</p>';
messagesMap["ValidationMessage5"] = "Minimum Allowed Value is @replace";
messagesMap["ValidationMessage6"] ="Search Field Cannot be Empty. Please Enter a Value";
messagesMap["ValidationMessage7"] ="HTML Tags are not allowed!";

export function getMessage(message){
	if(messagesMap[message]){
		return messagesMap[message];
	}
	else{
		return '';
	}
}