import * as ChartInfo from "../../../js/helpers/utils/chartinfo";
import _ from 'lodash';
import * as CustomStoryBoardInfo from "../../../js/helpers/utils/customstoryboardinfo";





export var dimensionsArray = [], stringArray = [], dateArray = [], numberArray = [], measuresArray = [], filterDataObject = [], reportsdetails = {};
export var model = {};

export function updateLocalStorage(modeldata, isCustomStoryboard) {
    reportsdetails = {};
    model = modeldata;
    var rawdata = model.reportdata;
    if (rawdata != undefined && rawdata != "") {
        var reportdata = JSON.parse(rawdata);
        var axisData = reportdata[0]["axis"];
        if (reportdata[4] != undefined) {
            var convertedvalues = reportdata[4]["convertedvalues"];
            sessionStorage.setItem("convertedvalues", convertedvalues);
        }
        if (reportdata[5] != undefined) {
            if (reportdata[5] != null && reportdata[5] != "null" && reportdata[5]["customFields"] != undefined && reportdata[5]["customFields"] != null && reportdata[5]["customFields"] != "null") {
                var customFields = reportdata[5]["customFields"];
                sessionStorage.setItem("customFields", customFields);
            }
        }
        if (reportdata[7] != undefined) {
            var customHierarchy = reportdata[7]["customHierarchy"];
            ChartInfo.functions.setcustomhierarchy(JSON.parse(customHierarchy));
        }
        if (reportdata[8] != undefined) {
            var customRange = reportdata[8]["customRange"];
            ChartInfo.functions.setRange(JSON.parse(customRange));
        }
        if (reportdata[9] != undefined) {
            var customMeasure = reportdata[9]["customMeasure"];
            ChartInfo.functions.setCustomMeasureField(JSON.parse(customMeasure));
        }
        if (reportdata[10] != undefined) {
            var customParameter = reportdata[10]["customParameter"];
            ChartInfo.functions.setCustomParameter(JSON.parse(customParameter));
        }
        if (reportdata[12] != undefined) {
            ChartInfo.functions.setMeasureHierarchy(JSON.parse(reportdata[12]["customMeasureHierarchy"]));
        }
        if (reportdata[14] != undefined) {
            _.each(JSON.parse(reportdata[14]["livedatasyncconfig"]), function (value, chartnumber) {
                ChartInfo.functions.setliveDataconfig(chartnumber, value);
            });
        }
        if (reportdata[15] != undefined) {
            if (reportdata[15].hasOwnProperty('timeZoneSettings')) {
                _.each(JSON.parse(reportdata[15]["timeZoneSettings"]), function (value, chartnumber) {
                    ChartInfo.functions.setTimeZoneSettings(chartnumber, value);
                });
            }
            if (reportdata[15].hasOwnProperty('auditMetaDataOptions')) {
                _.each(JSON.parse(reportdata[15]["auditMetaDataOptions"]), function (value, chartnumber) {
                    ChartInfo.functions.setAuditMetaDataOptions();
                });
            }
        }
        if (reportdata[18] != undefined && reportdata[18].hasOwnProperty('usercustomScript')) {
            if (reportdata[18].hasOwnProperty('usercustomScript')) {
                ChartInfo.functions.setUserCustomScript(model.id, reportdata[18]['usercustomScript']);
            }
        }
        if (reportdata[21] != undefined && reportdata[21].hasOwnProperty('tile_filters')) {
            if (reportdata[21]['tile_filters'] != undefined) {
                ChartInfo.functions.updateAllTileLevelFilters(reportdata[21]['tile_filters']);
            }
        }
    }
    if (sessionStorage.getItem("isImportedReport") != "true" || window.location.pathname.indexOf("importreport") < 0) {
        var newreportcustomfilterscript = model.filter_script;
        if (newreportcustomfilterscript != "") {
            //					sessionStorage.setItem("newreportCustomFilters", newreportcustomfilterscript);
        }
        var newreportcustomfieldscript = model.custom_fields_script;
        if (newreportcustomfieldscript != "") {
            //					sessionStorage.setItem("newreportCustomFields", newreportcustomfieldscript);
        }
    }
    var smartInsightsData = model.smartInsightsData;
    if (smartInsightsData != "" && smartInsightsData != null) {
        var parsedSmartInsightsData = JSON.parse(smartInsightsData);
        var smartInsightsObj = parsedSmartInsightsData.smartInsightsObj;
        ChartInfo.functions.setSmartInsightsTargetVal(smartInsightsObj.targetColumns);
        if (smartInsightsObj.ExcludeColumns != null) {
            ChartInfo.functions.setSmartInsightsExcludeValues(smartInsightsObj.ExcludeColumns);
        }
    }
    _.each(axisData, function (axisdetails) {
        var values = {};
        var xaxisData = null;
        if (!Array.isArray(axisdetails["xaxis"])) {
            var tempArray = [];
            tempArray.push(axisdetails["xaxis"]);
            xaxisData = tempArray;
        } else {
            xaxisData = axisdetails["xaxis"];
        }
        var mainreportid = axisdetails["mainreportid"];
        var datamodelId = axisdetails["datamodelId"];
        var currenttabid = axisdetails["tabId"];
        var currenttabname = axisdetails["tabName"];
        var yaxisData = axisdetails["yaxis"];
        var zaxisData = axisdetails["zaxis"];
        var customSolidGauge = axisdetails["customSolidGauge"];
        var tooltipData = axisdetails["tooltip"];
        var chartnumber = axisdetails["chartnumber"];
        var legend = axisdetails["legend"];
        var charttype = axisdetails["chartType"];
        var charttitle = axisdetails["reporttitle"];
        var colLimit = axisdetails["colLimit"];
        var orderType = axisdetails["orderType"];
        var sortType = axisdetails["sortType"];
        var sortingColumn = axisdetails["sortingColumn"];
        var sortingColumnAggregation = axisdetails["sortingColumnAggregation"];
        var rankingColumn = axisdetails["rankingColumn"];
        var rankingColumnAggregation = axisdetails["rankingColumnAggregation"];
        var xdatesortingEnabled = axisdetails['xdatesortingEnabled'];
        var dobyselectedcolumns = axisdetails["dobyselectedcolumns"];
        var metricdatefield = axisdetails["metricdatefield"];
        var metricdatefielddisplay = axisdetails["metricdatefielddisplay"];
        var metricdatetype = axisdetails["metricdatetype"];
        var metricprefix = axisdetails["metricprefix"];
        var metricsuffix = axisdetails["metricsuffix"];
        var metricfont = axisdetails["metricfont"];
        var solidgaugemin = axisdetails["solidgaugemin"];
        var solidgaugemax = axisdetails["solidgaugemax"];
        var pivotrowvalues = axisdetails["pivotrowvalues"];
        var pivotcolumnvalues = axisdetails["pivotcolumnvalues"];
        var pivotvalues = axisdetails["pivotvalues"];
        var richtextcontent = axisdetails["richtextcontent"];
        var colorThemeIndex = axisdetails["colorThemeIndex"];
        var reportcolortheme = axisdetails["reportcolortheme"];
        var chartSettings = axisdetails["chartSettings"];
        var predictionChartDetails = axisdetails["predictionArray"];
        var summarytablecolumnsArray = axisdetails['summarytablecolumns'];
        var summarytablegroupbyArray = axisdetails['summarytablegroupbys'];
        var summarytablevaluesArray = axisdetails['summarytablevalues'];
        var dobyrowlimit = axisdetails['dobyrowlimit'];
        var combinationchartslist = axisdetails["combinationchartslist"];
        var compareDateRangeObj = axisdetails["compareDateRangeObj"] != undefined ? axisdetails["compareDateRangeObj"] : null;
        var reference = axisdetails["reference"]
        var trendLine = axisdetails["trendLine"];
        var periodsvalue = axisdetails["periodsvalue"];
        var drillDownFilter = axisdetails["drillDownFilter"];
        var drillDownAllFilter = axisdetails["drillDownAllFilter"];
        var reportcolumnslist = axisdetails["reportcolumnslist"];
        var datamodelidlist = axisdetails["datamodelidlist"];
        var nlpFilter = axisdetails["nlpFilter"] != undefined ? axisdetails["nlpFilter"] : "";
        var nlpfiltercols = axisdetails["nlpfiltercols"] ? axisdetails["nlpfiltercols"] : "";
        var nlprowlimit = axisdetails["nlprowlimit"] ? axisdetails["nlprowlimit"] : "";
        var nlptableordertype = axisdetails["nlptableordertype"] ? axisdetails["nlptableordertype"] : "";
        var searchText = axisdetails["searchText"] ? axisdetails["searchText"] : "";
        ChartInfo.functions.setreferenceLineValues(chartnumber, reference);
        var getsummarytableaxisfieldsarray;
        var customfieldFilterObject = axisdetails["customfieldFilterObject"];
        if (axisdetails["getsummarytableaxisfieldsarray"] != undefined) {
            var getsummarytableaxisfieldsarray = axisdetails["getsummarytableaxisfieldsarray"];
            if (getsummarytableaxisfieldsarray != undefined) {
                getsummarytableaxisfieldsarray['isSave'] = true;
            }
            var customFieldsReportId = axisdetails['customFieldsReportId'];
            ChartInfo.functions.setSummarytableAxisFieldsArray(getsummarytableaxisfieldsarray, chartnumber);
        }
        var summarytabledrilldownfilters;
        if (axisdetails["summarytabledrilldownstates"] != undefined) {
            summarytabledrilldownfilters = axisdetails["summarytabledrilldownstates"]
        }
        sessionStorage.removeItem("customhierarchy" + parseInt(chartnumber));
        if (drillDownFilter != undefined) {
            _.each(drillDownFilter, function (value) {
                value["saved"] = "true";
            });
        }
        if (drillDownAllFilter != undefined) {
            _.each(drillDownAllFilter, function (value) {
                value["saved"] = "true";
            });
        }
        var customseriescolors = axisdetails["customseriescolors"];
        if (chartSettings == undefined) {
            if (charttype == "pie" || charttype == "3dpie" || charttype == "semicircle" || charttype == "donut") {
                chartSettings = { 'chartTitle': true, 'legend': false, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true, 'referenceLine': false };
            }
            else {
                chartSettings = { 'chartTitle': true, 'legend': true, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true, 'referenceLine': false };
            }
        }
        var customfieldsDrillDownObject = axisdetails['customfieldsDrillDownObject'];
        var datehistogramstate = axisdetails['datehistogramstate'];
        var summaryTableDateHistogram = axisdetails['summaryTableDateHistogram'];
        var tempSummaryTableFilterValues;
        if (axisdetails['tempSummaryTableFilterValues'] != undefined) {
            var tempSummaryTableFilterValues = axisdetails['tempSummaryTableFilterValues'];
            ChartInfo.functions.setTempSummaryTableFilterValues(tempSummaryTableFilterValues, chartnumber);
            values["savedSummaryFilterArray"] = tempSummaryTableFilterValues;
        }
        ChartInfo.functions.setConditionalFormatData(chartnumber, axisdetails["conditionalFormateData"]);

        var legendfilter = axisdetails["legendfilter"];
        var legendfilterAll = axisdetails["legendfilterallchart"];
        values["tabId"] = currenttabid;
        values["mainreportid"] = mainreportid;
        values["datamodelId"] = datamodelId;
        values["tabName"] = currenttabname;
        values['reportcolumnslist'] = reportcolumnslist
        values['datamodelidlist'] = datamodelidlist
        values["xAxis"] = xaxisData;
        values["yAxis"] = yaxisData;
        values["zAxis"] = zaxisData;
        values["customSolidGauge"] = customSolidGauge;
        values["tooltip"] = tooltipData;
        values["legend"] = legend;
        values["reporttitle"] = charttitle;
        values["dobyselectedcolumns"] = dobyselectedcolumns;
        values["chartType"] = charttype;
        values["colLimit"] = colLimit;
        values["orderType"] = orderType;
        values["sortType"] = sortType;
        values["sortingColumn"] = sortingColumn;
        values["sortingColumnAggregation"] = sortingColumnAggregation;
        values["rankingColumn"] = rankingColumn;
        values["xdatesortingEnabled"] = xdatesortingEnabled;
        values["rankingColumnAggregation"] = rankingColumnAggregation;
        values["metricdatefield"] = metricdatefield;
        values["metricdatefielddisplay"] = metricdatefielddisplay;
        values["metricdatetype"] = metricdatetype;
        values["metricprefix"] = metricprefix;
        values["metricsuffix"] = metricsuffix;
        values["metricfont"] = metricfont;
        values["solidgaugemin"] = solidgaugemin;
        values["solidgaugemax"] = solidgaugemax;
        values["rows"] = pivotrowvalues;
        values["columns"] = pivotcolumnvalues;
        values["values"] = pivotvalues;
        values["richtextcontent"] = richtextcontent;
        values["colorThemeIndex"] = colorThemeIndex;
        values["reportcolortheme"] = reportcolortheme;
        values["chartnumber"] = chartnumber;
        values["predictionArray"] = predictionChartDetails;
        values["chartSettings"] = chartSettings;
        values['summarytablegroupbys'] = summarytablegroupbyArray;
        values['summarytablecolumns'] = summarytablecolumnsArray;
        values['summarytablevalues'] = summarytablevaluesArray;
        values['dobyrowlimit'] = dobyrowlimit;
        values["combinationchartslist"] = combinationchartslist;
        values["compareDateRangeObj"] = compareDateRangeObj;
        values["reference"] = reference;
        values["trendLine"] = trendLine;
        values["periodsvalue"] = periodsvalue;
        values['drillDownFilter'] = drillDownFilter;
        values["drillDownAllFilter"] = drillDownAllFilter;
        values['customfieldFilterObject'] = customfieldFilterObject;
        values["nlpFilter"] = nlpFilter;
        values["nlpfiltercols"] = nlpfiltercols;
        values['nlprowlimit'] = nlprowlimit;
        values["nlptableordertype"] = nlptableordertype;
        values['searchText'] = searchText;
        if (getsummarytableaxisfieldsarray != undefined) {
            values["getsummarytableaxisfieldsarray"] = getsummarytableaxisfieldsarray;
        }
        if (summarytabledrilldownfilters != undefined && summarytabledrilldownfilters != null && summarytabledrilldownfilters != "undefined" && summarytabledrilldownfilters != "null") {
            sessionStorage.setItem("customhierarchy" + chartnumber, summarytabledrilldownfilters);
        }
        values["summarytabledrilldownname"] = axisdetails["summarytabledrilldownname"]
        values["summarytabledrilldowndisplayname"] = axisdetails["summarytabledrilldowndisplayname"]
        values["summarytabledrilldowndatatype"] = axisdetails["summarytabledrilldowndatatype"]
        values["customseriescolors"] = customseriescolors;
        values['datehistogramstate'] = datehistogramstate;
        values["summaryTableDateHistogram"] = summaryTableDateHistogram;
        values["legendfilter"] = legendfilter;
        values["legendfilterall"] = legendfilterAll;

        if (ChartInfo.functions.getCsbRefChartNum() != undefined && ChartInfo.functions.getCsbRefChartNum() != null) {
            ChartInfo.functions.updateChartDetails(ChartInfo.functions.getCsbRefChartNum(), JSON.stringify(values));
            ChartInfo.functions.updateCustomSeriesColorsDetails(ChartInfo.functions.getCsbRefChartNum(), customseriescolors);
        } else {
            ChartInfo.functions.updateChartDetails(chartnumber, JSON.stringify(values));
            if(window.localStorage.getItem("nlpreportdatatoiframe")!=null && window.localStorage.getItem("nlpreportdatatoiframe")!=undefined){
                ChartInfo.functions.updateChartDetails(chartnumber, window.localStorage.getItem("nlpreportdatatoiframe"));
            }
            if(window.localStorage.getItem("setNLPTableRowLimitDetails")!=null && window.localStorage.getItem("setNLPTableRowLimitDetails")!=undefined){
                ChartInfo.functions.setNLPTableRowLimitDetails(chartnumber, JSON.parse(window.localStorage.getItem("setNLPTableRowLimitDetails")));
            }
            ChartInfo.functions.updateCustomSeriesColorsDetails(chartnumber, customseriescolors);
            if (isCustomStoryboard) {
                //   CustomStoryBoardInfo.functions.setCustomStoryboardchartdetails((values));
                //   CustomStoryBoardInfo.functions.setCustomstroyboardReportColumns((reportcolumnslist));
            }
        }
    });
}
export function getgriddetails(chartnumber) {

    var reportdata = JSON.parse(model.reportdata);
    var grid = reportdata[3]["grid"];
    var gridnumber = chartnumber;
    var tempgridData = reportdata[3]["grid"][0];
    _.each(grid, function (gridData) {
        if (gridData["num"] == chartnumber) {
            gridnumber = gridData["num"];
            tempgridData = gridData;
        }
    });
    return tempgridData;
}

export function getFilterObject() {
    return filterDataObject;
}
export function setFilterObject() {
    if (window.location.pathname.indexOf("viewsmartinsight") >= 0 || window.location.pathname.indexOf("sharesmartinsightreport") >= 0 || (model.visualizationtype != undefined && model.visualizationtype != null && model.visualizationtype === 'smartinsight_storyboard')) {
        var reportdata = JSON.parse(model.smartinsightreportdata);
    } else {
        var reportdata = JSON.parse(model.reportdata);
    }
    var filterData = reportdata[2]["filter"];
    var customFieldsObj = JSON.parse(sessionStorage.getItem("customFields"));
    var values = [];
    if (sessionStorage.getItem("isImportedReport") == "true" || window.location.pathname.indexOf("importreport") >= 0) {
        _.each(filterData, function (obj, i) {
            if (obj.customfield != undefined) {
                _.each(customFieldsObj, function (cf, j) {
                    if (obj.columnname == cf.name) {
                        values.push(obj);
                    }
                })
            } else {
                values.push(obj);
            }
        })
        filterDataObject = values;
    } else {
        filterDataObject = filterData;
    }
}

export function getCustomParameterFilterObject(chartnumber) {
    var data = '';
    try {
        var reportdata = JSON.parse(model.reportdata);
        if (reportdata[11] != undefined) {
            data = reportdata[11]['customParameterFilters'];
        }
    } catch (e) { }
    return data;
}

export function getColumns() {
    var columns = model.reportcolumns;
    return columns;
}
export function getSharedFilters() {

    var shared_Filters_Data = model.shared_Filters_Data;
    if (shared_Filters_Data == undefined || shared_Filters_Data == "") {
        return "[]";
    }
    return shared_Filters_Data;
}
export function getEmbedReportFilters() {
    var embedReportFilters;
    var queryArr = [];
    var url = window.location.search;
    if (url.length > 0) {
        queryArr = url.replace('?', "").split("&");
        var embedObj = {};
        for (var i = 0; i < queryArr.length; i++) {
            var key = queryArr[i].split("=")[0];
            var value = queryArr[i].split("=")[1];
            if (key == "cid" || key == "ReportEndDate" || key == "scheduleid" || key == "date") {

            }
            else {
                embedObj[key] = [value];
            }

        }
        embedReportFilters = JSON.stringify(embedObj);
    } else {
        embedReportFilters = "[]";
    }

    return embedReportFilters;
}
export function getIsDirect() {

    var isDirect = model.isDirect;
    return isDirect;
}
export function getSharedViewOnlyFlag() {

    var view_Only_Flag = model.view_Only_Flag;
    return view_Only_Flag;
}
export function getCustomFields() {

    var reportdata = JSON.parse(model.reportdata);
    return reportdata[5]["customFields"];
}
export function isDrillDownAllEnable() {
    if (model == undefined || model.reportdata == undefined) {
        return true;
    }
    var reportdata = JSON.parse(model.reportdata);
    if (reportdata[17] != undefined) {
        return (reportdata[17]["isDrillDownAllenable"]);
    }
}
export function createDataTypeBuckets(options) {
    this.multifact = options.multifact;
    this.multifactentities = options.entities;
    if (this.multifact) {
        this.multifactkeys = Object.keys(JSON.parse(this.multifactentities));
    }
    // this.broker.on('updateMeasuresWithNewAggreations',this.updateMeasuresWithNewAggreations,this);
    var columns = JSON.parse(this.getColumns());
    dimensionsArray = [];
    measuresArray = [];
    reportsdetails = {};
    _.each(columns, function (column) {
        var reportmodel = {};
        reportmodel["columnName"] = column["columnName"];
        reportmodel["tableDisplayType"] = column["tableDisplayType"];
        reportmodel["columnDisplayName"] = column["columnDisplayName"];
        reportmodel["aggregation"] = column["aggregation"];
        reportmodel["tableName"] = column["tableName"];
        reportmodel["columnssrename"] = column["columnssrename"];
        reportmodel["unique"] = column["unique"];
        reportmodel["visible"] = column["visible"];
        reportmodel["dateFormat"] = column["dateFormat"];
        reportmodel["orginalColumnName"] = column["orginalColumnName"];
        reportmodel["tableAliasName"] = column["tableAliasName"];
        reportmodel["priorityColumn"] = column["priorityColumn"];
        reportmodel["id"] = column["id"];
        //reportmodel["tableDisplayName"] = column["tableDisplayName"];
        if (column["tableDisplayType"] === 'number') {
            measuresArray.push(reportmodel);
            dimensionsArray.push(reportmodel);
        }
        else if (column["tableDisplayType"] === 'string') {
            dimensionsArray.push(reportmodel);
        }
        else if (column["tableDisplayType"] === 'date' || column["tableDisplayType"] === 'datetime') {
            dimensionsArray.push(reportmodel);
        }
        else if (column["tableDisplayType"] === 'month') {
            dimensionsArray.push(reportmodel);
        }
        else if (column["tableDisplayType"] === 'day') {
            dimensionsArray.push(reportmodel);
        }
    });
}
export function updateMeasuresWithNewAggreations(newMeasureArray) {
    measuresArray = newMeasureArray;
}
export function getDimensions() {
    return dimensionsArray;
}

export function getMeasures() {
    return measuresArray;
}
export function updatereportchanges(reportId, reportname, reportfolder, reportdesc, chartnumber, syncConfigData) {
    var tempArray = { "reportId": reportId, "reportname": reportname, "reportfolder": reportfolder, "reportdesc": reportdesc, "syncConfigData": syncConfigData };
    reportsdetails[chartnumber] = tempArray;
}

export function getreportId(chartnumber) {
    var tempObj = reportsdetails[chartnumber];
    if (tempObj != undefined) {
        return tempObj["reportId"];
    } else {
        return undefined;
    }

}
export function getreportname(chartnumber) {
    var tempObj = reportsdetails[chartnumber];
    if (tempObj != undefined) {
        return tempObj["reportname"];
    } else {
        return undefined;
    }
}
export function getsyncconfigdata(chartnumber) {
    var tempObj = reportsdetails[chartnumber];
    if (tempObj != undefined) {
        return tempObj["syncConfigData"];
    } else {
        return undefined;
    }
}
export function getreportfolder(chartnumber) {
    var tempObj = reportsdetails[chartnumber];
    if (tempObj != undefined) {
        return tempObj["reportfolder"];
    } else {
        return undefined;
    }
}
export function getreportdesc(chartnumber) {
    var tempObj = reportsdetails[chartnumber];
    if (tempObj != undefined) {
        return tempObj["reportdesc"];
    } else {
        return undefined;
    }
}

export function createDataTypeBucketsForTemp(options) {
    this.multifact = options.multifact;
    this.multifactentities = options.multifactentities;
    this.facttables = options.facttables;
    this.cols = options.reportcolumns;
    if (this.multifact) {
        this.multifactkeys = Object.keys(JSON.parse(this.multifactentities));
    }
    // this.broker.on('updateMeasuresWithNewAggreations',this.updateMeasuresWithNewAggreations,this);
    stringArray = [];
    numberArray = [];
    dateArray = [];
    dimensionsArray = [];
    measuresArray = [];
    reportsdetails = {};
    _.each(this.cols, function (modelObj) {
        if (modelObj.tableDisplayType === 'number') {
            numberArray.push(modelObj);
            measuresArray.push(modelObj);
            dimensionsArray.push(modelObj);
        }
        if (modelObj.tableDisplayType === 'string') {
            stringArray.push(modelObj);
            dimensionsArray.push(modelObj);
        }
        if (modelObj.tableDisplayType === 'date' || modelObj.tableDisplayType === 'datetime') {
            dateArray.push(modelObj);
            dimensionsArray.push(modelObj);
        }
        if (modelObj.tableDisplayType === 'month') {
            dateArray.push(modelObj);
            dimensionsArray.push(modelObj);
        }
        if (modelObj.tableDisplayType === 'day') {
            dateArray.push(modelObj);
            dimensionsArray.push(modelObj);
        }
    });
}