import React from 'react';
import _ from 'lodash';
import $ from 'jquery';
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d';
import PubSub from 'pubsub-js';
import drilldow from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import Viewchartdrilldownmodal from "../../ViewStoryboard/ChartDrillDownModal";
import { whitelableMap } from "../../js/helpers/utils/whitelable";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import * as  TabInfo from '../../js/helpers/utils/tabinfo';
import * as hcnd from 'highcharts/modules/no-data-to-display';
import * as hcmore from 'highcharts/modules/no-data-to-display';
import { lang } from "../../js/helpers/utils/languages/language";
import StoryboardSmartInsightsView from "../../ViewStoryboard/storyboardsmartinsightsview";

hcnd(Highcharts);
hcmore(Highcharts);
highcharts3d(Highcharts);
drilldow(Highcharts);
dataModule(Highcharts);

var charts = {};

export default class BasicPieChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        var compos = {};
        compos["chartComponent"] = this.refs.chartComponent;
        compos["chartparent"] = this.refs.chartparent;
        ChartInfo.functions.setChartComponents(this.state.chartnumber, compos);
        ChartInfo.functions.setChartDataformap(this.refs.chartComponent.chart, this.state.chartnumber);
        if(this.props.chartid!=undefined && this.props.chartid=="nlpchart"){
            this.refs.chartComponent.chart.setSize($("#nlpchart.intelligentsearchchart").width(),$("#nlpchart.intelligentsearchchart").height())
        }else{
            if(this.refs!=undefined && this.refs.chartComponent!=undefined && this.refs.chartComponent.chart!=undefined){
                ChartUtil.charts[this.state.chartid]=this.refs.chartComponent.chart;
            }
            // ChartUtil.modifyChartHeightOnResize(this.state.chartnumber);
        }
    }
    render() {
        var data = this.props.data, xaxisname = this.props.xaxisname, xaxisColumnName = this.props.xaxisColumnName, xaxisDataType = this.props.xaxisDataType,
            yaxisnameArray = this.props.yaxisnameArray, chartid = this.props.chartid, chartType = this.props.chartType, legend = this.props.legend, position = this.props.position, features = this.props.features, isSharedReport = this.props.isSharedReport, colorThemeIndex = this.props.colorThemeIndex,
            chartSettings = this.props.chartSettings, tooltipNameArray = this.props.tooltipNameArray, trendstats = this.props.trendstats, iscustomstoryboard = this.props.iscustomstoryboard, yAxisDataTypeArray = this.props.yAxisDataTypeArray, yAxisAggArray = this.props.yAxisAggArray,
            ismultifact = this.props.ismultifact, multifactentities = this.props.multifactentities, datamodelId = this.props.datamodelId, reportid = this.props.reportid, facttables = this.props.facttables, yAxisOriginalNameArray = this.props.yAxisOriginalNameArray;

        var that = this.state;
        //  charts = {};
        that.chartid = chartid;
        if(charts!=undefined && chartid!=undefined && charts[chartid]!=undefined &&  charts[chartid].refs!=undefined &&  charts[chartid].refs.chartComponent!=undefined &&  charts[chartid].refs.chartComponent.chart!=undefined){
            charts[chartid].refs.chartComponent.chart.destroy();
        }
        var chartPoints = {};
        var columnsData = this.props.columnsData;
        var viewChartDrillDownModal = null;
        var isDrillDownEnable = true;
        var isViewDataEnable = true;
        var isExportChartEnable = false;
        var isFilterEnable = true;
        var isDrillThroughEnable = false;
        var issmartinsightlabel1 = "";
        var isSmartInsightLabel = "";
        var isSmartInsightVisible = "";
        var chart;
        var isDirect = 0;
        var isStoredProcedure = 0;
        var colorThemesArray = ChartInfo.functions.getColorThemes();
        var x, y;
        if(chartSettings.numberformat==undefined){
            var numberformat={};
            numberformat.decimals="0_2";
            chartSettings["numberformat"]=numberformat;
            }
        sessionStorage.setItem('pos', position);
        /*Converting Negative values to positive and add a reference class name to the data point*/
        _.each(data, function (a, b) {
            _.each(a.data, function (e, i) {
                if (e.y < 0) {
                    e.y *= -1;
                    e.className = "negative";
                }
            });
        });

        /*Set Chart height to fix resize issues */
        var chartnumber = chartid.replace("chart", "");
        that.chartnumber = chartnumber;

        //This is to remove previous chart from DOM
        //this.unbindEvents(chartnumber);

        if (data[0] != undefined && data[0].data != undefined && data[0].data.length > 100) {
            ChartUtil.showSeriesExeededWarning();
        }

        if ($('.tileview[data-num=' + chartnumber + ']').length === 1) {
            var setheight = $('.tileview[data-num=' + chartnumber + ']').height() - 42;
            $("#chart" + chartnumber).height(setheight);
        }

        var bgcolor = $('.maxanim7[data-num=' + chartnumber + ']').css('background-color');
        if (bgcolor == undefined || bgcolor === 'rgba(0, 0, 0, 0)') { bgcolor = 'rgba(255, 255, 255, 1)' }

        isExportChartEnable= ChartUtil.checkExportChartEnable(features, position, isSharedReport);
        var userLang = navigator.language || navigator.userLanguage;
        //var locale=getLocaleName(userLang);
        var locale = "en_za";
        var that = this;

        var options = {
            lang: {
                drillUpText: '\u25c1 Back',
                contextButtonTitle: 'Download',
                decimalPoint: '.',
                thousandsSep: ',',
                BacktoMap_key: 'Back to Map'
            },
            chart: {
                events: {
                    click: function (event) {
                        var $this = event.currentTarget;
                        if (event.target.hasAttribute('href')) {
                            var bgcolor = $('.maxanim7[data-num=' + chartnumber + ']').css('background-color');
                            if (bgcolor == undefined || bgcolor === 'rgba(0, 0, 0, 0)') { bgcolor = 'rgba(255, 255, 255, 1)' }
                            this.options.exporting.chartOptions.chart.backgroundColor = bgcolor;
                        }
                    },
                    drillup: function (e) {
                        var chartId = e.target.userOptions.chart.renderTo;
                        var chartnumber = chartId.replace("chart", "");
                        viewChartDrillDownModal.drillup(chartId, chartnumber, charts["chart" + chartnumber], e.seriesOptions.id);
                        ChartUtil.navCorrection(e);
                    },
                    drilldown: function (e) {
                        isDrillDownEnable = ChartUtil.checkDrillDownEnable(features, position, isSharedReport, iscustomstoryboard);
                        isViewDataEnable = ChartUtil.viewDataEnable(features, position, isSharedReport, iscustomstoryboard);
                        isFilterEnable = ChartUtil.checkFilterEnable(features, position, isSharedReport, iscustomstoryboard);
                        isDrillThroughEnable = ChartUtil.checkDrillThroughEnable(features, position, isSharedReport, iscustomstoryboard);
                        var isSmartInsightEnable = ChartUtil.checkSmartInsightsEnable(features, position, isSharedReport, iscustomstoryboard);
                        if (!e.seriesOptions) {

                            var chartId = e.target.userOptions.chart.renderTo;
                            var chartnumber = chartId.replace(
                                "chart", "");
                            chartPoints[chartnumber] = e.point;
                            var jsonobj;
                            var jsonArray = [];
                            var instantFilterArray = [];
                            if (legend != undefined && legend.columnName.length > 0) {
                                jsonobj = {}
                                jsonobj["columnname"] = legend.columnName;
                                jsonobj["dataType"] = legend.dataType;
                                jsonobj["data"] = chartPoints[chartnumber].series.name.split('[')[0];
                                jsonobj["columndisplayname"] = legend.displayName;
                                jsonobj["isdrilldownfilter"] = true;
                                var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == legend.columnName });
                                if (rangeObject.length > 0) {
                                    jsonobj["columnname"] = rangeObject[0]["xAxisName"][0].columnName;
                                }
                                jsonArray.push(jsonobj);
                            }
                            jsonobj = {};
                            jsonobj["columnname"] = e.target.userOptions.xAxis[0].title.orginaltextName;
                            jsonobj["dataType"] = (e.target.userOptions.xAxis[0].ctype == undefined) ? "string" : e.target.userOptions.xAxis[0].ctype;

                            var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == jsonobj["columnname"] });
                            if (rangeObject.length > 0) {
                                jsonobj["columnname"] = rangeObject[0].name;//rangeObject[0]["xAxisName"][0].columnName;
                            }
                            var value = e.point.name;
                            if (jsonobj["dataType"] == "date") {
                                if (value.length > 0) {
                                    var newDate = value;//Date.parse(value.replace(/-/g,'/'));
                                    if (isNaN(newDate)) {
                                        value = value;
                                    }
                                    else {
                                        value = newDate;//new Date(newDate).getTime();
                                    }
                                }
                            }
                            jsonobj["data"] = value;
                            jsonobj["isdrilldownfilter"] = true;
                            jsonobj["columndisplayname"] = e.target.userOptions.xAxis[0].title.displayname;
                            jsonArray.push(jsonobj);
                            sessionStorage.setItem('datatable_popup_filterdata', JSON.stringify(jsonArray));
                            ChartInfo.functions.setInstanceIncludeFilterDataForDrillDown(chartnumber, jsonArray);
                            instantFilterArray.push(jsonobj);
                            _.each(instantFilterArray, function (data, i) {
                                data["data"] = e.point.name;
                                if (ChartInfo.functions.getExcludeFilterData() != undefined && ChartInfo.functions.getExcludeFilterData().length != 0) {
                                    _.each(ChartInfo.functions.getExcludeFilterData(), function (ExcludeFilterData, j) {
                                        ChartInfo.functions.setExcludeFilterData(instantFilterArray);
                                    });
                                }
                                else {
                                    ChartInfo.functions.setExcludeFilterData(instantFilterArray);
                                }

                            });
                            var yaxisName = e.point.series.userOptions.yaxisname;
                            sessionStorage.setItem('datatable_popup_yaxis', yaxisName);
                            var top = this.pointer.mouseDownY;
                            var left = this.pointer.mouseDownX + 16;
                            var chartwidth = this.pointer.chart.chartWidth - 160;
                            var chartheight = this.pointer.chart.chartHeight - 130;
                            if (top > chartheight) {
                                top = chartheight;
                            }
                            if (left > chartwidth) {
                                left = chartwidth;
                            }
                            $('#chart' + chartnumber + ' .highcharts-container .pie-menu').remove();
                            $('.pie-menu').remove();

                            var isDrillDownVisible = "disabled";
                            var isDrillDownLabel = "disabled";

                            if (xaxisDataType == "customrange") {
                                isDrillDownEnable = false;
                                isKeepOnlyVisible = false;
                                isExcludeVisible = false;
                            }

                            if ((xaxisDataType == "" || xaxisDataType == undefined || xaxisColumnName == "Measure Names") || xaxisDataType == "customrange") {
                                isDrillDownEnable = false;
                            }

                            if (isDrillDownEnable) {
                                isDrillDownVisible = "pie-drilldown";
                                isDrillDownLabel = "";
                            }

                            var isViewDataVisible = "disabled";
                            var isViewDataLabel = "disabled";
                            if (isViewDataEnable) {
                                isViewDataVisible = "pie-viewdata";
                                isViewDataLabel = "";
                            }

                            var isDrillThroughVisible = "disabled";
                            var isDrillThroughLabel = "disabled";
                            if (xaxisDataType == "customrange") {
                                isDrillThroughEnable = false;
                            }
                            if (isDrillThroughEnable) {
                                isDrillThroughVisible = "pie-drillthrough";
                                isDrillThroughLabel = "";
                            }

                            var dataLength = data[0].data.length;
                            var isExcludeVisible = "disabled";
                            var isKeepOnlyLabel = "disabled";
                            var isKeepOnlyVisible = "disabled";
                            var isExcludeLabel = "disabled";
                            if (isFilterEnable && dataLength > 1 && xaxisDataType !== "customrange") {
                                isKeepOnlyVisible = "keeponly-point";
                                isKeepOnlyLabel = "";
                                isExcludeVisible = "exclude-point";
                                isExcludeLabel = "";
                            }
                            var isydrilldownenable = "disabled";
                            var yaxisname = e.point.name;
                            var drilldownmeasurearray = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e1) { return e1.name == yaxisname });
                            var chartDetails= ChartInfo.functions.getChartDetails(chartnumber) != undefined ? JSON.parse(ChartInfo.functions.getChartDetails(chartnumber)) : undefined;

                            if(iscustomstoryboard){
                                if(ChartInfo.functions.getMeasureReportId(chartDetails.reportid)!=undefined){
                                    drilldownmeasurearray=$.grep(JSON.parse(ChartInfo.functions.getMeasureReportId(chartDetails.reportid)), function (e1) { return e1.name == yaxisname });
                                }        
                            }
                            var hierarchylist = [];
                            if (drilldownmeasurearray.length > 0 && chartType != "3dpie" && drilldownmeasurearray[0].hierarchelist.length > 1) {
                                isydrilldownenable = "measuredilldown";
                            }

                            var isthreedpie;
                            if (chartType == "3dpie") {
                                isthreedpie = "disabled";
                            }
                            var isNoXaxis = "";
                            if (e.target.options.series[0].name == "Measure Names" && xaxisDataType == "") {
                                isNoXaxis = "disabled"
                            }
                            if (xaxisDataType == "" || xaxisDataType == undefined) {
                                var isExcludeVisible = "disabled";
                                var isKeepOnlyVisible = "disabled";
                                var isDrillDownVisible = "disabled";
                            }
                            isSmartInsightVisible = "disabled";
                            if(isSmartInsightEnable){
                                isSmartInsightVisible = "";
                            }
                            if(e.point.ydatatype!=undefined && e.point.ydatatype.toLowerCase()!="number"){
                                isSmartInsightVisible = "disabled";
                            }
                            if(ismultifact!="false"){
                                isSmartInsightVisible = "disabled";
                            }
                            var sourceType = "storyboard";
                            if (ChartInfo.functions.getChartDetailsForLogReport(chartnumber) != undefined) {
                                var chartdata = ChartInfo.functions.getChartDetailsForLogReport(chartnumber)["chartdetails"];
                                sourceType = chartdata["sourceType"];
                            }
                            //if(isDrillDownEnable || isViewDataEnable || isFilterEnable){
                            if (sessionStorage.getItem("drilldown" + chartnumber) != null && sessionStorage.getItem("drilldown" + chartnumber) != "null") {
                                $('#chart' + chartnumber + '').before('<div class="pie-menu" style="top:' + top + 'px;left:' + left + 'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close" id="piemenuclose3"><i class="fa fa-times"></i></div>'
                                    + '<div class="pie-list-wrapper">'
                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut " data-num="' + chartnumber + '"> <div class="boc-pie-menu-center">  </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isViewDataVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isViewDataLabel + '" id="isviewdatalabel3"><span class="pietooltip">View<br>Data</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isydrilldownenable + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"><span class="pietooltip">Y-Drill<br>Down</span><i class="fa fa-level-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillThroughVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillThroughLabel + '" id="isdrillthroughlabel1""><span class="pietooltip">Drill<br>Through</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillDownVisible + ' ' + isthreedpie + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillDownLabel + '" id="isdrilldownlabel3"><span class="pietooltip">Drill<br>Down</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    +'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isSmartInsightVisible + ' ' + isNoXaxis + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label pie-smartinsights ' + isSmartInsightLabel + '" id="issmartinsightlabel1"><span class="pietooltip">'+lang["storyboard.smartinsights"]+'</span><i class="fa fa-smartinsights small-14 marg-righ-10"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut " data-num="' + chartnumber + '"> <div class="boc-pie-menu-center">  </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    /*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'*/
                                    + '<span class="piemenuwrapper"></span>'
                                    + '</div>'
                                    + '<span class="wrapper"></span>'
                                );
                            }
                            else {
                                $('#chart' + chartnumber + '').before('<div class="pie-menu" style="top:' + top + 'px;left:' + left + 'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close" id="piemenuclose3"><i class="fa fa-times"></i></div>'
                                    + '<div class="pie-list-wrapper">'
                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isKeepOnlyVisible + ' ' + isNoXaxis + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label keeponly ' + isKeepOnlyLabel + '" id="iskeeponlylabel3"><span class="pietooltip"> '+lang["storyboard.keep"]+' <br>'+lang["storyboard.only"]+'</span><i class="fa fa-check"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isViewDataVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isViewDataLabel + '" id="isviewdatalabel3"><span class="pietooltip">'+lang["storyboard.view"]+'<br>'+lang["storyboard.data"]+'</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isydrilldownenable + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"><span class="pietooltip">'+lang["storyboard.y-drill"]+'<br>'+lang["storyboard.down"]+'</span><i class="fa fa-level-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillThroughVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillThroughLabel + '" id="isdrillthroughlabel1""><span class="pietooltip">'+lang["storyboard.drill"]+'<br>'+lang["storyboard.through"]+'</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillDownVisible + ' ' + isthreedpie + ' ' + isNoXaxis + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillDownLabel + '" id="isdrilldownlabel3"><span class="pietooltip">'+lang["storyboard.drill"]+'<br>'+lang["storyboard.down"]+'</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isExcludeVisible + ' ' + isNoXaxis + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label excludeonly ' + isExcludeLabel + '" id="isexcludelabel3"><span class="pietooltip">'+lang["storyboard.exclude"]+'</span><i class="fa fa-times"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    +'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isSmartInsightVisible + ' ' + isNoXaxis + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label pie-smartinsights ' + isSmartInsightLabel + '" id="issmartinsightlabel1"><span class="pietooltip">'+lang["storyboard.smartinsights"]+'</span><i class="fa fa-smartinsights small-14 marg-righ-10"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                    /*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'*/
                                    + '<span class="piemenuwrapper"></span>'
                                    + '</div>'
                                    + '<span class="wrapper"></span>'
                                );
                            }

                            //}
                            setTimeout(function () {
                                $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                            }, 200);
                            $('.boc-piemenu-close, .piemenuwrapper').on("click", function () {
                                $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                setTimeout(function () {
                                    $('.pie-menu').remove();
                                }, 800);
                            });
                            $('.wrapper').on("click", function () {
                                $('.pie-menu').remove();
                            });
                            $('.pie-drilldown').on("click", function () {
                                $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                setTimeout(function () {
                                    $('.pie-menu').remove();
                                }, 800);
                                if (iscustomstoryboard) {
                                    columnsData = ChartInfo.functions.getCustomstroyboardReportColumns(chartnumber);
                                }
                                charts["chart" + chartnumber] = e.target;
                                    chartPoints[chartnumber] = e.point;
                                viewChartDrillDownModal = new Viewchartdrilldownmodal(
                                    {
                                        chartId: chartId,
                                        chartnumber: chartnumber,
                                        columns: JSON.stringify(columnsData),
                                        xaxisName: e.target.userOptions.xAxis[0].title.orginaltextName,
                                        xaxisDisplayName: e.target.userOptions.xAxis[0].title.text,
                                        displayName: e.target.userOptions.xAxis[0].title.displayname,
                                        xaxisType: e.target.userOptions.xAxis[0].ctype,
                                        value: e.point.name,
                                        chart: charts["chart"
                                            + chartnumber],
                                        point: chartPoints[chartnumber],
                                        pos: position,
                                        //                                        broker: broker,
                                        iscuststroyboard: iscustomstoryboard,
                                        sourceType: sourceType,
                                        ismultifact: ismultifact,
                                        multifactentities: multifactentities,
                                        yaxisnameArray: yAxisOriginalNameArray,
                                        datamodelId: datamodelId,
                                        facttables: facttables,
                                        drilldownyAxisSeries: yaxisName
                                    });
                            });
                            $('.measuredilldown').on("click", function (event) {
                                event.preventDefault();
                                $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                setTimeout(function () {
                                    $('g.highcharts-tooltip').css('opacity', '1');
                                    $('.pie-menu').remove();
                                }, 800);
                                charts["chart" + chartnumber] = e.target;
                                    chartPoints[chartnumber] = e.point;
                                viewChartDrillDownModal = new Viewchartdrilldownmodal(
                                    {
                                        chartId: chartId,
                                        chartnumber: chartnumber,
                                        columns: JSON.stringify(columnsData),
                                        xaxisName: e.target.userOptions.xAxis[0].title.orginaltextName,
                                        xaxisDisplayName: e.target.userOptions.xAxis[0].title.text,
                                        displayName: e.target.userOptions.xAxis[0].title.displayname,
                                        xaxisType: e.target.userOptions.xAxis[0].ctype,
                                        value: e.point.name,
                                        chart: charts["chart"
                                            + chartnumber],
                                        point: chartPoints[chartnumber],
                                        pos: position,
                                        //                                        broker: broker,
                                        drilldownType: "measuredrilldown",
                                        iscuststroyboard: iscustomstoryboard,
                                        sourceType: sourceType,
                                        ismultifact: ismultifact,
                                        multifactentities: multifactentities,
                                        yaxisnameArray: yaxisnameArray,
                                        datamodelId: datamodelId,
                                        facttables: facttables,
                                        drilldownyAxisSeries: yaxisName
                                    });
                            });
                            $('.pie-viewdata').on("click", function (e) {
                                e.preventDefault();
                                PubSub.publish('viewDataTablePopUp', e);
                                setTimeout(function () {
                                    $('g.highcharts-tooltip').css('opacity', '1');
                                }, 1000);
                            });
                            $('.exclude-point').on("click", function (e) {
                                e.preventDefault();
                                PubSub.publish('exclude-point', e);
                            });
                            $('.keeponly-point').on("click", function (e) {
                                e.preventDefault();
                                PubSub.publish('keeponly-point', e);
                            });
                            $('.pie-drillthrough').on("click", function (e) {
                                e.preventDefault();
                                e.stopPropagation();
                                PubSub.publish('pie-drillthrough', e);
                            });
                            $('.pie-smartinsights').on("click", function (e1) {
                                $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                setTimeout(function () {
                                    $('g.highcharts-tooltip').css('opacity', '1');
                                    $('.pie-menu').remove();
                                }, 800);
                                e1.preventDefault();
                                e1.stopPropagation();
                                if (iscustomstoryboard) {
                                    columnsData = ChartInfo.functions.getCustomstroyboardReportColumns(chartnumber);
                                }
                                var sourceType = "storyboard";
                                if (ChartInfo.functions.getChartDetailsForLogReport(chartnumber) != undefined) {
                                    var chartdata = ChartInfo.functions.getChartDetailsForLogReport(chartnumber)["chartdetails"];
                                    sourceType = chartdata["sourceType"];
                                }
                                charts["chart" + chartnumber] = e.target;
                                chartPoints[chartnumber] = e.point;
                                let drilldownProps = {};
                                drilldownProps["chartId"] = chartId;
                                drilldownProps["chartnumber"] = chartnumber;
                                drilldownProps["columns"] = JSON.stringify(columnsData);
                                drilldownProps["xaxisName"] = e.target.userOptions.xAxis[0].title.orginaltextName;
                                drilldownProps["xaxisDisplayName"] = e.target.userOptions.xAxis[0].title.text;
                                drilldownProps["displayName"] = e.target.userOptions.xAxis[0].title.displayname;
                                drilldownProps["xaxisType"] = e.target.userOptions.xAxis[0].ctype;
                                drilldownProps["value"] = e.point.name;
                                drilldownProps["chart"] = e.target;
                                drilldownProps["point"] = e.point;
                                drilldownProps["pos"] = position;
                                drilldownProps["iscuststroyboard"] = iscustomstoryboard;
                                drilldownProps["sourceType"] = sourceType;
                                drilldownProps["datamodelId"] = datamodelId;
                                drilldownProps["ismultifact"] = ismultifact;
                                drilldownProps["multifactentities"] = multifactentities;
                                drilldownProps["yaxisnameArray"] = yAxisOriginalNameArray;
                                drilldownProps["facttables"] = facttables;
                                drilldownProps["drilldownyAxisSeries"] = yaxisName;
                                let storyboardmsmartinsightview = new StoryboardSmartInsightsView(drilldownProps)
                            });
                        }


                    }
                },
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                renderTo: chartid,
                reportId: reportid,
                type: 'pie',
                options3d: {},
                backgroundColor: 'transparent',
                style: {
                    fontFamily: whitelableMap["fontfamily"],
                    fontWeight: '400',
                },
                animation: false,
                spacingBottom: 20,
                className:"basicpiechart"
                //height:'auto'
            },
            title: {
                text: ''
            },
            legend: {
                margin: 10,
                enabled: false,
                itemStyle: {},
                useHTML: true,
                labelFormatter: function () {
                    var name = '<span title="' + this.name + '">' + this.name + '</span>'
                    return name;
                }
            },
            tooltip: {
                /*pointFormat : '{series.name}: <b>{point.percentage:.1f}% ({point.y:,.2f})</b>'*/
                formatter: function () {
                    // $("#chart" + chartnumber + " .highcharts-tooltip>span").css({ "background-color": chart.tooltip.options.backgroundColor, "borderStyle": "solid", "borderWidth": chart.tooltip.options.borderWidth, "border-color": chart.tooltip.options.borderColor });
                    // if ($("#chart" + chartnumber + " .highcharts-tooltip>span").length == 0) {
                    //     setTimeout(function () { $("#chart" + chartnumber + " .highcharts-tooltip>span").css({ "background-color": chart.tooltip.options.backgroundColor, "borderStyle": "solid", "borderWidth": chart.tooltip.options.borderWidth, "border-color": chart.tooltip.options.borderColor }); }, 200)
                    // }
                    //Add minus for negative values
                    if (this.point.className != undefined && this.point.className === "negative") {
                        this.y = -Math.abs(this.y);
                    }
                    var name = '<span style="font-size:10px">' + this.key + '</span><table>'
                    var details = ChartInfo.functions.getChartDetails(chartnumber) != undefined ? JSON.parse(ChartInfo.functions.getChartDetails(chartnumber)) : undefined;
                    var that = this;
                    var aggrigationName = that.series.options.aggType;
                    if(aggrigationName==='customaggregatedfield'){
                            aggrigationName = 'raw'
                        }
                    if (details != undefined && details['yAxis'].length != 0) {
                        var seriesName = (this.series.options.displayname == undefined) ? this.series.name : this.series.options.displayname;
                        $.each(that.series.options.data, function (i, j) {
                            if (j.name == that.key && j.y == that.y && j.aggType != undefined && j.aggType == that.point.aggType) {
                                aggrigationName = j.aggType;
                            }

                        });
                        name += '<tr><td style="color:' + that.color + ';padding:0">' + seriesName + ' <span class="bocaggtype" style="color: initial;">[' + aggrigationName + '] </span>' + ':&nbsp; </td>';
                    }
                    if (this.point.ydatatype == 'date' && this.series.options.aggType.toLowerCase().indexOf("count") < 0) {
                        var date = new Date(this.y);
                        name += '<td style="padding:0"><b>' + date.toLocaleString() + '</b></td></tr>';
                    }
                    else {
                        var num = ChartInfo.functions.addFormatting(this.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                        name += '<td style="padding:0"><b>' + num + '</b></td></tr>';
                    }
                    var that = this;
                    if (tooltipNameArray!=undefined && tooltipNameArray.length != 0) {
                        $.each(tooltipNameArray, function (i) {
                            var tooltipnow = tooltipNameArray[i];
                            var tooltipvalue = "t" + i;
                            var tooltipagg = "ta" + i;
                            name += '<tr><td style="color:' + that.color + ';padding:0">' + tooltipnow + ' <span class="bocaggtype" style="color: initial;">[' ;
                            name += that.point[tooltipagg] + '] </span>' + ': </td>';
                            if (that.point[tooltipvalue] == 0) {
                                name += '<td style="padding:0"><b>' + that.point[tooltipvalue] + '</b></td></tr>'
                            } else {
                                var num = ChartInfo.functions.addFormatting(that.point[tooltipvalue], chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                name += '<td style="padding:0"><b>' + num + '</b></td></tr>';
                            }
                        });
                    }
                    return name;


                },
                shared: false,
                useHTML: true,
                zIndex: 9
            },
            xAxis: [{
                ctype: xaxisDataType,
                type: "category",
                title: {
                    text: xaxisname,
                    orginaltextName: xaxisColumnName,
                    style: {}
                },
                labels: {
                    style: {}
                },
            }],
            drilldown: {
                // activeAxisLabelStyle: {
                //     cursor: 'pointer',
                //     textDecoration: 'none',
                //     color: '#000',
                //     fontWeight: '400'
                // },
                // activeDataLabelStyle: {
                //     cursor: 'pointer',
                //     textDecoration: 'none',
                //     color: '#000',
                //     fontWeight: '400'
                // },
                series: []
            },
            plotOptions: {
                pie: {
                    animation:false,
                    allowPointSelect: true,
                    showInLegend: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: chartSettings.dataLabel,
                        /*format : '<b>{point.name}</b>: {point.percentage:.1f} % <br>({point.y:,.2f})',*/
                        formatter: function () {
                            //Add minus for negative values
                            if (this.point.className != undefined && this.point.className === "negative") {
                                this.y = -Math.abs(this.y);
                            }
                            var percentagevalue=ChartInfo.functions.addFormatting(this.percentage,chartSettings.numberformat.abbrevation,chartSettings.numberformat.decimals,chartSettings.numberformat.seperator);
                            var name = '<span class="pie-chart-datalable" title="' + this.key + '"><b>' + this.key + '</b></span>' +/* $.formatNumber(this.percentage, { format: "#,###.##", locale: locale }) */percentagevalue + '% (';
                            if (this.point.ydatatype == 'date' && this.series.options.aggType.toLowerCase().indexOf("count") < 0) {
                                var date = new Date(this.y);
                                name += date.toLocaleString() + ')';
                            }
                            else {
                                var num=ChartInfo.functions.addFormatting(this.y,chartSettings.numberformat.abbrevation,chartSettings.numberformat.decimals,chartSettings.numberformat.seperator);
                                name += /*$.formatNumber(this.y, { format: "#,###.##", locale: locale })*/num + ')';
                            }

                            return name
                        },
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        },
                        useHTML: true
                    }
                },
                series: {
                    animation: false,
                    dataLabels: {
                        dataLabels: {
                            enabled: false,
                            style: { textShadow: 'none' },
                            formatter: function () {
                                var num=ChartInfo.functions.addFormatting(this.point.y,chartSettings.numberformat.abbrevation,chartSettings.numberformat.decimals,chartSettings.numberformat.seperator);
                                return  num;
                            }
                        }
                    }
                }
            },
            colors: colorThemesArray[colorThemeIndex],
            exporting: {
                enabled: false,
                buttons: {
                    contextButton: {
                        symbol: 'download',
                        symbolFill:"#fff",
                        symbolStrokeWidth:0,
                        theme:{
                            fill:'#ffffff',
                            padding:5,
                            stroke:'none',
                            cursor:'pointer'
                        },
                        menuItems: [{
                            textKey: 'printChart',
                            onclick: function () {
                                //console.log("printchart");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart printed"
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.print();
                            }
                        }, {
                            separator: true
                        }, {
                            textKey: 'downloadPNG',
                            onclick: function () {
                                console.log("downloadPNG");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart downloaded in PNG format"
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart();

                            }
                        }, {
                            textKey: 'downloadJPEG',
                            onclick: function () {
                                console.log("downloadJPEG");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart downloaded in JPEG format";
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart({
                                    type: 'image/jpeg'
                                });
                            }
                        }, {
                            separator: true
                        }, {
                            textKey: 'downloadPDF',
                            onclick: function () {
                                console.log("downloadPDF");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart downloaded in PDF format";
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart({
                                    type: 'application/pdf'
                                });
                            }
                        }, {
                            textKey: 'downloadSVG',
                            onclick: function () {
                                console.log("downloadSVG");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart downloaded in SVG format";
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart({
                                    type: 'image/svg+xml'
                                });
                            }
                        }]

                    }
                },
                chartOptions: {
                    chart: {
                        width: 800,
                        backgroundColor: bgcolor
                    },
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                style: {
                                    font: '6px ' + whitelableMap["fontfamily"],
                                    fontWeight: 'normal',
                                }
                            }
                        }
                    },
                    legend: {
                        itemStyle: {
                            font: '6px ' + whitelableMap["fontfamily"],
                            fontWeight: 'normal',
                        }
                    }
                }
            },
            credits: {
                enabled: false
            }
        };

        options.series = data;
        if (chartType == "3dpie") {
            options.chart.options3d.enabled = true;
            options.chart.options3d.alpha = '45';
            options.chart.options3d.beta = '0';
            options.plotOptions.pie.depth = '40';
            if (chartSettings['legend'] == true) {
                options.plotOptions.pie.showInLegend = true;
            } else {
                options.plotOptions.pie.showInLegend = false;
            }
        }
        //options.exporting.enabled = true;
        if (isExportChartEnable) {
            options.exporting.enabled = true;
        }
        if ($(window).width() < 780) {
            options.chart.zoomType = 'none';
        }
        /*-------Chart Settings------*/

        if (chartSettings['xAxisLabel'] == true) {
            options.xAxis[0].title.text = xaxisname;
            options.xAxis[0].title.displayname = xaxisname;
        } else {
            options.xAxis[0].title.text = xaxisname;
            options.xAxis[0].title.displayname = xaxisname;
            options.xAxis[0].title.enabled = false;
        }
        if (chartSettings['dataLabel'] == true) {
            options.plotOptions.series.dataLabels.allowOverlap = false;
            options.plotOptions.series.dataLabels.enabled = true;
        } else {
            options.plotOptions.series.dataLabels.enabled = false;
        }
        if (chartSettings['legend'] == true) {
            options.legend.enabled = true;
        } else {
            options.legend.enabled = false;
        }

        var objectcount = options.series.length;
        if (objectcount == 1) {
            //	options.series[0].size = '100%';
        } else if (objectcount == 2) {
            options.series[0].size = '78%';
            options.series[0].dataLabels = { "distance": -20 }
            options.series[1].size = '100%';
            options.series[1].innerSize = '78%';
        } else if (objectcount == 3) {
            options.series[0].size = '65%';
            options.series[0].dataLabels = { "distance": -80 }
            options.series[1].size = '78%';
            options.series[1].innerSize = '65%';
            options.series[1].dataLabels = { "distance": -30 }
            options.series[2].size = '100%';
            options.series[2].innerSize = '78%'
        }
        /*//Conditional Formating
        var conditionalFormatData=[];
        if(ChartInfo.getConditionalFormatData(chartnumber)!=undefined){
            conditionalFormatData=ChartInfo.getConditionalFormatData(chartnumber);
        }					
        //Initialize Conditional Formatting
        if(conditionalFormatData !=undefined && conditionalFormatData.length>0){
            this.conditionalFormating(chartnumber,conditionalFormatData,options)
        }*/
        //Add Advanced Chart Settings
        if (chartSettings["AdvancedChartSettings"] != undefined) {
            var advanced = chartSettings["AdvancedChartSettings"];
            $.extend(true, options, advanced);
        }

        //Add Back to Chart Button for Srilanka map
        if ($("#" + chartid + "").hasClass("leaflet") || $("#" + chartid + "").hasClass("world") || $("#" + chartid + "").hasClass("srilankamap") || $("#" + chartid + "").hasClass("countrymap") || $("#" + chartid + "").hasClass("indiamap")) {
            options.exporting.buttons = {
                customButton: {
                    titleKey: '\u25c1 Back to Map',
                    text: '\u25c1 Back to Map',
                    className:'MapDrillChart',
                    onclick: function () {
                        var chartnumber = chartid.replace("chart", "");
                        var legendfilter = [];
                        if (iscustomstoryboard) {
                            $(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
                            $(".pie-menu").remove();
                            $(".fullscreensample").removeClass("nopie");
                            ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
                            ChartInfo.functions.setLegendFilterobj(legendfilter, chartnumber);
                            sessionStorage.removeItem("drilldown" + chartnumber);
                            if ($("#drilldownallchartsinstoryboard").prop("checked") == true) {
                                $(".customstoryboard.refreshcharts").click();
                                ChartInfo.functions.setdrillfitlerArray(legendfilter);
                            }
                            else {
                                $(".customstoryboard.refreshcharts").trigger("click", ['backtomap', chartid])
                            }
                            setTimeout(function () {
                                $(".gridspinner").hide();
                            }, 300);

                        }
                        else {
                            $(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
                            sessionStorage.removeItem("drilldown" + chartnumber);
                            if ($("#drilldownallchartsinstoryboard").prop("checked") == true) {
                                $(".refreshcharts").click();
                                ChartInfo.functions.setdrillfitlerArray(legendfilter);
                            }
                            else {
                                //$(".refreshsinglecharts1[data-num="+this.userOptions.chart.renderTo.replace("chart","")+"]").click();

                                //                                broker.trigger('applyfiltesandredrawchart', undefined, chartnumber, "applyattributes", undefined, undefined, undefined, undefined, undefined, [], "drilldownview");
                                $(".singlereportview .refreshsinglecharts").click();
                            }
                        }

                    },
                    useHTML: true,
                    relativeTo: 'spacingBox',
                    position: {
                        y: 2,
                        x: -30,
                    },
                    theme: {
                        width: 80,
                        height: 12,
                        class: "highcharts-drillup-button",
                        'stroke-width': 1,
                        stroke: '#e8eaf1',
                        r: 0,
                        fill: '#fff',
                        cursor: 'pointer',
                        states: {
                            hover: {
                                fill: '#f9f9fd',
                                stroke: '#e8eaf1'
                            },
                            select: {
                                fill: '#f9f9fd',
                                stroke: '#e8eaf1'
                            }
                        }
                    },
                },
                contextButton: {
                    y: 2,
                    symbol: 'download',
                    symbolFill:"transparent",
                    symbolStrokeWidth:0,
                    theme:{
                        fill:'#ffffff',
                        padding:5,
                        stroke:'none',
                        cursor:'pointer'
                    },
                    menuItems: [{
                        textKey: 'printChart',
                        onclick: function () {
                            //console.log("printchart");
                            var chartId = this.userOptions.chart.renderTo;
                            var reportId = this.userOptions.chart.reportId;
                            if (chartId == "nlpchart") {
                                var msg = "NLP chart printed";

                            } else {
                                var msg = "Chart printed"
                            }
                            if (reportId != undefined) {
                                ChartUtil.auditMessage(reportId, msg);
                            }
                            this.print();
                        }
                    }, {
                        separator: true
                    }, {
                        textKey: 'downloadPNG',
                        onclick: function () {
                            //console.log("downloadPNG");
                            var chartId = this.userOptions.chart.renderTo;
                            var reportId = this.userOptions.chart.reportId;
                            if (chartId == "nlpchart") {
                                var msg = "NLP chart downloaded in PNG format";
                            } else {
                                var msg = "Chart downloaded in PNG format"
                            }
                            if (reportId != undefined) {
                                ChartUtil.auditMessage(reportId, msg);
                            }
                            this.exportChart();
                        }
                    }, {
                        textKey: 'downloadJPEG',
                        onclick: function () {
                            //console.log("downloadJPEG");
                            var chartId = this.userOptions.chart.renderTo;
                            var reportId = this.userOptions.chart.reportId;
                            if (chartId == "nlpchart") {
                                var msg = "NLP chart downloaded in JPEG format";
                            } else {
                                var msg = "Chart downloaded in JPEG format";
                            }
                            if (reportId != undefined) {
                                ChartUtil.auditMessage(reportId, msg);
                            }
                            this.exportChart({
                                type: 'image/jpeg'
                            });
                        }
                    }, {
                        separator: true
                    }, {
                        textKey: 'downloadPDF',
                        onclick: function () {
                            //console.log("downloadPDF");
                            var chartId = this.userOptions.chart.renderTo;
                            var reportId = this.userOptions.chart.reportId;
                            if (chartId == "nlpchart") {
                                var msg = "NLP chart downloaded in PDF format";
                            } else {
                                var msg = "Chart downloaded in PDF format";
                            }
                            if (reportId != undefined) {
                                ChartUtil.auditMessage(reportId, msg);
                            }
                            this.exportChart({
                                type: 'application/pdf'
                            });
                        }
                    }, {
                        textKey: 'downloadSVG',
                        onclick: function () {
                            //console.log("downloadSVG");
                            var chartId = this.userOptions.chart.renderTo;
                            var reportId = this.userOptions.chart.reportId;
                            if (chartId == "nlpchart") {
                                var msg = "NLP chart downloaded in SVG format";
                            } else {
                                var msg = "Chart downloaded in SVG format";
                            }
                            if (reportId != undefined) {
                                ChartUtil.auditMessage(reportId, msg);
                            }
                            this.exportChart({
                                type: 'image/svg+xml'
                            });
                        }
                    }]

                }
            }
            Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
                var path = [
                    // Arrow stem
                    'M', x + w * 0.5, y,
                    'L', x + w * 0.5, y + h * 0.7,
                    // Arrow head
                    'M', x + w * 0.3, y + h * 0.5,
                    'L', x + w * 0.5, y + h * 0.7,
                    'L', x + w * 0.7, y + h * 0.5,
                    // Box
                    'M', x, y + h * 0.9,
                    'L', x, y + h,
                    'L', x + w, y + h,
                    'L', x + w, y + h * 0.9
                ];
                return path;
            };  
            ChartInfo.functions.setChartTypeForMap(chartnumber, "pie");
            options.exporting.enabled = true;
        }
        // var chart = new Highcharts.Chart(options);
        var chart = this;
        charts[chartid] = chart;
        $("#" + chartid + " svg>title").remove();
        var chartnum = chartid.replace("chart", "");
        ChartInfo.functions.setChartSize(chartnum, chart);
        //Fix for textOutline for DataLabels
        if (chartSettings["AdvancedChartSettings"] != undefined) {
            var advanced = chartSettings["AdvancedChartSettings"];
            advanced.chart.type = chartType;
            ChartUtil.customTextOutline(advanced, chartnumber);
        }
       // ChartInfo.functions.setChartDataformap(chart, chartnum);
        var embedenable = $('.viewembedreportmodal .in').length;
        if (embedenable == 1) {
            setTimeout(function () {
                var chartno = chartid
                chartno = chartno.replace("chart", "");
                var acwidth = $('#' + chartid + ' .highcharts-container').width();

                if ($('.fullscreenmain .maxanim8').length == 1) {
                    var acheight = $('.maxanim1[data-num=' + chartno + ']').height() - 150;
                    $('#' + chartid).append("<img id='imageshot" + chartid + "' class='thumbimage' crossOrigin='Anonymous' style='z-index:9999;display: none;float: left;position: relative;top: 0;left: 0;width:" + acwidth + "px;max-height:" + acheight + "px;' width=" + acwidth + "   '>");
                } else {
                    var acheight = $('.maxanim1[data-num=' + chartno + ']').height() - 50;
                    $('#' + chartid).append("<img id='imageshot" + chartid + "' class='thumbimage' crossOrigin='Anonymous' style='z-index:9999;display: none;float: left;position: relative;top: 0;left: 0;width:" + acwidth + "px;max-height:" + acheight + "px;' width=" + acwidth + "   '>");
                }


                // URL to Highcharts export server
                var exportUrl = '//export.highcharts.com/';

                var thumbchart = chart.options;
                thumbchart.plotOptions.pie.dataLabels.format = "<b>{point.name}</b>: {point.percentage:.1f} %<br>({point.y:,.0f})"
                if ($('.fullscreenmain .maxanim8').length == 1) {

                    thumbchart.xAxis[0].labels.style.fontSize = '6px';
                    // thumbchart.xAxis[0].title.style.fontSize='8px';

                    thumbchart.yAxis[0].labels.style.fontSize = "6px";
                    // thumbchart.yAxis[0].title.style.fontSize='8px';

                    thumbchart.legend.itemStyle.fontSize = '6px';
                    setTimeout(function () {
                        thumbchart.xAxis[0].labels.style.fontSize = '11px';
                        thumbchart.yAxis[0].labels.style.fontSize = "11px";
                    }, 100);

                }
                else {

                    thumbchart.xAxis[0].labels.style.fontSize = '11px';
                    // thumbchart.xAxis[0].title.style.fontSize='8px';

                    thumbchart.yAxis[0].labels.style.fontSize = "11px";
                    // thumbchart.yAxis[0].title.style.fontSize='8px';

                    thumbchart.legend.itemStyle.fontSize = '8px';
                }


                // POST parameter for Highcharts export server
                var object = {
                    options: JSON.stringify(thumbchart),
                    type: 'image/png',
                    async: true,
                    width: acwidth,
                    //scale:0
                    //Height: acheight
                };
                $.ajax({
                    type: 'post',
                    url: exportUrl,
                    data: object,
                    success: function (data) {
                        // Update "src" attribute with received image URL
                        $('#imageshot' + chartid).attr('src', exportUrl + data);
                    }
                });
            }, 200);

            Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
                var path = [
                    // Arrow stem
                    'M', x + w * 0.5, y,
                    'L', x + w * 0.5, y + h * 0.7,
                    // Arrow head
                    'M', x + w * 0.3, y + h * 0.5,
                    'L', x + w * 0.5, y + h * 0.7,
                    'L', x + w * 0.7, y + h * 0.5,
                    // Box
                    'M', x, y + h * 0.9,
                    'L', x, y + h,
                    'L', x + w, y + h,
                    'L', x + w, y + h * 0.9
                ];
                return path;
            };  
        }
        return (
            <div style={{ height: "360px" }} ref="chartparent" >
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref="chartComponent"
                />
            </div>
        );
    }
}