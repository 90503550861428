import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import WorkspaceItemSettingsFilterModalHtml from './WorkspaceItemSettingsFilterModalHtml.jsx';
import * as ModalProperties from '../js/helpers/utils/modalproperties';
import WorkspaceAccordian from './WorkspaceAccordian';
const $ = window.$;

export default class AddFilters extends React.Component {
    constructor(props) {
        super(props);
        this.connId = this.props.connId;
        this.isCustomQuery = this.props.isCustomQuery;
        this.columns = this.props.columns;
        this.filters = this.props.filters;
        this.entityId = this.props.entityId;
        this.entityName = this.props.entityName;
        this.entitycollection = this.props.entitycollection;
    }
    componentDidMount() {
        $("#workspaceitemsfiltermodal").modal('show');
        _.each($(".rightfilter .acc-content"), function (element) {
            var child = $(element).attr('id').replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
            var parent = $(element).attr("data-parent")
            $(".reportfiltercheckbox[name='" + child + "']").prop('checked', true);
        });
        ModalProperties.resize();
    }
    loadFiltersView = () => {
        this.render();
    }
    WorkSpaceFilters = (e) => {
        var that = this;
        var filtersArray=document.getElementsByClassName("reportfiltercheckbox");
        var count=0;
        if(that.props.data_type=="existing"){
           var filtersData = that.entitycollection.getEntityFilters(that.entityName, that.props.connId)
            count=filtersData?filtersData.length:0;
        }
        $(".reportfiltercheckbox").each(function (element,index) {
            var $this = this;
            var type = $($this).attr("data-type");
            var tableName = $($this).attr("data-parent");
            var columnName = $($this).attr("data-child");
            var columnDisplayName = $($this).attr("name");
            if ($($this).is(":checked")) {
                if($('#filteraccordion' + " #accordion"+count +" div").length === 0){
                    $('#filteraccordion #accordion'+count).remove()
                                }
                if($('#filteraccordion' + " #accordion"+count).length === 0){
                $('#filteraccordion').append("<div data-accordion-id="+'accordion'+count+"' id="+'accordion'+count+"></div>");
                ReactDOM.render(
                    <WorkspaceAccordian
                        index={count}
                        isCustomQuery={that.props.isCustomQuery}
						tableName={tableName}
						columnName={columnName}
						columnDisplayName={columnDisplayName}
						columnType={type}
						entityId={that.entityId}
						entityName={that.entityName}
						fromrange={null}
						torange={null}
						filterArray={undefined}
						id={columnDisplayName}
						filterObject={''}
						script={null}
						connId={that.connId}
						daterange={undefined}
						customFields={"[]"}
						entitycollection ={ that.entitycollection}
                    />,
                    document.getElementById(`accordion${count}`)
                );
                }
            }
            else {
                tableName = $($this).attr("data-parent");
                columnDisplayName = $($this).attr("name");
                $("#workspaceitemsettingsfiltercontainer .acc-content[data-id='" + columnDisplayName + "']").remove();
            }
            count++;
        });
        $(".applyworkspacefilters").removeClass("disabled");
        // $("#workspaceitemsfiltermodal").modal("hide");
        $('#workspacefiltermodal').remove();
        $('.modal-backdrop').remove();
    }
    search = (e) => {
        var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $("#workspaceitemsfiltermodal input:checkbox[class='reportfiltercheckbox']").parents(".boc-list-item").each(function () {
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });
    }
    render() {
        var columns=this.columns;
        if(typeof columns == "string"){
            columns=JSON.parse(this.columns)
        }
        columns.sort(function(a, b){
            var x = a.columnDisplayName.toLowerCase();
            var y = b.columnDisplayName.toLowerCase();
             return x < y ? -1 : x > y ? 1 : 0 ;
          });


        return (
            <WorkspaceItemSettingsFilterModalHtml
                reportId={undefined}
                filterfields={columns}
                customfield={undefined}
                connId={this.connId}
                entityName={this.entityName}
                search={this.search}
                WorkSpaceFilters={this.WorkSpaceFilters}
            />
        );
    }
}