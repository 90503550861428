import React, { useEffect } from "react";
import _ from 'underscore';
import { lang } from "../js/helpers/utils/languages/language";
import { EditFilterModel } from "./editFilterModel";
import ReactDOM from "react-dom";
import * as ModalProperties from '../js/helpers/utils/modalproperties';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import WorkspaceAccordian from "../Workspace/WorkspaceAccordian";
import PubSub from 'pubsub-js';
import SendRequest from "../SendRequest";
const $ = window.$;
export const FiltersTab = (props) => {
    var { columns, entityName, isCustomQuery, connId,entityId,allColumns,tableName } = props;
    const {fetchData} = props;
    const isSurvey = window.location.href.indexOf("survey") >= 0 ? true : false;
    const isCampaign = window.location.href.indexOf("campaign") >= 0 ? true : false;
    useEffect(()=>{
         PubSub.subscribe('applyworkspacefilter', applyworkspacefilterEve,this);
         PubSub.subscribe('editdeleteaccordian', editdeleteaccordianEve,this);
         PubSub.subscribe('collapseOther', collapseOtherEve,this);
         return () => {
            PubSub.unsubscribe('applyworkspacefilter');
            PubSub.unsubscribe('editdeleteaccordian');
            PubSub.unsubscribe('collapseOther');
          };
    },[])
   const applyworkspacefilterEve = (msg, data)=>{
        let {currentTarget} = data;
        applyworkspacefilter(currentTarget,"pubsub")
    }
    const editdeleteaccordianEve = (msg, data)=>{
        let {currentTarget} = data;
        editdeleteaccordian(currentTarget,"pubsub")
    }
    const collapseOtherEve = (msg, data)=>{
        let {currentTarget} = data;
       collapseOther(currentTarget,"pubsub") 
    }
    const filterMenuToggle = () => {

    }
    const filterMenuToggleWarning = () => {
        if($(".tab-filters-container #filteraccordion [data-accordion-id]")!=undefined && $(".tab-filters-container #filteraccordion  .is-existing[data-accordion-id]")!=undefined && ($(".tab-filters-container #filteraccordion [data-accordion-id]").length != $(".tab-filters-container #filteraccordion  .is-existing[data-accordion-id]").length)){
            $(".queryinfomodal.modalfilter.singlefilterdropdown").addClass('hide');
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage("BirdWarning160"));
            return false
        }else{
            if(isCampaign) return true;
            $(".queryinfomodal.modalfilter.singlefilterdropdown").removeClass('hide');
        }
    }
    const collapseOther = (e,isFrom)=>{
        var $this;
        if(isFrom && isFrom==="pubsub"){
            $this = e;
        }else{
            $this = e.currentTarget;
        }
        var currentElementID = $($this).parent().find(".panel-collapse").attr("id");
        $("#filteraccordion" + " .panel-collapse.in").not("#" + currentElementID).collapse('hide');
        

        //set panel position
        var currentElementPosition = $($this).offset().left,
            windowWidth = $(window).width(),
            isRightMenuOpen = $("body").hasClass("rightpanelopen");
        if (isRightMenuOpen) {
            if ((windowWidth - currentElementPosition) > 675) {
                $($this).parent().find('.panelboc-content').css({ 'left': currentElementPosition, 'right': "auto" });
            } else {
                $($this).parent().find('.panelboc-content').css({ 'right': "380px", "left": "auto" });
            }
        } else {
            if ((windowWidth - currentElementPosition) > 300) {
                $($this).parent().find('.panelboc-content').css({ 'left': currentElementPosition, 'right': "auto" });
            } else {
                $($this).parent().find('.panelboc-content').css({ 'right': "5px", "left": "auto" });
            }
    }
}
    const editdeleteaccordian=(e,isFrom)=>{
        var temp;
        if(isFrom && isFrom=="pubsub"){
            temp = e;
        }else{
            temp=e.currentTarget;
        }
        var that=this;
        var filterDataObj = ChartInfo.functions.getEditFilterArray();
        var values = [];
        let columnsArray = [];
        let splicedArray = [...filterDataObj];
        if(filterDataObj!=undefined){
            var oldName = temp.name;
            _.each(filterDataObj,function(obj,i){
                if(obj.columndisplayname != temp.name ){
                    values.push(filterDataObj[i]);
                }
            })
        }
        var filterOrder = []; let has_match = false;
        $(".tab-filters-container #filteraccordion  [data-accordion-id]").each(function(index,a){
            var name = $(a).find('.acc-content').attr("data-id");
            if(name ==temp.name){
                has_match = true;
            }
            if(has_match && name!=temp.name){
                filterOrder.push(name);
            }
        });
        let resultArray = [];
        splicedArray.map((val,index)=>{
            if(val.columndisplayname == temp.name){
                let splice_position = index+1;
                resultArray = splicedArray.splice(splice_position,splicedArray.length-splice_position);
                return
            }
        })
        if(splicedArray!=undefined && splicedArray.length>0){
            values = [];
            var oldName = temp.name;
            _.each(splicedArray,function(obj,i){
                if(obj.columndisplayname != temp.name ){
                    values.push(filterDataObj[i]);
                }
            })
        }
        ChartInfo.functions.setEditFilterArray(values);
        let accordian_map = {};
        resultArray.map((val)=>{
            let columndisplaynames = val.columndisplayname
            let accordionid = $(".rightfiltersapply#rightfiltersapplys[data-columnname='"+columndisplaynames+"']").attr('data-accordionid');
            accordian_map[columndisplaynames] = accordionid
           ReactDOM.unmountComponentAtNode(document.getElementById(accordionid));
           columnsArray.push(columndisplaynames)
        })
        if(filterOrder.length>0 && filterOrder.length>columnsArray.length){
            $(".tab-filters-container #filteraccordion  [data-accordion-id]").each(function(index,a){
                let name = $(a).find('.acc-content').attr("data-id");
                if(filterOrder.indexOf(name)!=-1 && name!=temp.name){
                let tablename = $(a).find('.acc-content').attr("data-parent");
                let accordionid = $(a).find(".rightfiltersapply#rightfiltersapplys").attr('data-accordionid');
                let column_name = $(a).find(".rightfiltersapply#rightfiltersapplys").attr('data-columnname');
                let column_names = $(a).find(".rightfiltersapply#rightfiltersapplys").attr('data-name');
                if(accordionid && column_name){
                if(columnsArray.indexOf(column_name)==-1){
                    let data_type = $(a).find(".rightfiltersapply#rightfiltersapplys").attr('data-type');
                    let obj={};
                    obj["dataType"] = data_type;
                    obj["tablename"] = tablename;
                    obj["columndisplayname"] = name;
                    obj["columnname"] = column_names;
                    if($(a).hasClass('is-existing'))
                        accordian_map[name] = accordionid
                    else
                        obj["accordion"] = accordionid;
                    resultArray.push(obj);
                    columnsArray.push(name);
                    ReactDOM.unmountComponentAtNode(document.getElementById(accordionid));
                }
            }
            }
            });}

        resultArray.map((val)=>{
            let columndisplaynames = val.columndisplayname
            let accordionid = accordian_map[columndisplaynames]?accordian_map[columndisplaynames]:val["accordion"]?val["accordion"]:accordian_map[columndisplaynames];
            var type = val["dataType"];
            var tableName = val["tablename"];
            var columnName = val["columnname"];
            var columnDisplayName = val["columndisplayname"]
                let count = parseInt(accordionid.replace("accordion",""));
                ReactDOM.render(
                    <WorkspaceAccordian
                        index={count}
                        isCustomQuery={isCustomQuery}
						tableName={tableName}
						columnName={columnName}
						columnDisplayName={columnDisplayName}
						columnType={type}
						entityId={entityId}
						entityName={entityName}
						fromrange={null}
						torange={null}
						filterArray={undefined}
						id={columnDisplayName}
						filterObject={''}
						script={null}
						connId={connId}
						daterange={undefined}
						customFields={"[]"}
						entitycollection ={ []}
                        applyeditworkspacefilter={applyworkspacefilter}
                        editdeleteaccordian={editdeleteaccordian}
                        collapseOther={collapseOther}
                    />,
                    document.getElementById(accordionid)
                );
 
        })
        
        
        let accordion = $(".rightfiltersapply#rightfiltersapplys[data-columnname='"+temp.name+"']").attr('data-accordionid');
        $("#"+accordion).remove();
        $(e.currentTarget).parent().parent().parent().parent().parent().remove();
        $(".spinner").show();
        fetchData(values);
        if(Object.keys(accordian_map).length>0){
            let dependent_filters = "";
            Object.keys(accordian_map).map(val=>dependent_filters+=val+",");
            dependent_filters = dependent_filters.substring(0,dependent_filters.lastIndexOf(","));
            // $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage("BirdWarning159").replace("@replace", dependent_filters));
            return
        }
    }
    const applyworkspacefilter = (e,isFrom) => {
        var $this; 
        if(isFrom && isFrom=="pubsub"){
            $this= e;    
        }else{
        $this= e.currentTarget;
        e.preventDefault();
        }
        $("#campaignFilters .filter-count").empty().hide();
        var that = this;
        /** re-applying dependent filters */
        let columndisplayname = $($this).attr('data-columnname');
        let filterArray = ChartInfo.functions.getEditFilterArray();
        let splicedArray = [...filterArray];
        let resultArray = [];
        splicedArray.map((val,index)=>{
            if(val.columndisplayname == columndisplayname){
                let splice_position = index+1;
                resultArray = splicedArray.splice(splice_position,splicedArray.length-splice_position);
                return
            }
        })
        let columnsArray = [];
        let accordian_map = {};
        columnsArray.push(columndisplayname);
        splicedArray.map((val)=>{
            if(columnsArray.indexOf(val.columndisplayname)==-1)
                columnsArray.push(val.columndisplayname)
        })
        var filterOrder = [];
        $(".tab-filters-container #filteraccordion  [data-accordion-id]").each(function(index,a){
                var name = $(a).find('.acc-content').attr("data-id");
                filterOrder.push(name);
            });
        resultArray.map((val)=>{
            let columndisplaynames = val.columndisplayname
            let accordionid = $(".rightfiltersapply#rightfiltersapplys[data-columnname='"+columndisplaynames+"']").attr('data-accordionid');
            if(accordionid){
            accordian_map[columndisplaynames] = accordionid
            if(columnsArray.indexOf(val.columndisplayname)==-1)
                columnsArray.push(val.columndisplayname)
           ReactDOM.unmountComponentAtNode(document.getElementById(accordionid));
            }
        })
        
        if(columnsArray.length>0 && filterOrder.length>columnsArray.length){
        $(".tab-filters-container #filteraccordion  [data-accordion-id]").each(function(index,a){
            let name = $(a).find('.acc-content').attr("data-id");
            let tablename = $(a).find('.acc-content').attr("data-parent");
            let accordionid = $(a).find(".rightfiltersapply#rightfiltersapplys").attr('data-accordionid');
            let column_name = $(a).find(".rightfiltersapply#rightfiltersapplys").attr('data-columnname');
            let column_names = $(a).find(".rightfiltersapply#rightfiltersapplys").attr('data-name');
            if(accordionid && column_name){
            if(columnsArray.indexOf(column_name)==-1){
                let data_type = $(a).find(".rightfiltersapply#rightfiltersapplys").attr('data-type');
                let obj={};
                obj["dataType"] = data_type;
                obj["tablename"] = tablename;
                obj["columndisplayname"] = name;
                obj["columnname"] = column_names;
                if($(a).hasClass('is-existing'))
                    accordian_map[name] = accordionid
                else
                    obj["accordion"] = accordionid;
                resultArray.push(obj);
                columnsArray.push(name);
                ReactDOM.unmountComponentAtNode(document.getElementById(accordionid));
            }
        }
        });}
        if (isCustomQuery) {
            applyworkspacefilters("wscsq", entityName);
        } else {
            applyworkspacefilters(connId, entityName);
        }
        resultArray.map((val)=>{
            let columndisplaynames = val.columndisplayname
            let accordionid = accordian_map[columndisplaynames]?accordian_map[columndisplaynames]:val["accordion"]?val["accordion"]:accordian_map[columndisplaynames];
            var type = val["dataType"];
            var tableName = val["tablename"];
            var columnName = val["columnname"];
            var columnDisplayName = val["columndisplayname"]
            if(accordionid){
                let count = parseInt(accordionid.replace("accordion",""));
                ReactDOM.render(
                    <WorkspaceAccordian
                        index={count}
                        isCustomQuery={isCustomQuery}
						tableName={tableName}
						columnName={columnName}
						columnDisplayName={columnDisplayName}
						columnType={type}
						entityId={entityId}
						entityName={entityName}
						fromrange={null}
						torange={null}
						filterArray={undefined}
						id={columnDisplayName}
						filterObject={''}
						script={null}
						connId={connId}
						daterange={undefined}
						customFields={"[]"}
						entitycollection ={ []}
                        applyeditworkspacefilter={applyworkspacefilter}
                        editdeleteaccordian={editdeleteaccordian}
                        collapseOther={collapseOther}
                    />,
                    document.getElementById(accordionid)
                );
                }
        })
        if(Object.keys(accordian_map).length>0){
            let dependent_filters = "";
            Object.keys(accordian_map).map(val=>dependent_filters+=val+",");
            dependent_filters = dependent_filters.substring(0,dependent_filters.lastIndexOf(","));
            // $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage("BirdWarning159").replace("@replace", dependent_filters));
            return
        }
        /** re-applying dependent filters */
    }
    const getValues = (obj) => {
        var values = Object.keys(obj).map(function (e) {
            return obj[e]
        });
        return values;
    }
    const addFilters = (connId, entityName, stringClassName, numberClassName, dateClassName) => {
        var filterArray = [];
        var filtersKeyValueMap = {};
        var filtersCount = 0;
        var that = this;
        var isValid = true;
        $(stringClassName).each(function () {
            var $this = this;
            var filterObject = {};

            if ($($this).is(":checked")) {
                var columnName = $($this).attr("data-child");
                var myObjects = Object.keys(filtersKeyValueMap).map(function (itm) { return filtersKeyValueMap[itm]; });
                if (filtersKeyValueMap != undefined && myObjects != undefined
                    && filtersKeyValueMap[$($this).attr("data-child")] != undefined && Object.keys(filtersKeyValueMap[$($this).attr("data-child")]).length > 0) {
                    filtersKeyValueMap[$($this).attr("data-child")]["data"] += "@@" + $($this).parent().text();
                } else {
                    filterObject["dataType"] = $($this).attr("data-type");
                    filterObject["tablename"] = $($this).attr("data-parent");
                    filterObject["columndisplayname"] = $($this).attr("displayname");
                    filterObject["columnname"] = $($this).attr("data-child");
                    filterObject["data"] = $($this).parent().text();
                    filterObject["reportfiltersid"] = filtersCount + 1;
                    filtersKeyValueMap[$($this).attr("data-child")] = filterObject;
                    filterArray.push(filterObject);
                }
            }
        });
        $(numberClassName).each(function () {
            var filterObject = {};
            var $this = this;
            var th = $($this);
            var displayname = $($($this).find("input")).attr("displayname").replace(/([#;?&,%.+*~\':"!^$%[\]()<=>|\/@\s])/g, "");
            var filterlessthanvalue = $(numberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filterlessthanvalue").val();
            var filtergreaterthanvalue = $(numberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filtergreaterthanvalue").val();
            var columnName = $($($this).find("input")).attr("data-child");
            var isCustomField = false;

            if ($($($this).find("input")).attr("data-type") === "number") {
                filterObject["dataType"] = $($($this).find("input")).attr("data-type");
                filterObject["tablename"] = $($($this).find("input")).attr("data-parent");
                filterObject["columndisplayname"] = $($($this).find("input")).attr("displayname");
                filterObject["columnname"] = $($($this).find("input")).attr("data-child");
                if ((filterlessthanvalue == "" && filtergreaterthanvalue == "")) {
                    filterObject["fromRange"] = $(numberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').old_from;
                    filterObject["toRange"] = $(numberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').old_to;
                }
                else {
                    filterObject["filtergreaterthanarequalvalue"] = filtergreaterthanvalue;
                    filterObject["filterlessthanarequalvalue"] = filterlessthanvalue;
                }
                filterObject["customfiltervalue"] = $($($this).find("input[type='text']")).val();
                filtersKeyValueMap[$($this).attr("data-child")] = filterObject;
                filterArray.push(filterObject);
            }
        });
        $(dateClassName + "[data-type = 'date']").each(function () {
            var $this = this;
            var filterObject = {};
            filterObject["dataType"] = $($this).attr("data-type");
            filterObject["tablename"] = $($this).attr("data-parent");
            filterObject["columndisplayname"] = $($this).attr("displayname");
            filterObject["columnname"] = $($this).attr("data-child");
            filterObject["daterange"] = $($this).attr("date-range");
            var text = $($this).text();
            var dateArray = text.split("-");
            var fromdate = dateArray[0].trim();//Date.parse(dateArray[0].trim());
            var todate = dateArray[1].trim() //Date.parse(dateArray[1].trim());
            if (filterObject["daterange"] == "custom") {
                var fromdate = dateArray[0].trim() + " 00:00:00";
                var todate = dateArray[1].trim() + " 23:59:59";
            }
            filterObject["fromRange"] = fromdate;
            filterObject["toRange"] = todate;
            filtersKeyValueMap[$($this).attr("data-child")] = filterObject;
            filterArray.push(filterObject);
        });
        if (getValues(filtersKeyValueMap) === undefined || getValues(filtersKeyValueMap).length === 0) {
            isValid = false;
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning128'));
            return
        }
        /*if (isValid) {
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Success');
            $('.birdmessage-info').empty().text(getMessage('BirdSuccess56'));
        }*/
        filterArray.map(filter=>{
            if(filter.columndisplayname){
            var filtercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
            if(filter["data"] && filter.dataType && filter.dataType==="string"){
                let filtereddatatext = filter["data"].replace(/\@@/g, ', ');
                var unCheckedFilterItems = $(".filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)").length;
                if (unCheckedFilterItems == 0) {
                        $('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("All").attr("title", "All");
                }else{
                    $('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
                }
                }else if(filter.dataType && filter.dataType==="date"){
                    var dates = $('#filteraccordion .daterange-center[displayname="' + filter.columndisplayname + '"] span').text();
							$('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(dates).attr("title", dates);
                }else if(filter.dataType && filter.dataType==="number"){
                    var fromRange = filter.fromRange,toRange = filter.toRange
							if (fromRange == undefined && toRange == undefined) {
								fromRange = filter.filtergreaterthanarequalvalue;
								toRange = filter.filterlessthanarequalvalue;
							}
                            $('#filteraccordion .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(fromRange + " - " + toRange).attr("title", fromRange + " - " + toRange);
                }
            }
        })
        var filterOrder = [];
        $(".tab-filters-container #filteraccordion  [data-accordion-id]").each(function(index,a){
                var name = $(a).find('.acc-content').attr("data-id");
                let accordion = $(a).find(".rightfiltersapply#rightfiltersapplys").attr('data-accordionid');
                $("#"+accordion).addClass('is-existing');
                filterOrder.push(name);
            });
            if(filterOrder!=undefined && filterOrder.length!=0){
                filterArray = _.sortBy(filterArray, function(obj){ 
                                    return _.indexOf(filterOrder, obj.columndisplayname);
                                   });
            }
        ChartInfo.functions.setEditFilterArray(filterArray);
        $(".spinner").show();
        props.fetchData(filterArray);
        $("#filteraccordion .panel-collapse.in").collapse('hide');
        if(isSurvey){
            props.history.push(`/${window.localStorage.getItem("appName")}/welcome/survey/`+btoa(entityId));
        }else if(isCampaign){
            props.history.push(`/${window.localStorage.getItem("appName")}/welcome/campaign/`+btoa(entityId));
        }else{
            props.history.push(`/${window.localStorage.getItem("appName")}/welcome/edit_data/`+btoa(entityId));
        }
    }
    const getFilteredCount = () =>{
        let filters = ChartInfo.functions.getEditFilterArray();
        let form_data = new FormData();
        form_data.append("entityname", entityName);
        form_data.append("tablename", tableName);
        form_data.append("hubId",  "");
        form_data.append("filters", (filters !== undefined) ? JSON.stringify(filters) : "");
        form_data.append("customfields",  "");
        form_data.append("connId", (connId !== undefined) ? connId : "");
        form_data.append("workspaceId", 0);
        form_data.append("customquery",isCustomQuery ? isCustomQuery : false);
        form_data.append("columns", JSON.stringify(columns));
        form_data.append("fetchType", 'dataCount');
        SendRequest({
            url: "getworkspacePreviewData",
            queryString: "",
            body: form_data,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.error)
                    setTimeout(function () {
                        $('.birderror').remove();
                    }, 3000);
                } else {
                    let res = response[0];
                    let {data} = res;
                    let {datacount} = data
                    $(".filter-count").html(`<i class="fa fa-users fa-gold"></i> Voter Count <span>${datacount}</span>`)
                    $(".filter-count").show();
                }
            },
            rejectFunction: (error) => {$(".spinner").hide();},
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    const applyworkspacefilters = (connId, entityName) => {
        var that = this;
        var stringClassName = ".filtervalue";
        var numberClassName = ".filterrangevalues";
        var dateClassName = ".daterange-center";

        addFilters(connId, entityName, stringClassName, numberClassName, dateClassName);
    }
    const addfilter = (e, props) => {
        if(isCampaign){
            let bool = filterMenuToggleWarning();
            if(!bool) return;
        }
        $("#filtermodal").remove();
        $("body").append('<div id="filtermodal" class="editcustomfilter"></div>')
        var filterfields = [];
        const { columns, entityName, tableName,allColumns } = props;
        _.each(allColumns, function (column) {
            if (column.name != "edit") {
                var viewreportaddfiltermodel = {};
                viewreportaddfiltermodel["tableName"] = tableName;
                viewreportaddfiltermodel["tablename"] = tableName;
                viewreportaddfiltermodel["columnDisplayType"] = column.type;
                viewreportaddfiltermodel["tableDisplayType"] = column.type;
                viewreportaddfiltermodel["columnName"] = column.name;
                viewreportaddfiltermodel["orginalColumnName"] = column.name;
                viewreportaddfiltermodel["columnDisplayName"] = column.name;
                viewreportaddfiltermodel["priorityColumn"] = false;
                filterfields.push(viewreportaddfiltermodel);
            }
        });
        ReactDOM.render(<EditFilterModel filterfields={filterfields} connId={""} {...props} applyworkspacefilter={applyworkspacefilter} editdeleteaccordian={editdeleteaccordian} collapseOther={collapseOther}/>, document.getElementById('filtermodal'));
        $("#workspaceitemsfiltermodal").modal('show');
        _.each($(".rightfilter .acc-content"), function (element) {
            var child = $(element).attr('id').replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
            var parent = $(element).attr("data-parent")
            // $(".reportfiltercheckbox[name='" + child + "']").prop('checked', true);
            $(".reportfiltercheckbox[data-name='" + child + "']").closest('li').hide();
        });
        ModalProperties.resize();
    }

    return (
        /** Filters Start */
        <div className="tab-filters-container">
            <div className="gridload"><div></div><div></div><div></div><div></div></div>
            {isCampaign == true ?
            <>
                <div className="col-xs-12 nopadding">
                    <label className="select-template">
                        <h4 className="name-label">Choose your Voter Audience</h4>
                    </label>
                </div>
                <div className="datamain-fixed-head-container">
                    <span className="subhead">
                        <i className="fa fa fa-filter marg-righ-10"></i>
                        <span className="">Filters</span>
                    </span>
                    <button className="btn btn-bird active pull-right " onClick={getFilteredCount} title="Get Voter Count"><i className="fa fa-users"></i> Get Voter Count</button>
                    <button className="btn btn-bird-group active pull-right marg-righ-10" onClick={e => addfilter(e, props)} title="Add Filter"><i className="fa fa-plus"></i> Add Filter</button> 
                    <span class="filter-count"></span>
                </div>
            </>
                : ""}
            {isCampaign == false ?    
            <>
            <div className={`${'filteritem dropdown-toggle '}`} data-direction="left" data-toggle="dropdown" onClick={filterMenuToggleWarning} aria-expanded="true" title={lang["reports.filters"]}><i className="fa fa-filter"></i> {lang["reports.filters"]}</div>
            <ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
                <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlefilterdropdown" id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addfilter"]} onClick={e => addfilter(e, props)}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addfilter"]}</a></li>
                {/* <li><a href="javascript:void(0)" className="queryinfomodal modalfilter singlecustomparameterfilterdropdown" id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" onClick={addCustomParamerFilter} title={lang["reports.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["reports.addparameter"]}</a></li> */}
            </ul>
            </>
               : ""}
            <div className="panel-group rightfilter filteritem-container" id="filteraccordion"><div className="instant-filter-tags addInstanceFilterData"></div><div className="instant-filter-tags addURLInstanceFilterData"></div></div>
            <div className="filter-menu-toggle"><span onClick={filterMenuToggle} title={lang["reports.showhidefilters"]}><i className="fa fa-angle-up"></i></span></div>
        </div>
        /** Filters End */
    )
}