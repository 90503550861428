import React from "react";
import ReactDOM from "react-dom";
import _ from 'lodash';
import moment from 'moment';
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from "../SendRequest";
import "twbs-pagination/jquery.twbsPagination";
import "bootstrap-table/dist/bootstrap-table";
import "bootstrap-table/dist/bootstrap-table.min.css";
import WorkspaceSyncLogBookHistoryBootstrapTemplate from './WorkspaceSyncLogBookHistoryBootstrapTemplate.jsx'
const $ = window.$
export default class DatahubSyncLogBookHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            WSID: props.WSID,
            connectionName: props.connectionName
        }
    }
    componentDidMount() {
        this.getSyncLogs(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    }
    closepopup() {
        $(".spinner").hide();
        $("#workspacesyncloghistorypop").empty();
        ReactDOM.unmountComponentAtNode(document.querySelector('.synclogbookhistorycontainer'));
    }
    getSyncLogs(e, page, startDate, endDate, search, pageDisplay, order, colName) {
        if (e != undefined) {
            e.stopPropagation();
        }
        if (startDate == undefined) {
            startDate = moment().subtract(29, 'days');
        }
        if (endDate == undefined) {
            endDate = moment();
        }
        $(".spinner").show();
        var that = this;
        var index = 0;
        var noOfRecords = $(".syncloghistoryrowlimtbutton .page-size").text() == "" ? 10 : $(".syncloghistoryrowlimtbutton .page-size").text();
        var totalRecords = 0;

        if (page != undefined && page > 1) {
            index = (page - 1) * noOfRecords;
        }
        if (order == undefined) {
            order = 'asc';
        }
        if (colName == undefined) {
            colName = '';
        }
        this.dataFieldValue = colName;
        this.sortingOrder = order;
        this.startDate = startDate;
        this.endDate = endDate;
        this.page = page;
        var that = this
        let requestHeaders1 = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        var formdata = new FormData();
        formdata.set('WSID', atob(this.props.WSID));
        formdata.set('fromDate', startDate.format());
        formdata.set('toDate', endDate.format());
        formdata.set('index', index);
        formdata.set('noOfRecords', noOfRecords)
        formdata.set('searchCriteria', search == undefined ? "" : search)
        formdata.set('order', order)
        formdata.set('colName', colName)
        formdata.set('connectionName', this.state.connectionName)
        SendRequest({
            url: "getDataWorkspaceSyncLogsHistory",
            body: formdata,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                } else {
                    $(".spinner").hide();
                    var headerlist = response["header"];
                    var dataTypeslist = response["dataTypes"];
                    var data = response["data"];
                    totalRecords = response["totalRecords"];
                    var keys = Object.keys(headerlist);
                    _.each(data, function (val, index) {
                        if (val.data_sync_details != null && val.data_sync_details != '') {
                            var data_sync_details = JSON.parse(val.data_sync_details);
                            if (data_sync_details != undefined && data_sync_details != null && data_sync_details != '') {
                                val.data_sync_details = "<span title='" + val.data_sync_details + "'>" + data_sync_details.cronDetails + "</span>"
                            }
                        }
                        if (val.sync_status_reason != null && val.sync_status_reason != '') {
                            var sync_status_reason = val.sync_status_reason;
                            if (sync_status_reason != undefined && sync_status_reason != null && sync_status_reason != '') {
                                val.sync_status_reason = "<span class=data_sync_status_"+ (sync_status_reason.toLowerCase() === 'success' ? sync_status_reason.toLowerCase() : "failed") +" title='" + (sync_status_reason.toLowerCase() === 'success' ? "Success" : sync_status_reason) + "'>" + (sync_status_reason.toLowerCase() === 'success' ? sync_status_reason : "Failed") + "</span>"
                            }
                        }
                    });
                    $("#workspacesyncloghistorypop").empty();
                    let today = new Date();
                    let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $("#workspacesyncloghistorypop").append("<div  id='" + dynamicdiv + "'></div>");
                    ReactDOM.render(<WorkspaceSyncLogBookHistoryBootstrapTemplate keys={keys} headerlist={headerlist} search={search == undefined ? "" : search} dataTypeslist={dataTypeslist} />, document.getElementById(dynamicdiv))
                    $('#daterange-filter:nth-child(1n) span').html(startDate.format('MMMM D, YYYY') + ' - ' + endDate.format('MMMM D, YYYY'));
                    $("#daterange-filter").daterangepicker({
                        format: 'MM/DD/YYYY',
                        startDate: startDate,
                        endDate: endDate,
                        minDate: '01/01/1970',
                        maxDate: '',
                        showDropdowns: true,
                        showWeekNumbers: true,
                        timePicker: false,
                        timePickerIncrement: 1,
                        timePicker12Hour: true,
                        linkedCalendars: false,
                        ranges: {
                            'Today': [moment(), moment()],
                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                        },
                        opens: 'right',
                        drops: 'down',
                        buttonClasses: ['btn'],
                        applyClass: 'btn-bird',
                        cancelClass: 'btn-bird',
                        separator: ' to ',
                        locale: {
                            applyLabel: lang["workspace.submitbtn"],
                            cancelLabel: lang["workspace.cancelbtn"],
                            fromLabel: 'From',
                            toLabel: 'To',
                            customRangeLabel: 'Custom',
                            daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                            monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                            firstDay: 1,
                        }
                    }, function (start, end, label) {
                        $('#daterange-filter:nth-child(1n) span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
                        var pageDisplay = $(".syncloghistoryrowlimtbutton .page-size").text();
                        that.getSyncLogs(e, undefined, start, end, that.searchValue, pageDisplay, that.sortingOrder, that.dataFieldValue);
                    });
                    $('.daterangepicker.dropdown-menu').addClass("daterangefixright");
                    $('.daterangepicker').css('z-index', '9999');
                    $(".syncloghistoryrowlimtul li").removeClass('active');
                    $(".syncloghistoryrowlimtul li[value=" + noOfRecords + "]").addClass('active');
                    $(".syncloghistoryrowlimtbutton .page-size").text(noOfRecords);

                    //load Data
                    var $table = $(".syncloghistory table");
                    $table.bootstrapTable({ height: 500 });
                    $(".syncloghistory .table>thead>tr>th>.th-inner").addClass("sortable both")
                    $table.bootstrapTable('load', data);
                    $table.bootstrapTable('resetView');
                    $(window).on("resize", function () {
                        $table.bootstrapTable('resetView');
                        if (that.dataFieldValue != undefined && that.dataFieldValue != "") {
                            $(".syncloghistory table>thead>tr>th[data-field=" + that.dataFieldValue + "]>div.sortable").removeClass('asc').removeClass('desc').addClass(that.sortingOrder);
                        }
                    });
                    $('.spinner').hide();
                    //Show sorting in UI
                    if (that.dataFieldValue != undefined && that.dataFieldValue != "") {
                        $(".syncloghistory table>thead>tr>th[data-field=" + that.dataFieldValue + "]>div.sortable").removeClass('asc').removeClass('desc').addClass(that.sortingOrder);
                    }

                    var totalPages;
                    if (pageDisplay == undefined) {
                        pageDisplay = 10;
                    }
                    if (totalRecords > 10) {
                        totalPages = Math.ceil(totalRecords / pageDisplay);
                    } else {
                        totalPages = 1;
                    }
                    if ($('#pagination-view-history').data("twbs-pagination")) {
                        $('#pagination-view-history').twbsPagination('destroy');
                    }
                    var currentpage = page;
                    if (currentpage == undefined) {
                        currentpage = 1;
                    }
                    $('#pagination-view-history').twbsPagination({
                        totalPages: totalPages,
                        visiblePages: 5,
                        startPage: currentpage,
                        first: "«",
                        prev: "‹",
                        next: "›",
                        last: "»"
                    }).on('page', function (evt, page) {
                        var pageDisplayPage = $(".syncloghistoryrowlimtbutton .page-size").text();
                        that.getSyncLogs(e, page, startDate, endDate, that.searchValue, pageDisplayPage, that.sortingOrder, that.dataFieldValue);
                    });
                    $(".syncloghistoryrowlimtul li").click(function (e) {
                        that.rowlimitbutton(e)
                    })
                    $(".searchsyncloghistorytable").keyup(function (e) {
                        that.search(e)
                    })
                    $(".syncloghistory table>thead>tr>th").click(function (e) {
                        that.sorting(e)
                    });

                    if (that.searchValue) {
                        $.fn.setCursorPosition = function (pos) {
                            this.each(function (index, elem) {
                                if (elem.setSelectionRange) {
                                    elem.setSelectionRange(pos, pos);
                                } else if (elem.createTextRange) {
                                    var range = elem.createTextRange();
                                    range.collapse(true);
                                    range.moveEnd('character', pos);
                                    range.moveStart('character', pos);
                                    range.select();
                                }
                            });
                            return this;
                        };
                        $('.searchsyncloghistorytable').focus().setCursorPosition(that.searchValue.length);
                        $('.searchsyncloghistorytable').get(0).scrollLeft = $('.searchsyncloghistorytable').get(0).scrollWidth;
                    } else {
                        $('.searchsyncloghistorytable').focus();
                    }
                }
            },
            rejectFunction: () => { },
            headers: requestHeaders1,
        });

    }
    rowlimitbutton = (e) => {
        var $this = e.currentTarget;
        $(".syncloghistoryrowlimtul li").removeClass('active');
        var value = $($this).find('a').text();
        $($this).addClass('active');
        this.searchValue = $(".searchsyncloghistorytable").val();
        $(".syncloghistoryrowlimtbutton .page-size").text(value);
        this.getSyncLogs(e, undefined, this.startDate, this.endDate, this.searchValue, value, this.sortingOrder, this.dataFieldValue);
    }
    search = (e) => {
        var that = this;
        /*Delay function for to wait until user stops typing*/
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        /*Delay function for to wait until user stops typing*/
        var asc = $(".usersgroupsaudit .table>thead>tr>th>div.asc").parent().attr("data-field");
        var desc = $(".usersgroupsaudit .table>thead>tr>th>div.desc").parent().attr("data-field");
        if (asc != undefined) {
            that.dataFieldValue = asc;
            that.sortingOrder = 'asc';
        } else if (desc != undefined) {
            that.dataFieldValue = desc;
            that.sortingOrder = 'desc';
        }
        $(".searchsyncloghistorytable").keyup(function () {
            var search = $(e.currentTarget).val().toLowerCase();
            var pageDisplaySearch = $(".syncloghistoryrowlimtbutton .page-size").text();
            delay(function () {
                that.searchValue = $(".searchsyncloghistorytable").val();
                that.getSyncLogs(e, undefined, that.startDate, that.endDate, that.searchValue, pageDisplaySearch, that.sortingOrder, that.dataFieldValue);
            }, 1000);
        });
        $(".searchsyncloghistorytable").focus();
    }
    sorting = (e) => {
        var $this = e.currentTarget;
        $('.spinner').show();
        if ($($this).find(".th-inner").hasClass("asc")) {
            $(".syncloghistory .table>thead>tr>th>.th-inner").removeClass("asc desc");
            $($this).find(".th-inner").removeClass("asc").addClass("desc");
        } else if ($($this).find(".th-inner").hasClass("desc")) {
            $(".syncloghistory .table>thead>tr>th>.th-inner").removeClass("asc desc");
            $($this).find(".th-inner").removeClass("desc").addClass("asc");
        } else {
            $(".syncloghistory .table>thead>tr>th>.th-inner").removeClass("asc desc");
            $($this).find(".th-inner").removeClass("asc").addClass("desc")
        }
        var that = this;
        var asc = $(".syncloghistory .table>thead>tr>th>div.asc").parent().attr("data-field");
        var desc = $(".syncloghistory .table>thead>tr>th>div.desc").parent().attr("data-field");
        if (asc != undefined) {
            that.dataFieldValue = asc;
            that.sortingOrder = 'asc';
        } else if (desc != undefined) {
            that.dataFieldValue = desc;
            that.sortingOrder = 'desc';
        }
        var pageDisplaySearch = $(".syncloghistoryrowlimtbutton .page-size").text();
        var searchValue = $(".searchsyncloghistorytable").val();
        that.getSyncLogs(e, that.page, that.startDate, that.endDate, searchValue, pageDisplaySearch, that.sortingOrder, that.dataFieldValue);

    }
    render() {
        var that = this.state;

        return (
            <div>
                {/* <!-- DatahubSyncLogBookHistory.js --> */}
                <div className='pivotdataviewwrap'>
                    <div className="pivotdataview">
                        <div className="pivotdataviewclose" onClick={this.closepopup} id="pivotdataviewdatapop">
                            <i className='fa fa-times'></i>
                        </div>
                        <div className="pivottile">{lang["workspace.synopsis"]}</div>
                        <div className="container-fluid marg-bot-10">
                            <div className="row">
                                <div id="workspacesyncloghistorypop"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}