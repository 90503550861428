import React, { Component } from "react";
import _ from 'lodash';
//import $ from 'jquery';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d';
import PubSub from 'pubsub-js'
import drilldow from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import HighchartsMore from 'highcharts/highcharts-more'
import { whitelableMap } from "../../js/helpers/utils/whitelable";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import ReactDOM from "react-dom";
const $ = window.$;

export default class MetricChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var data = this.props.data, xaxisname = this.props.xaxisname, xaxisColumnName = this.props.xaxisColumnName, xaxisDataType = this.props.xaxisDataType, yaxisnameArray = this.props.yaxisnameArray, chartid = this.props.chartid, chartType = this.props.chartType, legend = this.props.legend, position = this.props.position, metrictype = this.props.metrictype, metricdatefield = this.props.metricdatefield, tileno = this.props.tileno, colorThemeIndex = this.props.colorThemeIndex, chartSettings = this.props.chartSettings, metricprefix = this.props.metricprefix, metricsuffix = this.props.metricsuffix, iscustomstoryboard = this.props.iscustomstoryboard, trendstats = this.props.trendstats, metricfont = this.props.metricfont;
        var colorThemesArray = ChartInfo.functions.getColorThemes();
        var metriccolor = colorThemesArray[colorThemeIndex][0];
        var prioritycolor = "";
        var userLang = navigator.language || navigator.userLanguage;
        var locale = "en_za";
        var names = "en_za";
        if(chartSettings.numberformat==undefined){
            var numberformat={};
            numberformat.decimals="0_2";
            chartSettings["numberformat"]=numberformat;
            }
            if(data && data.length==1 && window.localStorage.getItem("nlpreportdatatoiframe")!=null){
                let {aggType} = data[0];
                if(aggType && (aggType.toLowerCase()=="count"||aggType.toLowerCase()=="countdistinct")){
                    let obj = {
                        "decimals": "0",
                        "seperator": "thousands1"
                    }
                    chartSettings["numberformat"] = obj
                }}

        //Add Series Color to Metric Value
        var chartnumber = chartid.replace("chart", "");
        ChartUtil.streamingcharts.push(chartnumber);
        var customseriescolor = ChartInfo.functions.getCustomSeriesColorsDetails(chartnumber);
        if (customseriescolor != undefined) {
            _.each(customseriescolor, function (i, k) {
                if (yaxisnameArray[0] === i.name) {
                    metriccolor = i.color;
                    return false;
                }
            })
        }

        //Conditional Formating
        var conditionalFormatData = [];
        if (ChartInfo.functions.getConditionalFormatData(chartnumber) != undefined) {
            conditionalFormatData = ChartInfo.functions.getConditionalFormatData(chartnumber);
        }
        if (conditionalFormatData != undefined && conditionalFormatData.length > 0) {
            var datavalue = data[0].y;
            _.each(conditionalFormatData, function (conditions) {
                if (yaxisnameArray[0] == conditions.name) {
                    _.each(conditions.conditions, function (i) {
                        var value = i.value;
                        var range = i.range;
                        if (range == "<") {
                            if (datavalue < value) {
                                prioritycolor = i.color;
                            }
                        } else {
                            if (datavalue > value) {
                                prioritycolor = i.color;
                            }
                        }
                    });
                }
            });
        }

        var metriclayout = "0";

        if (metricfont != null && metricfont != undefined && metricfont.layout != null && metricfont.layout != undefined) {
            metriclayout = metricfont.layout;
        }
        //Metric Font Styles
        var metricfontsize = undefined,
            metricfontsizeparameter = undefined,
            metricfontfamily = undefined,
            metricfontweight = undefined,
            metricfontitalic = undefined,
            metricfontcolor = undefined,

            metricprefixfontsize = undefined,
            metricprefixfontsizeparameter = undefined,
            metricprefixfontfamily = undefined,
            metricprefixfontweight = undefined,
            metricprefixfontitalic = undefined,
            metricprefixfontcolor = undefined,

            metricsuffixfontsize = undefined,
            metricsuffixfontsizeparameter = undefined,
            metricsuffixfontfamily = undefined,
            metricsuffixfontweight = undefined,
            metricsuffixfontitalic = undefined,
            metricsuffixfontcolor = undefined;

        if (metricfont != null && metricfont != undefined) {
            metricfontfamily = metricfont.fontfamily;
            metricfontsize = metricfont.fontsize;
            metricfontsizeparameter = metricfont.fontsizeparameter;
            metricfontweight = metricfont.fontweight;
            metricfontitalic = metricfont.fontitalic;
            metricfontcolor = metricfont.fontcolor;
        }

        if (metricfont != null && metricfont != undefined && metricfont.prefixfont != undefined) {
            metricprefixfontfamily = metricfont.prefixfont.fontfamily;
            metricprefixfontsize = metricfont.prefixfont.fontsize;
            metricprefixfontsizeparameter = metricfont.prefixfont.fontsizeparameter;
            metricprefixfontweight = metricfont.prefixfont.fontweight;
            metricprefixfontitalic = metricfont.prefixfont.fontitalic;
            metricprefixfontcolor = metricfont.prefixfont.fontcolor;
        }

        if (metricfont != null && metricfont != undefined && metricfont.suffixfont != undefined) {
            metricsuffixfontfamily = metricfont.suffixfont.fontfamily;
            metricsuffixfontsize = metricfont.suffixfont.fontsize;
            metricsuffixfontsizeparameter = metricfont.suffixfont.fontsizeparameter;
            metricsuffixfontweight = metricfont.suffixfont.fontweight;
            metricsuffixfontitalic = metricfont.suffixfont.fontitalic;
            metricsuffixfontcolor = metricfont.suffixfont.fontcolor;
        }

        //Metric Custom Target Font Styles
        var metrictargetfontsize = undefined,
            metrictargetfontsizeparameter = undefined,
            metrictargetfontfamily = undefined,
            metrictargetfontweight = undefined,
            metrictargetfontitalic = undefined,
            metrictargetfontcolor = undefined,

            metrictargetprefix = undefined,
            metrictargetprefixfontsize = undefined,
            metrictargetprefixfontsizeparameter = undefined,
            metrictargetprefixfontfamily = undefined,
            metrictargetprefixfontweight = undefined,
            metrictargetprefixfontitalic = undefined,
            metrictargetprefixfontcolor = undefined,

            metrictargetsuffix = undefined,
            metrictargetsuffixfontsize = undefined,
            metrictargetsuffixfontsizeparameter = undefined,
            metrictargetsuffixfontfamily = undefined,
            metrictargetsuffixfontweight = undefined,
            metrictargetsuffixfontitalic = undefined,
            metrictargetsuffixfontcolor = undefined;

        if (metricfont != null && metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision != "" && metricfont.customcomparision.fontfamily != undefined) {
            metrictargetfontfamily = metricfont.customcomparision.fontfamily;
            metrictargetfontsize = metricfont.customcomparision.fontsize;
            metrictargetfontsizeparameter = metricfont.customcomparision.fontsizeparameter;
            metrictargetfontweight = metricfont.customcomparision.fontweight;
            metrictargetfontitalic = metricfont.customcomparision.fontitalic;
            metrictargetfontcolor = metricfont.customcomparision.fontcolor;
        }

        if (metricfont != null && metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision != "" && metricfont.customcomparision.prefixfont != undefined) {
            metrictargetprefix = metricfont.customcomparision.prefixfont.value;
            metrictargetprefixfontfamily = metricfont.customcomparision.prefixfont.fontfamily;
            metrictargetprefixfontsize = metricfont.customcomparision.prefixfont.fontsize;
            metrictargetprefixfontsizeparameter = metricfont.customcomparision.prefixfont.fontsizeparameter;
            metrictargetprefixfontweight = metricfont.customcomparision.prefixfont.fontweight;
            metrictargetprefixfontitalic = metricfont.customcomparision.prefixfont.fontitalic;
            metrictargetprefixfontcolor = metricfont.customcomparision.prefixfont.fontcolor;
        }

        if (metricfont != null && metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision != "" && metricfont.customcomparision.suffixfont != undefined) {
            metrictargetsuffix = metricfont.customcomparision.suffixfont.value;
            metrictargetsuffixfontfamily = metricfont.customcomparision.suffixfont.fontfamily;
            metrictargetsuffixfontsize = metricfont.customcomparision.suffixfont.fontsize;
            metrictargetsuffixfontsizeparameter = metricfont.customcomparision.suffixfont.fontsizeparameter;
            metrictargetsuffixfontweight = metricfont.customcomparision.suffixfont.fontweight;
            metrictargetsuffixfontitalic = metricfont.customcomparision.suffixfont.fontitalic;
            metrictargetsuffixfontcolor = metricfont.customcomparision.suffixfont.fontcolor;
        }

        //Metric Achievement Font Styles
        var metricachievementfontsize = undefined,
            metricachievementfontsizeparameter = undefined,
            metricachievementfontfamily = undefined,
            metricachievementfontweight = undefined,
            metricachievementfontitalic = undefined,
            metricachievementfontcolor = undefined,

            metricachievementprefix = undefined,
            metricachievementprefixfontsize = undefined,
            metricachievementprefixfontsizeparameter = undefined,
            metricachievementprefixfontfamily = undefined,
            metricachievementprefixfontweight = undefined,
            metricachievementprefixfontitalic = undefined,
            metricachievementprefixfontcolor = undefined,

            metricachievementsuffix = undefined,
            metricachievementsuffixfontsize = undefined,
            metricachievementsuffixfontsizeparameter = undefined,
            metricachievementsuffixfontfamily = undefined,
            metricachievementsuffixfontweight = undefined,
            metricachievementsuffixfontitalic = undefined,
            metricachievementsuffixfontcolor = undefined;

        if (metricfont != null && metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision.achievement != undefined && metricfont.customcomparision.achievement.fontfamily != undefined) {
            metricachievementfontfamily = metricfont.customcomparision.achievement.fontfamily;
            metricachievementfontsize = metricfont.customcomparision.achievement.fontsize;
            metricachievementfontsizeparameter = metricfont.customcomparision.achievement.fontsizeparameter;
            metricachievementfontweight = metricfont.customcomparision.achievement.fontweight;
            metricachievementfontitalic = metricfont.customcomparision.achievement.fontitalic;
            metricachievementfontcolor = metricfont.customcomparision.achievement.fontcolor;
        }

        if (metricfont != null && metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision.achievement != undefined && metricfont.customcomparision.achievement.prefixfont != undefined) {
            metricachievementprefix = metricfont.customcomparision.achievement.prefixfont.value;
            metricachievementprefixfontfamily = metricfont.customcomparision.achievement.prefixfont.fontfamily;
            metricachievementprefixfontsize = metricfont.customcomparision.achievement.prefixfont.fontsize;
            metricachievementprefixfontsizeparameter = metricfont.customcomparision.achievement.prefixfont.fontsizeparameter;
            metricachievementprefixfontweight = metricfont.customcomparision.achievement.prefixfont.fontweight;
            metricachievementprefixfontitalic = metricfont.customcomparision.achievement.prefixfont.fontitalic;
            metricachievementprefixfontcolor = metricfont.customcomparision.achievement.prefixfont.fontcolor;
        }

        if (metricfont != null && metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision.achievement != undefined && metricfont.customcomparision.achievement.suffixfont != undefined) {
            metricachievementsuffix = metricfont.customcomparision.achievement.suffixfont.value;
            metricachievementsuffixfontfamily = metricfont.customcomparision.achievement.suffixfont.fontfamily;
            metricachievementsuffixfontsize = metricfont.customcomparision.achievement.suffixfont.fontsize;
            metricachievementsuffixfontsizeparameter = metricfont.customcomparision.achievement.suffixfont.fontsizeparameter;
            metricachievementsuffixfontweight = metricfont.customcomparision.achievement.suffixfont.fontweight;
            metricachievementsuffixfontitalic = metricfont.customcomparision.achievement.suffixfont.fontitalic;
            metricachievementsuffixfontcolor = metricfont.customcomparision.achievement.suffixfont.fontcolor;
        }

        $("#" + chartid).empty();
        if (metricprefix == undefined) {
            metricprefix = '';
        }
        if (metricsuffix == undefined) {
            metricsuffix = '';
        }
        if (metrictargetprefix == undefined) {
            metrictargetprefix = '';
        }
        if (metrictargetsuffix == undefined) {
            metrictargetsuffix = '';
        }
        if (metricachievementprefix == undefined) {
            metricachievementprefix = '';
        }
        if (metricachievementsuffix == undefined) {
            metricachievementsuffix = '';
        }

        if (metricfont != undefined && metricfont != null && metricfont != "" && metriclayout == "1" && metricfont.customcomparision != undefined && metricfont.customcomparision.targetvalue != undefined && metricfont.customcomparision.targetvalue != "") {
            /*if(metrictype!="All"){
                
            }else{*/
            var actualvalue = ChartInfo.functions.addFormatting(data[0].y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator);
            if (data[0].y == 0) {
                actualvalue = 0;
            }
            var metrictargetvalue = ChartInfo.functions.addFormatting(metricfont.customcomparision.targetvalue, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator);
            if (metricfont.customcomparision.targetvalue == 0) {
                metrictargetvalue = 0;
            }
            var metricachievement = ChartInfo.functions.addFormatting((data[0].y / metricfont.customcomparision.targetvalue * 100), chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator);
            if (metricfont.customcomparision.targetvalue == 0) {
                metricachievement = 0
            }
            $("#" + chartid).append(""
                + "<div class='highcharts-container metric-container metric-comparision' style='height:100%;color:" + metriccolor + "'>"
                + "<div class='metricchart'></div>"
                + "<div class='metricchart' style='color:" + metricfontcolor + "'>"
                + "<span><span class='metricprefixcontent'>" + metricprefix + "</span>"
                + "<span class='metricvaluecontainer'>" + actualvalue + "</span>"
                + "</span><span class='metricsuffixcontent'>" + metricsuffix + "</span>"
                + "</div>"

                + "<div class='metriccharttarget' style='color:" + metrictargetfontcolor + "'>"
                + "<span><span class='metrictargetprefixcontent'>" + metrictargetprefix + "</span>"
                + "<span class='metrictargetvaluecontainer' target-value=" + metricfont.customcomparision.targetvalue + ">" + metrictargetvalue + "</span>"
                + "</span><span class='metrictargetsuffixcontent'>" + metrictargetsuffix + "</span>"
                + "</div>"

                + "<div class='metricchartachievement' style='color:" + metricachievementfontcolor + "'>"
                + "<span><span class='metricachievementvaluecontainer'>" + metricachievement + "</span>"
                + "<span class='metricachievementprefixcontent'>" + metricachievementprefix + "</span>"
                + "</span><span class='metricachievementsuffixcontent'>" + metricachievementsuffix + "</span>"
                + "</div>"

                + "</div>"
                + "");

            /*}*/
        } else {
            if (metrictype != "All") {
                if (metricdatefield === undefined || metricdatefield === "") {
                    if (data[0].metric === null || data[0].y === null || data[0].y === 0) {
                        $("#" + chartid).append("<div class='highcharts-container metric-container' style='height:100%;color:" + metriccolor + "'><div class='metricchart' style='color:" + metricfontcolor + "'><span class='metricprefixcontent'>" + metricprefix + "</span><span class='metricvaluecontainer'>" + (data[0].y) + "</span><span class='metricsuffixcontent'>" + metricsuffix + "</span></div></div>");
                    } else {
                        var num = ChartInfo.functions.addFormatting(data[0].y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                        $("#" + chartid).append("<div class='highcharts-container metric-container' style='height:100%;color:" + metriccolor + "'><div class='metricchart' style='color:" + metricfontcolor + "'><span class='metricprefixcontent'>" + metricprefix + "</span><span class='metricvaluecontainer'>" + num + "</span><span class='metricsuffixcontent'>" + metricsuffix + "</span></div></div>");
                    }
                }

                //						if(data[0].metric===null|| data[0].y===null || data[0].y===0 ){
                //							$("#" + chartid).append("<div class='highcharts-container metric-container' style='height:100%;'><div class='metricchart' style='color:"+metriccolor+"'>" +metricprefix+""+ 0+""+metricsuffix  +"<!--<span class='label-metric metric-decrease' data-toggle='tooltip' data-placement='top' title='Compare to "+metrictype+"'><i class='fa fa-caret-down'></i>&nbsp;data[0].metric</span>--></div></div>");
                //						}

                var actualmetricvalue = ChartInfo.functions.addFormatting(data[0].y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                if (data[0].y === 0) { actualmetricvalue = 0 }

                if (0 < data[0].metric) {
                    var num = ChartInfo.functions.addFormatting(data[0].metric, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                    $("#" + chartid).append("<div class='highcharts-container metric-container' style='height:100%;color:" + metriccolor + "'><div class='metricchart' style='color:" + metricfontcolor + "'><span class='metricprefixcontent'>" + metricprefix + "</span><span class='metricvaluecontainer'>" + actualmetricvalue + "</span><span class='metricsuffixcontent'>" + metricsuffix + "</span><span class='label-metric metric-increase' data-toggle='tooltip' data-placement='top' title='Compare to " + metrictype + "'><i class='fa fa-caret-up fa-solid'></i>&nbsp;" + num + "%" + "</span><!--<span class='label-metric metric-decrease'><i class='fa fa-caret-down'></i>&nbsp;data[0].metric</span>--></div></div>");
                }
                if (0 == data[0].metric) {
                    $("#" + chartid).append("<div class='highcharts-container metric-container' style='height:100%;color:" + metriccolor + "'><div class='metricchart' style='color:" + metricfontcolor + "'><span class='metricprefixcontent'>" + metricprefix + "</span><span class='metricvaluecontainer'>" + actualmetricvalue + "</span><span class='metricsuffixcontent'>" + metricsuffix + "</span><span class='label-metric metric-stable' data-toggle='tooltip' data-placement='top' title='Compare to " + metrictype + "'><i class='fa fa-sort fa-solid'></i>&nbsp;" + 0 + "%" + "</span><!--<span class='label-metric metric-decrease'><i class='fa fa-caret-down'></i>&nbsp;data[0].metric</span>--></div></div>");
                }
                if (0 > data[0].metric) {
                    $("#" + chartid).append("<div class='highcharts-container metric-container' style='height:100%;color:" + metriccolor + "'><div class='metricchart' style='color:" + metricfontcolor + "'><span class='metricprefixcontent'>" + metricprefix + "</span><span class='metricvaluecontainer'>" + actualmetricvalue + "</span><span class='metricsuffixcontent'>" + metricsuffix + "</span><span class='label-metric metric-decrease' data-toggle='tooltip' data-placement='top' title='Compare to " + metrictype + "'><i class='fa fa-caret-down fa-solid'></i>&nbsp;" + data[0].metric + "%" + "</span><!--<span class='label-metric metric-decrease'><i class='fa fa-caret-down'></i>&nbsp;data[0].metric</span>--></div></div>");
                }
            }
            else {
                if (data[0].y == 0) {
                    $("#" + chartid).append("<div class='highcharts-container metric-container' style='height:100%;color:" + metriccolor + "'><div class='metricchart' style='color:" + metricfontcolor + "'><span class='metricprefixcontent'>" + metricprefix + "</span><span class='metricvaluecontainer'>" + data[0].y + "</span>" + "<span class='metricsuffixcontent'>" + metricsuffix + "</span>")
                }
                else {
                    var num = ChartInfo.functions.addFormatting(data[0].y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator);
                    $("#" + chartid).append("<div class='highcharts-container metric-container' style='height:100%;color:" + metriccolor + "'><div class='metricchart' style='color:" + metricfontcolor + "'><span class='metricprefixcontent'>" + metricprefix + "</span><span class='metricvaluecontainer'>" + num + "</span>" + "<span class='metricsuffixcontent'>" + metricsuffix + "</span>")
                }

            }
        }
        //Update Metric Theme
        if (metricfontfamily == "Choose Font Family") { metricfontfamily = "" }
        if (metricprefixfontfamily == "Choose Font Family") { metricprefixfontfamily = "" }
        if (metricsuffixfontfamily == "Choose Font Family") { metricsuffixfontfamily = "" }
        $("#" + chartid + " .metricvaluecontainer").css({ "color": prioritycolor, "font-family": metricfontfamily, "font-size": metricfontsize + metricfontsizeparameter, "font-weight": metricfontweight, "font-style": metricfontitalic });
        if (metricfont != undefined && metricfont.prefixfont != undefined) {
            $("#" + chartid + " .metricprefixcontent").css({ "color": metricprefixfontcolor, "font-family": metricprefixfontfamily, "font-size": metricprefixfontsize + metricprefixfontsizeparameter, "font-weight": metricprefixfontweight, "font-style": metricprefixfontitalic });
        }
        if (metricfont != undefined && metricfont.suffixfont != undefined) {
            $("#" + chartid + " .metricsuffixcontent").css({ "color": metricsuffixfontcolor, "font-family": metricsuffixfontfamily, "line-height": "1em", "font-size": metricsuffixfontsize + metricsuffixfontsizeparameter, "font-weight": metricsuffixfontweight, "font-style": metricsuffixfontitalic });
        }
        //Update Metric Target Theme
        if (metrictargetfontfamily == "Choose Font Family") { metrictargetfontfamily = "" }
        if (metrictargetprefixfontfamily == "Choose Font Family") { metrictargetprefixfontfamily = "" }
        if (metrictargetsuffixfontfamily == "Choose Font Family") { metrictargetsuffixfontfamily = "" }
        $("#" + chartid + " .metrictargetvaluecontainer").css({ "color": metrictargetfontcolor, "font-family": metrictargetfontfamily, "font-size": metrictargetfontsize + metrictargetfontsizeparameter, "font-weight": metrictargetfontweight, "font-style": metrictargetfontitalic });
        if (metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision.prefixfont != undefined) {
            $("#" + chartid + " .metrictargetprefixcontent").css({ "color": metrictargetprefixfontcolor, "font-family": metrictargetprefixfontfamily, "font-size": metrictargetprefixfontsize + metrictargetprefixfontsizeparameter, "font-weight": metrictargetprefixfontweight, "font-style": metrictargetprefixfontitalic });
        }
        if (metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision.suffixfont != undefined) {
            $("#" + chartid + " .metrictargetsuffixcontent").css({ "color": metrictargetsuffixfontcolor, "font-family": metrictargetsuffixfontfamily, "line-height": "1em", "font-size": metrictargetsuffixfontsize + metrictargetsuffixfontsizeparameter, "font-weight": metrictargetsuffixfontweight, "font-style": metrictargetsuffixfontitalic });
        }

        //Update Metric Achievement Theme
        if (metricachievementfontfamily == "Choose Font Family") { metricachievementfontfamily = "" }
        if (metricachievementprefixfontfamily == "Choose Font Family") { metricachievementprefixfontfamily = "" }
        if (metricachievementsuffixfontfamily == "Choose Font Family") { metricachievementsuffixfontfamily = "" }
        $("#" + chartid + " .metricachievementvaluecontainer").css({ "color": metricachievementfontcolor, "font-family": metricachievementfontfamily, "font-size": metricachievementfontsize + metricachievementfontsizeparameter, "font-weight": metricachievementfontweight, "font-style": metricachievementfontitalic });
        if (metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision.prefixfont != undefined) {
            $("#" + chartid + " .metricachievementprefixcontent").css({ "color": metricachievementprefixfontcolor, "font-family": metricachievementprefixfontfamily, "font-size": metricachievementprefixfontsize + metricachievementprefixfontsizeparameter, "font-weight": metricachievementprefixfontweight, "font-style": metricachievementprefixfontitalic });
        }
        if (metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision.suffixfont != undefined) {
            $("#" + chartid + " .metricachievementsuffixcontent").css({ "color": metricachievementsuffixfontcolor, "font-family": metricachievementsuffixfontfamily, "line-height": "1em", "font-size": metricachievementsuffixfontsize + metricachievementsuffixfontsizeparameter, "font-weight": metricachievementsuffixfontweight, "font-style": metricachievementsuffixfontitalic });
        }

        $('[data-toggle="tooltip"]').tooltip();
        /*$('.fullscreenmain').attr('data-gs-width',"3");
        $('.fullscreenmain').attr('data-gs-height',"3");*/
        var heightset = $('.tileview[data-num=' + tileno + ']').height() - 2,
            topmargin = $('.boc-snapshot-options.maxanim3[data-num=' + tileno + ']').outerHeight() + 12;

        if (heightset > 0 && chartid != "nlpchart") {
            $('#chart' + tileno).css({ "margin-top": "-" + topmargin + "px", "overflow": "auto" }).height(heightset);
            $('.tileview[data-num=' + tileno + ']').find('.highcharts-container').css({ "height": heightset });
        }

        return (
            <div ref="chartparent"> </div>


        );
    }

}