import React from "react";
import { lang } from "../../js/helpers/utils/languages/language";
import _ from "underscore";
import SendRequest from '../../SendRequest';
import download from 'downloadjs'
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import * as ChartUtil from '../../js/helpers/utils/chartutil';

const $ = window.$;

export default class ViewMLParametersTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isSampling: false, isSamplingPreview: true, isAutoSampling: false };
    }
    componentDidMount() {
        if (this.props.isSampling) {
            this.setState({ isSampling: this.props.isSampling }, () => { $(".mlsamplevals").removeClass('hide'); $(".mlxandyaxis").removeClass('hide');this.getAllSamples() });
            $(".storyboardtoolbarfull").hide();
            $('.mlloadingbtn').hide();
        }
    }
    getAllSamples = () => {
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getallsamples",
            queryString: "?modelId=" + this.props.datamodelId,
            sucessFunction: response => {
                let {data,header} = response;
                $("#sampling-models-summarytable table").empty()
                $("#sampling-models-summarytable table").bootstrapTable("destroy")
                var keysArray = Object.keys(header);
                if (keysArray != null && keysArray != undefined && keysArray.length > 0) {
                    var keys = [];
                    for (var j1 = 0; j1 < keysArray.length; j1++) {
                        if (keys.indexOf(keysArray[j1]) === -1) {
                            keys.push(keysArray[j1]);
                        }
                    }
                    var headerHtml = "";
                    for (var j = 0; j < keys.length; j++) {
                        headerHtml = headerHtml + '<th class="text-center" data-field="' + keys[j] + '">' + header[keys[j]] + '</th>'
                    }
                    $("#sampling-models-summarytable").append('<div class="outliertable notifications-table-container" ><table class="table" id="outliertable" data-toggle="table" data-pagination="true" data-page-size="10" data-smart-display="true" data-search="true" data-pagination-v-align="bottom" data-search-align="left"><thead></thead><tbody></tbody></table></div>');
                    $("#sampling-models-summarytable table>thead").append('<tr>' + headerHtml + '</tr>');
                    $("#sampling-models-summarytable table").bootstrapTable({ height: 500, data: data });
                    $(".sampleexport").click((e)=>{
                        this.exportSampleData(e,data);
                    });
                }
                if(window.localStorage.hierachyAccess){
                    const  hierachyAccess= window.localStorage.hierachyAccess;
                     if(hierachyAccess && (hierachyAccess  !="state" && hierachyAccess  !="district" && hierachyAccess  !="constituency")){
                         $("#dropdownsamplecols").addClass('hide');
                     }
                 }
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
    }
    exportSampleData=(e,data)=>{
        let sampleid= $(e.currentTarget).attr("sampleid");
        let idData = data.filter(val=> val.sample_id == sampleid);
        let  metadata = JSON.parse(idData[0].metadata);
        let indexName = metadata.indexName;
        let primaryColumn = metadata.primaryColumn;
        console.log(indexName);
        let filters='[{\"dataType\":\"number\",\"tablename\":\"public.\\\"electoral_data\\\"\",\"columndisplayname\":\"sample_id\",\"columnname\":\"sample_id\",\"fromRange\":'+sampleid+',\"toRange\":'+sampleid+',\"customfiltervalue\":\"\"}]';
        var data = new FormData();
        data.append("entityname", indexName);
        data.append("tablename", indexName+"_sample_vw");
        data.append("hubId",  "");
        data.append("filters", (filters !== undefined) ? filters : "");
        data.append("customfields", "");
        data.append("connId",  "");
        data.append("workspaceId", 0);
        data.append("customquery", true);
        data.append("columns", "");
        SendRequest({
            url: "exportsampledata",
            queryString: "",
            body: data,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.error)
                    setTimeout(function () {
                        $('.birderror').remove();
                    }, 3000);
                } else {
                    // that.plotDataTable(response);
                    download(response, "SampleData.xlsx", "application/vnd.ms-excel");
                }
            },
            rejectFunction: (error) => {$(".spinner").hide();},
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    periodsForecastKeyUp = (e) => {
        e.stopPropagation();
        let $this = e.currentTarget;
        $($this).removeClass("bird-has-error")
        let value = $this.value
        if (value != undefined && value != "" && parseInt(value) <= 0) {
            $($this).addClass("bird-has-error")
        }
    }
    onSamplingMethodChange = (e) => {
        e.stopPropagation();
        let $this = e.currentTarget;
        let ChangedValue = $this.value
            this.setState({ isAutoSampling: !this.state.isAutoSampling })
    }
    preFetchSampling = ()=>{
        $(".outliertable.notifications-table-container").remove();$(".mlsamplevals").removeClass('hide'); $(".mlxandyaxis").removeClass('hide');
    }
    runSampling = () => {
        var that = this;
        let showWarning;
        /** Warning for sampling primarycolumn */
        if($(".mlpredictyaxisattributes .singleliitems").length==0){
            showWarning = "BirdInformation63"
        }
        /** Warning for samplingcolumn */
        if(!showWarning && $(".mlsamplingcolumns .singleliitems").length==0){
            showWarning = "BirdInformation64"
        }
        /** Warning for sampling criteria value  */
        if(!showWarning && $(".mlsamplingcriteriavalues .singleliitems").length==0){
            showWarning = "BirdInformation65"
        }
        /** Warning for sampling parameters */
        if(!showWarning && $(".mlsampleparameters .singleliitems").length==0){
            showWarning = "BirdInformation66"
        }
        if(showWarning){
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage(showWarning));
            return
        }
        if($(".sampling-criteria-type").val()==="auto" && $(".confidenceinterval").val()==""){
            $(".confidenceinterval").addClass('bird-has-error')
            return
        }
        if($(".sampling-criteria-type").val()==="manual" && $(".manual-confidence").val()==""){
            $(".manual-confidence").addClass('bird-has-error')
            return
        }
        let samplingParameterData = {}
        samplingParameterData["samplingMethod"]=$(".sampling-type").val();
        samplingParameterData["primaryColumn"] = $(".mlpredictyaxisattributes .singleliitems").attr("data-name");
        samplingParameterData["criteriaColumnName"]= $(".mlsamplingcolumns .singleliitems").attr("data-name");
        samplingParameterData["criteria"]= "['"+$(".mlsamplingcriteriavalues .singleliitems").attr("data-name")+"']";
        let sampleParameters = "";
        $(".mlsampleparameters .singleliitems").each(function(){
            sampleParameters+= $(this).attr('data-name')+","
        })
        sampleParameters = sampleParameters.substring(0,sampleParameters.lastIndexOf(","));
        samplingParameterData["sampleParameters"]= sampleParameters;
        samplingParameterData["samplesizeCalculation"]= $(".sampling-criteria-type").val()
        let metrics = {};
        metrics["confidenceLevel"] = $(".sampling-confidence-level").val();
        metrics["confidenceInterval"] = $(".confidenceinterval").val();
        metrics["percentage"] = $(".manual-confidence").val();
        samplingParameterData["metrics"] = JSON.stringify(metrics);
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        
        let formdata = new FormData();
        formdata.set('datamodelId', that.props.datamodelId);
        formdata.set('metadata', JSON.stringify(samplingParameterData));
        formdata.set('sampleId',0)
        SendRequest({
            url: "runDataSampling",
            body: formdata,
            sucessFunction: response => {
            if (response != null && response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $(".gridspinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                    }
                    that.setState({ isSamplingPreview: !that.state.isSamplingPreview,isAutoSampling:false },() => { that.preFetchSampling();that.getAllSamples() })
            },
            rejectFunction: () => { },
            headers: requestHeaders
        })
    }
    render() {
        return (
            <div className="container-fluid fill-height">
                <div className="row fill-height">
                    <div className="col-xs-12">
                        <h2>{lang["mlreports.modelparameters"]}</h2>
                    </div>
                    <div className="col-xs-12 fill-height" id="mlmodelparametersbox">
                        <div className="container-fluid modal-body fill-height">
                            <div className="row fill-height">
                                <div className="row-same-height fill-height">
                                    {this.state.isSampling && this.state.isSamplingPreview ?
                                                    <React.Fragment>
                                                        <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={`Add Sampling`} id="dropdownsamplecols" aria-expanded="false" data-toggle="dropdown" onClick={() => { this.setState({ isSamplingPreview: !this.state.isSamplingPreview},()=>this.preFetchSampling()) }}>
                                                            <i className="fa fa-plus-circle"></i>
                                                        </button>
                                                        <div id="sampling-models-summarytable">
                                                            
                                                        </div></React.Fragment> :
                                                        <React.Fragment>
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 col-xs-height settingscontent">
                                        <div className="col-xs-12">
                                            {!this.state.isSampling ?
                                                <React.Fragment>
                                                    <h2>{lang["mlreports.choosemodel"]}</h2>
                                                    <div className="container-fluid nopadding">
                                                        <div className="col-lg-12 nopadding marg-top-10">
                                                            <select ref={this.props.selectChange} className="sort-attribute-name mlpredicttionmodal nomargin" id="mlpredictionsettingpop" onChange={this.props.mlPredicttionmodalChange}>
                                                                <option value="mlselect">{lang["mlreports.select"]}</option>
                                                                {this.props.modelNames.map((modelName, i) => {
                                                                    return <option key={i} value={modelName.modelType}>{modelName.modelName}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <br /></React.Fragment> :
                                                <React.Fragment>
                                                    <div className="container-fluid nopadding">
                                                        <h2>{`Sampling method`}</h2>
                                                        <div className="col-lg-12 nopadding marg-top-10">
                                                            <select className="sort-attribute-name nomargin sampling-type">
                                                                <option value="stratified sampling">Stratified sampling</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <br />
                                                </React.Fragment>}
                                            <div className="mlxandyaxis hide">
                                                <legend className="subhead mlxaxis mlxaxisforparametermodel hide">
                                                    <div className="attraxisnames">{lang["mlreports.X-axis"]}</div>
                                                    <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdownxaxis" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showXAxisDropDown}>
                                                        <i className="fa fa-plus-circle"></i>
                                                    </button>
                                                    <ul className="list-group dropdown-menu dropdown-menu-right xaxisdropdown" aria-labelledby="dropdownxaxis" role="menu">
                                                    </ul>
                                                </legend>
                                                <div className="marg-top-10 mlxaxisforparametermodel hide">
                                                    <ul className="list-group mlpreidctxaxisattributes"></ul>
                                                </div>
                                                <div className="mlyaxis">
                                                    <legend className="subhead">
                                                        <div className="attraxisnames">{this.state.isSampling ? `Primary Column` : lang["mlreports.Y-axis"]}</div>
                                                        <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdownyaxis" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showyAxisDropDown}>
                                                            <i className="fa fa-plus-circle"></i>
                                                        </button>
                                                        <ul className="list-group dropdown-menu dropdown-menu-right yaxisdropdown" aria-labelledby="dropdownyaxis" role="menu">
                                                        </ul>
                                                    </legend>
                                                    <div className="marg-top-10">
                                                        <ul className="list-group mlpredictyaxisattributes"></ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mltextvals hide">
                                                {/* <legend className="subhead marg-top-10">
                                                    <div className="attraxisnames">{lang["mlreports.textcolms"]}</div>
                                                    <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdowntextcols" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showTextColumnsDropDown}>
                                                        <i className="fa fa-plus-circle"></i>
                                                    </button>
                                                    <ul className="list-group dropdown-menu dropdown-menu-right textcolsdropdown" aria-labelledby="dropdowntextcols" role="menu">
                                                    </ul>
                                                </legend>
                                                <div className="marg-top-10">
                                                    <ul className="list-group mltextcolumns"></ul>
                                                </div> */}
                                                <div className="container-fluid nopadding">
                                                    <h2>{`Analysis type`}</h2>
                                                    <div className="col-lg-12 nopadding marg-top-10">
                                                        <select className="sort-attribute-name nomargin sentiment-analysis-type" onChange={this.props.mlsentimentmodalChange}>
                                                            <option value="Knowledge-based">Knowledge-based Approach</option>
                                                            {/* <option value="statistical">Statistical Approach</option> */}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mldatevals hide">
                                                <legend className="subhead marg-top-10">
                                                    <div className="attraxisnames">{lang["mlreports.datecolums"]}</div>
                                                    <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdowndatecols" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showDateColumnsDropDown}>
                                                        <i className="fa fa-plus-circle"></i>
                                                    </button>
                                                    <ul className="list-group dropdown-menu dropdown-menu-right datecolsdropdown" aria-labelledby="dropdowndatecols" role="menu">
                                                    </ul>
                                                </legend>
                                                <div className="marg-top-10">
                                                    <ul className="list-group mldatecolumns"></ul>
                                                </div>
                                            </div>
                                            {this.state.isSampling ? <div className="mlsamplevals hide">
                                                <legend className="subhead marg-top-10">
                                                    <div className="attraxisnames">{`Sampling Column`}</div>
                                                    <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdownsamplingcols" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showSamplingColumnsDropdown}>
                                                        <i className="fa fa-plus-circle"></i>
                                                    </button>
                                                    <ul className="list-group dropdown-menu dropdown-menu-right samplingcolsdropdown" aria-labelledby="dropdowndatecols" role="menu">
                                                    </ul>
                                                </legend>
                                                <div className="marg-top-10">
                                                    <ul className="list-group mlsamplingcolumns"></ul>
                                                </div>
                                            </div> : <React.Fragment />}
                                            {this.props.inputParameters.map((inputParam) => {
                                                if (inputParam.modelType == 'automl') {
                                                    var inputparamval = JSON.parse(inputParam.inputParameter);
                                                    return (<div className="automlsetting hide">
                                                        <legend className="subheadpredection">{inputparamval[0].displayname} (sec)</legend>
                                                        <input type={inputparamval[0].displaytype} className="modal-form-fields form-field timeinterval" name={inputparamval[0].id} min={inputparamval[0].minval} max={inputparamval[0].maxval} />
                                                        <legend className="subheadpredection">{inputparamval[1].displayname} (%)</legend>
                                                        <input type={inputparamval[1].displaytype} className="modal-form-fields form-field trainedtestsplit" name={inputparamval[1].id} min={inputparamval[1].minval} max={inputparamval[1].maxval} />
                                                        <div className="pull-right marg-top-20">
                                                            <button className="btn btn-bird active " onClick={this.props.autommlrun}>{lang["mlreports.AutoMLRun"]}</button>
                                                        </div>
                                                    </div>)
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className="gap hidden-sm hidden-xs"></div>
                                    <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12 col-xs-height settingscontent">
                                        <div className="col-xs-12">
                                            <div className="mlparameterlists aggregator hide">
                                                <legend className="subhead">{lang["mlreports.aggregator"]} :</legend>
                                                <br />
                                                <legend className="subheadpredection">{lang["mlreports.target_num"]}</legend>
                                                <input type="number" className="modal-form-fields form-field" name="target_num_exemplars" placeholder={lang["mlreports.placeholdtarget"]} defaultValue="" />
                                                <div className="clear"></div>
                                                <legend className="subheadpredection">{lang["mlreports.rel_tol"]}</legend>
                                                <input type="number" className="modal-form-fields form-field" name="rel_tol_num_exemplars" placeholder={lang["mlreports.placeholdrel_tol"]} defaultValue="" />
                                                <div className="clear"></div>
                                                <legend className="subheadpredection ">{lang["mlreports.categorical"]}</legend>
                                                <select className="sort-attribute-name form-field" name="categorical_encoding">
                                                    <option value="auto">{lang["mlreports.auto"]}</option>
                                                    <option value="enum">{lang["mlreports.enum"]}</option>
                                                    <option value="binary">{lang["mlreports.binary"]}</option>
                                                    <option value="onehotinternal">{lang["mlreports.internal"]}</option>
                                                    <option value="onehotexplicit">{lang["mlreports.explicit"]}</option>
                                                    <option value="eigen">{lang["mlreports.eigen"]}</option>
                                                    <option value="labelencoder">{lang["mlreports.labelencode"]}</option>
                                                    <option value="sortbyresponse">{lang["mlreports.sortresponse"]}</option>
                                                    <option value="enumlimited">{lang["mlreports.enumltd"]}</option>
                                                </select>
                                            </div>
                                            {/* Forecasting */}
                                            {this.props.inputParameters.map((inputParam) => {
                                                if (inputParam.modelType == 'forecasting') {
                                                    var inputparamval = JSON.parse(inputParam.inputParameter);
                                                    return (<div className="mlparameterlists mlforecasting hide">
                                                        <h2>{inputParam.modelName}:</h2>
                                                        <br />
                                                        <div className="col-lg-5 col-xs-12">
                                                            <legend className="subheadpredection">{inputparamval[0].displayname}</legend>
                                                            <input type={inputparamval[0].displaytype} className="modal-form-fields form-field periods_forecast" name={inputparamval[0].id} min={inputparamval[0].minval} max={inputparamval[0].maxval} onKeyUp={this.periodsForecastKeyUp} />
                                                            <select className="sort-attribute-name form-field forecastselectperiod" name={`select-period`}>
                                                                <option value="Days">Days</option>
                                                                <option value="Weeks">Weeks</option>
                                                                <option value="Months">Months</option>
                                                                <option value="Years">Years</option>
                                                            </select>
                                                            <div className="clear"></div>
                                                            <legend className="subheadpredection">{inputparamval[1].displayname}</legend>
                                                            <select className="sort-attribute-name form-field forecastingfamily" name={inputparamval[1].id}>
                                                                {inputparamval[1].value.map((val) => {
                                                                    return <option value={val.value}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>
                                                            <div className="col-md-12 modeldatatype">
                                                                <input type="hidden" className={inputparamval[2].id} name={inputparamval[2].name} value={inputparamval[2].displaytype} /></div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </div>)
                                                }
                                            })
                                            }
                                            {/* Forecasting */}
                                            {/* <!-- GLM (Generalized Linear Modeling) --> */}
                                            {this.props.inputParameters.map((inputParam) => {
                                                if (inputParam.modelType == 'generalizedlinearmodeling') {
                                                    var inputparamval = JSON.parse(inputParam.inputParameter);
                                                    return (<div className="mlparameterlists mlgeneralizedlinearmodeling hide">
                                                        <h2>{inputParam.modelName}:</h2>
                                                        <br />
                                                        <div className="col-lg-5 col-xs-12">
                                                            <legend className="subheadpredection">{inputparamval[0].displayname}</legend>
                                                            <input type={inputparamval[0].displaytype} className="modal-form-fields form-field nfolds" name={inputparamval[0].id} min={inputparamval[0].minval} />
                                                            <div className="clear"></div>
                                                            <legend className="subheadpredection">{inputparamval[1].displayname}</legend>
                                                            <select className="sort-attribute-name form-field gmlfamily" name="{inputparamval[1].id}">
                                                                {inputparamval[1].value.map((val) => {
                                                                    return <option value={val.value}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>
                                                            {/* <legend className="subheadpredection">{inputparamval[2].displayname}</legend>
                                                            <select className="sort-attribute-name form-field glmbalanceclassNamees" name={inputparamval[2].id}>
                                                                {inputparamval[2].value.map((val) => {
                                                                    return <option value={val.name}>{val.name}</option>
                                                                })}
                                                            </select> */}
                                                            <div className="col-md-12 modeldatatype">
                                                                <input type="hidden" className={inputparamval[2].id} name={inputparamval[2].name} value={inputparamval[2].displaytype} /></div>
                                                        </div>
                                                    </div>)
                                                    // <!-- /GLM (Generalized Linear Modeling) -->
                                                }
                                            })}
                                            {/* <!-- Random Forest --> */}
                                            <div className="mlparameterlists mlrandomforest hide">
                                                <h2>{lang["mlreports.randomforest"]} :</h2>
                                                <br />
                                            </div>
                                            {/* <!-- /Random Forest --> */}

                                            {/* <!-- Text Analysis --> */}


                                            {/* <!-- Logistic Regression --> */}

                                            {this.props.inputParameters.map((inputParam) => {
                                                if (inputParam.modelType == 'logisticregression') {
                                                    var inputparamval = JSON.parse(inputParam.inputParameter);
                                                    return (<div className="mlparameterlists logisticregression hide">
                                                        <h2>{inputParam.modelName}:</h2>
                                                        <br />
                                                        <div className="col-lg-5 col-xs-12">
                                                            <div className="col-md-12 modeldatatype">
                                                                <input type="hidden" className={inputparamval[0].id} name={inputparamval[0].name} defaultValue={inputparamval[0].displaytype} />
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                            })}
                                            {/*<!-- /Logistic Regression -->*/}
                                            {/* <!-- Neural Network --> */}
                                            <div className="mlparameterlists mlneuralnetwork hide">
                                                <legend className="subhead">{lang["mlreports.neutaln/w"]} :</legend>
                                                <br />
                                            </div>
                                            {/* <!-- /Neural Network --> */}

                                            {/* <!-- Decision Tree --> */}
                                            {this.props.inputParameters.map((inputParam) => {
                                                if (inputParam.modelType == 'decisiontree') {
                                                    var inputparamval = JSON.parse(inputParam.inputParameter);
                                                    return (<div className="mlparameterlists mldecisiontree hide">
                                                        <h2>{inputParam.modelName}:</h2>
                                                        <br />
                                                        <div className="col-lg-5 col-xs-12">
                                                            <legend className="subhead">{inputparamval[2].displayname}</legend>
                                                            <br />
                                                            <div className="clear"></div>
                                                            {/*<legend className="subhead">{inputparamval[0].displayname}</legend>
                                                            <br />
                                                            <div className="clear"></div>
                                                             <legend className="subheadpredection">{inputparamval[1].displayname}</legend>
                                                            <select className="sort-attribute-name form-field decisiontreemethod" name={inputparamval[1].id}>
                                                                {inputparamval[1].value.map((val) => {
                                                                    return <option value={val.value}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div> */}
                                                            <legend className="subheadpredection">{inputparamval[1].displayname}</legend>
                                                            <input type={inputparamval[1].displaytype} className="modal-form-fields form-field decisiontreenumber" name={inputparamval[1].id} min={inputparamval[1].minval} max={inputparamval[1].maxval} />
                                                            <div className="clear"></div>
                                                            {/* <legend className="subheadpredection">{inputparamval[3].displayname}</legend>
                                                            <input type={inputparamval[3].displaytype} className="modal-form-fields form-field decisiontreerepeats" name={inputparamval[3].id} min={inputparamval[3].minval} max={inputparamval[3].maxval} />
                                                            <div className="clear"></div> */}

                                                            <legend className="subheadpredection">{inputparamval[3].displayname}</legend>
                                                            <select className="sort-attribute-name form-field decisiontreesplit" name={inputparamval[3].id}>
                                                                {inputparamval[3].value.map((val) => {
                                                                    return <option value={val.value}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>
                                                            <legend className="subheadpredection">{inputparamval[4].displayname}</legend>
                                                            <input type={inputparamval[4].displaytype} className="modal-form-fields form-field decisiontreetunelength" name={inputparamval[4].id} min={inputparamval[4].minval} max={inputparamval[4].maxval} />
                                                            <div className="clear"></div>
                                                            <div className="col-md-12 modeldatatype">
                                                                <input type="hidden" className={inputparamval[5].id} name={inputparamval[5].name} defaultValue={inputparamval[5].displaytype} /></div>
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                            })}
                                            {/* <!-- Decision Tree --> */}
                                            {/* <!-- Clustering --> */}
                                            {this.props.inputParameters.map((inputParam) => {
                                                if (inputParam.modelType == 'clustering') {
                                                    var inputparamval = JSON.parse(inputParam.inputParameter);
                                                    return (<div className="mlparameterlists mlclustering hide">
                                                        <h2>{inputParam.modelName}:</h2>
                                                        <br />
                                                        <div className="col-lg-5 col-xs-12">
                                                            <legend className="subheadpredection">{inputparamval[0].displayname}</legend>
                                                            <input type={inputparamval[0].displaytype} className="modal-form-fields form-field kvalue" name={inputparamval[0].id} from={inputparamval[0].fromval} min={inputparamval[0].minval} max={inputparamval[0].maxval} />
                                                            <div className="clear"></div>

                                                            <legend className="subheadpredection">{inputparamval[1].displayname}</legend>
                                                            <select className="sort-attribute-name form-field categoricalencoding" name={inputparamval[1].id}>

                                                                {inputparamval[1].value.map((val) => {
                                                                    return <option value={val.value}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>

                                                            {/* <legend className="subheadpredection">{inputparamval[2].displayname}</legend>
                                                            <select className="sort-attribute-name form-field ignore_const_cols" name={inputparamval[2].id}>
                                                                {inputparamval[2].value.map((val) => {
                                                                    return <option value={val.name}>{val.name}</option>
                                                                })}
                                                            </select> *
                                                            <div className="clear"></div>

                                                            <legend className="subheadpredection">{inputparamval[2].displayname}</legend>
                                                            <select className="sort-attribute-name form-field score_each_iteration" name={inputparamval[3].id}>
                                                                {inputparamval[2].value.map((val) => {
                                                                    return <option value={val.name}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>

                                                            <legend className="subheadpredection">{inputparamval[3].displayname}</legend>
                                                            <select className="sort-attribute-name form-field estimate_k" name="{inputparamval[4].id}">
                                                                {inputparamval[3].value.map((val) => {
                                                                    return <option value={val.name}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>*/}

                                                            <legend className="subheadpredection">{inputparamval[2].displayname}</legend>
                                                            <input type={inputparamval[1].displaytype} className="modal-form-fields form-field max_iterations" name={inputparamval[2].id} min={inputparamval[2].minval} max={inputparamval[2].maxval} />
                                                            <div className="clear"></div>

                                                            <legend className="subheadpredection">{inputparamval[3].displayname}</legend>
                                                            <select className="sort-attribute-name form-field standardize" name={inputparamval[3].id}>
                                                                {inputparamval[3].value.map((val) => {
                                                                    return <option value="{val.name}">{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>

                                                            {/* <legend className="subheadpredection">{inputparamval[7].displayname}</legend>
                                                            <input type={inputparamval[1].displaytype} className="modal-form-fields form-field max_runtime_secs" name={inputparamval[7].id} min={inputparamval[7].minval} max={inputparamval[7].maxval} />
                                                            <div className="clear"></div> */}
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                            })}
                                            {/* <!-- /Clustering --> */}
                                            <div className="automlsettingcontent hide">
                                                <div className="automlkpi-container">
                                                    <div className="col-md-3 col-xs-12 col-xs-height" id="trained-models"></div>
                                                    <div className="col-md-3 col-xs-12 col-xs-height" id="training-time-taken"></div>
                                                    <div className="col-md-3 col-xs-12 col-xs-height" id="trained-data"></div>
                                                    <div className="col-md-3 col-xs-12 col-xs-height" id="automl-test-data"></div>
                                                </div>
                                                <div id="trained-models-summarytable"></div>
                                            </div>
                                            {/* Sentiment Analysis Parameter */}
                                            <div className="mlparameterlists mltextvals hide">
                                                <h2 className="knowledgeval">Knowledge-based Approach&nbsp;:</h2>
                                                <h2 className="statisticval hide">Statistical Approach&nbsp;:</h2>
                                                <br />
                                                <div className="knowledgeval"><i className="fa fa-info-circle"></i>&nbsp;&nbsp;Classifies text based on words that emanate emotion. Pre-defined sentiment column is not required for this type of Analysis.</div>
                                                <div className="statisticval hide"><i className="fa fa-info-circle"></i>&nbsp;&nbsp;Utilizes ML Algorithms to predict the sentiment. It requires data to have a target column (sentiment of each text row) and a text column.​</div>
                                                <br />
                                                <div className="col-lg-5 col-xs-12">
                                                    <legend className="subhead marg-top-10">
                                                        <div className="attraxisnames">{`Text Column`}</div>
                                                        <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdowntextcols" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showTextColumnsDropDown}>
                                                            <i className="fa fa-plus-circle"></i>
                                                        </button>
                                                        <ul className="list-group dropdown-menu dropdown-menu-right textcolsdropdown" aria-labelledby="dropdowntextcols" role="menu">
                                                        </ul>
                                                    </legend>
                                                    <div className="marg-top-10">
                                                        <ul className="list-group mltextcolumns"></ul>
                                                    </div>
                                                    {/* <div className="mlyaxis statisticval hide">
                                                    <legend className="subhead">
                                                        <div className="attraxisnames">{`Target Column`}</div>
                                                        <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdownyaxis" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showyAxisDropDown}>
                                                            <i className="fa fa-plus-circle"></i>
                                                        </button>
                                                        <ul className="list-group dropdown-menu dropdown-menu-right yaxisdropdown" aria-labelledby="dropdownyaxis" role="menu">
                                                        </ul>
                                                    </legend>
                                                    <div className="marg-top-10">
                                                        <ul className="list-group mlpredictyaxisattributes"></ul>
                                                    </div>
                                                </div> */}
                                                    <div className="container-fluid nopadding">
                                                        <h2>{`Model Type`}</h2>
                                                        <div className="col-lg-12 nopadding marg-top-10">
                                                            <select className="sort-attribute-name nomargin knowledgeval sentiment-model-type">
                                                                <option value="auto">Auto</option>
                                                                <option value="textblob">TextBlob</option>
                                                                <option value="sentiword">SentiWord</option>
                                                                <option value="vader">Vader</option>
                                                            </select>
                                                            <select className="sort-attribute-name nomargin statisticval sentiment-model-type hide">
                                                                <option value="auto">Auto</option>
                                                                <option value="svm">SVM</option>
                                                                <option value="naive bayes">Naive Bayes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Sentiment Analysis Parameter */}
                                            {/** Sampling parameter */}
                                            {!this.state.isSampling ? <React.Fragment /> :
                                                    <div className="col-lg-5 col-xs-12">
                                                        <div className="mlparameterlists mlsamplevals">
                                                            <legend className="subhead marg-top-10">
                                                                <div className="attraxisnames">{`Sampling Criteria`}</div>
                                                                <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left disabled" data-tooltip={lang["mlreports.add"]} id="dropdownsamplingcriteria" aria-expanded="false" data-toggle="dropdown" onClick={this.props.ShowSamplingCriteriaValues}>
                                                                    <i className="fa fa-plus-circle"></i>
                                                                </button>
                                                                <ul className="list-group dropdown-menu dropdown-menu-right samplingcriteriadropdown" aria-labelledby="dropdowndatecols" role="menu">
                                                                </ul>
                                                            </legend>
                                                            <div className="marg-top-10">
                                                                <ul className="list-group mlsamplingcriteriavalues"></ul>
                                                            </div>
                                                            <legend className="subhead marg-top-10">
                                                                <div className="attraxisnames">{`Sampling Parameters`}</div>
                                                                <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdownsamplingparameters" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showSamplingParameters}>
                                                                    <i className="fa fa-plus-circle"></i>
                                                                </button>
                                                                <ul className="list-group dropdown-menu dropdown-menu-right samplingparamdropdown" aria-labelledby="dropdowndatecols" role="menu">
                                                                </ul>
                                                            </legend>
                                                            <div className="marg-top-10">
                                                                <ul className="list-group mlsampleparameters"></ul>
                                                            </div>
                                                            <h2>{`Sample size caliculator`}</h2>
                                                            <br />
                                                            <select className="sort-attribute-name nomargin sampling-criteria-type" onChange={this.onSamplingMethodChange}>
                                                                {/* <option value="auto">Auto</option> */}
                                                                <option value="manual">Manual</option>
                                                            </select>
                                                            <br />
                                                            {this.state.isAutoSampling ?
                                                                <React.Fragment>
                                                                    <div className="container-fluid nopadding">
                                                                        <h2>{`Confidence level`}</h2>
                                                                        <select className="sort-attribute-name nomargin sampling-confidence-level">
                                                                            <option value="80">80</option>
                                                                            <option value="85">85</option>
                                                                            <option value="90">90</option>
                                                                            <option value="95">95</option>
                                                                            <option value="99">99</option>
                                                                        </select>
                                                                    </div>
                                                                    <legend className="subhead marg-top-10">
                                                                        <div className="attraxisnames">{`Confidence interval`}</div>
                                                                        <br />
                                                                        <input type="number" className="modal-form-fields form-field confidenceinterval" />
                                                                    </legend></React.Fragment> :
                                                                <legend className="subhead marg-top-10">
                                                                    <div className="attraxisnames">{`Percentage`}</div>
                                                                    <br />
                                                                    <input type="number" className="modal-form-fields form-field manual-confidence" />
                                                                </legend>
                                                            }
                                                            <br />
                                                            <div>
                                                                <button type="button" className="btn btn-bird  marg-righ-5" onClick={this.runSampling}><i className="fa fa-check marg-righ-5"></i>RUN</button>
                                                                <button type="button" className="btn btn-bird  marg-righ-5" onClick={() => { this.setState({ isSamplingPreview: !this.state.isSamplingPreview,isAutoSampling:false },()=>{this.preFetchSampling();this.getAllSamples()})}}><i></i>Cancel</button>
                                                            </div>

                                                        </div>
                                                    </div>
                                            }
                                            {/** Sampling parameter */}
                                        </div>
                                    </div>
                                    </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}