import React, { Component } from 'react'
import ReactDOM from "react-dom"
import { getMessage } from '../js/helpers/utils/BirdMessage';
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from "../SendRequest";
import PubSub from 'pubsub-js'
import jstz from 'jstz'
import canvg from 'canvg'
import html2canvas from 'html2canvas'
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as ChartUtil from "../js/helpers/utils/chartutil";
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import Clipboard from 'clipboard';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
const $ = window.$;

export default class EmbedReportView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        var that = this;
        if (this.props.reportId == null || this.props.reportId == undefined) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
        } else {
            this.state.reportId = this.props.reportId
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            let getrequestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            var expiryTime = "00/00/0000 00:00"+"@@@"+window.localStorage.getItem("serverTimezone");
            var formData = new FormData();
            formData.set("dateSelectionn", ChartUtil.birdbtoa("embed",expiryTime));
            formData.set("timeSelectionn", "00:00");
            formData.set("embeddedLoginId", "1");
            formData.set("reportId", btoa(this.props.reportId));
            formData.set("isFromSmartInsight", that.props.isFromSmartInsight != undefined ? that.props.isFromSmartInsight : false);
            formData.set("isFromCustomStoryboard", that.props.isCustomStoryboard != undefined ? that.props.isCustomStoryboard : false);
            formData.set("datamodelId", that.props.datamodelId != undefined ? btoa(that.props.datamodelId) : null);
            formData.set("pathName", window.location.origin + "/"+window.localStorage.getItem("appName")+"/welcome")
            SendRequest({
                url: "publishreport",
                body: formData,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".embeddedspinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    } else {
                        console.log(response);
                        SendRequest({
                            url: "getUsersForSharing",
                            queryString: "",
                            sucessFunction: collection => {
                                that.state.collection = collection;
                                that.render(response);
                            },
                            rejectFunction: () => { },
                            headers: getrequestHeaders
                        });
                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
    }
    cancelEmbedModal() {
        ReactDOM.unmountComponentAtNode(document.querySelector('.embedreportdiv'))
    }
    componentDidMount() {

    }
    render(embeddedReportModel) {
        var localtimezone = new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1].substr(0, 8) + "(" + jstz.determine().name() + ")";
        var tempUsers = this.state.collection;
        tempUsers.sort(function (a, b) {
            var x = a.username.toLowerCase();
            var y = b.username.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        //  $('.embedreportdiv').append(this.template({embeddedReportModel: embeddedReportModel.toJSON(), reportName: this.options.reportName, localtimezone: localtimezone, servertimezone: this.options.servertimezone, features: this.options.features }));
        ReactDOM.render(<EmbedReportViewTemplate sendEmbeddedReportModel={this.sendEmbeddedReportModel} cancelEmbedModal={this.cancelEmbedModal} embeddedReportModel={embeddedReportModel} reportName={this.props.reportName} localtimezone={localtimezone} servertimezone={this.props.servertimezone} features={this.props.features} users={tempUsers} showadvanced={this.showadvanced} searchusersgroups={this.searchusersgroups}/>, document.querySelector('.embedreportdiv'));
        $('#embedurl').html(embeddedReportModel.private);
        $('#embedreportenddate').datepicker({
            minDate: new Date(),
        });
        var that = this;
        $('#embedreportendTime').clockpicker({
            autoclose: true,
            'default': 'now',
            afterDone: function () {
                if (!that.props.isFromSmartInsight) {
                    PubSub.publish("updatembbedreport")
                } else {
                    PubSub.publish("updatembbedsmatinsightreport")
                }
            }
        }).on('change', function (e) {
            if (!that.props.isFromSmartInsight) {
                PubSub.publish("updatembbedreport")
            } else {
                PubSub.publish("updatembbedsmatinsightreport")
            }
        })
        new Clipboard('.copyembed');
        $(".users-select").chosen({
            no_results_text: lang["storyboard.oops"],
            width: "100%",
            search_contains: true
        });
        ModalProperties.resize();
        $(window).on("resize", function () {
            ModalProperties.resize();
        });
        if($('.publishurl:checked').attr("data-value")==="with login"){
            $("#emailto").hide();
        }
        return (<div></div>)
    }
    sendEmbeddedReportModel = (e) => {
        $('#embeddedspinner').show();
        $('.narrativecontainer').css("overflow", "hidden");
        var that = this;
        var $this = e.currentTarget;
        var fromEmail = $("#frommail").attr('data-name');
        var embedscreenshot = $(".embedscreenshots input[type='radio']:checked").attr('data-name');
        e.preventDefault();
        if ($('.publishurl:checked').attr("data-value")!="with login" && 
        ($('#emailto').val() == "" || !$('#emailto').val().match("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"))) {
            $('#embeddedspinner').hide();
            $('.email').addClass('show');
            return;
        }
        var dataurl = "";
        var bgcolor;
        if (embedscreenshot == "embedscreenshotyesold") {
            $('footer.footer').css("display", "none");
            $('.tileview').css('overflow', 'hidden');
            for (var i = 0; i < 15; i++) {
                $('#chart' + i).css('overflow', 'hidden');
            }
            var dobysize = $('.doby-grid-cell').length;
            for (var i = 0; i < dobysize; i++) {
                var dobyright = $('.doby-grid-cell.r' + i).css('right');
                var dobyleft = $('.doby-grid-cell.l' + i).css('left');
                $('.doby-grid-cell.r' + i).css('right', dobyright);
                $('.doby-grid-cell.l' + i).css('left', dobyleft);
            }
            //Fix for bootstrap table download not working
            var fhtcellExists = $('.fht-cell').length > 0 ? true : false;
            var fhtcelllength = $('.fht-cell').length
            if (fhtcellExists) {
                for (var i = 0; i < fhtcelllength; i++) {
                    $(".fht-cell" + i + "").hide();
                    $(".th-inner").css("background-image", "none")
                }
            }
            var canvas = document.querySelector("#storyboardcanvas");
            var storyboardwidth = $(window).width()
            var storyboardheight = $('.leftpane .grid-stack').height() + 120;
            if ($('.fullscreenmain .maxanim8').length != 1 && $("body.filters-hidden").length != 1) {
                storyboardheight = storyboardheight + 60
            }
            canvas.setAttribute('width', storyboardwidth);
            canvas.setAttribute('height', storyboardheight);
            var tilesize = $('.tileview').length;
            var queryselector = $('.leftpane');
            if (embedscreenshot == "embedscreenshotyes") {
                $('.viewembedreportmodal').css('display', 'none');
                $('.screenshotmessage').removeClass('hide');

            }
            if ($('.fullscreenmain .maxanim8').length == 1) {
                //$('.fullscreenmain .maxanim8').find('.refreshsinglecharts1').eq(0).click();

                // Hide chart and show image
                //$('.fullscreenmain .maxanim8').find('.thumbimage').css('display','block');
                //$('.highcharts-container').css('display','none');
                $('.metric-container').css('display', 'block');
                $('.worldmaparea').css('display', 'block');

                queryselector = $('.fullscreenmain .maxanim7');
                bgcolor = $('.fullscreenmain .maxanim10').css('background-color');
                if (bgcolor == 'rgba(0, 0, 0, 0)' || bgcolor == undefined) {
                    $('.fullscreenmain .maxanim7').css('background-color', 'rgb(255,255,255)');
                }
                canvas = document.querySelector("#singlecanvas");
                var singlewidth = $(window).width()
                var singleheight = $('.fullscreenmain').height() + 100;
                canvas.setAttribute('width', singlewidth);
                canvas.setAttribute('height', singleheight);
            }
            else {
                for (var i = 0; i < tilesize; i++) {
                    if ($('.tileview[data-num=' + i + ']').length == 1) {
                        var temp = $('.tileview[data-num=' + i + ']').position().top;
                        $('.tileview[data-num=' + i + ']').css('top', temp);
                    } else {
                        tilesize++;
                    }
                }
            }
            var svgElements = $("body").find('svg');
            var getBackgroundColor = $(".maxanim7").css('background-color');
            if (getBackgroundColor == 'rgba(0, 0, 0, 0)' || getBackgroundColor == undefined) { getBackgroundColor = "rgb(255,255,255)" }
            svgElements.each(function () {
                var canvas, xml;
                canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                ctx.fillStyle = getBackgroundColor;
                canvas.className = "screenShotTempCanvas";
                xml = (new XMLSerializer()).serializeToString(this);
                xml = xml.replace(/xmlns=\"http:\/\/www\.w3\.org\/2000\/svg\"/, '');
                canvg.fromString(ctx, xml);
                $(canvas).insertAfter(this);
                $(this).attr('class', 'tempHide');
                $(this).hide();

            });
            //Fix for Leaflet Overlapping
            var leafletElemetns = $("body").find(".leafletcontainer");
            leafletElemetns.each(function () {
                var $this = this
                var mapPane = $($this).find(".leaflet-map-pane")[0];
                var mapTransform = mapPane.style.transform.replace("translate3d(", "").split(",");
                var mapX = parseFloat(mapTransform[0].replace("px", ""));
                var mapY = parseFloat(mapTransform[1].replace("px", ""));
                mapPane.style.transform = "translate3d(0px,0px,0px)";

                //Map bg images
                var myTiles = $($this).find(".leaflet-map-pane img.leaflet-tile");
                var tilesLeft = [];
                var tilesTop = [];
                for (var i = 0; i < myTiles.length; i++) {
                    var tileTransform = myTiles[i].style.transform.replace("translate3d(", "").split(",");
                    if (tileTransform[0] != "") {
                        tilesLeft.push(parseFloat(tileTransform[0].replace("px", "")));
                        tilesTop.push(parseFloat(tileTransform[1].replace("px", "")));
                        myTiles[i].style.left = (tilesLeft[i] + mapX) + "px";
                        myTiles[i].style.top = (tilesTop[i] + mapY) + "px";
                    }
                }
                $($this).find(".leaflet-map-pane img.leaflet-tile").css("transform", "")
                //Map Markers
                var myMarkerTiles = $($this).find(".leaflet-map-pane img.leaflet-marker-icon");
                var markerTilesLeft = [];
                var markerTilesTop = [];
                for (var i = 0; i < myMarkerTiles.length; i++) {
                    var markerTileTransform = myMarkerTiles[i].style.transform.replace("translate3d(", "").split(",");
                    if (markerTileTransform[0] != "") {
                        markerTilesLeft.push(parseFloat(markerTileTransform[0].replace("px", "")));
                        markerTilesTop.push(parseFloat(markerTileTransform[1].replace("px", "")));
                        myMarkerTiles[i].style.left = (markerTilesLeft[i] + mapX) + "px";
                        myMarkerTiles[i].style.top = (markerTilesTop[i] + mapY) + "px";
                    }
                }
                $($this).find(".leaflet-map-pane img.leaflet-marker-icon").css("transform", "");
                //Map Marker shadows
                var myMarkerShadowTiles = $($this).find(".leaflet-map-pane img.leaflet-marker-shadow");
                var markerShadowsLeft = [];
                var markerShadowsTop = [];
                for (var i = 0; i < myMarkerShadowTiles.length; i++) {
                    var markerShadowTransform = myMarkerShadowTiles[i].style.transform.replace("translate3d(", "").split(",");
                    if (markerShadowTransform[0] != "") {
                        markerShadowsLeft.push(parseFloat(markerShadowTransform[0].replace("px", "")));
                        markerShadowsTop.push(parseFloat(markerShadowTransform[1].replace("px", "")));
                        myMarkerShadowTiles[i].style.left = (markerShadowsLeft[i] + mapX) + "px";
                        myMarkerShadowTiles[i].style.top = (markerShadowsTop[i] + mapY) + "px";
                    }
                }
                $($this).find(".leaflet-map-pane img.leaflet-marker-shadow").css("transform", "");
            });
            /*$('.ui-resizable-handle').css('display','none');*/
            $(".sparklinecontainer").parent().css("overflow", "hidden");
            html2canvas(queryselector, { canvas: canvas, taintTest: true, logging: true, useCORS: true }).then(function (canvas) {
                if (embedscreenshot == "embedscreenshotyes") {
                    dataurl = canvas.toDataURL();
                } else {
                    dataurl = "";
                }
                setTimeout(function () {
                    $('.narrativecontainer').css("overflow", "auto");
                    // Show chart and hide image
                    $('.thumbimage').css('display', 'none');
                    //$('.highcharts-container').css('display','block');
                    $(".viewembedreportmodal").hide();
                    $('.screenshotmessage').addClass('hide');
                    // $('.viewembedreportmodal').css('display','block');
                    for (var i = 0; i < tilesize; i++) {
                        $('.tileview[data-num=' + i + ']').css("top", "");
                        $('#chart' + i + '').css('overflow', '');
                        if (bgcolor == 'rgb(255,255,255)') {
                            $('.fullscreenmain .maxanim7').css('background-color', '');
                        }
                    }
                    for (var i = 0; i < dobysize; i++) {
                        $('.doby-grid-cell.r' + i + '').css('right', '');
                        $('.doby-grid-cell.l' + i + '').css('left', '');
                    }
                    if (fhtcellExists) {
                        for (var i = 0; i < fhtcelllength; i++) {
                            $(".fht-cell" + i + "").show();
                            $(".th-inner").css("background-image", "")
                        }
                    }
                    $("body").find('.screenShotTempCanvas').remove();
                    $("body").find('.tempHide').show().removeClass('tempHide');
                }, 5000);

                var leafletElemetns = $("body").find(".leafletcontainer");
                leafletElemetns.each(function () {
                    var $this = this;
                    //Map Markers Reset
                    var myMarkerTiles = $($this).find(".leaflet-map-pane img.leaflet-marker-icon");
                    var markerTilesLeft = [];
                    var markerTilesTop = [];
                    for (var i = 0; i < myMarkerTiles.length; i++) {
                        myMarkerTiles[i].style.transform = "translate3d(" + myMarkerTiles[i].style.left + "," + myMarkerTiles[i].style.top + ",0)";
                        myMarkerTiles[i].style.left = "";
                        myMarkerTiles[i].style.top = "";
                    }
                    //Map Marker shadows Reset
                    var myMarkerShadowTiles = $($this).find(".leaflet-map-pane img.leaflet-marker-shadow");
                    var markerShadowsLeft = [];
                    var markerShadowsTop = [];
                    for (var i = 0; i < myMarkerShadowTiles.length; i++) {
                        myMarkerShadowTiles[i].style.transform = 'translate3d(' + myMarkerShadowTiles[i].style.left + "," + myMarkerShadowTiles[i].style.top + ",0)";
                        myMarkerShadowTiles[i].style.left = "";
                        myMarkerShadowTiles[i].style.top = "";
                    }
                })
                $(".sparklinecontainer").parent().css("overflow", "");
                $('footer.footer').css("display", "block");

                if (ChartInfo.functions.isScriptData($('#embedurl').val()) || ChartInfo.functions.isScriptData($('#emailto').val())
                    || ChartInfo.functions.isScriptData($('#emailsubject').val()) || ChartInfo.functions.isScriptData($('#emailbody').val())) {
                    return true;
                }
            });
        }
        if (embedscreenshot == "embedscreenshotyes") {
            
            if($(".singlereportview").length>0){
                var node = document.getElementsByClassName("maxanim10")[0];
                htmlToImage.toPng(node,{backgroundColor:"#ffffff"})
                    .then(function (dataUrl) {
                    dataurl = dataUrl;
                    sendStart();
                    })
                    .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                    });
            }else{
                var node = document.getElementsByClassName("leftpane")[0];
                htmlToImage.toPng(node)
                    .then(function (dataUrl) {
                    dataurl = dataUrl;
                    sendStart();
                    })
                    .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                    });
            }
        }else{
            sendStart()
        }
        function sendStart(){
            var mailArray = [];
            if($('.publishurl:checked').attr("data-value")=="with login"){
                $('.users-select optgroup[label=Users] option:selected').each(function (index, value) {
                    var userObject = {};;
                    userObject["email"] = $(value).attr("userEmail");
                    mailArray.push(userObject);
                });
            }else{
                var emailObject = {};
                emailObject["email"] = $('#emailto').val();
                mailArray.push(emailObject);
            }
            var toEmail = '';
        $.each(mailArray, function (i, j) {
            var emails = mailArray[i].email;
            toEmail += emails + ",";
        });
        if (toEmail != undefined) {
            toEmail = toEmail.substring(0, toEmail.length - 1);
        }
        if($('.publishurl:checked').attr("data-value")=="with login"){
            if (toEmail === undefined || toEmail === "") {
                $('#embeddedspinner').hide();
                $('.email').removeClass('show');
                $('.emailuser').addClass('show');
                setTimeout(function(){
                    $('.emailuser').removeClass('show');
                },3000)
                return;
            }
        }
            var dateSelected = $('#embedreportenddate').val();
            var timeSelected = $('#embedreportendTime').val();
            var formData = new FormData();
            formData.set("embeddedReportUrl", $('#embedurl').val());
            formData.set("toEmail", toEmail);
            formData.set("emailSubject", $('#emailsubject').val());
            formData.set("emailBody", $('#emailbody').val());
            formData.set("dataurl", dataurl);
            formData.set("access_permissions", $('.publishurl:checked').attr("data-value"));
            formData.set("fromEmail", fromEmail);
            formData.set("expirydate", dateSelected != undefined ? dateSelected : "");
            formData.set("expirytime", timeSelected);

            formData.set("reportId", that.state.reportId);
            formData.set("reportName", $('#emailsubject').attr('value') != undefined ? $('#emailsubject').attr('value') : "");
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };

            SendRequest({
                url: "sendembeddedreportemail",
                body: formData,
                sucessFunction: (response) => {
                    if (response.status == 'success') {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess04'));
                        //$('#main').prepend('<div class="alert alert-success birdsuccess alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 9999;left: 20%;">Message has been sent successfully</div>');
                        setTimeout(function () {
                            $('.birdmessage-center').removeClass("message-top-in");
                            $('#embeddedspinner').hide();
                            $(".spinner").hide();
                            ReactDOM.unmountComponentAtNode(document.querySelector('.embedreportdiv'))
                        }, 3000);
                    } else if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $('#embeddedspinner').hide();
                        //$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 9999;left: 20%;">'+response.error+'</div>');
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                        setTimeout(function () {
                            $('.birdmessage-center').removeClass("message-top-in");
                            $('#embeddedspinner').hide();
                            $(".spinner").hide();
                        }, 3000);
                    }
                    else {
                        $(".spinner").hide();
                        $('#embeddedspinner').hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(response.status);
                        //$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 9999;left: 20%;">'+response.status+'</div>');
                        setTimeout(function () {
                            $('.birdmessage-center').removeClass("message-top-in");
                            $('#embeddedspinner').hide();
                            $(".spinner").hide();
                        }, 3000);
                    }

                },
                rejectFunction: (response) => {},
                headers: requestHeaders
            });
        }
        
    }

    showadvanced = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        $($this).parent().find('.filtersearching').toggle('slow');
    }
    searchusersgroups = (e) => {
        e.preventDefault();
   			var startswith = $('#searchnamestartswith').val();
			var endswith = $("#searchnameendswith").val();
			var contains = $("#searchnamecontains").val();
    
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getSearchedGroupsList",
            queryString: "?startswith=" + startswith + "&endswith=" + endswith + "&contains=" + contains,
            sucessFunction: (response) => {
               var groupOptionArray = [];
            $.each(response, function(i, group)
            {
                groupOptionArray[i] = "<option key='" + i + "' value='" + group.groupId + "'>" + group.groupName + "</option>";
            });
            $('.users-select>optgroup.groupids').find('option').not(':selected').remove()
            $('.users-select>optgroup.groupids').prepend(groupOptionArray.join(''));
            $('.users-select').trigger("chosen:updated");
            $('.users-select').trigger('chosen:open');
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
        SendRequest({
            url: "getsearchedusersforsharing",
            queryString: "?startswith=" + startswith + "&endswith=" + endswith + "&contains=" + contains +"&isFromGovernance=" + false,
            sucessFunction: (response) => {
                var userOptionsArray = [];
                $.each(response, function(i, user)
                        {	if(!user.isLoginUser){
                            userOptionsArray[i] = "<option key='" + i + "' value='" + user.userid + "' userEmail='"+user.email+"'>" + user.username+" ("+user.email+")" + "</option>";
                            }
                        });
                $('.users-select>optgroup.userids').find('option').not(':selected').remove()
                $('.users-select>optgroup.userids').prepend(userOptionsArray.join(''));
                $('.users-select').trigger("chosen:updated");
                $('.users-select').trigger('chosen:open'); 
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
    }

}

class EmbedReportViewTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            <div className="modal fade viewembedreportmodal in" id="viewembedreportmodal"
                tabIndex="-1" role="dialog" aria-labelledby="embedreportmodal"
                aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                {/* <!-- embedreportview.js --> */}
                <div
                    className="modal-dialog modal-lg modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="embedreportclose" onClick={that.cancelEmbedModal} aria-hidden="true"
                                data-dismiss="modal">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-code"></i>{lang["storyboard.embedreport"]}</h4>
                        </div>
                        <div className="modal-body scrollsetnew">
                            <div className="alert alert-success sendembeddedreportsuccess"
                                style={{ display: "none" }} role="alert">{lang["storyboard.msgsuccess"]}</div>
                            <div className="alert alert-danger sendembeddedreporterror"
                                style={{ display: "none" }} role="alert">{lang["storyboard.msgerror"]}</div>
                            <div className="container-fluid">
                                <div className="row filtercontent">
                                    <form className="form-horizontal col-xs-12 newgroupform marg-bot-10"
                                        role="form">

                                        <div className="form-group form-group-sm col-lg-4 col-md-4 col-sm-12 col-xs-12 nopadding">
                                            <label for="urlaccess" className="col-xs-12 subhead">{lang["storyboard.permission"]}</label>
                                            <div className="col-xs-12 marg-left-10 nopadding reportLoginType">
                                                <div className="marg-left-10 marg-top-10">
                                                    <label><input type="radio" id="1" name="urlaccess" onChange={this.changePublishUrl}
                                                        className="publishurl updateurl" value="A" defaultChecked="checked"
                                                        purl={that.embeddedReportModel.private} data-value="with login" /> {lang["storyboard.withlogin"]}</label> <br></br> <label><input type="radio" id="2"
                                                        name="urlaccess" className="publishurl updateurl" value="I" onChange={this.changePublishUrl}
                                                        purl={that.embeddedReportModel.public} data-value="without login" /> {lang["storyboard.withoutlogin"]}</label>
                                                </div>
                                            </div>
                                            <label for="urlaccess" className="col-xs-12 subhead marg-top-10">{lang["storyboard.urlexpiry"]}</label>
                                            <div className="col-xs-12 marg-top-10">
                                                <div className="form-group form-group-sm">
                                                    <div className="col-xs-12">
                                                        <div className="input-group">
                                                            <input type="text" id="embedreportenddate" className="updateurl"
                                                                placeholder={lang["storyboard.date"]} readOnly="readonly" style={{ cursor: "pointer", opacity: "1", color: "#00083e" }} />
                                                            <div className="input-group-addon">
                                                                <i className="fa fa-calendar"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12">
                                                <div className="form-group form-group-sm">
                                                    <div className="col-xs-12">
                                                        <div className="input-group">
                                                            <input type="text" id="embedreportendTime"
                                                                placeholder={lang["storyboard.time"]} className="form-control updateurl" readOnly="readonly" style={{ cursor: "pointer", opacity: "1", color: "#00083e" }} />
                                                            <div className="input-group-addon">
                                                                <i className="fa fa-clock-o"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 nopadding">
                                            <label for="embedurl" className="col-xs-12 subhead">{lang["storyboard.urlaccess"]}</label>
                                            <div className="col-xs-12 marg-left-10 nopadding reportUrlAccessing">
                                                <div className="marg-left-10 marg-top-10">
                                                    <label><input type="radio" id="eI1" name="embediframe" onChange={this.changeIframeUrl}
                                                        className="iframeurl" defaultChecked="checked" /> {lang["storyboard.withoutiframe"]}</label> <label
                                                            className="marg-righ-10"><input type="radio" id="eI2" onChange={this.changeIframeUrl}
                                                                name="embediframe" className="iframeurl" /> {lang["storyboard.withframe"]}</label> <span
                                                                    className="btn btn-bird copyembed marg-left-10" id="copyembedclip"
                                                                    data-clipboard-target="#embedurl"><i
                                                                        className="fa fa-clipboard" id="clipboards"></i>&nbsp;&nbsp;{lang["storyboard.copyclip"]}</span>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 marg-top-10">
                                                <textarea className="form-control textarea resize-vertical embedtextareawidth" style={{ marginTop: "12px" }}
                                                    rows="13" id="embedurl" name="embedurl" placeholder="" readOnly></textarea>
                                            </div>
                                        </div>



                                    </form>
                                    <form className="form-horizontal emailembedurlform" role="form">
                                        <div className="col-xs-12 checkbox"
                                            style={{ paddingTop: "0px", minHeight: "10px", lineHeight: "22px", marginBottom: "10px" }}>
                                            <label className="subhead"><input type="checkbox"
                                                className="emailurlformvalid" onChange={this.emailurlformvalid} id="emailreports" />{lang["storyboard.reporturl"]}</label>
                                        </div>
                                        <div className="marg-top-10 col-xs-12 emailurlform hide">
                                            <div className="form-group form-group-sm">
                                                <label for="emailto"
                                                    className="col-lg-4 col-xs-3 control-label important">{lang["storyboard.to"]}</label>
                                                <div className="col-lg-5 col-xs-9">
                                                    <input type="email" className="form-control" id="emailto"
                                                        placeholder="" />
                                                    <span className="users-select-chosen">
                                                    <select name="grouping" data-placeholder={lang["storyboard.slctuser"]} className="users-select  modal-form-fields" id="groupings"  multiple tabIndex="6">
                                                            <optgroup className="userids" label={lang["storyboard.users"]}>
                                                             {this.props.users.map((user, index) => {
                                                                 if (!user.isLoginUser) {
                                                                        return <option key={index} value={user.userid} userEmail={user.email}>{`${user.username} (${user.email})`}</option>
                                                                    }
                                                             })}
                                                            </optgroup>
                                                    </select>
                                                    <span class="help-block grouperror hidden"></span>
                                                    <button class="btn btn-link pull-right btn-sm showadvanced" onClick={that.showadvanced}>{lang["admin.users.advncd"]}</button>
						                            <div class="row marg-bot-10 filtersearching " style={{ display: "none" }}>
                                                            <div className="stringfilters searching ">
                                                                {lang["storyboard.startswith"]} <input type="text" id="searchnamestartswith" data-id="searchnamestartswith" className="searchword" />
                            								</div>
                            								<div className="stringfilters searching">
                                                                {lang["storyboard.endswith"]} <input type="text" id="searchnameendswith" data-id="searchnameendswith"/>
                            								</div>
                            								<div className="stringfilters searching col-lg-10 col-md-10 col-sm-12 col-xs-12" style={{marginLeft:"-5px"}}>
                                                                {lang["storyboard.contains"]} <input type="text" id="searchnamecontains" data-id="searchnamecontains"/>
                            								</div>
                                                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{marginTop: "1px"}}>
                            									<button type="button" id="searchnames" className="btn btn-sm btn-bird marg-top-20 btn-highlight" onClick={that.searchusersgroups}>{lang["storyboard.go"]}</button>
                            								  </div>
                                                    </div>
                                                    </span>    
                                                    <span className="help-block hide email">{lang["storyboard.validemail"]}</span>
                                                    <span className="help-block hide emailuser">{lang["storyboard.validemailuser"]}</span>
                                                </div>
                                            </div>
                                            <div className="form-group form-group-sm">
                                                <label for="emailsubject"
                                                    className="col-lg-4 col-xs-3 control-label">{lang["storyboard.subject"]}</label>
                                                <div className="col-lg-5 col-xs-9">
                                                    <input type="text" className="form-control" id="emailsubject"
                                                        placeholder="" defaultValue={that.reportName} />
                                                        <input type="hidden" className="form-control emailfrom" id="frommail" placeholder="" data-name={that.embeddedReportModel.fromEmail} disabled="disabled" />
                                                </div>
                                            </div>
                                            <div className="form-group form-group-sm"
                                                style={{ marginBottom: "5px !important" }}>
                                                <label for="emailsubject"
                                                    className="col-lg-4 col-xs-3 control-label">{lang["storyboard.message"]}</label>
                                                <div className="col-lg-5 col-xs-9">
                                                    <textarea className="form-control textarea resize-vertical "
                                                        rows="2" id="emailbody" name="embedurl" placeholder=""></textarea>
                                                </div>
                                            </div>
                                            {that.features.map((featuresModule, index) => {
                                                return (featuresModule.moduleName == 'story_board') ? (
                                                    featuresModule.features.map((feature, index) => {
                                                        return (feature.featureName == 'viewreport_embed_attachment' && feature.allow == true) ? (
                                                            <div className="form-group form-group-sm embedscreenshots">
                                                                <label for="emailscreenshot"
                                                                    className="col-lg-4 col-xs-3 control-label">{lang["storyboard.addscreenshot"]}</label>
                                                                <div className="col-lg-5 col-xs-9">
                                                                    <label className="marg-left-5"><input type="radio" id="1"
                                                                        data-name="embedscreenshotyes" name="embedscreenshot"
                                                                        className="embedscreenshot" /> {lang["storyboard.yes"]}</label> <label
                                                                            className="marg-left-10"><input type="radio" id="2"
                                                                                data-name="embedscreenshotno" name="embedscreenshot"
                                                                                className="embedscreenshot"  defaultChecked="checked"/> {lang["storyboard.no"]}</label>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    })
                                                ) : null
                                            })}
                                            {that.features.map((featuresModule, index) => {
                                                return (featuresModule.moduleName == 'story_board') ? (
                                                    featuresModule.features.map((feature, index) => {
                                                        return (feature.featureName == 'viewreport_maximize_embed_report_attachment' && feature.allow == true) ? (
                                                            <div className="form-group form-group-sm embedscreenshots">
                                                                <label for="emailscreenshot"
                                                                    className="col-lg-4 col-xs-3 control-label">{lang["storyboard.addscreenshot"]}</label>
                                                                <div className="col-lg-5 col-xs-9">
                                                                    <label className="marg-left-5"><input type="radio" id="1"
                                                                        data-name="embedscreenshotyes" name="embedscreenshot"
                                                                        className="embedscreenshot" /> {lang["storyboard.yes"]}</label> <label
                                                                            className="marg-left-10"><input type="radio" id="2"
                                                                                data-name="embedscreenshotno" name="embedscreenshot"
                                                                                className="embedscreenshot"  defaultChecked="checked"/> {lang["storyboard.no"]}</label>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    })
                                                ) : null
                                            })}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" onClick={that.sendEmbeddedReportModel}
                                className="btn btn-bird emailembedurlbutton pull-right hide marg-left-10"
                                id="emailembedurlbutton"><i className="fa fa-envelope"></i> {lang["storyboard.send"]}</button>
                            <button type="button" className="btn btn-bird pull-right " onClick={that.cancelEmbedModal}
                                data-dismiss="modal" id="emailcancel"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                        </div>
                        <div className='gridspinner' id='embeddedspinner' style={{ display: "none" }}>
                            <div className='gridspinnercontent'>
                                {/* <!-- <img alt="Loading..." src="img/loading9.gif"> --> */}
                                <div className="semi-circle-spin">
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>
        )
    }
    emailurlformvalid = () => {
        $(".emailurlform").toggleClass("show hide");
        $(".emailembedurlbutton").toggleClass("show hide");
    }
    changePublishUrl = (e) => {
        var $this = e.currentTarget;
        if (!this.props.isFromSmartInsight) {
            PubSub.publish("updatembbedreport")
        } else {
            PubSub.publish("updatembbedsmatinsightreport")
        }
        var embedaccess = $('.publishurl:checked').attr("id");
        var url = $('.publishurl:checked').attr("purl");
        var access_permissions = $('.publishurl:checked').attr("data-value");
        if (embedaccess == "1") {
            $('.iframeurl[id="1"]').prop({ checked: true });
            $('#embedurl').html(url);
        } else if (embedaccess == "2") {
            $('.iframeurl[id="1"]').prop({ checked: true });
            $('#embedurl').html(url);

        }
        if(access_permissions === "with login"){
            $(".users-select-chosen").show();
            $("#emailto").hide();
        }else if(access_permissions === "without login"){
            $(".users-select-chosen").hide();
            $("#emailto").show()
        }
    }
    changeIframeUrl = (e) => {
        var $this = e.currentTarget;
        var iframe = $('.iframeurl:checked').attr("id");
        var url = $('.publishurl:checked').attr("purl");
        if (iframe == "eI1") {
            $('#embedurl').html(url);
        }
        else if (iframe == "eI2") {
            $('#embedurl').html('<iframe width="100%" height="800" src="' + url + '" frameborder="0"></iframe>');
        }
    }
}