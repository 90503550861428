import React, { useState } from "react";
import SendRequest from "../SendRequest";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import { name } from "pubsub-js";
import _ from "lodash";
const $ = window.$;

const SurveyForm = (props) => {
    const [response, setFinalResponse] = useState([]);
    const closepopup = () => {
        $('.pivotdataviewwrap').remove();
        if (document.getElementById("datatablepopupmodal") != null) {
            $('#datatablepopupmodal').remove();
        }
    }
    const previewform = (e, props) => {
        let finalResponse = [];
        let unansweredQuestions = [];
        const questionsInformation = props.fetchData;
        if (questionsInformation == undefined || questionsInformation.length == 0) {
            return;
        }
        questionsInformation.map(obj => {
            let val = "";
            let textinput = "";
            if (obj.questionType == "radio" || obj.questionType == "multiradio") {
                val = $('form').find('input[questionid="' + obj.questionId + '"]:checked').attr('id');
                if (obj.required == "1" && val == undefined) {
                    unansweredQuestions.push(obj.questionId);
                }
                let resObj = { "fid": props.formId, "qid": obj.questionId, "oid": val != undefined ? val : "", "uniqueid": props.uniqueColumnId, "textinput": textinput != undefined ? textinput : "" };
                finalResponse.push(resObj);
            } else if (obj.questionType == "checkbox" || obj.questionType == "multicheckbox") {
                if ($('form').find('input[questionid="' + obj.questionId + '"]:checked').length == 0) {
                    if (obj.required == "1") {
                        unansweredQuestions.push(obj.questionId);
                    }
                    let resObj = { "fid": props.formId, "qid": obj.questionId, "oid": val != undefined ? val : "", "uniqueid": props.uniqueColumnId, "textinput": textinput != undefined ? textinput : "" };
                    finalResponse.push(resObj);
                } else {
                    $('form').find('input[questionid="' + obj.questionId + '"]:checked').map((i, val) => {
                        val = $(val).attr("id");
                        let resObj = { "fid": props.formId, "qid": obj.questionId, "oid": val != undefined ? val : "", "uniqueid": props.uniqueColumnId, "textinput": textinput != undefined ? textinput : "" };
                        finalResponse.push(resObj);
                    })
                }

            } else if (obj.questionType == "text" || obj.questionType == "number" || obj.questionType == "email") {
                textinput = $('form').find('input[questionid="' + obj.questionId + '"]').val();
                if (obj.required == "1" && textinput == undefined) {
                    unansweredQuestions.push(obj.questionId);
                }
                let resObj = { "fid": props.formId, "qid": obj.questionId, "oid": val != undefined ? val : "", "uniqueid": props.uniqueColumnId, "textinput": textinput != undefined ? textinput : "" };
                finalResponse.push(resObj);
            } else if (obj.questionType == "textarea") {
                textinput = $('form').find('textarea[questionid="' + obj.questionId + '"]').val();
                if (obj.required == "1" && textinput == undefined) {
                    unansweredQuestions.push(obj.questionId);
                }
                let resObj = { "fid": props.formId, "qid": obj.questionId, "oid": val != undefined ? val : "", "uniqueid": props.uniqueColumnId, "textinput": textinput != undefined ? textinput : "" };
                finalResponse.push(resObj);
            }

        })
        if (unansweredQuestions.length > 0) {
            $(".queryinfomodal.modalfilter.singlefilterdropdown").addClass('hide');
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage("BirdWarning161"));
            return;
        }
        setFinalResponse(finalResponse);
        $(".btn.previewform").addClass("hide");
        $(".btn.cancelform").addClass("hide");
        $(".btn.submitform").removeClass("hide");
        $(".btn.backtoform").removeClass("hide");

        $(".surveyform .form-data").addClass("previewmode");
        $(".surveyform .form-data input:not(:checked)").parent().addClass("previewmode");
        $(".surveyform .form-data input, .surveyform .form-data textarea").attr("disabled", "disabled")
    }
    const backtoform = () => {
        $(".btn.previewform").removeClass("hide");
        $(".btn.cancelform").removeClass("hide");
        $(".btn.submitform").addClass("hide");
        $(".btn.backtoform").addClass("hide");

        $(".surveyform .form-data").removeClass("previewmode");
        $(".surveyform .form-data input").parent().removeClass("previewmode");
        $(".surveyform .form-data input, .surveyform .form-data textarea").prop("disabled", false)
    }

    const updateData = (e, props) => {
        const { indexName, entityId, formVisibleColumns } = props.editData;
        var form_data = new FormData();
        form_data.append("data", JSON.stringify(response));
        SendRequest({
            url: "saveFormResponse",
            queryString: "",
            body: form_data,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.error)
                    setTimeout(function () {
                        $('.birderror').remove();
                    }, 3000);
                } else {
                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Success');
                    $('.birdmessage-info').empty().text(formInformation.end_note);
                    setTimeout(function () {
                        $('.birdmessage-container .close').click();
                    }, 3000);
                    closepopup();
                    props.history.push(`/${window.localStorage.getItem("appName")}/welcome/survey/` + btoa(entityId))
                    $(".spinner").hide();
                }
            },
            rejectFunction: (error) => { $(".spinner").hide(); },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }


    const { data, columns, allColumns, formData, formId, uniqueColumnId } = props;
    const row_data = data
    const { formDisableColumns, formVisibleColumns } = props.editData;
    let columnTypes = {};
    let column_names = [];
    allColumns.map(val => columnTypes[val.name] = val.type)
    allColumns.map(val => { if (val.name && column_names.indexOf(val.name) == -1) column_names.push(val.name) });

    const formInformation = props.formData[0];
    const questionsInformation = props.fetchData;
    var multiquestion = [];

    return (
        <div className='pivotdataviewwrap custombrandform surveyform template1'>
            <div className="pivotdataview nopadding">
                {/* <div className="ball-pulse"><div></div><div></div><div></div></div> */}
                {/* <div className="pivotdataviewclose" onClick={closepopup} id="pivotdataviewdatapop">
                    <i className='fa fa-times'></i>
                </div> */}

                <div className="container-fluid marg-bot-10 nopadding">
                    {/* <div className="row"> */}
                    <div className="container-fluid form-intro">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12 ">
                                        <div className="form-title"> {formInformation.form_name}</div>
                                        <p>{formInformation.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid form-info">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12">
                                        {formVisibleColumns!=undefined? formVisibleColumns.map((val2, index2) => {
                                            const { displayName, formDisplayName } = val2;
                                            return <div id={index2} className="col-xs-6 col-md-3">
                                                <div className="form-group">
                                                    <label>{formDisplayName}</label>
                                                    <p>{row_data[displayName]}</p>
                                                </div>
                                            </div>
                                        }):<React.Fragment/>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid form-data">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12" id="customforms">
                                        <form id={`survey${formInformation.formid}`}>
                                            {

                                                questionsInformation.map((a, index) => {
                                                    var questionOptions = JSON.parse(a.optionsDisplay);
                                                    if (a.parentQuestion == null) {
                                                        if (a.questionType == "radio" || a.questionType == "checkbox") {
                                                            return (
                                                                <div className="col-xs-12" key={index}>
                                                                    <div className={`form-group ${questionOptions.orientation}`}>
                                                                        <label htmlFor={`name${a.questionId}`}><h3 className={`name-label ${a.required} mainquestion`}>{a.questionText}</h3></label>
                                                                        {
                                                                            a.choices.map((b, index) => {
                                                                                return (
                                                                                    <div className="form-group surveychoice" key={index}>
                                                                                        <label>
                                                                                            <input className="form-control" questionid={a.questionId} name={`radio${a.questionId}`} defaultValue="" type={a.questionType} id={b.optionid} /> {b.option}
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        } else if (a.questionType == "number" || a.questionType == "text" || a.questionType == "email") {
                                                            return (
                                                                <div className="col-xs-12" key={index}>
                                                                    <div className={`form-group ${questionOptions.orientation}`}>
                                                                        <label htmlFor={`radio${a.questionId}`}><h3 className={`name-label ${a.required} mainquestion`}>{a.questionText}</h3></label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" defaultValue="" questionid={a.questionId} type={a.questionType} data-width={questionOptions.width} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        } else if (a.questionType == "textarea") {
                                                            return (
                                                                <div className="col-xs-12" key={index}>
                                                                    <div className={`form-group ${questionOptions.orientation}`}>
                                                                        <label htmlFor={`radio${a.questionId}`}><h3 className={`name-label ${a.required} mainquestion`}>{a.questionText}</h3></label>
                                                                        <div className="form-group">
                                                                            <textarea className="form-control" defaultValue="" questionid={a.questionId} type={a.questionType} data-width={questionOptions.width}></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    } else if (a.parentQuestion != null) {
                                                        var mainQuestion = a.parentQuestion;
                                                        var qlength = multiquestion.filter(c => c.parentQuestion == a.parentQuestion);
                                                        if (qlength.length == 0) {
                                                            multiquestion.push({ "parentQuestion": a.parentQuestion })

                                                            var allchoices = a.choices,
                                                                qlist = questionsInformation.filter(c => c.parentQuestion == a.parentQuestion), //filter grouped questions
                                                                choicelength = allchoices.length;

                                                            return (
                                                                <div className="col-xs-12" key={index}>
                                                                    <div className={`form-group ${questionOptions.orientation}`}>
                                                                        <label id="name-label"><h3 className={`name-label ${a.required} mainquestion`}>{a.parentQuestion}</h3></label>
                                                                        <div className={`form-group ${a.questionType}`}>
                                                                            <table>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{width: `40%`}}></th>
                                                                                        {
                                                                                            allchoices.map((d, index) => {
                                                                                                return (<th key={index} style={{width: `calc(60% / ${choicelength})`,}}>{d.option}</th>)
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        qlist.map((e, index) => {
                                                                                            var optionType = "radio"
                                                                                            if (e.questionType == "multicheckbox") {
                                                                                                optionType = "checkbox"
                                                                                            }
                                                                                            return (
                                                                                                <tr key={index}>
                                                                                                    <td >{e.questionText}</td>
                                                                                                    {
                                                                                                        e.choices.map((f, index) => {
                                                                                                            return (
                                                                                                                <td key={index}>
                                                                                                                    <div className="form-group surveychoice" key={index}>
                                                                                                                        <label>
                                                                                                                            <input className="form-control" questionid={e.questionId} name={`radio${e.questionId}`} defaultValue="" type={optionType} id={f.optionid} /><span> {f.option}</span>
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </tr>

                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                })
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid form-endnote hide">
                        <div className="">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <p>{formInformation.end_note}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid form-footer">
                        <div className="">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12 disclaimer">
                                        <p>{formInformation.disclaimer}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid form-controls">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <button type="button" className="btn btn-bird cancelform pull-left" onClick={closepopup} ><i className="fa fa-times marg-righ-5"></i>Cancel</button>
                                        <button type="button" className="btn btn-bird backtoform pull-left hide" onClick={backtoform} ><i className="fa fa-arrow-left marg-righ-5"></i>Back</button>
                                        <button type="button" className="btn btn-bird previewform active" onClick={e => previewform(e, props)} ><i className="fa fa-eye marg-righ-5"></i>Preview</button>
                                        <button type="button" className="btn btn-bird submitform active hide" onClick={e => updateData(e, props)} ><i className="fa fa-check marg-righ-5"></i>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SurveyForm;