import React from "react";
import ReactDOM from "react-dom"
import * as collection from "../js/helpers/utils/SavedReportCollection";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import _ from 'lodash';
import moment from 'moment';
import SendRequest from '../SendRequest';
import PubSub from 'pubsub-js'
import ViewReportAccordianTemplate from './viewreportaccordian.jsx'
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;


export default class ViewReportAccordianView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.selectall = this.selectall.bind(this)
        this.unselectall = this.unselectall.bind(this)
        this.state.element = "#filteraccordion";
        this.state.isFromStoryBook = this.props.isFromStoryBook == undefined ? false : this.props.isFromStoryBook;
        this.state.tabid = this.props.tabid == undefined ? 0 : this.props.tabid;
        if (this.props.elelement != undefined) {
            this.state.element = this.props.elelement;
        }
        if ($("#tilefiltersmodal").length > 0) {
            this.state.element = "#tilefilterslist";
        }
        // bind to window
        $('.tab-filters-container .rightfilter').scroll(this.scrollFiltersMenu);

        var that = this;
        $('html').on('click', function (e) {
            //e.preventDefault();
            var container = $(".tab-filters-container .panelboc-content");
            if (!container.is(e.target) && container.has(e.target).length === 0
                && e.target.className != "applyBtn pull-right btn btn-bird"
                && e.target.className != "daterangepicker dropdown-menu ltr opensleft show-calendar"
                && !(e.target.className == "table-condensed" && e.target.parentElement.getAttribute('class') == "calendar-table calendar-date")
                && !(e.target.offsetParent != null && e.target.offsetParent.parentElement != null && e.target.offsetParent.className == "table-condensed" && e.target.offsetParent.parentElement.getAttribute('class') == "calendar-table calendar-date")
                && !(e.target.offsetParent != null && e.target.offsetParent.parentElement != null && e.target.offsetParent.className == "daterangepicker dropdown-menu ltr opensleft show-calendar" && e.target.offsetParent.parentElement.getAttribute('class') == "day")
                && e.target.className != "monthselect"
                && e.target.className != "yearselect"
                && e.target.className != "input-mini"
                && e.target.className != "input-mini active"
                && e.target.className != "ranges"
                && e.target.className != "calendar first left"
                && e.target.className != "calendar second right"
                && e.target.className != "boc-list-item singleliitems active"
                && e.target.tagName != "OPTION"
                && e.target.className != "boc-list-item singleliitems"
            ) {
                that.scrollFiltersMenu();
            }
        });

        this.state.script = this.props.script;
        this.state.filterArray = this.props.filterArray;
        //	this.broker.unbind('updateFilters');
        this.state.isFromGovernance = this.props.isFromGovernance;
        this.state.tableName = this.props.tableName;
        this.state.columnDisplayName = this.props.columnDisplayName;
        this.state.filterDisplayName = this.props.filterDisplayName;
        this.state.filterIgnore = this.props.filterIgnore;
        this.state.columnName = this.props.columnName;
        this.state.columnType = this.props.columnType;
        this.state.entities = this.props.entities;
        this.state.schemaId = this.props.schemaId;
        this.state.sharedFilters = this.props.sharedFilters;
        //	this.state.template = _.template(ViewReportAccordianTemplate);
        //	this.quicktemplate = _.template(QuickFiltersTemplate);
        this.state.fromrange = this.props.fromrange;
        this.state.torange = this.props.torange;
        //var fragmentArray = Backbone.history.fragment.split("/");
        var fragmentArray = "";
        this.state.className = this.props.className;
        this.state.reportId = "";
        this.state.displayName = this.props.columnDisplayName;
        this.state.filterObject = this.props.filterObject;
        this.state.customFields = this.props.customFields
        //	this.state.DrilldownFilters=_.template(DrilldownFilters);
        if (this.state.displayName != undefined) {
            this.state.displayName = this.state.displayName.replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, "");
        }
        if (fragmentArray.length == 2) {
            if (fragmentArray[0] != "customviewreport" && fragmentArray[0] != "viewstorybook" && fragmentArray[0] != "viewsmartinsight") {
                var reg = new RegExp('^[0-9]+$');
                if (fragmentArray[1] != "" && fragmentArray[1] != undefined && !reg.test(fragmentArray[1])) {
                    this.state.reportId = atob(fragmentArray[1]);//reportId
                } else {
                    this.state.reportId = fragmentArray[1];
                }
            }

        }
        if (fragmentArray.length == 4) {
            if (fragmentArray[0] === "schedulereport") {
                var reg = new RegExp('^[0-9]+$');
                if (fragmentArray[1] != "" && fragmentArray[1] != undefined && !reg.test(fragmentArray[1])) {
                    this.state.reportId = atob(fragmentArray[1]);
                } else {
                    this.state.reportId = fragmentArray[1];
                }
            }
        }
        if (this.props.schedule === "schedule") {
            if (fragmentArray[0] != "customviewreport") {
                var reg = new RegExp('^[0-9]+$');
                if (fragmentArray[1] != "" && fragmentArray[1] != undefined && !reg.test(fragmentArray[1])) {
                    this.state.reportId = atob(fragmentArray[1]);//reportId
                } else {
                    this.state.reportId = fragmentArray[1];
                }
            }
        }
        //this.state.href = Backbone.history.getFragment();
        this.state.href = "";
        this.state.isPurgedReport = false;
        if (this.state.href.toLowerCase().indexOf("viewpurgereport") >= 0) {
            this.state.isPurgedReport = true;
        }
        this.state.isImportedReport = false;
        if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
            this.state.isImportedReport = true;
        }
        this.state.href = window.location.pathname;
        this.state.isViewModel = false;
        if (this.state.href.toLowerCase().indexOf("viewmodel") >= 0 || (this.state.href.toLowerCase().indexOf("sharereport") >= 0 && this.state.href.toLowerCase().split("/").length > 2)) {
            if (this.props.mlrequestfrom != undefined && this.props.mlrequestfrom != '' && this.props.mlrequestfrom == 'save') {
                this.state.reportId = atob(this.state.href.split("/")[4]);
                this.state.isViewModel = true;
            } else if (this.props.mlrequestfrom != undefined && this.props.mlrequestfrom != '' && this.props.mlrequestfrom == 'temp') {
                this.state.reportId = "";
                this.state.isViewModel = true;
            }
            this.state.mlrequestfrom = this.props.mlrequestfrom
        }
        if(this.state.href.toLowerCase().indexOf("viewsmartinsight")>=0&&this.state.sharedFilters!=""&&this.state.sharedFilters!=undefined&&this.state.sharedFilters!=null){
            let sharedfilterarray = JSON.parse(this.state.sharedFilters);
            let smartfilter = ChartInfo.functions.getStoryboardSmartInsightFilterArray();
            sharedfilterarray = sharedfilterarray.concat(smartfilter);
            this.state.sharedFilters = JSON.stringify(sharedfilterarray);
        }
        if ($(this.state.element + " [data-id='acc" + this.state.displayName + "']").length === 0) {
            var that = this;
            var filterObj = new FormData();
            filterObj.set("tableName", that.state.tableName);
            filterObj.set("columnDisplayName", that.state.columnDisplayName);
            filterObj.set("filterDisplayName", that.state.filterDisplayName != undefined ? that.state.filterDisplayName : "");
            filterObj.set("filterIgnore", that.state.filterIgnore);
            filterObj.set("columnName", that.state.columnName);
            filterObj.set("reportId", this.state.reportId);
            filterObj.set("columnType", that.props.columnType);
            filterObj.set("script", that.state.script);
            filterObj.set("sqlFormula", that.props.sqlFormula);
            filterObj.set("datamodelId", that.props.datamodelId);
            filterObj.set("schemaId", that.state.schemaId != undefined ? that.state.schemaId : "");
            filterObj.set("entities", that.state.entities != undefined ? that.state.entities : "");
            filterObj.set("sharedFilters", that.state.sharedFilters);
            filterObj.set("indexName", that.props.indexName != undefined ? that.props.indexName : "");
            filterObj.set("esType", that.props.esType != undefined ? that.props.esType : "");
            filterObj.set("isPurgedReport", that.state.isPurgedReport);
            filterObj.set("isImportedReport", that.state.isImportedReport);
            filterObj.set("isFromGovernance", that.state.isFromGovernance);
            filterObj.set("customFields ", that.state.customFields);
            filterObj.set("isPublished",  ChartInfo.functions.getisPublished());
            if(that.props.columnType && that.props.columnType=="string" && that.props.mlrequestfrom && that.props.mlrequestfrom=="viewsample"){
                filterObj.set("limit ", that.props.limit?that.props.limit+"":"50");
                filterObj.set("offset",   that.props.offset?that.props.offset+"":0);
            }

            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };

            SendRequest({
                url: "getFilterData",
                body: filterObj,
                sucessFunction: response => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                       // $(".ball-pulse").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                        if(that.props.mlrequestfrom=="viewsample"){
                            that.props.resetScrolledForError();
                        }
                        that.render();
                    } else {
                        that.state.collection = response;
                        if(that.props.mlrequestfrom=="viewsample"){
                            that.props.addSamplingData(that.props,that.state.collection);
                        }else{
                            that.render();
                        }
                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
    }
    render() {
        //currentPath
        var isFilterRename = true,
            //  currentpath=Backbone.history.location.href,
            currentpath = "",
            temp = currentpath.split('#'),
            templen = temp.length;
        if (templen > 1) {
            var temp1 = temp[1].split('/')
            if (temp1 != undefined && (temp1[0] == "viewreport" || temp1[0] == "viewstoryboard")) {
                isFilterRename = true;
            }
        }
        var filterminvalue = (this.state.collection == undefined || this.state.collection[0] === undefined) ? -1 : this.state.collection[0].min;
        var filtermaxvalue = (this.state.collection == undefined || this.state.collection[0] === undefined) ? -1 : this.state.collection[0].max;
        var templatetheme = "theme1";
        if ($("#advancedsharestoryboardpermissionmodal.modal.in").length > 0 || ($('.datapermissiondiv, .datapermissionupdate, .mlstoryboardtabs').length > 0)) {
            templatetheme = undefined;
        }
        try {
            if ($(this.state.element + " [data-id='acc" + this.state.displayName + "']").length === 0) {
                $('.spinner').show();
                if (!(this.state.columnType == 'all' || this.state.columnType == 'list' || this.state.columnType == 'range')) {
                    if (this.state.columnType != "date" && this.state.columnType != "dateTime" && this.state.columnType != "datetime") {
                        var today = new Date();
                        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                        $(this.state.element).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                        ReactDOM.render(<ViewReportAccordianTemplate searchfiltersdata={this.searchfiltersdata} daterangepicker={this.daterangepicker} selectall={this.selectall} unselectall={this.unselectall} deleteaccordian={this.deleteaccordian} showadvancedfilters={this.showadvancedfilters} collapseother={this.collapseother}
                            tableName={this.state.tableName} columnName={this.state.columnName} columnDisplayName={this.state.columnDisplayName} filterDisplayName={this.state.filterDisplayName} filterIgnore={this.state.filterIgnore} columnType={this.state.columnType} filterValues={this.state.collection} filterArray={this.state.filterArray != undefined ? this.state.filterArray : []} columnDisplayNames={this.state.displayName} filterminvalue={filterminvalue} filtermaxvalue={filtermaxvalue} script={this.state.script} className={this.state.className} templatetheme={templatetheme} isFilterRename={isFilterRename}
                        />, document.getElementById(dynamicdiv));

                        // this.$el.append(this.template({ tableName: this.state.tableName, columnName: this.state.columnName, columnDisplayName: this.state.columnDisplayName, filterDisplayName: this.state.filterDisplayName, filterIgnore: this.state.filterIgnore, columnType: this.state.columnType, filterValues: this.state.collection, filterArray: this.state.filterArray, columnDisplayNames: this.state.displayName, filterminvalue: filterminvalue, filtermaxvalue: filtermaxvalue, script: this.state.script, className: this.state.className, templatetheme: templatetheme, isFilterRename: isFilterRename }));
                        //$(".quickfiltercontainer").prepend(this.quicktemplate({tableName:this.state.tableName,columnName:this.state.columnName,columnDisplayName:this.state.columnDisplayName, columnType:this.state.columnType, filterValues :this.state.collection,filterArray:this.state.filterArray,columnDisplayNames:this.state.displayName,filterminvalue:filterminvalue,filtermaxvalue:filtermaxvalue,script:this.state.script,filterObject:this.state.props.filterObject}));
                        if (this.state.filterArray != undefined) {
                            ChartInfo.functions.setFilterArray(this.state.filterArray);
                        }
                        $('.spinner').hide();
                        if (this.state.columnType === "number") {
                            var steps = 0;
                            var count = 0;
                            _.each(this.state.collection, function (data) {
                                if (count <= 1000) {
                                    if (data.value % 1 != 0) {
                                        steps = 0.5;
                                    }
                                    count++;
                                }
                            })
                            var temFilterMaxValue = filtermaxvalue;
                            //						if(parseInt(temFilterMaxValue)>=10000000){
                            //							temFilterMaxValue = 10000000;
                            //						}
                            $(this.state.element + " [data-id='acc" + this.state.displayName + "']" + " #numberrangeslider").ionRangeSlider({
                                type: "double",
                                grid: true,
                                from: this.state.fromrange,
                                to: this.state.torange,
                                min: filterminvalue,
                                max: temFilterMaxValue,
                                force_edges: true,
                                prettify_enabled: false,
                                onStart: function (data) {
                                    if (filterminvalue === filtermaxvalue && filterminvalue != -1 && filtermaxvalue != -1) {
                                        var tempdata = '';
                                        tempdata = sessionStorage.getItem('applicablefilters');
                                        var temp = (sessionStorage.applicablefilters == undefined) ? "" : sessionStorage.applicablefilters.split(',');
                                        if (temp.indexOf($(data.input).find('input').attr('data-name')) === -1 || temp.length == 0) {
                                            tempdata = tempdata + encodeURIComponent($(data.input).find('input').attr('data-name')) + ",";
                                            sessionStorage.setItem('applicablefilters', tempdata);
                                        }
                                    }
                                },
                                onChange: function (data) {
                                    var tempdata = '';
                                    tempdata = sessionStorage.getItem('applicablefilters');
                                    var temp = (sessionStorage.applicablefilters == undefined) ? "" : sessionStorage.applicablefilters.split(',');
                                    if (temp.indexOf($(data.input).find('input').attr('data-name')) === -1 || temp.length == 0) {
                                        tempdata = tempdata + encodeURIComponent($(data.input).find('input').attr('data-name')) + ",";
                                        sessionStorage.setItem('applicablefilters', tempdata);
                                    }
                                    /*$(".instant-floating-filters.rightpane, .singlesavedreportmodal.in").addClass("changesdone");
                                    $("#rightfiltersapplys, .applysavedfilter").addClass("btn-highlight");
                                    setTimeout(function(){$("#rightfiltersapplys, .applysavedfilter").addClass("tooltip-highlight");setTimeout(function(){$("#rightfiltersapplys, .applysavedfilter").removeClass("tooltip-highlight");},2500)},500);*/
                                },
                                onFinish: function (data) {

                                },
                                onUpdate: function (data) {
                                }
                            });
                            if (filterminvalue > 10000000 || temFilterMaxValue > 10000000) {
                                $(this.state.element + " [data-id='acc" + this.state.displayName + "']" + " #numberrangeslider").hide();
                                $(this.state.element + " [data-id='acc" + this.state.displayName + "']" + " #numberrangeslider").parent().hide()
                                $(this.state.element + " [data-id='acc" + this.state.displayName + "']" + " #numberrangeslider").parent().parent().find(".intfiltercontainer, .filtersearching").toggle();
                                $($(this.state.element + " [data-id='acc" + this.state.displayName + "']" + " #numberrangeslider").parent().parent().find(".intfiltercontainer span")[0]).hide();
                            }

                            var that = this;
                            _.each(this.state.filterObject, function (i, j) {
                                if (that.state.filterObject[j].columnDisplayName == that.state.columnDisplayName || that.state.filterObject[j].columndisplayname == that.state.columnDisplayName) {
                                    if ((that.state.filterObject[j].filtergreaterthanvalue != undefined || that.state.filterObject[j].filterlessthanvalue != undefined) && (that.state.filterObject[j].equalvalue == "" || that.state.filterObject[j].equalvalue == undefined) && (that.state.filterObject[j].invalue == "" || that.state.filterObject[j].invalue == undefined)) {
                                        $(that.state.element + " [data-id='acc" + that.state.displayName + "']" + " .intfiltervalue .filtergreaterthanvalue").parent().parent().show('slow');
                                        $(that.state.element + " [data-id='acc" + that.state.displayName + "']" + " .intfiltervalue .filtergreaterthanvalue").attr("value", that.state.filterObject[j].filtergreaterthanvalue);
                                        $(that.state.element + " [data-id='acc" + that.state.displayName + "']" + " .intfiltervalue .filterlessthanvalue").attr("value", that.state.filterObject[j].filterlessthanvalue);

                                    }
                                    else if (that.state.filterObject[j].invalue != "" && that.state.filterObject[j].invalue != undefined) {
                                        $(that.state.element + " [data-id='acc" + that.state.displayName + "']" + " .intfiltervalue .filterinvalue").attr("value", that.state.filterObject[j].invalue);
                                    }
                                    else {
                                        $(that.state.element + " [data-id='acc" + that.state.displayName + "']" + " .intfiltervalue .filterequalsvalue").attr("value", that.state.filterObject[j].equalvalue);
                                    }
                                }
                            });
                            //Updated Filtered Data in UI
                            $(that.state.elelement + ' .acc-content[data-id="' + that.state.columnDisplayName.replace(/([ ;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + '"] .filteredvalues').text(filterminvalue + " - " + temFilterMaxValue).attr("title", filterminvalue + " - " + temFilterMaxValue);
                            var that = this;
                            //$('#filtersrename'+that.state.columnName.replace(/([ ;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g,"")).click();
                            setTimeout(function () {
                                $('#filtersrename' + that.state.columnName.replace(/([ ;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "")).click();
                            }, 500);
                        }
                    }
                    else {
                        var daterange = (this.props.daterange == undefined) ? "last 30 days" : this.props.daterange;
                        var today = new Date();
                        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                        $(this.state.element).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                        ReactDOM.render(<ViewReportAccordianTemplate searchfiltersdata={this.searchfiltersdata} daterangepicker={this.daterangepicker} selectall={this.selectall} unselectall={this.unselectall} deleteaccordian={this.deleteaccordian} showadvancedfilters={this.showadvancedfilters} collapseother={this.collapseother} tableName={this.state.tableName} columnName={this.state.columnName} columnDisplayName={this.state.columnDisplayName} filterDisplayName={this.state.filterDisplayName} filterIgnore={this.state.filterIgnore} columnType={this.state.columnType} filterValues={this.state.collection} filterArray={this.state.filterArray != undefined ? this.state.filterArray : []} columnDisplayNames={this.state.displayName} script={this.state.script} daterange={daterange} className={this.state.className} templatetheme={templatetheme} isFilterRename={isFilterRename} />, document.getElementById(dynamicdiv));

                        //  this.$el.append(this.template({ tableName: this.state.tableName, columnName: this.state.columnName, columnDisplayName: this.state.columnDisplayName, filterDisplayName: this.state.filterDisplayName, filterIgnore: this.state.filterIgnore, columnType: this.state.columnType, filterValues: this.state.collection, filterArray: this.state.filterArray, columnDisplayNames: this.state.displayName, script: this.state.script, daterange: daterange, className: this.state.className, templatetheme: templatetheme, isFilterRename: isFilterRename }));
                        //$(".quickfiltercontainer").prepend(this.quicktemplate({tableName:this.state.tableName,columnName:this.state.columnName,columnDisplayName:this.state.columnDisplayName, columnType:this.state.columnType, filterValues :this.state.collection,filterArray:this.state.filterArray,columnDisplayNames:this.state.displayName,filterminvalue:filterminvalue,filtermaxvalue:filtermaxvalue,script:this.state.script,filterObject:this.props.filterObject,fromRange:this.state.fromrange,toRange:this.state.torange}));
                        $('.spinner').hide();
                    }
                }
                else {
                    var customparameterfields = ChartInfo.functions.getCustomParameter();
                    var parameterlistvlaues = '';
                    var parameterrangevalues = '';
                    var parametername = this.state.columnName;
                    var parametercurrentvalue = '';
                    _.each(customparameterfields, function (fields) {
                        if (fields.parametername == parametername) {
                            parameterlistvlaues = fields.parameterlistvalues;
                            parameterrangevalues = fields.parameterrangevalues;
                            parametercurrentvalue = fields.parametercurrentvalue;
                        }
                    });
                    var today = new Date();
                    var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(this.state.element).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    ReactDOM.render(<ViewReportAccordianTemplate searchfiltersdata={this.searchfiltersdata} daterangepicker={this.daterangepicker} selectall={this.selectall} unselectall={this.unselectall} deleteaccordian={this.deleteaccordian} showadvancedfilters={this.showadvancedfilters} collapseother={this.collapseother} tableName={this.state.tableName} columnName={this.state.columnName} columnDisplayName={this.state.columnDisplayName} filterDisplayName={this.state.filterDisplayName} filterIgnore={this.state.filterIgnore} columnType={this.state.columnType} value={this.props.daterange} parameterlistvlaues={parameterlistvlaues} parametercurrentvalue={parametercurrentvalue} filterValues={this.state.collection} filterArray={this.state.filterArray != undefined ? this.state.filterArray : []} columnDisplayNames={this.state.displayName} filterminvalue={filterminvalue} filtermaxvalue={filtermaxvalue} script={this.state.script} templatetheme={templatetheme} isFilterRename={isFilterRename} />, document.getElementById(dynamicdiv));
                    //  this.$el.append(this.template({ tableName: this.state.tableName, columnName: this.state.columnName, columnDisplayName: this.state.columnDisplayName, filterDisplayName: this.state.filterDisplayName, filterIgnore: this.state.filterIgnore, columnType: this.state.columnType, value: this.props.daterange, parameterlistvlaues: parameterlistvlaues, parametercurrentvalue: parametercurrentvalue, filterValues: this.state.collection, filterArray: this.state.filterArray, columnDisplayNames: this.state.displayName, filterminvalue: filterminvalue, filtermaxvalue: filtermaxvalue, script: this.state.script, templatetheme: templatetheme, isFilterRename: isFilterRename }));
                    $('.spinner').hide();
                    if (this.state.columnType === "range") {
                        var steps = 0;
                        var count = 0;
                        _.each(this.state.collection, function (data) {
                            if (count <= 1000) {
                                if (data.value % 1 != 0) {
                                    steps = 0.5;
                                }
                                count++;
                            }
                        })
                        parametercurrentvalue = this.props.daterange
                        $(this.state.element + " [data-id='acc" + this.state.columnName + "']" + " #numberrangeslider").ionRangeSlider({
                            type: "single",
                            grid: true,
                            step: parameterrangevalues.rangestersize,
                            from: parametercurrentvalue,
                            min: parameterrangevalues.rangemin,
                            max: parameterrangevalues.rangemax,
                            force_edges: true,
                            prettify_enabled: false,
                            onStart: function (data) {
                                /*if(parameterrangevalues.rangemin===parameterrangevalues.rangemax && parameterrangevalues.rangemin!=-1 && parameterrangevalues.rangemax!=-1){
                                var tempdata='';
                                tempdata=sessionStorage.getItem('applicablefilters');
                                var temp=(sessionStorage.applicablefilters==undefined) ? "":sessionStorage.applicablefilters.split(',');
                                if(temp.indexOf($(data.input).find('input').attr('data-name'))===-1 || temp.length==0){
                                    tempdata =tempdata+($(data.input).find('input').attr('data-name'))+",";
                                    sessionStorage.setItem('applicablefilters',tempdata);
                                }
                                }*/
                            },
                            onChange: function (data) {
                                var tempdata = '';
                                /*tempdata=sessionStorage.getItem('applicablefilters');
                                var temp=(sessionStorage.applicablefilters==undefined) ? "":sessionStorage.applicablefilters.split(',');
                                if(temp.indexOf($(data.input).find('input').attr('data-name'))===-1 || temp.length==0){
                                    tempdata =tempdata+($(data.input).find('input').attr('data-name'))+",";
                                    sessionStorage.setItem('applicablefilters',tempdata);
                                }*/
                            },
                            onFinish: function (data) {

                            },
                            onUpdate: function (data) {
                            }
                        });
                        var that = this;
                        setTimeout(function () {
                            $('#filtersrename' + that.state.columnName.replace(/([ ;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "")).click();
                        }, 500);
                /*_.each(this.filterObject,function(i,j){
                    if(that.state.filterObject[j].columndisplayname==that.state.columnDisplayName){
                    if(that.state.filterObject[j].filtergreaterthanvalue!="" ||that.state.filterObject[j].filterlessthanvalue!=""){
                        $("#acc"+that.state.displayName+" .intfiltervalue .filtergreaterthanvalue").attr("value",that.state.filterObject[j].filtergreaterthanvalue);
                        $("#acc"+that.state.displayName+" .intfiltervalue .filterlessthanvalue").attr("value",that.state.filterObject[j].filterlessthanvalue);
                        
                    }	
                }
                })*/;

                    }
                }
            }
            if (this.props.elelement == undefined) {
                var fcount = $(this.state.element + ' .acc-content').length;
                if (fcount > 0) {
                    $('.rightfiltersapply').removeClass('hide');
                    $('.filters-applied').remove();
                    $('.leftpane').append('<div class="filters-applied"><i class="fa fa-filter"></i>' + fcount +lang["storyboard.filtersapplied"]+' </div>');
                    $('.singlebadge').remove();
                    /*$('.modalfilter').append('<span class="singlebadge badge notifications-badge">'+fcount+'</span>');*/
                    $('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                    $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                }
                else {
                    $('.filters-applied').remove();
                    $('.rightfiltersapply').addClass('hide');
                    $('.singlebadge').remove();
                }
                if ($("#tilefiltersmodal").length > 0) {
                    $('.filters-applied').remove();
                    $(".rightfiltersapply:not(#rightfiltersapplys)").addClass('hide');
                    $('.singlebadge').remove();
                }
            }
            if (this.state.reportId != "") {
                $('#' + this.state.displayName + 'rangevaluemin').text(this.state.fromrange);

                $('#' + this.state.displayName + 'rangevaluemax').text(this.state.torange);
                $('#slider').find('input').closest("#rangeinput").val(this.state.fromrange);
                $('#slider').find('input').closest("#rangeoutput").val(this.state.torange);
            }
            if (this.state.columnType == "date" || this.state.columnType == "dateTime" || this.state.columnType == "datetime") {
                if (this.state.filterArray != undefined && this.state.filterArray.length != 0) {
                    ChartInfo.functions.setFilterArray(this.state.filterArray);
                }
                this.addDateTimeFilter(this.state.columnDisplayName);
            }
            if (this.props.elelement == undefined) {
                //setTimeout(function () {
                    var filtersLength = ChartInfo.functions.getFilterArray();
                    var test = ChartInfo.functions.getUserFilterArray();
                    let isMLReport = false;
                    if(window.location.pathname.indexOf("viewmodel")!=-1){
                        isMLReport = true;
                    }
                    if(filtersLength!=undefined && filtersLength.length!=0){
                        filtersLength = filtersLength.length;
                    }
                    if(filtersLength!=undefined && ($("#storyboardtab #filteraccordion.filteritem-container [classname]").length >= filtersLength || $(".singlereportview #filteraccordion.filteritem-container [classname]").length >= filtersLength)){
                        PubSub.publish('updateFilters');
                    }else if(filtersLength!=undefined && isMLReport ){
                        PubSub.publish('updateFilters');
                    }
                    
                //}, 1000);
            } else {
                this.previousfiltersdata(this.state.columnName, this.props.filterid);
            }
        } catch (e) {
            // TODO: handle exception
        }
        return (<div></div>)
    }
    previousfiltersdata(columnName, filteruserid) {
        var that = this;
        var parsedSharedfilters;
        var filtersChecked = false;
        if (this.sharedFilters != undefined && this.sharedFilters.length > 2) {
            parsedSharedfilters = JSON.parse(this.sharedFilters);
        }
        var userId = $(".datapermissionupdate").attr("data-userid");
        var filterArray = ChartInfo.functions.getUserFilterArray();;

        _.each(filterArray["groups"], function (item, id) {
            _.each($(".advanced-access-filtervalue"), function (val) {
                if (id == filteruserid) {
                    _.each(item, function (item) {
                        if (item.data != undefined && item.data.split("@@").indexOf($(val).closest("li").text()) >= 0 && item.columnname == columnName) {
                            $(val).prop("checked", true);
                            filtersChecked = true;
                        }
                    });
                }
            });
        });
        _.each(filterArray["users"], function (item, id) {
            _.each($(".advanced-access-filtervalue"), function (val) {
                if (id == filteruserid) {
                    _.each(item, function (item) {
                        if (item.data != undefined && item.data.split("@@").indexOf($(val).closest("li").text()) >= 0 && item.columnname == columnName) {
                            $(val).prop("checked", true);
                            filtersChecked = true;
                        }
                    });
                }
            });
        });

    }
    scrollFiltersMenu(e) {
        $("#filteraccordion .panel-collapse.in").collapse('hide');
        // $(this.state.element + " .panel-collapse.in").collapse('hide');
        setTimeout(function () {
            if ($('.fullscreenmain').length > 0 && $("#advancedsharestoryboardpermissionmodal.in").length === 0) {
                if ($(".tab-filters-container .filteritem-container .panel-collapse[aria-expanded='true']").length > 0) {
                    $(".tab-filters-container").css("z-index", "9999")
                } else {
                    $(".tab-filters-container").css("z-index", "")
                }
            } else {
                $(".tab-filters-container").css("z-index", "")
            }
        }, 0);
    }
    collapseother = (e) => {
        var $this = e.currentTarget;
        var currentElementID = $($this).parent().find(".panel-collapse").attr("id");
        $(this.state.element + " .panel-collapse.in").not("#" + currentElementID).collapse('hide');
        // if ($("#" + currentElementID).hasClass("in")) {
        //     $("#" + currentElementID).remove("in");
        // } else {
        //     $("#" + currentElementID).addClass("in");
        // }
        //  $("#filteraccordion .panel-collapse.in").not("#" + currentElementID).collapse('hide');


        //set panel position
        var currentElementPosition = $($this).offset().left,
            windowWidth = $(window).width(),
            isRightMenuOpen = $("body").hasClass("rightpanelopen");
        if (isRightMenuOpen) {
            if ((windowWidth - currentElementPosition) > 675) {
                $($this).parent().find('.panelboc-content').css({ 'left': currentElementPosition, 'right': "auto" });
            } else {
                $($this).parent().find('.panelboc-content').css({ 'right': "380px", "left": "auto" });
            }
        } else {
            if ((windowWidth - currentElementPosition) > 300) {
                $($this).parent().find('.panelboc-content').css({ 'left': currentElementPosition, 'right': "auto" });
            } else {
                $($this).parent().find('.panelboc-content').css({ 'right': "5px", "left": "auto" });
            }
        }

        //For Maximised reports
        if ($('.fullscreenmain').length > 0 && $("#advancedsharestoryboardpermissionmodal.in").length === 0) {
            $(".tab-filters-container").css("z-index", "9999")
        } else {
            $(".tab-filters-container").css("z-index", "")
        }
    }
    showadvancedfilters = (e) => {
        var $this = e.currentTarget;
        if ($($this).parent().parent().parent().hasClass('filterStringvalues')) {
            $($this).parent().parent().find('.filtersearching').toggle('slow');
        } else {
            $($this).parent().parent().find('.intfiltercontainer').toggle('slow');
        }
    }
    deleteaccordian = (event) => {
        if (this.props.elelement == undefined && $("#tilefiltersmodal").length === 0) {
            var temp = event.currentTarget;
            var sessiondata = [];
            var setsessionvalue = '';
            var displayname = temp.name.replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
            if (sessionStorage.applicablefilters != undefined) {
                sessiondata = sessionStorage.applicablefilters.split(',');
            }
            var tempdata = _.without(sessiondata, temp.name)
            _.each(tempdata, function (i, j) {
                setsessionvalue = setsessionvalue + tempdata[j] + ",";
            })
            sessionStorage.setItem('applicablefilters', setsessionvalue);
            $(".singlereportfiltercheckbox[name='" + displayname + "']").prop('checked', false);
            $(".singlereportcustomparameterfiltercheckbox[name='" + displayname + "']").prop('checked', false);
            $("[id='" + displayname + "']").remove();
            var fcount = $(this.state.elelement + ' .acc-content').length;
            if (fcount > 0) {
                //Force user to click on Apply Filters Button
                /*$(".instant-floating-filters.rightpane, .singlesavedreportmodal.in").addClass("changesdone");
                $("#rightfiltersapplys, .applysavedfilter").addClass("btn-highlight");
                setTimeout(function(){$("#rightfiltersapplys, .applysavedfilter").addClass("tooltip-highlight");setTimeout(function(){$("#rightfiltersapplys, .applysavedfilter").removeClass("tooltip-highlight");},2500)},500);*/

                $('.rightfiltersapply').removeClass('hide');
                $('.filters-applied').remove();
                $('.leftpane').append('<div class="filters-applied"><i class="fa fa-filter"></i>' + fcount +lang["storyboard.filtersapplied"]+' </div>');
                $('.singlebadge').remove();
                /*$('.modalfilter').append('<span class="singlebadge badge notifications-badge">'+fcount+'</span>');*/
                $('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
            }
            else {
                ChartInfo.functions.setIsFiltersAdded(false);
                sessionStorage.setItem("changesdone", 1);
                if ($(temp).next().hasClass("rightfiltersapply")) {
                    $(temp).next().addClass('hide');
                }
                //  $('.trend-stats-container').remove();
                $('.viewdatafromsinglechart').addClass('hide');
                $('.viewsinglechartsummary').addClass('hide');
                $('.viewdatafromchart').addClass('hide');
                $('.viewchartsummary').addClass('hide');
                $('.addInstanceFilterData').empty();
                if ($('.acc-content').length == 0 && $('.addInstanceFilterData').text().trim().length == 0) {
                    if ($('.addURLInstanceFilterData').text().trim().length == 0) {
                        $('.instant-floating-filters').hide();
                        $('.allscopeslistcontainer').hide();
                    }
                }
                sessionStorage.removeItem("instantExcludeFilters");
                sessionStorage.removeItem("instantIncludeFilters");
                sessionStorage.removeItem("instantExcludeCustomFilters");
                ChartInfo.functions.setInstanceIncludeFilterData("");
                ChartInfo.functions.setInstanceFilterData("");
                ChartInfo.functions.setIncludeFilterData("");
                ChartInfo.functions.setdrillfitlerArray("");
                //if ($('.fullscreenmain .maxanim8').length==1){
                $('.refreshsinglecharts').trigger('click');
                let isSmartInsights = false;
                if ((window.location.pathname.indexOf("viewsmartinsight") >= 0 || window.location.pathname.indexOf("embeddedsmartinsightreport") >= 0 || window.location.pathname.indexOf("sharesmartinsightreport") >= 0)) {
                    isSmartInsights = true;
                }
                ChartInfo.functions.getFilters(true);
                if (this.state.isViewModel) {
                    //$(".smartinsightintro-container").removeClass("hide");

                    //$("#mlsnapshotdatacontainer").empty();
                    //$(".smartinsightintro-container").removeClass("hide");
                    this.state.mlContainerCurrentIndex = $('#mlsplitcontainer').steps('getCurrentIndex');
                    if (this.props.mlrequestfrom != undefined && this.props.mlrequestfrom != '' && this.props.mlrequestfrom == 'temp') {
                        let mapdata = {}
                        mapdata["isRunModel"] = true;
                        mapdata["isReload"] = false;
                        PubSub.publish('applyfiltesandredrawmlchart', mapdata);
                    } else {
                        let mapdata = {}
                        mapdata["isRunModel"] = false;
                        mapdata["isReload"] = false;
                        PubSub.publish('applyfiltesandredrawmlchart', mapdata);
                    }
                    if (this.state.mlContainerCurrentIndex == 3) {
                        let mapdata = {}
                        mapdata["currentIndex"] = undefined;
                        mapdata["newIndex"] = this.state.mlContainerCurrentIndex;
                        mapdata["isRunModel"] = false;
                        PubSub.publish('loadNextPage', mapdata);
                    } else {
                        $("a[href=#mlsplitcontainer-h-" + this.state.mlContainerCurrentIndex + "]").trigger("click");
                    }
                    $(".instant-floating-filters.rightpane").removeClass("changesdone");
                    //this.broker.trigger('applyfiltesandredrawmlchart', undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined, undefined,undefined,"yes");
                } else if(isSmartInsights){
                    PubSub.publish('smartinsightrightfilters', event);
                }else {
                    PubSub.publish('applyfiltesandredrawchart')
                    // this.broker.trigger('applyfiltesandredrawchart', undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined, undefined,undefined,"yes");
                }
                //$('#innerfiltermodal').modal('hide')
                $('.hidebutton').trigger('click');
                $('.filters-applied').remove();
                $('.singlebadge').remove();
                var charts = ChartInfo.functions.getAllChartDetails();
                _.each(charts, function (i, j) {
                    sessionStorage.removeItem("drilldown" + j);
                    var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(j));
                    if (chartDetails["drillDownFilter"] != undefined) {
                        $("#chart" + j + "").before("<ul class='breadcrumb' data-num=" + j + "></ul>");
                        var breadCrumbElement = $('.breadcrumb[data-num=' + j + ']')
                        _.each(chartDetails["drillDownFilter"], function (value, index) {
                            if (value["saved"] == "true") {
                                var appendValue = value.data;
                                var ulEle = "";
                                var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
                                if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
                                    appendValue = breadCrumpElements["span"];
                                    ulEle = breadCrumpElements["ulelement"];
                                }
                                if (value.columndisplayname != undefined) {
                                    breadCrumbElement.append("<li class=drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name='" + value.data + "' class='close removedrilldown' title="+lang["storyboard.remove"]+"><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
                                } else {
                                    breadCrumbElement.append("<li class=drilldown saved>" + value.displayName + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name='" + value.data + "' class='close removedrilldown' title="+lang["storyboard.remove"]+">><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
                                }
                            }
                        });
                    }
                });

                var that = this;
                _.each(ChartInfo.functions.getAllChartDetails(), function (i, j) {
                    sessionStorage.removeItem("drilldown" + j);
                    $(".quickfiltercontainer").empty();
                    $(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer").attr("drilled-chart", j);
                    var chartDetails = JSON.parse(i);
                    var drillDownAllFilter = chartDetails['drillDownAllFilter'];
                    _.each(drillDownAllFilter, function (i, j) {
                        var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
                        if (name === null) {
                            var name = decodeURIComponent(drillDownAllFilter[j].name);
                        }
                        else if (name == "undefined") {
                            name = decodeURIComponent(drillDownAllFilter[j].displayName);
                            if (drillDownAllFilter[j].customfield != undefined) {
                                name = drillDownAllFilter[j].customfield.name;
                            }
                        }
                        if (drillDownAllFilter[j].type == "date") {
                            name = $(name).find('li:first').attr('column-display-name');
                            if (name === undefined) {
                                name = drillDownAllFilter[j].columndisplayname;
                            }
                        }
                        $(".quickfiltercontainer").append(that.DrilldownFilters({ columnDisplayName: name, filterValues: drillDownAllFilter[j].data }));
                    });
                });
                $(".quickfilterremove").html("<i title="+lang["storyboard.remove"]+"> i class='fa fa-times'></i>")
                $(".pie-menu").remove();
                $(".fullscreensample").removeClass("nopie");
                /*$(".instant-floating-filters.rightpane, .singlesavedreportmodal").removeClass("changesdone");
                $("#rightfiltersapplys, .applysavedfilter").removeClass("btn-highlight");*/
            }
        } else if ($("#tilefiltersmodal").length > 0) {
            var temp = event.currentTarget;
            var displayname = temp.name.replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
            $("#tilefilterslist [id='" + displayname + "']").remove();
            $(".storyboardfiltertoggleitem input[data-columndisplayname='" + displayname + "']").prop('checked', true);
            $(".storyboardfiltertoggleitem[columndisplayname='" + temp.name + "']").removeClass('disabled');
        } else {
            var temp = event.currentTarget;
            var displayname = temp.name.replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
            var userid = $(temp).closest(".permissioncontainerrightfilter").attr("data-userid");
            $(".dobyfilteritems[data-userid='" + userid + "'] .permissionfiltercheckbox[name='" + displayname + "']").prop('checked', false);
            $(".permissioncontainerrightfilter [id='" + displayname + "']").remove();
        }
        try {
            var temp = event.currentTarget;
            var columnname = temp.name.replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
            var columnNamesArray = ChartInfo.functions.getDrillthroughColumnNamesArray();
            var indexNumber = columnNamesArray.indexOf(columnname);
            if (indexNumber > -1) {
                columnNamesArray.splice(indexNumber, 1);
            }
            ChartInfo.functions.setDrillthroughColumnNamesArray(columnNamesArray);
        } catch (e) {

        }
    }
    selectall = (event) => {
        var temp = event.currentTarget;
        $("#uncheckall[name='" + temp.getAttribute('name').replace(/([;?&,%#+*~\':"!^$[\]()<=>`|\/@])/g, "") + "']").prop('checked', false);
        $("#checkall[name='" + temp.getAttribute('name').replace(/([;?&,%#+*~\':"!^$[\]()<=>`|\/@])/g, "") + "']").prop('checked', true);
        // if ($("#checkall[name='" + temp.getAttribute('name').replace(/([;?&,%#+*~\':"!^$[\]()<=>`|\/@])/g, "") + "']").is(":checked")) {
        $("input[displayname='" + temp.getAttribute('name').replace(/([;?&,%#+*~\':"!^$[\]()<=>`|\/@])/g, "") + "']").each(function () {
            this.checked = true;
        });
        // }
        var columndisplayname = temp.getAttribute('name').replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
        $(this.state.elelement + ' .acc-content[data-id="' + columndisplayname + '"] .filteredvalues').text("All").attr("All");
    }
    unselectall = (event) => {
        var temp = event.currentTarget;
        $("#checkall[name='" + temp.getAttribute('name').replace(/([;?&,%#+*~\':"!^$[\]()<=>`|\/@])/g, "") + "']").prop('checked', false);
        //  if ($("#uncheckall[name='" + temp.getAttribute('name').replace(/([;?&,%#+*~\':"!^$[\]()<=>`|\/@])/g, "") + "']").is(":checked")) {
        $("input[displayname='" + temp.getAttribute('name').replace(/([;?&,%#+*~\':"!^$[\]()<=>`|\/@])/g, "") + "']").each(function () {
            this.checked = false;
        });
        // }
        var columndisplayname = temp.getAttribute('name').replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
        $(this.state.elelement + ' .acc-content[data-id="' + columndisplayname + '"] .filteredvalues').text("None").attr("None");
    }
    daterangepicker = (e) => {
        var $this = e.currentTarget;
        var className = (this.state.className == undefined) ? 'daterange-center' : this.state.className;
        $('.' + className).removeClass('activedatefield')
        $($this).addClass('activedatefield');
        $('.' + className + '.activedatefield').on('apply.daterangepicker', function (ev, picker) {
            $('.' + className + '.activedatefield:nth-child(1n) span').html(picker.startDate.format('MMMM D, YYYY') + ' - ' + picker.endDate.format('MMMM D, YYYY'));
            $('.' + className + '.activedatefield:nth-child(1n)').attr("date-range", picker.chosenLabel.toLowerCase());
            if ($('.' + className + '.activedatefield').closest('ul').parent().parent().hasClass('panel-collapse')) {
                var id = $('.' + className + '.activedatefield').closest('ul').parent().parent().attr('id')
                setTimeout(function () { $("#" + id).collapse('show') }, 1000)
            }
            //Force user to click on Apply Filters Button
            /*$(".instant-floating-filters.rightpane, .singlesavedreportmodal.in").addClass("changesdone");
            $("#rightfiltersapplys, .applysavedfilter").addClass("btn-highlight");
            setTimeout(function(){$("#rightfiltersapplys, .applysavedfilter").addClass("tooltip-highlight");setTimeout(function(){$("#rightfiltersapplys, .applysavedfilter").removeClass("tooltip-highlight");},2500)},500);*/
        });
        if($($this).closest(".sidetheme").length>0){
            $("body>.daterangepicker").addClass("smartinsightdate");
        }
        //$('.daterangepicker.dropdown-menu').addClass("daterangefixright");
        /*if ($('.fullscreenmain').length==1){
            $('.daterangepicker.dropdown-menu').removeClass("daterangefixright");
            $('.daterangepicker.dropdown-menu').addClass("daterangefixleft");
        }
        else {
            $('.daterangepicker.dropdown-menu').removeClass("daterangefixleft");
            $('.daterangepicker.dropdown-menu').addClass("daterangefixright");
        }*/
    }
    addDateTimeFilter(name) {
        
        var minDateinData = new Date(this.state.collection[0].min);
        var maxDateinData = new Date(this.state.collection[0].max);
        var last30days = moment(maxDateinData).subtract(29, 'days').toDate();
        var fromDate;
        if (minDateinData > last30days) {
            fromDate = minDateinData;
        } else {
            fromDate = last30days;
        }
        name = name.replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
        if (this.state.fromrange != undefined && this.state.torange != undefined) {
            var dates = this.state.fromrange + ' - ' + this.state.torange;
            $(this.state.element + " [id='daterange" + name + "']" + ':nth-child(1n) span').html(this.state.fromrange + ' - ' + this.state.torange);
            fromDate = moment(this.state.fromrange).format('MM/DD/YYYY');
            maxDateinData = moment(this.state.torange).format('MM/DD/YYYY');
        } else {
            var dates = moment().subtract(29, 'days').format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY');
            $(this.state.element + " [id='daterange" + name + "']" + ':nth-child(1n) span').html(moment().subtract(29, 'days').format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY'));
        }
        var dates = moment().subtract(29, 'days').format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY')
        $(this.state.element + ' .acc-content[data-id="' + name + '"] .filteredvalues').text(dates).attr("title", dates);
        //$(this.state.element + " [id='daterange" + name + "']" + ':nth-child(1n) span').html(moment(fromDate).format('MMMM D, YYYY') + ' - ' + moment(maxDateinData).format('MMMM D, YYYY'));
        var minDate = '01/01/1970';
        var maxDate = '12/31/2020';
        var that = this;
        var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);
        if (minDateinData == "Invalid Date" || maxDateinData == "Invalid Date") {
            var removedsplcharscolumnName = name.replace(/([ ;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
            $(this.state.elelement + ' .acc-content[data-id="' + name + '"] .filteredvalues').text(dates).attr("title", lang["storyboard.nodatesavailble"]);
            $(this.state.element + " [id='daterange" + name + "']" + ':nth-child(1n) span').html(lang["storyboard.nodatesavailble"]);
            $('.acc-content[data-id="' + name + '"]').addClass('nodata-error');
            var hrefvar = $('#filtersrename' + name.replace(/([ ;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "")).attr('href');
            $(hrefvar + " #rightfiltersapplys").addClass('disabled');
            return;
        }
        if (isIE11) {
            var tempminDate = moment(this.state.collection[0].min).toDate();
            var tempmaxDate = moment(this.state.collection[0].max).toDate();
        } else {
            var tempminDate = new Date(this.state.collection[0].min);
            var tempmaxDate = new Date(this.state.collection[0].max);
        }

        var tempminday = tempminDate.getDate();
        var tempminmonth = tempminDate.getMonth();
        var tempminyear = tempminDate.getFullYear();
        var finaltempminDate = tempminmonth + 1 + '/' + tempminday + '/' + tempminyear;

        var tempmaxday = tempmaxDate.getDate();
        var tempmaxmonth = tempmaxDate.getMonth();
        var tempmaxyear = tempmaxDate.getFullYear();
        var finaltempmaxDate = tempmaxmonth + 1 + '/' + tempmaxday + '/' + tempmaxyear;
        minDate = (this.state.collection[0] === undefined) ? minDate : finaltempminDate;
        maxDate = (this.state.collection[0] === undefined) ? maxDate : finaltempmaxDate;
        if (this.state.sharedFilters != null && this.state.sharedFilters.length > 2) {
            _.each(JSON.parse(this.state.sharedFilters), function (value) {
                if (value.columnname.replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") == name.replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "")) {
                    minDate = moment(that.state.fromrange).format("MM/DD/YYYY");
                    maxDate = moment(that.state.torange).format("MM/DD/YYYY");
                }
            })
        }

        $(this.state.element + " [id='daterange" + name + "']" + ':nth-child(1n)').daterangepicker({
            format: 'MM/DD/YYYY',
            startDate: moment().subtract(29, 'days'),
			endDate: moment(),
          //  minDate: minDate,
            //maxDate: maxDate,
            showDropdowns: true,
            showWeekNumbers: true,
            timePicker: false,
            timePickerIncrement: 1,
            autoUpdateInput: true,
            timePicker12Hour: true,
            linkedCalendars: false,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'First Fiscal Quarter': [moment().startOf('year'), moment().month(2).endOf('month')],
                'Second Fiscal Quarter': [moment().month(3).startOf('month'),moment().month(5).endOf('month')],
                'Third Fiscal Quarter': [moment().month(6).startOf('month'),moment().month(8).endOf('month')],
                'Fourth Fiscal Quarter': [moment().month(9).startOf('month'),moment().month(11).endOf('month')],
                'This Year': [moment().startOf('year'), moment().endOf('year')],
                'Year To Date': [moment().startOf('year'), moment()],
                'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]

            },
            opens: 'left',
            drops: 'down',
            buttonClasses: ['btn'],
            applyClass: 'btn-bird',
            cancelClass: 'btn-bird',
            separator: ' to ',
            locale: {
                applyLabel: lang["storyboard.submitbtn"],
                cancelLabel: lang["storyboard.cancelbtn"],
                fromLabel: 'From',
                toLabel: 'To',
                customRangeLabel: 'Custom',
                daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                firstDay: 1,
            }
        }, function (start, end, label) {
            console.log(start.toISOString(), end.toISOString(), label);
            $(that.state.element + " [id='daterange" + name + "']" + ':nth-child(1n) span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));

            //For Filters UI
            $(that.state.element + ' .acc-content[data-id="' + name + '"] .filteredvalues').text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')).attr("title", start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        });

        if (this.state.fromrange != undefined && this.state.torange != undefined) {
            var startdate = new Date();
            var enddate = new Date();
            if (this.props.daterange == "today") {
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            else if (this.props.daterange == "yesterday") {
                startdate.setDate(startdate.getDate() - 1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setDate(enddate.getDate() - 1);
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            else if (this.props.daterange == "last 7 days") {
                startdate.setDate(startdate.getDate() - 6);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setDate(enddate.getDate());
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            else if (this.props.daterange == "last 30 days") {
                startdate.setDate(startdate.getDate() - 29);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setDate(enddate.getDate());
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            else if (this.props.daterange == "this month") {
                startdate.setDate(1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setDate((new Date(enddate.getFullYear(), enddate.getMonth() + 1, 0)).getDate());
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            else if (this.props.daterange == "last month") {
                startdate.setMonth(startdate.getMonth() - 1);
                startdate.setDate(1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setMonth(enddate.getMonth() - 1);
                var date = new Date();
                var lastday = new Date(date.getFullYear(), date.getMonth(), 0);
                enddate.setDate(lastday.getDate());
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(58);
            }
            else if (this.props.daterange == "this week") {
                startdate = moment().startOf('week')._d;
                enddate = moment().endOf('week')._d;
            }
            else if (this.props.daterange == "last week") {
                startdate = moment().subtract(1, 'week').startOf('week')._d;
                enddate = moment().subtract(1, 'week').endOf('week')._d;
            }
            else if (this.props.daterange == "last year") {
                startdate.setFullYear(startdate.getFullYear() - 1);
                startdate.setMonth(0);
                startdate.setDate(1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setFullYear(enddate.getFullYear() - 1);
                enddate.setMonth(11);
                enddate.setDate(31);
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            else if (this.props.daterange == "first fiscal quarter") {
                startdate.setMonth(0);
                startdate.setDate(1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setMonth(2);
                var lastday = new Date(enddate.getFullYear(), enddate.getMonth()+1, 0);
                enddate.setDate(lastday.getDate());
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);  
            }
            else if (this.props.daterange == "second fiscal quarter") {
                startdate.setMonth(3);
                startdate.setDate(1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setMonth(5);
                var lastday = new Date(enddate.getFullYear(), enddate.getMonth()+1, 0);
                enddate.setDate(lastday.getDate());
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);  
            }
            else if (this.props.daterange == "third fiscal quarter") {
                startdate.setMonth(6);
                startdate.setDate(1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setMonth(8);
                var lastday = new Date(enddate.getFullYear(), enddate.getMonth()+1, 0);
                enddate.setDate(lastday.getDate());
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);  
            }
            else if (this.props.daterange == "fourth fiscal quarter") {
                startdate.setMonth(9);
                startdate.setDate(1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setMonth(11);
                var lastday = new Date(enddate.getFullYear(), enddate.getMonth()+1, 0);
                enddate.setDate(lastday.getDate());
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);  
            }
            else if (this.props.daterange == "this year") {
                startdate.setMonth(0);
                startdate.setDate(1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setMonth(11);
                enddate.setDate(31);
                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);  
            }else if(this.props.daterange =="year to date"){
                startdate.setMonth(0);
                startdate.setDate(1);
                startdate.setHours(0);
                startdate.setMinutes(0);
                startdate.setSeconds(0);

                enddate.setHours(11);
                enddate.setMinutes(59);
                enddate.setSeconds(59);
            }
            if (this.props.daterange == "custom") {
                var start = moment(this.state.fromrange);
                var end = moment(this.state.torange);
                $(this.state.element + " [id='daterange" + name + "']" + ':nth-child(1n) span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
                $(this.state.element + " [id='daterange" + name + "']" + ':nth-child(1n)').data('daterangepicker').setStartDate(moment(start.format("YYYY/MM/DD")));
                $(this.state.element + " [id='daterange" + name + "']" + ':nth-child(1n)').data('daterangepicker').setEndDate(moment(end.format("YYYY/MM/DD")));
            } else {
                var start = moment(startdate.getTime());
                var end = moment(enddate.getTime());
                $(this.state.element + " [id='daterange" + name + "']" + ':nth-child(1n) span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
                $(this.state.element + " [id='daterange" + name + "']" + ':nth-child(1n)').data('daterangepicker').setStartDate(moment(start.format("YYYY/MM/DD")));
                $(this.state.element + " [id='daterange" + name + "']" + ':nth-child(1n)').data('daterangepicker').setEndDate(moment(end.format("YYYY/MM/DD")));
            }
        }
    }
    searchfiltersdata = (e) => {
        var temp = e.currentTarget;
        var filterArray = [];
        var cFS = [];
        var $this = this;
        var filterObject = {};
        filterObject["dataType"] = temp.getAttribute('data-type');
        filterObject["tablename"] = temp.getAttribute("data-parent");
        filterObject["columnname"] = temp.getAttribute("data-child");
        var columnDataType = temp.getAttribute('data-type');
        var script = temp.getAttribute("script");
        var start = $("[data-id='" + (temp.getAttribute("data-name").replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + "start") + "']").val();
        var end = $("[data-id='" + (temp.getAttribute("data-name").replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + "end") + "']").val();
        var contain = $("[data-id='" + (temp.getAttribute("data-name").replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + "contains") + "']").val();
        this.state.temp_columndisplayname = temp.getAttribute("data-child");

        filterObject["startwith"] = start;
        filterObject["endwith"] = end;
        filterObject["contains"] = contain;
        var that = this;
        var customFields = sessionStorage.getItem("customFields");
        //	if(this.options.script != null){
        if (customFields != undefined && customFields != null) {
            var parsedCustomFields = JSON.parse(customFields);
            _.each(parsedCustomFields, function (customField) {
                if (customField.name == that.state.temp_columndisplayname) {
                    cFS.push(customField);
                    filterObject["sqlcondition"] = customField.sqlformula;
                    return;
                }
            })
        }
        //}
        filterArray.push(filterObject);
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        var reqObj = new FormData();
        reqObj.set("tableName", this.state.tableName);
        reqObj.set("columnDisplayName", temp.getAttribute("displayname"));
        reqObj.set("columnName", temp.getAttribute("data-child"));
        reqObj.set("columnType", temp.getAttribute('data-type'));
        reqObj.set("reportId", that.state.reportId);
        reqObj.set("filterObject", JSON.stringify(filterArray));
        reqObj.set("script", JSON.stringify(cFS));
        reqObj.set("entities", that.props.entities);
        reqObj.set("schemaId", that.state.schemaId);
        reqObj.set("datamodelId", that.props.datamodelId);
        reqObj.set("sharedFilters", that.state.sharedFilters);
        reqObj.set("indexName", that.props.indexName != undefined ? that.props.indexName : "");
        reqObj.set("esType", that.props.esType != undefined ? that.props.esType : "");
        reqObj.set("isImportedReport", this.state.isImportedReport);

        SendRequest({
            url: "getsearchingfiltervalues",
            body: reqObj,
            sucessFunction: response => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $(".gridspinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                    /*$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">'+response.error+'</div>');
                           setTimeout(function() {
                            $('.birderror').remove();	
                           }, 3000);*/
                } else
                    that.state.filtercollection = response;
                that.render2(that.state.temp_columndisplayname, columnDataType);
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders
        });
    }
    render2(DisplayName, columnDataType) {
        var that = this;
        var filterArray = [];
        if (columnDataType != undefined) {
            this.state.columnType = columnDataType;
        }
        if (DisplayName != undefined) {
            DisplayName = DisplayName.replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
        }
        var className = (this.state.className == undefined) ? 'filtervalue' : "advanced-access-filtervalue";
        $('.' + className).each(function () {
            var $this = this;
            if ($($this).is(":checked")) {
                var filterObject = {};
                filterObject["dataType"] = $($this).attr("data-type");
                filterObject["tablename"] = $($this).attr("data-parent");
                filterObject["columndisplayname"] = $($this).attr("data-name");
                filterObject["columnname"] = $($this).attr("data-child");
                filterObject["data"] = $($this).parent().text();
                filterArray.push(filterObject);
            }
            //}
        });
        if (this.state.filtercollection.length == 0) {
            if (filterArray.length == 0) {
                $(that.state.element + " [data-id='acc" + DisplayName + "']" + ' ul.checkhelpitems').empty();
            }
            _.each(filterArray, function (data, k) {
                $('.' + className + '[data-child="' + DisplayName + '"][data-parent="' + data.tablename + '"]:not(:checked)').parent().parent().remove();
                //$('#acc'+data.columnname.replace(/([ ;?&,%#.+*~\':"!^$[\]()=>|\/@])/g, "")+' ul.checkhelpitems').append("<li class='boc-list-item nopadding-xs	valueoffilters'><label><input type='checkbox' class='filtervalue' title='"+data.data+"' data-type='"+data.dataType+"' data-parent='"+data.tablename+"' data-child='"+data.columnname.replace(/([#;&,.+*~\':"!^$%[\]()=>|\/@])/g, "")+"' data-name='"+data.columnname.replace(/([#;&,. +*~\':"!^$%[\]()=>|\/@])/g, "")+"'>"+data.data+"</label></li>");
            });

        }
        _.each(this.state.filtercollection, function (i, j) {
            var columnDisplayName = '';
            if (i.columnDisplayName != undefined) {
                columnDisplayName = i.columnDisplayName.replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
            }
            var tableName = $('.' + className + '[data-child="' + i.columnName + '"]').attr('data-parent');
            // For postgres table name is different. So advanced search is not working in filters
            var tablenameoriginal = $('.' + className + '[data-child="' + i.columnName + '"]').attr('data-parent');
            if (tableName !== undefined) {
                if (tableName.indexOf(".") > 0) {
                    if (tableName.split(".")[1].charAt(0) == '"') {
                        var table2 = tableName.split(".")[1].replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                        var t1 = "\\" + "\"" + table2 + "\\" + "\"";
                        tableName = tableName.split(".")[0] + "." + t1;
                    }
                }
            }
            if (j == 0) {

                $('.' + className + '[data-child="' + i.columnName + '"][data-parent="' + tableName + '"]:not(:checked)').parent().parent().remove();
            }
            if ($('.' + className + '[data-child="' + i.columnName + '"][data-parent="' + tableName + '"][data-value="' + (i.value + '').replace(/([;?&,%#+*~\':"!^$[\]()<=>`|\/@])/g, "") + '"]').is(":checked") == false)
                $(that.state.element + " [data-id='acc" + columnDisplayName + "']" + ' ul.checkhelpitems').append("<li class='boc-list-item nopadding-xs	valueoffilters'><label><input type='checkbox' class='" + className + "' title='" + i.value + "' data-col='" + i.columnName + "' displayname='" + i.columnDisplayName + "' data-type='" + that.state.columnType + "' data-parent='" + tablenameoriginal + "' data-child='" + i.columnName.replace(/([#;?&,+*~\':"!^$%[\]()<=>|\/@])/g, "") + "' data-value='" + (i.value + '').replace(/([;?&,%#+*~\':"!^$[\]()<=>`|\/@])/g, "") + "' data-name='" + i.columnName.replace(/([#;&,+*~\':"!^$%[\]()<=>|\/@])/g, "") + "'>" + i.value + "</label></li>");
        });
        if (this.state.columnType == "date" || this.state.columnType == "dateTime") {

            this.addDateTimeFilter(this.state.columnDisplayName);
        }
        PubSub.publish('updateFilters');
        $(".filtervalue").click(function (event) {
            event.stopPropagation();
        })
    }
}