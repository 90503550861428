import React from "react";
import ReactDOM from 'react-dom';
import SendRequest from '../SendRequest';
import NotificationsListTemplate from "./NotificationsList.jsx"
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'underscore';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import Header from "../Home/Header";
const $ = window.$;

export default class Notifications extends React.Component {
  constructor(props) {
    $(".spinner").show();
    super(props);
    this.state = {
      notifications: [],
      alerts: []
    };
  }
  UNSAFE_componentWillMount() {
    document.body.setAttribute("data-spy", "scroll");
    document.body.setAttribute("data-target", "#myScrollspy");
    document.body.classList.add("day");
  }
  loadScript = function (src) {
    var tag = document.createElement('script');
    tag.src = src;
    document.body.appendChild(tag);
  }
  getSharedNotificationslist(page, search, pageDisplay, order, colName) {
    var index = 0;
    var noOfRecords = $(".notificationrowlimtbutton .page-size").text() == "" ? 25 : $(".notificationrowlimtbutton .page-size").text();
    var totalRecords = 0;
    if (page != undefined && page > 1) {
      index = (page - 1) * noOfRecords;
    }
    if (order == undefined) {
      order = 'asc';
    }
    if (colName == undefined) {
      colName = '';
    }
    this.dataFieldValue = colName;
    this.sortingOrder = order;
    this.page = page;
    var that = this
    let requestHeaders = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "getdashboardreportslist",
      queryString: "?fromDate= " + "" + "&toDate= " + "" + "&index= " + index + "&noOfRecords=" + noOfRecords + "&searchCriteria=" + search + "&order=" + order + "&colName=" + colName + "&urltype=" + "getSharedPaginationNotifications",
      sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
          $(".spinner").hide();
        } else {
          var headerlist = response["header"];
          var data = response["data"];
          totalRecords = response["totalRecords"];
          var keys = Object.keys(headerlist);
          var totalPages;
          if (pageDisplay == undefined) {
            pageDisplay = 25;
          }
          if (totalRecords > 25) {
            totalPages = Math.ceil(totalRecords / pageDisplay);
          } else {
            totalPages = 1;
          }

          _.each(data, function (notification, index) {
            if (notification.vizType == "ml_storyboard") {
              notification.url = <a href={`${that.props.url}/sharereport/${btoa(notification.reportId)}/${btoa(notification.dataModelId)}`}>{lang["alerts.viewreport"]}</a>;
            } else if (notification.vizType == "smartinsight_storyboard") {
              notification.url = <a href={`${that.props.url}/sharesmartinsightreport/${btoa(notification.reportId)}/${btoa(notification.dataModelId)}`}>{lang["alerts.viewreport"]}</a>;
            } else if (notification.vizType == 'storybook') {
              notification.url = <a href={`${that.props.url}/sharestorybook/${btoa(notification.storybook_id)}`} class="sharereportnavigation" id="viewsharenotificationstorybook">{lang["alerts.viewreport"]}</a>
            } else if(notification.vizType == 'customstoryboard'){
              notification.url = <a href={`${that.props.url}/sharecustomstoryboard/${btoa(notification.vizid)}`} >{lang["alerts.viewreport"]}</a>
            }else {
              notification.url = <a href={`${that.props.url}/sharereport/${btoa(notification.vizid)}`} >{lang["alerts.viewreport"]}</a>
            }
          });
          var columns = [
            { dataField: 'id', text: 'ID', sort: false },
            { dataField: 'report_name', text: 'Report Name', sort: true },
            { dataField: 'comments', text: 'Message', sort: true },
            { dataField: 'user_first_name', text: 'Shared By', sort: true },
            { dataField: 'cus.create_date', text: 'Date', sort: true },
            { dataField: 'url', text: 'Link', sort: false }
          ];
          $("#sharereportslisttable").remove();
          $("#notificationsTable").append('<div id="sharereportslisttable">');
          ReactDOM.unmountComponentAtNode(document.getElementById("sharereportslisttable"));
          ReactDOM.render(<NotificationsListTemplate columns={columns} urlType="getSharedPaginationNotifications" searchkeyup={that.searchkeyup} url={that.props.url} search={search} totalRecords={totalRecords} sizePerPage={pageDisplay} data={data} />, document.querySelector("#sharereportslisttable"));
          if (search != '') {
            $(".searchnotificationtable[is-from='shared']").focus();
          }
          setTimeout(function(){$(".spinner").hide();},1500);

        }
      },
      rejectFunction: () => {$(".spinner").hide() },
      headers: requestHeaders

    });
  }
  getAlertNotificationslist(page, search, pageDisplay, order, colName) {
    var index = 0;
    var noOfRecords = $(".notificationrowlimtbutton .page-size").text() == "" ? 25 : $(".notificationrowlimtbutton .page-size").text();
    var totalRecords = 0;
    if (page != undefined && page > 1) {
      index = (page - 1) * noOfRecords;
    }
    if (order == undefined) {
      order = 'asc';
    }
    if (colName == undefined) {
      colName = '';
    }
    this.dataFieldValue = colName;
    this.sortingOrder = order;
    this.page = page;
    var that = this
    var filterid = window.localStorage.getItem("current")!=undefined ? atob(window.localStorage.getItem("current")):"";
    let requestHeaders = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "getdashboardreportslist",
      queryString: "?filterid="+filterid+"&fromDate= " + "" + "&toDate= " + "" + "&index= " + index + "&noOfRecords=" + noOfRecords + "&searchCriteria=" + search + "&order=" + order + "&colName=" + colName + "&urltype=" + "getAlertPaginationNotifications",
      sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
          $(".spinner").hide();
        } else {
          var headerlist = response["header"];
          var data = response["data"];
          if(filterid!=undefined && filterid!=""){
              var filteredResponser = $.grep(data, function (e) { return e.filterid == filterid });
              data =filteredResponser;
          }
          totalRecords = response["totalRecords"];
          var keys = Object.keys(headerlist);
          var totalPages;
          if (pageDisplay == undefined) {
            pageDisplay = 25;
          }
          if (totalRecords > 25) {
            totalPages = Math.ceil(totalRecords / pageDisplay);
          } else {
            totalPages = 1;
          }

          _.each(data, function (notification, index) {
            if(window.location != window.parent.location && window.location.search!=''){
              notification.url = <a href={`${that.props.url}/viewreport/${btoa(notification.reportId)}${window.location.search}`}>{lang["alerts.viewreport"]}</a>
            }else{
              notification.url = <a href={`${that.props.url}/sharereport/${btoa(notification.reportId)}`}>{lang["alerts.viewreport"]}</a>
            }
          });
          var columns = [
            // { dataField: 'id', text: 'ID', sort: false },
            { dataField: 'cuv.report_visualization_name', text: 'Report Name', sort: true },
            { dataField: 'cua.alert_name', text: 'Alert Name', sort: true },
            { dataField: 'cua.alert_message', text: 'Alert Description', sort: true },
            { dataField: 'ua.user_login_name', text: 'Created By', sort: true },
            { dataField: 'cuap.published_time', text: 'Published Time', sort: true },
            { dataField: 'cua.alert_condition', text: 'Alert Condition', sort: true },
            { dataField: 'url', text: 'Link', sort: false }
          ];
          $("#alertreportslisttable").remove();
          $("#alertsnotificationsTable").append('<div id="alertreportslisttable">')
          ReactDOM.unmountComponentAtNode(document.getElementById("alertreportslisttable"));
          ReactDOM.render(<NotificationsListTemplate columns={columns} urlType="getAlertPaginationNotifications" searchkeyup={that.searchkeyup} url={that.props.url} search={search} totalRecords={totalRecords} sizePerPage={pageDisplay} data={data} />, document.querySelector("#alertreportslisttable"));
          if (search != '') {
            $(".searchnotificationtable[is-from='alert']").focus();
          }
          setTimeout(function(){$(".spinner").hide();},1500);

        }
      },
      rejectFunction: () => {$(".spinner").hide(); },
      headers: requestHeaders

    });
  }
  componentDidMount() {
    $(".spinner").show();
    this.loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap-table/1.9.1/bootstrap-table.min.js');
    this.loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap-table/1.9.1/locale/bootstrap-table-en-US.min.js');
    this.getSharedNotificationslist(undefined, "", 25, 'asc', undefined);
    this.getAlertNotificationslist(undefined, "", 25, 'asc', undefined);
    // setTimeout(function(){$(".spinner").hide();},1500);
    var url = window.location.search;
		if (url.length > 0) {
			url = url.replace('?', "").split("&");
			for (var i = 0; i < url.length; i++) {
				var key = url[i].split("=")[0];
				if (key.indexOf("sharenotifications") != -1 && url[i].split("=")[1]!=undefined && url[i].split("=")[1] == "false") {
          $("#boc-carousel-area-sourceItemShareId").addClass('hide');
				}
        if (key.indexOf("alertnotifications") != -1 && url[i].split("=")[1]!=undefined && url[i].split("=")[1] == "false") {
          $("#boc-carousel-area-sourceItemAlertId").addClass('hide');
				}
			}
		}
  }
  searchkeyup = (e) => {
    var $this = e.currentTarget;
    var that = this;
    var isFrom = $($this).attr('is-from')
    var delay = (function () {
      var timer = 0;
      return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
      };
    })();
    var search = $(e.currentTarget).val().toLowerCase();
    var pageDisplaySearch = $("#pageDropDown").text();
    delay(function () {
      that.searchValue = $(".searchnotificationtable[is-from='" + isFrom + "']").val();
      if (isFrom === 'shared') {
        that.getSharedNotificationslist(undefined, that.searchValue, pageDisplaySearch, that.sortingOrder, that.dataFieldValue);
      } else if (isFrom === 'alert') {
        that.getAlertNotificationslist(undefined, that.searchValue, pageDisplaySearch, that.sortingOrder, that.dataFieldValue);
      }
    }, 100);

    $(".searchnotificationtable[is-from='" + isFrom + "']").focus();
  }
  render() {
    return (
      <React.Fragment>
        <Header {...this.props} url={this.props.url} pageType={this.props.pageType} />
      <div id="main">
        <section className="boc-main-section">
          <div className="boc-home">
            {/* <!--<div className="boc-home-header">Some Header Data if needed</div>--> */}
            <div className="boc-home-content notifiactionpage">
              <ul className="boc-carousel-home">
                <section className="boc-carousel-area-sourceItem" id="boc-carousel-area-sourceItemShareId">
                  <header className="boc-carousel-area-header">
                    <div className="boc-carousel-area-header-leftcontent">
                      <h2 className="boc-carousel-area-header-title">
                        <i className="fa fa-bell fa-fw"></i>&nbsp; {lang["alerts.notification"]}
                      </h2>
                    </div>
                    {/* <!-- <a href="#" className="boc-area-view-all-link">View All �</a> --> */}
                  </header>
                  <section className="boc-snapshot-container data-item">
                    <div className="notifications-table-container">
                      <div className="bootstrap-table">
                        <div className="fixed-table-toolbar">
                          <div className="pull-right search">
                            <input className="form-control searchnotificationtable" type="text" placeholder={lang["alerts.search"]} defaultValue={this.props.search} is-from="shared" onKeyUp={this.searchkeyup} />
                          </div>
                        </div>
                        <div id="notificationsTable">
                        </div>
                      </div>
                    </div>
                  </section>
                </section>
                <section className="boc-carousel-area-sourceItem" id="boc-carousel-area-sourceItemAlertId">
                  <header className="boc-carousel-area-header">
                    <div className="boc-carousel-area-header-leftcontent">
                      <h2 className="boc-carousel-area-header-title">
                        <i className="fa fa-exclamation-triangle fa-fw"></i>
                        &nbsp; {lang["alerts.alertnotification"]}
                      </h2>
                    </div>
                  </header>
                  <section className="boc-snapshot-container data-item">
                    <div className="notifications-table-container">
                      <div className="bootstrap-table">
                        <div className="fixed-table-toolbar">
                          <div className="pull-right search">
                            <input className="form-control searchnotificationtable" type="text" placeholder={lang["alerts.search"]} defaultValue={this.props.search} is-from="alert" onKeyUp={this.searchkeyup} />
                          </div>
                        </div>
                        <div id="alertsnotificationsTable">
                        </div>
                      </div>
                    </div>
                  </section>
                </section>
              </ul>
            </div>
          </div>
        </section>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-7">
                {/* <!-- <p className="text-muted"><%=whitelableMap["footertext"]%></p> --> */}
                {/* <!-- <div className="timezone hide pull-right">
                <span className="servertime"><b>Server Time:</b></span> <span
                    className="servertimezone" style="margin-right: 20px">Fri Sep
                    27 2019 10:37:00 GMT+0530 </span> <span className="localtime"><b>Client
                        Time:</b></span> <span className="localtimezone"> Fri Sep 27 2019
                    10:37:00 GMT+0530 </span>
            </div> --> */}
              </div>
            </div>
          </div>
        </footer>
      </div>
      </React.Fragment>
    );
  }
}
