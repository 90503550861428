import React from "react";
import XaxisAttributesTemplate from "./xaxisattributes.jsx"
import ReactDOM from "react-dom";
import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import { lang } from "../js/helpers/utils/languages/language";

export default class XaxisAttrModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            chartNumber: props.chartnumber,
            xaxiscolumnDisplayName: props.xaxiscolumnDisplayName,
            xaxiscolumnName: props.xaxiscolumnName,
            xaxiscolumnType: props.xaxiscolumnType,
            xaxistableName: props.xaxistableName,
            xaxisidentity: props.xaxisidentity,
            activeChartType: props.activeChartType,
            viewmode: props.viewmode,
            iscustomfunction: props.iscustomfunction,
            enableDrillThroughHiddenFilterOption: props.enableDrillThroughHiddenFilterOption
            //	this.template = _.template(XaxisAttributesTemplate);
        };
        this.render();
        this.deleteelement = this.deleteelement.bind(this);
    }
    render() {
        var enableDrillThroughHiddenFilterOption = false;
        if (this.state.activeChartType === "multixbar" && $(".xaxisattributes li.boc-list-item").length == 1) {
            enableDrillThroughHiddenFilterOption = true;
        }
        this.state.el = "#" + this.state.dynamicdiv;
        ReactDOM.render(<XaxisAttributesTemplate ele={this.state.el} deletecustomfield={this.props.deletecustomfield} editname={this.editname} deleteelement={this.deleteelement} xaxiscolumnDisplayName={this.state.xaxiscolumnDisplayName} xaxiscolumnName={this.state.xaxiscolumnName} xaxiscolumnType={this.state.xaxiscolumnType} xaxistableName={this.state.xaxistableName} activeChartType={this.state.activeChartType} xaxisidentity={this.state.xaxisidentity} viewmode={this.state.viewmode} iscustomfunction={this.state.iscustomfunction} features={this.props.features} is_storyboard={this.props.is_storyboard} enableDrillThroughHiddenFilterOption={enableDrillThroughHiddenFilterOption} ></XaxisAttributesTemplate>, document.getElementById(this.state.dynamicdiv));
        //$(".xaxisitems").append('<option data-name="'+this.xaxiscolumnName+'"data-type="'+this.xaxiscolumnType+'" title="'+this.xaxiscolumnDisplayName+"'>"+this.xaxiscolumnDisplayName+' </option>');
        /*var that=this;
        _.each($(".attributesdimensions"), function(e){
            var dimension = e;
            if($(dimension).attr('data-name')==that.xaxiscolumnName){
        $(".xaxisitems").append('<option data-name="'+$(dimension).attr('data-name')+'">'+$(dimension).attr('title')+' </option>');
            }
        });*/
        if (this.state.xaxisidentity === "latitude") {
            $(this.state.el).find(".aggregation-li-item.latitude").addClass("active");
        } else if (this.state.xaxisidentity === "longitude") {
            $(this.state.el).find(".aggregation-li-item.longitude").addClass("active");
        }
        if(this.state.enableDrillThroughHiddenFilterOption && this.state.activeChartType==="multixbar" && $(".xaxisattributes li.boc-list-item").length==1){
            $(this.state.el).find(".enabledrillthroughhiddenfilter").addClass("active");
        }
        return (<div></div>);
    }

    deleteelement = (e) => {
        var that = this;
        var $this = e.currentTarget;
        var currentvalue = $($this).closest('li').attr("data-name");
        $(".xaxisitems option[title='" + currentvalue + "']").remove();
        if($($this).closest(".mlsamplingcolumns").length != 0 && !$("#dropdownsamplingcriteria").hasClass('disabled')){
            $("#dropdownsamplingcriteria").addClass('disabled');
            $(".mlsamplingcriteriavalues").empty();
            that.props.resetOffset();
        }else if($($this).closest(".mlsamplingcriteriavalues").length != 0){
            that.props.resetOffset();
        }
        $($this).closest('li').parent().parent().remove();
        if (this.props.multifact == "true") {
            if ($("#Xitemdisplay").length <= 0) {
                $(".zaxisattributes div").remove();
                $(".legendAttributes div").remove();
            }
            $(".referenceattributes div").remove();
            $(".sortingattributes div").remove();
        }
        if ($('.mlpredicttionmodal').val() == "forecasting") {
            _.each($('.futurevalues_forecast .form-inline'), function (obj) {
                if ($(obj).attr("data-name") == currentvalue) {
                    $(obj).remove();
                }
            })
        }
       
    }
    editname = (e) => {
        var $this = e.currentTarget;
        var isSmartInsights = $($this).closest("ul").hasClass("smartxaxisattributes");
        if (!isSmartInsights) {
            $(".newslideoutattributes ul.xaxisattributes li span.axistitleedit").remove();
            $(".newslideoutattributes ul.xaxisattributes li span.itemname").show();
            var parentli = $($this).closest("li.singleliitems");
            var prevText = parentli.attr("title");
            if ($(parentli).attr("iscustomfunction") != "0" && $(parentli).attr("iscustomfunction") != undefined) {
                $(parentli).find(".itemtype").click();
            } else {
                if (this.props.is_storyboard == "Y") {
                    $.each(this.props.features, function (index, featuresModule) {
                        if (featuresModule.moduleName == 'story_board') {
                            $.each(featuresModule.features, function (index, feature) {
                                if (feature.featureName == 'viewreport_rename_column' && feature.allow == true) {
                                    $(parentli).find("span.itemname").hide();
                                    $(parentli).find("span.itemname").after('<span class="overlay"></span><span class="axistitleedit"><input class="itemname" value="' + prevText + '"><button class="btn btn-transparent btn-sm applxaxisitemtitleedit"><i class="fa fa-check fa-green"></i></button><button class="btn btn-transparent btn-sm cancelaxisitemtitleedit"><i class="fa fa-times fa-dred"></i></button><span class="renameerror help-block marg-left-10 hide">'+lang["storyboard.nameesxists"]+'</span><span class="renameempty help-block marg-left-10 hide">'+lang["storyboard.entername"]+'</span><span class="specialcharacters help-block marg-left-10 hide">'+lang["storyboard.exceptdot"]+'</span></span>');
                                    $(".newslideoutattributes ul.xaxisattributes li input.itemname").focus();
                                }
                            });
                        }

                    });
                } else if (this.props.is_storyboard == "N") {
                    $.each(this.props.features, function (index, featuresModule) {
                        if (featuresModule.moduleName == 'my_report') {
                            $.each(featuresModule.features, function (index, feature) {
                                if (feature.featureName == 'viewreport_maximize_rename_column' && feature.allow == true) {
                                    $(parentli).find("span.itemname").hide();
                                    $(parentli).find("span.itemname").after('<span class="overlay"></span><span class="axistitleedit"><input class="itemname" value="' + prevText + '"><button class="btn btn-transparent btn-sm applxaxisitemtitleedit"><i class="fa fa-check fa-green"></i></button><button class="btn btn-transparent btn-sm cancelaxisitemtitleedit"><i class="fa fa-times fa-dred"></i></button><span class="renameerror help-block marg-left-10 hide">'+lang["storyboard.nameesxists"]+'</span><span class="renameempty help-block marg-left-10 hide">'+lang["storyboard.entername"]+'</span><span class="specialcharacters help-block marg-left-10 hide">'+lang["storyboard.exceptdot"]+'</span></span>');
                                    $(".newslideoutattributes ul.xaxisattributes li input.itemname").focus();
                                }
                            });
                        }
                    });
                }

            }
        }
        var that = this;
        $('.applxaxisitemtitleedit').click(function (event) {
            var $this = event.currentTarget;
            var isAlreadyExist = false;
            var renamedField = $($this).parent().find('input.itemname').val().trim();
            var reservedwordCheck = renamedField.toLowerCase();
            var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
            var status = false;
            _.each(reservedwordslist, function (reservedword, j) {
                if (reservedwordCheck == reservedword) {
                    status = true
                }
            });
            if (status) {
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text("Column " + "" + getMessage('BirdWarning147'));
                $(".spinner").hide();
                return;

            }
            $('.help-block.specialcharacters').addClass('hide');
            _.each(that.props.dimensions, function (cols) {
                var colName = $($this).closest('li').attr('data-name');
                var colRename = $($this).parent().find('input.itemname').val().trim();
                var tempObject = sessionStorage.getItem("renameMeasuresorDimensions") == null ? {} : JSON.parse(sessionStorage.getItem("renameMeasuresorDimensions"));
                if (cols.columnName == colName) {
                    _.each(that.props.dimensions, function (cols) {
                        if (cols.columnDisplayName == colRename) {
                            isAlreadyExist = true;
                        }
                    });
                    if (/[~`!@#$%^&*()+\=\[\]{};':\\|,<>\/?"]/.test(colRename) || colRename.trim().length == 0) {
                        $('.help-block.specialcharacters').removeClass('hide');
                    } else if ((colRename.split('.').length > 2)) {
                        $('.help-block.specialcharacters').removeClass('hide');
                    } else if (isAlreadyExist) {
                        $('.help-block.renameempty').addClass('hide');
                        $('.help-block.renameerror').removeClass('hide');
                    } else if (colRename.toLowerCase().trim() == "min" || colRename.toLowerCase().trim() == "max"
                        || colRename.toLowerCase().trim() == "avg" || colRename.toLowerCase().trim() == "sum"
                        || colRename.toLowerCase().trim() == "count" || colRename.toLowerCase().trim() == "avgerage") {
                        $('.help-block.specialcharacters').removeClass('hide');
                    } else if ($($this).parent().find('input.itemname').val().trim() == "") {
                        $('.help-block.renameerror').addClass('hide');
                        $('.help-block.renameempty').removeClass('hide');
                    } else {
                        cols.columnDisplayName = $($this).parent().find('input.itemname').val().trim();
                        cols.columnssrename = 'yes';
                        tempObject[cols.columnName] = cols.columnDisplayName;
                        sessionStorage.setItem("renameMeasuresorDimensions", JSON.stringify(tempObject))
                        colName = colName;//.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
                        var convertedValues = sessionStorage.getItem("convertedvalues");
                        var parsedValues = JSON.parse(convertedValues);
                        _.each(parsedValues, function (val) {
                            if (val.columnName == cols.attributes['columnName']) {
                                val.columnDisplayName = $($this).parent().find('input.itemname').val().trim();
                            }
                        });
                        that.updateAllChartInfoByXaxisRenamed(tempObject);
                        sessionStorage.setItem("convertedvalues", JSON.stringify(parsedValues));
                        //                 	To  change xAxis, xAxis.,Legend,toolTips displayNames Instantly
                        $(".yaxisattributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".yaxisattributes").find(".singleliitems[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        $(".metricdatefieldsAttributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".metricdatefieldsAttributes").find(".singleliitems[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        $(".sortingattributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".combinationchartslistattributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".combinationchartslistattributes").find(".singleliitems[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        //					$(".combinationchartslistattributes").find(".singleliitems[data-name='"+colName+"']").attr("data-name",cols.columnDisplayName);
                        $(".xaxisattributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".xaxisattributes").find(".singleliitems[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        $(".legendAttributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".legendAttributes").find(".singleliitems[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        $(".tooltipattributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".tooltipattributes").find(".singleliitems[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        $(".zaxisattributes").find(".singleliitems[data-name='" + colName + "']").find("span.itemname").text(cols.columnDisplayName);
                        $(".zaxisattributes").find(".singleliitems[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        //					$(".sortingattributes").find(".singleliitems[data-name="+colName+"]").attr("title",cols.columnDisplayName);
                        $(".createdatedropdown[data-name='" + colName + "']").attr('data-value', colRename);
                        _.each($(".attributesmeasures"), function (e) {
                            var measures = e;
                            if ($(measures).attr('data-name') == colName) {
                                $(measures).attr('title', $($this).parent().find('input.itemname').val().trim())
                                $(measures).find('span.itemname').text($($this).parent().find('input.itemname').val().trim());
                            }
                        });
                        _.each($(".attributesdimensions"), function (e) {
                            var measures = e;
                            if ($(measures).attr('data-name') == colName) {
                                $(measures).attr('title', $($this).parent().find('input.itemname').val().trim())
                                $(measures).find('span.previewcolumndata').attr('data-displayname', $($this).parent().find('input.itemname').val().trim())
                                $(measures).find('span.itemname').text($($this).parent().find('input.itemname').val().trim());
                                $(measures).find('li.converttonumber').attr('data-value', $($this).parent().find('input.itemname').val().trim() + '_numb')
                                $(measures).find('li.converttostring').attr('data-value', $($this).parent().find('input.itemname').val().trim() + '_str')
                                $(measures).find('li.converttonumber').attr('title', $($this).parent().find('input.itemname').val().trim())
                                $(measures).find('li.converttonumber').attr('title', $($this).parent().find('input.itemname').val().trim())
                            }
                        });
                        $(".rankingitem option[data-name='" + colName + "']").attr("title", cols.columnDisplayName);
                        $(".rankingitem option[data-name='" + colName + "']").text(cols.columnDisplayName + '(' + $(".rankingitem option[data-name='" + colName + "']").attr('data-agg') + ')');
                        _.each(that.props.measures, function (cols) {
                            var colName = $($this).closest('li').attr('data-name');;
                            if (cols.columnName == colName) {
                                cols.columnDisplayName = $($this).parent().find('input.itemname').val().trim();
                            }
                        });
                        $($($($this).parent()).parent()).parent().find('span.itemname').text($($this).parent().find('input.itemname').val().trim());
                        $($($($this).parent()).parent()).parent().find('li').attr('title', $($this).parent().find('input.itemname').val().trim());
                        //$($($this).parent()).parent().hide();
                        // $('#filtersrename'+colName.replace(/([ ;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g,"")).text(colRename);
                        $('#filtersrename' + colName.replace(/([ ;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "")).attr('title', colRename);

                        $($this).parent().parent().find("span.itemname").show();
                        $(".newslideoutattributes ul.xaxisattributes li span.axistitleedit").remove();
                        $(".newslideoutattributes ul.xaxisattributes li span.overlay").remove();

                        //Update Conditional Formatting
                        $(".conditionalshow[data-name='" + colName + "']").attr("data-name", colRename);
                        $(".attrabutesformatting[data-name='" + colName + "'] [data-name]").attr("data-name", colRename);
                        $(".attrabutesformatting[data-name='" + colName + "']").attr("data-name", colRename);

                        var conditions = ChartInfo.functions.getConditionalFormatData(that.state.chartNumber);
                        if (conditions != undefined) {
                            _.each(conditions, function (i) {
                                if (i.name == colName) {
                                    i.name = colRename;
                                }
                            });
                            ChartInfo.functions.setConditionalFormatData(that.state.chartNumber, conditions)
                        }
                        //\Update Conditional Formatting

                        // update customRange
                        var rangeArray = ChartInfo.functions.getRange();
                        if (rangeArray != undefined && rangeArray.length > 0) {
                            _.each(rangeArray, function (j) {
                                if (j.xAxisName[0].columnName == colName) {
                                    j.xAxisName[0].columnDisplayName = colRename;
                                }
                            });
                            ChartInfo.functions.setRange(rangeArray);
                        }
                        //\Update customRange

                    }
                }
                var chartInfoDetails = ChartInfo.functions.getAllChartDetails();
                _.each(chartInfoDetails, function (i, j) {
                    var chartData = JSON.parse(i);
                    if (chartData["drillDownFilter"] != undefined && chartData["drillDownFilter"].length != 0) {
                        _.each(chartData["drillDownFilter"], function (value, index) {
                            if (value.columnname == colName) {
                                chartData["drillDownFilter"][index]['columnDisplayName'] = $($this).parent().find('input.itemname').val().trim();
                                chartData["drillDownFilter"][index]['columndisplayname'] = $($this).parent().find('input.itemname').val().trim();
                            }
                            ChartInfo.functions.updateChartDetails(j, JSON.stringify(chartData));
                        });
                    }

                    if (chartData["drillDownAllFilter"] != undefined && chartData["drillDownAllFilter"].length != 0) {
                        _.each(chartData["drillDownAllFilter"], function (value, index) {
                            if (value.columnname == colName) {
                                chartData["drillDownAllFilter"][index]['columnDisplayName'] = $($this).parent().find('input.itemname').val().trim();
                                chartData["drillDownAllFilter"][index]['columndisplayname'] = $($this).parent().find('input.itemname').val().trim();
                            }
                            ChartInfo.functions.updateChartDetails(j, JSON.stringify(chartData));
                        });
                    }
                });
                var getAllTileLevelFilters = ChartInfo.functions.getAllTileLevelFilters();
                if (getAllTileLevelFilters != undefined) {
                    for (var key in getAllTileLevelFilters) {
                        if (getAllTileLevelFilters.hasOwnProperty(key)) {
                            var tileLevelFilters = getAllTileLevelFilters[key];
                            if (tileLevelFilters != undefined && tileLevelFilters[1] != undefined && tileLevelFilters[1].tileFiltersList != undefined) {
                                var tileFiltersList = tileLevelFilters[1].tileFiltersList;
                                $.each(tileFiltersList, function (index, filter) {
                                    if (filter["columnname"] == colName) {
                                        filter["columndisplayname"] = $($this).parent().find('input.itemname').val().trim();
                                        filter["filterDisplayName"] = $($this).parent().find('input.itemname').val().trim();
                                    }
                                });
                            }
                        }
                    }
                }
            });
        });
        $('.cancelaxisitemtitleedit').click(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            $($this).parent().parent().find("span.itemname").show();
            $(".newslideoutattributes ul.xaxisattributes li span.axistitleedit").remove();
            $(".newslideoutattributes ul.xaxisattributes li span.overlay").remove();
        });
    }
    updateAllChartInfoByXaxisRenamed(tempObject) {
        var chartInfoDetails = ChartInfo.functions.getAllChartDetails();
        _.each(chartInfoDetails, function (i, j) {
            var chartDetails = JSON.parse(i);
            var customhierarchy = ChartInfo.functions.getcustomhierarchy();
            _.each(customhierarchy, function (value, index) {
                _.each(value.hierarchelist, function (value1, index1) {
                    if (tempObject.hasOwnProperty(value1.columnName)) {
                        value1["displayName"] = tempObject[value1.columnName];
                    }
                });
            });
            ChartInfo.functions.setcustomhierarchy(customhierarchy);
            _.each(chartDetails['yAxis'], function (value, index) {
                if (tempObject.hasOwnProperty(value.columnName)) {
                    value["displayName"] = tempObject[value.columnName];
                }
            });
            if (chartDetails["metricdatefield"] != undefined && tempObject.hasOwnProperty(chartDetails["metricdatefield"])) {
                chartDetails["metricdatefielddisplay"] = tempObject[chartDetails["metricdatefield"]];
            }
            _.each(chartDetails['xAxis'], function (value, index) {
                if (tempObject.hasOwnProperty(value.columnName)) {
                    value["displayName"] = tempObject[value.columnName];
                }
            });
            _.each(chartDetails['tooltip'], function (value, index) {
                if (tempObject.hasOwnProperty(value.columnName)) {
                    value["displayName"] = tempObject[value.columnName];
                }
            });
            if (chartDetails['legend'] != undefined) {
                var columnName = chartDetails['legend']["columnName"];
                if (tempObject.hasOwnProperty(chartDetails['legend'].columnName)) {
                    chartDetails['legend']["displayName"] = tempObject[columnName];
                }
            }
            // for updating summary table
            _.each(chartDetails["summarytablecolumns"], function (xAxisObject) {
                if (xAxisObject.columnName != undefined && tempObject[xAxisObject.columnName] != undefined) {
                    xAxisObject.displayName = tempObject[xAxisObject.columnName];
                }
            });
            if (chartDetails["summarytablegroupbys"] != undefined && tempObject[chartDetails["summarytablegroupbys"].columnName] != undefined) {
                chartDetails["summarytablegroupbys"].displayName = tempObject[chartDetails["summarytablegroupbys"].columnName];
            }
            _.each(chartDetails["summarytablevalues"], function (yaxisObject) {
                if (tempObject[yaxisObject.columnName] != undefined) {
                    yaxisObject.displayName = tempObject[yaxisObject.columnName];
                }
            });
            //for updating pivot table
            _.each(chartDetails["rows"], function (row, i) {
                if (tempObject[row.columnName] != undefined) {
                    row.displayName = tempObject[row.columnName];
                }
            });
            _.each(chartDetails["columns"], function (xAxisObject, i) {
                if (tempObject[xAxisObject.columnName] != undefined) {
                    xAxisObject.displayName = tempObject[xAxisObject.columnName];
                }
            });
            _.each(chartDetails["values"], function (yaxisObject) {
                if (tempObject[yaxisObject.columnName] != undefined) {
                    yaxisObject.displayName = tempObject[yaxisObject.columnName];
                }
            });
            _.each(chartDetails["combinationchartslist"], function (val, i) {
                if (tempObject.hasOwnProperty(val.columnName)) {
                    val.displayName = tempObject[val.columnName]
                }
            })
            ChartInfo.functions.updateChartDetails(j, JSON.stringify(chartDetails));
        });
    }
}