import React from "react";
const $=window.$
export default class MLSamplingPreview extends React.Component {
    constructor(props) {
        super(props);
    }
render(){
    return(
        <></>
    )
}}