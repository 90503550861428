import React, { Component } from "react";
import download from 'downloadjs'
import _ from 'lodash';
import $ from 'jquery';
import Highcharts from 'highcharts'
import SendRequest from '../../SendRequest';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import HighchartsReact from 'highcharts-react-official'
import PubSub from 'pubsub-js'

import ReactDOM from "react-dom";
import BootStrapTableHeaderTemplate from './bootstrapTableHeaderTemplate.jsx';

class BootStrapTable extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.state.isImportedReport = false;
        if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
            this.state.isImportedReport = true;
        }
    }
    componentWillUnmount() {
    }
    drawBootstrapTable(currentChartNumber, gridmodel, tableInfo, pos, popupwindow, reporttype, $this, isFromPagenation, ForPreviewData) {
        if (popupwindow != "popupview") {
            $("#spinnerchart" + currentChartNumber).show();
        }
        $("#chartmetric" + currentChartNumber).addClass('hide');
        $(".removeform").remove();
        var that = this.state;
        var thiscopy = this;
        that.offset = 0;
        that.rowLimit = 10;
        if (tableInfo != undefined && tableInfo.pageLimit != undefined) {
            that.rowLimit = tableInfo.pageLimit;
        }
        if (tableInfo != undefined && tableInfo.page != undefined && tableInfo.page > 1) {
            that.offset = (tableInfo.page - 1) * that.rowLimit;
        }
        var groupBy = tableInfo.groupBy;
        if (groupBy === undefined) {
            if (JSON.parse(gridmodel.get("selectedcolumns"))[0] != undefined) {
                groupBy = JSON.parse(gridmodel.get("selectedcolumns"))[0].actualnames;
                if (groupBy === "Measure Names") {
                    groupBy = JSON.parse(gridmodel.get("selectedcolumns"))[1].actualnames;
                }
            }
        }
        var groupByOriginal;
        var sortBy;
        var page;
        if (tableInfo != undefined) {
            var isDispalyNamefound = false;
            var selectedColumns = JSON.parse(gridmodel.get("selectedcolumns"));
            _.each(selectedColumns, function (model, i) {
                if (model.displaynames === groupBy) {
                    groupByOriginal = model.actualnames;
                    isDispalyNamefound = true;
                    return false; // breaks
                }
            });
            if (!isDispalyNamefound) {
                groupByOriginal = groupBy;
            }
            sortBy = tableInfo.sortBy;
            page = tableInfo.page;
            that.sortBy = sortBy;
            that.groupBy = groupBy;
            that.page = page;
        }
        if (sortBy === undefined)// Making First column as deafault Ascening order.
        {
            sortBy = "asc";
        }
        var dataModel = new FormData();
        if((window.location.pathname.indexOf("viewreport")!=-1||window.location.pathname.indexOf("sharereport")!=-1||window.location.pathname.indexOf("embeddedreport")!=-1 || window.location.pathname.indexOf("alertreport")!=-1)){
          if(window.location.pathname.split("/").length==5){
            this.state.reportId  = atob(window.location.pathname.split("/")[4]);
          }else if((window.location.pathname.indexOf("embeddedreport")!=-1 || window.location.pathname.indexOf("alertreport")!=-1) && window.location.pathname.split("/").length==6){
            this.state.reportId  = atob(window.location.pathname.split("/")[5]);
          }
          if(currentChartNumber !=null && currentChartNumber !=undefined && currentChartNumber !=''){
                   var nlprowlimitobj = ChartInfo.functions.getNLPTableRowLimitDetails(currentChartNumber);
                   if(nlprowlimitobj!=undefined && nlprowlimitobj!="" && nlprowlimitobj!=null){
                    dataModel.append("nlpDetailsObj", JSON.stringify(nlprowlimitobj));   
                    }
           }
         }
        dataModel.set("aggregation", gridmodel.get("aggregation"));
        dataModel.set("xaxis", gridmodel.get("xaxis"));
        dataModel.set("xaxisdataType", gridmodel.get("xaxisdataType"));
        dataModel.set("yaxis", gridmodel.get("yaxis"));
        dataModel.set("yAxisDataType", gridmodel.get("yAxisDataType"));
        dataModel.set("datamodelId", gridmodel.get("datamodelId"));
        dataModel.set("indexName", gridmodel.get("indexName"));
        dataModel.set("reportId", gridmodel.get("reportId"));
        dataModel.set("customFields", gridmodel.get("customFields"));
        if (gridmodel.get("filterObject") != "" && gridmodel.get("filterObject") !=undefined) {
            dataModel.set("filterObject", gridmodel.get("filterObject"));
        }
        //dataModel.set("filterObject", gridmodel.get("filterObject"));
        dataModel.set("rangefieldMap", gridmodel.get("rangefieldMap"));
        dataModel.set("selectedcolumns", gridmodel.get("selectedcolumns"));
        dataModel.set("entities", gridmodel.get("entities"));
        dataModel.set("rowLimit", that.rowLimit);
        dataModel.set("timeZone", gridmodel.get("timeZone"));
        dataModel.set("isPurgedReport", false);
        dataModel.set("isImportedReport", that.isImportedReport);
        dataModel.set("script", gridmodel.get("script") != undefined && gridmodel.get("script") != null ? gridmodel.get("script") : "");
        dataModel.set("customaggregatedscriptfield", gridmodel.get("customaggregatedscriptfield") != undefined && gridmodel.get("customaggregatedscriptfield") != null ? gridmodel.get("customaggregatedscriptfield") : "");
        dataModel.set("custommeasurehierarchy", gridmodel.get("custommeasurehierarchy") != undefined && gridmodel.get("custommeasurehierarchy") != null ? gridmodel.get("custommeasurehierarchy") : "");
        dataModel.set("offset", that.offset);
        dataModel.set("groupBy", groupByOriginal);
        dataModel.set("sortBy", sortBy);
        dataModel.set("numformat", gridmodel.get("numberformat"));
        dataModel.set("isAdvanceViz", false);
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getDataTable",
            body: dataModel,
            sucessFunction: this.reportDataSuccess,
            rejectFunction: this.reportDataError,
            headers: requestHeaders
        });
        $(".downloadreports li a").unbind().click(function (e) {
            e.preventDefault();
            var $this = e.currentTarget;
            if ($this.getAttribute("data-num") != undefined) {
                currentChartNumber = $this.getAttribute("data-num");
            }
            var filterDataForDownload = [];
            if (ChartInfo.functions.getFilters(true) != undefined && ChartInfo.functions.getFilters(true) != null && ChartInfo.functions.getFilters(true).length > 0) {
                filterDataForDownload = filterDataForDownload.concat(ChartInfo.functions.getFilters(true));
            }
            if (ChartInfo.functions.getdrillfitlerArray() != null && ChartInfo.functions.getdrillfitlerArray().length > 0 && ChartInfo.functions.getdrillfitlerArray() != undefined) {
                filterDataForDownload = filterDataForDownload.concat(ChartInfo.functions.getdrillfitlerArray());
            }
            if (ChartInfo.functions.getAllTempSummaryTableFilterValues() != undefined && ChartInfo.functions.getAllTempSummaryTableFilterValues().length > 0) {
                var summaryTabledrillDownfilter = [];
                _.each(ChartInfo.functions.getAllTempSummaryTableFilterValues(), function (value) {
                    if (value.isDrillDownAll != undefined && value.isDrillDownAll == true) {
                        summaryTabledrillDownfilter.push(value);
                    }
                });
                filterDataForDownload = filterDataForDownload.concat(summaryTabledrillDownfilter);
            }
            if (filterDataForDownload.length > 0) {
                gridmodel.set("filterObject", JSON.stringify(filterDataForDownload))
            } else {
                gridmodel.set("filterObject", "[]");
            }
            var ChartInfoNow = ChartInfo.functions.getChartDetails(currentChartNumber) == undefined ? undefined : ChartInfo.functions.getChartDetails(currentChartNumber);
            if (ChartInfoNow != undefined) {
                var data = JSON.parse(ChartInfoNow);
                var dobyselecteddata = data["dobyselectedcolumns"];
                gridmodel.set("selectedcolumns", JSON.stringify(dobyselecteddata));
            }
            if ($(".rowsfrom[data-num=" + currentChartNumber + "]").val() < 1 || $(".rowsfrom[data-num=" + currentChartNumber + "]").val().indexOf('.') != -1) {
                e.stopPropagation();
                $(".rowsfrom[data-num=" + currentChartNumber + "]").addClass('bird-has-error');
                $(".rowsto[data-num=" + currentChartNumber + "]").removeClass('bird-has-error');
                return;
            } else if ($(".rowsto[data-num=" + currentChartNumber + "]").val().indexOf('.') != -1) {
                $(".rowsfrom[data-num=" + currentChartNumber + "]").removeClass('bird-has-error');
                e.stopPropagation();
                $(".rowsto[data-num=" + currentChartNumber + "]").addClass('bird-has-error');
                return;
            } else {
                $(".rowsto[data-num=" + currentChartNumber + "]").removeClass('bird-has-error');
                $(".rowsfrom[data-num=" + currentChartNumber + "]").removeClass('bird-has-error');
            }
            if ($(".rowsto[data-num=" + currentChartNumber + "]").val() == "" || parseInt($(".rowsto[data-num=" + currentChartNumber + "]").val()) < 1 || parseInt($(".rowsto[data-num=" + currentChartNumber + "]").val()) < parseInt($(".rowsfrom[data-num=" + currentChartNumber + "]").val())) {
                e.stopPropagation();
                $(".rowsto[data-num=" + currentChartNumber + "]").addClass('bird-has-error');
                return;
            } else {
                $(".rowsto[data-num=" + currentChartNumber + "]").removeClass('bird-has-error');
            }
            var rowLimit = "";
            rowLimit = rowLimit.concat($(".rowsfrom[data-num=" + currentChartNumber + "]").val() - 1);
            rowLimit = rowLimit.concat(",");
            rowLimit = rowLimit.concat($(".rowsto[data-num=" + currentChartNumber + "]").val() - $(".rowsfrom[data-num=" + currentChartNumber + "]").val() + 1);
            var tableInfor = {
                groupBy: groupBy,
                sortBy: sortBy,
                page: page,
                downloadType: $($this).attr('class'),
                pageLimit: rowLimit,
                paginationdiv: tableInfo != undefined && tableInfo.paginationdiv != undefined ? tableInfo.paginationdiv : undefined
            };
            $(".pivotdataview .ball-pulse").css({ "z-index": "9" })
            thiscopy.downloadBootstrapTable(currentChartNumber, gridmodel, tableInfor, pos, popupwindow, reporttype, $this, true);
        })
    }
    reportDataSuccess = props => {
        $('.spinner').hide();
        $('.gridspinner').hide();
        try {
            var jsonData = props;
            var parsedData = jsonData;
            if (parsedData != undefined) {
                jsonData = JSON.parse(decodeURIComponent(parsedData));
            }
        } catch (e) {
        }
        if (jsonData.hasOwnProperty('error')) {
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('hide');
            $('.birdmessage h2').empty().append('Error : ');
            $('.birdmessage h2').append('<span class="errorcode">');
            $('.birdmessage .errorcode').empty().append(jsonData.errorCode);
            $('.birdmessage-info').empty().text(jsonData.error);
            $('.details').addClass('show');
            $('.messagedetails xmp').empty().append(jsonData.errorTrace);
            //$(".ball-pulse").hide();
            $("#spinnerchart" + currentChartNumber).hide();
            return
        }
        var that = this.state;
        var $this = that.$this;
        var popupwindow = that.popupwindow;
        var tableInfo = that.tableInfo;
        var isFromPagenation = that.isFromPagenation;
        var currentChartNumber = that.currentChartNumber;
        var len = jsonData.length - 1;
        var totalRecords = jsonData[len].datacount;
        var headerlist = jsonData[len].tablecolumns;
        var headerDataTypeslist = jsonData[len].tableColumnDataTypes;
        if(currentChartNumber !=undefined && currentChartNumber !='' && currentChartNumber!=null){
            var nlprowlimitobj = ChartInfo.functions.getNLPTableRowLimitDetails(currentChartNumber );
            if(nlprowlimitobj!=undefined && nlprowlimitobj!="" && nlprowlimitobj!=null){
              if(nlprowlimitobj["nlprowLimit"]!=undefined && nlprowlimitobj["nlprowLimit"]!="" && nlprowlimitobj["nlprowLimit"]!=null){
                totalRecords = parseInt(nlprowlimitobj["nlprowLimit"]) ;
              }
            }
        }
        var data;
        var keys = Object.keys(headerlist);
        var totalPages = 1;
        var pageDisplay;
        if (tableInfo != undefined && tableInfo.pageLimit != undefined) {
            pageDisplay = parseInt(tableInfo.pageLimit);
        }
        if (pageDisplay == undefined) {
            pageDisplay = 10;
        }
        var showPageList;
        if (totalRecords > 10) {
            showPageList = true;
            totalPages = Math.ceil(totalRecords / pageDisplay);
        } else {
            totalPages = 1;
            showPageList = false;
        }
        var currentpage = that.page;
        if (currentpage == undefined) {
            currentpage = 1;
        }
        var showTitle;
        var tempDataArray = [];
        _.each(jsonData, function (dataObject, i) {
            if (dataObject["data"] != undefined) {
                tempDataArray.push(dataObject["data"]);
            }
        });
        data = tempDataArray;
        var columns = [];
        _.each(headerlist, function (col, i) {
            var temp = {};
            temp["id"] = i;
            temp["dataField"] = col;
            temp["text"] = col;
            temp["sort"] = true;
            temp["align"] =  headerDataTypeslist[i]=='number'?'right':'left'
            temp["headerClasses"] = headerDataTypeslist[i]=='number'?'align-right':''
            columns.push(temp);
        });
        showTitle = tableInfo != undefined ? tableInfo.showTitle : false;
        if (popupwindow === "popupview") {
            $(".pivotdataview .ball-pulse").hide();
        }
        if (!isFromPagenation) {
            if (popupwindow === "popupview") {
                $(".drawbootstraptable" + currentChartNumber + "").empty();
                try {
                    ReactDOM.unmountComponentAtNode(document.getElementById("pivotdataview" + currentChartNumber));
                } catch (e) { }
                ReactDOM.render(<BootStrapTableHeaderTemplate storedprops={this.props} page={currentpage} sizePerPage={pageDisplay} columns={columns} data={data} chartnumber={currentChartNumber} totalRecords={totalRecords}></BootStrapTableHeaderTemplate>, document.getElementById("pivotdataview" + currentChartNumber));
                $('.pivotdataviewwrap').removeClass('opacityhide');
                $('.summarytable_datasetcount').text($('.datacount[data-num=' + currentChartNumber + ']  .publishinfo').text());
                $('.summarytable_filtered_datasetcount').text(totalRecords);
                if (tableInfo != undefined) {
                    if (showTitle == false) {
                        $('.summarytable_filtervalues').html(tableInfo.drilldownvalue)
                    } else {
                        $('.summarytable_filtervalues').text(tableInfo.drilldownvalue).attr("title", tableInfo.drilldownvalue);
                    }
                    if ((tableInfo.chartType === "summarytable" || tableInfo.chartType === "pivot")) {
                        $(".chartadvancedoptions[data-num='chart" + currentChartNumber + "']").hide();
                    }
                    else {
                        $(".chartadvancedoptions[data-num='chart" + currentChartNumber + "']").show();
                    }
                }
                //	$(".chartadvancedoptions[data-num='chart"+currentChartNumber+"']").show();
            }
            else {
                $("#chart" + currentChartNumber).empty();
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $("#chart" + currentChartNumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                ReactDOM.render(<BootStrapTableHeaderTemplate storedprops={this.props} page={currentpage} sizePerPage={pageDisplay} columns={columns} data={data} chartnumber={currentChartNumber} totalRecords={totalRecords}></BootStrapTableHeaderTemplate>, document.getElementById(dynamicdiv));

                //      ReactDOM.render(<BootStrapTableHeaderTemplate keys={keys} headerlist={headerlist} chartnumber={currentChartNumber} showPageList={showPageList}></BootStrapTableHeaderTemplate>,document.getElementById("chart"+currentChartNumber));
                //    $("#chart" + currentChartNumber).html(template({ keys: keys, headerlist: headerlist, chartnumber: currentChartNumber, showPageList: showPageList }));
                if (jsonData[len] != undefined && jsonData[len].filterCount != undefined) {
                    $(".filterdatacount").text("Filtered Count: " + jsonData[len].filterCount);
                    $(".filterDatacount").text(jsonData[len].filterCount);
                    ChartInfo.functions.setFilterDataCount(jsonData[len].filterCount);
                    $('.filterdatacountrow').removeClass("hide");
                } else {
                    $(".filterdatacount").text(" ");
                    ChartInfo.functions.setFilterDataCount(0);
                    $('.filterdatacountrow').addClass("hide");
                }

                if (tableInfo != undefined && tableInfo.showTitle) {
                    $(".boc-viewreport-title").removeClass("hide");
                    $(".boc-viewreport-title").text(tableInfo.title).attr("title", tableInfo.title);
                } else {
                    //$(".boc-viewreport-title").addClass("hide");
                }
                $(".chartadvancedoptions[data-num='chart" + currentChartNumber + "']").hide();
            }
        }
        if (tableInfo != undefined && tableInfo.chartType === "pivot") {
            $(".pivot_filtered_datasetcount").text(totalRecords);
        }
        if (popupwindow != "popupview") {
            $(".predictmodelsummary" + currentChartNumber).addClass("hide");
            $('.paginationdiv' + currentChartNumber).show();
            sessionStorage.removeItem("datatable_popup_filterdata");
            $('.richtextcontrolschart' + currentChartNumber).hide();
            $(".pivotnavigationbuttons" + currentChartNumber).hide();
            $(".pivotcharticon" + currentChartNumber).hide();
            $('.singlereport_summarymodal[data-num=' + currentChartNumber + ']').addClass('hide');
            $(".summarytablemessage").remove();

        }
        if (tableInfo != undefined && tableInfo.chartType === "doby") {
            $(".showtrending").hide();
        }
        $(".btablerowlimit li").removeClass('active');
        _.each($(".btablerowlimit li"), function (value) {
            if (that.rowLimit == value.textContent) {
                $(value).addClass("active")
            }
        });
        $($this).addClass('active');

        //Maximised stoary Board should have return button
        if (sessionStorage.getItem("customStoryBoardReportId") != null && sessionStorage.getItem("customStoryBoardReportId") != "null") {
            if ($('.redirectcustomstoryboard').length == 0) {
                $('.reporttitletoolbar' + currentChartNumber + ' .pull-right.bgcommon').prepend('<button class="btn btn-bird-group redirectcustomstoryboard" title="Back to Custom Storyboard"><i class="fa fa-share fa-flip-horizontal" aria-hidden="true"></i></button>')
            }

        }
        if (popupwindow === "popupview") {
            that.reporttype = "pop" + that.reporttype;
        }
        $(".btablerowlimit[data-num=" + currentChartNumber + "]").addClass(that.reporttype);
        $("th").addClass(that.reporttype + "sortby");
        if (tableInfo != undefined && tableInfo.paginationdiv === false) {
            $('.paginationdiv' + currentChartNumber).hide();
            $(".sinbleviewchartdata").show();
            $(".sinbleviewchartfromchartdata").show();
        }
        $(".page-size" + currentChartNumber).html(that.rowLimit);
        if (popupwindow != "popupview") {
            $(window).on("resize", function () {
                clearTimeout(window.resizedFinished);
                window.resizedFinished = setTimeout(function () {
                }, 1000);
            });
            $("#spinnerchart" + currentChartNumber).hide();
        } else {
            $(window).on("resize", function () {
                clearTimeout(window.resizedFinished);
                window.resizedFinished = setTimeout(function () {
                }, 1000);
            });
            $("#spinnerchart" + currentChartNumber).hide();
        }
        $('.drillmoreitemslist').click(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            var top = e.pageY + 14;
            if (top < 100) { top = 100; }
            var left = e.pageX - 10;
            $("body>.quickfilterslist, body>span.quickfiltermask").remove();
            var clonedEle = $($this).next().clone();
            $("body").append(clonedEle.removeClass("hide").css({ top: top, left: left })).append("<span class='quickfiltermask'></span>");
            $("body span.quickfiltermask").on("click", function (e) {
                $("body>.quickfilterslist, body>span.quickfiltermask").remove();
            });
        });
    };
    reportDataError = props => {
        console.log("Error in Report Body");
    };
    resizeTilesBootstrapTable(chartnumber, table) {
        var $table = table;
        var baseHeight = $("#chart" + chartnumber).height(),
            headerHeight = $("#chart" + chartnumber + " .notifications-table-container:nth(0) table>thead").height(),
            dataHeight = $("#chart" + chartnumber + " .notifications-table-container:nth(0) table>tbody").height(),
            paginationHeight = $("#chart" + chartnumber + " .notifications-table-container:nth(1)").height(),
            breadcrumbheight = $(".breadcrumb[data-num='" + chartnumber + "']").outerHeight();
        var chartHeight = baseHeight - (headerHeight + paginationHeight + 0) + 22 - breadcrumbheight;
        if (dataHeight != null && chartHeight >= dataHeight) { chartHeight = chartHeight - (chartHeight - dataHeight) + 33 };
        //   $table.bootstrapTable('resetView' ,{height:chartHeight});
    }
    resizePopupBootstrapTable(chartnumber, table) {
        var $table = table;
        var baseHeight = $(".pivotdataview[data-number='" + chartnumber + "']").outerHeight(),
            headerHeight = $(".pivotdataview[data-number='" + chartnumber + "'] .summarydatacountcontainer").outerHeight(),
            dataHeight = $(".pivotdataview[data-number='" + chartnumber + "'] .drawbootstraptable" + chartnumber + " .notifications-table-container:nth(0) table>tbody").height(),
            paginationHeight = $("#chart" + chartnumber + " .notifications-table-container:nth(1)").height();
        var chartHeight = baseHeight - (headerHeight + 120);
        if (dataHeight != null && chartHeight >= dataHeight) { chartHeight = dataHeight + 41 };
        //    $table.bootstrapTable('resetView' ,{height:chartHeight});
    }
    render() {
        var that = this.state;
        if (this.props.storedprops != undefined && this.props.storedprops != null) {
            this.props = this.props.storedprops;
        }
        that.currentChartNumber = this.props.currentChartNumber;
        that.gridmodel = this.props.gridmodel;
        that.tableInfo = this.props.tableInfo;
        that.pos = this.props.pos;
        that.popupwindow = this.props.popupwindow;
        that.reporttype = this.props.reporttype;
        that.$this = this.props.target;
        that.isFromPagenation = this.props.isFromPagenation;
        that.ForPreviewData = this.props.ForPreviewData;
        this.drawBootstrapTable(that.currentChartNumber, that.gridmodel, that.tableInfo, that.pos, that.popupwindow, that.reporttype, that.$this, that.isFromPagenation, that.ForPreviewData);
        return (
            <div></div>
        );
    }
    downloadBootstrapTable(currentChartNumber, gridmodel, tableInfo, pos, popupwindow, reporttype, $this, isFromPagenation) {
        if (tableInfo != undefined && (tableInfo.downloadType === "xls" || tableInfo.downloadType === "csv" || tableInfo.downloadType === "pdf")) {
            $(".gridspinner").hide()
            var dataModel = new FormData();
            if(currentChartNumber !=null && currentChartNumber !=undefined && currentChartNumber !=''){
                var nlprowlimitobj = ChartInfo.functions.getNLPTableRowLimitDetails(currentChartNumber);
                if(nlprowlimitobj!=undefined && nlprowlimitobj!="" && nlprowlimitobj!=null){
                 dataModel.append("nlpDetailsObj", JSON.stringify(nlprowlimitobj));   
                 }
        }
            dataModel.set("aggregation", gridmodel.get("aggregation"));
            dataModel.set("xaxis", gridmodel.get("xaxis"));
            dataModel.set("datamodelId", gridmodel.get("datamodelId"));
            dataModel.set("selectedcolumns", gridmodel.get("selectedcolumns"));
            dataModel.set("xaxisdataType", gridmodel.get("xaxisdataType"))
            dataModel.set("filterObject", gridmodel.get("filterObject"));
            dataModel.set("customFields", gridmodel.get("customFields"));
            dataModel.set("sortBy", gridmodel.get("sortBy"));
            dataModel.set("yaxis", gridmodel.get("yaxis"));
            dataModel.set("yAxisDataType", gridmodel.get("yAxisDataType"));
            dataModel.set("reportId", gridmodel.get("reportId"));
            dataModel.set("indexName", gridmodel.get("indexName"));
            dataModel.set("script", gridmodel.get("script"));
            dataModel.set("customaggregatedscriptfield", gridmodel.get("customaggregatedscriptfield"));
            dataModel.set("custommeasurehierarchy", gridmodel.get("custommeasurehierarchy"));
            dataModel.set("isPurgedReport", false);
            dataModel.set("timeZone", gridmodel.get("timeZone"));
            dataModel.set("entities", gridmodel.get("entities"));
            dataModel.set("isImportedReport", gridmodel.get("isImportedReport") === undefined ? false : gridmodel.get("isImportedReport"));
            dataModel.set("download", tableInfo.downloadType);
            dataModel.set("offset", tableInfo.offset);
            dataModel.set("rowLimit", tableInfo.pageLimit);

            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };

            SendRequest({
                url: "downloadDataTable",
                body: dataModel,
                sucessFunction: (blob) => {
                    if (tableInfo.downloadType == "pdf") {
                        download(blob, "datafile.pdf", "application/pdf");
                    } else if (tableInfo.downloadType == "xls") {
                        download(blob, "datafile.xlsx", "application/vnd.ms-excel");
                    } else {
                        download(blob, "my-csv-file.csv");
                    }
                },
                rejectFunction: reportDataError => {
                    console.log(reportDataError)
                },
                headers: requestHeaders
            });
        }
    }
    componentDidMount() {
        //this.modifyChartHeightOnResize();
    }

};
export default BootStrapTable;
