import React from 'react';
import ReactDOM from 'react-dom';
import smartinsightintro from "../../images/birdimages/smartinsightspinner.png";
import * as ModalProperties from "../../js/helpers/utils/modalproperties";
import { lang } from "../../js/helpers/utils/languages/language";
const $=window.$
export default class MLReportTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isSampling:false};
    }
    componentDidMount(){
        var that = this;
        if(window.location.pathname.indexOf("viewsample")!=-1){
            this.setState({isSampling:true},()=>{
                $("#mlsplitcontainer").empty();
                let htmlString = that.mlSamplingSteps()
                $("#mlsplitcontainer").html(htmlString)
                that.props.loadjquerysteps()
                $(".spinner").hide();
            })
        }
    }
    mlSamplingSteps = ()=>{
        return(
            /** Model Parameters begin */
            `<h3><span>${`RUN Model`}</span></h3>`
            +`<section>`
            +`<div class="mlmodelparameterscontainer" id="mlmodelparameterscontainer" tabid=${this.props.tabid}></div>`
        
            +`</section>`
            /*** Model Parameters end*/

            /*** Data Preview */
            /** 
            +`<h3>${`Data Preview`}</h3>`
            +`<section>`
                +`<div class="datapreviewcontainer" id="datapreviewcontainer" tabid=${this.props.tabid}></div>`
            +`</section>`
            */
            /*Data Preview End */
        
            /* Outcome  */
            /*** 
            +`<h3>${lang["mlreports.outcome"]}</h3>`
            +`<section>`
                +`<div class="smartinsightdatacontainer" tabid=${this.props.tabid}>`
                    +`<div class="col-xs-12 leftpane pull-left">`
                        
                        +`<section class="boc-snapshot-container">`
                            +`<div class="container-fluid">`
                                +`<div class="row" style="background: #f3f4f9;padding: 0 8px 0 0">`
                                    +`<div class="col-xs-12 marg-bot-10" style="margin: 0;width:calc(100% - 0px);background: #ffffff;padding: 2px">`
                                        +`<span class="mloutcomehead" tabid=${this.props.tabid}><i class="fa fa-mlreport marg-righ-10"></i><span></span></span>`
                                        +`<button class="runmodelbtn btn btn-bird btn-highlight pull-right"> ${lang["mlreports.runmodel"]}</button>`
                                        +`<button type="button" class="btn btn-bird btn-highlight marg-righ-5 refreshmlcharts pull-right" id="refreshmlcharts" title=${lang["mlreports.reloadreports"]}><i class="fa fa-repeat"></i></button>`
                                        +`<button class="btn btn-transparent pull-right mlwarning fa-orange hide" title=''><span class="fa-orange"><i class="fa fa-info"></i></span></button>`
                                    +`</div>`
                                +`</div>`
                            +`</div>`
                            +`<div class="container-fluid">`
                                +`<div class="row grid-stack" id="mlcharts" tabid=${this.props.tabid}>`
                                +`</div>`
                            +`</div>`
                        +`</section>`
                    +`</div>`
                    +`/div>`
            +`</section>`
            */
            /** Outcome end */
        )
    }
    render() {
        return (
            <React.Fragment>
                <section className="boc-viewreport-section mlreport" >
                    <div className="boc-home boc-dark-background">
                        <div id="reportheader">
                           
                        </div>
                        <div className="container-fluid fill-height">
                            <div className="row"><div className="col-xs-12 quickfiltercontainer"></div></div>
                            <div className="row fill-height">
                                <div className="mlstoryboardtabs fill-height">
                                    <div id="mlsplitcontainer" tabid={this.props.tabid}>
                                        {/* Data Snapshot */}
                                        <h3><span>{lang["mlreports.datasnap"]}</span></h3>
                                        <section>
                                            <div id="mldatasnapshot" className="fill-height" tabid={this.props.tabid}>
                                                <div className="smartinsightintro-container fill-height">
                                                    <div className="smartinsightintro">
                                                        <img className="smartintroicon rotateanim" alt={lang["mlreports.insightsrun"]} src={smartinsightintro} />
                                                        <span>{lang["mlreports.loadingdata"]} </span>
                                                    </div>
                                                </div>
                                                <div id="mlsnapshotdatacontainer" tabid={this.props.tabid} className="hide"></div>
                                            </div>

                                        </section>
                                        {/*Data Snapshot END*/}

                                        {/* Feature Engineering */}
                                        <h3><span>{lang["mlreports.featureeng"]}</span></h3>
                                        <section>
                                            <div className="featureengineeringcontainer" tabid={this.props.tabid}>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-xs-12">
                                                            <h2>{lang["mlreports.featureeng"]}<button className="btn btn-bird btn-sm btn-highlight pull-right createfeatureengineeringfield"><i className="fa fa-plus"></i><span>{lang["mlreports.createcustom"]}</span></button></h2>
                                                        </div>
                                                    </div>
                                                    <div className="row marg-top-10">
                                                        <div className="col-xs-12">
                                                            <div className="asd">
                                                                <div id="mlallcolumnscontainer" tabid={this.props.tabid} className="mlallcolumnscontainer">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xs-12">
                                                            <div className="mlcustomfieldscontainer" tabid={this.props.tabid}>
                                                                <ul className="list-group customdimensions"></ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {/*Feature Engineering END */}

                                        {/* RUN Model */}
                                        <h3>{lang["mlreports.Runmodel"]}</h3>
                                        <section>
                                            <div className="mlmodelparameterscontainer" id="mlmodelparameterscontainer" tabid={this.props.tabid}></div>
                                        </section>
                                        {/*RUN Model END */}

                                        {/* Outcome  */}
                                        <h3>{lang["mlreports.outcome"]}</h3>
                                        <section>
                                            <div className="smartinsightdatacontainer" tabid={this.props.tabid}>
                                                <div className="col-xs-12 leftpane pull-left">
                                                    {/* Gridster Start */}
                                                    <section className="boc-snapshot-container">
                                                        <div className="container-fluid">
                                                            <div className="row" style={{background: "#f3f4f9",padding:" 0 8px 0 0"}}>
                                                                <div className="col-xs-12 marg-bot-10" style={{margin: 0,width: "calc(100% - 0px)",background: "#ffffff",padding: "2px"}}>
                                                                    <span className="mloutcomehead" tabid={this.props.tabid}><i className="fa fa-mlreport marg-righ-10"></i><span></span></span>
                                                                    <button className="runmodelbtn btn btn-bird btn-highlight pull-right"> {lang["mlreports.runmodel"]}</button>
                                                                    <button type="button" className="btn btn-bird btn-highlight marg-righ-5 refreshmlcharts pull-right" id="refreshmlcharts" title={lang["mlreports.reloadreports"]}><i className="fa fa-repeat"></i></button>
                                                                    <button className="btn btn-transparent pull-right mlwarning fa-orange hide" title=''><span className="fa-orange"><i className="fa fa-info"></i></span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="container-fluid">
                                                            <div className="row grid-stack" id="mlcharts" tabid={this.props.tabid}>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </section>
                                        {/*Outcome END */}
                                    </div>
                                </div>

                            </div>
                        </div>
                        {this.props.displayFooter != false ? <React.Fragment>
                            <footer className="footer">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-7">

                                            <span>
                                                <button className="mlloadingbtn hide btn btn-bird ">
                                                    <i className="fa fa-spinner fa-pulse" style={{color: "#009688"}}></i> {lang["mlreports.modelrun"]}
							</button>
                                            </span>
                                        </div>

                                        <div className="col-xs-5">
                                            <div className="itsaudit hide">
                                                <span className="livecirclecontainer dataauditlivecontainer hide">
                                                    <span className="livecircle"></span> {lang["mlreports.dataudit"]}
							</span>
                                                <span className="livecirclecontainer metadataauditlivecontainer hide">
                                                    <span className="livecircle"></span>  {lang["mlreports.metadataaudit"]}
							</span>
                                            </div>
                                            <div className="itslive hide"><span className="liveupdatetime"></span><span className="livecirclecontainer"><span className="livecircle"></span>   {lang["mlreports.live"]}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </footer></React.Fragment>
                            : <React.Fragment></React.Fragment>
                        }
                    </div>
                    <div className="col-xs-12 instant-floating-filters rightpane pull-right" style={{display:"none"}}>
                        <div className="minifiltertoggle"><i className="fa fa-filter"></i></div>
                        <div className="panel panel-default" id="panel1">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    <a data-toggle="collapse" data-target="#collapseOne" href="javascript:void(0)" aria-expanded="true" className="filtershead" id="filtershead">
                                    {lang["mlreports.filters"]}
				                    </a>
                                    <a href="javascript:void(0)" className="toggleinstantfiltersmenu" id="closefiltersmenu" data-close="close" style={{display: "block",float: "right"}}><i className="fa fa-times-circle"></i></a>
                                    <ul className="dropdown-menu excludenulldropdown" role="menu">
                                        <li className="aggregation-li-item">
                                            <label><input type="checkbox" className="isexcludenulls" id="isexcludenullse" />&nbsp;{lang["mlreports.exclude"]} <b>{lang["mlreports.null"]}</b> {lang["mlreports.values"]}</label>
                                        </li>
                                    </ul>
                                </h4>
                            </div>

                            <div id="collapseOne" className="panel-collapse collapse in" aria-expanded="true">
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <button type="button" className="btn btn-bird btn-sm btn-highlight marg-left-10 modalfilter maxfiltersdropdown tooltip-right" id="maxfiltersdropdowns" data-tooltip={lang["mlreports.add"]} title={lang["mlreports.addnewfilter"]} data-direction="left" data-toggle="modal" data-target="#mySmallModal"><i className="fa fa-plus marg-righ-5"></i>{lang["mlreports.addnewfilter"]}</button>
                                            {/* <button type="button" className="btn btn-bird btn-sm btn-highlight marg-left-5 modalfilter maxcustomparameterfiltersdropdown tooltip-left" id="maxcustomparameterfiltersdropdowns" data-tooltip={lang["mlreports.add"]} title={lang["mlreports.addparameter"]} data-direction="left" data-toggle="modal" data-target="#mySmallModal"><i className="fa fa-plus marg-righ-5"></i>{lang["mlreports.addparameter"]}</button> */}
                                            <button className="btn btn-bird btn-sm btn-highlight marg-righ-15 pull-right rightfiltersapply tooltip-left hide" id="rightfiltersapplys" data-tooltip="APPLY" title={lang["mlreports.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["mlreports.applyfilters"]}</button>
                                        </div>
                                    </div>
                                    {/*Instant Filters */}
                                    <div className="instant-filter-container hide">
                                        <div className="row">
                                            <div className="container-fluid">
                                                <div className="allscopeslistcontainer">
                                                    <div className="subhead">{lang["mlreports.instantfilters"]} <div className="instant-filter-title"><span><i className="fa fa-circle"></i>{lang["mlreports.exclude"]}</span>&nbsp;/<span><i className="fa fa-circle"></i>{lang["mlreports.keeponly"]}</span></div></div>
                                                    <div className="allscopeslistinnercontainer">
                                                        <div className="instant-filter-tags addInstanceFilterData"></div>
                                                    </div>
                                                    <div className="parameterfiltersgroupdiv hide">
                                                        <div className="subhead">{lang["mlreports.parameterfilters"]} </div>
                                                        <div className="instancefilterlistinnercontainer">
                                                            <div className="instant-filter-tags addURLInstanceFilterData">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 nopadding">
                                        <div className="navbar ">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="sidetheme">

                                                        {/* Accordion Goes Here */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="mlcustomfieldsmodal"></div>
                <div className="screenshotmessage hide"><div className="screenshot-message">{lang["mlreports.text"]}</div></div>
            </React.Fragment>
        );
    }
}